import { LoadingOutlined } from '@ant-design/icons';
import { Progress } from 'antd';
import { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import mean from 'lodash/mean';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { THEME } from '../../Constants';
import useCroppedMedia from '../../hooks/useCroppedMedia';
import usePrevious from '../../hooks/usePrevious';
import { VideoTrim } from '../../pages/charters/charter-projects/components/project-editor/scene-timeline/utils/VideoTrimUtils';
import { RootState } from '../../redux/RootState';
import VideoEmpty from '../../resources/videos/empty/video_empty_10.mp4';
import { APIManager } from '../../services/api/APIManager';
import { fetchAnimationByCharterIdAndParams } from '../../services/api/ChartersService';
import { MediaFile } from '../../services/api/types/ChartersServiceTypes';
import AnimationsUtils from '../../utils/AnimationsUtils';
import LottieUtils from '../../utils/LottieUtils';
import MediaUtils from '../../utils/MediaUtils';
import SubtitlesUtils from '../../utils/SubtitlesUtils';
import { AnimationPosition, Logo, MaskCodes, PIP, Size } from '../../utils/types/AnimationTypes';
import { SubtitleBlock } from '../../utils/types/ProjectTypes';
import { AnimationTheme } from '../../utils/types/ThemeTypes';
import SceneVideoPlayer from './components/SceneVideoPlayer';
import SceneVideoPlayerSkeleton from './components/SceneVideoPlayerSkeleton';

type Props = {
  isPlaying?: boolean;
  animationName?: string;
  animationTheme: AnimationTheme;
  animationTexts: string[];
  animationDelay?: number;
  format: string;
  customColors?: boolean;
  animationPosition?: AnimationPosition;
  videoMedia?: MediaFile;
  videoUrl?: string;
  audioVolume?: number;
  pip?: PIP;
  subtitlesBlock?: SubtitleBlock[];
  seekTo?: number;
  controls?: boolean;
  hideVolumeControls?: boolean;
  fullscreenDisabled?: boolean;
  hideBorders?: boolean;
  wrapperClassName?: string;
  videoTrimValue?: VideoTrim;
  mask?: MaskCodes;
  logo?: Logo;
  colorOverrides?: Record<string, string>;
  onCurrentTimeChange?: (currentTime: number) => void;
  onDurationChange?: (duration: number) => void;
  onAnimationDurationChange?: (animationDuration: number) => void;
  onIsLoadingChange?: (isLoading: boolean) => void;
  onIsPlayingChange?: (newIsPlaying: boolean) => void;
  onEndReached?: () => void;
  onVideoEnded?: () => void;
  showAnimationLoadingOverlay?: boolean;
  isCarousel?: boolean;
  isSceneCopy?: boolean;
};

type StyleProps = {
  isLoading: boolean;
  hideBorders: boolean;
  isCarousel: boolean;
};

const useStyles = createUseStyles({
  scenePlayerWrapper: ({ isLoading, hideBorders }: StyleProps) => ({
    background: !isLoading ? 'black' : undefined,
    height: 0,
    paddingBottom: '56.25%', // (1 / ratio) * 100% where ratio = 16/9
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    border: !hideBorders ? `1px solid ${THEME.DEFAULT.BORDER_COLOR}` : undefined,
    borderRadius: !hideBorders ? 5 : undefined,
  }),
  scenePlayerContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  animationLoaderContainer: ({ isCarousel }: StyleProps) => ({
    position: 'absolute',
    zIndex: 3,
    background: '#c1c1c19e', // grey background with opacity ~70%
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: isCarousel ? 10 : 16,
  }),
  loadingIcon: ({ isCarousel }: StyleProps) => ({
    fontSize: isCarousel ? 12 : 24,
    marginBottom: 8,
  }),
});

const ScenePlayer: FunctionComponent<Props> = ({
  isPlaying = false,
  animationName,
  animationTheme,
  animationTexts,
  animationPosition,
  animationDelay,
  format,
  customColors = true,
  videoMedia,
  videoUrl,
  audioVolume,
  pip,
  subtitlesBlock,
  seekTo = 0,
  controls = true,
  hideVolumeControls = false,
  fullscreenDisabled = false,
  hideBorders = false,
  wrapperClassName,
  videoTrimValue,
  mask,
  logo,
  colorOverrides,
  onCurrentTimeChange,
  onDurationChange,
  onAnimationDurationChange,
  onIsLoadingChange,
  onIsPlayingChange,
  onEndReached,
  onVideoEnded,
  showAnimationLoadingOverlay = false,
  isCarousel = false,
  isSceneCopy = false,
}: Props) => {
  const { t } = useTranslation();

  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [isLottieLoading, setIsLottieLoading] = useState(false);
  const [croppedMedia, isCroppedMediaLoading, mediaCroppingProgress] = useCroppedMedia(
    videoMedia,
    isSceneCopy,
    isCarousel
  );
  const [croppedPip, isCroppedPipLoading, pipCroppingProgress] = useCroppedMedia(pip?.source, isSceneCopy, isCarousel);
  const [isPlayerLoading, setIsPlayerLoading] = useState(true);
  const [lottieJson, setLottieJson] = useState<string>();
  const [animationDuration, setAnimationDuration] = useState<number>(0);
  const [isSlide, setIsSlide] = useState(false);
  const [playerVideoUrl, setPlayerVideoUrl] = useState<string>();
  const [completePip, setCompletePip] = useState<PIP>();
  const [progress, setProgress] = useState<number>();
  const [playerSize, setPlayerSize] = useState<Size>();

  const apiParams = useMemo(() => {
    if (!(animationName && animationPosition)) {
      return undefined;
    }

    let duration = !isSlide && videoMedia ? videoMedia.duration : undefined; // Use the video duration for Recordable
    if (videoTrimValue && duration !== undefined) {
      duration = videoTrimValue.trimEnd - videoTrimValue.trimStart;
    } else if (pip && pip.duration) {
      duration = pip.duration;
    }

    let subtitleHeight = 0;
    if (playerSize && subtitlesBlock) {
      const playerFormatSize = AnimationsUtils.getVideoPlayerSizeByFormat(format, playerSize);
      subtitleHeight = SubtitlesUtils.getSubtitlesBlockMaxHeight(subtitlesBlock, playerFormatSize.width);
    }

    return {
      animationName,
      theme: animationTheme,
      format,
      animationTexts,
      useOriginalSettings: !customColors, // Use the original settings is the same as not using the custom colors
      duration,
      videoTrimValue,
      position: {
        code: animationPosition.code,
        x: animationPosition.x,
        y: animationPosition.y,
      },
      colorOverrides,
      subtitleHeight,
    };
  }, [
    animationName,
    animationTheme,
    format,
    animationTexts,
    customColors,
    animationPosition,
    isSlide,
    videoMedia,
    colorOverrides,
    playerSize,
  ]);

  const previousApiParams = usePrevious(apiParams);

  const classes = useStyles({ isLoading: isDataLoading, hideBorders, isCarousel });
  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());

  const charterId = useSelector((state: RootState) => state.charters.current?.id);

  useEffect(() => {
    const loadingValue = isCroppedMediaLoading || isCroppedPipLoading;
    setIsDataLoading(loadingValue ?? false);
  }, [isLottieLoading, isCroppedMediaLoading, isCroppedPipLoading]);

  // Check if the animation is a Slide
  useEffect(() => {
    if (!animationName) {
      setIsSlide(false);
      return;
    }
    setIsSlide(AnimationsUtils.isSlideAnimation(animationName));
  }, [animationName]);

  // Once the media is cropped, use the cropped URL if possible
  useEffect(() => {
    const croppedMediaUrl = croppedMedia ? MediaUtils.getMediaUrl(croppedMedia) : undefined;
    const url = croppedMediaUrl ?? videoUrl;
    setPlayerVideoUrl(url);
  }, [croppedMedia, videoUrl]);

  // If there is a PIP, fetch the url of the cropped PIP media and enhance the PIP by adding the cropped file url, else reset it
  useEffect(() => {
    const croppedPipUrl = croppedPip ? MediaUtils.getMediaUrl(croppedPip) : undefined;
    if (pip && croppedPipUrl) {
      setCompletePip({ ...pip, source: { ...pip.source, croppedFileUrl: croppedPipUrl } });
    } else {
      setCompletePip(undefined);
    }
  }, [pip, croppedPip, videoUrl]);

  // Compute the total cropping progression (average of the media and pip cropping)
  useEffect(() => {
    const progressions = [mediaCroppingProgress, pipCroppingProgress].filter((p) => p !== undefined);
    const meanProgress = mean(progressions);
    const formattedProgress = meanProgress !== undefined ? Math.trunc(meanProgress * 100) : undefined;
    setProgress(formattedProgress);
  }, [mediaCroppingProgress, pipCroppingProgress]);

  // Fetch the Lottie JSON file based on some params
  useEffect(() => {
    if (!(charterId && apiParams)) {
      setLottieJson(undefined);
      return;
    }

    // Check if the apiParams have changed (do not refetch the animation if other props changed, like isPlaying, etc.)
    if (!(charterId && apiParams) || (previousApiParams && isEqual(previousApiParams, apiParams))) {
      return;
    }

    const trueAnimationDelay = animationDelay || 0;
    if (seekTo >= trueAnimationDelay && seekTo < animationDuration + trueAnimationDelay) {
      setIsLottieLoading(true);
    }

    const cancelTokenSource = cancelTokenSourceRef.current;

    fetchAnimationByCharterIdAndParams(charterId, apiParams, cancelTokenSource)
      .then((response) => {
        setLottieJson(response);
        setAnimationDuration(LottieUtils.computeAnimationDuration(response.op, response.fr));
      })
      .catch((e) => {
        log.debug('Error during animation fetch', e);
      })
      .finally(() => {
        if (animationDelay && seekTo - (videoTrimValue?.trimStart || 0) < animationDelay) {
          setIsLottieLoading(false);
        }
      });
  }, [charterId, apiParams, seekTo, animationDelay]);

  const handleAnimationDomLoaded = () => {
    if (isLottieLoading) {
      setIsLottieLoading(false);
    }
  };

  const handlePlayerRender = (size: Size) => {
    if (size.width !== playerSize?.width && size.height !== playerSize?.height) {
      setPlayerSize(size);
    }
  };

  useEffect(() => {
    if (onIsLoadingChange) {
      onIsLoadingChange(isDataLoading || isPlayerLoading || isLottieLoading);
    }
  }, [isDataLoading, isPlayerLoading, onIsLoadingChange]);

  const onPlayerLoadingChange = (loading: boolean) => {
    setIsPlayerLoading(loading);
  };

  // Display the progress only if a crop is on-going
  const renderProgress = () => {
    if (!(progress && (isCroppedMediaLoading || isCroppedPipLoading))) {
      return null;
    }

    return (
      <div className={classes.progress}>
        <Progress type="circle" percent={progress} width={40} />
      </div>
    );
  };

  return (
    <>
      <div className={classNames(classes.scenePlayerWrapper, wrapperClassName)}>
        <div className={classes.scenePlayerContainer}>
          {isDataLoading && (
            <>
              <SceneVideoPlayerSkeleton />
              {renderProgress()}
            </>
          )}

          {!isSlide && showAnimationLoadingOverlay && !isDataLoading && isLottieLoading && (
            <div className={classes.animationLoaderContainer}>
              <div>
                <LoadingOutlined className={classes.loadingIcon} />
              </div>
              <div>{t('charters.projects.projectEditor.sceneEditor.loadingAnimation')}</div>
            </div>
          )}

          {!isDataLoading && (
            <SceneVideoPlayer
              isPlaying={isPlaying}
              isSlide={isSlide}
              videoUrl={playerVideoUrl ?? VideoEmpty}
              lottieAnimation={lottieJson}
              format={format}
              animationTheme={animationTheme}
              animationName={animationName}
              animationTexts={animationTexts}
              animationDelay={animationDelay}
              animationPosition={animationPosition}
              audioVolume={audioVolume}
              pip={completePip}
              subtitlesBlock={subtitlesBlock}
              seekTo={seekTo}
              controls={controls}
              hideVolumeControls={hideVolumeControls}
              fullscreenDisabled={fullscreenDisabled}
              videoDuration={videoMedia?.duration}
              videoTrimValue={videoTrimValue}
              mask={mask}
              logo={logo}
              isCarousel={isCarousel}
              onCurrentTimeChange={onCurrentTimeChange}
              onDurationChange={onDurationChange}
              onAnimationDurationChange={onAnimationDurationChange}
              onPlayerLoadingChange={onPlayerLoadingChange}
              onIsPlayingChange={onIsPlayingChange}
              onAnimationLoaded={handleAnimationDomLoaded}
              onEndReached={onEndReached}
              onVideoEnded={onVideoEnded}
              callbackPlayerRender={handlePlayerRender}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default ScenePlayer;
