import { Col, Descriptions, Result, Row, Tag } from 'antd';
import { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import i18n from 'i18next';
import log from 'loglevel';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import KannelleLoader from '../../components/loader/KannelleLoader';
import { DEVICE_SIZES_QUERIES } from '../../Constants';
import PublicPageShell from '../../core/shell/PublicPageShell';
import { RootState } from '../../redux/RootState';
import { APIManager, StatusCode } from '../../services/api/APIManager';
import { getPubliclySharedProjectData } from '../../services/api/PublicService';
import { PubliclySharedProjectMetadata } from '../../services/api/types/PublicServiceTypes';
import FileUtils from '../../utils/FileUtils';
import TimeUtils from '../../utils/TimeUtils';

type StyleProps = {
  height: number;
  width: number;
  isMobileOrTablet: boolean;
};

const useStyles = createUseStyles({
  container: {
    marginTop: 64,
    paddingBottom: 16,
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: 16,
    margin: '8px !important',
  },
  projectFinalVideoCol: {
    [`@media screen and ${DEVICE_SIZES_QUERIES.INTERMEDIATE}`]: {
      marginBottom: 30,
    },
  },
  cardContainer: {
    background: '#ffffff',
    borderRadius: '0 0 10px 10px',
    borderTop: '3px solid #3370FF',
    padding: 16,
    boxShadow: '0 10px 24px hsla(0, 0%, 0%, 0.2)',
    height: '100%',
    minWidth: 300,
  },
  metadataContainer: {
    minWidth: 400,
    position: 'relative',

    [`@media screen and ${DEVICE_SIZES_QUERIES.INTERMEDIATE}`]: {
      minWidth: 'unset',
    },
  },
  metadataDescription: {
    position: 'absolute',
    width: '100%',
    top: '50%',
    transform: 'translate(0, -50%)',

    '& .ant-descriptions-header': {
      marginBottom: 10,
    },
    '& .ant-descriptions-item': {
      paddingBottom: '10px !important',
    },
    '& .ant-descriptions-item-content': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .ant-descriptions-item-label': {
      fontWeight: 600,
    },
    '& .ant-descriptions-item-label::after': {
      content: 'none',
    },
    marginBottom: 10,

    [`@media screen and ${DEVICE_SIZES_QUERIES.INTERMEDIATE}`]: {
      position: 'relative',
      top: 'unset',
      transform: 'unset',
    },
  },
  title: {
    fontSize: 24,
    color: '#3370FF',
    marginBottom: 32,
    textAlign: 'center',
  },
  finalVideoPlayerContainer: ({ height, width }: StyleProps) => {
    let widthOnHeightRatio = 1;
    if (width && height) {
      widthOnHeightRatio = width / height;
    }
    // Landscape case (16/9 > 1)
    let playerWidth = '90%';
    // Portrait case (9/16 < 1)
    if (widthOnHeightRatio < 1) {
      playerWidth = '55%';
    } else if (widthOnHeightRatio === 1) {
      // Square case (1/1 = 1)
      playerWidth = '70%';
    }

    return {
      width: playerWidth,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxHeight: '65vh',
      overflow: 'hidden',
      margin: 'auto',
      [`@media screen and ${DEVICE_SIZES_QUERIES.INTERMEDIATE}`]: {
        width: '85%',
        margin: 'auto',
        marginBottom: 8,
        maxHeight: 'unset',
      },
      '& video': {
        borderRadius: 5,
      },
    };
  },
  projectMeta: ({ isMobileOrTablet }: StyleProps) => ({
    marginBottom: isMobileOrTablet ? 32 : 0,
  }),
});

const SharedProject: FunctionComponent = () => {
  const { publicCode } = useParams<{ publicCode: string }>();
  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());
  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);

  const [isLoading, setIsLoading] = useState(true);
  const [finalVideoUrl, setFinalVideoUrl] = useState<string>();
  const [metadata, setMetadata] = useState<PubliclySharedProjectMetadata>();
  const [error, setError] = useState<string>();

  const classes = useStyles({ height: metadata?.height, width: metadata?.width, isMobileOrTablet });
  const { t } = useTranslation();

  useEffect(() => {
    const cancelTokenSource = cancelTokenSourceRef.current;

    return (): void => {
      cancelTokenSource.cancel('Cancelled fetching publicly shared project data due to component unmount.');
    };
  }, []);

  useEffect(() => {
    if (!publicCode) {
      return;
    }

    setError(undefined);
    setIsLoading(true);

    getPubliclySharedProjectData(publicCode)
      .then((response) => {
        const { data } = response;
        setFinalVideoUrl(data.sharedUrl);
        setMetadata(data.metadata);
      })
      .catch((e) => {
        const errorMessage = e.response?.data?.message;
        log.debug('Error while fetching publicly shared project data', e, errorMessage);
        if (e.response?.status === StatusCode.NOT_FOUND) {
          setError(t('charters.projects.publiclyShared.notFound'));
        } else {
          setError(t('charters.projects.publiclyShared.forbidden'));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [publicCode, t, i18n]);

  const renderProjectFinalVideo = () => {
    if (!(metadata && finalVideoUrl)) {
      return null;
    }

    return (
      <div className={classes.cardContainer}>
        <h3 className={classes.title}>{metadata.projectTitle}</h3>

        <div className={classes.finalVideoPlayerContainer}>
          <ReactPlayer
            url={finalVideoUrl}
            controls
            width="inherit"
            height="inherit"
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload',
                  disablePictureInPicture: true,
                },
              },
            }}
          />
        </div>
      </div>
    );
  };

  const renderProjectMetadata = () => {
    if (!metadata) {
      return null;
    }

    return (
      <div className={classNames(classes.cardContainer, classes.metadataContainer)}>
        <h3>{metadata.companyName}</h3>
        <h4>{metadata.charterName}</h4>

        <Descriptions column={1} size="small" className={classes.metadataDescription}>
          <Descriptions.Item label={<Tag>{t('charters.projects.projectInfo.createdAt')}</Tag>}>
            {moment(metadata.createdAt).format(t('timeFormats.yearMonthDayHourMinute'))}
          </Descriptions.Item>

          <Descriptions.Item label={<Tag>{t('charters.projects.projectInfo.lastFinalizedAt')}</Tag>}>
            {moment(metadata.lastFinalizedAt).format(t('timeFormats.yearMonthDayHourMinute'))}
          </Descriptions.Item>

          <Descriptions.Item
            label={<Tag>{t('charters.projects.projectInfo.duration')}</Tag>}
          >{`${TimeUtils.formatSecondsIntoHumanTimeString(metadata.duration, t, {
            withMillis: true,
          })}`}</Descriptions.Item>

          <Descriptions.Item
            label={<Tag>{t('charters.projects.projectInfo.dimensions')}</Tag>}
          >{`${metadata.width} × ${metadata.height}`}</Descriptions.Item>

          <Descriptions.Item label={<Tag>{t('charters.projects.projectInfo.fileSize')}</Tag>}>
            {FileUtils.formatBytesIntoHumanReadableSize(metadata.size)}
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  };

  const renderError = () => {
    if (!error) {
      return null;
    }

    return <Result status="error" title={error} />;
  };

  if (error) {
    return <PublicPageShell>{renderError()}</PublicPageShell>;
  }

  return (
    <PublicPageShell>
      <div className={classes.container}>
        <Row gutter={[6, 6]} className={classes.rowContainer}>
          {isLoading ? (
            <KannelleLoader transparent />
          ) : (
            <>
              <Col xs={22} sm={22} md={16} lg={12} className={classes.projectFinalVideoCol}>
                {renderProjectFinalVideo()}
              </Col>

              <Col xs={22} sm={22} md={16} lg={8} className={classes.projectMeta}>
                {renderProjectMetadata()}
              </Col>
            </>
          )}
        </Row>
      </div>
    </PublicPageShell>
  );
};

export default SharedProject;
