import { Tag } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { PermissionSection } from '../../../../Constants';

type Props = {
  permissionSection: PermissionSection;
};

const useStyles = createUseStyles({
  permissionSectionTag: {
    color: 'white',
    borderRadius: 25,
    border: 'unset',
    marginRight: '0',
    textAlign: 'center',
    pointerEvents: 'none',
  },
});

const PermissionSectionTag: FunctionComponent<Props> = ({ permissionSection }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Tag className={classes.permissionSectionTag} style={{ background: permissionSection.color }}>
      {t(permissionSection.key)}
    </Tag>
  );
};

export default PermissionSectionTag;
