import classNames from 'classnames';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { createUseStyles } from 'react-jss';
import { PROJECTS, THEME } from '../../../../../../../../Constants';
import { MediaType } from '../../../../../../../../utils/types/MediaTypes';
import {
  AnchorType,
  ProjectSceneAnimation,
  ProjectSceneElementsCode,
  ProjectSceneMedia,
} from '../../../../../../../../utils/types/ProjectTypes';
import { VideoTrim, VideoTrimUtils } from '../../utils/VideoTrimUtils';
import SceneTrimAnchor from '../scene-video-row/SceneTrimAnchor';
import SceneTimelineGrayOverlay from '../SceneTimelineGrayOverlay';

type Props = {
  pip: ProjectSceneMedia;
  backgroundVideo?: ProjectSceneMedia;
  animation?: ProjectSceneAnimation;
  isSelected?: boolean;
  intervalTickWidth: number;
  videoTrimValue: VideoTrim;
  onClick: () => void;
  onPipDelayChange: (newDelay: number) => void;
  onPipTrimChange: (trimValue: VideoTrim) => void;
};

type StyleProps = {
  width: number;
  maxVideoWidth: number;
  pipTrimStart: number;
  trimType: MediaType;
  intervalTickWidth: number;
  delay: number;
  onGoingDelay: number;
  videoTrimStart: number;
  onGoingTrim: VideoTrim;
  isSelected: boolean;
  hasSceneBackgroundVideo: boolean;
  backgroundVideo: ProjectSceneMedia;
};

const useStyles = createUseStyles({
  draggablePipBar: {
    display: 'flex',
    height: '100%',
    width: '100%',
    left: PROJECTS.TRIM.ANCHOR_WIDTH,
    position: 'relative',
    '& .react-draggable-dragging': {
      cursor: 'move !important',
    },
  },
  frameContainer: ({ videoTrimStart, intervalTickWidth, pipTrimStart, delay }: StyleProps) => {
    const left =
      pipTrimStart - delay > videoTrimStart
        ? -PROJECTS.TRIM.ANCHOR_WIDTH
        : -PROJECTS.TRIM.ANCHOR_WIDTH + (videoTrimStart - pipTrimStart) * intervalTickWidth;

    return {
      left,
      position: 'absolute',
      height: '100%',
      width: '100%',
    };
  },
  scenePipBar: ({ width, isSelected, hasSceneBackgroundVideo }: StyleProps) => ({
    position: 'absolute',
    zIndex: 2,
    width,
    background: THEME.PROJECTS.EDITOR.TIMELINE.ELEMENT_BACKGROUND,
    height: '100%',
    // eslint-disable-next-line no-nested-ternary
    borderRadius: isSelected ? (hasSceneBackgroundVideo ? '0' : '6px 0 0 6px') : 6,
    display: 'flex',
    alignItems: 'center',
    borderTop: isSelected ? `6px solid ${THEME.PROJECTS.EDITOR.TIMELINE.SELECTED_ELEMENT_BORDER}` : undefined,
    borderBottom: isSelected ? `6px solid ${THEME.PROJECTS.EDITOR.TIMELINE.SELECTED_ELEMENT_BORDER}` : undefined,
    borderLeft:
      isSelected && !hasSceneBackgroundVideo
        ? `6px solid ${THEME.PROJECTS.EDITOR.TIMELINE.SELECTED_ELEMENT_BORDER}`
        : undefined,
    cursor: 'pointer',
  }),
  pipMediaName: ({ isSelected }: StyleProps) => ({
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    margin: isSelected ? '0 4px' : '0 10px',
    color: 'white',
    fontFamily: 'SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace',
    fontSize: 12,
  }),
  backgroundVideoFrame: ({
    maxVideoWidth,
    onGoingDelay,
    intervalTickWidth,
    videoTrimStart,
    pipTrimStart,
    delay,
  }: StyleProps) => {
    const left =
      pipTrimStart - delay > videoTrimStart
        ? onGoingDelay * intervalTickWidth
        : (onGoingDelay + videoTrimStart - pipTrimStart) * intervalTickWidth;

    return {
      cursor: 'pointer',
      height: '100%',
      display: 'flex',
      position: 'absolute',
      background: THEME.PROJECTS.EDITOR.TIMELINE.VIDEO_EXCLUDED_BACKGROUND,
      borderRadius: 8,
      width: maxVideoWidth,
      left,
    };
  },
  colorFrameCentralElement: ({ isSelected, onGoingTrim, intervalTickWidth }: StyleProps) => {
    if (!onGoingTrim) {
      return {};
    }

    const cleanTrimValue: VideoTrim = {
      trimStart: onGoingTrim.trimStart,
      trimEnd: onGoingTrim.trimEnd,
    };

    const unshadedAreaBetweenAnchorsWidth = VideoTrimUtils.calculateUnshadedAreaWidthBetweenStartAndEndAnchor(
      cleanTrimValue,
      intervalTickWidth
    );

    const unshadedAreaLeftBoundValue = VideoTrimUtils.calculateUnshadedAreaLeftBoundValue(
      cleanTrimValue.trimStart,
      intervalTickWidth
    );

    return {
      // Explanation for the `+ 2` and `- 1` below.
      // Sometimes, the CSS cannot fill an entire pixel with the color.
      // In such cases, it might fill with the color and sometimes it might be left empty so in order not to
      // have this behavior, we add some width (`2px` here) and remove `1px` to ensure that the anchor are above these gaps
      width: isSelected ? unshadedAreaBetweenAnchorsWidth + 2 : unshadedAreaBetweenAnchorsWidth,
      left: isSelected
        ? unshadedAreaLeftBoundValue - 1 + PROJECTS.TRIM.ANCHOR_WIDTH
        : unshadedAreaLeftBoundValue + PROJECTS.TRIM.ANCHOR_WIDTH,
    };
  },
});

const ScenePipBar: FunctionComponent<Props> = ({
  pip,
  backgroundVideo,
  animation,
  isSelected = false,
  intervalTickWidth,
  videoTrimValue,
  onClick,
  onPipDelayChange,
  onPipTrimChange,
}: Props) => {
  const pipTrimStartAt = pip.trimStartAt ?? 0;
  const pipTrimEndAt = pip.trimEndAt ? pip.trimEndAt : pip.duration ?? 0;
  const pipDelay = pip.delay ?? 0;

  const [onGoingDelay, setOnGoingDelay] = useState<number>(0);
  const [videoTrimStartAt, setVideoTrimStartAt] = useState<number>(0);

  const trimFromScenePip = useMemo(
    () => ({
      trimStart: pipTrimStartAt,
      trimEnd: pipTrimEndAt,
      delay: onGoingDelay,
    }),
    [pipTrimStartAt, pipTrimEndAt, onGoingDelay]
  );
  const [onGoingTrim, setOnGoingTrim] = useState<VideoTrim>();

  useEffect(() => {
    if (!pip) {
      return;
    }

    setOnGoingDelay(pipDelay);
    setOnGoingTrim({
      trimStart: pip.trimStartAt ?? 0,
      trimEnd: pip.trimEndAt ?? (pip.duration || 0),
      delay: pip.delay,
    });
  }, [pip]);

  useEffect(() => {
    setVideoTrimStartAt(backgroundVideo?.trimStartAt || 0);
  }, [backgroundVideo?.trimStartAt]);

  const pipDuration = pip.duration ?? 0;
  const delayWidth = pipDelay * intervalTickWidth;
  const width = pipDuration * intervalTickWidth;
  const videoTrim = {
    trimStart: backgroundVideo?.trimStartAt || 0,
    trimEnd: backgroundVideo?.trimEndAt || backgroundVideo?.media.duration || PROJECTS.PIP.PIP_ONLY_MAX_DURATION,
  };
  const maxVideoDuration = backgroundVideo?.media.duration || PROJECTS.PIP.PIP_ONLY_MAX_DURATION; // if no video then 10s is the limit
  const pipMediaDuration = pip.media.duration || 0;
  const maxVideoWidth =
    pip.media.mediaType === MediaType.VIDEO
      ? pipMediaDuration * intervalTickWidth
      : (videoTrim.trimEnd - videoTrim.trimStart ?? maxVideoDuration) * intervalTickWidth;
  const maxDuration =
    pip.media.mediaType === MediaType.VIDEO && pipMediaDuration
      ? pipMediaDuration
      : videoTrim.trimEnd - videoTrim.trimStart;
  const hasSceneBackgroundVideo = backgroundVideo !== undefined;

  const classes = useStyles({
    width,
    maxVideoWidth,
    pipTrimStart: pipTrimStartAt,
    isSelected,
    delayWidth,
    onGoingTrim,
    intervalTickWidth,
    delay: pipDelay,
    onGoingDelay,
    hasSceneBackgroundVideo,
    backgroundVideo,
    videoTrimStart: videoTrimStartAt,
    trimType: pip.media.mediaType,
  });

  if (!(pip && pip.media && pip.duration && pip.duration > 0)) {
    return null;
  }

  const handleAnchorChange = (localTrim: VideoTrim) => {
    setOnGoingTrim(localTrim);
  };

  const onDrag = (event: DraggableEvent, data: DraggableData) => {
    const newDelay = data.x / intervalTickWidth;

    setOnGoingDelay(newDelay);
  };

  const onDragStop = (event: DraggableEvent, data: DraggableData) => {
    onPipDelayChange(data.x / intervalTickWidth);
  };

  const calculatePositionOnX = () => {
    const minTrimValue = 0;
    const maxTrimValue = maxVideoDuration * intervalTickWidth;

    if (delayWidth < minTrimValue) {
      return minTrimValue;
    }

    if (delayWidth > maxTrimValue) {
      return maxTrimValue;
    }

    // TODO in the future this should be conditioned with if it's a pip image then return delay width otherwise return depending on trim
    return onGoingDelay * intervalTickWidth;
  };

  return (
    <div className={classes.draggablePipBar}>
      {pip.media.mediaType === MediaType.VIDEO && <div className={classes.backgroundVideoFrame} />}
      <SceneTimelineGrayOverlay
        element={ProjectSceneElementsCode.PIP}
        backgroundVideo={backgroundVideo}
        pip={pip}
        animation={animation}
        videoTrimValue={videoTrimValue}
        intervalTickWidth={intervalTickWidth}
      >
        <div className={classes.frameContainer}>
          {isSelected && hasSceneBackgroundVideo && (
            <SceneTrimAnchor
              type={AnchorType.START_ANCHOR}
              width={maxVideoWidth}
              intervalTickWidth={intervalTickWidth}
              trimValue={trimFromScenePip}
              callbackAnchorChange={handleAnchorChange}
              onTrimChange={onPipTrimChange}
              delay={onGoingDelay}
              maxDuration={maxDuration}
              isMediaImage={pip.media.mediaType === MediaType.IMAGE}
            />
          )}
          <Draggable
            disabled={!(isSelected && hasSceneBackgroundVideo)}
            axis="x"
            position={{
              x: calculatePositionOnX(),
              y: 0,
            }}
            bounds={{
              left: 0,
              top: 0,
              right: (videoTrim.trimEnd - videoTrimStartAt) * intervalTickWidth,
              bottom: 0,
            }}
            onStart={onClick}
            onDrag={onDrag}
            onStop={onDragStop}
          >
            <div className={classNames(classes.scenePipBar, classes.colorFrameCentralElement)} onClick={onClick}>
              <span className={classes.pipMediaName}>{pip.media.publicName}</span>
            </div>
          </Draggable>
          {isSelected && (
            <SceneTrimAnchor
              type={AnchorType.END_ANCHOR}
              width={maxVideoWidth}
              intervalTickWidth={intervalTickWidth}
              trimValue={trimFromScenePip}
              callbackAnchorChange={handleAnchorChange}
              onTrimChange={onPipTrimChange}
              delay={onGoingDelay}
              isMediaImage={pip.media.mediaType === MediaType.IMAGE}
            />
          )}
        </div>
      </SceneTimelineGrayOverlay>
    </div>
  );
};

export default ScenePipBar;
