/* eslint-disable import/no-cycle */
/**
 *
 * API MODELS
 *
 */
import { Area } from 'react-easy-crop/types';
import { Roles } from '../../../core/rule/RolesTypes';
import { AnimationFormats, PipPositionCode } from '../../../utils/types/AnimationTypes';
import { MediaType } from '../../../utils/types/MediaTypes';
import {
  Project,
  ProjectLogoDisplayMode,
  ProjectLogoPosition,
  ProjectLogoSize,
  ProjectSceneAnimationText,
} from '../../../utils/types/ProjectTypes';
import { ScenarioSceneTemplate } from '../../../utils/types/ScenarioTypes';
import { Font } from './FontFamiliesServiceTypes';
import { APIModelUserEnriched } from './UsersServiceTypes';

export enum AssetType {
  INTRO = 'intro',
  OUTRO = 'outro',
  MUSIC = 'music',
  LOGO = 'logo',
}

export type APIModelCharter = {
  createdAt: string;
  isActive: boolean;
  currentUser: { isActive: boolean; role: string; permissions: APICharterPermission[] };
  emailPatterns: string[];
  firmColors: string[];
  hiddenTemplates: [];
  id: number;
  name: string;
  plan: string;
  privateTemplates: [];
  themeColors: Array<APIModelThemeColor>;
  updatedAt: string;
  uri: string;
  onlyPrivateScenarios: boolean;
  // below are front end only param
  updating?: { role?: boolean; isActive?: boolean };
  highlightRow?: boolean; // info for table highlight
};

export type Asset = {
  createdAt: string;
  id: number;
  isActive: boolean;
  isArchived: boolean;
  updatedAt: string;
  url: string;
  hlsUrl?: string;
  publicName?: string;
  operationId: string;
  size?: number;
  duration?: number;
};

export type APIFullModelCharter = {
  createdAt: string;
  emailPatterns: string[];
  firmColors: string[];
  fontFiles: any;
  hiddenTemplates: [];
  id: number;
  logos: Array<Asset>;
  intros: Array<Asset>;
  musics: Array<Asset>;
  permissions: Array<APICharterPermission>;
  name: string;
  outros: Array<Asset>;
  plan: string;
  privateTemplates: string[];
  themeColors: Array<APIModelThemeColor>;
  updatedAt: string;
  uri: string;
  themeFonts?: ThemeFonts;
  onlyPrivateScenarios: boolean;
};

export type Subtitles = {
  currentUsage: number;
};

export type DashboardSubtitles = {
  subtitles: Subtitles;
};

export type UsersModelCharter = APIModelUserEnriched & {
  updating?: { role?: boolean; isActive?: boolean };
  highlightRow?: boolean;
  charter?: any; // todo a supprimer hein
};

export type ThemeFonts = {
  titleFont: Font;
  textFont: Font;
};

export type APIModelThemeColor = {
  code: string;
  colors: { [key: string]: string };
};

export type APIUsersModelCharters = {
  items: UsersModelCharter[];
  count: number;
};

export type APIPatchCharterUsersByCharterIdParams = {
  isActive?: boolean;
  role?: Roles;
  name?: string;
};

export type APIUpdateCharterByIdParams = {
  name?: string;
  colors?: Array<string>;
  onlyPrivateScenarios?: boolean;
  isActive?: boolean;
};

export type APICharterPermissionRoleAccess = {
  code: string;
  name: string;
  hasAccess: {
    access: boolean;
    locked: boolean;
    value?: string;
  };
};

export type APICharterPermission = {
  code: string;
  name: string;
  section: string;
  target: string;
  allowedValues?: string[];
  access: boolean;
  locked: boolean;
  value?: string;
  roles: Array<APICharterPermissionRoleAccess>;
  role?: string;
};

export type APIUpdateCharterPermissionsParams = {
  roleCode: string;
  permissionCode: string;
  access: boolean;
  value?: string | null;
};

export type APIUpdateCharterPermissionsResult = {
  code: string;
  name: string;
  target: string;
  section: string;
  access: string;
  locked: string;
  value?: string;
};

export type MediaCroppedArea = Area;

export type APIUpdateCharterScenarioMetadataParams = {
  scope: string;
  coverImageSourceMedia: {
    id: number;
    croppedArea: MediaCroppedArea;
  };
  defaultTheme: string;
  isMain: string;
};

export type UpdateCharterScenarioVariantScenesParams = ScenarioSceneTemplate;

export type APICreateCharterScenarioVariantParams = {
  language: string;
  format: string;
};

export type APIUpdateCharterScenarioVariantParams = {
  scenarioId: number;
  title: string;
  scope: string; // ScenarioScope
  description?: string;
  status: string;
  language: string;
  format: string;
  scenes: UpdateCharterScenarioVariantScenesParams[];
};

export type APIFetchAnimationByCharterIdParams = {
  animationName: string;
  theme: string;
  format: string;
  animationTexts?: string[];
  useOriginalSettings?: boolean;
  duration?: number;
  subtitleHeight?: number;
  position: {
    code: string;
    x?: number;
    y?: number;
  };
  colorOverrides?: Record<string, string>;
};

export type APICreateCharterScenarioParams = {
  scope: string;
  mainVariantTitle: string;
  mainVariantDescription?: string;
  mainVariantLanguage: string;
  mainVariantFormat: string;
  coverImageSourceMedia: {
    id: number;
    croppedArea: MediaCroppedArea;
  };
  defaultTheme: string;
};

export type ScenarioVariant = {
  id: number;
  isMain: boolean;
  title: string;
  description: string;
  format: string; // ScenarioFormat (code)
  language: string; // ScenarioLanguage (code)
  status: string; // ScenarioStatus (code)
  duration: number;
  sceneIndexOrder?: number[];
  scenes?: ScenarioSceneTemplate[];
  updatedAt?: string;
};

export type ExtendedScenarioVariant = ScenarioVariant & {
  scenarioId: number;
};

export type ScenarioPermission = {
  code: string;
  isAllowed: boolean;
};

export type Scenario = {
  id: number;
  code: string;
  coverImageSource: MediaFile;
  defaultTheme: string;
  variants: ScenarioVariant[];
  permission?: ScenarioPermission;
  scope: string; // ScenarioScope
};

export type APIGetPublicScenariosResult = {
  items: {
    charterId: number;
    scenario: Scenario;
  }[];
  count: number;
};

export type APIGetCharterScenariosResult = {
  items: Scenario[];
  count: number;
};

export type APIGetCharterScenarioDetailsByIdResult = Scenario;

export type APIUpdateCharterScenarioMetadataResult = Scenario;

export type APICreateCharterScenarioVariantResult = ScenarioVariant;

export type APIUpdateCharterScenarioVariantResult = ScenarioVariant;

export type APICreateCharterScenarioResult = Scenario;

export type APICloneCharterScenarioResult = Scenario;

export type APIGetCharterAssetSignedUrlToUploadParams = {
  charterId: number;
  filename: string;
  assetType: AssetType;
};

export type APIGetCharterAssetSignedUrlToUploadResult = {
  fileUploadURL: string;
  metadata: {
    filename: string;
    charterId: string;
    assetType: AssetType;
    operationId: string;
    publicName?: string;
  };
};

export type APIGetCharterFontSignedUrlToUploadResult = {
  fileUploadURL: string;
  metadata: {
    filename: string;
    charterId: string;
    operationId: string;
  };
};

export type APIGetCharterMediaSignedUrlToUploadParams = {
  charterId: number;
  filename: string;
  mediaType: MediaType;
  isMirrored?: boolean;
};

export type APIGetCharterMediaSignedUrlToUploadResult = {
  fileUploadURL: string;
  metadata: {
    filename: string;
    operationId: string;
    publicName?: string;
  };
};

export type MediaFile = {
  createdAt: string;
  id: number;
  isActive: boolean;
  isArchived: boolean;
  updatedAt: string;
  url: string;
  hlsUrl?: string;
  publicName?: string;
  operationId: string;
  status: string;
  size?: number;
  mediaType: MediaType;
  croppedArea?: MediaCroppedArea;
  height?: number;
  width?: number;
  duration?: number;
  thumbnailImage?: MediaFile;
  croppedFileUrl?: string;
  croppedHlsFileUrl?: string;
  progress?: number;
};

export type APIGetCharterMediaResult = {
  items: MediaFile[];
  count: number;
};

export type APIGetCharterMediaResponse = {
  data: APIGetCharterMediaResult;
};

export type APIUpdateCharterColorsParams = {
  themeColors: Array<APIModelThemeColor>;
};

export type APIPatchCharterThemeFontsByCharterIdParams = {
  titleFontId?: number;
  textFontId?: number;
};

export type APIPatchCharterThemeFontsByCharterIdResponse = APIFullModelCharter;

export type APIUsersModelCharterResponse = {
  data: UsersModelCharter;
  status: number;
};

export type APIPatchCharterUsersByCharterIdResult = APIUsersModelCharterResponse;

export type APICharterResponse = {
  data: APIFullModelCharter;
};

export type APIUsersResponse = {
  data: APIUsersModelCharters;
};

export type APIUpdateCharterByIdResponse = {
  data: APIModelCharter;
  status: number;
};

export type APIUpdateCharterColorsResponse = {
  data: APIModelCharter;
  status: number;
};

export type APIGetCharterPermissionsResult = {
  items: Array<APICharterPermission>;
  count: number;
};

export type APIGetCharterPermissionsResponse = {
  data: APIGetCharterPermissionsResult;
};

export type APIUpdateCharterPermissionResponse = {
  data: APIUpdateCharterPermissionsResult;
};

export type APIGetCharterProjectsResult = {
  items: Project[];
  count: number;
};

export type APIGetCharterProjectDetailsByIdResult = Project;

export type APIShareCharterProjectPubliclyResult = Project;

export type APICreateCharterProjectParams = {
  title: string;
  videoFormat: AnimationFormats;
  sceneMediaIds?: number[];
  scenarioVariantId?: number;
};

export type APICreateCharterProjectResult = Project;

export type APIUpdateCharterProjectParams = {
  title: string;
  theme?: string;
  customColors: boolean;
  sceneIndexesOrder: number[];
  audioVolume: number;
  audioProcessingEnabled?: boolean;
  mask?: string;
  dismissedFeatures: string[];
  introId?: number;
  outroId?: number;
  subtitlesLanguage?: string;
  logo?: {
    id: number;
    positionCode?: ProjectLogoPosition;
    size?: ProjectLogoSize;
    displayMode?: ProjectLogoDisplayMode;
  };
  music?: {
    id: number;
    volume?: number;
    duckingEnabled?: boolean;
    duckingVolume?: number;
    fadeOutEnabled?: boolean;
    fadeOutDuration?: number;
  };
  scenes: APIUpdateCharterProjectSceneParams[];
};

export type APIUpdateCharterProjectSceneParams = {
  id?: number;
  index: number;
  kind: string;
  isActive: boolean;
  title?: string;
  description?: string;
  animation?: {
    code: string;
    active: boolean;
    position: string;
    offsetX: number;
    offsetY: number;
    delay?: number;
    duration?: number;
    animationTexts?: ProjectSceneAnimationText[];
  };
  backgroundVideo?: {
    media: {
      id: number;
      croppedArea?: MediaCroppedArea;
      duration?: number;
    };
    audioVolume: number;
    trimStartAt?: number;
    trimEndAt?: number;
  };
  pip?: {
    media: {
      id: number;
      croppedArea?: MediaCroppedArea;
    };
    audioVolume: number;
    duration?: number;
    delay?: number;
    trimStartAt?: number;
    trimEndAt?: number;
    effectName?: string;
    effectValue?: number;
    positionCode?: PipPositionCode;
    positionValue?: number;
    size?: number;
  };
};

export type APIUpdateCharterProjectResult = Project;

export type APIProcessCharterMediaCroppedUrlByIdResult = {
  message: string;
  metadata: any;
  messageId: string;
};
