import { SubtitleBlock } from './types/ProjectTypes';

export default class SubtitlesUtils {
  static getSubtitleHeight = (text: string, playerWidth: number): number => {
    // 0.8 = 80% and 20 is the padding left-right
    const trueWidth = playerWidth * 0.8 - 20;
    // After some experiments, a letter is between 5-6 pixels (with exception spanning from 3-8) so I take in the middle
    const spaceTakenByText = text.length * 5.5;
    const numberOfLines = Math.ceil(spaceTakenByText / trueWidth);

    return numberOfLines * 26; // 26px is the line height
  };

  static getSubtitlesBlockMaxHeight = (subtitlesBlock?: SubtitleBlock[], playerWidth?: number): number => {
    if (!subtitlesBlock || !playerWidth) {
      return 0;
    }

    let subtitleHeight = 0;

    subtitlesBlock?.forEach((block) => {
      const height = SubtitlesUtils.getSubtitleHeight(block.text, playerWidth);
      if (height > subtitleHeight) {
        subtitleHeight = height;
      }
    });

    return subtitleHeight;
  };
}
