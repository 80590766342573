/* eslint-disable @typescript-eslint/ban-types */
import io, { Socket } from 'socket.io-client';

type EventCallback = (...args: any[]) => void;

export class SocketManager {
  private static _instance: SocketManager;
  private static _socket?: Socket;
  private static _isConnected = false;
  private static _token?: string;
  private static _rooms: string[];

  public static initiate = (accessToken?: string): void => {
    if (SocketManager._isConnected && SocketManager._socket?.connected) {
      return;
    }

    if (accessToken) {
      SocketManager._token = accessToken;
    }
    SocketManager.initInstance();
  };

  public static getInstance(): SocketManager {
    if (!SocketManager._instance) {
      SocketManager._instance = new SocketManager();
    }

    return SocketManager._instance;
  }

  private static initInstance(): void {
    if (!SocketManager._token) {
      return;
    }

    const WSBaseUrl = process.env.REACT_APP_WS_BASE_URL!;

    this._socket = io(`${WSBaseUrl}?token=${SocketManager._token}`, {
      reconnectionDelay: 5000,
      transports: ['websocket'],
    });

    this._socket.on('connect', () => {
      this._isConnected = true;
    });

    this._rooms = [];
  }

  static get socket(): Socket | undefined {
    return this._socket;
  }

  static get isConnected(): boolean {
    return this._isConnected;
  }

  static get rooms(): string[] {
    return this._rooms;
  }

  static joinRoom(roomName: string, clientId: string): void {
    const isAlreadyInRoom = this._rooms?.some((room) => room === roomName);
    if (!isAlreadyInRoom) {
      this._socket?.emit('join-room', roomName, clientId);
      this._rooms?.push(roomName);
    }
  }

  static joinRooms(roomNames: string[], clientId: string): void {
    roomNames.forEach((roomName) => {
      this.joinRoom(roomName, clientId);
    });
  }

  static leaveRoom(roomName: string, clientId: string): void {
    this._socket?.emit('leave-room', roomName, clientId);
    this._rooms = this._rooms?.filter((room) => room !== roomName);
  }

  static leaveRooms(roomNames: string[], clientId: string): void {
    roomNames.forEach((roomName) => {
      this.leaveRoom(roomName, clientId);
    });
  }

  static onReconnect(clientId: string): void {
    SocketManager.initiate();
    SocketManager.joinRooms(SocketManager.rooms, clientId);
  }

  static on(event: string, fn: EventCallback): void {
    this._socket?.on(event, fn);
  }

  static off(event: string, fn?: EventCallback): void {
    this._socket?.off(event, fn);
  }

  static onMessage(fn: EventCallback): void {
    this.on('message', fn);
  }

  static offMessage(fn: EventCallback): void {
    this.off('message', fn);
  }

  static close(): void {
    this._socket?.removeAllListeners();
    this._socket?.close();
  }
}
