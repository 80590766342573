import { ReloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Offline } from 'react-detect-offline';
import { isChrome, isFirefox, isMacOs, isWindows } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RootState } from '../../redux/RootState';

type StyleProps = {
  isMobileOrTablet: boolean;
  menuCollapsed: boolean;
};

const useStyles = createUseStyles({
  banner: ({ isMobileOrTablet, menuCollapsed }: StyleProps) => ({
    position: 'fixed',
    top: '64px',
    left: isMobileOrTablet || menuCollapsed ? '0px' : '210px',
    width: isMobileOrTablet || menuCollapsed ? '100%' : 'calc(100% - 210px)',
    height: '30px',
    color: '#FFFFFF',
    zIndex: '4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  offlineBanner: {
    background: '#FF4D4F',
  },
  backToOnlineBanner: {
    background: '#3EC73E',
  },
  reloadButton: {
    marginLeft: '20px',
    height: '20px !important',
    fontSize: '12px !important',
  },
});

const OfflineDetector: FunctionComponent = () => {
  const [isBackToOnline, setIsBackToOnline] = useState(false);
  const [isBackToOnlineVisible, setIsBackToOnlineVisible] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [isMobileOrTablet, menuCollapsed] = useSelector((state: RootState) => {
    return [state.app.isMobileOrTablet, state.app.menuCollapsed];
  });
  const pollingConfig =
    (isChrome && !isMacOs) || (isWindows && isFirefox)
      ? {
          url: 'https://ipv4.icanhazip.com',
          enabled: true,
          interval: 5000,
          timeout: 5000,
        }
      : false;

  const { t } = useTranslation();

  const classes = useStyles({ isMobileOrTablet, menuCollapsed });

  useEffect(() => {
    if (location) {
      setIsBackToOnlineVisible(false);
    }
  }, [location]);

  const checkIfIsBackToOnline = (newOnlineState: boolean): void => {
    if (newOnlineState) {
      setIsBackToOnline(true);
      setIsBackToOnlineVisible(true);
    }
  };

  const reloadThePage = (): void => {
    history.go(0);
  };

  return (
    <>
      <Offline onChange={checkIfIsBackToOnline} polling={pollingConfig}>
        <div className={classNames(classes.banner, classes.offlineBanner)}>{t('offline.noInternet')}</div>
      </Offline>
      {isBackToOnline && isBackToOnlineVisible && (
        <div className={classNames(classes.banner, classes.backToOnlineBanner)}>
          {t('offline.backToOnline')}
          <Button
            type="primary"
            shape="round"
            icon={<ReloadOutlined />}
            size="small"
            onClick={reloadThePage}
            className={classes.reloadButton}
          >
            {t('global.reload')}
          </Button>
        </div>
      )}
    </>
  );
};

export default OfflineDetector;
