import { AppstoreOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import Title from 'antd/lib/typography/Title';
import { CancelTokenSource } from 'axios';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PageContentLoader from '../../../components/loader/PageContentLoader';
import KannellePageHeader from '../../../components/page-header/KannellePageHeader';
import { LINK } from '../../../Constants';
import { RootState } from '../../../redux/RootState';
import { APIManager } from '../../../services/api/APIManager';
import { getBundles } from '../../../services/api/BundlesService';
import { APIBundles, APIGetBundlesResult } from '../../../services/api/types/BundlesServiceTypes';
import CompaniesUtils from '../../../utils/CompaniesUtils';
import LocalUtils from '../../../utils/LocalUtils';
import BundlesCardList from './BundlesCardList';
import BundlesTableList from './BundlesTableList';

const useStyles = createUseStyles({
  titleSwitchIcon: {
    fontSize: '12px !important',
    '& *': {
      fontSize: '12px !important',
    },
  },
});

const BundlesManagement: FunctionComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const companies = useSelector((state: RootState) => {
    return state.companies.list;
  });
  const isUserKnlTeamInCharter = CompaniesUtils.checkIsKnlProfile(companies || []);
  const [viewType, setViewType] = useState<'table' | 'card'>('table');
  const [data, setData] = useState<APIBundles[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());

  useEffect(() => {
    if (!isUserKnlTeamInCharter) {
      return undefined;
    }
    const cancelToken = cancelTokenSourceRef.current;
    const lang = LocalUtils.getLang();

    getBundles(lang, cancelToken).then((result: APIGetBundlesResult) => {
      setIsLoading(false);
      setData(result.data.items);
    });

    return (): void => {
      cancelToken.cancel('Cancel get or patch bundles due to component unmounting');
    };
    // eslint-disable-next-line
  }, []);

  if (!isUserKnlTeamInCharter) {
    history.push(LINK.UNAUTHORIZED.path);
  }

  if (isLoading) {
    return <PageContentLoader />;
  }

  const handleChangeView = (e: RadioChangeEvent) => {
    setViewType(e.target.value);
  };

  return (
    <KannellePageHeader
      title={
        <>
          <Title level={3}>{t('admin.bundles.title')}</Title>

          <Radio.Group className={classes.titleSwitchIcon} value={viewType} onChange={handleChangeView}>
            <Radio.Button value="table">
              <UnorderedListOutlined /> {t('admin.bundles.tableMode')}
            </Radio.Button>
            <Radio.Button value="card">
              <AppstoreOutlined /> {t('admin.bundles.cardMode')}
            </Radio.Button>
          </Radio.Group>
        </>
      }
    >
      {viewType === 'table' ? <BundlesTableList data={data} /> : <BundlesCardList data={data} />}
    </KannellePageHeader>
  );
};

export default BundlesManagement;
