import React, { FunctionComponent } from 'react';
import { SECURITY_MODAL_TYPE } from '../../Constants';
import DowngradeModal from './DowngradeModal';
import EmailNotVerifiedModal from './EmailNotVerifiedModal';
import FreeTrialEndModal from './FreeTrialEndModal';
import LoadingModal from './LoadingModal';
import MaintenanceModal from './MaintenanceModal';

type Props = {
  visible: boolean;
  type: SECURITY_MODAL_TYPE;
  modalProps?: any;
};

const AbstractSecurityModal: FunctionComponent<Props> = ({ visible, type, modalProps }: Props) => {
  switch (type) {
    case SECURITY_MODAL_TYPE.FREE_TRIAL_END:
      return <FreeTrialEndModal visible={visible} />;
    case SECURITY_MODAL_TYPE.EMAIL_NOT_VERIFIED:
      return <EmailNotVerifiedModal visible={visible} />;
    case SECURITY_MODAL_TYPE.DOWNGRADE:
      return <DowngradeModal visible={visible} {...modalProps} />;
    case SECURITY_MODAL_TYPE.LOADING:
      return <LoadingModal visible={visible} />;
    case SECURITY_MODAL_TYPE.MAINTENANCE:
      return <MaintenanceModal visible={visible} />;
    default:
      return null;
  }
};

export default AbstractSecurityModal;
