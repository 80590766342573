import React, { FunctionComponent } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { NavigationMenuItem } from '../../services/navigation/NavigationMenuItem';
import SecurityCheckRoute from './SecurityCheckRoute';

type Props = {
  route: NavigationMenuItem;
  path: string;
  isAllowed: boolean;
  isRouteAlwaysAccessible: boolean;
} & RouteProps;

const PrivateRoute: FunctionComponent<Props> = ({
  component,
  route,
  path,
  isAllowed,
  isRouteAlwaysAccessible,
  ...rest
}: Props) => {
  const Component = component!;

  const render = (props: any): JSX.Element | null =>
    isAllowed ? (
      <SecurityCheckRoute
        component={Component}
        isRouteAlwaysAccessible={isRouteAlwaysAccessible}
        route={route}
        {...props}
      />
    ) : null;

  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
