import { RedoOutlined } from '@ant-design/icons/lib';
import { Button, Tooltip } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  displayLabel?: boolean;
};

const KannelleRetryButton: FunctionComponent<Props> = ({ onClick, displayLabel = false }: Props) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('global.retry')}>
      {displayLabel ? (
        <Button type="primary" shape="round" size="small" danger icon={<RedoOutlined />} onClick={onClick}>
          {t('global.retry')}
        </Button>
      ) : (
        <Button type="primary" shape="circle" size="small" danger icon={<RedoOutlined />} onClick={onClick} />
      )}
    </Tooltip>
  );
};
export default KannelleRetryButton;
