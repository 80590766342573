import { WarningOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { THEME } from '../../Constants';
import { RootState } from '../../redux/RootState';

const useStyles = createUseStyles({
  tag: ({ isMobileOrTablet }) => {
    const mobileAddition = isMobileOrTablet
      ? {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          wordBreak: 'break-word',
          whiteSpace: 'break-spaces',
          textAlign: 'center',
        }
      : null;

    return {
      color: THEME.ALERT.WARNING_MAIN,
      background: THEME.ALERT.WARNING_BACKGROUND,
      border: 0,
      fontSize: 14,
      padding: isMobileOrTablet ? 0 : '2px 8px',
      margin: isMobileOrTablet ? 0 : '0px 8px 0px 0px',
      cursor: 'pointer',
      borderRadius: isMobileOrTablet ? 0 : 25,
      width: isMobileOrTablet ? '100%' : 'auto',
      height: isMobileOrTablet ? '100%' : 'auto',
      ...mobileAddition,
    };
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  error: {
    background: THEME.ALERT.ERROR_BACKGROUND,
    color: THEME.ALERT.ERROR_MAIN,
    marginRight: 8,
    boxShadow: 'none',
    borderWidth: 0,
    fontSize: '14px !important',
    '&:hover, &:active, &:visited': {
      backgroundColor: THEME.ALERT.ERROR_BACKGROUND,
      color: THEME.ALERT.ERROR_MAIN,
    },
    '& *': {
      fontSize: '14px !important',
      height: '100%',
    },

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    borderRadius: '15px !important',
  },
});

const KannelleHeaderCompanyInfo: FunctionComponent = () => {
  const isMobileOrTablet = useSelector((state: RootState) => {
    return state.app.isMobileOrTablet;
  });

  const classes = useStyles({ isMobileOrTablet });
  const { t } = useTranslation();

  return (
    <Tooltip title={<span>{t('header.companyNotAccessible')}</span>} placement="bottom">
      <span className={classes.iconContainer}>
        <Button className={classes.error} size="small" icon={<WarningOutlined />}>
          {!isMobileOrTablet && t('header.warning')}
        </Button>
      </span>
    </Tooltip>
  );
};

export default KannelleHeaderCompanyInfo;
