import { DeleteOutlined, StopOutlined } from '@ant-design/icons/lib';
import { message } from 'antd';
import log from 'loglevel';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import ValidationModal from '../../../components/modal/ConfirmModal';
import RoundTag from '../../../components/tag/round-tag/RoundTag';
import { THEME } from '../../../Constants';
import { Role } from '../../../core/rule/Roles';
import { StatusCode } from '../../../services/api/APIManager';
import { deleteUserFromCharter } from '../../../services/api/ChartersService';
import { APIModelCharter } from '../../../services/api/types/ChartersServiceTypes';
import { APICompanyUsers } from '../../../services/api/types/CompaniesServiceTypes';

type Props = {
  charter: APIModelCharter;
  user: APICompanyUsers;
  callbackRemoveUserFromCharter: (loading: boolean, charter?: APIModelCharter, user?: APICompanyUsers) => void;
};

type StyleProps = {
  bgColor: string;
  textColor: string;
};

const useStyles = createUseStyles({
  tag: (props: StyleProps) => ({
    color: props.textColor,
    backgroundColor: props.bgColor,
    borderRadius: '25px',
    cursor: 'pointer',
    paddingLeft: 12,
    marginBottom: 4,
    transition: 'width 300ms ease-in-out',
    '&:hover .anticon-delete': {
      opacity: 1,
      width: 24,
    },
  }),
  icon: {
    padding: 2,
    opacity: 0,
    width: 0,
    borderRadius: 50,

    transition:
      'opacity 300ms ease-in-out, width 300ms ease-in-out, background 300ms ease-in-out, color 300ms ease-in-out',
    '&:hover': {
      background: '#f0f0f0',
      color: '#b72b2b',
    },
  },
  disabledIcon: {
    marginRight: 4,
  },
});

const TagCharter = ({ charter, user, callbackRemoveUserFromCharter }: Props) => {
  const role = new Role(charter.currentUser.role);
  const { isActive } = charter.currentUser;
  const { t } = useTranslation();
  const { BACKGROUND_COLOR: bgColor, TEXT_COLOR: textColor } = isActive
    ? role.getRoleTheme()
    : THEME.CHARTERS.USERS.INACTIVE;
  const classes = useStyles({ bgColor, textColor });
  const [confirmVisible, setConfirmVisible] = useState(false);

  const handleClick = (e: any): void => {
    e.preventDefault();

    setConfirmVisible(true);
  };

  const handleValidation = (validation: boolean): void => {
    setConfirmVisible(false);

    if (!validation) {
      return;
    }
    callbackRemoveUserFromCharter(true);

    deleteUserFromCharter(charter.id, user.id)
      .then((response) => {
        if (response.status === StatusCode.NO_CONTENT) {
          callbackRemoveUserFromCharter(false, charter, user);
        }
      })
      .catch((error) => {
        log.error(error);
        message.error(t('users.removeUsers.error'));
      });
  };

  return (
    <RoundTag color={bgColor} key={`tag_charter_${charter.id}`} className={classes.tag}>
      {!isActive ? <StopOutlined className={classes.disabledIcon} /> : null}
      {charter.name} <DeleteOutlined onClick={handleClick} className={classes.icon} />
      <ValidationModal
        callBackValidation={handleValidation}
        message={t('users.removeUserFromCharter.validation')}
        visible={confirmVisible}
      />
    </RoundTag>
  );
};

export default TagCharter;
