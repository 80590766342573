import { PoweroffOutlined } from '@ant-design/icons/lib';
import { Button, Form, message, Modal } from 'antd';
import Tooltip from 'antd/es/tooltip';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Active from '../../../../components/form/Active';
import { LOGGING_EVENT } from '../../../../Constants';
import { RootState } from '../../../../redux/RootState';
import { patchUserByCharterIdAndUserId } from '../../../../services/api/ChartersService';
import { UsersModelCharter } from '../../../../services/api/types/ChartersServiceTypes';
import LogUtils from '../../../../utils/LogUtils';
import { SubRowCharterSelect } from '../../../../utils/types/UsersTableTypes';

type Props = {
  selectedRows: SubRowCharterSelect[];
  callbackHandleUpdate: (
    updating: boolean,
    charterId: number,
    updatedDataArray?: UsersModelCharter[],
    error?: boolean
  ) => void;
};

const UpdateUserIsActiveModal: FunctionComponent<Props> = ({ selectedRows, callbackHandleUpdate }: Props) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isActive, setIsActive] = useState(true);
  const companyStats = useSelector((state: RootState) => state.companies.stats);
  const currentCompany = useSelector((state: RootState) => state.companies.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      // we reset to default value if the user doesn't exist
      setIsActive(true);
    }
  }, [visible, form]);

  const callbackIsActiveChange = (value: boolean): void => {
    setIsActive(value);
    form.setFieldsValue({
      isActive: value,
    });
  };

  const showModal = (): void => {
    setVisible(true);
  };

  const handleSubmit = (): void => {
    form.validateFields().then((values) => {
      if (!companyStats) {
        return;
      }

      if (!companyStats.canUpdateCompanyUsersIsActive(selectedRows, values.isActive)) {
        message.error(t('global.maxNbLicensesReachedError', { count: companyStats?.users.max }));
        return;
      }
      setVisible(false);

      selectedRows.forEach((subRow) => {
        const event = LogUtils.getCompanyUsersBatchUpdateEvent(
          LOGGING_EVENT.BATCH_UPDATE_USERS_IS_ACTIVE,
          subRow,
          { isActive: values.isActive },
          subRow.charters.map((charter) => charter.id),
          currentCompany?.id
        );
        loggingManager.logEventObject(event);

        const updateData = {
          isActive: values.isActive,
        };

        subRow.charters.forEach((charter) => {
          if (charter.currentUser.isActive === values.isActive) {
            return;
          }
          callbackHandleUpdate(true, charter.id);

          patchUserByCharterIdAndUserId(charter.id, subRow.userId, updateData)
            .then((response) => {
              if (response.data) {
                companyStats.userIsActiveUpdated(values.isActive);
                callbackHandleUpdate(false, charter.id, [response.data]);
              }
            })
            .catch((error) => {
              log.error(error);
              callbackHandleUpdate(false, charter.id, undefined, true);
              message.error(
                <>
                  {t('users.updateIsActive.error', { count: selectedRows.length })}
                  <br />
                  {t('support.global.emojiFingerRight')}
                  <a href={`mailto:${t('support.global.supportEmail')}`}>{t('support.global.supportEmail')}</a>
                  {t('support.global.emojiFingerLeft')}
                </>
              );
            });
        });
      });
    });
  };

  const handleCancel = (): void => {
    setVisible(false);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  const button =
    selectedRows.length === 0 ? (
      <Tooltip title={t('users.selectOnSubTable')}>
        <Button disabled onClick={showModal}>
          <PoweroffOutlined /> {t('users.updateIsActiveButton')}
        </Button>
      </Tooltip>
    ) : (
      <Button onClick={showModal}>
        <PoweroffOutlined /> {t('users.updateIsActiveButton')}
      </Button>
    );

  return (
    <>
      {button}
      <Modal
        centered
        title={t('users.updateIsActive.title', { count: selectedRows.length })}
        visible={visible}
        onOk={handleSubmit}
        onCancel={handleCancel}
        cancelText={t('global.cancel')}
        okText={t('global.confirm')}
        forceRender
      >
        <Form
          form={form}
          {...formItemLayout}
          initialValues={{
            isActive: true,
          }}
        >
          <Active
            label={t('users.fields.active')}
            extra={t('users.fields.activeDesc')}
            isActive={isActive}
            callbackChange={callbackIsActiveChange}
          />
        </Form>
      </Modal>
    </>
  );
};

export default UpdateUserIsActiveModal;
