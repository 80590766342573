import { message, PageHeader, Table, Tag } from 'antd';
import { ColumnProps } from 'antd/es/table';
import classNames from 'classnames';
import Color from 'color';
import React, { FunctionComponent } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import useCanAccess from '../../hooks/useCanAccess';
import { RootState } from '../../redux/RootState';
import { APIModelCharter } from '../../services/api/types/ChartersServiceTypes';
import CharterUtils from '../../utils/CharterUtils';
import { PermissionList } from '../../utils/types/CharterPermissionTypes';
import AccessChecker from '../access-checker/AccessChecker';
import SecuredLink from '../secured-link/SecuredLink';
import QuickAccessCell from './QuickAccessCell';

const useStyles = createUseStyles({
  title: {
    padding: 0,
  },
  avatar: {
    marginRight: 4,
  },
  tag: {
    cursor: 'pointer',
    borderRadius: 25,
  },
  whiteTag: {
    color: '#000000',
    border: 'solid 1px #777777',
  },
  unauthorized: {
    cursor: 'not-allowed !important',
    color: '#7E8AAA !important',
  },
});

// TODO keep for later use
// const breakPoints: Breakpoint[] = ['lg', 'md'];
type Props = {
  from: string;
};

const ChartersTable: FunctionComponent<Props> = ({ from }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const charters = useSelector((state: RootState) => {
    return state.charters.list;
  });

  const { hasUserAccessToCharter, renderUnauthorizedWebMessage } = useCanAccess(PermissionList.WEB_DASHBOARD);

  const buildColumns = (): ColumnProps<APIModelCharter>[] => {
    return [
      {
        title: t('charters.name'),
        dataIndex: 'name',
        key: 'charter_table_name',
        render: (text: string, record: APIModelCharter): JSX.Element => {
          return (
            <SecuredLink
              renderUnauthorizedMessage={renderUnauthorizedWebMessage}
              hasAccess={hasUserAccessToCharter(record.id)}
              key={`table_link_${record.id}`}
              linkTo={`charters/${record.id}?from=${from}`}
            >
              {text}
            </SecuredLink>
          );
        },
        sorter: CharterUtils.sortChartersByName,
        defaultSortOrder: 'ascend',
      },
      // {
      //   title: (): JSX.Element => {
      //     return (
      //       <>
      //         <span style={{ marginRight: 8 }}>Users</span>
      //         <Tooltip title="Nombre d'utilisateur activé sur la charte graphique">
      //           <Button type="primary" size="small" shape="circle" icon={<QuestionOutlined />} />
      //         </Tooltip>
      //       </>
      //     );
      //   },
      //   key: 'users',
      //   dataIndex: 'users',
      //   render: (users: User[], record: CharterType, index: number): JSX.Element => {
      //     const displayedUsers = users.slice(0, 3);
      //     const notDisplayed = users.slice(3, users.length);
      //     const notDisplayedNames = users.map((user: User) => user.name).join(', ');
      //
      //     return (
      //       <span>
      //         {displayedUsers.map((user: User) => {
      //           return (
      //             <Tooltip title={user.name} key={`row_${index}_user_${user.id}`}>
      //               <Avatar className={classes.avatar}>{user.name[0]}</Avatar>
      //             </Tooltip>
      //           );
      //         })}
      //         {notDisplayed.length > 0 ? (
      //           <Tooltip title={notDisplayedNames}>
      //             <Avatar className={classes.avatar}>{notDisplayed.length}+</Avatar>
      //           </Tooltip>
      //         ) : null}
      //       </span>
      //     );
      //   },
      // },
      {
        title: t('charters.colors'),
        key: 'charter_table_firmColors',
        dataIndex: 'firmColors',
        render: (colors: string[], record: APIModelCharter, index: number): JSX.Element => {
          const hasAccess = hasUserAccessToCharter(record.id);

          return (
            <span>
              {colors
                .map((color: string, colIndex: number) => ({ color, id: `${color}-${colIndex}` }))
                .map(({ color, id }) => {
                  const colorObject = Color(color);

                  let complementaryClasses;
                  if (colorObject.isLight()) {
                    complementaryClasses = classes.whiteTag;
                  }

                  if (!hasAccess) {
                    return (
                      <AccessChecker
                        renderUnauthorizedMessage={renderUnauthorizedWebMessage}
                        hasAccess={hasAccess}
                        key={`tag_${record.id}_${color}`}
                      >
                        <Tag className={classNames(classes.tag, classes.unauthorized)}>{color.toUpperCase()}</Tag>
                      </AccessChecker>
                    );
                  }
                  return (
                    <CopyToClipboard
                      key={`row_${index}_firmColors_${id}`}
                      text={color.toUpperCase()}
                      onCopy={(): void => {
                        message.info(t('global.copiedToClipboard'));
                      }}
                    >
                      <Tag
                        className={classNames(classes.tag, complementaryClasses)}
                        color={color}
                        key={`tag_${record.id}_${color}`}
                      >
                        {color.toUpperCase()}
                      </Tag>
                    </CopyToClipboard>
                  );
                })}
            </span>
          );
        },
      },
      {
        title: t('charters.quickAccess'),
        dataIndex: 'name',
        key: 'charter_table_access',
        render: (text: string, record: APIModelCharter): JSX.Element => {
          const hasAccess = hasUserAccessToCharter(record.id);
          return (
            <QuickAccessCell
              row={record}
              hasAccess={hasAccess}
              renderUnauthorizedMessage={renderUnauthorizedWebMessage}
            />
          );
        },
      },
    ];
  };

  if (!charters) {
    return null;
  }

  return (
    <PageHeader className={classes.title} title={t('charters.title')}>
      <Table
        rowKey={(record): string => {
          return `charters_row_${record.id}`;
        }}
        tableLayout="fixed"
        key="charters_table"
        columns={buildColumns()}
        dataSource={charters.filter((charter) => charter.isActive)}
      />
    </PageHeader>
  );
};

export default ChartersTable;
