import { FlagOutlined, LogoutOutlined, RedoOutlined } from '@ant-design/icons/lib';
import { Menu } from 'antd';
import classNames from 'classnames';
import map from 'lodash/map';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { LANGUAGE, LOGGING_EVENT, THEME } from '../../Constants';
import { useAuth0 } from '../../core/auth/Auth0Config';
import { resetOnBoarding, updateLanguage } from '../../redux/action/AppAction';
import { RootState } from '../../redux/RootState';
import AuthUtils from '../../utils/AuthUtils';
import KannelleFaqButton from './KannelleFaqButton';

const { SubMenu } = Menu;

const styleMenu = createUseStyles({
  menu: {
    padding: '0 8px',
    width: 200,
    border: 'solid 1px #f0f0f0',
    boxShadow: '0 8px 15px 0 hsla(0, 0%, 0%, 0.15) !important',
    '& .ant-menu-item': {
      fontSize: THEME.MENU.FONT_SIZE_TEXT,
    },
    '& .ant-menu-item .anticon': {
      fontSize: THEME.MENU.FONT_SIZE_ICON,
      minWidth: THEME.MENU.FONT_SIZE_ICON,
    },
    '& .ant-menu-submenu-active': {
      color: `${THEME.MENU.MAIN_TEXT_COLOR} !important`,
    },
    '& .ant-menu-submenu-title:hover': {
      color: THEME.MENU.MAIN_TEXT_COLOR,
      backgroundColor: THEME.MENU.MAIN_BACKGROUND_COLOR,
      transition: 'all 300ms ease-in-out',
    },
  },
  languageSelected: {
    borderLeft: `5px solid ${THEME.MENU.MAIN_COLOR}`,
    margin: '0 !important',
    '& .anticon': {
      color: THEME.MENU.MAIN_TEXT_COLOR,
    },

    '&:hover, &:hover .anticon': {
      color: THEME.MENU.MAIN_TEXT_COLOR,
      backgroundColor: THEME.MENU.MAIN_BACKGROUND_COLOR,
      transition: 'all 300ms ease-in-out',
    },

    '&:after': {
      opacity: '0 !important',
    },
  },
  language: {
    margin: '0 !important',
    // normal padding + active border right padding
    paddingLeft: 'calc(16px + 5px) !important',
  },
  menuItemColor: {
    color: THEME.MENU.DEFAULT_TEXT_COLOR,
    '&:hover, &:hover .anticon': {
      color: THEME.MENU.MAIN_TEXT_COLOR,
      backgroundColor: THEME.MENU.MAIN_BACKGROUND_COLOR,
      transition: 'all 300ms ease-in-out',
    },
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: THEME.MENU.DEFAULT_TEXT_COLOR,
    '&:hover, &:hover .anticon': {
      color: `${THEME.MENU.MAIN_TEXT_COLOR} !important`,
      backgroundColor: THEME.MENU.MAIN_BACKGROUND_COLOR,
      transition: 'all 300ms ease-in-out',
    },
  },
  menuItemWithTooltip: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: THEME.MENU.DEFAULT_TEXT_COLOR,
    padding: '0px !important',
  },
  subMenu: {
    '& .ant-menu-submenu-title:hover': {
      '& .ant-menu-submenu-arrow::before, & .ant-menu-submenu-arrow::after': {
        background: `linear-gradient(to right, ${THEME.MENU.MAIN_TEXT_COLOR}, ${THEME.MENU.MAIN_TEXT_COLOR})`,
      },
    },
  },
  menuItemIcons: {
    color: THEME.MENU.DEFAULT_ICON_COLOR,
    marginRight: 8,
    '&:hover': {
      color: THEME.MENU.MAIN_TEXT_COLOR,
    },
  },
  flag: {
    fontSize: 20,
    marginRight: 8,
  },
  userNameOnSmallDevice: {
    marginTop: '15px !important',
    marginBottom: '0 !important',
    height: '20px !important',
    lineHeight: '20px !important',
  },
});

const KannelleLogoutDropDownMenu: FunctionComponent = () => {
  const classes = styleMenu();
  const { t } = useTranslation();

  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);
  const language = useSelector((state: RootState) => state.app.language);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);
  const company = useSelector((state: RootState) => state.companies.current);

  const dispatch = useDispatch();

  const updateLang = (lang: string): void => {
    loggingManager.logEvent(LOGGING_EVENT.CHANGE_CURRENT_LANGUAGE, {
      fromLanguage: language,
      toLanguage: lang,
      companyId: company?.id,
    });
    dispatch(updateLanguage(lang));
  };

  const { user, isAuthenticated, logout } = useAuth0();

  const logUserOut = (): void => {
    const auth0ReturnTo = AuthUtils.getAuthEnvironmentVariable(process.env.REACT_APP_AUTH0_RETURN_TO) || '';
    if (loggingManager) {
      loggingManager.logEvent(LOGGING_EVENT.LOGOUT, {
        email: user.email,
        name: user.name,
        companyId: company?.id,
      });
    }

    logout({ returnTo: auth0ReturnTo });
  };

  const replayOnBoarding = (): void => {
    dispatch(resetOnBoarding());
    loggingManager.logEvent(LOGGING_EVENT.ONBOARDING_REPLAYED, {
      email: user.email,
      name: user.name,
      companyId: company?.id,
    });
  };

  return (
    <Menu className={classes.menu} selectable={false}>
      {user && [
        isMobileOrTablet && (
          <Menu.Item key={`dropDown_${user.sub}_name`} disabled className={classes.userNameOnSmallDevice}>
            <strong>{user.name}</strong>
          </Menu.Item>
        ),

        <Menu.Item key={`dropDown_${user.sub}`} disabled>
          {user.email}
        </Menu.Item>,

        isAuthenticated && (
          <Menu.Item className={classes.menuItem} key="logout" onClick={logUserOut}>
            <LogoutOutlined className={classes.menuItemIcons} />
            {t('dropDown.logout')}
          </Menu.Item>
        ),
      ]}
      <Menu.Divider />

      <Menu.Item className={classes.menuItem} key="onBoarding" onClick={replayOnBoarding}>
        <RedoOutlined className={classes.menuItemIcons} /> {t('onBoarding.replay')}
      </Menu.Item>

      <Menu.Divider />

      {isMobileOrTablet && (
        <>
          <Menu.Item key="kannelleFaq" className={classes.menuItem}>
            <KannelleFaqButton iconClassName={classes.menuItemIcons} />
          </Menu.Item>

          <Menu.Divider />
        </>
      )}

      <SubMenu key="subLanguage" className={classes.subMenu} icon={<FlagOutlined />} title={t('language.title')}>
        {map(LANGUAGE, ({ key, code, flag }) => {
          const languageClass = code === language ? classes.languageSelected : classes.language;
          return (
            <Menu.Item
              className={classNames(classes.menuItem, languageClass)}
              key={`language_${code}`}
              onClick={(): void => updateLang(code)}
            >
              <span className={classes.flag}>{flag}</span>
              {t(key)}
            </Menu.Item>
          );
        })}
      </SubMenu>
    </Menu>
  );
};

export default KannelleLogoutDropDownMenu;
