import { UserDeleteOutlined } from '@ant-design/icons/lib';
import { Button, List, message, Modal } from 'antd';
import Tooltip from 'antd/es/tooltip';
import classNames from 'classnames';
import log from 'loglevel';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { LOGGING_EVENT } from '../../../../Constants';
import { RootState } from '../../../../redux/RootState';
import { StatusCode } from '../../../../services/api/APIManager';
import { deleteUserFromCharter } from '../../../../services/api/ChartersService';
import { APIRemoveUserFromCharterByIdResult } from '../../../../services/api/types/UsersServiceTypes';
import LogUtils from '../../../../utils/LogUtils';
import { SubRowCharterSelect } from '../../../../utils/types/UsersTableTypes';

const useStyles = createUseStyles({
  notClosableModal: {
    cursor: 'not-allowed',
    '& .ant-modal': {
      cursor: 'default',
    },
  },
  usersList: {
    maxHeight: '350px',
    overflowY: 'auto',
  },
  actionDescriptionMessage: {
    marginTop: '10px',
  },
});

type Props = {
  selectedRows: SubRowCharterSelect[];
  callbackUserFromCharterRemoved: (charterId: number, userId: string) => void;
};

const RemoveUserFromCharterModal: FunctionComponent<Props> = ({
  selectedRows,
  callbackUserFromCharterRemoved,
}: Props) => {
  const [visible, setVisible] = useState(false);
  const [isRemovalLoading, setIsRemovalLoading] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const currentCompany = useSelector((state: RootState) => state.companies.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);

  const showModal = (): void => {
    setVisible(true);
  };

  const handleCancel = (): void => {
    setVisible(false);
  };

  const handleSubmit = (): void => {
    setIsRemovalLoading(true);

    const promises: Promise<APIRemoveUserFromCharterByIdResult | void>[] = [];

    selectedRows.forEach((subRow) => {
      const event = LogUtils.getCompanyUsersBatchUpdateEvent(
        LOGGING_EVENT.BATCH_REMOVE_USERS,
        subRow,
        {}, // no props since we remove the user from the chart
        subRow.charters.map((charter) => charter.id),
        currentCompany?.id
      );
      loggingManager.logEventObject(event);

      subRow.charters.forEach((charter) => {
        // one request per user to avoid bulk fail
        promises.push(
          deleteUserFromCharter(charter.id, subRow.userId)
            .then((response) => {
              if (response.status === StatusCode.NO_CONTENT) {
                callbackUserFromCharterRemoved(charter.id, subRow.userId);
              }
            })
            .catch((error) => {
              log.error(error);
              message.error(t('users.removeUsers.error'));
            })
        );
      });
    });

    Promise.all(promises).then(() => {
      setIsRemovalLoading(false);
      setVisible(false);
    });
  };

  const renderUserListItem = (user: SubRowCharterSelect): React.ReactNode => {
    const { userEmail, userName } = user;
    const wasUsernameSet = userName !== userEmail;

    const charterNames = user.charters.map((charter) => {
      return charter.name;
    });
    return (
      <List.Item>
        <List.Item.Meta title={userEmail} description={wasUsernameSet && userName} />
        <List.Item.Meta title={charterNames.join(', ')} />
      </List.Item>
    );
  };

  const button =
    selectedRows.length === 0 ? (
      <Tooltip title={t('users.selectOnSubTable')}>
        <Button type="primary" danger disabled onClick={showModal}>
          <UserDeleteOutlined /> {t('users.removeUsersButton')}
        </Button>
      </Tooltip>
    ) : (
      <Button type="primary" danger onClick={showModal}>
        <UserDeleteOutlined /> {t('users.removeUsersButton')}
      </Button>
    );

  return (
    <>
      {button}

      <Modal
        wrapClassName={classNames(isRemovalLoading && classes.notClosableModal)}
        centered
        title={t('users.removeUsers.title', { count: selectedRows.length })}
        visible={visible}
        onOk={handleSubmit}
        onCancel={handleCancel}
        cancelText={t('global.cancel')}
        okText={t('global.remove')}
        okButtonProps={{ danger: true, loading: isRemovalLoading, disabled: isRemovalLoading }}
        cancelButtonProps={{ disabled: isRemovalLoading }}
        closable={!isRemovalLoading}
        maskClosable={!isRemovalLoading}
        forceRender
      >
        <p>{t('users.removeUsers.confirmRemoveQuestion', { count: selectedRows.length })}</p>
        <List
          bordered
          size="small"
          dataSource={selectedRows}
          renderItem={renderUserListItem}
          className={classes.usersList}
        />
        <p className={classes.actionDescriptionMessage}>
          {t('users.removeUsers.actionDescriptionMessage', { count: selectedRows.length })}
        </p>
      </Modal>
    </>
  );
};

export default RemoveUserFromCharterModal;
