import classNames from 'classnames';
import { find } from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { ANIMATION_CONSTANTS } from '../../../Constants';
import AnimationsUtils from '../../../utils/AnimationsUtils';
import ProjectUtils from '../../../utils/ProjectUtils';
import { AnimationFormats, Logo, MaskCodes, Size } from '../../../utils/types/AnimationTypes';
import { ProjectLogoStyle, SubtitleBlock } from '../../../utils/types/ProjectTypes';

type Props = {
  currentTime: number;
  subtitlesBlock: SubtitleBlock[];
  sceneVideoPlayerSize?: Size;
  format: string;
  mask?: MaskCodes;
  logo?: Logo;
};

type StyleProps = {
  sceneVideoPlayerSize?: Size;
  mask?: MaskCodes;
  format?: string;
  logoStyle?: ProjectLogoStyle;
};

const useStyles = createUseStyles({
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  '@keyframes fadeOut': {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },

  subtitleBlockContainer: ({ sceneVideoPlayerSize, mask, logoStyle, format }: StyleProps) => {
    const topOfLogoInBottomPosition =
      format !== AnimationFormats.FORMAT_16_9 ? (logoStyle?.bottom ?? 0) + (logoStyle?.height ?? 0) : 0;
    let playerScaleFactor = 1;
    if (format && sceneVideoPlayerSize) {
      const projectReferenceWidth = AnimationsUtils.getReferenceWidthByFormat(format);
      playerScaleFactor = sceneVideoPlayerSize.width / projectReferenceWidth;
    }

    const position =
      topOfLogoInBottomPosition +
      (mask === MaskCodes.MINI ? ANIMATION_CONSTANTS.SUBTITLE.MARGIN_WITH_MASK : ANIMATION_CONSTANTS.SUBTITLE.MARGIN);

    return {
      width: sceneVideoPlayerSize?.width ?? '100%',
      overflow: 'hidden',
      position: 'absolute',
      bottom: position * playerScaleFactor,

      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      zIndex: 4,
    };
  },

  textContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    color: 'white',
    size: '60px',
    fontFamily: "'Roboto', sans-serif",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '80%',
    width: 'fit-content',
    padding: '4px 10px',
    textAlign: 'center',
  },

  subtitleBlockContainerHidden: {
    opacity: 1,
    animation: '$fadeOut 100ms forwards',
  },
  subtitleBlockContainerVisible: {
    animation: '$fadeIn 100ms forwards',
  },
});

const SceneVideoPlayerVideoSubtitles: FunctionComponent<Props> = ({
  currentTime,
  subtitlesBlock,
  sceneVideoPlayerSize,
  mask,
  format,
  logo,
}: Props) => {
  const [isSubtitleBlockVisible, setIsSubtitleBlockVisible] = useState(false);
  const [text, setText] = useState<string>();

  const logoStyle =
    logo && sceneVideoPlayerSize
      ? ProjectUtils.computeLogoStyle(logo, format as AnimationFormats, mask === MaskCodes.MINI, sceneVideoPlayerSize)
      : undefined;

  const classes = useStyles({ sceneVideoPlayerSize, isSubtitleBlockVisible, mask, format, logoStyle });

  useEffect(() => {
    const block = find(subtitlesBlock, (subBlock) => {
      return subBlock.startAt <= currentTime && subBlock.endAt > currentTime;
    });

    if (!block) {
      setIsSubtitleBlockVisible(false);
    } else {
      setIsSubtitleBlockVisible(true);
      setText(block.text);
    }
  }, [currentTime, subtitlesBlock]);

  return (
    <div
      className={classNames(
        classes.subtitleBlockContainer,
        isSubtitleBlockVisible ? classes.subtitleBlockContainerVisible : classes.subtitleBlockContainerHidden
      )}
    >
      <span className={classes.textContainer}>{text}</span>
    </div>
  );
};

export default SceneVideoPlayerVideoSubtitles;
