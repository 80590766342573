import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { find } from 'lodash';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import KannelleLogo from '../../../../resources/img/K-rectangle-small.png';
import TextPlaceholder from '../../../../resources/img/textPlaceholder.png';
import { ScenarioVariant } from '../../../../services/api/types/ChartersServiceTypes';
import ScenarioUtils from '../../../../utils/ScenarioUtils';
import { AnimationPositionConfig, Animations, PipEffects } from '../../../../utils/types/AnimationTypes';

type Props = {
  scenarioVariant: ScenarioVariant;
  croppedDemoSourceImages?: Array<string | undefined>;
};

Font.registerEmojiSource({
  format: 'png',
  url: 'https://twemoji.maxcdn.com/v/latest/72x72/',
});

// StyleSheet (@react-pdf/renderer specific API)
const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  headerRow: {
    marginBottom: 10,
  },
  scenarioHeader: {
    textAlign: 'center',
  },
  kannelleLogo: {
    width: '40pt',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  scenarioVariantDetailsRow: {
    fontSize: 12,
  },
  documentDate: {
    position: 'absolute',
    top: 0,
    right: 0,
    fontSize: 10,
  },
  scene: {
    paddingTop: 5,
    paddingBottom: 5,
    borderBottom: '1pt solid #A7A7A7',
  },
  sceneTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  sceneDescription: {
    fontSize: 10,
    color: '#777777',
  },
  sceneDataContainer: {
    marginTop: 10,
    marginBottom: 10,
    flexDirection: 'row',
  },
  sceneHeader: {
    width: '200pt',
    marginRight: '15pt',
  },
  sceneFields: {
    fontSize: 10,
    flexGrow: 1,
  },
  demoCoverImageContainer: {
    marginRight: 20,
    marginTop: 10,
    width: '100pt',
    height: '80pt',
  },
  demoCoverImage: {
    maxWidth: '100%',
    maxHeight: '100%',
    width: 'auto',
    height: 'auto',
    objectFit: 'contain',
  },
  sceneField: {
    flexDirection: 'row',
  },
  subFields: {
    flexDirection: 'column',
    marginLeft: 20,
    fontSize: 8,
  },
  fieldLabel: {
    fontWeight: 'extrabold',
    marginRight: 5,
  },
  commentsSection: {
    marginTop: 5,
    border: '1pt solid #A7A7A7',
    paddingBottom: 50,
    flexGrow: 1,
  },
  commentsTitle: {
    position: 'absolute',
    fontSize: 8,
    fontWeight: 'extrabold',
    top: 2,
    left: 2,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

const ScenarioPDF: FunctionComponent<Props> = React.memo(({ scenarioVariant, croppedDemoSourceImages }: Props) => {
  const { t } = useTranslation();

  const variantFormat = ScenarioUtils.getVariantFormatByCode(scenarioVariant.format);
  const variantLanguageFlag = ScenarioUtils.getVariantLanguage(scenarioVariant.language)?.flag;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <View style={styles.headerRow} fixed>
          <Image style={styles.kannelleLogo} src={KannelleLogo} />
          <View style={styles.scenarioHeader}>
            <Text>{t('charters.scenarios.variantEditor.pdfTitle')}</Text>
            <View style={styles.scenarioVariantDetailsRow}>
              <Text>{scenarioVariant.title}</Text>
              {variantFormat && <Text>{t(variantFormat.key)}</Text>}
              {variantLanguageFlag && <Text>{variantLanguageFlag}</Text>}
            </View>
          </View>
          <View style={styles.documentDate}>
            <Text>{moment().format(t('timeFormats.yearMonthDayHourMinute'))}</Text>
          </View>
        </View>

        {/* Scenario scenes */}
        <View>
          {scenarioVariant &&
            scenarioVariant.scenes &&
            scenarioVariant.scenes.length > 0 &&
            scenarioVariant.scenes.map((scene, index) => {
              const animationTextNameObject = find(Animations, (item) => item.code === scene.animationText?.name);
              const animationPositionCodeObject = find(
                AnimationPositionConfig,
                (item) => item.code === scene.animationText?.position?.code
              );
              const userPipEffectNameObject = find(PipEffects, (item) => item.code === scene.userPip?.effect?.name);

              const isSlide = ScenarioUtils.isSlideKind(scene.kind);

              return (
                <View style={styles.scene} key={`scenarioScene_${scene.id}`}>
                  {/* Scene data */}
                  <View style={styles.sceneDataContainer}>
                    <View style={styles.sceneHeader}>
                      {/* Scene title and description */}
                      <Text style={styles.sceneTitle}>
                        {`${index + 1}. `}
                        {scene.title}
                      </Text>
                      <Text style={styles.sceneDescription}>{scene.description}</Text>
                      {/* Scene demoCoverImageSource */}
                      {isSlide && (
                        <View style={styles.demoCoverImageContainer}>
                          <Image src={TextPlaceholder} style={styles.demoCoverImage} />
                        </View>
                      )}
                      {!isSlide && croppedDemoSourceImages && croppedDemoSourceImages[index] && (
                        <View style={styles.demoCoverImageContainer}>
                          <Image src={croppedDemoSourceImages[index]!} style={styles.demoCoverImage} />
                        </View>
                      )}
                    </View>
                    {/* Scene fields */}
                    <View style={styles.sceneFields}>
                      {/* videoRecommendedTime */}
                      <View style={styles.sceneField}>
                        <Text style={styles.fieldLabel}>
                          {t('charters.scenarios.variantEditor.sceneForm.fields.videoRecommendedTime')}:
                        </Text>
                        <Text>{scene.videoRecommendedTime}</Text>
                      </View>

                      {/* videoMinRecordTime */}
                      {scene.videoMinRecordTime && (
                        <View style={styles.sceneField}>
                          <Text style={styles.fieldLabel}>
                            {t('charters.scenarios.variantEditor.sceneForm.fields.videoMinRecordTimeLabel')}:
                          </Text>
                          <Text>{scene.videoMinRecordTime}</Text>
                        </View>
                      )}

                      {/* videoMaxRecordTime */}
                      {scene.videoMaxRecordTime && (
                        <View style={styles.sceneField}>
                          <Text style={styles.fieldLabel}>
                            {t('charters.scenarios.variantEditor.sceneForm.fields.videoMaxRecordTimeLabel')}:
                          </Text>
                          <Text>{scene.videoMaxRecordTime}</Text>
                        </View>
                      )}

                      {/* animationText */}
                      {scene.animationText && (
                        <View>
                          <Text style={styles.fieldLabel}>
                            {t('charters.scenarios.variantEditor.sceneForm.fields.animationTextLabel')}:
                          </Text>
                          <View style={styles.subFields}>
                            {animationTextNameObject && (
                              <View key={animationTextNameObject.code} style={styles.sceneField}>
                                <Text style={styles.fieldLabel}>
                                  {t(
                                    'charters.scenarios.variantEditor.sceneForm.fields.animationTextSubfields.nameLabel'
                                  )}
                                  :
                                </Text>
                                <Text>{t(animationTextNameObject.key)}</Text>
                              </View>
                            )}
                            {animationPositionCodeObject && (
                              <View key={animationPositionCodeObject.code} style={styles.sceneField}>
                                <Text style={styles.fieldLabel}>
                                  {t(
                                    'charters.scenarios.variantEditor.sceneForm.fields.animationTextSubfields.positionLabel'
                                  )}
                                  :
                                </Text>
                                <Text>{t(animationPositionCodeObject.key)}</Text>
                              </View>
                            )}
                          </View>
                        </View>
                      )}

                      {/* overlayImage */}
                      {scene.overlayImage && (
                        <View style={styles.sceneField}>
                          <Text style={styles.fieldLabel}>
                            {t('charters.scenarios.variantEditor.sceneForm.fields.overlayImageLabel')}:
                          </Text>
                          <Text>{scene.overlayImage}</Text>
                        </View>
                      )}

                      {/* userPip */}
                      {scene.userPip && (
                        <View>
                          <Text style={styles.fieldLabel}>
                            {t('charters.scenarios.variantEditor.sceneForm.fields.userPipLabel')}:
                          </Text>
                          <View style={styles.subFields}>
                            {scene.userPip.duration && (
                              <View key={`${scene.id}_userPipDuration`} style={styles.sceneField}>
                                <Text style={styles.fieldLabel}>
                                  {t(
                                    'charters.scenarios.variantEditor.sceneForm.fields.userPipSubfields.durationLabel'
                                  )}
                                  :
                                </Text>
                                <Text>{scene.userPip.duration}</Text>
                              </View>
                            )}

                            {userPipEffectNameObject && (
                              <View key={userPipEffectNameObject.code} style={styles.sceneField}>
                                <Text style={styles.fieldLabel}>{t('animations.pip.effectLabel')}:</Text>
                                <Text>{t(userPipEffectNameObject.key)}</Text>
                              </View>
                            )}
                          </View>
                        </View>
                      )}

                      {/* Comments section */}
                      <View style={styles.commentsSection}>
                        <Text style={styles.commentsTitle}>{t('charters.scenarios.variantEditor.comments')}</Text>
                      </View>
                    </View>
                  </View>
                </View>
              );
            })}
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }): string => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
});

export default ScenarioPDF;
