import { Typography } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { FilterDropdownProps, SortOrder } from 'antd/lib/table/interface';
import { TFunction } from 'i18next';
import { find } from 'lodash';
import React, { ReactElement, ReactNode } from 'react';
import Highlighter from 'react-highlight-words';
import FilterIcon from '../../../components/table/search-in-column-filter/FilterIcon';
import SearchInColumnFilter from '../../../components/table/search-in-column-filter/SearchInColumnFilter';
import { PERMISSIONS_SECTIONS } from '../../../Constants';
import { Role } from '../../../core/rule/Roles';
import { APICharterPermission, APICharterPermissionRoleAccess } from '../../../services/api/types/ChartersServiceTypes';
import StringUtils from '../../../utils/StringUtils';
import { ChangedFieldPermissionByRole, PermissionRoles } from '../../../utils/types/CharterPermissionTypes';
import PermissionSectionTag from './components/PermissionSectionTag';
import PermissionSwitch from './components/PermissionSwitch';

const { Paragraph } = Typography;

type BuildColumnsArgs = {
  t: TFunction;
  classes: Record<string, string>;
  charterPermissions: APICharterPermission[];
  filter: Record<string, string> | undefined;
  setFilter: React.Dispatch<React.SetStateAction<Record<string, string> | undefined>>;
  callbackUpdateCharterPermissionForRole: (
    permissionCode: string,
    changedFieldsByRole: ChangedFieldPermissionByRole[]
  ) => void;
  isPermissionBeingUpdated: boolean;
  setIsPermissionBeingUpdated: React.Dispatch<React.SetStateAction<boolean>>;
  roleColumnWidth: string | number;
  otherColumnsWidth: string | number;
  currentUserRole: Role;
  runHelp: boolean;
};

export const buildColumns = ({
  t,
  classes,
  charterPermissions,
  filter,
  setFilter,
  callbackUpdateCharterPermissionForRole,
  isPermissionBeingUpdated,
  setIsPermissionBeingUpdated,
  roleColumnWidth,
  otherColumnsWidth,
  currentUserRole,
  runHelp = false,
}: BuildColumnsArgs): ColumnProps<APICharterPermission>[] => {
  return [
    {
      title: t('charters.permissions.table.name'),
      dataIndex: 'name',
      key: 'permission_name',
      width: otherColumnsWidth,
      className: classes.permissionHeaderColumn,
      render: (name: string): ReactElement => {
        return (
          <div className={classes.permissionNameCell}>
            {filter && filter.name ? (
              <Highlighter
                className={classes.highlightedText}
                searchWords={[filter.name]}
                autoEscape
                textToHighlight={name.toString()}
              />
            ) : (
              <Paragraph className={classes.permissionNameParagraph} ellipsis={{ tooltip: name, rows: 2 }}>
                {name}
              </Paragraph>
            )}
          </div>
        );
      },
      filterDropdown: (filterProps: FilterDropdownProps): ReactNode => {
        const possibleValues = charterPermissions?.map((p) => p.name);
        return (
          <SearchInColumnFilter
            {...filterProps}
            className={classes.searchInColumnFilter}
            dataIndex="name"
            setFilter={setFilter}
            placeholder={t('charters.permissions.table.searchOnPermissionNamePlaceholder')}
            possibleValues={possibleValues}
            runHelp={runHelp}
          />
        );
      },
      filterIcon: FilterIcon,
      onFilter: (value: string | number | boolean, record: APICharterPermission): boolean =>
        StringUtils.normalize(record.name).includes(StringUtils.normalize(value.toString())),
      sorter: (permA: APICharterPermission, permB: APICharterPermission): number =>
        StringUtils.compareNaturalNormalized(permA.name, permB.name),
    },
    {
      title: t('charters.permissions.table.section'),
      dataIndex: 'section',
      key: 'permission_section',
      width: otherColumnsWidth,
      className: classes.permissionHeaderColumn,
      filters: Object.entries(PERMISSIONS_SECTIONS).map(([key, value]) => ({
        value: key,
        text: <PermissionSectionTag permissionSection={value} />,
      })),
      onFilter: (value: string | number | boolean, record: APICharterPermission): boolean => record.section === value,
      filteredValue: runHelp ? charterPermissions?.map((p) => p.section) : undefined,
      render: (section: string): JSX.Element => {
        const permissionSection = PERMISSIONS_SECTIONS[section];
        return <PermissionSectionTag permissionSection={permissionSection} />;
      },
      sorter: (permA: APICharterPermission, permB: APICharterPermission): number => {
        const permissionSectionA = PERMISSIONS_SECTIONS[permA.section];
        const permissionSectionB = PERMISSIONS_SECTIONS[permB.section];
        return permissionSectionA.order < permissionSectionB.order ? -1 : 1;
      },
      defaultSortOrder: 'ascend' as SortOrder,
    },
    {
      title: t('roles.names.Owner'),
      dataIndex: 'roles',
      key: 'permission_role_owner',
      render: (roles: APICharterPermissionRoleAccess[], permission: APICharterPermission): JSX.Element | undefined => {
        const ownerRole = find(roles, (role) => role.code === PermissionRoles.OWNER);
        const currentRole = find(
          roles,
          (role) => StringUtils.compareNaturalNormalized(role.code, currentUserRole.currentRole) === 0
        );
        const hasAccess = currentRole && currentRole.hasAccess.access;

        if (!ownerRole) {
          return undefined;
        }
        return (
          <PermissionSwitch
            role={ownerRole}
            permission={permission}
            isChecked={ownerRole.hasAccess.access}
            disabled={
              currentUserRole.isOwner() || currentUserRole.isAdmin() || currentUserRole.isCreator() || !hasAccess
            }
            isPermissionBeingUpdated={isPermissionBeingUpdated}
            setIsPermissionBeingUpdated={setIsPermissionBeingUpdated}
            callback={callbackUpdateCharterPermissionForRole}
          />
        );
      },
      width: roleColumnWidth,
    },
    {
      title: t('roles.names.Admin'),
      dataIndex: 'roles',
      key: 'permission_role_admin',
      render: (roles: APICharterPermissionRoleAccess[], permission: APICharterPermission): JSX.Element | undefined => {
        const adminRole = find(roles, (role) => role.code === PermissionRoles.ADMIN);
        const currentRole = find(
          roles,
          (role) => StringUtils.compareNaturalNormalized(role.code, currentUserRole.currentRole) === 0
        );
        const hasAccess = currentRole && currentRole.hasAccess.access;

        if (!adminRole) {
          return undefined;
        }
        return (
          <PermissionSwitch
            role={adminRole}
            permission={permission}
            isChecked={adminRole.hasAccess.access}
            disabled={currentUserRole.isAdmin() || currentUserRole.isCreator() || !hasAccess}
            isPermissionBeingUpdated={isPermissionBeingUpdated}
            setIsPermissionBeingUpdated={setIsPermissionBeingUpdated}
            callback={callbackUpdateCharterPermissionForRole}
          />
        );
      },
      width: roleColumnWidth,
    },
    {
      title: t('roles.names.Creator'),
      dataIndex: 'roles',
      key: 'permission_role_creator',
      render: (roles: APICharterPermissionRoleAccess[], permission: APICharterPermission): JSX.Element | undefined => {
        const creatorRole = find(roles, (role) => role.code === PermissionRoles.CREATOR);
        const currentRole = find(
          roles,
          (role) => StringUtils.compareNaturalNormalized(role.code, currentUserRole.currentRole) === 0
        );
        const hasAccess = currentRole && currentRole.hasAccess.access;

        if (!creatorRole) {
          return undefined;
        }
        return (
          <PermissionSwitch
            role={creatorRole}
            permission={permission}
            isChecked={creatorRole.hasAccess.access}
            disabled={currentUserRole.isCreator() || !hasAccess}
            isPermissionBeingUpdated={isPermissionBeingUpdated}
            setIsPermissionBeingUpdated={setIsPermissionBeingUpdated}
            callback={callbackUpdateCharterPermissionForRole}
          />
        );
      },
      width: roleColumnWidth,
    },
  ];
};
