import { CancelTokenSource } from 'axios';
import log from 'loglevel';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '../redux/RootState';
import { APIManager } from '../services/api/APIManager';
import { processCharterMediaCroppedUrlById } from '../services/api/ChartersService';
import { SocketManager } from '../services/api/SocketManager';
import { MediaFile } from '../services/api/types/ChartersServiceTypes';
import MediaUtils from '../utils/MediaUtils';
import useSocket from './useSocket';

type MediaCropProgressPayload = {
  data: MediaFile & {
    messageId: string;
  };
  progress?: number;
};

type CroppedMediaResult = [croppedMedia?: MediaFile, isLoading?: boolean, progress?: number];

const useCroppedMedia = (media?: MediaFile, isSceneCopy?: boolean, isCarousel?: boolean): CroppedMediaResult => {
  const [room, setRoom] = useState<string>();
  const [currentMediaToCrop, setCurrentMediaToCrop] = useState<MediaFile>();
  const [croppedMedia, setCroppedMedia] = useState<MediaFile>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [progress, setProgress] = useState<number>();
  const [croppingMessageId, setCroppingMessageId] = useState<string>();
  const [shouldWeUpdateCrop, setShouldWeUpdateCrop] = useState(false);
  const [croppingProcessStarted, setCroppingProcessStarted] = useState<boolean>();

  // We want to leave the room on unmount ONLY when the carousel unmounts
  // => because it means the whole page as been unmounted
  const isSocketConnected = useSocket(room ?? undefined, isCarousel);
  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());

  const currentUserId = useSelector((state: RootState) => state.user.user?.id);
  const charterId = useSelector((state: RootState) => state.charters.current?.id);

  const mediaId = media?.id;

  const resetState = () => {
    setIsLoading(false);
    setCroppingProcessStarted(false);
    setProgress(undefined);
    setShouldWeUpdateCrop(false);
  };

  useEffect(() => {
    if (!media) {
      setIsLoading(false);
      setCroppingProcessStarted(false);
      setCroppedMedia(undefined);
      setCurrentMediaToCrop(undefined);
      setProgress(undefined);

      return;
    }

    const hasMediaIdChanged = media.id !== currentMediaToCrop?.id;
    const hasMediaCroppedAreaChanged = !MediaUtils.areCroppedAreaEqual(
      media.croppedArea,
      currentMediaToCrop?.croppedArea
    );

    if (hasMediaIdChanged || hasMediaCroppedAreaChanged) {
      setCurrentMediaToCrop({ ...media });
    }
  }, [media]);

  useEffect(() => {
    if (!currentMediaToCrop) {
      return;
    }

    if (currentMediaToCrop.croppedFileUrl || currentMediaToCrop.croppedHlsFileUrl) {
      const url = currentMediaToCrop.croppedFileUrl || currentMediaToCrop.croppedHlsFileUrl;
      if (url) {
        const croppedAreaFromUrl = MediaUtils.getCroppedAreaFromUrl(url);

        if (MediaUtils.areCroppedAreaEqual(croppedAreaFromUrl, currentMediaToCrop.croppedArea)) {
          setCroppedMedia(currentMediaToCrop);
          resetState();
          return;
        }
      }
    }

    // We generate the message id
    const messageId = uuidv4();
    setCroppingMessageId(messageId);

    setIsLoading(true);
    setShouldWeUpdateCrop(true);

    setProgress(undefined);
    setCroppingProcessStarted(false);
  }, [currentMediaToCrop]);

  // Join the right websockets room
  useEffect(() => {
    if (!currentUserId || !charterId || !mediaId) {
      return;
    }

    setRoom(`users/${currentUserId}/charters/${charterId}/media/${mediaId}`);
  }, [currentUserId, charterId, mediaId]);

  const handleMediaCropMessage = (payload: MediaCropProgressPayload) => {
    const { progress: payloadProgress, croppedFileUrl, croppedHlsFileUrl, croppedArea, messageId } = payload.data;

    // We check if the payload's messageId equals the cropping request messageId.
    // We also check if the payload's croppedArea aren't matching the current media, we ignore the message.
    // Both are to prevent successive crops to generate crossed messages, with inconsistent progressions.
    if (
      !(
        media &&
        croppingMessageId === messageId &&
        croppedArea &&
        MediaUtils.checkIfMediaHasCroppedArea(media, croppedArea)
      )
    ) {
      return;
    }

    setCroppingProcessStarted(true);

    // If the payload contains a 'progress' property which is defined and greater than the previous one, we update the 'progress' state
    if (payloadProgress !== undefined && payloadProgress !== null) {
      setProgress((prevState?: number) => (prevState && prevState > payloadProgress ? prevState : payloadProgress));
    }

    // If the payload contains cropped file urls, we set the progress to 100%, end the loading, and return the cropped media
    if (croppedFileUrl || croppedHlsFileUrl) {
      // When we get the message, then the cropping is done
      setCroppingProcessStarted(false);
      setProgress(undefined);
      setCroppedMedia({ ...payload.data });
      setIsLoading(false);
      setShouldWeUpdateCrop(false);
    }
  };

  // Handle the websocket messages
  useEffect(() => {
    if (!isSocketConnected || !croppingMessageId) {
      return undefined;
    }

    SocketManager.onMessage(handleMediaCropMessage);

    return (): void => SocketManager.offMessage(handleMediaCropMessage);
  }, [isSocketConnected, media, croppingMessageId]);

  const processCharterMedia = (processCharterId: number, processMedia: MediaFile) => {
    const cancelTokenSource = cancelTokenSourceRef.current;

    processCharterMediaCroppedUrlById(
      processCharterId,
      processMedia.id,
      processMedia.croppedArea!,
      croppingMessageId,
      cancelTokenSource
    ).catch((e: any) => {
      log.debug('Error while requesting the cropped media url', e);
    });
  };

  // Require the crop processing
  useEffect(() => {
    // Check if the room for the media has been joined yet
    const roomForMediaIsJoined = room && SocketManager.rooms?.includes(room);

    if (!roomForMediaIsJoined || croppingProcessStarted || !croppingMessageId || !shouldWeUpdateCrop || isSceneCopy) {
      return;
    }

    if (!charterId || !media) {
      setIsLoading(false);
      return;
    }

    if (!isLoading) {
      setIsLoading(true);
    }

    processCharterMedia(charterId, media);
  }, [isSocketConnected, shouldWeUpdateCrop, croppingMessageId, SocketManager.rooms, room]);

  return [croppedMedia, isLoading, progress];
};

export default useCroppedMedia;
