import { CheckOutlined, RollbackOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import log from 'loglevel';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { patchTranslations } from '../../../services/api/TranslationService';
import { APIPatchTranslation, APITranslation } from '../../../services/api/types/TranslationServiceTypes';

const useStyles = createUseStyles({
  inputContainer: {
    display: 'flex',
    '& .ant-input, & .ant-form': {
      flexGrow: 1,
    },
    '& .ant-form-item': {
      marginBottom: 0,
    },
    '& .ant-input, & .ant-btn': {
      borderRadius: 0,
    },
  },
  editableCell: {
    padding: 0,
  },
});

const EditableCell: FunctionComponent = ({
  editable,
  children,
  dataIndex,
  cellKey,
  record,
  handleUpdate,
  ...restProps
}: any) => {
  const classes = useStyles();
  const [editing, setEditing] = useState(false);
  const { t } = useTranslation();

  const toggleEdit = (): void => {
    setEditing(!editing);
  };

  let childNode = children;

  if (editable) {
    const [form] = Form.useForm();
    const { name, lang, url, category } = record;
    const value = record[dataIndex];
    let placeholder = '';
    if (!value) {
      placeholder = t('admin.translation.needTranslation');
    }

    const submit = (): void => {
      form.submit();
    };

    const handleCancel = (): void => {
      setEditing(false);
      form.setFieldsValue({
        [cellKey]: value,
      });
    };

    const handleSave = (val: string, language: string, urlValue: string, cat: string, row: APITranslation): void => {
      const patchData: APIPatchTranslation = {
        id: row.id,
        lang: language,
        category: cat,
        name: cellKey === 'translation_translations_name' ? val : name,
        url: cellKey === 'translation_translations_url' ? val : urlValue,
      };
      handleUpdate(true);
      patchTranslations(patchData)
        .then((response) => {
          const updatedPartialData = response.data;
          setEditing(false);

          handleUpdate(false, updatedPartialData, language);
          message.success(t('admin.translation.updateSuccess'));
        })
        .catch((e) => {
          log.debug(e.message);
          handleUpdate(false);
          message.error(t('admin.translation.error'));
        });
    };

    const onFinish = (): void => {
      form.validateFields().then((values): void => {
        handleSave(values[cellKey], lang, url, category, record);
      });
    };

    childNode = editing ? (
      <div className={classes.inputContainer}>
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{
            [cellKey]: value,
          }}
        >
          <Form.Item
            name={cellKey}
            required
            rules={[
              { required: true, message: t('admin.translation.fieldRequired') },
              { whitespace: true, message: t('admin.translation.fieldRequired') },
            ]}
          >
            <Input placeholder={placeholder} />
          </Form.Item>
        </Form>
        <Button onClick={handleCancel}>
          <RollbackOutlined />
        </Button>
        <Button onClick={submit}>
          <CheckOutlined />
        </Button>
      </div>
    ) : (
      <div className={classes.editableCell} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;
