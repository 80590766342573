import { useMemo } from 'react';
import { Asset } from '../../../../../../../services/api/types/ChartersServiceTypes';
import ProjectUtils from '../../../../../../../utils/ProjectUtils';
import { Project, ProjectScene } from '../../../../../../../utils/types/ProjectTypes';

type ProjectFinalRenderData = {
  projectDuration: number;
  projectElements: ExtendedProjectElement[];
  intro?: ExtendedAsset;
  outro?: ExtendedAsset;
  scenes?: ExtendedProjectScene[];
};

export enum ProjectElementType {
  INTRO = 'INTRO',
  OUTRO = 'OUTRO',
  SCENE = 'SCENE',
}

export type ExtendedAsset = Asset & {
  elementType: ProjectElementType;
  absoluteStartAt: number;
  absoluteEndAt: number;
};

export type ExtendedProjectScene = ProjectScene & {
  elementType: ProjectElementType;
  relativeStartAt: number;
  relativeEndAt: number;
  absoluteStartAt: number;
  absoluteEndAt: number;
};

export type ExtendedProjectElement = ExtendedAsset | ExtendedProjectScene;

const useProjectFinalRenderData = (project: Project): ProjectFinalRenderData => {
  const projectDuration = useMemo(() => ProjectUtils.computeProjectDuration(project), [project]);
  const projectElements: ExtendedProjectElement[] = useMemo(() => {
    const introElement: ExtendedProjectElement | undefined =
      project.intro && project.intro.duration
        ? {
            ...project.intro,
            elementType: ProjectElementType.INTRO,
            absoluteStartAt: 0,
            absoluteEndAt: project.intro.duration,
          }
        : undefined;

    const sceneElements: ExtendedProjectElement[] =
      project.scenes
        ?.filter((scene) => scene.isActive)
        .map((scene) => ({
          ...scene,
          elementType: ProjectElementType.SCENE,
          absoluteStartAt: ProjectUtils.computeSceneStartTimeInProject(project, scene),
          absoluteEndAt: ProjectUtils.computeSceneEndTimeInProject(project, scene),
          relativeStartAt: ProjectUtils.computeSceneRelativeStartAt(scene),
          relativeEndAt: ProjectUtils.computeSceneRelativeEndAt(scene),
        })) ?? [];

    const outroElement: ExtendedProjectElement | undefined =
      project.outro && project.outro.duration
        ? {
            ...project.outro,
            elementType: ProjectElementType.OUTRO,
            absoluteStartAt: ProjectUtils.computeOutroStartTimeInProject(project),
            absoluteEndAt: ProjectUtils.computeProjectDuration(project),
          }
        : undefined;

    return [
      ...(introElement ? [introElement] : []),
      ...(sceneElements ? [...sceneElements] : []),
      ...(outroElement ? [outroElement] : []),
    ];
  }, [project]);

  const intro = useMemo(
    () =>
      projectElements.find((element) => element.elementType === ProjectElementType.INTRO) as ExtendedAsset | undefined,
    [projectElements]
  );
  const outro = useMemo(
    () =>
      projectElements.find((element) => element.elementType === ProjectElementType.OUTRO) as ExtendedAsset | undefined,
    [projectElements]
  );
  const scenes = useMemo(
    () =>
      projectElements.filter((element) => element.elementType === ProjectElementType.SCENE) as
        | ExtendedProjectScene[]
        | undefined,
    [projectElements]
  );

  return {
    projectDuration,
    projectElements,
    intro,
    outro,
    scenes,
  };
};

export default useProjectFinalRenderData;
