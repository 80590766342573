import { EyeInvisibleFilled } from '@ant-design/icons';
import { Button, Form, InputNumber, Popconfirm, Switch } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { Store, StoreValue } from 'antd/lib/form/interface';
import { diff } from 'deep-object-diff';
import isEqual from 'lodash/isEqual';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTrashAlt } from 'react-icons/fa';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import AccessChecker from '../../../../../../components/access-checker/AccessChecker';
import AnimationSelector from '../../../../../../components/animation-selector/AnimationSelector';
import useCanAccess from '../../../../../../hooks/useCanAccess';
import { RootState } from '../../../../../../redux/RootState';
import CompaniesUtils from '../../../../../../utils/CompaniesUtils';
import MathUtils from '../../../../../../utils/MathUtils';
import ProjectUtils from '../../../../../../utils/ProjectUtils';
import { AnimationConfig } from '../../../../../../utils/types/AnimationTypes';
import { PermissionList } from '../../../../../../utils/types/CharterPermissionTypes';
import { ProjectScene, ProjectSceneAnimationText } from '../../../../../../utils/types/ProjectTypes';
import { AnimationTheme } from '../../../../../../utils/types/ThemeTypes';
import ProjectSceneAnimationTexts from './project-scene-animation-texts/ProjectSceneAnimationTexts';

type Props = {
  initialScene: ProjectScene;
  scene: ProjectScene;
  isSceneEdited: boolean;
  isSceneInError: boolean;
  sceneDuration: number;
  format?: string;
  theme: AnimationTheme;
  customColors: boolean;
  onEditScene: (updatedScene: ProjectScene) => void;
  onSubmitScene: (updatedScene: ProjectScene) => void;
  onIsSceneEditedChange: (isEdited: boolean) => void;
  onIsSceneInErrorChange: (isInError: boolean) => void;
};

const useStyles = createUseStyles({
  form: {
    height: '80%',
    overflow: 'auto',
    paddingRight: 8,
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '6px',
      backgroundColor: 'rgb(123, 123, 123)',
      boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
    },
    '&::-webkit-scrollbar-track-piece': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      borderRadius: '6px',
    },

    '& .ant-form-item-label': {
      padding: 0,
    },
    '& .ant-form-item': {
      marginBottom: 10,
    },
  },
  actionsContainer: {
    position: 'absolute',
    bottom: 10,
    right: 0,
    '& > button:not(:last-child)': {
      marginRight: 10,
    },
  },
  unuseSlideOrDeleteAnimationButton: {
    position: 'absolute',
    bottom: 10,
    '& .ant-btn': {
      lineHeight: 1,
    },
  },
});

const ProjectSceneAnimationOptions: FunctionComponent<Props> = ({
  initialScene,
  scene,
  isSceneEdited,
  isSceneInError,
  sceneDuration,
  format,
  theme,
  customColors,
  onEditScene,
  onSubmitScene,
  onIsSceneEditedChange,
  onIsSceneInErrorChange,
}: Props) => {
  const [initialValues, setInitialValues] = useState<Store>();
  const [animationConfig, setAnimationConfig] = useState<AnimationConfig>();

  const isSlideScene = ProjectUtils.isSlideScene(scene);

  const permissionToEditSlideScene = useCanAccess(PermissionList.SCENE_SLIDE_EDIT);
  const permissionToEditSceneAnimation = useCanAccess(PermissionList.SCENE_ANIMATION_TYPE_EDIT);

  const { t } = useTranslation();
  const classes = useStyles();
  const [form] = Form.useForm();

  const companies = useSelector((state: RootState) => state.companies.list);
  const isKnlTeam = companies ? CompaniesUtils.checkIsKnlProfile(companies) : false;

  useEffect(() => {
    if (!initialScene.animation) {
      return;
    }

    const config = ProjectUtils.transformProjectSceneAnimationToAnimationConfig(initialScene.animation);

    const formInitialValues = {
      sceneAnimationConfig: config,
      sceneAnimationTexts: initialScene.animation.animationTexts,
      sceneAnimationDelay: initialScene.animation.delay === undefined ? 0 : initialScene.animation.delay,
      sceneAnimationActive: initialScene.animation.active,
    };
    setAnimationConfig(config);
    setInitialValues(formInitialValues);
    form.setFieldsValue(formInitialValues);
  }, [initialScene, form]);

  useEffect(() => {
    form.setFieldsValue({
      sceneAnimationDelay: scene.animation?.delay !== undefined ? scene.animation?.delay : 0,
    });
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    checkIfSceneAnimationEdited();
  }, [scene.animation?.delay]);

  const buildSceneFromForm = () => {
    const fields = form.getFieldsValue();
    const isThereADelay = fields.sceneAnimationDelay !== undefined && fields.sceneAnimationDelay !== 0;

    if (!fields.sceneAnimationConfig) {
      return undefined;
    }

    return {
      ...scene,
      animation: {
        ...scene.animation,
        code: fields.sceneAnimationConfig.name,
        animationTexts: fields.sceneAnimationTexts,
        position: fields.sceneAnimationConfig.position.code,
        offsetX: fields.sceneAnimationConfig.position.x,
        offsetY: fields.sceneAnimationConfig.position.y,
        delay: isThereADelay ? fields.sceneAnimationDelay : undefined,
        active: fields.sceneAnimationActive,
      },
    };
  };

  // Callback to see if the animation options have been changed and differ from the ones of the
  // initialScene
  const [checkIfSceneAnimationEdited] = useDebouncedCallback(() => {
    // Transform the project initial scene animation into an AnimationConfig
    // And get the initial scene values
    const initialAnimationConfig =
      initialScene.animation && ProjectUtils.transformProjectSceneAnimationToAnimationConfig(initialScene.animation);
    const initialAnimationTexts = initialScene.animation?.animationTexts;
    const initialIsActive = initialScene.animation?.active;
    const initialDelay = initialScene.animation?.delay;

    // Get the field values
    const sceneAnimationConfig = form.getFieldValue('sceneAnimationConfig');
    const sceneAnimationTexts = form.getFieldValue('sceneAnimationTexts');
    const sceneAnimationActive = form.getFieldValue('sceneAnimationActive');
    const sceneAnimationDelay = form.getFieldValue('sceneAnimationDelay');

    // Clean properties to ignore into the comparison
    delete sceneAnimationConfig?.position?.key;

    // Compare the initial scene animation (config and texts) with the ones from the form
    const animationConfigIsEqual = isEqual(initialAnimationConfig, sceneAnimationConfig);
    const animationTextsAreEqual = initialAnimationTexts && isEqual(initialAnimationTexts, sceneAnimationTexts);
    const animationActiveAreEqual = initialIsActive === sceneAnimationActive;
    const animationDelayAreEqual =
      initialDelay === sceneAnimationDelay || (initialDelay === undefined && sceneAnimationDelay === 0);
    // Check if the animation was edited
    const wasNotEdited = isSlideScene
      ? animationConfigIsEqual && animationTextsAreEqual
      : animationConfigIsEqual && animationTextsAreEqual && animationActiveAreEqual && animationDelayAreEqual;

    if (wasNotEdited) {
      onIsSceneEditedChange(false);
    } else {
      onIsSceneEditedChange(true);
      // Log the difference for KnlTeam (ease of debug)
      if (isKnlTeam) {
        console.groupCollapsed('ℹ️ Animation was edited');
        console.log(
          'Animation config difference: ',
          !animationConfigIsEqual && initialAnimationConfig
            ? diff(initialAnimationConfig, sceneAnimationConfig)
            : undefined
        );
        console.log(
          'Animation texts difference: ',
          !animationTextsAreEqual && initialAnimationTexts
            ? diff(initialAnimationTexts, sceneAnimationTexts)
            : undefined
        );
        if (!isSlideScene) {
          console.log(
            'Animation active difference: ',
            !animationActiveAreEqual
              ? `initial value (${initialIsActive}) ≠ form value (${sceneAnimationActive})`
              : undefined
          );
          console.log(
            'Animation delay difference: ',
            !animationDelayAreEqual
              ? `initial value (${initialDelay}) ≠ form value (${sceneAnimationDelay})`
              : undefined
          );
        }
        console.groupEnd();
      }
    }
  }, 250);

  // 500 ms after a form field was changed, we call the `onFieldChange` callback with the "temporary" scene corresponding
  // to the unsaved scene
  const [delayedOnFieldChange] = useDebouncedCallback(() => {
    const updatedScene = buildSceneFromForm();
    if (updatedScene) {
      onEditScene(updatedScene);
    }
  }, 500);

  const onValuesChange = (changedValues: Store, allValues: Store): void => {
    if (isSlideScene && !permissionToEditSlideScene.hasUserAccess()) {
      return;
    }

    // Save the selected AnimationConfig
    setAnimationConfig(allValues.sceneAnimationConfig);

    // Check if the values in the form differ from the initial scene ones
    checkIfSceneAnimationEdited();

    // Delayed callback when one field gets updated
    delayedOnFieldChange();
  };

  const onReset = (): void => {
    form.resetFields();
    onIsSceneEditedChange(false);
  };

  const onSubmit = (): void => {
    form
      .validateFields()
      .then(() => {
        const updatedScene = buildSceneFromForm();

        if (!updatedScene) {
          return;
        }

        onSubmitScene(updatedScene);
        onIsSceneEditedChange(false);
      })
      .catch((e) => {
        log.error(e);
      });
  };

  const onDeleteAnimation = (): void => {
    const updatedScene = {
      ...scene,
      animation: undefined,
    };
    onSubmitScene(updatedScene);
  };

  const onUnuseSlideScene = (): void => {
    const updatedScene = {
      ...scene,
      isActive: false,
    };
    onSubmitScene(updatedScene);
  };

  const checkAnimationTexts = (rule: RuleObject, val: StoreValue): Promise<void> | void => {
    // If no animationTexts
    if (!(val && val.length > 0)) {
      return Promise.resolve();
    }
    // If there are some having no text (required)
    if (val.some((animationText: ProjectSceneAnimationText) => !animationText.text)) {
      return Promise.reject(
        new Error(t('charters.projects.projectEditor.sceneEditor.fields.animation.animationTextsRequired'))
      );
    }

    return Promise.resolve();
  };

  const onAnimationTextInErrorChange = (isInError: boolean) => {
    onIsSceneInErrorChange(isInError);
  };

  const renderUnuseSlideOrDeleteAnimationButton = () => {
    const unuseSlideOrDeleteAnimationPopConfirm = isSlideScene
      ? t('charters.projects.projectEditor.sceneEditor.fields.animation.unuseSlideAnimationPopConfirm')
      : t('charters.projects.projectEditor.sceneEditor.fields.animation.deleteAnimationPopConfirm');

    return (
      <AccessChecker
        hasAccess={permissionToEditSceneAnimation.hasUserAccess()}
        renderUnauthorizedMessage={permissionToEditSceneAnimation.renderUnauthorizedMessage}
      >
        <div className={classes.unuseSlideOrDeleteAnimationButton}>
          <Popconfirm
            title={unuseSlideOrDeleteAnimationPopConfirm}
            onConfirm={isSlideScene ? onUnuseSlideScene : onDeleteAnimation}
            okText={t('global.yes')}
            cancelText={t('global.no')}
            disabled={!permissionToEditSceneAnimation.hasUserAccess()}
          >
            <Button
              type="primary"
              danger
              shape="circle"
              icon={isSlideScene ? <EyeInvisibleFilled /> : <FaTrashAlt />}
              disabled={!permissionToEditSceneAnimation.hasUserAccess()}
            />
          </Popconfirm>
        </div>
      </AccessChecker>
    );
  };

  return (
    <Form
      form={form}
      layout="vertical"
      className={classes.form}
      size="small"
      initialValues={initialValues}
      onValuesChange={onValuesChange}
    >
      <AccessChecker
        renderUnauthorizedMessage={permissionToEditSlideScene.renderUnauthorizedMessage}
        hasAccess={isSlideScene ? permissionToEditSlideScene.hasUserAccess() : true}
      >
        {/* animationName, animationTexts, position, offsetX, offsetY */}
        <Form.Item
          name="sceneAnimationConfig"
          label={t('charters.projects.projectEditor.sceneEditor.fields.animation.animationLabel')}
          rules={[{ required: true }]}
        >
          <AnimationSelector
            fieldLabel={t('charters.projects.projectEditor.sceneEditor.fields.animation.animationLabel')}
            format={format}
            theme={theme}
            customColors={customColors}
            isSlide={isSlideScene}
            noReset
            disabled={isSlideScene && !permissionToEditSlideScene.hasUserAccess()}
          />
        </Form.Item>
        <Form.Item
          name="sceneAnimationTexts"
          label={t('charters.projects.projectEditor.sceneEditor.fields.animation.animationTextsLabel')}
          rules={[{ required: true, validator: checkAnimationTexts, validateTrigger: 'onSubmit' }]}
        >
          <ProjectSceneAnimationTexts
            selectedAnimationConfig={animationConfig}
            initialAnimationTexts={initialScene.animation?.animationTexts}
            onAnimationTextInErrorChange={onAnimationTextInErrorChange}
            disabled={!permissionToEditSlideScene.hasUserAccess()}
            isSlideScene={isSlideScene}
          />
        </Form.Item>

        {/* delay */}
        <Form.Item
          name="sceneAnimationDelay"
          label={t('charters.projects.projectEditor.sceneEditor.fields.animation.delayLabel')}
          hidden={isSlideScene}
        >
          <InputNumber
            min={0}
            max={ProjectUtils.getTrimEndAtValue(scene, sceneDuration) - ProjectUtils.getTrimStartAtValue(scene)}
            step={0.01}
            formatter={(value: number | undefined) =>
              value ? MathUtils.formatInputValueWithXDecimals(Number(value), 2)?.toString() : ''
            }
          />
        </Form.Item>
        {/* active */}
        <Form.Item
          name="sceneAnimationActive"
          label={t('charters.projects.projectEditor.sceneEditor.fields.animation.activeLabel')}
          valuePropName="checked"
          hidden={isSlideScene}
        >
          <Switch />
        </Form.Item>

        {renderUnuseSlideOrDeleteAnimationButton()}

        <div className={classes.actionsContainer}>
          <Button disabled={!isSceneEdited} onClick={onReset}>
            {t('global.reset')}
          </Button>
          <Button
            disabled={!isSceneEdited || isSceneInError || !scene.animation?.duration}
            type="primary"
            onClick={onSubmit}
          >
            {t('global.save')}
          </Button>
        </div>
      </AccessChecker>
    </Form>
  );
};

export default ProjectSceneAnimationOptions;
