import { Button, Col, Modal, Row, Select, Switch } from 'antd';
import { map } from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegFileImage } from 'react-icons/fa';
import { MdFeaturedVideo, MdOutlineHideImage, MdPhotoSizeSelectLarge } from 'react-icons/md';
import { createUseStyles } from 'react-jss';
import AccessChecker from '../../../../../../components/access-checker/AccessChecker';
import AssetSelector from '../../../../../../components/asset-selector/AssetSelector';
import useCanAccess from '../../../../../../hooks/useCanAccess';
import { Asset, AssetType } from '../../../../../../services/api/types/ChartersServiceTypes';
import ProjectUtils from '../../../../../../utils/ProjectUtils';
import { PermissionList } from '../../../../../../utils/types/CharterPermissionTypes';
import {
  ProjectLogo,
  ProjectLogoDisplayMode,
  ProjectLogoPosition,
  ProjectLogoPositions,
  ProjectLogoSize,
  ProjectLogoSizes,
} from '../../../../../../utils/types/ProjectTypes';

type Props = {
  value?: ProjectLogo;
  isVisible: boolean;
  fieldLabel: string;
  onCancel: () => void;
  onSubmit: (newValue?: ProjectLogo) => void;
};

const useStyles = createUseStyles({
  modal: {
    width: '80% !important',
  },
  fieldLabel: {
    display: 'flex',
    height: 32,
    justifyContent: 'flex-end',
    alignItems: 'center',
    '&::after': {
      content: "':'",
      position: 'relative',
      top: '-0.5px',
      margin: '0 8px 0 2px',
    },
  },
  fieldLabelIcon: {
    marginRight: 6,
  },
  formFieldContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
  fieldSelectorColumn: {
    display: 'flex',
    alignItems: 'center',
  },
  displayLogoOnSlidesExtra: {
    marginLeft: 10,
    color: '#666666',
  },
  selectField: {
    width: 200,
  },
});

const ProjectLogoSettingsModal: FunctionComponent<Props> = ({
  value,
  isVisible,
  fieldLabel,
  onCancel,
  onSubmit,
}: Props) => {
  const [selectedLogo, setSelectedLogo] = useState<Asset>();
  const [displayLogoOnSlides, setDisplayLogoOnSlides] = useState<boolean>();
  const [logoPosition, setLogoPosition] = useState<ProjectLogoPosition>();
  const [logoSize, setLogoSize] = useState<ProjectLogoSize>();

  const permissionToUpdateLogo = useCanAccess(PermissionList.LOGO);
  const permissionToDisplayLogoOnSlides = useCanAccess(PermissionList.LOGO_INCLUDE_SLIDES);
  const permissionToEditLogoPosition = useCanAccess(PermissionList.LOGO_POSITION);
  const permissionToEditLogoSize = useCanAccess(PermissionList.LOGO_SIZE);

  const { t } = useTranslation();
  const classes = useStyles();

  const leftColumnLayoutProps = { md: 8, lg: 8 };
  const rightColumnLayoutProps = { md: 24 - leftColumnLayoutProps.md, lg: 24 - leftColumnLayoutProps.lg };

  useEffect(() => {
    setSelectedLogo(value?.logoAsset);
    setDisplayLogoOnSlides(
      ProjectUtils.getPermissionForcedLogoDisplayMode(value?.displayMode, permissionToDisplayLogoOnSlides) ===
        ProjectLogoDisplayMode.ALL_SCENES
    );
    setLogoPosition(
      ProjectUtils.getPermissionForcedLogoPositionCode(value?.positionCode, permissionToEditLogoPosition)
    );
    setLogoSize(ProjectUtils.getPermissionForcedLogoSize(value?.size, permissionToEditLogoSize));
  }, [value, isVisible]);

  useEffect(() => {
    if (permissionToUpdateLogo.hasUserAccess()) {
      return;
    }

    // This effect is here in case the manager decided to change the permissions.
    onCancel();
  }, [permissionToUpdateLogo]);

  useEffect(() => {
    if (!permissionToDisplayLogoOnSlides.forcedValue && permissionToDisplayLogoOnSlides.hasUserAccess()) {
      return;
    }

    // This effect is here in case the manager decided to change the permissions.
    setDisplayLogoOnSlides(
      ProjectUtils.getPermissionForcedLogoDisplayMode(undefined, permissionToDisplayLogoOnSlides) ===
        ProjectLogoDisplayMode.ALL_SCENES
    );
  }, [permissionToDisplayLogoOnSlides]);

  const onSelectLogo = (asset?: Asset) => {
    setSelectedLogo(asset);
  };

  const onDisplayLogoOnSlidesChange = () => {
    setDisplayLogoOnSlides((prev) => !prev);
  };

  const onLogoPositionChange = (logoPositionCode: ProjectLogoPosition) => {
    setLogoPosition(logoPositionCode);
  };

  const onLogoSizeChange = (logoSizeCode: ProjectLogoSize) => {
    setLogoSize(logoSizeCode);
  };

  const submitForm = () => {
    // Reset the project logo
    if (!selectedLogo) {
      onSubmit(undefined);
      return;
    }

    onSubmit({
      logoAsset: selectedLogo,
      displayMode: displayLogoOnSlides ? ProjectLogoDisplayMode.ALL_SCENES : ProjectLogoDisplayMode.RECORDABLE_ONLY,
      positionCode: logoPosition,
      size: logoSize,
    });
  };

  const isSubmitButtonDisabled = () => {
    const displayMode = displayLogoOnSlides
      ? ProjectLogoDisplayMode.ALL_SCENES
      : ProjectLogoDisplayMode.RECORDABLE_ONLY;
    return (
      (!value && !selectedLogo) ||
      (value &&
        selectedLogo &&
        value.logoAsset.operationId === selectedLogo.operationId &&
        logoPosition === value.positionCode &&
        displayMode === value.displayMode &&
        logoSize === value.size)
    );
  };

  const renderLogoMediaSelectorField = () => {
    return (
      <Row className={classes.formFieldContainer}>
        <Col {...leftColumnLayoutProps} className={classes.fieldLabel}>
          <FaRegFileImage className={classes.fieldLabelIcon} />
          {t('charters.projects.projectEditor.projectFinalization.logo.logoFileLabel')}
        </Col>
        <Col {...rightColumnLayoutProps} className={classes.fieldSelectorColumn}>
          <AssetSelector
            fieldLabel={t('charters.projects.projectEditor.projectFinalization.logo.logoFileLabel')}
            assetType={AssetType.LOGO}
            onChange={onSelectLogo}
            value={selectedLogo}
          />
        </Col>
      </Row>
    );
  };

  const renderDisplayLogoOnSlidesSwitch = () => {
    return (
      <Row className={classes.formFieldContainer}>
        <Col {...leftColumnLayoutProps} className={classes.fieldLabel}>
          <MdOutlineHideImage className={classes.fieldLabelIcon} />
          {t('charters.projects.projectEditor.projectFinalization.logo.displayOnSlides')}
        </Col>

        <Col {...rightColumnLayoutProps} className={classes.fieldSelectorColumn}>
          <AccessChecker
            renderUnauthorizedMessage={permissionToDisplayLogoOnSlides.renderUnauthorizedMessage}
            hasAccess={permissionToDisplayLogoOnSlides.hasUserAccess()}
          >
            <Switch
              onChange={onDisplayLogoOnSlidesChange}
              checked={displayLogoOnSlides}
              disabled={!(selectedLogo && permissionToDisplayLogoOnSlides.hasUserAccess())}
            />
            <span className={classes.displayLogoOnSlidesExtra}>
              {displayLogoOnSlides
                ? t('charters.projects.projectEditor.projectFinalization.logo.visible')
                : t('charters.projects.projectEditor.projectFinalization.logo.hidden')}
            </span>
          </AccessChecker>
        </Col>
      </Row>
    );
  };

  const renderLogoPositionSelector = () => {
    return (
      <Row className={classes.formFieldContainer}>
        <Col {...leftColumnLayoutProps} className={classes.fieldLabel}>
          <MdFeaturedVideo className={classes.fieldLabelIcon} />
          {t('charters.projects.projectEditor.projectFinalization.logo.logoPosition')}
        </Col>
        <Col {...rightColumnLayoutProps} className={classes.fieldSelectorColumn}>
          <AccessChecker
            renderUnauthorizedMessage={permissionToEditLogoPosition.renderUnauthorizedMessage}
            hasAccess={permissionToEditLogoPosition.hasUserAccess()}
          >
            <Select
              onChange={onLogoPositionChange}
              value={logoPosition}
              className={classes.selectField}
              disabled={!(selectedLogo && permissionToEditLogoPosition.hasUserAccess())}
            >
              {map(ProjectLogoPositions, (position) => (
                <Select.Option key={position.code} value={position.code}>
                  {t(position.key)}
                </Select.Option>
              ))}
            </Select>
          </AccessChecker>
        </Col>
      </Row>
    );
  };

  const renderLogoSizeSelector = () => {
    return (
      <Row className={classes.formFieldContainer}>
        <Col {...leftColumnLayoutProps} className={classes.fieldLabel}>
          <MdPhotoSizeSelectLarge className={classes.fieldLabelIcon} />
          {t('charters.projects.projectEditor.projectFinalization.logo.logoSize')}
        </Col>
        <Col {...rightColumnLayoutProps} className={classes.fieldSelectorColumn}>
          <AccessChecker
            renderUnauthorizedMessage={permissionToEditLogoSize.renderUnauthorizedMessage}
            hasAccess={permissionToEditLogoSize.hasUserAccess()}
          >
            <Select
              onChange={onLogoSizeChange}
              value={logoSize}
              className={classes.selectField}
              disabled={!(selectedLogo && permissionToEditLogoSize.hasUserAccess())}
            >
              {map(ProjectLogoSizes, (size) => (
                <Select.Option key={size.code} value={size.code}>
                  {t(size.key)}
                </Select.Option>
              ))}
            </Select>
          </AccessChecker>
        </Col>
      </Row>
    );
  };

  return (
    <Modal
      title={fieldLabel}
      visible={isVisible}
      onCancel={onCancel}
      destroyOnClose
      footer={[
        <Button key="cancelModal" onClick={onCancel}>
          {t('global.cancel')}
        </Button>,
        <Button key="submit" onClick={submitForm} type="primary" disabled={isSubmitButtonDisabled()}>
          {t('global.submit')}
        </Button>,
      ]}
      className={classes.modal}
    >
      {renderLogoMediaSelectorField()}
      {renderDisplayLogoOnSlidesSwitch()}
      {renderLogoPositionSelector()}
      {renderLogoSizeSelector()}
    </Modal>
  );
};

export default ProjectLogoSettingsModal;
