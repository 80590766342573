import { ColumnProps } from 'antd/es/table';
import Fuse from 'fuse.js';
import { TFunction } from 'i18next';
import { findIndex } from 'lodash';
import React, { ReactElement, ReactNode } from 'react';
import Highlighter from 'react-highlight-words';
import FilterIcon from '../../../components/table/search-in-column-filter/FilterIcon';
import SearchInColumnFilter from '../../../components/table/search-in-column-filter/SearchInColumnFilter';
import { APIBundles } from '../../../services/api/types/BundlesServiceTypes';
import BundlesButtonActions from './BundlesButtonActions';

type ColumnsArgs = {
  bundles: APIBundles[];
  classes: Record<string, string>;
  t: TFunction;
  filter: Record<string, string> | undefined;
  setFilter: React.Dispatch<React.SetStateAction<Record<string, string> | undefined>>;
  filteredInfo: Record<string, string[] | null>;
};

interface ExtendedColumnProps<RecordType> extends ColumnProps<RecordType> {
  editable?: boolean;
}

export const buildBundlesTableColumns = ({
  bundles,
  t,
  classes,
  filter,
  setFilter,
  filteredInfo,
}: ColumnsArgs): ExtendedColumnProps<APIBundles>[] => {
  const fuseName = new Fuse(bundles, {
    keys: ['name'],
    threshold: 0.5,
  });

  return [
    {
      width: 120,
      render: (value, record: APIBundles): ReactElement => {
        return <BundlesButtonActions bundle={record} />;
      },
    },
    {
      title: t('admin.bundles.name'),
      dataIndex: 'name',
      key: 'bundles_name',
      filteredValue: filteredInfo.bundles_name || null,
      filterDropdown: (filterProps): ReactNode => (
        <SearchInColumnFilter
          {...filterProps}
          dataIndex="name"
          fuse={fuseName}
          placeholder={t('admin.bundles.searchOnColumnPlaceholder', {
            columnName: t('admin.bundles.name').toLowerCase(),
          })}
          setFilter={setFilter}
          possibleValues={bundles?.map((b) => b.name)}
        />
      ),
      filterIcon: FilterIcon,
      onFilter: (value, record: APIBundles): boolean => {
        const result = fuseName.search(value as any);
        const index = findIndex(result, ({ item }) => {
          return item.id === record.id;
        });

        return index >= 0;
      },

      render: (name: string): ReactElement => {
        const searchWords = filter && filter.name ? filter.name : '';
        return (
          <Highlighter
            className={classes.highlightedText}
            searchWords={[searchWords]}
            autoEscape
            textToHighlight={name}
          />
        );
      },
    },
  ];
};
