import { CancelTokenSource } from 'axios';
import { APIManager } from './APIManager';
import { APIGetFontFamiliesResponse } from './types/FontFamiliesServiceTypes';

const getPublicFontFamilies = (cancelSource?: CancelTokenSource): Promise<APIGetFontFamiliesResponse> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(`/fontfamilies`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

export { getPublicFontFamilies };
