import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { createUseStyles } from 'react-jss';
import { THEME } from '../../../../../../../../Constants';
import AnimationsUtils from '../../../../../../../../utils/AnimationsUtils';
import ProjectUtils from '../../../../../../../../utils/ProjectUtils';
import {
  ProjectSceneAnimation,
  ProjectSceneElementsCode,
  ProjectSceneMedia,
} from '../../../../../../../../utils/types/ProjectTypes';
import { VideoTrim, VideoTrimUtils } from '../../utils/VideoTrimUtils';
import SceneTimelineGrayOverlay from '../SceneTimelineGrayOverlay';

type Props = {
  animation: ProjectSceneAnimation;
  backgroundVideo?: ProjectSceneMedia;
  pip?: ProjectSceneMedia;
  width: number;
  isSelected?: boolean;
  intervalTickWidth: number;
  videoTrimValue: VideoTrim;
  pipTrimValue: VideoTrim;
  onAnimationDelayChange: (newDelay: number) => void;
  onClick: () => void;
};

type StyleProps = {
  width: number;
  intervalTickWidth: number;
  isSelected: boolean;
};

const useStyles = createUseStyles({
  draggableAnimationBar: {
    display: 'flex',
    height: '100%',
    width: '100%',
    '& .react-draggable-dragging': {
      cursor: 'move !important',
    },
  },
  videoAnimationBar: ({ width, isSelected }: StyleProps) => ({
    position: 'absolute',
    zIndex: 2,
    width,
    background: THEME.PROJECTS.EDITOR.TIMELINE.ELEMENT_BACKGROUND,
    height: '100%',
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
    border: isSelected ? `6px solid ${THEME.PROJECTS.EDITOR.TIMELINE.SELECTED_ELEMENT_BORDER}` : undefined,
    cursor: 'pointer',
  }),
  animationText: ({ isSelected }: StyleProps) => ({
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    margin: isSelected ? '0 4px' : '0 10px',
    color: 'white',
    fontFamily: 'SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace',
    fontSize: 12,
  }),
  inactiveAnimationBar: {
    opacity: '0.3',
    background: `repeating-linear-gradient(45deg, #606dbc, #606dbc 10px, #465298 10px, #465298 20px) !important`,
  },
});

const SceneAnimationBar: FunctionComponent<Props> = ({
  animation,
  pip,
  backgroundVideo,
  width,
  isSelected = false,
  intervalTickWidth,
  videoTrimValue,
  onAnimationDelayChange,
  onClick,
  pipTrimValue,
}: Props) => {
  const trimStart = VideoTrimUtils.getMinTrimStartForPosition(videoTrimValue, pipTrimValue);
  const isSlideAnimation = AnimationsUtils.isSlideAnimation(animation.code);
  // Width in px for 1 second
  const delay = animation.delay ?? 0;
  const delayWidth = (delay + trimStart) * intervalTickWidth;

  const classes = useStyles({
    width,
    isSelected,
    delayWidth,
  });

  const isActive = animation.active;

  const calculateLeftBoundPosition = () => {
    return trimStart * intervalTickWidth;
  };

  const calculateRightBoundPosition = () => {
    return videoTrimValue.trimEnd * intervalTickWidth;
  };

  const onDragStop = (event: DraggableEvent, data: DraggableData) => {
    onAnimationDelayChange(data.x / intervalTickWidth - trimStart);
  };

  const calculatePositionOnX = () => {
    if (delayWidth < videoTrimValue.trimStart * intervalTickWidth) {
      return videoTrimValue.trimStart * intervalTickWidth;
    }

    if (delayWidth > videoTrimValue.trimEnd * intervalTickWidth) {
      return videoTrimValue.trimEnd * intervalTickWidth;
    }

    return delayWidth;
  };

  return (
    <div className={classes.draggableAnimationBar}>
      <SceneTimelineGrayOverlay
        element={ProjectSceneElementsCode.ANIMATION}
        backgroundVideo={backgroundVideo}
        pip={pip}
        animation={animation}
        videoTrimValue={videoTrimValue}
        intervalTickWidth={intervalTickWidth}
      >
        <Draggable
          disabled={isSlideAnimation || !isSelected}
          axis="x"
          position={{
            x: calculatePositionOnX(),
            y: 0,
          }}
          bounds={{
            left: calculateLeftBoundPosition(),
            top: 0,
            right: calculateRightBoundPosition(),
            bottom: 0,
          }}
          onStart={onClick}
          onStop={onDragStop}
        >
          <div
            className={classNames(classes.videoAnimationBar, !isActive && classes.inactiveAnimationBar)}
            onClick={onClick}
          >
            <span className={classes.animationText}>{ProjectUtils.joinAnimationTexts(animation.animationTexts)}</span>
          </div>
        </Draggable>
      </SceneTimelineGrayOverlay>
    </div>
  );
};

export default SceneAnimationBar;
