import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined, LinkOutlined } from '@ant-design/icons';
import { Alert, Button, Descriptions, Empty, message, Progress, Tag, Tooltip } from 'antd';
import axios, { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { BiCopy, BiLinkExternal } from 'react-icons/bi';
import { createUseStyles } from 'react-jss';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import AccessChecker from '../../../../../../components/access-checker/AccessChecker';
import Preformatted from '../../../../../../components/preformatted/Preformatted';
import { DEVICE_SIZES_QUERIES, LOGGING_EVENT } from '../../../../../../Constants';
import useCanAccess from '../../../../../../hooks/useCanAccess';
import { RootState } from '../../../../../../redux/RootState';
import { APIManager } from '../../../../../../services/api/APIManager';
import { shareCharterProjectPublicly } from '../../../../../../services/api/ChartersService';
import { downloadFile } from '../../../../../../services/api/GlobalService';
import FileUtils from '../../../../../../utils/FileUtils';
import MediaUtils from '../../../../../../utils/MediaUtils';
import ProjectUtils from '../../../../../../utils/ProjectUtils';
import TimeUtils from '../../../../../../utils/TimeUtils';
import { PermissionList } from '../../../../../../utils/types/CharterPermissionTypes';
import { Project, ProjectFinalVideo, ProjectStatus } from '../../../../../../utils/types/ProjectTypes';

type StyleProps = {
  project: Project;
};

const useStyles = createUseStyles({
  descriptions: {
    '& .ant-descriptions-header': {
      marginBottom: 10,
    },
    '& .ant-descriptions-item': {
      paddingBottom: 6,
    },
    '& .ant-descriptions-item-content': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .ant-descriptions-item-label': {
      fontWeight: 600,
    },
    '& .ant-descriptions-item-label::after': {
      content: 'none',
    },
    marginBottom: 10,
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      '& .ant-descriptions-item': {
        paddingBottom: '0px !important',
      },
      '& .ant-descriptions-row:nth-child(2n)': {
        '& .ant-descriptions-item': {
          paddingBottom: '8px !important',
        },
      },
    },
  },
  videoFinalPlayerContainer: ({ project }: StyleProps) => {
    const { finalVideo } = project;
    let widthOnHeightRatio = 0;
    if (finalVideo && finalVideo.width && finalVideo.height) {
      widthOnHeightRatio = finalVideo.width / finalVideo.height;
    }
    // Landscape case (16/9 > 1)
    let playerWidth = '90%';
    // Portrait case (9/16 < 1)
    if (widthOnHeightRatio < 1) {
      playerWidth = '60%';
    } else if (widthOnHeightRatio === 1) {
      // Square case (1/1 = 1)
      playerWidth = '70%';
    }

    return {
      width: playerWidth,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxHeight: '65vh',
      overflow: 'hidden',
      [`@media screen and ${DEVICE_SIZES_QUERIES.INTERMEDIATE}`]: {
        width: '85%',
        margin: 'auto',
        marginBottom: 8,
        maxHeight: 'unset',
      },
      '& video': {
        borderRadius: 5,
      },
    };
  },
  videoFinalPlayerCard: {
    display: 'flex',
    justifyContent: 'center',
    [`@media screen and ${DEVICE_SIZES_QUERIES.INTERMEDIATE}`]: {
      flexDirection: 'column',
    },
  },
  videoFinalPlayerMetadataContainer: {
    width: '100%',
    display: 'flex',
    marginLeft: 32,
    flexDirection: 'column',
    justifyContent: 'center',
    [`@media screen and ${DEVICE_SIZES_QUERIES.INTERMEDIATE}`]: {
      marginLeft: 8,
    },
  },
  pageDescription: {
    marginBottom: 16,
    textAlign: 'justify',
  },
  sharedLinkContainer: {
    paddingTop: '20px !important',
  },
  sharedLinkTitle: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  },
  sharedLinkTooltip: {
    marginLeft: 4,
    fontSize: 12,
  },
  sharedLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *:not(:last-child)': {
      marginRight: 4,
    },
  },
  sharedLinkPreformatted: {
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      maxWidth: '75%',
    },
  },
  expiredSharedLink: {
    opacity: 0.5,
    textDecoration: 'line-through',
  },
  generateLinkButton: {
    paddingLeft: 0,
  },
  copyToClipboardSharedLink: {
    cursor: 'pointer',
  },
  openExternalLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#000000',
    '&:hover': {
      color: '#000000',
    },
  },
  sharedLinkExpirationStatusTag: {
    border: 'none',
  },
  disabledLinkAction: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  downloadButtonContainer: {
    paddingTop: '30px !important',
  },
  progressBar: {
    marginLeft: 20,
    width: 200,
  },
  lastFinalizationAlert: {
    marginBottom: '20px',
    textAlign: 'justify',
  },
});

type Props = {
  project: Project;
  onRefinalizeClick?: () => void;
};

const ProjectDetails: FunctionComponent<Props> = ({ project, onRefinalizeClick }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles({ project });
  const [finalVideo, setFinalVideo] = useState<ProjectFinalVideo>();
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState<number>();
  const [isPublicLinkGenerationLoading, setIsPublicLinkGenerationLoading] = useState(false);

  const permissionToShareProjectPublicly = useCanAccess(PermissionList.PROJECT_SHARE_PUBLICLY);
  const permissionToDownloadProject = useCanAccess(PermissionList.PROJECT_DOWNLOAD);
  const permissionToFinalizeProject = useCanAccess(PermissionList.PROJECT_FINALIZE);

  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());

  const charter = useSelector((state: RootState) => state.charters.current);
  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);

  const isBackupedProject = project && project.status === ProjectStatus.BACKUPED.code;

  const currentCharter = useSelector((state: RootState) => state.charters.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);
  const currentCompany = useSelector((state: RootState) => state.companies.current);
  const currentUser = useSelector((state: RootState) => state.user);

  useEffect(() => {
    const cancelTokenSource = cancelTokenSourceRef.current;

    return (): void => {
      cancelTokenSource.cancel('Final video download cancelled due to unmounting');
    };
  }, []);

  useEffect(() => {
    setFinalVideo(project.finalVideo);
  }, [project]);

  const onDownloadProgress = (percentage: number) => {
    setDownloadProgress(Math.floor(percentage * 100));
  };

  const onDownloadFileClick = () => {
    if (!project.finalVideo) {
      return;
    }

    setIsDownloadLoading(true);
    const cancelTokenSource = cancelTokenSourceRef.current;

    downloadFile(project.finalVideo.url, project.finalVideo.publicName, onDownloadProgress, cancelTokenSource)
      .catch((e) => {
        if (axios.isCancel(e)) {
          log.info('Final video downloading cancelled');
        } else {
          log.error(e);
        }
      })
      .finally(() => {
        setIsDownloadLoading(false);
        setDownloadProgress(undefined);
      });
  };

  const onGenerateSharedLink = () => {
    if (!(charter && finalVideo)) {
      return;
    }

    setIsPublicLinkGenerationLoading(true);

    // Analytics
    loggingManager.logEvent(LOGGING_EVENT.GENERATE_PUBLIC_LINK, {
      charterId: currentCharter?.id,
      companyId: currentCompany?.id,
      companyCheckoutChannel: currentCompany?.checkoutChannel,
      companyName: currentCompany?.name,
      charterName: currentCharter?.name,
      companySubscriptionLastStatus: currentCompany?.chargebeeSubscriptionLastStatus,
      userActiveInCharter: true,
      userRoleInCharter: currentUser.role?.currentRole,
      projectId: project?.projectUuid,
    });

    shareCharterProjectPublicly(charter.id, project.id)
      .then((response) => {
        setFinalVideo(response.finalVideo);
      })
      .finally(() => {
        setIsPublicLinkGenerationLoading(false);
      });
  };

  const renderSharedLinkUrl = (url: string, isExpired = false) => {
    const onCopySharedLinkUrlHandle = () => {
      // Analytics
      loggingManager.logEvent(LOGGING_EVENT.COPY_PUBLIC_LINK, {
        charterId: currentCharter?.id,
        companyId: currentCompany?.id,
        companyCheckoutChannel: currentCompany?.checkoutChannel,
        companyName: currentCompany?.name,
        charterName: currentCharter?.name,
        companySubscriptionLastStatus: currentCompany?.chargebeeSubscriptionLastStatus,
        userActiveInCharter: true,
        userRoleInCharter: currentUser.role?.currentRole,
        projectId: project?.projectUuid,
      });

      message.info(t('global.copiedToClipboard'));
    };

    const onOpenSharedLinkUrlHandle = () => {
      // Analytics
      loggingManager.logEvent(LOGGING_EVENT.OPEN_PUBLIC_LINK, {
        charterId: currentCharter?.id,
        companyId: currentCompany?.id,
        companyCheckoutChannel: currentCompany?.checkoutChannel,
        companyName: currentCompany?.name,
        charterName: currentCharter?.name,
        companySubscriptionLastStatus: currentCompany?.chargebeeSubscriptionLastStatus,
        userActiveInCharter: true,
        userRoleInCharter: currentUser.role?.currentRole,
        projectId: project?.projectUuid,
      });
    };

    return (
      <span className={classes.sharedLink}>
        <Preformatted className={classNames(classes.sharedLinkPreformatted, isExpired && classes.expiredSharedLink)}>
          {url}
        </Preformatted>
        {/* Copy to clipboard */}
        {!isExpired ? (
          <Tooltip
            title={t('global.clickCopyToClipboard')}
            placement="top"
            className={classes.copyToClipboardSharedLink}
          >
            <CopyToClipboard text={url} onCopy={onCopySharedLinkUrlHandle}>
              <BiCopy />
            </CopyToClipboard>
          </Tooltip>
        ) : (
          <BiCopy className={classes.disabledLinkAction} />
        )}

        {/* Open external link */}
        {!isExpired ? (
          <Tooltip title={t('global.openInNewTab')} placement="top">
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.openExternalLink}
              onClick={onOpenSharedLinkUrlHandle}
            >
              <BiLinkExternal />
            </a>
          </Tooltip>
        ) : (
          <BiLinkExternal className={classes.disabledLinkAction} />
        )}
      </span>
    );
  };

  const renderSharedLinkGenerationButton = (isRegeneration = false) => {
    return (
      <AccessChecker
        renderUnauthorizedMessage={permissionToShareProjectPublicly.renderUnauthorizedMessage}
        hasAccess={permissionToShareProjectPublicly.hasUserAccess()}
      >
        <Button
          onClick={onGenerateSharedLink}
          type="link"
          className={classes.generateLinkButton}
          icon={<LinkOutlined />}
          loading={isPublicLinkGenerationLoading}
          disabled={isPublicLinkGenerationLoading || !permissionToShareProjectPublicly.hasUserAccess()}
        >
          {t(
            isRegeneration
              ? 'charters.projects.projectInfo.regenerateLink'
              : 'charters.projects.projectInfo.generateLink'
          )}
        </Button>
      </AccessChecker>
    );
  };

  const renderSharedLink = () => {
    // If the project has not been shared publicly yet
    if (!finalVideo?.publicAccessCode || !finalVideo.publicAccessExpiresAt) {
      return renderSharedLinkGenerationButton();
    }

    const publicUrl =
      finalVideo?.publicAccessCode && ProjectUtils.buildProjectPubliclySharedUrlFromCode(finalVideo.publicAccessCode);

    // Fail-safe condition: if a publicAccessCode is defined, the publicAccessExpiresAt should be defined too
    if (!(finalVideo.publicAccessCode && finalVideo.publicAccessExpiresAt && publicUrl)) {
      return null;
    }

    // If there is already a shared code which is still valid
    if (TimeUtils.isDateInFuture(finalVideo.publicAccessExpiresAt)) {
      return (
        <div>
          {renderSharedLinkUrl(publicUrl)}
          <Tag icon={<CheckCircleOutlined />} color="success" className={classes.sharedLinkExpirationStatusTag}>
            {t('charters.projects.projectInfo.shareLinkValidUntil', {
              date: TimeUtils.formatDateAsYearMonthDayHourMinute(finalVideo.publicAccessExpiresAt),
            })}
          </Tag>
        </div>
      );
    }

    // If there is a shared code which has expired
    return (
      <div>
        {renderSharedLinkGenerationButton(true)}
        {renderSharedLinkUrl(publicUrl, true)}
        <Tag icon={<CloseCircleOutlined />} color="error" className={classes.sharedLinkExpirationStatusTag}>
          {t('charters.projects.projectInfo.shareLinkExpiredSince', {
            date: TimeUtils.formatDateAsYearMonthDayHourMinute(finalVideo.publicAccessExpiresAt),
          })}
        </Tag>
      </div>
    );
  };

  const renderDisclaimerAlert = () => {
    const { updatedAt, lastFinalizedAt } = project;

    // If the project has evolved since last finalization, display a message explaining that the final video might not be up-to-date
    const displayLastFinalizationPossiblyOutdatedAlert =
      lastFinalizedAt && TimeUtils.isDateAfterOtherDate(updatedAt, lastFinalizedAt, { ignoreMillis: true });

    if (!displayLastFinalizationPossiblyOutdatedAlert) {
      return null;
    }

    const hasCallToActionButton = onRefinalizeClick !== undefined;

    return (
      <Alert
        className={classes.lastFinalizationAlert}
        type="info"
        showIcon
        message={
          isBackupedProject ? (
            <div>
              {t('charters.projects.projectInfo.lastBackupedVersionDisclaimer1')}
              {hasCallToActionButton ? <br /> : ' '}
              {t('charters.projects.projectInfo.lastBackupedVersionDisclaimer2')}
            </div>
          ) : (
            <div>
              {t('charters.projects.projectInfo.lastFinalizedVersionDisclaimer1')}
              {hasCallToActionButton ? <br /> : ' '}
              {t('charters.projects.projectInfo.lastFinalizedVersionDisclaimer2')}
            </div>
          )
        }
        action={
          hasCallToActionButton ? (
            <AccessChecker
              renderUnauthorizedMessage={permissionToFinalizeProject.renderUnauthorizedMessage}
              hasAccess={permissionToFinalizeProject.hasUserAccess()}
            >
              <Button type="link" onClick={onRefinalizeClick} disabled={!permissionToFinalizeProject.hasUserAccess()}>
                {t('charters.projects.projectInfo.refinalizeProject')}
              </Button>
            </AccessChecker>
          ) : undefined
        }
      />
    );
  };

  if (!(project && project.finalVideo && project.finalVideo.url)) {
    return <Empty description={t('global.nodata')} />;
  }

  if (!finalVideo) {
    return null;
  }

  return (
    <div>
      {renderDisclaimerAlert()}

      <div className={classes.videoFinalPlayerCard}>
        <div className={classes.videoFinalPlayerContainer}>
          <ReactPlayer url={MediaUtils.getMediaUrl(finalVideo)} controls width="inherit" height="inherit" />
        </div>

        <Descriptions
          column={1}
          title={t('charters.projects.projectInfo.finalVideoMetadata')}
          className={classNames(classes.descriptions, classes.videoFinalPlayerMetadataContainer)}
          size="small"
          layout={isMobileOrTablet ? 'vertical' : 'horizontal'}
        >
          <Descriptions.Item label={<Tag>{t('charters.projects.projectInfo.createdAt')}</Tag>}>
            {TimeUtils.formatDateAsYearMonthDayHourMinute(finalVideo.createdAt)}
          </Descriptions.Item>
          {project.lastFinalizedAt && (
            <Descriptions.Item label={<Tag>{t('charters.projects.projectInfo.lastFinalizedAt')}</Tag>}>
              {TimeUtils.formatDateAsYearMonthDayHourMinute(project.lastFinalizedAt)}
            </Descriptions.Item>
          )}
          {finalVideo.duration && (
            <Descriptions.Item
              label={<Tag>{t('charters.projects.projectInfo.duration')}</Tag>}
            >{`${TimeUtils.formatSecondsIntoHumanTimeString(finalVideo.duration, t, {
              withMillis: true,
            })}`}</Descriptions.Item>
          )}
          <Descriptions.Item
            label={<Tag>{t('charters.projects.projectInfo.dimensions')}</Tag>}
          >{`${finalVideo.width} × ${finalVideo.height}`}</Descriptions.Item>
          {finalVideo.size && (
            <Descriptions.Item label={<Tag>{t('charters.projects.projectInfo.fileSize')}</Tag>}>
              {FileUtils.formatBytesIntoHumanReadableSize(finalVideo.size)}
            </Descriptions.Item>
          )}
          <Descriptions.Item className={classes.sharedLinkContainer}>
            <div>
              <div className={classes.sharedLinkTitle}>
                {t('charters.projects.projectInfo.shareLinkTitle')}{' '}
                <Tooltip
                  placement="top"
                  title={
                    isBackupedProject
                      ? t('charters.projects.projectInfo.sharedLinkBackupedTooltip')
                      : t('charters.projects.projectInfo.sharedLinkTooltip')
                  }
                >
                  <span className={classes.sharedLinkTooltip}>
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              </div>
              {renderSharedLink()}
            </div>
          </Descriptions.Item>

          <Descriptions.Item className={classes.downloadButtonContainer}>
            <AccessChecker
              hasAccess={permissionToDownloadProject.hasUserAccess()}
              renderUnauthorizedMessage={permissionToDownloadProject.renderUnauthorizedMessage}
            >
              <Button
                onClick={onDownloadFileClick}
                loading={isDownloadLoading}
                type="primary"
                disabled={!permissionToDownloadProject.hasUserAccess()}
              >
                {t('global.download')}
              </Button>
            </AccessChecker>

            {downloadProgress !== undefined && downloadProgress >= 0 && (
              <Progress percent={downloadProgress} status="active" size="small" className={classes.progressBar} />
            )}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
};

export default ProjectDetails;
