import { Form, message, Modal } from 'antd';
import { find, findIndex } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Role } from '../../core/rule/Roles';
import { Roles } from '../../core/rule/RolesTypes';
import { RootState } from '../../redux/RootState';
import { postUserInCharter } from '../../services/api/ChartersService';
import { APIModelCharter } from '../../services/api/types/ChartersServiceTypes';
import { APICompanyUsers } from '../../services/api/types/CompaniesServiceTypes';
import { APICreateUserParams, APIModelUserEnriched } from '../../services/api/types/UsersServiceTypes';
import RoleRadioGroup from '../form/RoleRadioGroup';
import CharterMultiSelect from '../select/CharterMultiSelect';

type Props = {
  visible: boolean;
  user: APICompanyUsers;
  callBackHideModal: () => void;
  callbackLinkUserToCharter: (isLoading: boolean, data?: APIModelUserEnriched, charter?: APIModelCharter) => void;
};

const LinkCharterModal: FunctionComponent<Props> = ({
  visible,
  callBackHideModal,
  callbackLinkUserToCharter,
  user,
}: Props) => {
  const { t } = useTranslation();
  const [selectedCharters, setSelectedCharters] = useState<number[]>([]);
  const [role, setRole] = useState(Roles.Creator);
  const [form] = Form.useForm();
  const charterList = useSelector((state: RootState) => state.charters.list);
  const companyStats = useSelector((state: RootState) => state.companies.stats);

  // Todo to remove when open to other users
  const currentUserRole = new Role(Roles.KnlTeam);

  if (!companyStats) {
    return null;
  }

  const handleChange = (value: number[]): void => {
    setSelectedCharters(value);
  };

  const hideModal = (): void => {
    setSelectedCharters([]);
    callBackHideModal();
  };

  const handleSubmit = (): void => {
    const isUserPresentAndActive =
      find(user.charters, (charter) => {
        return charter.currentUser.isActive;
      }) !== undefined;

    const canLinkUserToCharters = companyStats.canLinkUserToCharters(isUserPresentAndActive);

    if (!canLinkUserToCharters) {
      message.error(t('global.maxNbLicensesReachedError', { count: companyStats?.users.max }));
      return;
    }

    selectedCharters.forEach((charterId) => {
      const data: APICreateUserParams = {
        id: user.id,
        email: user.email,
        name: user.name,
        isActive: true,
        charterId,
        role,
      };

      callbackLinkUserToCharter(true);

      postUserInCharter(data).then((response) => {
        const charter = find(charterList, { id: charterId });
        callbackLinkUserToCharter(false, response.data, charter);
      });
    });

    setSelectedCharters([]);

    callBackHideModal();
  };

  const filteredCharterList = charterList?.filter((charter) => {
    return findIndex(user.charters, { id: charter.id }) < 0;
  });

  const handleRoleChange = (value: Roles): void => {
    setRole(value);
  };

  return (
    <Modal
      title={t('users.linkToCharters.title')}
      centered
      visible={visible}
      onOk={handleSubmit}
      onCancel={hideModal}
      okText={t('global.ok')}
      cancelText={t('global.close')}
      okButtonProps={{ disabled: selectedCharters.length === 0 }}
    >
      <div>
        <Form
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{
            role: Roles.Creator,
          }}
        >
          <p>{t('users.linkToCharters.select')}</p>
          <CharterMultiSelect
            selectedCharters={selectedCharters}
            charterList={filteredCharterList}
            handleChange={handleChange}
          />

          <RoleRadioGroup
            key="newUserRole"
            role={role}
            defaultRole={Roles.Creator}
            visibleRoles={currentUserRole.getAccessibleRoles()}
            label={t('users.fields.role')}
            callbackHandleChange={handleRoleChange}
            hasFeedback={false}
            noRules
          />
        </Form>
      </div>
    </Modal>
  );
};

export default LinkCharterModal;
