import { Card, Col, Row } from 'antd';
import React, { FunctionComponent } from 'react';
import { createUseStyles } from 'react-jss';
import { APIBundles } from '../../../services/api/types/BundlesServiceTypes';
import BundlesButtonActions from './BundlesButtonActions';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'space-between',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  col: {
    '& .ant-card-head-title div': {
      wordBreak: 'break-word',
      whiteSpace: 'normal',
    },
  },
  paragraph: {
    marginBottom: 8,
  },
  card: {
    height: '100%',
  },
});

type Props = {
  data: APIBundles[];
};

const BundlesCardList: FunctionComponent<Props> = ({ data }: Props) => {
  const classes = useStyles();

  return (
    <div>
      <Row gutter={[8, 8]}>
        {data.map((bundle) => {
          return (
            <Col className={classes.col} xs={12} sm={8} md={8} lg={6} key={`bundle-card-${bundle.id}`}>
              <Card className={classes.card} title={<div>{bundle.name}</div>}>
                <BundlesButtonActions bundle={bundle} />
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default BundlesCardList;
