import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationMenuItem } from '../../services/navigation/NavigationMenuItem';
import useStyles from './KannelleMenuStyle';

type Props = {
  route: NavigationMenuItem;
  iconClassName?: string;
};

const MenuSectionTitle: FunctionComponent<Props> = ({ route, iconClassName }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const Icon = route.icon ? <route.icon className={classNames(classes.treeMenuItemIcon, iconClassName)} /> : null;
  const title = route.title ?? '';

  return (
    <span className={classes.treeMenuItem}>
      {Icon}
      {t(title)}
    </span>
  );
};

export default MenuSectionTitle;
