import React, { FunctionComponent } from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

type Props = IContentLoaderProps;

const SceneTimelineSkeleton: FunctionComponent = (props: Props) => (
  <ContentLoader viewBox="0 0 400 100" height="100%" width="100%" {...props}>
    {/* Play/pause button */}
    <circle cx="157" cy="16" r="8.5" />

    {/* Current time / duration */}
    <circle cx="179" cy="16" r="8" />
    <rect x="177" y="8" width="50" height="16" />
    <circle cx="227" cy="16" r="8" />

    {/* Seek bar */}
    <rect x="90" y="38" width="2" height="60" />
    <path
      transform="translate(94, 34) scale(0.35) rotate(90)"
      r="30"
      d="M2.067,0.043C2.21-0.028,2.372-0.008,2.493,0.085l13.312,8.503c0.094,0.078,0.154,0.191,0.154,0.313
			c0,0.12-0.061,0.237-0.154,0.314L2.492,17.717c-0.07,0.057-0.162,0.087-0.25,0.087l-0.176-0.04
			c-0.136-0.065-0.222-0.207-0.222-0.361V0.402C1.844,0.25,1.93,0.107,2.067,0.043z"
    />
    <rect x="87.8" y="30" rx="2" ry="2" width="6" height="6" />

    {/* Time intervals */}
    <rect x="34" y="28" width="2" height="8" />
    <rect x="50" y="28" width="2" height="4" />
    <rect x="66" y="28" width="2" height="4" />
    <rect x="82" y="28" width="2" height="4" />
    <rect x="98" y="28" width="2" height="4" />
    <rect x="114" y="28" width="2" height="8" />
    <rect x="130" y="28" width="2" height="4" />
    <rect x="146" y="28" width="2" height="4" />
    <rect x="162" y="28" width="2" height="4" />
    <rect x="178" y="28" width="2" height="4" />
    <rect x="194" y="28" width="2" height="8" />
    <rect x="210" y="28" width="2" height="4" />
    <rect x="226" y="28" width="2" height="4" />
    <rect x="242" y="28" width="2" height="4" />
    <rect x="258" y="28" width="2" height="4" />
    <rect x="274" y="28" width="2" height="8" />
    <rect x="290" y="28" width="2" height="4" />
    <rect x="306" y="28" width="2" height="4" />
    <rect x="322" y="28" width="2" height="4" />
    <rect x="338" y="28" width="2" height="4" />
    <rect x="354" y="28" width="2" height="8" />
    <rect x="370" y="28" width="2" height="4" />

    {/* Animation row */}
    <circle cx="10" cy="50" r="6" />
    <rect x="34" y="44" rx="3" ry="3" width="140" height="12" />

    {/* Video row */}
    <circle cx="10" cy="70" r="6" />
    <rect x="34" y="64" rx="3" ry="3" width="340" height="12" />

    {/* Subtitles row */}
    <circle cx="10" cy="90" r="6" />
    <rect x="64" y="84" rx="3" ry="3" width="50" height="12" />
    <rect x="154" y="84" rx="3" ry="3" width="30" height="12" />
    <rect x="194" y="84" rx="3" ry="3" width="40" height="12" />
    <rect x="274" y="84" rx="3" ry="3" width="60" height="12" />
  </ContentLoader>
);

export default SceneTimelineSkeleton;
