import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, message, Modal, Radio } from 'antd';
import { Store } from 'antd/lib/form/interface';
import classNames from 'classnames';
import map from 'lodash/map';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import AccessChecker from '../../../../../components/access-checker/AccessChecker';
import AnimationSelector from '../../../../../components/animation-selector/AnimationSelector';
import MediaSelector from '../../../../../components/media-selector/MediaSelector';
import useCanAccess from '../../../../../hooks/useCanAccess';
import { MediaFile } from '../../../../../services/api/types/ChartersServiceTypes';
import ProjectUtils from '../../../../../utils/ProjectUtils';
import { AnimationConfig } from '../../../../../utils/types/AnimationTypes';
import { PermissionList } from '../../../../../utils/types/CharterPermissionTypes';
import { MediaType } from '../../../../../utils/types/MediaTypes';
import { ProjectScene, ProjectSceneKind } from '../../../../../utils/types/ProjectTypes';
import { AnimationTheme } from '../../../../../utils/types/ThemeTypes';

type Props = {
  isProjectLoading: boolean;
  format?: string;
  theme: AnimationTheme;
  customColors: boolean;
  scenes: ProjectScene[];
  onAddScene: (addedScene: ProjectScene) => void;
};

const useStyles = createUseStyles({
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
  },
  addSceneButton: {
    cursor: 'pointer',
    height: '80px',
    maxWidth: 150,
    minWidth: 120,
    borderRadius: 8,
    whiteSpace: 'normal',
    '& span': {
      marginLeft: 'unset !important',
    },
  },
  form: {
    height: '100%',
  },
  radioGroupFormItem: {
    '& .ant-radio-group': {
      display: 'flex',

      '& label': {
        alignItems: 'center',
      },
    },
  },
});

const AddProjectSceneButton: FunctionComponent<Props> = ({
  isProjectLoading,
  format,
  theme,
  customColors,
  onAddScene,
  scenes,
}: Props) => {
  const permissionToAddScene = useCanAccess(PermissionList.SCENE_IMPORT);

  const [isVisible, setIsVisible] = useState(false);
  const [isAsyncLoading, setIsAsyncLoading] = useState(false);
  const [selectedSceneKind, setSelectedSceneKind] = useState(
    permissionToAddScene.hasUserAccess() ? ProjectSceneKind.RECORDABLE.code : ProjectSceneKind.SLIDE.code
  );
  const [selectedMedia, setSelectedMedia] = useState<MediaFile>();
  const [selectedAnimationConfig, setSelectedAnimationConfig] = useState<AnimationConfig>();

  const isFormDisabled = isAsyncLoading || (!selectedMedia && !selectedAnimationConfig);

  const [form] = Form.useForm();
  const classes = useStyles();
  const { t } = useTranslation();

  const formInitialValues = useMemo(
    () => ({
      kind: permissionToAddScene.hasUserAccess() ? ProjectSceneKind.RECORDABLE.code : ProjectSceneKind.SLIDE.code,
    }),
    []
  );

  useEffect(() => {
    if (!isVisible) {
      form.resetFields();
      setSelectedMedia(undefined);
      setSelectedAnimationConfig(undefined);
    }
  }, [isVisible]);

  useEffect(() => {
    form.resetFields(['media', 'animation']);
  }, [selectedSceneKind]);

  const onShowModal = (): void => {
    setIsVisible(true);
  };

  const onHideModal = (): void => {
    setIsVisible(false);
  };

  const onValuesChange = (changedValues: Store, allValues: Store): void => {
    setSelectedSceneKind(allValues.kind);
    setSelectedMedia(allValues.media);
    setSelectedAnimationConfig(allValues.animation);
  };

  const onSubmit = (): void => {
    form
      .validateFields()
      .then(() => {
        setIsAsyncLoading(true);
        // Create the base of the new scene model
        let newScene: ProjectScene | undefined;
        if (selectedSceneKind === ProjectSceneKind.SLIDE.code && selectedAnimationConfig) {
          newScene = ProjectUtils.buildDefaultSlideSceneWithAnimation(selectedAnimationConfig, scenes);
        } else if (selectedSceneKind === ProjectSceneKind.RECORDABLE.code && selectedMedia) {
          if (selectedMedia.mediaType === MediaType.IMAGE) {
            newScene = ProjectUtils.buildDefaultRecordableSceneWithPipImage(selectedMedia, scenes);
          } else {
            newScene = ProjectUtils.buildDefaultRecordableSceneWithBackgroundVideo(selectedMedia, scenes);
          }
        }

        if (newScene) {
          onAddScene(newScene);
        }
      })
      .catch((error) => {
        message.error(t('charters.projects.projectEditor.addSceneModal.error'));
        log.error(error);
      })
      .finally(() => {
        onHideModal();
        setIsAsyncLoading(false);
      });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  return (
    <>
      <div className={classes.buttonContainer}>
        <Button
          className={classNames(classes.addSceneButton, 'addSceneButton')}
          icon={<PlusOutlined />}
          onClick={onShowModal}
          disabled={isProjectLoading}
        >
          {t('charters.projects.projectEditor.addSceneModal.addScene')}
        </Button>
      </div>

      <Modal
        visible={isVisible}
        onCancel={onHideModal}
        title={t('charters.projects.projectEditor.addSceneModal.newSceneModalTitle')}
        cancelText={t('global.cancel')}
        okText={t('global.submit')}
        onOk={onSubmit}
        cancelButtonProps={{ disabled: isFormDisabled }}
        okButtonProps={{
          loading: isAsyncLoading,
          disabled: isFormDisabled,
        }}
      >
        <Form
          form={form}
          {...formItemLayout}
          initialValues={formInitialValues}
          validateMessages={{
            required: t('global.requiredField'),
          }}
          className={classes.form}
          onValuesChange={onValuesChange}
        >
          {/* kind */}
          <Form.Item
            name="kind"
            label={t('charters.projects.projectEditor.addSceneModal.sceneKindLabel')}
            rules={[{ required: true }]}
            className={classes.radioGroupFormItem}
          >
            <Radio.Group buttonStyle="solid">
              {map(ProjectSceneKind, (kind) => (
                <AccessChecker
                  hasAccess={kind.code === ProjectSceneKind.SLIDE.code ? true : permissionToAddScene.hasUserAccess()}
                  renderUnauthorizedMessage={permissionToAddScene.renderUnauthorizedMessage}
                  key={kind.code}
                >
                  <Radio.Button
                    value={kind.code}
                    disabled={kind.code === ProjectSceneKind.RECORDABLE.code && !permissionToAddScene.hasUserAccess()}
                  >
                    {t(kind.key)}
                  </Radio.Button>
                </AccessChecker>
              ))}
            </Radio.Group>
          </Form.Item>
          {selectedSceneKind && selectedSceneKind === ProjectSceneKind.RECORDABLE.code && (
            <Form.Item name="media" label={t('charters.projects.projectEditor.addSceneModal.mediaLabel')}>
              <MediaSelector
                fieldLabel={t('charters.projects.projectEditor.addSceneModal.mediaLabel')}
                format={format}
                defaultMediaType={MediaType.VIDEO}
                enableWebcamRecording
              />
            </Form.Item>
          )}

          {selectedSceneKind && selectedSceneKind === ProjectSceneKind.SLIDE.code && (
            <Form.Item name="animation" label={t('charters.projects.projectEditor.addSceneModal.animationLabel')}>
              <AnimationSelector
                fieldLabel={t('charters.projects.projectEditor.addSceneModal.animationLabel')}
                format={format}
                theme={theme}
                customColors={customColors}
                isSlide
                noReset
                hasSlide
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default AddProjectSceneButton;
