import classNames from 'classnames';
import React, { FunctionComponent, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { Project } from '../../../../../../../utils/types/ProjectTypes';
import ProjectScenePreview from '../../ProjectScenePreview';
import { ExtendedProjectScene } from '../hooks/useProjectFinalRenderData';

type Props = {
  project: Project;
  scene: ExtendedProjectScene;
  currentTime: number;
  seekTo?: number;
  isPlaying: boolean;
  isVisible: boolean;
  onIsPlayingChange: (newIsPlaying: boolean) => void;
  onEndReached: () => void;
  onVideoEnded?: () => void;
  onUpdateCurrentTime: (newTimeInProject: number) => void;
  onIsLoadingChange: (newIsLoading: boolean) => void;
};

const useStyles = createUseStyles({
  sceneContainer: {
    zIndex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  invisibleSceneContainer: {
    zIndex: 0,
    visibility: 'hidden',
  },
  playerWrapper: {
    background: '#F7F7F7',
  },
});

const ProjectFinalRenderScenePlayer: FunctionComponent<Props> = ({
  project,
  scene,
  currentTime,
  seekTo,
  isPlaying,
  isVisible,
  onIsPlayingChange,
  onEndReached,
  onVideoEnded,
  onUpdateCurrentTime,
  onIsLoadingChange,
}: Props) => {
  const classes = useStyles();

  // Compute the video trim value based on the scene's background video
  const videoTrimValue = useMemo(() => {
    return scene.backgroundVideo?.trimStartAt !== undefined && scene.backgroundVideo?.trimEndAt !== undefined
      ? { trimStart: scene.backgroundVideo?.trimStartAt, trimEnd: scene.backgroundVideo?.trimEndAt }
      : undefined;
  }, [scene]);

  // Callback called when the time updates in the visible scene. Based on the new time in the scene, we compute
  // the new time in the project.
  const onCurrentTimeChangeInScene = (newTimeInScene: number) => {
    const newTime = scene.absoluteStartAt + newTimeInScene - scene.relativeStartAt;

    if (currentTime < newTime) {
      onUpdateCurrentTime(newTime);
    }
  };

  return (
    <div className={classNames(classes.sceneContainer, !isVisible && classes.invisibleSceneContainer)}>
      <ProjectScenePreview
        scene={scene}
        format={project.videoFormat}
        isPlaying={isPlaying && isVisible}
        seekTo={seekTo}
        theme={project.theme}
        customColors={project.customColors}
        projectAudioVolume={project.audioVolume}
        mask={project.mask}
        logo={project.logo}
        wrapperClassName={classes.playerWrapper}
        videoTrimValue={videoTrimValue}
        onIsPlayingChange={onIsPlayingChange}
        onCurrentTimeChange={isVisible ? onCurrentTimeChangeInScene : undefined}
        onIsLoadingChange={isVisible ? onIsLoadingChange : undefined}
        onEndReached={isVisible && isPlaying ? onEndReached : undefined}
        onVideoEnded={onVideoEnded}
      />
    </div>
  );
};

export default ProjectFinalRenderScenePlayer;
