import axios, { CancelTokenSource } from 'axios';

const uploadFileToSignedUrl = (
  url: string,
  fileToUpload: any,
  onUploadProgress?: (progressEvent: any) => void
): Promise<any> => {
  return axios({
    method: 'PUT',
    url,
    data: fileToUpload,
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress,
  });
};

const downloadFile = async (
  urlAddress: string,
  publicName?: string,
  onProgress?: (percentage: number) => void,
  cancelSource?: CancelTokenSource
) => {
  return axios({
    url: urlAddress,
    method: 'GET',
    responseType: 'blob',
    cancelToken: cancelSource?.token || undefined,
    ...(onProgress && {
      onDownloadProgress: (progressEvent) => {
        const { loaded } = progressEvent;
        const { total } = progressEvent;
        onProgress(loaded / total);
      },
    }),
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    if (publicName) {
      link.setAttribute('download', publicName);
    }
    link.click();
    window.URL.revokeObjectURL(url);
  });
};

export { uploadFileToSignedUrl, downloadFile };
