import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import React, { FunctionComponent, ReactNode } from 'react';

type Props = {
  renderUnauthorizedMessage: () => void;
  hasAccess: boolean;
  children: JSX.Element[] | JSX.Element | ReactNode | string;
  placement?: TooltipPlacement;
};

const AccessChecker: FunctionComponent<Props> = ({
  renderUnauthorizedMessage,
  hasAccess,
  children,
  placement = 'top',
}: Props) => {
  return !hasAccess ? (
    <Tooltip title={renderUnauthorizedMessage} placement={placement}>
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
};

export default AccessChecker;
