import { CancelTokenSource } from 'axios';
import { APIManager } from './APIManager';
import { APIMusicsResult } from './types/MusicsServiceTypes';

const getPublicMusics = (cancelSource?: CancelTokenSource): Promise<APIMusicsResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(`/musics`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

export { getPublicMusics };
