import { DatabaseOutlined, FolderOpenOutlined, FontSizeOutlined, LoginOutlined } from '@ant-design/icons/lib';
import { Breadcrumb, Button, Card, Row } from 'antd';
import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import PageContentLoader from '../../../components/loader/PageContentLoader';
import { LINK, RouteLink, THEME } from '../../../Constants';
import useCanAccess from '../../../hooks/useCanAccess';
import useFetchCharterById from '../../../hooks/useFetchCharterById';
import { RootState } from '../../../redux/RootState';
import { CharterIdPathParam } from '../../../services/navigation/NavigationConfigTypes';
import CompaniesUtils from '../../../utils/CompaniesUtils';
import { PermissionList } from '../../../utils/types/CharterPermissionTypes';

const useStyles = createUseStyles({
  row: {
    margin: '10px !important',
  },
  card: {
    width: 300,
    marginLeft: 8,
    marginTop: 8,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    backgroundColor: THEME.DEFAULT.MAIN_COLOR,
    color: '#ffffff',
    borderRadius: 25,
    marginRight: 8,
    fontSize: 18,
    width: 24,
    height: 24,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px',
  },
  breadcrumb: {
    marginLeft: 20,
  },
});

type GraphicalElementPage = RouteLink & {
  icon: React.ElementType;
  descriptionKey: string;
};

const CharterGraphicalElements: FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { charterId } = useParams<CharterIdPathParam>();
  const location = useLocation();
  const history = useHistory();
  const { loading: isCharterLoading } = useFetchCharterById(parseInt(charterId, 10));
  const charter = useSelector((state: RootState) => state.charters.current);
  const companies = useSelector((state: RootState) => state.companies.list);

  const { hasUserAccessToCharter } = useCanAccess(PermissionList.WEB_DASHBOARD);

  const isKnlTeam = companies ? CompaniesUtils.checkIsKnlProfile(companies) : false;

  const charterGraphicalElementsPages: GraphicalElementPage[] = useMemo(
    () =>
      [
        {
          ...LINK.ASSETS_LOGOS,
          icon: FolderOpenOutlined,
          descriptionKey: 'charters.logo.pageDesc',
        },
        {
          ...LINK.ASSETS_INTROS,
          icon: FolderOpenOutlined,
          descriptionKey: 'charters.intro.pageDesc',
        },
        {
          ...LINK.ASSETS_OUTROS,
          icon: FolderOpenOutlined,
          descriptionKey: 'charters.outro.pageDesc',
        },
        {
          ...LINK.ASSETS_MUSICS,
          icon: FolderOpenOutlined,
          descriptionKey: 'charters.music.pageDesc',
        },
        {
          ...LINK.CHARTER_FONTS,
          icon: FontSizeOutlined,
          descriptionKey: 'charters.fonts.shortDescription',
        },
        {
          ...LINK.CHARTER_MEDIA_LIBRARY,
          icon: DatabaseOutlined,
          descriptionKey: 'charters.mediaLibrary.pageDesc',
        },
      ].map((page: GraphicalElementPage) => {
        const path = page.path.replace(':charterId', charterId.toString()).replace('(\\d+)', '');
        const key = page.key.replace(':charterId', charterId.toString());
        return { ...page, path, key };
      }),
    [charterId, isKnlTeam]
  );

  const renderGraphicalElementPageCard = (page: GraphicalElementPage): JSX.Element => {
    return (
      <Card
        className={classes.card}
        key={page.key}
        title={
          <div className={classes.titleContainer}>
            <page.icon className={classes.icon} />
            {t(page.title)}
          </div>
        }
        extra={
          <Link to={page.path}>
            <Button type="primary" icon={<LoginOutlined />}>
              {t('global.go')}
            </Button>
          </Link>
        }
      >
        {t(page.descriptionKey)}
      </Card>
    );
  };

  if (isCharterLoading) {
    return <PageContentLoader />;
  }

  if (!hasUserAccessToCharter(parseInt(charterId, 10))) {
    history.push(LINK.UNAUTHORIZED.path);
    return null;
  }

  const renderBreadcrumb = (): JSX.Element | null => {
    if (!charter) {
      return null;
    }

    const routes = [
      {
        path: '/charters',
        breadcrumbName: t('menu.charters'),
      },
      {
        path: `/charters/${charter.id}`,
        breadcrumbName: charter.name,
      },
      {
        path: location.pathname,
        breadcrumbName: t('charters.graphicalElements.title'),
      },
    ];

    return (
      <Breadcrumb className={classes.breadcrumb}>
        {routes.map((route) => {
          let component;
          if (route.path === location.pathname) {
            component = route.breadcrumbName;
          } else {
            component = <Link to={route.path}>{route.breadcrumbName}</Link>;
          }

          return <Breadcrumb.Item key={route.path}>{component}</Breadcrumb.Item>;
        })}
      </Breadcrumb>
    );
  };

  return (
    <div>
      {renderBreadcrumb()}
      <Row gutter={[16, 16]} className={classes.row}>
        {charterGraphicalElementsPages.map((page) => renderGraphicalElementPageCard(page))}
      </Row>
    </div>
  );
};

export default CharterGraphicalElements;
