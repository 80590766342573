import { find } from 'lodash';
import { Role } from '../core/rule/Roles';
import { UsersModelCharter } from '../services/api/types/ChartersServiceTypes';
import { APICompanyUsers } from '../services/api/types/CompaniesServiceTypes';
import { APIModelUser } from '../services/api/types/UsersServiceTypes';
import StringUtils from './StringUtils';

export default class UsersUtils {
  static defaultSortUsersByActiveThenRoleAndPlaceCurrentUserFirst = (
    usersToSort: UsersModelCharter[],
    currentUser: APIModelUser
  ): UsersModelCharter[] => {
    return usersToSort.sort((userA, userB) => {
      return (
        UsersUtils.sortUsersByStatus(userA, userB, currentUser) ||
        UsersUtils.sortUsersByRoleAndPlaceCurrentUserFirst(userA, userB, currentUser!)
      );
    });
  };

  static sortUsersByName = (
    userA: UsersModelCharter | APICompanyUsers,
    userB: UsersModelCharter | APICompanyUsers,
    currentUser: APIModelUser,
    sortOrder?: string | null
  ): number => {
    const currentUserPriority = UsersUtils.getCurrentUserPriority(userA, userB, currentUser, sortOrder);
    if (currentUserPriority !== 0) {
      return currentUserPriority;
    }
    const addedUserPriority = UsersUtils.getUserHighlightRowPriority(userA, userB, sortOrder);
    if (addedUserPriority !== 0) {
      return addedUserPriority;
    }

    return StringUtils.compareNaturalNormalized(userA.name, userB.name);
  };

  static sortUsersByEmail = (
    userA: UsersModelCharter | APICompanyUsers,
    userB: UsersModelCharter | APICompanyUsers,
    currentUser: APIModelUser,
    sortOrder?: string | null
  ): number => {
    const currentUserPriority = UsersUtils.getCurrentUserPriority(userA, userB, currentUser, sortOrder);
    if (currentUserPriority !== 0) {
      return currentUserPriority;
    }
    const addedUserPriority = UsersUtils.getUserHighlightRowPriority(userA, userB, sortOrder);
    if (addedUserPriority !== 0) {
      return addedUserPriority;
    }

    return StringUtils.compareNaturalNormalized(userA.email, userB.email);
  };

  static sortUsersByRoleAndPlaceCurrentUserFirst = (
    userA: UsersModelCharter,
    userB: UsersModelCharter,
    currentUser: APIModelUser,
    sortOrder?: string | null
  ): number => {
    const currentUserPriority = UsersUtils.getCurrentUserPriority(userA, userB, currentUser, sortOrder);
    if (currentUserPriority !== 0) {
      return currentUserPriority;
    }
    const addedUserPriority = UsersUtils.getUserHighlightRowPriority(userA, userB, sortOrder);
    if (addedUserPriority !== 0) {
      return addedUserPriority;
    }

    const roleA = new Role(userA.acl.role);
    const roleB = new Role(userB.acl.role);

    return roleB.getWeight() - roleA.getWeight();
  };

  static checkIsCurrentUser = (user: UsersModelCharter | APICompanyUsers, currentUser: APIModelUser): boolean => {
    return user.id === currentUser?.id;
  };

  static getCurrentUserPriority = (
    userA: UsersModelCharter | APICompanyUsers,
    userB: UsersModelCharter | APICompanyUsers,
    currentUser: APIModelUser,
    sortOrder?: string | null
  ): number => {
    const isCurrentUserA = UsersUtils.checkIsCurrentUser(userA, currentUser);
    const isCurrentUserB = UsersUtils.checkIsCurrentUser(userB, currentUser);
    if (!isCurrentUserA && !isCurrentUserB) {
      return 0;
    }

    if (sortOrder === 'ascend' || !sortOrder) {
      if (isCurrentUserA) {
        return -10;
      }
      if (isCurrentUserB) {
        return 10;
      }
    } else if (sortOrder === 'descend') {
      if (isCurrentUserA) {
        return 10;
      }
      if (isCurrentUserB) {
        return -10;
      }
    }

    return 0;
  };

  static sortUsersByStatus = (
    userA: UsersModelCharter,
    userB: UsersModelCharter,
    currentUser: APIModelUser,
    sortOrder?: string | null
  ): number => {
    const currentUserPriority = UsersUtils.getCurrentUserPriority(userA, userB, currentUser, sortOrder);
    if (currentUserPriority !== 0) {
      return currentUserPriority;
    }
    const addedUserPriority = UsersUtils.getUserHighlightRowPriority(userA, userB, sortOrder);
    if (addedUserPriority !== 0) {
      return addedUserPriority;
    }

    if (userA.acl.isActive === userB.acl.isActive) {
      return 0;
    }
    return userA.acl.isActive < userB.acl.isActive ? 1 : -1;
  };

  static sortUsersBySubtitleConsumption = (
    userA: UsersModelCharter | APICompanyUsers,
    userB: UsersModelCharter | APICompanyUsers,
    currentUser: APIModelUser,
    sortOrder?: string | null
  ): number => {
    const currentUserPriority = UsersUtils.getCurrentUserPriority(userA, userB, currentUser, sortOrder);
    if (currentUserPriority !== 0) {
      return currentUserPriority;
    }
    const addedUserPriority = UsersUtils.getUserHighlightRowPriority(userA, userB, sortOrder);
    if (addedUserPriority !== 0) {
      return addedUserPriority;
    }

    return userA.dashboard.subtitles.currentUsage < userB.dashboard.subtitles.currentUsage ? -1 : 1;
  };

  static getUserHighlightRowPriority = (
    userA: UsersModelCharter | APICompanyUsers,
    userB: UsersModelCharter | APICompanyUsers,
    sortOrder?: string | null
  ): number => {
    if (!userA.highlightRow && !userB.highlightRow) {
      return 0;
    }
    const userAHighlightRow = userA.highlightRow;
    const userBHighlightRow = userB.highlightRow;

    if (sortOrder === 'ascend' || !sortOrder) {
      if (userAHighlightRow) {
        return -5;
      }
      if (userBHighlightRow) {
        return 5;
      }
    } else if (sortOrder === 'descend') {
      if (userAHighlightRow) {
        return 5;
      }
      if (userBHighlightRow) {
        return -5;
      }
    }

    return 0;
  };

  static getDisabledUsersIdsForRole = (users: UsersModelCharter[], role: Role): string[] => {
    const disabledUsers: Array<string> = [];
    const visibleButNotAccessibleRoles = role.getVisibleButNotAccessibleRolesCodes();
    users.forEach((user) => {
      if (visibleButNotAccessibleRoles.includes(user.acl.role)) {
        disabledUsers.push(user.id);
      }
    });

    return disabledUsers;
  };

  static getUserById = (users: APICompanyUsers[], id: string): APICompanyUsers | undefined => {
    return find(users, (user) => user.id === id);
  };
}
