import { Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import React, { ChangeEvent, Ref, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import AccessChecker from '../../../../../../../components/access-checker/AccessChecker';
import { THEME } from '../../../../../../../Constants';
import useCanAccess from '../../../../../../../hooks/useCanAccess';
import AnimationsUtils from '../../../../../../../utils/AnimationsUtils';
import {
  AnimationConfig,
  AnimationOption,
  AnimationTextDescriptor,
  AnimationTextValidationRule,
  AnimationTextValidationRuleCode,
  AnimationTextValidationRuleType,
} from '../../../../../../../utils/types/AnimationTypes';
import { PermissionList } from '../../../../../../../utils/types/CharterPermissionTypes';
import { ProjectSceneAnimationText } from '../../../../../../../utils/types/ProjectTypes';

type Props = {
  value?: ProjectSceneAnimationText[];
  onChange?: (newValue: ProjectSceneAnimationText[] | undefined) => void;
  selectedAnimationConfig?: AnimationConfig;
  initialAnimationTexts?: ProjectSceneAnimationText[];
  onAnimationTextInErrorChange: (isInError: boolean) => void;
  disabled: boolean;
  isSlideScene: boolean;
};

const useStyles = createUseStyles({
  animationTextField: {
    '& label': {
      fontSize: 12,
    },
    '& .ant-input-textarea-show-count': {
      fontSize: 12,
    },
  },
  animationTextError: {
    '& .ant-input': {
      borderColor: THEME.DEFAULT.DANGER_COLOR,
      '&:focus': {
        boxShadow: '0 0 0 2px rgb(255 77 79 / 20%)',
      },
    },
  },
  animationTextTooLong: {
    '& .ant-input-textarea-show-count': {
      '&::after': {
        color: 'red',
        fontWeight: 'bold',
      },
    },
  },
  message: {
    minHeight: 24,
    fontSize: 12,
    paddingTop: 0,
    width: '85%',
  },
  errorMessage: {
    color: THEME.DEFAULT.DANGER_COLOR,
  },
  warningMessage: {
    color: THEME.DEFAULT.WARNING_COLOR,
  },
});

type ProjectSceneAnimationTextValidationRule = AnimationTextValidationRule & { index: number };

const ProjectSceneAnimationTexts = React.forwardRef(
  (
    {
      value,
      onChange,
      selectedAnimationConfig,
      initialAnimationTexts,
      onAnimationTextInErrorChange,
      disabled,
      isSlideScene,
    }: Props,
    ref: Ref<any>
  ) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [selectedAnimationOption, setSelectedAnimationOption] = useState<AnimationOption>();
    const [failingAnimationTextsRules, setFailingAnimationTextsRules] = useState<
      ProjectSceneAnimationTextValidationRule[]
    >();

    const permissionToEditSceneAnimationText = useCanAccess(PermissionList.SCENE_ANIMATION_TEXT_EDIT);
    const isPermissionToEditSceneAnimationTextAccessible = !isSlideScene
      ? permissionToEditSceneAnimationText.hasUserAccess()
      : true;

    // Called to update the upper `form` object
    const triggerChange = (changedValue: ProjectSceneAnimationText[] | undefined): void => {
      if (onChange) {
        onChange(changedValue);
      }
    };

    const generateDefaultAnimationText = (placeholder: string): ProjectSceneAnimationText => {
      return {
        placeholderText: placeholder,
        demoText: placeholder,
        text: placeholder,
      };
    };

    useEffect(() => {
      if (!selectedAnimationConfig) {
        return;
      }

      const option = AnimationsUtils.getAnimationOptionByCode(selectedAnimationConfig.name);
      if (option) {
        setSelectedAnimationOption(option);
        const initialValue = option.animationTexts.map((descriptor, index) => {
          // We try to find it in the initial scene values for the `animationText` field,
          const initialAnimationTextValue = initialAnimationTexts && initialAnimationTexts[index];
          // Check if there are failing validation rules for this initial value (excluding the "max length" error and
          // the "contain emoji" warning which does not count as an error)
          const failingValidationRule =
            initialAnimationTextValue &&
            descriptor.validationRules &&
            descriptor.validationRules.length > 0 &&
            AnimationsUtils.validateAnimationRule(
              initialAnimationTextValue.text,
              descriptor.validationRules
            ).filter((rule) => AnimationsUtils.isRuleElseThanTextTooLongOrContainsEmoji(rule));

          // If there is an initial value with no failing validation rule, return the initial value
          if (initialAnimationTextValue && !(failingValidationRule && failingValidationRule.length > 0)) {
            return initialAnimationTextValue;
          }

          // Else, generate a default value for the animation
          return generateDefaultAnimationText(
            descriptor.placeholderKey ? t(descriptor.placeholderKey) : t(descriptor.labelKey)
          );
        });
        triggerChange(initialValue);
      }
      // eslint-disable-next-line
    }, [selectedAnimationConfig, initialAnimationTexts]);

    // Validate the animation texts in error/warning (too long, empty, only digits, contains emojis) every time the
    // value changes
    useEffect(() => {
      if (!(selectedAnimationOption && value)) {
        return;
      }

      const errorsOrWarnings: ProjectSceneAnimationTextValidationRule[] = [];

      // For each field
      selectedAnimationOption.animationTexts.forEach((descriptor: AnimationTextDescriptor, index: number) => {
        // Get the field value
        const animationTextValue = value[index];
        if (!animationTextValue) {
          return;
        }

        // Validate the field against its configured rules
        const rulesInErrorOrWarning = AnimationsUtils.validateAnimationRule(
          animationTextValue.text,
          descriptor.validationRules
        );

        // If some rules aren't satisfied, we build an array of the not passing rules to display error/warning messages
        if (rulesInErrorOrWarning && rulesInErrorOrWarning.length > 0) {
          rulesInErrorOrWarning.forEach((ruleInError) => {
            errorsOrWarnings.push({ ...ruleInError, index });
          });
        }
      });

      setFailingAnimationTextsRules(errorsOrWarnings);
    }, [selectedAnimationOption, value]);

    // If one field is in error/warning (except for the "text too long" and "contains emoji" error/warning which are
    // not-blocking), call the onAnimationTextInErrorChange callback to say one animation text is in error
    useEffect(() => {
      if (
        failingAnimationTextsRules &&
        failingAnimationTextsRules.length > 0 &&
        failingAnimationTextsRules.some((err) => AnimationsUtils.isRuleElseThanTextTooLongOrContainsEmoji(err))
      ) {
        onAnimationTextInErrorChange(true);
      } else {
        onAnimationTextInErrorChange(false);
      }
    }, [failingAnimationTextsRules, onAnimationTextInErrorChange]);

    const onAnimationTextInputChange = (e: ChangeEvent<HTMLTextAreaElement>, animationTextIndex: number): void => {
      const fieldValue = e.target.value?.replace(/(\r\n|\n|\r)/gm, '');
      const newAnimationTexts = cloneDeep(value);
      const updatedAnimationText = newAnimationTexts && newAnimationTexts[animationTextIndex];
      if (updatedAnimationText) {
        updatedAnimationText.text = fieldValue;
        updatedAnimationText.demoText = fieldValue;
        updatedAnimationText.placeholderText = fieldValue;
      }
      triggerChange(newAnimationTexts);
    };

    if (
      !(
        selectedAnimationOption &&
        selectedAnimationOption.animationTexts &&
        selectedAnimationOption.animationTexts.length > 0 &&
        value
      )
    ) {
      return null;
    }

    return (
      <div ref={ref}>
        {selectedAnimationOption.animationTexts.map((descriptor: AnimationTextDescriptor, index: number) => {
          const animationTextValue = value[index];
          if (!animationTextValue) {
            return null;
          }

          // All errors/warnings for the current field (if any)
          const errorsOrWarningsForField = failingAnimationTextsRules?.filter((err) => err.index === index);
          // Check if there is the "text too long" or "contains emoji" error/warning among the failing rules (do not
          // display an error message, only show the text area data-count limit is reached or a warning message)
          const isAnimationTextTooLong = errorsOrWarningsForField?.some(
            (rule) => rule.ruleCode === AnimationTextValidationRuleCode.MAX_LENGTH
          );
          // Check if there is at least one other error/warning different from "text too long" and "contains emoji"
          const isErrorOrWarningElseThanTextTooLongAndEmoji = errorsOrWarningsForField?.some((rule) =>
            AnimationsUtils.isRuleElseThanTextTooLongOrContainsEmoji(rule)
          );

          const maxLengthValue = descriptor.validationRules?.find(
            (r) => r.ruleCode === AnimationTextValidationRuleCode.MAX_LENGTH
          )?.ruleValue as number | undefined;
          const isDigitsOnlyField = descriptor.validationRules?.some(
            (r) => r.ruleCode === AnimationTextValidationRuleCode.DIGITS_OR_PERCENTAGE_ONLY
          );

          return (
            // We can use the index as key here since the animationTexts won't be reordered
            <div key={index.toString()}>
              <div>
                <AccessChecker
                  renderUnauthorizedMessage={permissionToEditSceneAnimationText.renderUnauthorizedMessage}
                  hasAccess={(isSlideScene && disabled) || isPermissionToEditSceneAnimationTextAccessible}
                >
                  <Form.Item
                    label={t(descriptor.labelKey)}
                    className={classNames(
                      classes.animationTextField,
                      isErrorOrWarningElseThanTextTooLongAndEmoji && classes.animationTextError,
                      isAnimationTextTooLong && classes.animationTextTooLong
                    )}
                  >
                    <TextArea
                      disabled={(isSlideScene && disabled) || !isPermissionToEditSceneAnimationTextAccessible}
                      placeholder={t(descriptor.labelKey)}
                      value={animationTextValue.text}
                      maxLength={maxLengthValue}
                      showCount
                      autoSize={!isDigitsOnlyField ? { minRows: 2, maxRows: 4 } : { minRows: 1, maxRows: 1 }}
                      onChange={(e): void => onAnimationTextInputChange(e, index)}
                    />
                    {/* Display the error/warning messages for the field if any */}
                    {errorsOrWarningsForField &&
                      errorsOrWarningsForField.length > 0 &&
                      errorsOrWarningsForField.map((errorForField) => {
                        if (!errorForField.message) {
                          return null;
                        }

                        return (
                          <div
                            key={`${index.toString()}_${errorForField.ruleCode}`}
                            className={classNames(
                              classes.message,
                              errorForField.ruleType === AnimationTextValidationRuleType.ERROR
                                ? classes.errorMessage
                                : classes.warningMessage
                            )}
                          >
                            {t(errorForField.message)}
                          </div>
                        );
                      })}
                  </Form.Item>
                </AccessChecker>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
);

export default ProjectSceneAnimationTexts;
