import React, { FunctionComponent } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { createUseStyles } from 'react-jss';
import { VideoTrim, VideoTrimUtils } from '../utils/VideoTrimUtils';

type Props = {
  videoTrimValue: VideoTrim;
  pipTrimValue: VideoTrim;
  duration: number;
  onCurrentTimeChange: (e: number) => void;
  currentTime: number;
  intervalTickWidth: number;
};

const useStyles = createUseStyles({
  seekBar: {
    position: 'absolute',
    height: '100%',
    width: 2,
    background: 'red',
    zIndex: 6,
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
    '&::before': {
      content: '""',
      width: 13,
      height: 11,
      background: 'red',
      position: 'absolute',
      left: -6,
      borderRadius: 4,
      top: -8,
    },
    '&::after': {
      content: '""',
      width: 0,
      height: 0,
      borderLeft: '7px solid transparent',
      borderRight: '6px solid transparent',
      borderTop: '14px solid red',
      position: 'absolute',
      top: 0,
      left: -6,
    },
  },
});

const SeekBar: FunctionComponent<Props> = ({
  duration,
  videoTrimValue,
  currentTime,
  onCurrentTimeChange,
  intervalTickWidth,
  pipTrimValue,
}: Props) => {
  const classes = useStyles();
  const trimStart = VideoTrimUtils.getMinTrimStartForPosition(videoTrimValue, pipTrimValue);
  const trimStartDifference = Math.max(0, trimStart - videoTrimValue.trimStart);

  const onSeekBarDrag = (event: DraggableEvent, data: DraggableData) => {
    const currentTimeValue = VideoTrimUtils.calculateSeekBarValue(data.x, intervalTickWidth) - trimStartDifference;
    if (currentTimeValue < duration + trimStartDifference) {
      onCurrentTimeChange(currentTimeValue);
    } else {
      onCurrentTimeChange(duration + trimStartDifference);
    }
  };

  return (
    <Draggable
      position={{
        x: VideoTrimUtils.calculateSeekBarPositionOnX(
          trimStart,
          videoTrimValue.trimEnd + trimStartDifference,
          currentTime + trimStartDifference,
          intervalTickWidth
        ),
        y: 0,
      }}
      bounds={{
        left: VideoTrimUtils.calculateSeekBarLeftBoundValue(trimStart, intervalTickWidth),
        top: 0,
        right: VideoTrimUtils.calculateSeekBarRightBoundValue(
          videoTrimValue.trimEnd - videoTrimValue.trimStart,
          trimStart,
          intervalTickWidth
        ),
        bottom: 0,
      }}
      defaultPosition={{ x: 0, y: 0 }}
      axis="x"
      onDrag={onSeekBarDrag}
    >
      <div className={classes.seekBar} />
    </Draggable>
  );
};

export default SeekBar;
