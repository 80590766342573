import { EditFilled } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlayCircle } from 'react-icons/all';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AccessChecker from '../../../../../components/access-checker/AccessChecker';
import PageContentLoader from '../../../../../components/loader/PageContentLoader';
import FormatTag from '../../../../../components/tag/format-tag/FormatTag';
import { DEVICE_SIZES_QUERIES, LINK } from '../../../../../Constants';
import useCanAccess from '../../../../../hooks/useCanAccess';
import { RootState } from '../../../../../redux/RootState';
import { getCharterScenarioDetailsById } from '../../../../../services/api/ChartersService';
import { ScenarioVariant } from '../../../../../services/api/types/ChartersServiceTypes';
import ScenarioUtils from '../../../../../utils/ScenarioUtils';
import { AnimationFormats } from '../../../../../utils/types/AnimationTypes';
import { ScenarioSceneTemplate } from '../../../../../utils/types/ScenarioTypes';
import ScenarioFinalPlayerModal from '../ScenarioFinalPlayerModal';
import ScenarioSceneDescription from './ScenarioSceneDescription';
import ScenarioSceneDescriptionCard from './ScenarioSceneDescriptionCard';

type Props = {
  trigger: JSX.Element;
  scenarioId: number;
  scenarioCode: string;
  scenarioVariantId: number;
  isEditable?: boolean;
};

type StyleProps = {
  isMobileOrTablet: boolean;
};

const useStyles = createUseStyles({
  modal: {
    width: '85% !important',
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      width: '95% !important',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  scenarioTitle: {
    fontSize: 14,
    maxWidth: '60%',
  },
  scenesContainer: ({ isMobileOrTablet }: StyleProps) => ({
    display: isMobileOrTablet ? 'unset' : 'flex',
    overflow: 'auto',
    paddingBottom: '20px',
    marginBottom: '20px',

    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      height: '9px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '6px',
      backgroundColor: 'rgba(0, 0, 0, .5)',
      boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
    },
  }),
  scenesWrapper: ({ isMobileOrTablet }: StyleProps) => ({
    display: isMobileOrTablet ? 'unset' : 'inline-flex',
  }),
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 32,
  },
  watchButton: {
    display: 'flex',
    alignItems: 'center',
  },
  watchIcon: {
    marginRight: 4,
  },
  editScenarioVariantButton: {
    marginLeft: 8,
  },
});

const ScenarioVariantDescription: FunctionComponent<Props> = ({
  trigger,
  scenarioId,
  scenarioCode,
  scenarioVariantId,
  isEditable = false,
}: Props) => {
  const [scenarioVariant, setScenarioVariant] = useState<ScenarioVariant>();
  const [selectedScene, setSelectedScene] = useState<ScenarioSceneTemplate>();
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFinalPlayerVisible, setIsFinalPlayerVisible] = useState(false);
  const [isScenePreviewPlaying, setIsScenePreviewPlaying] = useState(true);

  const currentCharter = useSelector((state: RootState) => state.charters.current);
  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);

  const classes = useStyles({ isMobileOrTablet });
  const { t } = useTranslation();
  const history = useHistory();

  const permissionToUseScenario = useCanAccess(`SCENARIO_${scenarioCode}`);
  const hasUserAccessToScenario = permissionToUseScenario.hasUserAccess();

  const charterId = currentCharter?.id;

  useEffect(() => {
    if (!(charterId && isModalVisible)) {
      return;
    }

    getCharterScenarioDetailsById(charterId, scenarioId)
      .then((response) => {
        const variant = response.variants.find((sv) => sv.id === scenarioVariantId);
        if (variant) {
          setScenarioVariant(variant);
        }
      })
      .catch(() => {
        log.error('Error while loading the scenario details');
        message.error(t('charters.scenarios.scenarioMetadataForm.scenarioFetchingError'));
        setIsModalVisible(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [charterId, scenarioId, scenarioVariantId, isModalVisible]);

  // If no scene is selected (web version only), we select the first scene of the scenario.
  // On small devices, it is possible to have no selected scene since selecting one scene opens
  // up a modal to edit it.
  useEffect(() => {
    if (
      !isMobileOrTablet &&
      scenarioVariant &&
      scenarioVariant.scenes &&
      scenarioVariant.scenes.length > 0 &&
      !selectedScene
    ) {
      setSelectedScene(scenarioVariant.scenes[0]);
    }
  }, [isMobileOrTablet, scenarioVariant, selectedScene]);

  const onOpenModal = () => {
    setIsModalVisible(true);
  };

  const onCloseModal = () => {
    setIsModalVisible(false);
  };

  const onSelectScene = (scene: ScenarioSceneTemplate): void => {
    if (selectedScene && scene.id === selectedScene.id) {
      return;
    }

    setSelectedScene(scene);
  };

  const onIsScenePreviewPlayingChange = (newIsPlaying: boolean): void => {
    setIsScenePreviewPlaying(newIsPlaying);
  };

  const onEditScenarioVariantClick = (): void => {
    const scenarioVariantEditorPath =
      charterId &&
      LINK.CHARTER_SCENARIO_VARIANT_EDITOR.path.replace(':charterId', charterId.toString()).replace('(\\d+)', '');

    if (!scenarioVariantEditorPath) {
      return;
    }

    history.push({
      pathname: scenarioVariantEditorPath,
      state: {
        scenarioId,
        scenarioVariantId,
      },
    });
  };

  const onOpenFinalPlayer = () => {
    setIsFinalPlayerVisible(true);
    setIsScenePreviewPlaying(false);
  };

  const onCloseFinalPlayer = () => {
    setIsFinalPlayerVisible(false);
  };

  const renderModalTitle = () => {
    if (!scenarioVariant) {
      return null;
    }

    const variantLanguageFlag = ScenarioUtils.getVariantLanguage(scenarioVariant.language)?.flag;
    const variantLanguageFormat = ScenarioUtils.getVariantFormatByCode(scenarioVariant.format);

    return (
      <div className={classes.title}>
        <span className={classes.scenarioTitle}>
          {scenarioVariant.title}
          {variantLanguageFlag !== undefined && ` - ${variantLanguageFlag}`}
          {variantLanguageFormat !== undefined && (
            <>
              {' '}
              - <FormatTag format={t(variantLanguageFormat.key)} image={variantLanguageFormat.image} />
            </>
          )}
        </span>

        <div className={classes.actionsContainer}>
          <Button className={classes.watchButton} onClick={onOpenFinalPlayer}>
            <FaPlayCircle className={classes.watchIcon} /> {t('charters.scenarios.variantEditor.finalPlayer')}
          </Button>

          {isEditable && (
            <AccessChecker
              renderUnauthorizedMessage={permissionToUseScenario.renderUnauthorizedMessage}
              hasAccess={hasUserAccessToScenario}
            >
              <div className={classes.editScenarioVariantButton}>
                <Button
                  type="primary"
                  icon={<EditFilled />}
                  onClick={onEditScenarioVariantClick}
                  disabled={!hasUserAccessToScenario}
                >
                  {t('global.edit')}
                </Button>
              </div>
            </AccessChecker>
          )}
        </div>
      </div>
    );
  };

  const renderSceneCarousel = () => {
    if (isLoading) {
      return null;
    }

    return (
      <div className={classes.scenesContainer}>
        {scenarioVariant &&
          scenarioVariant.scenes &&
          scenarioVariant.scenes.length > 0 &&
          scenarioVariant.scenes!.map((variantScene, index) => {
            return (
              <div key={`${variantScene.id}`} className={classes.scenesWrapper}>
                <ScenarioSceneDescriptionCard
                  scene={variantScene}
                  format={scenarioVariant.format}
                  index={index}
                  isSelected={selectedScene && variantScene.id === selectedScene.id}
                  onSelectScene={(): void => onSelectScene(variantScene)}
                />
              </div>
            );
          })}
      </div>
    );
  };

  const renderScenarioSceneDetails = () => {
    if (isLoading || !(selectedScene && scenarioVariant)) {
      return null;
    }

    return (
      <ScenarioSceneDescription
        scene={selectedScene}
        format={scenarioVariant.format}
        isScenePreviewPlaying={isScenePreviewPlaying}
        onIsScenePreviewPlayingChange={onIsScenePreviewPlayingChange}
      />
    );
  };

  return (
    <>
      <div onClick={onOpenModal}>{trigger}</div>

      <Modal
        visible={isModalVisible}
        onCancel={onCloseModal}
        title={renderModalTitle()}
        className={classes.modal}
        destroyOnClose
        footer={[
          <Button key="cancelModal" onClick={onCloseModal}>
            {t('global.cancel')}
          </Button>,
        ]}
      >
        {isLoading && <PageContentLoader />}
        <>
          {renderSceneCarousel()}
          {renderScenarioSceneDetails()}
        </>
      </Modal>

      <ScenarioFinalPlayerModal
        isVisible={isFinalPlayerVisible}
        scenes={scenarioVariant?.scenes}
        format={scenarioVariant?.format || AnimationFormats.FORMAT_16_9}
        handleClose={onCloseFinalPlayer}
      />
    </>
  );
};

export default ScenarioVariantDescription;
