import { SwapOutlined } from '@ant-design/icons/lib';
import { Button, Form, message, Modal, Tooltip } from 'antd';
import classNames from 'classnames';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import RoleRadioGroup from '../../../../../components/form/RoleRadioGroup';
import { LOGGING_EVENT } from '../../../../../Constants';
import { Roles } from '../../../../../core/rule/RolesTypes';
import {
  setUpdatingUserInCurrentCharter,
  updateUserInCurrentCharter,
} from '../../../../../redux/action/ChartersAction';
import { RootState } from '../../../../../redux/RootState';
import { patchUserByCharterIdAndUserId } from '../../../../../services/api/ChartersService';
import { UsersModelCharter } from '../../../../../services/api/types/ChartersServiceTypes';
import LogUtils from '../../../../../utils/LogUtils';

const useStyles = createUseStyles({
  buttonPosition: {
    float: 'right',
  },
});

type Props = {
  isDisabled?: boolean;
  selectedRows: UsersModelCharter[];
  renderUnauthorizedMessage?: () => void;
};

const UpdateUserRoleModal: FunctionComponent<Props> = ({
  selectedRows,
  isDisabled,
  renderUnauthorizedMessage,
}: Props) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const [form] = Form.useForm();
  const [role, setRole] = useState(Roles.Creator);
  const dispatch = useDispatch();
  const currentCharterId = useSelector((state: RootState) => state.charters.current?.id);
  const currentUserRole = useSelector((state: RootState) => state.user.role);
  const companyStats = useSelector((state: RootState) => state.companies.stats);
  const currentCompany = useSelector((state: RootState) => state.companies.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      // we reset to default value if the user doesn't exist
      setRole(Roles.Creator);
      form.setFieldsValue({ role: Roles.Creator });
    }
  }, [visible, form]);

  if (!currentCharterId || !currentUserRole) {
    return null;
  }

  const showModal = (): void => {
    setVisible(true);
  };

  const handleSubmit = (): void => {
    form.validateFields().then((values) => {
      if (!companyStats) {
        return;
      }

      if (!companyStats.canUpdateUsersFromRoleToRole(selectedRows, values.role)) {
        message.error(t('global.maxNbLicensesReachedError', { count: companyStats?.users.max }));
        return;
      }

      const event = LogUtils.getUsersBatchUpdateEvent(
        LOGGING_EVENT.BATCH_UPDATE_USERS_ROLE,
        selectedRows,
        { role: values.role },
        currentCharterId,
        currentCompany?.id
      );
      loggingManager.logEventObject(event);

      setVisible(false);
      selectedRows.forEach((row: UsersModelCharter) => {
        if (row.acl.role === values.role) {
          return;
        }
        dispatch(setUpdatingUserInCurrentCharter(row.id, 'role'));

        const updateData = {
          role: values.role,
        };

        // one request per user to avoid bulk fail
        patchUserByCharterIdAndUserId(currentCharterId, row.id, updateData)
          .then((response) => {
            if (response.data) {
              companyStats.userUpdatedFromRole(row.acl.role, values.role, row.acl.isActive);
              dispatch(updateUserInCurrentCharter(response.data));
            }
          })
          .catch((error) => {
            log.error(error);
            message.error(
              <>
                {t('users.updateRole.error', { count: selectedRows.length })}
                <br />
                {t('support.global.emojiFingerRight')}
                <a href={`mailto:${t('support.global.supportEmail')}`}>{t('support.global.supportEmail')}</a>
                {t('support.global.emojiFingerLeft')}
              </>
            );
          });
      });
    });
  };

  const handleCancel = (): void => {
    setVisible(false);
  };

  const handleRoleChange = (value: Roles): void => {
    setRole(value);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  return (
    <>
      <Tooltip title={isDisabled ? renderUnauthorizedMessage : undefined}>
        <Button
          className={classNames(classes.buttonPosition, 'updateUserRoleButton')}
          disabled={selectedRows.length === 0 || isDisabled}
          onClick={showModal}
        >
          <SwapOutlined /> {t('users.updateRoleButton')}
        </Button>
      </Tooltip>
      <Modal
        centered
        title={t('users.updateRole.title', { count: selectedRows.length })}
        visible={visible}
        onOk={handleSubmit}
        onCancel={handleCancel}
        cancelText={t('global.cancel')}
        okText={t('global.confirm')}
        forceRender
      >
        <Form
          form={form}
          {...formItemLayout}
          initialValues={{
            role: Roles.Creator,
          }}
        >
          <RoleRadioGroup
            label={t('users.fields.role')}
            visibleRoles={currentUserRole.getAccessibleRoles()}
            role={role}
            callbackHandleChange={handleRoleChange}
          />
        </Form>
      </Modal>
    </>
  );
};

export default UpdateUserRoleModal;
