import log from 'loglevel';
import React, { Suspense, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import './App.css';
import KannelleLoader from './components/loader/KannelleLoader';
import { DEVICE_SIZES_QUERIES } from './Constants';
import Router from './core/router/Router';
import { checkDeviceIsMobileOrTablet } from './redux/action/AppAction';
import LogUtils from './utils/LogUtils';

const useStyles = createUseStyles({
  '@global': {
    body: {
      fontFamily:
        "Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif",

      '-webkit-font-smoothing': 'antialiased',

      '&.overflowHidden': {
        overflow: 'hidden',
      },
    },
    '.ant-statistic-content': {
      fontFamily:
        "Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif",
    },
  },
});

const App: React.FunctionComponent = () => {
  // needed to initialise global css param
  useStyles();
  const isMobileOrTablet = useMediaQuery({ query: DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkDeviceIsMobileOrTablet(isMobileOrTablet));
  }, [dispatch, isMobileOrTablet]);

  // Initiate the loglevel relying on the NODE_ENV
  useEffect(() => {
    log.debug('Initiating loglevel');
    LogUtils.initLoggerLevel(process.env.NODE_ENV ?? 'development');
  }, []);

  return (
    <Suspense fallback={<KannelleLoader />}>
      <Router />
    </Suspense>
  );
};

export default App;
