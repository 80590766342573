import { Action, action } from 'typesafe-actions';
import Company from '../../model/Company';
import CompanyStats from '../../model/CompanyStats';
import { ChargebeePaymentMethod, ChargebeeSubscriptionObject } from '../../services/api/types/ChargebeeServiceTypes';
import { APIModelCharter } from '../../services/api/types/ChartersServiceTypes';
import { CompanyBundle } from '../../utils/types/BundlesTypes';

export enum COMPANIES_ACTION_TYPE {
  INITIALIZE_COMPANIES = 'INITIALIZE_COMPANIES',
  SELECT_COMPANY = 'SELECT_COMPANY',
  ADD_COMPANY = 'ADD_COMPANY',
  UPDATE_COMPANY = 'UPDATE_COMPANY',
  UPDATE_COMPANY_FROM_SOCKET = 'UPDATE_COMPANY_FROM_SOCKET',
  SET_COMPANY_STATS = 'SET_COMPANY_STATS',
  SET_COMPANY_BUNDLE = 'SET_COMPANY_BUNDLE',
  SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS',
  SET_FREE_TRIAL_SUBSCRIPTION = 'SET_FREE_TRIAL_SUBSCRIPTION',
  SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD',
  ADD_OR_UPDATE_CHARTER_IN_COMPANY = 'ADD_OR_UPDATE_CHARTER_IN_COMPANY',
  DELETE_CHARTER = 'DELETE_CHARTER',
  MEDIA_FILE_UPLOADED_PROCESSING = 'MEDIA_FILE_UPLOADED_PROCESSING',
  REFRESH_PROCESSING_MEDIA_BY_MEDIA_TYPE = 'REFRESH_PROCESSING_MEDIA_BY_MEDIA_TYPE',
  UPDATE_MEDIA_PROCESSING_PROGRESSION = 'UPDATE_MEDIA_PROCESSING_PROGRESSION',
  SET_COMPANY_USER = 'SET_COMPANY_USER',
}

export const initializeCompanies = (companies: Array<Company>): Action => {
  return action(COMPANIES_ACTION_TYPE.INITIALIZE_COMPANIES, companies);
};

export const selectCompany = (company: Company): Action => {
  return action(COMPANIES_ACTION_TYPE.SELECT_COMPANY, company);
};

export const setCompanyBundle = (bundleInfo?: CompanyBundle): Action => {
  return action(COMPANIES_ACTION_TYPE.SET_COMPANY_BUNDLE, bundleInfo);
};

export const addCompany = (company: Company): Action => {
  return action(COMPANIES_ACTION_TYPE.ADD_COMPANY, company);
};

export const updateCompany = (company: Company): Action => {
  return action(COMPANIES_ACTION_TYPE.UPDATE_COMPANY, company);
};

export const updateCompanyFromSocket = (company: Company): Action => {
  return action(COMPANIES_ACTION_TYPE.UPDATE_COMPANY_FROM_SOCKET, company);
};

export const setCompanyStats = (companyStats?: CompanyStats): Action => {
  return action(COMPANIES_ACTION_TYPE.SET_COMPANY_STATS, companyStats);
};

export const setSubscriptions = (subscriptions: ChargebeeSubscriptionObject[]): Action => {
  return action(COMPANIES_ACTION_TYPE.SET_SUBSCRIPTIONS, subscriptions);
};

export const setFreeTrialSubscription = (subscription?: ChargebeeSubscriptionObject): Action => {
  return action(COMPANIES_ACTION_TYPE.SET_FREE_TRIAL_SUBSCRIPTION, subscription);
};

export const setPaymentMethod = (subscription?: ChargebeePaymentMethod): Action => {
  return action(COMPANIES_ACTION_TYPE.SET_PAYMENT_METHOD, subscription);
};

export const addOrUpdateCharterInCompany = (company: Company, charter: APIModelCharter): Action => {
  return action(COMPANIES_ACTION_TYPE.ADD_OR_UPDATE_CHARTER_IN_COMPANY, { company, charter });
};

export const deleteCharter = (charterId: number): Action => {
  return action(COMPANIES_ACTION_TYPE.DELETE_CHARTER, { charterId });
};
