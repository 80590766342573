import { PlusOutlined } from '@ant-design/icons/lib';
import { Button } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';

type Props = {
  onAddScene?: () => void;
};

const useStyles = createUseStyles({
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 10px',
  },
  addSceneButton: {
    height: '100px',
    width: 170,
    boxShadow: '0 8px 8px 0 hsla(0, 0%, 0%, 0.15) !important',
    borderRadius: 8,
    whiteSpace: 'normal',
    '& span': {
      marginLeft: 'unset !important',
    },
  },
});

const AddScenarioScene: FunctionComponent<Props> = ({ onAddScene }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.buttonContainer}>
      <Button className={classes.addSceneButton} icon={<PlusOutlined />} onClick={onAddScene}>
        {t('charters.scenarios.variantEditor.addScene')}
      </Button>
    </div>
  );
};

export default AddScenarioScene;
