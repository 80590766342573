import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined, WarningOutlined } from '@ant-design/icons/lib';
import { Badge, Typography } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import ReactMarkdown from 'react-markdown';
import { THEME } from '../../Constants';
import { Notification } from '../../utils/types/NotificationTypes';

const { Text } = Typography;

type Props = {
  notification: Notification;
};

const useStyles = createUseStyles({
  menuItem: {
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingRight: '12px',
    paddingLeft: '2px',
    whiteSpace: 'normal',
    maxWidth: '320px',
    display: 'flex',
    alignItems: 'flex-start',
    '& .ant-dropdown-menu-title-content': {
      display: 'flex',
    },
  },
  invisibleBadge: {
    visibility: 'hidden',
  },
  unreadNotification: {
    backgroundColor: THEME.MENU.MAIN_BACKGROUND_COLOR_50_PERCENT_OPACITY,
  },
  unreadNotificationDot: {
    '& .ant-badge-status-dot': {
      marginLeft: '4px',
    },
    '& .ant-badge-status-text': {
      marginLeft: '4px',
    },
  },
  notificationIcon: {
    marginRight: '5px',
    paddingTop: '2px',
    lineHeight: 'inherit',
  },
  errorNotification: {
    color: '#ff4d4f',
  },
  successNotification: {
    color: '#52c41a',
  },
  warningNotification: {
    color: '#faad14',
  },
  infoNotification: {
    color: '#1890ff',
  },
  notificationDate: {
    fontSize: 12,
  },
});

const NotificationItem: FunctionComponent<Props> = ({ notification }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const read = notification.isRead;

  const getNotificationIcon = (notif: Notification): ReactElement => {
    switch (notif.type) {
      case 'error':
        return <CloseCircleOutlined className={classNames(classes.errorNotification, classes.notificationIcon)} />;
      case 'success':
        return <CheckCircleOutlined className={classNames(classes.successNotification, classes.notificationIcon)} />;
      case 'warning':
        return <WarningOutlined className={classNames(classes.warningNotification, classes.notificationIcon)} />;
      case 'info':
      default:
        return <InfoCircleOutlined className={classNames(classes.infoNotification, classes.notificationIcon)} />;
    }
  };

  const isJSXElement = React.isValidElement(notification.message);

  return (
    <div
      className={classNames(!read && classes.unreadNotification, classes.menuItem)}
      onClick={notification.onClick ?? undefined}
    >
      <Badge status="success" className={classNames(classes.unreadNotificationDot, read && classes.invisibleBadge)} />
      {getNotificationIcon(notification)}
      <div>
        {/* If the notification message is a JSX.Element, we render it, else we try to parse it as Markdown */}
        {isJSXElement ? notification.message : <ReactMarkdown>{notification.message.toString()}</ReactMarkdown>}
        <div className={classes.notificationDate}>
          <Text type="secondary">{notification.creationDate.format(t('timeFormats.yearMonthDayHourMinute'))}</Text>
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
