import { Action, action } from 'typesafe-actions';
import { LoggingManager } from '../../manager/logging/LoggingManager';
import { Notification } from '../../utils/types/NotificationTypes';

export enum APP_ACTION_TYPE {
  TOGGLE_MENU = 'TOGGLE_MENU',
  OPEN_MENU_ITEM = 'OPEN_MENU_ITEM',
  SET_OPEN_MENU_ITEM = 'SET_OPEN_MENU_ITEM',
  CHECK_DEVICE_IS_MOBILE_OR_TABLET = 'CHECK_DEVICE_IS_MOBILE_OR_TABLET',
  SET_IS_USER_UNAUTHORIZED = 'SET_IS_USER_UNAUTHORIZED',
  UPDATE_LANGUAGE = 'UPDATE_LANGUAGE',
  RESET_STORE_STATE = 'RESET_STORE_STATE',
  UPDATE_UNSAVED_CHANGE = 'UPDATE_UNSAVED_CHANGE',
  CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS',
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
  EDIT_NOTIFICATION_BY_ID = 'EDIT_NOTIFICATION_BY_ID',
  DELETE_NOTIFICATION_BY_ID = 'DELETE_NOTIFICATION_BY_ID',
  STORE_LOGGING_MANAGER = 'STORE_LOGGING_MANAGER',
  SET_ONBOARDING = 'SET_ONBOARDING',
  RESET_ONBOARDING = 'RESET_ONBOARDING',
  SET_API_TOKEN = 'SET_API_TOKEN',
  SET_IS_FREE_TRIAL_BANNER_DISPLAYED = 'SET_IS_FREE_TRIAL_BANNER_DISPLAYED',
}

export const toggleMenu = (): Action => {
  return action(APP_ACTION_TYPE.TOGGLE_MENU);
};

export const openMenuItem = (menuItemKey: string): Action => {
  return action(APP_ACTION_TYPE.OPEN_MENU_ITEM, menuItemKey);
};

export const setOpenMenuItem = (menuItemKeys: string[]): Action => {
  return action(APP_ACTION_TYPE.SET_OPEN_MENU_ITEM, menuItemKeys);
};

export const checkDeviceIsMobileOrTablet = (isMobileOrTablet: boolean): Action => {
  return action(APP_ACTION_TYPE.CHECK_DEVICE_IS_MOBILE_OR_TABLET, { isMobileOrTablet });
};

export const setIsUserUnauthorized = (isUnauthorized: boolean): Action => {
  return action(APP_ACTION_TYPE.SET_IS_USER_UNAUTHORIZED, { isUnauthorized });
};

export const updateLanguage = (language: string): Action => {
  return action(APP_ACTION_TYPE.UPDATE_LANGUAGE, { language });
};

export const resetStoreState = (): Action => {
  return action(APP_ACTION_TYPE.RESET_STORE_STATE);
};

export const updateUnsavedChange = (unsavedChange: boolean): Action => {
  return action(APP_ACTION_TYPE.UPDATE_UNSAVED_CHANGE, unsavedChange);
};

export const clearNotifications = (): Action => {
  return action(APP_ACTION_TYPE.CLEAR_NOTIFICATIONS);
};

export const pushNotification = (notification: Notification): Action => {
  return action(APP_ACTION_TYPE.PUSH_NOTIFICATION, notification);
};

export const editNotificationById = (
  notificationId: string,
  newNotificationParameters: Partial<Notification>
): Action => {
  return action(APP_ACTION_TYPE.EDIT_NOTIFICATION_BY_ID, { notificationId, newNotificationParameters });
};

export const deleteNotificationById = (notificationId: string): Action => {
  return action(APP_ACTION_TYPE.DELETE_NOTIFICATION_BY_ID, notificationId);
};

export const storeLoggingManager = (loggingManager: LoggingManager): Action => {
  return action(APP_ACTION_TYPE.STORE_LOGGING_MANAGER, loggingManager);
};

export const setOnBoarding = (done: boolean): Action => {
  return action(APP_ACTION_TYPE.SET_ONBOARDING, done);
};

export const resetOnBoarding = (): Action => {
  return action(APP_ACTION_TYPE.RESET_ONBOARDING);
};

export const setAPIToken = (token: string): Action => {
  return action(APP_ACTION_TYPE.SET_API_TOKEN, token);
};

export const setFreeTrialBannerDisplayed = (isDisplayed: boolean): Action => {
  return action(APP_ACTION_TYPE.SET_IS_FREE_TRIAL_BANNER_DISPLAYED, isDisplayed);
};
