import { BgColorsOutlined } from '@ant-design/icons/lib';
import { Card, Col, Row, Statistic } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { THEME } from '../../../Constants';
import { RootState } from '../../../redux/RootState';
import useStyles from './StatisticsStyle';

type Props = {
  statistics: {
    active: number;
    total: number;
  };
  maxNbCharters?: number;
};

const ChartersStatistics: FunctionComponent<Props> = ({ statistics, maxNbCharters }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobileOrTablet = useSelector((state: RootState) => {
    return state.app.isMobileOrTablet;
  });

  const unlimited = t('home.statistics.unlimited');

  const totalAllowed = maxNbCharters || unlimited;
  const activeChartersColor =
    statistics.active >= statistics.total && totalAllowed !== unlimited
      ? THEME.HOME.STATISTICS.INVALID_COLOR
      : THEME.HOME.STATISTICS.VALID_COLOR;

  if (!statistics) {
    return null;
  }

  return (
    <Col xs={12} sm={12} md={12} lg={7}>
      <Row gutter={0} className={classNames(classes.fullHeight, classes.row)}>
        <Col className={classes.column} span={24}>
          <Card className={classNames(classes.fullHeight, classes.card)}>
            <Statistic
              title={t('home.statistics.charters.totalAllowed')}
              value={totalAllowed}
              precision={0}
              valueStyle={{
                color: THEME.HOME.STATISTICS.DEFAULT_COLOR,
              }}
              prefix={<BgColorsOutlined />}
              className={isMobileOrTablet ? classes.smallStatisticsValue : ''}
            />
          </Card>
        </Col>
        <Col className={classes.column} span={24}>
          <Card className={classNames(classes.fullHeight, classes.card)}>
            <Statistic
              title={t('home.statistics.charters.active')}
              value={statistics.active}
              precision={0}
              valueStyle={{
                color: activeChartersColor,
              }}
              prefix={<BgColorsOutlined />}
              className={isMobileOrTablet ? classes.smallStatisticsValue : ''}
            />
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

export default ChartersStatistics;
