import { message } from 'antd';
import { CancelTokenSource } from 'axios';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import KannelleSwitch from '../../../../components/switch/KannelleSwitch';
import { LOGGING_EVENT } from '../../../../Constants';
import { RootState } from '../../../../redux/RootState';
import { APIManager } from '../../../../services/api/APIManager';
import { updateCharterPermissions } from '../../../../services/api/ChartersService';
import {
  APICharterPermission,
  APICharterPermissionRoleAccess,
} from '../../../../services/api/types/ChartersServiceTypes';
import { ChangedFieldPermissionByRole } from '../../../../utils/types/CharterPermissionTypes';

type Props = {
  permission: APICharterPermission;
  role: APICharterPermissionRoleAccess;
  isChecked: boolean;
  callback: (permissionCode: string, changedFieldsByRole: ChangedFieldPermissionByRole[]) => void;
  isPermissionBeingUpdated: boolean;
  setIsPermissionBeingUpdated: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
};

const PermissionSwitch: FunctionComponent<Props> = ({
  role,
  permission,
  isChecked,
  callback,
  isPermissionBeingUpdated,
  setIsPermissionBeingUpdated,
  disabled = false,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const company = useSelector((state: RootState) => state.companies.current);
  const currentCharter = useSelector((state: RootState) => state.charters.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);

  const { t } = useTranslation();
  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());

  useEffect(() => {
    const cancelTokenSource = cancelTokenSourceRef.current;

    return (): void => {
      cancelTokenSource.cancel('Cancelled patching charter permissions due to component unmount.');
    };
  }, []);

  const handleSwitchPermission = (newHasAccess: boolean): void => {
    if (!currentCharter) {
      return;
    }

    const data = {
      roleCode: role.code,
      permissionCode: permission.code,
      access: newHasAccess,
      ...(newHasAccess ? { value: null } : {}), // If the permission is back to enabled, we reset the value
    };

    setIsPermissionBeingUpdated(true);
    setIsLoading(true);
    setIsError(false);
    updateCharterPermissions(currentCharter.id, data, cancelTokenSourceRef.current)
      .then(() => {
        message.success(t('charters.permissions.permissionUpdateSuccess'));

        loggingManager.logEvent(LOGGING_EVENT.UPDATE_CHARTER_PERMISSION, {
          companyId: company?.id,
          charterId: currentCharter.id,
          permission: permission.code,
          [role.code.toLowerCase()]: newHasAccess,
          ...(newHasAccess ? { value: 'null' } : {}),
        });

        callback(permission.code, [{ roleCode: role.code, changedField: newHasAccess }]);
      })
      .catch((e) => {
        message.error(t('charters.permissions.permissionUpdateError'));
        log.error(`Error while updating charter permissions`, e);
        setIsError(true);
        setIsPermissionBeingUpdated(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <span onClick={(e): void => e.stopPropagation()}>
      <KannelleSwitch
        isChecked={isChecked}
        disabled={disabled || isPermissionBeingUpdated}
        loading={isLoading}
        error={isError}
        size="small"
        callbackChange={handleSwitchPermission}
        labelActivated="form.activated"
        labelDeactivated="form.deactivated"
      />
    </span>
  );
};

export default PermissionSwitch;
