import { BgColorsOutlined } from '@ant-design/icons/lib';
import { Form, message, Row } from 'antd';
import { BreadcrumbProps } from 'antd/es/breadcrumb';
import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import { FormInstance } from 'antd/lib/form';
import { CancelTokenSource } from 'axios';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import ErrorAlert from '../../../components/alert/ErrorAlert';
import PageContentLoader from '../../../components/loader/PageContentLoader';
import KannellePageHeader from '../../../components/page-header/KannellePageHeader';
import { DEVICE_SIZES_QUERIES, LINK, LOGGING_EVENT, THEME } from '../../../Constants';
import useCanAccess from '../../../hooks/useCanAccess';
import useFetchCharterById from '../../../hooks/useFetchCharterById';
import { updateCharter } from '../../../redux/action/ChartersAction';
import { addOrUpdateCharterInCompany } from '../../../redux/action/CompaniesAction';
import { RootState } from '../../../redux/RootState';
import { APIManager } from '../../../services/api/APIManager';
import { updateCharterById } from '../../../services/api/ChartersService';
import { APIUpdateCharterByIdParams } from '../../../services/api/types/ChartersServiceTypes';
import { CharterIdPathParam } from '../../../services/navigation/NavigationConfigTypes';
import LogUtils from '../../../utils/LogUtils';
import { PermissionList } from '../../../utils/types/CharterPermissionTypes';
import CharterCreationOrUpdateForm from './CharterCreationOrUpdateForm';
import CharterThemeColorsEdition from './theme-edition/CharterThemeColorsEdition';

const useStyles = createUseStyles({
  pageContent: {
    backgroundColor: '#FFFFFF',
    padding: 24,
    margin: 24,
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      margin: 0,
      padding: '5px 10px',
    },
  },
  avatar: {
    backgroundColor: THEME.DEFAULT.MAIN_COLOR,
  },
  pageDescription: {
    marginBottom: 16,
    textAlign: 'justify',
    '& > a': {
      display: 'contents',
    },
  },
  pageDescriptionHint: {
    marginTop: '20px',
    textAlign: 'justify',
  },
  errorAlert: {
    marginTop: '30px',
  },
});

const CharterUpdate: FunctionComponent = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const formRef = useRef<FormInstance>(null);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { charterId } = useParams<CharterIdPathParam>();
  const [colorsList, setColorsList] = useState<string[]>([]);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const classes = useStyles();
  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());

  const { hasUserAccessToCharter } = useCanAccess(PermissionList.WEB_DASHBOARD);

  const currentCharter = useSelector((state: RootState) => state.charters.current);
  const currentCompany = useSelector((state: RootState) => state.companies.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);

  const { loading, isError } = useFetchCharterById(parseInt(charterId, 10));

  useEffect(() => {
    const cancelTokenSource = cancelTokenSourceRef.current;
    return (): void => {
      cancelTokenSource.cancel('Cancelled updating charter or its colors due to component unmount.');
    };
  }, []);

  const onResetClick = (): void => {
    if (formRef.current && form && currentCharter) {
      form.resetFields();
      form.setFieldsValue({ name: currentCharter.name, colors: currentCharter.firmColors });
      setColorsList(currentCharter.firmColors);
    }
  };

  const onColorsListChange = (newColorsList: string[]): void => {
    setColorsList(newColorsList);
    if (formRef.current && form) {
      form.setFieldsValue({
        colors: newColorsList,
      });
    }
  };

  useEffect(() => {
    if (!currentCharter) {
      return;
    }
    onColorsListChange(currentCharter.firmColors);
    // eslint-disable-next-line
  }, [currentCharter]);

  if (!currentCharter || !currentCompany) {
    return null;
  }

  const onUpdateCharterValidation = (): void => {
    if (!formRef.current && form) {
      return;
    }

    form.validateFields().then((values) => {
      setIsSubmitLoading(true);
      const data: APIUpdateCharterByIdParams = {
        colors: values.colors,
        name: values.name,
      };

      updateCharterById(currentCharter.id, data, cancelTokenSourceRef.current)
        .then((response) => {
          const updatedCharter = response.data;
          const event = LogUtils.getChartersCreateOrUpdateEvent(
            LOGGING_EVENT.UPDATE_CHARTER,
            updatedCharter.name,
            currentCompany.id,
            updatedCharter.id
          );
          loggingManager.logEventObject(event);
          dispatch(updateCharter(updatedCharter));
          dispatch(addOrUpdateCharterInCompany(currentCompany, updatedCharter));
          message.success(t('charters.update.success'));
          setIsSubmitLoading(false);
        })
        .catch((e) => {
          log.debug(e.message);
          message.error(t('charters.update.error'));
          setIsSubmitLoading(false);
        });
    });
  };

  const pageDescription = (
    <>
      {t('charters.update.pageDescription')}
      {t('support.global.emojiFingerRight')}
      <a href={`mailto:${t('support.global.supportEmail')}`}>{t('support.global.supportEmail')}</a>
      {t('support.global.emojiFingerLeft')}
      <div className={classes.pageDescriptionHint}>{t('charters.update.pageDescriptionHint')}</div>
    </>
  );

  const routes = [
    {
      path: '/charters',
      breadcrumbName: t('menu.charters'),
    },
    {
      path: `/charters/${currentCharter.id}`,
      breadcrumbName: currentCharter.name,
    },
    {
      path: location.pathname,
      breadcrumbName: t('charters.update.title'),
    },
  ];

  const breadcrumbProps: BreadcrumbProps = {
    itemRender: (route: Route) => {
      if (route.path === location.pathname) {
        return route.breadcrumbName;
      }
      return <Link to={route.path}>{route.breadcrumbName}</Link>;
    },
    routes,
  };

  if (isError) {
    return <ErrorAlert className={classes.errorAlert} message={t('ajaxError.charterFetch')} />;
  }

  if (loading) {
    return <PageContentLoader />;
  }

  if (!hasUserAccessToCharter(parseInt(charterId, 10))) {
    history.push(LINK.UNAUTHORIZED.path);
    return null;
  }

  return (
    <KannellePageHeader
      title={t('charters.update.title')}
      breadcrumb={breadcrumbProps}
      className={classes.pageContent}
      avatar={{ className: classes.avatar, icon: <BgColorsOutlined /> }}
    >
      <Row className={classes.pageDescription}>{pageDescription}</Row>

      <CharterCreationOrUpdateForm
        form={form}
        formRef={formRef}
        colorsList={colorsList}
        onColorsListChange={onColorsListChange}
        isLoading={isSubmitLoading}
        onSubmit={onUpdateCharterValidation}
        onReset={onResetClick}
        charterToEdit={currentCharter}
      />

      <CharterThemeColorsEdition />
    </KannellePageHeader>
  );
};

export default CharterUpdate;
