import { PlusOutlined } from '@ant-design/icons/lib';
import { Button } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { DEVICE_SIZES_QUERIES } from '../../Constants';

type Props = {
  onClick: () => void;
};

const useStyles = createUseStyles({
  addColorButton: {
    height: '100px',
    maxWidth: 150,
    minWidth: 100,
    boxShadow: '0 8px 8px 0 hsla(0, 0%, 0%, 0.15) !important',
    borderRadius: 8,
    whiteSpace: 'normal',

    [`@media screen and ${DEVICE_SIZES_QUERIES.LARGE}`]: {
      width: 140,
    },
    [`@media screen and ${DEVICE_SIZES_QUERIES.MEDIUM}`]: {
      width: 125,
    },
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      width: 100,
    },

    '& span': {
      marginLeft: 'unset !important',
    },
  },
});

const AddColorCard: FunctionComponent<Props> = ({ onClick }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button className={classes.addColorButton} icon={<PlusOutlined />} onClick={onClick}>
      {t('colorCard.addColor')}
    </Button>
  );
};

export default AddColorCard;
