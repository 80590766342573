import React, { FunctionComponent, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { VideoTrim } from '../../../pages/charters/charter-projects/components/project-editor/scene-timeline/utils/VideoTrimUtils';
import AnimationsUtils from '../../../utils/AnimationsUtils';
import { MaskCodes, PIP, Size } from '../../../utils/types/AnimationTypes';
import { MediaType } from '../../../utils/types/MediaTypes';

type Props = {
  currentTime: number;
  format: string;
  pip?: PIP;
  mask?: MaskCodes;
  sceneVideoPlayerSize?: Size;
  sceneVideoTrimValue?: VideoTrim;
  onPipLoadingChange?: (loading: boolean) => void;
};

type StyleProps = {
  sceneVideoPlayerSize?: Size;
};

const useStyles = createUseStyles({
  pipContainer: ({ sceneVideoPlayerSize }: StyleProps) => ({
    height: sceneVideoPlayerSize?.height ?? '100%',
    width: sceneVideoPlayerSize?.width ?? '100%',
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  }),
  pipImageContainer: {
    position: 'absolute',
    overflow: 'hidden',
  },
  pipImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    position: 'absolute',
  },
  invisibleImageForLoad: {
    display: 'none',
  },
});

const SceneVideoPlayerImagePIP: FunctionComponent<Props> = ({
  currentTime,
  format,
  pip,
  mask,
  sceneVideoPlayerSize,
  sceneVideoTrimValue,
  onPipLoadingChange,
}: Props) => {
  const [pipImageLoading, setPipImageLoading] = useState(false);
  const classes = useStyles({ sceneVideoPlayerSize });

  // Callback to maintain the pipImageLoading state in sync with the parent one
  useEffect(() => {
    if (onPipLoadingChange) {
      onPipLoadingChange(pipImageLoading);
    }
  }, [pipImageLoading, onPipLoadingChange]);

  // If there is no pip, or it's not an image or if the player size is not defined
  if (!(pip && pip.source.mediaType === MediaType.IMAGE && sceneVideoPlayerSize)) {
    return null;
  }

  // Check if the pip image must be displayed depending on the currentTime, its delay and its duration
  const pipStartTime = sceneVideoTrimValue?.trimStart ? pip.delay + sceneVideoTrimValue.trimStart : pip.delay;
  const isPipStarted = currentTime >= pipStartTime;
  const isPipEnded = currentTime > pipStartTime + pip.duration;
  const pipProgress = (currentTime - pipStartTime) / pip.duration;
  if (!isPipStarted || isPipEnded) {
    return null;
  }
  const pipImageEffectStyle = AnimationsUtils.computeImagePipEffectStyle(pipProgress, pip, sceneVideoPlayerSize);
  const pipPositionStyle = AnimationsUtils.computePipPositionAndSize(pip, sceneVideoPlayerSize, format, mask);

  return (
    <div className={classes.pipContainer}>
      {/* Invisible image to load the pipImage and display it only when fully loaded */}
      <img
        className={classes.invisibleImageForLoad}
        src={pip.source.croppedFileUrl ?? pip.source.url}
        alt="PiP img loader"
        onLoad={() => {
          setPipImageLoading(false);
        }}
      />

      {!pipImageLoading && (
        <div className={classes.pipImageContainer} style={pipPositionStyle}>
          <img
            className={classes.pipImage}
            src={pip.source.croppedFileUrl ?? pip.source.url}
            alt="PiP img"
            style={pipImageEffectStyle}
          />
        </div>
      )}
    </div>
  );
};

export default SceneVideoPlayerImagePIP;
