import { Col, Input, Row, Select, Slider } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { THEME } from '../../../../Constants';

const { Option } = Select;

const useStyles = createUseStyles({
  fontSettingsToolbar: {
    border: `1px solid ${THEME.DEFAULT.BORDER_COLOR}`,
    '& .ant-input, & .ant-select': {
      border: 'none',
      borderRadius: '0',
      height: '100%',
    },
    '& .ant-input': {
      borderRight: `1px solid ${THEME.DEFAULT.BORDER_COLOR}`,
    },
  },
  fontSizeSelector: {
    width: 80,
    '& .ant-select-selection-item': {
      '&::after': {
        content: "'px' !important",
        visibility: 'visible !important',
      },
    },
  },
});

type Props = {
  fontSize: number;
  fontTextExample?: string;
  onFontSizeChange: (value: number | undefined) => void;
  onFontTextExampleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const FontSettingsToolbar: FunctionComponent<Props> = ({
  fontSize,
  fontTextExample,
  onFontSizeChange,
  onFontTextExampleChange,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const minFontSize = 8;
  const maxFontSize = 120;
  const fontSizeOptions = [8, 12, 14, 20, 24, 32, 40, 64, 96, 120];

  return (
    <Row className={classes.fontSettingsToolbar}>
      <Col xs={8} sm={9} md={10} lg={11} xl={12} className="text-example-container">
        <Input
          placeholder={t('charters.fonts.fontTextExamplePlaceholder')}
          value={fontTextExample}
          onChange={onFontTextExampleChange}
        />
      </Col>
      <Col xs={8} sm={6} md={4} lg={3} xl={2} className="font-size-select-container">
        <Select
          value={fontSize}
          onChange={onFontSizeChange}
          className={classes.fontSizeSelector}
          optionLabelProp="label"
          bordered={false}
        >
          {fontSizeOptions.map((fontSizeOption: number) => (
            <Option value={fontSizeOption} key={`fontSizeOption_${fontSizeOption}px`} label={fontSizeOption}>
              {fontSizeOption}px
            </Option>
          ))}
        </Select>
      </Col>
      <Col xs={8} sm={9} md={10} lg={10} xl={10} className="font-size-slider-container">
        <Slider
          min={minFontSize}
          max={maxFontSize}
          onChange={onFontSizeChange}
          value={fontSize}
          tipFormatter={(fontSizeValue) => `${fontSizeValue}px`}
        />
      </Col>
    </Row>
  );
};

export default FontSettingsToolbar;
