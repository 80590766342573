import { EditFilled } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { Ref, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import Preformatted from '../../../../../../components/preformatted/Preformatted';
import { Asset } from '../../../../../../services/api/types/ChartersServiceTypes';
import { Music } from '../../../../../../services/api/types/MusicsServiceTypes';
import AssetsUtils from '../../../../../../utils/AssetsUtils';
import StringUtils from '../../../../../../utils/StringUtils';
import { ProjectMusicFile } from '../../../../../../utils/types/ProjectTypes';
import ProjectMusicSettingsModal from './ProjectMusicSettingsModal';

type Props = {
  value?: ProjectMusicFile;
  onChange?: (newValue: ProjectMusicFile | undefined) => void;
  fieldLabel: string;
};

const useStyles = createUseStyles({
  configureMusicButton: {
    padding: 0,
  },
  fieldValueContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: 6,
  },
  selectedMusicContainer: {
    cursor: 'pointer',
    '& .anticon': {
      marginRight: 4,
    },
  },
});

const ProjectMusicSettings = React.forwardRef(({ value, onChange, fieldLabel }: Props, ref: Ref<any>) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const classes = useStyles();
  const { t } = useTranslation();

  const isValuePublicMusic = value && AssetsUtils.isPublicMusic(value);

  // Called to update the upper `form` object
  const triggerChange = (changedValue: ProjectMusicFile | undefined): void => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  const onOpenModal = (): void => {
    setIsModalVisible(true);
  };

  const onCloseModal = (): void => {
    setIsModalVisible(false);
  };

  const onSelectMusic = (music: ProjectMusicFile) => {
    triggerChange(music);
    onCloseModal();
  };

  const renderMusicValue = () => {
    if (!value) {
      return null;
    }

    let musicFileName;
    if (isValuePublicMusic) {
      musicFileName = (value as Music).name;
    } else {
      musicFileName = (value as Asset).publicName ?? StringUtils.getFileNameFromUrl((value as Asset).url);
    }
    return (
      <span className={classes.fieldValueContainer}>
        <Preformatted>{musicFileName}</Preformatted>
      </span>
    );
  };

  return (
    <div ref={ref}>
      <div onClick={onOpenModal}>
        {value ? (
          <span className={classes.selectedMusicContainer}>
            {renderMusicValue()}
            <Tooltip title={t('global.edit')}>
              <EditFilled />
            </Tooltip>
          </span>
        ) : (
          <Button type="link" className={classNames(classes.configureMusicButton)}>
            {t('charters.projects.projectEditor.projectFinalization.music.editButton')}
          </Button>
        )}
      </div>

      <ProjectMusicSettingsModal
        value={value}
        isVisible={isModalVisible}
        fieldLabel={fieldLabel}
        onCancel={onCloseModal}
        onSelectMusic={onSelectMusic}
      />
    </div>
  );
});

export default ProjectMusicSettings;
