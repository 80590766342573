import React, { FunctionComponent } from 'react';
import { Theme } from '../../../../../utils/ThemeUtils';
import { Animations } from '../../../../../utils/types/AnimationTypes';
import { LottieObject, ThemeAnimationProps } from '../../../../../utils/types/ThemeTypes';
import CharterAnimationThemeEdition from '../CharterAnimationThemeEdition';

const lottieArray: LottieObject[] = [
  {
    key: 'animations.list.dodge.publicName',
    aliases: ['Dodge'],
    animationName: Animations.DODGE.code,
    fields: [
      'shapeMain',
      'shapeShadow',
      'shapeShadowOpacity',
      'shapeSecondary',
      'shapeNeutral',
      'sourceNeutral',
      'textShadow',
      'textShadowOpacity',
      'figure',
      'figureBlink',
      'chartEmpty',
      'background',
    ],
  },
  {
    key: 'animations.list.lincoln.publicName',
    aliases: ['Lincoln'],
    animationName: Animations.LINCOLN.code,
    fields: [
      'shapeMain',
      'shapeShadow',
      'shapeShadowOpacity',
      'shapeSecondary',
      'shapeNeutral',
      'chartFill',
      'chartEmpty',
      'figure',
      'figureBlink',
      'text',
      'ctaTitleBlinking',
      'textShadow',
      'textShadowOpacity',
      'source',
      'shapeCosmetic',
      'shapeCosmeticOpacity',
      'background',
    ],
  },
  {
    key: 'animations.list.ram.publicName',
    aliases: ['Ram'],
    animationName: Animations.RAM.code,
    fields: [
      'shapeMain',
      'shapeShadow',
      'shapeShadowOpacity',
      'shapeSecondary',
      'shapeNeutral',
      'title',
      'coverText',
      'coverTextOpacity',
      'ctaTitleBlinking',
      'background',
    ],
  },
  {
    key: 'animations.list.roush.publicName',
    aliases: ['Roush'],
    animationName: Animations.ROUSH.code,
    fields: [
      'shapeMain',
      'shapeShadow',
      'shapeShadowOpacity',
      'shapeSecondary',
      'shapeNeutral',
      'cta',
      'textShadow',
      'textShadowOpacity',
      'ctaTitle',
      'ctaTitleBlinking',
      'background',
    ],
  },
  {
    key: 'animations.list.delorean.publicName',
    aliases: ['Delorean'],
    animationName: Animations.DELOREAN.code,
    fields: [
      'shapeMain',
      'shapeShadow',
      'shapeShadowOpacity',
      'shapeSecondary',
      'shapeNeutral',
      'title',
      'titleBlinking',
      'text',
      'sourceNeutral',
      'figure',
      'negativeBarBottom',
      'negativeBarTop',
      'positiveBarBottom',
      'positiveBarTop',
      'backgroundBarOpacity',
      'background',
    ],
  },
  {
    key: 'animations.list.austin.publicName',
    aliases: ['Austin'],
    animationName: Animations.AUSTIN.code,
    fields: [
      'shapeMain',
      'shapeShadow',
      'shapeShadowOpacity',
      'shapeSecondary',
      'shapeNeutral',
      'title',
      'titleBlinking',
      'text',
      'sourceNeutral',
      'figure',
      'negativeBarBottom',
      'negativeBarTop',
      'positiveBarBottom',
      'positiveBarTop',
      'backgroundBarOpacity',
      'transparentBackground',
      'backgroundOpacity',
    ],
  },
  {
    key: 'animations.list.aventi.publicName',
    aliases: ['Aventi'],
    animationName: Animations.AVENTI.code,
    fields: [
      'shapeMain',
      'shapeShadow',
      'shapeShadowOpacity',
      'shapeSecondary',
      'shapeNeutral',
      'cta',
      'textShadow',
      'textShadowOpacity',
      'ctaTitle',
      'ctaTitleBlinking',
      'background',
      'transparentBackground',
      'backgroundOpacity',
    ],
  },
  {
    key: 'animations.list.mclaren.publicName',
    aliases: ['Mclaren'],
    animationName: Animations.MCLAREN.code,
    fields: [
      'shapeMain',
      'shapeShadow',
      'shapeShadowOpacity',
      'shapeSecondary',
      'shapeNeutral',
      'sourceNeutral',
      'textShadow',
      'textShadowOpacity',
      'figure',
      'figureBlink',
      'chartEmpty',
      'background',
      'transparentBackground',
      'backgroundOpacity',
    ],
  },
  {
    key: 'animations.list.toyota.publicName',
    aliases: ['Toyota'],
    animationName: Animations.TOYOTA.code,
    fields: [
      'shapeMain',
      'shapeShadow',
      'shapeShadowOpacity',
      'shapeSecondary',
      'shapeNeutral',
      'chartFill',
      'chartEmpty',
      'figure',
      'figureBlink',
      'text',
      'ctaTitleBlinking',
      'textShadow',
      'textShadowOpacity',
      'source',
      'shapeCosmetic',
      'shapeCosmeticOpacity',
      'transparentBackground',
      'backgroundOpacity',
    ],
  },
  {
    key: 'animations.list.vector.publicName',
    aliases: ['Vector'],
    animationName: Animations.VECTOR.code,
    fields: [
      'shapeMain',
      'shapeShadow',
      'shapeShadowOpacity',
      'shapeSecondary',
      'shapeNeutral',
      'title',
      'coverText',
      'coverTextOpacity',
      'ctaTitleBlinking',
      'transparentBackground',
      'backgroundOpacity',
    ],
  },
  {
    key: 'animations.list.butterfly.publicName',
    aliases: ['Butterfly'],
    animationName: Animations.BUTTERFLY.code,
    fields: ['text', 'background'],
  },
  {
    key: 'animations.list.fox.publicName',
    aliases: ['Fox'],
    animationName: Animations.FOX.code,
    fields: ['text', 'background'],
  },
  {
    key: 'animations.list.owl.publicName',
    aliases: ['Owl'],
    animationName: Animations.OWL.code,
    fields: ['text', 'background'],
  },
  {
    key: 'animations.list.mini.publicName',
    aliases: ['Mini'],
    animationName: Animations.MINI.code,
    isStaticAnimation: true,
    fields: ['maskGradientTop', 'maskGradientBottom'],
  },
];

const BogotaTheme: FunctionComponent<ThemeAnimationProps> = ({
  theme,
  charterThemeColors,
  animationThemeColor,
  callback,
  onReset,
  onSubmit,
  disabledActions,
  loadingActions,
}: ThemeAnimationProps) => {
  return (
    <CharterAnimationThemeEdition
      code={theme.code}
      charterThemeColors={charterThemeColors}
      animationThemeColor={animationThemeColor}
      onEditColor={(newColors: Theme[]): void => callback(newColors)}
      lottieArray={lottieArray}
      onReset={onReset}
      onSubmit={onSubmit}
      disabledActions={disabledActions}
      loadingActions={loadingActions}
    />
  );
};

export default BogotaTheme;
