import { Form, Radio } from 'antd';
import map from 'lodash/map';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { DEVICE_SIZES_QUERIES } from '../../Constants';

type Props = {
  name: string;
  label?: string;
  extra?: string;
  isReadOnly?: boolean;
  hasFeedback?: boolean;
  options: Record<string, { code: string; key: string }>;
};

const useStyles = createUseStyles({
  radioGroup: {
    [`@media screen and ${DEVICE_SIZES_QUERIES.EXTRA_LARGE}`]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  radioItem: {
    [`@media screen and ${DEVICE_SIZES_QUERIES.EXTRA_LARGE}`]: {
      width: 250,
      height: 'initial',
      minHeight: '32px',
    },

    marginBottom: 8,
  },
});

const SelectAsRadio: FunctionComponent<Props> = ({
  name,
  label,
  extra,
  options,
  isReadOnly,
  hasFeedback = true,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Form.Item label={label} name={name} rules={[{ required: true }]} extra={extra} hasFeedback={hasFeedback}>
      <Radio.Group buttonStyle="solid" className={classes.radioGroup}>
        {map(options, (option) => {
          return (
            <Radio.Button className={classes.radioItem} key={option.code} value={option.code} disabled={isReadOnly}>
              <span>{t(option.key)}</span>
            </Radio.Button>
          );
        })}
      </Radio.Group>
    </Form.Item>
  );
};

export default SelectAsRadio;
