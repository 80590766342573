import { Badge } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { createUseStyles } from 'react-jss';
import { THEME } from '../../../../../../Constants';
import useCanAccess from '../../../../../../hooks/useCanAccess';
import { PermissionList } from '../../../../../../utils/types/CharterPermissionTypes';
import { ProjectScene } from '../../../../../../utils/types/ProjectTypes';
import { AnimationTheme } from '../../../../../../utils/types/ThemeTypes';
import AddProjectSceneButton from '../AddProjectSceneButton';
import ProjectSceneCard from './ProjectSceneCard';

type Props = {
  isProjectLoading: boolean;
  onSelectScene: (scene: ProjectScene) => void;
  scenes?: ProjectScene[];
  selectedScene?: ProjectScene;
  format?: string;
  theme: AnimationTheme;
  customColors: boolean;
  onAddScene: (addedScene: ProjectScene) => void;
  onDeleteScene: (sceneToDelete: ProjectScene) => void;
  onDuplicateScene: (sceneToDuplicate: ProjectScene) => void;
  onReorderScenes: (dropResult: DropResult) => void;
};

type StyleProps = {
  isProjectLoading: boolean;
};

const useStyles = createUseStyles({
  scenesCarousel: {
    margin: '16px 0',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: THEME.DEFAULT.FADE_COLOR,
    padding: '5px 10px',
    borderRadius: 5,
    overflow: 'auto',
  },
  scenesContainer: {
    display: 'flex',
  },
  sceneCardContainer: ({ isProjectLoading }: StyleProps) => ({
    marginRight: 18,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: isProjectLoading ? 'not-allowed' : 'pointer !important',
  }),
  sceneIndexBadge: {
    marginTop: 5,
    '& .ant-badge-count': {
      backgroundColor: THEME.DEFAULT.MAIN_TEXT_COLOR,
      boxShadow: 'none',
    },
  },
  selectedSceneIndexBadge: {
    '& .ant-badge-count': {
      backgroundColor: THEME.DEFAULT.MAIN_COLOR,
    },
  },
});

const ProjectScenesCarousel: FunctionComponent<Props> = ({
  isProjectLoading,
  scenes,
  selectedScene,
  onSelectScene,
  format,
  theme,
  customColors,
  onAddScene,
  onDeleteScene,
  onDuplicateScene,
  onReorderScenes,
}: Props) => {
  const classes = useStyles({ isProjectLoading });

  const permissionToReorderScenes = useCanAccess(PermissionList.SCENE_REORDER);

  if (!(scenes && scenes.length)) {
    return null;
  }

  return (
    <div className={classNames(classes.scenesCarousel, 'scenesCarousel')}>
      <DragDropContext
        onDragEnd={
          !isProjectLoading && permissionToReorderScenes.hasUserAccess()
            ? onReorderScenes
            : permissionToReorderScenes.renderUnauthorizedMessageModal
        }
      >
        <Droppable droppableId="project-scenes" direction="horizontal" isDropDisabled={isProjectLoading}>
          {(provided): JSX.Element => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={classNames(classes.scenesContainer, 'scenesContainer')}
            >
              {scenes?.map((scene, index) => {
                const isSelected = selectedScene ? selectedScene.id === scene.id : false;

                return (
                  <Draggable
                    index={index}
                    draggableId={scene.id.toString()}
                    key={scene.id}
                    isDragDisabled={isProjectLoading}
                  >
                    {(draggableProvided): JSX.Element => (
                      <div
                        ref={draggableProvided.innerRef}
                        className={classes.sceneCardContainer}
                        onClick={() => onSelectScene(scene)}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                      >
                        <ProjectSceneCard
                          scene={scene}
                          canDeleteScene={scenes?.length > 1}
                          isSelectedScene={isSelected}
                          isProjectLoading={isProjectLoading}
                          format={format}
                          theme={theme}
                          customColors={customColors}
                          onDeleteScene={onDeleteScene}
                          onDuplicateScene={onDuplicateScene}
                        />
                        <Badge
                          count={index + 1}
                          size="small"
                          className={classNames(classes.sceneIndexBadge, isSelected && classes.selectedSceneIndexBadge)}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <AddProjectSceneButton
        isProjectLoading={isProjectLoading}
        format={format}
        theme={theme}
        customColors={customColors}
        onAddScene={onAddScene}
        scenes={scenes}
      />
    </div>
  );
};

export default ProjectScenesCarousel;
