import { FileTextOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RadioChangeEvent } from 'antd/lib/radio';
import find from 'lodash/find';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import ErrorAlert from '../../components/alert/ErrorAlert';
import KannellePageHeader from '../../components/page-header/KannellePageHeader';
import { ANALYTICS, LOGGING_EVENT } from '../../Constants';
import { RootState } from '../../redux/RootState';
import { APIManager } from '../../services/api/APIManager';
import { getCompanyAnalytics } from '../../services/api/CompaniesService';
import {
  APICompanyAnalyticsEventAggregation,
  APICompanyAnalyticsEvents,
  APICompanyAnalyticsEventsOfType,
  APICompanyAnalyticsEventType,
  APICompanyAnalyticsResponse,
  APICompanyAnalyticsTimeRange,
} from '../../services/api/types/CompaniesServiceTypes';
import AnalyticsUtils from '../../utils/AnalyticsUtils';
import CompanyAnalyticsDataCollectionDateDisclaimer from './CompanyAnalyticsDataCollectionDateDisclaimer';
import CompanyAnalyticsGlobalGraph from './CompanyAnalyticsGlobalGraph';
import CompanyAnalyticsPerUser from './CompanyAnalyticsPerUser';
import CompanyAnalyticsSettings from './CompanyAnalyticsSettings';

const { Title } = Typography;

const useStyles = createUseStyles({
  pageTitle: {
    marginBottom: '5px !important',
  },
  perUserTableTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  csvLink: {
    marginBottom: '0.5em',
  },
});

const CompanyAnalytics: FunctionComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const currentUser = useSelector((state: RootState) => state.user.user);
  const company = useSelector((state: RootState) => state.companies.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);

  const [analyticsLoading, setAnalyticsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [eventType, setEventType] = useState<APICompanyAnalyticsEventType>(
    ANALYTICS.EVENT_TYPES.NB_SESSION.code as APICompanyAnalyticsEventType
  );
  const [timeRange, setTimeRange] = useState<APICompanyAnalyticsTimeRange>(
    ANALYTICS.TIME_RANGES.SIX_LAST_MONTHS.code as APICompanyAnalyticsTimeRange
  );
  const [excludeKnlTeam, setExcludeKnlTeam] = useState(true);
  const [aggregationType, setAggregationType] = useState<APICompanyAnalyticsEventAggregation>(
    ANALYTICS.AGGREGATION_TYPES.TOTAL.code as APICompanyAnalyticsEventAggregation
  );
  const [analyticsData, setAnalyticsData] = useState<APICompanyAnalyticsEvents[]>();
  const [
    relevantDataForEventAndAggregation,
    setRelevantDataForEventAndAggregation,
  ] = useState<APICompanyAnalyticsEventsOfType>();

  const eventDescriptionObject = find(ANALYTICS.EVENT_TYPES, (e) => e.code === eventType);
  const eventTitle = eventDescriptionObject ? t(eventDescriptionObject.key) : '';

  useEffect(() => {
    if (!company || !currentUser) {
      return undefined;
    }

    const cancelTokenSource = APIManager.getCancelToken();
    const startDate = AnalyticsUtils.getStartDateAsISOFromTimeRange(timeRange);
    const endDate = AnalyticsUtils.getEndDateAsISO();

    setAnalyticsLoading(true);

    loggingManager.logEvent(LOGGING_EVENT.FETCH_COMPANY_ANALYTICS, {
      companyId: company?.id,
      startDate,
      endDate,
      excludeKnlTeam,
    });

    getCompanyAnalytics(company.id, startDate, endDate, excludeKnlTeam, cancelTokenSource)
      .then((companyAnalytics: APICompanyAnalyticsResponse) => {
        setIsError(false);
        setAnalyticsData(companyAnalytics.data);
      })
      .catch((e: any) => {
        log.debug(e.message);
        setIsError(true);
      })
      .finally(() => {
        setAnalyticsLoading(false);
      });

    return (): void => {
      cancelTokenSource.cancel('Cancelled fetching company analytics due to component unmount.');
    };
  }, [company, timeRange, excludeKnlTeam]);

  useEffect(() => {
    if (!analyticsData) {
      return;
    }

    setRelevantDataForEventAndAggregation(
      AnalyticsUtils.getEventsDataByEventAndAggregation(analyticsData, eventType, aggregationType)
    );
  }, [analyticsData, eventType, aggregationType]);

  const onEventTypeChange = (selectedType: APICompanyAnalyticsEventType): void => {
    setEventType(selectedType);

    const startDate = AnalyticsUtils.getStartDateAsISOFromTimeRange(timeRange);
    const endDate = AnalyticsUtils.getEndDateAsISO();
    loggingManager.logEvent(LOGGING_EVENT.COMPANY_ANALYTICS_EVENT_TYPE_CHANGED, {
      companyId: company?.id,
      startDate,
      endDate,
      eventType: selectedType,
      excludeKnlTeam,
    });
  };

  const onTimeRangeChange = (e: RadioChangeEvent): void => {
    setTimeRange(e.target.value);
  };

  const onExcludeKnlTeamChange = (event: CheckboxChangeEvent): void => {
    setExcludeKnlTeam(event.target.checked);
  };

  const onAggregationTypeChange = (e: RadioChangeEvent): void => {
    setAggregationType(e.target.value);
  };

  const onDownloadCSV = (): void => {
    const startDate = AnalyticsUtils.getStartDateAsISOFromTimeRange(timeRange);
    const endDate = AnalyticsUtils.getEndDateAsISO();

    loggingManager.logEvent(LOGGING_EVENT.COMPANY_ANALYTICS_CSV_DOWNLOAD, {
      companyId: company?.id,
      startDate,
      endDate,
      excludeKnlTeam,
    });
  };

  if (isError || !company) {
    return <ErrorAlert message={t('ajaxError.statisticsFetch')} />;
  }

  return (
    <>
      <KannellePageHeader
        title={
          <Title id="analytics" level={3} className={classes.pageTitle}>
            {t('analytics.title')}
          </Title>
        }
      >
        {/* Data collection start date disclaimer */}
        <CompanyAnalyticsDataCollectionDateDisclaimer excludeKnlTeam={excludeKnlTeam} />

        {/* Analytics settings */}
        <Title level={4}>{t('analytics.configuration')}</Title>
        <CompanyAnalyticsSettings
          onEventTypeChange={onEventTypeChange}
          eventType={eventType}
          onTimeRangeChange={onTimeRangeChange}
          timeRange={timeRange}
          onExcludeKnlTeamChange={onExcludeKnlTeamChange}
          excludeKnlTeam={excludeKnlTeam}
          loading={analyticsLoading}
        />

        {/* Graph section */}
        <Title level={4}>{t('analytics.global')}</Title>

        <CompanyAnalyticsGlobalGraph
          periodEvents={relevantDataForEventAndAggregation?.periods}
          eventTitle={eventTitle}
          onAggregationTypeChange={onAggregationTypeChange}
          aggregationType={aggregationType}
          loading={analyticsLoading}
        />

        {/* Analytics per user section */}
        <div className={classes.perUserTableTitleContainer}>
          <Title level={4}>{t('analytics.perUser')}</Title>
          <CSVLink
            data={
              relevantDataForEventAndAggregation &&
              relevantDataForEventAndAggregation.items &&
              relevantDataForEventAndAggregation.items.length > 0
                ? AnalyticsUtils.buildCSVUserEventsForPeriodData(t, relevantDataForEventAndAggregation.items)
                : []
            }
            className={classes.csvLink}
            target="_blank"
            filename={AnalyticsUtils.generateCSVFilename(company.name, eventType, timeRange)}
          >
            <Button
              type="primary"
              icon={<FileTextOutlined />}
              onClick={onDownloadCSV}
              disabled={
                !(
                  relevantDataForEventAndAggregation &&
                  relevantDataForEventAndAggregation.items &&
                  relevantDataForEventAndAggregation.items.length > 0
                ) || analyticsLoading
              }
            >
              {t('analytics.exportCSV')}
            </Button>
          </CSVLink>
        </div>
        <CompanyAnalyticsPerUser tableData={relevantDataForEventAndAggregation?.items} loading={analyticsLoading} />
      </KannellePageHeader>
    </>
  );
};

export default CompanyAnalytics;
