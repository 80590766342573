import { DeleteOutlined } from '@ant-design/icons';
import { SettingOutlined, UserOutlined } from '@ant-design/icons/lib';
import { Button, Card, Col, Empty, message, Popconfirm, Row, Tooltip, Typography } from 'antd';
import { BreadcrumbProps } from 'antd/es/breadcrumb';
import { Route as RouteType } from 'antd/lib/breadcrumb/Breadcrumb';
import { CancelTokenSource } from 'axios';
import { find } from 'lodash';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoDuplicateOutline } from 'react-icons/io5';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import ColorCard from '../../../components/card/ColorCard';
import EmptyPageContent from '../../../components/empty/EmptyPageContent';
import PageContentLoader from '../../../components/loader/PageContentLoader';
import KannellePageHeader from '../../../components/page-header/KannellePageHeader';
import Preformatted from '../../../components/preformatted/Preformatted';
import CharterSupportCard from '../../../components/support-card/CharterSupportCard';
import { LINK } from '../../../Constants';
import { Roles } from '../../../core/rule/RolesTypes';
import useCanAccess from '../../../hooks/useCanAccess';
import useFetchCharterById from '../../../hooks/useFetchCharterById';
import { addCreatedCharter } from '../../../redux/action/ChartersAction';
import { RootState } from '../../../redux/RootState';
import { APIManager } from '../../../services/api/APIManager';
import { cloneCharter, updateCharterById } from '../../../services/api/ChartersService';
import { APIFullModelCharter, Asset } from '../../../services/api/types/ChartersServiceTypes';
import { CharterIdPathParam } from '../../../services/navigation/NavigationConfigTypes';
import CompaniesUtils from '../../../utils/CompaniesUtils';
import { PermissionList } from '../../../utils/types/CharterPermissionTypes';

const { Title } = Typography;
const useStyles = createUseStyles({
  colorCard: {
    boxShadow: '0 8px 8px 0 hsla(0, 0%, 0%, 0.15) !important',
    borderRadius: 8,
    overflow: 'hidden',

    '& .ant-card-body': {
      padding: '8px 16px',
    },

    '& .ant-card-actions': {
      backgroundColor: 'inherit',
      border: 'unset',
      '& li': {
        margin: '0 0 8px 0',
      },
    },
  },
  imageCard: {
    borderRadius: 8,
    height: 150,
    overflow: 'hidden',
    boxShadow: '0 8px 8px 0 hsla(0, 0%, 0%, 0.15) !important',
    border: 'unset',
    // TODO temporary color we will have the information light | dark later
    backgroundColor: '#dddddd',
    '& .ant-card-body': {
      padding: 8,
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    '& img': {
      borderRadius: 8,
    },
  },
  darkImageCard: {
    backgroundColor: '#333',
  },
  addColorCard: {
    backgroundColor: 'inherit',
    borderRadius: 8,
    height: '100%',
    border: 'dashed 2px #cccccc',
    color: '#cccccc',
    fontSize: 16,
  },
  bigIcons: {
    fontSize: 32,
  },
  colorButton: {
    '&:hover': {},
  },
  fullHeight: {
    height: '100%',
  },
  logo: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  colorCol: {
    maxWidth: 150,
    minWidth: 100,
  },
  supportCard: {
    marginTop: 50,
  },
  duplicateIcon: {
    marginRight: 4,
    position: 'relative',
    top: 2,
  },
});

const CharterHome: FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const { charterId } = useParams<CharterIdPathParam>();
  const query = new URLSearchParams(location.search);
  const from = query.get('from') || LINK.CHARTERS.key;
  const { t } = useTranslation();
  const [chart, setChart] = useState<APIFullModelCharter>();
  const [isCloneDisabled, setIsCloneDisabled] = useState(false);
  const [isCloneLoading, setIsCloneLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());
  const dispatch = useDispatch();

  const { loading, fetchedCharter, isError } = useFetchCharterById(parseInt(charterId, 10));
  const charter = useSelector((state: RootState) => {
    const charterFromList = find(state.charters.list, { id: state.charters.current?.id });
    return charterFromList;
  });
  const charters = useSelector((state: RootState) => state.charters.list);
  const numberOfCharters = charters?.length || 0;
  const companies = useSelector((state: RootState) => state.companies.list);
  const bundle = useSelector((state: RootState) => state.companies.bundle);

  const currentCompany = useSelector((state: RootState) => state.companies.current);
  const currentUserRoleInCharter = charter?.currentUser?.role || Roles.Creator;
  const isUserPoc = currentCompany?.isUserPoc || false;
  const isUserKnlTeam = CompaniesUtils.checkIsKnlProfile(companies || []);

  const { hasUserAccessToCharter } = useCanAccess(PermissionList.WEB_DASHBOARD);

  useEffect((): void => {
    if (!fetchedCharter) {
      return;
    }

    setChart(fetchedCharter);
  }, [fetchedCharter]);

  useEffect((): void => {
    if (!charters || !bundle) {
      return;
    }

    setIsCloneDisabled(charters?.length >= (bundle?.featureValues?.MAX_CHARTERS || 0));
  }, [charters, bundle]);

  if (loading) {
    return <PageContentLoader />;
  }
  if (!chart || isError) {
    return <EmptyPageContent />;
  }

  if (!hasUserAccessToCharter(parseInt(charterId, 10))) {
    history.push(LINK.UNAUTHORIZED.path);
    return null;
  }

  const fromLink = filter(LINK, (link) => link.key === from)[0];
  const routes = [
    {
      path: fromLink.path,
      breadcrumbName: t(fromLink.title),
    },
    {
      path: location.pathname,
      breadcrumbName: chart?.name,
    },
  ];

  const breadcrumbProps: BreadcrumbProps = {
    itemRender: (route: RouteType) => {
      if (route.path === location.pathname) {
        return route.breadcrumbName;
      }
      return <Link to={route.path}>{route.breadcrumbName}</Link>;
    },
    routes,
  };

  const handleGroupClone = () => {
    setIsCloneLoading(true);
    cloneCharter(parseInt(charterId, 10), cancelTokenSourceRef.current)
      .then((response) => {
        dispatch(addCreatedCharter(response.data));
        message.success(t('charters.clone.success'));

        const path = LINK.CHARTER.path.replace(':charterId', response.data.id.toString());
        history.push(path);
      })
      .catch((e) => {
        log.debug(e.message);
        message.error(t('charters.clone.error'));
      })
      .finally(() => {
        setIsCloneLoading(false);
      });
  };

  const handleArchiveGroup = () => {
    setIsDeleteLoading(true);
    updateCharterById(parseInt(charterId, 10), { isActive: false })
      .then(() => {
        message.success(t('charters.archiveSuccess'));
        history.push(LINK.HOME.path);
      })
      .catch(() => {
        message.success(t('charters.archiveError'));
      })
      .finally(() => {
        setIsDeleteConfirmVisible(false);
        setIsDeleteLoading(false);
      });
  };

  return (
    <>
      <KannellePageHeader
        title={<Title level={1}>{chart.name}</Title>}
        subTitle={<Preformatted>id: {chart.id}</Preformatted>}
        breadcrumb={breadcrumbProps}
        extra={
          (currentUserRoleInCharter !== Roles.Creator || isUserPoc) && [
            isUserKnlTeam && (
              <Popconfirm
                title={t('charters.archiveCharterConfirm')}
                onConfirm={handleArchiveGroup}
                okText={t('global.yes')}
                cancelText={t('global.no')}
                placement="bottom"
                onCancel={() => setIsDeleteConfirmVisible(false)}
                visible={isDeleteConfirmVisible}
                okButtonProps={{ loading: isDeleteLoading, disabled: isDeleteLoading }}
                cancelButtonProps={{ disabled: isDeleteLoading }}
              >
                <Tooltip title={numberOfCharters === 1 ? t('charters.cantArchive') : undefined}>
                  <Button
                    key="delete_user"
                    danger
                    type="primary"
                    loading={isDeleteLoading}
                    onClick={() => setIsDeleteConfirmVisible(true)}
                    disabled={numberOfCharters === 1}
                  >
                    <DeleteOutlined /> {t('charters.archiveCharter')}
                  </Button>
                </Tooltip>
              </Popconfirm>
            ),
            <Link key="edit_user_link" to={`/charters/${charterId}/users`}>
              <Button key="edit_user" type="primary">
                <UserOutlined /> {t('charters.editUsers')}
              </Button>
            </Link>,
            charter && charter.currentUser && (
              <Link key="edit_chart_link" to={`/charters/${charterId}/update`}>
                <Button key="edit_chart" type="primary">
                  <SettingOutlined /> {t('charters.editCharter')}
                </Button>
              </Link>
            ),
            charter && charter.currentUser && currentUserRoleInCharter !== Roles.Creator && (
              <Tooltip title={isCloneDisabled ? t('charters.clone.disabled') : undefined} placement="top">
                <Button
                  key="clone_group"
                  type="primary"
                  onClick={handleGroupClone}
                  disabled={isCloneLoading || isCloneDisabled}
                  loading={isCloneLoading}
                >
                  <IoDuplicateOutline className={classes.duplicateIcon} /> {t('charters.clone.title')}
                </Button>
              </Tooltip>
            ),
          ]
        }
      >
        <Row gutter={[16, 24]}>
          <Col sm={24} md={12} lg={12}>
            <Title id="colorTitle" level={3}>
              {t('charters.colors')}
            </Title>
            <Row gutter={[16, 16]}>
              {isEmpty(chart.firmColors) ? (
                <Empty description={t('global.nodata')} />
              ) : (
                chart.firmColors
                  .map((color: string, index: number) => ({ color, id: `${color}-${index}` }))
                  .map(({ color, id }) => {
                    return (
                      <Col key={`col_card_color_${id}`} className={classes.colorCol} span={12}>
                        <ColorCard color={color} />
                      </Col>
                    );
                  })
              )}
            </Row>
          </Col>
          <Col sm={24} md={12} lg={12}>
            <Title id="colorTitle" level={3}>
              {t('charters.logo.title')}
            </Title>
            <Row gutter={[16, 16]}>
              {isEmpty(chart.logos) ? (
                <Empty description={t('global.nodata')} />
              ) : (
                chart.logos.map((logo: Asset) => {
                  return (
                    <Col span={12} key={`col_card_logo_${logo.id}`}>
                      <Card className={classes.imageCard} style={{ padding: 0 }}>
                        <img className={classes.logo} src={logo.url} alt="" />
                      </Card>
                    </Col>
                  );
                })
              )}
            </Row>
          </Col>
        </Row>

        <div className={classes.supportCard}>
          <CharterSupportCard />
        </div>
      </KannellePageHeader>
    </>
  );
};

export default CharterHome;
