import { LoadingOutlined } from '@ant-design/icons/lib';
import { Checkbox, message } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { LOGGING_EVENT } from '../../../../Constants';
import { updateCharter } from '../../../../redux/action/ChartersAction';
import { RootState } from '../../../../redux/RootState';
import { APIManager } from '../../../../services/api/APIManager';
import { updateCharterById } from '../../../../services/api/ChartersService';
import { APIFullModelCharter, APIUpdateCharterByIdParams } from '../../../../services/api/types/ChartersServiceTypes';

type Props = {
  charter: APIFullModelCharter;
};

const useStyles = createUseStyles({
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const ScenarioPermission: FunctionComponent<Props> = ({ charter }: Props) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());
  const { t } = useTranslation();

  const company = useSelector((state: RootState) => state.companies.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);

  useEffect(() => {
    const cancelTokenSource = cancelTokenSourceRef.current;
    return (): void => {
      cancelTokenSource.cancel('Cancelled updating charter scenario permission due to component unmount.');
    };
  }, []);

  const onUpdateCharterScenarioPermission = (e: CheckboxChangeEvent): void => {
    if (!charter) {
      return;
    }

    const onlyPrivateScenariosValue = e.target.checked;

    const data: APIUpdateCharterByIdParams = {
      onlyPrivateScenarios: onlyPrivateScenariosValue,
    };

    setLoading(true);

    updateCharterById(charter.id, data, cancelTokenSourceRef.current)
      .then((response) => {
        const updatedCharter = response.data;
        loggingManager.logEvent(LOGGING_EVENT.UPDATE_CHARTER_PERMISSION, {
          companyId: company?.id,
          charterId: charter.id,
          onlyPrivateScenarios: onlyPrivateScenariosValue,
        });
        dispatch(updateCharter(updatedCharter));
        message.success(t('charters.permissions.scenarioPermission.successMessage'));
      })
      .catch((err) => {
        log.debug(err.message);
        message.error(t('charters.permissions.scenarioPermission.errorMessage'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classNames(classes.mainContainer, 'scenarioPermissionCheckbox')}>
      <Checkbox onChange={onUpdateCharterScenarioPermission} disabled={loading} checked={charter.onlyPrivateScenarios}>
        {t('charters.permissions.scenarioPermission.checkboxLabel')}
      </Checkbox>
      {loading && <LoadingOutlined />}
    </div>
  );
};

export default ScenarioPermission;
