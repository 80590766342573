/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */

import emojiRegex from 'emoji-regex';
import { filter, isNumber } from 'lodash';
import find from 'lodash/find';
import { ANIMATION_CONSTANTS, THEME_KEY } from '../Constants';
import i18n from '../i18n';
import VideoEmpty from '../resources/videos/empty/video_empty_10.mp4';
import Sample16_9 from '../resources/videos/sample-16_9.mp4';
import Sample1_1 from '../resources/videos/sample-1_1.mp4';
import Sample9_16 from '../resources/videos/sample-9_16.mp4';
import ProjectUtils from './ProjectUtils';
import StringUtils from './StringUtils';
import {
  AnimationConfig,
  AnimationFormats,
  AnimationOption,
  AnimationPosition,
  AnimationPositionConfig,
  AnimationPositionPreset,
  AnimationPositionPresets,
  AnimationPositionStyle,
  Animations,
  AnimationTextValidationRule,
  AnimationTextValidationRuleCode,
  BogotaSlideAnimations,
  Logo,
  LogoPosition,
  LowerThirdAnimations,
  MaskCodes,
  PIP,
  PipEffects,
  PipPositionCode,
  Size,
  SlideAnimations,
} from './types/AnimationTypes';
import { SubtitleBlock } from './types/ProjectTypes';
import { AnimationTheme } from './types/ThemeTypes';

class AnimationsUtils {
  // Compute the size of the animation player depending on the animation format,
  // the actual Lottie animation size, and the initial size of the container player (scale it)
  static getAnimationPlayerSize = (
    lottieObject: any,
    playerInitialSize: Size,
    animationFormat: string
  ): Size | undefined => {
    const animationSize = AnimationsUtils.getAnimationSizeFromLottieFile(lottieObject);

    if (playerInitialSize.height <= 0 || playerInitialSize.width <= 0) {
      return undefined;
    }

    const projectReferenceWidth = AnimationsUtils.getReferenceWidthByFormat(animationFormat);
    const projectReferenceHeight = AnimationsUtils.getReferenceHeightByFormat(animationFormat);

    const resizedAnimationHeight = (playerInitialSize.height * animationSize.height) / projectReferenceHeight;
    const resizedAnimationWidth = (playerInitialSize.width * animationSize.width) / projectReferenceWidth;

    return {
      width: resizedAnimationWidth,
      height: resizedAnimationHeight,
    };
  };

  // Read the animation dimensions from the JSON Lottie file
  static getAnimationSizeFromLottieFile = (lottieObject: any): Size => {
    return { width: lottieObject.w, height: lottieObject.h };
  };

  // Get the reference width for each format
  static getReferenceWidthByFormat = (format: string): number => {
    switch (format) {
      case AnimationFormats.FORMAT_1_1:
        return 1440;
      case AnimationFormats.FORMAT_9_16:
        return 1080;
      case AnimationFormats.FORMAT_16_9:
      default:
        return 1920;
    }
  };

  // Get the reference height for each format
  static getReferenceHeightByFormat = (format: string): number => {
    switch (format) {
      case AnimationFormats.FORMAT_1_1:
        return 1440;
      case AnimationFormats.FORMAT_9_16:
        return 1920;
      case AnimationFormats.FORMAT_16_9:
      default:
        return 1080;
    }
  };

  // Depending on the format and the size of the player container, determine the
  // actual size of the scene player to display
  // (ex.: 9:16 rectangle fitting into the 16:9 black parent container)
  static getVideoPlayerSizeByFormat = (format: string, playerTotalSize: Size): Size => {
    return {
      height: playerTotalSize.height,
      width: playerTotalSize.height * AnimationsUtils.getFormatAspectRatio(format),
    };
  };

  // Compute the margin of the mask if any, considering the player scaling factor
  static getMaskMarginWidth = (playerScalingFactor: number, maskCode?: MaskCodes): number => {
    if (!(maskCode && maskCode === MaskCodes.MINI)) {
      return 0;
    }

    return ANIMATION_CONSTANTS.MASK_MARGIN * playerScalingFactor;
  };

  // Compute the animation position style (scale it) depending on the code and the x/y
  // (which are related to the project reference dimensions). Translate the animations
  // if a mask is provided
  static getAnimationPositionStyle = (
    animationFormat: string,
    position: AnimationPosition,
    playerTotalSize: Size,
    options?: {
      mask?: MaskCodes;
      animationName?: string;
      animationTheme?: AnimationTheme;
      logo?: Logo;
      subtitlesBlock?: SubtitleBlock[];
      subtitlesHeight?: number;
    }
  ): AnimationPositionStyle => {
    const { mask, animationName, animationTheme, logo, subtitlesBlock, subtitlesHeight } = options ?? {};

    const projectReferenceWidth = AnimationsUtils.getReferenceWidthByFormat(animationFormat);
    const projectReferenceHeight = AnimationsUtils.getReferenceHeightByFormat(animationFormat);
    const widthScalingFactor = playerTotalSize.width / projectReferenceWidth;
    const heightScalingFactor = playerTotalSize.height / projectReferenceHeight;
    const maskMargin = AnimationsUtils.getMaskMarginWidth(widthScalingFactor, mask);

    const isBerlinNissan = animationTheme === AnimationTheme.BERLIN && animationName === Animations.NISSAN.code;

    const isBottomAnimation = [
      AnimationPositionConfig.BOTTOM_LEFT.code,
      AnimationPositionConfig.BOTTOM_RIGHT.code,
    ].includes(position.code);
    const isBottomLogo =
      logo?.positionCode && [LogoPosition.BOTTOM_RIGHT, LogoPosition.BOTTOM_LEFT].includes(logo.positionCode);

    // Specific case for BERLIN-NISSAN if a mask is present, only the Y position is translated, the X remains on 0
    const newPositionOnX =
      (position.x ? (position.x * playerTotalSize.width) / projectReferenceWidth : 0) +
      (!isBerlinNissan ? maskMargin : 0);
    let newPositionOnY = (position.y ? (position.y * playerTotalSize.height) / projectReferenceHeight : 0) + maskMargin;

    // Specific case when a logo is in a bottom corner, and the animation is bottomed too, we ensure that the
    // animation is translated above the logo (this rule does not exist on BERLIN-NISSAN)
    if (!isBerlinNissan && logo && isBottomLogo && isBottomAnimation) {
      const hasMask = mask !== undefined;
      const format = animationFormat as AnimationFormats;
      const logoStyle = ProjectUtils.computeLogoStyle(logo, format, hasMask, playerTotalSize);
      const topOfLogoInBottomPosition = (logoStyle?.bottom ?? 0) + (logoStyle?.height ?? 0);
      newPositionOnY = Math.max(
        newPositionOnY,
        topOfLogoInBottomPosition + ANIMATION_CONSTANTS.LOGO.MARGIN_WITH_ANIMATION * heightScalingFactor
      );
    }

    if (!isBerlinNissan && subtitlesBlock && subtitlesBlock.length > 0 && subtitlesHeight && isBottomAnimation) {
      let calculatedPositionY =
        ANIMATION_CONSTANTS.SUBTITLE.MARGIN_WITH_ANIMATION +
        subtitlesHeight +
        maskMargin +
        widthScalingFactor * ANIMATION_CONSTANTS.SUBTITLE.MARGIN;

      if (logo && isBottomLogo && animationFormat !== AnimationFormats.FORMAT_16_9) {
        const logoStyle = ProjectUtils.computeLogoStyle(
          logo,
          animationFormat as AnimationFormats,
          mask !== undefined,
          playerTotalSize
        );
        const topOfLogoInBottomPosition = (logoStyle?.bottom ?? 0) + (logoStyle?.height ?? 0);
        calculatedPositionY += topOfLogoInBottomPosition;
      }
      newPositionOnY = Math.max(newPositionOnY, calculatedPositionY);
    }

    switch (position.code) {
      case 'BOTTOMLEFT': {
        return {
          left: newPositionOnX,
          bottom: newPositionOnY,
        };
      }
      case 'BOTTOMRIGHT': {
        return {
          right: newPositionOnX,
          bottom: newPositionOnY,
        };
      }
      case 'FULLSCREEN':
      default:
        return {
          top: maskMargin ?? 0,
          left: maskMargin ?? 0,
          bottom: maskMargin ?? 0,
          right: maskMargin ?? 0,
        };
    }
  };

  // Get the aspect ratio as a number
  static getFormatAspectRatio = (format: string): number => {
    switch (format) {
      case AnimationFormats.FORMAT_9_16:
        return 9 / 16;
      case AnimationFormats.FORMAT_1_1:
        return 1;
      case AnimationFormats.FORMAT_16_9:
      default:
        return 16 / 9;
    }
  };

  static getAnimationOptionByCode = (animationCode: string): AnimationOption | undefined => {
    return find(Animations, (animation) => animation.code === animationCode);
  };

  // Get the list of every Slide animation (no associated video nor image)
  static getSlideAnimations = (): AnimationOption[] => {
    return SlideAnimations;
  };

  // Check if a given animation is a Slide or not
  static isSlideAnimation = (animation: string): boolean => {
    const animationConfig = AnimationsUtils.getAnimationOptionByCode(animation);
    return animationConfig ? AnimationsUtils.getSlideAnimations().includes(animationConfig) : false;
  };

  // Get the list of every lower-third animation
  static getLowerThirdAnimations = (): AnimationOption[] => {
    return LowerThirdAnimations;
  };

  // Check if a given animation is a lower-third or not
  static isLowerThirdAnimation = (animation: string): boolean => {
    const animationConfig = AnimationsUtils.getAnimationOptionByCode(animation);
    return animationConfig ? AnimationsUtils.getLowerThirdAnimations().includes(animationConfig) : false;
  };

  // Compute the scene current time percentage from the number of played seconds and
  // the scene total duration
  static getScenePercentage = (playedSeconds: number, sceneDuration: number): number => {
    if (playedSeconds < 0 || sceneDuration <= 0) {
      return 0;
    }

    return playedSeconds / sceneDuration;
  };

  static isMotionDesignAnimation = (animation: string): boolean => {
    return Object.values([
      Animations.RAM.code,
      Animations.LINCOLN.code,
      Animations.ROUSH.code,
      Animations.DELOREAN.code,
      Animations.DODGE.code,
      Animations.AUSTIN.code,
      Animations.AVENTI.code,
      Animations.MCLAREN.code,
      Animations.TOYOTA.code,
      Animations.VECTOR.code,
    ] as string[]).includes(animation);
  };

  static getDefaultAnimationDuration = (animation: string): number => {
    return AnimationsUtils.isMotionDesignAnimation(animation)
      ? ANIMATION_CONSTANTS.DEFAULT_MOTION_DESIGN_ANIMATION_DURATION
      : ANIMATION_CONSTANTS.DEFAULT_ANIMATION_DURATION;
  };

  static calculateSlideAnimationDuration = (animation: string, animationTexts: string[]): number => {
    const totalWords = animationTexts.reduce((acc, animationText) => {
      const numberOfWords = StringUtils.countWordsInString(animationText);
      // eslint-disable-next-line no-param-reassign
      acc += numberOfWords;
      return acc;
    }, 0);

    const computedDuration = totalWords * ANIMATION_CONSTANTS.DURATION_BY_WORD;

    const totalDurationByWordLimit = Math.min(computedDuration, ANIMATION_CONSTANTS.MAX_SLIDE_DURATION);

    return Math.max(AnimationsUtils.getDefaultAnimationDuration(animation), totalDurationByWordLimit);
  };

  // If the animation is not a lower third, the only allowed position is 'FULL_SCREEN', else the other options are allowed (except 'FULL_SCREEN')
  static getAnimationPositionByAnimationCode = (animationCode: string): AnimationPositionConfig[] => {
    const isLowerThirdAnimation = AnimationsUtils.isLowerThirdAnimation(animationCode);
    return !isLowerThirdAnimation
      ? [AnimationPositionConfig.FULL_SCREEN]
      : filter(AnimationPositionConfig, (config) => config.code !== AnimationPositionConfig.FULL_SCREEN.code);
  };

  static getPossiblePositionsByAnimationName = (
    animation: AnimationOption,
    theme?: AnimationTheme
  ): AnimationPosition[] => {
    // Special case for BERLIN-NISSAN: animation is like BOTTOMCENTER
    if (theme === AnimationTheme.BERLIN && animation.code === Animations.NISSAN.code) {
      return [
        {
          code: AnimationPositionConfig.BOTTOM_RIGHT.code,
          x: AnimationPositionPresets.FULL_SCREEN.x,
          y: AnimationPositionPresets.FULL_SCREEN.y,
          key: i18n.t('animations.positions.options.bottomCenter'),
        },
      ];
    }

    if (!AnimationsUtils.isLowerThirdAnimation(animation.code)) {
      return [
        {
          code: AnimationPositionConfig.FULL_SCREEN.code,
          x: AnimationPositionPresets.FULL_SCREEN.x,
          y: AnimationPositionPresets.FULL_SCREEN.y,
          key: i18n.t(AnimationPositionConfig.FULL_SCREEN.key),
        },
      ];
    }

    return Object.values(AnimationPositionConfig)
      .filter((position) => position.code !== AnimationPositionConfig.FULL_SCREEN.code)
      .flatMap((position) => {
        return Object.values(AnimationPositionPresets)
          .filter((preset) => preset.code === AnimationPositionPresets.MEDIUM_TRANSLATION.code)
          .map((preset) => ({
            code: position.code,
            x: preset.x,
            y: preset.y,
            key: `${i18n.t(position.key)}`,
          }));
      });
  };

  static findAnimationPositionPresetByOffset = (
    offsetX: number,
    offsetY: number
  ): AnimationPositionPreset | undefined => {
    return find(
      AnimationPositionPresets,
      (positionPreset: AnimationPositionPreset) => positionPreset.x === offsetX && positionPreset.y === offsetY
    );
  };

  static findAnimationPositionPresetByCode = (positionPresetCode: string): AnimationPositionPreset | undefined => {
    return find(
      AnimationPositionPresets,
      (positionPreset: AnimationPositionPreset) => positionPreset.code === positionPresetCode
    );
  };

  static getDefaultAnimationTextsByAnimation = (animation: AnimationOption): string[] => {
    return animation.animationTexts.map((animationText) =>
      animationText.placeholderKey ? i18n.t(animationText.placeholderKey) : i18n.t(animationText.labelKey)
    );
  };

  static getPublicAnimationNameByCode = (animationCode: string) => {
    return i18n.t(`animations.list.${animationCode.toLowerCase()}.publicName`);
  };

  // Check if the videoUrl passed as param is the VideoEmpty
  static isVideoEmpty = (videoUrl: string): boolean => {
    return videoUrl === VideoEmpty;
  };

  // Compute the style to apply to the PIP image depending on the effect name and value
  static computeImagePipEffectStyle = (progress: number, pip: PIP, playerSize: Size) => {
    if (!(pip.source && pip.duration && pip.effect?.name && pip.effect.value && progress > 0)) {
      return undefined;
    }

    const effectName = pip.effect.name;
    const effectValue = pip.effect.value;
    const size = pip.size ?? 1;

    switch (effectName) {
      case PipEffects.ZOOMIN.code: {
        const scale = 1 + (effectValue - 1) * progress;
        return { transform: `scale(${scale})` };
      }
      case PipEffects.ZOOMOUT.code: {
        const scale = 1 + (effectValue - 1) * (1 - progress);
        return { transform: `scale(${scale})` };
      }
      case PipEffects.TRANSLATION_RIGHT.code: {
        const containerWidth = size * playerSize.width;
        const scale = 1 + effectValue;
        const translationX = -(containerWidth * effectValue * (1 - 2 * progress)) / 2;
        const translationY = 0;
        return { transform: `translate(${translationX}px, ${translationY}px) scale(${scale})` };
      }
      case PipEffects.TRANSLATION_LEFT.code: {
        const containerWidth = size * playerSize.width;
        const scale = 1 + effectValue;
        const translationX = (containerWidth * effectValue * (1 - 2 * progress)) / 2;
        const translationY = 0;
        return { transform: `translate(${translationX}px, ${translationY}px) scale(${scale})` };
      }
      default:
      case PipEffects.NONE.code:
        return undefined;
    }
  };

  // Compute the style to apply  to the PIP to place it and resize it correctly in the player
  static computePipPositionAndSize = (pip: PIP, playerSize: Size, format: string, mask?: MaskCodes) => {
    const pipSize = pip.size ?? 1;
    const pipPositionValue = pip.positionValue ?? 0;
    const pipPositionCode = pip.positionCode ?? PipPositionCode.FULL_SCREEN;

    const aspectRatio = AnimationsUtils.getFormatAspectRatio(format);
    const isLandscape = aspectRatio >= 1;
    const projectReferenceWidth = AnimationsUtils.getReferenceWidthByFormat(format);
    const widthScalingFactor = playerSize.width / projectReferenceWidth;
    const maskMargin = mask ? AnimationsUtils.getMaskMarginWidth(widthScalingFactor, mask) : 0;

    const resizedPipHeight = pipSize * playerSize.height;
    const resizedPipWidth = pipSize * playerSize.width;

    const margin = isLandscape
      ? pipPositionValue * playerSize.height + maskMargin
      : pipPositionValue * playerSize.width + maskMargin;

    const sizeStyle = {
      height: resizedPipHeight,
      width: resizedPipWidth,
    };

    let style;

    switch (pipPositionCode) {
      case PipPositionCode.TOP_LEFT:
        style = {
          ...sizeStyle,
          top: margin,
          left: margin,
        };
        break;
      case PipPositionCode.TOP_RIGHT:
        style = {
          ...sizeStyle,
          top: margin,
          right: margin,
        };
        break;
      case PipPositionCode.BOTTOM_LEFT:
        style = {
          ...sizeStyle,
          bottom: margin,
          left: margin,
        };
        break;
      case PipPositionCode.BOTTOM_RIGHT:
        style = {
          ...sizeStyle,
          bottom: margin,
          right: margin,
        };
        break;
      case PipPositionCode.CENTER:
        style = {
          ...sizeStyle,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        };
        break;
      case PipPositionCode.FULL_SCREEN:
      default:
        style = {
          ...sizeStyle,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        };
    }

    return style;
  };

  // If the animation is a Recordable one (on a Recordable scene), the duration is undefined,
  // else if it is a Bogota (slide) one, it is 5, else it is 3
  static getAnimationDuration = (animation: string): number | undefined => {
    if (!AnimationsUtils.isSlideAnimation(animation)) {
      return undefined;
    }

    return BogotaSlideAnimations.some((a) => a.code === animation) ? 5 : 3;
  };

  // Return the default AnimationConfig depending on the kind (default Recordable or Slide)
  static getDefaultAnimationConfigByKind = (isSlide: boolean): AnimationConfig => {
    const defaultAnimation = isSlide
      ? AnimationsUtils.getSlideAnimations()[0]
      : AnimationsUtils.getLowerThirdAnimations()[0];
    const positions = AnimationsUtils.getPossiblePositionsByAnimationName(defaultAnimation);
    return {
      name: defaultAnimation.code,
      position: positions[0],
      duration: AnimationsUtils.getAnimationDuration(defaultAnimation.code),
    };
  };

  // Get the default position for each animation
  static getDefaultPositionByAnimationAndTheme = (theme: string, animation: string): AnimationPosition => {
    // Special case for BERLIN-NISSAN: animation is like BOTTOMCENTER
    if (theme === THEME_KEY.BERLIN.code && animation === Animations.NISSAN.code) {
      return {
        code: 'BOTTOMRIGHT',
        x: 0,
        y: 0,
      };
    }

    switch (animation) {
      case Animations.NISSAN.code:
      case Animations.MUSTANG.code:
      case Animations.MAZDA.code:
        return {
          code: 'BOTTOMRIGHT',
          x: 50,
          y: 50,
        };
      default:
        return {
          code: 'FULLSCREEN',
          x: undefined,
          y: undefined,
        };
    }
  };

  // Get the path to the sample video for Recordable animations
  static getSampleVideoByFormat = (format: string): string => {
    switch (format) {
      case AnimationFormats.FORMAT_9_16:
        return Sample9_16;
      case AnimationFormats.FORMAT_1_1:
        return Sample1_1;
      case AnimationFormats.FORMAT_16_9:
      default:
        return Sample16_9;
    }
  };

  // Returns true if the `text` string contains only digits or percentages (no alphabetical character)
  static validateDigitsOrPercentageOnlyAnimationRule = (text: string) => {
    return /^(-?\d+(\.\d+)?%?)$/.test(text);
  };

  // Returns true if the `text` string contains only digits
  static validateDigitsOnlyAnimationRule = (text: string) => {
    return /^(-?\d+(\.\d+)?)$/.test(text);
  };

  // Returns true if the `text` string contains any other character than spaces, or linebreaks
  static validateNotEmptyAnimationRule = (text: string) => {
    return !(!text || /^\s*$/.test(text));
  };

  // Returns true if the `text` string has a length lower or equal to the `value`
  static validateMaxLengthAnimationRule = (text: string, value: number) => {
    return text.length < value;
  };

  // Returns true if the `text` string does not contain emojis
  static validateContainsEmojiAnimationRule = (text: string) => {
    const regex = emojiRegex();
    return !regex.test(text);
  };

  // Check if the `rule` param is a 'blocking' validation rule, different from the 'Text too long' and 'contains emoji' validation rules
  static isRuleElseThanTextTooLongOrContainsEmoji = (rule: AnimationTextValidationRule): boolean => {
    return ![AnimationTextValidationRuleCode.MAX_LENGTH, AnimationTextValidationRuleCode.CONTAINS_EMOJI].includes(
      rule.ruleCode
    );
  };

  static validateAnimationRule = (
    text: string,
    validationRules?: AnimationTextValidationRule[]
  ): AnimationTextValidationRule[] => {
    if (!(validationRules && validationRules.length > 0)) {
      return [];
    }

    const findRuleByCode = (ruleCode: AnimationTextValidationRuleCode): AnimationTextValidationRule | undefined => {
      return validationRules.find((rule) => rule.ruleCode === ruleCode);
    };

    const rulesInError = [];
    const notEmptyRule = findRuleByCode(AnimationTextValidationRuleCode.NOT_EMPTY);
    const digitsOrPercentageOnlyRule = findRuleByCode(AnimationTextValidationRuleCode.DIGITS_OR_PERCENTAGE_ONLY);
    const digitsOnlyRule = findRuleByCode(AnimationTextValidationRuleCode.DIGITS_ONLY);
    const maxLengthRule = findRuleByCode(AnimationTextValidationRuleCode.MAX_LENGTH);
    const containsEmojiRule = findRuleByCode(AnimationTextValidationRuleCode.CONTAINS_EMOJI);

    let notEmptyResult = true;
    let onlyDigitsResult = true;
    let maxLengthResult = true;
    let containsEmojiResult = true;

    if (notEmptyRule) {
      notEmptyResult = AnimationsUtils.validateNotEmptyAnimationRule(text);
      if (!notEmptyResult) {
        rulesInError.push(notEmptyRule);
      }
    }
    if (digitsOrPercentageOnlyRule) {
      onlyDigitsResult = AnimationsUtils.validateDigitsOrPercentageOnlyAnimationRule(text);
      if (!onlyDigitsResult) {
        rulesInError.push(digitsOrPercentageOnlyRule);
      }
    }
    if (digitsOnlyRule) {
      onlyDigitsResult = AnimationsUtils.validateDigitsOnlyAnimationRule(text);
      if (!onlyDigitsResult) {
        rulesInError.push(digitsOnlyRule);
      }
    }
    if (maxLengthRule && maxLengthRule.ruleValue !== undefined && isNumber(maxLengthRule.ruleValue)) {
      maxLengthResult = AnimationsUtils.validateMaxLengthAnimationRule(text, maxLengthRule.ruleValue);
      if (!maxLengthResult) {
        rulesInError.push(maxLengthRule);
      }
    }
    if (containsEmojiRule) {
      containsEmojiResult = AnimationsUtils.validateContainsEmojiAnimationRule(text);
      if (!containsEmojiResult) {
        rulesInError.push(containsEmojiRule);
      }
    }

    return rulesInError;
  };

  static isSwitchingToAnimationForcingNumbers = (
    sourceAnimationCode?: string,
    targetAnimationCode?: string
  ): boolean => {
    if (!(sourceAnimationCode && targetAnimationCode)) {
      return false;
    }

    const sourceAnimation = AnimationsUtils.getAnimationOptionByCode(sourceAnimationCode);
    const targetAnimation = AnimationsUtils.getAnimationOptionByCode(targetAnimationCode);

    const animationHasDigitsOnlyRule = (animationOption: AnimationOption): boolean => {
      return animationOption.animationTexts.some((animationText) =>
        animationText.validationRules?.some((r) =>
          [
            AnimationTextValidationRuleCode.DIGITS_ONLY,
            AnimationTextValidationRuleCode.DIGITS_OR_PERCENTAGE_ONLY,
          ].includes(r.ruleCode)
        )
      );
    };

    const sourceAnimationHasDigitsOnlyRule = sourceAnimation ? animationHasDigitsOnlyRule(sourceAnimation) : false;
    const targetAnimationHasDigitsOnlyRule = targetAnimation ? animationHasDigitsOnlyRule(targetAnimation) : false;

    return !sourceAnimationHasDigitsOnlyRule && targetAnimationHasDigitsOnlyRule;
  };
}

export default AnimationsUtils;
