import { LoadingOutlined } from '@ant-design/icons';
import { Button, Card, Descriptions, message, Select, Tooltip } from 'antd';
import i18n from 'i18next';
import { findIndex } from 'lodash';
import map from 'lodash/map';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import KannelleSwitch from '../../../../../components/switch/KannelleSwitch';
import FormatTag from '../../../../../components/tag/format-tag/FormatTag';
import { RootState } from '../../../../../redux/RootState';
import {
  downloadSubtitlesSrtFile,
  getSubtitlesLanguages,
  postProjectSubtitles,
} from '../../../../../services/api/SubtitlesService';
import { SubtitlesLanguage } from '../../../../../services/api/types/SubtitlesServiceTypes';
import ProjectUtils from '../../../../../utils/ProjectUtils';
import TimeUtils from '../../../../../utils/TimeUtils';
import { Project, ProjectScene, SubtitleBlockMode } from '../../../../../utils/types/ProjectTypes';
import ProjectDetailsModal from '../project-card/project-details/ProjectDetailsModal';
import ProjectSubtitleGenerationModal from '../project-card/project-details/ProjectSubtitleGenerationModal';
import EditableProjectTitleCell from './scene-timeline/components/EditableProjectTitleCell';

type Props = {
  project: Project;
  editedScene: ProjectScene;
  isLoading: boolean;
  hasProjectActiveScenes: boolean;
  isTitleLoading: boolean;
  isSubtitleLoading: boolean;
  onNextStepClick: (callbackIfConfirmed?: () => void) => void;
  onEditProjectTitle: (newTitle: string) => void;
  isFinalVideoModalVisible: boolean;
  onShowFinalVideoModal: () => void;
  onHideFinalVideoModal: () => void;
  onEditProjectSubtitle: (isActive: boolean, lang?: string) => void;
  onSubtitlesGenerated: (updatedProject: Project) => void;
};

type SubtitlesLanguageSelectElement = { code: string; key: Element | JSX.Element | string };

const useStyles = createUseStyles({
  projectMetadataDescription: {
    '& .ant-descriptions-header': {
      marginBottom: 6,
    },
    '& .ant-descriptions-item': {
      paddingBottom: '4px !important',
    },
  },
  seeLastFinalizedVideoButton: {
    paddingRight: 0,
  },
  neverFinalizedLabel: {
    fontStyle: 'italic',
    color: '#7E8AAA',
  },
  durationLoadingIcon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 8,
  },
  floatRight: {
    float: 'right',
  },

  subtitleLangContainer: {
    minWidth: 150,
    '& .ant-select': {
      width: '100%',
    },
  },
  iconContainer: {
    marginRight: 8,
  },
  dropdownLanguage: {
    width: '200px !important',
  },
  downloadSrtButton: {
    marginTop: 8,
  },
});

const ProjectMetadata: FunctionComponent<Props> = ({
  project,
  editedScene,
  isLoading,
  isTitleLoading,
  isSubtitleLoading,
  hasProjectActiveScenes,
  onNextStepClick,
  onEditProjectTitle,
  isFinalVideoModalVisible,
  onShowFinalVideoModal,
  onHideFinalVideoModal,
  onEditProjectSubtitle,
  onSubtitlesGenerated,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const lang = i18n.language?.split('-')[0] || 'en';

  const [areSubtitlesLanguageLoading, setAreSubtitlesLanguageLoading] = useState(false);
  const [subtitlesLanguages, setSubtitlesLanguages] = useState<SubtitlesLanguageSelectElement[]>([]);
  const [areSubtitlesActivated, setAreSubtitlesActivated] = useState(project.subtitlesLanguage !== undefined);
  const [isSubtitleGenerationModalVisible, setIsSubtitleGenerationModalVisible] = useState(false);
  const [canGenerateSubtitles, setCanGenerateSubtitles] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const currentCharter = useSelector((state: RootState) => state.charters.current);
  const currentCharterId = currentCharter?.id;

  useEffect(() => {
    setAreSubtitlesLanguageLoading(true);

    getSubtitlesLanguages(lang)
      .then((response) => {
        const formattedResponse = response.items.map((responseItem: SubtitlesLanguage) => {
          return {
            code: responseItem.code,
            key: (
              <>
                <span className={classes.iconContainer}>{responseItem.flag}</span> {responseItem.name}
              </>
            ),
          };
        });

        setSubtitlesLanguages(formattedResponse);
      })
      .finally(() => {
        setAreSubtitlesLanguageLoading(false);
      });
  }, [i18n.language]);

  const checkCanGenerateSubtitles = (): void => {
    const scenes = project.scenes?.filter(ProjectUtils.isRecordableScene);

    let canGenerateSubtitlesCheck = false;

    if (!scenes || scenes.length === 0) {
      setCanGenerateSubtitles(false);
      return;
    }

    canGenerateSubtitlesCheck = scenes.some((scene) => {
      if (!scene.subtitles || scene.subtitles.blocks?.length === 0) {
        return true;
      }

      const manuallyEditedSubtitleBlock = findIndex(scene?.subtitles?.blocks, { mode: SubtitleBlockMode.MANUAL });
      return manuallyEditedSubtitleBlock < 0;
    });

    if (canGenerateSubtitlesCheck) {
      setCanGenerateSubtitles(canGenerateSubtitlesCheck);
      return;
    }

    if (ProjectUtils.isRecordableScene(editedScene)) {
      if (editedScene.subtitles && editedScene.subtitles.blocks.length > 0) {
        const manuallyEditedSubtitleBlock = findIndex(editedScene.subtitles.blocks, { mode: SubtitleBlockMode.MANUAL });
        canGenerateSubtitlesCheck = manuallyEditedSubtitleBlock < 0;
      } else {
        canGenerateSubtitlesCheck = true;
      }
    }

    setCanGenerateSubtitles(canGenerateSubtitlesCheck);
  };

  useEffect(() => {
    checkCanGenerateSubtitles();
  }, [project, editedScene]);

  const handleSubtitlesActivation = (isActive: boolean) => {
    setAreSubtitlesActivated(isActive);
    onEditProjectSubtitle(isActive, isActive ? subtitlesLanguages[0].code : undefined);
  };

  const handleSubtitlesLangChange = (newSubtitlesLang: string) => {
    onEditProjectSubtitle(areSubtitlesActivated, areSubtitlesActivated ? newSubtitlesLang : undefined);
  };

  const hasFinalVideo = project.finalVideo !== undefined;
  const format = ProjectUtils.getProjectFormatByCode(project.videoFormat);

  const onRefinalizeProjectFromDetailsModalClick = () => {
    onNextStepClick(() => {
      onHideFinalVideoModal();
    });
  };

  const handleSubtitleGeneration = () => {
    if (!currentCharterId || !project.subtitlesLanguage) {
      return;
    }
    setIsSubtitleGenerationModalVisible(true);

    postProjectSubtitles(currentCharterId, project.id, project.subtitlesLanguage);
  };

  function resolveAndDownloadFile(fileContent: any, fileName: string) {
    const url = window.URL.createObjectURL(new Blob([fileContent]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  }

  const handleDownloadSrt = () => {
    if (!currentCharterId || !project.subtitlesLanguage) {
      return;
    }
    setIsDownloadLoading(true);

    downloadSubtitlesSrtFile(currentCharterId, project.id)
      .then((response) => {
        const fileName = response.headers['content-disposition']
          ? response.headers['content-disposition'].split('filename=')[1]
          : 'subtitles.srt';

        resolveAndDownloadFile(response.data, fileName);
      })
      .catch(() => {
        message.error(t('charters.projects.projectEditor.projectMetadata.downloadSrtError'));
      })
      .finally(() => {
        setIsDownloadLoading(false);
      });
  };

  const callBackHideModal = () => {
    setIsSubtitleGenerationModalVisible(false);
  };

  const checkHasProjectSubtitles = (): boolean => {
    const sceneWithSubtitles = project.scenes?.filter((scene) => scene.subtitles && scene.subtitles.blocks.length > 0);

    if (!sceneWithSubtitles) {
      return false;
    }

    return sceneWithSubtitles.length > 0;
  };

  const hasProjectSubtitles = checkHasProjectSubtitles();

  return (
    <Card>
      <Descriptions
        title={t('charters.projects.projectEditor.projectMetadata.title')}
        column={3}
        size="small"
        className={classes.projectMetadataDescription}
        extra={
          hasFinalVideo ? (
            <Button type="link" onClick={onShowFinalVideoModal} className={classes.seeLastFinalizedVideoButton}>
              {t('charters.projects.projectEditor.projectMetadata.seeLastFinalized')}
            </Button>
          ) : (
            <span className={classes.neverFinalizedLabel}>{t('charters.projects.projectCard.neverFinalized')}</span>
          )
        }
      >
        <Descriptions.Item label={t('charters.projects.projectEditor.projectMetadata.projectTitle')} span={3}>
          <EditableProjectTitleCell
            project={project}
            onEditProjectTitle={onEditProjectTitle}
            isDisabled={isTitleLoading}
          />
        </Descriptions.Item>
        {format && (
          <Descriptions.Item label={t('charters.projects.projectEditor.projectMetadata.projectFormat')} span={2}>
            <FormatTag format={t(format.key)} image={format.image} />
          </Descriptions.Item>
        )}
        {project.duration && (
          <Descriptions.Item
            label={
              <>
                {isLoading && <LoadingOutlined className={classes.durationLoadingIcon} />}
                {t('charters.projects.projectEditor.projectMetadata.projectDuration')}
              </>
            }
            className={classes.floatRight}
          >
            {TimeUtils.formatSecondsIntoHumanTimeString(project.duration, t, { withMillis: true })}
          </Descriptions.Item>
        )}
        {project.duration && (
          <Descriptions.Item span={3} label={t('charters.projects.projectEditor.projectMetadata.subtitle')}>
            <KannelleSwitch
              isChecked={project.subtitlesLanguage !== undefined}
              size="small"
              callbackChange={handleSubtitlesActivation}
              loading={isSubtitleLoading || areSubtitlesLanguageLoading}
              disabled={isSubtitleLoading || areSubtitlesLanguageLoading}
              labelActivated="form.activated"
              labelDeactivated="form.deactivated"
            />
          </Descriptions.Item>
        )}

        {areSubtitlesActivated && (
          <Descriptions.Item span={2} label={t('charters.projects.projectEditor.projectMetadata.subtitleLang')}>
            <span className={classes.subtitleLangContainer}>
              <Select
                size="small"
                onChange={handleSubtitlesLangChange}
                value={project.subtitlesLanguage}
                disabled={isSubtitleLoading}
                dropdownClassName={classes.dropdownLanguage}
              >
                {map(subtitlesLanguages, (item: SubtitlesLanguageSelectElement) => {
                  return (
                    <Select.Option key={item.code} value={item.code}>
                      {item.key}
                    </Select.Option>
                  );
                })}
              </Select>
            </span>
          </Descriptions.Item>
        )}
        {areSubtitlesActivated && (
          <Descriptions.Item className={classes.floatRight}>
            <div>
              <div>
                <Tooltip
                  title={
                    !canGenerateSubtitles && t('charters.projects.projectEditor.projectMetadata.cantGenerateSubtitles')
                  }
                >
                  <Button
                    onClick={handleSubtitleGeneration}
                    type="primary"
                    size="small"
                    disabled={!canGenerateSubtitles || isSubtitleLoading}
                  >
                    {t('charters.projects.projectEditor.projectMetadata.generateSubtitles')}
                  </Button>
                </Tooltip>
              </div>
              <div>
                <Button
                  onClick={handleDownloadSrt}
                  type="primary"
                  size="small"
                  disabled={!hasProjectSubtitles || isSubtitleLoading || isDownloadLoading}
                  className={classes.downloadSrtButton}
                  loading={isDownloadLoading}
                >
                  {t('charters.projects.projectEditor.projectMetadata.downloadSrt')}
                </Button>
              </div>
            </div>
          </Descriptions.Item>
        )}
      </Descriptions>

      {hasFinalVideo && (
        <ProjectDetailsModal
          isVisible={isFinalVideoModalVisible}
          onHide={onHideFinalVideoModal}
          project={project}
          onRefinalizeClick={hasProjectActiveScenes ? onRefinalizeProjectFromDetailsModalClick : undefined}
        />
      )}
      {areSubtitlesActivated && currentCharterId && (
        <ProjectSubtitleGenerationModal
          charterId={currentCharterId}
          projectId={project.id}
          isVisible={isSubtitleGenerationModalVisible}
          project={project}
          callBackHideModal={callBackHideModal}
          onSubtitlesGenerated={onSubtitlesGenerated}
        />
      )}
    </Card>
  );
};

export default ProjectMetadata;
