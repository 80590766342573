import i18n, { TFunction } from 'i18next';
import moment from 'moment';

export default class TimeUtils {
  static formatSecondsIntoHumanTimeString(
    timeSeconds: number,
    t: TFunction,
    options: { withMillis: boolean } = { withMillis: false }
  ): string {
    const duration = moment.duration(timeSeconds, 'seconds');
    const hours = duration.as('hours');
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    const millis = duration.milliseconds();
    const momentUTC = moment.utc(duration.asMilliseconds());

    if (hours >= 1) {
      if (hours >= 24) {
        return `${hours} ${t('timeLabel.hours')}`;
      }
      if (minutes) {
        // X h Y min
        return momentUTC.format(t('timeFormats.hoursMinutes'));
      }
      // X h
      return momentUTC.format(t('timeFormats.hours', { count: hours }));
    }

    if (minutes) {
      if (seconds) {
        // X min Y sec
        return momentUTC.format(t('timeFormats.minutesSeconds'));
      }
      // X min
      return momentUTC.format(t('timeFormats.minutes', { count: minutes }));
    }

    // X sec

    // Display the millis or round the seconds to the closest number (by taking the millis into account)
    // Ex: timeSeconds = 6.8
    // options.withMillis === false -> '7 seconds'
    // options.withMillis === true -> '6.8 seconds'
    // Round the seconds to the closest number
    if (options.withMillis) {
      const roundedMillis = Math.round(millis / 100) * 100;
      const trueMillis = roundedMillis >= 1000 ? millis : roundedMillis;
      momentUTC.milliseconds(trueMillis);
      return momentUTC.format(t('timeFormats.secondsMillis', { count: momentUTC.seconds() }));
    }

    const roundedSeconds = Math.round(seconds + millis / 1000);
    momentUTC.seconds(roundedSeconds);

    return roundedSeconds >= 60
      ? momentUTC.format(t('timeFormats.minutesSeconds'))
      : momentUTC.format(t('timeFormats.seconds', { count: momentUTC.seconds() }));
  }

  static formatSecondsIntoDuration = (
    timeInSeconds: number,
    options: { withMillis?: boolean; roundSeconds?: boolean } = { withMillis: false, roundSeconds: false }
  ): string => {
    // If we do not want to display the millis or explicitly requested, round up to the nearest second
    const duration = moment.duration(
      options.withMillis || !options.roundSeconds ? timeInSeconds : Math.round(timeInSeconds),
      'seconds'
    );
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    const millis = Math.trunc(duration.milliseconds());

    if (hours > 0) {
      return `${hours}:${TimeUtils.padWithZerosIfNeeded(minutes)}:${TimeUtils.padWithZerosIfNeeded(seconds)}${
        options.withMillis ? `.${TimeUtils.padMillisWithZerosIfNeeded(millis)}` : ''
      }`;
    }

    if (options.withMillis) {
      return `${minutes}:${TimeUtils.padWithZerosIfNeeded(seconds)}.${TimeUtils.padMillisWithZerosIfNeeded(millis)}`;
    }

    const positiveSeconds = Math.abs(seconds);

    return `${seconds < 0 ? '-' : ''}${minutes}:${TimeUtils.padWithZerosIfNeeded(positiveSeconds)}`;
  };

  static padWithZerosIfNeeded = (value: number): string => {
    if (value > 9) {
      return value.toString();
    }

    return `0${value}`;
  };

  static padMillisWithZerosIfNeeded = (value: number): string => {
    if (value > 99) {
      return value.toString();
    }
    if (value > 9) {
      return `0${value}`;
    }

    return `00${value}`;
  };

  static getDateISO8061Format = (momentObject: moment.Moment): string => {
    return momentObject.format();
  };

  static fromTimestampToHumanReadableDate = (t: TFunction, timestamp: number): string => {
    return moment(timestamp).format(t('timeFormats.dateDayOfYear'));
  };

  static fromSecondsToMillis = (seconds: number): number => {
    return seconds * 1000;
  };

  static formatDateAsYearMonthDayHourMinute = (date: string): string | undefined => {
    const momentDate = moment(date);

    if (!momentDate.isValid()) {
      return undefined;
    }

    return momentDate.format(i18n.t('timeFormats.yearMonthDayHourMinute'));
  };

  static isDateAfterOtherDate = (
    date1: string,
    date2: string,
    options: { ignoreMillis: boolean } = { ignoreMillis: false }
  ): boolean => {
    const moment1 = moment(date1);
    const moment2 = moment(date2);

    if (options.ignoreMillis) {
      moment1.milliseconds(0);
      moment2.milliseconds(0);
    }

    return moment1.isAfter(moment2);
  };

  static isDateInFuture = (date: string): boolean => {
    const now = moment().toISOString();
    return TimeUtils.isDateAfterOtherDate(date, now);
  };
}
