import { Table } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { APIBundles } from '../../../services/api/types/BundlesServiceTypes';
import { buildBundlesTableColumns } from './BundlesTableColumns';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'space-between',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

type Props = {
  data: APIBundles[];
};

const BundlesTableList: FunctionComponent<Props> = ({ data }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [filter, setFilter] = useState<Record<string, string>>();
  const [filteredInfo, setFilteredInfo] = useState({});

  const columns = buildBundlesTableColumns({
    bundles: data,
    classes,
    t,
    filter,
    setFilter,
    filteredInfo,
  });

  return (
    <Table
      tableLayout="fixed"
      rowKey={(record): string => `bundles_row_${record.id}`}
      columns={columns}
      dataSource={data}
      scroll={{ x: 'max-content' }}
      pagination={false}
      locale={{ filterConfirm: t('global.ok'), filterReset: t('global.reset'), emptyText: t('global.nodata') }}
      onChange={(pagination, filters, sorter: any, extra): void => {
        if (extra.action === 'filter') {
          setFilteredInfo(filters);
        }
      }}
    />
  );
};

export default BundlesTableList;
