import { SettingOutlined } from '@ant-design/icons';
import { message, Row, Tag } from 'antd';
import classNames from 'classnames';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ErrorAlert from '../../components/alert/ErrorAlert';
import EmptyPageContent from '../../components/empty/EmptyPageContent';
import PageContentLoader from '../../components/loader/PageContentLoader';
import KannellePageHeader from '../../components/page-header/KannellePageHeader';
import { DEVICE_SIZES_QUERIES, LINK, LOGGING_EVENT, THEME } from '../../Constants';
import useCanAccess from '../../hooks/useCanAccess';
import Company from '../../model/Company';
import { setCompanyBundle, updateCompany } from '../../redux/action/CompaniesAction';
import { RootState } from '../../redux/RootState';
import { getChargebeeSubscriptionById } from '../../services/api/ChargebeeService';
import {
  getCompanyById,
  getCompanyContacts,
  patchCompany,
  postCompanyBundleById,
  getCompanyUsers
} from '../../services/api/CompaniesService';
import { APISubscriptionResult, ChargebeeSubscriptionObject } from '../../services/api/types/ChargebeeServiceTypes';
import {
  APICompanyBundleParams,
  APICompanyFileStorageAgreement,
  APICompanyUsers,
  APIPatchCompanyParams,
} from '../../services/api/types/CompaniesServiceTypes';
import BundlesUtils from '../../utils/BundlesUtils';
import CompaniesUtils from '../../utils/CompaniesUtils';
import LogUtils from '../../utils/LogUtils';
import { PermissionList } from '../../utils/types/CharterPermissionTypes';
import ChargebeePortal from '../home/ChargebeePortal';
import CompanyDescription from './CompanyDescription';
import CompanyForm from './form/CompanyForm';

const useStyles = createUseStyles({
  pageContent: {
    backgroundColor: '#FFFFFF',
    margin: 24,
    marginBottom: 0,
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      margin: 0,
    },
    '& .ant-page-header-heading-left': {
      minWidth: 320,
    },
  },
  pageContentMobile: {
    backgroundColor: '#FFFFFF',
    margin: 24,
    marginBottom: 0,
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      margin: 0,
    },
    '& .ant-page-header-heading-extra': {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      '& .ant-tag': {
        marginBottom: 8,
      },
    },
  },
  pageContentChargebee: {
    marginTop: 0,
  },
  avatar: {
    backgroundColor: THEME.DEFAULT.MAIN_COLOR,
  },
  subtitleRow: {
    marginBottom: 16,
  },
  successTag: {
    color: '#0E5814',
    backgroundColor: '#E3F9E5',
    borderRadius: 25,
    border: 0,
    width: 'auto !important',
    minWidth: 'auto !important',
    margin: '8px 8px 0px 0px',
  },
  errorTag: {
    color: '#610316',
    backgroundColor: '#FFE3E3',
    borderRadius: 25,
    border: 0,
    width: 'auto !important',
    minWidth: 'auto !important',
    margin: '8px 8px 0px 0px',
  },
  tagContainer: {
    flexWrap: 'wrap',
    display: 'flex',
  },
});

const CompanySettings: FunctionComponent = () => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompany = useSelector((state: RootState) => state.companies.current);
  const companies = useSelector((state: RootState) => state.companies.list);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);
  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);
  const [contactsLoading, setContactsLoading] = useState(true);
  const [fileStorageAgreementLoading, setFileStorageAgreementLoading] = useState(true);
  const [contacts, setContacts] = useState<APICompanyUsers[]>([]);
  const [users, setUsers] = useState<APICompanyUsers[]>([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [subscriptions, setSubscriptions] = useState<ChargebeeSubscriptionObject[]>([]);
  const [fileStorageAgreement, setFileStorageAgreement] = useState<APICompanyFileStorageAgreement>();
  const isUserKnlTeamInCharter = CompaniesUtils.checkIsKnlProfile(companies || []);

  const { hasUserAccessToCompany } = useCanAccess(PermissionList.WEB_DASHBOARD);

  useEffect(() => {
    if (!currentCompany || !currentCompany.chargebeeSubscriptions) {
      return;
    }

    const promises = currentCompany.chargebeeSubscriptions.map((subscriptionId: string) => {
      return getChargebeeSubscriptionById(subscriptionId).then((response: APISubscriptionResult) => {
        return response.data;
      });
    });

    Promise.all(promises).then((values) => {
      setSubscriptions([...values]);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!currentCompany) {
      return;
    }

    getCompanyContacts(currentCompany.id)
      .then((response) => {
        setContacts(response.data.items);
      })
      .finally(() => {
        setContactsLoading(false);
      });


      getCompanyUsers(currentCompany.id)
      .then((response) => {
        setUsers(response.data.items);
      })
      .finally(() => {
        setUsersLoading(false);
      });
  }, [currentCompany]);

  useEffect(() => {
    if (!currentCompany) {
      return;
    }

    getCompanyById(currentCompany.id)
      .then((response) => {
        const fileStorageAgreementResponse = response.data?.fileStorageAgreement ?? undefined;
        setFileStorageAgreement(fileStorageAgreementResponse);
      })
      .finally(() => {
        setFileStorageAgreementLoading(false);
      });
  }, [currentCompany]);

  if (contactsLoading || fileStorageAgreementLoading) {
    return <PageContentLoader />;
  }

  if (!currentCompany) {
    return <EmptyPageContent />;
  }
  if (!hasUserAccessToCompany(currentCompany.id)) {
    history.push(LINK.UNAUTHORIZED.path);
  }

  const handleSubmit = (
    values: APIPatchCompanyParams,
    callbackAsyncDone: () => void,
    bundleValues?: APICompanyBundleParams
  ): void => {
    patchCompany(currentCompany.id, values)
      .then((response) => {
        const isUserPoc = currentCompany?.isUserPoc || false;
        const company = new Company(response.data, isUserPoc);

        const event = LogUtils.getCompanyCreateOrUpdateEvent(LOGGING_EVENT.UPDATE_COMPANY, values, company.id);
        loggingManager.logEventObject(event);

        if (values.connectedToPitchy) {
          const connectedToPitchyEvent = LogUtils.getCompanyCreateOrUpdateEvent(
            LOGGING_EVENT.CONNECT_TO_PITCHY,
            values,
            company.id
          );
          loggingManager.logEventObject(connectedToPitchyEvent);
        }

        setFileStorageAgreement(company.fileStorageAgreement);

        if (bundleValues) {
          postCompanyBundleById(company.id, BundlesUtils.formatBundleForApi(bundleValues))
            .then(() => {
              dispatch(updateCompany(company));
              dispatch(setCompanyBundle(BundlesUtils.formatApiCompanyBundleParamsToStore(bundleValues)));

              message.success(t('company.form.updateSuccess'));
            })
            .catch((e: any) => {
              log.error(e.message);
              message.error(t('company.form.updateError'));
            })
            .finally(() => {
              callbackAsyncDone();
            });
        }
      })
      .catch((e: any) => {
        log.error(e.message);
        message.error(t('company.form.updateError'));
      })
      .finally(() => {
        if (!bundleValues) {
          callbackAsyncDone();
        }
      });
  };

  let extra;
  if (isUserKnlTeamInCharter) {
    const subscriptionPresent =
      currentCompany.chargebeeSubscriptions && currentCompany.chargebeeSubscriptions.length > 0;
    extra = [
      <Tag
        key="tagChargebeeCustomer"
        className={currentCompany.chargebeeCustomer ? classes.successTag : classes.errorTag}
      >
        {currentCompany.chargebeeCustomer
          ? t('company.form.chargebeeCustomerPresent')
          : t('company.form.chargebeeCustomerAbsent')}
      </Tag>,
      <Tag key="tagChargebeePlan" className={subscriptionPresent ? classes.successTag : classes.errorTag}>
        {subscriptionPresent
          ? t('company.form.chargebeeSubscriptionPresent')
          : t('company.form.chargebeeSubscriptionAbsent')}
      </Tag>,
    ];
  }

  return (
    <>
      <KannellePageHeader
        title={isUserKnlTeamInCharter ? t('company.form.updateTitle') : t('company.form.informationTitle')}
        extra={<div className={classes.tagContainer}>{extra}</div>}
        className={isMobileOrTablet ? classes.pageContentMobile : classes.pageContent}
        avatar={{ className: classes.avatar, icon: <SettingOutlined /> }}
      >
        {isUserKnlTeamInCharter && (
          <Row className={classes.subtitleRow}>
            <p>{t('company.form.updateDesc')}</p>
          </Row>
        )}
        {isUserKnlTeamInCharter ? (
          <CompanyForm
            type="update"
            handleSubmit={handleSubmit}
            currentCompany={currentCompany}
            subscriptions={subscriptions}
            contacts={contacts}
            users={users}
            fileStorageAgreed={fileStorageAgreement && !!fileStorageAgreement.signatory}
          />
        ) : (
          <CompanyDescription
            currentCompany={currentCompany}
            subscriptions={subscriptions}
            contacts={contacts}
            handleSubmit={handleSubmit}
            fileStorageAgreed={fileStorageAgreement && !!fileStorageAgreement.signatory}
          />
        )}
      </KannellePageHeader>
      {isUserKnlTeamInCharter && currentCompany.chargebeeCustomer && (
        <KannellePageHeader
          title={<span>{t('company.form.chargebeePortal')}</span>}
          className={classNames(classes.pageContent, classes.pageContentChargebee)}
          avatar={{ className: classes.avatar, icon: <SettingOutlined /> }}
        >
          {!currentCompany.chargebeePlan && <ErrorAlert message="company.form.warningChargebeePlan" />}
          {currentCompany.chargebeePlan && (
            <ChargebeePortal key="ChargebeePortal" chargebeeCustomer={currentCompany.chargebeeCustomer} />
          )}
          {/* We comment this for now, might need it in the (far) future */}
          {/* {currentCompany.chargebeePlan && ( */}
          {/*  <ChargebeeCheckout key="ChargebeeCheckout" currentCompany={currentCompany} /> */}
          {/* )} */}
        </KannellePageHeader>
      )}
    </>
  );
};

export default CompanySettings;
