import React, { FunctionComponent, useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import ReactPlayer from 'react-player';
import {
  VideoTrim,
  VideoTrimUtils,
} from '../../../pages/charters/charter-projects/components/project-editor/scene-timeline/utils/VideoTrimUtils';
import AnimationsUtils from '../../../utils/AnimationsUtils';
import MathUtils from '../../../utils/MathUtils';
import MediaUtils from '../../../utils/MediaUtils';
import { MaskCodes, PIP, Size } from '../../../utils/types/AnimationTypes';

type Props = {
  currentTime: number;
  isPlaying: boolean;
  format: string;
  mask?: MaskCodes;
  pip?: PIP;
  sceneVideoPlayerSize?: Size;
  sceneVideoTrimValue?: VideoTrim;
  seekTo?: number;
};

type StyleProps = {
  sceneVideoPlayerSize?: Size;
  shouldBeDisplayed?: boolean;
};

const useStyles = createUseStyles({
  pipContainer: ({ sceneVideoPlayerSize, shouldBeDisplayed }: StyleProps) => ({
    display: shouldBeDisplayed ? 'block' : 'none',
    height: sceneVideoPlayerSize?.height ?? '100%',
    width: sceneVideoPlayerSize?.width ?? '100%',
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  }),
  pipVideoContainer: {
    position: 'absolute',
  },
});

const SceneVideoPlayerVideoPIP: FunctionComponent<Props> = ({
  currentTime,
  isPlaying,
  format,
  pip,
  mask,
  sceneVideoPlayerSize,
  sceneVideoTrimValue,
  seekTo = 0,
}: Props) => {
  const pipVideoPlayerRef = useRef<ReactPlayer>(null);

  const pipDelay = pip?.delay || 0;
  const pipDuration = pip?.duration || 0;
  const pipTrimStartAt = pip?.trimStartAt || 0;

  const pipStartTime =
    sceneVideoTrimValue?.trimStart && sceneVideoTrimValue?.trimStart > pipTrimStartAt
      ? pipDelay + sceneVideoTrimValue.trimStart
      : pipDelay;

  const isPipStarted = currentTime >= pipStartTime;
  const isPipEnded = currentTime > pipStartTime + pipDuration;
  const isVisible = isPipStarted && !isPipEnded;
  const classes = useStyles({ sceneVideoPlayerSize, shouldBeDisplayed: isPipStarted && !isPipEnded });

  useEffect(() => {
    if (seekTo !== undefined) {
      pipVideoPlayerRef.current?.seekTo(
        MathUtils.formatInputValueWithXDecimals(seekTo - pipDelay + pipTrimStartAt, 2),
        'seconds'
      );
    }
  }, [seekTo]);

  useEffect(() => {
    if (!sceneVideoTrimValue) {
      return;
    }

    const trimStart = VideoTrimUtils.getMinTrimStartForPosition(sceneVideoTrimValue, {
      trimStart: pip?.trimStartAt || 0,
      trimEnd: pip?.trimEndAt || 0,
      delay: pip?.delay || 0,
    });

    const correctedTime = currentTime - trimStart;
    const currentPlayerTime = pipVideoPlayerRef?.current?.getCurrentTime() || 0;

    if (correctedTime < currentPlayerTime) {
      pipVideoPlayerRef.current?.seekTo(correctedTime, 'seconds');
    }
  }, [currentTime]);

  useEffect(() => {
    if (isVisible && isPlaying) {
      pipVideoPlayerRef.current?.seekTo(pipTrimStartAt);
    }
  }, [isVisible]);

  // If there is no pip, or if the video player's size is not defined
  if (!(pip && sceneVideoPlayerSize)) {
    return null;
  }

  const pipPositionStyle = AnimationsUtils.computePipPositionAndSize(pip, sceneVideoPlayerSize, format, mask);

  return (
    <div className={classes.pipContainer}>
      <div className={classes.pipVideoContainer} style={pipPositionStyle}>
        <ReactPlayer
          url={MediaUtils.getMediaUrl(pip.source)}
          playing={isPlaying && isVisible}
          loop
          volume={pip.audioVolume}
          width="100%"
          height="100%"
          progressInterval={25}
          ref={pipVideoPlayerRef}
          key={pip.source.url}
        />
      </div>
    </div>
  );
};

export default SceneVideoPlayerVideoPIP;
