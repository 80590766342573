import { CopyOutlined, DeleteOutlined, EyeInvisibleFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';
import i18n, { TFunction } from 'i18next';
import { cloneDeep } from 'lodash';
import React from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { Placement, Step } from 'react-joyride';
import { THEME } from '../Constants';
import { Roles } from '../core/rule/RolesTypes';
import Company from '../model/Company';
import AddScenarioScene from '../pages/charters/charter-scenarios/components/AddScenarioScene';
import {
  Asset,
  DashboardSubtitles,
  MediaCroppedArea,
  MediaFile,
  Scenario,
  UsersModelCharter,
} from '../services/api/types/ChartersServiceTypes';
import { AnimationFormats } from './types/AnimationTypes';
import { MediaType } from './types/MediaTypes';
import { Project, ProjectCreationType, ProjectFormats, ProjectScene, ProjectStatus } from './types/ProjectTypes';
import { ScenarioStatus } from './types/ScenarioTypes';
import { AnimationTheme } from './types/ThemeTypes';

export default class JoyRideUtils {
  static getOnBoardingStepComputer = (t: TFunction, companies?: Company[]): Step[] => {
    const steps = [
      {
        target: '.user-info',
        title: t('onBoarding.steps.stepProfile.title'),
        content: (
          <span>
            <span style={{ marginRight: 8 }}>{t('onBoarding.steps.stepProfile.emojiStart')}</span>
            {t('onBoarding.steps.stepProfile.text')}
          </span>
        ),
        disableBeacon: true,
      },
      {
        target: '.helps-link',
        title: t('onBoarding.steps.stepHelp.title'),
        content: (
          <span>
            <span style={{ marginRight: 8 }}>{t('onBoarding.steps.stepHelp.emojiStart')}</span>
            {t('onBoarding.steps.stepHelp.text')}
            <span style={{ marginLeft: 8 }}>{t('onBoarding.steps.stepHelp.emojiEnd')}</span>
          </span>
        ),
        placement: 'right' as Placement,
        disableBeacon: true,
      },
      {
        target: '.notification-container',
        title: t('onBoarding.steps.stepNotifications.title'),
        content: (
          <span>
            <span style={{ marginRight: 8 }}>{t('onBoarding.steps.stepNotifications.emojiStart')}</span>
            {t('onBoarding.steps.stepNotifications.text')}
          </span>
        ),
        disableBeacon: true,
      },
      {
        target: '.menu-container',
        title: t('onBoarding.steps.stepMenu.title'),
        content: (
          <span>
            <span style={{ marginRight: 8 }}>{t('onBoarding.steps.stepMenu.emojiStart')}</span>
            {t('onBoarding.steps.stepMenu.text')}
          </span>
        ),
        placement: 'right' as Placement,
        disableBeacon: true,
      },
      {
        target: '.charters-link',
        title: t('onBoarding.steps.stepCharters.title'),
        content: (
          <span>
            <p>
              <span style={{ marginRight: 8 }}>{t('onBoarding.steps.stepCharters.emojiStart')}</span>
              {t('onBoarding.steps.stepCharters.text')}
            </p>
          </span>
        ),
        placement: 'right' as Placement,
        disableBeacon: true,
      },
      {
        target: '.first-charter-link',
        title: t('onBoarding.steps.stepCharterDetails.title'),
        content: (
          <span>
            <p>
              <span style={{ marginRight: 8 }}>{t('onBoarding.steps.stepCharterDetails.emojiStart')}</span>
              {t('onBoarding.steps.stepCharterDetails.textPart1')}
            </p>
            <p>{t('onBoarding.steps.stepCharterDetails.textPart2')}</p>
          </span>
        ),
        placement: 'right' as Placement,
        disableBeacon: true,
      },
      {
        target: '.company-settings-link',
        title: t('onBoarding.steps.stepCompanySettings.title'),
        content: (
          <span>
            <span style={{ marginRight: 8 }}>{t('onBoarding.steps.stepCompanySettings.emojiStart')}</span>
            {t('onBoarding.steps.stepCompanySettings.text')}
          </span>
        ),
        placement: 'right' as Placement,
        disableBeacon: true,
      },
    ];

    if (companies && companies.length > 1) {
      steps.unshift({
        target: '.companySelector',
        title: t('onBoarding.steps.stepCompany.title'),
        content: (
          <span>
            <span style={{ marginRight: 8 }}>{t('onBoarding.steps.stepCompany.emojiStart')}</span>
            {t('onBoarding.steps.stepCompany.text')}
          </span>
        ),
        disableBeacon: true,
      } as any);
    }

    return steps;
  };

  static getOnBoardingStepMobile = (t: TFunction, companies?: Company[]): Step[] => {
    const steps = [
      {
        target: '.user-info',
        title: t('onBoarding.steps.stepProfile.title'),
        content: t('onBoarding.steps.stepProfile.text'),
        placement: 'left-end' as Placement,
        disableBeacon: true,
      },
      {
        target: '.notification-container',
        title: t('onBoarding.steps.stepNotifications.title'),
        content: t('onBoarding.steps.stepNotifications.text'),
        disableBeacon: true,
      },
      {
        target: '.menu-trigger',
        title: t('onBoarding.steps.stepMenu.title'),
        content: t('onBoarding.steps.stepMenu.text'),
        placement: 'right' as Placement,
        disableBeacon: true,
      },
    ];

    if (companies && companies.length > 1) {
      steps.unshift({
        target: '.companySelector',
        title: t('onBoarding.steps.stepCompany.title'),
        content: t('onBoarding.steps.stepCompany.text'),
        disableBeacon: true,
      } as any);
    }

    return steps;
  };

  static getCharterThemeSteps = (t: TFunction): Step[] => {
    const steps = [
      {
        target: '.theme-container',
        title: t('userHelp.theme.steps.stepSection.title'),
        content: t('userHelp.theme.steps.stepSection.text'),
        disableBeacon: true,
      },
      {
        target: '.initializer-panel',
        title: t('userHelp.theme.steps.stepInitializer.title'),
        content: t('userHelp.theme.steps.stepInitializer.text'),
        disableBeacon: true,
      },
      {
        target: '.initializer-apply',
        title: t('userHelp.theme.steps.stepInitializerApply.title'),
        content: t('userHelp.theme.steps.stepInitializerApply.text'),
        position: 'top',
        disableBeacon: true,
      },
      {
        target: '.algiers',
        title: t('userHelp.theme.steps.stepTheme.title'),
        content: t('userHelp.theme.steps.stepTheme.text'),
        position: 'top',
        disableBeacon: true,
      },
      {
        target: '.themeEditionContainer',
        title: t('userHelp.theme.steps.stepThemeLeft.title'),
        content: t('userHelp.theme.steps.stepThemeLeft.text'),
        position: 'top',
        disableBeacon: true,
      },
      {
        target: '.filterContainer',
        title: t('userHelp.theme.steps.stepThemeFilter.title'),
        content: t('userHelp.theme.steps.stepThemeFilter.text'),
        position: 'right',
        disableBeacon: true,
      },
      {
        target: '.themeFieldsContainer',
        title: t('userHelp.theme.steps.stepThemeFields.title'),
        content: t('userHelp.theme.steps.stepThemeFields.text'),
        position: 'right',
        disableBeacon: true,
      },
      {
        target: '.lottieArrayContainer',
        title: t('userHelp.theme.steps.stepThemeAnimations.title'),
        content: (
          <span>
            <p>{t('userHelp.theme.steps.stepThemeAnimations.text')}</p>
            <p>{t('userHelp.theme.steps.stepThemeAnimations.text1')}</p>
            <ul>
              <li>{t('userHelp.theme.steps.stepThemeAnimations.text2')}</li>
              <li>{t('userHelp.theme.steps.stepThemeAnimations.text3')}</li>
            </ul>
          </span>
        ),
        position: 'top',
        disableBeacon: true,
      },
      {
        target: '.themeEditionContainer .ant-btn.ant-btn-primary',
        title: t('userHelp.theme.steps.stepSave.title'),
        content: t('userHelp.theme.steps.stepSave.text'),
        position: 'right',
        disableBeacon: true,
      },
      {
        target: '.saveThemeColors',
        title: t('userHelp.theme.steps.stepSaveAll.title'),
        content: t('userHelp.theme.steps.stepSaveAll.text'),
        position: 'right',
        disableBeacon: true,
      },
    ];

    return steps;
  };

  static getUsersSteps = (t: TFunction): Step[] => {
    return [
      {
        target: '.usersPageContainer',
        title: t('userHelp.users.steps.stepPage.title'),
        content: t('userHelp.users.steps.stepPage.text'),
        disableBeacon: true,
      },
      {
        target: '.addUserButton .ant-btn',
        title: t('userHelp.users.steps.stepAddUser.title'),
        content: t('userHelp.users.steps.stepAddUser.text'),
        disableBeacon: true,
      },
      {
        target: '.removeUserButton',
        title: t('userHelp.users.steps.stepRemoveUser.title'),
        content: t('userHelp.users.steps.stepRemoveUser.text'),
        disableBeacon: true,
      },
      {
        target: '.updateUserRoleButton',
        title: t('userHelp.users.steps.stepUpdateRoleBatch.title'),
        content: t('userHelp.users.steps.stepUpdateRoleBatch.text'),
        disableBeacon: true,
      },
      {
        target: '.updateUserIsActiveButton',
        title: t('userHelp.users.steps.stepUpdateIsActiveBatch.title'),
        content: t('userHelp.users.steps.stepUpdateIsActiveBatch.text'),
        disableBeacon: true,
      },
      {
        target: '.ant-table-tbody tr:nth-child(3) .usernameContainer',
        title: t('userHelp.users.steps.stepUserName.title'),
        content: t('userHelp.users.steps.stepUserName.text'),
        disableBeacon: true,
      },
      {
        target: '.ant-table-tbody tr:nth-child(3) .roleTagContainer',
        title: t('userHelp.users.steps.stepUpdateRole.title'),
        content: t('userHelp.users.steps.stepUpdateRole.text'),
        disableBeacon: true,
      },
      {
        target: '.ant-table-tbody tr:nth-child(3) .statusTag',
        title: t('userHelp.users.steps.stepUpdateIsActive.title'),
        content: t('userHelp.users.steps.stepUpdateIsActive.text'),
        disableBeacon: true,
      },
      {
        target: '.ant-table-column-sorter',
        title: t('userHelp.users.steps.stepSort.title'),
        content: t('userHelp.users.steps.stepSort.text'),
        disableBeacon: true,
      },
      {
        target: '.ant-table-filter-trigger-container',
        title: t('userHelp.users.steps.stepFilter.title'),
        content: t('userHelp.users.steps.stepFilter.text'),
        disableBeacon: true,
      },
    ];
  };

  static getUsersStepsMobile = (t: TFunction): Step[] => {
    return [
      {
        target: '.usersPageContainer',
        title: t('userHelp.users.steps.stepPage.title'),
        content: t('userHelp.users.steps.stepPage.text'),
        disableBeacon: true,
      },
      {
        target: '.addUserButton .ant-btn',
        title: t('userHelp.users.steps.stepAddUser.title'),
        content: t('userHelp.users.steps.stepAddUser.text'),
        disableBeacon: true,
      },
      {
        target: '.removeUserButton',
        title: t('userHelp.users.steps.stepRemoveUser.title'),
        content: t('userHelp.users.steps.stepRemoveUser.text'),
        disableBeacon: true,
      },
      {
        target: '.updateUserRoleButton',
        title: t('userHelp.users.steps.stepUpdateRoleBatch.title'),
        content: t('userHelp.users.steps.stepUpdateRoleBatch.text'),
        disableBeacon: true,
      },
      {
        target: '.updateUserIsActiveButton',
        title: t('userHelp.users.steps.stepUpdateIsActiveBatch.title'),
        content: t('userHelp.users.steps.stepUpdateIsActiveBatch.text'),
        disableBeacon: true,
      },
      {
        target: '.updateUserIsActiveButton',
        title: t('userHelp.users.steps.stepMobileAction.title'),
        content: t('userHelp.users.steps.stepMobileAction.text'),
        disableBeacon: true,
        placement: 'center' as Placement,
      },
    ];
  };

  static getFontsSteps = (t: TFunction): Step[] => {
    return [
      {
        target: '.ant-page-header',
        title: t('userHelp.fonts.steps.stepPage.title'),
        content: t('userHelp.fonts.steps.stepPage.text'),
        disableBeacon: true,
      },
      {
        target: '.ant-page-header-heading-extra .ant-btn',
        title: t('userHelp.fonts.steps.stepAddFont.title'),
        content: t('userHelp.fonts.steps.stepAddFont.text'),
        disableBeacon: true,
      },
      {
        target: '.fontTabs',
        content: t('userHelp.fonts.steps.stepFonts.text'),
        disableBeacon: true,
      },
      {
        target: '.fontTabs .ant-tabs-nav-list .ant-tabs-tab:nth-child(1)',
        title: t('userHelp.fonts.steps.stepKannelleFontsTab.title'),
        content: t('userHelp.fonts.steps.stepKannelleFontsTab.text'),
        disableBeacon: true,
      },
      {
        target: '.fontTabs .ant-tabs-nav-list .ant-tabs-tab:nth-child(2)',
        title: t('userHelp.fonts.steps.stepUploadedFontsTab.title'),
        content: t('userHelp.fonts.steps.stepUploadedFontsTab.text'),
        disableBeacon: true,
      },
      {
        target: '.kannelleFonts .ant-card .ant-btn',
        title: t('userHelp.fonts.steps.stepSelectFont.title'),
        content: t('userHelp.fonts.steps.stepSelectFont.text'),
        disableBeacon: true,
      },
      {
        target: '.titleFont',
        content: t('userHelp.fonts.steps.stepTitleFont.text'),
        disableBeacon: true,
      },
      {
        target: '.textFont',
        content: t('userHelp.fonts.steps.stepTextFont.text'),
        disableBeacon: true,
      },
      {
        target: '.reset-font',
        title: t('userHelp.fonts.steps.stepResetFont.title'),
        content: t('userHelp.fonts.steps.stepResetFont.text'),
        disableBeacon: true,
      },
      {
        target: '.apply-font',
        title: t('userHelp.fonts.steps.stepApplyFont.title'),
        content: t('userHelp.fonts.steps.stepApplyFont.text'),
        disableBeacon: true,
      },
      {
        target: '.text-example-container',
        title: t('userHelp.fonts.steps.stepFontExample.title'),
        content: t('userHelp.fonts.steps.stepFontExample.text'),
        disableBeacon: true,
      },
      {
        target: '.font-size-select-container',
        title: t('userHelp.fonts.steps.stepFontSelectSize.title'),
        content: t('userHelp.fonts.steps.stepFontSelectSize.text'),
        disableBeacon: true,
      },
      {
        target: '.font-size-slider-container',
        title: t('userHelp.fonts.steps.stepFontSliderSize.title'),
        content: t('userHelp.fonts.steps.stepFontSliderSize.text'),
        disableBeacon: true,
      },
    ];
  };

  static getAssetsSteps = (t: TFunction, assetType: string): Step[] => {
    return [
      {
        target: '.ant-page-header',
        title: t(`userHelp.${assetType}.steps.stepGeneral.title`),
        content: t(`userHelp.${assetType}.steps.stepGeneral.text`),
        disableBeacon: true,
        placement: 'center' as Placement,
      },
      {
        target: '.ant-page-header-heading-extra .ant-btn',
        title: t(`userHelp.${assetType}.steps.stepAdd.title`),
        content: t(`userHelp.${assetType}.steps.stepAdd.text`),
        disableBeacon: true,
      },
      {
        target: '.activated-assets',
        title: t(`userHelp.${assetType}.steps.stepActivated.title`),
        content: t(`userHelp.${assetType}.steps.stepActivated.text`),
        disableBeacon: true,
      },
      {
        target: '.deactivated-assets',
        title: t(`userHelp.${assetType}.steps.stepDeactivated.title`),
        content: t(`userHelp.${assetType}.steps.stepDeactivated.text`),
        disableBeacon: true,
      },
      {
        target: '.activated-assets .ant-switch',
        title: t(`userHelp.${assetType}.steps.stepActivation.title`),
        content: t(`userHelp.${assetType}.steps.stepActivation.text`),
        disableBeacon: true,
      },
    ];
  };

  static getCharterMediaLibrarySteps = (t: TFunction): Step[] => {
    return [
      {
        target: '.ant-page-header',
        title: t(`userHelp.charterMediaLibrary.steps.stepGeneral.title`),
        content: t(`userHelp.charterMediaLibrary.steps.stepGeneral.text`),
        disableBeacon: true,
        placement: 'center' as Placement,
      },
      {
        target: '.ant-page-header-heading-extra .ant-btn',
        title: t(`userHelp.charterMediaLibrary.steps.stepAdd.title`),
        content: t(`userHelp.charterMediaLibrary.steps.stepAdd.text`),
        disableBeacon: true,
      },
      {
        target: '.ant-tabs-tab:nth-child(1)',
        title: t(`userHelp.charterMediaLibrary.steps.stepImages.title`),
        content: t(`userHelp.charterMediaLibrary.steps.stepImages.text`),
        disableBeacon: true,
      },
      {
        target: '.ant-tabs-tab:nth-child(2)',
        title: t(`userHelp.charterMediaLibrary.steps.stepVideos.title`),
        content: t(`userHelp.charterMediaLibrary.steps.stepVideos.text`),
        disableBeacon: true,
      },
    ];
  };

  static getCharterScenariosSteps = (t: TFunction): Step[] => {
    return [
      {
        target: '.ant-page-header',
        title: t(`userHelp.charterScenarios.steps.stepGeneral.title`),
        content: t(`userHelp.charterScenarios.steps.stepGeneral.text`),
        disableBeacon: true,
        placement: 'center' as Placement,
      },
      {
        target: '.ant-page-header-heading-extra .ant-btn',
        title: t(`userHelp.charterScenarios.steps.stepCreate.title`),
        content: t(`userHelp.charterScenarios.steps.stepCreate.text`),
        disableBeacon: true,
      },
      {
        target: '.ant-col .ant-card:nth-child(1)',
        title: t(`userHelp.charterScenarios.steps.stepScenarioCard.title`),
        content: t(`userHelp.charterScenarios.steps.stepScenarioCard.text`),
        disableBeacon: true,
      },
      {
        target: '.ant-col .ant-card:nth-child(1) .scenarioActionsButton',
        title: t(`userHelp.charterScenarios.steps.stepScenarioActions.title`),
        content: t(`userHelp.charterScenarios.steps.stepScenarioActions.text`),
        disableBeacon: true,
      },
      {
        target: '.ant-col .ant-card:nth-child(1) .variantsContainer',
        title: t(`userHelp.charterScenarios.steps.stepScenarioVariants.title`),
        content: t(`userHelp.charterScenarios.steps.stepScenarioVariants.text`),
        disableBeacon: true,
      },
      {
        target: '.ant-col .ant-card:nth-child(1) .mainVariant',
        title: t(`userHelp.charterScenarios.steps.stepScenarioMainVariant.title`),
        content: t(`userHelp.charterScenarios.steps.stepScenarioMainVariant.text`),
        disableBeacon: true,
      },
      {
        target: '.statusTag',
        title: t(`userHelp.charterScenarios.steps.stepVariantStatus.title`),
        content: (
          <div>
            {t('userHelp.charterScenarios.steps.stepVariantStatus.text')}
            <ul>
              <li>
                <Tag color={ScenarioStatus.PUBLISHED.color}>{t(ScenarioStatus.PUBLISHED.key)}</Tag>
                {t(`userHelp.charterScenarios.steps.stepVariantStatus.published`)}
              </li>
              <li>
                <Tag color={ScenarioStatus.DRAFT.color}>{t(ScenarioStatus.DRAFT.key)}</Tag>
                {t(`userHelp.charterScenarios.steps.stepVariantStatus.draft`)}
              </li>
              <li>
                <Tag color={ScenarioStatus.ARCHIVED.color}>{t(ScenarioStatus.ARCHIVED.key)}</Tag>
                {t(`userHelp.charterScenarios.steps.stepVariantStatus.archived`)}
              </li>
            </ul>
          </div>
        ),
        disableBeacon: true,
        placement: 'right' as Placement,
      },
      {
        target: '.ant-col .ant-card:nth-child(1) .ant-card-actions',
        title: t(`userHelp.charterScenarios.steps.stepAddVariant.title`),
        content: t(`userHelp.charterScenarios.steps.stepAddVariant.text`),
        disableBeacon: true,
      },
    ];
  };

  static getCharterScenarioVariantEditorSteps = (t: TFunction, canDeleteScene: boolean): Step[] => {
    return [
      {
        target: '.ant-page-header',
        title: t(`userHelp.charterScenarioVariantEditor.steps.stepGeneral.title`),
        content: t(`userHelp.charterScenarioVariantEditor.steps.stepGeneral.text`),
        disableBeacon: true,
        placement: 'center' as Placement,
      },
      {
        target: '.scenarioVariantMetadataForm',
        title: t(`userHelp.charterScenarioVariantEditor.steps.stepScenarioVariantMetadataForm.title`),
        content: t(`userHelp.charterScenarioVariantEditor.steps.stepScenarioVariantMetadataForm.text`),
        disableBeacon: true,
      },
      {
        target: '.variantsScenesList',
        title: t(`userHelp.charterScenarioVariantEditor.steps.stepVariantsScenesList.title`),
        content: (
          <>
            <p>{t(`userHelp.charterScenarioVariantEditor.steps.stepVariantsScenesList.text1`)}</p>
            <p>{t(`userHelp.charterScenarioVariantEditor.steps.stepVariantsScenesList.text2`)}</p>
            <AddScenarioScene />
          </>
        ),
        disableBeacon: true,
        placement: 'auto' as Placement,
      },
      {
        target: '.ant-card:nth-child(1)',
        title: t(`userHelp.charterScenarioVariantEditor.steps.stepSceneCard.title`),
        content: t(`userHelp.charterScenarioVariantEditor.steps.stepSceneCard.text`),
        disableBeacon: true,
        placement: 'right' as Placement,
      },
      {
        target: '.sceneEditionForm',
        title: t(`userHelp.charterScenarioVariantEditor.steps.stepSceneEditionForm.title`),
        content: t(`userHelp.charterScenarioVariantEditor.steps.stepSceneEditionForm.text`),
        disableBeacon: true,
      },
      {
        target: '.unsavedVariantSceneChangesBadge',
        title: t(`userHelp.charterScenarioVariantEditor.steps.stepUnsavedChangesButton.title`),
        content: t(`userHelp.charterScenarioVariantEditor.steps.stepUnsavedChangesButton.text`),
        disableBeacon: true,
      },
      {
        target: '.updateVariantSceneButton',
        title: t(`userHelp.charterScenarioVariantEditor.steps.stepUpdateVariantSceneButton.title`),
        content: t(`userHelp.charterScenarioVariantEditor.steps.stepUpdateVariantSceneButton.text`),
        disableBeacon: true,
      },
      {
        target: '.ant-card:nth-child(1) span.anticon-drag',
        title: t(`userHelp.charterScenarioVariantEditor.steps.stepReorderScenes.title`),
        content: t(`userHelp.charterScenarioVariantEditor.steps.stepReorderScenes.text`),
        disableBeacon: true,
      },
      {
        target: '.ant-card:nth-child(1) .duplicateScenarioVariantScene',
        title: t(`userHelp.charterScenarioVariantEditor.steps.stepDuplicateScene.title`),
        content: t(`userHelp.charterScenarioVariantEditor.steps.stepDuplicateScene.text`),
        disableBeacon: true,
      },
      ...(canDeleteScene
        ? [
            {
              target: '.ant-card:nth-child(1) .deleteScenarioVariantScene',
              title: t(`userHelp.charterScenarioVariantEditor.steps.stepDeleteScene.title`),
              content: t(`userHelp.charterScenarioVariantEditor.steps.stepDeleteScene.text`),
              disableBeacon: true,
            },
          ]
        : []),
      {
        target: '.deleteScenarioVariantButton',
        title: t(`userHelp.charterScenarioVariantEditor.steps.stepDeleteVariant.title`),
        content: t(`userHelp.charterScenarioVariantEditor.steps.stepDeleteVariant.text`),
        disableBeacon: true,
      },
      {
        target: '.downloadScenarioVariantPDFButton',
        title: t(`userHelp.charterScenarioVariantEditor.steps.stepDownloadPdf.title`),
        content: t(`userHelp.charterScenarioVariantEditor.steps.stepDownloadPdf.text`),
        disableBeacon: true,
      },
    ];
  };

  static getCharterScenarioVariantEditorStepsMobile = (t: TFunction, canDeleteScene: boolean): Step[] => {
    return [
      {
        target: '.ant-page-header',
        title: t(`userHelp.charterScenarioVariantEditor.steps.stepGeneral.title`),
        content: t(`userHelp.charterScenarioVariantEditor.steps.stepGeneral.text`),
        disableBeacon: true,
        placement: 'center' as Placement,
      },
      {
        target: '.scenarioVariantMetadataForm',
        title: t(`userHelp.charterScenarioVariantEditor.steps.stepScenarioVariantMetadataForm.title`),
        content: t(`userHelp.charterScenarioVariantEditor.steps.stepScenarioVariantMetadataForm.text`),
        disableBeacon: true,
      },
      {
        target: '.variantsScenesList',
        title: t(`userHelp.charterScenarioVariantEditor.steps.stepVariantsScenesList.title`),
        content: (
          <>
            <p>{t(`userHelp.charterScenarioVariantEditor.steps.stepVariantsScenesList.text1Mobile`)}</p>
            <p>{t(`userHelp.charterScenarioVariantEditor.steps.stepVariantsScenesList.text2`)}</p>
            <AddScenarioScene />
          </>
        ),
        disableBeacon: true,
        placement: 'auto' as Placement,
      },
      {
        target: '.ant-card:nth-child(1)',
        title: t(`userHelp.charterScenarioVariantEditor.steps.stepSceneCard.title`),
        content: t(`userHelp.charterScenarioVariantEditor.steps.stepSceneCard.text`),
        disableBeacon: true,
        placement: 'right' as Placement,
      },
      {
        target: '.ant-card:nth-child(1) span.anticon-drag',
        title: t(`userHelp.charterScenarioVariantEditor.steps.stepReorderScenes.title`),
        content: t(`userHelp.charterScenarioVariantEditor.steps.stepReorderScenes.text`),
        disableBeacon: true,
      },
      {
        target: '.ant-card:nth-child(1) .duplicateScenarioVariantScene',
        title: t(`userHelp.charterScenarioVariantEditor.steps.stepDuplicateScene.title`),
        content: t(`userHelp.charterScenarioVariantEditor.steps.stepDuplicateScene.text`),
        disableBeacon: true,
        placement: 'auto' as Placement,
      },
      ...(canDeleteScene
        ? [
            {
              target: '.ant-card:nth-child(1) .deleteScenarioVariantScene',
              title: t(`userHelp.charterScenarioVariantEditor.steps.stepDeleteScene.title`),
              content: t(`userHelp.charterScenarioVariantEditor.steps.stepDeleteScene.text`),
              disableBeacon: true,
              placement: 'auto' as Placement,
            },
          ]
        : []),
      {
        target: '.deleteScenarioVariantButton',
        title: t(`userHelp.charterScenarioVariantEditor.steps.stepDeleteVariant.title`),
        content: t(`userHelp.charterScenarioVariantEditor.steps.stepDeleteVariant.text`),
        disableBeacon: true,
      },
      {
        target: '.downloadScenarioVariantPDFButton',
        title: t(`userHelp.charterScenarioVariantEditor.steps.stepDownloadPdf.title`),
        content: t(`userHelp.charterScenarioVariantEditor.steps.stepDownloadPdf.text`),
        disableBeacon: true,
      },
    ];
  };

  static getPermissionsSteps = (t: TFunction): Step[] => {
    return [
      {
        target: '.ant-page-header',
        title: t(`userHelp.permissions.steps.stepGeneral.title`),
        content: t(`userHelp.permissions.steps.stepGeneral.text`),
        disableBeacon: true,
        placement: 'center' as Placement,
      },
      {
        target: '.scenarioPermissionCheckbox',
        title: t('userHelp.permissions.steps.stepPublicScenario.title'),
        content: t('userHelp.permissions.steps.stepPublicScenario.text'),
        disableBeacon: true,
      },
      {
        target: '.ant-table-tbody tr:nth-child(1) td:nth-child(4)',
        title: t('userHelp.permissions.steps.stepUpdateOwner.title'),
        content: t('userHelp.permissions.steps.stepUpdateOwner.text'),
        disableBeacon: true,
      },
      {
        target: '.ant-table-tbody tr:nth-child(1) td:nth-child(5)',
        title: t('userHelp.permissions.steps.stepUpdateAdmin.title'),
        content: t('userHelp.permissions.steps.stepUpdateAdmin.text'),
        disableBeacon: true,
      },
      {
        target: '.ant-table-tbody tr:nth-child(1) td:nth-child(6)',
        title: t('userHelp.permissions.steps.stepUpdateCreator.title'),
        content: t('userHelp.permissions.steps.stepUpdateCreator.text'),
        disableBeacon: true,
      },
      {
        target: '.ant-table-column-sorter-full',
        title: t('userHelp.permissions.steps.stepSort.title'),
        content: t('userHelp.permissions.steps.stepSort.text'),
        disableBeacon: true,
      },
      {
        target: '.ant-table-cell:nth-child(2) .ant-table-filter-trigger',
        title: t('userHelp.permissions.steps.stepSearch.title'),
        content: t('userHelp.permissions.steps.stepSearch.text'),
        disableBeacon: true,
      },
      {
        target: '.ant-table-cell:nth-child(3) .ant-table-filter-trigger',
        title: t('userHelp.permissions.steps.stepFilter.title'),
        content: t('userHelp.permissions.steps.stepFilter.text'),
        disableBeacon: true,
      },
    ];
  };

  static getCharterProjectsSteps = (t: TFunction): Step[] => {
    return [
      {
        target: '.ant-page-header',
        title: t(`userHelp.charterProjects.steps.stepGeneral.title`),
        content: t(`userHelp.charterProjects.steps.stepGeneral.text`),
        disableBeacon: true,
        placement: 'center' as Placement,
      },
      {
        target: '.ant-page-header-heading-extra .ant-btn',
        title: t(`userHelp.charterProjects.steps.stepCreate.title`),
        content: t(`userHelp.charterProjects.steps.stepCreate.text`),
        disableBeacon: true,
      },
      {
        target: '.ant-col .ant-card:nth-child(1)',
        title: t(`userHelp.charterProjects.steps.stepProjectCard.title`),
        content: t(`userHelp.charterProjects.steps.stepProjectCard.text`),
        disableBeacon: true,
      },
      {
        target: '.ant-col .ant-card:nth-child(1) .ant-tag',
        title: t(`userHelp.charterProjects.steps.stepProjectStatus.title`),
        content: (
          <div>
            {t('userHelp.charterProjects.steps.stepProjectStatus.text')}
            <ul>
              <li>
                <Tag color={ProjectStatus.FINALIZED.color}>{t(ProjectStatus.FINALIZED.key)}</Tag>
                {t(`userHelp.charterProjects.steps.stepProjectStatus.finalized`)}
              </li>
              <li>
                <Tag color={ProjectStatus.DRAFT.color}>{t(ProjectStatus.DRAFT.key)}</Tag>
                {t(`userHelp.charterProjects.steps.stepProjectStatus.draft`)}
              </li>
              <li>
                <Tag color={ProjectStatus.BACKUPED.color}>{t(ProjectStatus.BACKUPED.key)}</Tag>
                {t(`userHelp.charterProjects.steps.stepProjectStatus.backuped`)}
              </li>
              <li>
                <Tag color={ProjectStatus.PROCESSING.color}>{t(ProjectStatus.PROCESSING.key)}</Tag>
                {t(`userHelp.charterProjects.steps.stepProjectStatus.processing`)}
              </li>
              <li>
                <Tag color={ProjectStatus.ERROR.color}>{t(ProjectStatus.ERROR.key)}</Tag>
                {t(`userHelp.charterProjects.steps.stepProjectStatus.error`)}
              </li>
            </ul>
          </div>
        ),
        disableBeacon: true,
        placement: 'right' as Placement,
      },
      {
        target: '.ant-col .ant-card:nth-child(1) .ant-card-body div:nth-child(1)',
        title: t(`userHelp.charterProjects.steps.projectFormat.title`),
        content: t(`userHelp.charterProjects.steps.projectFormat.text`),
        disableBeacon: true,
      },
      {
        target: '.ant-col .ant-card:nth-child(1) .ant-card-body',
        title: t(`userHelp.charterProjects.steps.projectMetadata.title`),
        content: t(`userHelp.charterProjects.steps.projectMetadata.text`),
        disableBeacon: true,
      },
      {
        target: '.ant-col .ant-card:nth-child(1) .ant-card-actions li:nth-child(1)',
        title: t(`userHelp.charterProjects.steps.stepDeleteAction.title`),
        content: t(`userHelp.charterProjects.steps.stepDeleteAction.text`),
        disableBeacon: true,
      },
      {
        target: '.ant-col .ant-card:nth-child(1) .ant-card-actions li:nth-child(2)',
        title: t(`userHelp.charterProjects.steps.stepWatchAction.title`),
        content: t(`userHelp.charterProjects.steps.stepWatchAction.text`),
        disableBeacon: true,
      },
    ];
  };

  static getCharterProjectEditorSteps = (t: TFunction): Step[] => {
    return [
      {
        target: '.ant-page-header',
        title: t(`userHelp.charterProjectEditor.stepEditor.stepGeneral.title`),
        content: t(`userHelp.charterProjectEditor.stepEditor.stepGeneral.text`),
        disableBeacon: true,
        placement: 'center' as Placement,
      },
      {
        target: '.ant-card:nth-child(1)',
        title: t(`userHelp.charterProjectEditor.stepEditor.projectProperties.title`),
        content: t(`userHelp.charterProjectEditor.stepEditor.projectProperties.text`),
        disableBeacon: true,
        placement: 'right' as Placement,
      },
      {
        target: '.ant-page-header-content .scenesCarousel',
        title: t(`userHelp.charterProjectEditor.stepEditor.carousel.title`),
        content: (
          <>
            <p>{t(`userHelp.charterProjectEditor.stepEditor.carousel.text1`)}</p>
            <p>{t(`userHelp.charterProjectEditor.stepEditor.carousel.text2`)}</p>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                style={{
                  height: 80,
                  width: 160,
                  borderRadius: 8,
                  whiteSpace: 'normal',
                }}
                icon={<PlusOutlined />}
              >
                {t('charters.projects.projectEditor.addSceneModal.addScene')}
              </Button>
            </div>
          </>
        ),
        disableBeacon: true,
      },
      {
        target: '.ant-page-header-content .scenesContainer > div:nth-last-child(3)', // The recordable scene in carousel
        title: t(`userHelp.charterProjectEditor.stepEditor.sceneCard.title`),
        content: (
          <div>
            <p>{t('userHelp.charterProjectEditor.stepEditor.sceneCard.text1')}</p>
            <p>{t('userHelp.charterProjectEditor.stepEditor.sceneCard.text2')}</p>
            <ul>
              <li>
                {t(`userHelp.charterProjectEditor.stepEditor.sceneCard.action1`)}
                <Button icon={<CopyOutlined />} shape="circle" size="small" style={{ marginLeft: 8 }} />
              </li>
              <li>
                {t(`userHelp.charterProjectEditor.stepEditor.sceneCard.action2`)}
                <Button danger icon={<DeleteOutlined />} shape="circle" size="small" style={{ marginLeft: 8 }} />
              </li>
              <li>{t(`userHelp.charterProjectEditor.stepEditor.sceneCard.action3`)}</li>
            </ul>
          </div>
        ),
        disableBeacon: true,
      },
      {
        target: '.ant-row .ant-col',
        title: t(`userHelp.charterProjectEditor.stepEditor.scenePreview.title`),
        content: t(`userHelp.charterProjectEditor.stepEditor.scenePreview.text`),
        disableBeacon: true,
        placement: 'right' as Placement,
      },
      {
        target: '.ant-page-header',
        title: t(`userHelp.charterProjectEditor.stepEditor.recordableScene.title`),
        content: t(`userHelp.charterProjectEditor.stepEditor.recordableScene.text`),
        disableBeacon: true,
        placement: 'center' as Placement,
      },
      {
        target: '.ant-page-header-content .sceneElementAnimationRow',
        title: t(`userHelp.charterProjectEditor.stepEditor.sceneAnimation.title`),
        content: (
          <>
            <p>{t(`userHelp.charterProjectEditor.stepEditor.sceneAnimation.text1`)}</p>
            <ul>
              <li>
                <p>{t(`userHelp.charterProjectEditor.stepEditor.sceneAnimation.text2`)}</p>
              </li>
              <li>
                <p>{t(`userHelp.charterProjectEditor.stepEditor.sceneAnimation.text3`)}</p>
              </li>
            </ul>
            <p>{t(`userHelp.charterProjectEditor.stepEditor.sceneAnimation.text4`)}</p>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button shape="round" icon={<PlusOutlined />} style={{ background: '#EDEFF3' }}>
                {t('charters.projects.projectEditor.sceneEditor.fields.animation.addAnAnimation')}
              </Button>
            </div>
          </>
        ),
        disableBeacon: true,
      },
      {
        target: '.ant-page-header-content .sceneElementPipRow',
        title: t(`userHelp.charterProjectEditor.stepEditor.scenePip.title`),
        content: (
          <>
            <p>{t(`userHelp.charterProjectEditor.stepEditor.scenePip.text1`)}</p>
            <ul>
              <li>
                <p>{t(`userHelp.charterProjectEditor.stepEditor.scenePip.text2`)}</p>
              </li>
              <li>
                <p>{t(`userHelp.charterProjectEditor.stepEditor.scenePip.text3`)}</p>
              </li>
            </ul>
            <p>{t(`userHelp.charterProjectEditor.stepEditor.scenePip.text4`)}</p>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button shape="round" icon={<PlusOutlined />} style={{ background: '#EDEFF3' }}>
                {t('charters.projects.projectEditor.sceneEditor.fields.pip.addPip')}
              </Button>
            </div>
          </>
        ),
        disableBeacon: true,
      },
      {
        target: '.ant-page-header-content .sceneElementVideoRow',
        title: t(`userHelp.charterProjectEditor.stepEditor.sceneVideo.title`),
        content: (
          <>
            <p>{t(`userHelp.charterProjectEditor.stepEditor.sceneVideo.text1`)}</p>
            <ul>
              <li>
                <p>{t(`userHelp.charterProjectEditor.stepEditor.sceneVideo.text2`)}</p>
              </li>
              <li>
                <p>{t(`userHelp.charterProjectEditor.stepEditor.sceneVideo.text3`)}</p>
              </li>
            </ul>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                height: 40,
              }}
            >
              <BsChevronCompactLeft
                style={{
                  borderRadius: '8px 0 0 8px',
                  backgroundColor: THEME.PROJECTS.EDITOR.TIMELINE.SELECTED_ELEMENT_BORDER,
                  color: 'white',
                  height: '100%',
                  marginRight: 20,
                }}
              />
              <BsChevronCompactRight
                style={{
                  borderRadius: '0 8px 8px 0',
                  backgroundColor: THEME.PROJECTS.EDITOR.TIMELINE.SELECTED_ELEMENT_BORDER,
                  color: 'white',
                  height: '100%',
                  marginLeft: 20,
                }}
              />
            </div>
          </>
        ),
        disableBeacon: true,
      },
      {
        target: '.ant-row .ant-col:nth-child(2)',
        title: t(`userHelp.charterProjectEditor.stepEditor.sceneRecordableOptions.title`),
        content: (
          <>
            <p>{t(`userHelp.charterProjectEditor.stepEditor.sceneRecordableOptions.text1`)}</p>
            <ul>
              <li>
                <p>{t(`userHelp.charterProjectEditor.stepEditor.sceneRecordableOptions.text2`)}</p>
              </li>
              <li>
                <p>{t(`userHelp.charterProjectEditor.stepEditor.sceneRecordableOptions.text3`)}</p>
              </li>
              <li>
                <p>{t(`userHelp.charterProjectEditor.stepEditor.sceneRecordableOptions.text4`)}</p>
              </li>
            </ul>
          </>
        ),
        disableBeacon: true,
        placement: 'left' as Placement,
      },
      {
        target: '.ant-page-header',
        title: t(`userHelp.charterProjectEditor.stepEditor.slideScene.title`),
        content: t(`userHelp.charterProjectEditor.stepEditor.slideScene.text`),
        disableBeacon: true,
        placement: 'center' as Placement,
      },
      {
        target: '.ant-page-header-content .sceneElementAnimationRow',
        title: t(`userHelp.charterProjectEditor.stepEditor.sceneAnimation.title`),
        content: (
          <>
            <p>{t(`userHelp.charterProjectEditor.stepEditor.sceneAnimation.text1`)}</p>
            <ul>
              <li>
                <p>{t(`userHelp.charterProjectEditor.stepEditor.sceneAnimation.text3`)}</p>
              </li>
            </ul>
          </>
        ),
        disableBeacon: true,
      },
      {
        target: '.ant-row .ant-col:nth-child(2)',
        title: t(`userHelp.charterProjectEditor.stepEditor.sceneSlideOptions.title`),
        content: (
          <>
            <p>{t(`userHelp.charterProjectEditor.stepEditor.sceneSlideOptions.text1`)}</p>
            <ul>
              <li>
                <p>{t(`userHelp.charterProjectEditor.stepEditor.sceneSlideOptions.text2`)}</p>
              </li>
              <li>
                <p>{t(`userHelp.charterProjectEditor.stepEditor.sceneSlideOptions.text3`)}</p>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button type="primary" danger shape="circle" size="small" icon={<EyeInvisibleFilled />} />
                </div>
              </li>
            </ul>
          </>
        ),
        disableBeacon: true,
        placement: 'left' as Placement,
      },

      {
        target: '.ant-page-header-content .scenesContainer > div:last-child', // The inactive scene in carousel
        title: t(`userHelp.charterProjectEditor.stepEditor.inactiveScene.title`),
        content: (
          <div>
            <p>{t('userHelp.charterProjectEditor.stepEditor.inactiveScene.text')}</p>
          </div>
        ),
        disableBeacon: true,
      },
      {
        target: '.useThisSceneButton',
        title: t(`userHelp.charterProjectEditor.stepEditor.useThisSceneButton.title`),
        content: (
          <div>
            <p>{t('userHelp.charterProjectEditor.stepEditor.useThisSceneButton.text')}</p>
          </div>
        ),
        disableBeacon: true,
      },
      {
        target: '.ant-btn-primary',
        title: t(`userHelp.charterProjectEditor.stepEditor.nextStep.title`),
        content: t(`userHelp.charterProjectEditor.stepEditor.nextStep.text`),
        disableBeacon: true,
      },
      {
        target: '.ant-btn-primary',
        content: t(`userHelp.charterProjectEditor.stepEditor.editAgain.text`),
        disableBeacon: true,
        placement: 'center' as Placement,
      },
      {
        target: '.ant-page-header',
        title: t(`userHelp.charterProjectEditor.stepFinalization.stepGeneral.title`),
        content: t(`userHelp.charterProjectEditor.stepFinalization.stepGeneral.text`),
        disableBeacon: true,
        placement: 'center' as Placement,
      },
      {
        target: '.ant-row .ant-col',
        title: t(`userHelp.charterProjectEditor.stepFinalization.projectPreview.title`),
        content: t(`userHelp.charterProjectEditor.stepFinalization.projectPreview.text`),
        disableBeacon: true,
        placement: 'right' as Placement,
      },
      {
        target: '.ant-row .ant-col:nth-child(2)',
        title: t(`userHelp.charterProjectEditor.stepFinalization.projectOptions.title`),
        content: (
          <>
            <p>{t(`userHelp.charterProjectEditor.stepFinalization.projectOptions.text1`)}</p>
            <ul>
              <li>
                <p>{t(`userHelp.charterProjectEditor.stepFinalization.projectOptions.text2`)}</p>
              </li>
              <li>
                <p>{t(`userHelp.charterProjectEditor.stepFinalization.projectOptions.text3`)}</p>
              </li>{' '}
              <li>
                <p>{t(`userHelp.charterProjectEditor.stepFinalization.projectOptions.text4`)}</p>
              </li>{' '}
              <li>
                <p>{t(`userHelp.charterProjectEditor.stepFinalization.projectOptions.text5`)}</p>
              </li>{' '}
              <li>
                <p>{t(`userHelp.charterProjectEditor.stepFinalization.projectOptions.text6`)}</p>
              </li>{' '}
              <li>
                <p>{t(`userHelp.charterProjectEditor.stepFinalization.projectOptions.text7`)}</p>
              </li>
            </ul>
          </>
        ),
        disableBeacon: true,
        placement: 'left' as Placement,
      },
      {
        target: '.ant-btn-primary',
        title: t(`userHelp.charterProjectEditor.stepFinalization.finalize.title`),
        content: t(`userHelp.charterProjectEditor.stepFinalization.finalize.text`),
        disableBeacon: true,
      },
    ];
  };

  static generateSampleAsset = (assetUrl: string): Asset => {
    return {
      createdAt: 'string',
      id: -666,
      isActive: true,
      isArchived: false,
      updatedAt: 'string',
      url: assetUrl,
      operationId: '123456789ABCD',
    };
  };

  static generateSampleMediaFile = (mediaUrl: string, mediaType: MediaType): MediaFile => {
    return {
      createdAt: 'string',
      id: -666,
      isActive: true,
      isArchived: false,
      updatedAt: 'string',
      url: mediaUrl,
      publicName: `sample-file.${mediaType === MediaType.IMAGE ? 'png' : 'mp4'}`,
      operationId: '123456789ABCD',
      status: 'PROCESSED',
      mediaType,
    };
  };

  static generateSampleUser = (): UsersModelCharter => {
    return {
      createdAt: 'string',
      dashboard: { subtitles: { currentUsage: 0 } },
      email: 'john.doe@example.com',
      id: 'ExampleUser',
      acl: {
        isActive: true,
        role: Roles.Owner,
        permissions: [],
      },
      name: 'John Doe',
      updatedAt: 'string',
      highlightRow: false,
    };
  };

  static generateSampleScenario = (demoCoverUrl: string): Scenario => {
    return {
      id: -666,
      coverImageSource: {
        id: -667,
        url: demoCoverUrl,
        isArchived: false,
        operationId: '123456789ABCD',
        publicName: 'sample-cover.jpg',
        status: 'PROCESSED',
        mediaType: MediaType.IMAGE,
        createdAt: '2020-03-25T10:55:07.568Z',
        updatedAt: '2020-03-25T10:55:10.052Z',
        isActive: true,
        croppedArea: { x: 0, y: 67, width: 539, height: 404 },
      },
      code: 'SAMPLE_SCENARIO_1605544068000',
      defaultTheme: 'BALI',
      scope: 'PRIVATE',
      variants: [
        {
          id: -668,
          isMain: false,
          title: 'Mon scénario de test',
          description: "C'est le scénario de test",
          format: '9:16',
          language: 'fr',
          status: 'PUBLISHED',
          duration: 5,
          sceneIndexOrder: [0],
        },
        {
          id: -669,
          isMain: true,
          title: 'My sample scenario',
          description: 'This is the sample scenario',
          format: '16:9',
          language: 'en',
          status: 'PUBLISHED',
          duration: 5,
          sceneIndexOrder: [0],
        },
      ],
    };
  };

  static generateSampleProject = (): Project => {
    return {
      id: -666,
      projectUuid: '123456789ABCD',
      title: 'Kannelle',
      creationType: ProjectCreationType.EMPTY,
      customColors: true,
      videoFormat: AnimationFormats.FORMAT_16_9,
      dismissedFeatures: [],
      sceneIndexesOrder: [],
      audioVolume: 0,
      finalVideo: ('finalVideo' as unknown) as MediaFile,
      author: {
        id: '667',
        email: 'kannelle@kannelle.io',
        name: 'kannelle',
        createdAt: '2020-03-25T10:55:07.568Z',
        updatedAt: '2020-03-25T10:55:10.052Z',
        dashboard: ('DashboardSubtitles' as unknown) as DashboardSubtitles,
      },
      createdAt: '2020-03-25T10:55:07.568Z',
      updatedAt: '2020-03-25T10:55:10.052Z',
      lastSyncAt: '2020-03-25T10:55:10.052Z',
      status: ProjectStatus.FINALIZED.code,
      theme: AnimationTheme.BALI,
    };
  };

  static updateProjectForDemo = (project: Project): Project => {
    const croppedAreaSquareFormat: MediaCroppedArea = {
      x: 420,
      y: 0,
      width: 1080,
      height: 1080,
    };

    const croppedAreaLandscapeFormat: MediaCroppedArea = {
      x: 0,
      y: 0,
      width: 1920,
      height: 1080,
    };

    const croppedAreaPortraitFormat: MediaCroppedArea = {
      x: 656,
      y: 0,
      width: 603,
      height: 1072,
    };

    const getMediaCroppedAreaValue = () => {
      switch (project.videoFormat) {
        case ProjectFormats.LANDSCAPE_16_9.code:
          return croppedAreaLandscapeFormat;
        case ProjectFormats.PORTRAIT_9_16.code:
          return croppedAreaPortraitFormat;
        default:
          return croppedAreaSquareFormat;
      }
    };

    const scenesToAdd1: ProjectScene = {
      id: 666666666,
      index: 100000,
      title: 'Scene',
      isActive: true,
      kind: 'RECORDABLE',
      description: i18n.t(`userHelp.charterProjectEditor.projectDemo.sceneDescription`),
      videoRecommendedTime: 5,
      videoMinRecordTime: 0,
      videoMaxRecordTime: 20,
      cameraZoom: 0,
      createdAt: ('2020-03-25T10:55:07.568Z' as unknown) as Date,
      updatedAt: ('2020-03-25T10:55:10.052Z' as unknown) as Date,
      backgroundVideo: {
        media: {
          id: 19805,
          isActive: true,
          isArchived: false,
          url: 'https://files.kannelle.io/workspaces/85/videos/85b16d0d-8108-457d-890e-e368d3e069a1.mp4',
          hlsUrl:
            'https://files.kannelle.io/workspaces/85/videos/85b16d0d-8108-457d-890e-e368d3e069a1/85b16d0d-8108-457d-890e-e368d3e069a1.m3u8',
          operationId: '797b0c35-0101-4224-aa6d-57fa48c08f05',
          publicName: i18n.t(`userHelp.charterProjectEditor.projectDemo.videoTitle`),
          status: 'PROCESSED',
          mediaType: MediaType.VIDEO,
          height: 1080,
          width: 1920,
          duration: 6.166,
          size: 12896504,
          croppedArea: getMediaCroppedAreaValue(),
          updatedAt: '2022-01-13T09:30:21.653Z',
          createdAt: '2022-01-13T09:30:09.246Z',
        },
        audioVolume: 1,
        createdAt: '2022-01-11T15:57:54.741Z',
        updatedAt: '2022-01-11T15:57:54.741Z',
      },
      pip: {
        media: {
          id: 20137,
          isActive: true,
          isArchived: false,
          url: 'https://files.kannelle.io/workspaces/85/images/b42ebe05-9735-459e-b7e5-5bf2c6097b66.png',
          operationId: 'b7b1d8b9-f76a-441a-a47c-85063f213663',
          publicName: i18n.t(`userHelp.charterProjectEditor.projectDemo.pipTitle`),
          status: 'PROCESSED',
          mediaType: MediaType.IMAGE,
          height: 1080,
          width: 606,
          size: 1112679,
          // No need to compute the croppedArea for the PIP depending on the format since the PIP is not visible during the JoyRide
          croppedArea: {
            x: 0,
            y: 369,
            width: 592,
            height: 333,
          },
          updatedAt: '2022-01-26T16:47:21.490Z',
          createdAt: '2022-01-26T16:47:17.890Z',
        },
        audioVolume: 0,
        duration: 3,
        delay: 2,
        effectName: 'ZOOMIN',
        effectValue: 1.2,
        createdAt: '2022-01-26T16:48:03.212Z',
        updatedAt: '2022-01-26T16:48:03.212Z',
      },
    };

    const scenesToAdd2: ProjectScene = {
      id: 666666667,
      index: 100001,
      title: 'Scene',
      isActive: true,
      kind: 'SLIDE',
      description: i18n.t(`userHelp.charterProjectEditor.projectDemo.sceneDescription`),
      videoRecommendedTime: 5,
      videoMinRecordTime: 0,
      videoMaxRecordTime: 20,
      cameraZoom: 0,
      createdAt: ('2020-03-25T10:55:07.568Z' as unknown) as Date,
      updatedAt: ('2020-03-25T10:55:10.052Z' as unknown) as Date,
      animation: {
        code: 'FORD',
        active: true,
        position: 'FULLSCREEN',
        offsetX: 0,
        offsetY: 0,
        duration: 2,
        animationTexts: [
          {
            demoText: i18n.t(`userHelp.charterProjectEditor.projectDemo.animationTexts1.demoText`),
            placeholderText: i18n.t(`userHelp.charterProjectEditor.projectDemo.animationTexts1.placeholderText`),
            text: i18n.t(`userHelp.charterProjectEditor.projectDemo.animationTexts1.text`),
          },
          {
            demoText: i18n.t(`userHelp.charterProjectEditor.projectDemo.animationTexts2.demoText`),
            placeholderText: i18n.t(`userHelp.charterProjectEditor.projectDemo.animationTexts2.placeholderText`),
            text: i18n.t(`userHelp.charterProjectEditor.projectDemo.animationTexts2.text`),
          },
        ],
      },
    };
    const scenesToAdd3: ProjectScene = {
      id: 666666668,
      index: 100002,
      title: 'Scene',
      isActive: false,
      kind: 'SLIDE',
      description: i18n.t(`userHelp.charterProjectEditor.projectDemo.sceneDescription`),
      videoRecommendedTime: 5,
      videoMinRecordTime: 0,
      videoMaxRecordTime: 20,
      cameraZoom: 0,
      createdAt: ('2020-03-25T10:55:07.568Z' as unknown) as Date,
      updatedAt: ('2020-03-25T10:55:10.052Z' as unknown) as Date,
      animation: {
        code: 'PEUGEOT',
        active: true,
        position: 'FULLSCREEN',
        offsetX: 0,
        offsetY: 0,
        duration: 2,
        animationTexts: [
          {
            demoText: i18n.t(`userHelp.charterProjectEditor.projectDemo.animationTexts1.demoText`),
            placeholderText: i18n.t(`userHelp.charterProjectEditor.projectDemo.animationTexts1.placeholderText`),
            text: i18n.t(`userHelp.charterProjectEditor.projectDemo.animationTexts1.text`),
          },
          {
            demoText: i18n.t(`userHelp.charterProjectEditor.projectDemo.animationTexts2.demoText`),
            placeholderText: i18n.t(`userHelp.charterProjectEditor.projectDemo.animationTexts2.placeholderText`),
            text: i18n.t(`userHelp.charterProjectEditor.projectDemo.animationTexts2.text`),
          },
        ],
      },
    };

    const newProjectScenes: ProjectScene[] | undefined = cloneDeep(project.scenes);
    const newIndexOrder = cloneDeep(project.sceneIndexesOrder);

    newProjectScenes?.push(scenesToAdd1, scenesToAdd2, scenesToAdd3);
    newIndexOrder?.push(100000, 100001, 100002);

    return { ...project, scenes: newProjectScenes, sceneIndexesOrder: newIndexOrder };
  };
}
