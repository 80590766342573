import { EditFilled } from '@ant-design/icons';
import { Table } from 'antd';
import { map } from 'lodash';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { APITranslation } from '../../../services/api/types/TranslationServiceTypes';
import EditableCell from './EditableCell';
import EditableRow from './EditableRow';

type Props = {
  record: APITranslation;
  handleUpdate: any;
};

const useStyles = createUseStyles({
  expandedRowTable: {
    wordBreak: 'break-word',
    '& .ant-table-container, & .ant-table-container::before': {
      boxShadow: 'unset !important',
    },
  },
  container: {
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'space-between',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const TranslationTableSubRow: FunctionComponent<Props> = ({ record, handleUpdate }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const expandedRowColumns = [
    {
      key: 'translation_lang',
      dataIndex: 'lang',
      render: (lang: string): JSX.Element => <div>{t(`admin.translation.${lang}`)}</div>,
      width: 100,
    },
    {
      key: 'translation_translations_name',
      dataIndex: 'name',
      render: (name: string): JSX.Element | undefined => (
        <div className={classes.container}>
          {name}
          <span className={classes.iconContainer}>
            <EditFilled />
          </span>
        </div>
      ),
      width: 200,
      editable: true,
    },
  ];

  if (record.category === 'FAQ') {
    expandedRowColumns.push({
      key: 'translation_translations_url',
      dataIndex: 'url',
      render: (url: string): JSX.Element | undefined => (
        <div className={classes.container}>
          {url}
          <span className={classes.iconContainer}>
            <EditFilled />
          </span>
        </div>
      ),
      width: 200,
      editable: true,
    });
  }

  const dataSource = record.translations
    ? map(record.translations, (translation, key) => {
        return {
          id: record.id,
          name: translation?.name,
          url: translation?.url,
          lang: key,
          category: record.category,
        };
      })
    : [];

  const cols = expandedRowColumns.map((col) => {
    return {
      ...col,
      onCell: (rowRecord: APITranslation) => ({
        dataIndex: col.dataIndex,
        record: rowRecord,
        cellKey: col.key,
        editable: col.editable,
        handleUpdate,
      }),
    };
  });

  return (
    <>
      <Table
        tableLayout="fixed"
        className={classes.expandedRowTable}
        columns={cols as any}
        dataSource={dataSource}
        components={components}
        pagination={false}
        showHeader={false}
        rowKey={(rowRecord): string => {
          return `translation_sub_row_${record.code}_${rowRecord.lang}`;
        }}
      />
    </>
  );
};

export default TranslationTableSubRow;
