import { Form } from 'antd';
// eslint-disable-next-line import/no-named-default
import { default as ColorLib } from 'color';
import React, { FunctionComponent, useEffect, useState } from 'react';
import ColorCard from '../card/ColorCard';

type Props = {
  label: string;
  color: string;
  onEditColor: (newColor: string) => void;
};

const Color: FunctionComponent<Props> = ({ label, color, onEditColor }: Props) => {
  const [colorObject, setColorObject] = useState<ColorLib>();

  useEffect(() => {
    try {
      setColorObject(ColorLib(color));
    } catch (e) {
      setColorObject(ColorLib('#000000'));
      console.error(e);
    }
  }, [color]);

  if (!colorObject) {
    return null;
  }

  return (
    <Form.Item label={label}>
      <ColorCard color={colorObject.hex().toString()} layout="horizontal" size="small" onEditColor={onEditColor} />
    </Form.Item>
  );
};

export default Color;
