import { Empty } from 'antd';
import React, { FunctionComponent } from 'react';
import { createUseStyles } from 'react-jss';
import FileUploader, { FileUploaderProps } from '../modal/FileUploader';

type Props = {
  emptyMessage: string;
} & FileUploaderProps;

const useStyles = createUseStyles({
  emptyContainer: {
    minHeight: '70vh',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    display: 'block',
    marginBottom: '0.5em',
    color: '#3B3B3B',
    fontWeight: 400,
    fontSize: 20,
    lineHeight: '1.4',
  },
});

const EmptyFilesContent: FunctionComponent<Props> = ({
  emptyMessage,
  triggerText,
  modalTitle,
  uploadInstructionText,
  uploadInstructionHint,
  uploadInstructionSizeLimit,
  acceptFileType,
  sizeLimit,
  onSizeLimitExceeded,
  onUploadStatusChangeToDone,
  onUploadStatusChangeToError,
  onUploadFile,
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.emptyContainer}>
      <Empty
        description={
          <span>
            <span className={classes.title}>{emptyMessage}</span>
            <FileUploader
              triggerText={triggerText}
              modalTitle={modalTitle}
              uploadInstructionText={uploadInstructionText}
              uploadInstructionHint={uploadInstructionHint}
              uploadInstructionSizeLimit={uploadInstructionSizeLimit}
              acceptFileType={acceptFileType}
              sizeLimit={sizeLimit}
              onSizeLimitExceeded={onSizeLimitExceeded}
              onUploadStatusChangeToDone={onUploadStatusChangeToDone}
              onUploadStatusChangeToError={onUploadStatusChangeToError}
              onUploadFile={onUploadFile}
            />
          </span>
        }
      />
    </div>
  );
};

export default EmptyFilesContent;
