import { forEach } from 'lodash';
import React, { FunctionComponent, useMemo } from 'react';
import { Redirect, Route, Router as DomRouter, Switch } from 'react-router-dom';
import OfflineDetector from '../../components/offline-detector/OfflineDetector';
import SharedProject from '../../pages/shared-project/SharedProject';
import SubscriptionCancelled from '../../pages/subscription/SubscriptionCancelled';
import Unauthorized from '../../pages/unauthorized/Unauthorized';
import { NavigationConfig as routes } from '../../services/navigation/NavigationConfig';
import { NavigationMenuItems } from '../../services/navigation/NavigationMenuItem';
import { useAuth0 } from '../auth/Auth0Config';
import AuthBarrier from '../auth/AuthBarrier';
import Shell from '../shell/Shell';
import history from './History';
import PrivateRoute from './PrivateRoute';

const Router: FunctionComponent = () => {
  const { isAuthenticated } = useAuth0();

  const getAllNavigationRoutes = (navRoutes: NavigationMenuItems): NavigationMenuItems => {
    let navigationRoutes: NavigationMenuItems = [];

    forEach(navRoutes, (route) => {
      let childrenRoutes: NavigationMenuItems = [];
      if (route.children) {
        childrenRoutes = getAllNavigationRoutes(route.children);
      }

      navigationRoutes = [...navigationRoutes, route, ...childrenRoutes];
    });

    return navigationRoutes;
  };

  const navigationRoutes = useMemo(() => {
    return getAllNavigationRoutes(routes);
  }, [routes]);
  const defaultRoute = navigationRoutes.filter((route) => route.isDefaultRoute)[0];

  return (
    <DomRouter history={history}>
      <Switch>
        {/* Public route */}
        {/* CODE SAMPLE FOR PUBLIC ROUTE */}
        <Route path="/unauthorized" exact>
          <Unauthorized />
        </Route>
        {/* Disabled for now - SELFCHECKOUT-TAG */}
        {/* <Route path="/checkout" exact> */}
        {/*  <FreeTrialCheckout /> */}
        {/* </Route> */}
        <Route path="/subscription-cancelled" exact>
          <SubscriptionCancelled />
        </Route>
        <Route path="/shared/:publicCode" exact>
          <SharedProject />
        </Route>
        <AuthBarrier>
          <Shell>
            <OfflineDetector />
            <Switch>
              {/* Private route */}
              {navigationRoutes.map((route) => {
                return (
                  route.component && (
                    <PrivateRoute
                      route={route}
                      exact={route.exact ?? false}
                      path={route.path}
                      key={route.key}
                      component={route.component!}
                      isRouteAlwaysAccessible={route.isRouteAlwaysAccessible || false}
                      isAllowed={isAuthenticated}
                    />
                  )
                );
              })}
              {isAuthenticated && <Redirect to={defaultRoute.path} />}
            </Switch>
          </Shell>
        </AuthBarrier>
      </Switch>
    </DomRouter>
  );
};

export default Router;
