import { CancelTokenSource } from 'axios';
import { APIManager } from './APIManager';
import {
  APICheckoutPostChargebeeResult,
  APIPortalPostChargebeeResult,
  APISubscriptionResult,
} from './types/ChargebeeServiceTypes';

const getChargebee = (
  chargebeeCustomer: string,
  cancelSource?: CancelTokenSource
): Promise<APIPortalPostChargebeeResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(`/chargebee/portal?customerId=${chargebeeCustomer}`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const getChargebeeCheckout = (
  data: {
    customerId?: string;
    chargebeePlan?: string;
    chargebeePlanQuantity?: number;
  },
  cancelSource?: CancelTokenSource
): Promise<APICheckoutPostChargebeeResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(
    `/chargebee/checkout?customerId=${data.customerId}&plan=${data.chargebeePlan}&quantity=${data.chargebeePlanQuantity}`,
    {
      cancelToken: cancelSource?.token || undefined,
    }
  );
};

const getChargebeeSubscriptionById = (
  subscriptionId: string,
  cancelSource?: CancelTokenSource
): Promise<APISubscriptionResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(`/chargebee/subscriptions/${subscriptionId}`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const deleteChargebeeSubscriptionById = (subscriptionId: string, cancelSource?: CancelTokenSource): Promise<void> => {
  const apiManager = APIManager.getInstance();

  return apiManager.delete(`/chargebee/subscriptions/${subscriptionId}`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

export { getChargebee, getChargebeeCheckout, getChargebeeSubscriptionById, deleteChargebeeSubscriptionById };
