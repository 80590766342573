import { SettingOutlined } from '@ant-design/icons';
import { message, Row } from 'antd';
import { CancelTokenSource } from 'axios';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import KannellePageHeader from '../../components/page-header/KannellePageHeader';
import { DEVICE_SIZES_QUERIES, LINK, LOGGING_EVENT, THEME } from '../../Constants';
import Company from '../../model/Company';
import { setChartersList } from '../../redux/action/ChartersAction';
import { addCompany, selectCompany, setCompanyBundle, updateCompany } from '../../redux/action/CompaniesAction';
import { RootState } from '../../redux/RootState';
import { APIManager } from '../../services/api/APIManager';
import { createCompany, postCompanyBundleById } from '../../services/api/CompaniesService';
import { APICompanyBundleParams, APICreateCompanyParams } from '../../services/api/types/CompaniesServiceTypes';
import BundlesUtils from '../../utils/BundlesUtils';
import CompaniesUtils from '../../utils/CompaniesUtils';
import LogUtils from '../../utils/LogUtils';
import CompanyForm from './form/CompanyForm';

const useStyles = createUseStyles({
  pageContent: {
    backgroundColor: '#FFFFFF',
    margin: 24,
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      margin: 0,
    },
  },
  avatar: {
    backgroundColor: THEME.DEFAULT.MAIN_COLOR,
  },
  subtitleRow: {
    marginBottom: 16,
  },
});

const CreateCompany: FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const companies = useSelector((state: RootState) => state.companies.list);
  const currentUser = useSelector((state: RootState) => state.user.user);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);

  const isUserKnlTeamInCharter = CompaniesUtils.checkIsKnlProfile(companies || []);
  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());

  useEffect(() => {
    const cancelTokenSource = cancelTokenSourceRef.current;

    return (): void => {
      cancelTokenSource.cancel('Cancelled create company due to component unmount.');
    };
  }, []);

  const handleSubmit = (
    values: APICreateCompanyParams,
    callbackAsyncDone: () => void,
    bundleValues?: APICompanyBundleParams
  ): void => {
    createCompany(values, cancelTokenSourceRef.current).then((response) => {
      const isCurrentUserPoc = currentUser?.email === values.pocEmail;
      const company = new Company(response.data, isCurrentUserPoc);
      company.created = true;

      const event = LogUtils.getCompanyCreateOrUpdateEvent(LOGGING_EVENT.CREATE_COMPANY, values, company.id);
      loggingManager.logEventObject(event);

      dispatch(addCompany(company));
      dispatch(selectCompany(company));
      dispatch(setChartersList([]));

      if (bundleValues) {
        postCompanyBundleById(company.id, BundlesUtils.formatBundleForApi(bundleValues))
          .then(() => {
            dispatch(updateCompany(company));
            dispatch(setCompanyBundle(BundlesUtils.formatApiCompanyBundleParamsToStore(bundleValues)));
          })
          .finally(() => {
            message.success(t('company.form.createSuccess'));
            callbackAsyncDone();
            history.push('/charters/creation');
          });
      } else {
        message.success(t('company.form.createSuccess'));
        callbackAsyncDone();
        history.push('/charters/creation');
      }
    });
  };

  if (!isUserKnlTeamInCharter) {
    history.push(LINK.UNAUTHORIZED.path);
  }

  return (
    <KannellePageHeader
      title={t('company.form.createTitle')}
      className={classes.pageContent}
      avatar={{ className: classes.avatar, icon: <SettingOutlined /> }}
    >
      <Row className={classes.subtitleRow}>
        <p>{t('company.form.createDesc')}</p>
      </Row>
      <CompanyForm type="create" handleSubmit={handleSubmit} />
    </KannellePageHeader>
  );
};

export default CreateCompany;
