import { Tag } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Role } from '../../../../core/rule/Roles';

type Props = {
  roleName: string;
  className?: string;
  closable?: boolean;
  onClose?: (e: any) => void;
};

const useStyles = createUseStyles({
  roleTag: {
    borderRadius: 25,
    border: 'unset',
    marginRight: '0',
    textAlign: 'center',
    pointerEvents: 'none',
  },
});

const KannelleRoleTag: FunctionComponent<Props> = ({ roleName, onClose, className, closable }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const role = new Role(roleName);
  const roleTheme = role.getRoleTheme();
  const translatedRole = t(`roles.names.${role.currentRole}`);
  const { BACKGROUND_COLOR: bgColor, TEXT_COLOR: textColor } = roleTheme;

  return (
    <Tag
      className={classNames(classes.roleTag, className)}
      closable={closable}
      onClose={onClose}
      style={{ backgroundColor: bgColor, color: textColor }}
    >
      {translatedRole.toUpperCase()}
    </Tag>
  );
};

export default KannelleRoleTag;
