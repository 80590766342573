import handlebars from 'handlebars';
import i18next from 'i18next';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { APINotification } from '../services/api/types/UsersServiceTypes';
import { Notification } from './types/NotificationTypes';

export default class NotificationUtils {
  static generateNotificationId = (): string => {
    return uuidv4();
  };

  static transformAPINotificationIntoNotification = (apiNotification: APINotification): Notification => {
    const messageSource = apiNotification.message;
    const template = handlebars.compile(messageSource, { noEscape: true });
    let metadata;
    if (apiNotification.metadata) {
      metadata = NotificationUtils.transformNotificationsMetadata(apiNotification.metadata);
    }

    return {
      id: NotificationUtils.generateNotificationId(),
      creationDate: moment(apiNotification.sendDate),
      type: 'info',
      isRead: apiNotification.seen,
      message: template(metadata),
    };
  };

  static APINotificationsToNotifications = (
    apiNotifications: APINotification[],
    callback?: (notif: Notification) => void
  ): Notification[] => {
    const notifs: Notification[] = [];
    apiNotifications.forEach((apiNotification) => {
      const notif = NotificationUtils.transformAPINotificationIntoNotification(apiNotification);
      notifs.push(notif);
      if (callback) {
        callback(notif);
      }
    });
    return notifs;
  };

  static sortNotificationsByAntiChronologicalCreationDate = (notifA: Notification, notifB: Notification): number => {
    return notifB.creationDate.diff(notifA.creationDate);
  };

  // For now, to know which type of metadata we transform, we base ourselves on the key name. If it includes "Date"
  // (case insensitively), we assume it is an Unix timestamp. If it contains "Duration", then it is a period, etc.
  static transformNotificationsMetadata = (metadata: Record<string, string>): Record<string, string> => {
    const transformedMetadata = { ...metadata };

    Object.keys(transformedMetadata).forEach((key) => {
      if (key.match(/Date/i)) {
        // If it is a Date, it is actually a Unix timestamp (implemented as momentTimestamp = unixTimeStamp / 1000)
        const unixTimestamp = parseInt(transformedMetadata[key], 10) / 1000;
        const momentDate = moment.unix(unixTimestamp);
        transformedMetadata[key] = momentDate.format(i18next.t('timeFormats.humanDate'));
      } else if (key.match(/Duration/i)) {
        // If it is a Duration, it is actually an ISO 8601 duration, so we call "humanize" to format it
        const duration = moment.duration(transformedMetadata[key]);
        transformedMetadata[key] = duration.humanize();
      }
      // Else it is another type, we do nothing
    });

    return transformedMetadata;
  };
}
