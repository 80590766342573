import { find, forEach } from 'lodash';
import React, { ReactNode } from 'react';
import { InputCheckboxHelpType } from '../components/form/InputCheckBox';
import { Role } from '../core/rule/Roles';
import { Roles } from '../core/rule/RolesTypes';
import i18n from '../i18n';
import Company from '../model/Company';
import { APICompanyUsers, APIModelCompany } from '../services/api/types/CompaniesServiceTypes';
import StringUtils from './StringUtils';
import { PermissionList } from './types/CharterPermissionTypes';

export default class CompaniesUtils {
  static sortCompaniesByName = (companyA: Company, companyB: Company): number =>
    StringUtils.compareNaturalNormalized(companyA.name, companyB.name);

  static getValidCompany = (
    companiesApi: APIModelCompany[],
    isKnlTeam = false,
    pocCompanies?: APIModelCompany[]
  ): Company[] => {
    const activeCompanies: Company[] = [];

    if (pocCompanies) {
      pocCompanies
        .filter((apiCompany) => apiCompany.isActive)
        .forEach((apiCompany) => {
          const company = new Company(apiCompany, true);
          activeCompanies.push(company);
        });
    }

    companiesApi.forEach((apiCompany) => {
      const company = new Company(apiCompany);

      const activePocCompanies = find(activeCompanies, { id: company.id });
      // if the user is Poc AND present in the charter we must then
      // replace the charters from the poc company to Also have the
      // current user role and is active
      if (activePocCompanies) {
        activePocCompanies.charters = activePocCompanies.charters.map((pocCharter) => {
          const charterCompany = find(company.charters, { id: pocCharter.id });

          if (charterCompany) {
            return {
              ...pocCharter,
              currentUser: charterCompany.currentUser,
            };
          }

          return { ...pocCharter };
        });

        company.charters = activePocCompanies.charters;
        return;
      }

      if (!company.isValid(isKnlTeam)) {
        return;
      }

      activeCompanies.push(company);
    });

    return activeCompanies;
  };

  static getCurrentCompany = (companies: Company[], currentStateCompany?: Company): Company => {
    return (
      find(companies, (company) => company.id === currentStateCompany?.id) ||
      companies.sort(CompaniesUtils.sortCompaniesByName)[0]
    );
  };

  static checkIsKnlProfile = (companies: Company[] = []): boolean => {
    return companies.some((company) => {
      const { charters } = company;
      return charters.some((charter) => {
        return charter.currentUser && charter.currentUser.role === Roles.KnlTeam;
      });
    });
  };

  static checkUserHasRole = (companies: Company[] = [], role: string): boolean => {
    return companies.some((company) => {
      const { charters } = company;
      return charters.some((charter) => {
        return charter.currentUser && charter.currentUser.role === role;
      });
    });
  };

  static checkIsKnlProfileFromApi = (companiesApi: APIModelCompany[]): boolean => {
    return companiesApi.some((apiCompany) => {
      const company = new Company(apiCompany);
      const { charters } = company;
      return charters.some((charter) => {
        return charter.currentUser.role === Roles.KnlTeam;
      });
    });
  };

  static getNonKnlTeamUsersInCompanies = (companyUsers: APICompanyUsers[]): APICompanyUsers[] => {
    return companyUsers.filter((companyUser) => {
      let isUserKnl = false;
      companyUser.charters.forEach((charter) => {
        if (!isUserKnl && charter.currentUser.role === Roles.KnlTeam) {
          isUserKnl = true;
        }
      });

      return !isUserKnl;
    });
  };

  static getActiveUserIdsInCompanies = (companies: APICompanyUsers[]): string[] => {
    const activesUser: string[] = [];
    companies.forEach((companyUser) => {
      companyUser.charters.forEach((charter) => {
        if (charter.currentUser.isActive && !activesUser.includes(companyUser.id)) {
          activesUser.push(companyUser.id);
        }
      });
    });

    return activesUser;
  };

  static getHighestRoleInCompany = (company?: Company): Role | undefined => {
    if (!company) {
      return undefined;
    }

    const { charters } = company;
    let highestRole: Role | undefined;
    forEach(charters, (group) => {
      if (!group?.currentUser || !group?.currentUser?.role) {
        return;
      }

      const groupRole = new Role(group?.currentUser?.role);

      if (!highestRole || highestRole.getWeight() < groupRole.getWeight()) {
        highestRole = groupRole;
      }
    });

    return highestRole;
  };

  static buildFileStorageAgreementHelp = (displayPocOnlyWarning: boolean, pocEmails: string[]) => {
    if (!(displayPocOnlyWarning && pocEmails.length > 0)) {
      return [];
    }
    return [
      {
        helpType: 'info' as InputCheckboxHelpType,
        content: (
          <div>
            {i18n.t('company.form.fields.fileStorageAgreementHelp.helpPocOnlyPart1')}
            {pocEmails
              .map<ReactNode>((email: string) => (
                <a href={`mailto:${email}`} key={email}>
                  {email}
                </a>
              ))
              .reduce((prev, curr) => [prev, ', ', curr])}
            {i18n.t('company.form.fields.fileStorageAgreementHelp.helpPocOnlyPart2')}
          </div>
        ),
      },
    ];
  };

  static getFirstAccessibleCompany = (companies: Company[] | undefined): Company | undefined => {
    return companies?.find((company) => {
      return CompaniesUtils.isCompanyAccessible(company);
    });
  };

  static hasAccessibleCompany = (companies: Company[] | undefined): boolean => {
    return CompaniesUtils.getFirstAccessibleCompany(companies) !== undefined;
  };

  static isCompanyAccessible = (company: Company | undefined): boolean => {
    if (company && CompaniesUtils.isUserPocInCompany([company], company?.id)) {
      return true;
    }
    const hasAccess = company?.charters.some((charter) => {
      const charterPermission = charter.currentUser.permissions.find(
        (permission) => permission.code === PermissionList.WEB_DASHBOARD && permission.access === true
      );
      return charterPermission !== undefined;
    });
    return hasAccess !== undefined ? hasAccess : false;
  };

  static isUserPocInCompany = (companies: Company[] | undefined, companyId: number): boolean => {
    const company = companies?.find((comp) => comp.id === companyId);
    return company?.isUserPoc ?? false;
  };
}
