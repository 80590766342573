import {
  ArrowsAltOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import { Modal, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import PageContentLoader from '../../../../components/loader/PageContentLoader';
import Preformatted from '../../../../components/preformatted/Preformatted';
import ScenePlayer from '../../../../components/scene-player/ScenePlayer';
import SampleVideo from '../../../../resources/videos/sample2_16_9.mp4';
import AnimationsUtils from '../../../../utils/AnimationsUtils';
import { AnimationFormats } from '../../../../utils/types/AnimationTypes';
import { AnimationTheme, LottieObject } from '../../../../utils/types/ThemeTypes';

const UPDATED_ANIMATION_DURATION_IN_SECONDS = 8;

const useStyles = createUseStyles({
  lottieContainer: {
    cursor: 'pointer',
    position: 'relative',
    marginBottom: 5,
    '& div svg': {
      display: 'block',
    },
    '& .lottiePlayer': {
      height: '100%',
      background: '#33333387', // background not opaque
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      fontSize: 32,
      top: 0,
      left: 0,
      color: '#ffffff',
    },
  },
  playerActionsOnHover: {
    opacity: 0,
    transition: 'opacity 200ms ease-in-out',
    borderRadius: 5,

    '&:hover': {
      zIndex: 2,
      opacity: 1,
    },
  },
  invisiblePlayerActions: {
    opacity: 0,
    cursor: 'default',
    '&:hover': {
      opacity: 0,
    },
  },
  enlargeAnimationIcon: {
    top: '5px',
    right: '5px',
    position: 'absolute',
    fontSize: '15px',
    height: '20px',
    width: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #FFFFFF',
    borderRadius: '50%',
  },
  animationNameContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  animationNameInModalContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  updatedAnimation: {
    animation: `$shadowDrop ${UPDATED_ANIMATION_DURATION_IN_SECONDS}s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`,
  },

  '@keyframes shadowDrop': {
    '0%': {
      transform: 'translateZ(0)',
      boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
    },
    '50%': {
      transform: 'translateZ(50px)',
      boxShadow: '0 0 20px 0px rgba(0, 191, 0, 0.7)',
    },
    '100%': {
      transform: 'translateZ(0)',
      boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
    },
  },
  animationName: {
    fontSize: '16px',
    fontWeight: '700',
  },
  animationAliasContainer: {
    paddingBottom: 8,
    borderBottom: 'solid 1px #eeeeee',
    '& h3': {
      color: '#ffffff',
      marginBottom: 0,
    },
    '& > *:not(:last-child)': {
      marginRight: '8px',
    },
  },
  animationFieldsContainer: {
    marginTop: 4,
    '& h3': {
      color: '#ffffff',
      marginBottom: 0,
    },
  },
  animationAliasName: {
    fontSize: '12px',
    fontStyle: 'italic',
    backgroundColor: 'rgba(202, 202, 202, 0.5)',
  },
  animationAliasIcon: {
    marginLeft: 5,
  },
  fullscreenAnimationModal: {
    width: '70% !important',
  },
  playerWrapper: {
    borderRadius: 5,
  },
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: '#F7F7F7',
    zIndex: 4,
  },
});

type Props = {
  theme: AnimationTheme;
  lottieObject: LottieObject;
  displayAnimationAliases?: boolean;
  itemKey: string;
  callBackPlayPause?: (key: string, isPaused: boolean) => void;
  isPlaying?: boolean;
  updatedAnimations?: string[];
  themeColors: Record<string, string>;
};

const LottieDisplay: FunctionComponent<Props> = ({
  theme,
  lottieObject,
  itemKey,
  callBackPlayPause,
  displayAnimationAliases = false,
  isPlaying,
  updatedAnimations,
  themeColors,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isPaused, setIsPaused] = useState(true);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isUpdatedAnimation, setIsUpdatedAnimation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isSlideAnimation = AnimationsUtils.isSlideAnimation(lottieObject.animationName);
  const animationOption = AnimationsUtils.getAnimationOptionByCode(lottieObject.animationName);
  const animationPosition = animationOption
    ? AnimationsUtils.getPossiblePositionsByAnimationName(animationOption, theme)[0]
    : undefined;
  const animationTexts = animationOption
    ? AnimationsUtils.getDefaultAnimationTextsByAnimation(animationOption)
    : undefined;

  // Effect to display a green box-shadow behind the updated animations, and restart it every time the `updatedAnimations`
  // array changes
  useEffect(() => {
    let isMounted = true;

    const isUpdated =
      updatedAnimations && updatedAnimations.length > 0 ? updatedAnimations.includes(lottieObject.key) : false;

    setIsUpdatedAnimation(isUpdated);
    setTimeout(() => {
      if (isMounted) {
        setIsUpdatedAnimation(false);
      }
    }, UPDATED_ANIMATION_DURATION_IN_SECONDS * 1000);

    return (): void => {
      isMounted = false;
    };
  }, [updatedAnimations]);

  useEffect(() => {
    setIsPaused(!isPlaying);
  }, [isPlaying]);

  const handlePlayPause = (): void => {
    const newIsPaused = !isPaused;
    if (callBackPlayPause) {
      callBackPlayPause(itemKey, newIsPaused);
    }
  };

  const handleOpenAnimationModal = (e: SyntheticEvent<HTMLSpanElement>): void => {
    e.stopPropagation();
    setIsModalOpened(true);
    if (callBackPlayPause) {
      callBackPlayPause(itemKey, true);
    }
  };

  const handleCloseAnimationModal = (): void => {
    setIsModalOpened(false);
    if (callBackPlayPause) {
      callBackPlayPause(itemKey, true);
    }
  };

  const handleIsLoadingChange = (newIsLoading: boolean) => {
    setIsLoading(newIsLoading);
  };

  const renderAnimationPlayer = () => {
    const isPlayableAnimation = !lottieObject.isStaticAnimation;

    // Override only the theme color concerned by the current animation
    const fieldsForAnimation = Object.keys(themeColors)
      .filter((key) => lottieObject.fields.includes(key))
      .reduce(
        (obj, key) => ({
          ...obj,
          [key]: themeColors[key],
        }),
        {}
      );

    return (
      <div className={classNames(classes.lottieContainer)} onClick={isPlayableAnimation ? handlePlayPause : undefined}>
        {isLoading && <PageContentLoader indicator={<LoadingOutlined spin />} className={classes.loader} />}

        <ScenePlayer
          isPlaying={!isPaused}
          animationName={lottieObject.animationName}
          animationTheme={theme}
          animationPosition={animationPosition}
          videoUrl={!isSlideAnimation ? SampleVideo : undefined}
          format={AnimationFormats.FORMAT_16_9}
          animationTexts={animationTexts ?? []}
          controls={false}
          colorOverrides={fieldsForAnimation}
          seekTo={isPlayableAnimation ? 1.3 : undefined}
          hideBorders
          onIsLoadingChange={handleIsLoadingChange}
          wrapperClassName={classes.playerWrapper}
        />

        <span
          className={classNames(
            'lottiePlayer',
            classes.playerActionsOnHover,
            isModalOpened && !isPlayableAnimation && classes.invisiblePlayerActions
          )}
        >
          {!isModalOpened && (
            <span className={classes.enlargeAnimationIcon} onClick={handleOpenAnimationModal}>
              <ArrowsAltOutlined />
            </span>
          )}
          {isPlayableAnimation && <>{isPaused ? <PlayCircleOutlined /> : <PauseCircleOutlined />}</>}
        </span>
      </div>
    );
  };

  const { fields } = lottieObject;

  const animationNameAndAliases = (
    <>
      <Preformatted className={classes.animationName}>{t(lottieObject.key)}</Preformatted>

      <Tooltip
        placement="bottomLeft"
        title={
          <>
            {lottieObject.aliases && displayAnimationAliases && (
              <div className={classes.animationAliasContainer}>
                <h3>{t('charters.themes.aliasTitle', { count: lottieObject.aliases.length })}</h3>
                {lottieObject.aliases.map((animationAliasName) => (
                  <Preformatted key={`${itemKey}-${animationAliasName}`} className={classes.animationAliasName}>
                    {animationAliasName}
                  </Preformatted>
                ))}
              </div>
            )}
            <div className={classes.animationFieldsContainer}>
              <h3>{t('charters.themes.fieldTitle', { count: fields.length })}</h3>
              <ul>
                {fields.map(
                  (field: string): JSX.Element => {
                    return <li key={`tooltip-${itemKey}-${field}`}>{t(`charters.themes.formFields.${field}`)}</li>;
                  }
                )}
              </ul>
            </div>
          </>
        }
      >
        <span className={classes.animationAliasIcon}>
          <InfoCircleOutlined />
        </span>
      </Tooltip>
    </>
  );

  return (
    <div>
      <div className={classNames(isUpdatedAnimation && classes.updatedAnimation)}>{renderAnimationPlayer()}</div>
      {/* Animation name and aliases */}
      <div className={classes.animationNameContainer}>{animationNameAndAliases}</div>

      {/* Modal for fullscreen animation */}
      <Modal
        title={
          displayAnimationAliases && (
            <div className={classes.animationNameInModalContainer}>{animationNameAndAliases}</div>
          )
        }
        visible={isModalOpened}
        onCancel={handleCloseAnimationModal}
        centered
        footer={null}
        destroyOnClose
        className={classes.fullscreenAnimationModal}
      >
        {renderAnimationPlayer()}
      </Modal>
    </div>
  );
};
export default LottieDisplay;
