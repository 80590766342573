import FontFaceObserver from 'fontfaceobserver';
import log from 'loglevel';
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import PageContentLoader from '../loader/PageContentLoader';

type Props = {
  fontFamilyName: string;
  fontWeight?: number;
  fontStyle?: string;
  children: JSX.Element[] | JSX.Element | ReactNode | string;
  loader?: JSX.Element;
};

const FontObserver: FunctionComponent<Props> = ({ fontFamilyName, fontWeight, fontStyle, children, loader }: Props) => {
  const [isFontLoading, setIsFontLoading] = useState(true);
  useEffect(() => {
    let isMounted = true;

    const params = {
      ...(fontWeight && { weight: fontWeight }),
      ...(fontStyle && { style: fontStyle }),
    };

    const weight = fontWeight || 'normal';
    const style = fontStyle || 'normal';

    const fontFaceObserver = new FontFaceObserver(fontFamilyName, params);
    fontFaceObserver
      .load(null, 10000)
      .then(
        () => {
          if (isMounted) {
            setIsFontLoading(false);
          }
        },
        () => {
          log.error(`Font '${fontFamilyName}' not available (weight: ${weight}, style: ${style}).`);
          if (isMounted) {
            setIsFontLoading(false);
          }
        }
      )
      .catch(() => {
        log.error(`Error while fetching font '${fontFamilyName}' (weight: ${weight}, style: ${style}).`);
        if (isMounted) {
          setIsFontLoading(false);
        }
      });

    return (): void => {
      isMounted = false;
    };
  }, [fontFamilyName, fontStyle, fontWeight]);

  if (isFontLoading) {
    return loader || <PageContentLoader />;
  }

  return <>{children}</>;
};

export default FontObserver;
