import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Switch, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCamera, FaVideo } from 'react-icons/fa';
import { MdCamera, MdFlip } from 'react-icons/md';
import { RiTimerFill } from 'react-icons/ri';
import { createUseStyles } from 'react-jss';
import { THEME } from '../../Constants';
import { MediaType } from '../../utils/types/MediaTypes';

type Props = {
  isProcessing: boolean;
  isUploading: boolean;
  isCapturing: boolean;
  isCountdownTimerRunning: boolean;
  isCountdownActive: boolean;
  isMirroringActive: boolean;
  previewImageOrVideoSource?: string;
  mediaType?: MediaType;
  isMediaTypeForced: boolean;
  onMediaTypeChange: () => void;
  onCountdownActiveChange: (checked: boolean) => void;
  onIsMirroringActiveChange: (checked: boolean) => void;
};

const useStyles = createUseStyles({
  mediaTypeSwitchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cameraSettingSwitch: {
    minWidth: 44,
    '& .ant-switch-inner': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  photoMediaSwitch: {
    backgroundColor: THEME.DEFAULT.MAIN_COLOR_GREEN,
  },
  cameraSettingIconContainer: {
    fontSize: 18,
    marginRight: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  countdownSwitchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mirrorSwitchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const WebcamRecorderModalSettings: FunctionComponent<Props> = ({
  isProcessing,
  isUploading,
  isCapturing,
  isCountdownTimerRunning,
  isCountdownActive,
  isMirroringActive,
  previewImageOrVideoSource,
  mediaType,
  isMediaTypeForced,
  onMediaTypeChange,
  onCountdownActiveChange,
  onIsMirroringActiveChange,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // Render a switch enabling to switch the mediaType to either capture a picture or a video
  const renderMediaTypeSwitch = () => {
    // Disabled if the media is being processed or uploaded, or if a capture is in progress, or if a specific mediaType is required,
    // or if the countdown timer is running, or if a preview is displayed (to prevent resetting the rush without confirming)
    const isDisabled =
      isProcessing ||
      isUploading ||
      isCapturing ||
      isCountdownTimerRunning ||
      isMediaTypeForced ||
      previewImageOrVideoSource !== undefined;

    return (
      <div className={classes.mediaTypeSwitchContainer}>
        <Tooltip title={t('charters.mediaLibrary.webcamRecorder.mediaTypeTooltip')}>
          <div className={classes.cameraSettingIconContainer}>
            <MdCamera />
          </div>
        </Tooltip>
        <Switch
          checked={mediaType === MediaType.VIDEO}
          className={classNames(classes.cameraSettingSwitch, mediaType === MediaType.IMAGE && classes.photoMediaSwitch)}
          checkedChildren={<FaVideo />}
          unCheckedChildren={<FaCamera />}
          size="default"
          disabled={isDisabled}
          onChange={onMediaTypeChange}
        />
      </div>
    );
  };

  const renderCountdownSwitch = () => {
    // Disabled if the media is being processed or uploaded, or if a capture is in progress, or if the countdown timer is running,
    // or if a preview is displayed (to prevent resetting the rush without confirming)
    const isDisabled =
      isProcessing || isUploading || isCapturing || isCountdownTimerRunning || previewImageOrVideoSource !== undefined;

    return (
      <div className={classes.countdownSwitchContainer}>
        <Tooltip title={t('charters.mediaLibrary.webcamRecorder.countdownTimerTooltip')}>
          <div className={classes.cameraSettingIconContainer}>
            <RiTimerFill />
          </div>
        </Tooltip>
        <Switch
          checked={isCountdownActive}
          className={classes.cameraSettingSwitch}
          checkedChildren={`3${t('timeLabel.secondsShort')}`}
          unCheckedChildren={`0${t('timeLabel.secondsShort')}`}
          size="default"
          disabled={isDisabled}
          onChange={onCountdownActiveChange}
        />
      </div>
    );
  };

  const renderMirrorSwitch = () => {
    // Disabled if the media is being processed or uploaded, or if a capture is in progress, or if the countdown timer is running,
    // or if a preview is displayed (to prevent resetting the rush without confirming)
    const isDisabled =
      isProcessing || isUploading || isCapturing || isCountdownTimerRunning || previewImageOrVideoSource !== undefined;

    return (
      <div className={classes.mirrorSwitchContainer}>
        <Tooltip title={t('charters.mediaLibrary.webcamRecorder.mirroringTooltip')}>
          <div className={classes.cameraSettingIconContainer}>
            <MdFlip />
          </div>
        </Tooltip>
        <Switch
          checked={isMirroringActive}
          className={classes.cameraSettingSwitch}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          size="default"
          disabled={isDisabled}
          onChange={onIsMirroringActiveChange}
        />
      </div>
    );
  };

  return (
    <>
      {renderMediaTypeSwitch()}
      {renderCountdownSwitch()}
      {renderMirrorSwitch()}
    </>
  );
};

export default WebcamRecorderModalSettings;
