import { Dispatch, Middleware } from 'redux';
import { Roles } from '../../core/rule/RolesTypes';
import CompaniesUtils from '../../utils/CompaniesUtils';
import { CHARTERS_ACTION_TYPE, selectCharter } from '../action/ChartersAction';
import { setCurrentUserRole } from '../action/UserAction';
import { RootState } from '../RootState';
import { ChartersMiddlewareService } from '../service/ChartersMiddlewareService';

export const ChartersMiddleware: Middleware = (api) => (next) => (action): Dispatch => {
  if (action.type === CHARTERS_ACTION_TYPE.SELECT_CHARTER_BY_ID) {
    // this may be moved to another file
    const state: RootState = api.getState();
    const { charterId, permissions } = action.payload;
    const charter = ChartersMiddlewareService.getCharterFromStateById(state, charterId);
    if (charter) {
      api.dispatch(selectCharter(charter, permissions));
    }
  }

  if (action.type === CHARTERS_ACTION_TYPE.SELECT_CHARTER) {
    const state: RootState = api.getState();
    const { charter } = action.payload;
    const charterFromList = ChartersMiddlewareService.getCharterFromStateById(state, charter.id);
    const role = ChartersMiddlewareService.getCurrentUserRoleByCharter(state, charterFromList);
    const isUserKannelle = CompaniesUtils.checkIsKnlProfile(state?.companies?.list);
    const userRole = role !== Roles.KnlTeam && isUserKannelle ? Roles.KnlTeam : role;

    next(setCurrentUserRole(userRole));
  }

  return next(action);
};
