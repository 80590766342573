declare global {
  interface Window {
    satismeter: any;
  }
}

const hideSatismeter = (): void => {
  window.satismeter = undefined;
  const satismeterWidget = document.getElementsByClassName('sm-survey')[0];
  if (satismeterWidget !== undefined) {
    const satismeterParent = satismeterWidget.parentNode;
    while (satismeterParent && satismeterParent.firstChild) {
      satismeterParent.firstChild.remove();
    }
  }
};

export default hideSatismeter;
