import { Col, Divider, Empty, Row, Tabs, Tooltip } from 'antd';
import { ColProps } from 'antd/lib/col';
import Title from 'antd/lib/typography/Title';
import { UploadFile } from 'antd/lib/upload/interface';
import classNames from 'classnames';
import find from 'lodash/find';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/RootState';
import { MediaFile } from '../../../services/api/types/ChartersServiceTypes';
import { MediaType } from '../../../utils/types/MediaTypes';
import ImageFileCard from '../../card/ImageFileCard';
import ProcessingFileCard from '../../card/ProcessingFileCard';
import VideoOrMusicFileCardPlayer from '../../card/VideoOrMusicFileCardPlayer';
import EmptyFilesContent from '../../empty/EmptyFilesContent';
import PageContentLoader from '../../loader/PageContentLoader';
import { FileUploaderProps } from '../../modal/FileUploader';
import ScreenRecorder from '../../screen-recorder/ScreenRecorder';
import WebcamRecorder, { WebcamRecorderOptions } from '../../webcam-recorder/WebcamRecorder';

const { TabPane } = Tabs;

type Props = {
  isLoading: boolean;
  mediaList?: MediaFile[];
  activeTab: MediaType;
  uniqueActiveTab?: MediaType;
  handleActiveTabChange: (tabKey: MediaType) => void;
  onRetryUploadMediaFile: (uploadFile: UploadFile, mediaType: MediaType) => void;
  extra?: JSX.Element;
  selectedMedia?: MediaFile | MediaFile[];
  disabledMedia?: MediaFile | MediaFile[];
  onSelectMedia?: (media: MediaFile) => void;
  onArchiveMedia?: (media: MediaFile) => Promise<MediaFile>;
  className?: string;
  multiple?: boolean;
  disabledTabMessages?: { mediaType: MediaType; message: string }[];
  enableWebcamRecording?: boolean;
  webcamRecordingOptions?: WebcamRecorderOptions;
  format?: string;
} & FileUploaderProps;

const useStyles = createUseStyles({
  title: {
    '&.ant-typography': {
      color: '#3B3B3B',
      fontWeight: 400,
      marginTop: 10,
      borderBottom: 'solid 1px #dddddd',
    },
  },
  tabs: {
    '& .ant-tabs-extra-content': {
      display: 'inline-flex',
    },
  },
  empty: {
    width: '100%',
  },
  mediaRow: {
    margin: '0 !important',
    marginBottom: '20px !important',
  },
});

const MediaLibraryTabs: FunctionComponent<Props> = ({
  isLoading,
  mediaList,
  activeTab,
  uniqueActiveTab,
  handleActiveTabChange,
  onRetryUploadMediaFile,
  triggerText,
  modalTitle,
  uploadInstructionText,
  uploadInstructionHint,
  uploadInstructionSizeLimit,
  acceptFileType,
  sizeLimit,
  onSizeLimitExceeded,
  onUploadStatusChangeToDone,
  onUploadStatusChangeToError,
  onUploadFile,
  extra,
  selectedMedia,
  onSelectMedia,
  onArchiveMedia,
  disabledMedia,
  disabledTabMessages = [],
  className = undefined,
  multiple = false,
  enableWebcamRecording = false,
  webcamRecordingOptions,
  format,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [mediaPlaying, setMediaPlaying] = React.useState<MediaFile>();
  const processingMedia = useSelector((state: RootState) => {
    return state.charters.processingMedia;
  });

  const uploadProps: FileUploaderProps = {
    triggerText,
    modalTitle,
    uploadInstructionText,
    uploadInstructionHint,
    uploadInstructionSizeLimit,
    acceptFileType,
    sizeLimit,
    onSizeLimitExceeded,
    onUploadStatusChangeToDone,
    onUploadStatusChangeToError,
    onUploadFile,
  };

  const handleMediaPlayingChange = (isPlaying: boolean, media: MediaFile): void => {
    if (isPlaying) {
      setMediaPlaying(media);
    } else {
      setMediaPlaying(undefined);
    }
  };

  const getLayoutColPropsByMediaType = (mediaType: MediaType): Partial<ColProps> => {
    return mediaType === MediaType.IMAGE
      ? { xs: 24, sm: 24, md: 12, lg: 6, xl: 6, xxl: 4 }
      : { xs: 24, sm: 24, md: 12, lg: 8, xl: 6, xxl: 6 };
  };

  const renderProcessingMediaCards = (mediaType: MediaType): JSX.Element | null => {
    const processingMediaOfType = processingMedia[mediaType];
    const layoutColProps = getLayoutColPropsByMediaType(mediaType);

    if (!(processingMediaOfType && processingMediaOfType.length > 0)) {
      return null;
    }
    return (
      <>
        <Title className={classes.title} level={5}>
          {t(`charters.mediaLibrary.processingTitle`)}
        </Title>

        <Row gutter={[16, 16]} className={classes.mediaRow}>
          {processingMediaOfType.map((uploadFile: UploadFile) => {
            return (
              <Col key={uploadFile.uid} {...layoutColProps}>
                <ProcessingFileCard
                  uploadFile={uploadFile}
                  onRetryUploadFile={(): void => onRetryUploadMediaFile(uploadFile, mediaType)}
                  processingMessage={t('charters.mediaLibrary.processing')}
                  processingErrorMessage={t('charters.mediaLibrary.processingError')}
                  trigger={onSelectMedia ? 'button' : undefined}
                />
              </Col>
            );
          })}
        </Row>

        <Divider />
      </>
    );
  };

  const renderMediaCards = (mediaType: MediaType): JSX.Element => {
    const layoutColProps = getLayoutColPropsByMediaType(mediaType);
    const mediaListOfType = mediaList!.filter((media) => media.mediaType === mediaType);

    if (!(mediaListOfType && mediaListOfType.length > 0)) {
      return <Empty className={classes.empty} image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('global.nodata')} />;
    }

    return (
      <Row gutter={[16, 16]} className={classes.mediaRow}>
        {mediaListOfType.map((media) => {
          let isSelected = false;
          if (selectedMedia) {
            if (Array.isArray(selectedMedia)) {
              isSelected = find(selectedMedia, { id: media.id }) !== undefined;
            } else {
              isSelected = media.id === selectedMedia.id;
            }
          }

          if (disabledMedia && !isSelected) {
            if (Array.isArray(disabledMedia)) {
              isSelected = find(disabledMedia, { id: media.id }) !== undefined;
            } else {
              isSelected = media.id === disabledMedia.id;
            }
          }

          return (
            <Col key={media.id} {...layoutColProps}>
              {mediaType === MediaType.IMAGE ? (
                <ImageFileCard
                  imageFile={media}
                  selected={isSelected}
                  disabled={multiple ? false : isSelected}
                  trigger={onSelectMedia ? 'button' : undefined}
                  callbackOnSelect={onSelectMedia ? (): void => onSelectMedia!(media) : undefined}
                  handleArchive={onArchiveMedia ? () => onArchiveMedia(media) : undefined}
                />
              ) : (
                <VideoOrMusicFileCardPlayer
                  videoOrMusicFile={media}
                  videoOrMusicPlaying={mediaPlaying}
                  callbackPlaying={(isPlaying: boolean): void => handleMediaPlayingChange(isPlaying, media)}
                  selected={isSelected}
                  disabled={multiple ? false : isSelected}
                  trigger={onSelectMedia ? 'button' : undefined}
                  callbackOnSelect={onSelectMedia ? (): void => onSelectMedia!(media) : undefined}
                  handleArchive={onArchiveMedia ? () => onArchiveMedia(media) : undefined}
                />
              )}
            </Col>
          );
        })}
      </Row>
    );
  };

  const renderTabPaneContent = (mediaType: MediaType): JSX.Element => {
    if (isLoading) {
      return <PageContentLoader />;
    }

    // If no media of the mediaType and none processing for that same mediaType, display the empty page with the uploader
    if (
      !(mediaList && mediaList.filter((media) => media.mediaType === mediaType).length > 0) &&
      !(processingMedia && processingMedia[mediaType].length > 0)
    ) {
      return (
        <EmptyFilesContent
          emptyMessage={t(
            mediaType === MediaType.IMAGE
              ? 'charters.mediaLibrary.images.noImage'
              : 'charters.mediaLibrary.videos.noVideo'
          )}
          {...uploadProps}
        />
      );
    }

    return (
      <>
        {renderProcessingMediaCards(mediaType)}
        {renderMediaCards(mediaType)}
      </>
    );
  };

  const renderExtraItems = () => {
    if (!extra && !enableWebcamRecording) {
      return null;
    }

    return (
      <>
        {enableWebcamRecording && (
          <WebcamRecorder
            format={format}
            mediaType={uniqueActiveTab}
            options={webcamRecordingOptions}
            onRecordingProcessed={onSelectMedia}
          />
        )}
        <>
          <ScreenRecorder onRecordingProcessed={onSelectMedia} />
        </>
        {extra}
      </>
    );
  };

  const renderTab = (mediaType: MediaType, props: { tab: string; key: string; disabled: boolean }) => {
    const disabledMessage = find(disabledTabMessages, { mediaType });

    if (!disabledMessage) {
      return <TabPane {...props}>{renderTabPaneContent(mediaType)}</TabPane>;
    }

    return (
      <TabPane
        {...props}
        disabled
        tab={
          <Tooltip title={disabledMessage.message} placement="right">
            {props.tab}
          </Tooltip>
        }
      >
        {renderTabPaneContent(mediaType)}
      </TabPane>
    );
  };

  return (
    <Tabs
      defaultActiveKey="image"
      activeKey={activeTab}
      onChange={(tabKey: string): void => handleActiveTabChange(tabKey as MediaType)}
      tabBarExtraContent={{ right: renderExtraItems() }}
      className={classNames(classes.tabs, className)}
    >
      {renderTab(MediaType.IMAGE, {
        tab: t('charters.mediaLibrary.images.title'),
        key: 'image',
        disabled: uniqueActiveTab === MediaType.VIDEO,
      })}
      {renderTab(MediaType.VIDEO, {
        tab: t('charters.mediaLibrary.videos.title'),
        key: 'video',
        disabled: uniqueActiveTab === MediaType.IMAGE,
      })}
    </Tabs>
  );
};

export default MediaLibraryTabs;
