import classNames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { THEME } from '../../Constants';

const useStyles = createUseStyles({
  preformatted: {
    ...THEME.DEFAULT.PREFORMATTED_STYLE,
  },
});

type Props = {
  children: JSX.Element[] | JSX.Element | ReactNode | string;
  className?: string;
};

const Preformatted: FunctionComponent<Props> = ({ className, children }: Props) => {
  const classes = useStyles();

  return <span className={classNames(classes.preformatted, className)}>{children}</span>;
};

export default Preformatted;
