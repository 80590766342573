import { Tag } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

type Props = {
  className?: string;
  color?: string;
  key?: string;
  onClick?: () => void;
  children: JSX.Element | JSX.Element[] | ReactNode | string;
};

const useStyles = createUseStyles({
  tag: {
    borderRadius: '25px',
    cursor: 'pointer',
  },
});

const TagCharter: FunctionComponent<Props> = ({ className, children, ...rest }: Props) => {
  const classes = useStyles();

  return (
    <Tag {...rest} className={classNames(classes.tag, className)}>
      {children}
    </Tag>
  );
};

export default TagCharter;
