import { UserAddOutlined } from '@ant-design/icons/lib';
import { Button, Tooltip } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

const DisabledAddUserModal: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t('users.addUserDisabled')}>
        <Button key="addUserButton" type="primary" disabled>
          <UserAddOutlined /> {t('users.addButton')}
        </Button>
      </Tooltip>
    </>
  );
};

export default DisabledAddUserModal;
