import { createUseStyles } from 'react-jss';
import { THEME } from '../../Constants';

const useStyles = createUseStyles({
  menuContainer: {
    height: '100%',
  },
  treeMenuItemSelect: {
    backgroundColor: `${THEME.MENU.MAIN_BACKGROUND_COLOR}`,
    borderRight: `5px solid ${THEME.MENU.MAIN_COLOR}`,
    color: THEME.MENU.MAIN_TEXT_COLOR,
    minWidth: 180,

    '& .ant-tree-node-content-wrapper.ant-tree-node-selected': {
      background: `${THEME.MENU.MAIN_BACKGROUND_COLOR} !important`,
    },

    '& .ant-tree-node-content-wrapper, & .ant-tree-title, & .ant-tree-title a': {
      color: `${THEME.MENU.MAIN_TEXT_COLOR} !important`,
    },
  },
  mainTreeItem: {
    height: 40,
    lineHeight: '40px',
  },
  secondaryTreeItem: {
    fontSize: `${THEME.MENU.FONT_SIZE_TEXT}px !important`,
  },
  treeMenuItem: {
    display: 'flex',
    alignItems: 'center !important',
    fontSize: THEME.MENU.FONT_SIZE_TEXT,
    marginRight: '5px',
  },
  disabledTreeMenuItem: {
    display: 'flex',
    alignItems: 'center !important',
    fontSize: THEME.MENU.FONT_SIZE_TEXT,
    '& .ant-tree-title': {
      color: '#888888 !important',
    },
    '&.ant-tree-treenode': {
      '&:hover': {
        '& .ant-tree-node-content-wrapper, & .ant-tree-node-content-wrapper.ant-tree-node-selected, & .ant-tree-title span, & .ant-tree-title a': {
          color: `#888888 !important`,
          backgroundColor: 'inherit',
        },
        backgroundColor: '#ffffff !important',
      },
    },
  },
  treeMenuChildNode: {
    minWidth: 180,
    alignItems: 'center !important',
  },
  treeMenuItemIcon: {
    marginRight: 8,
  },
  kannelleTree: {
    width: '100%',
    maxHeight: '90%',
    borderBottom: '#eeeeee solid 1px',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '0 4px',
    '& > .ant-tree-treenode': {
      display: 'none',
    },
    '& .ant-tree-treenode': {
      width: '100%',
      '& .ant-tree-node-content-wrapper, & .ant-tree-title, & .ant-tree-title a': {
        width: '100%',
        display: 'flex',
        color: THEME.MENU.DEFAULT_TEXT_COLOR,
        '&.ant-tree-node-selected': {
          backgroundColor: '#fff',
        },
      },

      '&:hover': {
        color: `${THEME.MENU.MAIN_TEXT_COLOR} !important`,
        backgroundColor: THEME.MENU.MAIN_BACKGROUND_COLOR,

        '& .ant-tree-node-content-wrapper, & .ant-tree-node-content-wrapper.ant-tree-node-selected, & .ant-tree-title, & .ant-tree-title a': {
          color: `${THEME.MENU.MAIN_TEXT_COLOR} !important`,
          backgroundColor: 'inherit',
        },
      },

      '& .ant-tree-node-content-wrapper:hover, &:hover .anticon': {
        backgroundColor: 'inherit',
        color: 'inherit',
      },

      transition: 'all 300ms ease-in-out',
    },

    '& .ant-tree-switcher': {
      display: 'flex',
      height: 40,
      justifyContent: 'center',
      alignItems: 'center',
      width: 12,
    },
  },
  charterCreationMenuItem: {
    '& .ant-tree-switcher': {
      width: 0,
    },
    '& .ant-tree-node-content-wrapper': {
      paddingLeft: 0,
      marginLeft: '-1px',
    },
  },
  routeIcon: {
    marginRight: '4px',
  },
  logoContainer: {
    textAlign: 'center',
    height: 64,
    lineHeight: '64px',
    marginBottom: 16,
  },
  logo: {
    width: '90%',
    maxWidth: 250,
    minWidth: 170,
    maxHeight: 64,
  },
  version: {
    position: 'absolute',
    bottom: '15px',
    padding: '8px 0 0 16px',
    width: '100%',
    fontSize: THEME.MENU.VERSION_FONT_SIZE,
    color: THEME.MENU.VERSION_TEXT_COLOR,
  },
  externalLink: {
    '& .anticon:last-child': {
      visibility: 'hidden',
    },
    '&:hover .anticon:last-child': {
      visibility: 'visible',
    },
  },
  externalLinkIcon: {
    marginLeft: '5px',
    fontSize: '10px',
  },
});

export default useStyles;
