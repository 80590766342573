import { Button, Col, Modal, Row } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FaAndroid, FaApple } from 'react-icons/all';
import { createUseStyles } from 'react-jss';
import iOSQRCode from '../../../../../resources/img/qrcode_apps_ios.png';
import androidQRCode from '../../../../../resources/img/qrcode_googlestore_kannelle.png';

type Props = {
  isVisible: boolean;
  onHide: () => void;
};

const useStyles = createUseStyles({
  downloadApps: {
    marginTop: 16,
  },
  appButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '70%',
    margin: 'auto',
  },
  appsRow: {
    marginTop: 40,
  },
  appColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  qrCode: {
    marginTop: 20,
    width: '70%',
  },
  brandLogo: {
    marginRight: 6,
  },
});

const WebEditingDesktopOnlyModal: FunctionComponent<Props> = ({ isVisible, onHide }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Modal
      title={t('charters.projects.desktopOnlyModal.title')}
      visible={isVisible}
      footer={[
        <Button key="okButton" type="primary" onClick={onHide}>
          {t('global.ok')}
        </Button>,
      ]}
      onCancel={onHide}
    >
      <div>{t('charters.projects.desktopOnlyModal.description')}</div>
      <div className={classes.downloadApps}>{t('charters.projects.desktopOnlyModal.downloadMobileApps')}</div>

      <Row gutter={[8, 8]} className={classes.appsRow}>
        <Col span={12}>
          <div className={classes.appColumn}>
            <Button
              className={classes.appButton}
              type="primary"
              target="_blank"
              rel="noreferrer"
              href="https://play.google.com/store/apps/details?id=io.kannelle.app&hl=fr&gl=US"
            >
              <FaAndroid className={classes.brandLogo} /> {t('charters.projects.desktopOnlyModal.android')}
            </Button>
            <img src={androidQRCode} alt="Android QR code" className={classes.qrCode} />
          </div>
        </Col>
        <Col span={12}>
          <div className={classes.appColumn}>
            <Button
              className={classes.appButton}
              type="primary"
              target="_blank"
              rel="noreferrer"
              href="https://apps.apple.com/fr/app/kannelle/id1370345661"
            >
              <FaApple className={classes.brandLogo} /> {t('charters.projects.desktopOnlyModal.ios')}
            </Button>
            <img src={iOSQRCode} alt="iOS QR code" className={classes.qrCode} />
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default WebEditingDesktopOnlyModal;
