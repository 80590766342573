import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import AccessChecker from '../../../../../../../../components/access-checker/AccessChecker';
import AnimationSelectorModal from '../../../../../../../../components/animation-selector/AnimationSelectorModal';
import { THEME } from '../../../../../../../../Constants';
import useCanAccess from '../../../../../../../../hooks/useCanAccess';
import AnimationsUtils from '../../../../../../../../utils/AnimationsUtils';
import ProjectUtils from '../../../../../../../../utils/ProjectUtils';
import {
  AnimationConfig,
  AnimationFormats,
  AnimationOption,
  RecordableAnimations,
  SlideAnimations,
} from '../../../../../../../../utils/types/AnimationTypes';
import { PermissionList } from '../../../../../../../../utils/types/CharterPermissionTypes';
import {
  ProjectScene,
  ProjectSceneElement,
  ProjectSceneElements,
  ProjectSceneElementsCode,
} from '../../../../../../../../utils/types/ProjectTypes';
import { AnimationTheme } from '../../../../../../../../utils/types/ThemeTypes';
import { VideoTrim, VideoTrimUtils } from '../../utils/VideoTrimUtils';
import SceneTimelineGrayOverlay from '../SceneTimelineGrayOverlay';

type Props = {
  isSlide: boolean;
  format?: AnimationFormats;
  theme: AnimationTheme;
  customColors: boolean;
  scene: ProjectScene;
  intervalTickWidth: number;
  onSubmitScene: (updatedScene: ProjectScene) => void;
  onSelectElement: (e: ProjectSceneElement) => void;
  callbackIfUnsavedChangesConfirmed: (
    callback: (saveConfirmed?: boolean) => void,
    confirmTitle?: string,
    confirmDescription?: string
  ) => void;
  resetTrimAndDelayValues: () => void;
  videoTrimValue: VideoTrim;
  pipTrimValue: VideoTrim;
};

type StyleProps = {
  intervalTickWidth: number;
  scene: ProjectScene;
  trimStart: number;
};

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    height: '100%',
    width: '100%',
    '& .react-draggable-dragging': {
      cursor: 'move !important',
    },
  },
  unauthorized: {
    borderRadius: '50%',
  },
  addAnimationButton: ({ intervalTickWidth, trimStart }: StyleProps) => {
    const leftPosition = VideoTrimUtils.calculateStartAnchorPositionOnX(trimStart || 0, intervalTickWidth);
    const common = {
      zIndex: 2,
      background: THEME.PROJECTS.EDITOR.TIMELINE.BUTTON_BACKGROUND,
      position: 'absolute',
      top: 4,
      '&:hover, &:focus': {
        background: THEME.PROJECTS.EDITOR.TIMELINE.BUTTON_BACKGROUND_HOVER,
        color: 'black',
        borderColor: THEME.PROJECTS.EDITOR.TIMELINE.BUTTON_BACKGROUND_ACTIVE,
      },
      '&:active': {
        background: THEME.PROJECTS.EDITOR.TIMELINE.BUTTON_BACKGROUND_ACTIVE,
        color: 'black',
        borderColor: THEME.PROJECTS.EDITOR.TIMELINE.BUTTON_BORDER_ACTIVE,
      },
    };

    return { ...common, left: leftPosition };
  },
});

const AddAnimationToSceneButton: FunctionComponent<Props> = ({
  isSlide,
  format,
  theme,
  customColors,
  scene,
  onSubmitScene,
  onSelectElement,
  intervalTickWidth,
  callbackIfUnsavedChangesConfirmed,
  resetTrimAndDelayValues,
  videoTrimValue,
  pipTrimValue,
}: Props) => {
  const trimStart = VideoTrimUtils.getMinTrimStartForPosition(videoTrimValue, pipTrimValue);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [animations, setAnimations] = useState<AnimationOption[]>();

  const permissionToEditSceneAnimation = useCanAccess(PermissionList.SCENE_ANIMATION_TYPE_EDIT);

  const classes = useStyles({
    intervalTickWidth,
    scene,
    trimStart,
  });
  const { t } = useTranslation();

  useEffect(() => {
    const animationsForKind = isSlide ? SlideAnimations : RecordableAnimations;
    setAnimations(animationsForKind);
  }, [isSlide]);

  const onOpenModal = (): void => {
    setIsModalVisible(true);
  };

  const onCloseModal = (): void => {
    setIsModalVisible(false);
  };

  const onSelectAnimation = (selectedAnimationConfig: AnimationConfig): void => {
    if (!(selectedAnimationConfig.position && selectedAnimationConfig.position.code)) {
      return;
    }

    const animationOption = AnimationsUtils.getAnimationOptionByCode(selectedAnimationConfig.name);
    const defaultTexts: string[] = animationOption
      ? AnimationsUtils.getDefaultAnimationTextsByAnimation(animationOption)
      : [];
    const animationTexts = ProjectUtils.buildAnimationTextsArray(defaultTexts);

    const updatedScene = {
      ...scene,
      animation: {
        code: selectedAnimationConfig.name,
        animationTexts,
        position: selectedAnimationConfig.position.code,
        offsetX: selectedAnimationConfig.position?.x,
        offsetY: selectedAnimationConfig.position?.y,
        delay: undefined,
        active: true,
        // Force the initial animation duration to undefined (it will be computed directly thanks to the Lottie file in the ProjectScenePreview component)
        duration: undefined,
      },
    };

    onSubmitScene(updatedScene);

    onSelectElement(ProjectSceneElements.ANIMATION);
  };

  const onAddAnimationButtonHandle = () => {
    if (!permissionToEditSceneAnimation.hasUserAccess()) {
      return undefined;
    }
    return callbackIfUnsavedChangesConfirmed((saveConfirmed) => {
      if (saveConfirmed === false) {
        resetTrimAndDelayValues();
      }
      onOpenModal();
    });
  };

  return (
    <div className={classes.container}>
      <SceneTimelineGrayOverlay
        element={ProjectSceneElementsCode.ANIMATION}
        backgroundVideo={scene?.backgroundVideo}
        pip={scene?.pip}
        animation={scene?.animation}
        videoTrimValue={videoTrimValue}
        intervalTickWidth={intervalTickWidth}
      >
        <AccessChecker
          hasAccess={permissionToEditSceneAnimation.hasUserAccess()}
          renderUnauthorizedMessage={permissionToEditSceneAnimation.renderUnauthorizedMessage}
        >
          <Button
            shape="round"
            icon={<PlusOutlined />}
            className={classNames(
              classes.addAnimationButton,
              !permissionToEditSceneAnimation.hasUserAccess() && classes.unauthorized
            )}
            disabled={!permissionToEditSceneAnimation.hasUserAccess()}
            onClick={onAddAnimationButtonHandle}
          >
            {t('charters.projects.projectEditor.sceneEditor.fields.animation.addAnAnimation')}
          </Button>
        </AccessChecker>
      </SceneTimelineGrayOverlay>

      <AnimationSelectorModal
        onSelectAnimation={onSelectAnimation}
        isVisible={isModalVisible}
        fieldLabel={t('charters.projects.projectEditor.sceneEditor.fields.animation.addAnAnimation')}
        format={format ?? AnimationFormats.FORMAT_16_9}
        theme={theme}
        customColors={customColors}
        onCancel={onCloseModal}
        animations={animations}
      />
    </div>
  );
};

export default AddAnimationToSceneButton;
