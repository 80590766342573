import { DeleteOutlined } from '@ant-design/icons';
import { CodeOutlined, VideoCameraAddOutlined } from '@ant-design/icons/lib';
import { Button, Col, Form, message, Modal, Popconfirm, Row, Tooltip, Typography } from 'antd';
import { BreadcrumbProps } from 'antd/es/breadcrumb';
import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import { find, isEqual, omit } from 'lodash';
import log from 'loglevel';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { FaPlayCircle } from 'react-icons/all';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { Link, Prompt, useHistory, useLocation } from 'react-router-dom';
import KannelleLoader from '../../../../components/loader/KannelleLoader';
import KannelleHelpButton from '../../../../components/page-header/KannelleHelpButton';
import KannellePageHeader from '../../../../components/page-header/KannellePageHeader';
import { DEVICE_SIZES_QUERIES, LINK, LOGGING_EVENT, THEME, WEB_SOCKET_ACTIONS } from '../../../../Constants';
import useSocket from '../../../../hooks/useSocket';
import JoyRideHelpCharterScenarioVariantEditor from '../../../../onboarding/JoyRideHelpCharterScenarioVariantEditor';
import { RootState } from '../../../../redux/RootState';
import { APIManager } from '../../../../services/api/APIManager';
import {
  deleteCharterScenarioVariant,
  getCharterScenarioDetailsById,
  updateCharterScenarioVariant,
} from '../../../../services/api/ChartersService';
import { SocketManager } from '../../../../services/api/SocketManager';
import {
  APIUpdateCharterScenarioVariantParams,
  ExtendedScenarioVariant,
  Scenario,
  ScenarioVariant,
} from '../../../../services/api/types/ChartersServiceTypes';
import {
  DeletedScenarioPayload,
  DeletedScenarioVariantPayload,
  ScenarioUpdateResponse,
} from '../../../../services/api/types/WebSocketTypes';
import CompaniesUtils from '../../../../utils/CompaniesUtils';
import ScenarioUtils from '../../../../utils/ScenarioUtils';
import { AnimationFormats } from '../../../../utils/types/AnimationTypes';
import { ScenarioSceneTemplate, ScenarioStatus, TemplateSequenceKind } from '../../../../utils/types/ScenarioTypes';
import AddScenarioScene from './AddScenarioScene';
import ScenarioFinalPlayerModal from './ScenarioFinalPlayerModal';
import ScenarioPDFDownloadButton from './ScenarioPDFDownloadButton';
import ScenarioSceneCard from './ScenarioSceneCard';
import ScenarioSceneForm from './ScenarioSceneForm';
import ScenarioVariantMetadataForm from './ScenarioVariantMetadataForm';

const { Title } = Typography;
const { confirm } = Modal;

type StyleProps = {
  isMobileOrTablet: boolean;
};

const useStyles = createUseStyles({
  pageContent: {
    backgroundColor: '#FFFFFF',
    height: 'calc(100% - 140px)',
    margin: 24,
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      margin: 0,
    },
    [`@media screen and ${DEVICE_SIZES_QUERIES.EXTRA_LARGE}`]: {
      '& .ant-page-header-heading': {
        flexWrap: 'wrap',
      },
    },

    '& .ant-page-header-content': {
      maxHeight: 'calc(100% - 60px)',
    },

    '& .ant-page-header-heading-title': {
      fontSize: 26,
    },
  },
  titleWithIcon: {
    fontSize: '26px !important',
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    backgroundColor: THEME.DEFAULT.MAIN_COLOR,
    minWidth: 32,
  },
  scenesMainContainer: {
    overflowX: 'auto !important',
  },
  scenesWrapperContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto!important',
    overflowY: 'hidden',
    paddingBottom: '20px !important',
    marginBottom: '20px !important',

    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      borderRight: '0',
    },

    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      height: '9px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '6px',
      backgroundColor: 'rgba(0, 0, 0, .5)',
      boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
    },
  },
  scenesWrapper: ({ isMobileOrTablet }: StyleProps) => ({
    display: isMobileOrTablet ? 'unset' : 'inline-flex',
    flexGrow: isMobileOrTablet ? 'unset' : 1,
  }),
  scenesContainer: ({ isMobileOrTablet }: StyleProps) => ({
    display: isMobileOrTablet ? 'unset' : 'flex',
  }),
  confirmModal: {
    '& .ant-modal-confirm-body-wrapper': {
      position: 'relative',
    },
  },
  confirmCancelButton: {
    display: 'none',
  },
  confirmContinueWithoutSavingButton: {
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
  deleteVariantConfirm: {
    maxWidth: 350,
  },
  watchButton: {
    display: 'flex',
    alignItems: 'center',
  },
  watchIcon: {
    marginRight: 4,
  },
});

const ScenarioVariantEditor: FunctionComponent = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const [scenarioId, setScenarioId] = useState<number>();
  const [scenarioVariantId, setScenarioVariantId] = useState<number>();
  const [scenario, setScenario] = useState<Scenario>();
  const [scenarioVariant, setScenarioVariant] = useState<ScenarioVariant>();
  const [isLoadingVariantDetails, setIsLoadingVariantDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [room, setRoom] = useState<string>();
  const [isSelectedSceneEdited, setIsSelectedSceneEdited] = useState(false);
  const [isFinalPlayerModalVisible, setIsFinalPlayerModalVisible] = useState(false);
  const [runHelp, setRunHelp] = useState(false);
  const [selectedScene, setSelectedScene] = useState<ScenarioSceneTemplate>();
  const [sceneManipulatedInForm, setSceneManipulatedInForm] = useState<ScenarioSceneTemplate>();
  const [isUnsavedChangesForHelpExample, setIsUnsavedChangesForHelpExample] = useState(false);
  const [isScenePreviewPlaying, setIsScenePreviewPlaying] = useState(true);

  const currentCharter = useSelector((state: RootState) => state.charters.current);
  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);
  const companies = useSelector((state: RootState) => state.companies.list);
  const company = useSelector((state: RootState) => state.companies.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);

  const isSocketConnected = useSocket(room ?? undefined);

  const previousScenarioVariant = useRef<ScenarioVariant>();
  const scenesCarousel = useRef<HTMLDivElement>(null);
  const firstSceneCard = useRef<HTMLDivElement>(null);
  const callbackToExecute = useRef<() => void>();
  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());

  const classes = useStyles({ isMobileOrTablet });
  const isKnlTeam = companies ? CompaniesUtils.checkIsKnlProfile(companies) : false;
  const currentCharterId = currentCharter?.id;
  const charterScenariosPath = currentCharterId
    ? LINK.CHARTER_SCENARIOS.path.replace(':charterId', currentCharterId.toString()).replace('(\\d+)', '')
    : '';

  const [form] = Form.useForm();

  useEffect(() => {
    const cancelTokenSource = cancelTokenSourceRef.current;

    return (): void => {
      cancelTokenSource.cancel(
        'Cancelled fetching or patching charter scenario variant details due to charter change or component unmount.'
      );
    };
  }, []);

  useEffect(() => {
    if (!currentCharterId) {
      return;
    }

    setRoom(`charters/${currentCharterId}/scenarios`);
  }, [currentCharterId]);

  useEffect(() => {
    if (!currentCharterId) {
      return;
    }

    const locationState = location.state as any;

    // If there are missing data from the location state, we redirect to the scenarios list page
    if (!(locationState && locationState.scenarioId && locationState.scenarioVariantId)) {
      history.push(charterScenariosPath);
      return;
    }

    // Else we get the scenarioId and scenarioVariantId from the location state
    const { scenarioId: id, scenarioVariantId: variantId } = locationState as any;
    setScenarioId(id);
    setScenarioVariantId(variantId);
  }, [history, location, currentCharterId]);

  const onIsScenePreviewPlayingChange = (isPlaying: boolean) => {
    setIsScenePreviewPlaying(isPlaying);
  };

  // Callback to fetch the details of a ScenarioVariant
  const fetchCharterScenarioVariantDetails = useCallback(
    (charterId: number, concernedScenarioId: number, variantId: number): void => {
      setIsLoadingVariantDetails(true);
      const cancelTokenSource = cancelTokenSourceRef.current;
      getCharterScenarioDetailsById(charterId, concernedScenarioId, cancelTokenSource)
        .then((scenarioResponse) => {
          setScenario(scenarioResponse);
          const variant = find(scenarioResponse.variants, (v) => v.id === variantId);
          if (variant !== undefined) {
            // Add missing fields (for front-end) to the variant object received
            const preparedVariant = ScenarioUtils.prepareScenarioVariantForForm(variant);
            setScenarioVariant(preparedVariant);
            // Save the fetched variant as the previousScenarioVariant ref initial value
            previousScenarioVariant.current = preparedVariant;
          }
        })
        .catch((e) => {
          log.debug('Error during charter scenario details fetch', e);
        })
        .finally(() => {
          setIsLoadingVariantDetails(false);
        });
    },
    []
  );

  useEffect(() => {
    if (!(currentCharterId && scenarioId && scenarioVariantId)) {
      return;
    }

    fetchCharterScenarioVariantDetails(currentCharterId, scenarioId, scenarioVariantId);
  }, [currentCharterId, scenarioId, scenarioVariantId, fetchCharterScenarioVariantDetails]);

  // Handle websocket messages
  useEffect(() => {
    const handleWebSocketMessage = (payload: ScenarioUpdateResponse): void => {
      if (WEB_SOCKET_ACTIONS.CHARTER_LOCAL_SCENARIOS.includes(payload.action) && payload.action === 'scenario_delete') {
        // If one scenario has been deleted
        const deletedScenarioPayload = payload.data as DeletedScenarioPayload;
        const { id: deletedScenarioId } = deletedScenarioPayload;
        // If the currently opened scenario variant is in the deleted scenario, we go back to the scenarios list
        if (deletedScenarioId === scenarioId) {
          history.push(charterScenariosPath);
        }
      } else if (WEB_SOCKET_ACTIONS.CHARTER_LOCAL_SCENARIO_VARIANTS_EDITOR.includes(payload.action)) {
        // If one scenario variant has been deleted
        if (payload.action === 'scenario_variant_delete') {
          const deletedVariantPayload = payload.data as DeletedScenarioVariantPayload;
          const { id: deletedVariantId } = deletedVariantPayload;
          // If the currently opened scenario variant is the deleted one, we go back to the scenarios list
          if (deletedVariantId === scenarioVariant?.id) {
            history.push(charterScenariosPath);
          }
          return;
        }

        // If a ScenarioVariant has been updated
        const updatedVariantExtended = payload.data as ExtendedScenarioVariant;
        const updatedVariant = omit(updatedVariantExtended, 'scenarioId') as ScenarioVariant;

        // If the updatedVariant does not correspond to the current one being edited in the form, we return
        if (scenarioVariantId !== updatedVariant.id) {
          return;
        }

        // If just the metadata were updated (not the scenario scenes)
        if (payload.action === 'scenario_variant_metadata_patch') {
          if (
            updatedVariant.title === scenarioVariant?.title &&
            updatedVariant.description === scenarioVariant?.description
          ) {
            return;
          }

          const newVariant = ScenarioUtils.prepareScenarioVariantForForm({
            ...scenarioVariant,
            title: updatedVariant.title,
            description: updatedVariant.description,
            updatedAt: updatedVariant.updatedAt,
          } as ScenarioVariant);
          previousScenarioVariant.current = newVariant;
          setScenarioVariant(newVariant);
          return;
        }

        // Remove the extended data from the websocket payload
        const preparedVariant = ScenarioUtils.prepareScenarioVariantForForm(updatedVariant);
        // Update the ref value: since a websocket is received, we do not need to patch the scenarioVariant (another client did it to trigger the WS)
        previousScenarioVariant.current = preparedVariant;
        // Update the scenarioVariant with the one received in the websocket
        setScenarioVariant(preparedVariant);
        // Update the selectedScene in case the selected one was modified by another client (by id or title in case of a scene creation having not yet an id)
        if (selectedScene && updatedVariant.scenes) {
          const selectedSceneIndex = updatedVariant.scenes.findIndex(
            (scene) => scene.id === selectedScene.id || scene.title === selectedScene.title
          );
          setSelectedScene(
            selectedSceneIndex >= 0 ? updatedVariant.scenes[selectedSceneIndex] : updatedVariant.scenes[0]
          );
        }

        // If there is a stored callback to execute after the scenario variant patch, we execute it once
        if (callbackToExecute && callbackToExecute.current) {
          callbackToExecute.current();
          callbackToExecute.current = undefined;
        }
      }
    };

    if (isSocketConnected) {
      SocketManager.onMessage(handleWebSocketMessage);

      return (): void => SocketManager.offMessage(handleWebSocketMessage);
    }

    return undefined;
  }, [isSocketConnected, scenarioVariantId, selectedScene, scenarioVariant, callbackToExecute, currentCharterId]);

  // If no scene is selected (web version only), we select the first scene of the scenario.
  // On small devices, it is possible to have no selected scene since selecting one scene opens
  // up a modal to edit it.
  useEffect(() => {
    if (
      !isMobileOrTablet &&
      scenarioVariant &&
      scenarioVariant.scenes &&
      scenarioVariant.scenes.length > 0 &&
      !selectedScene
    ) {
      setSelectedScene(scenarioVariant.scenes[0]);
    }
  }, [isMobileOrTablet, scenarioVariant, selectedScene]);

  // Whenever the selected scene changes, we also ensure the sceneManipulatedInForm (for the selected scene card) is updated
  useEffect(() => {
    if (!selectedScene) {
      return;
    }

    setSceneManipulatedInForm(selectedScene);
  }, [selectedScene]);

  // Callback to patch a scenario variant
  const patchScenarioVariant = useCallback((): void => {
    if (
      !(
        currentCharterId &&
        scenarioId &&
        scenario &&
        scenarioVariantId &&
        scenarioVariant &&
        scenarioVariant.scenes &&
        scenarioVariant.scenes.length > 0
      )
    ) {
      return;
    }

    setIsLoading(true);

    const cancelTokenSource = cancelTokenSourceRef.current;

    // Clean the patch payload
    const scenes = scenarioVariant.scenes.map((scene) => ScenarioUtils.removeUselessFieldsFromScene(scene));

    const data: APIUpdateCharterScenarioVariantParams = {
      scenarioId,
      title: scenarioVariant.title,
      scope: scenario.scope,
      description: scenarioVariant.description,
      language: scenarioVariant.language,
      format: scenarioVariant.format,
      status: scenarioVariant.status,
      scenes,
    };

    updateCharterScenarioVariant(currentCharterId, scenarioId, scenarioVariantId, data, cancelTokenSource)
      .then((updatedScenarioVariant) => {
        // Save the scenarioVariant we are patching as the new reference value
        previousScenarioVariant.current = updatedScenarioVariant;

        message.success(t('charters.scenarios.variantEditor.variantUpdateSuccess'));
      })
      .catch((error) => {
        scenarioVariant.status = previousScenarioVariant.current?.status || ScenarioStatus.DRAFT.code;

        setScenarioVariant({ ...scenarioVariant });

        const errorResponse = error.response;
        const errorResponseData = error.response?.data;

        if (errorResponseData && errorResponseData.data && errorResponse.status === 403) {
          message.error(t('charters.scenarios.variantEditor.bundleLimitationError'), 3);
        } else {
          message.error(t('charters.scenarios.variantEditor.variantUpdateError'));
        }

        log.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [t, currentCharterId, scenarioId, scenarioVariantId, scenarioVariant]);

  // Whenever the callback changes (mostly when the scenarioVariant state gets updated), we patch the scenario variant
  useEffect(() => {
    if (!scenarioVariant) {
      return;
    }

    const cleanedCurrentPreviousScenarioVariant = { ...previousScenarioVariant.current } as ScenarioVariant;
    cleanedCurrentPreviousScenarioVariant.scenes =
      cleanedCurrentPreviousScenarioVariant.scenes &&
      (ScenarioUtils.cleanScenesOrScene(cleanedCurrentPreviousScenarioVariant.scenes) as ScenarioSceneTemplate[]);

    const cleanedScenarioVariant = { ...scenarioVariant } as ScenarioVariant;
    cleanedScenarioVariant.scenes =
      cleanedScenarioVariant.scenes &&
      (ScenarioUtils.cleanScenesOrScene(cleanedScenarioVariant.scenes) as ScenarioSceneTemplate[]);
    // If the previousScenarioVariant ref is not already set (first page rendering) or if the scenarioVariant is the same as the one in the
    // ref (deeply), we do not patch it again through the API
    if (!previousScenarioVariant.current || isEqual(cleanedCurrentPreviousScenarioVariant, cleanedScenarioVariant)) {
      return;
    }

    patchScenarioVariant();
  }, [patchScenarioVariant, scenarioVariant]);

  const onUpdateScene = (updatedScene: ScenarioSceneTemplate, sceneDiff?: any): void => {
    const scenarioScenes = scenarioVariant?.scenes;
    if (!scenarioScenes) {
      return;
    }

    const newScenes = [...scenarioScenes];
    const updatedSceneIndex = newScenes.findIndex(
      (scene) => scene.id === updatedScene.id || scene.index === updatedScene.index
    );
    if (updatedSceneIndex >= 0) {
      newScenes.splice(updatedSceneIndex, 1, updatedScene);
      setScenarioVariant(
        (prev) => ({ ...prev, scenes: ScenarioUtils.calculateScenesIndexes(newScenes) } as ScenarioVariant)
      );
      setSelectedScene(updatedScene);
      // Scroll to the scenes carousel
      if (scenesCarousel && scenesCarousel.current) {
        scenesCarousel.current.scrollIntoView({ block: 'center' });
      }

      if (currentCharterId && scenarioVariant && sceneDiff) {
        Object.entries(sceneDiff).forEach(([key, value]) => {
          loggingManager.logEvent(LOGGING_EVENT.UPDATE_SCENARIO_VARIANT_SCENE_FIELD, {
            charterId: currentCharterId,
            companyId: company?.id,
            scenarioId,
            title: scenarioVariant.title,
            language: scenarioVariant.language,
            format: scenarioVariant.format,
            scenarioVariantId,
            updatedFieldName: key,
            [key]: value ?? 'undefined',
          });
        });
      }
    }
  };

  const callbackIfUnsavedChangesConfirmed = (
    callback: () => void,
    confirmTitle?: string,
    confirmDescription?: string
  ): void => {
    if (!(isSelectedSceneEdited && selectedScene)) {
      callback();
    } else {
      const confirmModal = confirm({
        title: confirmTitle || t('charters.scenarios.variantEditor.unsavedChangesConfirmTitle'),
        className: classes.confirmModal,
        width: 600,
        content: (
          <div>
            {confirmDescription ||
              t('charters.scenarios.variantEditor.unsavedChangesConfirmDescription', {
                sceneName: selectedScene.title,
              })}
            {/* Trick: absolutely positioned button in the confirm modal footer since this Modal.confirm() API does not allow custom footer */}
            <Button
              onClick={() => {
                callback(); // Execute the action
                confirmModal.destroy(); // Close the modal
              }}
              type="link"
              className={classes.confirmContinueWithoutSavingButton} // Position the button absolutely at the footer level
            >
              {t('global.continueWithoutSaving')}
            </Button>
          </div>
        ),
        closable: true,
        maskClosable: true,
        cancelButtonProps: { className: classes.confirmCancelButton }, // Hide the 'cancel' button: the only way to cancel is through the close icon
        okText: t('global.saveAndContinue'),
        onOk: () => {
          if (!sceneManipulatedInForm) {
            callback();
            return;
          }

          form
            .validateFields() // Ensure the form fields are valid
            .then(() => {
              // If the form fields are valid: update the scene and
              // program the execution of the callback after the scene being saved
              onUpdateScene(sceneManipulatedInForm);
              callbackToExecute.current = callback;
            })
            .catch(() => {
              // Else, display an error message and do not execute the callback
              message.error(t('charters.scenarios.variantEditor.sceneForm.validationError'));
            });
        },
      });
    }
  };

  const onDragEnd = (result: DropResult): void => {
    const callbackIfReorderConfirmed = (): void => {
      const { source, destination, draggableId } = result;
      const variant = previousScenarioVariant.current ?? scenarioVariant;
      const scenarioScenes = variant?.scenes;

      if (destination?.index === source.index || !scenarioScenes) {
        return;
      }

      const reorderedScenes = [...scenarioScenes];
      const draggedScene = find(reorderedScenes, (scene) => {
        const sceneIdentifier = scene.id ?? scene.index;
        return sceneIdentifier.toString() === draggableId;
      });
      if (draggedScene && destination) {
        reorderedScenes.splice(source.index, 1);
        reorderedScenes.splice(destination.index, 0, draggedScene);
        setScenarioVariant(
          (prev) => ({ ...prev, scenes: ScenarioUtils.calculateScenesIndexes(reorderedScenes) } as ScenarioVariant)
        );

        if (currentCharterId && variant) {
          loggingManager.logEvent(LOGGING_EVENT.REORDER_SCENARIO_VARIANT_SCENES, {
            charterId: currentCharterId,
            companyId: company?.id,
            scenarioId,
            title: variant.title,
            language: variant.language,
            format: variant.format,
            scenarioVariantId,
          });
        }
      }
    };

    // Check if there are unsaved changes and ask for confirmation before reordering
    callbackIfUnsavedChangesConfirmed(
      () => {
        callbackIfReorderConfirmed();
      },
      undefined,
      t('charters.scenarios.variantEditor.unsavedChangesReorderConfirmDescription', {
        sceneName: selectedScene?.title,
      })
    );
  };

  const onAddScene = (): void => {
    const callbackIfAddSceneConfirmed = (): void => {
      const variant = previousScenarioVariant.current ?? scenarioVariant;
      const scenarioScenes = variant?.scenes;
      const length = scenarioScenes?.length ?? 0;

      const newScene = {
        index: length,
        title: `${t('charters.scenarios.variantEditor.newScene')} ${length + 1}`,
        description: `${t('charters.scenarios.variantEditor.newSceneDescription')} ${length + 1}`,
        kind: TemplateSequenceKind.RECORDABLE.code,
        videoRecommendedTime: 5,
        backgroundMusicVolume: 0.5,
        userVideoAudioVolume: 1,
      };
      const newScenes = scenarioScenes ? [...scenarioScenes] : [];
      newScenes.push(newScene);

      setScenarioVariant(
        (prev) => ({ ...prev, scenes: ScenarioUtils.calculateScenesIndexes(newScenes) } as ScenarioVariant)
      );

      if (currentCharterId && variant) {
        loggingManager.logEvent(LOGGING_EVENT.ADD_SCENARIO_VARIANT_SCENE, {
          charterId: currentCharterId,
          companyId: company?.id,
          scenarioId,
          title: variant.title,
          language: variant.language,
          format: variant.format,
          scenarioVariantId,
        });
      }

      setSelectedScene(newScene);
    };

    // Check if there are unsaved changes and ask for confirmation before adding a new scene
    callbackIfUnsavedChangesConfirmed(
      () => {
        callbackIfAddSceneConfirmed();
      },
      undefined,
      t('charters.scenarios.variantEditor.unsavedChangesAddSceneConfirmDescription', {
        sceneName: selectedScene?.title,
      })
    );
  };

  const onDeleteScene = (deletedScene: ScenarioSceneTemplate): void => {
    if (!scenarioVariant?.scenes) {
      return;
    }

    // Check if there are unsaved changes and ask for confirmation before adding a new scene
    callbackIfUnsavedChangesConfirmed(
      () => {
        const variant = previousScenarioVariant.current ?? scenarioVariant;
        const scenarioScenes = variant?.scenes;
        const newScenes = ScenarioUtils.calculateScenesIndexes(
          [...scenarioScenes!].filter((s) => s.id !== deletedScene.id)
        );
        setScenarioVariant((prev) => ({ ...prev, scenes: newScenes } as ScenarioVariant));
        if (selectedScene && selectedScene.id === deletedScene.id) {
          setSelectedScene(newScenes[0]);
        }

        if (currentCharterId && variant) {
          loggingManager.logEvent(LOGGING_EVENT.DELETE_SCENARIO_VARIANT_SCENE, {
            charterId: currentCharterId,
            companyId: company?.id,
            scenarioId,
            title: variant.title,
            language: variant.language,
            format: variant.format,
            scenarioVariantId,
            deletedSceneId: deletedScene.id,
          });
        }
      },
      undefined,
      t('charters.scenarios.variantEditor.unsavedChangesDeleteSceneConfirmDescription', {
        sceneName: selectedScene?.title,
      })
    );
  };

  const onDuplicateScene = (sceneToDuplicate: ScenarioSceneTemplate): void => {
    if (!scenarioVariant?.scenes) {
      return;
    }

    const callbackIfDuplicateConfirmed = (): void => {
      const variant = previousScenarioVariant.current ?? scenarioVariant;
      const scenarioScenes = variant?.scenes;
      const newScenes = [...scenarioScenes!];
      const sceneToDuplicateIndex = newScenes.findIndex((s) => s.id === sceneToDuplicate.id);
      if (!(sceneToDuplicateIndex >= 0)) {
        return;
      }
      // We deeply change the ids when needed
      const sceneCopy = {
        ...sceneToDuplicate,
        id: undefined,
        title: `${sceneToDuplicate.title} (${t('charters.scenarios.variantEditor.sceneCopy')})`,
        ...(sceneToDuplicate.overlayTexts
          ? {
              overlayTexts: sceneToDuplicate.overlayTexts.map((overlayText) => ({
                ...overlayText,
                id: ScenarioUtils.generateId(),
              })),
            }
          : {}),
      };
      newScenes.splice(sceneToDuplicateIndex + 1, 0, sceneCopy);
      setScenarioVariant(
        (prev) => ({ ...prev, scenes: ScenarioUtils.calculateScenesIndexes(newScenes) } as ScenarioVariant)
      );
      // If the current scene has no unsaved changes, we select the new one.
      // Else we stay on the current scene
      if (!isSelectedSceneEdited) {
        setSelectedScene(sceneCopy);
      }

      if (currentCharterId && variant) {
        loggingManager.logEvent(LOGGING_EVENT.DUPLICATE_SCENARIO_VARIANT_SCENE, {
          charterId: currentCharterId,
          companyId: company?.id,
          scenarioId,
          title: variant.title,
          language: variant.language,
          format: variant.format,
          scenarioVariantId,
          duplicatedSceneId: sceneToDuplicate.id,
        });
      }
    };

    // Check if there are unsaved changes and ask for confirmation before adding a new scene
    callbackIfUnsavedChangesConfirmed(
      () => {
        callbackIfDuplicateConfirmed();
      },
      undefined,
      t('charters.scenarios.variantEditor.unsavedChangesDuplicateSceneConfirmDescription', {
        sceneName: selectedScene?.title,
      })
    );
  };

  const onSelectScene = (scene: ScenarioSceneTemplate): void => {
    if (selectedScene && scene.id === selectedScene.id) {
      return;
    }

    callbackIfUnsavedChangesConfirmed(() => {
      setSelectedScene(scene);
    });
  };

  // Whenever a field in the scene form is changed
  const onSceneFormChange = (temporaryScene: ScenarioSceneTemplate): void => {
    setSceneManipulatedInForm(temporaryScene);
  };

  const onUpdateScenarioVariantMetadata = (title: string, description: string, status: string): void => {
    const updatedVariant = {
      ...scenarioVariant,
      title,
      description,
      status,
    };

    if (currentCharterId && scenarioVariant) {
      loggingManager.logEvent(LOGGING_EVENT.UPDATE_SCENARIO_VARIANT_METADATA, {
        charterId: currentCharterId,
        companyId: company?.id,
        scenarioId,
        title: scenarioVariant.title,
        description: scenarioVariant.description,
        status: scenarioVariant.status,
        language: scenarioVariant.language,
        format: scenarioVariant.format,
        scenarioVariantId,
        newTitle: title,
        newDescription: description,
        newStatus: status,
      });
    }

    setScenarioVariant(updatedVariant as ScenarioVariant);
  };

  const onDeleteScenarioVariantConfirmed = (): void => {
    if (!(currentCharterId && scenarioId && scenarioVariantId)) {
      return;
    }

    setIsDeleteLoading(true);
    deleteCharterScenarioVariant(currentCharterId, scenarioId, scenarioVariantId)
      .then(() => {
        setIsSelectedSceneEdited(false);
        message.success(t('charters.scenarios.variantEditor.deleteVariantSuccess'));
        history.push(charterScenariosPath);
      })
      .catch((e) => {
        log.error(e);
        message.error(t('charters.scenarios.variantEditor.deleteVariantError'));
      })
      .finally(() => {
        setIsDeleteLoading(false);
      });
  };

  const onCloseSceneModal = (): void => {
    setSelectedScene(undefined);
  };

  const onGenerateScenarioJSON = (): void => {
    const scenarioScenes = scenarioVariant?.scenes;
    if (!scenarioScenes) {
      return;
    }

    const cleanedScenes = ScenarioUtils.cleanScenesOrScene(scenarioScenes);
    const cleanedVariant = { ...scenarioVariant, scenes: cleanedScenes };
    console.log(JSON.stringify(cleanedVariant, null, 2));
    message.success(t('charters.scenarios.variantEditor.jsonFileInConsole'));
  };

  const startHelp = (): void => {
    if (firstSceneCard && firstSceneCard.current) {
      firstSceneCard.current.scrollIntoView({ block: 'end' });
    }

    setRunHelp(true);

    // If the selected scene do not yet have unsaved changes, we simulate unsaved changes for the help
    if (!isSelectedSceneEdited) {
      setIsUnsavedChangesForHelpExample(true);
      setIsSelectedSceneEdited(true);
    }
  };

  const endHelp = (): void => {
    setRunHelp(false);
    // If we simulate unsaved changes on the selected scene for the help sake, we reset it
    if (isUnsavedChangesForHelpExample) {
      setIsSelectedSceneEdited(false);
      setIsUnsavedChangesForHelpExample(false);
    }
  };

  const renderDeleteScenarioVariantButton = () => {
    const canDeleteScenarioVariant = !scenarioVariant?.isMain;

    // If the scenario variant can't be deleted
    if (!canDeleteScenarioVariant) {
      return (
        <Tooltip title={t('charters.scenarios.variantEditor.deleteMainVariantTooltip')}>
          <Button type="ghost" danger size="small" disabled className="deleteScenarioVariantButton">
            <DeleteOutlined /> {t('charters.scenarios.variantEditor.deleteVariant')}
          </Button>
        </Tooltip>
      );
    }

    return (
      <Popconfirm
        title={t('charters.scenarios.variantEditor.deleteVariantConfirm')}
        onConfirm={onDeleteScenarioVariantConfirmed}
        okText={t('global.yesDelete')}
        cancelText={t('global.no')}
        okButtonProps={{ danger: true }}
        overlayClassName={classes.deleteVariantConfirm}
      >
        <Button
          type="ghost"
          danger
          size="small"
          loading={isDeleteLoading}
          disabled={isDeleteLoading}
          className="deleteScenarioVariantButton"
        >
          <DeleteOutlined /> {t('charters.scenarios.variantEditor.deleteVariant')}
        </Button>
      </Popconfirm>
    );
  };

  const handleClose = () => {
    setIsFinalPlayerModalVisible(false);
  };

  const handleOpen = () => {
    setIsFinalPlayerModalVisible(true);
    setIsScenePreviewPlaying(false);
  };

  const renderFinalPlayerButton = () => {
    return (
      <Button className={classes.watchButton} size="small" onClick={handleOpen}>
        <FaPlayCircle className={classes.watchIcon} /> {t('charters.scenarios.variantEditor.finalPlayer')}
      </Button>
    );
  };

  if (!currentCharter) {
    return null;
  }

  const routes = [
    {
      path: '/charters',
      breadcrumbName: t('menu.charters'),
    },
    {
      path: `/charters/${currentCharter.id}`,
      breadcrumbName: currentCharter.name,
    },
    {
      path: `/charters/${currentCharter.id}/scenarios`,
      breadcrumbName: t('charters.scenarios.title'),
    },
    {
      path: location.pathname,
      breadcrumbName: t('charters.scenarios.variantEditor.title'),
    },
  ];

  const breadcrumbProps: BreadcrumbProps = {
    itemRender: (route: Route) => {
      if (route.path === location.pathname) {
        return route.breadcrumbName;
      }
      return <Link to={route.path}>{route.breadcrumbName}</Link>;
    },
    routes,
  };

  if (isLoadingVariantDetails) {
    return <KannelleLoader />;
  }

  return (
    <>
      {isLoading && <KannelleLoader transparent />}

      <KannellePageHeader
        title={
          <div className={classes.titleWithIcon}>
            {t('charters.scenarios.variantEditor.title')}
            <KannelleHelpButton startHelp={startHelp} />
          </div>
        }
        breadcrumb={breadcrumbProps}
        className={classes.pageContent}
        avatar={{ className: classes.avatar, icon: <VideoCameraAddOutlined /> }}
        extra={
          <>
            {isKnlTeam && (
              <Button onClick={onGenerateScenarioJSON} type="dashed" size="small">
                <CodeOutlined /> {t('charters.scenarios.variantEditor.downloadJSON')}
              </Button>
            )}

            {renderDeleteScenarioVariantButton()}

            {renderFinalPlayerButton()}

            {scenarioVariant && scenarioVariant.scenes && scenarioVariant.scenes.length > 0 && (
              <ScenarioPDFDownloadButton scenarioVariant={scenarioVariant} />
            )}
          </>
        }
      >
        <JoyRideHelpCharterScenarioVariantEditor
          runHelp={runHelp}
          callbackRunDone={endHelp}
          canDeleteScene={(scenarioVariant && scenarioVariant.scenes && scenarioVariant.scenes.length > 1) ?? false}
        />

        <Prompt when={isSelectedSceneEdited} message={t('charters.scenarios.variantEditor.unsavedChangesPrompt')} />

        {/* ScenarioVariant metadata form */}
        <ScenarioVariantMetadataForm scenarioVariant={scenarioVariant} onUpdate={onUpdateScenarioVariantMetadata} />

        {/* ScenarioVariant scenes edition */}
        <Title level={4}>{t('charters.scenarios.variantEditor.sceneEditionTitle')}</Title>
        <Row gutter={16}>
          <div className={classNames(classes.scenesMainContainer, 'variantsScenesList')} ref={scenesCarousel}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="scenario-scenes" direction={isMobileOrTablet ? 'vertical' : 'horizontal'}>
                {(provided): JSX.Element => (
                  <div className={classes.scenesWrapperContainer} {...provided.droppableProps} ref={provided.innerRef}>
                    <div className={classes.scenesWrapper}>
                      <div className={classes.scenesContainer}>
                        {scenarioVariant &&
                          scenarioVariant.scenes &&
                          scenarioVariant.scenes.length > 0 &&
                          scenarioVariant.scenes!.map((variantScene, index) => {
                            const isSelectedManipulatedInForm =
                              sceneManipulatedInForm && sceneManipulatedInForm.id === variantScene.id;
                            // If the card corresponds to the selected scene (which we are manipulating in the form), we display the information
                            // of the `sceneManipulatedInForm` (including not yet saved changes), else we consider the `variantScene`
                            const scene = isSelectedManipulatedInForm ? sceneManipulatedInForm! : variantScene;
                            return (
                              <div key={`${scene.id}`} ref={index === 0 ? firstSceneCard : undefined}>
                                <ScenarioSceneCard
                                  scene={scene}
                                  index={index}
                                  format={scenarioVariant.format}
                                  isDeletable={scenarioVariant.scenes!.length > 1}
                                  onDeleteScene={(): void => onDeleteScene(scene)}
                                  onDuplicateScene={(): void => onDuplicateScene(scene)}
                                  onSelectScene={(): void => onSelectScene(scene)}
                                  isSelected={selectedScene && scene.id === selectedScene.id}
                                  isUnsaved={isSelectedManipulatedInForm && isSelectedSceneEdited}
                                />
                              </div>
                            );
                          })}
                        {provided.placeholder}
                        <AddScenarioScene onAddScene={onAddScene} />
                      </div>
                    </div>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            {!isMobileOrTablet ? (
              selectedScene && (
                <ScenarioSceneForm
                  form={form}
                  selectedScene={selectedScene}
                  sceneManipulatedInForm={sceneManipulatedInForm}
                  onFieldChange={onSceneFormChange}
                  onUpdateScene={onUpdateScene}
                  isSelectedSceneEdited={isSelectedSceneEdited}
                  setIsSelectedSceneEdited={setIsSelectedSceneEdited}
                  format={scenarioVariant?.format}
                  isScenePreviewPlaying={isScenePreviewPlaying}
                  callBackHandlePlayPause={onIsScenePreviewPlayingChange}
                />
              )
            ) : (
              <ScenarioSceneForm
                form={form}
                isInModal
                selectedScene={selectedScene}
                sceneManipulatedInForm={sceneManipulatedInForm}
                onFieldChange={onSceneFormChange}
                onUpdateScene={onUpdateScene}
                isSelectedSceneEdited={isSelectedSceneEdited}
                setIsSelectedSceneEdited={setIsSelectedSceneEdited}
                onCloseSceneModal={onCloseSceneModal}
                format={scenarioVariant?.format}
                isScenePreviewPlaying={isScenePreviewPlaying}
                callBackHandlePlayPause={onIsScenePreviewPlayingChange}
              />
            )}
          </Col>
        </Row>
      </KannellePageHeader>

      <ScenarioFinalPlayerModal
        isVisible={isFinalPlayerModalVisible}
        scenes={scenarioVariant?.scenes}
        format={scenarioVariant?.format || AnimationFormats.FORMAT_16_9}
        handleClose={handleClose}
      />
    </>
  );
};

export default ScenarioVariantEditor;
