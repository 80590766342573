import { Form } from 'antd';
import React, { FunctionComponent } from 'react';

const EditableRow: FunctionComponent = ({ ...props }: any) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <tr {...props} />
    </Form>
  );
};

export default EditableRow;
