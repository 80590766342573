import { Card, Skeleton } from 'antd';
import React, { FunctionComponent } from 'react';
import { createUseStyles } from 'react-jss';
import FontObserver from '../../../../../components/font-observer/FontObserver';
import { Font, FontFamily } from '../../../../../services/api/types/FontFamiliesServiceTypes';
import FontFamilyModal from './FontFamilyModal';

type Props = {
  fontFamily: FontFamily;
  selectedTitleFont?: Font;
  selectedTextFont?: Font;
  onSelectTitleFont: (font: Font, fontFamily: FontFamily) => void;
  onSelectTextFont: (font: Font, fontFamily: FontFamily) => void;
  fontSize: number;
  text: string;
};

const useStyles = createUseStyles({
  fontCard: {
    maxHeight: '500px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .ant-card-head-title': {
      fontWeight: '700',
    },
    '& .ant-card-body': {
      wordWrap: 'break-word',
      overflow: 'hidden',
      flexGrow: '1 ',
    },
  },
});

const FontFamilyCard: FunctionComponent<Props> = ({
  fontFamily,
  selectedTitleFont,
  selectedTextFont,
  onSelectTitleFont,
  onSelectTextFont,
  fontSize,
  text,
}: Props) => {
  const classes = useStyles();

  return (
    <Card
      title={fontFamily.name}
      className={classes.fontCard}
      actions={[
        <FontFamilyModal
          fontFamily={fontFamily}
          selectedTitleFont={selectedTitleFont}
          selectedTextFont={selectedTextFont}
          onSelectTitleFont={onSelectTitleFont}
          onSelectTextFont={onSelectTextFont}
        />,
      ]}
    >
      <FontObserver fontFamilyName={fontFamily.name} loader={<Skeleton active title={false} paragraph={{ rows: 6 }} />}>
        <span
          style={{
            fontFamily: fontFamily.name,
            fontSize,
          }}
        >
          {text}
        </span>
      </FontObserver>
    </Card>
  );
};

export default FontFamilyCard;
