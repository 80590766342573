import { createReducer } from '@reduxjs/toolkit';
import i18n from 'i18next';
import log from 'loglevel';
import { LoggingManager } from '../../manager/logging/LoggingManager';
import { Notification } from '../../utils/types/NotificationTypes';
import { APP_ACTION_TYPE } from '../action/AppAction';
import { USER_ACTION_TYPE } from '../action/UserAction';

export type AppState = {
  menuCollapsed: boolean;
  menuKeysOpen: string[];
  language: string;
  isUnauthorized?: boolean;
  isMobileOrTablet: boolean;
  isFreeTrialBannerDisplayed: boolean;
  unsavedChange: boolean;
  notifications: Notification[];
  loggingManager: LoggingManager;
  onboardingDone: boolean;
  apiToken: string;
};

const getCurrentLanguage = (): string => i18n.language || window.localStorage.i18nextLng || 'en-US';

const initialState: AppState = {
  menuCollapsed: false,
  isFreeTrialBannerDisplayed: false,
  menuKeysOpen: [],
  language: getCurrentLanguage(),
  isUnauthorized: undefined,
  isMobileOrTablet: false,
  unsavedChange: false,
  notifications: [],
  loggingManager: new LoggingManager(),
  onboardingDone: true,
  apiToken: '',
};

export const AppReducer = createReducer(initialState, {
  [APP_ACTION_TYPE.RESET_STORE_STATE]: () => {
    log.info('Store Hard reset App');
    return { ...initialState };
  },
  [APP_ACTION_TYPE.TOGGLE_MENU]: (draftState) => {
    log.info(`Menu will be ${!draftState.menuCollapsed ? 'collapsed' : 'expended'} `);
    draftState.menuCollapsed = !draftState.menuCollapsed;
  },
  [APP_ACTION_TYPE.OPEN_MENU_ITEM]: (draftState, action) => {
    if (!draftState.menuKeysOpen.includes(action.payload)) {
      draftState.menuKeysOpen = [...draftState.menuKeysOpen, action.payload];
    }
  },
  [APP_ACTION_TYPE.SET_OPEN_MENU_ITEM]: (draftState, action) => {
    draftState.menuKeysOpen = [...action.payload];
  },
  [APP_ACTION_TYPE.SET_IS_USER_UNAUTHORIZED]: (draftState, action) => {
    draftState.isUnauthorized = action?.payload?.isUnauthorized ?? false;
  },
  [APP_ACTION_TYPE.CHECK_DEVICE_IS_MOBILE_OR_TABLET]: (draftState, action) => {
    const isMobileOrTablet = action?.payload?.isMobileOrTablet;
    log.info(`Device is ${!isMobileOrTablet ? 'not ' : ''}a mobile or a tablet`);
    draftState.isMobileOrTablet = isMobileOrTablet;
    draftState.menuCollapsed = !!isMobileOrTablet; // On small devices, the menu is collapsed by default
  },
  [APP_ACTION_TYPE.UPDATE_LANGUAGE]: (draftState, action) => {
    const language = action?.payload?.language;
    log.info(`Update language to: ${language}`);
    i18n.changeLanguage(language);
    draftState.language = language;
  },
  [APP_ACTION_TYPE.UPDATE_UNSAVED_CHANGE]: (draftState, action) => {
    draftState.unsavedChange = action.payload;
  },
  [APP_ACTION_TYPE.CLEAR_NOTIFICATIONS]: (draftState) => {
    draftState.notifications = [];
  },
  [APP_ACTION_TYPE.PUSH_NOTIFICATION]: (draftState, action) => {
    const notification = action.payload;
    const notifications = [...draftState.notifications] || [];
    notifications.push(notification);
    draftState.notifications = notifications;
  },
  [APP_ACTION_TYPE.EDIT_NOTIFICATION_BY_ID]: (draftState, action) => {
    const { notificationId, newNotificationParameters } = action.payload;
    const notifications = [...draftState.notifications] || [];
    const notificationIndex = notifications.findIndex((notif) => notif.id === notificationId);
    if (notificationIndex >= 0) {
      const currentNotification = notifications[notificationIndex];
      notifications[notificationIndex] = { ...currentNotification, ...newNotificationParameters };
    }
    draftState.notifications = notifications;
  },
  [APP_ACTION_TYPE.DELETE_NOTIFICATION_BY_ID]: (draftState, action) => {
    const notificationId = action.payload;
    const notifications = [...draftState.notifications] || [];
    const notificationIndex = notifications.findIndex((notif) => notif.id === notificationId);
    if (notificationIndex >= 0) {
      notifications.splice(notificationIndex, 1);
    }
    draftState.notifications = notifications;
  },
  [APP_ACTION_TYPE.STORE_LOGGING_MANAGER]: (draftState, action) => {
    draftState.loggingManager = action.payload;
  },
  [APP_ACTION_TYPE.SET_ONBOARDING]: (draftState, action) => {
    draftState.onboardingDone = action.payload;
  },
  [APP_ACTION_TYPE.RESET_ONBOARDING]: (draftState) => {
    draftState.onboardingDone = false;
  },
  [APP_ACTION_TYPE.SET_API_TOKEN]: (draftState, action) => {
    draftState.apiToken = action.payload;
  },
  [USER_ACTION_TYPE.INITIALIZE_USER]: (draftState, action) => {
    draftState.onboardingDone = action.payload.onboarded.web;
  },
  [APP_ACTION_TYPE.SET_IS_FREE_TRIAL_BANNER_DISPLAYED]: (draftState, action) => {
    draftState.isFreeTrialBannerDisplayed = action.payload;
  },
});
