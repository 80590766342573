import i18n from 'i18next';

export default class FileUtils {
  static formatBytesIntoHumanReadableSize(bytes: number, decimals = 2): string {
    if (bytes === 0) {
      return `0 ${i18n.t('fileSizeUnits.bytes')}`;
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const fileSizeUnits = [
      'fileSizeUnits.bytes',
      'fileSizeUnits.kb',
      'fileSizeUnits.mb',
      'fileSizeUnits.gb',
      'fileSizeUnits.tb',
      'fileSizeUnits.pb',
    ];

    const i = Math.min(Math.floor(Math.log(bytes) / Math.log(k)), 5);

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${i18n.t(fileSizeUnits[i])}`;
  }

  static base64toBlob = async (b64Data: string): Promise<Blob> => {
    const base64Response = await fetch(b64Data);
    return base64Response.blob();
  };
}
