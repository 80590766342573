import { Form, Switch } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { THEME } from '../../Constants';

const useStyles = createUseStyles({
  main: {
    color: THEME.DEFAULT.MAIN_TEXT_COLOR,
  },
  switch: {
    marginRight: '8px !important',
  },
});

type Props = {
  isActive: boolean;
  callbackChange: (value: boolean) => void;
  label?: string;
  extra?: string;
};

const Active: FunctionComponent<Props> = ({ isActive, callbackChange, label, extra }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [checked, setChecked] = useState(isActive);

  useEffect(() => {
    setChecked(isActive);
  }, [isActive]);

  const info = checked ? <span className={classes.main}>{t('form.active')}</span> : <span>{t('form.disabled')}</span>;

  const handleChange = (): void => {
    const newValue = !checked;
    setChecked(newValue);
    callbackChange(newValue);
  };

  return (
    <>
      <Form.Item
        label={label}
        name="isActive"
        rules={[{ required: true }]}
        extra={extra}
        validateStatus="success"
        valuePropName="checked"
        hasFeedback
      >
        <span>
          <Switch checked={checked} className={classes.switch} onChange={handleChange} />
          {info}
        </span>
      </Form.Item>
    </>
  );
};

export default Active;
