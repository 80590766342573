import { Button, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdLaptop } from 'react-icons/io';
import { createUseStyles } from 'react-jss';
import { MediaFile } from '../../services/api/types/ChartersServiceTypes';
// eslint-disable-next-line import/no-cycle
import { ScreenRecorderModal } from './ScreenRecorderModal';

type Props = {
  screen?: boolean;
  audio?: boolean;
  video?: boolean;
  options?: ScreenRecorderOptions;
  onRecordingProcessed?: (media: MediaFile) => void;
};

export type ScreenRecorderOptions = {
  recommendedTime?: number;
  overlayImage?: string;
};

const useStyles = createUseStyles({
  recordButton: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 8,
    '& > svg': {
      marginRight: 8,
    },
  },
  recordButtonIcon: {},
  recordButtonIconDisabled: {
    verticalAlign: 'middle',
    marginRight: 8,
  },
});

const useIsReactMediaRecorderAvailable = (options: any) => {
  if (!window.MediaRecorder) {
    console.log('Screenrecording: Unsupported browser');
    return false;
  }

  if (options.screen) {
    if (!(window.navigator.mediaDevices as any).getDisplayMedia) {
      console.log("Screenrecording: This browser doesn't support screen capturing");
      return false;
    }
  }

  return true;
};

const ScreenRecorder: React.FunctionComponent<Props> = ({
  screen = true,
  audio = true,
  video = true,
  options,
  onRecordingProcessed,
}: Props) => {
  const [isRecordModalVisible, setIsRecordModalVisible] = useState(false);
  const isReactMediaRecorderAvailable = useIsReactMediaRecorderAvailable({ screen, audio, video });

  const classes = useStyles();
  const { t } = useTranslation();

  const onOpenModal = () => {
    setIsRecordModalVisible(true);
  };

  const onCloseModal = () => {
    setIsRecordModalVisible(false);
  };

  const renderButton = () => (
    <Button
      onClick={onOpenModal}
      icon={
        <IoMdLaptop
          className={isReactMediaRecorderAvailable ? classes.recordButtonIcon : classes.recordButtonIconDisabled}
        />
      }
      type="ghost"
      className={classes.recordButton}
      disabled={!isReactMediaRecorderAvailable}
    >
      {t('charters.mediaLibrary.screenRecorder.recordButton')}
    </Button>
  );

  return (
    <>
      {isReactMediaRecorderAvailable ? (
        renderButton()
      ) : (
        <Tooltip placement="top" title={t('charters.mediaLibrary.screenRecorder.browserNotSupportedMessage')}>
          {renderButton()}
        </Tooltip>
      )}

      {isReactMediaRecorderAvailable && (
        <ScreenRecorderModal
          isVisible={isRecordModalVisible}
          audio={audio}
          video={video}
          screen={screen}
          options={options}
          onRecordingProcessed={onRecordingProcessed}
          onCancel={onCloseModal}
        />
      )}
    </>
  );
};

export default ScreenRecorder;
