import { floor } from 'lodash';

export default class MathUtils {
  static formatInputValueWithTwoDecimals = (inputValue: number | undefined) => {
    return `${floor(inputValue || 0, 2)}`;
  };

  static formatInputValueWithXDecimals = (inputValue: number, decimal = 3) => {
    return Number(inputValue.toFixed(decimal));
  };

  static convertBytesToKilobytes = (bytes: number, floorResult = true) => {
    const kilobytes = bytes / 1024;
    return floorResult ? Math.floor(kilobytes) : kilobytes;
  };
}
