import { Alert, Button, Form, InputNumber, message, Popconfirm } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { find, findIndex } from 'lodash';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTrashAlt } from 'react-icons/fa';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import InputText from '../../../../../../components/form/InputText';
import { SUBTITLES_CONSTANTS } from '../../../../../../Constants';
import { RootState } from '../../../../../../redux/RootState';
import { deleteSubtitleBlock, patchSubtitleBlock } from '../../../../../../services/api/SubtitlesService';
import MathUtils from '../../../../../../utils/MathUtils';
import {
  Project,
  ProjectScene,
  ProjectSceneElement,
  ProjectSceneElements,
  SubtitleBlock,
  SubtitleBlockMode,
  Subtitles,
} from '../../../../../../utils/types/ProjectTypes';

type Props = {
  project: Project;
  initialScene: ProjectScene;
  scene: ProjectScene;
  subtitles: Subtitles;
  isSceneEdited: boolean;
  isSceneInError: boolean;
  selectedElementId: number;
  onEditScene: (updatedScene: ProjectScene, updateInitialScene?: boolean) => void;
  onIsSceneEditedChange: (isEdited: boolean) => void;
  onSelectElement: (e: ProjectSceneElement, skipConfirm?: boolean, elementId?: number) => void;
};

const useStyles = createUseStyles({
  form: {
    height: '80%',
    overflow: 'auto',
    paddingRight: 8,
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '6px',
      backgroundColor: 'rgb(123, 123, 123)',
      boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
    },
    '&::-webkit-scrollbar-track-piece': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      borderRadius: '6px',
    },

    '& .ant-form-item-label': {
      padding: 0,
    },
    '& .ant-form-item': {
      marginBottom: 10,
    },
  },
  actionsContainer: {
    position: 'absolute',
    bottom: 10,
    right: 0,
    '& > button:not(:last-child)': {
      marginRight: 10,
    },
  },
  deletePipButton: {
    position: 'absolute',
    bottom: 10,
    '& .ant-btn': {
      lineHeight: 1,
    },
  },
  info: {
    padding: 4,
    '& .ant-alert-message': {
      color: '#094662 !important',
    },
  },
});

const ProjectSceneSubtitleBlockOptions: FunctionComponent<Props> = ({
  project,
  initialScene,
  scene,
  selectedElementId,
  subtitles,
  isSceneEdited,
  isSceneInError,
  onEditScene,
  onIsSceneEditedChange,
  onSelectElement,
}: Props) => {
  const currentCharter = useSelector((state: RootState) => state.charters.current);
  const [initialValues, setInitialValues] = useState<Store>();
  const [initialSubtitleBlock, setInitialSubtitleBlock] = useState<SubtitleBlock>();
  const [selectedSubtitleBlock, setSelectedSubtitleBlock] = useState<SubtitleBlock>();
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const classes = useStyles();
  const [form] = Form.useForm();

  // Callback to see if the subtitle block options have been changed and differ from the ones of the
  // initialScene
  const [checkIfSubtitleBlockIsEdited] = useDebouncedCallback(() => {
    // Get the initial scene values
    const initialSubtitleBlockText = initialSubtitleBlock?.text;
    const initialSubtitleBlockStart = MathUtils.formatInputValueWithXDecimals(Number(initialSubtitleBlock?.startAt), 2);
    const initialSubtitleBlockEnd = MathUtils.formatInputValueWithXDecimals(Number(initialSubtitleBlock?.endAt), 2);

    // Get the field values
    const formSubtitleBlockText = form.getFieldValue('subtitleText');
    const formSubtitleBlockStart = form.getFieldValue('subtitleStart');
    const formSubtitleBlockEnd = form.getFieldValue('subtitleEnd');

    // Compare the initial scene subtitle block params with the ones from the form
    const isSubtitleBlockTextEqual = initialSubtitleBlockText === formSubtitleBlockText;
    const isSubtitleBlockStartEqual = initialSubtitleBlockStart === formSubtitleBlockStart;
    const isSubtitleBlockEndEqual = initialSubtitleBlockEnd === formSubtitleBlockEnd;

    // Check if the subtitle block was edited
    const wasNotEdited = isSubtitleBlockTextEqual && isSubtitleBlockStartEqual && isSubtitleBlockEndEqual;

    onIsSceneEditedChange(!wasNotEdited);
  }, 250);

  useEffect(() => {
    const subtitleBlock = find(subtitles.blocks, { id: selectedElementId });

    if (subtitleBlock) {
      if (!initialSubtitleBlock || (initialSubtitleBlock && selectedElementId !== initialSubtitleBlock?.id)) {
        setInitialSubtitleBlock({ ...subtitleBlock });
      }
      setSelectedSubtitleBlock({ ...subtitleBlock });

      checkIfSubtitleBlockIsEdited();
    }
  }, [selectedElementId, subtitles]);

  useEffect(() => {
    if (!initialSubtitleBlock) {
      return;
    }

    const formInitialValues = {
      subtitleText: initialSubtitleBlock.text,
      subtitleStart: MathUtils.formatInputValueWithXDecimals(Number(initialSubtitleBlock.startAt), 2),
      subtitleEnd: MathUtils.formatInputValueWithXDecimals(Number(initialSubtitleBlock.endAt), 2),
    };

    setInitialValues(formInitialValues);
    form.setFieldsValue(formInitialValues);
  }, [initialScene, form, initialSubtitleBlock]);

  useEffect(() => {
    // When the initial scene changes it means that we need to re-get our subtitle block because it's been updated
    const subtitleBlock = find(subtitles.blocks, { id: selectedElementId });
    if (subtitleBlock) {
      setInitialSubtitleBlock({ ...subtitleBlock });
    }
  }, [initialScene]);

  useEffect(() => {
    if (!selectedSubtitleBlock) {
      return;
    }

    const values = {
      subtitleText: selectedSubtitleBlock.text,
      subtitleStart: MathUtils.formatInputValueWithXDecimals(Number(selectedSubtitleBlock.startAt), 2),
      subtitleEnd: MathUtils.formatInputValueWithXDecimals(Number(selectedSubtitleBlock.endAt), 2),
    };

    form.setFieldsValue(values);
  }, [selectedSubtitleBlock?.startAt, selectedSubtitleBlock?.endAt, selectedSubtitleBlock?.text]);

  if (!selectedSubtitleBlock) {
    return null;
  }

  const getMinStartAtPosition = (): number => {
    const index = findIndex(subtitles.blocks, { id: selectedSubtitleBlock.id });

    if (index <= 0) {
      return Math.max(0, selectedSubtitleBlock.endAt - SUBTITLES_CONSTANTS.MAX_DURATION);
    }

    return subtitles.blocks[index - 1].startAt + 0.5;
  };

  const getMaxEndAtPosition = (): number => {
    const index = findIndex(subtitles.blocks, { id: selectedSubtitleBlock.id });

    if (subtitles.blocks.length === 0 || index === subtitles.blocks.length - 1) {
      return selectedSubtitleBlock.startAt + SUBTITLES_CONSTANTS.MAX_DURATION;
    }

    const timeAvailableNextAnchor = subtitles.blocks[index + 1].startAt - selectedSubtitleBlock.endAt;
    const currentDuration = selectedSubtitleBlock.endAt - selectedSubtitleBlock.startAt;

    return timeAvailableNextAnchor + currentDuration > SUBTITLES_CONSTANTS.MAX_DURATION
      ? SUBTITLES_CONSTANTS.MAX_DURATION + selectedSubtitleBlock.startAt
      : subtitles.blocks[index + 1].startAt;
  };

  const buildSceneFromForm = (isReset = false) => {
    if (!selectedSubtitleBlock) {
      return undefined;
    }

    const fields = form.getFieldsValue();
    const updatedSubtitleBlocks = [...subtitles.blocks];
    const subtitleBlockToUpdateIndex = findIndex(updatedSubtitleBlocks, { id: selectedElementId });
    const subtitleBlockToUpdate: SubtitleBlock = { ...selectedSubtitleBlock, mode: SubtitleBlockMode.MANUAL };

    if (isReset && initialSubtitleBlock) {
      updatedSubtitleBlocks.splice(subtitleBlockToUpdateIndex, 1, initialSubtitleBlock);
      setSelectedSubtitleBlock(initialSubtitleBlock);
      return {
        ...scene,
        subtitles: scene.subtitles
          ? {
              ...scene.subtitles,
              blocks: [...updatedSubtitleBlocks],
            }
          : undefined,
      };
    }

    subtitleBlockToUpdate.text = fields.subtitleText;
    subtitleBlockToUpdate.startAt = MathUtils.formatInputValueWithXDecimals(Number(fields.subtitleStart), 2);
    subtitleBlockToUpdate.endAt = MathUtils.formatInputValueWithXDecimals(Number(fields.subtitleEnd), 2);

    setSelectedSubtitleBlock({ ...subtitleBlockToUpdate });
    updatedSubtitleBlocks.splice(subtitleBlockToUpdateIndex, 1, subtitleBlockToUpdate);

    return {
      ...scene,
      subtitles: scene.subtitles
        ? {
            ...scene.subtitles,
            blocks: [...updatedSubtitleBlocks],
          }
        : undefined,
    };
  };

  const buildAndEditScene = (isReset = false) => {
    const updatedScene = buildSceneFromForm(isReset);

    if (updatedScene) {
      onEditScene(updatedScene);
    }
  };

  const onReset = (): void => {
    form.resetFields();
    onIsSceneEditedChange(false);

    // Reset the changes to the project scene being edited
    buildAndEditScene(true);
  };

  const handleDeleteSubtitleBlock = (): void => {
    if (currentCharter && project && selectedElementId) {
      setIsLoading(true);
      deleteSubtitleBlock(currentCharter.id, project.id, scene.id, selectedElementId)
        .then(() => {
          const subtitlesBlocks = [...subtitles.blocks];
          const indexToDelete = findIndex(subtitlesBlocks, { id: selectedElementId });
          subtitlesBlocks.splice(indexToDelete, 1);
          if (subtitlesBlocks.length > 0) {
            onSelectElement(ProjectSceneElements.SUBTITLES, true, subtitlesBlocks[0].id);
          } else {
            onSelectElement(ProjectSceneElements.RECORDABLE_VIDEO, true);
          }

          onEditScene(
            {
              ...scene,
              subtitles: {
                ...subtitles,
                blocks: [...subtitlesBlocks],
              },
            },
            true
          );
        })
        .finally(() => {
          setIsLoading(false);
          message.success(t('charters.projects.projectEditor.sceneEditor.fields.subtitles.deleteSuccess'));
        });
    }
  };

  const onSubmit = (): void => {
    form
      .validateFields()
      .then(() => {
        const updatedScene = buildSceneFromForm();

        if (!updatedScene) {
          return;
        }

        if (currentCharter && project && selectedElementId) {
          setIsSaveLoading(true);

          patchSubtitleBlock(currentCharter.id, project.id, scene.id, selectedElementId, {
            ...selectedSubtitleBlock,
            startAt: MathUtils.formatInputValueWithXDecimals(Number(selectedSubtitleBlock.startAt), 2),
            endAt: MathUtils.formatInputValueWithXDecimals(Number(selectedSubtitleBlock.endAt), 2),
          })
            .then(() => {
              onEditScene(updatedScene, true);
              setInitialSubtitleBlock({ ...selectedSubtitleBlock });
            })
            .finally(() => {
              message.success(t('charters.projects.projectEditor.projectUpdateSuccess'));
              setIsSaveLoading(false);
            });
        }

        onIsSceneEditedChange(false);
      })
      .catch((e) => {
        log.error(e);
      });
  };

  const onValuesChange = (): void => {
    // Check if the values in the form differ from the initial scene ones
    checkIfSubtitleBlockIsEdited();

    buildAndEditScene();

    onIsSceneEditedChange(true);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      className={classes.form}
      size="small"
      initialValues={initialValues}
      onValuesChange={onValuesChange}
    >
      <InputText
        name="subtitleText"
        label={t('charters.projects.projectEditor.sceneEditor.fields.subtitles.subtitleText')}
        placeholder={t('charters.projects.projectEditor.sceneEditor.fields.subtitles.subtitleTextPlaceholder')}
        required
        hasFeedback={false}
      />

      {selectedSubtitleBlock && [
        <Form.Item
          key="subtitleStart"
          name="subtitleStart"
          label={t('charters.projects.projectEditor.sceneEditor.fields.subtitles.subtitleStart')}
          rules={[{ required: true }]}
        >
          <InputNumber
            min={getMinStartAtPosition()}
            max={selectedSubtitleBlock.endAt - SUBTITLES_CONSTANTS.MIN_DURATION}
            step={1}
            formatter={(value: number | undefined) =>
              value ? MathUtils.formatInputValueWithXDecimals(Number(value), 2)?.toString() : ''
            }
          />
        </Form.Item>,
        <Form.Item
          label={t('charters.projects.projectEditor.sceneEditor.fields.subtitles.subtitleEnd')}
          key="subtitleEnd"
          name="subtitleEnd"
          rules={[{ required: true }]}
        >
          <InputNumber
            min={selectedSubtitleBlock.startAt + SUBTITLES_CONSTANTS.MIN_DURATION}
            max={getMaxEndAtPosition()}
            step={0.1}
            formatter={(value: number | undefined) =>
              value ? MathUtils.formatInputValueWithXDecimals(Number(value), 2)?.toString() : ''
            }
          />
        </Form.Item>,
      ]}

      <Alert
        className={classes.info}
        message={t('charters.projects.projectEditor.sceneEditor.fields.subtitles.subtitleInfo')}
        type="info"
      />

      <div className={classes.deletePipButton}>
        <Popconfirm
          title={t('charters.projects.projectEditor.sceneEditor.fields.subtitles.delete')}
          key="deleteSubtitle"
          onConfirm={handleDeleteSubtitleBlock}
          okText={t('global.yes')}
          cancelText={t('global.no')}
        >
          <Button type="primary" danger shape="circle" icon={<FaTrashAlt />} disabled={isLoading} loading={isLoading} />
        </Popconfirm>
      </div>

      <div className={classes.actionsContainer}>
        <Button onClick={onReset} disabled={!isSceneEdited || isLoading}>
          {t('global.reset')}
        </Button>
        <Button
          type="primary"
          onClick={onSubmit}
          loading={isSaveLoading}
          disabled={!isSceneEdited || isSceneInError || isLoading || isSaveLoading}
        >
          {t('global.save')}
        </Button>
      </div>
    </Form>
  );
};

export default ProjectSceneSubtitleBlockOptions;
