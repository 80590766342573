import { CancelTokenSource } from 'axios';
import FontFaceObserver from 'fontfaceobserver';
import log from 'loglevel';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAuth0 } from '../core/auth/Auth0Config';
import { RootState } from '../redux/RootState';
import { APIManager } from '../services/api/APIManager';
import { getCharterFontFamilies } from '../services/api/ChartersService';
import { getPublicFontFamilies } from '../services/api/FontFamiliesService';
import { APIGetFontFamiliesResponse, FontFamily } from '../services/api/types/FontFamiliesServiceTypes';

const useFetchFonts = () => {
  const { user, isAuthenticated } = useAuth0();

  const [publicFontFamilies, setPublicFontFamilies] = useState<FontFamily[]>();
  const [charterFontFamilies, setCharterFontFamilies] = useState<FontFamily[]>();

  const currentCharter = useSelector((state: RootState) => state.charters.current);

  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());

  // Fetch the Kannelle public fonts
  useEffect(() => {
    if (!user || !isAuthenticated) {
      return;
    }

    const cancelTokenSource = cancelTokenSourceRef.current;
    getPublicFontFamilies(cancelTokenSource)
      .then((response: APIGetFontFamiliesResponse) => {
        const { data } = response;
        setPublicFontFamilies(data.items);
      })
      .catch((e) => {
        log.debug('Error during public fonts fetch', e);
      });
  }, [user, isAuthenticated]);

  // Fetch the charter private fonts
  useEffect(() => {
    if (!currentCharter || !user || !isAuthenticated) {
      return;
    }

    const cancelTokenSource = cancelTokenSourceRef.current;
    getCharterFontFamilies(currentCharter.id, cancelTokenSource)
      .then((response: APIGetFontFamiliesResponse) => {
        const { data } = response;
        setCharterFontFamilies(data.items);
      })
      .catch((e) => {
        log.debug('Error during charter fonts fetch', e);
      });
  }, [currentCharter, user, isAuthenticated]);

  // Append a stylesheet link to the 'document.head'
  const appendStylesheetLink = useCallback((stylesheetUrl: string) => {
    const { head } = document;
    const link = document.createElement('link');

    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = stylesheetUrl;

    head.appendChild(link);
  }, []);

  // For each font-family, check if its stylesheet needs to be loaded
  useEffect(() => {
    const allFontFamilies: FontFamily[] = [
      ...(publicFontFamilies ? [...publicFontFamilies] : []),
      ...(charterFontFamilies ? [...charterFontFamilies] : []),
    ];

    allFontFamilies.forEach((ff) => {
      const fontFaceObserver = new FontFaceObserver(ff.name);
      fontFaceObserver
        .load(null, 10000)
        .then(undefined, () => {
          appendStylesheetLink(ff.stylesheetUrl);
        })
        .catch(() => {
          appendStylesheetLink(ff.stylesheetUrl);
        });
    });
  }, [publicFontFamilies, charterFontFamilies]);
};

export default useFetchFonts;
