import { createReducer } from '@reduxjs/toolkit';
import { UploadFile } from 'antd/lib/upload/interface';
import { cloneDeep, findIndex, remove } from 'lodash';
import log from 'loglevel';
import {
  APICharterPermission,
  APIFullModelCharter,
  APIModelCharter,
  Asset,
  MediaFile,
  Scenario,
  UsersModelCharter,
} from '../../services/api/types/ChartersServiceTypes';
import CharterUtils from '../../utils/CharterUtils';
import MediaUtils from '../../utils/MediaUtils';
import { Project } from '../../utils/types/ProjectTypes';
import UsersUtils from '../../utils/UsersUtils';
import { APP_ACTION_TYPE } from '../action/AppAction';
import { CHARTERS_ACTION_TYPE } from '../action/ChartersAction';
import { COMPANIES_ACTION_TYPE } from '../action/CompaniesAction';

export type ChartersState = {
  list?: Array<APIModelCharter>;
  current?: APIFullModelCharter;
  currentCharterUsers?: UsersModelCharter[];
  currentCharterScenarios?: Scenario[];
  currentCharterProjects?: Project[];
  currentCharterMedia?: MediaFile[];
  currentCharterPermissions?: APICharterPermission[];
  processingAssets: {
    [key: string]: UploadFile[];
  };
  processingMedia: {
    [key: string]: UploadFile[];
  };
};

const initialState: ChartersState = {
  list: undefined,
  current: undefined,
  currentCharterMedia: [],
  currentCharterScenarios: [],
  currentCharterProjects: [],
  currentCharterPermissions: [],
  processingAssets: {
    logo: [],
    intro: [],
    outro: [],
    music: [],
  },
  processingMedia: {
    image: [],
    video: [],
  },
};

export const ChartersReducer = createReducer(initialState, {
  [APP_ACTION_TYPE.RESET_STORE_STATE]: () => {
    log.info('Store Hard reset Charters');
    return { ...initialState };
  },
  [CHARTERS_ACTION_TYPE.SET_CHARTERS_LIST]: (draftState, action) => {
    log.info('We fetched the current charters successfully: ', action.payload);
    draftState.list = [...action.payload];
  },
  [CHARTERS_ACTION_TYPE.SELECT_CHARTER]: (draftState, action) => {
    log.info('We selected the charter: ', action.payload);

    const { charter, permissions } = action.payload;

    draftState.current = charter;
    draftState.processingMedia = { image: [], video: [] };
    draftState.currentCharterPermissions = permissions;
  },
  [CHARTERS_ACTION_TYPE.UPDATE_CHARTER_PERMISSIONS]: (draftState, action) => {
    log.info('We update the charter permissions: ', action.payload);

    draftState.currentCharterPermissions = action.payload;
  },
  [CHARTERS_ACTION_TYPE.SET_CHARTER_USERS]: (draftState, action) => {
    log.info('Current charter users:', action.payload);
    draftState.currentCharterUsers = action.payload;
  },
  [CHARTERS_ACTION_TYPE.ADD_USER_TO_CHARTER]: (draftState, action) => {
    log.info('add user to charters:', action.payload);

    if (draftState.currentCharterUsers) {
      const newUser = action.payload;
      newUser.highlightRow = true;
      draftState.currentCharterUsers = [
        // part of the array before the specified index
        ...draftState?.currentCharterUsers?.slice(0, 1), // inserted item
        newUser, // part of the array after the specified index
        ...draftState?.currentCharterUsers?.slice(1),
      ];
    }
  },
  [CHARTERS_ACTION_TYPE.RESET_ADDED_USER_TO_CHARTER_PRIORITY]: (draftState, action) => {
    const { currentCharterUsers } = draftState;

    if (currentCharterUsers) {
      const resetNeeded = currentCharterUsers.some((row) => row.highlightRow);
      if (resetNeeded) {
        const charterUsersUpdated = currentCharterUsers.map((user) => {
          const userToUpdate = user;
          userToUpdate.highlightRow = false;

          return userToUpdate;
        });

        draftState.currentCharterUsers = UsersUtils.defaultSortUsersByActiveThenRoleAndPlaceCurrentUserFirst(
          [...charterUsersUpdated],
          action.payload
        );
      }
    }
  },
  [CHARTERS_ACTION_TYPE.UPDATING_USER_IN_CURRENT_CHARTER]: (draftState, action) => {
    log.info('Updating user in charters:', action.payload);

    const { currentCharterUsers } = draftState;

    if (currentCharterUsers) {
      draftState.currentCharterUsers = currentCharterUsers.map((user) => {
        const userToUpdate = user;

        if (userToUpdate.id === action.payload.userId) {
          userToUpdate.updating = { [action.payload.key]: true };
        }

        return userToUpdate;
      });
    }
  },
  [CHARTERS_ACTION_TYPE.UPDATE_USER_IN_CURRENT_CHARTER]: (draftState, action) => {
    const updatedUser = action.payload;
    log.info('Update user in charters:', updatedUser);

    const { currentCharterUsers } = draftState;

    if (currentCharterUsers) {
      draftState.currentCharterUsers = currentCharterUsers.map((user) => {
        const userToUpdate = user;
        const isUpdatedUser = user.id === updatedUser.id;

        if (isUpdatedUser) {
          userToUpdate.acl.role = updatedUser.acl.role;
          userToUpdate.acl.isActive = updatedUser.acl.isActive;
          userToUpdate.name = updatedUser.name;
          userToUpdate.updating = { role: false, isActive: false };
          userToUpdate.highlightRow = true;
        } else {
          userToUpdate.highlightRow = false;
        }

        return userToUpdate;
      });
    }
  },
  [CHARTERS_ACTION_TYPE.UPDATE_CURRENT_USER_IN_CHARTER_LIST]: (draftState, action) => {
    log.info('Update user in charter list:', action.payload);
    const { user, charterId } = action.payload;

    const { list } = draftState;

    if (list) {
      const currentCharterIndex = findIndex(list, { id: charterId });
      const currentCharter = { ...list[currentCharterIndex] };

      if (!currentCharter.currentUser) {
        currentCharter.currentUser = { isActive: user.acl.isActive, role: user.acl.role, permissions: [] };
      } else {
        currentCharter.currentUser.isActive = user.acl.isActive;
        currentCharter.currentUser.role = user.acl.role;
      }

      list.splice(currentCharterIndex, 1, currentCharter);
      draftState.list = [...list];
    }
  },
  // Case when a POC user removes himself from a charter
  [CHARTERS_ACTION_TYPE.REMOVE_CURRENT_USER_FROM_CURRENT_CHARTER]: (draftState, action) => {
    log.info('Remove the current user in the current charter in list', action.payload);
    const { currentCharterUsers, list, current } = draftState;

    if (currentCharterUsers && list && current) {
      const currentCharterIndex = findIndex(list, { id: current.id });
      const currentCharter = { ...list[currentCharterIndex] };
      currentCharter.currentUser = {} as any;

      list.splice(currentCharterIndex, 1, currentCharter);
      draftState.list = [...list];
    }
  },
  [CHARTERS_ACTION_TYPE.REMOVE_USER_FROM_CURRENT_CHARTER]: (draftState, action) => {
    log.info('Removing user from charters:', action.payload);

    const { currentCharterUsers } = draftState;
    if (currentCharterUsers) {
      draftState.currentCharterUsers = currentCharterUsers.filter((user) => user.id !== action.payload);
    }
  },
  [CHARTERS_ACTION_TYPE.ADD_CREATED_CHARTER]: (draftState, action) => {
    log.info('Adding created charter:', action.payload);

    // Add the new charter at first position in the charters list
    const { list } = draftState;
    const newCharterList = list ? [...list] : [];
    newCharterList.unshift(action.payload);

    draftState.list = newCharterList;
  },
  [CHARTERS_ACTION_TYPE.UPDATE_CHARTER]: (draftState, action) => {
    log.info('Updating charter:', action.payload);

    const updatedCharter = action.payload;
    const { list } = draftState;
    const newCharterList = list ?? [];
    const updatedCharterIndex = newCharterList?.findIndex((charter) => charter.id === updatedCharter.id);
    newCharterList[updatedCharterIndex] = updatedCharter;

    draftState.list = newCharterList;
    draftState.current = updatedCharter;
  },
  [CHARTERS_ACTION_TYPE.FILE_UPLOADED_PROCESSING]: (draftState, action) => {
    log.info('Uploaded file:', action.payload);

    const { file, assetType } = action.payload;

    const processingAssets = draftState.processingAssets || [];
    const uploadFiles = CharterUtils.getUploadFilesByType(assetType, processingAssets);
    const processingAssetIndex = findIndex(uploadFiles, (uploadFile: UploadFile) => {
      return uploadFile.response.data.operationId === file.response.data.operationId;
    });
    // If the asset was already in processing, it was in error, we reset it
    if (processingAssetIndex >= 0) {
      uploadFiles[processingAssetIndex].response.data.error = undefined;
    } else {
      // Else a new file has been uploaded, we add it to the processing assets
      uploadFiles.push(file);
    }

    draftState.processingAssets[assetType] = uploadFiles;
  },
  [CHARTERS_ACTION_TYPE.REFRESH_PROCESSING_ASSETS_BY_TYPE]: (draftState, action) => {
    log.info('Refresh processing assets by type:', action.payload);

    const { assetType, assets } = action.payload;
    const processingAssets = CharterUtils.getUploadFilesByType(assetType, draftState.processingAssets);

    assets.forEach((asset: Asset) => {
      const processingAssetIndex = findIndex(processingAssets, (file: UploadFile) => {
        return file.response.data.operationId === asset.operationId;
      });
      if (processingAssetIndex >= 0) {
        processingAssets.splice(processingAssetIndex, 1);
      }
    });

    if (draftState.current) {
      const charterAssetNameByType = CharterUtils.getCharterAssetsNameByType(assetType);
      if (charterAssetNameByType) {
        draftState.current[charterAssetNameByType] = assets;
      }
    }

    draftState.processingAssets[assetType] = processingAssets;
  },
  [CHARTERS_ACTION_TYPE.UPDATE_PROCESSING_FILE_OPERATION_ID]: (draftState, action) => {
    const { oldOperationId, newOperationId, assetType } = action.payload;
    log.info('Update file asset processing operation id for retry:', action.payload);

    const processingAssets = CharterUtils.getUploadFilesByType(assetType, draftState.processingAssets);
    const processingAssetIndex = findIndex(processingAssets, (file: UploadFile) => {
      return file.response.data.operationId === oldOperationId;
    });

    if (processingAssetIndex >= 0) {
      // Update the processingAsset by setting it is in error
      processingAssets[processingAssetIndex].response.data.operationId = newOperationId;
    }

    draftState.processingAssets[assetType] = processingAssets;
  },
  [CHARTERS_ACTION_TYPE.FILE_UPLOADED_PROCESSED]: (draftState, action) => {
    const { asset, assetType } = action.payload;
    log.info('File asset successfully processed:', asset);

    const processingAssets = CharterUtils.getUploadFilesByType(assetType, draftState.processingAssets);
    const currentCharter = cloneDeep(draftState.current);

    const processingAssetIndex = findIndex(processingAssets, (file: UploadFile) => {
      return file.response.data.operationId === asset.operationId;
    });
    if (currentCharter) {
      if (processingAssetIndex >= 0) {
        // Remove the asset from the processing assets (only if present)
        processingAssets.splice(processingAssetIndex, 1);
      }
      // Anyway, add the asset to the charter assets (if another client uploaded an asset, etc.)
      const charterAssets = CharterUtils.getCharterAssetsByType(assetType, currentCharter) || [];
      const charterAssetNameByType = CharterUtils.getCharterAssetsNameByType(assetType);
      if (charterAssetNameByType) {
        charterAssets.unshift(asset);
        currentCharter[charterAssetNameByType] = charterAssets;
      }
    }

    draftState.current = currentCharter;
    draftState.processingAssets[assetType] = processingAssets;
  },
  [CHARTERS_ACTION_TYPE.SET_FILE_PROCESSING_IN_ERROR]: (draftState, action) => {
    const { asset, assetType, errorCode } = action.payload;
    log.info('File asset processing error:', asset);

    const processingAssets = CharterUtils.getUploadFilesByType(assetType, draftState.processingAssets);

    const processingAssetIndex = findIndex(processingAssets, (file: UploadFile) => {
      return file.response.data.operationId === asset.operationId;
    });
    if (processingAssetIndex >= 0) {
      // Update the processingAsset by setting it is in error
      processingAssets[processingAssetIndex].response.data.error = errorCode;
    }

    draftState.processingAssets[assetType] = processingAssets;
  },
  [CHARTERS_ACTION_TYPE.UPDATE_ASSET_PROCESSING_PROGRESSION]: (draftState, action) => {
    const { operationId, progress, assetType } = action.payload;
    const { processingAssets } = draftState;
    const processingAssetsOfType = [...CharterUtils.getUploadFilesByType(assetType, processingAssets)];
    const processingAssetIndex = findIndex(processingAssetsOfType, (file: UploadFile) => {
      return file.response.data.operationId === operationId;
    });
    if (processingAssetIndex >= 0) {
      processingAssetsOfType[processingAssetIndex].response.data.progress = progress;
    }

    draftState.processingAssets[assetType] = processingAssetsOfType;
  },
  [CHARTERS_ACTION_TYPE.UPDATE_ASSET]: (draftState, action) => {
    const { asset, assetType } = action.payload;
    log.info('File asset has been updated:', asset);

    const currentCharter = cloneDeep(draftState.current);

    if (currentCharter) {
      const charterAssets = CharterUtils.getCharterAssetsByType(assetType, currentCharter) || [];
      const charterAssetNameByType = CharterUtils.getCharterAssetsNameByType(assetType);
      if (charterAssetNameByType) {
        const updatedAssetIndex = charterAssets.findIndex((charterAsset) => asset.id === charterAsset.id);
        if (updatedAssetIndex >= 0) {
          charterAssets[updatedAssetIndex] = asset;
          currentCharter[charterAssetNameByType] = charterAssets;
        }
      }
    }

    draftState.current = currentCharter;
  },
  [CHARTERS_ACTION_TYPE.SAVE_CHARTER_MEDIA]: (draftState, action) => {
    draftState.currentCharterMedia = action.payload;
  },
  [CHARTERS_ACTION_TYPE.MEDIA_FILE_UPLOADED_PROCESSING]: (draftState, action) => {
    log.info('Uploaded media file:', action.payload);

    const { file, mediaType } = action.payload;

    const processingMedia = draftState.processingMedia || [];
    const processingMediaOfType = [
      ...MediaUtils.getProcessingMediaOfTypeAfterMediaUploadedProcessing(processingMedia, mediaType, file),
    ];

    draftState.processingMedia[mediaType] = processingMediaOfType;
  },

  [CHARTERS_ACTION_TYPE.UPDATE_PROCESSING_MEDIA_OPERATION_ID]: (draftState, action) => {
    const { oldOperationId, newOperationId, mediaType } = action.payload;
    log.info('Update media processing operation id for retry:', action.payload);

    const processingMediaFromState = draftState.processingMedia || [];
    const processingMedia = processingMediaFromState[mediaType];
    const processingMediaIndex = MediaUtils.findProcessingMediaIndexByOperationId(processingMedia, oldOperationId);

    if (processingMediaIndex >= 0) {
      processingMedia[processingMediaIndex].response.data.operationId = newOperationId;
    }

    draftState.processingMedia[mediaType] = processingMedia;
  },
  [CHARTERS_ACTION_TYPE.REFRESH_PROCESSING_MEDIA_BY_MEDIA_TYPE]: (draftState, action) => {
    log.info('Refresh processing media by media type:', action.payload);

    const { mediaList, mediaType } = action.payload;
    const processingMediaFromState = draftState.processingMedia || [];
    const processingMedia = processingMediaFromState[mediaType];
    const refreshedProcessingMediaOfType = MediaUtils.removeProcessedMediaFromProcessingList(
      processingMedia,
      mediaList
    );

    draftState.processingMedia[mediaType] = refreshedProcessingMediaOfType;
  },
  [CHARTERS_ACTION_TYPE.MEDIA_FILE_UPLOADED_PROCESSED]: (draftState, action) => {
    const { media, mediaType } = action.payload;
    log.info('Media file successfully processed:', media);

    const processingMediaFromState = draftState.processingMedia || [];
    const processingMedia = processingMediaFromState[mediaType];

    const processingMediaIndex = MediaUtils.findProcessingMediaIndexByOperationId(processingMedia, media.operationId);
    if (processingMediaIndex >= 0) {
      // Remove the media from the processing media (only if present)
      processingMedia.splice(processingMediaIndex, 1);
    }

    // Anyway, add the media to the charter media (if another client uploaded a media, etc.)
    const charterMedia = draftState.currentCharterMedia ?? [];
    charterMedia.unshift(media);

    draftState.processingMedia[mediaType] = processingMedia;
    draftState.currentCharterMedia = charterMedia;
  },
  [CHARTERS_ACTION_TYPE.UPDATE_MEDIA]: (draftState, action) => {
    const media = action.payload;
    log.info('Media file updated:', media);

    // Update the media
    const charterMedia = draftState.currentCharterMedia ?? [];
    const updatedMediaIndex = charterMedia.findIndex((m) => m.id === media.id);
    if (updatedMediaIndex >= 0) {
      charterMedia[updatedMediaIndex] = media;
    }

    draftState.currentCharterMedia = charterMedia;
  },
  [CHARTERS_ACTION_TYPE.SET_MEDIA_FILE_PROCESSING_IN_ERROR]: (draftState, action) => {
    const { media, mediaType, errorCode } = action.payload;
    log.info('Media file processing error:', media);

    const processingMediaFromState = draftState.processingMedia || [];
    const processingMedia = processingMediaFromState[mediaType];

    const processingMediaIndex = MediaUtils.findProcessingMediaIndexByOperationId(processingMedia, media.operationId);
    if (processingMediaIndex >= 0) {
      // Update the processingMedia by setting it is in error
      processingMedia[processingMediaIndex].response.data.error = errorCode;
    }

    draftState.processingMedia[mediaType] = processingMedia;
  },
  [CHARTERS_ACTION_TYPE.UPDATE_MEDIA_PROCESSING_PROGRESSION]: (draftState, action) => {
    const { operationId, progress, mediaType } = action.payload;
    const processingMediaFromState = draftState.processingMedia || [];
    const processingMedia = [...processingMediaFromState[mediaType]];
    const processingMediaIndex = MediaUtils.findProcessingMediaIndexByOperationId(processingMedia, operationId);
    if (processingMediaIndex >= 0) {
      processingMedia[processingMediaIndex].response.data.progress = progress;
    }

    draftState.processingMedia[mediaType] = processingMedia;
  },
  [CHARTERS_ACTION_TYPE.SAVE_CHARTER_SCENARIOS]: (draftState, action) => {
    draftState.currentCharterScenarios = action.payload;
  },
  [CHARTERS_ACTION_TYPE.UPDATE_CHARTER_SCENARIO]: (draftState, action) => {
    const updatedScenario = action.payload;
    const newScenarios = draftState.currentCharterScenarios || [];

    const scenarioIndex = findIndex(newScenarios, (scenario) => scenario.id === updatedScenario.id);
    if (scenarioIndex >= 0) {
      newScenarios[scenarioIndex] = updatedScenario;
    } else {
      newScenarios.unshift(updatedScenario);
    }

    draftState.currentCharterScenarios = newScenarios;
  },
  [CHARTERS_ACTION_TYPE.REMOVE_CHARTER_SCENARIO]: (draftState, action) => {
    const deletedScenarioId = action.payload;
    const newScenarios = draftState.currentCharterScenarios || [];

    const scenarioIndex = findIndex(newScenarios, (scenario) => scenario.id === deletedScenarioId);
    if (scenarioIndex >= 0) {
      newScenarios.splice(scenarioIndex, 1);
    }

    draftState.currentCharterScenarios = newScenarios;
  },
  [CHARTERS_ACTION_TYPE.UPDATE_CHARTER_SCENARIO_VARIANT]: (draftState, action) => {
    const { scenarioId, scenarioVariant } = action.payload;
    const newScenarios = draftState.currentCharterScenarios || [];

    // Find the scenario
    const scenarioIndex = findIndex(newScenarios, (scenario) => scenario.id === scenarioId);
    if (scenarioIndex >= 0) {
      // Find the variant
      const variantIndex = findIndex(
        newScenarios[scenarioIndex].variants,
        (variant) => variant.id === scenarioVariant.id
      );
      // If the variant already existed, we update it
      if (variantIndex >= 0) {
        newScenarios[scenarioIndex].variants[variantIndex] = scenarioVariant;
      } else {
        // Else we unshift it
        newScenarios[scenarioIndex].variants.unshift(scenarioVariant);
      }

      draftState.currentCharterScenarios = newScenarios;
    }
  },
  [CHARTERS_ACTION_TYPE.REMOVE_CHARTER_SCENARIO_VARIANT]: (draftState, action) => {
    const { scenarioId, scenarioVariantId } = action.payload;
    const newScenarios = draftState.currentCharterScenarios || [];

    // Find the scenario
    const scenarioIndex = findIndex(newScenarios, (scenario) => scenario.id === scenarioId);
    if (scenarioIndex >= 0) {
      // Find the variant
      const variantIndex = findIndex(
        newScenarios[scenarioIndex].variants,
        (variant) => variant.id === scenarioVariantId
      );
      // If the variant already existed, we remove it
      if (variantIndex >= 0) {
        newScenarios[scenarioIndex].variants.splice(variantIndex, 1);
      }

      draftState.currentCharterScenarios = newScenarios;
    }
  },
  [CHARTERS_ACTION_TYPE.UPDATE_CHARTER_SCENARIO_VARIANT_METADATA]: (draftState, action) => {
    const { scenarioId, scenarioVariantId, title, description } = action.payload;
    const newScenarios = draftState.currentCharterScenarios || [];

    // Find the scenario
    const scenarioIndex = findIndex(newScenarios, (scenario) => scenario.id === scenarioId);
    if (scenarioIndex >= 0) {
      // Find the variant
      const variantIndex = findIndex(
        newScenarios[scenarioIndex].variants,
        (variant) => variant.id === scenarioVariantId
      );
      // If the variant already existed, we update it
      if (variantIndex >= 0) {
        newScenarios[scenarioIndex].variants[variantIndex].title = title;
        newScenarios[scenarioIndex].variants[variantIndex].description = description;
      }
    }

    draftState.currentCharterScenarios = newScenarios;
  },
  [COMPANIES_ACTION_TYPE.DELETE_CHARTER]: (draftState, action) => {
    const { charterId } = action.payload;
    log.info('Remove charter from list: ', charterId);

    if (draftState.list) {
      const list = [...draftState.list];
      const charterIndexToDelete = findIndex(list, { id: charterId });

      list.splice(charterIndexToDelete, 1);

      draftState.list = [...list];
    }
  },
  [CHARTERS_ACTION_TYPE.SAVE_CHARTER_PROJECTS]: (draftState, action) => {
    draftState.currentCharterProjects = action.payload;
  },
  [CHARTERS_ACTION_TYPE.ADD_CHARTER_PROJECT]: (draftState, action) => {
    const addedProject = action.payload;
    const newProjects = draftState.currentCharterProjects ?? [];
    newProjects.unshift(addedProject);
    draftState.currentCharterProjects = newProjects;
  },
  [CHARTERS_ACTION_TYPE.UPDATE_CHARTER_PROJECT]: (draftState, action) => {
    const updatedProject = action.payload;
    const newProjects = draftState.currentCharterProjects ?? [];
    const projectToUpdateIndex = newProjects.findIndex((p) => p.id === updatedProject.id);
    if (projectToUpdateIndex >= 0) {
      newProjects[projectToUpdateIndex] = updatedProject;
    }
    draftState.currentCharterProjects = newProjects;
  },
  [CHARTERS_ACTION_TYPE.DELETE_CHARTER_PROJECT]: (draftState, action) => {
    const deletedProjectId = action.payload;
    const newProjects = draftState.currentCharterProjects ?? [];
    remove(newProjects, (project) => project.id === deletedProjectId);
    draftState.currentCharterProjects = newProjects;
  },
});
