import { Col, Modal, Row, Spin } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';

type Props = {
  visible: boolean;
};

const useStyles = createUseStyles({
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
  },
});

const LoadingModal: FunctionComponent<Props> = (props: Props) => {
  const { visible } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Modal visible={visible} footer={null} closable={false}>
      <Row>
        <Col span={4}>
          <Spin className={classes.loader} size="large" />
        </Col>
        <Col span={18}>{t('loadingModal.text')}</Col>
      </Row>
    </Modal>
  );
};

export default LoadingModal;
