import i18n from 'i18next';
import log from 'loglevel';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import KannelleLoader from '../../components/loader/KannelleLoader';
import { CHARTER_ACTIVE, LINK } from '../../Constants';
import useCharterGlobalSocket from '../../hooks/useCharterGlobalSocket';
import useCompanyGlobalSocket from '../../hooks/useCompanyGlobalSocket';
import Company from '../../model/Company';
import { setAPIToken, setIsUserUnauthorized } from '../../redux/action/AppAction';
import { setChartersList } from '../../redux/action/ChartersAction';
import { initializeCompanies, selectCompany, setCompanyBundle } from '../../redux/action/CompaniesAction';
import { initializeUser } from '../../redux/action/UserAction';
import { RootState } from '../../redux/RootState';
import { APIManager } from '../../services/api/APIManager';
import { getCompaniesByUser, getCompanyBundleById } from '../../services/api/CompaniesService';
import { getUserCompanies, getUserMe } from '../../services/api/UsersService';
import AppsUtils from '../../utils/AppsUtils';
import BundlesUtils from '../../utils/BundlesUtils';
import CharterUtils from '../../utils/CharterUtils';
import CompaniesUtils from '../../utils/CompaniesUtils';
import SecurityUtils from '../../utils/SecurityUtils';
import { useAuth0 } from './Auth0Config';

type Props = {
  children: React.ReactNode;
};

const AuthBarrier: FunctionComponent<Props> = ({ children }: Props) => {
  const dispatch = useDispatch();
  const { loading, isAuthenticated, loginWithRedirect, user, getTokenSilently } = useAuth0();
  const history = useHistory();
  const [currentCompanyFromState] = useSelector((state: RootState) => {
    return [state.companies.current];
  });

  const [wasAccessTokenSet, setWasAccessTokenSet] = useState(false);
  const [auth0Token, setAuth0Token] = useState<string>();
  const [initializedSession, setInitializedSession] = useState(false);
  const [lastFocusTimestamp, setLastFocusTimestamp] = useState<number>();

  const lang = i18n.language?.split('-')[0] || 'en';
  const auth0UserIdPrefix = process.env.REACT_APP_AUTH0_USER_ID_PREFIX ?? '';

  useCompanyGlobalSocket();
  useCharterGlobalSocket();

  // Redirect the user to the authentication form if needed
  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const authenticate = async (): Promise<void> => {
      await loginWithRedirect({
        appState: { targetUrl: window.location.pathname },
      });
    };
    authenticate();
  }, [loading, isAuthenticated, loginWithRedirect]);

  useEffect(() => {
    const onFocus = () => {
      if (!lastFocusTimestamp) {
        setLastFocusTimestamp(moment().valueOf());
      }
    };

    // When the window is focused, we update the check timestamp so that we may recheck the token
    window.addEventListener('focus', onFocus);

    return () => window.removeEventListener('focus', onFocus);
  }, []);

  const redirectUserToLoginPage = () => {
    loginWithRedirect({
      appState: { targetUrl: window.location.pathname },
    });
  };

  useEffect(() => {
    if (!wasAccessTokenSet || !auth0Token) {
      return undefined;
    }

    const parsedToken = AppsUtils.parseJwt(auth0Token);
    const parsedTokenInMillis = parsedToken.exp * 1000;
    const nowTs = moment().valueOf();
    const timer = parsedTokenInMillis - nowTs;

    if (timer <= 0) {
      redirectUserToLoginPage();
    }

    const timeout = setTimeout(() => {
      // This should redirect the user to the login page if the token is expired
      if (nowTs >= parsedTokenInMillis) {
        redirectUserToLoginPage();
      }
    }, timer);

    return () => {
      clearTimeout(timeout);
    };
  }, [wasAccessTokenSet, lastFocusTimestamp, auth0Token]);

  // Set the API access token that we got from Auth0's authentication
  useEffect(() => {
    const setupAPIAccessToken = async (): Promise<void> => {
      if (isAuthenticated) {
        const token = await getTokenSilently();
        APIManager.setAccessToken(token);
        dispatch(setAPIToken(token));
        setAuth0Token(token);
        setWasAccessTokenSet(true);
        log.info('Access token was correctly set.');
      } else {
        APIManager.clearAccessToken();
        setWasAccessTokenSet(false);
      }
    };
    setupAPIAccessToken();
  }, [isAuthenticated, getTokenSilently, dispatch]);

  useEffect(() => {
    if (!isAuthenticated || !user || !wasAccessTokenSet) {
      return;
    }

    const userId = user?.sub.replace(auth0UserIdPrefix, '');
    Promise.all([
      getCompaniesByUser(userId, CHARTER_ACTIVE.ACTIVE, undefined, { lang }),
      getUserMe(),
      getUserCompanies(userId, true),
    ])
      .then(([companiesResponse, userResponse, userCompaniesContactResponse]) => {
        const pocCompanies = userCompaniesContactResponse.data.items;
        const isKnlTeam = CompaniesUtils.checkIsKnlProfileFromApi(companiesResponse.data.items);
        const activeCompanies: Company[] = CompaniesUtils.getValidCompany(
          companiesResponse.data.items,
          isKnlTeam,
          pocCompanies
        );

        const userData =
          userResponse.data && userResponse.data.id ? userResponse.data : userCompaniesContactResponse.data.user;
        const identifySkalinUser = (userEmail: string) => {
          const { ska } = window;
          ska((skalin: any) => {
            skalin.identity({ email: userEmail });
          });
        };
        identifySkalinUser(userData.email);
        // TODO this is to be changed in a near future
        if (currentCompanyFromState?.created) {
          activeCompanies.push(currentCompanyFromState);
          dispatch(initializeCompanies(activeCompanies));
          dispatch(setChartersList([]));
          dispatch(initializeUser(userData));
          setInitializedSession(true);
          return;
        }

        dispatch(initializeCompanies(activeCompanies));

        if (
          !(SecurityUtils.isPlatformAccessible(activeCompanies) && CompaniesUtils.hasAccessibleCompany(activeCompanies))
        ) {
          dispatch(setIsUserUnauthorized(true));
          history.push(LINK.UNAUTHORIZED.path);
          return;
        }

        const currentCompany = CompaniesUtils.getCurrentCompany(activeCompanies, currentCompanyFromState);
        const charters = currentCompany?.getCompanyAllowedCharters();

        const firstAccessibleCompany = CompaniesUtils.getFirstAccessibleCompany(activeCompanies);
        const isPreviousConnectedCompanyAccessible = CompaniesUtils.isCompanyAccessible(currentCompany);

        getCompanyBundleById(currentCompany.id).then((bundleResponse) => {
          // we need to reselect the company to update the config (that might have been updated)
          if (firstAccessibleCompany && !isPreviousConnectedCompanyAccessible) {
            dispatch(selectCompany(firstAccessibleCompany));
          } else {
            dispatch(selectCompany(currentCompany));
          }
          dispatch(setChartersList(charters));
          dispatch(
            setCompanyBundle(
              bundleResponse.data ? BundlesUtils.formatApiBundlesToStore(bundleResponse.data) : undefined
            )
          );
          dispatch(initializeUser(userData));
          dispatch(setIsUserUnauthorized(false));
          setInitializedSession(true);
        });

        if (currentCompany.id !== currentCompanyFromState?.id) {
          history.push(LINK.HOME.path);
        }
      })
      .catch((error) => {
        log.error(error);
        dispatch(setIsUserUnauthorized(true));
        history.push(LINK.UNAUTHORIZED.path);
      });
    // eslint-disable-next-line
  }, [user, isAuthenticated, wasAccessTokenSet, dispatch, history, i18n.language]);

  useEffect(() => {
    if (!isAuthenticated || !user || !wasAccessTokenSet) {
      return;
    }

    const userId = user?.sub.replace(auth0UserIdPrefix, '');

    Promise.all([
      getCompaniesByUser(userId, CHARTER_ACTIVE.ACTIVE, undefined, { lang }),
      getUserCompanies(userId, true),
    ]).then(([companiesResponse, userCompaniesContactResponse]) => {
      const pocCompanies = userCompaniesContactResponse.data.items;
      const isKnlTeam = CompaniesUtils.checkIsKnlProfileFromApi(companiesResponse.data.items);
      const activeCompanies: Company[] = CompaniesUtils.getValidCompany(
        companiesResponse.data.items,
        isKnlTeam,
        pocCompanies
      );

      const currentCompany = CompaniesUtils.getCurrentCompany(activeCompanies, currentCompanyFromState);
      const charters = currentCompany?.getCompanyAllowedCharters();

      if (currentCompany.isUserPoc) {
        return;
      }

      if (!CharterUtils.hasAccessibleCharter(charters)) {
        dispatch(setIsUserUnauthorized(true));
        history.push(LINK.UNAUTHORIZED.path);
      }
    });
  }, [currentCompanyFromState]);

  if (loading || !wasAccessTokenSet || !initializedSession) {
    return <KannelleLoader />;
  }

  if (!(isAuthenticated && user)) {
    return null;
  }

  return <>{children}</>;
};

export default AuthBarrier;
