import { Button, Col, Form, Row, Select, Typography } from 'antd';
import classNames from 'classnames';
import { find, map } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import EmptyPageContent from '../../../../components/empty/EmptyPageContent';
import { DEVICE_SIZES_QUERIES } from '../../../../Constants';
import { RootState } from '../../../../redux/RootState';
import CompaniesUtils from '../../../../utils/CompaniesUtils';
import LottieUtils from '../../../../utils/LottieUtils';
import ThemeUtils, { Theme, ThemeColorKey } from '../../../../utils/ThemeUtils';
import { AnimationTheme, LottieObject } from '../../../../utils/types/ThemeTypes';
import CharterThemeColorsEditionForm from './CharterThemeColorsEditionForm';
import LottieDisplay from './LottieDisplay';

const { Option } = Select;

type Props = {
  code: string;
  charterThemeColors: Theme[];
  animationThemeColor: Theme;
  lottieArray: LottieObject[];
  onEditColor: (newColors: Theme[]) => void;
  onReset: () => void;
  onSubmit: () => void;
  disabledActions?: boolean;
  loadingActions?: boolean;
};

const useStyles = createUseStyles({
  lottieAnimationsColumn: {
    padding: 16,
  },
  filterContainer: {
    padding: 8,
    borderBottom: 'solid 1px #eeeeee',
  },
  filter: {
    width: '100%',
  },
  themeEditionContainer: {
    height: '100%',
    borderRight: 'solid 1px #cccccc',
  },
  lottieAnimationContainer: {
    padding: '0 8px',
  },
  lottieArrayContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  actionsContainer: {
    marginTop: 16,
    display: 'flex',
    paddingRight: 10,
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: 8,
    },
  },
  mobileActionsContainer: {
    [`@media screen and ${DEVICE_SIZES_QUERIES.EXTRA_LARGE}`]: {
      flexDirection: 'column',
      '& button': {
        margin: '10px',
      },
    },
  },
});

const CharterAnimationThemeEdition: FunctionComponent<Props> = ({
  charterThemeColors,
  animationThemeColor,
  onEditColor,
  lottieArray,
  code,
  onReset,
  onSubmit,
  disabledActions = false,
  loadingActions = false,
}: Props) => {
  const classes = useStyles();
  const themeFields = find(ThemeUtils.getThemes(), { code });
  const { t } = useTranslation();
  const [lottiePlaying, setLottiePlaying] = useState<string>();
  const [updatedAnimations, setUpdatedAnimations] = useState<string[]>();
  const [filteredThemeFields, setFilteredThemeFields] = useState<ThemeColorKey | undefined>(themeFields);
  const charter = useSelector((state: RootState) => state.charters.current);
  const companies = useSelector((state: RootState) => state.companies.list);
  const isKnlTeam = companies ? CompaniesUtils.checkIsKnlProfile(companies) : false;

  if (!(charter && charterThemeColors) || !filteredThemeFields || !themeFields) {
    return <EmptyPageContent />;
  }

  const onThemeColorKeyValueChange = (themeCode: string, keyCode: string, newValue: string): void => {
    const newThemeColors = [...charterThemeColors];
    const themeColorIndex = newThemeColors.findIndex((th) => th.code === themeCode);
    if (themeColorIndex >= 0) {
      newThemeColors[themeColorIndex] = {
        ...newThemeColors[themeColorIndex],
        colors: {
          ...newThemeColors[themeColorIndex].colors,
          [keyCode]: newValue,
        },
      };

      onEditColor(newThemeColors);

      // Find the animations that are impacted by the changed value
      const animationsImpactedByField = lottieArray
        .filter((lottieObject) => lottieObject.fields.includes(keyCode))
        .map((lottieObject) => lottieObject.key);
      setUpdatedAnimations(animationsImpactedByField);
    }
  };

  const onResetTheme = (): void => {
    onReset();
  };

  const handlePlayPause = (itemKey: string, isPaused: boolean): void => {
    if (isPaused) {
      setLottiePlaying(undefined);
    } else {
      setLottiePlaying(itemKey);
    }
  };

  const renderLottieArray = (lottieArrayToRender: LottieObject[]): JSX.Element[] => {
    return map(lottieArrayToRender, (lottieObject: LottieObject) => {
      const key = `${themeFields.code}-${lottieObject.key}`;
      const layout = { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 };

      return (
        <Col {...layout} key={key}>
          <LottieDisplay
            theme={code as AnimationTheme}
            updatedAnimations={updatedAnimations}
            callBackPlayPause={handlePlayPause}
            lottieObject={lottieObject}
            isPlaying={lottiePlaying === key}
            displayAnimationAliases={isKnlTeam}
            itemKey={key}
            themeColors={animationThemeColor.colors}
          />
        </Col>
      );
    });
  };

  const renderLottieGrid = (): JSX.Element => {
    return (
      <Row gutter={[8, 8]}>
        <Row className={classNames(classes.lottieArrayContainer, 'lottieArrayContainer')} gutter={[16, 16]}>
          {renderLottieArray(lottieArray)}
        </Row>
      </Row>
    );
  };

  const renderActionButtons = (): JSX.Element => {
    const actionButtonsLayout = {
      wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
    };

    return (
      <Form.Item {...actionButtonsLayout}>
        <span className={classNames(classes.actionsContainer, classes.mobileActionsContainer)}>
          <Button onClick={onResetTheme} disabled={disabledActions}>
            {t('global.reset')}
          </Button>
          <Button type="primary" onClick={onSubmit} loading={loadingActions} disabled={disabledActions}>
            {t('global.save')}
          </Button>
        </span>
      </Form.Item>
    );
  };

  const handleChangeFilter = (value: string[]): void => {
    if (value.length === 0) {
      setFilteredThemeFields(themeFields);
      return;
    }

    const newFilteredThemeFields = LottieUtils.filterThemeFieldByAnimations(value, lottieArray, themeFields);
    setFilteredThemeFields(newFilteredThemeFields);
  };

  return (
    <Row>
      <Col xs={12} sm={12} md={8} lg={8} xl={8}>
        <div className={classNames(classes.themeEditionContainer, 'themeEditionContainer')}>
          <div className={classNames(classes.filterContainer, 'filterContainer')}>
            <Typography>{t('charters.themes.filter')}</Typography>
            <Select
              mode="multiple"
              className={classes.filter}
              placeholder={t('charters.themes.filterPlaceholder')}
              allowClear
              onChange={handleChangeFilter}
            >
              {map(lottieArray, (lottieObject: LottieObject) => {
                return (
                  <Option key={lottieObject.key} value={lottieObject.key}>
                    {t(lottieObject.key)}
                  </Option>
                );
              })}
            </Select>
          </div>

          <CharterThemeColorsEditionForm
            themeFields={filteredThemeFields}
            charterThemeColors={charterThemeColors}
            onThemeColorKeyValueChange={onThemeColorKeyValueChange}
          />
          {renderActionButtons()}
        </div>
      </Col>
      <Col xs={12} sm={12} md={16} lg={16} xl={16} className={classes.lottieAnimationsColumn}>
        {renderLottieGrid()}
      </Col>
    </Row>
  );
};

export default CharterAnimationThemeEdition;
