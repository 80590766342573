/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloudDownloadOutlined,
  CloudSyncOutlined,
  ReconciliationOutlined,
} from '@ant-design/icons';
import React from 'react';
import Format16_9 from '../../resources/img/formats/format_16_9.png';
import Format1_1 from '../../resources/img/formats/format_1_1.png';
import Format9_16 from '../../resources/img/formats/format_9_16.png';
import AerialCover from '../../resources/img/musics/aerial.png';
import CharterCover from '../../resources/img/musics/charter-musics.png';
import CinematicCover from '../../resources/img/musics/cinematic.png';
import DynamicCover from '../../resources/img/musics/dynamic.png';
import JoyfulCover from '../../resources/img/musics/joyful.png';
import MysteriousCover from '../../resources/img/musics/mysterious.png';
import StrongCover from '../../resources/img/musics/strong.png';
import AlgiersCover from '../../resources/img/themes/algiers.png';
import BaliCover from '../../resources/img/themes/bali.png';
import BerlinCover from '../../resources/img/themes/berlin.png';
import BogotaCover from '../../resources/img/themes/bogota.png';
import GenevaCover from '../../resources/img/themes/geneva.png';
import LosAngelesCover from '../../resources/img/themes/los_angeles.png';
import SeattleCover from '../../resources/img/themes/seattle.png';
import { Asset, MediaFile } from '../../services/api/types/ChartersServiceTypes';
import { Music } from '../../services/api/types/MusicsServiceTypes';
import { APIModelUser } from '../../services/api/types/UsersServiceTypes';
import {
  AnimationFormats,
  LogoDisplayMode,
  LogoPosition,
  LogoSize,
  MaskCodes,
  PipPositionCode,
} from './AnimationTypes';
import { AnimationTheme } from './ThemeTypes';

export type Project = {
  id: number;
  projectUuid: string;
  title: string;
  creationType: ProjectCreationType;
  customColors: boolean;
  videoFormat: AnimationFormats;
  dismissedFeatures: Array<string>;
  sceneIndexesOrder: Array<number>;
  audioVolume: number;
  audioProcessingEnabled?: boolean;
  duration?: number;
  mask?: MaskCodes;
  updatedAt: string;
  createdAt: string;
  lastSyncAt: string;
  lastFinalizedAt?: string;
  subtitlesLanguage?: string;
  finalVideo?: ProjectFinalVideo;
  totalMedia?: { processed?: number; all?: number };
  author?: APIModelUser;
  status: string; // ProjectStatus (code)
  scenes?: ProjectScene[];
  theme: AnimationTheme; // ProjectTheme (code)
  logo?: ProjectLogo;
  intro?: Asset;
  outro?: Asset;
  music?: ProjectMusic;
};

export enum ProjectCreationType {
  EMPTY = 'EMPTY',
  MEDIA = 'MEDIA',
  SCENARIO = 'SCENARIO',
}

export type ProjectMusicFile = Asset | Music;

export enum ProjectMusicDuckingVolume {
  NONE = 0,
  LOW = -9,
  MEDIUM = -12,
  HIGH = -15,
}

export const ProjectMusicDuckingVolumes: ProjectMusicDuckingVolumeOptions = {
  LOW: {
    value: ProjectMusicDuckingVolume.LOW,
    key: 'charters.projects.projectEditor.projectFinalization.volume.duckingVolume.low',
  },
  MEDIUM: {
    value: ProjectMusicDuckingVolume.MEDIUM,
    key: 'charters.projects.projectEditor.projectFinalization.volume.duckingVolume.medium',
  },
  HIGH: {
    value: ProjectMusicDuckingVolume.HIGH,
    key: 'charters.projects.projectEditor.projectFinalization.volume.duckingVolume.high',
  },
};
export type ProjectMusicDuckingVolumeOptions = Record<string, ProjectMusicDuckingVolumeOption>;
export type ProjectMusicDuckingVolumeOption = { value: ProjectMusicDuckingVolume; key: string };

export enum ProjectMusicFadeOutDuration {
  NONE = 0,
  SLOW = 3000,
  MEDIUM = 2000,
  FAST = 1000,
}

export const ProjectMusicFadeOutDurations: ProjectMusicFadeOutDurationOptions = {
  SLOW: {
    value: ProjectMusicFadeOutDuration.SLOW,
    key: 'charters.projects.projectEditor.projectFinalization.volume.fadeOutDuration.slow',
  },
  MEDIUM: {
    value: ProjectMusicFadeOutDuration.MEDIUM,
    key: 'charters.projects.projectEditor.projectFinalization.volume.fadeOutDuration.medium',
  },
  FAST: {
    value: ProjectMusicFadeOutDuration.FAST,
    key: 'charters.projects.projectEditor.projectFinalization.volume.fadeOutDuration.fast',
  },
};
export type ProjectMusicFadeOutDurationOptions = Record<string, ProjectMusicFadeOutDurationOption>;
export type ProjectMusicFadeOutDurationOption = { value: ProjectMusicFadeOutDuration; key: string };

export type ProjectMusic = {
  file: ProjectMusicFile;
  volume?: number;
  duckingEnabled?: boolean;
  duckingVolume?: ProjectMusicDuckingVolume;
  fadeOutEnabled?: boolean;
  fadeOutDuration?: ProjectMusicFadeOutDuration;
};

export type ProjectLogo = {
  logoAsset: Asset;
  positionCode?: ProjectLogoPosition;
  size?: ProjectLogoSize;
  displayMode?: ProjectLogoDisplayMode;
};

export type ProjectFinalVideo = MediaFile & {
  publicAccessExpiresAt?: string;
  publicAccessCode?: string;
  publicAccessUrl?: string;
};

export type ProjectScene = {
  id: number;
  index: number;
  title?: string;
  isActive: boolean;
  kind: string; // ProjectSceneKind (code)
  description?: string;
  videoRecommendedTime?: number;
  videoMinRecordTime?: number;
  videoMaxRecordTime?: number;
  cameraZoom?: number;
  createdAt?: Date;
  updatedAt?: Date;
  backgroundVideo?: ProjectSceneMedia;
  pip?: ProjectSceneMedia;
  animation?: ProjectSceneAnimation;
  overlay?: ProjectSceneOverlay;
  subtitles?: Subtitles;
  // Front-end only properties
  isSceneDuplicateTemporary?: boolean;
};

export enum SubtitleBlockMode {
  MANUAL = 'MANUAL',
  AUTO = 'AUTO',
}

export type Subtitles = { language: string; blocks: SubtitleBlock[] };

export type SubtitleBlock = {
  id: number;
  createdAt: string;
  startAt: number;
  endAt: number;
  mode: SubtitleBlockMode;
  text: string;
  updatedAt: string;
};

export type ProjectSceneMedia = {
  media: MediaFile;
  audioVolume?: number;
  duration?: number;
  effectName?: string;
  effectValue?: number;
  createdAt?: string;
  updatedAt?: string;
  delay?: number;
  trimStartAt?: number;
  trimEndAt?: number;
  positionCode?: PipPositionCode;
  positionValue?: number;
  size?: number;
};

export type ProjectSceneAnimation = ProjectSceneAnimationLayout & {
  active?: boolean;
  delay?: number;
  duration?: number;
};

export type ProjectSceneAnimationLayout = {
  code: string;
  animationTexts: ProjectSceneAnimationText[];
  position: string;
  offsetX?: number;
  offsetY?: number;
};

export type ProjectSceneOverlay = {
  id: number;
  phoneName: string;
  phoneUrl: string;
  tabletName: string;
  tabletUrl: string;
  updatedAt: string;
  createdAt: string;
};

export type ProjectSceneAnimationText = {
  placeholderText?: string;
  demoText: string;
  text: string;
};

export const ProjectFormats: ProjectFormatsOptions = {
  LANDSCAPE_16_9: {
    code: AnimationFormats.FORMAT_16_9,
    key: 'charters.projects.formatOptions.16_9',
    image: Format16_9,
  },
  PORTRAIT_9_16: {
    code: AnimationFormats.FORMAT_9_16,
    key: 'charters.projects.formatOptions.9_16',
    image: Format9_16,
  },
  SQUARE_1_1: {
    code: AnimationFormats.FORMAT_1_1,
    key: 'charters.projects.formatOptions.1_1',
    image: Format1_1,
  },
};

export type ProjectFormatsOptions = Record<string, ProjectFormat>;
export type ProjectFormat = { code: string; key: string; image: string };

export const ProjectSceneKind: ProjectSceneKindOptions = {
  RECORDABLE: { code: 'RECORDABLE', key: 'charters.projects.projectEditor.sceneEditor.fields.kind.recordable' },
  SLIDE: { code: 'SLIDE', key: 'charters.projects.projectEditor.sceneEditor.fields.kind.slide' },
};

export type ProjectSceneKindOptions = Record<string, ProjectSceneKindOption>;
export type ProjectSceneKindOption = {
  code: string;
  key: string;
};

export enum ProjectSceneElementsCode {
  ANIMATION = 'ANIMATION',
  PIP = 'PIP',
  RECORDABLE_VIDEO = 'RECORDABLE_VIDEO',
  SUBTITLES = 'SUBTITLES',
}

export const ProjectSceneElements: ProjectSceneElementOptions = {
  ANIMATION: {
    code: ProjectSceneElementsCode.ANIMATION,
    titleKey: 'charters.projects.projectEditor.sceneEditor.options.animation',
  },
  RECORDABLE_VIDEO: {
    code: ProjectSceneElementsCode.RECORDABLE_VIDEO,
    titleKey: 'charters.projects.projectEditor.sceneEditor.options.video',
  },
  PIP: {
    code: ProjectSceneElementsCode.PIP,
    titleKey: 'charters.projects.projectEditor.sceneEditor.options.pip',
  },
  SUBTITLES: {
    code: ProjectSceneElementsCode.SUBTITLES,
    titleKey: 'charters.projects.projectEditor.sceneEditor.options.subtitles',
  },
};

export type ProjectSceneElementOptions = Record<string, ProjectSceneElement>;
export type ProjectSceneElement = { code: string; titleKey: string };

export const ProjectStatus: ProjectStatusOptions = {
  DRAFT: {
    code: 'DRAFT',
    key: 'charters.projects.statusOptions.draft',
    color: '#e0b000',
    icon: ReconciliationOutlined,
  },
  FINALIZED: {
    code: 'FINALIZED',
    key: 'charters.projects.statusOptions.finalized',
    color: '#04c52e',
    icon: CheckCircleOutlined,
  },
  BACKUPED: {
    code: 'BACKUPED',
    key: 'charters.projects.statusOptions.backuped',
    color: '#046bc5',
    icon: CloudDownloadOutlined,
  },
  PROCESSING: {
    code: 'PROCESSING',
    key: 'charters.projects.statusOptions.processing',
    color: '#a254ca',
    icon: CloudSyncOutlined,
  },
  ERROR: {
    code: 'ERROR',
    key: 'charters.projects.statusOptions.error',
    color: '#ea4242',
    icon: CloseCircleOutlined,
  },
};

export type ProjectStatusOptions = Record<string, ProjectStatus>;
export type ProjectStatus = { code: string; key: string; color: string; icon: React.ElementType };

export const ProjectTheme: ProjectThemeOptions = {
  BALI: {
    code: AnimationTheme.BALI,
    key: 'charters.projects.projectEditor.projectFinalization.graphicalTheme.themes.bali',
    cover: BaliCover,
  },
  BERLIN: {
    code: AnimationTheme.BERLIN,
    key: 'charters.projects.projectEditor.projectFinalization.graphicalTheme.themes.berlin',
    cover: BerlinCover,
  },
  GENEVA: {
    code: AnimationTheme.GENEVA,
    key: 'charters.projects.projectEditor.projectFinalization.graphicalTheme.themes.geneva',
    cover: GenevaCover,
  },
  ALGIERS: {
    code: AnimationTheme.ALGIERS,
    key: 'charters.projects.projectEditor.projectFinalization.graphicalTheme.themes.algiers',
    cover: AlgiersCover,
  },
  LOS_ANGELES: {
    code: AnimationTheme.LOS_ANGELES,
    key: 'charters.projects.projectEditor.projectFinalization.graphicalTheme.themes.losAngeles',
    cover: LosAngelesCover,
  },
  SEATTLE: {
    code: AnimationTheme.SEATTLE,
    key: 'charters.projects.projectEditor.projectFinalization.graphicalTheme.themes.seattle',
    cover: SeattleCover,
  },
  BOGOTA: {
    code: AnimationTheme.BOGOTA,
    key: 'charters.projects.projectEditor.projectFinalization.graphicalTheme.themes.bogota',
    cover: BogotaCover,
  },
};

export type ProjectThemeOptions = Record<string, ProjectThemeOption>;
export type ProjectThemeOption = {
  code: AnimationTheme;
  key: string;
  cover: string;
};

export const ProjectMask: ProjectMaskOptions = {
  NONE: {
    code: MaskCodes.NONE,
    key: 'charters.projects.projectEditor.projectFinalization.graphicalTheme.mask.none',
  },
  MINI: {
    code: MaskCodes.MINI,
    key: 'charters.projects.projectEditor.projectFinalization.graphicalTheme.mask.mini',
  },
};

export type ProjectMaskOptions = Record<MaskCodes, ProjectMaskOption>;
export type ProjectMaskOption = {
  code: string;
  key: string;
};

export const ProjectMusicCategories: ProjectMusicCategoryOptions = {
  AERIAL: {
    code: 'AERIAL',
    key: 'charters.projects.projectEditor.projectFinalization.music.categories.aerial',
    cover: AerialCover,
  },
  CINEMATIC: {
    code: 'CINEMATIC',
    key: 'charters.projects.projectEditor.projectFinalization.music.categories.cinematic',
    cover: CinematicCover,
  },
  DYNAMIC: {
    code: 'DYNAMIC',
    key: 'charters.projects.projectEditor.projectFinalization.music.categories.dynamic',
    cover: DynamicCover,
  },
  JOYFUL: {
    code: 'JOYFUL',
    key: 'charters.projects.projectEditor.projectFinalization.music.categories.joyful',
    cover: JoyfulCover,
  },
  MYSTERIOUS: {
    code: 'MYSTERIOUS',
    key: 'charters.projects.projectEditor.projectFinalization.music.categories.mysterious',
    cover: MysteriousCover,
  },
  STRONG: {
    code: 'STRONG',
    key: 'charters.projects.projectEditor.projectFinalization.music.categories.strong',
    cover: StrongCover,
  },
  CHARTER: {
    code: 'CHARTER',
    cover: CharterCover,
  },
};

export type ProjectMusicCategoryOptions = Record<string, ProjectMusicCategoryOption>;
export type ProjectMusicCategoryOption = {
  code: string;
  key?: string;
  cover: string;
};

// ProjectLogoPosition
export const ProjectLogoPosition = { ...LogoPosition };
export type ProjectLogoPosition = LogoPosition;

export enum AnchorType {
  START_ANCHOR = 'startAnchor',
  END_ANCHOR = 'endAnchor',
}

export const ProjectLogoPositions: ProjectLogoPositionOptions = {
  BOTTOM_LEFT: {
    code: ProjectLogoPosition.BOTTOM_LEFT,
    key: 'charters.projects.projectEditor.projectLogoPosition.bottomLeft',
  },
  BOTTOM_RIGHT: {
    code: ProjectLogoPosition.BOTTOM_RIGHT,
    key: 'charters.projects.projectEditor.projectLogoPosition.bottomRight',
  },
  TOP_LEFT: {
    code: ProjectLogoPosition.TOP_LEFT,
    key: 'charters.projects.projectEditor.projectLogoPosition.topLeft',
  },
  TOP_RIGHT: {
    code: ProjectLogoPosition.TOP_RIGHT,
    key: 'charters.projects.projectEditor.projectLogoPosition.topRight',
  },
};
export type ProjectLogoPositionOptions = Record<string, ProjectLogoPositionOption>;
export type ProjectLogoPositionOption = { code: ProjectLogoPosition; key: string };

// ProjectLogoSize
export const ProjectLogoSize = { ...LogoSize };
export type ProjectLogoSize = LogoSize;

export const ProjectLogoSizes: ProjectLogoSizeOptions = {
  XS: {
    code: ProjectLogoSize.XS,
    key: 'charters.projects.projectEditor.projectLogoSize.xs',
  },
  S: {
    code: ProjectLogoSize.S,
    key: 'charters.projects.projectEditor.projectLogoSize.s',
  },
  M: {
    code: ProjectLogoSize.M,
    key: 'charters.projects.projectEditor.projectLogoSize.m',
  },
  L: {
    code: ProjectLogoSize.L,
    key: 'charters.projects.projectEditor.projectLogoSize.l',
  },
  XL: {
    code: ProjectLogoSize.XL,
    key: 'charters.projects.projectEditor.projectLogoSize.xl',
  },
};
export type ProjectLogoSizeOptions = Record<string, ProjectLogoSizeOption>;
export type ProjectLogoSizeOption = { code: ProjectLogoSize; key: string };

export const ProjectLogoDisplayMode = { ...LogoDisplayMode };
export type ProjectLogoDisplayMode = LogoDisplayMode;

export type ProjectThemeParameters = {
  theme: AnimationTheme;
  mask?: MaskCodes;
  customColors: boolean;
};

export type ProjectVolumeParameters = {
  audioVolume: number;
  backgroundMusicVolume?: number;
  audioProcessingEnabled?: boolean;
  musicDuckingVolume?: ProjectMusicDuckingVolume;
  musicFadeOutDuration?: ProjectMusicFadeOutDuration;
};

export type PlayerSize = {
  height: number;
  width: number;
};

export type ProjectLogoStyle = {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  height: number;
};
