import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { WEB_SOCKET_ACTIONS } from '../Constants';
import Company from '../model/Company';
import { updateCompanyFromSocket } from '../redux/action/CompaniesAction';
import { RootState } from '../redux/RootState';
import { SocketManager } from '../services/api/SocketManager';
import { CompanyResponse } from '../services/api/types/WebSocketTypes';
import useSocket from './useSocket';

const useCompanyGlobalSocket = (): SocketManager | undefined => {
  const [rooms, setRooms] = useState<string>();
  const isSocketConnected = useSocket(rooms);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const currentCompany = useSelector((state: RootState) => state.companies.current);

  useEffect(() => {
    if (currentCompany) {
      setRooms(`company/${currentCompany.id}`);
    }
  }, [currentCompany]);

  useEffect(() => {
    if (!currentCompany) {
      return undefined;
    }

    const handleCompanyPatchMessage = (payload: CompanyResponse): void => {
      const companyPayloadData = payload.data;
      const { isUserPoc } = currentCompany;
      const company = new Company(companyPayloadData, isUserPoc);

      dispatch(updateCompanyFromSocket(company));
    };

    const handleMessage = (payload: CompanyResponse): void => {
      if (payload.action === WEB_SOCKET_ACTIONS.COMPANY.COMPANY_PATCH) {
        handleCompanyPatchMessage(payload);
      }
    };

    if (isSocketConnected) {
      SocketManager.onMessage(handleMessage);

      return (): void => SocketManager.offMessage(handleMessage);
    }

    return undefined;
  }, [SocketManager, currentCompany, dispatch, t, history]);

  return SocketManager;
};

export default useCompanyGlobalSocket;
