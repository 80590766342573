import { DownOutlined } from '@ant-design/icons/lib';
import { Tooltip, Tree } from 'antd';
import classNames from 'classnames';
import i18n from 'i18next';
import { findIndex } from 'lodash';
import { DataNode } from 'rc-tree/es/interface';
import { EventDataNode, Key } from 'rc-tree/lib/interface';
import React, { FunctionComponent, ReactText, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { LINK } from '../../Constants';
import { Roles } from '../../core/rule/RolesTypes';
import useCanAccess from '../../hooks/useCanAccess';
import { toggleMenu } from '../../redux/action/AppAction';
import { RootState } from '../../redux/RootState';
import logoKnl from '../../resources/img/K-rectangle.png';
import { APIModelCharter } from '../../services/api/types/ChartersServiceTypes';
import { NavigationConfig as routes } from '../../services/navigation/NavigationConfig';
import { NavigationMenuItem } from '../../services/navigation/NavigationMenuItem';
import CharterUtils from '../../utils/CharterUtils';
import CompaniesUtils from '../../utils/CompaniesUtils';
import LocationUtils from '../../utils/LocationUtils';
import { PermissionList } from '../../utils/types/CharterPermissionTypes';
import AccessChecker from '../access-checker/AccessChecker';
import useStyles from './KannelleMenuStyle';
import MenuLink from './MenuLink';
import MenuSectionTitle from './MenuSectionTitle';

type Props = {
  isMobileOrTablet: boolean;
};

const KannelleMenu: FunctionComponent<Props> = ({ isMobileOrTablet }: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [expandedKeys, setExpandedKeys] = useState<ReactText[]>([]);
  const [routeTree, setRouteTree] = useState<DataNode[]>([]);

  const { hasUserAccessToCharter, renderUnauthorizedWebMessage } = useCanAccess(PermissionList.WEB_DASHBOARD);

  const charters = useSelector((state: RootState) => state.charters.list);
  const currentCompany = useSelector((state: RootState) => state.companies.current);
  const companies = useSelector((state: RootState) => state.companies.list);
  const bundle = useSelector((state: RootState) => state.companies.bundle);
  const menuKeysOpen = useSelector((state: RootState) => state.app.menuKeysOpen);
  const subscriptions = useSelector((state: RootState) => state.companies.subscriptions);
  const freeTrialSubscription = useSelector((state: RootState) => state.companies.freeTrialSubscription);
  const charterPermissions = useSelector((state: RootState) => state.charters.currentCharterPermissions);

  const isUserKnlTeamInCharter = CompaniesUtils.checkIsKnlProfile(companies || []);
  const highestRoleInCurrentCompany = CompaniesUtils.getHighestRoleInCompany(currentCompany);
  const isUserPoc = currentCompany?.isUserPoc || false;

  useEffect(() => {
    if (!expandedKeys.includes(location.pathname)) {
      const keys = LocationUtils.getTreeKeysForLocationPathName(location.pathname);

      setExpandedKeys(keys);
    }
  }, [location, expandedKeys]);

  useEffect(() => {
    if (menuKeysOpen.length) {
      setExpandedKeys([...expandedKeys, ...menuKeysOpen]);
    } else if (menuKeysOpen) {
      setExpandedKeys([]);
    }
    // eslint-disable-next-line
  }, [menuKeysOpen]);

  const appVersion = process.env.REACT_APP_VERSION;

  const buildDisabledSubTreeNode = (
    key: string,
    title: string,
    tooltipText: string,
    RouteIcon?: React.ElementType,
    hasWebAccess = true
  ) => {
    const Icon = RouteIcon ? <RouteIcon className={classNames(classes.treeMenuItemIcon, classes.routeIcon)} /> : null;

    return {
      title: (
        <AccessChecker renderUnauthorizedMessage={renderUnauthorizedWebMessage} hasAccess={hasWebAccess} key={key}>
          <Tooltip title={hasWebAccess ? tooltipText : undefined} placement="right">
            {Icon}
            {t(title)}
          </Tooltip>
        </AccessChecker>
      ),
      key,
      isLeaf: true,
      className: classNames(classes.disabledTreeMenuItem),
    };
  };

  const buildCharterDisabledSubTreeNode = (
    route: NavigationMenuItem,
    charter: APIModelCharter,
    hasWebAccess?: boolean
  ): DataNode | undefined => {
    if (!route.isInMenu) {
      return undefined;
    }

    const key = route.key.replace(':charterId', charter.id.toString());

    return buildDisabledSubTreeNode(key, route.title ?? '', t('menu.disabledCharterLink'), route.icon, hasWebAccess);
  };

  const checkIsRouteAccessible = (route: NavigationMenuItem, userRoleInCharter?: string): boolean => {
    if (!currentCompany) {
      return false;
    }

    if (route.knlTeamOnly && !isUserKnlTeamInCharter) {
      return false;
    }

    if (route.roleRestriction && route.roleRestriction.length > 0 && !isUserKnlTeamInCharter) {
      let roleCheckedAndPresent = false;

      route.roleRestriction.forEach((role) => {
        const isRolePresent =
          userRoleInCharter !== undefined
            ? role === userRoleInCharter
            : CompaniesUtils.checkUserHasRole([currentCompany], role);

        if (!roleCheckedAndPresent && isRolePresent) {
          roleCheckedAndPresent = isRolePresent;
        }
      });

      if ((!roleCheckedAndPresent && !isUserPoc) || (isUserPoc && !route.isPoc?.accessible)) {
        return false;
      }
    }

    return true;
  };

  const checkIsRouteBundleRestricted = (route: NavigationMenuItem): boolean => {
    // No route restriction for KnlTeam users
    if (isUserKnlTeamInCharter) {
      return false;
    }

    if (route.bundleRestrictions && bundle) {
      let isRouteRestricted = false;
      route.bundleRestrictions.forEach((code) => {
        isRouteRestricted = bundle?.featureValues[code] === false ?? false;
      });

      return isRouteRestricted;
    }

    return false;
  };

  const transformChildrenToTreeNodes = (children: NavigationMenuItem[]): DataNode[] => {
    const childrenTreeNode: DataNode[] = [];
    children.forEach((child: NavigationMenuItem): void => {
      const conditionCantAccessYourPlan =
        !subscriptions || subscriptions?.length === 0 || freeTrialSubscription !== undefined;
      const conditionCantAccessOurPlans = !freeTrialSubscription && subscriptions && subscriptions?.length > 0;

      if (!child.isInMenu) {
        return;
      }

      if (child.needsChargebeeSubscription !== undefined && !currentCompany?.subscriptionCreated) {
        // Your plans
        if (child.needsChargebeeSubscription && conditionCantAccessYourPlan) {
          return;
        }
        // Our plans
        if (!child.needsChargebeeSubscription && conditionCantAccessOurPlans) {
          return;
        }
      } else if (child.needsChargebeeSubscription === false && currentCompany?.subscriptionCreated) {
        // Our plans
        return;
      }

      const title = child.title || '';
      const accessErrorMessage = child.accessErrorMessage || '';

      if (!checkIsRouteAccessible(child)) {
        if (highestRoleInCurrentCompany?.isCreator() && !isUserPoc) {
          return;
        }
        childrenTreeNode.push(buildDisabledSubTreeNode(child.key, title ?? '', t(accessErrorMessage)));
        return;
      }

      childrenTreeNode.push({
        title: <Link to={child.path}>{child.title && t(title)}</Link>,
        key: child.key,
        children: child.children && transformChildrenToTreeNodes(child.children),
        isLeaf: !child.children,
        className: classNames(
          child.path === location.pathname && classes.treeMenuItemSelect,
          classes.treeMenuItem,
          classes.secondaryTreeItem
        ),
      });
    });

    return childrenTreeNode;
  };

  const buildCharterSubTreeNode = (route: NavigationMenuItem, charter: APIModelCharter): DataNode | undefined => {
    if ((route.knlTeamOnly && !isUserKnlTeamInCharter) || !route.isInMenu) {
      return undefined;
    }

    const path = route.path.replace(':charterId', charter.id.toString()).replace('(\\d+)', '');
    const key = route.key.replace(':charterId', charter.id.toString());
    const associatedPaths = route.associatedPaths?.map((associatedPath) =>
      associatedPath.replace(':charterId', charter.id.toString()).replace('(\\d+)', '')
    );

    let children: DataNode[] = [];
    if (route && route.children) {
      children = [];
      route.children.forEach((child) => {
        const childDataNode = buildCharterSubTreeNode(child, charter);
        if (childDataNode) {
          children.push(childDataNode);
        }
      });
    }

    return {
      title: <Link to={path}>{t(route.title || '')}</Link>,
      key,
      isLeaf: children.length <= 0,
      children: children.length > 0 ? [...children] : undefined,
      className: classNames(
        (path === location.pathname || associatedPaths?.includes(location.pathname)) && classes.treeMenuItemSelect,
        classes.treeMenuItem,
        classes.secondaryTreeItem
      ),
    };
  };

  const buildAddCharterTreeNode = (charterRootRoute: NavigationMenuItem) => {
    const charterCreationRoute = charterRootRoute?.children?.filter(
      (route) => route.key === LINK.CHARTERS_CREATION.key
    )[0];

    if (!charterCreationRoute) {
      return undefined;
    }

    if (
      charters &&
      bundle &&
      bundle.featureValues.MAX_CHARTERS &&
      charters?.length >= bundle.featureValues.MAX_CHARTERS
    ) {
      return buildDisabledSubTreeNode(
        charterCreationRoute.key,
        charterCreationRoute.title ?? '',
        t('charters.creation.bundleLimitExceeded'),
        charterCreationRoute.icon
      );
    }

    return {
      title: <MenuLink route={charterCreationRoute} iconClassName={classes.routeIcon} />,
      key: LINK.CHARTERS_CREATION.key,
      className: classNames(
        LINK.CHARTERS_CREATION.path === location.pathname && classes.treeMenuItemSelect,
        classes.treeMenuItem,
        classes.secondaryTreeItem,
        classes.charterCreationMenuItem
      ),
    };
  };
  const getChartersTreeNodes = (charterRootRoute: NavigationMenuItem): DataNode[] | undefined => {
    let chartersTreeNodes: DataNode[] = [];

    if (charters) {
      chartersTreeNodes = charters
        .slice()
        .filter((charter) => charter.isActive)
        .sort(CharterUtils.sortChartersByName)
        .map((charter, index) => {
          const availableRoutes = charterRootRoute.children;

          const children: any[] = [];
          const disabledChildren: any[] = [];
          const hasAccess = hasUserAccessToCharter(charter.id);
          availableRoutes?.forEach((charterSubRoute) => {
            if (!checkIsRouteAccessible(charterSubRoute, charter?.currentUser?.role)) {
              if ((highestRoleInCurrentCompany?.isCreator() && !isUserPoc) || !charterSubRoute.isInMenu) {
                return;
              }
              if (charter.currentUser.role === Roles.Creator) {
                return;
              }
            }

            if (
              (isUserPoc &&
                charterSubRoute.isPoc?.accessible &&
                charterSubRoute.isPoc?.inGroupNeeded &&
                !isUserKnlTeamInCharter &&
                (!charter.currentUser || !charter.currentUser.isActive)) ||
              !hasAccess
            ) {
              disabledChildren.push(buildCharterDisabledSubTreeNode(charterSubRoute, charter, hasAccess));
              return;
            }

            children.push(buildCharterSubTreeNode(charterSubRoute, charter));
          });

          return {
            title: <Link to={hasAccess ? `/charters/${charter.id}` : '#'}>{charter.name}</Link>,
            key: `/charters/${charter.id}`,
            children: [
              ...(children.filter((node) => node !== undefined) as DataNode[]),
              ...(disabledChildren.filter((node) => node !== undefined) as DataNode[]),
            ],
            className: classNames(
              `/charters/${charter.id}` === location.pathname && classes.treeMenuItemSelect,
              classes.treeMenuItem,
              classes.secondaryTreeItem,
              index === 0 && 'first-charter-link' // Only on the first charter (for onboarding)
            ),
          };
        });
    }

    // Add the 'Create new charter' link if possible
    const addCharterLink = buildAddCharterTreeNode(charterRootRoute);
    if (addCharterLink) {
      chartersTreeNodes.push(addCharterLink);
    }

    return chartersTreeNodes;
  };

  const getRouteTreeData = (): DataNode[] => {
    const dataNode: DataNode[] = [];
    routes
      .filter((route: NavigationMenuItem) => route.isInMenu)
      .forEach((route: NavigationMenuItem): void => {
        const isLeaf = !route.children && !route.asyncChildren;
        let children;
        if (route.chartersRoot) {
          children = getChartersTreeNodes(route);
        } else if (route.children) {
          children = transformChildrenToTreeNodes(route.children);
        }

        if (route.knlTeamOnly && !isUserKnlTeamInCharter) {
          return;
        }

        if (checkIsRouteBundleRestricted(route)) {
          dataNode.push(
            buildDisabledSubTreeNode(route.key, route.title ?? '', t('menu.bundleRestriction'), route.icon)
          );

          return;
        }

        if (!checkIsRouteAccessible(route)) {
          if (highestRoleInCurrentCompany?.isCreator() && !isUserPoc) {
            return;
          }
          const accessErrorMessage = route.accessErrorMessage || '';
          dataNode.push(buildDisabledSubTreeNode(route.key, route.title ?? '', t(accessErrorMessage), route.icon));
          return;
        }

        dataNode.push({
          title: route.isNotLink ? <MenuSectionTitle route={route} /> : <MenuLink route={route} />,
          key: route.key,
          isLeaf,
          className: classNames(
            route.path === location.pathname && classes.treeMenuItemSelect,
            classes.mainTreeItem,
            classes.treeMenuItem,
            route.className || ''
          ),
          children,
          selectable: route.isNotLink,
        });
      });

    return dataNode;
  };

  const onMenuItemSelect = (
    selectedKeys: Key[],
    info: {
      event: 'select';
      selected: boolean;
      node: EventDataNode;
      selectedNodes: DataNode[];
      nativeEvent: MouseEvent;
    }
  ): void => {
    if (info.node.selectable) {
      if (expandedKeys.includes(info.node.key)) {
        const newExpandKeys = [...expandedKeys];
        const index = findIndex(newExpandKeys, info.node.key);
        newExpandKeys.splice(index, 1);
        setExpandedKeys([...newExpandKeys]);
      } else {
        setExpandedKeys([...expandedKeys, info.node.key]);
      }
    }

    // If we are on a mobile or tablet, clicking an item should close the menu
    if (isMobileOrTablet) {
      dispatch(toggleMenu());
    }
  };

  const handleExpand = (tableExpandedKeys: ReactText[]): void => {
    setExpandedKeys(tableExpandedKeys);
  };

  useEffect(() => {
    // When we change company we must re-calculate the route for companySettings -> our plan / current plan
    setRouteTree(getRouteTreeData());
  }, [currentCompany, charters, subscriptions, expandedKeys, i18n.language, charterPermissions, bundle]);

  return (
    <div className={classNames(classes.menuContainer, 'menu-container')}>
      {isMobileOrTablet && (
        <div className={classes.logoContainer}>
          <img className={classes.logo} src={logoKnl} alt="logo" />
        </div>
      )}
      <Tree
        className={classes.kannelleTree}
        expandedKeys={expandedKeys}
        onExpand={handleExpand}
        switcherIcon={<DownOutlined />}
        treeData={routeTree}
        showIcon
        onSelect={onMenuItemSelect}
      />
      <div className={classes.version}>{appVersion}</div>
    </div>
  );
};

export default KannelleMenu;
