import { NotificationOutlined } from '@ant-design/icons';
import { Row, Table } from 'antd';
import { CancelTokenSource } from 'axios';
import { find } from 'lodash';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import ErrorAlert from '../../../components/alert/ErrorAlert';
import PageContentLoader from '../../../components/loader/PageContentLoader';
import KannellePageHeader from '../../../components/page-header/KannellePageHeader';
import { THEME } from '../../../Constants';
import { RootState } from '../../../redux/RootState';
import { APIManager } from '../../../services/api/APIManager';
import { getCompanyNotificationConfigs } from '../../../services/api/CompaniesService';
import { APICompanyNotificationConfig } from '../../../services/api/types/CompaniesServiceTypes';
import LocalUtils from '../../../utils/LocalUtils';
import { buildColumns } from './NotificationConfigsColumns';

type Props = {
  isKnlTeam: boolean;
};

const useStyles = createUseStyles({
  pageHeader: {
    padding: 0,
  },
  avatar: {
    backgroundColor: THEME.DEFAULT.MAIN_COLOR,
  },
  subtitleRow: {
    marginBottom: 16,
  },
  errorAlert: {
    marginTop: '30px',
  },
  searchInColumnFilter: {
    minWidth: '18rem !important',
  },
  highlightedText: {
    '& mark': {
      backgroundColor: '#ffc069',
      padding: 0,
    },
  },
  configName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  configCodeTooltip: {
    marginLeft: 5,
  },
});

const NotificationConfigs: FunctionComponent<Props> = ({ isKnlTeam }: Props) => {
  const [configs, setConfigs] = useState<APICompanyNotificationConfig[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isConfigBeingUpdated, setIsConfigBeingUpdated] = useState(false);
  const [filter, setFilter] = useState<Record<string, string>>();

  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());

  const company = useSelector((state: RootState) => state.companies.current);
  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);

  const configColumnWidth = isMobileOrTablet ? 180 : 250;

  useEffect(() => {
    const cancelTokenSource = cancelTokenSourceRef.current;

    return (): void => {
      cancelTokenSource.cancel('Cancelled fetching company notification configs due to component unmount.');
    };
  }, []);

  useEffect(() => {
    if (!company) {
      return;
    }

    const lang = LocalUtils.getLang();
    const cancelTokenSource = cancelTokenSourceRef.current;
    getCompanyNotificationConfigs(company.id, lang, cancelTokenSource)
      .then((res) => {
        setConfigs(
          res.data.items.filter((notificationConfig) => {
            // Only KnlTeam users can see the APICompanyNotificationConfig with the scope "PROSPECT"
            if (!isKnlTeam) {
              return notificationConfig.scope !== 'PROSPECT';
            }
            return true;
          })
        );
      })
      .catch((e) => {
        log.debug('Error while fetching company notification configs', e);
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [company, isKnlTeam, i18n.language]);

  const callbackUpdateCompanyNotifications = (
    notificationConfigId: number,
    updatedField: 'isEmailActive' | 'isWebActive',
    newValue: boolean
  ): void => {
    const updatedNotificationConfigs = configs;
    const configToUpdate = find(updatedNotificationConfigs, (config) => config.id === notificationConfigId);
    if (!configToUpdate) {
      return;
    }
    configToUpdate[updatedField] = newValue;
    setConfigs(updatedNotificationConfigs);
    setIsConfigBeingUpdated(false);
  };

  const columns = buildColumns({
    t,
    classes,
    configs,
    filter,
    setFilter,
    configColumnWidth,
    isConfigBeingUpdated,
    setIsConfigBeingUpdated,
    callbackUpdateCompanyNotifications,
    isKnlTeam,
  });

  if (isError) {
    return <ErrorAlert className={classes.errorAlert} message={t('ajaxError.notificationConfigsFetch')} />;
  }

  if (isLoading) {
    return <PageContentLoader />;
  }

  return (
    <KannellePageHeader
      title={t('notificationConfigs.title')}
      className={classes.pageHeader}
      avatar={{ className: classes.avatar, icon: <NotificationOutlined /> }}
    >
      <Row className={classes.subtitleRow}>
        <p>{t('notificationConfigs.pageDescription')}</p>
      </Row>

      <Table
        tableLayout={isMobileOrTablet ? 'fixed' : 'auto'}
        bordered
        columns={columns}
        dataSource={configs}
        rowKey={(record): string => {
          return `config_row_${record.id}`;
        }}
        locale={{ filterConfirm: t('global.ok'), filterReset: t('global.reset'), emptyText: t('global.nodata') }}
        showSorterTooltip={false}
      />
    </KannellePageHeader>
  );
};

export default NotificationConfigs;
