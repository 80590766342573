import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { THEME } from '../../../../../../../../Constants';
import {
  ProjectSceneAnimation,
  ProjectSceneElementsCode,
  ProjectSceneMedia,
} from '../../../../../../../../utils/types/ProjectTypes';
import { VideoTrim, VideoTrimUtils } from '../../utils/VideoTrimUtils';
import SceneTimelineGrayOverlay from '../SceneTimelineGrayOverlay';

type Props = {
  intervalTickWidth: number;
  backgroundVideo?: ProjectSceneMedia;
  animation?: ProjectSceneAnimation;
  pip?: ProjectSceneMedia;
  videoTrimValue: VideoTrim;
  onClick: () => void;
};

type StyleProps = {
  intervalTickWidth: number;
  backgroundVideo?: ProjectSceneMedia;
};

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  addPipButton: ({ intervalTickWidth, backgroundVideo }: StyleProps) => {
    const leftPosition = VideoTrimUtils.calculateStartAnchorPositionOnX(
      backgroundVideo && backgroundVideo.trimStartAt ? backgroundVideo.trimStartAt : 0,
      intervalTickWidth
    );

    const common = {
      zIndex: 2,
      background: THEME.PROJECTS.EDITOR.TIMELINE.BUTTON_BACKGROUND,
      position: 'absolute',
      top: 4,
      '&:hover, &:focus': {
        background: THEME.PROJECTS.EDITOR.TIMELINE.BUTTON_BACKGROUND_HOVER,
        color: 'black',
        borderColor: THEME.PROJECTS.EDITOR.TIMELINE.BUTTON_BACKGROUND_ACTIVE,
      },
      '&:active': {
        background: THEME.PROJECTS.EDITOR.TIMELINE.BUTTON_BACKGROUND_ACTIVE,
        color: 'black',
        borderColor: THEME.PROJECTS.EDITOR.TIMELINE.BUTTON_BORDER_ACTIVE,
      },
    };

    return { ...common, left: leftPosition };
  },
});

const AddPipToSceneButton: FunctionComponent<Props> = ({
  intervalTickWidth,
  backgroundVideo,
  animation,
  pip,
  videoTrimValue,
  onClick,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles({
    intervalTickWidth,
    backgroundVideo,
  });

  return (
    <div className={classes.container}>
      <SceneTimelineGrayOverlay
        element={ProjectSceneElementsCode.PIP}
        backgroundVideo={backgroundVideo}
        pip={pip}
        animation={animation}
        videoTrimValue={videoTrimValue}
        intervalTickWidth={intervalTickWidth}
      >
        <Button shape="round" icon={<PlusOutlined />} className={classes.addPipButton} onClick={onClick}>
          {t('charters.projects.projectEditor.sceneEditor.fields.pip.addPip')}
        </Button>
      </SceneTimelineGrayOverlay>
    </div>
  );
};

export default AddPipToSceneButton;
