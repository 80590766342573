import { filter, find, omit, sample } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { ScenarioVariant } from '../services/api/types/ChartersServiceTypes';
import ObjectUtils from './ObjectUtils';
import {
  AnimationPositionConfig,
  AnimationPositionPreset,
  AnimationPositionPresets,
  Animations,
  LowerThirdAnimations,
  PipEffect,
  PipEffects,
} from './types/AnimationTypes';
import {
  ScenarioFormat,
  ScenarioFormats,
  ScenarioLanguage,
  ScenarioLanguages,
  ScenarioSceneTemplate,
  ScenarioStatus,
  TemplateCameraOverlayText,
  TemplateSequenceKind,
} from './types/ScenarioTypes';

export default class ScenarioUtils {
  // Remove falsy properties (undefined, null, false, etc.) and useless fields (for back-end) to pretty-print a scenes array or a scene
  static cleanScenesOrScene = (
    scenesOrScene: ScenarioSceneTemplate[] | ScenarioSceneTemplate
  ): ScenarioSceneTemplate[] | ScenarioSceneTemplate => {
    const objectWithoutNullProperties = ObjectUtils.removeFalsyProperties(scenesOrScene);
    return Array.isArray(scenesOrScene)
      ? ScenarioUtils.removeUselessFieldsFromScenes(objectWithoutNullProperties)
      : ScenarioUtils.removeUselessFieldsFromScene(objectWithoutNullProperties);
  };

  // Return the JSON of the scenes or the scene as a string.
  static transformScenesOrSceneIntoJSON = (scenesOrScene: ScenarioSceneTemplate[] | ScenarioSceneTemplate): string => {
    const objectWithoutUselessProperties = ScenarioUtils.cleanScenesOrScene(scenesOrScene);
    return JSON.stringify(objectWithoutUselessProperties, null, 2);
  };

  // Remove useless fields (for back-end) from a scene before patching it (overlayTexts.id, animationText.position.key, etc.)
  static removeUselessFieldsFromScene = (scene: ScenarioSceneTemplate): ScenarioSceneTemplate => {
    if (!scene.overlayTexts) {
      return scene;
    }

    const newScene = { ...scene };
    newScene.overlayTexts = newScene.overlayTexts!.map((overlay) => omit(overlay, 'id')) as TemplateCameraOverlayText[];
    delete newScene.animationText?.position?.key;
    delete newScene.demoCoverImageSource?.duration;

    return newScene;
  };

  static removeUselessFieldsFromScenes = (scenes: ScenarioSceneTemplate[]): ScenarioSceneTemplate[] => {
    return scenes.map((scene) => ScenarioUtils.removeUselessFieldsFromScene(scene));
  };

  // To enable ScenarioVariant manipulation in the form, we require some adjustments on the object received from the API.
  // For instance, since `overlayTexts` is an array (reorderable), we require each `overlayText` item to have a unique id.
  static prepareScenarioVariantForForm = (scenarioVariant: ScenarioVariant): ScenarioVariant => {
    const { scenes } = scenarioVariant;

    if (!scenes) {
      return scenarioVariant;
    }

    const exhaustedVariant = scenarioVariant;
    exhaustedVariant.scenes = scenes?.map((scene) => {
      const exhaustedScene = scene;
      const { overlayTexts } = exhaustedScene;
      exhaustedScene.overlayTexts = overlayTexts?.map((overlayText) => ({
        ...overlayText,
        id: ScenarioUtils.generateId(),
      }));
      return exhaustedScene;
    });
    return exhaustedVariant;
  };

  static generateId = (): string => {
    return uuidv4();
  };

  static calculateSceneKindIndex = (sceneToFind: ScenarioSceneTemplate, scenario: ScenarioSceneTemplate[]): number => {
    if (!scenario.some((s) => s.id === sceneToFind.id)) {
      return -1;
    }
    const scenarioFilteredByKind = scenario.filter((s) => s.kind === sceneToFind.kind);
    return scenarioFilteredByKind.findIndex((s) => s.id === sceneToFind.id);
  };

  static calculateScenesIndexes = (scenario: ScenarioSceneTemplate[]): ScenarioSceneTemplate[] => {
    return scenario.map((scene, index) => ({
      ...scene,
      index,
      kindIndex: ScenarioUtils.calculateSceneKindIndex(scene, scenario),
    }));
  };

  static isSlideKind = (kindAsString: string): boolean => {
    return kindAsString === TemplateSequenceKind.SLIDE.code;
  };

  static isPlaneAnimation = (animationTextName: string): boolean => {
    return animationTextName === Animations.PLANE.code;
  };

  static isLowerThirdAnimation = (animationCode: string): boolean => {
    return LowerThirdAnimations.some((animation) => animation.code === animationCode);
  };

  // If the animation is not a lower third, the only allowed option is 'FULL_SCREEN', else the other options are allowed (except 'FULL_SCREEN')
  static getTemplateAnimationPositionConfigByAnimationCode = (animationCode: string): AnimationPositionConfig[] => {
    const isLowerThirdAnimation = ScenarioUtils.isLowerThirdAnimation(animationCode);
    return !isLowerThirdAnimation
      ? [AnimationPositionConfig.FULL_SCREEN]
      : filter(AnimationPositionConfig, (config) => config.code !== AnimationPositionConfig.FULL_SCREEN.code);
  };

  static findTemplateAnimationPositionPresetsByCoordinates = (
    x: number,
    y: number
  ): AnimationPositionPreset | undefined => {
    return find(
      AnimationPositionPresets,
      (positionPreset: AnimationPositionPreset) => positionPreset.x === x && positionPreset.y === y
    );
  };

  static findPipEffectByCode = (code: string): PipEffect | undefined => {
    return find(PipEffects, (videoPipEffect) => videoPipEffect.code === code);
  };

  static getVariantLanguage = (languageCode: string): ScenarioLanguage | undefined => {
    return find(ScenarioLanguages, (lang) => lang.code === languageCode);
  };

  static getVariantStatusByCode = (statusCode: string): ScenarioStatus | undefined => {
    return find(ScenarioStatus, (statusObject) => statusObject.code === statusCode);
  };

  static getVariantFormatByCode = (formatCode: string): ScenarioFormat | undefined => {
    return find(ScenarioFormats, (formatObject) => formatObject.code === formatCode);
  };

  static getRandomUserPipEffect = (): PipEffect => {
    const zoomInEffect = PipEffects.ZOOMIN;
    const randomEffect = sample(PipEffects);
    return randomEffect && randomEffect.code !== PipEffects.NONE.code ? randomEffect : zoomInEffect;
  };

  static getAnimationTextsFromScene = (scene?: ScenarioSceneTemplate): string[] => {
    return scene?.overlayTexts?.map((overlayText) => overlayText.text) || [];
  };

  static computeSceneDuration = (scene: ScenarioSceneTemplate): number => {
    if (scene.animationText && !scene.demoVideoUri) {
      return scene.animationText.duration ?? 0;
    }

    return scene.demoVideoUri?.duration ?? 0;
  };

  static computeScenesDuration = (scenes: ScenarioSceneTemplate[]): number => {
    if (!(scenes && scenes.length > 0)) {
      return 0;
    }

    return scenes.reduce((prev, currentScene) => prev + ScenarioUtils.computeSceneDuration(currentScene), 0);
  };

  static computeSceneStartTimeInScenario = (scenes: ScenarioSceneTemplate[], scene: ScenarioSceneTemplate): number => {
    const previousScenes = scenes?.slice(0, scenes?.indexOf(scene));

    return (
      previousScenes?.reduce((prev, current) => {
        return prev + ScenarioUtils.computeSceneDuration(current);
      }, 0) ?? 0
    );
  };

  static computeSceneEndTimeInScenario = (scenes: ScenarioSceneTemplate[], scene: ScenarioSceneTemplate): number => {
    const absoluteStartTime = ScenarioUtils.computeSceneStartTimeInScenario(scenes, scene);
    const sceneDuration = ScenarioUtils.computeSceneDuration(scene);

    return absoluteStartTime + sceneDuration;
  };
}
