import { Button } from 'antd';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Browser } from 'react-kawaii';
import { useDispatch } from 'react-redux';
import { THEME } from '../../Constants';
import { useAuth0 } from '../../core/auth/Auth0Config';
import { setIsUserUnauthorized } from '../../redux/action/AppAction';

const useStyles = createUseStyles({
  unauthorizedRoot: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'url(backgroundUnauthorized.svg)',
    backgroundSize: 'cover',
  },

  iconError: {
    color: THEME.DEFAULT.UNAUTHORIZED.ERROR,
    fontSize: '6em',
  },
  textError: {
    color: THEME.DEFAULT.UNAUTHORIZED.ERROR,
  },
});

const Unauthorized: FunctionComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const auth0 = useAuth0();

  useEffect(() => {
    dispatch(setIsUserUnauthorized(true));
  }, [dispatch]);

  return (
    <div className={classes.unauthorizedRoot}>
      <Browser size={200} mood="ko" color={THEME.DEFAULT.UNAUTHORIZED.ERROR} />
      <h1 className={classes.textError}>{t('unauthorized.title')}</h1>
      <h3>{t('unauthorized.message')}</h3>
      <div>
        <Button
          onClick={(): void => {
            auth0.loginWithRedirect({});
          }}
        >
          {t('global.backToHome')}
        </Button>
      </div>
    </div>
  );
};

export default Unauthorized;
