/* eslint-disable */
// @ts-nocheck

const initializeSatismeter = (): void => {
  window.satismeter =
    window.satismeter ||
    function (): void {
      (window.satismeter.q = window.satismeter.q || []).push(arguments);
    };
  window.satismeter.l = 1 * new Date();
  const script = document.createElement('script');
  const parent = document.getElementsByTagName('script')[0].parentNode;
  script.async = 1;
  script.src = 'https://app.satismeter.com/satismeter.js';
  parent.appendChild(script);
};

export default initializeSatismeter;
