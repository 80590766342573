import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LINK } from '../../Constants';
import { setFreeTrialBannerDisplayed } from '../../redux/action/AppAction';
import { RootState } from '../../redux/RootState';
import { ChargebeeSubscriptionObject } from '../../services/api/types/ChargebeeServiceTypes';
import ChargebeeUtils from '../../utils/ChargebeeUtils';

const useStyles = createUseStyles({
  contentLayout: ({ isMobileOrTablet }) => ({
    position: 'fixed',
    top: 64,
    left: isMobileOrTablet ? 0 : 210,
    width: isMobileOrTablet ? '100%' : 'calc(100% - 210px);',
    overflow: 'hidden',
    textAlign: 'center',
    padding: 4,
    background: '#C1EAC5',
    color: '#05400A',
    zIndex: 6,
  }),
  tag: {
    margin: 4,
    border: 'unset',
    borderRadius: 25,
    background: '#05400A',
    color: '#ffffff',
    cursor: 'pointer',
  },
  link: {
    margin: 2,
    fontWeight: 'bold',
    color: '#05400A',
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: '#207227',
      textDecoration: 'underline',
    },
  },
});

const FreeTrialBanner: FunctionComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);
  const subscriptions = useSelector((state: RootState) => state.companies.subscriptions);
  const [subscriptionInfoToDisplay, setSubscriptionInfoToDisplay] = useState<ChargebeeSubscriptionObject>();
  const classes = useStyles({ isMobileOrTablet });

  useEffect(() => {
    if (!subscriptions) {
      dispatch(setFreeTrialBannerDisplayed(false));
      return;
    }

    const freeTrialSubscription = ChargebeeUtils.getFreeTrialSubscription(subscriptions);

    if (freeTrialSubscription) {
      setSubscriptionInfoToDisplay(freeTrialSubscription);
      dispatch(setFreeTrialBannerDisplayed(true));
    } else {
      setSubscriptionInfoToDisplay(undefined);
      dispatch(setFreeTrialBannerDisplayed(false));
    }
  }, [subscriptions]);

  const handleClick = () => {
    history.push(LINK.COMPANY_BUNDLES.path);
  };

  if (!subscriptionInfoToDisplay) {
    return null;
  }

  const now = moment();
  const trialEnd = moment((subscriptionInfoToDisplay.subscription.trial_end || 0) * 1000);
  const remainingTrialDays = trialEnd.diff(now, 'days');

  if (remainingTrialDays < 0) {
    return null;
  }

  return (
    <div className={classes.contentLayout}>
      <span>
        {t('freeTrialBanner.part1', { count: remainingTrialDays })}
        <span className={classes.link} onClick={handleClick}>
          {t('freeTrialBanner.part2')}
        </span>
        {t('freeTrialBanner.part3')}
      </span>
    </div>
  );
};

export default FreeTrialBanner;
