import { Asset, AssetType } from '../services/api/types/ChartersServiceTypes';
import { Music } from '../services/api/types/MusicsServiceTypes';
import StringUtils from './StringUtils';
import { UseCanAccessResult } from './types/PermissionType';

export enum ImageMimeTypes {
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  X_PNG = 'image/x-png',
  PNG = 'image/png',
}

export enum VideoMimeTypes {
  MPEG = 'video/mpeg',
  GPP3rd = 'video/3gpp',
  MP4 = 'video/mp4',
  QUICKTIME = 'video/quicktime',
}

export enum AudioMimeTypes {
  WAVE = 'audio/wave',
  WAV = 'audio/wav',
  X_WAV = 'audio/x-wav',
  X_PN_WAV = 'audio/x-pn-wav',
  OGG = 'audio/ogg',
  MP3 = 'audio/mp3',
  MIDI = 'audio/midi',
  MPEG = 'audio/mpeg',
  MP4 = 'audio/mp4',
  M4A = 'audio/m4a',
  X_M4A = 'audio/x-m4a',
}

export default class AssetsUtils {
  static getLogoMimeTypeList(): string[] {
    return [ImageMimeTypes.JPG, ImageMimeTypes.JPEG, ImageMimeTypes.X_PNG, ImageMimeTypes.PNG];
  }

  static getIntroMimeTypeList(): string[] {
    return [VideoMimeTypes.MPEG, VideoMimeTypes.GPP3rd, VideoMimeTypes.MP4, VideoMimeTypes.QUICKTIME];
  }

  static getOutroMimeTypeList(): string[] {
    return [VideoMimeTypes.MPEG, VideoMimeTypes.GPP3rd, VideoMimeTypes.MP4, VideoMimeTypes.QUICKTIME];
  }

  static getMusicMimeTypeList(): string[] {
    return [
      AudioMimeTypes.WAVE,
      AudioMimeTypes.WAV,
      AudioMimeTypes.X_WAV,
      AudioMimeTypes.X_PN_WAV,
      AudioMimeTypes.OGG,
      AudioMimeTypes.MP3,
      AudioMimeTypes.MIDI,
      AudioMimeTypes.MPEG,
      AudioMimeTypes.MP4,
      AudioMimeTypes.M4A,
      AudioMimeTypes.X_M4A,
    ];
  }

  static getSizeLimitByAssetType = (assetType: AssetType): number => {
    switch (assetType) {
      case AssetType.INTRO:
      case AssetType.OUTRO:
        return 20;
      case AssetType.MUSIC:
      case AssetType.LOGO:
      default:
        return 10;
    }
  };

  // Differentiate Asset (private) or Music (public) if the `tags` property is defined
  static isPublicMusic = (musicFile: Asset | Music): musicFile is Music => {
    return 'tags' in musicFile;
  };

  static getAssetById = (assetList: Asset[], assetId?: number): Asset | undefined => {
    if (!assetId) {
      return undefined;
    }

    return assetList.find((asset) => asset.id === assetId, 10);
  };

  static getAssetName = (asset: Asset): string => {
    return asset.publicName ?? StringUtils.getFileNameFromUrl(asset.url);
  };

  static computeAssetNameWithPermission = (
    assetValue: Asset,
    assetList: Asset[],
    permissionToEditAsset: UseCanAccessResult
  ): string => {
    if (!permissionToEditAsset.hasUserAccess() && permissionToEditAsset.forcedValue) {
      const asset = AssetsUtils.getAssetById(assetList, parseInt(permissionToEditAsset.forcedValue, 10));
      if (asset) {
        return AssetsUtils.getAssetName(asset);
      }
    }

    return AssetsUtils.getAssetName(assetValue);
  };

  static getAssetUrl = (asset: Asset): string => {
    return asset.hlsUrl ?? asset.url;
  };
}
