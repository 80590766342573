import { CheckOutlined, CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Radio, Row, Slider, Tooltip } from 'antd';
import { RadioChangeEvent } from 'antd/es/radio';
import classNames from 'classnames';
import map from 'lodash/map';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiEqualizer } from 'react-icons/bi';
import {
  FaMicrophone,
  FaMicrophoneAlt,
  FaMicrophoneAltSlash,
  FaSignal,
  FaVolumeOff,
  FaVolumeUp,
  FaWaveSquare,
} from 'react-icons/fa';
import { IoMusicalNotes } from 'react-icons/io5';
import { createUseStyles } from 'react-jss';
import KannelleSwitch from '../../../../../../components/switch/KannelleSwitch';
import MathUtils from '../../../../../../utils/MathUtils';
import ProjectUtils from '../../../../../../utils/ProjectUtils';
import {
  ProjectMusicDuckingVolume,
  ProjectMusicDuckingVolumes,
  ProjectMusicFadeOutDuration,
  ProjectMusicFadeOutDurations,
  ProjectVolumeParameters,
} from '../../../../../../utils/types/ProjectTypes';

type Props = {
  value?: ProjectVolumeParameters;
  isVisible: boolean;
  fieldLabel: string;
  onCancel: () => void;
  onSubmit: (volumeParameters: ProjectVolumeParameters) => void;
};

const useStyles = createUseStyles({
  modal: {
    width: '70% !important',
  },
  fieldLabel: {
    display: 'flex',
    height: 32,
    justifyContent: 'flex-end',
    alignItems: 'center',
    '&::after': {
      content: "':'",
      position: 'relative',
      top: '-0.5px',
      margin: '0 8px 0 2px',
    },
  },
  fieldLabelIcon: {
    marginRight: 6,
  },
  formFieldContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 24,
  },
  fieldSelectorColumn: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldSlider: {
    flex: 1,
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 8,
  },
  sliderIcon: {
    color: '#666666',
  },
  switchField: {
    paddingLeft: 8,
  },
  musicRadioButtonParam: {
    marginLeft: 20,
  },
  fieldTooltip: {
    marginLeft: 4,
    marginRight: 2,
    fontSize: 12,
  },
  musicVolumeFadeOutDurationIcon: {
    transform: 'scale(-1, 1)',
  },
});

const ProjectVolumeSettingsModal: FunctionComponent<Props> = ({
  value,
  isVisible,
  fieldLabel,
  onCancel,
  onSubmit,
}: Props) => {
  const [audioVolume, setAudioVolume] = useState<number>();
  const [backgroundMusicVolume, setBackgroundMusicVolume] = useState<number>();
  const [audioProcessingEnabled, setAudioProcessingEnabled] = useState<boolean>(false);
  const [musicDuckingVolume, setMusicDuckingVolume] = useState<ProjectMusicDuckingVolume>();
  const [musicFadeOutDuration, setMusicFadeOutDuration] = useState<ProjectMusicFadeOutDuration>();

  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    setAudioVolume(value?.audioVolume);
    setBackgroundMusicVolume(value?.backgroundMusicVolume);
    setAudioProcessingEnabled(value?.audioProcessingEnabled ?? false);
    setMusicDuckingVolume(value?.musicDuckingVolume);
    setMusicFadeOutDuration(value?.musicFadeOutDuration);
  }, [value, isVisible]);

  const leftColumnLayoutProps = { md: 10, lg: 10 };
  const rightColumnLayoutProps = { md: 24 - leftColumnLayoutProps.md, lg: 24 - leftColumnLayoutProps.lg };

  const slidersMarks = { '0': '' };

  const formatPercentage = (percentage?: number): string => {
    if (percentage === undefined) {
      return '';
    }
    return `${ProjectUtils.formatPercentageIntoHundredPercent(percentage)}%`;
  };

  const onAudioVolumeChange = (volume: number) => {
    setAudioVolume(ProjectUtils.formatVolumeWithPrecision(ProjectUtils.getSigmoidalVolume(volume)));
  };

  const onBackgroundMusicVolumeChange = (volume: number) => {
    setBackgroundMusicVolume(ProjectUtils.formatVolumeWithPrecision(ProjectUtils.getSigmoidalVolume(volume)));
  };

  const onAudioProcessingEnabledChange = (enabled: boolean) => {
    setAudioProcessingEnabled(enabled);
  };

  const onMusicDuckingEnabledChange = (enabled: boolean) => {
    if (enabled) {
      setMusicDuckingVolume(ProjectMusicDuckingVolume.MEDIUM);
      return;
    }

    setMusicDuckingVolume(0);
  };

  const onMusicFadeOutEnabledChange = (enabled: boolean) => {
    if (enabled) {
      setMusicFadeOutDuration(ProjectMusicFadeOutDuration.MEDIUM);
      return;
    }

    setMusicFadeOutDuration(0);
  };

  const onMusicDuckingVolumeChange = (e: RadioChangeEvent) => {
    const duckingVolume = e.target.value;
    setMusicDuckingVolume(duckingVolume);
  };

  const onMusicFadeOutDurationChange = (e: RadioChangeEvent) => {
    const fadeOutDuration = e.target.value;
    setMusicFadeOutDuration(fadeOutDuration);
  };

  const renderAudioVolumeField = () => {
    const sliderMarks = {
      ...slidersMarks,
      '-5': <FaMicrophoneAltSlash className={classes.sliderIcon} />,
      '5': <FaMicrophoneAlt className={classes.sliderIcon} />,
    };
    return (
      <Row className={classes.formFieldContainer}>
        <Col {...leftColumnLayoutProps} className={classes.fieldLabel}>
          <FaMicrophone className={classes.fieldLabelIcon} />
          {t('charters.projects.projectEditor.projectFinalization.volume.audioVolumeLabel')}
        </Col>
        <Col {...rightColumnLayoutProps} className={classes.fieldSelectorColumn}>
          <Slider
            min={-5}
            max={5}
            step={0.1}
            marks={sliderMarks}
            tipFormatter={formatPercentage}
            onChange={onAudioVolumeChange}
            value={ProjectUtils.getVolumePercentageFromSigmoidalVolume(audioVolume)}
            className={classes.fieldSlider}
          />
        </Col>
      </Row>
    );
  };

  const renderBackgroundMusicVolumeField = () => {
    // Hide the slider if no music is selected for the project
    if (backgroundMusicVolume === undefined) {
      return null;
    }

    const sliderMarks = {
      ...slidersMarks,
      '-5': <FaVolumeOff className={classes.sliderIcon} />,
      '5': <FaVolumeUp className={classes.sliderIcon} />,
    };

    return (
      <Row className={classes.formFieldContainer}>
        <Col {...leftColumnLayoutProps} className={classes.fieldLabel}>
          <IoMusicalNotes className={classes.fieldLabelIcon} />
          {t('charters.projects.projectEditor.projectFinalization.volume.backgroundMusicVolumeLabel')}
        </Col>
        <Col {...rightColumnLayoutProps} className={classes.fieldSelectorColumn}>
          <Slider
            min={-5}
            max={5}
            step={0.1}
            marks={sliderMarks}
            tipFormatter={formatPercentage}
            onChange={onBackgroundMusicVolumeChange}
            value={ProjectUtils.getVolumePercentageFromSigmoidalVolume(backgroundMusicVolume)}
            className={classes.fieldSlider}
          />
        </Col>
      </Row>
    );
  };

  const renderAudioProcessingEnabledField = () => {
    return (
      <Row className={classes.formFieldContainer}>
        <Col {...leftColumnLayoutProps} className={classes.fieldLabel}>
          <BiEqualizer className={classes.fieldLabelIcon} />
          {t('charters.projects.projectEditor.projectFinalization.volume.audioProcessingEnabledLabel')}
          <Tooltip title={t('charters.projects.projectEditor.projectFinalization.volume.audioProcessingTooltip')}>
            <InfoCircleOutlined className={classes.fieldTooltip} />
          </Tooltip>
        </Col>
        <Col {...rightColumnLayoutProps} className={classNames(classes.fieldSelectorColumn, classes.switchField)}>
          <KannelleSwitch
            isChecked={audioProcessingEnabled}
            callbackChange={onAudioProcessingEnabledChange}
            labelActivated="global.yes"
            labelDeactivated="global.no"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Col>
      </Row>
    );
  };

  const renderMusicDuckingField = () => {
    const isMusicDuckingEnabled =
      musicDuckingVolume !== undefined && musicDuckingVolume !== ProjectMusicDuckingVolume.NONE;

    return (
      <Row className={classes.formFieldContainer}>
        <Col {...leftColumnLayoutProps} className={classes.fieldLabel}>
          <FaWaveSquare className={classes.fieldLabelIcon} />
          {t('charters.projects.projectEditor.projectFinalization.volume.musicDuckingLabel')}
          <Tooltip title={t('charters.projects.projectEditor.projectFinalization.volume.musicDuckingTooltip')}>
            <InfoCircleOutlined className={classes.fieldTooltip} />
          </Tooltip>
        </Col>
        <Col {...rightColumnLayoutProps} className={classNames(classes.fieldSelectorColumn, classes.switchField)}>
          <KannelleSwitch
            isChecked={isMusicDuckingEnabled}
            callbackChange={onMusicDuckingEnabledChange}
            labelActivated="global.yes"
            labelDeactivated="global.no"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />

          <Radio.Group
            buttonStyle="solid"
            className={classes.musicRadioButtonParam}
            value={musicDuckingVolume}
            disabled={!isMusicDuckingEnabled}
            onChange={onMusicDuckingVolumeChange}
          >
            {map(ProjectMusicDuckingVolumes, (duckingVolumeOption) => {
              return (
                <Radio.Button key={duckingVolumeOption.value} value={duckingVolumeOption.value}>
                  <span>{t(duckingVolumeOption.key)}</span>
                </Radio.Button>
              );
            })}
          </Radio.Group>
        </Col>
      </Row>
    );
  };

  const renderMusicFadeOutField = () => {
    const isMusicFadeOutEnabled =
      musicFadeOutDuration !== undefined && musicFadeOutDuration !== ProjectMusicFadeOutDuration.NONE;

    return (
      <Row className={classes.formFieldContainer}>
        <Col {...leftColumnLayoutProps} className={classes.fieldLabel}>
          <FaSignal className={classNames(classes.fieldLabelIcon, classes.musicVolumeFadeOutDurationIcon)} />
          {t('charters.projects.projectEditor.projectFinalization.volume.musicFadeOutDurationLabel')}
        </Col>
        <Col {...rightColumnLayoutProps} className={classNames(classes.fieldSelectorColumn, classes.switchField)}>
          <KannelleSwitch
            isChecked={isMusicFadeOutEnabled}
            callbackChange={onMusicFadeOutEnabledChange}
            labelActivated="global.yes"
            labelDeactivated="global.no"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />

          <Radio.Group
            buttonStyle="solid"
            className={classes.musicRadioButtonParam}
            value={musicFadeOutDuration}
            disabled={!isMusicFadeOutEnabled}
            onChange={onMusicFadeOutDurationChange}
          >
            {map(ProjectMusicFadeOutDurations, (fadeOutDurationOption) => {
              return (
                <Radio.Button key={fadeOutDurationOption.value} value={fadeOutDurationOption.value}>
                  <span>{t(fadeOutDurationOption.key)}</span>
                </Radio.Button>
              );
            })}
          </Radio.Group>
        </Col>
      </Row>
    );
  };

  const isSubmitButtonDisabled = () => {
    const audioVolumeFromProject =
      value && value.audioVolume ? MathUtils.formatInputValueWithXDecimals(value.audioVolume, 3) : 0;
    const audioVolumeFromSlider = MathUtils.formatInputValueWithXDecimals(audioVolume ?? 0, 3);
    const backgroundMusicVolumeFromProject =
      value && value.backgroundMusicVolume
        ? MathUtils.formatInputValueWithXDecimals(value.backgroundMusicVolume, 3)
        : 0;
    const backgroundMusicVolumeFromSlider = MathUtils.formatInputValueWithXDecimals(backgroundMusicVolume ?? 0, 3);
    const audioProcessingEnabledFromProject = value?.audioProcessingEnabled ?? false;

    return (
      value &&
      audioVolumeFromProject === audioVolumeFromSlider &&
      backgroundMusicVolumeFromProject === backgroundMusicVolumeFromSlider &&
      audioProcessingEnabledFromProject === audioProcessingEnabled &&
      value.musicDuckingVolume === musicDuckingVolume &&
      value.musicFadeOutDuration === musicFadeOutDuration
    );
  };

  const submitForm = () => {
    if (audioVolume === undefined || backgroundMusicVolume === undefined) {
      return;
    }

    onSubmit({
      audioVolume: ProjectUtils.formatVolumeWithPrecision(audioVolume),
      backgroundMusicVolume: ProjectUtils.formatVolumeWithPrecision(backgroundMusicVolume),
      audioProcessingEnabled,
      musicDuckingVolume,
      musicFadeOutDuration,
    });
  };

  return (
    <Modal
      title={fieldLabel}
      visible={isVisible}
      onCancel={onCancel}
      destroyOnClose
      footer={[
        <Button key="cancelModal" onClick={onCancel}>
          {t('global.cancel')}
        </Button>,
        <Button key="submit" onClick={submitForm} type="primary" disabled={isSubmitButtonDisabled()}>
          {t('global.submit')}
        </Button>,
      ]}
      className={classes.modal}
    >
      {renderAudioVolumeField()}
      {renderBackgroundMusicVolumeField()}
      {renderAudioProcessingEnabledField()}
      {renderMusicDuckingField()}
      {renderMusicFadeOutField()}
    </Modal>
  );
};

export default ProjectVolumeSettingsModal;
