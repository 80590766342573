import { CrownOutlined } from '@ant-design/icons';
import { CaretDownOutlined, MoreOutlined } from '@ant-design/icons/lib';
import { Button, Dropdown, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { THEME } from '../../Constants';
import { useAuth0 } from '../../core/auth/Auth0Config';
import { Roles } from '../../core/rule/RolesTypes';
import { RootState } from '../../redux/RootState';
import CompaniesUtils from '../../utils/CompaniesUtils';
import Preformatted from '../preformatted/Preformatted';
import KannelleLogoutDropDownMenu from './KannelleLogoutDropDownMenu';

const useStyles = createUseStyles({
  dropdown: {
    position: 'fixed',
  },
  mobileMoreButton: {
    marginLeft: '8px',
  },
  knlTeamIcon: {
    marginRight: 4,
  },
  knlTeamDescription: {
    fontSize: 12,
    fontWeight: 'initial',
  },
  knlTeamCompaniesList: {
    whiteSpace: 'initial',
  },
  knlTeamIconTooltip: {
    borderRadius: 5,
    width: 400,
    maxWidth: 'none',
    maxHeight: 500,
    overflowY: 'auto',
    overflowX: 'hidden',
    '& .ant-tooltip-content': {
      borderRadius: 5,
      overflow: 'hidden',
    },
    '& .ant-tooltip-inner': {
      width: 'fit-content',
      maxWidth: 400,
    },
  },
  knlTeamCompanyName: {
    backgroundColor: 'rgba(89, 156, 224, 0.22)',
    fontWeight: 'bold',
  },
  knlTeamCharterName: {
    backgroundColor: THEME.DEFAULT.PREFORMATTED_IN_TOOLTIP.BACKGROUND_COLOR,
    fontStyle: THEME.DEFAULT.PREFORMATTED_IN_TOOLTIP.FONT_STYLE,
    fontWeight: 'initial',
  },
});

const KannelleHeaderUserInfo: FunctionComponent = () => {
  const classes = useStyles();
  const { user } = useAuth0();
  const { t } = useTranslation();

  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);
  const companies = useSelector((state: RootState) => state.companies.list);
  const isKnlTeam = companies ? CompaniesUtils.checkIsKnlProfile(companies) : false;

  if (!user) {
    return null;
  }

  const renderKnlTeamIcon = () => {
    if (!isKnlTeam) {
      return null;
    }

    const companiesWithKnlTeam = companies
      ?.slice()
      .sort(CompaniesUtils.sortCompaniesByName)
      .map((company) => {
        const { charters } = company;
        const chartersWithKnlTeam = charters.filter((ch) => ch.currentUser && ch.currentUser.role === Roles.KnlTeam);
        return {
          companyId: company.id,
          companyName: company.name,
          chartersWithKnlTeam: chartersWithKnlTeam.map((ch) => ({ charterId: ch.id, charterName: ch.name })),
        };
      });

    return (
      <Tooltip
        autoAdjustOverflow
        title={
          <span onClick={(e) => e.stopPropagation()}>
            <span className={classes.knlTeamDescription}>{t('header.knlTeamChartersList')}</span>
            <ul className={classes.knlTeamCompaniesList}>
              {companiesWithKnlTeam?.map((companyWithKnlTeam) => (
                <li key={`${companyWithKnlTeam.companyId}-${companyWithKnlTeam.companyName}`}>
                  <Preformatted className={classes.knlTeamCompanyName}>{companyWithKnlTeam.companyName}</Preformatted> (
                  {companyWithKnlTeam.chartersWithKnlTeam.map((ch, index) => (
                    <React.Fragment key={ch.charterId}>
                      {index > 0 ? ', ' : ''}
                      <Preformatted className={classes.knlTeamCharterName}>{ch.charterName}</Preformatted>
                    </React.Fragment>
                  ))}
                  )
                </li>
              ))}
            </ul>
          </span>
        }
        overlayClassName={classes.knlTeamIconTooltip}
        getPopupContainer={(trigger): HTMLElement => trigger.parentElement ?? document.body}
        placement="bottomLeft"
      >
        <CrownOutlined className={classes.knlTeamIcon} />
      </Tooltip>
    );
  };

  return (
    <Dropdown overlay={<KannelleLogoutDropDownMenu />} overlayClassName={classes.dropdown} trigger={['click']}>
      {!isMobileOrTablet ? (
        <Button className="user-info" type="link">
          <strong>
            {renderKnlTeamIcon()}
            {user.name}
          </strong>
          <CaretDownOutlined />
        </Button>
      ) : (
        <Button
          shape="circle"
          type="primary"
          icon={<MoreOutlined />}
          className={classNames(classes.mobileMoreButton, 'user-info')}
        />
      )}
    </Dropdown>
  );
};

export default KannelleHeaderUserInfo;
