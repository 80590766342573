import { Button, Modal, Slider, Tooltip } from 'antd';
import React, { FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiFullscreen } from 'react-icons/bi';
import { BsPauseFill, BsPlayFill } from 'react-icons/bs';
import { FaVolumeDown, FaVolumeMute, FaVolumeUp } from 'react-icons/fa';
import { createUseStyles } from 'react-jss';
import ReactPlayer from 'react-player';
import { ANIMATION_CONSTANTS } from '../../../Constants';
import useWindowSize from '../../../hooks/useWindowSize';
import { VideoTrim } from '../../../pages/charters/charter-projects/components/project-editor/scene-timeline/utils/VideoTrimUtils';
import AnimationsUtils from '../../../utils/AnimationsUtils';
import SubtitlesUtils from '../../../utils/SubtitlesUtils';
import TimeUtils from '../../../utils/TimeUtils';
import {
  AnimationPosition,
  AnimationPositionConfig,
  AnimationPositionStyle,
  Logo,
  MaskCodes,
  PIP,
  Size,
} from '../../../utils/types/AnimationTypes';
import { MediaType } from '../../../utils/types/MediaTypes';
import { SubtitleBlock } from '../../../utils/types/ProjectTypes';
import { AnimationTheme } from '../../../utils/types/ThemeTypes';
import LottiePlayer from '../../lottie/LottiePlayer';
import SceneVideoPlayerImagePIP from './SceneVideoPlayerImagePIP';
import SceneVideoPlayerVideoPIP from './SceneVideoPlayerVideoPIP';
import SceneVideoPlayerVideoSubtitles from './SceneVideoPlayerVideoSubtitles';

type Props = {
  isPlaying: boolean;
  isSlide: boolean;
  videoUrl?: string;
  lottieAnimation?: any;
  format: string;
  animationDelay?: number;
  animationTheme?: AnimationTheme;
  animationName?: string;
  animationTexts: string[];
  animationPosition?: AnimationPosition;
  fullscreenDisabled?: boolean;
  pip?: PIP;
  subtitlesBlock?: SubtitleBlock[];
  audioVolume?: number;
  seekTo?: number;
  controls?: boolean;
  hideVolumeControls?: boolean;
  videoDuration?: number;
  videoTrimValue?: VideoTrim;
  mask?: MaskCodes;
  logo?: Logo;
  isCarousel?: boolean;
  onCurrentTimeChange?: (currentTime: number) => void;
  onDurationChange?: (duration: number) => void;
  onAnimationDurationChange?: (animationDuration: number) => void;
  onPlayerLoadingChange?: (isPlayerLoading: boolean) => void;
  onIsPlayingChange?: (newIsPlaying: boolean) => void;
  onAnimationLoaded?: () => void;
  onEndReached?: () => void;
  onVideoEnded?: () => void;
  callbackPlayerRender?: (playerSize: Size) => void;
};

type StyleProps = {
  sceneVideoPlayerSize?: Size;
  lottiePlayerSize?: Size;
  animationPositionStyle?: AnimationPositionStyle;
  displayControls: boolean;
};

const useStyles = createUseStyles({
  sceneVideoPlayerWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  sceneVideoPlayerContainer: ({ sceneVideoPlayerSize }: StyleProps) => ({
    height: sceneVideoPlayerSize?.height ?? '100%',
    width: sceneVideoPlayerSize?.width ?? '100%',
    position: 'relative',
    pointerEvents: 'none',
  }),
  pipVideoContainer: ({ sceneVideoPlayerSize }: StyleProps) => ({
    height: sceneVideoPlayerSize?.height ?? '100%',
    width: sceneVideoPlayerSize?.width ?? '100%',
    position: 'absolute',
    overflow: 'hidden',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  }),
  videoContainer: {
    height: '100%',
  },
  lottiePlayer: ({ lottiePlayerSize, animationPositionStyle }: StyleProps) => {
    const { top, right, bottom, left } = animationPositionStyle || {};
    return {
      zIndex: 2,
      maxHeight: '100%',
      maxWidth: '100%',
      position: 'absolute',
      ...(top !== undefined && { top }),
      ...(right !== undefined && { right }),
      ...(bottom !== undefined && { bottom }),
      ...(left !== undefined && { left }),
      height: lottiePlayerSize?.height ?? '100%',
      width: lottiePlayerSize?.width ?? '100%',
      '& > svg': {
        maxHeight: '100%',
        display: 'block',
      },
    };
  },
  controlBarContainer: ({ displayControls }: StyleProps) => ({
    padding: 10,
    height: 40,
    zIndex: 3,
    background: '#00000070',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    opacity: displayControls ? 1 : 0,
    transition: 'opacity 0.4s',
    '& > *:not(:last-child)': {
      marginRight: 15,
    },
  }),
  controlButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: 20,
  },
  controlSeekBar: {
    display: 'flex',
    flexGrow: 2,
  },
  volumeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginRight: 4,
    fontSize: '14px !important',
  },
  volumeSliderTooltip: {
    '& .ant-tooltip-inner': {
      padding: '4px 0px 10px',
    },
  },
  volumeSlider: {
    height: 100,
  },
  timeValue: {
    color: 'white',
  },
  fullscreenModal: {
    width: '75% !important',
    '& .ant-modal-body': {
      position: 'relative',
    },
  },
  videoPlayerContainerInModal: {
    marginTop: 30,
    position: 'relative',
    background: 'black',
  },
  emptyVideo: {
    visibility: 'hidden',
  },
});

const SceneVideoPlayer: FunctionComponent<Props> = ({
  isPlaying,
  isSlide,
  videoUrl,
  lottieAnimation,
  format,
  animationPosition,
  animationDelay = 0,
  animationTheme,
  animationName,
  animationTexts,
  fullscreenDisabled = false,
  pip,
  subtitlesBlock,
  audioVolume: propAudioVolume = 0,
  seekTo = 0,
  controls = true,
  hideVolumeControls = false,
  videoDuration: propVideoDuration,
  videoTrimValue,
  mask,
  logo,
  isCarousel,
  onCurrentTimeChange,
  onDurationChange,
  onAnimationDurationChange,
  onPlayerLoadingChange,
  onIsPlayingChange,
  onAnimationLoaded,
  onEndReached,
  onVideoEnded,
  callbackPlayerRender,
}: Props) => {
  const [currentTime, setCurrentTime] = useState(seekTo);
  const [audioVolume, setAudioVolume] = useState(propAudioVolume);
  const [animationDuration, setAnimationDuration] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [sceneDuration, setSceneDuration] = useState(0);
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const [sceneVideoPlayerSize, setSceneVideoPlayerSize] = useState<Size>();
  const [lottiePlayerSize, setLottiePlayerSize] = useState<Size>();
  const [animationPositionStyle, setAnimationPositionStyle] = useState<AnimationPositionStyle>();
  const [displayControls, setDisplayControls] = useState(false);
  const [isFullscreenModalVisible, setIsFullscreenModalVisible] = useState(false);
  const [isUsingEmptyVideo, setIsUsingEmptyVideo] = useState(false);
  const [isPipLoading, setIsPipLoading] = useState<boolean>();
  const [internalVideoUrl, setInternalVideoUrl] = useState(videoUrl);
  const { t } = useTranslation();

  const classes = useStyles({
    lottiePlayerSize,
    sceneVideoPlayerSize,
    animationPositionStyle,
    displayControls,
  });
  const sceneVideoPlayerRef = useRef<HTMLDivElement>(null);
  const videoPlayerRef = useRef<ReactPlayer>(null);
  const windowSize = useWindowSize();

  const isFullScreenAnimation = animationPosition?.code === AnimationPositionConfig.FULL_SCREEN.code;
  const hasMiniMask = mask && mask === MaskCodes.MINI;
  const progressInterval = ANIMATION_CONSTANTS.PLAYER_PROGRESS_INTERVAL;

  // From the rendered SceneVideoPlayer, calculate the scene component sizes
  useEffect(() => {
    if (sceneVideoPlayerRef.current) {
      // Get the rendered total size of the sceneVideoPlayerRef (16:9 black rectangle)
      const boundingRect = sceneVideoPlayerRef.current.getBoundingClientRect();
      const playerInitialSize = {
        width: Math.max(boundingRect.width, sceneVideoPlayerRef.current.offsetWidth),
        height: Math.max(boundingRect.height, sceneVideoPlayerRef.current.offsetHeight),
      };

      // Compute the size of the sceneVideoPlayer (into the right format, fitting into the 16:9 black rectangle)
      const videoPlayerSize = AnimationsUtils.getVideoPlayerSizeByFormat(format, playerInitialSize);
      setSceneVideoPlayerSize(videoPlayerSize);

      // Compute the animation size (scale it)
      if (lottieAnimation) {
        let newLottiePlayerSize = AnimationsUtils.getAnimationPlayerSize(lottieAnimation, videoPlayerSize, format);

        // If the animation is fullscreen, we optionally scale it down if a mask is rendered
        if (isFullScreenAnimation && newLottiePlayerSize) {
          const projectReferenceWidth = AnimationsUtils.getReferenceWidthByFormat(format);
          const playerScalingFactor = videoPlayerSize.width / projectReferenceWidth;
          const maskMargin = mask ? AnimationsUtils.getMaskMarginWidth(playerScalingFactor, mask) : 0;
          newLottiePlayerSize = {
            height: newLottiePlayerSize.height - 2 * maskMargin,
            width: newLottiePlayerSize.width - 2 * maskMargin,
          };
        }
        setLottiePlayerSize(newLottiePlayerSize);
      }

      // Compute the animation position style depending on the configured animation position
      if (animationPosition) {
        const subtitlesHeight = SubtitlesUtils.getSubtitlesBlockMaxHeight(subtitlesBlock, videoPlayerSize.width);
        const positionStyle = AnimationsUtils.getAnimationPositionStyle(format, animationPosition, videoPlayerSize, {
          mask,
          animationName,
          animationTheme,
          logo,
          subtitlesBlock,
          subtitlesHeight,
        });

        setAnimationPositionStyle(positionStyle);
      }
    }
  }, [
    lottieAnimation,
    format,
    animationPosition,
    animationName,
    animationTheme,
    isFullScreenAnimation,
    mask,
    logo,
    sceneVideoPlayerRef,
    windowSize,
    subtitlesBlock,
  ]);

  useEffect(() => {
    if (sceneVideoPlayerSize && !isPipLoading && isPlayerReady && !isCarousel) {
      callbackPlayerRender?.(sceneVideoPlayerSize);
    }
  }, [sceneVideoPlayerSize, isPipLoading, isPlayerReady, isPlayerReady, sceneVideoPlayerRef]);

  // If the empty video is used, set the isUsingEmptyVideo state to true
  useEffect(() => {
    setIsUsingEmptyVideo(videoUrl ? AnimationsUtils.isVideoEmpty(videoUrl) : false);
  }, [videoUrl]);

  // Whenever the player url changes, set the player as unready
  useEffect(() => {
    if (videoUrl !== internalVideoUrl) {
      setIsPlayerReady(false);
      setInternalVideoUrl(videoUrl);
    }
  }, [videoUrl]);

  // Whenever the seekTo value changes, we seek to the seekTo value in the video player and set the currentTime
  useEffect(() => {
    if (seekTo !== undefined && videoPlayerRef.current && isPlayerReady) {
      setCurrentTime(seekTo);
      videoPlayerRef.current?.seekTo(seekTo, 'seconds');
    }
  }, [seekTo, isPlayerReady, videoPlayerRef]);

  // If the currentTime is greater than the sceneDuration, we reset it to 0 and
  // reset the player to 0 too. This is especially useful for Slide animations
  // using the empty video as background.
  useEffect(() => {
    const sceneStart = videoTrimValue?.trimStart ?? 0;
    const sceneEnd = videoTrimValue?.trimEnd ?? sceneDuration;

    if (!sceneEnd) {
      return;
    }

    if (videoPlayerRef.current && isPlayerReady && (currentTime < sceneStart || currentTime >= sceneEnd)) {
      videoPlayerRef.current.seekTo(sceneStart, 'seconds');
      setCurrentTime(sceneStart);

      if (onCurrentTimeChange) {
        onCurrentTimeChange(sceneStart);
      }

      if (onEndReached) {
        onEndReached();
      }
    }
  }, [currentTime, sceneDuration, videoTrimValue, onCurrentTimeChange, onEndReached]);

  // Whenever the currentTime changes, we seek to the currentTime in the video player
  useEffect(() => {
    if (videoPlayerRef.current && isPlayerReady && !isPlaying) {
      videoPlayerRef.current.seekTo(currentTime, 'seconds');
    }
  }, [currentTime, isPlaying, videoPlayerRef]);

  // If the propAudioVolume change, we change the audioVolume
  useEffect(() => {
    setAudioVolume(propAudioVolume);
  }, [propAudioVolume]);

  // The scene duration is either the animation duration if the scene is a Slide
  // (or if the empty video is used, in case only a Recordable animation is set),
  // else it is the pipTotal duration, or the video duration (and the animation
  // might be truncated)
  useEffect(() => {
    // In case of a Slide, we use the animation duration
    if (isSlide) {
      setSceneDuration(animationDuration);
      return;
    }

    // If there is a video which is the videoEmpty
    if (isUsingEmptyVideo) {
      // If a pip is defined, use the pip total duration
      if (pip) {
        const pipTotalDuration = pip.duration + pip.delay;
        setSceneDuration(pipTotalDuration);
      } else {
        // Else use the animation duration (if the videoEmpty is used, we know an animation is set)
        setSceneDuration(animationDuration);
      }
      return;
    }

    // In any other case, use the video duration, or the animation duration as fallback
    setSceneDuration(videoDuration ?? animationDuration);
  }, [isSlide, videoDuration, animationDuration, isUsingEmptyVideo, pip]);

  useEffect(() => {
    if (onPlayerLoadingChange) {
      onPlayerLoadingChange(isPipLoading || !isPlayerReady);
    }
  }, [isPipLoading, isPlayerReady, onPlayerLoadingChange]);

  // Callback whenever the scene duration changes
  useEffect(() => {
    if (!onDurationChange) {
      return;
    }

    onDurationChange(sceneDuration);
  }, [sceneDuration, onDurationChange]);

  // Callback to display the controls bar
  const onDisplayControls = () => {
    setDisplayControls(true);
  };

  // Callback to hide the controls bar
  const onHideControls = () => {
    setDisplayControls(false);
  };

  // Callback when the play button is clicked
  const onPlayClick = () => {
    if (onIsPlayingChange) {
      onIsPlayingChange(true);
    }
  };

  // Callback when the pause button is clicked
  const onPauseClick = () => {
    if (onIsPlayingChange) {
      onIsPlayingChange(false);
    }
  };

  // Callback when the LottiePlayer loaded the animation and computed its duration
  const onLottieAnimationDurationChange = (lottieFileDuration: number) => {
    let duration: number;

    if (isSlide && animationName) {
      duration = AnimationsUtils.calculateSlideAnimationDuration(animationName, animationTexts);
    } else {
      duration = lottieFileDuration;
    }

    setAnimationDuration(duration);
    if (onAnimationDurationChange) {
      onAnimationDurationChange(duration);
    }
  };

  // Callback when the ReactPlayer loaded the video and computed its duration
  const onVideoDurationChange = (duration: number) => {
    if (videoTrimValue) {
      setVideoDuration(videoTrimValue.trimEnd - videoTrimValue.trimStart);
      return;
    }

    setVideoDuration(propVideoDuration ?? duration);
  };

  // Callback when the video is playing and its current time is progressing
  const onProgress = (progressState: {
    played: number;
    playedSeconds: number;
    loaded: number;
    loadedSeconds: number;
  }) => {
    if (!isPlaying) {
      return;
    }
    const { playedSeconds } = progressState;

    setCurrentTime(playedSeconds);
    // Callback when the current time is updated
    if (onCurrentTimeChange) {
      onCurrentTimeChange(playedSeconds);
    }
  };

  // Callback when the ReactPlayer loaded the video successfully and is ready to play
  const onReady = () => {
    setIsPlayerReady(true);
  };

  // Callback when the pip media loading value changes
  const onPipLoadingChange = (loading: boolean) => {
    setIsPipLoading(loading);
  };

  // Callback when the currentTime value has been changed in the seek bar
  // If the scene is playing, we force pausing it first
  // Then, we set the currentTime according to the percentage value from the seek bar
  const onSeekBarChange = (percentage?: number) => {
    if (percentage !== undefined) {
      onPauseClick();
      const newTime = percentage * sceneDuration;
      setCurrentTime(newTime);
    }
  };

  // Callback when opening the fullscreen modal
  const onOpenFullscreenModal = () => {
    setIsFullscreenModalVisible(true);
    onPauseClick();
  };

  // Callback when closing the fullscreen modal
  const onCloseFullscreenModal = () => {
    setIsFullscreenModalVisible(false);
  };

  // calculate the correct currentTime depending on the trimStartAt value && the animationDelay
  const getCurrentTimeAdjustedWithTrimValue = () => {
    if (videoTrimValue && videoTrimValue.trimStart) {
      return currentTime - animationDelay - videoTrimValue.trimStart;
    }
    return currentTime - animationDelay;
  };

  // Render the volume settings
  const renderVolumeSettings = (): ReactNode => {
    if (!controls || hideVolumeControls) {
      return null;
    }

    const onVolumeChange = (percentage: number | undefined): void => {
      if (percentage !== undefined) {
        setAudioVolume(percentage);
      }
    };

    // Render the right volume icon depending on the volume value
    const renderVolumeIcon = () => {
      const onMuteVolumeToggle = () => setAudioVolume((prev) => (prev > 0 ? 0 : 1));

      if (audioVolume === 0) {
        return <FaVolumeMute onClick={onMuteVolumeToggle} className={classes.controlButton} />;
      }
      if (audioVolume >= 0.5) {
        return <FaVolumeUp onClick={onMuteVolumeToggle} className={classes.controlButton} />;
      }

      return <FaVolumeDown onClick={onMuteVolumeToggle} className={classes.controlButton} />;
    };

    return (
      <Tooltip
        title={
          <div className={classes.volumeSlider}>
            <Slider
              vertical
              value={audioVolume}
              onChange={onVolumeChange}
              tipFormatter={null}
              min={0}
              max={1}
              step={0.01}
            />
          </div>
        }
        overlayClassName={classes.volumeSliderTooltip}
      >
        <div className={classes.volumeContainer}>
          {renderVolumeIcon()}
          <div />
        </div>
      </Tooltip>
    );
  };

  // Render the control bar
  const renderPlayerControlBar = () => {
    const relativeTime = currentTime - (videoTrimValue?.trimStart ?? 0);

    return (
      <div className={classes.controlBarContainer}>
        {isPlaying ? (
          <BsPauseFill className={classes.controlButton} onClick={onPauseClick} />
        ) : (
          <BsPlayFill className={classes.controlButton} onClick={onPlayClick} />
        )}

        {renderVolumeSettings()}

        <div className={classes.timeValue}>{TimeUtils.formatSecondsIntoDuration(relativeTime)}</div>

        <Slider
          className={classes.controlSeekBar}
          min={0}
          max={1}
          step={0.01}
          onChange={onSeekBarChange}
          value={AnimationsUtils.getScenePercentage(relativeTime, sceneDuration)}
          tipFormatter={null}
        />

        <div className={classes.timeValue}>{TimeUtils.formatSecondsIntoDuration(sceneDuration)}</div>

        {/* Disable the fullscreen button on already fullscreen animations (in modal) */}
        {!fullscreenDisabled && <BiFullscreen className={classes.controlButton} onClick={onOpenFullscreenModal} />}
      </div>
    );
  };

  const renderPIP = () => {
    // If there is no pip
    if (!pip) {
      return null;
    }

    if (pip.source.mediaType === MediaType.VIDEO) {
      return (
        <SceneVideoPlayerVideoPIP
          currentTime={currentTime}
          isPlaying={isPlaying}
          format={format}
          pip={pip}
          mask={mask}
          sceneVideoPlayerSize={sceneVideoPlayerSize}
          sceneVideoTrimValue={videoTrimValue}
          seekTo={seekTo}
        />
      );
    }

    return (
      <SceneVideoPlayerImagePIP
        currentTime={currentTime}
        format={format}
        mask={mask}
        pip={pip}
        sceneVideoPlayerSize={sceneVideoPlayerSize}
        onPipLoadingChange={onPipLoadingChange}
        sceneVideoTrimValue={videoTrimValue}
      />
    );
  };

  const renderSubtitles = () => {
    // If there is no subtitle block or in the carousel
    if (!subtitlesBlock || isCarousel) {
      return null;
    }

    return (
      <SceneVideoPlayerVideoSubtitles
        currentTime={currentTime}
        subtitlesBlock={subtitlesBlock}
        sceneVideoPlayerSize={sceneVideoPlayerSize}
        mask={mask}
        format={format}
        logo={logo}
      />
    );
  };

  const isLottiePlayerDisplayed = () => {
    if (videoTrimValue && videoTrimValue.trimStart) {
      return lottieAnimation && currentTime >= animationDelay + videoTrimValue.trimStart;
    }
    return lottieAnimation && currentTime >= animationDelay;
  };

  return (
    <>
      <div
        ref={sceneVideoPlayerRef}
        className={classes.sceneVideoPlayerWrapper}
        onMouseEnter={controls ? onDisplayControls : undefined}
        onMouseLeave={controls ? onHideControls : undefined}
      >
        <div className={classes.sceneVideoPlayerContainer}>
          <div className={classes.videoContainer}>
            <ReactPlayer
              url={videoUrl}
              playing={isPlaying}
              volume={audioVolume}
              width="100%"
              height="100%"
              onReady={onReady}
              onProgress={onProgress}
              onEnded={onVideoEnded}
              progressInterval={progressInterval}
              onDuration={onVideoDurationChange}
              className={isSlide ? classes.emptyVideo : undefined}
              ref={videoPlayerRef}
              key={videoUrl}
            />
          </div>

          {isLottiePlayerDisplayed() && (
            <div>
              <LottiePlayer
                animationData={lottieAnimation}
                onLoad={onAnimationLoaded}
                // Math.min() to avoid the flashing effect and remain on the last frame
                goTo={TimeUtils.fromSecondsToMillis(Math.min(getCurrentTimeAdjustedWithTrimValue(), animationDuration))}
                play={isPlaying}
                // The loop is handled by the currentTime state
                loop={false}
                keepLastFrame
                // Preserve the aspect ratio of the native animation, and stretch the animations for specific cases
                // such as fullscreen animations when a mask is rendered, or when the player is in the scenes carousel
                rendererSettings={{
                  ...((isFullScreenAnimation && hasMiniMask) || isCarousel
                    ? { preserveAspectRatio: 'none' }
                    : undefined),
                  filterSize: {
                    width: '200%',
                    height: '200%',
                    x: '-50%',
                    y: '-50%',
                  },
                }}
                onDurationChange={onLottieAnimationDurationChange}
                className={classes.lottiePlayer}
              />
            </div>
          )}

          {renderPIP()}
          {renderSubtitles()}
        </div>
        {controls && renderPlayerControlBar()}
      </div>

      <Modal
        visible={isFullscreenModalVisible}
        onCancel={onCloseFullscreenModal}
        className={classes.fullscreenModal}
        destroyOnClose
        footer={[
          <Button key="cancelModal" onClick={onCloseFullscreenModal}>
            {t('global.close')}
          </Button>,
        ]}
      >
        <div className={classes.videoPlayerContainerInModal}>
          <SceneVideoPlayer
            isPlaying={isPlaying}
            pip={pip}
            animationName={animationName}
            animationTexts={animationTexts}
            isSlide={isSlide}
            lottieAnimation={lottieAnimation}
            format={format}
            animationPosition={animationPosition}
            videoUrl={videoUrl}
            fullscreenDisabled
            controls={controls}
            onIsPlayingChange={onIsPlayingChange}
            audioVolume={audioVolume}
          />
        </div>
      </Modal>
    </>
  );
};

export default SceneVideoPlayer;
