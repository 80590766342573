import { CheckCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Card, Tooltip } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { THEME } from '../../Constants';
import { RootState } from '../../redux/RootState';
import AnimationsUtils from '../../utils/AnimationsUtils';
import CompaniesUtils from '../../utils/CompaniesUtils';
import { AnimationConfig, AnimationOption, AnimationPosition } from '../../utils/types/AnimationTypes';
import { AnimationTheme } from '../../utils/types/ThemeTypes';
import Preformatted from '../preformatted/Preformatted';
import ScenePlayer from '../scene-player/ScenePlayer';

type Props = {
  animationOption: AnimationOption;
  animationPosition: AnimationPosition;
  theme: AnimationTheme;
  format: string;
  customColors: boolean;
  animationDefaultTexts: string[];
  isSelected?: boolean;
  onSelectAnimation: (animationConfig: AnimationConfig) => void;
};

const useStyles = createUseStyles({
  animationPositionDescription: {
    marginLeft: 8,
    fontSize: 10,
  },
  selectButton: {
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 8,
  },
  selectedAnimationIcon: {
    position: 'absolute',
    top: '2px',
    right: '4px',
    color: THEME.DEFAULT.MAIN_TEXT_COLOR,
  },
  animationCardTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  animationCodeTooltip: {
    marginRight: 8,
  },
});

const AnimationSelectorCard: FunctionComponent<Props> = ({
  animationOption,
  animationPosition,
  theme,
  format,
  customColors,
  animationDefaultTexts,
  isSelected = false,
  onSelectAnimation,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [animationDuration, setAnimationDuration] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);

  const companies = useSelector((state: RootState) => state.companies.list);
  const isKnlTeam = companies ? CompaniesUtils.checkIsKnlProfile(companies) : false;

  const isSlide = AnimationsUtils.isSlideAnimation(animationOption.code);

  const onIsPlayingChange = (newIsPlaying: boolean) => {
    setIsPlaying(newIsPlaying);
  };

  const renderAnimationCardTitle = (animationName: string, positionDescription?: string, animationCode?: string) => {
    return (
      <div className={classes.animationCardTitle}>
        {/* Display the animation code for KnlTeam users only */}
        {isKnlTeam && animationCode && (
          <Tooltip placement="bottomLeft" title={<Preformatted>{animationCode}</Preformatted>}>
            <span className={classes.animationCodeTooltip}>
              <InfoCircleOutlined />
            </span>
          </Tooltip>
        )}
        <Preformatted>{animationName}</Preformatted>
        {positionDescription && <span className={classes.animationPositionDescription}>{positionDescription}</span>}
      </div>
    );
  };

  return (
    <Card title={renderAnimationCardTitle(t(animationOption.key), animationPosition.key, animationOption.code)}>
      {isSelected && (
        <div className={classes.selectedAnimationIcon}>
          <CheckCircleFilled />
        </div>
      )}
      <ScenePlayer
        animationName={animationOption.code}
        animationTheme={theme}
        animationTexts={animationDefaultTexts}
        format={format}
        customColors={customColors}
        animationPosition={animationPosition}
        isPlaying={isPlaying}
        seekTo={1.3}
        videoUrl={!isSlide ? AnimationsUtils.getSampleVideoByFormat(format) : undefined}
        onAnimationDurationChange={(duration) => {
          setAnimationDuration(duration);
        }}
        onIsLoadingChange={(loading: boolean) => {
          setIsLoading(loading);
        }}
        onIsPlayingChange={onIsPlayingChange}
      />

      <Button
        shape="round"
        className={classes.selectButton}
        disabled={isSelected || isLoading || !animationDuration}
        onClick={() =>
          onSelectAnimation({
            name: animationOption.code,
            position: animationPosition,
            duration: animationDuration,
          })
        }
      >
        {t('global.select')}
      </Button>
    </Card>
  );
};

export default AnimationSelectorCard;
