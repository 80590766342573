import { find } from 'lodash';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteProps, useHistory, useLocation, useParams } from 'react-router-dom';
import EmptyPageContent from '../../components/empty/EmptyPageContent';
import KannelleLoader from '../../components/loader/KannelleLoader';
import AbstractSecurityModal from '../../components/modal/AbstractSecurityModal';
import { CHARTER_ACTIVE, LINK, SECURITY_MODAL_TYPE, WEB_SOCKET_ACTIONS } from '../../Constants';
import useSocket from '../../hooks/useSocket';
import Company from '../../model/Company';
import { setIsUserUnauthorized, toggleMenu, updateUnsavedChange } from '../../redux/action/AppAction';
import { setChartersList } from '../../redux/action/ChartersAction';
import {
  // eslint-disable-next-line prettier/prettier
  updateCompany
} from '../../redux/action/CompaniesAction';
import { RootState } from '../../redux/RootState';
import { APIManager } from '../../services/api/APIManager';
import { getCompaniesByUser } from '../../services/api/CompaniesService';
import { SocketManager } from '../../services/api/SocketManager';
import { APIModelCharter } from '../../services/api/types/ChartersServiceTypes';
import { APIGetCompaniesResult } from '../../services/api/types/CompaniesServiceTypes';
import { AssetOrMediaProcessingResponse } from '../../services/api/types/WebSocketTypes';
import { getUserCompanies } from '../../services/api/UsersService';
import { CharterIdPathParam } from '../../services/navigation/NavigationConfigTypes';
import { NavigationMenuItem } from '../../services/navigation/NavigationMenuItem';
import CompaniesUtils from '../../utils/CompaniesUtils';
import SecurityUtils from '../../utils/SecurityUtils';
import StringUtils from '../../utils/StringUtils';
import { useAuth0 } from '../auth/Auth0Config';
import { Role } from '../rule/Roles';

type Props = {
  route: NavigationMenuItem;
  path: string;
  isAllowed: boolean;
  isRouteAlwaysAccessible: boolean;
} & RouteProps;

// eslint-disable-next-line react/prop-types
const SecurityCheckRoute: FunctionComponent<Props> = ({ route, component, isRouteAlwaysAccessible, ...props }: any) => {
  const Component = component!;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isUrlAccessible, setIsUrlAccessible] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalType, setModalType] = useState<SECURITY_MODAL_TYPE>();
  const [modalProps /* , setModalProps */] = useState<any>({});
  const history = useHistory();
  const { user } = useAuth0();
  const location = useLocation();
  const { charterId } = useParams<CharterIdPathParam>();
  const currentCompanyFromState = useSelector((state: RootState) => state.companies.current);
  const unsavedChange = useSelector((state: RootState) => state.app.unsavedChange);
  const menuCollapsed = useSelector((state: RootState) => state.app.menuCollapsed);
  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);
  const companies = useSelector((state: RootState) => state.companies.list);
  const company = useSelector((state: RootState) => state.companies.current);
  const bundle = useSelector((state: RootState) => state.companies.bundle);
  const isUserKnlTeamInCharter = CompaniesUtils.checkIsKnlProfile(companies || []);
  const isCurrentUserPoc = company?.isUserPoc || false;
  const isSocketConnected = useSocket(`company/${currentCompanyFromState?.id}`);

  const path = location.pathname;
  const params = useParams();

  const maintenanceEnv = process.env.REACT_APP_IS_IN_MAINTENANCE;
  const isInMaintenance = maintenanceEnv && StringUtils.stringToBoolean(maintenanceEnv);

  const manageAccess = (
    companiesResponse: APIGetCompaniesResult,
    userCompaniesContactResponse: APIGetCompaniesResult
    // companyUsersResponse: APICompanyUsersResult
  ): void => {
    const isKnlTeam = CompaniesUtils.checkIsKnlProfileFromApi(companiesResponse.data.items);
    const pocCompanies = userCompaniesContactResponse.data.items;
    const activeCompanies: Company[] = CompaniesUtils.getValidCompany(
      companiesResponse.data.items,
      isKnlTeam,
      pocCompanies
    );

    const currentCompany = CompaniesUtils.getCurrentCompany(activeCompanies, currentCompanyFromState);
    const charters = currentCompany?.getCompanyAllowedCharters();
    const highestRoleInCurrentCompany = CompaniesUtils.getHighestRoleInCompany(currentCompany);
    const currentCharter = find(charters, { id: parseInt(charterId, 10) }) as APIModelCharter;
    const userRoleInCharter =
      currentCharter && currentCharter.currentUser ? new Role(currentCharter?.currentUser?.role) : undefined;

    if (
      !(SecurityUtils.isPlatformAccessible(activeCompanies) && CompaniesUtils.hasAccessibleCompany(activeCompanies))
    ) {
      history.push(LINK.UNAUTHORIZED.path);
      setIsUrlAccessible(false);
      return;
    }

    if (
      !SecurityUtils.isRouteAccessibleForUser(
        route,
        isKnlTeam,
        charterId && userRoleInCharter ? userRoleInCharter : highestRoleInCurrentCompany,
        isCurrentUserPoc,
        bundle
      )
    ) {
      history.push(LINK.UNAUTHORIZED.path);
      setIsUrlAccessible(false);
      return;
    }

    if (currentCompanyFromState?.created) {
      setIsUrlAccessible(SecurityUtils.isUrlAccessible(activeCompanies, [], params));
    } else if (isCurrentUserPoc && charterId && !SecurityUtils.isPathAccessibleForPoc(path, charters, charterId)) {
      setIsUrlAccessible(false);
    } else {
      // Disabled for now - SELFCHECKOUT-TAG
      // const companyUsers = companyUsersResponse.data.items;
      // const filteredCompanyUsers = CompaniesUtils.getNonKnlTeamUsersInCompanies(companyUsers);
      // const isDowngradeNecessary = SecurityUtils.isDowngradeNecessary(currentCompany, charters, filteredCompanyUsers);
      //
      // if (isDowngradeNecessary && currentCompany.chargebeePlan) {
      //   getBundle(encodeURIComponent(currentCompany.chargebeePlan), LocalUtils.getLang()).then((response) => {
      //     setIsVisible(true);
      //     setIsUrlAccessible(false);
      //     setModalProps({
      //       bundleName: response.data.name,
      //       companyUsers: filteredCompanyUsers,
      //       companyCharters: [...charters],
      //       numberOfUsers: currentCompany.maxNbLicenses,
      //       chartersLimit: currentCompany.maxNbCharters,
      //       closable: false,
      //       callbackHandleClose: () => {
      //         setIsUrlAccessible(true);
      //         setIsVisible(false);
      //       },
      //     });
      //     setModalType(SECURITY_MODAL_TYPE.DOWNGRADE);
      //   });
      //
      //   return;
      // }

      dispatch(setChartersList(charters));
      setIsUrlAccessible(SecurityUtils.isUrlAccessible(activeCompanies, charters, params));
    }
  };

  useEffect(() => {
    if (!currentCompanyFromState) {
      return undefined;
    }

    const auth0UserIdPrefix = process.env.REACT_APP_AUTH0_USER_ID_PREFIX ?? '';
    const userId = user?.sub.replace(auth0UserIdPrefix, '');

    if (!menuCollapsed && isMobileOrTablet) {
      dispatch(toggleMenu());
    }

    if (unsavedChange) {
      dispatch(updateUnsavedChange(false));
    }
    const cancelTokenSource = APIManager.getCancelToken();

    const cancelMessage = 'Cancelled fetching companies by user due to component unmount.';
    Promise.all([
      getCompaniesByUser(userId, CHARTER_ACTIVE.ACTIVE, cancelTokenSource),
      getUserCompanies(userId, true),
      // getCompanyUsers(currentCompanyFromState?.id),
    ])
      .then(([companiesResponse, userCompaniesContactResponse /* , companyUsersResponse */]) => {
        if (!isUserKnlTeamInCharter && !currentCompanyFromState?.isActive) {
          history.push(LINK.UNAUTHORIZED.path);
          setIsUrlAccessible(false);
        } else if (!user.email_verified) {
          setIsVisible(true);
          setIsUrlAccessible(false);
          setModalType(SECURITY_MODAL_TYPE.EMAIL_NOT_VERIFIED);
        } else if (currentCompanyFromState.subscriptionCreated) {
          setIsVisible(true);
          setIsUrlAccessible(false);
          setModalType(SECURITY_MODAL_TYPE.LOADING);
        } else {
          manageAccess(companiesResponse, userCompaniesContactResponse); // , companyUsersResponse
        }
      })
      .catch((e) => {
        log.debug(e.message);
        if (e.message !== cancelMessage) {
          dispatch(setIsUserUnauthorized(true));
          history.push(LINK.UNAUTHORIZED.path);
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return (): void => {
      cancelTokenSource.cancel(cancelMessage);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!currentCompanyFromState || !isSocketConnected) {
      return undefined;
    }

    const handleCompanyMessage = (payload: any): void => {
      const currentCompanyToUpdate = new Company({ ...currentCompanyFromState.toJsonModel() }, false);
      currentCompanyToUpdate.chargebeeSubscriptions = [payload.data.subscriptionId];
      currentCompanyToUpdate.subscriptionCreated = false;

      dispatch(updateCompany(currentCompanyToUpdate));

      setIsVisible(false);
      setIsUrlAccessible(true);
    };

    const handleMessage = (payload: AssetOrMediaProcessingResponse): void => {
      const isCompanyMessage = WEB_SOCKET_ACTIONS.COMPANY_GLOBAL.includes(payload.action);
      if (isCompanyMessage) {
        handleCompanyMessage(payload);
      }
    };

    SocketManager.onMessage(handleMessage);

    return (): void => SocketManager.offMessage(handleMessage);
  }, [isSocketConnected, dispatch, history]);

  if (loading) {
    return <KannelleLoader />;
  }

  if (isInMaintenance) {
    return <AbstractSecurityModal visible type={SECURITY_MODAL_TYPE.MAINTENANCE} />;
  }

  return isUrlAccessible || isRouteAlwaysAccessible ? (
    <Component {...props} />
  ) : (
    <div>
      <EmptyPageContent />
      {isVisible && modalType && <AbstractSecurityModal visible={isVisible} type={modalType} modalProps={modalProps} />}
    </div>
  );
};

export default SecurityCheckRoute;
