import { Theme, ThemeColorKey } from '../ThemeUtils';

export enum AnimationTheme {
  BALI = 'BALI',
  BERLIN = 'BERLIN',
  GENEVA = 'GENEVA',
  ALGIERS = 'ALGIERS',
  LOS_ANGELES = 'LOS_ANGELES',
  SEATTLE = 'SEATTLE',
  BOGOTA = 'BOGOTA',
}

export type ThemeAnimationProps = {
  theme: ThemeColorKey;
  charterThemeColors: Theme[];
  animationThemeColor: Theme;
  callback: (newColors: Theme[]) => void;
  onReset: () => void;
  onSubmit: () => void;
  disabledActions?: boolean;
  loadingActions?: boolean;
};

export type LottieObject = {
  key: string;
  animationName: string;
  isStaticAnimation?: boolean;
  aliases?: string[];
  fields: string[];
};
