import { LockOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  label?: string;
  extra?: string;
  placeholder?: string;
  size?: SizeType;
};

const Password: FunctionComponent<Props> = ({ label, extra, placeholder, size = 'middle' }: Props) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={label}
      name="password"
      rules={[
        { type: 'string' },
        { required: true, message: t('form.passwordRuleRequiredError') },
        { whitespace: true, message: t('form.passwordRuleEmptyRequiredError') },
        { min: 8, message: t('form.passwordRuleMinError') },
        {
          pattern: /^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)\S{8,}$/,
          message: t('form.passwordRuleRegExRequiredError'),
        },
      ]}
      extra={extra}
      hasFeedback
    >
      <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder={placeholder} size={size} />
    </Form.Item>
  );
};

export default Password;
