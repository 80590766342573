import { RightOutlined } from '@ant-design/icons/lib';
import { Col, Row, Table } from 'antd';
import { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import { each, find, findIndex } from 'lodash';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import ErrorAlert from '../../../components/alert/ErrorAlert';
import PageContentLoader from '../../../components/loader/PageContentLoader';
import { CHARTER_USER, DEVICE_SIZES_QUERIES, LOGGING_EVENT, THEME } from '../../../Constants';
import { Roles } from '../../../core/rule/RolesTypes';
import { CHARTERS_USERS_RULE } from '../../../core/rule/Rules';
import useFetchCompanyStats from '../../../hooks/useFetchCompanyStats';
import { addedUserToCharterDisplayed } from '../../../redux/action/ChartersAction';
import { RootState } from '../../../redux/RootState';
import { APIManager } from '../../../services/api/APIManager';
import { getCompanyUsers } from '../../../services/api/CompaniesService';
import { APIModelCharter, UsersModelCharter } from '../../../services/api/types/ChartersServiceTypes';
import { APICompanyUsers, APICompanyUsersResult } from '../../../services/api/types/CompaniesServiceTypes';
import { APIModelUser, APIModelUserEnriched } from '../../../services/api/types/UsersServiceTypes';
import { SubRowCharterSelect } from '../../../utils/types/UsersTableTypes';
import UsersUtils from '../../../utils/UsersUtils';
import DisabledAddUserModal from './modals/DisabledAddUserModal';
import FilterUserCharterTable from './modals/FilterUserCharterTable';
import RemoveUserFromCharterModal from './modals/RemoveUserFromCharterModal';
import UpdateUserIsActiveModal from './modals/UpdateUserIsActiveModal';
import UpdateUserRoleModal from './modals/UpdateUserRoleModal';
import UsersExpandedRow from './UsersExpandedRow';
import { buildColumns } from './UsersTableColumns';

type Props = {
  showAction?: boolean;
};

const useStyles = createUseStyles({
  table: {
    '& .userCharter': {
      padding: '0px !important',
    },
    '& .ant-table-expanded-row-fixed': {
      padding: 8,
    },
    '& .ant-pagination': {
      paddingBottom: '50px',
    },
  },

  title: {
    marginTop: 16,
    padding: 0,
  },
  avatar: {
    marginRight: 4,
  },
  disabledUserRow: {
    opacity: '0.65',
    cursor: 'not-allowed',
    pointerEvents: 'none',
  },
  '@keyframes fadein': {
    '0:': { backgroundColor: '#E3F9E5' },
    '100.0%': { backgroundColor: 'inherit' },
  },
  rowAdded: {
    '& td, & .ant-table-cell-fix-left': {
      animationName: '$fadein',
      animationDuration: '4s',
      animationTimingFunction: 'ease-in-out',
      animationIterationCount: 1,
      animationFillMode: 'forwards',
      animationPlayState: 'running',
      backgroundColor: THEME.GLOBAL.MAIN_HIGHLIGHT_BACKGROUND_COLOR,
    },
  },
  userId: {
    display: 'block',
  },
  highlightedText: {
    '& mark': {
      backgroundColor: '#ffc069',
      padding: 0,
    },
  },
  addUserPosition: {
    [`@media screen and ${DEVICE_SIZES_QUERIES.LARGE}`]: {
      justifyContent: 'flex-end',
    },
  },
  expandableIcon: {
    border: '1px solid #F0F0F0',
    borderRadius: '2px',
    height: '18px',
    width: '18px',
    float: 'left',
    position: 'relative',
    display: 'inline-flex',
    textDecoration: 'none',
    padding: '0',
    lineHeight: '18px',
    outline: 'none',
    background: '#FFFFFF',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  expandedIcon: {
    transform: 'rotate(90deg)',
    transition: 'transform 0.3s ease-out',
  },
  collapsedIcon: {
    transform: 'rotate(0deg)',
    transition: 'transform 0.3s ease-out',
  },
});

type SubTableFilter = {
  charter?: number[];
  role?: string[];
  isActive?: boolean;
};

const UsersTable: FunctionComponent<Props> = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showAction } = props;
  const { loading: refreshHookLoading } = useFetchCompanyStats();
  const currentUser = useSelector((state: RootState) => state.user.user);
  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);
  const currentUserRole = useSelector((state: RootState) => state.user.role);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);
  const company = useSelector((state: RootState) => state.companies.current);

  const [users, setUsers] = useState<APICompanyUsers[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<APICompanyUsers[]>([]);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [filter, setFilter] = useState<Record<string, string>>();
  const [subTableFilter, setSubTableFilter] = useState<SubTableFilter>({});
  const [selectedSubRows, setSelectedSubRows] = useState<SubRowCharterSelect[]>([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [expandedRows, setExpandedRows] = useState<readonly React.ReactText[]>([]);

  const canReadUserIds = currentUserRole?.isAllowedTo(CHARTERS_USERS_RULE.READ_USERS_ID_KNL_TEAM) ?? false;

  const fetchUsers = (cancelTokenSource: CancelTokenSource): void => {
    if (!company || !currentUser) {
      return;
    }

    getCompanyUsers(company.id, cancelTokenSource)
      .then((usersByCharterResponse: APICompanyUsersResult) => {
        setIsError(false);
        const sortedUsers = usersByCharterResponse.data.items.sort((userA, userB) => {
          const currentUserPriority = UsersUtils.getCurrentUserPriority(userA, userB, currentUser);
          if (currentUserPriority !== 0) {
            return currentUserPriority;
          }
          return userA.charters.length > userB.charters.length ? -1 : 1;
        });
        setUsers([...sortedUsers]);
        setFilteredUsers([...sortedUsers]);
        setLoading(false);
      })
      .catch((e: any) => {
        log.debug(e.message);
        setIsError(true);
      });
  };

  useEffect(() => {
    if (!company) {
      return undefined;
    }
    setLoading(true);

    const cancelTokenSource = APIManager.getCancelToken();
    fetchUsers(cancelTokenSource);

    return (): void => {
      cancelTokenSource.cancel('Cancelled fetching users by company id due to component unmount.');
    };
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (
      !subTableFilter ||
      (!subTableFilter?.charter && !subTableFilter?.role && subTableFilter?.isActive === undefined)
    ) {
      setFilteredUsers([...users]);
      return;
    }

    let filtered = [...users];
    if (subTableFilter.charter && subTableFilter.charter.length > 0) {
      filtered = filtered
        .filter((user) => {
          const charterIdList = user.charters.map((charter) => charter.id);
          return subTableFilter?.charter?.some((charterId: number) => charterIdList.includes(charterId));
        })
        .map((user) => {
          const userFiltered = { ...user };
          const originalCharter = [...userFiltered.charters];
          userFiltered.charters = [
            ...originalCharter.filter((charter) => subTableFilter?.charter?.includes(charter.id)),
          ];
          return userFiltered;
        });
    }
    if (subTableFilter.role && subTableFilter.role.length > 0) {
      filtered = filtered
        .filter((user) => {
          return user.charters.some((charter) => {
            return subTableFilter?.role?.includes(charter.currentUser.role);
          });
        })
        .map((user) => {
          const userFiltered = { ...user };
          const originalCharter = [...userFiltered.charters];
          userFiltered.charters = originalCharter.filter((charter) =>
            subTableFilter?.role?.includes(charter.currentUser.role)
          );
          return userFiltered;
        });
    }
    if (subTableFilter.isActive !== undefined) {
      filtered = filtered
        .filter((user) => {
          return user.charters.some((charter) => {
            return charter.currentUser.isActive === subTableFilter.isActive;
          });
        })
        .map((user) => {
          const userFiltered = { ...user };
          const originalCharter = [...userFiltered.charters];
          userFiltered.charters = originalCharter.filter(
            (charter) => charter.currentUser.isActive === subTableFilter.isActive
          );
          return userFiltered;
        });
    }

    setFilteredUsers(filtered);
  }, [subTableFilter, users]);

  if (isError) {
    return <ErrorAlert message={t('ajaxError.usersFetch')} />;
  }

  if (refreshHookLoading) {
    return <PageContentLoader />;
  }

  if (!users || users.length === 0 || !currentUser) {
    return null;
  }

  const handleUserUpdating = (charterId: number, updatingRole = false, updatingIsActive = false): void => {
    selectedSubRows.forEach((subRow) => {
      const userIndex = findIndex(users, {
        id: subRow.userId,
      });
      const subRowCharterIndex = findIndex(subRow.charters, { id: charterId });

      if (userIndex < 0 || subRowCharterIndex < 0) {
        return;
      }
      const user = users[userIndex];

      user.charters
        .filter((charter) => {
          return charter.id === charterId;
        })
        .forEach((charter) => {
          const charterToUpdate = charter;
          charterToUpdate.updating = { role: updatingRole, isActive: updatingIsActive };
        });
    });

    setUsers([...users]);
  };

  const handleUsersRoleUpdate = (
    updating: boolean,
    charterId: number,
    updatedDataArray?: UsersModelCharter[],
    error = false
  ): void => {
    if (updating) {
      handleUserUpdating(charterId, true);
      return;
    }
    if (error) {
      handleUserUpdating(charterId, false);
      return;
    }

    const toUpdateUsers: APICompanyUsers[] = [];
    users.forEach((user) => {
      if (findIndex(selectedSubRows, { userId: user.id }) < 0) {
        toUpdateUsers.push(user);
        return;
      }

      user.charters
        .filter((charter) => {
          return charter.id === charterId;
        })
        .forEach((charter) => {
          const newData = find(updatedDataArray, { id: user.id });
          if (!newData) {
            return;
          }
          const updatedCharter = charter;
          updatedCharter.updating = { role: false };
          updatedCharter.currentUser.role = newData.acl.role;
        });
      toUpdateUsers.push(user);
    });

    setUsers([...toUpdateUsers]);
  };

  const handleUserRoleUpdate = (userToUpdate: APICompanyUsers, charterId: number, role: Roles): void => {
    const user = find(users, { id: userToUpdate.id });
    if (user) {
      const charter = find(user.charters, { id: charterId });

      if (charter) {
        charter.currentUser.role = role;
      }
      setUsers([...users]);
    }
  };

  const handleUsersIsActiveUpdate = (
    updating: boolean,
    charterId: number,
    updatedDataArray?: UsersModelCharter[]
  ): void => {
    if (updating) {
      handleUserUpdating(charterId, false, true);
      return;
    }

    users.forEach((user) => {
      if (findIndex(selectedSubRows, { userId: user.id }) < 0) {
        return;
      }

      user.charters
        .filter((charter) => {
          return charter.id === charterId;
        })
        .forEach((charter) => {
          const newData = find(updatedDataArray, { id: user.id });
          if (!newData) {
            return;
          }
          const updatedCharter = charter;
          updatedCharter.updating = { isActive: false };
          updatedCharter.currentUser.isActive = newData.acl.isActive;
        });
    });

    setUsers([...users]);
  };

  const handleUserIsActiveUpdate = (userToUpdate: APICompanyUsers, charterId: number, isActive: boolean): void => {
    const user = find(users, { id: userToUpdate.id });
    if (user) {
      const charter = find(user.charters, { id: charterId });

      if (charter) {
        charter.currentUser.isActive = isActive;
      }
      setUsers([...users]);
    }
  };

  const handleRemoveUser = (charterId: number, userId: string): void => {
    const userToUpdate = find(users, { id: userId });
    if (!userToUpdate) {
      return;
    }

    const indexCharterToRemove = findIndex(userToUpdate.charters, { id: charterId });
    userToUpdate.charters.splice(indexCharterToRemove, 1);
    setUsers([...users]);
    setSelectedSubRows([]);
  };

  const callbackOpenRow = (row: APICompanyUsers): void => {
    const rowId = `charters_users_row_${row.id}`;
    if (!expandedRows.includes(rowId)) {
      setExpandedRows([...expandedRows, rowId]);
    }
  };

  const resetHighlightSubRow = (charters: APIModelCharter[]): void => {
    charters
      .filter((charter) => charter.highlightRow)
      .forEach((charter) => {
        const charterToUpdate = charter;
        charterToUpdate.highlightRow = false;
      });
  };

  const callbackLinkUserToCharter = (
    isLoading: boolean,
    data?: APIModelUserEnriched,
    charter?: APIModelCharter
  ): void => {
    if (isLoading) {
      setLoading(true);
      return;
    }

    if (!data || !charter) {
      return;
    }

    setLoading(false);
    const concernedUser = find(users, { id: data.id });
    if (concernedUser) {
      const userRowId = `users_row_${concernedUser.id}`;
      resetHighlightSubRow(concernedUser.charters);
      const charterToAdd = { ...charter };
      charterToAdd.currentUser = {
        isActive: data.acl.isActive,
        role: data.acl.role,
        permissions: [],
      };
      charterToAdd.highlightRow = true;
      concernedUser.charters = [...concernedUser.charters, charterToAdd];

      if (!expandedRows.includes(userRowId)) {
        setExpandedRows([...expandedRows, userRowId]);
      }

      setUsers([...users]);
    }
  };

  const callbackRemoveUserFromCharter = (
    isLoading: boolean,
    charter?: APIModelCharter,
    user?: APICompanyUsers
  ): void => {
    if (isLoading) {
      setLoading(true);
      return;
    }

    if (!user || !charter) {
      return;
    }
    setLoading(false);
    const concernedUser = find(users, { id: user.id });
    if (concernedUser) {
      const indexCharter = findIndex(concernedUser.charters, { id: charter.id });
      if (indexCharter >= 0) {
        concernedUser.charters.splice(indexCharter, 1);
      }

      setUsers([...users]);
    }
  };

  const callbackUserNameUpdated = (user: APIModelUser): void => {
    if (!user) {
      return;
    }

    const concernedUser = find(users, { id: user.id });
    if (concernedUser) {
      concernedUser.name = user.name;
      setUsers([...users]);
    }
  };

  const handleSearch = (confirm: () => void): void => {
    setLoading(true);
    setTimeout(() => confirm(), 100);
  };

  const handleExpandChange = (tableExpandedRows: readonly React.ReactText[]): void => {
    setExpandedRows(tableExpandedRows);
  };
  const handleSubRowSelection = (user: APICompanyUsers, subRowsSelected: APIModelCharter[]): void => {
    if (subRowsSelected.length === 0) {
      const selectedSubRowItemToRemove = findIndex(selectedSubRows, (subRow) => {
        return subRow.userId === user.id;
      });

      const selectedSubRowsToUpdate = [...selectedSubRows];
      selectedSubRowsToUpdate.splice(selectedSubRowItemToRemove, 1);

      setSelectedSubRows(selectedSubRowsToUpdate);
      return;
    }

    const selectedSubRowItem = find(selectedSubRows, (subRow) => {
      return subRow.userId === user.id;
    });

    if (selectedSubRowItem) {
      selectedSubRowItem.charters = subRowsSelected;
    } else {
      setSelectedSubRows([
        ...selectedSubRows,
        {
          userId: user.id,
          userName: user.name !== user.email ? user.name : user.email,
          userEmail: user.email,
          charters: subRowsSelected,
        },
      ]);
    }
  };

  const handleFilterCharterChange = (value: number[] | undefined): void => {
    subTableFilter.charter = value;
    setSubTableFilter({ ...subTableFilter });
  };

  const handleFilterRoleChange = (value: string[] | undefined): void => {
    subTableFilter.role = value;
    setSubTableFilter({ ...subTableFilter });
  };

  const handleFilterIsActiveChange = (value: boolean | undefined): void => {
    if (value === undefined) {
      subTableFilter.isActive = undefined;
    } else {
      subTableFilter.isActive = value;
    }
    setSubTableFilter({ ...subTableFilter });
  };

  const columns = buildColumns({
    users,
    currentUser,
    filter,
    setFilter,
    isMobileOrTablet,
    canReadUserIds,
    classes,
    t,
    filteredInfo,
    callbackOpenRow,
    callbackLinkUserToCharter,
    callbackRemoveUserFromCharter,
    callbackUserNameUpdated,
    handleSearch,
  });

  return (
    <>
      {showAction === true ? (
        <Row style={{ marginBottom: 16 }} justify="space-between">
          <Col sm={24} md={24} lg={16}>
            <Row gutter={[8, 8]}>
              <Col>
                <RemoveUserFromCharterModal
                  selectedRows={selectedSubRows}
                  callbackUserFromCharterRemoved={handleRemoveUser}
                />
              </Col>
              <Col>
                <UpdateUserRoleModal selectedRows={selectedSubRows} callbackHandleUpdate={handleUsersRoleUpdate} />
              </Col>
              <Col>
                <UpdateUserIsActiveModal
                  selectedRows={selectedSubRows}
                  callbackHandleUpdate={handleUsersIsActiveUpdate}
                />
              </Col>
              <Col>
                <FilterUserCharterTable
                  callbackFilterCharterChange={handleFilterCharterChange}
                  callbackFilterRoleChange={handleFilterRoleChange}
                  callbackFilterIsActiveChange={handleFilterIsActiveChange}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={24} md={24} lg={8}>
            <Row className={classes.addUserPosition}>
              <Col>
                <DisabledAddUserModal />
              </Col>
            </Row>
          </Col>
        </Row>
      ) : null}

      <Table
        tableLayout="fixed"
        className={classes.table}
        columns={columns}
        dataSource={filteredUsers}
        showSorterTooltip={false}
        loading={loading}
        rowKey={(record): string => {
          return `users_row_${record.id}`;
        }}
        onChange={(pagination, filters, sorter: any, extra): void => {
          if (extra.action === 'sort') {
            if (sorter.field) {
              loggingManager.logEvent(LOGGING_EVENT.SORT_USERS, {
                columnName: sorter.field,
                direction: sorter.order,
                companyId: company?.id,
              });
            }
          } else if (extra.action === 'filter') {
            const filterSyntheses: { [key: string]: any } = {};
            each(filters, (dataSource: any, key: string) => {
              if (dataSource) {
                filterSyntheses[key.replace('user_', '')] = dataSource;
              }
            });

            loggingManager.logEvent(LOGGING_EVENT.FILTER_USERS, {
              filter: filterSyntheses,
              companyId: company?.id,
            });
            setFilteredInfo(filters);
            setLoading(false);
          }
          dispatch(addedUserToCharterDisplayed(currentUser));
        }}
        rowClassName={(record): string => {
          if (record.highlightRow) {
            return classes.rowAdded;
          }
          if (record.id === currentUser?.id) {
            return classes.disabledUserRow;
          }
          return '';
        }}
        scroll={{ x: 'max-content' }}
        pagination={{
          defaultPageSize: CHARTER_USER.PAGINATION.PAGE_SIZE,
          showSizeChanger: users.length > CHARTER_USER.PAGINATION.PAGE_SIZE,
        }}
        size="small"
        locale={{ filterConfirm: t('global.ok'), filterReset: t('global.reset'), emptyText: t('global.nodata') }}
        expandable={{
          expandedRowRender: (record: APICompanyUsers): JSX.Element => (
            <UsersExpandedRow
              user={{ ...record }}
              handleSubRowSelection={handleSubRowSelection}
              callbackUpdateIsActive={handleUserIsActiveUpdate}
              callbackUpdateRole={handleUserRoleUpdate}
            />
          ),
          rowExpandable: (record: APICompanyUsers): boolean => record.id !== currentUser?.id,
          onExpandedRowsChange: handleExpandChange,
          expandedRowKeys: expandedRows,
          expandIcon: (expandIconProps): JSX.Element | null => {
            if (!expandIconProps.expandable) {
              return null;
            }
            return (
              <span
                className={classNames(
                  classes.expandableIcon,
                  expandIconProps.expanded ? classes.expandedIcon : classes.collapsedIcon
                )}
                onClick={(e): void => expandIconProps.onExpand(expandIconProps.record, e)}
              >
                <RightOutlined />
              </span>
            );
          },
        }}
      />
    </>
  );
};

export default UsersTable;
