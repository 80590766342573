import { InfoCircleOutlined } from '@ant-design/icons/lib';
import { Form, InputNumber, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDebounce } from 'use-debounce';

type Props = {
  label: string;
  step?: number;
  min?: number;
  max?: number;
  value: number;
  onChange: (newValue?: number) => void;
  className?: string;
};

const useStyles = createUseStyles({
  inputNumber: {
    '& .ant-input-number-handler-wrap': {
      zIndex: '0',
    },
  },
  tooltip: {
    marginLeft: 8,
  },
});

const InputFloat: FunctionComponent<Props> = ({
  label,
  min = 0,
  max = 1,
  step = 0.01,
  value,
  onChange,
  className,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(value);
  const [debouncedValue] = useDebounce(selectedValue, 300);

  useEffect(() => {
    if (value) {
      setSelectedValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (debouncedValue !== value) {
      onChange(debouncedValue);
    }
    // eslint-disable-next-line
  }, [debouncedValue]);

  const onInputChange = (newValue?: string | number | null): void => {
    if (typeof newValue === 'number') {
      setSelectedValue(newValue);
    }
  };

  return (
    <Form.Item label={label}>
      <InputNumber
        step={step}
        min={min}
        max={max}
        className={classNames(classes.inputNumber, className)}
        value={selectedValue}
        onChange={onInputChange}
      />
      <Tooltip title={t('charters.themes.formFields.opacityHelper')}>
        <span className={classes.tooltip}>
          <InfoCircleOutlined />
        </span>
      </Tooltip>
    </Form.Item>
  );
};

export default InputFloat;
