import { UserDeleteOutlined } from '@ant-design/icons/lib';
import { Button, List, message, Modal, Tooltip } from 'antd';
import classNames from 'classnames';
import log from 'loglevel';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { LOGGING_EVENT } from '../../../../../Constants';
import {
  removeCurrentUserFromCurrentCharter,
  removeUserFromCurrentCharter,
} from '../../../../../redux/action/ChartersAction';
import { RootState } from '../../../../../redux/RootState';
import { StatusCode } from '../../../../../services/api/APIManager';
import { deleteUserFromCharter } from '../../../../../services/api/ChartersService';
import { UsersModelCharter } from '../../../../../services/api/types/ChartersServiceTypes';
import { APIRemoveUserFromCharterByIdResult } from '../../../../../services/api/types/UsersServiceTypes';
import LogUtils from '../../../../../utils/LogUtils';

const useStyles = createUseStyles({
  notClosableModal: {
    cursor: 'not-allowed',
    '& .ant-modal': {
      cursor: 'default',
    },
  },
  usersList: {
    maxHeight: '350px',
    overflowY: 'auto',
  },
  actionDescriptionMessage: {
    marginTop: '10px',
  },
});

type Props = {
  isDisabled?: boolean;
  selectedRows: UsersModelCharter[];
  renderUnauthorizedMessage?: () => void;
};

const RemoveUserFromCharterModal: FunctionComponent<Props> = ({
  selectedRows,
  isDisabled,
  renderUnauthorizedMessage,
}: Props) => {
  const [visible, setVisible] = useState(false);
  const [isRemovalLoading, setIsRemovalLoading] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentUser = useSelector((state: RootState) => state.user.user);
  const companyStats = useSelector((state: RootState) => state.companies.stats);
  const currentCharterId = useSelector((state: RootState) => state.charters.current?.id);
  const currentCompany = useSelector((state: RootState) => state.companies.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);

  if (!currentCharterId) {
    return null;
  }

  const showModal = (): void => {
    setVisible(true);
  };

  const handleCancel = (): void => {
    setVisible(false);
  };

  const handleSubmit = (): void => {
    setIsRemovalLoading(true);

    const promises: Promise<APIRemoveUserFromCharterByIdResult | void>[] = [];

    const event = LogUtils.getUsersBatchUpdateEvent(
      LOGGING_EVENT.BATCH_REMOVE_USERS,
      selectedRows,
      {}, // no props since we remove the user from the chart
      currentCharterId,
      currentCompany?.id
    );
    loggingManager.logEventObject(event);
    selectedRows.forEach((user: UsersModelCharter) => {
      // one request per user to avoid bulk fail
      promises.push(
        deleteUserFromCharter(currentCharterId, user.id)
          .then((response) => {
            if (response.status === StatusCode.NO_CONTENT) {
              if (companyStats) {
                companyStats.userDeactivated();
              }
              dispatch(removeUserFromCurrentCharter(user.id));

              if (currentUser?.id === user.id) {
                dispatch(removeCurrentUserFromCurrentCharter());
              }
            }
          })
          .catch((error) => {
            log.error(error);
            message.error(t('users.removeUsers.error'));
          })
      );
    });

    Promise.all(promises).then(() => {
      setIsRemovalLoading(false);
      setVisible(false);
    });
  };

  const renderUserListItem = (user: UsersModelCharter): React.ReactNode => {
    const { email, name } = user;
    const wasUsernameSet = name !== email;

    return (
      <List.Item>
        <List.Item.Meta title={wasUsernameSet ? user.name : user.email} description={wasUsernameSet && user.email} />
      </List.Item>
    );
  };

  return (
    <>
      <Tooltip title={isDisabled ? renderUnauthorizedMessage : undefined}>
        <Button
          className="removeUserButton"
          type="primary"
          danger
          disabled={selectedRows.length === 0 || isDisabled}
          onClick={showModal}
        >
          <UserDeleteOutlined /> {t('users.removeUsersButton')}
        </Button>
      </Tooltip>
      <Modal
        wrapClassName={classNames(isRemovalLoading && classes.notClosableModal)}
        centered
        title={t('users.removeUsers.title', { count: selectedRows.length })}
        visible={visible}
        onOk={handleSubmit}
        onCancel={handleCancel}
        cancelText={t('global.cancel')}
        okText={t('global.remove')}
        okButtonProps={{ danger: true, loading: isRemovalLoading, disabled: isRemovalLoading }}
        cancelButtonProps={{ disabled: isRemovalLoading }}
        closable={!isRemovalLoading}
        maskClosable={!isRemovalLoading}
        forceRender
      >
        <p>{t('users.removeUsers.confirmRemoveQuestion', { count: selectedRows.length })}</p>
        <List
          bordered
          size="small"
          dataSource={selectedRows}
          renderItem={renderUserListItem}
          className={classes.usersList}
        />
        <p className={classes.actionDescriptionMessage}>
          {t('users.removeUsers.actionDescriptionMessage', { count: selectedRows.length })}
        </p>
      </Modal>
    </>
  );
};

export default RemoveUserFromCharterModal;
