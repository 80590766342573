import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/RootState';
import { SocketManager } from '../services/api/SocketManager';

const useSocket = (room?: string | string[], leaveRoomOnUnmount = true): boolean => {
  const token = useSelector((state: RootState) => state.app.apiToken);

  const user = useSelector((state: RootState) => {
    return state.user.user;
  });

  useEffect(() => {
    SocketManager.initiate(token);
  }, [token]);

  // Join and leave the room
  useEffect(() => {
    if (!(user && room && SocketManager.getInstance() && SocketManager.isConnected)) {
      return undefined;
    }

    const isRoomArray = Array.isArray(room);
    const joinRooms = (): void => {
      if (isRoomArray) {
        SocketManager.joinRooms(room as string[], user.id);
      } else {
        SocketManager.joinRoom(room as string, user.id);
      }
    };

    // Join the room/rooms
    joinRooms();

    return (): void => {
      if (leaveRoomOnUnmount) {
        if (isRoomArray) {
          SocketManager.leaveRooms(room as string[], user.id);
        } else {
          SocketManager.leaveRoom(room as string, user.id);
        }
      }
    };
  }, [room, user]);

  return SocketManager.getInstance() && SocketManager.isConnected;
};

export default useSocket;
