import { Button, Modal, Slider } from 'antd';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { CropperProps } from 'react-easy-crop';
import { Area, MediaSize } from 'react-easy-crop/types';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { MediaFile } from '../../services/api/types/ChartersServiceTypes';
import MediaUtils from '../../utils/MediaUtils';
import { MediaType } from '../../utils/types/MediaTypes';
import ImageMediaResizer from './ImageMediaResizer';
import VideoMediaResizer from './VideoMediaResizer';

type Props = {
  mediaToResize: MediaFile;
  format: string;
  onMediaCropped: (croppedMedia: MediaFile) => void;
  isVisible: boolean;
  onCloseModal?: () => void;
};

type StyleProps = {
  mediaType: MediaType;
};

const useStyles = createUseStyles({
  mediaResizeModal: {
    overflow: 'hidden',
    width: '75% !important',
    '& .ant-modal-body': {
      position: 'relative',
      height: '60vh',
    },
  },
  cropperContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 80,
  },
  zoomControl: ({ mediaType }: StyleProps) => ({
    position: 'absolute',
    left: '50%',
    right: '50%',
    bottom: mediaType === MediaType.IMAGE ? 20 : 10,
    transform: 'translateX(-50%)',
    width: '50%',
  }),
});

const MediaResizerModal: FunctionComponent<Props> = ({
  isVisible,
  mediaToResize,
  format,
  onMediaCropped,
  onCloseModal,
}: Props) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState<Area>();
  const [mediaSize, setMediaSize] = useState<MediaSize>();
  const aspectRatio = useMemo(() => MediaUtils.transformFormatToAspectRatio(format), [format]);
  const { t } = useTranslation();
  const classes = useStyles({ mediaType: mediaToResize.mediaType });

  const isClosable = useMemo(() => !!onCloseModal, [onCloseModal]);

  useEffect(() => {
    if (!isVisible) {
      setCroppedArea(undefined);
    }
  }, [isVisible]);

  useEffect(() => {
    // Reset the crop and the zoom to `mediaToResize.croppedArea`
    if (isVisible && mediaSize) {
      if (mediaToResize.croppedArea) {
        const { crop: mediaCrop, zoom: mediaZoom } = MediaUtils.getInitialCropFromCroppedArea(
          mediaToResize.croppedArea,
          mediaSize
        );
        setCrop(mediaCrop);
        setZoom(mediaZoom);
      } else {
        setCrop({ x: 0, y: 0 });
        setZoom(1);
      }
    }
  }, [isVisible, mediaToResize, mediaSize]);

  const onCropComplete = useCallback(
    (croppedAreaInPercentage, croppedAreaPixels) => {
      const validatedCroppedArea = MediaUtils.validateCroppedAreaForFormat(croppedAreaPixels, format, mediaToResize);
      setCroppedArea(validatedCroppedArea);
    },
    [format]
  );

  const onSubmit = (): void => {
    const croppedMedia = { ...mediaToResize, croppedArea };
    onMediaCropped(croppedMedia);
  };

  const cropperCommonProps = {
    crop,
    zoom,
    aspect: aspectRatio,
    onCropChange: setCrop,
    onCropComplete,
    onZoomChange: setZoom,
    onMediaLoaded: setMediaSize,
  } as CropperProps;

  return (
    <>
      {isVisible && (
        <Modal
          title={t('charters.mediaLibrary.mediaResizer.title')}
          visible={isVisible}
          cancelText={t('global.cancel')}
          okText={t('global.submit')}
          onCancel={onCloseModal}
          closable={isClosable}
          onOk={onSubmit}
          className={classes.mediaResizeModal}
          footer={[
            ...(isClosable // Add the cancel button only if the resizerModal is closable (if the resize is not required or one was already set)
              ? [
                  <Button key="cancelModal" onClick={onCloseModal}>
                    {t('global.cancel')}
                  </Button>,
                ]
              : []),
            <Button key="submit" onClick={onSubmit} type="primary" disabled={!croppedArea}>
              {t('global.submit')}
            </Button>,
          ]}
        >
          <div className={classes.cropperContainer}>
            {mediaToResize.mediaType === MediaType.IMAGE ? (
              <ImageMediaResizer image={mediaToResize.url} {...cropperCommonProps} />
            ) : (
              <VideoMediaResizer video={mediaToResize.url} {...cropperCommonProps} />
            )}
          </div>

          <div className={classes.zoomControl}>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(newZoom: number): void => setZoom(newZoom)}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default MediaResizerModal;
