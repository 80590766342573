import { UserAddOutlined } from '@ant-design/icons/lib';
import { Button, Form, message, Modal, Tooltip } from 'antd';
import classNames from 'classnames';
import { find, map } from 'lodash';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import WarningAlert from '../../../../../components/alert/WarningAlert';
import Active from '../../../../../components/form/Active';
import Email from '../../../../../components/form/Email';
import InputCheckBox from '../../../../../components/form/InputCheckBox';
import InputSelect from '../../../../../components/form/InputSelect';
import InputSwitch from '../../../../../components/form/InputSwitch';
import Name from '../../../../../components/form/Name';
import Password from '../../../../../components/form/Password';
import RoleRadioGroup from '../../../../../components/form/RoleRadioGroup';
import { LOGGING_EVENT } from '../../../../../Constants';
import { Role } from '../../../../../core/rule/Roles';
import { RoleList, RoleListType, Roles } from '../../../../../core/rule/RolesTypes';
import CompanyStats from '../../../../../model/CompanyStats';
import { addUserToCharter, updateCurrentUserInCharterList } from '../../../../../redux/action/ChartersAction';
import { setCompanyStats } from '../../../../../redux/action/CompaniesAction';
import { RootState } from '../../../../../redux/RootState';
import { postUserInCharter } from '../../../../../services/api/ChartersService';
import { getCompanyStatsById } from '../../../../../services/api/CompaniesService';
import { UsersModelCharter } from '../../../../../services/api/types/ChartersServiceTypes';
import { APICompanyUsers } from '../../../../../services/api/types/CompaniesServiceTypes';
import { APICreateUserParams, APIModelUser } from '../../../../../services/api/types/UsersServiceTypes';
import { createUser } from '../../../../../services/api/UsersService';
import CompaniesUtils from '../../../../../utils/CompaniesUtils';
import LogUtils from '../../../../../utils/LogUtils';
import UsersUtils from '../../../../../utils/UsersUtils';

type Props = {
  isDisabled?: boolean;
  isBundleDisabled?: boolean;
  callbackResetFilter: () => void;
  companyUsers: APICompanyUsers[];
  renderUnauthorizedMessage?: () => void;
};

const useStyles = createUseStyles({
  modal: {
    '& .ant-modal-body': {
      padding: '8px 24px',
    },
  },
  notClosableModal: {
    cursor: 'not-allowed',
    '& .ant-modal': {
      cursor: 'default',
    },
  },
});

const AddUserModal: FunctionComponent<Props> = ({
  callbackResetFilter,
  companyUsers,
  isDisabled,
  isBundleDisabled = false,
  renderUnauthorizedMessage,
}: Props) => {
  const [visible, setVisible] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isAsyncLoading, setIsAsyncLoading] = useState(false);
  const [isNewUser, setIsNewUser] = useState<boolean>();
  const [userPresentInCharter, setUserPresentInCharter] = useState<UsersModelCharter>();
  const [emailChecked, setEmailChecked] = useState<boolean>();
  const [id, setId] = useState<string>();
  const [visibleRoles, setVisibleRoles] = useState<RoleListType[]>([]);
  const [defaultRole, setDefaultRole] = useState(Roles.Creator);
  const [role, setRole] = useState(Roles.Creator);
  const [isActive, setIsActive] = useState(true);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [errorInfo, setErrorInfo] = useState();
  const [displayWarning, setDisplayWarning] = useState(false);
  const [disabledUserIds, setDisabledUserIds] = useState<string[]>();
  const [isFieldValidating, setIsFieldValidating] = useState(false);
  const [usersOptions, setUsersOptions] = useState<{ code: string; key: string }[]>([]);
  const dispatch = useDispatch();

  const currentCharterUsers = useSelector((state: RootState) => state.charters.currentCharterUsers);
  const currentCharterId = useSelector((state: RootState) => state.charters.current?.id);
  const currentCompany = useSelector((state: RootState) => state.companies.current);
  const currentUser = useSelector((state: RootState) => state.user.user);
  const currentUserRole = useSelector((state: RootState) => state.user.role);
  const companyStats = useSelector((state: RootState) => state.companies.stats);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);
  const companies = useSelector((state: RootState) => state.companies.list);
  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);

  const isUserKnlTeamInCharter = CompaniesUtils.checkIsKnlProfile(companies || []);
  const isUserDisabled = disabledUserIds && id ? disabledUserIds.includes(id) : false;
  const isCurrentUser = id && currentUser ? currentUser.id === id : false;
  const isCurrentUserPoc = currentCompany?.isUserPoc || false;

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setEmailChecked(undefined);
      setUserPresentInCharter(undefined);
      setIsNewUser(undefined);
      setIsFieldValidating(false);

      // we reset to default value if the user doesn't exist
      setId(undefined);
      setRole(defaultRole);
      setIsActive(true);
    }
  }, [visible, form, defaultRole]);

  useEffect(() => {
    const options = map(companyUsers, (users) => {
      return {
        code: users.email,
        key: users.email,
      };
    });

    const filteredOptions = options.filter((option) => {
      return find(currentCharterUsers, { email: option.code }) === undefined;
    });

    setUsersOptions(filteredOptions);
  }, [currentCharterUsers]);

  useEffect(() => {
    if (currentCharterUsers && currentCharterUsers.length && currentUserRole) {
      const disabledUsersIdsForCurrentUserRole = UsersUtils.getDisabledUsersIdsForRole(
        currentCharterUsers,
        currentUserRole
      );
      setDisabledUserIds(disabledUsersIdsForCurrentUserRole);
    }
  }, [currentUserRole, currentCharterUsers]);

  useEffect(() => {
    if (!currentUserRole || !companyStats || isFieldValidating) {
      return;
    }

    const email = form.getFieldValue('email');
    const isUserPresentAndActive =
      find(companyUsers, (user) => {
        return user.email === email;
      }) !== undefined;

    if (
      companyStats?.canCreateNewUser() ||
      (currentUserRole.isKnlTeam() && companyStats?.canLinkUserToCharters(isUserPresentAndActive))
    ) {
      setVisibleRoles(currentUserRole.getAccessibleRoles());
      setDefaultRole(Roles.Creator);
      form.setFieldsValue({ role: Roles.Creator });
      setDisplayWarning(false);
    } else if (!companyStats?.canCreateNewUser() && !currentUserRole.isKnlTeam()) {
      setVisibleRoles(currentUserRole.getAccessibleRoles());
      setDefaultRole(Roles.Creator);
      setDisplayWarning(true);
    } else if (currentUserRole.isKnlTeam()) {
      form.setFieldsValue({ role: Roles.KnlTeam });
      setDefaultRole(Roles.KnlTeam);
      setDisplayWarning(true);
      setVisibleRoles([RoleList.KnlTeam]);
    }

    // eslint-disable-next-line
  }, [companyStats, companyStats?.users.active, currentUserRole, isFieldValidating, currentCharterUsers]);

  if (!currentCharterId || !currentUserRole || !companyStats) {
    return null;
  }

  const showModal = (): void => {
    setVisible(true);
  };

  const hideModal = (resetFilter: boolean): void => {
    if (resetFilter) {
      callbackResetFilter();
    }
    setIsAsyncLoading(false);
    setVisible(false);
  };

  const callbackCheckedEmail = (isValidEmail: boolean, responseIsNewUser?: boolean, user?: APIModelUser): void => {
    if (!isValidEmail) {
      setEmailChecked(false);
      return;
    }

    setIsFieldValidating(false);
    setEmailChecked(true);
    setIsNewUser(responseIsNewUser);
    setId(user?.id);

    if (!responseIsNewUser && user) {
      const foundUser = find(currentCharterUsers, { id: user?.id });

      const isFoundUserActive = foundUser && foundUser.acl.isActive !== undefined ? foundUser.acl.isActive : true;

      const userRoleToSet = foundUser?.acl?.role || defaultRole;
      const isRolePresent = find(visibleRoles, { code: userRoleToSet }) !== undefined;
      const visibleRole = isRolePresent ? userRoleToSet : visibleRoles[visibleRoles.length - 1].code;

      setUserPresentInCharter(foundUser);
      form.setFieldsValue({
        role: visibleRole,
        isActive: isFoundUserActive,
      });
      setRole(visibleRole);
      setIsActive(isActive);
    } else {
      setRole(defaultRole);
      setIsActive(true);
      setUserPresentInCharter(undefined);

      // we reset to default value if the user doesn't exist
      form.setFieldsValue({
        role: defaultRole,
        isActive: true,
      });
    }
  };

  const handleUserEmailSelected = (value: string) => {
    if (value !== '') {
      const user = find(companyUsers, { email: value });
      if (!user) {
        return;
      }
      setEmailChecked(true);
      setIsNewUser(false);
      setRole(defaultRole);
      setId(user.id);
      setIsActive(true);
      setUserPresentInCharter(undefined);

      form.setFieldsValue({
        role: defaultRole,
        isActive: true,
      });
    }
  };

  const callbackIsValidating = (isValidating: boolean): void => {
    setIsFieldValidating(isValidating);
  };

  const callbackIsActiveChange = (value: boolean): void => {
    setIsActive(value);
    form.setFieldsValue({
      isActive: value,
    });
  };

  const callbackEmailValidationChange = (value: boolean): void => {
    setVerifyEmail(value);
    form.setFieldsValue({
      verifyEmail: value,
    });
  };

  const callbackIsProspectChange = (value: boolean): void => {
    form.setFieldsValue({
      isProspect: value,
    });
  };

  const handleSubmitError = (error: string, errorMessage: JSX.Element | string, duration?: number): void => {
    log.error(error);
    message.error(errorMessage, duration);
    setIsAsyncLoading(false);
  };

  const handleCreateSubmitSuccess = (newIsActive: boolean, msg: string): void => {
    if (newIsActive && currentCompany) {
      getCompanyStatsById(currentCompany.id).then((response) => {
        const updatedCompanyStats = new CompanyStats(response.data, currentCompany);
        dispatch(setCompanyStats(updatedCompanyStats));

        message.success(msg);
        hideModal(true);
      });
    } else {
      message.success(msg);
      hideModal(true);
    }
  };

  const handleRoleChange = (value: Roles): void => {
    setRole(value);
  };

  const handleSubmit = (): void => {
    const errorMessage = (
      <>
        {t('users.addUser.error')}
        <br />
        {t('support.global.emojiFingerRight')}
        <a href={`mailto:${t('support.global.supportEmail')}`}>{t('support.global.supportEmail')}</a>
        {t('support.global.emojiFingerLeft')}
      </>
    );

    form
      .validateFields()
      .then((values) => {
        setIsAsyncLoading(true);
        const data: APICreateUserParams = {
          id,
          email: values.email,
          name: values.name,
          password: values.password,
          role: values.role,
          isActive: values.isActive,
          charterId: currentCharterId,
          verifyEmail: isUserKnlTeamInCharter ? values.verifyEmail : true,
          isProspect: values.isProspect,
        };

        if (isNewUser) {
          createUser(data)
            .then((response) => {
              const newUser = response.data;
              const event = LogUtils.getUsersCreateOrLinkToCharterEvent(
                LOGGING_EVENT.CREATE_USER,
                newUser.id,
                data,
                currentCharterId,
                currentCompany?.id
              );
              loggingManager.logEventObject(event);

              dispatch(addUserToCharter(newUser));
              handleCreateSubmitSuccess(values.isActive, t('users.addUser.addSuccess'));
            })
            .catch((error) => {
              const errorResponse = error.response;
              const errorResponseData = errorResponse?.data;
              if (errorResponseData && errorResponseData.data && errorResponse.status === 403) {
                handleSubmitError(
                  error,
                  <>{t('users.addUser.bundleError', { role: t(`roles.names.${data.role}`) })}</>,
                  3
                );
              } else {
                handleSubmitError(error, errorMessage);
              }
            });
        } else {
          // linking the user with the charters
          postUserInCharter(data)
            .then((response) => {
              const responseUser = response.data;
              const event = LogUtils.getUsersCreateOrLinkToCharterEvent(
                LOGGING_EVENT.USER_LINK_TO_CHARTER,
                responseUser.id,
                data,
                currentCharterId,
                currentCompany?.id
              );
              loggingManager.logEventObject(event);

              dispatch(addUserToCharter(responseUser));

              if (currentUser?.id === id) {
                dispatch(updateCurrentUserInCharterList(response.data, currentCharterId));
              }
              handleCreateSubmitSuccess(values.isActive, t('users.addUser.addSuccess'));
            })
            .catch((error) => {
              const errorResponse = error.response;
              const errorResponseData = error.response?.data;
              const backEndErrorMessage = error.response?.data?.message || '';
              const userAlreadyExist = backEndErrorMessage.includes('already exists in charter');
              if (errorResponseData && errorResponseData.data && errorResponse.status === 403) {
                handleSubmitError(
                  error,
                  <>{t('users.addUser.bundleError', { role: t(`roles.names.${data.role}`) })}</>,
                  3
                );
              } else if (userAlreadyExist) {
                const errorRoleCode = error.response?.data.data.roleCode || '';
                const objectRole = find(
                  RoleList,
                  (roleFromList) => roleFromList.code.toLowerCase() === errorRoleCode.toLowerCase()
                );

                if (objectRole) {
                  const userExistRole = new Role(objectRole.code);
                  const errorMessageToDisplay = userExistRole.isKnlTeam() ? (
                    <>{t('users.addUser.errorKnlUserAlreadyPresent')}</>
                  ) : (
                    <>{t('users.addUser.errorUserAlreadyPresent')}</>
                  );

                  // User already exists error
                  handleSubmitError(error, errorMessageToDisplay);
                }
              } else {
                // Generic error
                handleSubmitError(error, errorMessage);
              }
            });
        }
      })
      .catch((validationErrorInfo) => {
        setErrorInfo(validationErrorInfo);
      });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  const canAddUser = !isCurrentUser || isCurrentUserPoc;
  const canAddProspect =
    emailChecked && isNewUser && currentUserRole.isKnlTeam() && !isUserDisabled && !isCurrentUser && canAddUser;

  // eslint-disable-next-line no-nested-ternary
  const tooltipTitle = isBundleDisabled
    ? t('users.addButtonBundleDisabled')
    : isDisabled
    ? renderUnauthorizedMessage
    : undefined;

  return (
    <>
      <Tooltip title={tooltipTitle}>
        <Button key="addUserButton" type="primary" disabled={isDisabled || isBundleDisabled} onClick={showModal}>
          <UserAddOutlined /> {t('users.addButton')}
        </Button>
      </Tooltip>
      <Modal
        width={isMobileOrTablet ? 520 : 620}
        className={classes.modal}
        wrapClassName={classNames(isAsyncLoading && classes.notClosableModal)}
        centered
        title={t('users.addUser.title')}
        visible={visible}
        onOk={handleSubmit}
        closable={!isAsyncLoading}
        maskClosable={!isAsyncLoading}
        onCancel={(): void => hideModal(false)}
        cancelButtonProps={{ disabled: isAsyncLoading }}
        cancelText={t('global.cancel')}
        okText={t('global.submit')}
        okButtonProps={{
          loading: isAsyncLoading,
          disabled:
            isAsyncLoading || isUserDisabled || isFieldValidating || !canAddUser || userPresentInCharter !== undefined,
        }}
        forceRender
      >
        {displayWarning && emailChecked && currentUserRole.isKnlTeam() && !isUserDisabled && !isCurrentUser && (
          <WarningAlert message="users.addUser.warningRole" />
        )}
        {isUserDisabled && !isCurrentUser && <WarningAlert message="users.addUser.warningUnauthorizedRole" />}
        {isCurrentUser && !isCurrentUserPoc && <WarningAlert message="users.addUser.warningCurrentUser" />}
        {!currentUserRole.isKnlTeam() && !isCurrentUser && displayWarning && (
          <WarningAlert message="users.addUser.warningLinkUser" />
        )}

        {userPresentInCharter && <WarningAlert message="users.addUser.errorUserAlreadyPresent" />}

        <Form
          form={form}
          {...formItemLayout}
          initialValues={{
            role: Roles.Creator,
            isActive: true,
            verifyEmail: false,
          }}
        >
          {!companyStats.canCreateNewUser() && !currentUserRole.isKnlTeam() ? (
            <InputSelect
              isReadOnly={false}
              name="email"
              options={usersOptions}
              onChangeCallback={handleUserEmailSelected}
              label={t('users.fields.email')}
              extra={t('users.fields.emailDesc')}
            />
          ) : (
            <Email
              callback={callbackCheckedEmail}
              callbackIsValidating={callbackIsValidating}
              visible={visible}
              errorInfo={errorInfo}
              label={t('users.fields.email')}
              extra={t('users.fields.emailDesc')}
              placeholder={t('users.fields.emailPlaceholder')}
            />
          )}

          {!userPresentInCharter &&
            emailChecked &&
            isNewUser &&
            !isUserDisabled &&
            canAddUser && [
              <Name
                key="newUserName"
                label={t('users.fields.name')}
                extra={t('users.fields.nameDesc')}
                placeholder={t('users.fields.namePlaceholder')}
              />,
              <Password
                key="newUserPassword"
                label={t('users.fields.password')}
                extra={t('users.fields.passwordDesc')}
                placeholder={t('users.fields.passwordPlaceholder')}
              />,
            ]}

          {!userPresentInCharter &&
            emailChecked &&
            !isUserDisabled &&
            canAddUser && [
              <RoleRadioGroup
                key="newUserRole"
                role={role}
                defaultRole={defaultRole}
                visibleRoles={visibleRoles}
                label={t('users.fields.role')}
                callbackHandleChange={handleRoleChange}
              />,
              <Active
                key="newUserActive"
                callbackChange={callbackIsActiveChange}
                isActive={isActive}
                label={t('users.fields.active')}
                extra={t('users.fields.activeDesc')}
              />,
            ]}
          {/* input verify Email is hidden */}
          {false && emailChecked && isNewUser && !isUserDisabled && !isCurrentUser && isUserKnlTeamInCharter && (
            <InputSwitch
              key="verifyEmail"
              name="verifyEmail"
              label={t('users.fields.verifyEmail')}
              extra={t('users.fields.verifyEmailExtra')}
              activeLabel={t('users.fields.verifyEmailActive')}
              inactiveLabel={t('users.fields.verifyEmailDisabled')}
              callbackChange={callbackEmailValidationChange}
              isChecked={verifyEmail}
            />
          )}
          {canAddProspect && (
            <InputCheckBox
              name="isProspect"
              label={t('users.fields.isProspect')}
              extra={t('users.fields.isProspectDesc')}
              callback={callbackIsProspectChange}
            />
          )}
        </Form>
      </Modal>
    </>
  );
};

export default AddUserModal;
