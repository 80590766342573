import { QuestionOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { FunctionComponent } from 'react';
import { createUseStyles } from 'react-jss';
import { THEME } from '../../Constants';

type Props = {
  startHelp: () => void;
};

const useStyles = createUseStyles({
  helpIcon: {
    marginLeft: 8,
    backgroundColor: THEME.MENU.MAIN_BACKGROUND_COLOR,
    color: THEME.MENU.MAIN_TEXT_COLOR,
    boxShadow: 'none',
    borderWidth: 0,
    fontSize: '16px !important',
    '&:hover': {
      backgroundColor: THEME.MENU.MAIN_BACKGROUND_COLOR_50_PERCENT_OPACITY,
      color: THEME.MENU.MAIN_TEXT_COLOR,
    },
    '& *': {
      fontSize: '16px !important',
      height: '100%',
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

const KannelleHelpButton: FunctionComponent<Props> = ({ startHelp }: Props) => {
  const classes = useStyles();

  return (
    <span className={classes.iconContainer}>
      <Button
        className={classes.helpIcon}
        shape="circle"
        size="small"
        icon={<QuestionOutlined />}
        onClick={startHelp}
      />
    </span>
  );
};

export default KannelleHelpButton;
