import { Badge, Empty, Tooltip, Typography } from 'antd';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { THEME } from '../../../../../Constants';
import ProjectUtils from '../../../../../utils/ProjectUtils';
import { Project, ProjectScene, ProjectSceneElement } from '../../../../../utils/types/ProjectTypes';
import { AnimationTheme } from '../../../../../utils/types/ThemeTypes';
import ProjectSceneAnimationOptions from './project-scene-options/ProjectSceneAnimationOptions';
import ProjectSceneInactiveOptions from './project-scene-options/ProjectSceneInactiveOptions';
import ProjectScenePipOptions from './project-scene-options/ProjectScenePipOptions';
import ProjectSceneSubtitleBlockOptions from './project-scene-options/ProjectSceneSubtitleBlockOptions';
import ProjectSceneVideoOptions from './project-scene-options/ProjectSceneVideoOptions';
import { VideoTrim } from './scene-timeline/utils/VideoTrimUtils';

const { Title } = Typography;

type Props = {
  project: Project;
  initialScene: ProjectScene;
  scene: ProjectScene;
  isSceneEdited: boolean;
  isSceneInError: boolean;
  selectedElement?: ProjectSceneElement;
  selectedElementId?: number;
  onSelectElement: (e: ProjectSceneElement, skipConfirm?: boolean) => void;
  sceneDuration: number;
  isLoading?: boolean;
  format?: string;
  theme: AnimationTheme;
  customColors: boolean;
  videoTrimValue: VideoTrim;
  onEditScene: (updatedScene: ProjectScene, updateInitialScene?: boolean) => void;
  onSubmitScene: (updatedScene: ProjectScene) => void;
  onIsSceneEditedChange: (isEdited: boolean) => void;
  onIsSceneInErrorChange: (isInError: boolean) => void;
  onTrimChange: (minmaxAnchors: VideoTrim) => void;
  onDeleteSceneElement: () => void;
};

const useStyles = createUseStyles({
  optionsContainer: {
    padding: 10,
    height: '100%',
  },
  optionsTitleContainer: {
    display: 'flex',
  },
  optionsTitle: {
    height: '5%',
    fontSize: '15px !important',
    lineHeight: '1.4 !important',
  },
  emptyOptions: {
    height: '95%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  options: {
    height: '95%',
    position: 'relative',
  },
  formEditedBadge: {
    paddingLeft: '10px',
    '& .ant-badge-status-dot': {
      background: THEME.DEFAULT.TEXT_COLOR_CONTRASTING_BLUE,
      width: '8px',
      height: '8px',
    },
    '& .ant-badge-status-dot::after': {
      border: `1px solid ${THEME.DEFAULT.TEXT_COLOR_CONTRASTING_BLUE}`,
    },
  },
});

const ProjectSceneOptions: FunctionComponent<Props> = ({
  project,
  initialScene,
  scene,
  selectedElement,
  selectedElementId,
  onSelectElement,
  isSceneEdited,
  isSceneInError,
  sceneDuration,
  isLoading,
  format,
  theme,
  customColors,
  videoTrimValue,
  onEditScene,
  onSubmitScene,
  onIsSceneEditedChange,
  onIsSceneInErrorChange,
  onTrimChange,
  onDeleteSceneElement,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const isActiveScene = scene.isActive;

  useEffect(() => {
    onIsSceneEditedChange(false);
    onIsSceneInErrorChange(false);
  }, [initialScene]);

  const renderOptionsTitle = () => {
    if (!isActiveScene) {
      return null;
    }

    const title =
      selectedElement && !isLoading
        ? t(selectedElement.titleKey)
        : t('charters.projects.projectEditor.sceneEditor.options.default');

    return (
      <div className={classes.optionsTitleContainer}>
        <Title level={5} className={classes.optionsTitle}>
          {title}
        </Title>

        {isSceneEdited && (
          <Tooltip title={t('charters.projects.projectEditor.sceneEditor.unsavedChangesTooltip')}>
            <Badge className={classes.formEditedBadge} status="processing" />
          </Tooltip>
        )}
      </div>
    );
  };

  const renderEmptyOption = () => {
    return (
      <div className={classes.emptyOptions}>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t('charters.projects.projectEditor.sceneEditor.noSelectedElement')}
        />
      </div>
    );
  };

  const renderOptionsForSelectedElement = () => {
    if (!isActiveScene) {
      return <ProjectSceneInactiveOptions scene={scene} format={format} onSubmitScene={onSubmitScene} />;
    }

    if (!selectedElement) {
      return renderEmptyOption();
    }

    let Component = <></>;
    if (ProjectUtils.isVideoSelected(selectedElement)) {
      Component = (
        <ProjectSceneVideoOptions
          initialScene={initialScene}
          scene={scene}
          isSceneEdited={isSceneEdited}
          format={format}
          onEditScene={onEditScene}
          onSubmitScene={onSubmitScene}
          onIsSceneEditedChange={onIsSceneEditedChange}
          sceneDuration={sceneDuration}
          onTrimChange={onTrimChange}
          videoTrimValue={videoTrimValue}
          onSelectElement={onSelectElement}
          onDeleteSceneElement={onDeleteSceneElement}
        />
      );
    }

    if (ProjectUtils.isAnimationSelected(selectedElement)) {
      Component = (
        <ProjectSceneAnimationOptions
          initialScene={initialScene}
          scene={scene}
          isSceneEdited={isSceneEdited}
          isSceneInError={isSceneInError}
          sceneDuration={sceneDuration}
          format={format}
          theme={theme}
          customColors={customColors}
          onEditScene={onEditScene}
          onSubmitScene={onSubmitScene}
          onIsSceneEditedChange={onIsSceneEditedChange}
          onIsSceneInErrorChange={onIsSceneInErrorChange}
        />
      );
    }

    if (ProjectUtils.isPipSelected(selectedElement)) {
      Component = (
        <ProjectScenePipOptions
          initialScene={initialScene}
          scene={scene}
          isSceneEdited={isSceneEdited}
          isSceneInError={isSceneInError}
          sceneDuration={sceneDuration}
          format={format}
          onEditScene={onEditScene}
          onSubmitScene={onSubmitScene}
          onIsSceneEditedChange={onIsSceneEditedChange}
          onSelectElement={onSelectElement}
          onDeleteSceneElement={onDeleteSceneElement}
        />
      );
    }

    if (ProjectUtils.areSubtitlesSelected(selectedElement) && selectedElementId && scene.subtitles) {
      Component = (
        <ProjectSceneSubtitleBlockOptions
          project={project}
          initialScene={initialScene}
          scene={scene}
          selectedElementId={selectedElementId}
          subtitles={scene.subtitles}
          isSceneEdited={isSceneEdited}
          isSceneInError={isSceneInError}
          onEditScene={onEditScene}
          onIsSceneEditedChange={onIsSceneEditedChange}
          onSelectElement={onSelectElement}
        />
      );
    }

    return <div className={classes.options}>{Component}</div>;
  };

  return (
    <div className={classes.optionsContainer}>
      {renderOptionsTitle()}
      {isActiveScene && isLoading ? renderEmptyOption() : renderOptionsForSelectedElement()}
    </div>
  );
};

export default ProjectSceneOptions;
