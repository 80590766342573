import axios, { AxiosInstance, AxiosRequestConfig, CancelTokenSource } from 'axios';
import { cloneDeep } from 'lodash';
import { AXIOS_PARAMS } from '../../Constants';

export enum StatusCode {
  SUCCESS = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

export class APIManager {
  private static instance?: AxiosInstance;

  static accessToken?: string;

  static setAccessToken = (accessToken: string): void => {
    APIManager.accessToken = accessToken;
  };

  static clearAccessToken = (): void => {
    APIManager.accessToken = undefined;
  };

  static getInstance = (): AxiosInstance => {
    if (!APIManager.instance) {
      const APIBaseURL = process.env.REACT_APP_API_BASE_URL!;

      APIManager.instance = axios.create({
        baseURL: `${APIBaseURL}`,
        timeout: AXIOS_PARAMS.TIMEOUT_IN_MS,
        headers: {
          Authorization: `Bearer ${APIManager.accessToken}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
    }

    return APIManager.instance!;
  };

  static getBasicInstance = (): AxiosInstance => {
    const APIBaseURL = process.env.REACT_APP_API_BASE_URL!;
    const basicUsername = process.env.REACT_APP_BASIC_USERNAME!;
    const basicPassword = process.env.REACT_APP_BASIC_PASSWORD!;

    return axios.create({
      baseURL: `${APIBaseURL}`,
      timeout: AXIOS_PARAMS.TIMEOUT_IN_MS,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      auth: {
        username: basicUsername,
        password: basicPassword,
      },
    });
  };

  static getCancelToken = (): CancelTokenSource => {
    const { CancelToken } = axios;
    return CancelToken.source();
  };

  static overrideAxiosInstance = (instance: AxiosInstance, axiosOverride: AxiosRequestConfig): AxiosInstance => {
    const newInstance = cloneDeep(instance) as AxiosInstance;
    if (axiosOverride) {
      Object.entries(axiosOverride).forEach(([key, value]) => {
        newInstance.defaults[key as keyof AxiosRequestConfig] = value;
      });
    }

    return newInstance;
  };
}
