import React, { FunctionComponent } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ChartersTable from '../../components/charters-table/ChartersTable';
import { LINK } from '../../Constants';
import useCanAccess from '../../hooks/useCanAccess';
import { RootState } from '../../redux/RootState';
import { PermissionList } from '../../utils/types/CharterPermissionTypes';

const useStyles = createUseStyles({
  tableContainer: {
    padding: '20px',
  },
});

const ChartersList: FunctionComponent = () => {
  const history = useHistory();
  const classes = useStyles();
  const company = useSelector((state: RootState) => state.companies.current);

  const { hasUserAccessToCompany } = useCanAccess(PermissionList.WEB_DASHBOARD);

  if (!(company && hasUserAccessToCompany(company.id))) {
    history.push(LINK.UNAUTHORIZED.path);
  }

  return (
    <div className={classes.tableContainer}>
      <ChartersTable from={LINK.CHARTERS.key} />
    </div>
  );
};

export default ChartersList;
