import { LoginOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LINK } from '../../../Constants';

type Props = {
  classes: Record<string, string>;
};

const AdminChartersCard: FunctionComponent<Props> = ({ classes }: Props) => {
  const { t } = useTranslation();
  const { path, title } = LINK.ADMIN_CHARTERS;

  return (
    <Card
      className={classes.card}
      title={
        <div className={classes.titleContainer}>
          <UnorderedListOutlined className={classes.icon} /> {t(title)}
        </div>
      }
      extra={
        <Link to={path}>
          <Button type="primary" icon={<LoginOutlined />}>
            {t('global.go')}
          </Button>
        </Link>
      }
    >
      {t('admin.charters.desc')}
    </Card>
  );
};

export default AdminChartersCard;
