import { LoadingOutlined } from '@ant-design/icons';
import { Button, Card, Tooltip } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import classNames from 'classnames';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import KannelleRetryButton from '../retry-button/KannelleRetryButton';
import KannelleSwitch from '../switch/KannelleSwitch';

type Props = {
  uploadFile: UploadFile;
  onRetryUploadFile: () => void;
  processingMessage: string;
  processingErrorMessage: string;
  trigger?: 'switch' | 'button';
};

const useStyles = createUseStyles({
  card: {
    boxShadow: '0 8px 8px 0 hsla(0, 0%, 0%, 0.15) !important',
    overflow: 'hidden',

    display: 'flex',
    flexDirection: 'column',

    height: '100%',
    wordBreak: 'break-word',
    width: '100%',

    '& .ant-card-body': {
      color: '#808080',

      '& p': {
        flexGrow: 1,
      },
      padding: '8px 8px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
  },
  imageFileCoverContainer: {
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    height: 200,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',

    backgroundColor: '#808080',
    color: '#F0F0F0',
  },
  fileCoverLoader: {
    fontSize: 32,
    marginBottom: 16,
  },
  ellipsisP: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  pNoWrap: {
    whiteSpace: 'nowrap',
  },
  selectButton: {
    margin: 'auto',
    width: 'fit-content',
  },
});

const ProcessingFileCard: FunctionComponent<Props> = ({
  uploadFile,
  onRetryUploadFile,
  processingMessage,
  processingErrorMessage,
  trigger,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const myNodeRef = useCallback((node: HTMLParagraphElement) => {
    if (node) {
      setIsEllipsisActive(node.offsetWidth < node.scrollWidth);
    }
  }, []);

  const fileName = uploadFile.fileName || uploadFile.name;
  const { progress, error } = uploadFile.response.data;

  return (
    <Card
      className={classNames(classes.card)}
      cover={
        <div className={classes.imageFileCoverContainer}>
          {error ? (
            <>
              <p>{processingErrorMessage}</p>
              <KannelleRetryButton onClick={onRetryUploadFile} displayLabel />
            </>
          ) : (
            <>
              <LoadingOutlined className={classes.fileCoverLoader} />
              {progress !== undefined && <p>{Math.floor(progress * 100).toFixed(0)}%</p>}
              <p>{processingMessage}</p>
            </>
          )}
        </div>
      }
    >
      {isEllipsisActive ? (
        <Tooltip title={fileName}>
          <p ref={myNodeRef} className={classes.ellipsisP}>
            {fileName}
          </p>
        </Tooltip>
      ) : (
        <p className={classes.pNoWrap} ref={myNodeRef}>
          {fileName}
        </p>
      )}

      {trigger === 'switch' && (
        <KannelleSwitch
          disabled
          isChecked
          size="small"
          labelActivated="form.activated"
          labelDeactivated="form.deactivated"
        />
      )}

      {trigger === 'button' && (
        <Button shape="round" className={classes.selectButton} disabled>
          {t('global.select')}
        </Button>
      )}
    </Card>
  );
};

export default ProcessingFileCard;
