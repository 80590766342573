import { CancelTokenSource } from 'axios';
import { APIManager } from './APIManager';
import {
  APIGetTranslationsResult,
  APIPatchTranslation,
  APIPatchTranslationsResult,
} from './types/TranslationServiceTypes';

const getTranslations = (cancelSource?: CancelTokenSource): Promise<APIGetTranslationsResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(`/translations`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const getTranslationsByCode = (filter: string, cancelSource?: CancelTokenSource): Promise<APIGetTranslationsResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(`/translations?translationCode=${filter}`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const patchTranslations = (
  data: APIPatchTranslation,
  cancelSource?: CancelTokenSource
): Promise<APIPatchTranslationsResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.patch(`/translations/${data.id}`, data, {
    cancelToken: cancelSource?.token || undefined,
  });
};

export { getTranslations, getTranslationsByCode, patchTranslations };
