export enum NODE_ENV {
  PRODUCTION = 'production',
  DEVELOPMENT = 'development',
}

export const MEDIA_SIZE_LIMIT = 250;

export const THEME = {
  GLOBAL: {
    MAIN_HIGHLIGHT_BACKGROUND_COLOR: '#DCEEFB',
    MAIN_TITLE_COLOR: '#092E47',
  },
  MENU: {
    BACKGROUND: '#ffffff',
    // SELECTED MENU ITEM OR HOVER COLOR
    MAIN_COLOR: '#3371FF',
    MAIN_TEXT_COLOR: '#003E6B',
    MAIN_BACKGROUND_COLOR: '#DCEEFB',
    MAIN_BACKGROUND_COLOR_50_PERCENT_OPACITY: 'rgba(220, 238, 251, 0.5)',
    // NOT SELECTED ITEM
    DEFAULT_TEXT_COLOR: '#222222',
    DEFAULT_ICON_COLOR: '#7E7E7E',
    // FONT SIZE
    FONT_SIZE_SMALL_TEXT: 12,
    FONT_SIZE_TEXT: 14,
    FONT_SIZE_ICON: 18,
    // VERSION NUMBER
    VERSION_FONT_SIZE: 'smaller',
    VERSION_TEXT_COLOR: 'gray',
    // MOBILE OR TABLET MENU
    CLOSE_MOBILE_MENU_BUTTON_COLOR: '#2680C2',
  },
  DEFAULT: {
    // NOT SELECTED ITEM
    DEFAULT_GREY_TEXT_COLOR: '#222222',
    // DEFAULT KANNELLE MAIN COLOR
    MAIN_COLOR: '#3371FF',
    MAIN_COLOR_GREEN: '#00BCAB',
    MAIN_TEXT_COLOR: '#0A558C',
    TEXT_COLOR_CONTRASTING_BLUE: '#1B3EDF', // to investigate
    FADE_COLOR: '#DCEEFB',
    HOVERED_LINK: '#5c92ff',
    BORDER_COLOR: '#D9D9D9',
    VALID_COLOR: '#00BCAB',
    ERROR: '#f46671',
    DANGER_COLOR: '#FF4D4F',
    WARNING_COLOR: '#FFA500',
    PREFORMATTED_STYLE: {
      padding: '.2em .4em',
      margin: '0',
      fontSize: '75%',
      backgroundColor: 'rgba(27,31,35,.05)',
      borderRadius: '3px',
      fontFamily: 'SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace',
      width: 'fit-content',
    },
    PREFORMATTED_IN_TOOLTIP: {
      BACKGROUND_COLOR: 'rgba(106, 106, 106, 0.4)',
      FONT_STYLE: 'italic',
    },
    INFO_ALERT: {
      BACKGROUND_COLOR: '#F7F7F7',
      BORDER_COLOR: '#D2D2D2',
    },
    UNAUTHORIZED: {
      ERROR: '#f46671',
    },
  },
  HOME: {
    STATISTICS: {
      VALID_COLOR: '#00BCAB',
      INVALID_COLOR: '#F1C40F',
      DEFAULT_COLOR: '#222222',
    },
  },
  CHARTERS: {
    ROLES: {
      KnlTeam: {
        TEXT_COLOR: '#0E5814',
        BACKGROUND_COLOR: '#E3F9E5',
      },
      Admin: {
        TEXT_COLOR: '#7C5E10',
        BACKGROUND_COLOR: '#FCEFC7',
      },
      Owner: {
        TEXT_COLOR: '#0B4F71',
        BACKGROUND_COLOR: '#D1EEFC',
      },
      Creator: {
        TEXT_COLOR: '#000000A6',
        BACKGROUND_COLOR: '#EFEDED',
      },
    },
    USERS: {
      INACTIVE: {
        TEXT_COLOR: '#610316',
        BACKGROUND_COLOR: '#FFE3E3',
      },
    },
  },
  ALERT: {
    ERROR_MAIN: '#610316',
    ERROR_BACKGROUND: '#FFE3E3',

    WARNING_MAIN: '#7C5E10',
    WARNING_BACKGROUND: '#FFFAEB',
  },
  PROJECTS: {
    EDITOR: {
      TIMELINE: {
        ELEMENT_BACKGROUND: '#5c83ee',
        GENERATED_ELEMENT_BACKGROUND: '#27AB83',
        VIDEO_EXCLUDED_BACKGROUND: `
          repeating-linear-gradient(
            45deg,
            #adc6ff,
            #adc6ff 10px,
            #9cb1e0 10px,
            #9cb1e0 20px
          );`,
        SELECTED_ELEMENT_BORDER: '#1B3EDF',
        GENERATED_SELECTED_ELEMENT_BORDER: '#0C6B58',
        TRIM_ANCHOR_ANIMATION_NO_DELAY: '0s',
        TRIM_ANCHOR_ANIMATION_NO_DURATION: '0s',
        TRIM_ANCHOR_ANIMATION_FUNCTION: 'cubic-bezier(0.42, 0, 0.58, 1.0)',
        BUTTON_BACKGROUND: '#EDEFF3',
        BUTTON_BACKGROUND_HOVER: '#D0D0D0',
        BUTTON_BACKGROUND_ACTIVE: '#C0C0C0',
        BUTTON_BORDER_ACTIVE: '#B0B0B0',
      },
    },
  },
};

export const CHARTER_USER = {
  PAGINATION: {
    PAGE_SIZE: 20,
  },
  SUBTITLES_CONSUMPTION_FILTER: {
    MARKS: {
      0: { key: '0s' },
      3600: { key: '1h' },
      7200: { key: '2h' },
      10800: { key: '3h' },
      18000: { key: '5h+' },
    },
  },
};

export const DEVICE_SIZES_QUERIES = {
  MOBILE_OR_TABLET: '(max-width: 768px)',
  INTERMEDIATE: '(max-width: 1020px)',
  EXTRA_LARGE: '(max-width: 1160px)',
  EXTRA_LARGER: '(min-width: 1200px)',
  LARGE: ' (min-width: 996px)',
  MEDIUM: ' (min-width: 769px) and (max-width: 995px)',
  SMALL_LAPTOP: '(max-width: 1260px)',
  HUGE: '(max-width: 1400px)',
  LAPTOP: '(max-width: 1480px)',
};

export const AXIOS_PARAMS = {
  TIMEOUT_IN_MS: 15000,
  TIMEOUT_IN_MS_LARGE_FOR_HEAVY_OPERATIONS: 30000,
};

export const LANGUAGE = {
  FRENCH: {
    key: 'language.french',
    code: 'fr-FR',
    flag: '🇫🇷',
  },
  ENGLISH: {
    key: 'language.english',
    code: 'en-US',
    flag: '🇬🇧',
  },
};

export const KANNELLE_PRODUCT = {
  MAX_SUBTITLES_SECONDS_PER_MONTH: 7200, // 2 hours
};

export type RouteLink = { key: string; path: string; title: string; associatedPaths?: string[] };

export const LINK: Record<string, RouteLink> = {
  HOME: {
    key: '/home',
    path: '/home',
    title: 'menu.home',
  },
  CHARTERS: {
    key: '/charters',
    path: '/charters',
    title: 'menu.charters',
  },
  CHARTER: {
    key: '/charters/:charterId',
    path: '/charters/:charterId',
    title: 'menu.charters',
  },
  CHARTERS_CREATION: {
    key: '/charters/creation',
    path: '/charters/creation',
    title: 'charters.creation.menuTitle',
  },
  CHARTER_USERS: {
    key: '/charters/:charterId/users',
    path: '/charters/:charterId(\\d+)/users',
    title: 'users.title',
  },
  CHARTER_UPDATE: {
    key: '/charters/:charterId/update',
    path: '/charters/:charterId(\\d+)/update',
    title: 'charters.update.title',
  },
  CHARTER_GRAPHICAL_ELEMENTS: {
    key: '/charters/:charterId/elements',
    path: '/charters/:charterId(\\d+)/elements',
    title: 'charters.graphicalElements.title',
  },
  CHARTER_FONTS: {
    key: '/charters/:charterId/elements/fonts',
    path: '/charters/:charterId(\\d+)/elements/fonts',
    title: 'charters.fonts.title',
  },
  CHARTER_MEDIA_LIBRARY: {
    key: '/charters/:charterId/elements/media-library',
    path: '/charters/:charterId(\\d+)/elements/media-library',
    title: 'charters.mediaLibrary.title',
  },
  CHARTER_PERMISSIONS: {
    key: '/charters/:charterId/permissions',
    path: '/charters/:charterId(\\d+)/permissions',
    title: 'charters.permissions.title',
  },
  CHARTER_SCENARIOS: {
    key: '/charters/:charterId/scenarios',
    path: '/charters/:charterId(\\d+)/scenarios',
    associatedPaths: ['/charters/:charterId(\\d+)/scenarios/editor'],
    title: 'charters.scenarios.title',
  },
  CHARTER_SCENARIO_VARIANT_EDITOR: {
    key: '/charters/:charterId/scenarios/editor',
    path: '/charters/:charterId(\\d+)/scenarios/editor',
    title: 'charters.scenarios.variantEditor.title',
  },
  CHARTER_PROJECTS: {
    key: '/charters/:charterId/projects',
    path: '/charters/:charterId(\\d+)/projects',
    associatedPaths: ['/charters/:charterId(\\d+)/projects/editor'],
    title: 'charters.projects.title',
  },
  CHARTER_PROJECT_EDITOR: {
    key: '/charters/:charterId/projects/editor',
    path: '/charters/:charterId(\\d+)/projects/editor',
    title: 'charters.projects.editor.title',
  },
  ASSETS_LOGOS: {
    key: '/charters/:charterId/elements/logos',
    path: '/charters/:charterId(\\d+)/elements/logos',
    title: 'charters.logo.title',
  },
  ASSETS_INTROS: {
    key: '/charters/:charterId/elements/intros',
    path: '/charters/:charterId(\\d+)/elements/intros',
    title: 'charters.intro.title',
  },
  ASSETS_OUTROS: {
    key: '/charters/:charterId/elements/outros',
    path: '/charters/:charterId(\\d+)/elements/outros',
    title: 'charters.outro.title',
  },
  ASSETS_MUSICS: {
    key: '/charters/:charterId/elements/musics',
    path: '/charters/:charterId(\\d+)/elements/musics',
    title: 'charters.music.title',
  },
  UNAUTHORIZED: {
    key: '/unauthorized',
    path: '/unauthorized',
    title: '',
  },
  SUBSCRIPTION_CANCELLED: {
    key: '/subscription-cancelled',
    path: '/subscription-cancelled',
    title: '',
  },
  ADMIN: {
    key: '/admin',
    path: '/admin',
    title: 'admin.title',
  },
  ADMIN_TRANSLATION: {
    key: '/admin/translation',
    path: '/admin/translation',
    title: 'admin.translation.title',
  },
  ADMIN_BUNDLES: {
    key: '/admin/checkout-bundles',
    path: '/admin/checkout-bundles',
    title: 'admin.bundles.title',
  },
  ADMIN_CHARTERS: {
    key: '/admin/charters',
    path: '/admin/charters',
    title: 'admin.charters.title',
  },
  ADMIN_BUNDLES_EDIT: {
    key: '/admin/bundles/:bundleId(\\d+)',
    path: '/admin/bundles/:bundleId(\\d+)',
    title: 'admin.bundles.title',
  },
  USERS: {
    key: '/users',
    path: '/users',
    title: 'menu.users',
  },
  COMPANY_ANALYTICS: {
    key: '/company/analytics',
    path: '/company/analytics',
    title: 'menu.analytics',
  },
  // Disabled for now - SELFCHECKOUT-TAG
  // COMPANY_BUNDLES: {
  //   key: '/company/checkout-bundles',
  //   path: '/company/checkout-bundles',
  //   title: 'menu.checkout-bundles',
  // },
  // COMPANY_CURRENT_PLAN: {
  //   key: '/company/current-plan',
  //   path: '/company/current-plan',
  //   title: 'menu.currentPlan',
  // },
};

export const SUBSCRIPTION_TYPE = {
  TRIAL: {
    code: 'trial',
    key: 'company.form.subscriptionType.trial',
  },
  ONE_SHOT: {
    code: 'one-shot',
    key: 'company.form.subscriptionType.oneshot',
  },
  SUBSCRIPTION: {
    code: 'subscription',
    key: 'company.form.subscriptionType.subscription',
  },
};

export const SUBSCRIPTION_PLAN = {
  ENTERPRISE: {
    code: 'enterprise',
    key: 'company.form.subscriptionPlan.enterprise',
  },
  STARTUP: {
    code: 'startup',
    key: 'company.form.subscriptionPlan.startup',
  },
  NGO: {
    code: 'NGO',
    key: 'company.form.subscriptionPlan.ngo',
  },
  SERVICE_EXCHANGE: {
    code: 'service_exchange',
    key: 'company.form.subscriptionPlan.serviceExchange',
  },
  EDUCATION: {
    code: 'education',
    key: 'company.form.subscriptionPlan.education',
  },
  GOVERNMENT: {
    code: 'government',
    key: 'company.form.subscriptionPlan.government',
  },
  CHURN: {
    code: 'churn',
    key: 'company.form.subscriptionPlan.churn',
  },
};

export const CHARTER = {
  DEFAULT_COLORS: ['#000000', '#FFFFFF'],
  CORPORATE_COLORS: [THEME.DEFAULT.MAIN_COLOR, THEME.DEFAULT.MAIN_COLOR_GREEN],
};

export const LAYER_TYPE = {
  TEXT: 5,
  SOLID: 1,
  SHAPE: 4,
  UNKNOWN: 0,
};

export const THEME_KEY = {
  ALGIERS: {
    code: 'ALGIERS',
    labelCode: 'charters.themes.labels.algiers',
  },
  BALI: {
    code: 'BALI',
    labelCode: 'charters.themes.labels.bali',
  },
  GENEVA: {
    code: 'GENEVA',
    labelCode: 'charters.themes.labels.geneva',
  },
  LOS_ANGELES: {
    code: 'LOS_ANGELES',
    labelCode: 'charters.themes.labels.losAngeles',
  },
  SEATTLE: {
    code: 'SEATTLE',
    labelCode: 'charters.themes.labels.seattle',
  },
  BERLIN: {
    code: 'BERLIN',
    labelCode: 'charters.themes.labels.berlin',
  },
  BOGOTA: {
    code: 'BOGOTA',
    labelCode: 'charters.themes.labels.bogota',
  },
};

export const LOGGING_EVENT = {
  // App
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  CHANGE_CURRENT_LANGUAGE: 'Change current language',
  // Company
  CHANGE_CURRENT_COMPANY: 'Change current company',
  CLONE_COMPANY: 'Clone company',
  CREATE_COMPANY: 'Create company',
  UPDATE_COMPANY: 'Update company',
  // Charter
  CREATE_CHARTER: 'Create charter',
  UPDATE_CHARTER: 'Update charter',
  // Charter-theme
  INITIALIZE_CHARTER_THEME: 'Initialize charter theme',
  SAVE_CHARTER_THEME: 'Save charter theme',
  // User
  CREATE_USER: 'Create user',
  UPDATE_USER: 'Update user',
  LINK_USER_POC: 'Link user POC to company',
  USER_LINK_TO_CHARTER: 'User link to charter',
  BATCH_UPDATE_USERS_ROLE: 'Batch update users role',
  BATCH_UPDATE_USERS_IS_ACTIVE: 'Batch update users isActive',
  BATCH_REMOVE_USERS: 'Batch remove users',
  FILTER_USERS: 'Filter Users',
  SORT_USERS: 'Sort Users',
  // Assets
  UPLOAD_ASSET: 'Upload asset',
  UPDATE_ASSET: 'Update asset',
  // Fonts
  UPDATE_CHARTER_FONTS: 'Update charter fonts',
  REINITIALIZE_CHARTER_FONTS: 'Reinitialize charter fonts',
  UPLOAD_CHARTER_FONT: 'Upload charter font',
  // Media
  UPLOAD_MEDIA: 'Upload charter media',
  UPDATE_MEDIA: 'Update charter media',
  // Notification configs
  UPDATE_NOTIFICATION_CONFIG: 'Update notification config',
  UPDATE_NOTIFICATION_PARAMETERS: 'Update notification parameters',
  // Permissions
  UPDATE_CHARTER_PERMISSION: 'Update charter permission',
  UPDATE_CHARTER_PERMISSION_VALUES: 'Update charter permission values',
  // Onboarding
  ONBOARDING_FINISHED: 'Onboarding finished',
  ONBOARDING_REPLAYED: 'Onboarding replayed',
  // Help
  OPEN_EXTERNAL_HELP: 'Open external help',
  // Scenarios
  UPDATE_SCENARIO_METADATA: 'Update scenario metadata',
  CREATE_SCENARIO: 'Create scenario',
  CREATE_SCENARIO_VARIANT: 'Create scenario variant',
  UPDATE_SCENARIO_VARIANT_SCENE_FIELD: 'Update scenario variant scene field',
  REORDER_SCENARIO_VARIANT_SCENES: 'Reorder scenario variant scenes',
  ADD_SCENARIO_VARIANT_SCENE: 'Add scenario variant scene',
  DELETE_SCENARIO_VARIANT_SCENE: 'Delete scenario variant scene',
  DUPLICATE_SCENARIO_VARIANT_SCENE: 'Duplicate scenario variant scene',
  UPDATE_SCENARIO_VARIANT_METADATA: 'Update scenario variant metadata',
  SCENARIO_VARIANT_PDF_DOWNLOAD: 'Scenario variant PDF download',
  // Project
  NEW_PROJECT: 'New project',
  SELECT_PROJECT_SCENE: 'Select Scene',
  FINALIZING_PROJECT: 'Finalizing Project',
  FINALIZED_PROJECT: 'Finalize Project',
  FINALIZING_ERROR_PROJECT: 'Finalizing Error Project',
  ADD_SLIDE: 'Add Slide',
  UNUSE_SLIDE: 'Unuse Slide',
  DUPLICATE_PROJECT_SCENE: 'Duplicate Scene',
  DELETE_PROJECT_SCENE: 'Delete Scene',
  REORDER_PROJECT_SCENES: 'Dragged Dropped Scene',
  // Public share link
  GENERATE_PUBLIC_LINK: 'Generate Public Link',
  OPEN_PUBLIC_LINK: 'Open Public Link',
  COPY_PUBLIC_LINK: 'Copy Public Link',
  // Analytics
  FETCH_COMPANY_ANALYTICS: 'Fetch company analytics',
  COMPANY_ANALYTICS_EVENT_TYPE_CHANGED: 'Company analytics event type changed',
  COMPANY_ANALYTICS_CSV_DOWNLOAD: 'Company analytics CSV download',
  // Bridge Pitchy
  CONNECT_TO_PITCHY: 'Connect to Pitchy',
};

export const EMAIL_LANGUAGE_OPTIONS = {
  BROWSER_LANGUAGE: {
    code: '$BROWSER_LANGUAGE',
    key: 'notificationConfigs.parameters.emailLanguages.browserLanguage',
  },
  FRENCH: {
    code: 'fr',
    key: 'notificationConfigs.parameters.emailLanguages.french',
  },
  ENGLISH: {
    code: 'en',
    key: 'notificationConfigs.parameters.emailLanguages.english',
  },
};

export const EMAIL_SENDER_OPTIONS = {
  DEFAULT: {
    code: 'default',
    key: 'notificationConfigs.parameters.emailSender.default',
  },
  CURRENT_USER: {
    code: '$CURRENT_USER',
    key: 'notificationConfigs.parameters.emailSender.currentUser',
  },
};

export type PermissionSection = {
  code: string;
  color: string;
  key: string;
  order: number;
};

export const PERMISSIONS_SECTIONS: Record<string, PermissionSection> = {
  WEB: {
    code: 'WEB',
    color: '#264653',
    key: 'charters.permissions.sections.web',
    order: 1,
  },
  GRAPHICAL_CHARTER: {
    code: 'GRAPHICAL_CHARTER',
    color: '#e97c61',
    key: 'charters.permissions.sections.graphicalCharter',
    order: 2,
  },
  SCENARIO: {
    code: 'SCENARIO',
    color: '#f4a261',
    key: 'charters.permissions.sections.scenario',
    order: 3,
  },
  VIDEO_FORMAT: {
    code: 'VIDEO_FORMAT',
    color: '#8ab17d',
    key: 'charters.permissions.sections.videoFormat',
    order: 4,
  },
  THEME: {
    code: 'THEME',
    color: '#e9c46a',
    key: 'charters.permissions.sections.theme',
    order: 5,
  },
  FRAME: {
    code: 'FRAME',
    color: '#2a9d8f',
    key: 'charters.permissions.sections.frame',
    order: 6,
  },
  PROJECT: {
    code: 'PROJECT',
    color: '#ef9d88',
    key: 'charters.permissions.sections.project',
    order: 7,
  },
  MUSIC: {
    code: 'MUSIC',
    color: '#287271',
    key: 'charters.permissions.sections.music',
    order: 8,
  },
};

export const TRANSLATION_CATEGORIES: Record<string, string> = {
  FAQ: '#2c3e50',
  NOTIFICATIONCONFIG: '#e67e22',
  PERMISSION: '#e74c3c',
  ADVANTAGE: '#1a5da9',
};

export enum WEB_SOCKET_COMPANY_ACTIONS {
  COMPANY_PATCH = 'company_patch',
}

export const WEB_SOCKET_ACTIONS = {
  COMPANY_GLOBAL: ['company_subscription_post'],
  CHARTER_GLOBAL_ASSETS: [
    'logo_put',
    'intro_put',
    'outro_put',
    'music_put',
    'logo_patch',
    'intro_patch',
    'outro_patch',
    'music_patch',
  ],
  COMPANY: WEB_SOCKET_COMPANY_ACTIONS,
  CHARTER_GLOBAL_FONT: ['font_put'],
  CHARTER_GLOBAL_MEDIA: ['media_put', 'media_patch'],
  CHARTER_LOCAL_SCENARIOS: ['scenario_patch', 'scenario_put', 'scenario_delete'],
  CHARTER_LOCAL_SCENARIO_VARIANTS: [
    'scenario_variant_patch',
    'scenario_variant_put',
    'scenario_variant_delete',
    'scenario_variant_metadata_patch',
  ],
  CHARTER_LOCAL_SCENARIO_VARIANTS_EDITOR: [
    'scenario_variant_patch',
    'scenario_variant_metadata_patch',
    'scenario_variant_delete',
  ],
  CHARTER_LOCAL_PERMISSIONS: ['permission_put', 'permission_patch'],
  CHARTER_LOCAL_PROJECTS: ['project_patch', 'project_delete', 'project_put', 'finalvideo_put', 'finalvideo_error'],
  GLOBAL_PERMISSIONS: ['permission_put', 'permission_delete'],
};

export const SCENARIO_COVER_FORMAT = '3:1';

export const ANALYTICS = {
  AGGREGATION_TYPES: {
    TOTAL: { code: 'user', key: 'analytics.totalCount' },
    TOTAL_PER_AU: { code: 'total_per_au', key: 'analytics.totalByAU' },
  },
  EVENT_TYPES: {
    NB_SESSION: { code: 'session_start', key: 'analytics.sessionStartCount' },
    NB_FINALIZE: { code: 'finalize_project', key: 'analytics.finalizeCount' },
    NB_NEW_PROJECTS: { code: 'new_project', key: 'analytics.newProjectsCount' },
  },
  TIME_RANGES: {
    THREE_LAST_MONTHS: { code: 'THREE_LAST_MONTHS', key: 'analytics.timeRangeThreeMonths' },
    SIX_LAST_MONTHS: { code: 'SIX_LAST_MONTHS', key: 'analytics.timeRangeSixMonths' },
    TWELVE_LAST_MONTH: { code: 'TWELVE_LAST_MONTHS', key: 'analytics.timeRangeTwelveMonths' },
  },
  PAGINATION: {
    PAGE_SIZE: 10,
  },
};

export const CHARGEBEE_PRICING_MODEL = {
  FLAT_FEE: 'flat_fee',
  PER_UNIT: 'per_unit',
  TIERED: 'tiered',
  VOLUME: 'volume',
  STAIR_STEP: 'stairstep',
};

export const CHARGEBEE_SUBSCRIPTION_STATUS = {
  FUTURE: 'future',
  IN_TRIAL: 'in_trial',
  ACTIVE: 'active',
  NON_RENEWING: 'non_renewing',
  PAUSED: 'paused',
  CANCELLED: 'cancelled',
};

export const PROJECTS = {
  TRIM: {
    ANCHOR_WIDTH: 14,
    MIN_VIDEO_DURATION: 0.5,
  },
  MIN_INTERVAL_TICK_WIDTH: 10,
  DEFAULT_INTERVAL_TICK_WIDTH: 40,
  MAX_INTERVAL_TICK_WIDTH: 200,
  TENTH_OF_SECONDS_VISIBILITY_MIN_TICK_WIDTH: 64,
  PIP: {
    PIP_MIN_DURATION: 0.5,
    PIP_ONLY_MAX_DURATION: 10,
    PIP_ONLY_DEFAULT_DURATION: 3,
    PIP_DEFAULT_POSITION: 0,
    PIP_DEFAULT_SIZE: 1,
  },
  WEBCAM_RECORDER: {
    COUNTDOWN_TIME: 3, // In seconds
    RECORD_LIMIT: 5 * 60, // In seconds
    RECORD_LIMIT_WARNING_LIMIT: 30, // In seconds: number of seconds before the webcam capture to stop (display a warning)
    MIN_RECORD_TIME: 0.5, // In seconds
  },
  SCREEN_RECORDER: {
    COUNTDOWN_TIME: 3, // In seconds
    RECORD_LIMIT: 5 * 60, // In seconds
    RECORD_LIMIT_WARNING_LIMIT: 30, // In seconds: number of seconds before the webcam capture to stop (display a warning)
    MIN_RECORD_TIME: 0.5, // In seconds
  },
};

export const ANIMATION_CONSTANTS = {
  DEFAULT_ANIMATION_DURATION: 2,
  DEFAULT_MOTION_DESIGN_ANIMATION_DURATION: 5,
  DURATION_BY_WORD: 0.4,
  MAX_SLIDE_DURATION: 8,
  MAX_ANIMATION_TEXT_LENGTH: 70,
  MAX_ANIMATION_SHORT_TEXT_LENGTH: 56,
  MAX_DIGITS_ONLY_ANIMATION_TEXT_LENGTH: 6,
  MAX_DIGITS_ONLY_SHORT_ANIMATION_TEXT_LENGTH: 2,
  MASK_MARGIN: 60,
  LOGO: {
    MARGIN: 50,
    MARGIN_WITH_MASK: 110,
    MARGIN_WITH_ANIMATION: 20,
  },
  SUBTITLE: {
    MARGIN: 50,
    MARGIN_WITH_ANIMATION: 20,
    MARGIN_WITH_MASK: 110,
  },
  PLAYER_PROGRESS_INTERVAL: 25,
};

export const SUBTITLES_CONSTANTS = {
  MAX_DURATION: 2,
  MIN_DURATION: 0.5,
  DEFAULT_INTERVAL_BETWEEN_BLOCKS: 1,
};

export enum SECURITY_MODAL_TYPE {
  FREE_TRIAL_END = 'FREE_TRIAL_END',
  EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED',
  DOWNGRADE = 'DOWNGRADE',
  LOADING = 'LOADING',
  MAINTENANCE = 'MAINTENANCE',
}

export enum MEDIA_STATUS {
  PROCESSED = 'PROCESSED',
  PROCESSING = 'PROCESSING',
}

export enum BUNDLE_PRICING_PERIOD {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum SLACK_NOTIFICATION_CHANNEL {
  CONTACT_US = 'CONTACT_US',
  DOWNGRADE_CONTACT_US = 'DOWNGRADE_CONTACT_US',
  SELF_CHECKOUT = 'SELF_CHECKOUT',
}

export const FREE_TRIAL_DENY_EMAIL_LIST = [
  'hotmail.com',
  'gmail.com',
  'yahoo.co',
  'yahoo.com',
  'mailinator.com',
  'gmail.co.in',
  'aol.com',
  'yandex.com',
  'msn.com',
  'gawab.com',
  'inbox.com',
  'gmx.com',
  'rediffmail.com',
  'in.com',
  'live.com',
  'hotmail.co.uk',
  'hotmail.fr',
  'yahoo.fr',
  'wanadoo.fr',
  'comcast.net',
  'yahoo.co.uk',
  'yahoo.com.br',
  'yahoo.co.in',
  'free.fr',
  'gmx.de',
  'gmx.de',
  'yandex.ru',
  'ymail.com',
  'libero.it',
  'outlook.com',
  'uol.com.br',
  'bol.com.br',
  'mail.ru',
  'cox.net',
  'hotmail.it',
  'sbcglobal.net',
  'sfr.fr',
  'live.fr',
  'verizon.net',
  'live.co.uk',
  'googlemail.com',
  'yahoo.es',
  'ig.com.br',
  'live.nl',
  'bigpond.com',
  'terra.com.br',
  'yahoo.it',
  'neuf.fr',
  'yahoo.de',
  'live.com',
  'yahoo.de',
  'rocketmail.com',
  'att.net',
  'laposte.net',
  'facebook.com',
  'bellsouth.net',
  'yahoo.in',
  'hotmail.es',
  'charter.net',
  'yahoo.ca',
  'yahoo.com.au',
  'rambler.ru',
  'hotmail.de',
  'tiscali.it',
  'shaw.ca',
  'yahoo.co.jp',
  'sky.com',
  'earthlink.net',
  'optonline.net',
  'freenet.de',
  't-online.de',
  'aliceadsl.fr',
  'virgilio.it',
  'home.nl',
  'qq.com',
  'telenet.be',
  'me.com',
  'yahoo.com.ar',
  'tiscali.co.uk',
  'yahoo.com.mx',
  'gmx.net',
  'mail.com',
  'planet.nl',
  'tin.it',
  'live.it',
  'ntlworld.com',
  'arcor.de',
  'yahoo.co.id',
  'frontiernet.net',
  'hetnet.nl',
  'live.com.au',
  'yahoo.com.sg',
  'zonnet.nl',
  'club-internet.fr',
  'juno.com',
  'optusnet.com.au',
  'blueyonder.co.uk',
  'bluewin.ch',
  'skynet.be',
  'sympatico.ca',
  'windstream.net',
  'mac.com',
  'centurytel.net',
  'chello.nl',
  'live.ca',
  'aim.com',
  'laposte.net',
  'bigpond.net.au',
];

export enum CHARTER_ACTIVE {
  ACTIVE = 'true',
  INACTIVE = 'false',
  ALL = 'all',
}

export enum CompanyBundleFeatureList {
  MAX_ADMINS_AND_OWNERS = 'MAX_ADMINS_AND_OWNERS',
  MAX_CREATORS = 'MAX_CREATORS',
  MAX_CHARTERS = 'MAX_CHARTERS',
  MAX_LOGO_PER_CHARTER = 'MAX_LOGO_PER_CHARTER',
  MAX_INTRO_PER_CHARTER = 'MAX_INTRO_PER_CHARTER',
  MAX_OUTRO_PER_CHARTER = 'MAX_OUTRO_PER_CHARTER',
  MAX_CUSTOM_SCENARIOS = 'MAX_CUSTOM_SCENARIOS',
  ACCESS_TO_SUBTITLES = 'ACCESS_TO_SUBTITLES',
  ACCESS_TO_ANALYTICS = 'ACCESS_TO_ANALYTICS',
}

export const COMPANY_BUNDLE_FEATURES = {
  MAX_ADMINS_AND_OWNERS: {
    featureCode: CompanyBundleFeatureList.MAX_ADMINS_AND_OWNERS,
    fieldName: 'maxNbOwnerAndAdmin',
  },
  MAX_CREATORS: {
    featureCode: CompanyBundleFeatureList.MAX_CREATORS,
    fieldName: 'maxNbCreators',
  },
  MAX_CHARTERS: {
    featureCode: CompanyBundleFeatureList.MAX_CHARTERS,
    fieldName: 'maxNbGroups',
  },
  MAX_LOGO_PER_CHARTER: {
    featureCode: CompanyBundleFeatureList.MAX_LOGO_PER_CHARTER,
    fieldName: 'maxNbLogos',
  },
  MAX_INTRO_PER_CHARTER: {
    featureCode: CompanyBundleFeatureList.MAX_INTRO_PER_CHARTER,
    fieldName: 'maxNbIntros',
  },
  MAX_OUTRO_PER_CHARTER: {
    featureCode: CompanyBundleFeatureList.MAX_OUTRO_PER_CHARTER,
    fieldName: 'maxNbOutros',
  },
  MAX_CUSTOM_SCENARIOS: {
    featureCode: CompanyBundleFeatureList.MAX_CUSTOM_SCENARIOS,
    fieldName: 'maxNbPrivateScenarios',
  },
  ACCESS_TO_SUBTITLES: {
    featureCode: CompanyBundleFeatureList.ACCESS_TO_SUBTITLES,
    fieldName: 'subtitlesAccess',
  },
  ACCESS_TO_ANALYTICS: {
    featureCode: CompanyBundleFeatureList.ACCESS_TO_ANALYTICS,
    fieldName: 'analyticsAccess',
  },
};
