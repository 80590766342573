import { CancelTokenSource } from 'axios';
import { SubtitleBlock } from '../../utils/types/ProjectTypes';
import { APIManager } from './APIManager';
import { APIGetCharterProjectDetailsByIdResult } from './types/ChartersServiceTypes';
import { APIGetSubtitlesLanguagesResponse } from './types/SubtitlesServiceTypes';

const getSubtitlesLanguages = (
  lang: string,
  cancelSource?: CancelTokenSource
): Promise<APIGetSubtitlesLanguagesResponse> => {
  const apiManager = APIManager.getInstance();

  return apiManager
    .get(`/subtitles/languages?lang=${lang}`, {
      cancelToken: cancelSource?.token || undefined,
    })
    .then((response) => {
      return response.data as APIGetSubtitlesLanguagesResponse;
    });
};

const patchSubtitleBlock = (
  charterId: number,
  projectId: number,
  sceneId: number,
  subtitleId: number,
  data: SubtitleBlock,
  cancelSource?: CancelTokenSource
): Promise<any> => {
  const apiManager = APIManager.getInstance();

  return apiManager
    .patch(`/charters/${charterId}/projects/${projectId}/scenes/${sceneId}/subtitles/blocks/${subtitleId}`, data, {
      cancelToken: cancelSource?.token || undefined,
    })
    .then((response) => {
      return response.data as APIGetCharterProjectDetailsByIdResult;
    });
};

const postProjectSubtitles = (
  charterId: number,
  projectId: number,
  language: string,
  cancelSource?: CancelTokenSource
): Promise<any> => {
  const apiManager = APIManager.getInstance();

  return apiManager
    .post(
      `/charters/${charterId}/projects/${projectId}/subtitles`,
      {
        language, // The language in which the subtitles must be generated
      },
      {
        cancelToken: cancelSource?.token || undefined,
      }
    )
    .then((response) => {
      return response.data as APIGetCharterProjectDetailsByIdResult;
    });
};

const postProjectSubtitlesForScene = (
  charterId: number,
  projectId: number,
  sceneId: number,
  language: string,
  cancelSource?: CancelTokenSource
): Promise<any> => {
  const apiManager = APIManager.getInstance();

  return apiManager
    .post(
      `/charters/${charterId}/projects/${projectId}/scenes/${sceneId}/subtitles`,
      {
        language, // The language in which the subtitles must be generated
      },
      {
        cancelToken: cancelSource?.token || undefined,
      }
    )
    .then((response) => {
      return response.data as APIGetCharterProjectDetailsByIdResult;
    });
};

const downloadSubtitlesSrtFile = (
  charterId: number,
  projectId: number,
  cancelSource?: CancelTokenSource
): Promise<any> => {
  const apiManager = APIManager.getInstance();

  return apiManager
    .get(`/charters/${charterId}/projects/${projectId}/subtitles/download`, {
      cancelToken: cancelSource?.token || undefined,
    })
    .then((response) => {
      return response as any;
    });
};

const deleteSubtitleBlock = (
  charterId: number,
  projectId: number,
  sceneId: number,
  subtitleId: number,
  cancelSource?: CancelTokenSource
): Promise<any> => {
  const apiManager = APIManager.getInstance();

  return apiManager
    .delete(`/charters/${charterId}/projects/${projectId}/scenes/${sceneId}/subtitles/blocks/${subtitleId}`, {
      cancelToken: cancelSource?.token || undefined,
    })
    .then((response) => {
      return response.data as APIGetCharterProjectDetailsByIdResult;
    });
};

export {
  getSubtitlesLanguages,
  patchSubtitleBlock,
  postProjectSubtitles,
  postProjectSubtitlesForScene,
  deleteSubtitleBlock,
  downloadSubtitlesSrtFile,
};
