/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */

import { CloudServerOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { Alert, Button, Modal } from 'antd';
import Progress from 'antd/es/progress';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsRecordCircle } from 'react-icons/bs';
import { ImStop } from 'react-icons/im';
import { createUseStyles } from 'react-jss';
import { useReactMediaRecorder } from 'react-media-recorder';
import { useSelector } from 'react-redux';
import { useTimer } from 'use-timer';
import { DEVICE_SIZES_QUERIES, LOGGING_EVENT, PROJECTS, THEME, WEB_SOCKET_ACTIONS } from '../../Constants';
import useSocket from '../../hooks/useSocket';
import { RootState } from '../../redux/RootState';
import { getCharterMediaSignedUrlToUpload } from '../../services/api/ChartersService';
import { uploadFileToSignedUrl } from '../../services/api/GlobalService';
import { SocketManager } from '../../services/api/SocketManager';
import { MediaFile } from '../../services/api/types/ChartersServiceTypes';
import { AssetOrMediaProcessingResponse } from '../../services/api/types/WebSocketTypes';
import MathUtils from '../../utils/MathUtils';
import MediaUtils from '../../utils/MediaUtils';
import TimeUtils from '../../utils/TimeUtils';
import { ExtendedVideoMediaType, MediaType } from '../../utils/types/MediaTypes';
import SceneVideoPlayerSkeleton from '../scene-player/components/SceneVideoPlayerSkeleton';
import VideoWithCustomControls from '../video/VideoWithCustomControls';
// eslint-disable-next-line import/no-cycle
import { ScreenRecorderOptions } from './ScreenRecorder';

type Props = {
  isVisible: boolean;
  screen?: boolean;
  audio?: boolean;
  video?: boolean;
  options?: ScreenRecorderOptions;
  onRecordingProcessed?: (media: MediaFile) => void;
  onCancel: () => void;
};

const useStyles = createUseStyles({
  recordButton: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 8,
    '& > svg': {
      marginRight: 8,
    },
  },
  recorderAndVideoContainer: {
    height: '40vh',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 4,
    overflow: 'hidden',
  },
  recorderContainer: {},
  previewContainer: {
    width: '100%',
  },
  previewVideoPlayerContainer: {
    width: '100%',
  },
  videoPlayerContainer: {
    marginTop: '24px',
  },
  previewVideoPlayer: {},
  previewText: {
    position: 'absolute',
    bottom: '0',
    top: '0',
    margin: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  previewTimer: {
    position: 'absolute',
    top: '16px',
    right: '48px',
  },
  countdownTimer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontFamily: 'Menlo, monospace',
    color: 'white',
    fontSize: 100,
    textShadow: '1px 2px rgba(0, 0, 0, 0.5)',
    zIndex: 1,
  },
  recordingVideo: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    borderRadius: 4,
    overflow: 'hidden',
    '& video': {
      borderRadius: 4,
    },
  },
  previewMedia: {
    maxHeight: '100%',
    maxWidth: '100%',
    borderRadius: 4,
    '& video': {
      maxHeight: '100%',
      maxWidth: '100%',
      objectFit: 'cover',
    },
  },
  alertContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
    position: 'relative',
  },
  startRecordingButton: {
    '& > div': {
      borderRadius: '50%',
      background: 'red',
    },
  },
  stopRecordingButton: {
    '& > div': {
      borderRadius: 3,
      background: 'red',
    },
  },
  modalFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
  },
  uploadProgressContainer: {
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      fontSize: 10,
    },
    flex: 1,
    display: 'inline-flex',
    alignItems: 'center',
  },
  uploadIcon: {
    marginRight: 4,
  },
  processingIcon: {
    marginRight: 4,
  },
  uploadProgressBar: {
    marginLeft: 8,
    marginRight: 20,
    width: 150,
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      fontSize: 10,
      width: 100,
    },
  },
  recordingDataContainer: {
    fontFamily: 'Menlo, monospace',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    fontWeight: 'bold',
  },
  invisibleDot: {
    visibility: 'hidden',
  },
  recordingDot: {
    animation: '1s $blink ease infinite',
  },
  orangeTimer: {
    color: THEME.DEFAULT.WARNING_COLOR,
  },
  redTimer: {
    color: THEME.DEFAULT.DANGER_COLOR,
  },
  recordingLimitStopWarning: {},
});

export const ScreenRecorderModal: React.FunctionComponent<Props> = ({
  isVisible,
  screen = true,
  audio = true,
  video = true,
  options,
  onRecordingProcessed,
  onCancel,
}) => {
  const { t } = useTranslation();

  const [isCountdownActive, setIsCountdownActive] = useState(false);
  const [recordedMediaBlob, setRecordedMediaBlob] = useState<Blob | undefined>(undefined);
  const [uploadProgress, setUploadProgress] = useState<number>();
  const [isUploading, setIsUploading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [fileName, setFileName] = useState<string>();
  const [socketRoom, setSocketRoom] = useState<string>();
  const isSocketConnected = useSocket(socketRoom ?? undefined);

  const charter = useSelector((state: RootState) => state.charters.current);
  const company = useSelector((state: RootState) => state.companies.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);

  // Timer to compute the recording time (for videos)
  const { time: timer, start: startTimer, pause: pauseTimer, reset: resetTimer } = useTimer({
    interval: 500,
    step: 0.5,
  });

  // Countdown timer to optionally give the user 3000ms before the recording/screenshot to start
  const {
    time: countdownTimer,
    start: startCountdownTimer,
    reset: resetCountdownTimer,
    status: countdownTimerStatus,
  } = useTimer({ initialTime: PROJECTS.SCREEN_RECORDER.COUNTDOWN_TIME, timerType: 'DECREMENTAL', endTime: 0 });
  const isCountdownTimerRunning = countdownTimerStatus === 'RUNNING';

  const classes = useStyles();

  const onStart = () => {
    console.log('onStart');
    startTimer();
  };

  const onStop = (blobUrl: string, blob: Blob) => {
    pauseTimer();
    setRecordedMediaBlob(blob);
  };

  /**
   * Screen recorder hook
   * Status and callback:
   * Media Window Selector opened: acquiring_media
   * Media Window Selector chosen: idle
   * Recording start: recording
   * onStart
   * Recording stop: stopping
   * Recording stopped: stopped
   * onStop
   */
  const {
    status,
    startRecording: startRecord,
    stopRecording: stopRecord,
    mediaBlobUrl,
    error,
    clearBlobUrl,
  } = useReactMediaRecorder({
    screen,
    audio,
    video,
    onStart,
    onStop,
  });

  // Join the right websockets room
  useEffect(() => {
    if (!charter) {
      return;
    }

    setSocketRoom(`charters/${charter.id}/media`);
  }, [charter]);

  const isScreenRecordingCapturing = () => status === 'recording';
  const isScreenRecordingUploadable = () => status === 'stopped' && mediaBlobUrl;

  useEffect(() => {
    console.log('ScreenRecorder status', status);
  }, [status]);

  useEffect(() => {
    console.log('ScreenRecorder error', error);
  }, [error]);

  // Callback to reset all states to an initial state, as if the modal had never been opened before
  const onResetAll = () => {
    setRecordedMediaBlob(undefined);
    setFileName(undefined);
    setIsUploading(false);
    setUploadProgress(undefined);
    setIsProcessing(false);
    resetTimer();
    resetCountdownTimer();
    clearBlobUrl();
  };

  const startRecording = () => {
    // If there is a recorded file already, reset previous values
    if (mediaBlobUrl || recordedMediaBlob) {
      onResetAll();
    }

    // If there is a countdown timer (3000ms), we start it
    if (isCountdownActive) {
      startCountdownTimer();
    }

    startRecord();
  };

  const stopRecording = () => {
    stopRecord();
  };

  const cancelModal = () => {
    if (isScreenRecordingCapturing()) {
      stopRecording();
    }
    onCancel();
    onResetAll();
  };

  // Callback executed when receiving a websocket message in the joined room, to listen for the processing state of the
  // recorded or captured media
  const onMediaProcessed = (payload: AssetOrMediaProcessingResponse) => {
    const isMediaMessage = WEB_SOCKET_ACTIONS.CHARTER_GLOBAL_MEDIA.includes(payload.action);

    if (!isMediaMessage) {
      return;
    }

    const processedMedia = payload.data as MediaFile;
    const { status: apiStatus, publicName } = processedMedia;

    // If the received message corresponds to the uploaded file
    if (fileName && publicName === fileName) {
      // We know that the uploading is achieved and that the processing started
      setIsUploading(false);
      setIsProcessing(true);

      // If the processing is achieved, we reset the processing states, execute the 'onRecordingProcessed' callback if defined
      // and close the modal
      if (apiStatus === 'PROCESSED') {
        setUploadProgress(undefined);
        setIsProcessing(false);

        if (onRecordingProcessed) {
          onRecordingProcessed(processedMedia);
        }
        cancelModal();
      }
    }
  };

  // Handle the websocket messages when the recorded or captured media is being processed
  useEffect(() => {
    if (!isSocketConnected) {
      return undefined;
    }

    SocketManager.onMessage(onMediaProcessed);

    return (): void => SocketManager.offMessage(onMediaProcessed);
  }, [isSocketConnected, fileName]);

  // Whenever the modal is shown or hidden, we ensure to reset all states to the initial values
  useEffect(() => {
    onResetAll();
  }, [isVisible]);

  // Automatically stop the recording if the recording time limit is reached
  useEffect(() => {
    if (isScreenRecordingCapturing() && timer >= PROJECTS.SCREEN_RECORDER.RECORD_LIMIT) {
      stopRecording();
    }
  }, [timer, status]);

  // Callback executed to upload a file onto S3
  const onUploadFile = async () => {
    if (!(charter && recordedMediaBlob)) {
      return;
    }

    // Build the file name
    const recordingFileName = MediaUtils.buildRecordingFileName(ExtendedVideoMediaType.SCREEN_RECORDING);
    setFileName(recordingFileName);

    // Upload the file onto S3
    const params = {
      charterId: charter.id,
      filename: recordingFileName,
      mediaType: MediaType.VIDEO,
    };

    setUploadProgress(0);
    setIsUploading(true);

    loggingManager.logEvent(LOGGING_EVENT.UPLOAD_MEDIA, {
      name: params.filename,
      size: `${Math.floor(MathUtils.convertBytesToKilobytes(recordedMediaBlob.size))} ${t('charters.mediaLibrary.KB')}`,
      charterId: charter.id,
      companyId: company?.id,
      mimeType: recordedMediaBlob.type,
    });

    getCharterMediaSignedUrlToUpload(params)
      .then((signedUrl) => {
        return uploadFileToSignedUrl(signedUrl.fileUploadURL, recordedMediaBlob, (event) => {
          setUploadProgress(Math.floor((event.loaded / event.total) * 100));
        }).catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  };

  // Render the recording time, and if there is a recommended time, compare the recorded time with the recommended time
  const renderRecordingTime = () => {
    if (!isScreenRecordingCapturing()) {
      return null;
    }

    const recommendedTime = options?.recommendedTime;

    let timerClassName = '';
    // If there is a recommended time
    if (recommendedTime !== undefined && timer !== undefined) {
      const redTimerLimit = 2 * recommendedTime;
      // If the timer is greater than the recommended time but lower than its double, color it in orange
      if (timer >= recommendedTime && timer < redTimerLimit) {
        timerClassName = classes.orangeTimer;
      } else if (timer >= redTimerLimit) {
        // Else if the timer is greater than twice the recommended time, color it in red
        timerClassName = classes.redTimer;
      }
    }

    return (
      <div className={classes.recordingDataContainer}>
        {/* Blinking red dot to show the recording status */}
        <svg
          height="40"
          width="40"
          className={classNames(classes.recordingDot, !isScreenRecordingCapturing() && classes.invisibleDot)}
        >
          <circle cx="20" cy="30" r="5" fill="red" />
        </svg>

        {/* Timer and the optional recommended time */}
        {timer !== undefined && (
          <div>
            <span className={timerClassName}>{TimeUtils.formatSecondsIntoDuration(Math.trunc(timer))}</span>
            {recommendedTime !== undefined && <span> / {TimeUtils.formatSecondsIntoDuration(recommendedTime)}</span>}
          </div>
        )}
      </div>
    );
  };

  // Render a warning message to explain the recording time limit is about to be reached and the recording will stop
  const renderRecordingLimitWarning = () => {
    const remainingSecondsBeforeStoppingCapture = Math.ceil(PROJECTS.SCREEN_RECORDER.RECORD_LIMIT - timer);

    if (
      !(
        isScreenRecordingCapturing() &&
        remainingSecondsBeforeStoppingCapture <= PROJECTS.SCREEN_RECORDER.RECORD_LIMIT_WARNING_LIMIT
      )
    ) {
      return null;
    }

    return (
      <div className={classes.recordingLimitStopWarning}>
        <Alert
          showIcon
          message={t('charters.mediaLibrary.screenRecorder.recordingWillStopWarning', {
            limit: Math.round(PROJECTS.SCREEN_RECORDER.RECORD_LIMIT / 60),
            remaining: remainingSecondsBeforeStoppingCapture,
          })}
          type="warning"
        />
      </div>
    );
  };

  const renderScreenRecorderError = () => {
    if (error === 'permission_denied' || error !== '') {
      const message =
        error === 'permission_denied'
          ? t('charters.mediaLibrary.screenRecorder.screenrecorderPermissionDeniedErrorMessage')
          : t('charters.mediaLibrary.screenRecorder.screenrecorderGenericErrorMessage');

      return (
        <div className={classes.recordingLimitStopWarning}>
          <Alert showIcon message={message} type="error" />
        </div>
      );
    }
    return null;
  };

  const renderPreview = () => {
    if (!mediaBlobUrl) {
      return (
        <div className={classes.previewVideoPlayerContainer}>
          <SceneVideoPlayerSkeleton className={classes.previewVideoPlayer} />
          <div className={classes.previewText}>{t('charters.mediaLibrary.screenRecorder.previewPleaseRecord')}</div>
          <div className={classes.previewTimer}>{renderRecordingTime()}</div>
        </div>
      );
    }

    // If the media to preview is a video and if it's taken in mirroring mode, we have to mirror the preview.
    // Therefore, we have to implement our own controls for the video player
    return (
      <div className={classes.videoPlayerContainer}>
        <VideoWithCustomControls
          url={mediaBlobUrl || ''}
          className={classes.previewMedia}
          config={{ file: { attributes: { disablePictureInPicture: true } } }}
        />
      </div>
    );
  };

  // Render the modal footer, composed of the cancel/submit buttons, as well as a progress bar for the upload/processing progress
  const renderModalFooter = () => {
    return (
      <div className={classes.modalFooter}>
        <div key="uploadOrProgress" className={classes.uploadProgressContainer}>
          {isUploading || isProcessing ? (
            <div>
              {isUploading ? (
                <>
                  <CloudUploadOutlined className={classes.uploadIcon} />
                  {t('charters.mediaLibrary.webcamRecorder.uploadInProgress')}
                </>
              ) : (
                <>
                  <CloudServerOutlined className={classes.processingIcon} />
                  {t('charters.mediaLibrary.webcamRecorder.processingInProgress')}
                </>
              )}
              <Progress percent={uploadProgress} status="active" size="small" className={classes.uploadProgressBar} />
            </div>
          ) : (
            <div />
          )}
        </div>
        <>
          <Button key="cancelModal" onClick={cancelModal}>
            {t('global.cancel')}
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={onUploadFile}
            loading={isUploading || isProcessing}
            disabled={isUploading || isProcessing || !isScreenRecordingUploadable()}
          >
            {t('global.submit')}
          </Button>
        </>
      </div>
    );
  };

  return (
    <Modal
      visible={isVisible}
      onCancel={onCancel}
      title={t('charters.mediaLibrary.screenRecorder.title')}
      destroyOnClose
      footer={renderModalFooter()}
    >
      <>
        <div className={classes.recorderAndVideoContainer}>
          <div className={classes.recorderContainer}>
            {isCountdownActive && isCountdownTimerRunning && countdownTimer > 0 && (
              <div className={classes.countdownTimer}>{countdownTimer}</div>
            )}
            <Button
              onClick={startRecording}
              icon={<BsRecordCircle />}
              type="ghost"
              className={classes.recordButton}
              disabled={status && status === 'recording'}
            >
              {mediaBlobUrl
                ? t('charters.mediaLibrary.screenRecorder.recordAgain')
                : t('charters.mediaLibrary.screenRecorder.recordFirstTime')}
            </Button>
            <Button
              onClick={stopRecording}
              icon={<ImStop />}
              type="ghost"
              className={classes.recordButton}
              disabled={status && status !== 'recording'}
            >
              {t('charters.mediaLibrary.screenRecorder.stopRecord')}
            </Button>
          </div>
          <div className={classes.previewContainer}>
            <div className={classes.recordingVideo}>{renderPreview()}</div>
          </div>
        </div>

        <div className={classes.alertContainer}>
          {renderRecordingLimitWarning()}
          {renderScreenRecorderError()}
        </div>
      </>
    </Modal>
  );
};
