import { LoadingOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { usePageVisibility } from 'react-page-visibility';
import PageContentLoader from '../../../../../../components/loader/PageContentLoader';
import { ANIMATION_CONSTANTS, DEVICE_SIZES_QUERIES } from '../../../../../../Constants';
import useWindowSize from '../../../../../../hooks/useWindowSize';
import AnimationsUtils from '../../../../../../utils/AnimationsUtils';
import { PlayerSize, Project } from '../../../../../../utils/types/ProjectTypes';
import ProjectFinalRenderBackgroundMusicPlayer from './components/ProjectFinalRenderBackgroundMusicPlayer';
import ProjectFinalRenderIntroOutroPlayer from './components/ProjectFinalRenderIntroOutroPlayer';
import ProjectFinalRenderLogo from './components/ProjectFinalRenderLogo';
import ProjectFinalRenderMask from './components/ProjectFinalRenderMask';
import ProjectFinalRenderPlayerControls from './components/ProjectFinalRenderPlayerControls';
import ProjectFinalRenderScenePlayer from './components/ProjectFinalRenderScenePlayer';
import useProjectFinalRenderData, {
  ExtendedAsset,
  ExtendedProjectElement,
  ExtendedProjectScene,
  ProjectElementType,
} from './hooks/useProjectFinalRenderData';

type Props = {
  project: Project;
  reset?: boolean;
  fullscreenDisabled?: boolean;
  onReset?: () => void;
};

const useStyles = createUseStyles({
  mainContainer: {
    height: 0,
    paddingBottom: '56.25%', // (1 / ratio) * 100% where ratio = 16/9
    width: '100%',
    position: 'relative',
    boxShadow: '0 8px 8px 0 hsla(0, 0%, 0%, 0.15) !important',
    borderRadius: 4,
    overflow: 'hidden',
    background: '#F7F7F7',
  },
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: '#F7F7F7',
  },
  fullscreenModal: {
    width: '85% !important',
    [`@media screen and ${DEVICE_SIZES_QUERIES.EXTRA_LARGER}`]: {
      width: '70% !important',
    },
    '& .ant-modal-body': {
      position: 'relative',
    },
  },
  playerInModalContainer: {
    marginTop: 30,
    position: 'relative',
  },
  noActiveSceneMessage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const ProjectFinalRenderPlayer: FunctionComponent<Props> = ({
  project,
  reset,
  fullscreenDisabled = false,
  onReset,
}: Props) => {
  const classes = useStyles();
  const finalRenderPlayerRef = useRef<HTMLDivElement>(null);

  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSeeking, setIsSeeking] = useState(false);
  const [displayControls, setDisplayControls] = useState(false);
  const [visibleProjectElement, setVisibleProjectElement] = useState<ExtendedProjectElement>();
  const [visibleElementSeekTo, setVisibleElementSeekTo] = useState(0);
  const [playerSize, setPlayerSize] = useState<PlayerSize>();
  const [isFullscreenModalVisible, setIsFullscreenModalVisible] = useState(false);

  const { t } = useTranslation();
  const windowSize = useWindowSize();
  const isPageVisible = usePageVisibility();
  const { projectDuration, intro, outro, scenes, projectElements } = useProjectFinalRenderData(project);

  // Determine if one ExtendedProjectElement is an ExtendedProjectScene
  const isExtendedScene = (element: ExtendedProjectElement): element is ExtendedProjectScene => {
    return (element as ExtendedProjectScene).elementType === ProjectElementType.SCENE;
  };

  // Callback to get the new element to render in the project, depending on the visibleProjectElement state
  const getNextElement = (): ExtendedProjectElement | undefined => {
    if (!visibleProjectElement) {
      return undefined;
    }

    const visibleElementIndex = projectElements.findIndex((element) => element.id === visibleProjectElement.id);

    // If there is one following element, return it
    if (visibleElementIndex < projectElements.length - 1) {
      return projectElements[visibleElementIndex + 1];
    }

    // Else, return the first element of the project
    return projectElements[0];
  };

  // Calculate the player size, which is useful for rescaling some project elements (such as the logo, etc.)
  useEffect(() => {
    if (finalRenderPlayerRef.current) {
      const boundingRect = finalRenderPlayerRef.current.getBoundingClientRect();
      const playerInitialSize = {
        width: Math.max(boundingRect.width, finalRenderPlayerRef.current.offsetWidth),
        height: Math.max(boundingRect.height, finalRenderPlayerRef.current.offsetHeight),
      };

      const videoPlayerSize = AnimationsUtils.getVideoPlayerSizeByFormat(project.videoFormat, playerInitialSize);
      setPlayerSize(videoPlayerSize);
    }
  }, [project.videoFormat, finalRenderPlayerRef, windowSize]);

  // Callback determining which project element to show (intro, right scene or outro) depending on the `newTime` input
  const updateVisibleElement = useCallback(
    (newTime: number) => {
      if (intro && newTime < intro.absoluteEndAt) {
        setVisibleProjectElement(intro);
        return intro;
      }

      if (outro && newTime >= outro.absoluteStartAt) {
        setVisibleProjectElement(outro);
        return outro;
      }

      const currentlyVisibleScene = scenes?.find((scene) => {
        return newTime >= scene.absoluteStartAt && newTime < scene.absoluteEndAt;
      });

      setVisibleProjectElement(currentlyVisibleScene);
      setVisibleElementSeekTo(currentlyVisibleScene?.relativeStartAt ?? 0);

      return currentlyVisibleScene;
    },
    [intro, outro, scenes]
  );

  const onVideoEnded = () => {
    if (!scenes) return;

    const index = scenes?.findIndex(
      (scene) => currentTime >= scene.absoluteStartAt && currentTime < scene.absoluteEndAt
    );

    if (index === -1) {
      return;
    }

    if (scenes?.length < index + 1) {
      return;
    }

    const newCurrentTime = scenes[index + 1].absoluteStartAt;
    updateVisibleElement(newCurrentTime);
  };

  // In this effect, we ensure to update the visible element (intro, scene or outro) depending on the current time
  useEffect(() => {
    if (!visibleProjectElement) {
      updateVisibleElement(currentTime);
    }
  }, [currentTime, updateVisibleElement]);

  // Callback called to reset the player states
  const resetPlayer = useCallback(() => {
    setCurrentTime(0);
    setIsPlaying(false);
    const newVisibleElement = updateVisibleElement(0);
    setVisibleElementSeekTo(newVisibleElement?.absoluteStartAt ?? 0);
  }, [updateVisibleElement]);

  // When reaching the end of the project, or if explicitly required through the `reset` prop, ensure to reset the
  // player states
  useEffect(() => {
    if ((projectDuration && currentTime >= projectDuration) || reset) {
      resetPlayer();

      // Once the player has been reset, call the onReset() callback passed as prop to notify the parent about the
      // player reset
      if (onReset) {
        onReset();
      }
    }
  }, [projectDuration, currentTime, resetPlayer, reset, onReset]);

  // Whenever the video is playing and the visibleProjectElement changes, we ensure to reset the seek value
  useEffect(() => {
    if (isPlaying) {
      const newSeekTo =
        (visibleProjectElement && isExtendedScene(visibleProjectElement) ? visibleProjectElement.relativeStartAt : 0) ??
        0;
      setVisibleElementSeekTo(newSeekTo);
    }
  }, [visibleProjectElement]);

  // Whenever the isPlaying state changes, we ensure to reset the isSeeking state to false: if the user clicks start/pause,
  // he can't be still seeking
  useEffect(() => {
    if (isSeeking) {
      setIsSeeking(false);
    }
  }, [isPlaying]);

  // For performance optimization, whenever the tab is passed background (browsing another tab of the same window),
  // we reset the player. Indeed, while the dashboard is not a visible tab, we render a loader instead of the player
  useEffect(() => {
    if (!isPageVisible) {
      resetPlayer();
    }
  }, [isPageVisible, resetPlayer]);

  // Callback to update the currentTime state
  const onUpdateCurrentTime = useCallback((newTime: number) => {
    setCurrentTime(newTime);
  }, []);

  // Callback to update the isLoading state
  const onIsLoadingChange = useCallback((newIsLoading: boolean) => {
    setIsLoading(newIsLoading);
  }, []);

  // Callback called when the visibleElement reaches its end. If the finished element is the last one, then we reset
  // the currentTime to 0 and pause the player. Else, if the finished element has a following one, we ensure to update
  // the currentTime to the absoluteStartAt of this following element
  const onPlayNextElement = (endedElement: ExtendedProjectElement) => {
    const endedElementIndex = projectElements.findIndex((element) => element.id === endedElement.id);

    // If element is not found in the projectElements
    if (endedElementIndex < 0) {
      return;
    }

    // If the endedElement is not the last one, we play the next element
    if (endedElementIndex < projectElements.length - 1) {
      const nextElement = projectElements[endedElementIndex + 1];
      const newTime = nextElement.absoluteStartAt;
      updateVisibleElement(newTime);
      setCurrentTime(newTime);
      return;
    }

    // Else we reset to zero
    if (endedElementIndex === projectElements.length - 1) {
      // Timeout to reset the player, so has to ensure not to receive any other `onProgress` callback coming from the players
      const progressInterval = ANIMATION_CONSTANTS.PLAYER_PROGRESS_INTERVAL;
      setTimeout(resetPlayer, progressInterval * 2);
    }
  };

  // Callback to play the player
  const onPlay = useCallback(() => {
    setIsPlaying(true);
  }, []);

  // Callback to pause the player
  const onPause = useCallback(() => {
    setIsPlaying(false);
  }, []);

  // Callback called to either play or pause the player
  const onIsPlayingChange = useCallback(
    (newValue: boolean): void => {
      return newValue ? onPlay() : onPause();
    },
    [onPlay, onPause]
  );

  // Callback called when the user seeked in the video. We determine the newTime to reach depending on the seeked
  // value, and which element must be visible at this target time. Then, we ensure to correctly seek in that new
  // visible element
  const onSeekBarChange = (percentage?: number) => {
    if (percentage !== undefined) {
      if (!isSeeking) {
        setIsSeeking(true);
      }

      onPause();
      const newTime = percentage * projectDuration;
      const newVisibleElement = updateVisibleElement(newTime);

      // Set the visibleElementSeekTo state to correctly seek in the new visible element
      if (newVisibleElement) {
        const relativeStart = isExtendedScene(newVisibleElement) ? newVisibleElement.relativeStartAt : 0;
        const seekTo = newTime - newVisibleElement.absoluteStartAt + relativeStart;
        setVisibleElementSeekTo(seekTo);
      }

      setCurrentTime(newTime);
    }
  };

  // Callback called when the seekBar is released
  const onSeekBarReleased = useCallback(() => {
    setIsSeeking(false);
  }, []);

  // Callback when opening the fullscreen modal
  const onOpenFullscreenModal = useCallback(() => {
    setIsFullscreenModalVisible(true);
    onPause();
  }, []);

  // Callback when closing the fullscreen modal
  const onCloseFullscreenModal = useCallback(() => {
    setIsFullscreenModalVisible(false);
  }, []);

  // Callback to display the controls bar
  const onDisplayControls = useCallback(() => {
    setDisplayControls(true);
  }, []);

  // Callback to hide the controls bar
  const onHideControls = useCallback(() => {
    setDisplayControls(false);
  }, []);

  // Method to render either the project intro or outro
  const renderIntroOrOutro = (introOrOutro: ExtendedAsset) => {
    const isIntroOrOutroVisible = visibleProjectElement?.id === introOrOutro.id;
    const isNextElement = getNextElement()?.id === introOrOutro.id;

    // If the intro/outro is not visible nor the next element, do not render it at all
    if (!(isIntroOrOutroVisible || isNextElement)) {
      return null;
    }

    return (
      <ProjectFinalRenderIntroOutroPlayer
        introOrOutro={introOrOutro}
        currentTime={currentTime}
        audioVolume={project.audioVolume}
        seekTo={visibleElementSeekTo}
        isPlaying={isPlaying}
        isVisible={isIntroOrOutroVisible}
        playerSize={playerSize}
        onEndReached={() => onPlayNextElement(introOrOutro)}
        onUpdateCurrentTime={onUpdateCurrentTime}
      />
    );
  };

  // Method to render one project scene
  const renderScene = (scene: ExtendedProjectScene) => {
    const isSceneVisible = scene.id === visibleProjectElement?.id;
    const isNextScene = getNextElement()?.id === scene.id;

    // If the scene is not visible nor the next element, do not render it at all
    if (!(isSceneVisible || isNextScene)) {
      return null;
    }

    return (
      <ProjectFinalRenderScenePlayer
        key={scene.id}
        project={project}
        scene={scene}
        currentTime={currentTime}
        seekTo={visibleElementSeekTo}
        isPlaying={isPlaying}
        isVisible={isSceneVisible}
        onIsPlayingChange={onIsPlayingChange}
        onEndReached={() => onPlayNextElement(scene)}
        onVideoEnded={onVideoEnded}
        onUpdateCurrentTime={onUpdateCurrentTime}
        onIsLoadingChange={onIsLoadingChange}
      />
    );
  };

  // Method to render the controls to play/pause the player, seek in the project and seek the current time and project
  // duration
  const renderControls = () => {
    return (
      <ProjectFinalRenderPlayerControls
        isPlaying={isPlaying}
        isLoading={isLoading}
        currentTime={currentTime}
        projectDuration={projectDuration}
        fullscreenDisabled={fullscreenDisabled}
        displayControls={displayControls}
        onPlay={onPlay}
        onPause={onPause}
        onSeekBarChange={onSeekBarChange}
        onSeekBarReleased={onSeekBarReleased}
        onOpenFullscreenModal={onOpenFullscreenModal}
      />
    );
  };

  // Method to render the background music
  const renderBackgroundMusic = () => {
    return (
      <ProjectFinalRenderBackgroundMusicPlayer
        project={project}
        isPlaying={isPlaying && !isLoading}
        currentTime={currentTime}
        projectDuration={projectDuration}
        isSeeking={isSeeking}
        visibleProjectElement={visibleProjectElement}
        intro={intro}
        outro={outro}
      />
    );
  };

  // Method to render the mask
  const renderMask = () => {
    return (
      <ProjectFinalRenderMask project={project} playerSize={playerSize} visibleProjectElement={visibleProjectElement} />
    );
  };

  // Method to render the logo
  const renderLogo = () => {
    // Do not render the logo while the player is loading one other element
    if (isLoading) {
      return null;
    }

    return (
      <ProjectFinalRenderLogo
        logo={project.logo}
        format={project.videoFormat}
        playerSize={playerSize}
        mask={project.mask}
        visibleProjectElement={visibleProjectElement}
      />
    );
  };

  // Method to render the player in a fullscreen modal
  const renderFullScreenModal = () => {
    return (
      <Modal
        visible={isFullscreenModalVisible}
        className={classes.fullscreenModal}
        destroyOnClose
        footer={[
          <Button key="cancelModal" onClick={onCloseFullscreenModal}>
            {t('global.close')}
          </Button>,
        ]}
        onCancel={onCloseFullscreenModal}
      >
        <div className={classes.playerInModalContainer}>
          {/* If the player is already fullscreen in the modal, we disable the fullscreen button */}
          <ProjectFinalRenderPlayer project={project} reset={reset} fullscreenDisabled onReset={onReset} />
        </div>
      </Modal>
    );
  };

  // Performance optimization: if the dashboard is not visible (browsing another tab of the same browser window),
  // we render a loader
  if (!isPageVisible) {
    return (
      <div className={classes.mainContainer}>
        <PageContentLoader indicator={<LoadingOutlined spin />} className={classes.loader} />
      </div>
    );
  }

  if (!(scenes && scenes.length > 0)) {
    return (
      <div className={classes.mainContainer}>
        <div className={classes.noActiveSceneMessage}>
          {t('charters.projects.projectEditor.projectFinalization.mustHaveActiveScenes')}
        </div>
      </div>
    );
  }

  // Main render method, overlaying the different layers composing the project
  return (
    <div
      className={classes.mainContainer}
      ref={finalRenderPlayerRef}
      onMouseEnter={onDisplayControls}
      onMouseLeave={onHideControls}
    >
      {isLoading && <PageContentLoader indicator={<LoadingOutlined spin />} className={classes.loader} />}

      {renderControls()}
      {renderBackgroundMusic()}
      {renderMask()}
      {renderLogo()}

      {intro && renderIntroOrOutro(intro)}
      {scenes?.map((scene) => renderScene(scene))}
      {outro && renderIntroOrOutro(outro)}

      {!fullscreenDisabled && renderFullScreenModal()}
    </div>
  );
};

export default ProjectFinalRenderPlayer;
