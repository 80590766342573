import React, { FunctionComponent } from 'react';
import { CompanyBundleFeatureList } from '../../../../Constants';
import { AssetType } from '../../../../services/api/types/ChartersServiceTypes';
import GenericCharterAssets from './manager/GenericCharterAssets';

const CharterLogos: FunctionComponent = () => {
  return (
    <GenericCharterAssets
      acceptFileType="image/jpg,image/jpeg,image/x-png,image/png"
      assetType={AssetType.LOGO}
      sizeLimit={10}
      bundleFeatureLimit={CompanyBundleFeatureList.MAX_LOGO_PER_CHARTER}
    />
  );
};

export default CharterLogos;
