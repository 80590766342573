import { Button, message, Typography } from 'antd';
import { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoDuplicateOutline } from 'react-icons/io5';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import ChartersTable from '../../components/charters-table/ChartersTable';
import KannellePageHeader from '../../components/page-header/KannellePageHeader';
import Preformatted from '../../components/preformatted/Preformatted';
import { LINK, LOGGING_EVENT } from '../../Constants';
import Company from '../../model/Company';
import { setChartersList } from '../../redux/action/ChartersAction';
import { addCompany } from '../../redux/action/CompaniesAction';
import { RootState } from '../../redux/RootState';
import { APIManager } from '../../services/api/APIManager';
import { cloneCompany } from '../../services/api/CompaniesService';
import CompaniesUtils from '../../utils/CompaniesUtils';
import Statistics from './Statistics';

const { Title } = Typography;

type StyleProps = { isMobileOrTablet: boolean };

const useStyles = createUseStyles({
  title: {
    fontSize: ({ isMobileOrTablet }: StyleProps): string => (isMobileOrTablet ? '26px !important' : '38px !important'),
  },
  companyId: {
    marginLeft: ({ isMobileOrTablet }: StyleProps): string => (isMobileOrTablet ? '0px' : '25px'),
    fontSize: ({ isMobileOrTablet }: StyleProps): string => (isMobileOrTablet ? '11px' : '18px'),
    marginBottom: '0.5em',
    lineHeight: '1.35',
    display: 'block',
  },
  mobilePageHeader: {
    padding: '16px 6px',
    '& .ant-page-header-heading-left': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',

      '& .ant-page-header-heading-title': {
        maxWidth: '75%',
        '& *': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
      },

      '& .ant-page-header-heading-sub-title': {
        maxWidth: '25%',
        marginRight: '0px !important',
      },
    },
  },
  duplicateIcon: {
    marginRight: 4,
    position: 'relative',
    top: 2,
  },
});

const Home: FunctionComponent = () => {
  const [isCloneLoading, setIsCloneLoading] = useState(false);
  const currentCompany = useSelector((state: RootState) => state.companies.current);
  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);
  const companies = useSelector((state: RootState) => state.companies.list);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);

  const classes = useStyles({ isMobileOrTablet });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());

  const canCloneCompany = companies ? CompaniesUtils.checkIsKnlProfile(companies) : false;

  useEffect(() => {
    const cancelTokenSource = cancelTokenSourceRef.current;

    return (): void => {
      cancelTokenSource.cancel('Cancelled cloning company due to component unmount.');
    };
  }, []);

  if (!currentCompany) {
    return null;
  }

  const onCloneCompany = (): void => {
    setIsCloneLoading(true);
    cloneCompany(currentCompany.id, cancelTokenSourceRef.current)
      .then((response) => {
        const clonedCompany = new Company(response.data);

        loggingManager.logEvent(LOGGING_EVENT.CLONE_COMPANY, {
          companyId: clonedCompany.id,
          fromCompanyId: currentCompany.id,
        });

        dispatch(addCompany(clonedCompany));
        dispatch(setChartersList(clonedCompany.charters));

        message.success(t('home.clone.success'));
        setIsCloneLoading(false);
      })
      .catch((e) => {
        log.debug(e.message);
        setIsCloneLoading(false);
        message.error(t('home.clone.error'));
      });
  };

  return (
    <KannellePageHeader
      title={
        <Title level={1} className={classes.title}>
          {currentCompany.name}
        </Title>
      }
      subTitle={<Preformatted className={classes.companyId}>id: {currentCompany.id}</Preformatted>}
      className={classNames(isMobileOrTablet && classes.mobilePageHeader)}
      extra={
        canCloneCompany && (
          <Button
            key="clone_company"
            type="primary"
            onClick={onCloneCompany}
            disabled={isCloneLoading}
            loading={isCloneLoading}
          >
            <IoDuplicateOutline className={classes.duplicateIcon} /> {t('home.clone.title')}
          </Button>
        )
      }
    >
      <Statistics />
      <ChartersTable from={LINK.HOME.key} />
    </KannellePageHeader>
  );
};

export default Home;
