import { HomeOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Tag } from 'antd';
import { find, findIndex, forEach, map, trim } from 'lodash';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import Active from '../../../components/form/Active';
import ContactsInput from '../../../components/form/ContactsInput';
import InputCheckBox from '../../../components/form/InputCheckBox';
import InputEmail from '../../../components/form/InputEmail';
import InputNumber from '../../../components/form/InputNumber';
import InputSelect from '../../../components/form/InputSelect';
import InputSwitch from '../../../components/form/InputSwitch';
import InputText from '../../../components/form/InputText';
import Name from '../../../components/form/Name';
import PageContentLoader from '../../../components/loader/PageContentLoader';
import AddPocUserModal from '../../../components/modal/AddPocUserModal';
import { CHARGEBEE_SUBSCRIPTION_STATUS, COMPANY_BUNDLE_FEATURES, THEME } from '../../../Constants';
import useFetchCompanyStats from '../../../hooks/useFetchCompanyStats';
import Company from '../../../model/Company';
import CompanyStats from '../../../model/CompanyStats';
import { getBundles } from '../../../services/api/BundlesService';
import { getCompanyBundleById } from '../../../services/api/CompaniesService';
import { deleteUserPoc } from '../../../services/api/PocsService';
import {
  APIBundles,
  APIBundlesFeatures,
  APIGetBundlesResult,
  APIGetCompanyBundleResult,
} from '../../../services/api/types/BundlesServiceTypes';
import { ChargebeeSubscriptionObject } from '../../../services/api/types/ChargebeeServiceTypes';
import {
  APICompanyBundleParams,
  APICompanyUsers,
  APICreateCompanyParams,
  APIPatchCompanyParams,
} from '../../../services/api/types/CompaniesServiceTypes';
import LocalUtils from '../../../utils/LocalUtils';

const useStyles = createUseStyles({
  buttonContainer: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: 8,
    },
  },
  successTag: {
    color: '#0E5814',
    backgroundColor: '#E3F9E5',
    borderRadius: 25,
    border: 0,
    width: 'auto !important',
    minWidth: 'auto !important',
    margin: '8px 8px 4px 0px',
  },
  errorTag: {
    color: '#610316',
    backgroundColor: '#FFE3E3',
    borderRadius: 25,
    border: 0,
    width: 'auto !important',
    minWidth: 'auto !important',
    margin: '8px 8px 4px 0px',
  },
  titleSubscription: {
    marginBottom: 0,
    fontWeight: 'bold',
  },
  warningSection: {
    color: THEME.ALERT.WARNING_MAIN,
    background: THEME.ALERT.WARNING_BACKGROUND,
    padding: 16,
  },
});

type Props = {
  type: 'create' | 'update' | 'readOnly';
  currentCompany?: Company;
  currentCompanyStats?: CompanyStats;
  subscriptions?: ChargebeeSubscriptionObject[];
  contacts?: APICompanyUsers[];
  users?: APICompanyUsers[];
  handleSubmit: (
    values: APICreateCompanyParams | APIPatchCompanyParams,
    callback: () => void,
    bundleValues: APICompanyBundleParams
  ) => void;
  fileStorageAgreed?: boolean;
};

const CompanyForm: FunctionComponent<Props> = ({
  type,
  handleSubmit,
  currentCompany,
  subscriptions,
  contacts,
  users,
  fileStorageAgreed = false,
}: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const classes = useStyles();
  const { loading, fetchedCompanyStats: currentCompanyStats } = useFetchCompanyStats();
  const [isActive, setIsActive] = useState(currentCompany?.isActive !== undefined ? currentCompany?.isActive : true);
  const [pocUsers, setPocUsers] = useState<APICompanyUsers[]>(contacts ?? []);
  const [adminOrOwnerUsersEmail, setAdminOrOwnerUsersEmail] = useState<{
    code: number | string;
    key: string;
  }[]>([]);
  const [asyncLoading, setAsyncLoading] = useState(false);
  const [acceptedFileStorage, setAcceptedFileStorage] = useState(fileStorageAgreed);
  const [initialValues, setInitialValues] = useState({
    name: currentCompany?.name || '',
    companyEmail: currentCompany?.email || '',
    companyBillingEmail: currentCompany?.billingEmail || '',
    dunsSiret: currentCompany?.dunsSiret || '',
    isActive: currentCompany?.isActive !== undefined ? currentCompany?.isActive : true,
    maxNbLicenses: currentCompany?.maxNbLicenses || 5,
    maxNbCharters: currentCompany?.maxNbCharters || 5,
    maxNbLicensesUnlimited: currentCompany?.maxNbLicenses === 0 || false,
    maxNbChartersUnlimited: currentCompany?.maxNbCharters === 0 || false,
    acceptFileStorage: fileStorageAgreed,
    connectedToPitchy: currentCompany?.connectedToPitchy || false,
    forcePitchyRecipient: currentCompany?.forcePitchyRecipient || false,
    pitchyRecipient: currentCompany?.pitchyRecipient || '',
  });
  // Bundle
  const [bundleLoading, setBundleLoading] = useState(false);
  const [bundlesLoading, setBundlesLoading] = useState(false);
  const [bundles, setBundles] = useState<APIBundles[]>([]);
  const [selectedBundleId, setSelectedBundleId] = useState<number>();
  const [bundlesOptions, setBundlesOptions] = useState<
    {
      code: number | string;
      key: string;
    }[]
  >([]);
  const [selectedConnectToPitchy, setSelectedConnectToPitchy] = useState<boolean | undefined>(false);
  const [selectedForcePitchyRecipient, setSelectedForcePitchyRecipient] = useState<boolean | undefined>(false);

  useEffect(() => {
    const emailsUniques = new Set<any>();
    users?.forEach((user) => {
      user.charters.forEach((charter) => {
        if ((charter.currentUser.role === 'Owner' && charter.currentUser.isActive) || (charter.currentUser.role === 'Admin' && charter.currentUser.isActive)) {
          emailsUniques.add(user.email);
        }
      })
    })

    const emailsUniquesArray = Array.from(emailsUniques)
    const formatEmailsUniquesArray = emailsUniquesArray.map((email) => {
      return { code: email, key: email }
    })

    setAdminOrOwnerUsersEmail(formatEmailsUniquesArray);
  }, [users]);

  useEffect(() => {
    setPocUsers(contacts ?? []);
  }, [contacts]);

  useEffect(() => {
    setSelectedConnectToPitchy(currentCompany?.connectedToPitchy);
    setSelectedForcePitchyRecipient(currentCompany?.forcePitchyRecipient);
  }, [currentCompany]);

  const getValueByFeatureCode = (
    features: APIBundlesFeatures[],
    code: string
  ): string | boolean | number | undefined => {
    const feature = find(features, { code }) as APIBundlesFeatures;

    if (!feature) {
      return undefined;
    }

    return feature.customValue !== undefined ? feature.customValue : feature.defaultValue;
  };

  const getValuesForFeatures = (features: APIBundlesFeatures[]) => {
    const values: Record<string, string | boolean | number> = {};
    forEach(COMPANY_BUNDLE_FEATURES, (bundleFeature) => {
      const value = getValueByFeatureCode(features, bundleFeature.featureCode);
      if (value !== undefined) {
        values[bundleFeature.fieldName] = value;
      }
    });

    return values;
  };

  const handleBundleSelected = (bundleIdString: string, bundleList: APIBundles[]) => {
    const bundle = find(bundleList, { id: parseInt(bundleIdString, 10) });

    if (bundle) {
      setSelectedBundleId(bundle.id);

      const { features } = bundle;
      form.setFieldsValue({
        ...getValuesForFeatures(features),
      });
    } else {
      setSelectedBundleId(undefined);
    }
  };

  const handleBundlesFetched = (bundleResponse: APIGetBundlesResult) => {
    const fetchedBundles = bundleResponse.data.items;
    setBundlesLoading(false);
    setBundles(fetchedBundles);

    const fetchedBundlesOptions = fetchedBundles.map((fetchedBundle) => {
      return {
        code: fetchedBundle.id,
        key: fetchedBundle.name,
      };
    });
    setBundlesOptions(fetchedBundlesOptions);
  };

  const handleBundleFetched = (bundleResponse: APIGetCompanyBundleResult) => {
    setBundleLoading(false);

    form.setFieldsValue({
      bundle: bundleResponse.data.id,
    });

    if (bundleResponse.data && bundleResponse.data.id) {
      handleBundleSelected(bundleResponse.data.id.toString(), [bundleResponse.data]);
      setSelectedBundleId(bundleResponse.data.id);
      const { id, features } = bundleResponse.data;

      const updatedInitialValues = {
        ...initialValues,
        bundle: id,
        ...getValuesForFeatures(features),
      };

      setInitialValues(updatedInitialValues);
    }
  };

  const handleConnectedToPitchySelected = (value: boolean) => {
    setSelectedConnectToPitchy(value);
    form.setFieldsValue({
      connectedToPitchy: value,
    })

    if (value === false) {
      setSelectedForcePitchyRecipient(false)
      form.setFieldsValue({
        forcePitchyRecipient: false,
      });
    }
  }

  const handleForcePitchyRecipientSelected = (value: boolean) => {
    setSelectedForcePitchyRecipient(value)
    form.setFieldsValue({
      forcePitchyRecipient: value,
    });
  }

  const handlePitchyRecipientSelected = (value: any) => {
    form.setFieldsValue({ pitchyRecipient: value })
  };

  useEffect(() => {
    const lang = LocalUtils.getLang();
    setBundlesLoading(true);

    if (type === 'update' && currentCompany) {
      setBundleLoading(true);

      Promise.all([getBundles(lang), getCompanyBundleById(currentCompany.id)]).then(
        ([bundleListResponse, bundleResponse]) => {
          handleBundlesFetched(bundleListResponse);
          handleBundleFetched(bundleResponse);
        }
      );
    } else {
      getBundles(lang).then((bundleListResponse) => {
        handleBundlesFetched(bundleListResponse);
      });
    }
  }, []);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 10 },
      xl: { span: 8 },
      xxl: { span: 6, offset: 2 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 12 },
      xl: { span: 12 },
      xxl: { span: 10 },
    },
  };

  const onReset = (): void => {
    form.resetFields();

    if (form.getFieldValue('bundle')) {
      setSelectedBundleId(form.getFieldValue('bundle'));
    }
  };

  const callbackIsActiveChange = (value: boolean): void => {
    setIsActive(value);
    form.setFieldsValue({
      isActive: value,
    });
  };

  const getMaxNbLicensesFromBundleForm = () => {
    const maxNbCreators = form.getFieldValue('maxNbCreators');
    const maxNbOwnerAndAdmin = form.getFieldValue('maxNbOwnerAndAdmin');

    return maxNbCreators + maxNbOwnerAndAdmin;
  };

  const handleFormSubmit = (): void => {
    form.validateFields().then((values) => {
      const formattedValues = {
        name: trim(values.name),
        email: currentCompany?.email ? undefined : trim(values.companyEmail),
        billingEmail: currentCompany?.billingEmail ? undefined : trim(values.companyBillingEmail),
        maxNbLicenses: getMaxNbLicensesFromBundleForm(),
        maxNbCharters: values.maxNbGroups ? values.maxNbGroups : 0,
        autoCollection: 'off',
        dunsSiret: values.dunsSiret,
        connectedToPitchy: values.connectedToPitchy,
        forcePitchyRecipient: values.forcePitchyRecipient ? values.forcePitchyRecipient : false,
        pitchyRecipient: values.pitchyRecipient? values.pitchyRecipient : '',
        isActive: values.isActive,
        pocEmail: pocUsers.length ? pocUsers[0].email : undefined,
        acceptedFileStorage,
      };

      const formattedBundleValues: APICompanyBundleParams = {
        bundleId: values.bundle,
        bundleName: find(bundles, { id: values.bundle })?.name || '',
        featureOverrides: [],
        // This is to persist the info in Redux. It won't be used in the back-end.
        featureValues: [],
      };

      const initialBundle = find(bundles, { id: values.bundle });
      if (initialBundle) {
        const { features } = initialBundle;

        forEach(COMPANY_BUNDLE_FEATURES, (bundleField) => {
          const feature = find(features, { code: bundleField.featureCode }) as APIBundlesFeatures;
          const initialValue = feature ? feature.defaultValue : undefined;

          if (initialValue !== values[bundleField.fieldName]) {
            formattedBundleValues.featureOverrides.push({
              featureCode: bundleField.featureCode,
              customValue: values[bundleField.fieldName],
            });
          } else {
            formattedBundleValues?.featureValues?.push({
              featureCode: bundleField.featureCode,
              value: values[bundleField.fieldName],
            });
          }
        });
      }

      setAsyncLoading(true);
      handleSubmit(formattedValues, () => setAsyncLoading(false), formattedBundleValues);
    });
  };

  const handleCallbackLinkToUser = (newPocUser: APICompanyUsers): void => {
    setPocUsers([newPocUser]);

    form.validateFields(['contacts']);
  };

  const handleCallbackDeletePoc = (contact: APICompanyUsers): void => {
    const index = findIndex(pocUsers, { id: contact.id });

    deleteUserPoc({ companyId: currentCompany?.id, id: contact.id }).then(() => {
      const newPocUserList = [...pocUsers];
      newPocUserList.splice(index, 1);

      setPocUsers([...newPocUserList]);
    });
  };

  const handleCallbackOnFileStorageChange = (fileStorageAccepted: boolean): void => {
    setAcceptedFileStorage(fileStorageAccepted);
  };

  const buttonLabels = {
    submit: type === 'create' ? t('global.submit') : t('company.form.update'),
    reset: type === 'create' ? t('global.reset') : t('global.cancel'),
  };

  if (loading || bundlesLoading || bundleLoading) {
    return <PageContentLoader />;
  }

  return (
    <>
      <Form form={form} {...formItemLayout} initialValues={initialValues}>
        <Name
          key="newCompanyName"
          label={t('company.form.fields.name')}
          extra={t('company.form.fields.nameDesc')}
          placeholder={t('company.form.fields.namePlaceholder')}
          icon={<HomeOutlined />}
          isReadOnly={type === 'readOnly'}
        />
        <InputEmail
          key="companyEmail"
          name="companyEmail"
          label={t('company.form.fields.email')}
          placeholder={t('company.form.fields.emailPlaceholder')}
          isReadOnly={type === 'readOnly'}
        />
        <InputEmail
          key="companyBillingEmail"
          name="companyBillingEmail"
          label={t('company.form.fields.billingEmail')}
          placeholder={t('company.form.fields.billingEmailPlaceholder')}
          isReadOnly={type === 'readOnly'}
        />
        <InputText
          name="dunsSiret"
          label={t('company.form.fields.dunsSiret')}
          placeholder={t('company.form.fields.dunsSiretPlaceholder')}
          isReadOnly={type === 'readOnly'}
        />
        <ContactsInput
          name="contacts"
          label={t('company.form.fields.contacts', { count: pocUsers?.length || 1 })}
          contacts={pocUsers || []}
          callbackDeletePoc={handleCallbackDeletePoc}
          addPocComponents={
            <AddPocUserModal
              callbackLinkToUser={handleCallbackLinkToUser}
              contacts={pocUsers.map((contact) => contact.email) || []}
              companyId={type === 'update' ? currentCompany?.id : undefined}
            />
          }
        />
        <InputCheckBox
          name="acceptFileStorage"
          label={t('company.form.fields.fileStorage')}
          checkboxLabel={t('company.form.fields.acceptFileStorage')}
          defaultChecked={initialValues.acceptFileStorage}
          extra={t('company.form.fields.acceptFileStorageExtra')}
          callback={handleCallbackOnFileStorageChange}
        />
        {type === 'update' && <Divider>{t('company.form.settingsSection')}</Divider>}
        {type === 'update' && (
          <Active
            key="isActive"
            callbackChange={callbackIsActiveChange}
            isActive={isActive}
            label={t('company.form.fields.active')}
            extra={t('company.form.fields.activeDesc')}
          />
        )}
        <Divider>{t('company.form.bundleSection')}</Divider>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 18, offset: 4 }}
          xl={{ span: 20, offset: 2 }}
          xxl={{ span: 20, offset: 2 }}
        >
          <p className={classes.warningSection}>
            {t('company.form.bundleSectionWarningPart1')}
            <br />
            {t('company.form.bundleSectionWarningPart2')}
          </p>
        </Col>
        <InputSelect
          label={t('company.form.fields.bundleSelect')}
          options={bundlesOptions}
          name="bundle"
          errorText={t('checkout.form.mandatory')}
          size="middle"
          allowClear
          onChangeCallback={(value: string) => handleBundleSelected(value, bundles)}
        />
        {selectedBundleId && (
          <div>
            <InputNumber
              key="maxNbOwnerAndAdmin"
              name="maxNbOwnerAndAdmin"
              required
              errorText={t('checkout.form.mandatory')}
              label={t('company.form.fields.maxNbAdminsAndOwners')}
              min={type === 'update' ? currentCompanyStats?.users.adminOwners.active : 1}
            />
            <InputNumber
              key="maxNbCreators"
              name="maxNbCreators"
              required
              errorText={t('checkout.form.mandatory')}
              label={t('company.form.fields.maxNbCreators')}
              min={type === 'update' ? currentCompanyStats?.users.creators.active : 1}
            />
            <InputNumber
              key="maxNbGroups"
              name="maxNbGroups"
              required
              errorText={t('checkout.form.mandatory')}
              label={t('company.form.fields.maxNbGroups')}
              min={type === 'create' ? 1 : currentCompanyStats?.charters.active || 1}
            />
            <InputNumber
              key="maxNbLogos"
              name="maxNbLogos"
              required
              errorText={t('checkout.form.mandatory')}
              label={t('company.form.fields.maxNbLogos')}
            />
            <InputNumber
              key="maxNbIntros"
              name="maxNbIntros"
              required
              errorText={t('checkout.form.mandatory')}
              label={t('company.form.fields.maxNbIntros')}
            />
            <InputNumber
              key="maxNbOutros"
              name="maxNbOutros"
              required
              errorText={t('checkout.form.mandatory')}
              label={t('company.form.fields.maxNbOutros')}
            />
            <InputNumber
              key="maxNbPrivateScenarios"
              name="maxNbPrivateScenarios"
              required
              errorText={t('checkout.form.mandatory')}
              label={t('company.form.fields.maxNbPrivateScenarios')}
            />

            <InputSwitch
              key="subtitlesAccess"
              name="subtitlesAccess"
              label={t('company.form.fields.subtitlesAccess')}
              activeLabel={t('global.yes')}
              inactiveLabel={t('global.no')}
              callbackChange={(value: boolean) => {
                form.setFieldsValue({
                  subtitlesAccess: value,
                });
              }}
              isChecked={form.getFieldValue('subtitlesAccess')}
            />

            <InputSwitch
              key="analyticsAccess"
              name="analyticsAccess"
              label={t('company.form.fields.analyticsAccess')}
              activeLabel={t('global.yes')}
              inactiveLabel={t('global.no')}
              callbackChange={(value: boolean) => {
                form.setFieldsValue({
                  analyticsAccess: value,
                });
              }}
              isChecked={form.getFieldValue('analyticsAccess')}
            />

            <InputSwitch
              key="connectedToPitchy"
              name="connectedToPitchy"
              label={t('company.form.fields.connectedToPitchy')}
              activeLabel={t('global.yes')}
              inactiveLabel={t('global.no')}
              callbackChange={handleConnectedToPitchySelected}
              isChecked={form.getFieldValue('connectedToPitchy')}
            />

            <>
              <div  style={selectedConnectToPitchy ? { display: 'contents' } : { display: 'none' }}>
                <InputSwitch
                  key="forcePitchyRecipient"
                  name="forcePitchyRecipient"
                  label={t('company.form.fields.forcePitchyRecipient')}
                  activeLabel={t('global.yes')}
                  inactiveLabel={t('global.no')}
                  callbackChange={handleForcePitchyRecipientSelected}
                  isChecked={form.getFieldValue('forcePitchyRecipient')}
                />
              </div>
              {selectedForcePitchyRecipient && (

                <InputSelect
                  label={t('company.form.fields.emailRecipient')}
                  options={adminOrOwnerUsersEmail}
                  name="pitchyRecipient"
                  errorText={t('checkout.form.mandatory')}
                  size="middle"
                  allowClear
                  onChangeCallback={handlePitchyRecipientSelected}
                />
              )}
            </>
          </div>
        )}
        {subscriptions && subscriptions.length > 0 && <Divider>{t('company.form.subscriptionSection')}</Divider>}
        {map(subscriptions, ({ subscription, card }) => {
          if (!subscription) {
            return null;
          }

          const isSubscriptionCancelledOrPaused =
            subscription &&
            (subscription.status === CHARGEBEE_SUBSCRIPTION_STATUS.CANCELLED ||
              subscription.status === CHARGEBEE_SUBSCRIPTION_STATUS.PAUSED);

          return (
            <React.Fragment key={`subscription_${subscription.id}`}>
              <Form.Item
                label={<h3 className={classes.titleSubscription}>{t('company.form.subscriptionSection')}</h3>}
                colon={false}
              >
                <Tag className={classes.successTag}>
                  {t('company.form.subscription')}
                  {t(`company.form.chargebeeSubscription.${subscription.status}`).toLowerCase()}
                </Tag>

                <Tag className={card ? classes.successTag : classes.errorTag}>
                  {card ? t('company.form.chargebeeCardPresent') : t('company.form.chargebeeCardAbsent')}
                </Tag>
              </Form.Item>

              {subscription.started_at && (
                <Form.Item label={t('company.form.fields.startDate')} hasFeedback>
                  {moment(subscription.started_at * 1000).format(t('timeFormats.yearMonthDayHourMinute'))}
                </Form.Item>
              )}
              {subscription.start_date && (
                <Form.Item label={t('company.form.fields.futureStartDate')} hasFeedback>
                  {moment(subscription.start_date * 1000).format(t('timeFormats.yearMonthDayHourMinute'))}
                </Form.Item>
              )}

              <Form.Item label={t('company.form.fields.nextBilling')} hasFeedback>
                {subscription.next_billing_at &&
                  moment(subscription.next_billing_at * 1000).format(t('timeFormats.yearMonthDayHourMinute'))}
                {!subscription.next_billing_at && isSubscriptionCancelledOrPaused && (
                  <span>{t('company.form.fields.noBillingScheduled')}</span>
                )}
              </Form.Item>

              <Form.Item label={t('company.form.fields.billingPeriod')} hasFeedback>
                {`${subscription.total_dues
                  ? `${subscription.total_dues / 100} €`
                  : t('company.form.fields.invoicePeriod')
                  }   / ${subscription.billing_period} ${t(`timeLabel.${subscription.billing_period_unit}`)}`}
              </Form.Item>
            </React.Fragment>
          );
        })}
        {type !== 'readOnly' && (
          <Col sm={24} md={22} xl={20} xxl={18}>
            <span className={classes.buttonContainer}>
              <Button onClick={onReset}>{buttonLabels.reset}</Button>
              <Button type="primary" onClick={handleFormSubmit} loading={asyncLoading}>
                {buttonLabels.submit}
              </Button>
            </span>
          </Col>
        )}
      </Form>
    </>
  );
};

export default CompanyForm;
