import { CancelTokenSource } from 'axios';
import { APIManager } from './APIManager';
import { PostCheckoutResultResponse } from './types/CompaniesServiceTypes';
import { APICreateCheckoutParams, APIGetPubliclySharedProjectDataResponse } from './types/PublicServiceTypes';

const postCheckout = (
  data: APICreateCheckoutParams,
  cancelSource?: CancelTokenSource
): Promise<PostCheckoutResultResponse> => {
  const apiManager = APIManager.getBasicInstance();

  return apiManager.post(`/public/checkout`, data, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const getPubliclySharedProjectData = (
  publicCode: string,
  cancelSource?: CancelTokenSource
): Promise<APIGetPubliclySharedProjectDataResponse> => {
  const apiManager = APIManager.getBasicInstance();

  return apiManager.get(`/public/share/projects/${publicCode}`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

export { postCheckout, getPubliclySharedProjectData };
