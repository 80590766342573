import { Alert, Button, Divider, Form, message, Modal } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import InputText from '../../../../../../../../components/form/InputText';
import { SUBTITLES_CONSTANTS } from '../../../../../../../../Constants';
import useSocket from '../../../../../../../../hooks/useSocket';
import { RootState } from '../../../../../../../../redux/RootState';
import { postSubtitleBlockForProjectScene } from '../../../../../../../../services/api/ChartersService';
import { SocketManager } from '../../../../../../../../services/api/SocketManager';
import { postProjectSubtitlesForScene } from '../../../../../../../../services/api/SubtitlesService';
import {
  ProjectSceneSubtitleGeneratedResponse,
  ProjectSceneSubtitleGenerationProgressResponse,
} from '../../../../../../../../services/api/types/WebSocketTypes';
import { SubtitleBlock, SubtitleBlockMode } from '../../../../../../../../utils/types/ProjectTypes';

type Props = {
  projectId: number;
  sceneId: number;
  isVisible: boolean;
  callbackHideModal: () => void;
  subtitleBlocks: SubtitleBlock[];
  subtitlesLanguage: string;
  onAddSubtitleBlock: (subtitle: SubtitleBlock) => void;
  onAddSubtitleBlocks: (subtitle: SubtitleBlock[]) => void;
  positionTime: number;
};

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  generateButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .ant-btn': {
      marginLeft: 8,
    },
  },
  info: {
    padding: 4,
    marginBottom: 8,
    '& .ant-alert-message': {
      color: '#094662 !important',
    },
  },
  disabledFooterModal: {
    '& .ant-modal-footer': {
      padding: 0,
      border: 'unset',
    },
  },
});

const AddSubtitleBlockModal: FunctionComponent<Props> = ({
  projectId,
  sceneId,
  isVisible,
  subtitleBlocks,
  subtitlesLanguage,
  callbackHideModal,
  onAddSubtitleBlock,
  onAddSubtitleBlocks,
  positionTime,
}: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const formRef = useRef<FormInstance>(null);

  const canGenerateSubtitle =
    subtitleBlocks.length === 0 ||
    subtitleBlocks.find((block) => block.mode === SubtitleBlockMode.MANUAL) === undefined;

  const classes = useStyles({ canGenerateSubtitle });

  const currentCharter = useSelector((state: RootState) => state.charters.current);
  const currentCharterId = currentCharter?.id;

  const isSocketConnected = useSocket(`charters/${currentCharterId}/projects/${projectId}/scenes/${sceneId}`);

  const [isAsyncLoading, setIsAsyncLoading] = useState(false);
  const [isGenerateSubtitleLoading, setIsGenerateSubtitleLoading] = useState(false);
  const [closable, setClosable] = useState(true);

  useEffect(() => {
    if (!isVisible) {
      setIsAsyncLoading(false);

      if (formRef.current && form) {
        form.resetFields();
      }
    }
  }, [isVisible, form]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  // Handle websocket messages
  useEffect(() => {
    const handleUpdatedProject = (
      payload: ProjectSceneSubtitleGenerationProgressResponse | ProjectSceneSubtitleGeneratedResponse
    ): void => {
      let messagePayload;

      // eslint-disable-next-line default-case
      switch (payload.action) {
        case 'project_scene_subtitles_generated': {
          setClosable(true);
          setIsGenerateSubtitleLoading(false);

          messagePayload = payload as ProjectSceneSubtitleGeneratedResponse;

          if ((messagePayload.data as any).blocks) {
            onAddSubtitleBlocks((messagePayload.data as any).blocks);
          } else if ((messagePayload.data as any).message) {
            message.error(t('charters.projects.projectEditor.sceneEditor.fields.subtitles.generateSubtitleSceneError'));
          }
          break;
        }
      }
    };

    if (isSocketConnected) {
      SocketManager.onMessage(handleUpdatedProject);

      return (): void => SocketManager.offMessage(handleUpdatedProject);
    }

    return undefined;
  }, [isSocketConnected]);

  const onHideModal = (): void => {
    if (!closable) {
      return;
    }
    setIsAsyncLoading(false);
    setIsGenerateSubtitleLoading(false);
    callbackHideModal();
  };

  const getNewSubtitleBlockStartAtTime = () => {
    if (subtitleBlocks.length === 0) {
      return 0;
    }

    return subtitleBlocks[subtitleBlocks.length - 1].endAt;
  };

  const handleSubmit = (): void => {
    if ((!formRef.current && form) || !currentCharterId) {
      return;
    }

    form.validateFields().then((values) => {
      setIsAsyncLoading(true);

      const startTime = positionTime === 0 ? getNewSubtitleBlockStartAtTime() : positionTime;

      postSubtitleBlockForProjectScene(currentCharterId, projectId, sceneId, {
        language: subtitlesLanguage,
        text: values.newSubtitleText,
        startAt: startTime,
        endAt: startTime + SUBTITLES_CONSTANTS.MIN_DURATION,
      })
        .then((response) => {
          form.resetFields();

          onAddSubtitleBlock(response);
        })
        .finally(() => {
          setIsAsyncLoading(false);
          message.success(t('charters.projects.projectEditor.sceneEditor.fields.subtitles.addSubtitleSuccess'));
          onHideModal();
        });
    });
  };

  const handleGenerateSubtitle = (): void => {
    if (!currentCharterId) {
      return;
    }
    setIsGenerateSubtitleLoading(true);

    setClosable(false);

    postProjectSubtitlesForScene(currentCharterId, projectId, sceneId, subtitlesLanguage);
  };

  return (
    <Modal
      visible={isVisible}
      onCancel={onHideModal}
      title={t('charters.projects.projectEditor.sceneEditor.fields.subtitles.addSubtitle')}
      cancelText={t('global.cancel')}
      okText={t('global.submit')}
      onOk={handleSubmit}
      cancelButtonProps={{
        disabled: isAsyncLoading || isGenerateSubtitleLoading || !closable,
      }}
      okButtonProps={{
        loading: isAsyncLoading,
        disabled: isAsyncLoading || isGenerateSubtitleLoading || !closable,
      }}
      className={canGenerateSubtitle ? classes.disabledFooterModal : undefined}
      footer={canGenerateSubtitle ? [] : undefined}
      closable={!isGenerateSubtitleLoading && !isAsyncLoading && closable}
      maskClosable={!isGenerateSubtitleLoading && !isAsyncLoading}
    >
      <Form
        form={form}
        ref={formRef}
        {...formItemLayout}
        validateMessages={{
          required: t('global.requiredField'),
        }}
        name="addSubtitle"
      >
        <InputText
          name="newSubtitleText"
          label={t('charters.projects.projectEditor.sceneEditor.fields.subtitles.subtitleText')}
          placeholder={t('charters.projects.projectEditor.sceneEditor.fields.subtitles.subtitleTextPlaceholder')}
          required
          hasFeedback={false}
        />
      </Form>

      {canGenerateSubtitle && (
        <>
          <div className={classes.generateButtonContainer}>
            <Button onClick={onHideModal} disabled={isAsyncLoading || isGenerateSubtitleLoading}>
              {t('global.cancel')}
            </Button>
            <Button
              type="primary"
              onClick={handleSubmit}
              loading={isAsyncLoading}
              disabled={isAsyncLoading || isGenerateSubtitleLoading}
            >
              {t('global.submit')}
            </Button>
          </div>
          <Divider />
          <div className={classes.container}>
            <Alert
              className={classes.info}
              message={t('charters.projects.projectEditor.sceneEditor.fields.subtitles.generateSubtitleSceneInfo')}
              type="info"
            />

            <div className={classes.generateButtonContainer}>
              <Button onClick={handleGenerateSubtitle} loading={isGenerateSubtitleLoading} type="primary">
                {t('charters.projects.projectEditor.sceneEditor.fields.subtitles.generateSubtitleButton')}
              </Button>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default AddSubtitleBlockModal;
