import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons/lib';
import { Button, Slider, Tooltip } from 'antd';
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import Cropper, { CropperProps } from 'react-easy-crop';
import { FaVolumeDown, FaVolumeMute, FaVolumeUp } from 'react-icons/fa';
import { createUseStyles } from 'react-jss';
import TimeUtils from '../../utils/TimeUtils';
import PageContentLoader from '../loader/PageContentLoader';

type Props = Omit<CropperProps, 'image'>;

const useStyles = createUseStyles({
  videoLoaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  videoControlsContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: '-35px',
  },
  videoControls: {
    margin: '0 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  videoPlayPauseButton: {
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .anticon': {
      lineHeight: 0,
    },
  },
  videoSeekBar: {
    display: 'flex',
    flexGrow: 2,

    '& .ant-slider-handle': {
      width: '1px',
      border: '1px solid',
      borderRadius: 0,
    },
  },
  videoTimeIndicator: {
    fontSize: '12px',
  },
  volumeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginRight: 4,
  },
  volumeSliderTooltip: {
    '& .ant-tooltip-inner': {
      padding: '4px 0px 10px',
    },
  },
  volumeSlider: {
    height: 100,
  },
});

const VideoMediaResizer: FunctionComponent<Props> = ({ video, ...cropperProps }: Props) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isVideoPaused, setIsVideoPaused] = useState(false);
  const [videoPercentage, setVideoPercentage] = useState(0);
  const [videoNode, setVideoNode] = useState<HTMLVideoElement>();
  const [videoVolume, setVideoVolume] = useState(0);

  const classes = useStyles();

  const cropperRef = useCallback((node) => {
    if (node !== null) {
      const videoElement = node.querySelector('video');
      setVideoNode(videoElement);
    }
  }, []);

  useEffect(() => {
    if (!videoNode) {
      return undefined;
    }

    videoNode.muted = false;

    const onPlay = (): void => {
      setIsVideoPaused(false);
    };
    const onPause = (): void => {
      setIsVideoPaused(true);
    };
    const onTimeUpdate = (): void => {
      const percentage = videoNode.currentTime / videoNode.duration;
      setVideoPercentage(percentage);
    };

    videoNode.addEventListener('play', onPlay);
    videoNode.addEventListener('pause', onPause);
    videoNode.addEventListener('timeupdate', onTimeUpdate);

    return (): void => {
      videoNode.removeEventListener('play', onPlay);
      videoNode.removeEventListener('pause', onPause);
      videoNode.removeEventListener('timeupdate', onTimeUpdate);
    };
  }, [videoNode]);

  useEffect(() => {
    if (!videoNode) {
      return;
    }

    videoNode.volume = videoVolume;
  }, [videoNode, videoVolume]);

  const onVideoLoaded = (): void => {
    // Timeout needed to ensure the media is loaded and the crop size is calculated
    setTimeout(() => {
      setIsVideoLoaded(true);
    }, 500);
  };

  const onPlayPauseClick = (): void => {
    if (!videoNode) {
      return;
    }

    if (videoNode.paused || videoNode.ended) {
      videoNode.play();
    } else {
      videoNode.pause();
    }
  };

  const onVolumeChange = (percentage: number | undefined): void => {
    if (percentage !== undefined) {
      setVideoVolume(percentage);
    }
  };

  const onMuteVolumeToggle = (): void => {
    // If the volume is already muted, unmute it, else mute it
    setVideoVolume((prev) => (prev > 0 ? 0 : 1));
  };

  const onSeekBarChange = (percentage: number | undefined): void => {
    if (!videoNode) {
      return;
    }

    if (percentage !== undefined) {
      videoNode.currentTime = percentage * videoNode.duration;
      setVideoPercentage(percentage);
    }
  };

  const formatSeekbarValue = (value?: number): ReactNode => {
    if (!videoNode || !value) {
      return '';
    }
    return TimeUtils.formatSecondsIntoDuration(videoNode.currentTime);
  };

  const renderVolumeSettings = (): ReactNode => {
    // Render the right volume icon depending on the volume value
    const renderVolumeIcon = () => {
      if (videoVolume === 0) {
        return <FaVolumeMute onClick={onMuteVolumeToggle} />;
      }
      if (videoVolume >= 0.5) {
        return <FaVolumeUp onClick={onMuteVolumeToggle} />;
      }

      return <FaVolumeDown onClick={onMuteVolumeToggle} />;
    };

    return (
      <Tooltip
        title={
          <div className={classes.volumeSlider}>
            <Slider
              vertical
              value={videoVolume}
              onChange={onVolumeChange}
              tipFormatter={null}
              min={0}
              max={1}
              step={0.01}
            />
          </div>
        }
        overlayClassName={classes.volumeSliderTooltip}
      >
        <div className={classes.volumeContainer}>
          {renderVolumeIcon()}
          <div />
        </div>
      </Tooltip>
    );
  };

  return (
    <>
      {/* First, we render invisibly the video to await for the onLoadedData to complete.
            When the video was fully loaded, we display the actual cropper
        */}
      <video onLoadedData={onVideoLoaded} src={video} style={{ visibility: 'hidden' }} muted />
      {/* If the video is loaded, we can render the cropper since it can fully calculate the cropper grid.
            Else we display a loader
        */}
      {isVideoLoaded ? (
        <div>
          <div ref={cropperRef}>
            <Cropper video={video} {...cropperProps} />
          </div>

          <div className={classes.videoControlsContainer}>
            <div className={classes.videoControls}>
              <Button
                className={classes.videoPlayPauseButton}
                type="primary"
                onClick={onPlayPauseClick}
                icon={isVideoPaused ? <PlayCircleOutlined /> : <PauseCircleOutlined />}
              />
              {renderVolumeSettings()}
              <Slider
                className={classes.videoSeekBar}
                min={0}
                max={1}
                step={0.01}
                onChange={onSeekBarChange}
                value={videoPercentage}
                tipFormatter={formatSeekbarValue}
              />
              {videoNode && (
                <div className={classes.videoTimeIndicator}>
                  {TimeUtils.formatSecondsIntoDuration(videoNode.currentTime)} /{' '}
                  {TimeUtils.formatSecondsIntoDuration(videoNode.duration)}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.videoLoaderContainer}>
          <PageContentLoader />
        </div>
      )}
    </>
  );
};

export default VideoMediaResizer;
