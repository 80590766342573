import { map } from 'lodash';
import * as log from 'loglevel';
import { NODE_ENV } from '../Constants';
import { UsersModelCharter } from '../services/api/types/ChartersServiceTypes';
import { APICreateCompanyParams, APIPatchCompanyParams } from '../services/api/types/CompaniesServiceTypes';
import { APICreateUserParams } from '../services/api/types/UsersServiceTypes';
import { EventObject } from './types/LoggingTypes';
import { SubRowCharterSelect } from './types/UsersTableTypes';

export default class LogUtils {
  static initLoggerLevel(env: string): void {
    log.setLevel(env === NODE_ENV.PRODUCTION ? log.levels.SILENT : log.levels.TRACE);
  }

  static getCompanyCreateOrUpdateEvent = (
    event: string,
    formData: APICreateCompanyParams | APIPatchCompanyParams,
    companyId: number
  ): EventObject => {
    return {
      name: event,
      props: {
        name: formData.name,
        maxNbLicenses: formData.maxNbLicenses,
        maxNbCharters: formData.maxNbCharters,
        companyId,
      },
    };
  };

  static getChartersCreateOrUpdateEvent = (
    event: string,
    name: string,
    companyId: number,
    charterId: number
  ): EventObject => {
    return {
      name: event,
      props: {
        name,
        companyId,
        charterId,
      },
    };
  };

  static getUsersCreateOrLinkToCharterEvent = (
    event: string,
    userId: string,
    userData: APICreateUserParams,
    charterId: number,
    companyId?: number
  ): EventObject => {
    return {
      name: event,
      props: {
        userId,
        name: userData.name,
        email: userData.email,
        role: userData.role,
        isActive: userData.isActive,
        companyId,
        charterId,
      },
    };
  };

  static getUsersUpdateEvent = (
    event: string,
    userId: string,
    updateProps: {
      name?: string;
      isActive?: boolean;
      role?: string;
    },
    charterId?: number,
    companyId?: number
  ): EventObject => {
    const amplitudeEvent: EventObject = {
      name: event,
      props: {
        userId,
        companyId,
        charterId,
      },
    };

    if (updateProps.name !== undefined) {
      amplitudeEvent.props.name = updateProps.name;
    }
    if (updateProps.isActive !== undefined) {
      amplitudeEvent.props.isActive = updateProps.isActive;
    }
    if (updateProps.role !== undefined) {
      amplitudeEvent.props.role = updateProps.role;
    }

    return amplitudeEvent;
  };

  static getUsersPocUpdateEvent = (
    event: string,
    userId: string,
    updateProps: {
      name?: string;
      email?: boolean;
    },
    companyId?: number
  ): EventObject => {
    const amplitudeEvent: EventObject = {
      name: event,
      props: {
        userId,
        companyId,
        ...(updateProps.name !== undefined && { name: updateProps.name }),
        ...(updateProps.email !== undefined && { email: updateProps.email }),
      },
    };

    return amplitudeEvent;
  };

  static getUsersBatchUpdateEvent = (
    event: string,
    selectedRows: UsersModelCharter[],
    batchUpdateProps: {
      isActive?: boolean;
      role?: string;
    },
    charterId: number,
    companyId?: number
  ): EventObject => {
    const emails = map(selectedRows, (rows: UsersModelCharter) => {
      return rows.email;
    });
    const ids = map(selectedRows, (rows: UsersModelCharter) => {
      return rows.id;
    });
    const amplitudeEvent: EventObject = {
      name: event,
      props: {
        userIds: ids,
        userEmails: emails,
        userCount: selectedRows.length,
        companyId,
        charterId,
      },
    };

    if (batchUpdateProps.isActive !== undefined) {
      amplitudeEvent.props.isActive = batchUpdateProps.isActive;
    }
    if (batchUpdateProps.role !== undefined) {
      amplitudeEvent.props.role = batchUpdateProps.role;
    }

    return amplitudeEvent;
  };

  static getCompanyUsersBatchUpdateEvent = (
    event: string,
    selectedRow: SubRowCharterSelect,
    batchUpdateProps: {
      isActive?: boolean;
      role?: string;
    },
    charterIds: number[],
    companyId?: number
  ): EventObject => {
    const amplitudeEvent: EventObject = {
      name: event,
      props: {
        userId: selectedRow.userId,
        userEmail: selectedRow.userEmail,
        charterCount: charterIds.length,
        companyId,
        charterIds,
      },
    };

    if (batchUpdateProps.isActive !== undefined) {
      amplitudeEvent.props.isActive = batchUpdateProps.isActive;
    }
    if (batchUpdateProps.role !== undefined) {
      amplitudeEvent.props.role = batchUpdateProps.role;
    }

    return amplitudeEvent;
  };
}
