import { CancelTokenSource } from 'axios';
import { APIManager } from './APIManager';
import { APICreatePocResult, APIDeletePocResult, APIPostPocResult, PocApiParam } from './types/PocsServiceTypes';

const createPoc = (data: PocApiParam, cancelSource?: CancelTokenSource): Promise<APICreatePocResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.post(`/pocs`, data, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const postUserPoc = (data: PocApiParam, cancelSource?: CancelTokenSource): Promise<APIPostPocResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.post(
    `/companies/${data.companyId}/pocs/${data.id}`,
    {},
    {
      cancelToken: cancelSource?.token || undefined,
    }
  );
};

const deleteUserPoc = (data: PocApiParam): Promise<APIDeletePocResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.delete(`/companies/${data.companyId}/pocs/${data.id}`);
};

export { createPoc, postUserPoc, deleteUserPoc };
