import React, { FunctionComponent } from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

type Props = IContentLoaderProps;

const SceneVideoPlayerSkeleton: FunctionComponent<Props> = (props: Props) => {
  return (
    <ContentLoader viewBox="0 0 400 270" height="100%" width="100%" {...props}>
      {/* The video player rectangle */}
      <rect x="16" y="17" rx="0" ry="0" width="360" height="200" />
      {/* The seekbar */}
      <rect x="49" y="238" rx="2" ry="2" width="285" height="6" />
      {/* The seek anchor */}
      <circle cx="69" cy="241" r="8" />
      {/* The play button */}
      <path
        transform="translate(15, 228) scale(1.5)"
        r="30"
        d="M2.067,0.043C2.21-0.028,2.372-0.008,2.493,0.085l13.312,8.503c0.094,0.078,0.154,0.191,0.154,0.313
			c0,0.12-0.061,0.237-0.154,0.314L2.492,17.717c-0.07,0.057-0.162,0.087-0.25,0.087l-0.176-0.04
			c-0.136-0.065-0.222-0.207-0.222-0.361V0.402C1.844,0.25,1.93,0.107,2.067,0.043z"
      />
      {/* The fullscreen icon */}
      <path
        transform="translate(350, 228) scale(0.05)"
        d="M0,0v512h512V0H0z M477.867,477.867H34.133V34.133h443.733V477.867z"
      />
      <polygon
        transform="translate(350, 228) scale(0.05)"
        points="126.533,102.4 199.111,102.4 199.111,68.267 68.267,68.267 68.267,199.111 102.4,199.111 102.4,126.538
			198.422,222.558 222.556,198.423"
      />
      <polygon
        transform="translate(350, 228) scale(0.05)"
        points="222.557,313.581 198.422,289.445 102.4,385.467 102.4,312.889 68.267,312.889 68.267,443.733 199.111,443.733
			199.111,409.6 126.538,409.6"
      />
      <polygon
        transform="translate(350, 228) scale(0.05)"
        points="409.6,312.889 409.6,385.467 313.578,289.444 289.444,313.578 385.462,409.6 312.889,409.6 312.889,443.733
			443.733,443.733 443.733,312.889"
      />
      <polygon
        transform="translate(350, 228) scale(0.05)"
        points="312.889,68.267 312.889,102.4 385.467,102.4 289.444,198.423 313.578,222.558 409.6,126.538 409.6,199.111
			443.733,199.111 443.733,68.267"
      />
    </ContentLoader>
  );
};

export default SceneVideoPlayerSkeleton;
