import { EditOutlined, UserAddOutlined } from '@ant-design/icons/lib';
import { Button, Form, message, Modal } from 'antd';
import classNames from 'classnames';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { LOGGING_EVENT } from '../../Constants';
import { Roles } from '../../core/rule/RolesTypes';
import { RootState } from '../../redux/RootState';
import { createPoc, postUserPoc } from '../../services/api/PocsService';
import { APICompanyUsers } from '../../services/api/types/CompaniesServiceTypes';
import { APIModelUser } from '../../services/api/types/UsersServiceTypes';
import CompaniesUtils from '../../utils/CompaniesUtils';
import LogUtils from '../../utils/LogUtils';
import Email from '../form/Email';
import InputSwitch from '../form/InputSwitch';
import Name from '../form/Name';
import Password from '../form/Password';

const useStyles = createUseStyles({
  modal: {
    '& .ant-modal-body': {
      padding: '8px 24px',
    },
  },
  notClosableModal: {
    cursor: 'not-allowed',
    '& .ant-modal': {
      cursor: 'default',
    },
  },
  button: {
    marginLeft: 8,
    border: 'none',
    borderRadius: 25,
    padding: '2px 8px',
  },
  icon: {
    fontSize: 16,
  },
});

type Props = {
  callbackLinkToUser: (email: APICompanyUsers) => void;
  contacts: string[];
  companyId?: number;
};

const AddPocUserModal: FunctionComponent<Props> = ({ callbackLinkToUser, companyId, contacts }: Props) => {
  const [visible, setVisible] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isAsyncLoading, setIsAsyncLoading] = useState(false);
  const [isNewUser, setIsNewUser] = useState<boolean>();
  const [emailChecked, setEmailChecked] = useState<boolean>();
  const [id, setId] = useState<string>();
  const [errorInfo, setErrorInfo] = useState();
  const [isFieldValidating, setIsFieldValidating] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const currentUser = useSelector((state: RootState) => state.user.user);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);
  const currentCompany = useSelector((state: RootState) => state.companies.current);
  const companies = useSelector((state: RootState) => state.companies.list);

  const isCurrentUser = id && currentUser && currentUser.id === id;
  const isUserKnlTeamInCharter = CompaniesUtils.checkIsKnlProfile(companies || []);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setEmailChecked(undefined);
      setIsNewUser(undefined);
      setIsFieldValidating(false);

      // we reset to default value if the user doesn't exist
      setId(undefined);
    }
  }, [visible, form]);

  const showModal = (): void => {
    setVisible(true);
  };

  const hideModal = (): void => {
    setIsAsyncLoading(false);
    setVisible(false);
  };

  const callbackCheckedEmail = (isValidEmail: boolean, responseIsNewUser?: boolean, user?: APIModelUser): void => {
    if (!isValidEmail) {
      setEmailChecked(false);
      return;
    }

    setIsFieldValidating(false);
    setEmailChecked(true);
    setIsNewUser(responseIsNewUser);

    if (user) {
      setId(user.id);

      form.setFieldsValue({
        email: user.email,
        name: user.name,
      });
    } else {
      setId(undefined);
      form.setFieldsValue({
        name: undefined,
      });
    }
  };

  const callbackIsValidating = (isValidating: boolean): void => {
    setIsFieldValidating(isValidating);
  };

  const callbackEmailValidationChange = (value: boolean): void => {
    setVerifyEmail(value);
    form.setFieldsValue({
      verifyEmail: value,
    });
  };

  const handleSubmitError = (error: string, errorMessage: JSX.Element | string): void => {
    log.error(error);
    message.error(errorMessage);
    setIsAsyncLoading(false);
  };

  const handleSubmit = (): void => {
    const errorMessage = (
      <>
        {t('company.form.pocUserError')}
        <br />
        {t('support.global.emojiFingerRight')}
        <a href={`mailto:${t('support.global.supportEmail')}`}>{t('support.global.supportEmail')}</a>
        {t('support.global.emojiFingerLeft')}
      </>
    );

    form
      .validateFields()
      .then((values) => {
        setIsAsyncLoading(true);
        const data: any = {
          id,
          email: values.email,
          name: values.name,
          password: values.password,
          companyId,
          verifyEmail: values.verifyEmail,
        };

        if (isNewUser) {
          createPoc(data)
            .then((response) => {
              const event = LogUtils.getUsersPocUpdateEvent(
                LOGGING_EVENT.LINK_USER_POC,
                currentUser?.id || '',
                { name: data.name, email: data.email },
                currentCompany?.id
              );

              const responsePocUser: APICompanyUsers = {
                id: response.data.id,
                name: response.data.name,
                createdAt: response.data.createdAt,
                dashboard: { subtitles: { currentUsage: 0 } },
                email: response.data.email,
                updatedAt: response.data.updatedAt,
                charters: [],
              };

              loggingManager.logEventObject(event);
              callbackLinkToUser(responsePocUser);
              hideModal();
            })
            .catch((error) => {
              handleSubmitError(error, errorMessage);
            });
        } else if (!isNewUser && companyId) {
          postUserPoc(data)
            .then(() => {
              const event = LogUtils.getUsersPocUpdateEvent(
                LOGGING_EVENT.LINK_USER_POC,
                currentUser?.id || '',
                { name: data.name, email: data.email },
                currentCompany?.id
              );
              loggingManager.logEventObject(event);
              callbackLinkToUser(values);
              hideModal();
            })
            .catch((error) => {
              handleSubmitError(error, errorMessage);
            });
        } else {
          callbackLinkToUser(values);
          hideModal();
        }
      })
      .catch((validationErrorInfo) => {
        setErrorInfo(validationErrorInfo);
      });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  const Component = contacts.length ? EditOutlined : UserAddOutlined;

  return (
    <>
      <Button className={classes.button} key="addPocUserButton" type="link" onClick={showModal}>
        <Component className={classes.icon} key="addPocUserButton" onClick={showModal} />{' '}
        {t(`company.form.${contacts.length ? 'updatePocButton' : 'addPocButton'}`)}
      </Button>
      <Modal
        className={classes.modal}
        wrapClassName={classNames(isAsyncLoading && classes.notClosableModal)}
        centered
        width={620}
        title={t(`company.form.${contacts.length ? 'updatePocTitle' : 'addPocTitle'}`)}
        visible={visible}
        onOk={handleSubmit}
        closable={!isAsyncLoading}
        maskClosable={!isAsyncLoading}
        onCancel={(): void => hideModal()}
        cancelButtonProps={{ disabled: isAsyncLoading }}
        cancelText={t('global.cancel')}
        okText={t('global.submit')}
        okButtonProps={{
          loading: isAsyncLoading,
          disabled: isAsyncLoading || isFieldValidating,
        }}
        forceRender
      >
        <Form
          form={form}
          {...formItemLayout}
          initialValues={{
            role: Roles.Creator,
            isActive: true,
            verifyEmail: false,
          }}
        >
          <Email
            callback={callbackCheckedEmail}
            callbackIsValidating={callbackIsValidating}
            visible={visible}
            errorInfo={errorInfo}
            label={t('users.fields.email')}
            extra={t('users.fields.emailDesc')}
            placeholder={t('users.fields.emailPlaceholder')}
          />
          {emailChecked &&
            isNewUser &&
            !isCurrentUser && [
              <Name
                key="newUserName"
                label={t('users.fields.name')}
                extra={t('users.fields.nameDesc')}
                placeholder={t('users.fields.namePlaceholder')}
              />,
              <Password
                key="newUserPassword"
                label={t('users.fields.password')}
                extra={t('users.fields.passwordDesc')}
                placeholder={t('users.fields.passwordPlaceholder')}
              />,
            ]}
          {/* input verify Email is hidden */}
          {false && emailChecked && isNewUser && !isCurrentUser && isUserKnlTeamInCharter && (
            <InputSwitch
              key="verifyEmail"
              name="verifyEmail"
              label={t('users.fields.verifyEmail')}
              extra={t('users.fields.verifyEmailExtra')}
              activeLabel={t('users.fields.verifyEmailActive')}
              inactiveLabel={t('users.fields.verifyEmailDisabled')}
              callbackChange={callbackEmailValidationChange}
              isChecked={verifyEmail}
            />
          )}
        </Form>
      </Modal>
    </>
  );
};

export default AddPocUserModal;
