import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import App from './App';
import { useAuth0 } from './core/auth/Auth0Config';
import useSatismeter from './hooks/use-satismeter/useSatismeter';
import { useAppcues } from './hooks/useAppcues';
import useFetchFonts from './hooks/useFetchFonts';
import { resetStoreState } from './redux/action/AppAction';
import LocalStorage from './redux/LocalStorage';
import { RootState } from './redux/RootState';

const AppInitializer: React.FunctionComponent = () => {
  const { user, isAuthenticated } = useAuth0();
  const dispatch = useDispatch();

  const { boot: bootSatismeter, hide: hideSatismeter } = useSatismeter();

  const charter = useSelector((state: RootState) => state.charters.current);
  const company = useSelector((state: RootState) => state.companies.current);
  const language = useSelector((state: RootState) => state.app.language);
  const isUserUnauthorized = useSelector((state: RootState) => state.app.isUnauthorized);

  const lang = language === 'fr' || language === 'fr-FR' ? 'fr' : 'en';

  useFetchFonts();

  useEffect(() => {
    if (!user || !isAuthenticated) {
      return;
    }

    const auth0UserIdPrefix = process.env.REACT_APP_AUTH0_USER_ID_PREFIX ?? '';
    const userId = user?.sub.replace(auth0UserIdPrefix, '');

    const persistedState = LocalStorage.loadState();
    if (persistedState?.user?.user?.id && persistedState?.user?.user?.id !== userId) {
      dispatch(resetStoreState());
    }
  }, [user, isAuthenticated, dispatch]);

  // Initiate the Satismeter session
  useEffect(() => {
    // If the user is not authenticated or is unauthorized to access the dashboard, we do not boot Satismeter
    if (!user || !isAuthenticated || isUserUnauthorized === undefined || isUserUnauthorized) {
      return;
    }

    const auth0UserIdPrefix = process.env.REACT_APP_AUTH0_USER_ID_PREFIX ?? '';
    const userId = user?.sub.replace(auth0UserIdPrefix, '');

    bootSatismeter({
      userId,
      traits: {
        name: user.name,
        email: user.email,
      },
    });
  }, [user, isAuthenticated, bootSatismeter, isUserUnauthorized]);

  // Hide the Satismeter widget for unauthorized users
  // (in some cases, it might have been displayed before reaching the unauthorized page)
  useEffect(() => {
    if (isUserUnauthorized === undefined || !isUserUnauthorized) {
      return;
    }

    hideSatismeter();
  }, [hideSatismeter, isUserUnauthorized]);

  useEffect(() => {
    if (user) {
      useAppcues(user);
    }
  }, [user]);

  return (
    <>
      <Helmet>
        <script type="text/javascript">{`window.AppcuesSettings = { enableURLDetection: true };`}</script>
        <script src={`//fast.appcues.com/${process.env.REACT_APP_APPCUES_ACCOUNT_ID}.js`} />
      </Helmet>
      <App />
    </>
  );
};

export default AppInitializer;
