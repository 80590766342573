import { EyeFilled } from '@ant-design/icons';
import { LockOutlined, StarFilled } from '@ant-design/icons/lib';
import { Card, Divider, Tag, Tooltip } from 'antd';
import classNames from 'classnames';
import { find } from 'lodash';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import Preformatted from '../../../../components/preformatted/Preformatted';
import FormatTag from '../../../../components/tag/format-tag/FormatTag';
import useCroppedMedia from '../../../../hooks/useCroppedMedia';
import { RootState } from '../../../../redux/RootState';
import { Scenario, ScenarioVariant } from '../../../../services/api/types/ChartersServiceTypes';
import CompaniesUtils from '../../../../utils/CompaniesUtils';
import ScenarioUtils from '../../../../utils/ScenarioUtils';
import { ScenarioScopes } from '../../../../utils/types/ScenarioTypes';
import CreateNewScenarioVariantModal from './CreateNewScenarioVariantModal';
import CreateOrEditScenarioModal from './CreateOrEditScenarioModal';
import ScenarioVariantDescription from './scenario-variant-description/ScenarioVariantDescription';

type Props = {
  scenario: Scenario;
  isEditable?: boolean;
};

const useStyles = createUseStyles({
  scenarioCard: {
    textAlign: 'center',
    '& .ant-card-body': {
      padding: 0,
    },
    '& .ant-card-actions > li': {
      margin: '5px 0',
    },
  },
  scenarioCoverContainer: {
    backgroundColor: '#000000',
    position: 'relative',
    width: '100%',
    paddingTop: '33%', // 3 x 1 format
  },
  scenarioCover: {
    opacity: '0.7',
    borderRadius: '2px 2px 0 0',
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    filter: 'blur(10px)',
  },
  scenarioCoverCropped: {
    animation: '$removeBlur 1s ease-in-out forwards',
  },
  '@keyframes removeBlur': {
    '0%': {
      filter: 'blur(10px)',
    },
    '100%': {
      filter: 'none',
    },
  },
  scenarioCardTitle: {
    color: '#FFFFFF',
    textShadow: '1px 1px 10px #000000',
    padding: 10,
    width: '80%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  scenarioScopeContainer: {
    position: 'absolute',
    top: '3px',
    right: '5px',
    '& .ant-tag': {
      margin: '0',
    },
  },
  scenarioCode: {
    position: 'absolute',
    bottom: '3px',
    left: '5px',
    fontSize: '10px',
    backgroundColor: '#2b2b2b57',
    color: 'white',
    maxWidth: '60%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  scenarioActionsButton: {
    position: 'absolute',
    bottom: '8px',
    right: '5px',
    margin: '0',
    height: '20px',
    fontSize: '12px',
  },
  variantsDivider: {
    margin: 0,
  },
  variantsContainer: {
    '& > div:not(:last-child)': {
      borderBottom: '1px solid #E2E2E2',
    },
    fontSize: '12px',
  },
  scenarioVariant: {
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  scenarioVariantTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > *': {
      marginRight: '5px',
    },
  },
  scenarioVariantTitle: {
    textAlign: 'left',
  },
  variantMetadataContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: '0 2px',
    },
  },
  statusTag: {
    fontSize: 10,
    padding: '0 2px',
  },
  variantLanguage: {
    fontSize: '16px',
    marginRight: 2,
  },
  isMainIcon: {
    color: '#FADB14',
    fontSize: '14px',
  },
  isMainPlaceholder: {
    height: '12px',
    width: '12px',
  },
});

const ScenarioDetailedCard: FunctionComponent<Props> = ({ scenario, isEditable = true }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const companies = useSelector((state: RootState) => state.companies.list);
  const isKnlTeam = companies ? CompaniesUtils.checkIsKnlProfile(companies) : false;

  const [croppedCoverImage, isCoverImageCropLoading] = useCroppedMedia(scenario.coverImageSource);

  const mainVariant = find(scenario.variants, (variant) => variant.isMain);

  if (!mainVariant) {
    return null;
  }

  const renderScenarioVariantStatusTag = (statusCode: string): JSX.Element | null => {
    const status = ScenarioUtils.getVariantStatusByCode(statusCode);

    if (!status) {
      return null;
    }

    return (
      <Tag className={classNames(classes.statusTag, 'statusTag')} color={status?.color}>
        {t(status.key)}
      </Tag>
    );
  };

  const renderScenarioVariantFormatTag = (formatCode: string): JSX.Element | null => {
    const format = ScenarioUtils.getVariantFormatByCode(formatCode);

    if (!format) {
      return null;
    }

    return <FormatTag format={t(format.key)} image={format.image} />;
  };

  const renderScenarioVariantInCard = (scenarioVariant: ScenarioVariant): JSX.Element => {
    const languageFlag = ScenarioUtils.getVariantLanguage(scenarioVariant.language)?.flag;

    return (
      <ScenarioVariantDescription
        key={scenarioVariant.id}
        trigger={
          <div className={classNames(classes.scenarioVariant, scenarioVariant.isMain && 'mainVariant')}>
            <div className={classes.scenarioVariantTitleContainer}>
              {scenarioVariant.isMain ? (
                <Tooltip title={t('charters.scenarios.mainVariantTooltip')}>
                  <StarFilled className={classes.isMainIcon} />
                </Tooltip>
              ) : (
                <div className={classes.isMainPlaceholder} />
              )}
              <span className={classes.scenarioVariantTitle}>{scenarioVariant.title}</span>
            </div>

            <div className={classes.variantMetadataContainer}>
              {renderScenarioVariantStatusTag(scenarioVariant.status)}
              <span className={classes.variantLanguage}>{languageFlag}</span>
              {renderScenarioVariantFormatTag(scenarioVariant.format)}
              <EyeFilled />
            </div>
          </div>
        }
        scenarioId={scenario.id}
        scenarioCode={scenario.code}
        scenarioVariantId={scenarioVariant.id}
        isEditable={isEditable}
      />
    );
  };

  return (
    <>
      <Card
        className={classes.scenarioCard}
        actions={isEditable ? [<CreateNewScenarioVariantModal scenario={scenario} key="add_variant" />] : []}
      >
        <div className={classes.scenarioCoverContainer}>
          <img
            alt={!isCoverImageCropLoading ? 'Scenario cover' : ''}
            className={classNames(classes.scenarioCover, !isCoverImageCropLoading && classes.scenarioCoverCropped)}
            src={croppedCoverImage?.croppedFileUrl}
          />

          <div className={classes.scenarioCardTitle}>{mainVariant.title}</div>
          <div className={classes.scenarioScopeContainer}>
            {scenario.scope === ScenarioScopes.PUBLIC.code ? (
              <Tooltip title={t('charters.scenarios.publicScopeTooltip')}>
                <Tag color="blue">{t('charters.scenarios.publicScope')}</Tag>
              </Tooltip>
            ) : (
              <Tooltip title={t('charters.scenarios.privateScopeTooltip')}>
                <Tag color="gold">
                  <LockOutlined />
                </Tag>
              </Tooltip>
            )}
          </div>
          {isKnlTeam && <Preformatted className={classes.scenarioCode}>{scenario.code}</Preformatted>}

          {isEditable && (
            <CreateOrEditScenarioModal
              scenarioToEdit={scenario}
              triggerClassName={classes.scenarioActionsButton}
              mode="edition"
            />
          )}
        </div>
        <Divider className={classes.variantsDivider} />

        <div className={classNames(classes.variantsContainer, 'variantsContainer')}>
          {scenario.variants
            .slice()
            .sort((variant) => (variant.isMain ? -1 : 1)) // Display the main variant first
            .map((variant) => renderScenarioVariantInCard(variant))}
        </div>
      </Card>
    </>
  );
};

export default ScenarioDetailedCard;
