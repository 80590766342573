import { CloseCircleFilled, EditFilled } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent, Ref, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useCanAccess from '../../hooks/useCanAccess';
import useFetchCharterById from '../../hooks/useFetchCharterById';
import { RootState } from '../../redux/RootState';
import { Asset, AssetType } from '../../services/api/types/ChartersServiceTypes';
import { CharterIdPathParam } from '../../services/navigation/NavigationConfigTypes';
import AssetsUtils from '../../utils/AssetsUtils';
import { PermissionList } from '../../utils/types/CharterPermissionTypes';
import AccessChecker from '../access-checker/AccessChecker';
import Preformatted from '../preformatted/Preformatted';
import AssetSelectorModal from './AssetSelectorModal';

type Props = {
  value?: Asset;
  onChange?: (newValue: Asset | undefined) => void;
  fieldLabel: string;
  assetType: AssetType;
  size?: 'normal' | 'small';
  disabled?: boolean;
};

const useStyles = createUseStyles({
  selectedFileNameContainer: {
    cursor: 'pointer',
    '& .anticon': {
      marginRight: 4,
    },
  },
  selectedFileName: {
    fontSize: '11px',
    marginRight: 4,
  },
  selectAssetButton: {
    padding: 0,
  },
  smallAssetSelectorButton: {
    fontSize: '11px',
  },
  disabledSelectedFilename: {
    cursor: 'not-allowed',
    opacity: '0.4',
  },
  unauthorized: {
    cursor: 'not-allowed',
    '& button': {
      padding: 0,
    },
  },
});

const AssetSelector: FunctionComponent<Props> = React.forwardRef(
  ({ value, onChange, fieldLabel, assetType, size = 'normal', disabled = false }: Props, ref: Ref<any>) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();

    const { charterId } = useParams<CharterIdPathParam>();
    const { loading: isCharterLoading } = useFetchCharterById(parseInt(charterId, 10), true);

    let permissionToCheck;
    if (assetType === AssetType.INTRO) {
      permissionToCheck = PermissionList.INTRO;
    } else if (assetType === AssetType.OUTRO) {
      permissionToCheck = PermissionList.OUTRO;
    } else if (assetType === AssetType.LOGO) {
      permissionToCheck = PermissionList.LOGO;
    }

    const permissionToEditAsset = useCanAccess(permissionToCheck);

    const charter = useSelector((state: RootState) => state.charters.current);
    const assetList = useMemo(() => {
      if (!charter) {
        return [];
      }
      switch (assetType) {
        case AssetType.LOGO:
          return charter.logos;
        case AssetType.INTRO:
          return charter.intros;
        case AssetType.OUTRO:
          return charter.outros;
        case AssetType.MUSIC:
        default:
          return charter.musics;
      }
    }, [assetType, charter]);

    // Called to update the upper `form` object
    const triggerChange = (changedValue: Asset | undefined): void => {
      if (onChange) {
        onChange(changedValue);
      }
    };

    const onOpenModal = (): void => {
      if (!permissionToEditAsset.hasUserAccess()) {
        return;
      }

      setIsModalVisible(true);
    };

    const onCloseModal = (): void => {
      setIsModalVisible(false);
    };

    const onSelectAsset = (asset: Asset): void => {
      triggerChange(asset);
      onCloseModal();
    };

    const onResetAsset = (): void => {
      if (!permissionToEditAsset.hasUserAccess()) {
        return;
      }
      triggerChange(undefined);
    };

    const getPermissionForcedAssetValue = (selectedAsset: Asset | undefined) => {
      if (!selectedAsset) {
        return undefined;
      }
      if (!permissionToEditAsset.hasUserAccess()) {
        const { forcedValue } = permissionToEditAsset;
        if (forcedValue) {
          const assetId = parseInt(forcedValue, 10);
          return assetList.find((asset) => asset.id === assetId);
        }
      }

      return selectedAsset;
    };

    const renderUnauthorizedMessage = () => {
      if (!permissionToEditAsset.hasUserAccess()) {
        return permissionToEditAsset.renderUnauthorizedMessage();
      }

      return undefined;
    };

    return (
      <div ref={ref}>
        <div onClick={onOpenModal}>
          <AccessChecker
            renderUnauthorizedMessage={renderUnauthorizedMessage}
            hasAccess={permissionToEditAsset.hasUserAccess()}
          >
            {value ? (
              <span
                className={classNames(
                  classes.selectedFileNameContainer,
                  disabled && classes.disabledSelectedFilename,
                  !permissionToEditAsset.hasUserAccess() && classes.unauthorized
                )}
              >
                <Preformatted className={classes.selectedFileName}>
                  {AssetsUtils.computeAssetNameWithPermission(value, assetList, permissionToEditAsset)}
                </Preformatted>
                <Tooltip title={permissionToEditAsset.hasUserAccess() ? t('charters.assets.edit') : undefined}>
                  <EditFilled hidden={!permissionToEditAsset.hasUserAccess()} />
                </Tooltip>
                <Tooltip title={permissionToEditAsset.hasUserAccess() ? t('charters.assets.reset') : undefined}>
                  <CloseCircleFilled
                    hidden={!permissionToEditAsset.hasUserAccess()}
                    onClick={(e): void => {
                      e.stopPropagation();
                      onResetAsset();
                    }}
                  />
                </Tooltip>
              </span>
            ) : (
              <Button
                type="link"
                className={classNames(
                  size === 'small' && classes.smallAssetSelectorButton,
                  classes.selectAssetButton,
                  !permissionToEditAsset.hasUserAccess() && classes.unauthorized
                )}
                disabled={disabled || !permissionToEditAsset.hasUserAccess()}
              >
                {t(`charters.${assetType}.select`)}
              </Button>
            )}
          </AccessChecker>
        </div>

        <AssetSelectorModal
          onCancel={onCloseModal}
          isVisible={isModalVisible}
          isLoading={isCharterLoading}
          fieldLabel={fieldLabel}
          assetList={assetList}
          selectedAsset={getPermissionForcedAssetValue(value)}
          onSelectAsset={onSelectAsset}
          assetType={assetType}
        />
      </div>
    );
  }
);

export default AssetSelector;
