import {
  ContainerOutlined,
  FolderOpenOutlined,
  SecurityScanOutlined,
  SettingOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { LINK } from '../../Constants';
import { Roles } from '../../core/rule/RolesTypes';
import { RootState } from '../../redux/RootState';
import { APIModelCharter } from '../../services/api/types/ChartersServiceTypes';
import CompaniesUtils from '../../utils/CompaniesUtils';
import SecuredLink from '../secured-link/SecuredLink';

type Props = {
  row: APIModelCharter;
  hasAccess: boolean;
  renderUnauthorizedMessage: () => void;
};

const useStyles = createUseStyles({
  button: {
    marginLeft: 8,
    marginBottom: 8,
  },
});

const getLinkWithId = (link: string, id: number) => {
  return link.replace(':charterId(\\d+)', id.toString());
};

const QuickAccessCell: FunctionComponent<Props> = ({ row, hasAccess, renderUnauthorizedMessage }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const companies = useSelector((state: RootState) => state.companies.list);
  const company = useSelector((state: RootState) => state.companies.current);
  const isUserKnlTeamInCharter = CompaniesUtils.checkIsKnlProfile(companies || []);
  const isCurrentUserPoc = company?.isUserPoc || false;
  const canAccessCharter = row.currentUser && row.currentUser.isActive;
  const canPocAccessCharterLink = (isCurrentUserPoc && canAccessCharter) || !isCurrentUserPoc || isUserKnlTeamInCharter;
  const userRoleInCurrentCharter = row.currentUser && row.currentUser.role;

  const creatorAccessibleLinks = [
    <SecuredLink
      renderUnauthorizedMessage={renderUnauthorizedMessage}
      hasAccess={hasAccess}
      key={`table_link_${row.id}_projects`}
      linkTo={getLinkWithId(LINK.CHARTER_PROJECTS.path, row.id)}
    >
      <Tooltip title={hasAccess ? t(LINK.CHARTER_PROJECTS.title) : undefined}>
        <Button
          className={classes.button}
          type="primary"
          shape="circle"
          icon={<FolderOpenOutlined />}
          size="small"
          disabled={!hasAccess}
        />
      </Tooltip>
    </SecuredLink>,
  ];

  const adminAndOwnerAccessibleLinks = [
    <SecuredLink
      renderUnauthorizedMessage={renderUnauthorizedMessage}
      hasAccess={hasAccess}
      key={`table_link_${row.id}_update`}
      linkTo={getLinkWithId(LINK.CHARTER_UPDATE.path, row.id)}
    >
      <Tooltip title={hasAccess ? t(LINK.CHARTER_UPDATE.title) : undefined}>
        <Button
          className={classes.button}
          type="primary"
          shape="circle"
          icon={<SettingOutlined />}
          size="small"
          disabled={!hasAccess}
        />
      </Tooltip>
    </SecuredLink>,
    <SecuredLink
      renderUnauthorizedMessage={renderUnauthorizedMessage}
      hasAccess={hasAccess}
      key={`table_link_${row.id}_elements`}
      linkTo={getLinkWithId(LINK.CHARTER_GRAPHICAL_ELEMENTS.path, row.id)}
    >
      <Tooltip title={hasAccess ? t(LINK.CHARTER_GRAPHICAL_ELEMENTS.title) : undefined}>
        <Button
          className={classes.button}
          type="primary"
          shape="circle"
          icon={<ContainerOutlined />}
          size="small"
          disabled={!hasAccess}
        />
      </Tooltip>
    </SecuredLink>,
    <SecuredLink
      renderUnauthorizedMessage={renderUnauthorizedMessage}
      hasAccess={hasAccess}
      key={`table_link_${row.id}_permissions`}
      linkTo={getLinkWithId(LINK.CHARTER_PERMISSIONS.path, row.id)}
    >
      <Tooltip title={hasAccess ? t(LINK.CHARTER_PERMISSIONS.title) : undefined}>
        <Button
          className={classes.button}
          type="primary"
          shape="circle"
          icon={<SecurityScanOutlined />}
          size="small"
          disabled={!hasAccess}
        />
      </Tooltip>
    </SecuredLink>,
    <SecuredLink
      renderUnauthorizedMessage={renderUnauthorizedMessage}
      hasAccess={hasAccess}
      key={`table_link_${row.id}_scenarios`}
      linkTo={getLinkWithId(LINK.CHARTER_SCENARIOS.path, row.id)}
    >
      <Tooltip title={hasAccess ? t(LINK.CHARTER_SCENARIOS.title) : undefined}>
        <Button
          className={classes.button}
          type="primary"
          shape="circle"
          icon={<VideoCameraOutlined />}
          size="small"
          disabled={!hasAccess}
        />
      </Tooltip>
    </SecuredLink>,
    ...creatorAccessibleLinks,
  ];

  return (
    <>
      {((userRoleInCurrentCharter && userRoleInCurrentCharter !== Roles.Creator) || isCurrentUserPoc) && (
        <SecuredLink
          renderUnauthorizedMessage={renderUnauthorizedMessage}
          hasAccess={hasAccess}
          key={`table_link_${row.id}_users`}
          linkTo={getLinkWithId(LINK.CHARTER_USERS.path, row.id)}
        >
          <Tooltip title={hasAccess ? t(LINK.CHARTER_USERS.title) : undefined}>
            <Button
              className={classes.button}
              type="primary"
              shape="circle"
              icon={<UserOutlined />}
              size="small"
              disabled={!hasAccess}
            />
          </Tooltip>
        </SecuredLink>
      )}

      {/* eslint-disable-next-line no-nested-ternary */}
      {isCurrentUserPoc && !canPocAccessCharterLink
        ? undefined
        : userRoleInCurrentCharter === Roles.Creator
        ? creatorAccessibleLinks
        : adminAndOwnerAccessibleLinks}
    </>
  );
};

export default QuickAccessCell;
