import { Modal, Typography } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import Preformatted from '../components/preformatted/Preformatted';
import { THEME } from '../Constants';
import { Role } from '../core/rule/Roles';
import { Roles } from '../core/rule/RolesTypes';
import { RootState } from '../redux/RootState';
import CompaniesUtils from '../utils/CompaniesUtils';
import { PermissionAction, PermissionList } from '../utils/types/CharterPermissionTypes';
import { UseCanAccessResult } from '../utils/types/PermissionType';

const { Title } = Typography;

const useStyles = createUseStyles({
  permissionMessageTitle: {
    alignItems: 'center',
  },
  permissionMessage: {
    color: 'white',
  },
  permissionName: {
    backgroundColor: THEME.DEFAULT.PREFORMATTED_IN_TOOLTIP.BACKGROUND_COLOR,
    fontStyle: THEME.DEFAULT.PREFORMATTED_IN_TOOLTIP.FONT_STYLE,
  },
});

const useCanAccess = (
  permissionCode?: string,
  overrideCharterPermission?: {
    roles: Roles[];
    permissionAction: PermissionAction;
  }
): UseCanAccessResult => {
  const currentCompany = useSelector((state: RootState) => state.companies.current);
  const charters = useSelector((state: RootState) => state.charters.list);
  const charterPermissions = useSelector((state: RootState) => state.charters.currentCharterPermissions);
  const currentUserRole = useSelector((state: RootState) => state.user.role) ?? new Role(Roles.Creator);
  const isCurrentUserPoc = currentCompany?.isUserPoc || false;
  const companies = useSelector((state: RootState) => state.companies.list);
  const isKnlTeam = companies ? CompaniesUtils.checkIsKnlProfile(companies) : false;

  const forcedValue = useMemo(() => {
    if (!charterPermissions) {
      return undefined;
    }

    const charterPermission = charterPermissions.find((permission) => permission.code === permissionCode);

    return currentUserRole.getPermissionForcedValue(charterPermission);
  }, [charterPermissions, permissionCode, currentUserRole]);

  // Is the feature hidden
  const [isHidden, setIsHidden] = useState<boolean>(false);

  // Is the feature disabled
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const { t } = useTranslation();
  const classes = useStyles();

  // Unauthorized message
  const renderUnauthorizedMessage = () => {
    if (!(charters && permissionCode)) {
      return undefined;
    }
    const permission = charterPermissions?.find((charterPermission) => charterPermission.code === permissionCode);
    const permissionName = permission ? permission.name : permissionCode;

    return (
      <>
        <Title level={5} style={{ color: 'white' }} className={classes.permissionMessageTitle}>
          {t('charters.permissions.access.unauthorizedAccess')}
        </Title>
        <Text className={classes.permissionMessage}>
          {t('charters.permissions.access.messagePart1')}
          <Preformatted className={classes.permissionName}>{permissionName}</Preformatted>
          {t('charters.permissions.access.messagePart2')}
        </Text>
      </>
    );
  };

  // Unauthorized message
  const renderUnauthorizedWebMessage = () => {
    if (!(companies && permissionCode)) {
      return undefined;
    }

    const charterWithPermission = currentCompany?.charters.find((charter) =>
      charter?.currentUser?.permissions?.find((charterPermission) => charterPermission.code === permissionCode)
    );
    const charterPermission = charterWithPermission?.currentUser?.permissions?.find(
      (permission) => permission.code === permissionCode
    );
    const permissionName = charterPermission ? charterPermission.name : permissionCode;

    return (
      <>
        <Title level={5} style={{ color: 'white' }} className={classes.permissionMessageTitle}>
          {t('charters.permissions.access.unauthorizedAccess')}
        </Title>
        <Text className={classes.permissionMessage}>
          {t('charters.permissions.access.messagePart1')}
          <Preformatted className={classes.permissionName}>{permissionName}</Preformatted>
          {t('charters.permissions.access.messagePart2')}
        </Text>
      </>
    );
  };

  // Unauthorized message modal
  const renderUnauthorizedMessageModal = () => {
    if (!(charters && permissionCode)) {
      return;
    }

    const name = charterPermissions?.find((charterPermission) => charterPermission.code === permissionCode)?.name;
    const permissionName = name ?? permissionCode;

    Modal.info({
      title: t('charters.permissions.access.unauthorizedAccess'),
      width: 600,
      content: (
        <Text>
          {t('charters.permissions.access.messagePart1')}
          <Preformatted className={classes.permissionName}>{permissionName}</Preformatted>
          {t('charters.permissions.access.messagePart2')}
        </Text>
      ),
    });
  };

  // Has user access to the company
  const hasUserAccessToCompany = (companyId: number) => {
    if (CompaniesUtils.isUserPocInCompany(companies, companyId) || isKnlTeam) {
      return true;
    }

    if (!(companies && permissionCode)) {
      return false;
    }

    const company = companies.find((comp) => comp.id === companyId);
    const hasAccess = company?.charters.some((charter) => {
      const charterPermission = charter?.currentUser?.permissions?.find(
        (permission) => permission.code === permissionCode
      );
      return charterPermission !== undefined && charterPermission.access;
    });
    return hasAccess !== undefined ? hasAccess : false;
  };

  // Has user access to a given charter
  const hasUserAccessToCharter = (charterId: number) => {
    if (isCurrentUserPoc || isKnlTeam) {
      return true;
    }

    if (!(charters && permissionCode)) {
      return false;
    }

    const charter = charters.find((ch) => ch.id === charterId);

    const permission = charter?.currentUser?.permissions?.find(
      (charterPermission) => charterPermission.code === permissionCode
    );
    return permission !== undefined && permission.access;
  };

  // Has user access to the company
  const hasUserAccessToCompanyFeature = (companyId: number) => {
    if (CompaniesUtils.isUserPocInCompany(companies, companyId) || isKnlTeam) {
      return true;
    }

    if (!(companies && permissionCode)) {
      return false;
    }

    const company = companies.find((comp) => comp.id === companyId);
    const hasAccess = company?.charters.some((charter) => {
      const permissionToAccessCharter = charter?.currentUser?.permissions?.find(
        (charterPermission) => charterPermission.code === PermissionList.WEB_DASHBOARD
      );
      const permission = charter?.currentUser?.permissions?.find(
        (charterPermission) => charterPermission.code === permissionCode
      );
      return permission?.access && permissionToAccessCharter?.access;
    });
    return hasAccess ?? false;
  };

  // Has user access to the feature
  const hasUserAccess = () => {
    if (isCurrentUserPoc || isKnlTeam) {
      return true;
    }

    if (!(charterPermissions && permissionCode)) {
      return false;
    }

    const charterPermission = charterPermissions.find((permission) => permission.code === permissionCode);
    return charterPermission ? currentUserRole.hasPermissionTo(charterPermission) : false;
  };

  useEffect(() => {
    // Update isHidden state
    if (overrideCharterPermission) {
      const isUserConcerned =
        overrideCharterPermission.roles.filter((role) => currentUserRole.getWeight() === new Role(role).getWeight())
          .length > 0;
      if (overrideCharterPermission.permissionAction === PermissionAction.HIDDEN) {
        setIsHidden(isUserConcerned);
      }
      // Update isDisabled state
      if (overrideCharterPermission.permissionAction === PermissionAction.DISABLED) {
        setIsDisabled(isUserConcerned);
      }
    }
  }, [currentUserRole, overrideCharterPermission]);

  return {
    isDisabled,
    isHidden,
    renderUnauthorizedMessage,
    renderUnauthorizedWebMessage,
    renderUnauthorizedMessageModal,
    hasUserAccessToCompany,
    hasUserAccessToCompanyFeature,
    hasUserAccessToCharter,
    hasUserAccess,
    forcedValue,
  };
};

export default useCanAccess;
