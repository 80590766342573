import { Button, message, Modal } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useAuth0 } from '../../core/auth/Auth0Config';
import { postVerifyUserEmailById } from '../../services/api/UsersService';
import AuthUtils from '../../utils/AuthUtils';

type Props = {
  visible: boolean;
};

const useStyles = createUseStyles({
  logoutButton: {
    float: 'left',
  },
});

const EmailNotVerifiedModal: FunctionComponent<Props> = ({ visible }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user, logout } = useAuth0();

  const handleResendEmail = () => {
    const auth0UserIdPrefix = process.env.REACT_APP_AUTH0_USER_ID_PREFIX ?? '';
    const userId = user?.sub.replace(auth0UserIdPrefix, '');

    postVerifyUserEmailById(userId).then(() => {
      message.success(t('emailNotVerified.emailSent'));
    });
  };

  const logUserOut = (): void => {
    const auth0ReturnTo = AuthUtils.getAuthEnvironmentVariable(process.env.REACT_APP_AUTH0_RETURN_TO) || '';

    logout({ returnTo: auth0ReturnTo });
  };

  return (
    <Modal
      title={t('emailNotVerified.title')}
      visible={visible}
      footer={[
        <Button key="logout" onClick={logUserOut} type="link" className={classes.logoutButton}>
          {t('emailNotVerified.logout')}
        </Button>,
        <Button key="resendEmail" onClick={handleResendEmail} type="primary">
          {t('emailNotVerified.resendEmail')}
        </Button>,
        <Button key="reload" onClick={() => window.location.reload()} type="primary">
          {t('emailNotVerified.reload')}
        </Button>,
      ]}
      closable={false}
    >
      <p>{t('emailNotVerified.content')}</p>
    </Modal>
  );
};

export default EmailNotVerifiedModal;
