import log from 'loglevel';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import LottiePlayer from '../../../../../../../components/lottie/LottiePlayer';
import { RootState } from '../../../../../../../redux/RootState';
import { fetchAnimationByCharterIdAndParams } from '../../../../../../../services/api/ChartersService';
import {
  AnimationPositionConfig,
  AnimationPositionPresets,
  LottieAnimation,
  MaskCodes,
} from '../../../../../../../utils/types/AnimationTypes';
import { PlayerSize, Project } from '../../../../../../../utils/types/ProjectTypes';
import { ExtendedProjectElement, ProjectElementType } from '../hooks/useProjectFinalRenderData';

type Props = {
  project: Project;
  playerSize?: PlayerSize;
  visibleProjectElement?: ExtendedProjectElement;
};

type StyleProps = {
  playerSize: PlayerSize;
};

const useStyles = createUseStyles({
  maskContainer: {
    zIndex: 2,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  maskLottiePlayer: ({ playerSize }: StyleProps) => ({
    height: playerSize?.height ?? '100%',
    width: playerSize?.width ?? '100%',
    position: 'relative',
    margin: 'auto',
  }),
});

const ProjectFinalRenderMask: FunctionComponent<Props> = ({ project, playerSize, visibleProjectElement }: Props) => {
  const classes = useStyles({ playerSize });
  const [lottieJson, setLottieJson] = useState<LottieAnimation>();

  const charter = useSelector((state: RootState) => state.charters.current);
  const charterId = charter?.id;

  const hasMiniMask = project.mask && project.mask === MaskCodes.MINI;
  const isIntroOrOutroVisible = visibleProjectElement && visibleProjectElement.elementType !== ProjectElementType.SCENE;

  // Fetch the Lottie animation for the mask
  useEffect(() => {
    if (!(charterId && hasMiniMask)) {
      return;
    }

    const params = {
      animationName: project.mask!,
      theme: project.theme,
      format: project.videoFormat,
      useOriginalSettings: !project.customColors,
      position: {
        code: AnimationPositionConfig.FULL_SCREEN.code,
        x: AnimationPositionPresets.FULL_SCREEN.x,
        y: AnimationPositionPresets.FULL_SCREEN.y,
      },
    };

    fetchAnimationByCharterIdAndParams(charterId, params)
      .then((response) => {
        setLottieJson(response);
      })
      .catch((e) => {
        log.debug('Error during mask animation fetch', e);
      });
  }, [charterId, project.mask, project.theme, project.customColors, project.videoFormat]);

  if (!hasMiniMask || isIntroOrOutroVisible) {
    return null;
  }

  return (
    <div className={classes.maskContainer}>
      <LottiePlayer
        animationData={lottieJson}
        play={false}
        loop={false}
        keepLastFrame
        className={classes.maskLottiePlayer}
      />
    </div>
  );
};

export default ProjectFinalRenderMask;
