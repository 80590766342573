import { Typography } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import KannellePageHeader from '../../components/page-header/KannellePageHeader';
import { LINK } from '../../Constants';
import { RootState } from '../../redux/RootState';
import CompaniesUtils from '../../utils/CompaniesUtils';
import UsersTable from './users-table/UsersTable';

const { Title } = Typography;

const Users: FunctionComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const companies = useSelector((state: RootState) => {
    return state.companies.list;
  });
  const isUserKnlTeamInCharter = CompaniesUtils.checkIsKnlProfile(companies || []);

  if (!isUserKnlTeamInCharter) {
    history.push(LINK.UNAUTHORIZED.path);
  }

  return (
    <>
      <KannellePageHeader
        title={
          <Title id="users" level={3}>
            {t('users.title')}
          </Title>
        }
      >
        <UsersTable showAction />
      </KannellePageHeader>
    </>
  );
};

export default Users;
