import { VideoCameraAddOutlined } from '@ant-design/icons';
import { Button, message, Modal, Steps, Tooltip } from 'antd';
import { BreadcrumbProps } from 'antd/es/breadcrumb';
import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import Title from 'antd/lib/typography/Title';
import { CancelTokenSource } from 'axios';
import { cloneDeep, find, findIndex, isEqual } from 'lodash';
import log from 'loglevel';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { Link, Prompt, useHistory, useLocation } from 'react-router-dom';
import AccessChecker from '../../../../../components/access-checker/AccessChecker';
import KannelleLoader from '../../../../../components/loader/KannelleLoader';
import KannelleHelpButton from '../../../../../components/page-header/KannelleHelpButton';
import KannellePageHeader from '../../../../../components/page-header/KannellePageHeader';
import { DEVICE_SIZES_QUERIES, LINK, LOGGING_EVENT, THEME } from '../../../../../Constants';
import useCanAccess from '../../../../../hooks/useCanAccess';
import useCharterPermissions from '../../../../../hooks/useCharterPermissions';
import useCountdown from '../../../../../hooks/useCountdown';
import useSocket from '../../../../../hooks/useSocket';
import JoyRideHelpCharterProjectEditor from '../../../../../onboarding/JoyRideHelpCharterProjectEditor';
import { RootState } from '../../../../../redux/RootState';
import { APIManager } from '../../../../../services/api/APIManager';
import {
  generateCharterProjectFinalVideo,
  getCharterProjectDetailsById,
  updateCharterProject,
} from '../../../../../services/api/ChartersService';
import { SocketManager } from '../../../../../services/api/SocketManager';
import { patchSubtitleBlock } from '../../../../../services/api/SubtitlesService';
import {
  ProjectFinalVideoProcessingErrorResponse,
  ProjectProcessingResponse,
  ProjectUpdateResponse,
} from '../../../../../services/api/types/WebSocketTypes';
import CompaniesUtils from '../../../../../utils/CompaniesUtils';
import ProjectUtils from '../../../../../utils/ProjectUtils';
import TimeUtils from '../../../../../utils/TimeUtils';
import { PermissionList } from '../../../../../utils/types/CharterPermissionTypes';
import {
  Project,
  ProjectScene,
  ProjectSceneElementsCode,
  ProjectSceneKind,
} from '../../../../../utils/types/ProjectTypes';
import ProjectScenesCarousel from './project-scenes-carousel/ProjectScenesCarousel';
import ProjectFinalization from './ProjectFinalization';
import ProjectMetadata from './ProjectMetadata';
import ProjectSceneEditor from './ProjectSceneEditor';

const { confirm } = Modal;

const { Step } = Steps;

const useStyles = createUseStyles({
  pageContent: {
    backgroundColor: '#FFFFFF',
    padding: 24,
    margin: 24,
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      margin: 0,
      padding: '5px 10px',
    },
  },
  titleWithIcon: {
    fontSize: '26px !important',
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    backgroundColor: THEME.DEFAULT.MAIN_COLOR,
    minWidth: 32,
  },
  projectConfigurationMainContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  projectMetadataContainer: {
    flex: 3,
    marginRight: 16,
    '& .ant-card-body': {
      padding: '10px 14px',
    },
  },
  projectStepsContainer: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  stepActionsContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  changeStepButton: {
    marginTop: 24,
  },
  confirmModal: {
    '& .ant-modal-confirm-body-wrapper': {
      position: 'relative',
    },
  },
  confirmCancelButton: {
    display: 'none',
  },
  confirmContinueWithoutSavingButton: {
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
  unsavedChangesConfirmedModal: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    marginBottom: 8,
  },
});

const ProjectEditor: FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const [currentStep, setCurrentStep] = useState(0);
  const [projectId, setProjectId] = useState<number>();
  const [project, setProject] = useState<Project>();
  const [isProjectUpdated,setProjectUpdated] = useState<boolean>(false)
  const [selectedScene, setSelectedScene] = useState<ProjectScene>();
  const [editedScene, setEditedScene] = useState<ProjectScene>();
  const [estimatedFinalizationDuration, setEstimatedFinalizationDuration] = useState<number>();
  const [isEstimatedFinalizationDurationVisible, setIsEstimatedFinalizationDurationVisible] = useState(false);
  const [isSceneEdited, setIsSceneEdited] = useState(false);
  const [isSceneInError, setIsSceneInError] = useState(false);
  const [isLoadingProjectDetails, setIsLoadingProjectDetails] = useState(false);
  const [isProjectUpdateLoading, setIsProjectUpdateLoading] = useState(false);
  const [isProjectTitleUpdateLoading, setIsProjectTitleUpdateLoading] = useState(false);
  const [isFinalVideoModalVisible, setIsFinalVideoModalVisible] = useState(false);
  const [isVideoFinalizeLoading, setIsVideoFinalizeLoading] = useState(false);
  const [rooms, setRooms] = useState<string[]>();
  const [runHelp, setRunHelp] = useState(false);
  const permissionToFinalizeProject = useCanAccess(PermissionList.PROJECT_FINALIZE);
  const permissionToEditIntro = useCanAccess(PermissionList.INTRO);
  const permissionToEditOutro = useCanAccess(PermissionList.OUTRO);
  const permissionToEditLogo = useCanAccess(PermissionList.LOGO);
  const permissionToAccessCustomColors = useCanAccess(PermissionList.CUSTOM_COLORS);
  const permissionToDisplayLogoOnSlides = useCanAccess(PermissionList.LOGO_INCLUDE_SLIDES);
  const permissionToEditLogoPosition = useCanAccess(PermissionList.LOGO_POSITION);
  const permissionToEditLogoSize = useCanAccess(PermissionList.LOGO_SIZE);

  const isSocketConnected = useSocket(rooms ?? undefined);
  const previousProject = useRef<Project>();
  const callbackToExecute = useRef<() => void>();
  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());
  const currentCharter = useSelector((state: RootState) => state.charters.current);
  const currentCharterId = currentCharter?.id;
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);
  const currentCompany = useSelector((state: RootState) => state.companies.current);
  const currentUser = useSelector((state: RootState) => state.user);
  const companies = useSelector((state: RootState) => state.companies.list);
  const isKnlTeam = companies ? CompaniesUtils.checkIsKnlProfile(companies) : false;

  useCharterPermissions();

  const estimatedFinalizationTimeLeft = useCountdown(estimatedFinalizationDuration);

  // Visual index of the selected scene in the project (not the 'scene.index' property)
  const selectedSceneIndex = useMemo(() => {
    return project && selectedScene && project.scenes && project.scenes.findIndex((s) => selectedScene.id === s.id);
  }, [project, selectedScene]);

  // If the project is created from a scenario, use the scenario scene title, else use the visual title, built using the visual index
  const selectedSceneTitle = useMemo(() => {
    if (project && selectedScene && selectedScene.title) {
      return selectedScene.title;
    }

    return selectedSceneIndex !== undefined && selectedSceneIndex >= 0
      ? t('charters.projects.projectEditor.sceneEditor.defaultTitle', {
          sceneIndex: selectedSceneIndex + 1,
        })
      : t('charters.projects.projectEditor.sceneEditor.defaultTitle');
  }, [selectedScene, selectedSceneIndex, project]);

  // Check if the project has at least one active scene
  const hasProjectActiveScenes = useMemo(() => {
    return project?.scenes?.some((scene) => scene.isActive) ?? false;
  }, [project]);

  const patchProject = useCallback(
    (updatedProject: Project) => {
      if (!currentCharterId || runHelp) {
        return;
      }

      const cancelTokenSource = cancelTokenSourceRef.current;
      const updateProjectParams = ProjectUtils.buildUpdateProjectParams(updatedProject);
      const messageKey = `message_${updatedProject.id}`;

      if (!updateProjectParams) {
        return;
      }

      setIsProjectUpdateLoading(true);
      message.loading({
        content: t('charters.projects.projectEditor.projectUpdateLoading'),
        key: messageKey,
        duration: 0, // Do not dismiss: the loading message will be updated to a success or error message
      });

      // Save the project we are patching as the new reference value
      previousProject.current = updatedProject;

      updateCharterProject(currentCharterId, updatedProject.id, updateProjectParams, cancelTokenSource)
        .then((updatedProjectResponse) => {
          setProject(updatedProjectResponse);
          message.success({ content: t('charters.projects.projectEditor.projectUpdateSuccess'), key: messageKey });
        })
        .catch((error) => {
          log.error(error);
          message.error({ content: t('charters.projects.projectEditor.projectUpdateError'), key: messageKey });
          setIsVideoFinalizeLoading(false);
        })
        .finally(() => {
          setIsProjectUpdateLoading(false);
          setIsProjectTitleUpdateLoading(false);
        });
    },
    [currentCharterId]
  );

  useEffect(() => {
    if (!currentCharterId) {
      return;
    }

    setRooms([
      `charters/${currentCharterId}/projects`,
      ...(project ? [`charters/${currentCharterId}/projects/${project.projectUuid}`] : []),
    ]);
  }, [currentCharterId, project]);

  useEffect(() => {
    if (!currentCharterId) {
      return;
    }

    const locationState = location.state as any;

    // If there are missing data from the location state, we redirect to the projects list page
    if (!(locationState && locationState.projectId)) {
      history.push(LINK.CHARTER_PROJECTS.path.replace(':charterId', currentCharterId.toString()).replace('(\\d+)', ''));
      return;
    }

    // Else we get the projectId from the location state
    const { projectId: id } = locationState as any;
    setProjectId(id);
  }, [history, location, currentCharterId]);

  // Update the project with the permission forced value
  useEffect(() => {
    if (!(project && currentCharter)) {
      return;
    }

    // Comparison of projects
    const projectWithRestrictions = ProjectUtils.getProjectWithPermissionForcedValues(
      currentCharter,
      project,
      permissionToEditIntro,
      permissionToEditOutro,
      permissionToEditLogo,
      permissionToEditLogoSize,
      permissionToEditLogoPosition,
      permissionToDisplayLogoOnSlides,
      permissionToAccessCustomColors
    );

    if (!isEqual(project, projectWithRestrictions)) {
      setProject(projectWithRestrictions);
    }
  }, [
    currentCharter,
    project,
    permissionToEditIntro,
    permissionToEditOutro,
    permissionToEditLogo,
    permissionToEditLogoSize,
    permissionToEditLogoPosition,
    permissionToDisplayLogoOnSlides,
    permissionToAccessCustomColors,
  ]);

  // Callback to fetch the details of a Project
  const fetchCharterProjectDetails = useCallback((charterId: number, concernedProjectId: number): void => {
    setIsLoadingProjectDetails(true);
    const cancelTokenSource = cancelTokenSourceRef.current;
    getCharterProjectDetailsById(charterId, concernedProjectId, cancelTokenSource)
      .then((projectResponse) => {
        setProject(projectResponse);
        // Save the fetched project as the previousProject ref initial value
        previousProject.current = projectResponse;
      })
      .catch((e) => {
        log.error('Error during charter project details fetch', e);
      })
      .finally(() => {
        setIsLoadingProjectDetails(false);
      });
  }, []);

  useEffect(() => {
    if (!(currentCharterId && projectId)) {
      return;
    }

    fetchCharterProjectDetails(currentCharterId, projectId);
  }, [currentCharterId, projectId, fetchCharterProjectDetails]);

  // Handle websocket messages
  useEffect(() => {
    const handleUpdatedProject = (
      payload: ProjectUpdateResponse | ProjectProcessingResponse | ProjectFinalVideoProcessingErrorResponse
    ): void => {
      if (payload.action === 'project_patch') {
        // If a Project has been updated
        const updatedProject = (payload as ProjectUpdateResponse).data;

        // If the updatedProject does not correspond to the currently opened one
        if (projectId !== updatedProject.id) {
          return;
        }
        setProjectUpdated(true)
        // Update the ref value: since a websocket is received, we do not need to patch the project (another client did it to trigger the WS)
        previousProject.current = updatedProject;
        setProject(updatedProject);

        // Ensure the previous selected scene is the right one (in case of a created scene with a temporary id, since the id is changed when
        // the scene gets persisted, we ensure it is the right scene with the index)
        if (selectedScene) {
          const updatedSelectedScene = updatedProject.scenes?.find((s) => s.index === selectedScene.index);
          if (updatedSelectedScene) {
            setSelectedScene(updatedSelectedScene);
          }
        }

        // If there is a stored callback to execute after the project patch, we execute it once
        if (callbackToExecute && callbackToExecute.current) {
          callbackToExecute.current();
          callbackToExecute.current = undefined;
        }
      } else if (payload.action === 'finalvideo_put') {
        // If a Project has been finalized
        const finalizedProject = (payload as ProjectProcessingResponse).data;

        // If the message concerns the finalVideo in PROCESSING, or if the projectUuid does not match, ignore it
        if (
          !finalizedProject.finalVideo ||
          finalizedProject.finalVideo.status !== 'PROCESSED' ||
          !(project && finalizedProject.projectUuid === project.projectUuid)
        ) {
          return;
        }

        const updatedProject = {
          ...previousProject.current,
          finalVideo: finalizedProject.finalVideo,
          lastFinalizedAt: finalizedProject.lastFinalizedAt,
        } as Project;

        previousProject.current = updatedProject;
        setProject(updatedProject);

        message.success(t('charters.projects.projectEditor.steps.finalizeSuccess'));
        setIsEstimatedFinalizationDurationVisible(false);
        setEstimatedFinalizationDuration(undefined);
        setIsVideoFinalizeLoading(false);
        setIsFinalVideoModalVisible(true);

        // Analytics
        loggingManager.logEvent(LOGGING_EVENT.FINALIZED_PROJECT, {
          charterId: currentCharterId,
          companyId: currentCompany?.id,
          companyCheckoutChannel: currentCompany?.checkoutChannel,
          companyName: currentCompany?.name,
          charterName: currentCharter?.name,
          companySubscriptionLastStatus: currentCompany?.chargebeeSubscriptionLastStatus,
          userActiveInCharter: true,
          userRoleInCharter: currentUser.role?.currentRole,
          projectAudioVolume: project?.audioVolume,
          projectBackgroundMusicVolume: project.music?.volume,
          projectCustomColors: project?.customColors,
          projectId: project?.projectUuid,
          projectTheme: project?.theme,
          projectTotalScenes: project?.scenes?.length,
          projectWatermarkPosition: project?.logo?.positionCode,
        });
      } else if (payload.action === 'finalvideo_error') {
        log.error('Project processing error', payload);
        message.error(
          <>
            {t('charters.projects.projectEditor.steps.finalizeError')}
            <br />
            {t('support.global.emojiFingerRight')}
            <a href={`mailto:${t('support.global.supportEmail')}`}>{t('support.global.supportEmail')}</a>
            {t('support.global.emojiFingerLeft')}
          </>
        );
        setIsVideoFinalizeLoading(false);
        setIsEstimatedFinalizationDurationVisible(false);
        setEstimatedFinalizationDuration(undefined);

        // Analytics
        loggingManager.logEvent(LOGGING_EVENT.FINALIZING_ERROR_PROJECT, {
          charterId: currentCharterId,
          companyId: currentCompany?.id,
          companyCheckoutChannel: currentCompany?.checkoutChannel,
          companyName: currentCompany?.name,
          charterName: currentCharter?.name,
          companySubscriptionLastStatus: currentCompany?.chargebeeSubscriptionLastStatus,
          userActiveInCharter: true,
          userRoleInCharter: currentUser.role?.currentRole,
          projectAudioVolume: project?.audioVolume,
          projectBackgroundMusicVolume: project?.music?.volume,
          projectCustomColors: project?.customColors,
          projectId: project?.projectUuid,
          projectTheme: project?.theme,
          projectTotalScenes: project?.scenes?.length,
          projectWatermarkPosition: project?.logo?.positionCode,
        });
      }
    };

    if (isSocketConnected) {
      SocketManager.onMessage(handleUpdatedProject);

      return (): void => SocketManager.offMessage(handleUpdatedProject);
    }

    return undefined;
  }, [isSocketConnected, projectId, selectedScene, callbackToExecute]);

  // If no scene is selected, we select the first one
  useEffect(() => {
    if (selectedScene || !(project && project.scenes && project.scenes.length > 0)) {
      return;
    }

    setSelectedScene(project.scenes[0]);
  }, [project]);

  // Whenever the selected scene changes, we also ensure the editedScene is updated
  useEffect(() => {
    if (!selectedScene) {
      return;
    }

    setEditedScene(selectedScene);
    setIsSceneEdited(false);
    setIsSceneInError(false);
  }, [selectedScene]);

  useEffect(() => {
    if (estimatedFinalizationDuration !== undefined && estimatedFinalizationDuration > 0) {
      setIsEstimatedFinalizationDurationVisible(true);
    }
  }, [estimatedFinalizationDuration]);

  const onSubmitScene = useCallback(
    (updatedScene: ProjectScene) => {
      if (!(project && project.scenes)) {
        return;
      }

      const newScenes = [...project.scenes];
      const updatedSceneIndex = newScenes.findIndex(
        (scene) => scene.id === updatedScene.id || scene.index === updatedScene.index
      );

      if (updatedSceneIndex >= 0) {
        newScenes.splice(updatedSceneIndex, 1, updatedScene);
        patchProject({ ...project, scenes: newScenes });
        setSelectedScene(updatedScene);
      }

      // Analytics
      if (ProjectUtils.isSlideScene(updatedScene)) {
        const { isActive } = updatedScene;
        const event = isActive ? LOGGING_EVENT.ADD_SLIDE : LOGGING_EVENT.UNUSE_SLIDE;
        loggingManager.logEvent(event, {
          charterId: currentCharter?.id,
          companyId: currentCompany?.id,
          companyCheckoutChannel: currentCompany?.checkoutChannel,
          companyName: currentCompany?.name,
          charterName: currentCharter?.name,
          companySubscriptionLastStatus: currentCompany?.chargebeeSubscriptionLastStatus,
          userActiveInCharter: true,
          userRoleInCharter: currentUser.role?.currentRole,
          projectId: project.projectUuid,
          sceneIndex: updatedSceneIndex,
          sceneKind: ProjectSceneKind.SLIDE.code,
          slideAnimationText: updatedScene.animation?.animationTexts?.map((at) => at.text),
          slideDuration: updatedScene.animation?.duration,
        });
      }
    },
    [project]
  );

  const callbackIfUnsavedChangesConfirmed = (
    callback: (saveConfirmed?: boolean) => void,
    confirmTitle?: string,
    confirmDescription?: string,
    skipConfirm = false,
    selectedElement?: ProjectSceneElementsCode
  ): void => {
    if (skipConfirm || !(isSceneEdited && selectedScene)) {
      callback();
    } else {
      const confirmModal = confirm({
        title: confirmTitle || t('charters.projects.projectEditor.unsavedChangesConfirmTitle'),
        className: classes.confirmModal,
        width: 600,
        content: (
          <div>
            {confirmDescription ||
              t('charters.projects.projectEditor.unsavedChangesConfirmDescription', {
                sceneName: selectedSceneTitle,
              })}
            {/* Trick: absolutely positioned button in the confirm modal footer since this Modal.confirm() API does not allow custom footer */}
            <Button
              onClick={() => {
                callback(false); // Execute the action
                confirmModal.destroy(); // Close the modal
              }}
              type="link"
              className={classes.confirmContinueWithoutSavingButton} // Position the button absolutely at the footer level
            >
              {t('global.continueWithoutSaving')}
            </Button>
          </div>
        ),
        closable: true,
        maskClosable: true,
        cancelButtonProps: { className: classes.confirmCancelButton }, // Hide the 'cancel' button: the only way to cancel is through the close icon
        okText: t('global.saveAndContinue'),
        okButtonProps: { disabled: isSceneInError },
        onOk: () => {
          if (!editedScene) {
            callback();
            return;
          }

          if (!selectedElement || selectedElement !== ProjectSceneElementsCode.SUBTITLES) {
            onSubmitScene(editedScene); // Save the changes
            callbackToExecute.current = callback;
          } else {
            callback(true);
          }
        },
      });
    }
  };

  const onNextStepClick = (callbackIfConfirmed?: () => void) => {
    const callbackIfGoToNextStepConfirmed = () => {
      setCurrentStep(1);
      // If a callback is provided, execute it once the modal is confirmed
      if (callbackIfConfirmed) {
        callbackIfConfirmed();
      }
    };

    // Check if there are unsaved changes and ask for confirmation before going to the next step
    callbackIfUnsavedChangesConfirmed(
      callbackIfGoToNextStepConfirmed,
      undefined,
      t('charters.projects.projectEditor.unsavedChangesNextStepConfirmDescription', {
        sceneName: selectedSceneTitle,
      })
    );
  };

  const onGenerateVideoClick = () => {
    if (!(currentCharterId && project && hasProjectActiveScenes)) {
      return;
    }

    setEstimatedFinalizationDuration(ProjectUtils.estimateProjectProcessingTimeInSeconds(project));
    setIsVideoFinalizeLoading(true);

    // Analytics
    loggingManager.logEvent(LOGGING_EVENT.FINALIZING_PROJECT, {
      charterId: currentCharterId,
      companyId: currentCompany?.id,
      companyCheckoutChannel: currentCompany?.checkoutChannel,
      companyName: currentCompany?.name,
      charterName: currentCharter?.name,
      companySubscriptionLastStatus: currentCompany?.chargebeeSubscriptionLastStatus,
      userActiveInCharter: true,
      userRoleInCharter: currentUser.role?.currentRole,
      projectAudioVolume: project.audioVolume,
      projectBackgroundMusicVolume: project.music?.volume,
      projectCustomColors: project.customColors,
      projectId: project.projectUuid,
      projectTheme: project.theme,
      projectTotalScenes: project.scenes?.length,
      projectWatermarkPosition: project.logo?.positionCode,
    });

    generateCharterProjectFinalVideo(currentCharterId, project.id).catch((e) => {
      message.error(t('charters.projects.projectEditor.steps.finalizeError'));
      log.error(e);
    });
  };

  const onPreviousStepClick = () => {
    setCurrentStep(0);
  };

  const onStepClicked = (clickedStep: number) => {
    if (clickedStep === currentStep) {
      return;
    }

    const callbackIfGoToStepConfirmed = () => {
      setCurrentStep(clickedStep);
    };

    // Check if there are unsaved changes and ask for confirmation before going to the selected step
    callbackIfUnsavedChangesConfirmed(
      callbackIfGoToStepConfirmed,
      undefined,
      t('charters.projects.projectEditor.unsavedChangesGoToStepConfirmDescription', {
        sceneName: selectedSceneTitle,
      })
    );
  };

  const onSelectScene = (scene: ProjectScene) => {
    if (isKnlTeam) {
      console.log(scene);
    }

    // If the scene is already the selected one, return
    if (selectedScene?.id === scene.id) {
      return;
    }

    // Check if there are unsaved changes and ask for confirmation before selecting another scene
    callbackIfUnsavedChangesConfirmed(() => {
      setSelectedScene(scene);

      if (!runHelp) {
        // Analytics
        loggingManager.logEvent(LOGGING_EVENT.SELECT_PROJECT_SCENE, {
          charterId: currentCharterId,
          companyId: currentCompany?.id,
          companyCheckoutChannel: currentCompany?.checkoutChannel,
          companyName: currentCompany?.name,
          charterName: currentCharter?.name,
          companySubscriptionLastStatus: currentCompany?.chargebeeSubscriptionLastStatus,
          userActiveInCharter: true,
          userRoleInCharter: currentUser.role?.currentRole,
          projectId: project?.projectUuid,
          sceneIndex: scene.index,
        });
      }
    });
  };

  const onEditProject = useCallback((newProject: Project) => {
    const updatedScenesIndexOrder = newProject.scenes?.map((s) => s.index) ?? [];
    patchProject({ ...newProject, sceneIndexesOrder: updatedScenesIndexOrder });
  }, []);

  const onEditProjectTitle = useCallback(
    (newTitle: string) => {
      if (!project) {
        return;
      }

      setIsProjectTitleUpdateLoading(true);
      patchProject({ ...project, title: newTitle });
    },
    [project]
  );

  const handleEditProjectSubtitlesLanguage = (isActive: boolean, lang?: string) => {
    if (!project) {
      return;
    }

    patchProject({ ...project, subtitlesLanguage: isActive ? lang : undefined });
  };

  const handleSubtitlesGenerated = (updatedProject: Project) => {
    if (!project) {
      return;
    }

    patchProject(cloneDeep(updatedProject));
  };

  const onEditScene = (updatedScene: ProjectScene, updateInitialScene = false) => {
    if (!project) {
      return;
    }

    setEditedScene(updatedScene);

    const { scenes } = project;
    const index = findIndex(project.scenes, { id: updatedScene.id });

    scenes?.splice(index, 1, updatedScene);

    // This param should be true ONLY when there was a submit that is not related to project (IE the subtitles which api are separated)
    if (updateInitialScene) {
      setSelectedScene(cloneDeep(updatedScene));
    }
  };

  const onAddScene = useCallback(
    (addedScene: ProjectScene) => {
      const callbackIfAddSceneConfirmed = () => {
        const projectRef = previousProject.current ?? project;

        if (!(projectRef && projectRef.scenes)) {
          return;
        }

        const newSceneIndex = projectRef.scenes.length;
        const addedSceneWithTitle = {
          ...addedScene,
          title: t('charters.projects.projectEditor.sceneEditor.defaultTitle', {
            sceneIndex: newSceneIndex + 1,
          }),
        };

        const newScenes = [...projectRef.scenes, addedSceneWithTitle];
        const newProject = { ...projectRef, scenes: newScenes };
        onEditProject(newProject);
        setSelectedScene(addedSceneWithTitle);
        setEditedScene(addedSceneWithTitle);

        // Analytics
        if (ProjectUtils.isSlideScene(addedScene)) {
          loggingManager.logEvent(LOGGING_EVENT.ADD_SLIDE, {
            charterId: currentCharter?.id,
            companyId: currentCompany?.id,
            companyCheckoutChannel: currentCompany?.checkoutChannel,
            companyName: currentCompany?.name,
            charterName: currentCharter?.name,
            companySubscriptionLastStatus: currentCompany?.chargebeeSubscriptionLastStatus,
            userActiveInCharter: true,
            userRoleInCharter: currentUser.role?.currentRole,
            projectId: newProject.projectUuid,
            sceneIndex: newSceneIndex,
            sceneKind: ProjectSceneKind.SLIDE.code,
            slideAnimationText: addedScene.animation?.animationTexts?.map((at) => at.text),
            slideDuration: addedScene.animation?.duration,
          });
        }
      };

      // Check if there are unsaved changes and ask for confirmation before adding a new scene
      callbackIfUnsavedChangesConfirmed(
        callbackIfAddSceneConfirmed,
        undefined,
        t('charters.projects.projectEditor.unsavedChangesAddSceneConfirmDescription', {
          sceneName: selectedSceneTitle,
        })
      );
    },
    [project, previousProject, callbackIfUnsavedChangesConfirmed]
  );

  const onSubmitSubtitleBlock = (selectedElementId: number): void => {
    if (!currentCharter || !selectedScene || !project || !selectedScene || !editedScene || !selectedElementId) {
      return;
    }

    const editedElement = find(editedScene.subtitles?.blocks, {
      id: selectedElementId,
    });

    if (!editedElement) {
      return;
    }

    setIsProjectUpdateLoading(true);

    patchSubtitleBlock(currentCharter.id, project.id, selectedScene.id, selectedElementId, {
      ...editedElement,
    })
      .then(() => {
        setSelectedScene(cloneDeep(editedScene));
      })
      .finally(() => {
        setIsProjectUpdateLoading(false);
        message.success(t('charters.projects.projectEditor.projectUpdateSuccess'));
      });
  };

  const onDeleteScene = useCallback(
    (sceneToDelete: ProjectScene) => {
      const callbackIfDeleteSceneConfirmed = () => {
        const projectRef = previousProject.current ?? project;

        if (!(projectRef && projectRef.scenes)) {
          return;
        }

        const newScenes = [...projectRef.scenes].filter((s) => s.id !== sceneToDelete.id);
        onEditProject({ ...projectRef, scenes: newScenes });

        // If the selected scene is deleted, we select the first scene (if exists)
        if (selectedScene && (selectedScene.id === sceneToDelete.id || selectedScene.index === sceneToDelete.index)) {
          setSelectedScene(newScenes && newScenes.length > 0 ? newScenes[0] : undefined);
        }

        // Analytics
        loggingManager.logEvent(LOGGING_EVENT.DELETE_PROJECT_SCENE, {
          charterId: currentCharter?.id,
          companyId: currentCompany?.id,
          companyCheckoutChannel: currentCompany?.checkoutChannel,
          companyName: currentCompany?.name,
          charterName: currentCharter?.name,
          companySubscriptionLastStatus: currentCompany?.chargebeeSubscriptionLastStatus,
          userActiveInCharter: true,
          userRoleInCharter: currentUser.role?.currentRole,
          projectId: project?.projectUuid,
          sceneIndex: sceneToDelete.index,
        });
      };

      // Check if there are unsaved changes and ask for confirmation before deleting a scene
      callbackIfUnsavedChangesConfirmed(
        callbackIfDeleteSceneConfirmed,
        undefined,
        t('charters.projects.projectEditor.unsavedChangesDeleteSceneConfirmDescription', {
          sceneName: selectedSceneTitle,
        })
      );
    },
    [project, previousProject, callbackIfUnsavedChangesConfirmed]
  );

  const onDuplicateScene = useCallback(
    (sceneToDuplicate: ProjectScene) => {
      const callbackIfDuplicateSceneConfirmed = () => {
        const projectRef = previousProject.current ?? project;

        if (!(projectRef && projectRef.scenes)) {
          return;
        }

        // Compute the index of the new scene
        const scenesIndexes = projectRef.scenes.map((s) => s.index);
        const newSceneIndex = Math.max(...scenesIndexes) + 1;

        const newScenes = [...projectRef.scenes];
        const sceneToDuplicateIndex = newScenes.findIndex((s) => s.id === sceneToDuplicate.id);
        if (!(sceneToDuplicateIndex >= 0)) {
          return;
        }

        const sceneCopy = {
          ...sceneToDuplicate,
          index: newSceneIndex,
          id: new Date().getTime(), // Temporary id
          title: `${sceneToDuplicate.title} (${t('charters.projects.projectEditor.addSceneModal.sceneCopy')})`,
          // We need this to prevent the crop to be fetched for this scene
          isSceneDuplicateTemporary: true,
        };

        newScenes.splice(sceneToDuplicateIndex + 1, 0, sceneCopy);
        onEditProject({ ...projectRef, scenes: newScenes });

        setSelectedScene(sceneCopy);

        // Analytics
        loggingManager.logEvent(LOGGING_EVENT.DUPLICATE_PROJECT_SCENE, {
          charterId: currentCharter?.id,
          companyId: currentCompany?.id,
          companyCheckoutChannel: currentCompany?.checkoutChannel,
          companyName: currentCompany?.name,
          charterName: currentCharter?.name,
          companySubscriptionLastStatus: currentCompany?.chargebeeSubscriptionLastStatus,
          userActiveInCharter: true,
          userRoleInCharter: currentUser.role?.currentRole,
          projectId: project?.projectUuid,
          sceneIndex: sceneToDuplicate.index,
        });
      };

      // Check if there are unsaved changes and ask for confirmation before duplicating a scene
      callbackIfUnsavedChangesConfirmed(
        () => callbackIfDuplicateSceneConfirmed(),
        undefined,
        t('charters.projects.projectEditor.unsavedChangesDuplicateSceneConfirmDescription', {
          sceneName: selectedSceneTitle,
        })
      );
    },
    [project, previousProject, callbackIfUnsavedChangesConfirmed]
  );

  const onReorderScenesDragEnd = useCallback(
    (result: DropResult) => {
      const callbackIfReorderConfirmed = () => {
        const projectRef = previousProject.current ?? project;
        const { source, destination, draggableId } = result;

        if (!(projectRef && projectRef.scenes && destination?.index !== source.index)) {
          return;
        }

        const reorderedScenes = [...projectRef.scenes];
        const draggedScene = find(reorderedScenes, (scene) => {
          const sceneIdentifier = scene.id;
          return sceneIdentifier.toString() === draggableId;
        });
        if (draggedScene && destination) {
          reorderedScenes.splice(source.index, 1);
          reorderedScenes.splice(destination.index, 0, draggedScene);
          onEditProject({ ...projectRef, scenes: reorderedScenes });
        }

        // Analytics
        loggingManager.logEvent(LOGGING_EVENT.REORDER_PROJECT_SCENES, {
          charterId: currentCharter?.id,
          companyId: currentCompany?.id,
          companyCheckoutChannel: currentCompany?.checkoutChannel,
          companyName: currentCompany?.name,
          charterName: currentCharter?.name,
          companySubscriptionLastStatus: currentCompany?.chargebeeSubscriptionLastStatus,
          userActiveInCharter: true,
          userRoleInCharter: currentUser.role?.currentRole,
          projectId: project?.projectUuid,
          sceneIndex: draggedScene?.index,
          sceneKind: draggedScene?.kind,
          newPositionIndex: destination?.index,
          previousPositionIndex: source.index,
        });
      };

      // Check if there are unsaved changes and ask for confirmation before reordering
      callbackIfUnsavedChangesConfirmed(
        callbackIfReorderConfirmed,
        undefined,
        t('charters.projects.projectEditor.unsavedChangesReorderConfirmDescription', {
          sceneName: selectedSceneTitle,
        })
      );
    },
    [project, previousProject, callbackIfUnsavedChangesConfirmed]
  );

  const onIsSceneEditedChange = useCallback((newIsSceneEdited: boolean) => {
    setIsSceneEdited(newIsSceneEdited);
  }, []);

  const onIsSceneInErrorChange = useCallback((newIsSceneInError: boolean) => {
    setIsSceneInError(newIsSceneInError);
  }, []);

  const onShowFinalVideoModal = (): void => {
    setIsFinalVideoModalVisible(true);
  };

  const onHideFinalVideoModal = (): void => {
    setIsFinalVideoModalVisible(false);
  };

  if (!currentCharter) {
    return null;
  }

  if (isLoadingProjectDetails) {
    return <KannelleLoader />;
  }

  const startHelp = (): void => {
    if (isKnlTeam) {
      console.log(project);
    }
    setRunHelp(true);
  };

  const endHelp = (): void => {
    setRunHelp(false);
  };

  const routes = [
    {
      path: '/charters',
      breadcrumbName: t('menu.charters'),
    },
    {
      path: `/charters/${currentCharter.id}`,
      breadcrumbName: currentCharter.name,
    },
    {
      path: `/charters/${currentCharter.id}/projects`,
      breadcrumbName: t('charters.projects.title'),
    },
    {
      path: location.pathname,
      breadcrumbName: t('charters.projects.projectEditor.title'),
    },
  ];

  const breadcrumbProps: BreadcrumbProps = {
    itemRender: (route: Route) => {
      if (route.path === location.pathname) {
        return route.breadcrumbName;
      }
      return <Link to={route.path}>{route.breadcrumbName}</Link>;
    },
    routes,
  };

  let finalizeTooltipMessage;
  if (!hasProjectActiveScenes) {
    finalizeTooltipMessage = t('charters.projects.projectEditor.steps.mustHaveActiveScenes');
  } else if (estimatedFinalizationTimeLeft > 0) {
    finalizeTooltipMessage = t('charters.projects.projectEditor.steps.estimatedFinalizationTime', {
      time: TimeUtils.formatSecondsIntoDuration(estimatedFinalizationTimeLeft),
    });
  } else {
    finalizeTooltipMessage = t('charters.projects.projectEditor.steps.finalizationShouldEndSoon');
  }

  const projectSteps = (
    <>
      <Steps current={currentStep} size="small" onChange={onStepClicked}>
        <Step title={t('charters.projects.projectEditor.steps.edition')} />
        <Step title={t('charters.projects.projectEditor.steps.finalize')} />
      </Steps>

      <div className={classes.stepActionsContainer}>
        <Button className={classes.changeStepButton} onClick={onPreviousStepClick} disabled={currentStep === 0}>
          {t('global.previous')}
        </Button>
        {currentStep === 0 ? (
          <Button type="primary" className={classes.changeStepButton} onClick={() => onNextStepClick()}>
            {t('global.next')}
          </Button>
        ) : (
          <AccessChecker
            renderUnauthorizedMessage={permissionToFinalizeProject.renderUnauthorizedMessage}
            hasAccess={permissionToFinalizeProject.hasUserAccess()}
          >
            <Tooltip
              placement="bottom"
              title={finalizeTooltipMessage}
              visible={isEstimatedFinalizationDurationVisible || !hasProjectActiveScenes}
              zIndex={0}
            >
              <Button
                type="primary"
                className={classes.changeStepButton}
                onClick={onGenerateVideoClick}
                loading={isVideoFinalizeLoading}
                disabled={
                  isVideoFinalizeLoading || !permissionToFinalizeProject.hasUserAccess() || !hasProjectActiveScenes || !isProjectUpdated
                }
              >
                {t('charters.projects.projectEditor.steps.generateVideo')}
              </Button>
            </Tooltip>
          </AccessChecker>
        )}
      </div>
    </>
  );

  const renderStepComponent = () => {
    if (!project) {
      return null;
    }

    const { scenes } = project;
    if (currentStep === 0) {
      return (
        <>
          <ProjectScenesCarousel
            isProjectLoading={isProjectUpdateLoading || isLoadingProjectDetails}
            scenes={scenes}
            selectedScene={selectedScene}
            onSelectScene={onSelectScene}
            format={project?.videoFormat}
            theme={project.theme}
            customColors={project.customColors}
            onAddScene={onAddScene}
            onReorderScenes={onReorderScenesDragEnd}
            onDeleteScene={onDeleteScene}
            onDuplicateScene={onDuplicateScene}
          />

          {selectedScene && editedScene && (
            <>
              <Title level={5}>{selectedSceneTitle}</Title>

              <ProjectSceneEditor
                project={project}
                initialScene={selectedScene}
                editedScene={editedScene}
                isSceneEdited={isSceneEdited}
                isSceneInError={isSceneInError}
                format={project?.videoFormat}
                theme={project.theme}
                customColors={project.customColors}
                audioVolume={project.audioVolume}
                selectedSceneTitle={selectedSceneTitle}
                onEditScene={onEditScene}
                onSubmitScene={onSubmitScene}
                onIsSceneEditedChange={onIsSceneEditedChange}
                onIsSceneInErrorChange={onIsSceneInErrorChange}
                callbackIfUnsavedChangesConfirmed={callbackIfUnsavedChangesConfirmed}
                onSubmitSubtitleBlock={onSubmitSubtitleBlock}
              />
            </>
          )}
        </>
      );
    }

    return <ProjectFinalization project={project} onEditProject={onEditProject} />;
  };

  return (
    <>
      <Prompt when={isSceneEdited} message={t('charters.projects.projectEditor.unsavedChangesPrompt')} />

      <KannellePageHeader
        title={
          <div className={classes.titleWithIcon}>
            {t('charters.projects.projectEditor.title')}
            <KannelleHelpButton startHelp={startHelp} />
          </div>
        }
        breadcrumb={breadcrumbProps}
        className={classes.pageContent}
        avatar={{ className: classes.avatar, icon: <VideoCameraAddOutlined /> }}
      >
        <JoyRideHelpCharterProjectEditor
          runHelp={runHelp}
          callbackRunDone={endHelp}
          project={project}
          selectedScene={selectedScene}
          currentStep={currentStep}
          onEditProject={onEditProject}
          onStepClicked={onStepClicked}
          onSelectScene={onSelectScene}
        />

        <div className={classes.projectConfigurationMainContainer}>
          <div className={classes.projectMetadataContainer}>
            {project && editedScene && (
              <ProjectMetadata
                project={project}
                editedScene={editedScene}
                onEditProjectTitle={onEditProjectTitle}
                onNextStepClick={onNextStepClick}
                isLoading={isLoadingProjectDetails || isProjectUpdateLoading}
                isTitleLoading={isProjectTitleUpdateLoading}
                isSubtitleLoading={isLoadingProjectDetails || isProjectUpdateLoading}
                hasProjectActiveScenes={hasProjectActiveScenes}
                isFinalVideoModalVisible={isFinalVideoModalVisible}
                onShowFinalVideoModal={onShowFinalVideoModal}
                onHideFinalVideoModal={onHideFinalVideoModal}
                onEditProjectSubtitle={handleEditProjectSubtitlesLanguage}
                onSubtitlesGenerated={handleSubtitlesGenerated}
              />
            )}
          </div>
          <div className={classes.projectStepsContainer}>{projectSteps}</div>
        </div>
        {renderStepComponent()}
      </KannellePageHeader>
    </>
  );
};

export default ProjectEditor;
