import React, { FunctionComponent } from 'react';
import { Theme } from '../../../../../utils/ThemeUtils';
import { Animations } from '../../../../../utils/types/AnimationTypes';
import { LottieObject, ThemeAnimationProps } from '../../../../../utils/types/ThemeTypes';
import CharterAnimationThemeEdition from '../CharterAnimationThemeEdition';

const lottieArray: LottieObject[] = [
  {
    key: 'animations.list.plane.publicName',
    aliases: ['Plane', 'Citroën', 'Skateboard', 'Lime'],
    animationName: Animations.PLANE.code,
    fields: [
      'titleText',
      'shape',
      'text',
      'background',
      'backgroundOpacity',
      'titleBackground',
      'titleBackgroundOpacity',
    ],
  },
  {
    key: 'animations.list.ford.publicName',
    aliases: ['Ford', 'Bird', 'Simca'],
    animationName: Animations.FORD.code,
    fields: ['text', 'shape', 'background'],
  },
  {
    key: 'animations.list.mazda.publicName',
    aliases: ['Mazda', 'Mustang'],
    animationName: Animations.MUSTANG.code, // Here Mazda = Mustang except Mustang is on the right and Mazda on the left
    fields: ['text', 'shape', 'background', 'backgroundOpacity'],
  },
  {
    key: 'animations.list.nissan.publicName',
    aliases: ['Nissan'],
    animationName: Animations.NISSAN.code,
    fields: ['shape', 'text', 'background', 'backgroundOpacity'],
  },
  {
    key: 'animations.list.peugeot.publicName',
    aliases: ['Peugeot', 'Vélib'],
    animationName: Animations.PEUGEOT.code,
    fields: ['text', 'shape', 'background'],
  },
  {
    key: 'animations.list.butterfly.publicName',
    aliases: ['Butterfly'],
    animationName: Animations.BUTTERFLY.code,
    fields: ['text', 'background'],
  },
  {
    key: 'animations.list.fox.publicName',
    aliases: ['Fox'],
    animationName: Animations.FOX.code,
    fields: ['text', 'background'],
  },
  {
    key: 'animations.list.owl.publicName',
    aliases: ['Owl'],
    animationName: Animations.OWL.code,
    fields: ['text', 'background'],
  },
  {
    key: 'animations.list.mini.publicName',
    aliases: ['Mini'],
    animationName: Animations.MINI.code,
    isStaticAnimation: true,
    fields: ['maskGradientTop', 'maskGradientBottom'],
  },
];

const BaliTheme: FunctionComponent<ThemeAnimationProps> = ({
  theme,
  charterThemeColors,
  animationThemeColor,
  callback,
  onReset,
  onSubmit,
  disabledActions,
  loadingActions,
}: ThemeAnimationProps) => {
  return (
    <CharterAnimationThemeEdition
      code={theme.code}
      charterThemeColors={charterThemeColors}
      animationThemeColor={animationThemeColor}
      onEditColor={(newColors: Theme[]): void => callback(newColors)}
      lottieArray={lottieArray}
      onReset={onReset}
      onSubmit={onSubmit}
      disabledActions={disabledActions}
      loadingActions={loadingActions}
    />
  );
};

export default BaliTheme;
