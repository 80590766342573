import { PROJECTS } from '../../../../../../../Constants';
import MathUtils from '../../../../../../../utils/MathUtils';

export type VideoTrim = {
  trimStart: number;
  trimEnd: number;
  delay?: number;
};

export class VideoTrimUtils {
  static calculateStartAnchorValue(data: number, intervalTickWidth: number): number {
    if (!data || !intervalTickWidth || data / intervalTickWidth < 0) {
      return 0;
    }
    return data / intervalTickWidth;
  }

  static calculateEndAnchorValue(data: number, intervalTickWidth: number, delay = 0): number {
    if (!data || !intervalTickWidth) {
      return 0;
    }
    return (data - PROJECTS.TRIM.ANCHOR_WIDTH - delay * intervalTickWidth) / intervalTickWidth;
  }

  static calculateStartAnchorPositionOnX(value: number, intervalTickWidth: number): number {
    if (!value || !intervalTickWidth) {
      return 0;
    }
    return value * intervalTickWidth;
  }

  static calculateEndAnchorPositionOnX(value: number, intervalTickWidth: number): number {
    if (!value || !intervalTickWidth) {
      return PROJECTS.TRIM.ANCHOR_WIDTH;
    }
    return value * intervalTickWidth + PROJECTS.TRIM.ANCHOR_WIDTH;
  }

  static calculateStartAnchorRightBoundValue(value: number, intervalTickWidth: number): number {
    if (!value || !intervalTickWidth) {
      return 0;
    }

    return (value - PROJECTS.TRIM.MIN_VIDEO_DURATION) * intervalTickWidth;
  }

  static calculateStartAnchorLeftBoundValue(
    trim: VideoTrim,
    intervalTickWidth: number,
    maxDuration = 0,
    isMediaImage = false
  ): number {
    if (!maxDuration) {
      return 0;
    }

    const trimDelay = trim.delay || 0;

    if (!isMediaImage) {
      return trimDelay * intervalTickWidth;
    }

    const trimmedDuration = MathUtils.formatInputValueWithXDecimals(trim.trimEnd - trim.trimStart, 2);
    const formattedMaxDuration = MathUtils.formatInputValueWithXDecimals(maxDuration, 2);
    const availableTime = MathUtils.formatInputValueWithXDecimals(formattedMaxDuration - trimmedDuration, 2);
    const calculatedLeftBoundTime =
      MathUtils.formatInputValueWithXDecimals(trim.trimStart - availableTime, 2) + trimDelay;
    const calculatedLeftBoundPx = MathUtils.formatInputValueWithXDecimals(
      calculatedLeftBoundTime * intervalTickWidth,
      0
    );

    return calculatedLeftBoundPx < 0 ? trimDelay : calculatedLeftBoundPx;
  }

  static calculateEndAnchorLeftBoundValue(value: number, intervalTickWidth: number): number {
    if (!value) {
      return PROJECTS.TRIM.ANCHOR_WIDTH + PROJECTS.TRIM.MIN_VIDEO_DURATION * intervalTickWidth;
    }
    return (value + PROJECTS.TRIM.MIN_VIDEO_DURATION) * intervalTickWidth + PROJECTS.TRIM.ANCHOR_WIDTH;
  }

  static calculateEndAnchorRightBoundValue(maxWidth: number, widthIcon: number, delay = 0): number {
    if (!maxWidth || !widthIcon) {
      return 0;
    }
    return maxWidth + delay + widthIcon;
  }

  static calculateSeekBarValue(data: number, intervalTickWidth: number): number {
    if (!data || !intervalTickWidth) {
      return PROJECTS.TRIM.ANCHOR_WIDTH;
    }
    return (data - PROJECTS.TRIM.ANCHOR_WIDTH) / intervalTickWidth;
  }

  static calculateSeekBarPositionOnX(
    startTrimValue: number,
    endTrimValue: number,
    currentTime: number,
    intervalTickWidth: number
  ): number {
    const leftBoundValue = this.calculateSeekBarLeftBoundValue(startTrimValue, intervalTickWidth);
    const rightBoundValue = this.calculateSeekBarRightBoundValue(
      endTrimValue - startTrimValue,
      startTrimValue,
      intervalTickWidth
    );

    if (!currentTime || !intervalTickWidth) {
      return leftBoundValue;
    }

    const currentPosition = currentTime * intervalTickWidth + PROJECTS.TRIM.ANCHOR_WIDTH;
    if (leftBoundValue > currentPosition) {
      return leftBoundValue;
    }

    if (rightBoundValue < currentPosition) {
      return rightBoundValue;
    }
    return currentTime * intervalTickWidth + PROJECTS.TRIM.ANCHOR_WIDTH;
  }

  static calculateSeekBarLeftBoundValue(startTrimValue: number, intervalTickWidth: number): number {
    return PROJECTS.TRIM.ANCHOR_WIDTH + startTrimValue * intervalTickWidth;
  }

  static calculateSeekBarRightBoundValue(value: number, startTrimValue: number, intervalTickWidth: number): number {
    if (!value || !intervalTickWidth) {
      return 0;
    }

    return value * intervalTickWidth + startTrimValue * intervalTickWidth + PROJECTS.TRIM.ANCHOR_WIDTH;
  }

  static calculateGrayAreaWidthBeforeStartAnchor(value: number, intervalTickWidth: number): number {
    if (!value || !intervalTickWidth) {
      return 0;
    }
    return value * intervalTickWidth - PROJECTS.TRIM.ANCHOR_WIDTH;
  }

  static calculateGrayAreaWidthAfterEndAnchor(value: number, maxWidth: number, intervalTickWidth: number): number {
    if (!value || !maxWidth || !intervalTickWidth) {
      return 0;
    }
    return (maxWidth / intervalTickWidth - value) * maxWidth - PROJECTS.TRIM.ANCHOR_WIDTH;
  }

  static calculateUnshadedAreaWidthBetweenStartAndEndAnchor(value: VideoTrim, intervalTickWidth: number): number {
    if (!value || !intervalTickWidth) {
      return 0;
    }
    return Math.ceil((value.trimEnd - value.trimStart) * intervalTickWidth);
  }

  static calculateUnshadedAreaLeftBoundValue(value: number, intervalTickWidth: number): number {
    if (!value || !intervalTickWidth) {
      return 0;
    }
    return value * intervalTickWidth;
  }

  static getMinTrimStartForPosition(videoTrimValue: VideoTrim, pipTrimValue: VideoTrim): number {
    if (!videoTrimValue && !pipTrimValue) {
      return 0;
    }
    const videoTrimStart = videoTrimValue.trimStart || 0;
    const pipVideoTrimStart = pipTrimValue.trimStart || 0;
    const pipVideoDelay = pipTrimValue.delay || 0;

    return videoTrimStart >= pipVideoTrimStart - pipVideoDelay ? videoTrimStart : pipVideoTrimStart;
  }

  static getTrimEndForSeekbarHover(videoTrimValue: VideoTrim, pipTrimValue: VideoTrim): number {
    if (!videoTrimValue && !pipTrimValue) {
      return 0;
    }
    const videoTrimEnd = videoTrimValue.trimEnd || 0;
    const pipVideoTrimStart = pipTrimValue.trimStart || 0;
    const pipVideoTrimEnd = pipTrimValue.trimEnd || 0;
    const pipVideoDelay = pipTrimValue.delay || 0;

    if (pipVideoTrimStart + pipVideoTrimEnd < videoTrimEnd) {
      return videoTrimEnd;
    }

    return videoTrimEnd <= pipVideoTrimEnd + pipVideoDelay ? videoTrimEnd : pipVideoTrimEnd;
  }

  static getVideoOverflowWithPipTrimValue(videoTrimStart: number, pipTrimValue: VideoTrim): number {
    if (!videoTrimStart && !pipTrimValue) {
      return 0;
    }
    const pipVideoTrimStart = pipTrimValue.trimStart || 0;
    const pipVideoDelay = pipTrimValue.delay || 0;

    return videoTrimStart >= pipVideoTrimStart - pipVideoDelay ? 0 : pipVideoTrimStart - videoTrimStart;
  }
}
