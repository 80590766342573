import React, { FunctionComponent } from 'react';
import ScenePlayer from '../../../../../components/scene-player/ScenePlayer';
import ProjectUtils from '../../../../../utils/ProjectUtils';
import { AnimationFormats, Logo, MaskCodes, PIP } from '../../../../../utils/types/AnimationTypes';
import { ProjectScene, SubtitleBlock } from '../../../../../utils/types/ProjectTypes';
import { AnimationTheme } from '../../../../../utils/types/ThemeTypes';
import { VideoTrim } from './scene-timeline/utils/VideoTrimUtils';

type Props = {
  scene: ProjectScene;
  format?: string;
  isPlaying?: boolean;
  seekTo?: number;
  theme: AnimationTheme;
  customColors?: boolean;
  projectAudioVolume?: number;
  wrapperClassName?: string;
  videoTrimValue?: VideoTrim;
  mask?: MaskCodes;
  logo?: Logo;
  onCurrentTimeChange?: (currentTime: number) => void;
  onDurationChange?: (duration: number) => void;
  onAnimationDurationChange?: (animationDuration: number) => void;
  onIsPlayingChange?: (newIsPlaying: boolean) => void;
  onIsLoadingChange?: (newIsLoading: boolean) => void;
  isCarousel?: boolean;
  onEndReached?: () => void;
  onVideoEnded?: () => void;
};

const ProjectScenePreview: FunctionComponent<Props> = ({
  scene,
  format,
  isPlaying = true,
  seekTo = 0,
  theme = AnimationTheme.BALI,
  customColors = true,
  projectAudioVolume = 1,
  wrapperClassName,
  onCurrentTimeChange,
  videoTrimValue,
  mask,
  logo,
  onDurationChange,
  onAnimationDurationChange,
  onIsPlayingChange,
  onIsLoadingChange,
  isCarousel = false,
  onEndReached,
  onVideoEnded,
}: Props) => {
  // Animation
  const sceneAnimation = scene.animation && scene.animation.active ? scene.animation : undefined;
  const animationPosition = sceneAnimation?.position
    ? { code: sceneAnimation.position, x: sceneAnimation.offsetX, y: sceneAnimation.offsetY }
    : undefined;
  const animationTexts = ProjectUtils.getAnimationTextsFromScene(scene);
  const backgroundVideo = scene.backgroundVideo?.media;
  const animationDelay = scene.animation ? scene.animation.delay : undefined;

  // Video
  // The projectAudioVolume defines the maximum allowed scenes volume
  const audioVolume =
    scene.backgroundVideo?.audioVolume && Math.min(scene.backgroundVideo?.audioVolume, projectAudioVolume);

  // PIP
  const playerPip: PIP | undefined =
    scene && scene.pip && scene.pip.media && scene.pip.duration !== undefined
      ? {
          source: scene.pip.media,
          duration: scene.pip.duration,
          delay: scene.pip.delay ?? 0,
          trimStartAt: scene.pip.trimStartAt ?? 0,
          trimEndAt: scene.pip.trimEndAt ?? 0,
          audioVolume: scene.pip.audioVolume ?? 0,
          effect: {
            name: scene.pip.effectName,
            value: scene.pip.effectValue,
          },
          size: scene.pip.size,
          positionCode: scene.pip.positionCode,
          positionValue: scene.pip.positionValue,
        }
      : undefined;

  // Subtitles
  const subtitlesBlock: SubtitleBlock[] | undefined = scene && scene.subtitles ? scene.subtitles.blocks : undefined;

  return (
    <ScenePlayer
      key={`player_scene_${scene.id}`}
      animationName={sceneAnimation?.code}
      animationTheme={theme}
      customColors={customColors}
      animationTexts={animationTexts}
      format={format ?? AnimationFormats.FORMAT_16_9}
      videoMedia={backgroundVideo}
      animationPosition={animationPosition}
      audioVolume={audioVolume}
      pip={playerPip}
      subtitlesBlock={subtitlesBlock}
      isPlaying={isPlaying}
      controls={!scene.isActive} // The controls in the player are active only for inactive scene, else they are controlled in the timeline
      hideVolumeControls
      fullscreenDisabled
      onIsPlayingChange={onIsPlayingChange}
      seekTo={seekTo}
      hideBorders
      videoTrimValue={videoTrimValue}
      mask={mask}
      logo={logo}
      wrapperClassName={wrapperClassName}
      onCurrentTimeChange={onCurrentTimeChange}
      onDurationChange={onDurationChange}
      animationDelay={animationDelay}
      onAnimationDurationChange={onAnimationDurationChange}
      onIsLoadingChange={onIsLoadingChange}
      onEndReached={onEndReached}
      onVideoEnded={onVideoEnded}
      showAnimationLoadingOverlay
      isCarousel={isCarousel}
      isSceneCopy={scene.isSceneDuplicateTemporary}
    />
  );
};
export default ProjectScenePreview;
