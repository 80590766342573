import { UploadFile } from 'antd/lib/upload/interface';
import { Action, action } from 'typesafe-actions';
import {
  APICharterPermission,
  APIFullModelCharter,
  APIModelCharter,
  Asset,
  AssetType,
  MediaFile,
  Scenario,
  ScenarioVariant,
  UsersModelCharter,
} from '../../services/api/types/ChartersServiceTypes';
import { APIModelUser, APIModelUserEnriched } from '../../services/api/types/UsersServiceTypes';
import { MediaType } from '../../utils/types/MediaTypes';
import { Project } from '../../utils/types/ProjectTypes';

type UpdatingUserKey = 'role' | 'isActive';

export enum CHARTERS_ACTION_TYPE {
  SET_CHARTERS_LIST = 'SET_CHARTERS_LIST',
  SELECT_CHARTER_BY_ID = 'SELECT_CHARTER_BY_ID',
  SELECT_CHARTER = 'SELECT_CHARTER',
  UPDATE_CHARTER_PERMISSIONS = 'UPDATE_CHARTER_PERMISSIONS',
  SET_CHARTER_USERS = 'SET_CHARTER_USERS',
  ADD_USER_TO_CHARTER = 'ADD_USER_TO_CHARTER',
  RESET_ADDED_USER_TO_CHARTER_PRIORITY = 'RESET_ADDED_USER_TO_CHARTER_PRIORITY',
  UPDATE_USER_IN_CURRENT_CHARTER = 'UPDATE_USER_IN_CURRENT_CHARTER',
  UPDATE_CURRENT_USER_IN_CHARTER_LIST = 'UPDATE_CURRENT_USER_IN_CHARTER_LIST',
  UPDATING_USER_IN_CURRENT_CHARTER = 'UPDATING_USER_IN_CURRENT_CHARTER',
  REMOVE_USER_FROM_CURRENT_CHARTER = 'REMOVE_USER_FROM_CURRENT_CHARTER',
  REMOVE_CURRENT_USER_FROM_CURRENT_CHARTER = 'REMOVE_CURRENT_USER_FROM_CURRENT_CHARTER',
  ADD_CREATED_CHARTER = 'ADD_CREATED_CHARTER',
  UPDATE_CHARTER = 'UPDATE_CHARTER',
  FILE_UPLOADED_PROCESSING = 'FILE_UPLOADED_PROCESSING',
  REFRESH_PROCESSING_ASSETS_BY_TYPE = 'REFRESH_PROCESSING_ASSETS_BY_TYPE',
  FILE_UPLOADED_PROCESSED = 'FILE_UPLOADED_PROCESSED',
  SET_FILE_PROCESSING_IN_ERROR = 'SET_FILE_PROCESSING_IN_ERROR',
  UPDATE_ASSET_PROCESSING_PROGRESSION = 'UPDATE_ASSET_PROCESSING_PROGRESSION',
  UPDATE_ASSET = 'UPDATE_ASSET',
  SAVE_CHARTER_MEDIA = 'SAVE_CHARTER_MEDIA',
  MEDIA_FILE_UPLOADED_PROCESSING = 'MEDIA_FILE_UPLOADED_PROCESSING',
  MEDIA_FILE_UPLOADED_PROCESSED = 'MEDIA_FILE_UPLOADED_PROCESSED',
  UPDATE_MEDIA = 'UPDATE_MEDIA',
  SET_MEDIA_FILE_PROCESSING_IN_ERROR = 'SET_MEDIA_FILE_PROCESSING_IN_ERROR',
  REFRESH_PROCESSING_MEDIA_BY_MEDIA_TYPE = 'REFRESH_PROCESSING_MEDIA_BY_MEDIA_TYPE',
  UPDATE_MEDIA_PROCESSING_PROGRESSION = 'UPDATE_MEDIA_PROCESSING_PROGRESSION',
  SAVE_CHARTER_SCENARIOS = 'SAVE_CHARTER_SCENARIOS',
  UPDATE_CHARTER_SCENARIO = 'UPDATE_CHARTER_SCENARIO',
  REMOVE_CHARTER_SCENARIO = 'REMOVE_CHARTER_SCENARIO',
  UPDATE_CHARTER_SCENARIO_VARIANT = 'UPDATE_CHARTER_SCENARIO_VARIANT',
  REMOVE_CHARTER_SCENARIO_VARIANT = 'REMOVE_CHARTER_SCENARIO_VARIANT',
  UPDATE_CHARTER_SCENARIO_VARIANT_METADATA = 'UPDATE_CHARTER_SCENARIO_VARIANT_METADATA',
  SAVE_CHARTER_PROJECTS = 'SAVE_CHARTER_PROJECTS',
  ADD_CHARTER_PROJECT = 'ADD_CHARTER_PROJECT',
  UPDATE_CHARTER_PROJECT = 'UPDATE_CHARTER_PROJECT',
  DELETE_CHARTER_PROJECT = 'DELETE_CHARTER_PROJECT',
  UPDATE_PROCESSING_FILE_OPERATION_ID = 'UPDATE_PROCESSING_FILE_OPERATION_ID',
  UPDATE_PROCESSING_MEDIA_OPERATION_ID = 'UPDATE_PROCESSING_MEDIA_OPERATION_ID',
}

export const setChartersList = (charters: Array<APIModelCharter>): Action => {
  return action(CHARTERS_ACTION_TYPE.SET_CHARTERS_LIST, charters);
};

export const selectCharterById = (charterId: number, permissions: Array<APICharterPermission>): Action => {
  return action(CHARTERS_ACTION_TYPE.SELECT_CHARTER_BY_ID, { charterId, permissions });
};

export const selectCharter = (
  charter: APIModelCharter | APIFullModelCharter,
  permissions: APICharterPermission[]
): Action => {
  return action(CHARTERS_ACTION_TYPE.SELECT_CHARTER, { charter, permissions });
};

export const updateCharterPermissions = (permissions: APICharterPermission[]): Action => {
  return action(CHARTERS_ACTION_TYPE.UPDATE_CHARTER_PERMISSIONS, permissions);
};

export const setCharterUsers = (users: UsersModelCharter[]): Action => {
  return action(CHARTERS_ACTION_TYPE.SET_CHARTER_USERS, users);
};

export const addUserToCharter = (users: APIModelUserEnriched): Action => {
  return action(CHARTERS_ACTION_TYPE.ADD_USER_TO_CHARTER, users);
};

export const updateUserInCurrentCharter = (user: APIModelUserEnriched): Action => {
  return action(CHARTERS_ACTION_TYPE.UPDATE_USER_IN_CURRENT_CHARTER, user);
};

export const updateCurrentUserInCharterList = (user: APIModelUserEnriched, charterId: number): Action => {
  return action(CHARTERS_ACTION_TYPE.UPDATE_CURRENT_USER_IN_CHARTER_LIST, { user, charterId });
};

export const setUpdatingUserInCurrentCharter = (userId: string, key: UpdatingUserKey): Action => {
  return action(CHARTERS_ACTION_TYPE.UPDATING_USER_IN_CURRENT_CHARTER, { userId, key });
};

export const removeUserFromCurrentCharter = (userId: string): Action => {
  return action(CHARTERS_ACTION_TYPE.REMOVE_USER_FROM_CURRENT_CHARTER, userId);
};

export const removeCurrentUserFromCurrentCharter = (): Action => {
  return action(CHARTERS_ACTION_TYPE.REMOVE_CURRENT_USER_FROM_CURRENT_CHARTER);
};

export const addedUserToCharterDisplayed = (currentUser: APIModelUser): Action => {
  return action(CHARTERS_ACTION_TYPE.RESET_ADDED_USER_TO_CHARTER_PRIORITY, currentUser);
};

export const addCreatedCharter = (createdCharter: APIModelCharter): Action => {
  return action(CHARTERS_ACTION_TYPE.ADD_CREATED_CHARTER, createdCharter);
};

export const updateCharter = (updatedCharter: APIModelCharter): Action => {
  return action(CHARTERS_ACTION_TYPE.UPDATE_CHARTER, updatedCharter);
};

export const fileUploadedAndProcessing = (file: UploadFile, assetType: AssetType): Action => {
  return action(CHARTERS_ACTION_TYPE.FILE_UPLOADED_PROCESSING, { file, assetType });
};

export const fileUploadedProcessed = (asset: Asset, assetType: AssetType): Action => {
  return action(CHARTERS_ACTION_TYPE.FILE_UPLOADED_PROCESSED, { asset, assetType });
};

export const setFileProcessingInError = (asset: Asset, assetType: AssetType, errorCode: string): Action => {
  return action(CHARTERS_ACTION_TYPE.SET_FILE_PROCESSING_IN_ERROR, { asset, assetType, errorCode });
};

export const refreshProcessingAssetsByType = (assets: Asset[], assetType: AssetType): Action => {
  return action(CHARTERS_ACTION_TYPE.REFRESH_PROCESSING_ASSETS_BY_TYPE, { assets, assetType });
};

export const updateProcessingFileOperationId = (
  oldOperationId: string,
  newOperationId: string,
  assetType: AssetType
): Action => {
  return action(CHARTERS_ACTION_TYPE.UPDATE_PROCESSING_FILE_OPERATION_ID, {
    oldOperationId,
    newOperationId,
    assetType,
  });
};

export const updateAssetProcessingProgression = (
  operationId: string,
  progress: number,
  assetType: AssetType
): Action => {
  return action(CHARTERS_ACTION_TYPE.UPDATE_ASSET_PROCESSING_PROGRESSION, { operationId, progress, assetType });
};

export const updateAsset = (asset: Asset, assetType: AssetType): Action => {
  return action(CHARTERS_ACTION_TYPE.UPDATE_ASSET, { asset, assetType });
};

export const saveCharterMedia = (media: MediaFile[]): Action => {
  return action(CHARTERS_ACTION_TYPE.SAVE_CHARTER_MEDIA, media);
};

export const mediaFileUploadedAndProcessing = (file: UploadFile, mediaType: MediaType): Action => {
  return action(CHARTERS_ACTION_TYPE.MEDIA_FILE_UPLOADED_PROCESSING, { file, mediaType });
};

export const mediaFileUploadedProcessed = (media: MediaFile, mediaType: MediaType): Action => {
  return action(CHARTERS_ACTION_TYPE.MEDIA_FILE_UPLOADED_PROCESSED, { media, mediaType });
};

export const updateMedia = (media: MediaFile): Action => {
  return action(CHARTERS_ACTION_TYPE.UPDATE_MEDIA, media);
};

export const setMediaFileProcessingInError = (media: MediaFile, mediaType: MediaType, errorCode: string): Action => {
  return action(CHARTERS_ACTION_TYPE.SET_MEDIA_FILE_PROCESSING_IN_ERROR, { media, mediaType, errorCode });
};

export const refreshProcessingMediaByMediaType = (mediaList: MediaFile[], mediaType: MediaType): Action => {
  return action(CHARTERS_ACTION_TYPE.REFRESH_PROCESSING_MEDIA_BY_MEDIA_TYPE, { mediaList, mediaType });
};

export const updateProcessingMediaOperationId = (
  oldOperationId: string,
  newOperationId: string,
  mediaType: MediaType
): Action => {
  return action(CHARTERS_ACTION_TYPE.UPDATE_PROCESSING_MEDIA_OPERATION_ID, {
    oldOperationId,
    newOperationId,
    mediaType,
  });
};

export const updateMediaProcessingProgression = (
  operationId: string,
  progress: number,
  mediaType: MediaType
): Action => {
  return action(CHARTERS_ACTION_TYPE.UPDATE_MEDIA_PROCESSING_PROGRESSION, { operationId, progress, mediaType });
};

export const saveCharterScenarios = (scenarios: Scenario[]): Action => {
  return action(CHARTERS_ACTION_TYPE.SAVE_CHARTER_SCENARIOS, scenarios);
};

export const updateCharterScenario = (updatedScenario: Scenario): Action => {
  return action(CHARTERS_ACTION_TYPE.UPDATE_CHARTER_SCENARIO, updatedScenario);
};

export const removeCharterScenario = (scenarioId: number): Action => {
  return action(CHARTERS_ACTION_TYPE.REMOVE_CHARTER_SCENARIO, scenarioId);
};

export const updateCharterScenarioVariant = (scenarioId: number, scenarioVariant: ScenarioVariant): Action => {
  return action(CHARTERS_ACTION_TYPE.UPDATE_CHARTER_SCENARIO_VARIANT, { scenarioId, scenarioVariant });
};

export const removeCharterScenarioVariant = (scenarioId: number, scenarioVariantId: number): Action => {
  return action(CHARTERS_ACTION_TYPE.REMOVE_CHARTER_SCENARIO_VARIANT, { scenarioId, scenarioVariantId });
};

export const updateCharterScenarioVariantMetadata = (
  scenarioId: number,
  scenarioVariantId: number,
  title: string,
  description: string
): Action => {
  return action(CHARTERS_ACTION_TYPE.UPDATE_CHARTER_SCENARIO_VARIANT_METADATA, {
    scenarioId,
    scenarioVariantId,
    title,
    description,
  });
};

export const saveCharterProjects = (projects: Project[]): Action => {
  return action(CHARTERS_ACTION_TYPE.SAVE_CHARTER_PROJECTS, projects);
};

export const addCharterProject = (project: Project): Action => {
  return action(CHARTERS_ACTION_TYPE.ADD_CHARTER_PROJECT, project);
};

export const updateCharterProject = (project: Project): Action => {
  return action(CHARTERS_ACTION_TYPE.UPDATE_CHARTER_PROJECT, project);
};

export const deleteCharterProject = (projectId: number): Action => {
  return action(CHARTERS_ACTION_TYPE.DELETE_CHARTER_PROJECT, projectId);
};
