import { ExportOutlined } from '@ant-design/icons/lib';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { NavigationMenuItem } from '../../services/navigation/NavigationMenuItem';
import useStyles from './KannelleMenuStyle';

type Props = {
  route: NavigationMenuItem;
  iconClassName?: string;
};

const MenuLink: FunctionComponent<Props> = ({ route, iconClassName }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();

  if (!route) {
    return null;
  }

  const shouldUseReplace = location.pathname === route.path;

  const Icon = route.icon ? <route.icon className={classNames(classes.treeMenuItemIcon, iconClassName)} /> : null;
  const title = route.title ?? '';

  // If the path starts with a `/` we deduce it is an internal url.
  // Else, we conclude it is an external link, which corresponds to a translation key to be translated
  const isInternalUrl = route.path.startsWith('/');
  const path = isInternalUrl ? route.path : t(route.path);

  return isInternalUrl ? (
    <Link className={classes.treeMenuItem} to={path} replace={shouldUseReplace}>
      {Icon}
      {t(title)}
    </Link>
  ) : (
    <a
      href={path}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames(classes.treeMenuItem, classes.externalLink)}
    >
      {Icon}
      {t(title)}
      <ExportOutlined className={classes.externalLinkIcon} />
    </a>
  );
};

export default MenuLink;
