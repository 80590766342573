import { LoadingOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { THEME } from '../../Constants';
import KannelleRetryButton from '../retry-button/KannelleRetryButton';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > span': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  switchContainer: {
    minWidth: 110,
  },
  disabledContainer: {
    opacity: '0.4',
    cursor: 'not-allowed',
  },
  main: {
    color: THEME.DEFAULT.MAIN_TEXT_COLOR,
  },
  switch: {
    marginRight: '8px !important',
  },
  loadingIcon: {
    fontSize: '10px',
    marginLeft: '5px',
  },
});

type Props = {
  isChecked: boolean;
  callbackChange?: (value: boolean) => void;
  disabled?: boolean;
  size?: 'small' | 'default';
  loading?: boolean;
  error?: boolean;
  isFieldControlled?: boolean;
  labelActivated?: string;
  labelDeactivated?: string;
  checkedChildren?: JSX.Element;
  unCheckedChildren?: JSX.Element;
};

const KannelleSwitch: FunctionComponent<Props> = ({
  isChecked,
  callbackChange,
  disabled,
  loading,
  error,
  isFieldControlled = false,
  size = 'default',
  labelActivated = 'form.active',
  labelDeactivated = 'form.disabled',
  checkedChildren,
  unCheckedChildren,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [checked, setChecked] = useState(isChecked);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  useEffect(() => {
    if (error) {
      setChecked(isChecked);
    }
  }, [error, isChecked]);

  const info = checked ? <span className={classes.main}>{t(labelActivated)}</span> : <span>{t(labelDeactivated)}</span>;

  const handleChange = (): void => {
    const newValue = !checked;
    // If the field is not controlled then we must set the value internally
    // otherwise we use the isChecked property
    if (!isFieldControlled) {
      setChecked(newValue);
    }
    if (callbackChange) {
      callbackChange(newValue);
    }
  };

  return (
    <span className={classNames(classes.container, disabled && classes.disabledContainer)}>
      <span className={classes.switchContainer}>
        <Switch
          checked={checked}
          size={size}
          className={classes.switch}
          onChange={handleChange}
          disabled={disabled || loading}
          checkedChildren={checkedChildren}
          unCheckedChildren={unCheckedChildren}
        />
        {info}
      </span>

      {!loading && error && (
        <KannelleRetryButton
          onClick={(): void => {
            handleChange();
          }}
        />
      )}
      {loading && <LoadingOutlined className={classes.loadingIcon} />}
    </span>
  );
};

export default KannelleSwitch;
