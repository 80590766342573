export enum PermissionRoles {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  CREATOR = 'CREATOR',
}

export type ChangedFieldPermissionByRole = {
  roleCode: string;
  changedField?: string | boolean;
};

export enum AssetPermissions {
  LOGO = 'LOGO',
  INTRO = 'INTRO',
  OUTRO = 'OUTRO',
}

export enum TranslatedOptionsPermissions {
  LOGO_SIZE = 'LOGO_SIZE',
  LOGO_INCLUDE_SLIDES = 'LOGO_INCLUDE_SLIDES',
  CUSTOM_COLORS = 'CUSTOM_COLORS',
  LOGO_POSITION = 'LOGO_POSITION',
}

export enum PermissionAction {
  HIDDEN = 'HIDDEN',
  DISABLED = 'DISABLED',
}

export enum PermissionList {
  ADD_USER = 'ADD_USER',
  DELETE_USER = 'DELETE_USER',
  EDIT_USER_INFO = 'EDIT_USER_INFO',
  WEB_DASHBOARD = 'WEB_DASHBOARD',
  // Project
  PROJECT_CREATE = 'PROJECT_CREATE',
  PROJECT_EDIT = 'PROJECT_EDIT',
  PROJECT_DELETE = 'PROJECT_DELETE',
  PROJECT_FINALIZE = 'PROJECT_FINALIZE',
  PROJECT_SHARE_PUBLICLY = 'PROJECT_SHARE_PUBLICLY',
  PROJECT_DOWNLOAD = 'PROJECT_DOWNLOAD',
  PROJECT_DUPLICATE = 'PROJECT_DUPLICATE',
  // Scene
  SCENE_SLIDE_EDIT = 'SCENE_SLIDE_EDIT',
  SCENE_DELETE = 'SCENE_DELETE',
  SCENE_DUPLICATE = 'SCENE_DUPLICATE',
  SCENE_REORDER = 'SCENE_REORDER',
  SCENE_IMPORT = 'SCENE_IMPORT',
  // Animation
  SCENE_ANIMATION_TEXT_EDIT = 'SCENE_ANIMATION_TEXT_EDIT',
  SCENE_ANIMATION_TYPE_EDIT = 'SCENE_ANIMATION_TYPE_EDIT',
  // Finalized options
  CUSTOM_COLORS = 'CUSTOM_COLORS',
  OUTRO = 'OUTRO',
  INTRO = 'INTRO',
  LOGO = 'LOGO',
  LOGO_SIZE = 'LOGO_SIZE',
  LOGO_INCLUDE_SLIDES = 'LOGO_INCLUDE_SLIDES',
  LOGO_POSITION = 'LOGO_POSITION',
  // Theme
  THEME_BALI = 'THEME_BALI',
  THEME_BERLIN = 'THEME_BERLIN',
  THEME_GENEVA = 'THEME_GENEVA',
  THEME_LOS_ANGELES = 'THEME_LOS_ANGELES',
  THEME_SEATTLE = 'THEME_SEATTLE',
  THEME_ALGIERS = 'THEME_ALGIERS',
  // Frame
  FRAME = 'FRAME',
  FRAME_MINI = 'FRAME_MINI',
  // Video format
  VIDEO_FORMAT_1_1 = 'VIDEO_FORMAT_1_1',
  VIDEO_FORMAT_9_16 = 'VIDEO_FORMAT_9_16',
  VIDEO_FORMAT_16_9 = 'VIDEO_FORMAT_16_9',
}

export const LogoIncludeSlides: any = {
  STANDARD: {
    code: 'STANDARD',
    key: 'permissionValuesOptions.LOGO_INCLUDE_SLIDES.STANDARD',
  },
  ALL_USER_VIDEOS: {
    code: 'ALL_USER_VIDEOS',
    key: 'permissionValuesOptions.LOGO_INCLUDE_SLIDES.ALL_USER_VIDEOS',
  },
};

export const CustomColors: any = {
  CUSTOM: {
    code: 'CUSTOM',
    key: 'permissionValuesOptions.CUSTOM_COLORS.CUSTOM',
  },
  ORIGINAL: {
    code: 'ORIGINAL',
    key: 'permissionValuesOptions.CUSTOM_COLORS.ORIGINAL',
  },
};
