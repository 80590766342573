import { Tag } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { TFunction } from 'i18next';
import React, { ReactElement, ReactNode } from 'react';
import Highlighter from 'react-highlight-words';
import FilterIcon from '../../../components/table/search-in-column-filter/FilterIcon';
import SearchInColumnFilter from '../../../components/table/search-in-column-filter/SearchInColumnFilter';
import { TRANSLATION_CATEGORIES } from '../../../Constants';
import { APITranslation } from '../../../services/api/types/TranslationServiceTypes';
import StringUtils from '../../../utils/StringUtils';

type ColumnsArgs = {
  classes: Record<string, string>;
  t: TFunction;

  filter: Record<string, string> | undefined;
  setFilter: React.Dispatch<React.SetStateAction<Record<string, string> | undefined>>;
  filteredInfo: Record<string, string[] | null>;
};

interface ExtendedColumnProps<RecordType> extends ColumnProps<RecordType> {
  editable?: boolean;
}

export const buildTranslationTableColumns = ({
  t,
  classes,
  filter,
  setFilter,
  filteredInfo,
}: ColumnsArgs): ExtendedColumnProps<APITranslation>[] => {
  return [
    {
      title: t('admin.translation.code'),
      dataIndex: 'code',
      width: 250,
      key: 'translation_code',
      sorter: (translationA, translationB): number =>
        StringUtils.compareNaturalNormalized(translationA.code, translationB.code),
      render: (code: string): ReactElement => {
        return (
          <div>
            {filter && filter.translation_code ? (
              <Highlighter
                className={classes.highlightedText}
                searchWords={[filter.translation_code]}
                autoEscape
                textToHighlight={code}
              />
            ) : (
              <>
                <span>{code}</span>
              </>
            )}
          </div>
        );
      },
      filteredValue: filteredInfo.translation_code || null,
      filterDropdown: (filterProps): ReactNode => (
        <SearchInColumnFilter
          {...filterProps}
          dataIndex="translation_code"
          placeholder={t('global.searchOnColumnPlaceholder', {
            columnName: t('admin.translation.code').toLowerCase(),
          })}
          setFilter={setFilter}
        />
      ),
      filterIcon: FilterIcon,
      onFilter: (value, record: APITranslation): boolean => {
        return record.code
          ? StringUtils.normalize(record.code.toString()).includes(StringUtils.normalize(value.toString()))
          : false;
      },
    },
    {
      title: t('admin.translation.category'),
      dataIndex: 'category',
      width: 180,
      key: 'translation_category',
      sorter: (translationA, translationB): number =>
        StringUtils.compareNaturalNormalized(translationA.category, translationB.category),
      filters: Object.entries(TRANSLATION_CATEGORIES).map(([key, color]) => {
        return {
          value: key,
          text: (
            <Tag className={classes.tag} style={{ background: color }}>
              {key}
            </Tag>
          ),
        };
      }),
      render: (category: string): ReactElement => {
        const color: string = TRANSLATION_CATEGORIES[category];
        return (
          <Tag className={classes.tag} style={{ background: color }}>
            {category}
          </Tag>
        );
      },
      filteredValue: filteredInfo.translation_category || null,
      onFilter: (value, record: APITranslation): boolean => {
        return StringUtils.normalize(record.category.toString()).includes(StringUtils.normalize(value.toString()));
      },
    },
    {
      title: t('admin.translation.default'),
      dataIndex: 'name',
      width: 400,
      key: 'translation_name',
      render: (name: string): ReactElement => {
        return (
          <div>
            {filter && filter.translation_name ? (
              <Highlighter
                className={classes.highlightedText}
                searchWords={[filter.translation_name]}
                autoEscape
                textToHighlight={name}
              />
            ) : (
              <>
                <span>{name}</span>
              </>
            )}
          </div>
        );
      },
      filteredValue: filteredInfo.translation_name || null,
      filterDropdown: (filterProps): ReactNode => (
        <SearchInColumnFilter
          {...filterProps}
          dataIndex="translation_name"
          placeholder={t('global.searchOnColumnPlaceholder', {
            columnName: t('admin.translation.default').toLowerCase(),
          })}
          setFilter={setFilter}
        />
      ),
      filterIcon: FilterIcon,
      onFilter: (value, record: APITranslation): boolean => {
        return StringUtils.normalize(record.name.toString()).includes(StringUtils.normalize(value.toString()));
      },
    },
  ];
};
