import { RightOutlined, UnlockOutlined } from '@ant-design/icons/lib';
import { Row, Table } from 'antd';
import { BreadcrumbProps } from 'antd/es/breadcrumb';
import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import { cloneDeep, find, findIndex, uniqBy } from 'lodash';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import ErrorAlert from '../../../components/alert/ErrorAlert';
import PageContentLoader from '../../../components/loader/PageContentLoader';
import KannelleHelpButton from '../../../components/page-header/KannelleHelpButton';
import KannellePageHeader from '../../../components/page-header/KannellePageHeader';
import { DEVICE_SIZES_QUERIES, LINK, THEME, WEB_SOCKET_ACTIONS } from '../../../Constants';
import { Role } from '../../../core/rule/Roles';
import { Roles } from '../../../core/rule/RolesTypes';
import useCanAccess from '../../../hooks/useCanAccess';
import useFetchCharterById from '../../../hooks/useFetchCharterById';
import useSocket from '../../../hooks/useSocket';
import JoyRideHelpPermissions from '../../../onboarding/JoyRideHelpPermissions';
import { RootState } from '../../../redux/RootState';
import { APIManager } from '../../../services/api/APIManager';
import { getCharterPermissions } from '../../../services/api/ChartersService';
import { SocketManager } from '../../../services/api/SocketManager';
import { APICharterPermission } from '../../../services/api/types/ChartersServiceTypes';
import { PermissionUpdateResponse } from '../../../services/api/types/WebSocketTypes';
import { CharterIdPathParam } from '../../../services/navigation/NavigationConfigTypes';
import CompaniesUtils from '../../../utils/CompaniesUtils';
import LocalUtils from '../../../utils/LocalUtils';
import {
  AssetPermissions,
  ChangedFieldPermissionByRole,
  PermissionList,
} from '../../../utils/types/CharterPermissionTypes';
import { buildColumns } from './CharterPermissionsColumns';
import PermissionExpandedRow from './components/PermissionExpandedRow';
import PermissionExpandedRowAssetChoice from './components/PermissionExpandedRowAssetChoice';
import ScenarioPermission from './components/ScenarioPermission';

const useStyles = createUseStyles({
  pageContent: {
    backgroundColor: '#FFFFFF',
    padding: 24,
    margin: 24,
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      margin: 0,
      padding: '5px 10px',
    },
  },
  titleWithIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    backgroundColor: THEME.DEFAULT.MAIN_COLOR,
  },
  pageDescription: {
    marginBottom: 16,
    textAlign: 'justify',
  },
  permissionsTable: {
    '& .ant-table-expanded-row > td': {
      padding: 0,
      borderLeft: `2px solid ${THEME.MENU.MAIN_COLOR}`,
    },
    '& .ant-table-content': {
      overflowX: 'auto',
    },
  },
  table: {
    '& .ant-table-content': {
      overflow: 'auto hidden !important',
    },
  },
  searchInColumnFilter: {
    minWidth: '18rem !important',
  },
  highlightedText: {
    '& mark': {
      backgroundColor: '#ffc069',
      padding: 0,
    },
    overflow: 'hidden',
  },
  errorAlert: {
    marginTop: '30px',
  },
  permissionHeaderColumn: {
    [`@media screen and ${DEVICE_SIZES_QUERIES.HUGE}`]: {
      width: 200,
    },
  },
  scenarioPermissionRow: {
    margin: '20px 0',
  },
  expandableIcon: {
    border: '1px solid #F0F0F0',
    borderRadius: '2px',
    height: '18px',
    width: '18px',
    float: 'left',
    position: 'relative',
    display: 'inline-flex',
    textDecoration: 'none',
    padding: '0',
    lineHeight: '18px',
    outline: 'none',
    background: '#FFFFFF',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  expandedIcon: {
    transform: 'rotate(90deg)',
    transition: 'transform 0.3s ease-out',
  },
  collapsedIcon: {
    transform: 'rotate(0deg)',
    transition: 'transform 0.3s ease-out',
  },
  permissionNameCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  permissionNameParagraph: ({ otherColumnsWidth }) => ({
    width: otherColumnsWidth,
    marginBottom: '0px !important',
    wordBreak: 'break-word',
  }),
});

const CharterPermissions: FunctionComponent = () => {
  const history = useHistory();

  const [charterPermissions, setCharterPermissions] = useState<APICharterPermission[]>([]);
  const [isLoadingPermissions, setIsLoadingPermissions] = useState(false);
  const [filter, setFilter] = useState<Record<string, string>>();
  const [isPermissionBeingUpdated, setIsPermissionBeingUpdated] = useState(false);
  const [rooms, setRooms] = useState<string[]>();

  const isSocketConnected = useSocket(rooms ?? undefined);
  const permissionsGlobalRoom = 'global/permissions';

  const currentCharter = useSelector((state: RootState) => state.charters.current);
  const currentUserRole = useSelector((state: RootState) => state.user.role) ?? new Role(Roles.Creator);
  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);
  const companies = useSelector((state: RootState) => state.companies.list);

  const { hasUserAccessToCharter } = useCanAccess(PermissionList.WEB_DASHBOARD);

  const roleColumnWidth = isMobileOrTablet ? 250 : 230;
  const otherColumnsWidth = isMobileOrTablet ? 220 : 'auto';
  const classes = useStyles({ roleColumnWidth, otherColumnsWidth });
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { charterId } = useParams<CharterIdPathParam>();
  const { loading: isCharterLoading, isError } = useFetchCharterById(parseInt(charterId, 10));
  const [runHelp, setRunHelp] = useState(false);

  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());

  const isKnlTeam = companies ? CompaniesUtils.checkIsKnlProfile(companies) : false;

  useEffect(() => {
    const cancelTokenSource = cancelTokenSourceRef.current;

    return (): void => {
      cancelTokenSource.cancel('Cancelled fetching charter permissions due to charter change or component unmount.');
    };
  }, []);

  useEffect(() => {
    setIsLoadingPermissions(true);
    const lang = LocalUtils.getLang();
    const cancelTokenSource = cancelTokenSourceRef.current;
    getCharterPermissions(parseInt(charterId, 10), lang, cancelTokenSource)
      .then((response) => {
        const { data } = response;
        setCharterPermissions(uniqBy(data.items, (permission) => permission.code)); // Ensure we do not have duplicates
      })
      .catch((e) => {
        log.debug('Error during charter permissions fetch', e);
      })
      .finally(() => {
        setIsLoadingPermissions(false);
      });
  }, [charterId, i18n.language, isKnlTeam]);

  useEffect(() => {
    if (!currentCharter) {
      return;
    }

    setRooms([`charters/${currentCharter.id}/permissions`, permissionsGlobalRoom]);
  }, [currentCharter]);

  useEffect(() => {
    if (!currentCharter) {
      return undefined;
    }

    const handleUpdatedPermission = (payload: PermissionUpdateResponse): void => {
      if (
        WEB_SOCKET_ACTIONS.CHARTER_LOCAL_PERMISSIONS.includes(payload.action) ||
        WEB_SOCKET_ACTIONS.GLOBAL_PERMISSIONS.includes(payload.action)
      ) {
        // If the socket was sent in the global room and the current charter is excluded, we do nothing
        if (
          payload.roomName === permissionsGlobalRoom &&
          payload.data &&
          payload.data.excludedCharters &&
          payload.data.excludedCharters.includes(currentCharter.id)
        ) {
          return;
        }

        // Extract the permission from the websocket
        const payloadPermission = payload.data as APICharterPermission;
        // If translations are provided for the permission, extract the consistent one depending on the current language (of the dashboard)
        if (payload.data && payload.data.translations) {
          const lang = (i18n.language.split('-')[0] || 'en') as 'fr' | 'en';
          const permissionTranslation = payload.data.translations![lang]?.name;
          if (permissionTranslation) {
            payloadPermission.name = permissionTranslation;
          }
        }

        // If a permission has been added (to the charter or to every charter)
        if (payload.action === 'permission_put') {
          setCharterPermissions((prev) => {
            const newPermissions = [...prev, payloadPermission];
            return uniqBy(newPermissions, (permission) => permission.code);
          });
        } else if (payload.action === 'permission_patch') {
          setCharterPermissions((prev) => {
            const permissionIndex = findIndex(prev, (perm) => perm.code === payloadPermission.code);
            if (permissionIndex >= 0) {
              const newPermissions = [...prev];
              if (payloadPermission.role) {
                const roleIndex = newPermissions[permissionIndex].roles.findIndex((role) => {
                  return role.code === payloadPermission.role;
                });
                newPermissions[permissionIndex].roles[roleIndex].hasAccess.access = payloadPermission.access;
                newPermissions[permissionIndex].roles[roleIndex].hasAccess.value = payloadPermission.value;
              } else {
                newPermissions[permissionIndex] = payloadPermission;
              }

              return uniqBy(newPermissions, (permission) => permission.code);
            }
            return prev;
          });
        } else if (payload.action === 'permission_delete') {
          setCharterPermissions((prev) => {
            const permissionIndex = findIndex(prev, (perm) => perm.code === payloadPermission.code);
            if (permissionIndex >= 0) {
              const newPermissions = [...prev];
              newPermissions.splice(permissionIndex, 1);
              return uniqBy(newPermissions, (permission) => permission.code);
            }
            return prev;
          });
        }
      }
    };

    if (isSocketConnected) {
      SocketManager.onMessage(handleUpdatedPermission);

      return (): void => SocketManager.offMessage(handleUpdatedPermission);
    }

    return undefined;
  }, [isSocketConnected, currentCharter, i18n.language]);

  const hasPermissionValues = (permission: APICharterPermission): boolean => {
    return permission && permission.allowedValues ? permission.allowedValues.length > 0 : false;
  };

  if (!currentCharter) {
    return null;
  }

  if (!hasUserAccessToCharter(parseInt(charterId, 10))) {
    history.push(LINK.UNAUTHORIZED.path);
    return null;
  }

  const routes = [
    {
      path: '/charters',
      breadcrumbName: t('menu.charters'),
    },
    {
      path: `/charters/${currentCharter.id}`,
      breadcrumbName: currentCharter.name,
    },
    {
      path: location.pathname,
      breadcrumbName: t('charters.permissions.title'),
    },
  ];

  const breadcrumbProps: BreadcrumbProps = {
    itemRender: (route: Route) => {
      if (route.path === location.pathname) {
        return route.breadcrumbName;
      }
      return <Link to={route.path}>{route.breadcrumbName}</Link>;
    },
    routes,
  };

  const pageDescription = <>{t('charters.permissions.description')}</>;

  // Update the charterPermissions state by updating the right permission.
  // It will know which fields to update depending on the `changedFieldsByRole.changedField` property type.
  // If it is a boolean, we toggle the permission `access`.
  // Else if it is a string, we update the value for that permission.
  // We do it for all roles in the `changedFieldsByRole` array.
  const callbackUpdateCharterPermissionForRole = (
    permissionCode: string,
    changedFieldsByRole: ChangedFieldPermissionByRole[]
  ): void => {
    const newPermissions = cloneDeep(charterPermissions);
    const permissionToUpdate = find(newPermissions, (perm) => perm.code === permissionCode);
    if (!permissionToUpdate) {
      return;
    }
    changedFieldsByRole.forEach((changedFieldByRole) => {
      const roleToUpdate = find(permissionToUpdate.roles, (role) => role.code === changedFieldByRole.roleCode);
      if (!roleToUpdate) {
        return;
      }
      // Based on the changed field type, we know what property to update
      if (typeof changedFieldByRole.changedField === 'boolean') {
        roleToUpdate.hasAccess.access = changedFieldByRole.changedField;
        // If the permission is back to enabled, we reset its value
        if (changedFieldByRole.changedField) {
          roleToUpdate.hasAccess.value = undefined;
        }
      } else {
        roleToUpdate.hasAccess.value = changedFieldByRole.changedField;
      }
    });

    setCharterPermissions(newPermissions);
    setIsPermissionBeingUpdated(false);
  };

  const columns = buildColumns({
    t,
    classes,
    charterPermissions,
    filter,
    setFilter,
    callbackUpdateCharterPermissionForRole,
    isPermissionBeingUpdated,
    setIsPermissionBeingUpdated,
    roleColumnWidth,
    otherColumnsWidth,
    currentUserRole,
    runHelp,
  });

  const startHelp = (): void => {
    setRunHelp(true);
  };

  const endHelp = (): void => {
    setRunHelp(false);
  };

  if (isError) {
    return <ErrorAlert className={classes.errorAlert} message={t('ajaxError.charterFetch')} />;
  }

  if (isCharterLoading) {
    return <PageContentLoader />;
  }

  return (
    <KannellePageHeader
      title={
        <div className={classes.titleWithIcon}>
          {t('charters.permissions.title')}
          <KannelleHelpButton startHelp={startHelp} />
        </div>
      }
      breadcrumb={breadcrumbProps}
      className={classes.pageContent}
      avatar={{ className: classes.avatar, icon: <UnlockOutlined /> }}
    >
      <JoyRideHelpPermissions runHelp={runHelp} callbackRunDone={endHelp} />

      <Row className={classes.pageDescription}>{pageDescription}</Row>

      <Row className={classes.scenarioPermissionRow}>
        <ScenarioPermission charter={currentCharter} />
      </Row>

      {!isLoadingPermissions && charterPermissions && charterPermissions.length > 0 ? (
        <Table
          rowKey={(record): string => {
            return `charters_permission_row_${record.code}`;
          }}
          tableLayout="fixed"
          columns={columns}
          dataSource={charterPermissions}
          className={classNames(classes.permissionsTable, classes.table)}
          pagination={false}
          expandable={{
            expandedRowRender: (record: APICharterPermission): JSX.Element => {
              const Component = Object.keys(AssetPermissions).includes(record.code)
                ? PermissionExpandedRowAssetChoice
                : PermissionExpandedRow;

              return (
                <Component
                  permission={record}
                  roleColumnWidth={roleColumnWidth}
                  callback={callbackUpdateCharterPermissionForRole}
                  isPermissionBeingUpdated={isPermissionBeingUpdated}
                  setIsPermissionBeingUpdated={setIsPermissionBeingUpdated}
                />
              );
            },
            rowExpandable: hasPermissionValues,
            expandRowByClick: true,
            expandIcon: (expandIconProps): JSX.Element | null => {
              if (!expandIconProps.expandable) {
                return null;
              }
              return (
                <span
                  className={classNames(
                    classes.expandableIcon,
                    expandIconProps.expanded ? classes.expandedIcon : classes.collapsedIcon
                  )}
                  onClick={(e): void => expandIconProps.onExpand(expandIconProps.record, e)}
                >
                  <RightOutlined />
                </span>
              );
            },
          }}
          locale={{ filterConfirm: t('global.ok'), filterReset: t('global.reset'), emptyText: t('global.nodata') }}
          showSorterTooltip={false}
        />
      ) : (
        <PageContentLoader />
      )}
    </KannellePageHeader>
  );
};

export default CharterPermissions;
