import { Role } from '../core/rule/Roles';
import { DASHBOARD_RULE } from '../core/rule/Rules';
import { APIModelCharter } from '../services/api/types/ChartersServiceTypes';
import {
  APICompanyCheckoutChannel,
  APICompanyFileStorageAgreement,
  APIModelCompany,
} from '../services/api/types/CompaniesServiceTypes';

export default class Company {
  private readonly _contractEndDate: string;
  private readonly _contractNbMonths: number;
  private readonly _contractPlan: string;
  private readonly _chargebeePlan: string;
  private readonly _dunsSiret: string;
  private readonly _chargebeeCustomer: string;
  private _chargebeeSubscriptions: string[];
  private readonly _chargebeeSubscriptionLastStatus: string;
  private readonly _checkoutChannel: APICompanyCheckoutChannel;
  private readonly _contractStartDate: string;
  private readonly _contractType: string;
  private readonly _createdAt: string;
  private readonly _id: number;
  private readonly _connectedToPitchy: boolean;
  private readonly _forcePitchyRecipient: boolean;
  private readonly _pitchyRecipient: string;
  private readonly _isActive: boolean;
  private _maxNbCharters: number;
  private _maxNbLicenses: number;
  private readonly _name: string;
  private readonly _email: string;
  private readonly _billingEmail: string;
  private readonly _updatedAt: string;
  private _charters: APIModelCharter[];
  private _created: boolean;
  private _isUserPoc: boolean;
  private _subscriptionCreated: boolean;
  private _autoCollection: string;
  private readonly _fileStorageAgreement?: APICompanyFileStorageAgreement;

  constructor(apiCompany: APIModelCompany, isUserPoc = false) {
    this._contractEndDate = apiCompany.contractEndDate;
    this._contractNbMonths = apiCompany.contractNbMonths;
    this._contractPlan = apiCompany.contractPlan;
    this._dunsSiret = apiCompany.dunsSiret;
    this._chargebeePlan = apiCompany.chargebeePlan;
    this._chargebeeCustomer = apiCompany.chargebeeCustomer;
    this._chargebeeSubscriptions =
      typeof apiCompany.chargebeeSubscription === 'string'
        ? [apiCompany.chargebeeSubscription]
        : apiCompany.chargebeeSubscription;
    this._chargebeeSubscriptionLastStatus = apiCompany.chargebeeSubscriptionLastStatus;
    this._checkoutChannel = apiCompany.checkoutChannel;
    this._contractStartDate = apiCompany.contractStartDate;
    this._contractType = apiCompany.contractType;
    this._createdAt = apiCompany.createdAt;
    this._id = apiCompany.id;
    this._connectedToPitchy = apiCompany.connectedToPitchy;
    this._forcePitchyRecipient = apiCompany.forcePitchyRecipient;
    this._pitchyRecipient = apiCompany.pitchyRecipient;
    this._isActive = apiCompany.isActive;
    this._isUserPoc = isUserPoc;
    this._maxNbCharters = apiCompany.maxNbCharters;
    this._maxNbLicenses = apiCompany.maxNbLicenses;
    this._name = apiCompany.name;
    this._email = apiCompany.email;
    this._billingEmail = apiCompany.billingEmail;
    this._updatedAt = apiCompany.updatedAt;
    this._charters = this.getAllowedCharters(apiCompany.charters, isUserPoc);
    this._created = false;
    this._subscriptionCreated = false;
    this._autoCollection = apiCompany.autoCollection;
    this._fileStorageAgreement = apiCompany.fileStorageAgreement;
  }

  getCompanyAllowedCharters = (): APIModelCharter[] => {
    return this.getAllowedCharters(this.charters, this.isUserPoc);
  };

  private getAllowedCharters = (charters: APIModelCharter[], isUserPoc = false): APIModelCharter[] => {
    if (isUserPoc) {
      return charters;
    }

    return charters.filter((charter) => {
      if (!charter.currentUser || !charter.currentUser.role || !charter.currentUser.isActive) {
        return false;
      }

      const role = new Role(charter.currentUser.role);
      return role.isAllowedTo(DASHBOARD_RULE.VISIT);
    });
  };

  get contractEndDate(): string {
    return this._contractEndDate;
  }

  get contractNbMonths(): number {
    return this._contractNbMonths;
  }

  get contractPlan(): string {
    return this._contractPlan;
  }

  get contractStartDate(): string {
    return this._contractStartDate;
  }

  get contractType(): string {
    return this._contractType;
  }

  get createdAt(): string {
    return this._createdAt;
  }

  get id(): number {
    return this._id;
  }

  get connectedToPitchy(): boolean {
    return this._connectedToPitchy;
  }
  get forcePitchyRecipient(): boolean {
    return this._forcePitchyRecipient;
  }
  get pitchyRecipient(): string {
    return this._pitchyRecipient;
  }

  get isActive(): boolean {
    return this._isActive;
  }

  get maxNbCharters(): number {
    return this._maxNbCharters;
  }

  set maxNbCharters(maxNbCharters: number) {
    this._maxNbCharters = maxNbCharters;
  }

  get maxNbLicenses(): number {
    return this._maxNbLicenses;
  }

  set maxNbLicenses(maxNbLicenses: number) {
    this._maxNbLicenses = maxNbLicenses;
  }

  get name(): string {
    return this._name;
  }

  get email(): string {
    return this._email;
  }

  get billingEmail(): string {
    return this._billingEmail;
  }

  get updatedAt(): string {
    return this._updatedAt;
  }

  get isUserPoc(): boolean {
    return this._isUserPoc;
  }

  get chargebeePlan(): string {
    return this._chargebeePlan;
  }

  get dunsSiret(): string {
    return this._dunsSiret;
  }

  get chargebeeCustomer(): string {
    return this._chargebeeCustomer;
  }

  get chargebeeSubscriptions(): string[] {
    return this._chargebeeSubscriptions;
  }

  set chargebeeSubscriptions(chargebeeSubscriptions) {
    this._chargebeeSubscriptions = chargebeeSubscriptions;
  }

  get chargebeeSubscriptionLastStatus(): string {
    return this._chargebeeSubscriptionLastStatus;
  }

  get checkoutChannel(): APICompanyCheckoutChannel {
    return this._checkoutChannel;
  }

  get charters(): APIModelCharter[] {
    return this._charters;
  }

  set charters(charters: APIModelCharter[]) {
    this._charters = charters;
  }

  get created(): boolean {
    return this._created;
  }

  set created(created: boolean) {
    this._created = created;
  }

  get subscriptionCreated(): boolean {
    return this._subscriptionCreated;
  }

  set subscriptionCreated(subscriptionCreated: boolean) {
    this._subscriptionCreated = subscriptionCreated;
  }

  get autoCollectionAsBoolean(): boolean {
    return this._autoCollection === 'on';
  }

  get autoCollection(): string {
    return this._autoCollection;
  }

  set autoCollection(autoCollection: string) {
    this._autoCollection = autoCollection;
  }

  get fileStorageAgreement(): APICompanyFileStorageAgreement | undefined {
    return this._fileStorageAgreement;
  }

  isValid(isKnlTeam = false): boolean {
    if (isKnlTeam) {
      return true;
    }

    return this.isActive && (this.charters.length > 0 || this.created);
  }

  toJsonModel(): APIModelCompany {
    return {
      contractEndDate: this._contractEndDate,
      contractNbMonths: this._contractNbMonths,
      contractPlan: this._contractPlan,
      chargebeePlan: this._chargebeePlan,
      dunsSiret: this._dunsSiret,
      chargebeeCustomer: this._chargebeeCustomer,
      chargebeeSubscription: this._chargebeeSubscriptions,
      chargebeeSubscriptionLastStatus: this._chargebeeSubscriptionLastStatus,
      checkoutChannel: this._checkoutChannel,
      contractStartDate: this._contractStartDate,
      contractType: this._contractType,
      createdAt: this._createdAt,
      id: this._id,
      connectedToPitchy: this._connectedToPitchy,
      forcePitchyRecipient: this._forcePitchyRecipient,
      pitchyRecipient: this._pitchyRecipient,
      isActive: this._isActive,
      maxNbCharters: this._maxNbCharters,
      maxNbLicenses: this._maxNbLicenses,
      name: this._name,
      email: this._email,
      billingEmail: this._billingEmail,
      updatedAt: this._updatedAt,
      autoCollection: this._autoCollection,
      charters: this._charters,
      fileStorageAgreement: this._fileStorageAgreement,
    };
  }
}
