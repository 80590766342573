import { InfoCircleOutlined } from '@ant-design/icons/lib';
import { Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { TFunction } from 'i18next';
import React, { ReactElement, ReactNode } from 'react';
import Highlighter from 'react-highlight-words';
import Preformatted from '../../../components/preformatted/Preformatted';
import FilterIcon from '../../../components/table/search-in-column-filter/FilterIcon';
import SearchInColumnFilter from '../../../components/table/search-in-column-filter/SearchInColumnFilter';
import { APICompanyNotificationConfig } from '../../../services/api/types/CompaniesServiceTypes';
import StringUtils from '../../../utils/StringUtils';
import NotificationConfigSwitch from './NotificationConfigSwitch';

type BuildColumnsArgs = {
  t: TFunction;
  classes: Record<string, string>;
  configs: APICompanyNotificationConfig[];
  filter: Record<string, string> | undefined;
  setFilter: React.Dispatch<React.SetStateAction<Record<string, string> | undefined>>;
  configColumnWidth: string | number;
  callbackUpdateCompanyNotifications: (
    notificationConfigId: number,
    updatedField: 'isEmailActive' | 'isWebActive',
    newValue: boolean
  ) => void;
  isConfigBeingUpdated: boolean;
  setIsConfigBeingUpdated: React.Dispatch<React.SetStateAction<boolean>>;
  isKnlTeam: boolean;
};

export const buildColumns = ({
  t,
  classes,
  configs,
  filter,
  setFilter,
  configColumnWidth,
  callbackUpdateCompanyNotifications,
  isConfigBeingUpdated,
  setIsConfigBeingUpdated,
  isKnlTeam,
}: BuildColumnsArgs): ColumnProps<APICompanyNotificationConfig>[] => {
  return [
    {
      title: t('notificationConfigs.table.notificationType'),
      dataIndex: 'name',
      key: 'notification_config_name',
      render: (name: string, config: APICompanyNotificationConfig): ReactElement => {
        return (
          <div className={classes.configName}>
            {filter && filter.name ? (
              <Highlighter
                className={classes.highlightedText}
                searchWords={[filter.name]}
                autoEscape
                textToHighlight={name.toString()}
              />
            ) : (
              <>
                <span>{name}</span>
              </>
            )}
            {isKnlTeam && (
              <Tooltip placement="bottomLeft" title={<Preformatted>{config.code}</Preformatted>}>
                <span className={classes.configCodeTooltip}>
                  <InfoCircleOutlined />
                </span>
              </Tooltip>
            )}
          </div>
        );
      },
      filterDropdown: (filterProps: FilterDropdownProps): ReactNode => {
        const possibleValues = configs?.map((c) => c.name);
        return (
          <SearchInColumnFilter
            {...filterProps}
            className={classes.searchInColumnFilter}
            dataIndex="name"
            setFilter={setFilter}
            placeholder={t('notificationConfigs.table.searchOnNotificationTypePlaceholder')}
            possibleValues={possibleValues}
          />
        );
      },
      filterIcon: FilterIcon,
      onFilter: (value: string | number | boolean, record: APICompanyNotificationConfig): boolean =>
        StringUtils.normalize(record.name).includes(StringUtils.normalize(value.toString())),
      sorter: (notifA: APICompanyNotificationConfig, notifB: APICompanyNotificationConfig): number =>
        StringUtils.compareNaturalNormalized(notifA.name, notifB.name),
      defaultSortOrder: 'ascend',
    },
    {
      title: t('notificationConfigs.table.isEmailActive'),
      dataIndex: 'isEmailActive',
      key: 'notification_config_isEmailActive',
      width: configColumnWidth,
      sorter: (notificationA: APICompanyNotificationConfig, notificationB: APICompanyNotificationConfig): number => {
        if (notificationA.isEmailActive === notificationB.isEmailActive) {
          return 0;
        }
        return notificationA.isEmailActive ? 1 : -1;
      },
      render: (isEmailActive: boolean, row: APICompanyNotificationConfig): ReactElement => {
        return (
          <NotificationConfigSwitch
            fieldToSwitch="isEmailActive"
            notificationConfig={row}
            isConfigBeingUpdated={isConfigBeingUpdated}
            setIsConfigBeingUpdated={setIsConfigBeingUpdated}
            callback={callbackUpdateCompanyNotifications}
          />
        );
      },
      filters: [
        {
          value: true,
          text: t('form.activated'),
        },
        {
          value: false,
          text: t('form.deactivated'),
        },
      ],
      onFilter: (value: string | number | boolean, record: APICompanyNotificationConfig): boolean =>
        record.isEmailActive === value,
    },
  ];
};
