import { CheckCircleOutlined } from '@ant-design/icons/lib';
import { Col, Form, Radio, Row } from 'antd';
import { RadioChangeEvent } from 'antd/es/radio';
import classNames from 'classnames';
import { filter, find, map } from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { THEME } from '../../Constants';
import { RoleList, RoleListType, Roles } from '../../core/rule/RolesTypes';

const useStyles = createUseStyles({
  formItemRole: {
    '& .ant-form-item-children-icon': {
      top: '20% !important',
    },
  },
  radioGroup: {
    width: '90%',
  },
  radioButton: {
    width: '100%',
  },
  radioButtonContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  iconCircle: {
    marginRight: 8,
  },
  emptyCircle: {
    height: 14,
    width: 14,
    border: 'solid 1px #333333',
    borderRadius: '50%',
    marginRight: 8,
  },
});

type Props = {
  role: Roles;
  defaultRole?: Roles;
  label?: string;
  hasFeedback?: boolean;
  noRules?: boolean;
  visibleRoles: RoleListType[];
  callbackHandleChange?: (value: Roles) => void;
};

const RoleRadioGroup: FunctionComponent<Props> = ({
  role,
  defaultRole,
  label,
  visibleRoles,
  callbackHandleChange,
  noRules,
  hasFeedback = true,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [roleItem, setRoleItem] = useState(role);
  const [roleExtra, setRoleExtra] = useState(RoleList[role].infoKey);

  useEffect(() => {
    const isRolePresent = find(visibleRoles, { code: role }) !== undefined;

    if (isRolePresent) {
      setRoleItem(role);
      setRoleExtra(RoleList[role].infoKey);
    } else if (defaultRole) {
      setRoleItem(defaultRole);
      setRoleExtra(RoleList[defaultRole].infoKey);
    }
  }, [role, defaultRole, visibleRoles]);

  const handleChange = (e: RadioChangeEvent): void => {
    setRoleItem(e.target.value);
    setRoleExtra(filter(visibleRoles, (visibleRole: RoleListType) => visibleRole.code === e.target.value)[0].infoKey);
    if (callbackHandleChange) {
      callbackHandleChange(e.target.value);
    }
  };

  return (
    <Form.Item
      label={label}
      className={classes.formItemRole}
      name="role"
      rules={noRules ? [] : [{ required: true }]}
      extra={t(roleExtra)}
      validateStatus="success"
      hasFeedback={hasFeedback}
    >
      <Radio.Group className={classes.radioGroup} onChange={handleChange} buttonStyle="solid">
        <Row gutter={[8, 8]}>
          {map(visibleRoles, (visibleRole) => {
            const { BACKGROUND_COLOR: bgColor, TEXT_COLOR: textColor } = THEME.CHARTERS.ROLES[visibleRole.code];

            const isSelected = visibleRole.code === roleItem;
            const style = {
              backgroundColor: bgColor,
              color: textColor,
              borderColor: isSelected ? textColor : bgColor,
            };

            return (
              <Col key={`addUser_${visibleRole.code}`} xs={24} sm={24} md={12}>
                <Radio.Button className={classNames(classes.radioButton)} value={visibleRole.code} style={style}>
                  <span className={classes.radioButtonContent}>
                    {isSelected ? (
                      <CheckCircleOutlined className={classes.iconCircle} />
                    ) : (
                      <span className={classes.emptyCircle} />
                    )}
                    {t(visibleRole.key)}
                  </span>
                </Radio.Button>
              </Col>
            );
          })}
        </Row>
      </Radio.Group>
    </Form.Item>
  );
};

export default RoleRadioGroup;
