import { find, forEach } from 'lodash';
import { THEME_KEY } from '../Constants';

export enum ThemeColorKeyDefaultMapping {
  BLACK = 'BLACK',
  WHITE = 'WHITE',
  COLOR_MAJOR = 'COLOR_MAJOR',
  COLOR_MINOR = 'COLOR_MINOR',
  COLOR_SECONDARY = 'COLOR_SECONDARY',
  OPACITY_MAIN = 'OPACITY_MAIN',
  OPACITY_TITLE_BACKGROUND = 'OPACITY_TITLE_BACKGROUND',
  OPACITY_SUBTITLE = 'OPACITY_SUBTITLE',
  OPACITY_SECONDARY = 'OPACITY_SECONDARY',
  OPACITY_CHART = 'OPACITY_CHART',
}

export type ThemeColorKey = {
  code: string;
  label: string;
  colorKeys: Array<ThemeColorKeyConfig>;
};

export enum ThemeColorKeyType {
  COLOR = 'COLOR',
  NUMBER = 'NUMBER',
  // BOOLEAN = 'BOOLEAN',
}

export type ThemeColorKeyConfig = {
  key: string;
  type: ThemeColorKeyType;
  defaultMapping: ThemeColorKeyDefaultMapping;
};

export type Theme = {
  code: string;
  colors: { [key: string]: string };
};

export default class ThemeUtils {
  static WHITE = '#FFFFFF';
  static BLACK = '#000000';

  static buildInitialThemeSettingsByBaseColors(baseColors: string[]): { [key: string]: string } {
    const colorsNumber = baseColors.length;

    const colorMajor = colorsNumber >= 1 ? baseColors[0] : ThemeUtils.BLACK;
    const colorMinor = colorsNumber >= 2 ? baseColors[1] : ThemeUtils.WHITE;
    const colorSecondary = colorsNumber >= 3 ? baseColors[2] : colorMajor;
    return {
      WHITE: ThemeUtils.WHITE,
      BLACK: ThemeUtils.BLACK,
      COLOR_MAJOR: colorMajor,
      COLOR_MINOR: colorMinor,
      COLOR_SECONDARY: colorSecondary,
      OPACITY_MAIN: '0.70',
      OPACITY_TITLE_BACKGROUND: '0.50',
      OPACITY_SUBTITLE: '0.80',
      OPACITY_SECONDARY: '1.00',
      OPACITY_CHART: '0.20',
    };
  }

  static buildThemeColorsByBaseColors(baseColors: string[]): Array<Theme> {
    const themeColorsInitializer = ThemeUtils.buildInitialThemeSettingsByBaseColors(baseColors);

    return ThemeUtils.buildThemeColorsByInitializer(themeColorsInitializer);
  }

  static buildThemeColorsByInitializer(initializer: { [key: string]: string }): Array<Theme> {
    return ThemeUtils.getThemes().map((theme) => {
      let newColors = {};
      theme.colorKeys.forEach((config) => {
        newColors = { ...newColors, [config.key]: initializer[config.defaultMapping] };
      });
      return { code: theme.code.toUpperCase(), colors: newColors };
    });
  }

  static getColorValueByThemeAndKey = (
    themeColors: Array<Theme>,
    themeCode: string,
    keyCode: string,
    defaultMapping?: string
  ): string => {
    const theme = themeColors.find((th) => th.code === themeCode);
    let color = theme && theme.colors[keyCode] ? theme.colors[keyCode] : ThemeUtils.WHITE;

    if (defaultMapping && theme && !theme.colors[keyCode]) {
      color = defaultMapping === ThemeColorKeyDefaultMapping.BLACK ? ThemeUtils.BLACK : ThemeUtils.WHITE;
    }

    return color.toUpperCase();
  };

  static getNumberValueByThemeAndKey = (
    themeColors: Array<Theme>,
    themeCode: string,
    keyCode: string
  ): string | undefined => {
    const theme = themeColors.find((th) => th.code === themeCode);
    return theme && theme.colors[keyCode] ? theme.colors[keyCode] : undefined;
  };

  // Takes an array of Theme in input and checks if all themes are configured.
  // If there is one missing Theme, it will be initialized as if the initializer
  // in input had been applied on it.
  // It returns the completed Theme array as well as the codes of the themes that have been added.
  static completeMissingThemes = (
    themesToComplete: Array<Theme>,
    initializer: { [key: string]: string }
  ): { completedThemes: Array<Theme>; addedThemeCodes: Array<string> } => {
    const completeThemeList = ThemeUtils.getThemes();
    if (themesToComplete.length === completeThemeList.length) {
      return { completedThemes: themesToComplete, addedThemeCodes: [] };
    }

    const completedList = [...themesToComplete];
    const themesColorsWithInitializer = ThemeUtils.buildThemeColorsByInitializer(initializer);
    const addedThemeCodes: string[] = [];

    completeThemeList.forEach((themeFromCompleteList) => {
      const isMissingTheme = !completedList.some((theme) => themeFromCompleteList.code === theme.code);
      if (isMissingTheme) {
        const missingTheme = find(
          themesColorsWithInitializer,
          (initializedTheme) => initializedTheme.code === themeFromCompleteList.code
        );
        if (missingTheme) {
          completedList.push(missingTheme);
          addedThemeCodes.push(missingTheme.code);
        }
      }
    });

    return { completedThemes: completedList, addedThemeCodes };
  };

  // This function fill color/opacity key that may be missing
  static completeThemesColorKey = (themes: Array<Theme>, initializer: { [key: string]: string }): Theme[] => {
    const completedList: Theme[] = [];
    const themesColorsWithInitializer = ThemeUtils.buildThemeColorsByInitializer(initializer);

    themes.forEach((themeToComplete) => {
      const initializedTheme = themesColorsWithInitializer.find((theme) => themeToComplete.code === theme.code);
      if (
        initializedTheme &&
        Object.keys(themeToComplete.colors).length !== Object.keys(initializedTheme.colors).length
      ) {
        let themeComplete: Theme = { ...themeToComplete };
        forEach(initializedTheme.colors, (value, key) => {
          if (!themeComplete.colors[key]) {
            themeComplete = {
              ...themeComplete,
              colors: {
                ...themeComplete.colors,
                [key]: value,
              },
            };
          }
        });

        completedList.push(themeComplete);
      } else {
        completedList.push(themeToComplete);
      }
    });

    return completedList;
  };

  static getThemes(): Array<ThemeColorKey> {
    return [
      {
        code: THEME_KEY.ALGIERS.code,
        label: THEME_KEY.ALGIERS.labelCode,
        colorKeys: [
          { key: 'titleText', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          {
            key: 'titleBackgroundOpacity',
            type: ThemeColorKeyType.NUMBER,
            defaultMapping: ThemeColorKeyDefaultMapping.OPACITY_MAIN,
          },
          { key: 'titleBackground', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.BLACK },
          {
            key: 'subtitleOpacity',
            type: ThemeColorKeyType.NUMBER,
            defaultMapping: ThemeColorKeyDefaultMapping.OPACITY_SUBTITLE,
          },
          { key: 'subtitleTopText', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          {
            key: 'subtitleTopBackground',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MAJOR,
          },
          {
            key: 'subtitleBottomText',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.WHITE,
          },
          {
            key: 'subtitleBottomBackground',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MINOR,
          },
          {
            key: 'slideSplitTopText',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.WHITE,
          },
          {
            key: 'slideSplitTopBackground',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MINOR,
          },
          {
            key: 'slideSplitBottomText',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.WHITE,
          },
          {
            key: 'slideSplitBottomBackground',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MAJOR,
          },
          { key: 'gradientText', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          {
            key: 'gradientLeft',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MAJOR,
          },
          {
            key: 'gradientRight',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MAJOR,
          },
          {
            key: 'maskGradientTop',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MINOR,
          },
          {
            key: 'maskGradientBottom',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MINOR,
          },
          { key: 'background', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MAJOR },
          { key: 'text', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
        ],
      },
      {
        code: THEME_KEY.BALI.code,
        label: THEME_KEY.BALI.labelCode,
        colorKeys: [
          { key: 'titleText', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          { key: 'titleBackground', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.BLACK },
          {
            key: 'titleBackgroundOpacity',
            type: ThemeColorKeyType.NUMBER,
            defaultMapping: ThemeColorKeyDefaultMapping.OPACITY_MAIN,
          },
          { key: 'background', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MAJOR },
          {
            key: 'backgroundOpacity',
            type: ThemeColorKeyType.NUMBER,
            defaultMapping: ThemeColorKeyDefaultMapping.OPACITY_TITLE_BACKGROUND,
          },
          { key: 'text', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          { key: 'shape', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MINOR },
          {
            key: 'maskGradientTop',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MAJOR,
          },
          {
            key: 'maskGradientBottom',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MAJOR,
          },
        ],
      },
      {
        code: THEME_KEY.GENEVA.code,
        label: THEME_KEY.GENEVA.labelCode,
        colorKeys: [
          { key: 'titleText', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          { key: 'titleBackground', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.BLACK },
          {
            key: 'titleBackgroundOpacity',
            type: ThemeColorKeyType.NUMBER,
            defaultMapping: ThemeColorKeyDefaultMapping.OPACITY_TITLE_BACKGROUND,
          },
          { key: 'background', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MAJOR },
          { key: 'text', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          { key: 'shape', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          { key: 'shadow', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.BLACK },
          {
            key: 'shadowOpacity',
            type: ThemeColorKeyType.NUMBER,
            defaultMapping: ThemeColorKeyDefaultMapping.OPACITY_MAIN,
          },
          {
            key: 'maskGradientTop',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MINOR,
          },
          {
            key: 'maskGradientBottom',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MINOR,
          },
        ],
      },
      {
        code: THEME_KEY.LOS_ANGELES.code,
        label: THEME_KEY.LOS_ANGELES.labelCode,
        colorKeys: [
          { key: 'titleText', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          { key: 'titleBackground', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.BLACK },
          {
            key: 'titleBackgroundOpacity',
            type: ThemeColorKeyType.NUMBER,
            defaultMapping: ThemeColorKeyDefaultMapping.OPACITY_MAIN,
          },
          { key: 'subtitleTopText', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          {
            key: 'subtitleBottomText',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.WHITE,
          },
          {
            key: 'subtitleBackground',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MAJOR,
          },
          { key: 'shadow', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.BLACK },
          {
            key: 'shadowOpacity',
            type: ThemeColorKeyType.NUMBER,
            defaultMapping: ThemeColorKeyDefaultMapping.OPACITY_TITLE_BACKGROUND,
          },
          { key: 'slideBackground', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          { key: 'slideText', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MAJOR },
          {
            key: 'slideTextStrong',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MINOR,
          },
          { key: 'floatingText', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          {
            key: 'floatingBackground',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MAJOR,
          },
          {
            key: 'maskGradientTop',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MINOR,
          },
          {
            key: 'maskGradientBottom',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_SECONDARY,
          },
          { key: 'background', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MAJOR },
          { key: 'text', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
        ],
      },
      {
        code: THEME_KEY.SEATTLE.code,
        label: THEME_KEY.SEATTLE.labelCode,
        colorKeys: [
          { key: 'titleText', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          { key: 'titleBackground', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.BLACK },
          {
            key: 'titleBackgroundOpacity',
            type: ThemeColorKeyType.NUMBER,
            defaultMapping: ThemeColorKeyDefaultMapping.OPACITY_SUBTITLE,
          },
          {
            key: 'subtitleTopText',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MAJOR,
          },
          {
            key: 'subtitleTopBackground',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.WHITE,
          },
          {
            key: 'subtitleBottomText',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.WHITE,
          },
          {
            key: 'subtitleBottomBackground',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MAJOR,
          },
          {
            key: 'slideSplitTopText',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.WHITE,
          },
          {
            key: 'slideSplitTopBackground',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MINOR,
          },
          {
            key: 'slideSplitBottomText',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MINOR,
          },
          {
            key: 'slideSplitBottomBackground',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.WHITE,
          },
          {
            key: 'slideFullBackground',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MINOR,
          },
          { key: 'slideFullText', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          {
            key: 'floatingText',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MAJOR,
          },
          {
            key: 'floatingBackground',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.WHITE,
          },
          { key: 'shape', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MAJOR },
          {
            key: 'maskGradientTop',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_SECONDARY,
          },
          {
            key: 'maskGradientBottom',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_SECONDARY,
          },
          { key: 'background', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MAJOR },
          { key: 'text', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
        ],
      },
      {
        code: THEME_KEY.BERLIN.code,
        label: THEME_KEY.BERLIN.labelCode,
        colorKeys: [
          {
            key: 'titleGradientTop',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MINOR,
          },
          {
            key: 'titleGradientBottom',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_SECONDARY,
          },
          { key: 'titleBackground', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.BLACK },
          {
            key: 'titleBackgroundOpacity',
            type: ThemeColorKeyType.NUMBER,
            defaultMapping: ThemeColorKeyDefaultMapping.OPACITY_SUBTITLE,
          },
          { key: 'background', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MAJOR },
          { key: 'text', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          {
            key: 'textBackground',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MAJOR,
          },
          {
            key: 'textBackgroundOpacity',
            type: ThemeColorKeyType.NUMBER,
            defaultMapping: ThemeColorKeyDefaultMapping.OPACITY_SECONDARY,
          },
          {
            key: 'gradientTop',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MINOR,
          },
          {
            key: 'gradientBottom',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_SECONDARY,
          },
          {
            key: 'neutralGradientTop',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.WHITE,
          },
          {
            key: 'neutralGradientBottom',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.BLACK,
          },
          { key: 'slideBackground', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          { key: 'shapeMain', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MINOR },
          {
            key: 'shapeSecond',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_SECONDARY,
          },
          { key: 'shapeNeutral', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          {
            key: 'maskGradientTop',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MINOR,
          },
          {
            key: 'maskGradientBottom',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_SECONDARY,
          },
        ],
      },
      {
        code: THEME_KEY.BOGOTA.code,
        label: THEME_KEY.BOGOTA.labelCode,
        colorKeys: [
          { key: 'shapeNeutral', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          { key: 'shapeMain', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MINOR },
          {
            key: 'shapeSecondary',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MAJOR,
          },
          {
            key: 'shapeShadow',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.BLACK,
          },
          {
            key: 'shapeShadowOpacity',
            type: ThemeColorKeyType.NUMBER,
            defaultMapping: ThemeColorKeyDefaultMapping.OPACITY_MAIN,
          },
          { key: 'background', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MAJOR },
          {
            key: 'transparentBackground',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.BLACK,
          },
          {
            key: 'backgroundOpacity',
            type: ThemeColorKeyType.NUMBER,
            defaultMapping: ThemeColorKeyDefaultMapping.OPACITY_SECONDARY,
          },
          { key: 'coverText', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          {
            key: 'coverTextOpacity',
            type: ThemeColorKeyType.NUMBER,
            defaultMapping: ThemeColorKeyDefaultMapping.OPACITY_TITLE_BACKGROUND,
          },
          { key: 'title', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          { key: 'titleBottom', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          {
            key: 'titleBlinking',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MINOR,
          },
          { key: 'chartEmpty', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          { key: 'chartFill', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MINOR },
          { key: 'figure', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          {
            key: 'figureBlink',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MINOR,
          },
          { key: 'text', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          { key: 'source', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MINOR },
          { key: 'sourceNeutral', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          { key: 'shapeCosmetic', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          {
            key: 'shapeCosmeticOpacity',
            type: ThemeColorKeyType.NUMBER,
            defaultMapping: ThemeColorKeyDefaultMapping.OPACITY_MAIN,
          },
          { key: 'negativeBarTop', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          {
            key: 'negativeBarBottom',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.WHITE,
          },
          {
            key: 'positiveBarTop',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MINOR,
          },
          {
            key: 'positiveBarBottom',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MINOR,
          },
          {
            key: 'backgroundBarOpacity',
            type: ThemeColorKeyType.NUMBER,
            defaultMapping: ThemeColorKeyDefaultMapping.OPACITY_CHART,
          },
          { key: 'ctaTitle', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          {
            key: 'ctaTitleBlinking',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MINOR,
          },
          { key: 'cta', type: ThemeColorKeyType.COLOR, defaultMapping: ThemeColorKeyDefaultMapping.WHITE },
          {
            key: 'textShadow',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.BLACK,
          },
          {
            key: 'textShadowOpacity',
            type: ThemeColorKeyType.NUMBER,
            defaultMapping: ThemeColorKeyDefaultMapping.OPACITY_MAIN,
          },
          {
            key: 'maskGradientTop',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MINOR,
          },
          {
            key: 'maskGradientBottom',
            type: ThemeColorKeyType.COLOR,
            defaultMapping: ThemeColorKeyDefaultMapping.COLOR_MINOR,
          },
        ],
      },
    ];
  }
}
