import { Form, message, Tag } from 'antd';
import { map } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/RootState';
import { APICompanyUsers } from '../../services/api/types/CompaniesServiceTypes';
import ValidationModal from '../modal/ConfirmModal';

type Props = {
  name: string;
  label: string;
  contacts: APICompanyUsers[];
  extra?: string;
  addPocComponents: JSX.Element;
  callbackDeletePoc: (contact: APICompanyUsers) => void;
};

const useStyles = createUseStyles({
  tag: {
    borderRadius: 25,
    fontSize: 14,
    borderWidth: 0,
    color: '#1F2933',
    background: '#E4E7EB',
  },
});

const ContactsInput: FunctionComponent<Props> = ({
  addPocComponents,
  name,
  label,
  extra,
  contacts,
  callbackDeletePoc,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [contactSelected, setContactSelected] = useState<any>();
  const currentUser = useSelector((state: RootState) => state.user.user);

  const handleClick = (e: any, contact: APICompanyUsers): void => {
    e.preventDefault();
    if (contact.email === currentUser?.email) {
      message.error(t('company.form.errorRemoveYourselfPoc'));
      return;
    }
    setConfirmVisible(true);
    setContactSelected(contact);
  };

  const handleValidation = (validation: boolean): void => {
    setConfirmVisible(false);
    if (validation) {
      callbackDeletePoc(contactSelected);
    }
  };

  // you can't remove a poc if you only have 1
  const closable = contacts.length > 1;

  const checkContacts = (): Promise<void> | void => {
    if (contacts.length > 0) {
      return Promise.resolve();
    }

    if (contacts.length === 0) {
      return Promise.reject(new Error(t('company.form.contactsRuleRequiredError')));
    }

    return Promise.resolve();
  };

  return (
    <>
      <Form.Item
        name={name}
        label={label}
        extra={extra}
        required
        rules={[{ required: true, message: t('company.form.contactsRuleRequiredError'), validator: checkContacts }]}
      >
        <>
          {map(contacts, (contact) => {
            return (
              <Tag
                className={classes.tag}
                key={`tag_${contact.id}`}
                closable={closable}
                onClose={(e) => handleClick(e, contact)}
              >
                {contact.email}
              </Tag>
            );
          })}
          {addPocComponents}
        </>
      </Form.Item>

      <ValidationModal
        callBackValidation={handleValidation}
        message={t('company.form.removePocFromCompany')}
        visible={confirmVisible}
      />
    </>
  );
};

export default ContactsInput;
