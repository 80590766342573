import { Button, Modal } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { DEVICE_SIZES_QUERIES } from '../../../../../../Constants';
import { Project } from '../../../../../../utils/types/ProjectTypes';
import ProjectDetails from './ProjectDetails';

type Props = {
  isVisible: boolean;
  onHide: () => void;
  project: Project;
  onRefinalizeClick?: () => void;
};

const useStyles = createUseStyles({
  fullscreenModal: {
    width: '75% !important',
    '& .ant-modal-body': {
      position: 'relative',
    },
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      width: '90% !important',
      '& .ant-modal-body': {
        padding: 12,
      },
    },
  },
});

const ProjectDetailsModal: FunctionComponent<Props> = ({ isVisible, onHide, project, onRefinalizeClick }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Modal
      title={t('charters.projects.projectInfo.title', { title: project.title })}
      className={classes.fullscreenModal}
      visible={isVisible}
      onOk={onHide}
      onCancel={onHide}
      destroyOnClose
      footer={[
        <Button onClick={onHide} key={project.id}>
          {t('global.close')}
        </Button>,
      ]}
    >
      <ProjectDetails project={project} onRefinalizeClick={onRefinalizeClick} />
    </Modal>
  );
};

export default ProjectDetailsModal;
