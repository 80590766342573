import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Col, Divider, message, Modal, Row, Tabs } from 'antd';
import Title from 'antd/lib/typography/Title';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import classNames from 'classnames';
import map from 'lodash/map';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import ProcessingFileCard from '../../../../../../components/card/ProcessingFileCard';
import VideoOrMusicFileCardPlayer from '../../../../../../components/card/VideoOrMusicFileCardPlayer';
import PageContentLoader from '../../../../../../components/loader/PageContentLoader';
import FileUploader, {
  DraggerCustomRequestParams,
  FileUploaderProps,
} from '../../../../../../components/modal/FileUploader';
import { LOGGING_EVENT, THEME } from '../../../../../../Constants';
import { fileUploadedAndProcessing } from '../../../../../../redux/action/ChartersAction';
import { RootState } from '../../../../../../redux/RootState';
import CharterMusicsCover from '../../../../../../resources/img/musics/charter-musics.png';
import { getCharterAssetSignedUrlToUpload } from '../../../../../../services/api/ChartersService';
import { uploadFileToSignedUrl } from '../../../../../../services/api/GlobalService';
import { getPublicMusics } from '../../../../../../services/api/MusicsService';
import { Asset, AssetType } from '../../../../../../services/api/types/ChartersServiceTypes';
import { APIMusicsResult, Music } from '../../../../../../services/api/types/MusicsServiceTypes';
import AssetsUtils from '../../../../../../utils/AssetsUtils';
import MathUtils from '../../../../../../utils/MathUtils';
import { ProjectMusicCategories, ProjectMusicFile } from '../../../../../../utils/types/ProjectTypes';

const { TabPane } = Tabs;

type Props = {
  value?: ProjectMusicFile;
  isVisible: boolean;
  fieldLabel: string;
  onCancel: () => void;
  onSelectMusic: (music: Music | Asset) => void;
};

const useStyles = createUseStyles({
  modal: {
    width: '80% !important',
  },
  modalTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 32,
  },
  fieldLabel: {
    display: 'flex',
    height: 32,
    justifyContent: 'flex-end',
    alignItems: 'center',
    '&::after': {
      content: "':'",
      position: 'relative',
      top: '-0.5px',
      margin: '0 8px 0 2px',
    },
  },
  fieldLabelIcon: {
    marginRight: 6,
  },
  formFieldContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 40,
  },
  fieldSelectorColumn: {
    display: 'flex',
    alignItems: 'center',
  },
  categoryCoverImage: {
    height: 120,
    opacity: 0.6,
  },
  categoryTabs: {
    '& .ant-tabs-tab': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  categoryCard: {
    borderRadius: 5,
    overflow: 'hidden',
    boxShadow: `2px 2px 7px 0px ${THEME.DEFAULT.BORDER_COLOR}`,
    position: 'relative',
  },
  categoryName: {
    color: '#FFFFFF',
    textShadow: '1px 1px 10px #000000',
    padding: 10,
    width: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  selectedCategoryCoverImage: {
    outline: `5px solid ${THEME.MENU.MAIN_COLOR}`,
    outlineOffset: '-5px',
    opacity: 1,
  },
  selectedMusicInCategory: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: THEME.MENU.MAIN_COLOR,
    fontSize: 20,
    marginRight: '0px !important',
  },
  categoryTabContainer: {
    padding: 15,
  },
  musicCard: {
    position: 'relative',
  },
  selectedMusicIcon: {
    position: 'absolute',
    right: 8,
    bottom: 12,
    color: THEME.MENU.MAIN_COLOR,
    fontSize: 18,
  },
  title: {
    '&.ant-typography': {
      color: '#3B3B3B',
      fontWeight: 400,
      marginTop: 10,
      borderBottom: 'solid 1px #dddddd',
    },
  },
  assetRow: {
    margin: '0 !important',
    marginBottom: '20px !important',
    maxHeight: '50vh',
    overflow: 'auto',
  },
  archivedAssetMessage: {
    float: 'left',
    fontSize: 12,
    lineHeight: '24px',
  },
});

const ProjectMusicSettingsModal: FunctionComponent<Props> = ({
  value,
  isVisible,
  fieldLabel,
  onCancel,
  onSelectMusic,
}: Props) => {
  const [publicMusics, setPublicMusics] = useState<Music[]>();
  const [publicMusicsLoading, setPublicMusicsLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(ProjectMusicCategories.AERIAL.code);
  const [playingMusic, setPlayingMusic] = useState<Music | Asset>();

  const isValuePublicMusic = value && AssetsUtils.isPublicMusic(value);

  const charter = useSelector((state: RootState) => state.charters.current);
  const company = useSelector((state: RootState) => state.companies.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);
  const processingAssets = useSelector((state: RootState) => {
    return state.charters.processingAssets;
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const layoutTabProps = { xs: 24, sm: 24, md: 12, lg: 8, xl: 6, xxl: 6 };
  const sizeLimit = AssetsUtils.getSizeLimitByAssetType(AssetType.MUSIC);
  const musicFileTypes = useMemo(() => AssetsUtils.getMusicMimeTypeList().join(','), []);

  // Determine if the select music is archived or not in the charter
  const isSelectedMusicArchived = useMemo(() => {
    if (!(value && charter && charter.musics && charter.musics.length > 0)) {
      return false;
    }

    const selectedAssetMusic = charter.musics?.find((music) => music.id === value.id);
    return selectedAssetMusic?.isArchived ?? false;
  }, [value, charter]);

  // Fetch the Kannelle public musics
  useEffect(() => {
    if (!isVisible) {
      return;
    }

    getPublicMusics()
      .then((response: APIMusicsResult) => {
        const { data } = response;
        setPublicMusics(data.items);
      })
      .finally(() => {
        setPublicMusicsLoading(false);
      });
  }, [isVisible]);

  useEffect(() => {
    if (!isVisible) {
      setPlayingMusic(undefined);
    }
  }, [isVisible]);

  useEffect(() => {
    if (!isVisible) {
      return;
    }

    if (!isValuePublicMusic) {
      setSelectedCategory(ProjectMusicCategories.CHARTER.code);
    } else {
      const selectedMusicTag = (value as Music).tag;
      setSelectedCategory(selectedMusicTag);
    }
  }, [isVisible]);

  const onSizeLimitExceeded = (): void => {
    message.error(t(`charters.music.uploadSizeLimit`, { limit: sizeLimit }));
  };

  const onUploadStatusChangeToError = (info: UploadChangeParam): void => {
    message.error(t('charters.assets.uploadError', { fileName: info.file.name }));
  };

  // When the upload is done, automatically select the Charter musics category to show the processing card
  const onUploadStatusChangeToDone = (info: UploadChangeParam): void => {
    dispatch(fileUploadedAndProcessing(info.file, AssetType.MUSIC));
    setSelectedCategory(ProjectMusicCategories.CHARTER.code);
  };

  const onUploadFile = (requestParam: DraggerCustomRequestParams): void => {
    if (!charter) {
      return;
    }

    const params = {
      charterId: charter.id,
      filename: requestParam.file.name,
      assetType: AssetType.MUSIC,
    };

    loggingManager.logEvent(LOGGING_EVENT.UPLOAD_ASSET, {
      name: requestParam.file.name,
      size: `${Math.floor(MathUtils.convertBytesToKilobytes(requestParam.file.size))} ${t('charters.assets.KB')}`,
      charterId: charter.id,
      companyId: company?.id,
      mimeType: requestParam.file.type,
    });

    getCharterAssetSignedUrlToUpload(params)
      .then((signedUrl) => {
        return uploadFileToSignedUrl(signedUrl.fileUploadURL, requestParam.file, (event) => {
          requestParam.onProgress({ percent: (event.loaded / event.total) * 100 }, requestParam.file);
        })
          .then(() => {
            requestParam.onSuccess({ ...requestParam, data: signedUrl.metadata });
          })
          .catch((err) => requestParam.onError(err));
      })
      .catch((err) => requestParam.onError(err));
  };

  const onRetryUploadFile = (uploadFile: UploadFile): void => {
    if (!charter) {
      return;
    }

    const fileName = uploadFile.fileName || uploadFile.name;

    const params = {
      charterId: charter.id,
      filename: fileName,
      assetType: AssetType.MUSIC,
    };

    dispatch(fileUploadedAndProcessing(uploadFile, AssetType.MUSIC));

    getCharterAssetSignedUrlToUpload(params)
      .then((signedUrl) => {
        return uploadFileToSignedUrl(signedUrl.fileUploadURL, uploadFile.originFileObj).catch((err) =>
          log.error(`An error occurred while retrying to upload an asset: ${err}`)
        );
      })
      .catch((err) => log.error(`An error occurred while retrying to upload an asset: ${err}`));
  };

  const uploaderProps: FileUploaderProps = {
    triggerText: t('charters.assets.upload'),
    modalTitle: t(`charters.music.uploadModalTitle`),
    uploadInstructionText: t(`charters.music.uploadTitle`),
    uploadInstructionHint: t(`charters.music.uploadHint`),
    uploadInstructionSizeLimit: t(`charters.music.uploadSizeLimit`, { limit: sizeLimit }),
    acceptFileType: musicFileTypes,
    sizeLimit,
    onSizeLimitExceeded,
    onUploadStatusChangeToDone,
    onUploadStatusChangeToError,
    onUploadFile,
  };

  const onCategoryChange = (categoryCode: string) => {
    setSelectedCategory(categoryCode);
  };

  const handlePlayingMusicChange = (isPlaying: boolean, music: Music | Asset): void => {
    if (isPlaying) {
      setPlayingMusic(music);
    } else {
      setPlayingMusic(undefined);
    }
  };

  const renderProcessingCharterMusics = () => {
    const processingMusics = processingAssets[AssetType.MUSIC];

    if (!(processingMusics && processingMusics.length > 0)) {
      return null;
    }

    return (
      <>
        <Title className={classes.title} level={5}>
          {t(`charters.music.processing`)}
        </Title>

        <Row gutter={[16, 16]} className={classes.assetRow}>
          {processingMusics.map((uploadFile: UploadFile) => {
            return (
              <Col key={uploadFile.uid} {...layoutTabProps}>
                <ProcessingFileCard
                  uploadFile={uploadFile}
                  onRetryUploadFile={(): void => onRetryUploadFile(uploadFile)}
                  processingMessage={t('charters.assets.processing')}
                  processingErrorMessage={t('charters.assets.processingError')}
                  trigger="button"
                />
              </Col>
            );
          })}
        </Row>

        <Divider />
      </>
    );
  };

  const renderCharterMusicCategory = () => {
    const processingMusics = processingAssets[AssetType.MUSIC];
    const charterMusicWithoutArchived = charter?.musics?.filter((music) => !music.isArchived);
    if (
      !(
        charter &&
        ((charterMusicWithoutArchived && charterMusicWithoutArchived.length > 0) || processingMusics.length > 0)
      )
    ) {
      return null;
    }

    const isSelectedMusicInCategory = !isValuePublicMusic;
    const isSelectedCategory = selectedCategory === ProjectMusicCategories.CHARTER.code;

    return (
      <TabPane
        key={ProjectMusicCategories.CHARTER.code}
        tab={
          <div className={classes.categoryCard}>
            <img
              src={CharterMusicsCover}
              alt="Charter musics cover"
              className={classNames(
                classes.categoryCoverImage,
                isSelectedCategory && classes.selectedCategoryCoverImage
              )}
            />
            <div className={classes.categoryName}>{charter.name}</div>
            {isSelectedMusicInCategory && <CheckCircleFilled className={classes.selectedMusicInCategory} />}
          </div>
        }
      >
        {renderProcessingCharterMusics()}

        <Row gutter={[16, 16]} className={classes.categoryTabContainer}>
          {charterMusicWithoutArchived?.map((music) => {
            const isSelectedAsset = isSelectedMusicInCategory && music.id === value?.id;

            return (
              <Col key={music.id} {...layoutTabProps}>
                <div className={classes.musicCard}>
                  <VideoOrMusicFileCardPlayer
                    videoOrMusicFile={music}
                    videoOrMusicPlaying={playingMusic}
                    isMusic
                    callbackPlaying={(isPlaying: boolean): void => handlePlayingMusicChange(isPlaying, music)}
                    callbackOnSelect={() => onSelectMusic(music)}
                    trigger="button"
                    disabled={isSelectedAsset}
                  />
                  {isSelectedAsset && <CheckCircleFilled className={classes.selectedMusicIcon} />}
                </div>
              </Col>
            );
          })}
        </Row>
      </TabPane>
    );
  };

  const renderPublicMusicCategories = () => {
    if (!publicMusics) {
      return null;
    }

    return (
      <>
        {map(ProjectMusicCategories, (category) => {
          const isSelectedMusicInCategory = isValuePublicMusic && (value as Music).tag === category.code;
          const isSelectedCategory = selectedCategory === category.code;
          const musicsForCategory = publicMusics.filter((publicMusic) => publicMusic.tag === category.code);

          return (
            musicsForCategory &&
            musicsForCategory.length > 0 && (
              <TabPane
                tab={
                  <div className={classes.categoryCard}>
                    <img
                      src={category.cover}
                      alt={`${category.code} cover`}
                      className={classNames(
                        classes.categoryCoverImage,
                        isSelectedCategory && classes.selectedCategoryCoverImage
                      )}
                    />
                    {category.key && <div className={classes.categoryName}>{t(category.key)}</div>}
                    {isSelectedMusicInCategory && <CheckCircleFilled className={classes.selectedMusicInCategory} />}
                  </div>
                }
                key={category.code}
              >
                <Row gutter={[16, 16]} className={classes.categoryTabContainer}>
                  {musicsForCategory.map((music) => {
                    const isSelectedMusic = isValuePublicMusic && music.id === value?.id;
                    return (
                      <Col key={music.id} {...layoutTabProps}>
                        <div className={classes.musicCard}>
                          <VideoOrMusicFileCardPlayer
                            videoOrMusicFile={music}
                            videoOrMusicPlaying={playingMusic}
                            isMusic
                            callbackPlaying={(isPlaying: boolean): void => handlePlayingMusicChange(isPlaying, music)}
                            callbackOnSelect={() => onSelectMusic(music)}
                            trigger="button"
                            disabled={isSelectedMusic}
                          />
                          {isSelectedMusic && <CheckCircleFilled className={classes.selectedMusicIcon} />}
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </TabPane>
            )
          );
        })}
      </>
    );
  };

  const renderMusicCategories = () => {
    return (
      publicMusics && (
        <Tabs
          tabPosition="top"
          className={classes.categoryTabs}
          activeKey={selectedCategory}
          onChange={onCategoryChange}
        >
          {renderCharterMusicCategory()}

          {renderPublicMusicCategories()}
        </Tabs>
      )
    );
  };

  // Field label and a button to upload a music
  const renderModalTitle = () => {
    return (
      <div className={classes.modalTitleContainer}>
        {fieldLabel}
        <FileUploader {...uploaderProps} />
      </div>
    );
  };

  const renderMessageAboutArchivedMusic = () => {
    return (
      <div key="archivedAssetMessage" className={classes.archivedAssetMessage}>
        {t(`charters.music.selectedAssetIsArchived`)}
      </div>
    );
  };

  return (
    <Modal
      title={renderModalTitle()}
      visible={isVisible}
      onCancel={onCancel}
      destroyOnClose
      footer={[
        ...(isSelectedMusicArchived ? [renderMessageAboutArchivedMusic()] : []),
        <Button key="cancelModal" onClick={onCancel}>
          {t('global.cancel')}
        </Button>,
      ]}
      className={classes.modal}
    >
      {publicMusicsLoading ? <PageContentLoader /> : renderMusicCategories()}
    </Modal>
  );
};

export default ProjectMusicSettingsModal;
