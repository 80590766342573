import { PlusCircleOutlined } from '@ant-design/icons/lib';
import { Alert, Form, message, Modal, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Store } from 'antd/lib/form/interface';
import { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import { map } from 'lodash';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AccessChecker from '../../../../components/access-checker/AccessChecker';
import FormatTag from '../../../../components/tag/format-tag/FormatTag';
import { DEVICE_SIZES_QUERIES, LINK, LOGGING_EVENT, THEME } from '../../../../Constants';
import useCanAccess from '../../../../hooks/useCanAccess';
import { RootState } from '../../../../redux/RootState';
import { APIManager } from '../../../../services/api/APIManager';
import { createCharterScenarioVariant } from '../../../../services/api/ChartersService';
import {
  APICreateCharterScenarioVariantParams,
  Scenario,
  ScenarioVariant,
} from '../../../../services/api/types/ChartersServiceTypes';
import { ScenarioFormats, ScenarioLanguages } from '../../../../utils/types/ScenarioTypes';

const { Option } = Select;

type Props = {
  scenario: Scenario;
};

const useStyles = createUseStyles({
  modal: {
    width: '60% !important',
  },
  addVariantButtonContainer: {
    color: THEME.DEFAULT.MAIN_TEXT_COLOR,
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  disabledAddVariantButtonContainer: {
    cursor: 'not-allowed',
    color: 'rgba(0, 0, 0, 0.25)',
  },
  addVariantIcon: {
    marginRight: '5px',
  },
  form: {
    height: '100%',
    '& label': {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      height: 'unset !important',
      whiteSpace: 'normal',
      '&::before': {
        marginRight: '0 !important',
      },

      [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
        justifyContent: 'flex-start',
      },
    },
    '& .ant-select-selection-item, & .ant-select-item-option-content': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  translationDescription: {
    backgroundColor: THEME.DEFAULT.INFO_ALERT.BACKGROUND_COLOR,
    border: `1px solid ${THEME.DEFAULT.INFO_ALERT.BORDER_COLOR}`,
    marginBottom: '20px',
    textAlign: 'justify',
  },
  disabledOption: {
    opacity: 0.4,
    marginRight: 5,
  },
});

const CreateNewScenarioVariantModal: FunctionComponent<Props> = ({ scenario }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const formRef = useRef<FormInstance>(null);
  const history = useHistory();

  const [isVisible, setIsVisible] = useState(false);
  const [isAsyncLoading, setIsAsyncLoading] = useState(false);
  const [mainVariant, setMainVariant] = useState<ScenarioVariant>();
  const [isValidVariant, setIsValidVariant] = useState(false);
  const [isLanguageSelected, setIsLanguageSelected] = useState(false);
  const [notAllowedFormats, setNotAllowedFormats] = useState<string[]>();
  const [notAllowedLanguages, setNotAllowedLanguages] = useState<string[]>();

  const charter = useSelector((state: RootState) => state.charters.current);
  const company = useSelector((state: RootState) => state.companies.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);
  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());

  const permissionToAccessScenario = useCanAccess(`SCENARIO_${scenario.code}`);
  const hasUserAccessToScenario = permissionToAccessScenario.hasUserAccess();

  useEffect(() => {
    const main = scenario.variants.find((variant) => variant.isMain);
    if (main) {
      setMainVariant(main);
    }
  }, [scenario]);

  useEffect(() => {
    if (!isVisible) {
      setIsAsyncLoading(false);

      if (formRef.current && form) {
        form.resetFields();
      }
    }
  }, [isVisible, form]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const onShowModal = (): void => {
    setIsVisible(true);
    setNotAllowedFormats(undefined);
    setNotAllowedLanguages(undefined);
  };

  const onHideModal = (): void => {
    setIsVisible(false);
  };

  const onValuesChange = (changedValues: Store, allValues: Store): void => {
    const { variantLanguage, variantFormat } = allValues;

    setIsLanguageSelected(!!variantLanguage);

    // If the variantLanguage was changed or reset, we also reset the variantFormat
    if (!changedValues.variantFormat) {
      form.setFieldsValue({ variantFormat: undefined });
    }

    if (!(variantLanguage && variantFormat)) {
      setIsValidVariant(false);
      setNotAllowedLanguages(undefined);
      setNotAllowedFormats(undefined);
    } else {
      setIsValidVariant(true);
    }

    // If the language is set, we disable the format for the existing variants with the selected language
    if (variantLanguage) {
      const disabledFormats: string[] = [];
      scenario.variants.forEach((variant) => {
        if (variant.language === variantLanguage) {
          disabledFormats.push(variant.format);
        }
      });
      setNotAllowedFormats(disabledFormats);
      setNotAllowedLanguages(undefined);
    } else if (variantFormat) {
      // Else if the format is set, we disable the language for the existing variants with the selected format
      const disabledLanguages: string[] = [];
      scenario.variants.forEach((variant) => {
        if (variant.format === variantFormat) {
          disabledLanguages.push(variant.language);
        }
      });
      setNotAllowedLanguages(disabledLanguages);
      setNotAllowedFormats(undefined);
    }
  };

  const handleSubmit = (): void => {
    if ((!formRef.current && form) || !charter) {
      return;
    }

    const cancelTokenSource = cancelTokenSourceRef.current;

    form.validateFields().then((values) => {
      setIsAsyncLoading(true);

      const newVariantValues: APICreateCharterScenarioVariantParams = {
        language: values.variantLanguage,
        format: values.variantFormat,
      };

      loggingManager.logEvent(LOGGING_EVENT.CREATE_SCENARIO_VARIANT, {
        charterId: charter.id,
        companyId: company?.id,
        scenarioId: scenario.id,
        ...newVariantValues,
      });

      createCharterScenarioVariant(charter.id, scenario.id, newVariantValues, cancelTokenSource)
        .then((createdVariant) => {
          message.success(t('charters.scenarios.addNewVariantForm.variantCreationSuccess'));
          onHideModal();

          // Redirect to the editor on the main variant of the created scenario
          if (createdVariant) {
            const scenarioVariantEditorPath =
              charter &&
              LINK.CHARTER_SCENARIO_VARIANT_EDITOR.path
                .replace(':charterId', charter.id.toString())
                .replace('(\\d+)', '');

            if (!scenarioVariantEditorPath) {
              return;
            }

            history.push({
              pathname: scenarioVariantEditorPath,
              state: {
                scenarioId: scenario.id,
                scenarioVariantId: createdVariant.id,
              },
            });
          }
        })
        .catch((error) => {
          message.error(t('charters.scenarios.addNewVariantForm.variantCreationError'));
          log.error(error);
        })
        .finally(() => {
          setIsAsyncLoading(false);
        });
    });
  };

  return (
    <>
      <AccessChecker
        renderUnauthorizedMessage={permissionToAccessScenario.renderUnauthorizedMessage}
        hasAccess={hasUserAccessToScenario}
      >
        <span
          className={classNames(
            classes.addVariantButtonContainer,
            !hasUserAccessToScenario && classes.disabledAddVariantButtonContainer
          )}
          onClick={hasUserAccessToScenario ? onShowModal : undefined}
        >
          <PlusCircleOutlined className={classes.addVariantIcon} />
          {t('charters.scenarios.addVariant')}
        </span>
      </AccessChecker>

      <Modal
        visible={isVisible}
        onCancel={onHideModal}
        title={
          mainVariant
            ? t('charters.scenarios.addNewVariantForm.titleWithVariant', { mainVariantTitle: mainVariant.title })
            : t('charters.scenarios.addNewVariantForm.title')
        }
        cancelText={t('global.cancel')}
        okText={t('global.submit')}
        className={classes.modal}
        onOk={handleSubmit}
        cancelButtonProps={{
          disabled: isAsyncLoading,
        }}
        okButtonProps={{
          loading: isAsyncLoading,
          disabled: isAsyncLoading || !isValidVariant,
        }}
      >
        <Form
          form={form}
          ref={formRef}
          {...formItemLayout}
          validateMessages={{
            required: t('global.requiredField'),
          }}
          onValuesChange={onValuesChange}
          className={classes.form}
          name={`createVariant-${scenario.id}`}
        >
          {/* variantLanguage */}
          <Form.Item
            name="variantLanguage"
            label={t('charters.scenarios.addNewVariantForm.variantLanguageLabel')}
            rules={[{ required: true }]}
            extra={t('charters.scenarios.addNewVariantForm.variantLanguageExtra')}
            hasFeedback
          >
            <Select
              getPopupContainer={(trigger): HTMLElement => trigger.parentNode}
              placeholder={t('charters.scenarios.addNewVariantForm.variantLanguagePlaceholder')}
              allowClear
            >
              {map(ScenarioLanguages, (language) => {
                const isDisabled = notAllowedLanguages?.includes(language.code);
                return (
                  <Option key={language.code} value={language.code} disabled={isDisabled}>
                    <span className={classNames(isDisabled && classes.disabledOption)}>
                      {t(language.key)} {language.flag}
                    </span>
                    {isDisabled && t('charters.scenarios.addNewVariantForm.existingVariantWarning')}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          {/* variantFormat */}
          <Form.Item
            name="variantFormat"
            label={t('charters.scenarios.addNewVariantForm.variantFormatLabel')}
            rules={[{ required: true }]}
            extra={t('charters.scenarios.addNewVariantForm.variantFormatExtra')}
            hasFeedback
          >
            <Select
              getPopupContainer={(trigger): HTMLElement => trigger.parentNode}
              placeholder={t('charters.scenarios.addNewVariantForm.variantFormatPlaceholder')}
              allowClear
              disabled={!isLanguageSelected}
            >
              {map(ScenarioFormats, (format) => {
                const isDisabled = notAllowedFormats?.includes(format.code);
                return (
                  <Option key={format.code} value={format.code} disabled={isDisabled}>
                    <span className={classNames(isDisabled && classes.disabledOption)}>
                      <FormatTag format={t(format.key)} image={format.image} />
                    </span>
                    {isDisabled && t('charters.scenarios.addNewVariantForm.existingVariantWarning')}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>

        {mainVariant && (
          <Alert
            className={classes.translationDescription}
            message={t('charters.scenarios.addNewVariantForm.variantTranslationDetails', {
              mainVariantTitle: mainVariant.title,
            })}
          />
        )}
      </Modal>
    </>
  );
};

export default CreateNewScenarioVariantModal;
