import { EditFilled, InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Dropdown, Menu, message, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import KannelleRetryButton from '../../../../../components/retry-button/KannelleRetryButton';
import { LOGGING_EVENT, THEME } from '../../../../../Constants';
import { Roles } from '../../../../../core/rule/RolesTypes';
import { CHARTERS_USERS_RULE } from '../../../../../core/rule/Rules';
import { updateCurrentUserInCharterList, updateUserInCurrentCharter } from '../../../../../redux/action/ChartersAction';
import { setCompanyStats } from '../../../../../redux/action/CompaniesAction';
import { RootState } from '../../../../../redux/RootState';
import { updateCharterUserRoleByCharterId } from '../../../../../services/api/ChartersService';
import { UsersModelCharter } from '../../../../../services/api/types/ChartersServiceTypes';
import LogUtils from '../../../../../utils/LogUtils';
import KannelleRoleTag from './KannelleRoleTag';

type Props = {
  concernedUser: UsersModelCharter;
  disabled?: boolean;
};

const useStyles = createUseStyles({
  roleTagContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  editableRoleTag: {
    cursor: 'pointer',
  },
  nonEditableRoleTag: {
    cursor: 'default',
  },
  editRoleIcon: {
    fontSize: '10px',
    marginLeft: '5px',
    cursor: 'pointer',
  },
  loadingIcon: {
    fontSize: '10px',
    marginLeft: '5px',
  },
  rolesChoiceMenuHeader: {
    '&:hover': {
      cursor: 'default',
    },
  },
  dropdownMenuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .ant-dropdown-menu-title-content': {
      display: 'flex',
    },

    '& .ant-tag': {
      width: '100%',
    },
  },
  roleTooltipIcon: {
    color: THEME.DEFAULT.MAIN_TEXT_COLOR,
    float: 'right',
    marginLeft: '10px',
  },
  actionsContainer: {
    '& :not(:last-child)': {
      marginRight: '5px',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const KannelleRoleTagDropdown: FunctionComponent<Props> = ({ concernedUser, disabled = false }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const currentUser = useSelector((state: RootState) => state.user.user);
  const currentUserRole = useSelector((state: RootState) => state.user.role);
  const currentCharter = useSelector((state: RootState) => state.charters.current);
  const companyStats = useSelector((state: RootState) => state.companies.stats);
  const currentCompany = useSelector((state: RootState) => state.companies.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);
  const isCurrentUserPoc = currentCompany?.isUserPoc || false;

  const [isLoading, setIsLoading] = useState(concernedUser.updating?.role || false);
  const [selectedNewRole, setSelectedNewRole] = useState<Roles>();
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();

  const canUpdateToKnlTeam = currentUserRole?.isAllowedTo(CHARTERS_USERS_RULE.UPDATE_USERS_ROLES_TO_KNL_TEAM);
  const canUpdateToOwner =
    currentUserRole?.isAllowedTo(CHARTERS_USERS_RULE.UPDATE_USERS_ROLES_TO_OWNER) || isCurrentUserPoc;

  const updateUserRole = (newRole?: Roles): void => {
    const roleToSet = newRole || selectedNewRole;
    if (!roleToSet || concernedUser.acl.role === roleToSet || !companyStats) {
      return;
    }

    if (!companyStats.canChangeRole(concernedUser.acl.role, concernedUser.acl.isActive)) {
      message.error(t('global.maxNbLicensesReachedError', { count: companyStats?.users.max }));
      return;
    }

    const event = LogUtils.getUsersUpdateEvent(
      LOGGING_EVENT.UPDATE_USER,
      concernedUser.id,
      { role: roleToSet },
      currentCharter!.id,
      currentCompany?.id
    );
    loggingManager.logEventObject(event);

    setIsLoading(true);
    setIsError(false);
    updateCharterUserRoleByCharterId(currentCharter!.id, concernedUser.id, roleToSet)
      .then((response) => {
        if (response.data) {
          companyStats.userUpdatedFromRole(concernedUser.acl.role, roleToSet, concernedUser.acl.isActive);
          dispatch(setCompanyStats(companyStats));
          dispatch(updateUserInCurrentCharter(response.data));
          if (currentUser?.id === concernedUser.id) {
            dispatch(updateCurrentUserInCharterList(response.data, currentCharter!.id));
          }
          setIsLoading(false);
        }
      })
      .catch((error) => {
        const errorResponse = error.response;
        const errorResponseData = error.response?.data;

        if (errorResponseData && errorResponseData.data && errorResponse.status === 403) {
          message.error(<>{t('users.bundleChangeRoleError', { role: t(`roles.names.${roleToSet}`) })}</>, 3);
        } else {
          message.error(
            <>
              {t('users.updateRole.error', { count: 1 })}
              <br />
              {t('support.global.emojiFingerRight')}
              <a href={`mailto:${t('support.global.supportEmail')}`}>{t('support.global.supportEmail')}</a>
              {t('support.global.emojiFingerLeft')}
            </>
          );

          setIsError(true);
        }

        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(concernedUser.updating?.role || false);
  }, [concernedUser.updating]);

  const renderTooltipByRole = (role: Roles): JSX.Element => {
    const tooltipText = t(`roles.tooltip.${role}`);
    return (
      <Tooltip placement="right" trigger="click" title={tooltipText}>
        <InfoCircleOutlined className={classes.roleTooltipIcon} onClick={(e): void => e.stopPropagation()} />
      </Tooltip>
    );
  };

  const renderRolesChoiceMenu = (): JSX.Element => {
    const renderRoleChoiceItemByRole = (role: Roles): JSX.Element => (
      <Menu.Item
        key={`role_tag_${role}`}
        className={classes.dropdownMenuItem}
        onClick={(): void => {
          setSelectedNewRole(role);
          updateUserRole(role);
        }}
      >
        <KannelleRoleTag roleName={role} />
        {renderTooltipByRole(role)}
      </Menu.Item>
    );

    return (
      <Menu>
        <Menu.Item key="roleDropDownTitle" disabled className={classes.rolesChoiceMenuHeader}>
          {t('users.changeRole')}
        </Menu.Item>
        <Menu.Divider />
        {canUpdateToKnlTeam && renderRoleChoiceItemByRole(Roles.KnlTeam)}
        {canUpdateToOwner && renderRoleChoiceItemByRole(Roles.Owner)}
        {renderRoleChoiceItemByRole(Roles.Admin)}
        {renderRoleChoiceItemByRole(Roles.Creator)}
      </Menu>
    );
  };

  if (!concernedUser) {
    return null;
  }

  return (
    <Dropdown overlay={renderRolesChoiceMenu} disabled={disabled || isLoading} trigger={['click']}>
      <span
        className={classNames(
          classes.roleTagContainer,
          !disabled && !isLoading ? classes.editableRoleTag : classes.nonEditableRoleTag,
          'roleTagContainer'
        )}
      >
        <KannelleRoleTag roleName={concernedUser.acl.role} />

        <span className={classes.actionsContainer}>
          {!disabled && !isLoading && isError && (
            <KannelleRetryButton
              onClick={(e): void => {
                e.stopPropagation();
                updateUserRole();
              }}
            />
          )}
          {!disabled && !isLoading && <EditFilled className={classes.editRoleIcon} />}
          {!disabled && isLoading && <LoadingOutlined className={classes.loadingIcon} />}
        </span>
      </span>
    </Dropdown>
  );
};

export default KannelleRoleTagDropdown;
