import { UploadFile } from 'antd/lib/upload/interface';
import { APIFullModelCharter, APIModelCharter, Asset, AssetType } from '../services/api/types/ChartersServiceTypes';
import StringUtils from './StringUtils';
import { PermissionList } from './types/CharterPermissionTypes';

type ProcessingAssets = {
  [key: string]: UploadFile[];
};

export default class CharterUtils {
  static sortChartersByName = (charterA: APIModelCharter, charterB: APIModelCharter): number =>
    StringUtils.compareNaturalNormalized(charterA.name, charterB.name);

  static getCharterAssetsByType = (charterAssetType: AssetType, charter: APIFullModelCharter): Asset[] => {
    switch (charterAssetType) {
      case AssetType.LOGO:
        return charter.logos;
      case AssetType.INTRO:
        return charter.intros;
      case AssetType.OUTRO:
        return charter.outros;
      case AssetType.MUSIC:
        return charter.musics;
      default:
        return [];
    }
  };

  static getCharterAssetsNameByType = (
    charterAssetType: AssetType
  ): 'logos' | 'intros' | 'outros' | 'musics' | undefined => {
    switch (charterAssetType) {
      case AssetType.LOGO:
        return 'logos';
      case AssetType.INTRO:
        return 'intros';
      case AssetType.OUTRO:
        return 'outros';
      case AssetType.MUSIC:
        return 'musics';
      default:
        return undefined;
    }
  };

  static getUploadFilesByType = (charterAssetType: AssetType, processingAssets: ProcessingAssets): UploadFile[] => {
    switch (charterAssetType) {
      case AssetType.LOGO:
        return processingAssets.logo || [];
      case AssetType.INTRO:
        return processingAssets.intro || [];
      case AssetType.OUTRO:
        return processingAssets.outro || [];
      case AssetType.MUSIC:
        return processingAssets.music || [];
      default:
        return [];
    }
  };

  static getCharterByIdFromList = (chartersList: APIModelCharter[], charterId: number): APIModelCharter | undefined => {
    return chartersList.find((charter) => charter.id === charterId);
  };

  static hasAccessibleCharter = (charters: APIModelCharter[]): boolean => {
    const hasAccessToAtLeastOneCharter = charters.some((charter) => {
      const hasAccessToCharterPermission = charter.currentUser.permissions.find(
        (permission) => permission.code === PermissionList.WEB_DASHBOARD && permission.access === true
      );
      return hasAccessToCharterPermission !== undefined;
    });
    return hasAccessToAtLeastOneCharter !== undefined ? hasAccessToAtLeastOneCharter : false;
  };
}
