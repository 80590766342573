import { DeleteOutlined } from '@ant-design/icons';
import { CheckCircleFilled } from '@ant-design/icons/lib';
import { Button, Card, message, Popconfirm, Tooltip } from 'antd';
import { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import log from 'loglevel';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { THEME } from '../../Constants';
import { APIManager } from '../../services/api/APIManager';
import { Asset, MediaFile } from '../../services/api/types/ChartersServiceTypes';
import StringUtils from '../../utils/StringUtils';
import KannelleSwitch from '../switch/KannelleSwitch';

type Props = {
  imageFile: Asset | MediaFile;
  callbackOnIsActiveChange?: (isActive: boolean) => void;
  selected?: boolean;
  disabled?: boolean;
  assetType?: string;
  trigger?: 'switch' | 'button';
  callbackOnSelect?: () => void;
  handleIsActiveChange?: (
    isActive: boolean,
    imageFile: Asset | MediaFile,
    cancelTokenSource?: CancelTokenSource
  ) => Promise<Asset | MediaFile>;
  handleArchive?: (imageFile: Asset | MediaFile, cancelTokenSource?: CancelTokenSource) => Promise<Asset | MediaFile>;
};

type StyleProps = {
  hasArchiveButton: boolean;
};

const useStyles = createUseStyles({
  card: {
    boxShadow: '0 8px 8px 0 hsla(0, 0%, 0%, 0.15) !important',
    overflow: 'hidden',

    display: 'flex',
    flexDirection: 'column',

    height: '100%',
    wordBreak: 'break-word',
    width: '100%',

    '& .ant-card-body': {
      '& p': {
        flexGrow: 1,
      },
      padding: '8px 8px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
  },
  imageFileCoverContainer: {
    padding: 8,
    display: 'flex',
    height: 200,
    alignItems: 'center',
    justifyContent: 'center',

    backgroundColor: '#EDEDED',

    '& img': {
      maxHeight: '100%',
      maxWidth: '100%',
    },
  },
  fileNameContainer: ({ hasArchiveButton }: StyleProps) => ({
    marginBottom: hasArchiveButton ? '16px' : 'unset',
  }),
  ellipsisP: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  pNoWrap: {
    whiteSpace: 'nowrap',
  },
  selectedImageFile: {
    position: 'absolute',
    top: '2px',
    right: '4px',
    color: THEME.DEFAULT.MAIN_TEXT_COLOR,
  },
  selectButton: {
    margin: 'auto',
    width: 'fit-content',
  },
  selectedCardButton: {
    '&, &:active, &:focus': {
      color: THEME.DEFAULT.MAIN_TEXT_COLOR,
      background: THEME.DEFAULT.FADE_COLOR,
      borderColor: THEME.DEFAULT.MAIN_TEXT_COLOR,
    },
    '&:hover': {
      color: THEME.DEFAULT.HOVERED_LINK,
      borderColor: THEME.DEFAULT.HOVERED_LINK,
    },
  },
  archiveButtonContainer: {
    position: 'absolute',
    right: '8px',
    bottom: '8px',
  },
});

const ImageFileCard: FunctionComponent<Props> = ({
  handleIsActiveChange,
  handleArchive,
  imageFile,
  trigger,
  callbackOnIsActiveChange,
  callbackOnSelect,
  assetType,
  selected = false,
  disabled = false,
}: Props) => {
  const classes = useStyles({ hasArchiveButton: handleArchive !== undefined });
  const [loading, setLoading] = useState(false);
  const [isArchivedLoading, setIsArchivedLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const [cancelToken, setCancelToken] = useState<CancelTokenSource>();
  const myNodeRef = useCallback((node: HTMLParagraphElement) => {
    if (node) {
      setIsEllipsisActive(node.offsetWidth < node.scrollWidth);
    }
  }, []);
  const { t } = useTranslation();
  const fileName = imageFile.publicName ?? StringUtils.getFileNameFromUrl(imageFile.url);

  useEffect(() => {
    if (!cancelToken) {
      return undefined;
    }

    return (): void => {
      cancelToken.cancel('Update cancelled due to unmounting');
    };
  }, [cancelToken]);

  const handleChange = (isActive: boolean): void => {
    const cancelTokenSource = APIManager.getCancelToken();

    if (!handleIsActiveChange) {
      return;
    }

    setCancelToken(cancelToken);
    setLoading(true);
    setError(false);

    handleIsActiveChange(isActive, imageFile, cancelTokenSource)
      .then(() => {
        setLoading(false);
        if (callbackOnIsActiveChange) {
          callbackOnIsActiveChange(isActive);
        }
      })
      .catch((patchError) => {
        const errorResponse = patchError.response;
        if (errorResponse.status === 403) {
          message.error(t(`charters.${assetType}.bundleLimitExceedIsActive`), 3);
        }

        log.error(patchError);
        setError(true);
        setLoading(false);
      });
  };

  const onArchive = (): void => {
    const cancelTokenSource = APIManager.getCancelToken();
    setCancelToken(cancelToken);
    setIsArchivedLoading(true);

    if (!handleArchive) {
      return;
    }

    handleArchive(imageFile, cancelTokenSource)
      .then(() => {
        message.success(t('charters.archive.successfullyArchived'));
      })
      .catch((patchError) => {
        message.error(t('charters.archive.archiveError'));
        log.error(patchError.response);
      })
      .finally(() => {
        setIsArchivedLoading(false);
      });
  };

  return (
    <Card
      className={classNames(classes.card)}
      cover={
        <div className={classes.imageFileCoverContainer}>
          <img alt="example" src={imageFile.url} />
        </div>
      }
    >
      {trigger === 'switch' && selected && (
        <div className={classes.selectedImageFile}>
          <CheckCircleFilled />
        </div>
      )}
      <div className={classes.fileNameContainer}>
        {isEllipsisActive ? (
          <Tooltip title={fileName}>
            <p ref={myNodeRef} className={classes.ellipsisP}>
              {fileName}
            </p>
          </Tooltip>
        ) : (
          <p className={classes.pNoWrap} ref={myNodeRef}>
            {fileName}
          </p>
        )}
      </div>

      {trigger === 'switch' && imageFile.isActive !== undefined && (
        <KannelleSwitch
          isChecked={imageFile.isActive}
          size="small"
          callbackChange={handleChange}
          loading={loading}
          error={error}
          labelActivated="form.activated"
          labelDeactivated="form.deactivated"
        />
      )}

      {trigger === 'button' && callbackOnSelect && (
        <Button
          shape="round"
          className={classNames(classes.selectButton, selected && classes.selectedCardButton)}
          disabled={disabled}
          onClick={callbackOnSelect}
          icon={selected ? <CheckCircleFilled /> : undefined}
        >
          {selected ? t('global.unselect') : t('global.select')}
        </Button>
      )}

      {handleArchive !== undefined && (
        <div className={classes.archiveButtonContainer}>
          <Popconfirm
            title={t('charters.archive.archiveConfirm')}
            onConfirm={onArchive}
            okText={t('global.yes')}
            cancelText={t('global.no')}
            okButtonProps={{ loading: isArchivedLoading, disabled: isArchivedLoading, danger: true }}
            disabled={isArchivedLoading}
          >
            <Button
              type="primary"
              danger
              shape="circle"
              icon={<DeleteOutlined />}
              size="small"
              loading={isArchivedLoading}
            />
          </Popconfirm>
        </div>
      )}
    </Card>
  );
};

export default ImageFileCard;
