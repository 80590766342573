import { Col, Form, Row } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPaintBrush } from 'react-icons/fa';
import { IoMusicalNotes } from 'react-icons/io5';
import { MdFeaturedVideo } from 'react-icons/md';
import { RiVideoDownloadFill, RiVideoUploadFill } from 'react-icons/ri';
import { VscSettings } from 'react-icons/vsc';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import AssetSelector from '../../../../../components/asset-selector/AssetSelector';
import { THEME } from '../../../../../Constants';
import useCanAccess from '../../../../../hooks/useCanAccess';
import { RootState } from '../../../../../redux/RootState';
import { Asset, AssetType } from '../../../../../services/api/types/ChartersServiceTypes';
import ProjectUtils from '../../../../../utils/ProjectUtils';
import { PermissionList } from '../../../../../utils/types/CharterPermissionTypes';
import {
  Project,
  ProjectLogo,
  ProjectMusicDuckingVolume,
  ProjectMusicFadeOutDuration,
  ProjectMusicFile,
  ProjectThemeParameters,
  ProjectVolumeParameters,
} from '../../../../../utils/types/ProjectTypes';
import ProjectFinalRenderPlayer from './project-final-render-player/ProjectFinalRenderPlayer';
import ProjectLogoSettings from './project-logo-settings/ProjectLogoSettings';
import ProjectMusicSettings from './project-music-settings/ProjectMusicSettings';
import ProjectThemeSettings from './project-theme-settings/ProjectThemeSettings';
import ProjectVolumeSettings from './project-volume-settings/ProjectVolumeSettings';

type Props = {
  project: Project;
  onEditProject: (newProject: Project) => void;
};

const useStyles = createUseStyles({
  finalRenderPlayerContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  formContainer: {
    marginLeft: 16,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  form: {
    '& .ant-form-item-label': {
      padding: 0,
    },
    '& .ant-form-item': {
      marginBottom: 10,
    },
  },
  mainContainer: {
    marginTop: 24,
  },
  labelIconContainer: {
    background: THEME.DEFAULT.MAIN_COLOR,
    opacity: 0.8,
    color: 'white',
    width: 20,
    height: 20,
    fontSize: 10,
    lineHeight: 20,
    textAlign: 'center',
    borderRadius: 20,
    marginRight: 6,
    position: 'relative',
  },
  labelIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 12,
  },
});

const ProjectFinalization: FunctionComponent<Props> = ({ project, onEditProject }: Props) => {
  const [form] = Form.useForm();
  const currentCharter = useSelector((state: RootState) => state.charters.current);

  const [resetFinalRenderPlayer, setResetFinalRenderPlayer] = useState(false);

  const classes = useStyles();
  const { t } = useTranslation();

  const leftColumnLayoutProps = { md: 16, lg: 16 };
  const rightColumnLayoutProps = { md: 24 - leftColumnLayoutProps.md, lg: 24 - leftColumnLayoutProps.lg };

  const permissionToEditIntro = useCanAccess(PermissionList.INTRO);
  const permissionToEditOutro = useCanAccess(PermissionList.OUTRO);
  const permissionToEditLogo = useCanAccess(PermissionList.LOGO);
  const permissionToDisplayLogoOnSlides = useCanAccess(PermissionList.LOGO_INCLUDE_SLIDES);
  const permissionToEditLogoPosition = useCanAccess(PermissionList.LOGO_POSITION);
  const permissionToEditLogoSize = useCanAccess(PermissionList.LOGO_SIZE);

  // The form is controlled by the project values
  useEffect(() => {
    if (!currentCharter) {
      return;
    }

    form.setFieldsValue({
      logo: ProjectUtils.getPermissionForcedLogo(
        currentCharter,
        project,
        permissionToEditLogo,
        permissionToEditLogoSize,
        permissionToEditLogoPosition,
        permissionToDisplayLogoOnSlides
      ),
      intro: ProjectUtils.getPermissionForcedIntro(currentCharter, project, permissionToEditIntro),
      outro: ProjectUtils.getPermissionForcedOutro(currentCharter, project, permissionToEditOutro),
      music: project.music?.file,
      volume: {
        audioVolume: project.audioVolume,
        audioProcessingEnabled: project.audioProcessingEnabled,
        backgroundMusicVolume: project.music?.volume,
        musicDuckingVolume: project.music?.duckingVolume,
        musicFadeOutDuration: project.music?.fadeOutDuration,
      },
      theme: {
        theme: project.theme,
        mask: project?.mask,
        customColors: project.customColors,
      },
    });
  }, [
    project,
    permissionToEditIntro,
    permissionToEditOutro,
    permissionToEditLogo,
    permissionToEditLogoSize,
    permissionToEditLogoPosition,
    permissionToDisplayLogoOnSlides,
  ]);

  const onLogoSettingsChange = (newLogo?: ProjectLogo): void => {
    const newProject = {
      ...project,
      logo: newLogo,
    };

    onEditProject(newProject);
  };

  const onIntroSettingsChange = (newIntro?: Asset): void => {
    const newProject = {
      ...project,
      intro: newIntro,
    };

    onEditProject(newProject);
  };

  const onOutroSettingsChange = (newOutro?: Asset): void => {
    const newProject = {
      ...project,
      outro: newOutro,
    };

    onEditProject(newProject);
  };

  const onMusicSettingsChange = (newMusic?: ProjectMusicFile): void => {
    if (!newMusic) {
      return;
    }

    const newProject = {
      ...project,
      music: {
        ...project.music,
        file: newMusic,
      },
    };
    onEditProject(newProject);
  };

  const onVolumeSettingsChange = (volumeParameters?: ProjectVolumeParameters): void => {
    if (!volumeParameters) {
      return;
    }

    const newProject = {
      ...project,
      audioVolume: ProjectUtils.optimizeVolumePrecisionValue(volumeParameters.audioVolume), // Volume for the scenes
      audioProcessingEnabled: volumeParameters.audioProcessingEnabled, // Equalize scenes volumes
      ...(project.music &&
        volumeParameters.backgroundMusicVolume !== undefined && {
          music: {
            ...project.music,
            volume: ProjectUtils.optimizeVolumePrecisionValue(volumeParameters.backgroundMusicVolume), // Volume for the background music
            duckingEnabled: !!volumeParameters.musicDuckingVolume,
            duckingVolume: volumeParameters.musicDuckingVolume ?? ProjectMusicDuckingVolume.NONE, // Music ducking volume on slides
            fadeOutEnabled: !!volumeParameters.musicFadeOutDuration,
            fadeOutDuration: volumeParameters.musicFadeOutDuration ?? ProjectMusicFadeOutDuration.NONE, // Music fade out duration
          },
        }),
    };
    onEditProject(newProject);
  };

  const onThemeSettingsChange = (themeParameters?: ProjectThemeParameters): void => {
    if (!(themeParameters && project.scenes)) {
      return;
    }

    // There exists specific rules to be applied on scenes for some precise themes.
    // So in case the theme settings changed, we ensure to apply those rules and correctly transform the scenes.
    const scenes = ProjectUtils.transformProjectScenesBasedOnThemeSpecificRules(project.scenes, themeParameters.theme);

    const newProject = {
      ...project,
      customColors: themeParameters.customColors,
      theme: themeParameters.theme,
      mask: themeParameters.mask,
      scenes,
    };

    onEditProject(newProject);
  };

  // Whenever the finalization options change, we ensure to reset the final render player
  const onValuesChange = () => {
    setResetFinalRenderPlayer(true);
  };

  const renderFinalizationOptions = () => {
    return (
      <div className={classes.formContainer}>
        <Form form={form} className={classes.form} layout="vertical" size="small" onValuesChange={onValuesChange}>
          <Form.Item
            name="logo"
            label={
              <>
                <span className={classes.labelIconContainer}>
                  <MdFeaturedVideo className={classes.labelIcon} />
                </span>
                {t('charters.projects.projectEditor.projectFinalization.logo.label')}
              </>
            }
          >
            <ProjectLogoSettings
              fieldLabel={t('charters.projects.projectEditor.projectFinalization.logo.label')}
              onChange={onLogoSettingsChange}
            />
          </Form.Item>

          <Form.Item
            name="intro"
            label={
              <>
                <span className={classes.labelIconContainer}>
                  <RiVideoUploadFill className={classes.labelIcon} />
                </span>
                {t('charters.projects.projectEditor.projectFinalization.addIntro')}
              </>
            }
          >
            <AssetSelector
              fieldLabel={t('charters.projects.projectEditor.projectFinalization.addIntro')}
              assetType={AssetType.INTRO}
              onChange={onIntroSettingsChange}
            />
          </Form.Item>

          <Form.Item
            name="outro"
            label={
              <>
                <span className={classes.labelIconContainer}>
                  <RiVideoDownloadFill className={classes.labelIcon} />
                </span>
                {t('charters.projects.projectEditor.projectFinalization.addOutro')}
              </>
            }
          >
            <AssetSelector
              fieldLabel={t('charters.projects.projectEditor.projectFinalization.addOutro')}
              assetType={AssetType.OUTRO}
              onChange={onOutroSettingsChange}
            />
          </Form.Item>

          {/* music */}
          <Form.Item
            name="music"
            label={
              <>
                <span className={classes.labelIconContainer}>
                  <IoMusicalNotes className={classes.labelIcon} />
                </span>
                {t('charters.projects.projectEditor.projectFinalization.music.label')}
              </>
            }
          >
            <ProjectMusicSettings
              fieldLabel={t('charters.projects.projectEditor.projectFinalization.music.label')}
              onChange={onMusicSettingsChange}
            />
          </Form.Item>

          {/* volume */}
          <Form.Item
            name="volume"
            label={
              <>
                <span className={classes.labelIconContainer}>
                  <VscSettings className={classes.labelIcon} />
                </span>
                {t('charters.projects.projectEditor.projectFinalization.volume.label')}
              </>
            }
          >
            <ProjectVolumeSettings
              fieldLabel={t('charters.projects.projectEditor.projectFinalization.volume.label')}
              onChange={onVolumeSettingsChange}
            />
          </Form.Item>

          {/* theme */}
          <Form.Item
            name="theme"
            label={
              <>
                <span className={classes.labelIconContainer}>
                  <FaPaintBrush className={classes.labelIcon} />
                </span>
                {t('charters.projects.projectEditor.projectFinalization.graphicalTheme.label')}
              </>
            }
          >
            <ProjectThemeSettings
              fieldLabel={t('charters.projects.projectEditor.projectFinalization.graphicalTheme.label')}
              onChange={onThemeSettingsChange}
            />
          </Form.Item>
        </Form>
      </div>
    );
  };

  const renderFinalRenderPlayer = () => {
    return (
      <div className={classes.finalRenderPlayerContainer}>
        <ProjectFinalRenderPlayer
          project={project}
          reset={resetFinalRenderPlayer}
          onReset={() => setResetFinalRenderPlayer(false)}
        />
      </div>
    );
  };

  return (
    <div className={classes.mainContainer}>
      <Row>
        <Col {...leftColumnLayoutProps}>{renderFinalRenderPlayer()}</Col>
        <Col {...rightColumnLayoutProps}>{renderFinalizationOptions()}</Col>
      </Row>
    </div>
  );
};

export default ProjectFinalization;
