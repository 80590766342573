import { MailOutlined } from '@ant-design/icons/lib';
import { Form, Input } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { VALIDATE_TRIGGER_VALUE } from '../../types/AntType';
import StringUtils from '../../utils/StringUtils';

type Props = {
  name: string;
  label?: string;
  placeholder?: string;
  validateTrigger?: VALIDATE_TRIGGER_VALUE;
  isReadOnly?: boolean;
  validateEmailFreeTrial?: boolean;
  hasFeedback?: boolean;
  size?: SizeType;
};

const InputEmail: FunctionComponent<Props> = ({
  name,
  label = '',
  placeholder,
  isReadOnly = false,
  validateEmailFreeTrial = false,
  validateTrigger = VALIDATE_TRIGGER_VALUE.ON_CHANGE,
  hasFeedback = true,
  size = 'middle',
}: Props) => {
  const { t } = useTranslation();

  const validateEmail = (val: string): boolean => {
    const isEmail = StringUtils.checkIsEmail(val);
    if (val === '') {
      return false;
    }

    return isEmail;
  };

  return (
    <Form.Item
      label={label}
      name={name}
      validateTrigger={validateTrigger}
      rules={[
        { required: true, message: t('form.emailRuleRequiredError') },
        { whitespace: true, message: t('form.emailRuleEmptyRequiredError') },
        {
          validator: (rule, value): Promise<void> => {
            if (validateEmail(value)) {
              if (validateEmailFreeTrial) {
                if (StringUtils.checkIsEmailAuthorized(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(t('form.emailNotAuthorized'));
              }

              return Promise.resolve();
            }

            return Promise.reject(t('form.emailRuleTypeError'));
          },
        },
      ]}
      hasFeedback={hasFeedback}
      required
      normalize={(v): string => v?.toLowerCase()}
    >
      <Input
        prefix={<MailOutlined className="site-form-item-icon" />}
        placeholder={placeholder}
        disabled={isReadOnly}
        size={size}
      />
    </Form.Item>
  );
};

export default InputEmail;
