import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactJoyride, { CallBackProps, EVENTS, STATUS } from 'react-joyride';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/RootState';
import JoyRideUtils from '../utils/JoyRideUtils';
import KannelleJoyRideTooltip from './KannelleJoyRideTooltip';

type Props = {
  runHelp: boolean;
  callbackRunDone: () => void;
};

const JoyRideHelpPermissions: FunctionComponent<Props> = ({ runHelp, callbackRunDone }: Props) => {
  const { t } = useTranslation();
  const isMobileOrTablet = useSelector((state: RootState) => {
    return state.app.isMobileOrTablet;
  });
  const [run, setRun] = useState(false);

  useEffect(() => {
    setRun(runHelp);
  }, [runHelp]);

  const getMobileScrollLeft = (index: number): number => {
    switch (index) {
      case 2:
        return 425;
      case 3:
        return 675;
      case 4:
        return 900;
      default:
        return 0;
    }
  };

  const scrollToTop = (element: Element) => {
    element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  };

  const handleJoyrideCallback = (data: CallBackProps): void => {
    const { status, lifecycle, type, index } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status) && lifecycle === 'complete') {
      setRun(false);
      document.getElementsByClassName('ant-table-content')[0].scrollLeft = 0;
      callbackRunDone();
    }

    if (type === EVENTS.STEP_BEFORE) {
      scrollToTop(document.getElementsByClassName('ant-table-content')[0]);
      if (index > 1 && index < 5) {
        if (!isMobileOrTablet) {
          document.getElementsByClassName('ant-table-content')[0].scrollLeft = index * 140;
        } else {
          document.getElementsByClassName('ant-table-content')[0].scrollLeft = getMobileScrollLeft(index);
        }
      } else {
        document.getElementsByClassName('ant-table-content')[0].scrollLeft = 0;
      }
    }
  };
  const steps = JoyRideUtils.getPermissionsSteps(t);

  return (
    <>
      <ReactJoyride
        disableScrolling
        continuous
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        tooltipComponent={KannelleJoyRideTooltip}
        steps={steps}
        callback={handleJoyrideCallback}
        locale={{
          back: t('onBoarding.steps.back'),
          close: t('onBoarding.steps.close'),
          last: t('onBoarding.steps.last'),
          next: t('onBoarding.steps.next'),
          open: t('onBoarding.steps.open'),
          skip: t('onBoarding.steps.skip'),
        }}
      />
    </>
  );
};

export default JoyRideHelpPermissions;
