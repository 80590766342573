import React, { FunctionComponent } from 'react';
import { CompanyBundleFeatureList } from '../../../../Constants';
import { AssetType } from '../../../../services/api/types/ChartersServiceTypes';
import GenericCharterAssets from './manager/GenericCharterAssets';

const CharterOutros: FunctionComponent = () => {
  return (
    <GenericCharterAssets
      acceptFileType="video/mpeg,video/3gpp,video/mp4,video/quicktime"
      assetType={AssetType.OUTRO}
      sizeLimit={20}
      bundleFeatureLimit={CompanyBundleFeatureList.MAX_OUTRO_PER_CHARTER}
    />
  );
};

export default CharterOutros;
