import { Layout } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import FreeTrialBanner from '../../components/banner/FreeTrialBanner';
import KannelleHeader from '../../components/header/KannelleHeader';
import KannelleSider from '../../components/sider/KannelleSider';
import JoyRideOnBoarding from '../../onboarding/JoyRideOnBoarding';
import { RootState } from '../../redux/RootState';

const { Content } = Layout;

const useStyles = createUseStyles({
  contentLayout: {
    position: 'absolute',
    top: 64,
    minHeight: 'calc(100% - 64px)',
  },
  contentLayoutMobileOrCollapsedMenu: {
    left: 0,
    width: '100% !important',
  },
  contentLayoutLargeWithMenu: {
    left: 200,
    width: 'calc(100% - 200px)!important',
  },
  content: ({ isMobileOrTablet, isFreeTrialBannerDisplayed }) => ({
    margin: isFreeTrialBannerDisplayed && isMobileOrTablet ? '40px 16px 70px' : '16px 16px 70px',
    minHeight: 'unset',
    height: '100%',
  }),
});

type Props = {
  children: JSX.Element | JSX.Element[];
};

const Shell: FunctionComponent<Props> = ({ children }: Props) => {
  const menuCollapsed = useSelector((state: RootState) => state.app.menuCollapsed);
  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);
  const isFreeTrialBannerDisplayed = useSelector((state: RootState) => state.app.isFreeTrialBannerDisplayed);

  const classes = useStyles({ isMobileOrTablet, isFreeTrialBannerDisplayed });

  return (
    <Layout>
      <JoyRideOnBoarding />
      <KannelleHeader />
      <Layout>
        <KannelleSider />
        <Layout
          className={classNames(
            classes.contentLayout,
            menuCollapsed || isMobileOrTablet
              ? classes.contentLayoutMobileOrCollapsedMenu
              : classes.contentLayoutLargeWithMenu
          )}
        >
          <Content className={classes.content}>
            {children}
            <FreeTrialBanner />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Shell;
