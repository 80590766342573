import React, { FunctionComponent } from 'react';
import { createUseStyles } from 'react-jss';
import { VideoTrim, VideoTrimUtils } from '../utils/VideoTrimUtils';

type Props = {
  scrollValueOnX: number;
  mousePositionOnXWithScroll: number;
  intervalTickWidth: number;
  onCurrentTimeChange: (e: number) => void;
  videoTrimValue: VideoTrim;
  pipTrimValue: VideoTrim;
};

type StyleProps = {
  scrollValueOnX: number;
  mousePositionOnXWithScroll: number;
  duration: number;
  intervalTickWidth: number;
  globalMargin: number;
  videoTrimValue: VideoTrim;
  isGreaterThanMinPosition: boolean;
  isLowerThanMaxPosition: boolean;
};

const useStyles = createUseStyles({
  seekBarHover: ({
    scrollValueOnX,
    mousePositionOnXWithScroll,
    isGreaterThanMinPosition,
    isLowerThanMaxPosition,
  }: StyleProps) => {
    return {
      cursor: isGreaterThanMinPosition && isLowerThanMaxPosition ? 'default' : 'not-allowed',
      position: 'absolute',
      height: 290,
      top: 70,
      left: mousePositionOnXWithScroll - scrollValueOnX,
      width: 2,
      background: 'grey',
      zIndex: 6,
      opacity: 0.5,
      '&::before': {
        content: '""',
        width: 13,
        height: 11,
        background: 'grey',
        position: 'absolute',
        left: -6,
        borderRadius: 4,
        top: -8,
      },
      '&::after': {
        content: '""',
        width: 0,
        height: 0,
        borderLeft: '7px solid transparent',
        borderRight: '6px solid transparent',
        borderTop: '14px solid grey',
        position: 'absolute',
        top: 0,
        left: -6,
      },
    };
  },
});

const SeekBarHover: FunctionComponent<Props> = ({
  scrollValueOnX,
  mousePositionOnXWithScroll,
  intervalTickWidth,
  onCurrentTimeChange,
  videoTrimValue,
  pipTrimValue,
}: Props) => {
  // Page margin: 24 px
  // Page padding: 24 px
  // seekbarContainerMarginLeft: 20 + PROJECTS.TRIM.ANCHOR_WIDTH = 34px,
  // ==> total 82 px
  const globalMargin = 82;

  const trimStart = VideoTrimUtils.getMinTrimStartForPosition(videoTrimValue, pipTrimValue);
  const trimStartDifference = Math.max(
    0,
    pipTrimValue.trimStart - (pipTrimValue?.delay || 0) > videoTrimValue.trimStart
      ? pipTrimValue.trimStart - videoTrimValue.trimStart
      : 0
  );
  const trimEnd =
    pipTrimValue.trimEnd === 0
      ? videoTrimValue.trimEnd
      : VideoTrimUtils.getTrimEndForSeekbarHover(videoTrimValue, pipTrimValue) + trimStartDifference;

  const isGreaterThanMinPosition = mousePositionOnXWithScroll > globalMargin + trimStart * intervalTickWidth;
  // -2 px because of the seekbar width
  const isLowerThanMaxPosition = (mousePositionOnXWithScroll - globalMargin - 2) / intervalTickWidth <= trimEnd;

  const classes = useStyles({
    mousePositionOnXWithScroll,
    scrollValueOnX,
    isGreaterThanMinPosition,
    isLowerThanMaxPosition,
  });

  const onClickHandle = () => {
    // Center of the seekbarWidth = 1px
    const newTime = (mousePositionOnXWithScroll - globalMargin - 1) / intervalTickWidth - trimStartDifference;

    // Forced to do this check because of the behavior of the colorFrameCentralElement in SceneVideoTrimBar based on the isSelected value
    if (isGreaterThanMinPosition && isLowerThanMaxPosition) {
      if (newTime > trimEnd) {
        onCurrentTimeChange(trimEnd);
      } else if (newTime < trimStart - trimStartDifference) {
        onCurrentTimeChange(trimStart);
      } else {
        onCurrentTimeChange(newTime);
      }
    }
  };

  return <div className={classes.seekBarHover} onClick={onClickHandle} />;
};

export default SeekBarHover;
