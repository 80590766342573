import classNames from 'classnames';
import { findIndex } from 'lodash';
import range from 'lodash/range';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { PROJECTS } from '../../../../../../../Constants';
import TimeUtils from '../../../../../../../utils/TimeUtils';
import { VideoTrim, VideoTrimUtils } from '../utils/VideoTrimUtils';

type Props = {
  maxSeconds: number;
  intervalTickWidth: number;
  videoTrimValue: VideoTrim;
  pipTrimValue: VideoTrim;
};

const useStyles = createUseStyles({
  timeIntervalsContainer: {
    position: 'relative',
  },
  timeTicksContainer: {
    position: 'relative',
    marginTop: 4,
  },
  timeInterval: {
    width: PROJECTS.DEFAULT_INTERVAL_TICK_WIDTH,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  timeTick: {
    width: PROJECTS.DEFAULT_INTERVAL_TICK_WIDTH,
    position: 'absolute',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: 10,
    top: 20,
    overflow: 'hidden',
  },
  multipleOf5Tick: {
    height: '16px !important',
    fontWeight: 'bold',
  },
  tenthOfSecondTick: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: 6,
    top: 20,
    overflow: 'hidden',
    opacity: 0.4,
  },
});

const VideoGraduatedTimeRange: FunctionComponent<Props> = ({
  maxSeconds,
  videoTrimValue,
  pipTrimValue,
  intervalTickWidth,
}: Props) => {
  const classes = useStyles();
  const [consolidatedTrimValue, setConsolidatedTrimValue] = useState<VideoTrim>(videoTrimValue);

  const displayTenthOfSeconds = intervalTickWidth >= PROJECTS.TENTH_OF_SECONDS_VISIBILITY_MIN_TICK_WIDTH;
  const closestMultipleOf5 = (x: number) => Math.ceil(x / 5) * 5;

  useEffect(() => {
    const trimStart = VideoTrimUtils.getMinTrimStartForPosition(videoTrimValue, pipTrimValue);
    setConsolidatedTrimValue({
      trimStart,
      trimEnd: videoTrimValue.trimEnd,
    });
  }, [videoTrimValue, pipTrimValue]);

  const calculateSecondIntervals = () => {
    const startRange = Math.floor(-consolidatedTrimValue.trimStart);

    let firstRange: number[] = [];
    if (startRange < 0) {
      firstRange = range(closestMultipleOf5(startRange), 0, 5);
    }

    const rangeInterval: number[] = range(0, closestMultipleOf5(maxSeconds) + 1, 5);

    return [...firstRange, ...rangeInterval];
  };

  const calculateSecondTicks = () => {
    const startRange = -consolidatedTrimValue.trimStart;
    let firstRange: number[] = [];
    if (startRange < 0) {
      firstRange = range(Math.floor(startRange), 0);
    }

    const rangeInterval: number[] = range(0, closestMultipleOf5(maxSeconds) + 1);

    return [...firstRange, ...rangeInterval];
  };

  const secondTicks = calculateSecondTicks();
  const secondIntervals = calculateSecondIntervals();

  const renderTenthOfSeconds = (secondIndex: number) => {
    if (!displayTenthOfSeconds) {
      return null;
    }

    const tenthOfSecond = range(1, 10);
    return tenthOfSecond.map((tenth, tenthIndex) => {
      // Compute the absolute position of the tenthOfSecond tick
      const left =
        (secondIndex + (tenthIndex + 1) / 10) * intervalTickWidth + PROJECTS.DEFAULT_INTERVAL_TICK_WIDTH / 2 - 2;
      return (
        <span
          className={classes.tenthOfSecondTick}
          style={{
            left,
          }}
          key={`${secondIndex}_tenth_${tenth}`}
        >
          |
        </span>
      );
    });
  };

  return (
    <div>
      <div className={classes.timeIntervalsContainer}>
        {secondIntervals.map((interval: number) => {
          const formattedTime = TimeUtils.formatSecondsIntoDuration(interval);
          const tick = findIndex(secondTicks, (t) => t === interval);

          return (
            <span key={interval} className={classes.timeInterval} style={{ left: tick * intervalTickWidth }}>
              {formattedTime}
            </span>
          );
        })}
      </div>
      <div className={classes.timeTicksContainer}>
        {secondTicks.map((tick: number, index) => {
          return (
            <React.Fragment key={tick}>
              <span
                className={classNames(classes.timeTick, tick % 5 === 0 && classes.multipleOf5Tick)}
                style={{ left: index * intervalTickWidth }}
              >
                |
              </span>

              {displayTenthOfSeconds && renderTenthOfSeconds(index)}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default VideoGraduatedTimeRange;
