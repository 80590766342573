import { EyeFilled } from '@ant-design/icons';
import { Button } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import MediaSelector from '../../../../../../components/media-selector/MediaSelector';
import { PROJECTS } from '../../../../../../Constants';
import { MediaFile } from '../../../../../../services/api/types/ChartersServiceTypes';
import ProjectUtils from '../../../../../../utils/ProjectUtils';
import { PipEffects } from '../../../../../../utils/types/AnimationTypes';
import { MediaType } from '../../../../../../utils/types/MediaTypes';
import { ProjectScene } from '../../../../../../utils/types/ProjectTypes';

type Props = {
  scene: ProjectScene;
  format?: string;
  onSubmitScene: (updatedScene: ProjectScene) => void;
};

const useStyles = createUseStyles({
  inactiveOptions: {
    height: '95%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const ProjectSceneInactiveOptions: FunctionComponent<Props> = ({ scene, format, onSubmitScene }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const isSlideScene = ProjectUtils.isSlideScene(scene);

  const onUseSlideScene = () => {
    const newScene = {
      ...scene,
      isActive: true,
    };

    onSubmitScene(newScene);
  };

  const onMediaSelection = (newMedia?: MediaFile) => {
    if (!newMedia) {
      return;
    }

    const isVideo = newMedia?.mediaType === MediaType.VIDEO;

    const newScene = {
      ...scene,
      isActive: true,
      ...(isVideo && {
        pip: undefined,
        backgroundVideo: {
          ...scene.backgroundVideo,
          media: newMedia,
          audioVolume: scene.backgroundVideo?.audioVolume ?? 1,
        },
      }),
      ...(!isVideo && {
        backgroundVideo: undefined,
        pip: {
          ...scene.pip,
          media: newMedia,
          duration: scene.pip?.duration ?? PROJECTS.PIP.PIP_ONLY_DEFAULT_DURATION,
          audioVolume: scene.pip?.audioVolume ?? 0,
          effectName: scene.pip?.effectName ?? PipEffects.ZOOMIN.code,
          effectValue: scene.pip?.effectValue ?? PipEffects.ZOOMIN.allowedValues?.soft.value,
        },
      }),
    };

    onSubmitScene(newScene);
  };

  const renderUseThisSceneButton = () => {
    return (
      <Button
        type="primary"
        shape="round"
        icon={<EyeFilled />}
        size="large"
        onClick={isSlideScene ? onUseSlideScene : undefined}
        className="useThisSceneButton"
      >
        {isSlideScene
          ? t('charters.projects.projectEditor.sceneEditor.inactive.useThisSlideSceneButton')
          : t('charters.projects.projectEditor.sceneEditor.inactive.useThisRecordableSceneButton')}
      </Button>
    );
  };

  return (
    <div className={classes.inactiveOptions}>
      {isSlideScene ? (
        renderUseThisSceneButton()
      ) : (
        <MediaSelector
          fieldLabel={t('charters.projects.projectEditor.sceneEditor.inactive.mediaSelectionTitle')}
          format={format}
          enableWebcamRecording
          webcamRecordingOptions={{
            recommendedTime: scene.videoRecommendedTime,
            overlayImage: scene.overlay?.phoneUrl,
          }}
          trigger={renderUseThisSceneButton()}
          onChange={onMediaSelection}
        />
      )}
    </div>
  );
};

export default ProjectSceneInactiveOptions;
