import { EditFilled } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { Ref, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdColorPalette } from 'react-icons/io';
import { IoColorWand } from 'react-icons/io5';
import { MdBorderStyle } from 'react-icons/md';
import { createUseStyles } from 'react-jss';
import Preformatted from '../../../../../../components/preformatted/Preformatted';
import useCanAccess from '../../../../../../hooks/useCanAccess';
import ProjectUtils from '../../../../../../utils/ProjectUtils';
import { CustomColors, PermissionList } from '../../../../../../utils/types/CharterPermissionTypes';
import { ProjectMask, ProjectThemeParameters } from '../../../../../../utils/types/ProjectTypes';
import ProjectThemeSettingsModal from './ProjectThemeSettingsModal';

type Props = {
  value?: ProjectThemeParameters;
  onChange?: (newValue: ProjectThemeParameters | undefined) => void;
  fieldLabel: string;
};

const useStyles = createUseStyles({
  configureThemeButton: {
    padding: 0,
  },
  fieldValueContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: 6,
  },
  selectedThemeContainer: {
    cursor: 'pointer',
    '& .anticon': {
      marginRight: 4,
    },
  },
  fieldLabelIcon: {
    marginRight: 2,
  },
});

const ProjectThemeSettings = React.forwardRef(({ value, onChange, fieldLabel }: Props, ref: Ref<any>) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const permissionToAccessCustomColors = useCanAccess(PermissionList.CUSTOM_COLORS);

  const classes = useStyles();
  const { t } = useTranslation();

  // Called to update the upper `form` object
  const triggerChange = (changedValue: ProjectThemeParameters | undefined): void => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  const onOpenModal = (): void => {
    setIsModalVisible(true);
  };

  const onCloseModal = (): void => {
    setIsModalVisible(false);
  };

  const onSubmit = (values: ProjectThemeParameters) => {
    triggerChange(values);
    onCloseModal();
  };

  const renderThemeValue = (themeCode: string) => {
    const selectedTheme = ProjectUtils.findProjectThemeByCode(themeCode);
    if (!selectedTheme) {
      return null;
    }

    return (
      <Tooltip title={t('charters.projects.projectEditor.projectFinalization.graphicalTheme.themePopover')}>
        <span className={classes.fieldValueContainer}>
          <IoColorWand className={classes.fieldLabelIcon} />
          <Preformatted>{t(selectedTheme.key)}</Preformatted>
        </span>
      </Tooltip>
    );
  };

  const renderMaskValue = (maskCode?: string) => {
    const selectedMask = maskCode ? ProjectUtils.findProjectMaskByCode(maskCode) : ProjectMask.NONE;
    if (!selectedMask) {
      return null;
    }

    return (
      <Tooltip title={t('charters.projects.projectEditor.projectFinalization.graphicalTheme.maskPopover')}>
        <span className={classes.fieldValueContainer}>
          <MdBorderStyle className={classes.fieldLabelIcon} />
          <Preformatted>{t(selectedMask.key)}</Preformatted>
        </span>
      </Tooltip>
    );
  };

  const renderColorsValue = (useCustomColors: boolean) => {
    const computeUseCustomColorsValue = () => {
      if (!permissionToAccessCustomColors.hasUserAccess()) {
        return permissionToAccessCustomColors.forcedValue === CustomColors.CUSTOM.code;
      }
      return useCustomColors;
    };

    return (
      <Tooltip title={t('charters.projects.projectEditor.projectFinalization.graphicalTheme.animationColorsPopover')}>
        <span className={classes.fieldValueContainer}>
          <IoMdColorPalette className={classes.fieldLabelIcon} />
          <Preformatted>
            {t(
              computeUseCustomColorsValue()
                ? 'charters.projects.projectEditor.projectFinalization.graphicalTheme.charterColors'
                : 'charters.projects.projectEditor.projectFinalization.graphicalTheme.standardColors'
            )}
          </Preformatted>
        </span>
      </Tooltip>
    );
  };

  return (
    <div ref={ref}>
      <div onClick={onOpenModal}>
        {value ? (
          <span className={classes.selectedThemeContainer}>
            {renderThemeValue(value.theme)}
            {renderMaskValue(value?.mask)}
            {renderColorsValue(value.customColors)}
            <Tooltip title={t('global.edit')}>
              <EditFilled />
            </Tooltip>
          </span>
        ) : (
          <Button type="link" className={classNames(classes.configureThemeButton)}>
            {t('charters.projects.projectEditor.projectFinalization.graphicalTheme.editButton')}
          </Button>
        )}
      </div>

      <ProjectThemeSettingsModal
        value={value}
        isVisible={isModalVisible}
        fieldLabel={fieldLabel}
        onCancel={onCloseModal}
        onSubmit={onSubmit}
      />
    </div>
  );
});

export default ProjectThemeSettings;
