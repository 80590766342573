import log from 'loglevel';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CompanyStats from '../model/CompanyStats';
import { setCompanyStats } from '../redux/action/CompaniesAction';
import { RootState } from '../redux/RootState';
import { APIManager } from '../services/api/APIManager';
import { getCompanyStatsById } from '../services/api/CompaniesService';

type FetchResult = {
  loading: boolean;
  isError: boolean;
  fetchedCompanyStats?: CompanyStats;
};
const useFetchCompanyStats = (): FetchResult => {
  const [fetchedCompanyStats, setFetchedCompanyStats] = useState<CompanyStats | undefined>();
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const [company] = useSelector((state: RootState) => {
    return [state.companies.current];
  });

  useEffect(() => {
    if (!company) {
      return undefined;
    }

    const cancelTokenSource = APIManager.getCancelToken();

    setLoading(true);
    getCompanyStatsById(company.id, cancelTokenSource)
      .then((response) => {
        const companyStats = new CompanyStats(response.data, company);
        dispatch(setCompanyStats(companyStats));
        setFetchedCompanyStats(companyStats);
        setLoading(false);
        setIsError(false);
      })
      .catch((error) => {
        log.error('Error during service worker registration:', error);
        setLoading(false);
        setIsError(true);
      });

    return (): void => {
      cancelTokenSource.cancel('Fetch company stats canceled due to component unmounting');
    };
    // eslint-disable-next-line
  }, [company]);

  return {
    loading,
    fetchedCompanyStats,
    isError,
  };
};

export default useFetchCompanyStats;
