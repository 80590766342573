import { find, isEqual, omit } from 'lodash';
import { ANIMATION_CONSTANTS, PROJECTS } from '../Constants';
import {
  APIFullModelCharter,
  APIUpdateCharterProjectParams,
  APIUpdateCharterProjectSceneParams,
  Asset,
  MediaFile,
} from '../services/api/types/ChartersServiceTypes';
// eslint-disable-next-line import/no-cycle
import AnimationsUtils from './AnimationsUtils';
import MathUtils from './MathUtils';
import {
  AnimationConfig,
  AnimationFormats,
  AnimationPositionPresets,
  Animations,
  PipEffects,
  PipPositionCode,
} from './types/AnimationTypes';
import { CustomColors, LogoIncludeSlides } from './types/CharterPermissionTypes';
import { UseCanAccessResult } from './types/PermissionType';
import {
  PlayerSize,
  Project,
  ProjectFormat,
  ProjectFormats,
  ProjectLogo,
  ProjectLogoDisplayMode,
  ProjectLogoPosition,
  ProjectLogoSize,
  ProjectLogoStyle,
  ProjectMask,
  ProjectMaskOption,
  ProjectMusicDuckingVolumeOption,
  ProjectMusicDuckingVolumes,
  ProjectMusicFadeOutDurationOption,
  ProjectMusicFadeOutDurations,
  ProjectScene,
  ProjectSceneAnimation,
  ProjectSceneAnimationText,
  ProjectSceneElement,
  ProjectSceneElements,
  ProjectSceneKind,
  ProjectStatus,
  ProjectTheme,
  ProjectThemeOption,
} from './types/ProjectTypes';
import { AnimationTheme } from './types/ThemeTypes';

export default class ProjectUtils {
  static isVideoSelected = (selectedElement?: ProjectSceneElement): boolean => {
    return selectedElement ? selectedElement.code === ProjectSceneElements.RECORDABLE_VIDEO.code : false;
  };

  static isAnimationSelected = (selectedElement?: ProjectSceneElement): boolean => {
    return selectedElement ? selectedElement.code === ProjectSceneElements.ANIMATION.code : false;
  };

  static isPipSelected = (selectedElement?: ProjectSceneElement): boolean => {
    return selectedElement ? selectedElement.code === ProjectSceneElements.PIP.code : false;
  };

  static areSubtitlesSelected = (selectedElement?: ProjectSceneElement): boolean => {
    return selectedElement ? selectedElement.code === ProjectSceneElements.SUBTITLES.code : false;
  };

  static getAnimationTextsFromScene = (scene?: ProjectScene): string[] => {
    return scene?.animation?.animationTexts?.map((animationText) => animationText.text) || [];
  };

  static getProjectFormatByCode = (formatCode: string): ProjectFormat | undefined => {
    return find(ProjectFormats, (formatObject) => formatObject.code === formatCode);
  };

  static getProjectStatusByCode = (statusCode: string): ProjectStatus | undefined => {
    return find(ProjectStatus, (statusObject) => statusObject.code === statusCode);
  };

  static isSlideScene = (scene: ProjectScene): boolean => {
    return scene.kind === ProjectSceneKind.SLIDE.code;
  };

  static isRecordableScene = (scene: ProjectScene): boolean => {
    return scene.kind === ProjectSceneKind.RECORDABLE.code;
  };

  static joinAnimationTexts = (animationTexts: ProjectSceneAnimationText[]): string => {
    return animationTexts.map((animationText) => animationText.text).join(' ');
  };

  static transformProjectSceneAnimationToAnimationConfig = (
    projectSceneAnimation: ProjectSceneAnimation
  ): AnimationConfig => {
    return {
      name: projectSceneAnimation.code,
      position: {
        code: projectSceneAnimation.position,
        x: projectSceneAnimation.offsetX,
        y: projectSceneAnimation.offsetY,
      },
      delay: projectSceneAnimation.delay,
      duration: projectSceneAnimation.duration,
    };
  };

  static buildAnimationTextsArray = (texts: string[]): ProjectSceneAnimationText[] => {
    return texts.map((text) => {
      return {
        demoText: text,
        placeholderText: text,
        text,
      };
    });
  };

  // Build the skeleton of a scene with the default values, depending on its kind
  static buildDefaultSceneByKind = (kind: string, scenes: ProjectScene[]): ProjectScene => {
    // Generate a temporary id for the scene
    // It will be replaced by a back-end id once the scene is saved
    const temporarySceneId = new Date().getTime();

    const scenesIndexes = scenes.map((s) => s.index);
    const newSceneIndex = Math.max(...scenesIndexes) + 1;

    return {
      id: temporarySceneId,
      isActive: true, // When adding a new scene, it is always active by default
      index: newSceneIndex, // index is 0-based, so we do not need to make the `index: scenesCount + 1`
      kind,
      videoRecommendedTime: 5,
      videoMinRecordTime: 0.5,
      videoMaxRecordTime: 20,
      cameraZoom: 0,
    };
  };

  // Generates the default scene object from a given animationConfig, by calculating the scene default values,
  // the animation defaultTexts, default position, etc.
  static buildDefaultSlideSceneWithAnimation = (
    animationConfig: AnimationConfig,
    scenes: ProjectScene[]
  ): ProjectScene | undefined => {
    const sceneSkeleton = ProjectUtils.buildDefaultSceneByKind(ProjectSceneKind.SLIDE.code, scenes);

    const animationOption = AnimationsUtils.getAnimationOptionByCode(animationConfig.name);

    if (!(animationOption && animationConfig.position)) {
      return undefined;
    }

    const animationDefaultTexts = AnimationsUtils.getDefaultAnimationTextsByAnimation(animationOption);

    return {
      ...sceneSkeleton,
      animation: {
        code: animationConfig.name,
        duration: animationConfig.duration,
        animationTexts: ProjectUtils.buildAnimationTextsArray(animationDefaultTexts),
        position: animationConfig.position.code,
        offsetX: animationConfig.position.x,
        offsetY: animationConfig.position.y,
        active: true,
      },
    };
  };

  // Generates the default scene object from a given backgroundVideo
  static buildDefaultRecordableSceneWithBackgroundVideo = (
    backgroundVideo: MediaFile,
    scenes: ProjectScene[]
  ): ProjectScene => {
    const sceneSkeleton = ProjectUtils.buildDefaultSceneByKind(ProjectSceneKind.RECORDABLE.code, scenes);

    return {
      ...sceneSkeleton,
      backgroundVideo: {
        media: backgroundVideo,
        audioVolume: 1,
      },
    };
  };

  // Generates the default scene object from a given PIP image
  static buildDefaultRecordableSceneWithPipImage = (pipImage: MediaFile, scenes: ProjectScene[]): ProjectScene => {
    const sceneSkeleton = ProjectUtils.buildDefaultSceneByKind(ProjectSceneKind.RECORDABLE.code, scenes);

    return {
      ...sceneSkeleton,
      pip: {
        media: pipImage,
        duration: PROJECTS.PIP.PIP_ONLY_DEFAULT_DURATION,
        audioVolume: 0,
        effectName: PipEffects.ZOOMIN.code,
        effectValue: PipEffects.ZOOMIN.allowedValues?.soft.value,
        positionCode: PipPositionCode.FULL_SCREEN,
        positionValue: 0,
        size: 1,
      },
    };
  };

  static findProjectThemeByCode = (themeCode: string): ProjectThemeOption | undefined => {
    return find(ProjectTheme, (theme) => theme.code === themeCode);
  };

  static findProjectMaskByCode = (maskCode: string): ProjectMaskOption | undefined => {
    return find(ProjectMask, (mask) => mask.code === maskCode);
  };

  static findProjectMusicDuckingVolumeByValue = (
    musicDuckingVolumeValue: number
  ): ProjectMusicDuckingVolumeOption | undefined => {
    return find(ProjectMusicDuckingVolumes, (option) => option.value === musicDuckingVolumeValue);
  };

  static findProjectMusicFadeOutDurationByValue = (
    musicFadeOutDurationValue: number
  ): ProjectMusicFadeOutDurationOption | undefined => {
    return find(ProjectMusicFadeOutDurations, (option) => option.value === musicFadeOutDurationValue);
  };

  static areProjectsEqual = (projectA: Project, projectB: Project): boolean => {
    const cleanedProjectA = omit(projectA, ['lastSyncAt', 'updatedAt']);
    const cleanedProjectB = omit(projectB, ['lastSyncAt', 'updatedAt']);
    return isEqual(cleanedProjectA, cleanedProjectB);
  };

  static buildUpdateProjectSceneParams = (scene: ProjectScene): APIUpdateCharterProjectSceneParams => {
    return {
      id: scene.id,
      index: scene.index,
      kind: scene.kind,
      isActive: scene.isActive,
      title: scene.title,
      description: scene.description,
      ...(scene.animation &&
        scene.animation.active !== undefined &&
        scene.animation.offsetX !== undefined &&
        scene.animation.offsetY !== undefined && {
          animation: {
            code: scene.animation.code,
            active: scene.animation.active,
            position: scene.animation.position,
            offsetX: scene.animation.offsetX,
            offsetY: scene.animation.offsetY,
            delay: scene.animation.delay,
            duration: scene.animation.duration,
            animationTexts: scene.animation.animationTexts,
          },
        }),
      ...(scene.backgroundVideo &&
        scene.backgroundVideo.audioVolume !== undefined && {
          backgroundVideo: {
            media: {
              id: scene.backgroundVideo.media.id,
              croppedArea: scene.backgroundVideo.media.croppedArea,
              duration: scene.backgroundVideo.media.duration,
            },
            audioVolume: scene.backgroundVideo.audioVolume,
            trimStartAt: scene.backgroundVideo.trimStartAt,
            trimEndAt: scene.backgroundVideo.trimEndAt,
          },
        }),
      ...(scene.pip && {
        pip: {
          media: scene.pip.media,
          audioVolume: scene.pip.audioVolume ?? 0,
          duration: scene.pip.duration,
          delay: scene.pip.delay ?? 0,
          trimStartAt: scene.pip.trimStartAt,
          trimEndAt: scene.pip.trimEndAt,
          effectName: scene.pip.effectName,
          effectValue: scene.pip.effectValue,
          positionCode: scene.pip.positionCode,
          positionValue: scene.pip.positionValue,
          size: scene.pip.size,
        },
      }),
    };
  };

  static buildUpdateProjectParams = (project: Project): APIUpdateCharterProjectParams | undefined => {
    if (!(project.scenes && project.scenes.length > 0)) {
      return undefined;
    }

    const scenes = project.scenes.map((scene) => ProjectUtils.buildUpdateProjectSceneParams(scene));

    return {
      title: project.title,
      theme: project.theme,
      customColors: project.customColors,
      sceneIndexesOrder: project.sceneIndexesOrder,
      audioVolume: project.audioVolume,
      audioProcessingEnabled: project.audioProcessingEnabled,
      mask: project.mask,
      dismissedFeatures: [],
      subtitlesLanguage: project.subtitlesLanguage,
      introId: project.intro?.id,
      outroId: project.outro?.id,
      ...(project.logo &&
        project.logo.logoAsset && {
          logo: {
            id: project.logo.logoAsset.id,
            positionCode: project.logo.positionCode,
            size: project.logo.size,
            displayMode: project.logo.displayMode,
          },
        }),
      ...(project.music &&
        project.music.file && {
          music: {
            id: project.music.file.id,
            volume: project.music.volume,
            duckingEnabled: project.music.duckingEnabled,
            duckingVolume: project.music.duckingVolume,
            fadeOutEnabled: project.music.fadeOutEnabled,
            fadeOutDuration: project.music.fadeOutDuration,
          },
        }),
      scenes,
    };
  };

  // Apply specific rules to transform the project scenes depending on the theme
  // For now, the only specific case concerns BERLIN-NISSAN, for which there is no offsetX nor offsetY (like 'BOTTOMCENTER')
  // So we transform the project scenes if the theme changed from or to `BERLIN` for the existing `NISSAN` animations
  static transformProjectScenesBasedOnThemeSpecificRules = (
    projectScenes: ProjectScene[],
    theme: AnimationTheme
  ): ProjectScene[] => {
    // If the new theme is BERLIN
    if (theme === AnimationTheme.BERLIN) {
      return projectScenes.map((scene) => {
        // Transform the existing `NISSAN` animations to satisfy the specific `BERLIN-NISSAN` rule
        if (scene.animation && scene.animation.code === Animations.NISSAN.code) {
          return {
            ...scene,
            animation: {
              ...scene.animation,
              offsetX: AnimationPositionPresets.FULL_SCREEN.x,
              offsetY: AnimationPositionPresets.FULL_SCREEN.y,
            },
          } as ProjectScene;
        }
        return scene;
      });
    }

    // Else, if the theme changed, we check if there are scenes with the `NISSAN` animation with no offsets (remove
    // the `BERLIN-NISSAN` specific rule)
    return projectScenes.map((scene) => {
      if (scene.animation && scene.animation.code === Animations.NISSAN.code) {
        return {
          ...scene,
          animation: {
            ...scene.animation,
            offsetX:
              scene.animation.offsetX === AnimationPositionPresets.FULL_SCREEN.x
                ? AnimationPositionPresets.MEDIUM_TRANSLATION.x
                : scene.animation.offsetX,
            offsetY:
              scene.animation.offsetY === AnimationPositionPresets.FULL_SCREEN.y
                ? AnimationPositionPresets.MEDIUM_TRANSLATION.y
                : scene.animation.offsetY,
          },
        } as ProjectScene;
      }
      return scene;
    });
  };

  static getSigmoidalVolume = (x: number): number => {
    if (x === -5) {
      return 0;
    }
    if (x === 5) {
      return 1;
    }
    return 1 / (1 + Math.exp(-x));
  };

  static getVolumePercentageFromSigmoidalVolume = (x: number | undefined): number => {
    if (!x) {
      return -5;
    }
    if (x === 1) {
      return 5;
    }
    return Math.log(x / (1 - x));
  };

  static formatVolumeWithPrecision = (volume: number, precision = 3): number => {
    return parseFloat(volume.toFixed(precision));
  };

  static formatVolumeToPercentage = (audioVolume: number): number => {
    return Math.round((ProjectUtils.getVolumePercentageFromSigmoidalVolume(audioVolume) + 5) * 10);
  };

  static formatPercentageIntoHundredPercent = (percentage: number): number => {
    return Math.round((percentage + 5) * 10);
  };

  static optimizeVolumePrecisionValue = (audioVolume: number): number => {
    return ProjectUtils.forceValueOnUpperOrLowerBound(Math.floor(audioVolume * 1000) / 1000);
  };

  static forceValueOnUpperOrLowerBound = (audioVolume: number): number => {
    if (audioVolume < 0.008) {
      return 0;
    }
    if (audioVolume > 0.992) {
      return 1;
    }
    return audioVolume;
  };

  static buildProjectPubliclySharedUrlFromCode = (publicCode: string): string => {
    const { origin } = window.location;

    return `${origin}/shared/${publicCode}`;
  };

  // Calculate the maximum value of trimStartAt
  static getMaximumValueOfTrimStartAt = (scene: ProjectScene, sceneDuration: number) => {
    if (scene && scene.backgroundVideo && scene.backgroundVideo.trimEndAt !== undefined) {
      return MathUtils.formatInputValueWithXDecimals(
        scene.backgroundVideo.trimEndAt - PROJECTS.TRIM.MIN_VIDEO_DURATION,
        3
      );
    }
    return MathUtils.formatInputValueWithXDecimals(sceneDuration - PROJECTS.TRIM.MIN_VIDEO_DURATION, 3);
  };

  // Calculate the minimum value of trimStartAt
  static getMinimumValueOfTrimEndAt = (scene: ProjectScene) => {
    if (scene && scene.backgroundVideo && scene.backgroundVideo.trimStartAt !== undefined) {
      return MathUtils.formatInputValueWithXDecimals(
        scene.backgroundVideo.trimStartAt + PROJECTS.TRIM.MIN_VIDEO_DURATION,
        3
      );
    }
    return MathUtils.formatInputValueWithXDecimals(PROJECTS.TRIM.MIN_VIDEO_DURATION, 3);
  };

  static getTrimStartAtValue = (scene: ProjectScene) => {
    if (scene && scene.backgroundVideo && scene.backgroundVideo && scene.backgroundVideo.trimStartAt !== undefined) {
      return scene.backgroundVideo.trimStartAt;
    }
    return 0;
  };

  static getTrimEndAtValue = (scene: ProjectScene, sceneDuration: number) => {
    if (scene && scene.backgroundVideo && scene.backgroundVideo && scene.backgroundVideo.trimEndAt !== undefined) {
      return scene.backgroundVideo.trimEndAt;
    }
    return sceneDuration;
  };

  static computeInitialPipDurationOverVideo = (videoDuration?: number): number => {
    if (!videoDuration || videoDuration < 0) {
      return PROJECTS.PIP.PIP_ONLY_DEFAULT_DURATION;
    }

    return Math.max(videoDuration / 3, PROJECTS.PIP.PIP_MIN_DURATION);
  };

  static computeInitialPipDelayOverVideo = (videoDuration?: number): number => {
    if (!videoDuration || videoDuration < 0) {
      return 0;
    }

    return videoDuration / 3;
  };

  // Get the permission forced intro asset if there is one. If not, get the project's intro asset
  static getPermissionForcedIntro = (
    currentCharter: APIFullModelCharter,
    project: Project,
    permissionToEditIntro: UseCanAccessResult
  ): Asset | undefined => {
    if (!permissionToEditIntro.hasUserAccess()) {
      const { forcedValue } = permissionToEditIntro;
      if (forcedValue) {
        return currentCharter.intros.find((intro) => intro.id === parseInt(forcedValue, 10));
      }

      return undefined;
    }

    return project.intro;
  };

  // Get the forced outro asset if there is one. If not, get the project's outro asset
  static getPermissionForcedOutro = (
    currentCharter: APIFullModelCharter,
    project: Project,
    permissionToEditOutro: UseCanAccessResult
  ): Asset | undefined => {
    if (!permissionToEditOutro.hasUserAccess()) {
      const { forcedValue } = permissionToEditOutro;
      if (forcedValue) {
        return currentCharter.outros.find((outro) => outro.id === parseInt(forcedValue, 10));
      }

      return undefined;
    }

    return project.outro;
  };

  // Get the forced custom colors value if there is one. If not, get the project's custom colors
  static getPermissionForcedCustomColors = (
    customColors: boolean | undefined,
    permissionToAccessCustomColors: UseCanAccessResult
  ): boolean => {
    if (!permissionToAccessCustomColors.hasUserAccess()) {
      return permissionToAccessCustomColors.forcedValue === CustomColors.CUSTOM.code;
    }

    return customColors ?? true;
  };

  // Get the forced logo display mode value if there is one. If not, get the project's logo display mode value
  static getPermissionForcedLogoDisplayMode = (
    logoDisplayMode: ProjectLogoDisplayMode | undefined,
    permissionToDisplayLogoOnSlides: UseCanAccessResult
  ): ProjectLogoDisplayMode => {
    if (!permissionToDisplayLogoOnSlides.hasUserAccess()) {
      return permissionToDisplayLogoOnSlides.forcedValue === LogoIncludeSlides.ALL_USER_VIDEOS.code
        ? ProjectLogoDisplayMode.ALL_SCENES
        : ProjectLogoDisplayMode.RECORDABLE_ONLY;
    }

    return logoDisplayMode ?? ProjectLogoDisplayMode.RECORDABLE_ONLY;
  };

  // Get the forced logo size value if there is one. If not, get the project's logo size value
  static getPermissionForcedLogoSize = (
    logoSize: ProjectLogoSize | undefined,
    permissionToEditLogoSize: UseCanAccessResult
  ): ProjectLogoSize => {
    if (!permissionToEditLogoSize.hasUserAccess()) {
      const { forcedValue } = permissionToEditLogoSize;

      return forcedValue ? parseFloat(forcedValue) : ProjectLogoSize.S;
    }

    return logoSize ?? ProjectLogoSize.S;
  };

  // Get the forced logo position value if there is one. If not, get the project's logo position value
  static getPermissionForcedLogoPositionCode = (
    logoPositionCode: ProjectLogoPosition | undefined,
    permissionToEditLogoPosition: UseCanAccessResult
  ): ProjectLogoPosition => {
    if (!permissionToEditLogoPosition.hasUserAccess()) {
      const { forcedValue } = permissionToEditLogoPosition;

      return (forcedValue as ProjectLogoPosition) ?? ProjectLogoPosition.TOP_RIGHT;
    }

    return logoPositionCode ?? ProjectLogoPosition.TOP_RIGHT;
  };

  // Get the forced logo asset if there is one. If not, get the project's logo asset
  static getPermissionForcedLogo = (
    currentCharter: APIFullModelCharter,
    project: Project,
    permissionToEditLogo: UseCanAccessResult,
    permissionToEditLogoSize: UseCanAccessResult,
    permissionToEditLogoPosition: UseCanAccessResult,
    permissionToDisplayLogoOnSlides: UseCanAccessResult
  ): ProjectLogo | undefined => {
    const logoDisplayMode = ProjectUtils.getPermissionForcedLogoDisplayMode(
      project?.logo?.displayMode,
      permissionToDisplayLogoOnSlides
    );
    const logoPositionCode = ProjectUtils.getPermissionForcedLogoPositionCode(
      project?.logo?.positionCode,
      permissionToEditLogoPosition
    );
    const logoSize = ProjectUtils.getPermissionForcedLogoSize(project.logo?.size, permissionToEditLogoSize);

    if (!permissionToEditLogo.hasUserAccess()) {
      const { forcedValue } = permissionToEditLogo;
      if (forcedValue) {
        const logoAsset = currentCharter.logos.find((logo) => logo.id === parseInt(forcedValue, 10));
        if (logoAsset) {
          return {
            logoAsset,
            displayMode: logoDisplayMode,
            positionCode: logoPositionCode,
            size: logoSize,
          };
        }
      }

      return undefined;
    }

    if (!project.logo?.logoAsset) {
      return undefined;
    }

    return {
      logoAsset: project.logo.logoAsset,
      displayMode: logoDisplayMode,
      positionCode: logoPositionCode,
      size: logoSize,
    };
  };

  // Get the forced project value if there is one.
  static getProjectWithPermissionForcedValues = (
    currentCharter: APIFullModelCharter,
    project: Project,
    permissionToEditIntro: UseCanAccessResult,
    permissionToEditOutro: UseCanAccessResult,
    permissionToEditLogo: UseCanAccessResult,
    permissionToEditLogoSize: UseCanAccessResult,
    permissionToEditLogoPosition: UseCanAccessResult,
    permissionToDisplayLogoOnSlides: UseCanAccessResult,
    permissionToAccessCustomColors: UseCanAccessResult
  ): Project => {
    // The new project with permission forced values
    const newProject = {
      ...project,
      intro: ProjectUtils.getPermissionForcedIntro(currentCharter, project, permissionToEditIntro),
      outro: ProjectUtils.getPermissionForcedOutro(currentCharter, project, permissionToEditOutro),
      logo: ProjectUtils.getPermissionForcedLogo(
        currentCharter,
        project,
        permissionToEditLogo,
        permissionToEditLogoSize,
        permissionToEditLogoPosition,
        permissionToDisplayLogoOnSlides
      ),
      customColors: ProjectUtils.getPermissionForcedCustomColors(project.customColors, permissionToAccessCustomColors),
    };

    // Delete undefined properties
    if (!newProject.intro) {
      delete newProject.intro;
    }

    if (!newProject.outro) {
      delete newProject.outro;
    }

    if (!newProject.logo) {
      delete newProject.logo;
    }

    return newProject;
  };

  static computeRecordableSceneDuration = (scene: ProjectScene): number => {
    const hasBackgroundVideo = scene.backgroundVideo !== undefined;

    if (!hasBackgroundVideo) {
      return scene.pip?.duration ?? 0;
    }

    const sceneMediaDuration = scene.backgroundVideo?.media?.duration ?? 0;
    return ProjectUtils.getTrimEndAtValue(scene, sceneMediaDuration) - ProjectUtils.getTrimStartAtValue(scene);
  };

  static computeSceneDuration = (scene: ProjectScene): number => {
    if (ProjectUtils.isRecordableScene(scene)) {
      return ProjectUtils.computeRecordableSceneDuration(scene);
    }

    return scene.animation?.duration ?? 0;
  };

  static computeProjectDuration = (project: Project): number => {
    if (!(project.scenes && project.scenes.length > 0)) {
      return 0;
    }

    const scenesDuration = project.scenes
      .filter((scene) => scene.isActive)
      .reduce((prev, currentScene) => prev + ProjectUtils.computeSceneDuration(currentScene), 0);
    const introDuration = project.intro?.duration ?? 0;
    const outroDuration = project.outro?.duration ?? 0;

    return introDuration + scenesDuration + outroDuration;
  };

  static computeSceneRelativeStartAt = (scene: ProjectScene): number => {
    if (ProjectUtils.isSlideScene(scene)) {
      return 0;
    }

    if (scene.backgroundVideo) {
      return scene.backgroundVideo?.trimStartAt ?? 0;
    }

    return scene.pip?.trimStartAt ?? 0;
  };

  static computeSceneRelativeEndAt = (scene: ProjectScene): number => {
    if (ProjectUtils.isSlideScene(scene)) {
      return scene.animation?.duration ?? 0;
    }

    if (scene.backgroundVideo) {
      return scene.backgroundVideo?.trimEndAt ?? scene.backgroundVideo.media.duration ?? 0;
    }

    return scene.pip?.trimEndAt ?? scene.pip?.duration ?? 0;
  };

  static computeSceneStartTimeInProject = (project: Project, scene: ProjectScene): number => {
    const activeScenes = project.scenes?.filter((s) => s.isActive);
    const previousScenes = activeScenes?.slice(0, activeScenes?.indexOf(scene));
    const absoluteStartTimeInScenes =
      previousScenes?.reduce((prev, current) => {
        return prev + ProjectUtils.computeSceneDuration(current);
      }, 0) ?? 0;
    const introDuration = project.intro?.duration ?? 0;

    return MathUtils.formatInputValueWithXDecimals(introDuration + absoluteStartTimeInScenes, 3);
  };

  static computeSceneEndTimeInProject = (project: Project, scene: ProjectScene): number => {
    const absoluteStartTime = ProjectUtils.computeSceneStartTimeInProject(project, scene);
    const sceneDuration = ProjectUtils.computeSceneDuration(scene);

    return MathUtils.formatInputValueWithXDecimals(absoluteStartTime + sceneDuration, 3);
  };

  static computeOutroStartTimeInProject = (project: Project): number => {
    return ProjectUtils.computeProjectDuration(project) - (project.outro?.duration ?? 0);
  };

  static estimateProjectProcessingTimeInSeconds = (project: Project): number => {
    const { scenes } = project;

    // Filter out the inactive scenes
    const activeScenes = scenes?.filter((scene) => scene.isActive);

    if (!(activeScenes && activeScenes.length > 0)) {
      return 0;
    }

    // Sum the durations of the `RECORDABLE` scenes
    const recordableScenesDurationSum = activeScenes
      ?.filter(ProjectUtils.isRecordableScene)
      .reduce((previousSum, scene) => {
        const sceneMediaDuration = scene.backgroundVideo?.media.duration ?? 0;
        const sceneDuration =
          ProjectUtils.getTrimEndAtValue(scene, sceneMediaDuration) - ProjectUtils.getTrimStartAtValue(scene);
        return previousSum + sceneDuration;
      }, 0);

    // If there are scenes with the `PLANE` animation, add an extra time of twice the duration of the longest `PLANE` scene
    const maxPlaneSceneDuration = activeScenes
      ?.filter((scene) => scene.animation && scene.animation.code === Animations.PLANE.code)
      .reduce((previousMax, scene) => {
        const sceneDuration = ProjectUtils.computeRecordableSceneDuration(scene);
        return Math.max(previousMax, sceneDuration);
      }, 0);
    const extraTimeForMaxPlaneScene = maxPlaneSceneDuration * 2;

    // If there are `SLIDE` scenes, we add an extra time of 10 times the duration of the longest one
    const maxSlideDuration = activeScenes?.filter(ProjectUtils.isSlideScene).reduce((previousMax, scene) => {
      const sceneDuration = scene.animation?.duration ?? 0;
      return Math.max(previousMax, sceneDuration);
    }, 0);
    const extraTimeForMaxSlideScene = maxSlideDuration * 10;

    // If the scenes contain a PIP, we had the PIP duration divided by two for each scene
    const pipDurationsExtraTime =
      activeScenes
        ?.filter((s) => s.pip !== undefined)
        .reduce((previous, scene) => {
          const pipDuration = scene.pip?.duration ?? 0;
          return previous + pipDuration;
        }, 0) / 2;

    // Check if the project has at least one scene with a lower-third animation and if so, apply an extra time of 5 seconds
    const hasOneLowerThird = activeScenes?.some(
      (scene) => scene.animation && AnimationsUtils.isLowerThirdAnimation(scene.animation.code)
    );
    const extraTimeIfHasLowerThird = hasOneLowerThird ? 5 : 0;

    // Add extra times for the optional project intro/outro
    const introDuration = project.intro?.duration ?? 0;
    const outroDuration = project.outro?.duration ?? 0;
    const extraTimeIntro = introDuration * 4;
    const extraTimeOutro = outroDuration * 4;

    return (
      recordableScenesDurationSum +
      pipDurationsExtraTime +
      extraTimeIfHasLowerThird +
      extraTimeForMaxPlaneScene +
      extraTimeForMaxSlideScene +
      extraTimeIntro +
      extraTimeOutro
    );
  };

  static computeLogoStyle = (
    logo: ProjectLogo,
    format: AnimationFormats,
    hasMask: boolean,
    playerSize: PlayerSize
  ): ProjectLogoStyle | undefined => {
    if (!(logo.size && logo.positionCode)) {
      return undefined;
    }

    const projectReferenceWidth = AnimationsUtils.getReferenceWidthByFormat(format);

    let style;
    const playerScaleFactor = playerSize.width / projectReferenceWidth;
    const margin = hasMask
      ? ANIMATION_CONSTANTS.LOGO.MARGIN_WITH_MASK * playerScaleFactor
      : ANIMATION_CONSTANTS.LOGO.MARGIN * playerScaleFactor;

    switch (logo.positionCode) {
      case ProjectLogoPosition.TOP_LEFT:
        style = { top: margin, left: margin };
        break;
      case ProjectLogoPosition.TOP_RIGHT:
        style = { top: margin, right: margin };
        break;
      case ProjectLogoPosition.BOTTOM_LEFT:
        style = { bottom: margin, left: margin };
        break;
      case ProjectLogoPosition.BOTTOM_RIGHT:
      default:
        style = { right: margin, bottom: margin };
        break;
    }

    style = { ...style, height: playerSize.height * logo.size };

    return style;
  };
}
