import { Button, Col, Divider, Form, InputNumber, message, Modal, Radio, Select, Switch } from 'antd';
import { FormInstance, RuleObject } from 'antd/lib/form';
import { Store, StoreValue } from 'antd/lib/form/interface';
import { RadioChangeEvent } from 'antd/lib/radio';
import classNames from 'classnames';
import { diff } from 'deep-object-diff';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import log from 'loglevel';
import TextArea from 'antd/lib/input/TextArea';

import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDebouncedCallback } from 'use-debounce';
import AnimationSelector from '../../../../components/animation-selector/AnimationSelector';
import ScenarioSceneOverlayTexts from '../../../../components/form/ScenarioSceneOverlayTexts';
import ScenarioSceneUserPip from '../../../../components/form/ScenarioSceneUserPip';
import TextInput from '../../../../components/form/TextInput';
import MediaSelector from '../../../../components/media-selector/MediaSelector';
import { DEVICE_SIZES_QUERIES, THEME } from '../../../../Constants';
import AnimationsUtils from '../../../../utils/AnimationsUtils';
import ObjectUtils from '../../../../utils/ObjectUtils';
import ScenarioUtils from '../../../../utils/ScenarioUtils';
import { AnimationConfig } from '../../../../utils/types/AnimationTypes';
import { MediaType } from '../../../../utils/types/MediaTypes';
import {
  DemoMediaType,
  ScenarioFormats,
  ScenarioSceneTemplate,
  TemplateCameraOverlayText,
  TemplateSequenceKind,
  UserVideoAudioVolume,
} from '../../../../utils/types/ScenarioTypes';
import { AnimationTheme } from '../../../../utils/types/ThemeTypes';
import ScenarioScenePreview from './ScenarioScenePreview';

const { Option } = Select;

type Props = {
  form: FormInstance;
  selectedScene?: ScenarioSceneTemplate;
  sceneManipulatedInForm?: ScenarioSceneTemplate;
  onFieldChange: (updatedScene: ScenarioSceneTemplate) => void;
  onUpdateScene: (updatedScene: ScenarioSceneTemplate, sceneDiff?: any) => void;
  isSelectedSceneEdited: boolean;
  setIsSelectedSceneEdited: React.Dispatch<React.SetStateAction<boolean>>;
  isInModal?: boolean;
  onCloseSceneModal?: () => void;
  format?: string;
  isScenePreviewPlaying: boolean;
  callBackHandlePlayPause: (isPlaying: boolean) => void;
};

const useStyles = createUseStyles({
  container: {
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      marginLeft: '0',
      marginTop: '20px',
    },
  },
  buttonContainer: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: 8,
    },
  },
  form: {
    height: '100%',
    '& label': {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      height: 'unset !important',
      whiteSpace: 'normal',
      '&::before': {
        marginRight: '0 !important',
      },

      [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
        justifyContent: 'flex-start',
      },
    },
  },
  fields: {
    maxHeight: '90%',
    paddingRight: '10px',
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '6px',
      backgroundColor: 'rgba(0, 0, 0, .3)',
      boxShadow: '0 0 1px rgba(255, 255, 255, .3)',
    },

    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      maxHeight: 'unset',
      overflow: 'unset',
    },
    '& .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector, .ant-form-item-has-error .ant-input-number': {
      borderColor: `${THEME.DEFAULT.BORDER_COLOR} !important`,
    },
  },
  radioGroupFormItem: {
    '& .ant-radio-group': {
      display: 'flex',

      '& label': {
        alignItems: 'center',
      },
    },
  },
  overlayTextsFormItem: {
    '& label[for="overlayTexts"]': {
      visibility: 'hidden',
    },
  },
  hiddenFormItem: {
    display: 'none',
  },
  overlayImage: {
    width: 50,
    border: '1px solid #afafaf',
    filter: 'brightness(0.2)',
    borderRadius: 3,
    marginRight: 10,
  },
  overlayImageSquare: {
    extend: 'overlayImage',
    height: 40,
    width: 40,
    objectFit: 'cover',
  },
  overlayImagePortrait: {
    extend: 'overlayImage',
    height: 40,
    width: 30,
    objectFit: 'cover',
  },
  scenePreviewContainer: {
    width: '50%',
    margin: 'auto',
    marginBottom: 20,
  },
});

const ScenarioSceneForm: FunctionComponent<Props> = ({
  form,
  selectedScene,
  sceneManipulatedInForm,
  onFieldChange,
  onUpdateScene,
  isSelectedSceneEdited,
  setIsSelectedSceneEdited,
  isInModal = false,
  onCloseSceneModal,
  format,
  isScenePreviewPlaying,
  callBackHandlePlayPause,
}: Props) => {
  const [initialValues, setInitialValues] = useState<Store>();
  const [isSlideScene, setIsSlideScene] = useState(false);
  const [animationText, setAnimationText] = useState<AnimationConfig>();
  const [demoMediaType, setDemoMediaType] = useState<string>();
  const [isVideoRecommendedTimeWarning, setIsVideoRecommendedTimeWarning] = useState(false);

  const formRef = useRef<FormInstance>(null);

  const classes = useStyles();
  const { t } = useTranslation();

  const slideKind = formRef.current && form.getFieldValue('kind');

  useEffect(() => {
    setIsSlideScene(ScenarioUtils.isSlideKind(slideKind));
  }, [slideKind]);

  useEffect(() => {
    if (!selectedScene) {
      return;
    }

    const isSlide = selectedScene.kind ? ScenarioUtils.isSlideKind(selectedScene.kind) : false;

    let initialDemoMediaType;
    if (!isSlide) {
      if (selectedScene.userPip) {
        initialDemoMediaType = DemoMediaType.IMAGE.code;
      } else {
        initialDemoMediaType = DemoMediaType.VIDEO.code;
      }
    }

    const formInitialValues = {
      title: selectedScene.title,
      description: selectedScene.description,
      kind: selectedScene.kind,
      demoMediaType: initialDemoMediaType,
      demoCoverImageSource: selectedScene.demoCoverImageSource,
      demoVideoUri: selectedScene.demoVideoUri,
      videoRecommendedTime: selectedScene.videoRecommendedTime,
      excludeWatermark: selectedScene.excludeWatermark || false,
      ...(selectedScene.overlayTexts && selectedScene.overlayTexts.length > 0
        ? { overlayTexts: selectedScene.overlayTexts }
        : { overlayTexts: undefined }),
      ...(selectedScene.animationText ? { animationText: selectedScene.animationText } : { animationText: undefined }),
      backgroundMusicVolume: selectedScene.backgroundMusicVolume,
      videoMinRecordTime: selectedScene.videoMinRecordTime,
      videoMaxRecordTime: selectedScene.videoMaxRecordTime,
      ...(selectedScene.overlayImage ? { overlayImage: selectedScene.overlayImage } : { overlayImage: undefined }),
      userVideoAudioVolume: selectedScene.userVideoAudioVolume || UserVideoAudioVolume.VOLUME_ON.value,
      ...(selectedScene.userPip ? { userPip: selectedScene.userPip } : { userPip: undefined }),
    };

    setAnimationText(selectedScene.animationText);
    setIsSelectedSceneEdited(false);
    setInitialValues(formInitialValues);
    setIsSlideScene(isSlide);
    setDemoMediaType(initialDemoMediaType);

    if (formRef.current) {
      form.setFieldsValue(formInitialValues);
    }
  }, [selectedScene, form, setIsSelectedSceneEdited]);

  // Checks if the scene has been edited and return the diff (if the calculateDiff param is true) between the initial
  // scene values and the current form values
  const checkIfSceneEdited = useCallback(
    (calculateDiff = false): any | null => {
      const cleanedInitialValues = ScenarioUtils.removeUselessFieldsFromScene(
        ObjectUtils.removeFalsyProperties(initialValues)
      );
      const cleanedAllValues = ScenarioUtils.removeUselessFieldsFromScene(
        ObjectUtils.removeFalsyProperties(form.getFieldsValue())
      );
      // In the comparison, we ignore the optional `thumbnailImage` field in `demoVideoUri`.
      // Indeed, when selecting in the form a `demoVideoUri`, the associated media has the information of the `thumbnailImage` for that video.
      // However, this information is not returned by the scenarios APIs since it is useless in that context.
      delete cleanedInitialValues.demoVideoUri?.thumbnailImage;
      delete cleanedAllValues.demoVideoUri?.thumbnailImage;

      // Check if fields have changed (and ignore fields that did not exist and are now set to undefined, etc).
      if (isEqual(cleanedInitialValues, cleanedAllValues)) {
        setIsSelectedSceneEdited(false);
      } else {
        setIsSelectedSceneEdited(true);
      }

      return calculateDiff ? diff(cleanedInitialValues, cleanedAllValues) : null;
    },
    [initialValues, form, setIsSelectedSceneEdited]
  );

  useEffect(() => {
    const ensureSelectedAnimationIsAllowedForKind = (): void => {
      if (!animationText) {
        return;
      }

      // If there is already an animation that is not valid for the specified kind,
      // we change it to the first allowed one
      // (for instance if the user changes a scene from 'Slide' to 'Recordable' and
      // a 'Recordable' animation was set, we change it to the default 'Slide' with the
      // right position options)
      if (
        (!isSlideScene && AnimationsUtils.isSlideAnimation(animationText.name)) ||
        (isSlideScene && !AnimationsUtils.isSlideAnimation(animationText.name))
      ) {
        const defaultAnimationForKind = AnimationsUtils.getDefaultAnimationConfigByKind(isSlideScene);
        form.setFieldsValue({ animationText: defaultAnimationForKind });
        setAnimationText(defaultAnimationForKind);
      }
    };

    form.setFieldsValue({ excludeWatermark: isSlideScene });
    ensureSelectedAnimationIsAllowedForKind();

    checkIfSceneEdited();
  }, [isSlideScene, form, checkIfSceneEdited, animationText]);

  const buildSceneFromForm = (
    values: Store,
    baseScene: ScenarioSceneTemplate,
    isSlide: boolean
  ): ScenarioSceneTemplate => {
    return {
      index: baseScene.index,
      kindIndex: baseScene.kindIndex,
      id: baseScene?.id,
      title: values.title,
      description: values.description,
      kind: values.kind,
      ...(values.overlayTexts ? { overlayTexts: values.overlayTexts } : {}),
      ...(values.animationText ? { animationText: values.animationText } : {}),
      backgroundMusicVolume: values.backgroundMusicVolume,
      excludeWatermark: isSlide, // The watermark is excluded on slides, included on other kinds
      userVideoAudioVolume: values.userVideoAudioVolume,
      // The following fields need to be reset when saving a slide (exist only for recordable scenes)
      demoVideoUri: !isSlide ? values.demoVideoUri : undefined,
      demoCoverImageSource: !isSlide ? values.demoCoverImageSource : undefined,
      videoRecommendedTime: !isSlide ? values.videoRecommendedTime : undefined,
      videoMinRecordTime: !isSlide ? values.videoMinRecordTime : undefined,
      videoMaxRecordTime: !isSlide ? values.videoMaxRecordTime : undefined,
      ...(!isSlide && values.overlayImage ? { overlayImage: values.overlayImage } : {}),
      ...(!isSlide && values.userPip ? { userPip: values.userPip } : {}),
    };
  };

  // 500 ms after a form field was changed, we call the `onFieldChange` callback with the "temporary" scene corresponding
  // to the unsaved scene
  const [delayedOnFieldChange] = useDebouncedCallback(() => {
    if (selectedScene) {
      onFieldChange(buildSceneFromForm(form.getFieldsValue(), selectedScene, isSlideScene));
    }
  }, 500);

  if (!initialValues) {
    return null;
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 10 },
      xl: { span: 8 },
      xxl: { span: 6, offset: 2 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 12 },
      xl: { span: 12 },
      xxl: { span: 10 },
    },
  };

  const resetOverlayTextsIfNoAnimationText = (sceneAnimationText?: AnimationConfig): void => {
    if (!sceneAnimationText) {
      form.setFieldsValue({ overlayTexts: undefined });
    }
  };

  // If a 'demoVideoUri' is set, we assign the 'thumbnailImage' media associated to the video as the cover
  const assignThumbnailAsCoverIfNotDefined = (allValues: Store): void => {
    if (allValues.demoVideoUri && allValues.demoVideoUri.thumbnailImage) {
      const thumbnail = allValues.demoVideoUri.thumbnailImage;
      const thumbnailCroppedArea = allValues.demoVideoUri.croppedArea;
      form.setFieldsValue({ demoCoverImageSource: { ...thumbnail, croppedArea: thumbnailCroppedArea } });
    }
  };

  // If a 'userPip' is set (serving as demo image), we assign the 'userPip' media as the cover
  const assignUserPipDemoImageAsCoverIfNotDefined = (allValues: Store): void => {
    if (allValues.userPip && allValues.userPip.demoSource) {
      const userPipMedia = allValues.userPip.demoSource;

      form.setFieldsValue({ demoCoverImageSource: { ...userPipMedia, duration: undefined } });
    }
  };

  // If the scene is a slide, the demoMediaType is undefined
  // Else, it is the demoMediaType from the form, or 'VIDEO' if undefined
  const setDemoMediaTypeWithKind = (isSlide: boolean, allValues: Store): void => {
    const demoMediaTypeValue = isSlide ? undefined : allValues.demoMediaType ?? DemoMediaType.VIDEO.code;
    setDemoMediaType(demoMediaTypeValue);
    form.setFieldsValue({
      demoMediaType: demoMediaTypeValue,
      userPip:
        !isSlide && demoMediaType === DemoMediaType.IMAGE.code && allValues.userPip ? allValues.userPip : undefined,
    });
  };

  const onValuesChange = (changedValues: Store, allValues: Store): void => {
    const isSlide = ScenarioUtils.isSlideKind(allValues.kind);
    setIsSlideScene(isSlide);
    setAnimationText(allValues.animationText);

    setDemoMediaTypeWithKind(isSlide, allValues);
    resetOverlayTextsIfNoAnimationText(allValues.animationText);
    // If a video is selected, we use the thumbnail of the video as cover
    assignThumbnailAsCoverIfNotDefined(allValues);
    // If a userPip (demoImage) is selected, we use that same image as cover
    assignUserPipDemoImageAsCoverIfNotDefined(allValues);
    checkIfSceneEdited();

    delayedOnFieldChange();
  };

  const checkOverlayTexts = (rule: RuleObject, val: StoreValue): Promise<void> | void => {
    // If no overlayTexts
    if (!(val && val.length > 0)) {
      return Promise.resolve();
    }
    // If there are some having no text (required)
    if (val.some((overlay: TemplateCameraOverlayText) => !overlay.text)) {
      return Promise.reject(new Error(t('charters.scenarios.variantEditor.sceneForm.rules.overlayTextsRequired')));
    }
    // If there are some whose length is too high (greater than 70 characters)
    if (val.some((overlay: TemplateCameraOverlayText) => overlay.text.length > 70)) {
      return Promise.reject(new Error(t('charters.scenarios.variantEditor.sceneForm.rules.overlayTextsTooLong')));
    }

    return Promise.resolve();
  };

  const checkUserPip = (rule: RuleObject, val: StoreValue): Promise<void> | void => {
    if (!val.demoSource) {
      return Promise.reject(new Error(t('charters.scenarios.variantEditor.sceneForm.rules.userPipDemoSourceRequired')));
    }
    return Promise.resolve();
  };

  const checkVideoMinRecordTimeLessThanMax = (rule: RuleObject, val: StoreValue): Promise<void> | void => {
    const max = formRef.current && form.getFieldValue('videoMaxRecordTime');
    if (max == null || val == null || val < max) {
      return Promise.resolve();
    }

    return Promise.reject(new Error(t('charters.scenarios.variantEditor.sceneForm.rules.videoMinRecordTimeMaxValue')));
  };

  const checkVideoMaxRecordTimeMoreThanMin = (rule: RuleObject, val: StoreValue): Promise<void> | void => {
    const min = formRef.current && form.getFieldValue('videoMinRecordTime');
    if (min == null || val == null || val > min) {
      return Promise.resolve();
    }

    return Promise.reject(new Error(t('charters.scenarios.variantEditor.sceneForm.rules.videoMaxRecordTimeMinValue')));
  };

  const revalidateFormFields = (formFields: string[]): Promise<Store> | undefined => {
    if (!formRef.current) {
      return undefined;
    }
    return form.validateFields(formFields);
  };

  const onReset = (): void => {
    if (formRef.current) {
      setIsSlideScene(ScenarioUtils.isSlideKind(initialValues.kind));
      setAnimationText(initialValues.animationText);
      form.resetFields();
    }
    setIsSelectedSceneEdited(false);
  };

  const onSubmit = (): void => {
    if (!selectedScene || !formRef.current) {
      return;
    }

    form
      .validateFields()
      .then((values) => {
        const sceneDiff = checkIfSceneEdited(true);

        const updatedScene = buildSceneFromForm(values, selectedScene, isSlideScene);
        onUpdateScene(updatedScene, sceneDiff ?? undefined);

        // Close the modal if there is one (mobile behaviour)
        if (isInModal && onCloseSceneModal) {
          onCloseSceneModal();
        }
      })
      .catch((e) => {
        log.error(e);
        message.error(t('charters.scenarios.variantEditor.sceneForm.validationError'));
      });
  };

  // If the `videoRecommendedTime` field exceeds 15, we set a warning on it
  const onVideoRecommendedTimeChange = (value?: number | string | null): void => {
    setIsVideoRecommendedTimeWarning(value ? value > 15 : false);
  };

  // On `kind` change, if the new kind is `Recordable`, we ensure to reset the demo media type as it was initially, so
  // either a video (demoVideoUri) or an image (userPip)
  const onKindChange = (e: RadioChangeEvent): void => {
    const newKind = e.target.value;
    if (!ScenarioUtils.isSlideKind(newKind)) {
      setDemoMediaTypeWithKind(false, initialValues);
      form.setFieldsValue({ userPip: initialValues.userPip, demoVideoUri: initialValues.demoVideoUri });
    }
  };

  const onIsScenePreviewPlayingChange = (isPlaying: boolean) => {
    callBackHandlePlayPause(isPlaying);
  };

  const onPauseScenePreview = () => {
    onIsScenePreviewPlayingChange(false);
  };
  
  const verifyRowsNumber = (e: any) => {
    const textarea = e.target;
    const maxRows = 8;

    const lineCount = textarea.value.split("\n").length;

    if (lineCount > maxRows) {
        // If the number of lines exceeds the limit, prevent further input
        e.preventDefault();
    }
};

  const renderForm = (): JSX.Element => {
    const allowedOverlayImagesForFormat = format
      ? ScenarioUtils.getVariantFormatByCode(format)?.allowedOverlayImages
      : [];

    return (
      <Form
        form={form}
        ref={formRef}
        {...formItemLayout}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
        size="small"
        className={classes.form}
        validateMessages={{
          required: t('charters.scenarios.variantEditor.sceneForm.rules.generalRequired'),
        }}
      >
        <div className={classes.fields}>
          {/* title */}
          <TextInput
            fieldName="title"
            label={t('charters.scenarios.variantEditor.sceneForm.fields.titleLabel')}
            extra={t('charters.scenarios.variantEditor.sceneForm.fields.titleExtra')}
            placeholder={t('charters.scenarios.variantEditor.sceneForm.fields.titlePlaceholder')}
            requiredMessage={t('charters.scenarios.variantEditor.sceneForm.rules.titleRequired')}
            emptyMessage={t('charters.scenarios.variantEditor.sceneForm.rules.titleNotEmpty')}
            hasFeedback={false}
          />
                    {/* description */}

 <Form.Item
                name="description"
                label={t('charters.scenarios.variantEditor.sceneForm.fields.descriptionLabel')}
                rules={[{ required: true }]}
                extra={t('charters.scenarios.variantEditor.sceneForm.fields.descriptionExtra')}
                hasFeedback
              >
              <TextArea
                disabled={false}
                placeholder={t('charters.scenarios.variantEditor.sceneForm.fields.descriptionPlaceholder')}
                onChange={(e)=>verifyRowsNumber(e)}
                autoSize={{ minRows: 1, maxRows: 8 }}
              />
                            </Form.Item>
          

          {/* kind */}
          <Form.Item
            name="kind"
            label={t('charters.scenarios.variantEditor.sceneForm.fields.kindLabel')}
            rules={[{ required: true }]}
            extra={t('charters.scenarios.variantEditor.sceneForm.fields.kindExtra')}
            className={classes.radioGroupFormItem}
          >
            <Radio.Group buttonStyle="solid" onChange={onKindChange}>
              {map(TemplateSequenceKind, (kind) => (
                <Radio.Button key={kind.code} value={kind.code}>
                  {t(kind.key)}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>

          <Form.Item
            hidden
            name="excludeWatermark"
            label="excludeWatermark"
            rules={[{ required: true }]}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          {!isSlideScene && (
            <>
              {/* demoCoverImageSource */}
              <Form.Item hidden name="demoCoverImageSource">
                <MediaSelector fieldLabel="" mediaTypes={[MediaType.IMAGE]} format={format} />
              </Form.Item>

              {/* demoMediaType */}
              <Form.Item
                name="demoMediaType"
                label={t('charters.scenarios.variantEditor.sceneForm.fields.demoMediaTypeLabel')}
                rules={[{ required: true }]}
                extra={t('charters.scenarios.variantEditor.sceneForm.fields.demoMediaTypeExtra')}
                className={classes.radioGroupFormItem}
              >
                <Radio.Group buttonStyle="solid">
                  {map(DemoMediaType, (demoMediaTypeOption) => (
                    <Radio.Button key={demoMediaTypeOption.code} value={demoMediaTypeOption.code}>
                      {t(demoMediaTypeOption.key)}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>

              {/* demoVideoUri */}
              {demoMediaType === DemoMediaType.VIDEO.code && (
                <Form.Item
                  name="demoVideoUri"
                  label={t('charters.scenarios.variantEditor.sceneForm.fields.demoVideoUriLabel')}
                  extra={t('charters.scenarios.variantEditor.sceneForm.fields.demoVideoUriExtra')}
                  rules={[{ required: true }]}
                >
                  <MediaSelector
                    fieldLabel={t('charters.scenarios.variantEditor.sceneForm.fields.demoVideoUriLabel')}
                    mediaTypes={[MediaType.VIDEO]}
                    format={format}
                    onClick={onPauseScenePreview}
                  />
                </Form.Item>
              )}

              {/* userPip */}
              {demoMediaType === DemoMediaType.IMAGE.code && (
                <Form.Item
                  name="userPip"
                  label={t('charters.scenarios.variantEditor.sceneForm.fields.userPipLabel')}
                  extra={t('charters.scenarios.variantEditor.sceneForm.fields.userPipExtra')}
                  rules={[{ validator: checkUserPip }, { required: true }]}
                  validateTrigger={['onSubmit']}
                >
                  <ScenarioSceneUserPip
                    format={format}
                    callbackOnDemoSourceChange={(): void => {
                      revalidateFormFields(['userPip']);
                    }}
                    onMediaSelectorClick={onPauseScenePreview}
                  />
                </Form.Item>
              )}
            </>
          )}

          {/* animationText */}
          <Form.Item
            name="animationText"
            label={t('charters.scenarios.variantEditor.sceneForm.fields.animationTextLabel')}
            extra={t('charters.scenarios.variantEditor.sceneForm.fields.animationTextExtra')}
            rules={[{ required: isSlideScene }]}
            validateTrigger={['onSubmit']}
          >
            <AnimationSelector
              fieldLabel={t('charters.scenarios.variantEditor.sceneForm.fields.animationTextLabel')}
              format={format}
              isSlide={isSlideScene}
              onClick={onPauseScenePreview}
              theme={AnimationTheme.BALI}
            />
          </Form.Item>

          {/* overlayTexts */}
          <Form.Item
            name="overlayTexts"
            label={t('charters.scenarios.variantEditor.sceneForm.fields.overlayTextsLabel')}
            extra={t('charters.scenarios.variantEditor.sceneForm.fields.overlayTextsExtra')}
            rules={[{ validator: checkOverlayTexts }]}
            className={classNames(classes.overlayTextsFormItem, !animationText && classes.hiddenFormItem)}
          >
            <ScenarioSceneOverlayTexts
              selectedAnimationText={animationText}
              initialOverlayTexts={selectedScene?.overlayTexts}
            />
          </Form.Item>

          {/* backgroundMusicVolume */}
          <Form.Item name="backgroundMusicVolume" hidden>
            <InputNumber min={0} max={1} step={0.1} />
          </Form.Item>

          {/* userVideoAudioVolume (hidden field) */}
          <Form.Item name="userVideoAudioVolume" hidden>
            <Select>
              {map(UserVideoAudioVolume, (userVideoAudioVolume) => (
                <Option key={userVideoAudioVolume.code} value={userVideoAudioVolume.value}>
                  {t(userVideoAudioVolume.key)}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* In case of slide scene, hide the overlayImage, userPip, and durations fields */}
          {!isSlideScene && (
            <>
              {/* overlayImage */}
              <Form.Item
                name="overlayImage"
                label={t('charters.scenarios.variantEditor.sceneForm.fields.overlayImageLabel')}
                extra={t('charters.scenarios.variantEditor.sceneForm.fields.overlayImageExtra')}
              >
                <Select
                  placeholder={t('charters.scenarios.variantEditor.sceneForm.fields.overlayImagePlaceholder')}
                  getPopupContainer={(trigger): HTMLElement => trigger.parentNode}
                  allowClear
                  optionLabelProp="label"
                >
                  {map(allowedOverlayImagesForFormat, (overlayImage) => (
                    <Option key={overlayImage.code} value={overlayImage.code} label={t(overlayImage.key)}>
                      {overlayImage.image && (
                        <img
                          alt="Overlay"
                          src={overlayImage.image}
                          className={classNames(
                            classes.overlayImage, // Default style (landscape overlayImages)
                            format === ScenarioFormats.SQUARE_1_1.code && classes.overlayImageSquare, // Style square overlayImages
                            format === ScenarioFormats.PORTRAIT_9_16.code && classes.overlayImagePortrait // Style portrait overlayImages
                          )}
                        />
                      )}
                      {t(overlayImage.key)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Divider>{t('charters.scenarios.variantEditor.sceneForm.fields.durations')}</Divider>

              {/* videoRecommendedTime */}
              <Form.Item
                name="videoRecommendedTime"
                label={t('charters.scenarios.variantEditor.sceneForm.fields.videoRecommendedTime')}
                extra={t('charters.scenarios.variantEditor.sceneForm.fields.videoRecommendedTimeExtra')}
                rules={[{ required: true }]}
                validateStatus={isVideoRecommendedTimeWarning ? 'warning' : undefined}
                help={
                  isVideoRecommendedTimeWarning
                    ? t('charters.scenarios.variantEditor.sceneForm.fields.videoRecommendedTimeWarning', { limit: 15 })
                    : undefined
                }
              >
                <InputNumber min={1} max={1000} onChange={onVideoRecommendedTimeChange} />
              </Form.Item>

              {/* videoMinRecordTime */}
              <Form.Item
                name="videoMinRecordTime"
                label={t('charters.scenarios.variantEditor.sceneForm.fields.videoMinRecordTimeLabel')}
                extra={t('charters.scenarios.variantEditor.sceneForm.fields.videoMinRecordTimeExtra')}
                rules={[{ validator: checkVideoMinRecordTimeLessThanMax }]}
              >
                <InputNumber
                  min={0.5}
                  max={5}
                  step={0.5}
                  placeholder={t('charters.scenarios.variantEditor.sceneForm.fields.videoMinRecordTimePlaceholder')}
                  onChange={(): void => {
                    revalidateFormFields(['videoMinRecordTime', 'videoMaxRecordTime']);
                  }}
                />
              </Form.Item>

              {/* videoMaxRecordTime */}
              <Form.Item
                name="videoMaxRecordTime"
                label={t('charters.scenarios.variantEditor.sceneForm.fields.videoMaxRecordTimeLabel')}
                extra={t('charters.scenarios.variantEditor.sceneForm.fields.videoMaxRecordTimeExtra')}
                rules={[{ validator: checkVideoMaxRecordTimeMoreThanMin }]}
              >
                <InputNumber
                  min={0.5}
                  max={1000}
                  step={0.5}
                  placeholder={t('charters.scenarios.variantEditor.sceneForm.fields.videoMaxRecordTimePlaceholder')}
                  onChange={(): void => {
                    revalidateFormFields(['videoMinRecordTime', 'videoMaxRecordTime']);
                  }}
                />
              </Form.Item>
            </>
          )}
        </div>

        {/* Form buttons (only for web, on mobile they are displayed as modal actions) */}
        {!isInModal && (
          <Col sm={24} md={22} xl={20} xxl={18}>
            <span className={classes.buttonContainer}>
              <Button onClick={onReset} disabled={!isSelectedSceneEdited} size="middle">
                {t('global.reset')}
              </Button>
              <Button
                type="primary"
                onClick={onSubmit}
                disabled={!isSelectedSceneEdited}
                className="updateVariantSceneButton"
                size="middle"
              >
                {t('charters.scenarios.variantEditor.updateScene')}
              </Button>
            </span>
          </Col>
        )}
      </Form>
    );
  };

  const renderScenePreview = () => {
    return (
      <div className={classes.scenePreviewContainer}>
        <ScenarioScenePreview
          scene={sceneManipulatedInForm}
          format={format}
          isPlaying={isScenePreviewPlaying}
          onIsPlayingChange={onIsScenePreviewPlayingChange}
        />
      </div>
    );
  };

  return (
    <div className={classNames(classes.container, 'sceneEditionForm')}>
      {/* Mobile version (modal) */}
      {isInModal ? (
        <Modal
          visible={!!selectedScene}
          onCancel={onCloseSceneModal ?? undefined}
          footer={[
            <Button key="resetSceneButton" onClick={onReset} disabled={!isSelectedSceneEdited}>
              {t('global.reset')}
            </Button>,
            <Button key="updateSceneButton" type="primary" onClick={onSubmit} disabled={!isSelectedSceneEdited}>
              {t('charters.scenarios.variantEditor.updateScene')}
            </Button>,
          ]}
        >
          {renderForm()}
        </Modal>
      ) : (
        <>
          {/* Web version */}
          {renderScenePreview()}

          {renderForm()}
        </>
      )}
    </div>
  );
};

export default ScenarioSceneForm;
