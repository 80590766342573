import React, { FunctionComponent } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const BundlesEdition: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <h2>There is nothing to edit on the bundle yet, this should come soon.</h2>
    </div>
  );
};

export default BundlesEdition;
