import { FormatPainterOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Form, message, Row } from 'antd';
import { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import { cloneDeep, find, isEqual } from 'lodash';
import log from 'loglevel';
import React, { FunctionComponent, Suspense, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import EmptyPageContent from '../../../../components/empty/EmptyPageContent';
import Color from '../../../../components/form/Color';
import InputFloat from '../../../../components/form/InputFloat';
import KannelleLoader from '../../../../components/loader/KannelleLoader';
import KannelleHelpButton from '../../../../components/page-header/KannelleHelpButton';
import KannellePageHeader from '../../../../components/page-header/KannellePageHeader';
import StylesheetLink from '../../../../components/stylesheet-link/StylesheetLink';
import { DEVICE_SIZES_QUERIES, LOGGING_EVENT, THEME, THEME_KEY } from '../../../../Constants';
import useUnsavedChangesNotification from '../../../../hooks/useUnsavedChangesNotification';
import JoyRideHelpChartersTheme from '../../../../onboarding/JoyRideHelpChartersTheme';
import { updateUnsavedChange } from '../../../../redux/action/AppAction';
import { updateCharter } from '../../../../redux/action/ChartersAction';
import { addOrUpdateCharterInCompany } from '../../../../redux/action/CompaniesAction';
import { RootState } from '../../../../redux/RootState';
import { APIManager } from '../../../../services/api/APIManager';
import { updateCharterColors } from '../../../../services/api/ChartersService';
import { getPublicFontFamilies } from '../../../../services/api/FontFamiliesService';
import { APIGetFontFamiliesResponse, FontFamily } from '../../../../services/api/types/FontFamiliesServiceTypes';
import ThemeAnimationUtils from '../../../../utils/ThemeAnimationUtils';
import ThemeUtils, { Theme, ThemeColorKey, ThemeColorKeyDefaultMapping } from '../../../../utils/ThemeUtils';

const { Panel } = Collapse;

const useStyles = createUseStyles({
  pageHeader: {
    padding: 0,
  },
  pageDescription: {
    marginBottom: 16,
    textAlign: 'justify',
    flexDirection: 'column',
    '& > a': {
      display: 'contents',
    },
  },
  avatar: {
    backgroundColor: THEME.DEFAULT.MAIN_COLOR,
  },
  collapse: {
    '& .ant-collapse-content-active': {
      overflow: 'inherit',
    },
  },
  form: {
    width: '100%',
    '& .ant-form-item-label': {
      whiteSpace: 'normal',
    },
  },
  mobileForm: {
    width: '100%',
  },
  formInputNumber: {
    width: '110px',
  },
  actionsContainer: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: 8,
    },
  },
  mobileActionsContainer: {
    flexDirection: 'column',
    '& button': {
      margin: '10px',
    },
  },
  panel: {
    '& .ant-collapse-content-box': {
      padding: 0,
    },
  },
  initializerRow: {
    margin: 'auto',
    width: '100%',

    [`@media screen and ${DEVICE_SIZES_QUERIES.LARGE}`]: {
      maxWidth: '60%',
    },
  },
  helpIcon: {
    marginLeft: 8,
    fontSize: '14px !important',
    '& *': {
      fontSize: '14px !important',
    },
  },
  titleWithIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

const CharterThemeColorsEdition: FunctionComponent = () => {
  const classes = useStyles();
  const [charterThemeColors, setCharterThemeColors] = useState<Theme[]>();
  const [initialCharterThemeColors, setInitialCharterThemeColors] = useState<Theme[]>();
  const [unsavedThemes, setUnsavedThemes] = useState<string[]>([]);
  const [savedSingleTheme, setSavedSingleTheme] = useState<string>();
  const [initializer, setInitializer] = useState<{ [key: string]: string }>();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [runHelp, setRunHelp] = useState(false);
  const [activeKey, setActiveKey] = useState<string[] | string>();
  const [publicFontFamilies, setPublicFontFamilies] = useState<FontFamily[]>();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());

  const charter = useSelector((state: RootState) => state.charters.current);
  const chartersList = useSelector((state: RootState) => state.charters.list);
  const currentCompany = useSelector((state: RootState) => state.companies.current);
  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);
  const unsavedChange = useSelector((state: RootState) => state.app.unsavedChange);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };

  const actionButtonsLayout = {
    wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
  };

  useEffect(() => {
    const cancelTokenSource = cancelTokenSourceRef.current;
    return (): void => {
      cancelTokenSource.cancel('Cancelled updating charter theme colors due to component unmount.');
    };
  }, []);

  // Fetch the Kannelle public fonts
  useEffect(() => {
    const cancelTokenSource = cancelTokenSourceRef.current;
    getPublicFontFamilies(cancelTokenSource)
      .then((response: APIGetFontFamiliesResponse) => {
        const { data } = response;
        setPublicFontFamilies(data.items);
      })
      .catch((e) => {
        log.debug('Error during public fonts fetch', e);
      });
  }, []);

  useEffect(() => {
    // Whenever a single theme was submitted, we only update this saved theme in the charterThemeColors and the initialCharterThemeColors
    const updateSavedSingleTheme = (): void => {
      const newColorsForSavedTheme = find(charterThemeColors, (theme) => theme.code === savedSingleTheme);
      const updatedThemeColors =
        newColorsForSavedTheme &&
        charterThemeColors?.map((theme) => (theme.code === savedSingleTheme ? newColorsForSavedTheme : theme));
      const updatedInitialThemeColors =
        newColorsForSavedTheme &&
        initialCharterThemeColors?.map((theme) => (theme.code === savedSingleTheme ? newColorsForSavedTheme : theme));
      if (updatedThemeColors && updatedInitialThemeColors) {
        setCharterThemeColors(updatedThemeColors);
        setInitialCharterThemeColors(cloneDeep(updatedInitialThemeColors));
      }
      setSavedSingleTheme(undefined);
    };

    if (charter && charter.firmColors) {
      const themesInitializer = ThemeUtils.buildInitialThemeSettingsByBaseColors(charter.firmColors);
      setInitializer(themesInitializer);
      if (charter.themeColors) {
        // If just one theme was submitted, we do not want to reset all other themes
        if (savedSingleTheme) {
          updateSavedSingleTheme();
        } else {
          // Some charters may have missing themes (not yet configured).
          // We add them as if the initializer had been applied on them and mark them
          // as unsaved themes.
          const { completedThemes, addedThemeCodes } = ThemeUtils.completeMissingThemes(
            charter.themeColors,
            themesInitializer
          );

          const completedThemesAndColorKeys = ThemeUtils.completeThemesColorKey(completedThemes, themesInitializer);
          setCharterThemeColors(completedThemesAndColorKeys);
          setInitialCharterThemeColors(cloneDeep(completedThemesAndColorKeys));
          setUnsavedThemes(addedThemeCodes);
        }
      }
    }
    // eslint-disable-next-line
  }, [charter, charter?.themeColors, charter?.firmColors]);

  useUnsavedChangesNotification(
    unsavedThemes && unsavedThemes.length > 0,
    t('charters.themes.unsavedThemesNotification', {
      count: unsavedThemes.length,
      themeList: unsavedThemes
        .map((code): string => ThemeUtils.getThemes().filter((theme) => theme.code === code)[0].label)
        .map((label): string => t(label))
        .join(', '),
    }),
    [unsavedThemes]
  );

  if (!(charter && charterThemeColors)) {
    return <EmptyPageContent />;
  }

  const onInitializerChange = (key: string, newValue: string): void => {
    if (!initializer) {
      return;
    }
    const newInitializer = { ...initializer };
    newInitializer[key] = newValue;
    setInitializer(newInitializer);
  };

  const onValidateThemeInitializer = (): void => {
    if (!initializer) {
      return;
    }
    message.info(
      <>
        {t('charters.themes.initializer.saveThemeColorsWarning1')}
        <br />
        {t('charters.themes.initializer.saveThemeColorsWarning2')}
      </>,
      7
    );

    loggingManager.logEvent(LOGGING_EVENT.INITIALIZE_CHARTER_THEME, {
      colorMajor: initializer[ThemeColorKeyDefaultMapping.COLOR_MAJOR],
      colorMinor: initializer[ThemeColorKeyDefaultMapping.COLOR_MINOR],
      colorSecondary: initializer[ThemeColorKeyDefaultMapping.COLOR_SECONDARY],
      opacityMain: initializer[ThemeColorKeyDefaultMapping.OPACITY_MAIN],
      opacityTitleBackground: initializer[ThemeColorKeyDefaultMapping.OPACITY_TITLE_BACKGROUND],
      opacitySubtitle: initializer[ThemeColorKeyDefaultMapping.OPACITY_SUBTITLE],
      opacitySecondary: initializer[ThemeColorKeyDefaultMapping.OPACITY_SECONDARY],
      opacityChart: initializer[ThemeColorKeyDefaultMapping.OPACITY_CHART],
      companyId: currentCompany?.id,
      charterId: charter.id,
    });
    const newThemeColors = ThemeUtils.buildThemeColorsByInitializer(initializer);
    // Set as 'unsavedThemes' the themes that have been updated with the initializer
    const reinitializedThemes: string[] = [];
    newThemeColors.forEach((newTheme) => {
      const oldTheme = find(charterThemeColors, (theme) => theme.code === newTheme.code);
      if (oldTheme && !isEqual(newTheme, oldTheme)) {
        reinitializedThemes.push(newTheme.code);
      }
    });
    setUnsavedThemes(reinitializedThemes);

    setCharterThemeColors(newThemeColors);
    setInitialCharterThemeColors(cloneDeep(newThemeColors));
  };

  const addUnsavedTheme = (codeOfUnsavedThemeToAdd: string): void => {
    if (!unsavedThemes.includes(codeOfUnsavedThemeToAdd)) {
      setUnsavedThemes([...unsavedThemes, codeOfUnsavedThemeToAdd]);
    }
  };

  const removeUnsavedTheme = (codeOfUnsavedThemeToRemove: string): void => {
    if (unsavedThemes.includes(codeOfUnsavedThemeToRemove)) {
      setUnsavedThemes(unsavedThemes.filter((unsavedTheme: string) => unsavedTheme !== codeOfUnsavedThemeToRemove));
    }
  };

  const onSubmitThemeColors = (): void => {
    const charterToUpdate = chartersList?.find((ch) => ch.id === charter.id);
    if (!(charterThemeColors && charterToUpdate && currentCompany)) {
      return;
    }

    setIsSubmitLoading(true);
    dispatch(updateUnsavedChange(false));
    setUnsavedThemes([]);

    loggingManager.logEvent(LOGGING_EVENT.SAVE_CHARTER_THEME, {
      companyId: currentCompany?.id,
      charterId: charter.id,
    });

    updateCharterColors(charterToUpdate.id, { themeColors: charterThemeColors }, cancelTokenSourceRef.current)
      .then((updatedCharterResponse) => {
        const updatedCharter = { ...charterToUpdate, themeColors: updatedCharterResponse.data.themeColors };
        dispatch(updateCharter(updatedCharter));
        dispatch(addOrUpdateCharterInCompany(currentCompany, updatedCharter));
        message.success(t('charters.themes.saveSuccess'));
        setIsSubmitLoading(false);
      })
      .catch((e) => {
        log.debug(e.message);
        message.error(t('charters.themes.saveError'));
        setIsSubmitLoading(false);
      });
  };

  const onSubmitSingleThemeColors = (themeToSave: ThemeColorKey): void => {
    const codeOfThemeToSave = themeToSave.code;
    const charterToUpdate = chartersList?.find((ch) => ch.id === charter.id);
    const themeToSaveColors = charterThemeColors.find((th) => th.code === codeOfThemeToSave);
    if (!(charterThemeColors && charterToUpdate && currentCompany && initialCharterThemeColors && themeToSaveColors)) {
      return;
    }

    setSavedSingleTheme(themeToSave.code);

    const charterColorsToSave = initialCharterThemeColors.map((theme) =>
      theme.code === codeOfThemeToSave ? themeToSaveColors : theme
    );

    updateCharterColors(charterToUpdate.id, { themeColors: charterColorsToSave }, cancelTokenSourceRef.current)
      .then((updatedCharterResponse) => {
        removeUnsavedTheme(themeToSave.code);
        const updatedCharter = { ...charterToUpdate, themeColors: updatedCharterResponse.data.themeColors };
        dispatch(updateCharter(updatedCharter));
        dispatch(addOrUpdateCharterInCompany(currentCompany, updatedCharter));
        message.success(t('charters.themes.saveSingleThemeSuccess', { themeName: t(themeToSave.label) }));
      })
      .catch((e) => {
        setSavedSingleTheme(undefined);
        log.debug(e.message);
        message.error(t('charters.themes.saveSingleThemeError', { themeName: t(themeToSave.label) }));
      });
  };

  const onResetSingleThemeColors = (codeOfThemeToReset: string): void => {
    if (!initialCharterThemeColors) {
      return;
    }

    removeUnsavedTheme(codeOfThemeToReset);

    const themeToReset = initialCharterThemeColors.find((th) => th.code === codeOfThemeToReset);
    if (themeToReset) {
      const newThemeColors = charterThemeColors.map((theme) =>
        theme.code === codeOfThemeToReset ? themeToReset : theme
      );
      setCharterThemeColors(cloneDeep(newThemeColors));
    }
  };

  const handleChangeCharterColor = (newColors: Theme[], themeCode: string): void => {
    if (!unsavedChange) {
      dispatch(updateUnsavedChange(true));
    }
    addUnsavedTheme(themeCode);

    setCharterThemeColors(newColors);
  };

  const renderInitializePanel = (): React.ReactNode => {
    const colLayout = {
      xs: 24,
      sm: 24,
      md: 12,
      lg: 12,
      xl: 12,
    };

    if (!initializer) {
      return null;
    }

    return (
      <Panel className="initializer-panel" header={t('charters.themes.initializer.title')} key="init" forceRender>
        <Row className={classes.initializerRow}>
          <Col {...colLayout}>
            {['COLOR_MAJOR', 'COLOR_MINOR', 'COLOR_SECONDARY'].map((key) => (
              <Color
                label={t(`charters.themes.initializer.fields.${key}`)}
                key={key}
                color={initializer[key]}
                onEditColor={(newColor): void => onInitializerChange(key, newColor)}
              />
            ))}
          </Col>

          <Col {...colLayout}>
            {['OPACITY_MAIN', 'OPACITY_TITLE_BACKGROUND', 'OPACITY_SUBTITLE', 'OPACITY_SECONDARY', 'OPACITY_CHART'].map(
              (key) => (
                <InputFloat
                  key={key}
                  label={t(`charters.themes.initializer.fields.${key}`)}
                  className={classes.formInputNumber}
                  value={parseFloat(initializer[key])}
                  onChange={(newValue): void => onInitializerChange(key, newValue ? newValue.toString() : '0')}
                />
              )
            )}
          </Col>
        </Row>

        <Form.Item {...actionButtonsLayout}>
          <span className={classNames(classes.actionsContainer, isMobileOrTablet && classes.mobileActionsContainer)}>
            <Button type="primary" className="initializer-apply" onClick={onValidateThemeInitializer}>
              {t('charters.themes.initializer.apply')}
            </Button>
          </span>
        </Form.Item>
      </Panel>
    );
  };

  const startHelp = (): void => {
    setRunHelp(true);
    setActiveKey([]);
  };

  const endHelp = (): void => {
    setRunHelp(false);
    setActiveKey(undefined);
  };

  const togglePanel = (panelKey: string | string[]): void => {
    if (!runHelp) {
      setActiveKey(panelKey);
    }
  };

  const callbackTogglePanel = (panelKey: string): void => {
    if (!activeKey) {
      return;
    }
    const activeKeys: any = activeKey;
    if (activeKeys.includes(panelKey)) {
      setActiveKey(activeKeys.filter((key: string) => key !== panelKey));
    } else if (activeKeys) {
      setActiveKey([...activeKeys, panelKey]);
    }
  };

  const pageDescription = (
    <>
      {t('charters.themes.pageDescription')}
      <div>{t('charters.themes.pageDescriptionInitializer')}</div>
    </>
  );

  return (
    <KannellePageHeader
      title={
        <div className={classes.titleWithIcon}>
          {t('charters.themes.title')}
          <KannelleHelpButton startHelp={startHelp} />
        </div>
      }
      className={classNames(classes.pageHeader, 'theme-container')}
      avatar={{ className: classes.avatar, icon: <FormatPainterOutlined /> }}
    >
      <JoyRideHelpChartersTheme runHelp={runHelp} callbackRunDone={endHelp} callbackTogglePanel={callbackTogglePanel} />
      <Row className={classes.pageDescription}>{pageDescription}</Row>

      <Prompt when={unsavedChange && unsavedThemes.length > 0} message={t('charters.themes.unsavedChangeWarning')} />

      {publicFontFamilies &&
        publicFontFamilies.map((fontFamily) => (
          <StylesheetLink url={fontFamily.stylesheetUrl} key={`public_fontFamily_${fontFamily.id}`} />
        ))}

      <Form
        {...formItemLayout}
        className={classNames(classes.form, isMobileOrTablet && classes.mobileForm)}
        layout="vertical"
      >
        <Collapse
          className={classes.collapse}
          activeKey={activeKey}
          destroyInactivePanel
          onChange={(key): void => {
            togglePanel(key);
          }}
        >
          {/* Initialize Panel */}
          {renderInitializePanel()}

          {/* Theme Panels */}
          {ThemeUtils.getThemes().map((theme) => {
            const Component = ThemeAnimationUtils.getAnimationsComponentByThemeCode(theme.code);
            const animationTheme = find(charterThemeColors, { code: theme.code });

            if (!(Component && animationTheme)) {
              return null;
            }

            const forceRender = runHelp && theme.code === THEME_KEY.ALGIERS.code;
            return (
              <Panel
                className={classNames(classes.panel, theme.code.toLowerCase())}
                header={t(theme.label)}
                key={theme.code}
                forceRender={forceRender}
              >
                <Suspense fallback={<KannelleLoader />}>
                  <Component
                    key={theme.code}
                    theme={theme}
                    charterThemeColors={charterThemeColors}
                    animationThemeColor={animationTheme}
                    callback={(newColors: Theme[]): void => handleChangeCharterColor(newColors, theme.code)}
                    onReset={(): void => onResetSingleThemeColors(theme.code)}
                    onSubmit={(): void => onSubmitSingleThemeColors(theme)}
                    disabledActions={!unsavedThemes.includes(theme.code)}
                    loadingActions={savedSingleTheme ? savedSingleTheme === theme.code : false}
                  />
                </Suspense>
              </Panel>
            );
          })}
        </Collapse>

        <Form.Item {...actionButtonsLayout}>
          <span className={classNames(classes.actionsContainer, isMobileOrTablet && classes.mobileActionsContainer)}>
            <Button
              className="saveThemeColors"
              type="primary"
              loading={isSubmitLoading}
              disabled={isSubmitLoading}
              onClick={onSubmitThemeColors}
            >
              {t('charters.themes.saveThemeColors')}
            </Button>
          </span>
        </Form.Item>
      </Form>
    </KannellePageHeader>
  );
};

export default CharterThemeColorsEdition;
