import { Empty, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import map from 'lodash/map';
import React, { FunctionComponent } from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import PageContentLoader from '../../components/loader/PageContentLoader';
import { ANALYTICS, DEVICE_SIZES_QUERIES, THEME } from '../../Constants';
import {
  APICompanyAnalyticsEventAggregation,
  APICompanyAnalyticsEventForPeriod,
} from '../../services/api/types/CompaniesServiceTypes';
import AnalyticsUtils from '../../utils/AnalyticsUtils';

const useStyles = createUseStyles({
  mainContainer: {
    position: 'relative',
    display: 'block',
    width: '100%',
    marginBottom: 30,
    background: '#FFFFFF',
    borderRadius: 10,
  },
  subContainer: {
    padding: 20,
  },
  chartContainer: {
    width: '90%',
    maxWidth: '1200px',
    margin: '15px auto',
    '& .chartjs-size-monitor': {
      width: '100%',
      height: '100%',
    },
    [`@media screen and ${DEVICE_SIZES_QUERIES.EXTRA_LARGE}`]: {
      width: '100%',
    },
  },
  aggregationContainer: {
    marginBottom: 30,
  },
  aggregationLabel: {
    '&::after': {
      content: '":"',
      position: 'relative',
      top: '-.5px',
      margin: '0 8px 0 2px',
    },
  },
  loader: {
    borderRadius: 10,
    background: '#FFFFFF99',
    height: '100%',
    width: '100%',
    minHeight: 100,
    zIndex: 2,
    position: 'absolute',
  },
});

type Props = {
  eventTitle: string;
  periodEvents?: APICompanyAnalyticsEventForPeriod[];
  onAggregationTypeChange: (e: RadioChangeEvent) => void;
  aggregationType: APICompanyAnalyticsEventAggregation;
  loading?: boolean;
};

const CompanyAnalyticsGlobalGraph: FunctionComponent<Props> = ({
  eventTitle,
  periodEvents,
  onAggregationTypeChange,
  aggregationType,
  loading = false,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const data = React.useMemo(() => {
    if (!periodEvents) {
      return { labels: '', datasets: [] };
    }

    return {
      labels: AnalyticsUtils.getMonthsAsStringsFromPeriodEvents(t, periodEvents).map((i) => i.title),
      datasets: [
        {
          label: eventTitle,
          data: periodEvents.map((eventForPeriod) => eventForPeriod.count),
          fill: false,
          backgroundColor: THEME.DEFAULT.MAIN_COLOR,
          borderColor: THEME.DEFAULT.FADE_COLOR,
          radius: 5,
        },
      ],
    };
  }, [t, periodEvents, eventTitle]);

  const options = React.useMemo(
    () => ({
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: t('analytics.timeInMonths'),
            },
          },
        ],
      },
    }),
    []
  );

  if (!periodEvents && !loading) {
    return <Empty description={t('global.nodata')} />;
  }

  return (
    <div className={classes.mainContainer}>
      {loading && (
        <div className={classes.loader}>
          <PageContentLoader />
        </div>
      )}
      <div className={classes.subContainer}>
        <div className={classes.aggregationContainer}>
          <div className={classes.aggregationLabel}>{t('analytics.aggregation')}</div>
          <Radio.Group buttonStyle="solid" onChange={onAggregationTypeChange} value={aggregationType}>
            {map(ANALYTICS.AGGREGATION_TYPES, ({ key, code }) => (
              <Radio.Button value={code} key={code}>
                {t(key)}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>
        <div className={classes.chartContainer}>
          <Line data={data} options={options} />
        </div>
      </div>
    </div>
  );
};

export default CompanyAnalyticsGlobalGraph;
