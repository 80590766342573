import { InfoCircleOutlined } from '@ant-design/icons';
import { Badge, Card, Empty, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { DEVICE_SIZES_QUERIES, THEME } from '../../../../../Constants';
import useCroppedMedia from '../../../../../hooks/useCroppedMedia';
import ScenarioUtils from '../../../../../utils/ScenarioUtils';
import { ScenarioSceneTemplate } from '../../../../../utils/types/ScenarioTypes';
import ScenarioScenePreview from '../ScenarioScenePreview';

type Props = {
  scene: ScenarioSceneTemplate;
  format: string;
  index: number;
  isSelected?: boolean;
  onSelectScene: () => void;
};

const useStyles = createUseStyles({
  cardContainer: {
    height: '100%',
    width: '220px',
    margin: '0 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      margin: 'auto',
      marginBottom: 16,
      width: '80%',
    },
  },
  sceneCard: {
    width: '100%',
    minHeight: 200,
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',

    '& .ant-card-head': {
      padding: '0 4px',
    },
    '& .ant-card-head-title': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '8px 0',
    },
    '& .ant-card-head-wrapper': {
      height: '100%',
    },
    '& .ant-card-body': {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
    },
  },
  sceneTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 12,
    width: '100%',
  },
  sceneTitle: {
    width: '60%',
    marginLeft: 10,
  },
  ellipsisDiv: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    flex: 1,
    minWidth: 0,
  },
  divNoWrap: {
    whiteSpace: 'nowrap',
  },
  sceneCardTooltipsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 4,
    '& :not(:first-child)': {
      marginLeft: '6px',
    },
  },
  selectedScene: {
    borderRight: `5px solid ${THEME.MENU.MAIN_COLOR}`,
    '& .ant-card-head': {
      backgroundColor: `${THEME.MENU.MAIN_BACKGROUND_COLOR}`,
      color: THEME.MENU.MAIN_TEXT_COLOR,
    },
  },
  coverImageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  coverImage: {
    maxWidth: '100%',
    maxHeight: '120px',
    filter: 'blur(10px)',
    width: '100%',
    objectFit: 'contain',
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      maxHeight: 'none',
    },
  },
  coverImageCropped: {
    animation: '$removeBlur 1s ease-in-out forwards',
  },
  '@keyframes removeBlur': {
    '0%': {
      filter: 'blur(10px)',
    },
    '100%': {
      filter: 'none',
    },
  },
  emptySceneCover: {
    margin: '24px 0',
    justifySelf: 'center',
    alignSelf: 'center',
  },
  sceneIndexBadge: {
    marginTop: 5,
    '& .ant-badge-count': {
      backgroundColor: THEME.DEFAULT.MAIN_TEXT_COLOR,
      boxShadow: 'none',
    },
  },
  selectedSceneIndexBadge: {
    '& .ant-badge-count': {
      backgroundColor: THEME.DEFAULT.MAIN_COLOR,
    },
  },
});

const ScenarioSceneDescriptionCard: FunctionComponent<Props> = ({
  scene,
  index,
  format,
  isSelected = false,
  onSelectScene,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isSceneTitleEllipsisActive, setIsSceneTitleEllipsisActive] = useState(false);
  const [croppedDemoCoverImage, isDemoCoverImageCropLoading] = useCroppedMedia(scene.demoCoverImageSource);

  const isSlideScene = ScenarioUtils.isSlideKind(scene.kind);

  const sceneTitleRef = useCallback(
    (node: HTMLDivElement) => {
      if (node) {
        setIsSceneTitleEllipsisActive(node.offsetWidth < node.scrollWidth);
      }
    },
    [scene]
  );

  const renderSceneTitle = () => {
    const { title } = scene;

    return (
      <div className={classes.sceneTitle}>
        {isSceneTitleEllipsisActive ? (
          <Tooltip title={title} className={classes.ellipsisDiv}>
            <div ref={sceneTitleRef}>{title}</div>
          </Tooltip>
        ) : (
          <div ref={sceneTitleRef} className={classes.divNoWrap}>
            {title}
          </div>
        )}
      </div>
    );
  };

  const renderSceneCover = (): JSX.Element => {
    if (isSlideScene && scene.animationText) {
      return (
        <ScenarioScenePreview
          scene={scene}
          format={format}
          isPlaying={false}
          controls={false}
          initialTime={1.3}
          hideBorders
        />
      );
    }

    return scene.demoCoverImageSource ? (
      <img
        alt={!isDemoCoverImageCropLoading ? 'Demo cover source' : ''}
        className={classNames(classes.coverImage, !isDemoCoverImageCropLoading && classes.coverImageCropped)}
        src={croppedDemoCoverImage?.croppedFileUrl}
      />
    ) : (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={t('charters.scenarios.variantEditor.sceneForm.fields.noDemoCoverImageSource')}
        className={classes.emptySceneCover}
      />
    );
  };

  const renderSceneDescription = (): JSX.Element => {
    return (
      <div onClick={(e): void => e.stopPropagation()}>
        <Tooltip title={scene.description}>
          <span onClick={(e): void => e.stopPropagation()}>
            <InfoCircleOutlined />
          </span>
        </Tooltip>
      </div>
    );
  };

  return (
    <div className={classes.cardContainer}>
      <Card
        title={
          <>
            <div className={classes.sceneTitleContainer}>
              {renderSceneTitle()}
              <div className={classes.sceneCardTooltipsContainer}>{renderSceneDescription()}</div>
            </div>
          </>
        }
        className={classNames(classes.sceneCard, isSelected && classes.selectedScene)}
        onClick={onSelectScene}
      >
        <div className={classes.coverImageContainer}>{renderSceneCover()}</div>
      </Card>

      <Badge
        count={index + 1}
        size="small"
        className={classNames(classes.sceneIndexBadge, isSelected && classes.selectedSceneIndexBadge)}
      />
    </div>
  );
};

export default ScenarioSceneDescriptionCard;
