import { Button, Col, Divider, Empty, message, Modal, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { LOGGING_EVENT } from '../../Constants';
import useSocket from '../../hooks/useSocket';
import {
  fileUploadedAndProcessing,
  refreshProcessingAssetsByType,
  updateAssetProcessingProgression,
  updateProcessingFileOperationId,
} from '../../redux/action/ChartersAction';
import { RootState } from '../../redux/RootState';
import { getCharterAssetSignedUrlToUpload } from '../../services/api/ChartersService';
import { uploadFileToSignedUrl } from '../../services/api/GlobalService';
import { SocketManager } from '../../services/api/SocketManager';
import { Asset, AssetType } from '../../services/api/types/ChartersServiceTypes';
import { AssetOrMediaProcessingResponse } from '../../services/api/types/WebSocketTypes';
import AssetsUtils from '../../utils/AssetsUtils';
import MathUtils from '../../utils/MathUtils';
import ImageFileCard from '../card/ImageFileCard';
import ProcessingFileCard from '../card/ProcessingFileCard';
import VideoOrMusicFileCardPlayer from '../card/VideoOrMusicFileCardPlayer';
import EmptyFilesContent from '../empty/EmptyFilesContent';
import PageContentLoader from '../loader/PageContentLoader';
import FileUploader, { DraggerCustomRequestParams, FileUploaderProps } from '../modal/FileUploader';

type Props = {
  isVisible: boolean;
  isLoading: boolean;
  assetList?: Asset[];
  fieldLabel: string;
  onCancel: () => void;
  assetType: AssetType;
  onSelectAsset: (asset: Asset) => void;
  selectedAsset?: Asset;
  onSubmitAssetSelection?: undefined;
};

const useStyles = createUseStyles({
  modalTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 32,
  },
  assetChoiceModal: {
    width: '75% !important',
    '& .ant-modal-body': {
      paddingTop: '10px',
    },
  },
  empty: {
    width: '100%',
  },
  title: {
    '&.ant-typography': {
      color: '#3B3B3B',
      fontWeight: 400,
      marginTop: 10,
      borderBottom: 'solid 1px #dddddd',
    },
  },
  assetRow: {
    margin: '0 !important',
    paddingBottom: '20px !important',
    maxHeight: '50vh',
    overflow: 'auto',
  },
  archivedAssetMessage: {
    float: 'left',
    fontSize: 12,
    lineHeight: '24px',
  },
});

const AssetSelectorModal: FunctionComponent<Props> = ({
  isVisible,
  isLoading,
  assetList,
  fieldLabel,
  onCancel,
  assetType,
  onSelectAsset,
  selectedAsset,
}: Props) => {
  const [assetPlaying, setAssetPlaying] = useState<Asset>();
  const [room, setRoom] = useState<string>();
  const charter = useSelector((state: RootState) => state.charters.current);
  const company = useSelector((state: RootState) => state.companies.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);
  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);
  const processingAssets = useSelector((state: RootState) => {
    return state.charters.processingAssets;
  });

  // Determine if the select asset is archived or not in the charter
  const isSelectedAssetArchived = useMemo(() => {
    if (!(selectedAsset && assetList && assetList.length > 0)) {
      return false;
    }

    const selectedAssetInList = assetList?.find((asset) => asset.id === selectedAsset.id);
    return selectedAssetInList?.isArchived ?? false;
  }, [assetList, selectedAsset]);

  const isSocketConnected = useSocket(room ?? undefined);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles({ isMobileOrTablet });
  const sizeLimit = AssetsUtils.getSizeLimitByAssetType(assetType);

  const assetFileTypes = useMemo(() => {
    switch (assetType) {
      case AssetType.INTRO:
        return AssetsUtils.getIntroMimeTypeList().join(',');
      case AssetType.OUTRO:
        return AssetsUtils.getOutroMimeTypeList().join(',');
      case AssetType.MUSIC:
        return AssetsUtils.getMusicMimeTypeList().join(',');
      case AssetType.LOGO:
      default:
        return AssetsUtils.getLogoMimeTypeList().join(',');
    }
  }, [assetType]);

  const layoutColProps = useMemo(() => {
    return assetType === AssetType.LOGO
      ? { xs: 24, sm: 24, md: 8, lg: 8, xl: 6, xxl: 4 }
      : { xs: 24, sm: 24, md: 12, lg: 8, xl: 6, xxl: 6 };
  }, [assetType]);

  // Remove processed assets of assetType from the processingAssets in store
  useEffect(() => {
    if (!assetList) {
      return;
    }

    dispatch(refreshProcessingAssetsByType(assetList, assetType));
  }, [assetList, dispatch, assetType]);

  useEffect(() => {
    if (!charter) {
      return;
    }
    setRoom(`charters/${charter.id}/${assetType}`);
  }, [charter, assetType]);

  useEffect(() => {
    const updateProgress = (payload: AssetOrMediaProcessingResponse): void => {
      const { operationId, progress } = payload.data;
      if (progress) {
        dispatch(updateAssetProcessingProgression(operationId, progress, assetType));
      }
    };

    if (isSocketConnected) {
      SocketManager.onMessage(updateProgress);

      return (): void => SocketManager.offMessage(updateProgress);
    }

    return undefined;
  }, [isSocketConnected, assetType, dispatch]);

  const onSizeLimitExceeded = (): void => {
    message.error(t(`charters.${assetType}.uploadSizeLimit`, { limit: sizeLimit }));
  };

  const onUploadStatusChangeToError = (info: UploadChangeParam): void => {
    message.error(t('charters.assets.uploadError', { fileName: info.file.name }));
  };

  const onUploadStatusChangeToDone = (info: UploadChangeParam): void => {
    dispatch(fileUploadedAndProcessing(info.file, assetType));
  };

  const onUploadFile = (requestParam: DraggerCustomRequestParams): void => {
    if (!charter) {
      return;
    }

    const params = {
      charterId: charter.id,
      filename: requestParam.file.name,
      assetType,
    };

    loggingManager.logEvent(LOGGING_EVENT.UPLOAD_ASSET, {
      name: requestParam.file.name,
      size: `${Math.floor(MathUtils.convertBytesToKilobytes(requestParam.file.size))} ${t('charters.assets.KB')}`,
      charterId: charter.id,
      companyId: company?.id,
      mimeType: requestParam.file.type,
    });

    getCharterAssetSignedUrlToUpload(params)
      .then((signedUrl) => {
        return uploadFileToSignedUrl(signedUrl.fileUploadURL, requestParam.file, (event) => {
          requestParam.onProgress({ percent: (event.loaded / event.total) * 100 }, requestParam.file);
        })
          .then(() => {
            requestParam.onSuccess({ ...requestParam, data: signedUrl.metadata });
          })
          .catch((err) => requestParam.onError(err));
      })
      .catch((err) => requestParam.onError(err));
  };

  const onRetryUploadFile = (uploadFile: UploadFile): void => {
    if (!charter) {
      return;
    }

    const fileName = uploadFile.fileName || uploadFile.name;

    const params = {
      charterId: charter.id,
      filename: fileName,
      assetType,
    };

    dispatch(fileUploadedAndProcessing(uploadFile, assetType));

    getCharterAssetSignedUrlToUpload(params)
      .then((signedUrl) => {
        dispatch(
          updateProcessingFileOperationId(
            uploadFile.response.data.operationId,
            signedUrl.metadata.operationId,
            assetType
          )
        );
        return uploadFileToSignedUrl(signedUrl.fileUploadURL, uploadFile.originFileObj).catch((err) =>
          log.error(`An error occurred while retrying to upload an asset: ${err}`)
        );
      })
      .catch((err) => log.error(`An error occurred while retrying to upload an asset: ${err}`));
  };

  const uploaderProps: FileUploaderProps = {
    triggerText: t('charters.assets.upload'),
    modalTitle: t(`charters.${assetType}.uploadModalTitle`),
    uploadInstructionText: t(`charters.${assetType}.uploadTitle`),
    uploadInstructionHint: t(`charters.${assetType}.uploadHint`),
    uploadInstructionSizeLimit: t(`charters.${assetType}.uploadSizeLimit`, { limit: sizeLimit }),
    acceptFileType: assetFileTypes,
    sizeLimit,
    onSizeLimitExceeded,
    onUploadStatusChangeToDone,
    onUploadStatusChangeToError,
    onUploadFile,
  };

  const handleAssetPlayingChange = (isPlaying: boolean, asset: Asset): void => {
    if (isPlaying) {
      setAssetPlaying(asset);
    } else {
      setAssetPlaying(undefined);
    }
  };

  const renderProcessingAssetCards = (): JSX.Element | null => {
    const processingAssetsOfType = processingAssets[assetType];

    if (!(processingAssetsOfType && processingAssetsOfType.length > 0)) {
      return null;
    }
    return (
      <>
        <Title className={classes.title} level={5}>
          {t(`charters.${assetType}.processing`)}
        </Title>

        <Row gutter={[16, 16]} className={classes.assetRow}>
          {processingAssetsOfType.map((uploadFile: UploadFile) => {
            return (
              <Col key={uploadFile.uid} {...layoutColProps}>
                <ProcessingFileCard
                  uploadFile={uploadFile}
                  onRetryUploadFile={(): void => onRetryUploadFile(uploadFile)}
                  processingMessage={t('charters.assets.processing')}
                  processingErrorMessage={t('charters.assets.processingError')}
                  trigger="button"
                />
              </Col>
            );
          })}
        </Row>

        <Divider />
      </>
    );
  };

  const renderAssetCards = (): JSX.Element => {
    const assetListWithoutArchived = assetList?.filter((asset) => !asset.isArchived);

    if (!(assetListWithoutArchived && assetListWithoutArchived.length > 0)) {
      return <Empty className={classes.empty} image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('global.nodata')} />;
    }

    return (
      <Row gutter={[16, 16]} className={classes.assetRow}>
        {assetListWithoutArchived.map((asset) => {
          let isSelected = false;
          if (selectedAsset) {
            isSelected = asset.id === selectedAsset.id;
          }
          return (
            <Col key={asset.id} {...layoutColProps}>
              {assetType === AssetType.LOGO ? (
                <ImageFileCard
                  imageFile={asset}
                  selected={isSelected}
                  disabled={isSelected}
                  trigger="button"
                  callbackOnSelect={(): void => onSelectAsset(asset)}
                />
              ) : (
                <VideoOrMusicFileCardPlayer
                  videoOrMusicFile={asset}
                  videoOrMusicPlaying={assetPlaying}
                  callbackPlaying={(isPlaying: boolean): void => handleAssetPlayingChange(isPlaying, asset)}
                  selected={isSelected}
                  disabled={isSelected}
                  trigger="button"
                  callbackOnSelect={(): void => onSelectAsset(asset)}
                />
              )}
            </Col>
          );
        })}
      </Row>
    );
  };

  const renderUploadedAndProcessingAssetCards = () => {
    if (isLoading) {
      return <PageContentLoader />;
    }

    // If no asset of the assetType and none processing for that same assetType, display the empty page with the uploader
    if (!assetList && !(processingAssets && processingAssets[assetType].length > 0)) {
      return <EmptyFilesContent emptyMessage={t('charters.assets.noAssets')} {...uploaderProps} />;
    }

    return (
      <>
        {renderProcessingAssetCards()}
        {renderAssetCards()}
      </>
    );
  };

  const renderModalTitle = () => {
    return (
      <div className={classes.modalTitleContainer}>
        {fieldLabel}
        <FileUploader {...uploaderProps} />
      </div>
    );
  };

  const renderMessageAboutArchivedAsset = () => {
    return (
      <div key="archivedAssetMessage" className={classes.archivedAssetMessage}>
        {t(`charters.${assetType}.selectedAssetIsArchived`)}
      </div>
    );
  };

  return (
    <Modal
      title={renderModalTitle()}
      visible={isVisible}
      onCancel={onCancel}
      footer={[
        ...(isSelectedAssetArchived ? [renderMessageAboutArchivedAsset()] : []),
        <Button key="cancelModal" onClick={onCancel}>
          {t('global.cancel')}
        </Button>,
      ]}
      className={classes.assetChoiceModal}
    >
      {renderUploadedAndProcessingAssetCards()}
    </Modal>
  );
};

export default AssetSelectorModal;
