import { Form, Switch } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { THEME } from '../../Constants';

const useStyles = createUseStyles({
  main: {
    color: THEME.DEFAULT.MAIN_TEXT_COLOR,
  },
  switch: {
    marginRight: '8px !important',
  },
});

type Props = {
  name: string;
  isChecked: boolean;
  activeLabel?: string;
  inactiveLabel?: string;
  callbackChange: (value: boolean) => void;
  label?: string;
  extra?: string;
};

const InputSwitch: FunctionComponent<Props> = ({
  name,
  isChecked,
  callbackChange,
  label,
  extra,
  activeLabel,
  inactiveLabel,
}: Props) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(isChecked);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  const info = checked ? <span className={classes.main}>{activeLabel}</span> : <span>{inactiveLabel}</span>;

  const handleChange = (): void => {
    const newValue = !checked;
    setChecked(newValue);
    callbackChange(newValue);
  };

  return (
    <Form.Item
      label={label}
      name={name}
      rules={[{ required: true }]}
      extra={extra}
      validateStatus="success"
      valuePropName="checked"
      hasFeedback
    >
      <span>
        <Switch checked={checked} className={classes.switch} onChange={handleChange} />
        {info}
      </span>
    </Form.Item>
  );
};

export default InputSwitch;
