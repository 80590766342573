import { Button, Cascader, Divider, Form, InputNumber, Popconfirm, Select, Switch } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { diff } from 'deep-object-diff';
import { isEqual, map } from 'lodash';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTrashAlt } from 'react-icons/fa';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import SliderWithInput from '../../../../../../components/form/SliderWithInput';
import MediaSelector from '../../../../../../components/media-selector/MediaSelector';
import { PROJECTS } from '../../../../../../Constants';
import { RootState } from '../../../../../../redux/RootState';
import { MediaCroppedArea, MediaFile } from '../../../../../../services/api/types/ChartersServiceTypes';
import CompaniesUtils from '../../../../../../utils/CompaniesUtils';
import MathUtils from '../../../../../../utils/MathUtils';
import ProjectUtils from '../../../../../../utils/ProjectUtils';
import { PipEffects, PipPositionCode, PipPositions } from '../../../../../../utils/types/AnimationTypes';
import { MediaType } from '../../../../../../utils/types/MediaTypes';
import { ProjectScene, ProjectSceneElement, ProjectSceneElements } from '../../../../../../utils/types/ProjectTypes';

type Props = {
  initialScene: ProjectScene;
  scene: ProjectScene;
  isSceneEdited: boolean;
  isSceneInError: boolean;
  format?: string;
  sceneDuration: number;
  onEditScene: (updatedScene: ProjectScene) => void;
  onSubmitScene: (updatedScene: ProjectScene) => void;
  onIsSceneEditedChange: (isEdited: boolean) => void;
  onSelectElement: (e: ProjectSceneElement, skipConfirm?: boolean) => void;
  onDeleteSceneElement: () => void;
};

const useStyles = createUseStyles({
  form: {
    height: '80%',
    overflow: 'auto',
    paddingRight: 8,
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '6px',
      backgroundColor: 'rgb(123, 123, 123)',
      boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
    },
    '&::-webkit-scrollbar-track-piece': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      borderRadius: '6px',
    },

    '& .ant-form-item-label': {
      padding: 0,
    },
    '& .ant-form-item': {
      marginBottom: 10,
    },
  },
  actionsContainer: {
    position: 'absolute',
    bottom: 10,
    right: 0,
    '& > button:not(:last-child)': {
      marginRight: 10,
    },
  },
  deletePipButton: {
    position: 'absolute',
    bottom: 10,
    '& .ant-btn': {
      lineHeight: 1,
    },
  },
});

const ProjectScenePipOptions: FunctionComponent<Props> = ({
  initialScene,
  scene,
  isSceneEdited,
  isSceneInError,
  format,
  sceneDuration,
  onEditScene,
  onIsSceneEditedChange,
  onSubmitScene,
  onSelectElement,
  onDeleteSceneElement,
}: Props) => {
  const [initialValues, setInitialValues] = useState<Store>();
  const [isMediaSet, setIsMediaSet] = useState(initialScene.pip?.media !== undefined);
  const [positionCode, setPositionCode] = useState(initialScene.pip?.positionCode);

  const isSlideScene = ProjectUtils.isSlideScene(scene);

  const { t } = useTranslation();
  const classes = useStyles();
  const [form] = Form.useForm();

  const companies = useSelector((state: RootState) => state.companies.list);
  const isKnlTeam = companies ? CompaniesUtils.checkIsKnlProfile(companies) : false;

  const hasSceneBackgroundVideo = scene.backgroundVideo !== undefined;
  let backgroundVideoDuration: number | undefined;
  if (scene.backgroundVideo) {
    if (scene.backgroundVideo?.trimStartAt !== undefined && scene.backgroundVideo.trimEndAt !== undefined) {
      backgroundVideoDuration = scene.backgroundVideo.trimEndAt - scene.backgroundVideo.trimStartAt;
    } else {
      backgroundVideoDuration = sceneDuration;
    }
  }

  // Build the options for the PIP effect cascader (effect name and value))
  const pipEffectOptions = useMemo(() => {
    return map(PipEffects, (pipEffect) => {
      return {
        value: pipEffect.code,
        label: t(pipEffect.key),
        children: map(pipEffect.allowedValues, (allowedValue) => ({
          value: allowedValue.value,
          label: t(allowedValue.key),
        })),
      };
    });
  }, [PipEffects, t]);

  useEffect(() => {
    // Default PIP effect is a soft ZOOMIN
    const defaultPipEffect = [PipEffects.ZOOMIN.code, PipEffects.ZOOMIN.allowedValues?.soft.value];

    const isInitialNonePipEffect = initialScene.pip?.effectName === PipEffects.NONE.code;
    const initialPipEffect =
      isInitialNonePipEffect || (initialScene.pip?.effectName && initialScene.pip?.effectValue)
        ? [initialScene.pip!.effectName, initialScene.pip?.effectValue]
        : defaultPipEffect;

    const initialPipDuration =
      scene.pip?.media?.mediaType === MediaType.IMAGE
        ? (initialScene?.pip?.duration ?? ProjectUtils.computeInitialPipDurationOverVideo(backgroundVideoDuration)) ||
          PROJECTS.PIP.PIP_ONLY_DEFAULT_DURATION
        : initialScene?.pip?.duration ?? (scene.pip?.media?.duration || PROJECTS.PIP.PIP_ONLY_DEFAULT_DURATION);

    const initialPipDelay =
      scene.pip?.media?.mediaType === MediaType.IMAGE
        ? (initialScene?.pip?.delay ?? ProjectUtils.computeInitialPipDelayOverVideo(backgroundVideoDuration)) || 0
        : initialScene?.pip?.delay ?? 0;

    const initialPipTrimStart = initialScene?.pip?.trimStartAt ?? 0;
    const initialPipTrimEnd = (initialScene?.pip?.trimEndAt ?? initialScene?.pip?.media?.duration) || 0;

    const initialPipPositionCode = initialScene?.pip?.positionCode ?? PipPositionCode.FULL_SCREEN;
    const initialPipPositionValue = initialScene?.pip?.positionValue ?? 0;
    const initialPipSize = initialScene?.pip?.size ?? 1;

    const formInitialValues = {
      scenePipMedia: initialScene?.pip?.media,
      scenePipEffect: initialPipEffect,
      scenePipDuration: initialPipDuration,
      scenePipDelay: initialPipDelay,
      scenePipTrimStart: initialPipTrimStart,
      scenePipTrimEnd: initialPipTrimEnd,
      scenePipPositionCode: initialPipPositionCode,
      scenePipPositionValue: initialPipPositionValue,
      scenePipSize: initialPipSize,
      scenePipVideoAudioVolume: initialScene?.pip?.audioVolume ?? 0,
    };

    setInitialValues(formInitialValues);
    form.setFieldsValue(formInitialValues);
  }, [initialScene, form, backgroundVideoDuration]);

  const checkIsCroppedAreaEquals = (initialCroppedArea: MediaCroppedArea, updatedCroppedArea: MediaCroppedArea) => {
    return (
      initialCroppedArea.x === updatedCroppedArea.x &&
      initialCroppedArea.y === updatedCroppedArea.y &&
      initialCroppedArea.width === updatedCroppedArea.width &&
      initialCroppedArea.height === updatedCroppedArea.height
    );
  };

  // Callback to see if the PIP options have been changed and differ from the ones of the
  // initialScene
  const [checkIfScenePipEdited] = useDebouncedCallback(() => {
    // Get the initial scene values
    const initialPipMedia = initialScene.pip?.media;
    const initialPipEffectName = initialScene.pip?.effectName;
    const initialPipEffectValue = initialScene.pip?.effectValue;
    const initialPipTrimStartAt = initialScene.pip?.trimStartAt || 0;
    const initialPipTrimEndAt = initialScene.pip?.trimEndAt || 0;
    const initialPipDuration =
      initialScene.pip?.media?.mediaType === MediaType.IMAGE
        ? initialScene.pip?.duration
        : initialPipTrimEndAt - initialPipTrimStartAt;
    const initialPipDelay = initialScene.pip?.delay;
    const initialPipAudioVolume = initialScene.pip?.audioVolume;
    const initialPipPositionCode = initialScene?.pip?.positionCode;
    const initialPipPositionValue = initialScene.pip?.positionValue;
    const initialPipSize = initialScene.pip?.size;

    // Get the field values
    const scenePipMedia = form.getFieldValue('scenePipMedia');
    const scenePipEffect = form.getFieldValue('scenePipEffect');
    const scenePipEffectName = scenePipEffect ? scenePipEffect[0] : undefined;
    const scenePipEffectValue = scenePipEffect ? scenePipEffect[1] : undefined;
    const scenePipDuration = form.getFieldValue('scenePipDuration');
    const scenePipDelay = form.getFieldValue('scenePipDelay');
    const scenePipTrimStart = form.getFieldValue('scenePipTrimStart');
    const scenePipTrimEnd = form.getFieldValue('scenePipTrimEnd');
    const scenePipPositionCode = form.getFieldValue('scenePipPositionCode');
    const scenePipPositionValue = form.getFieldValue('scenePipPositionValue');
    const scenePipSize = form.getFieldValue('scenePipSize');
    const scenePipVideoAudioVolume = form.getFieldValue('scenePipVideoAudioVolume') ? 1 : 0;

    // Compare the initial scene PIP params with the ones from the form
    const pipCroppedAreaIsEqual =
      initialPipMedia && scenePipMedia && initialPipMedia.croppedArea && scenePipMedia.croppedArea
        ? checkIsCroppedAreaEquals(initialPipMedia.croppedArea, scenePipMedia.croppedArea)
        : false;
    const pipMediaIsEqual =
      initialPipMedia && scenePipMedia ? isEqual(initialPipMedia.id, scenePipMedia.id) && pipCroppedAreaIsEqual : false;
    const pipEffectNameIsEqual =
      scene.pip?.media?.mediaType === MediaType.IMAGE ? initialPipEffectName === scenePipEffectName : true;
    const pipEffectValueIsEqual =
      scene.pip?.media?.mediaType === MediaType.IMAGE ? initialPipEffectValue === scenePipEffectValue : true;
    const pipDurationIsEqual = initialPipDuration === scenePipDuration;
    const pipDelayIsEqual = initialPipDelay === scenePipDelay;
    const pipTrimStartAtIsEqual =
      scene.pip?.media?.mediaType === MediaType.VIDEO ? initialPipTrimStartAt === scenePipTrimStart : true;
    const pipTrimEndAtIsEqual =
      scene.pip?.media?.mediaType === MediaType.VIDEO ? initialPipTrimEndAt === scenePipTrimEnd : true;
    const pipAudioVolumeIsEqual =
      scene.pip?.media?.mediaType === MediaType.VIDEO ? initialPipAudioVolume === scenePipVideoAudioVolume : true;
    const pipPositionCodeIsEqual = initialPipPositionCode === scenePipPositionCode;
    const pipPositionValueIsEqual = initialPipPositionValue === scenePipPositionValue;
    const pipSizeIsEqual = initialPipSize === scenePipSize;

    // Check if the PIP was edited
    const wasNotEdited =
      pipMediaIsEqual &&
      pipEffectNameIsEqual &&
      pipEffectValueIsEqual &&
      pipDurationIsEqual &&
      pipDelayIsEqual &&
      pipTrimStartAtIsEqual &&
      pipTrimEndAtIsEqual &&
      pipAudioVolumeIsEqual &&
      pipPositionCodeIsEqual &&
      pipPositionValueIsEqual &&
      pipSizeIsEqual;
    if (wasNotEdited) {
      onIsSceneEditedChange(false);
    } else {
      onIsSceneEditedChange(true);
      // Log the difference for KnlTeam (ease of debug)
      if (isKnlTeam) {
        console.groupCollapsed('ℹ️ PIP was edited');
        console.log(
          'PIP media difference: ',
          !pipMediaIsEqual ? diff(initialPipMedia ?? {}, scenePipMedia) : undefined
        );
        console.log(
          'PIP effect difference: ',
          !(pipEffectNameIsEqual && pipEffectValueIsEqual)
            ? `initial value (${initialPipEffectName} ${initialPipEffectValue}) ≠ form value (${scenePipEffectName} ${scenePipEffectValue})`
            : undefined
        );
        console.log(
          'PIP duration difference: ',
          !pipDurationIsEqual ? `initial value (${initialPipDuration}) ≠ form value (${scenePipDuration})` : undefined
        );
        console.log(
          'PIP delay difference: ',
          !pipDelayIsEqual ? `initial value (${initialPipDelay}) ≠ form value (${scenePipDelay})` : undefined
        );
        console.log(
          'PIP position code difference: ',
          !pipPositionCodeIsEqual
            ? `initial value (${initialPipPositionCode}) ≠ form value (${scenePipPositionCode})`
            : undefined
        );
        console.log(
          'PIP position value difference: ',
          !pipPositionValueIsEqual
            ? `initial value (${initialPipPositionValue}) ≠ form value (${scenePipPositionValue})`
            : undefined
        );
        console.log(
          'PIP size difference: ',
          !pipSizeIsEqual ? `initial value (${initialPipSize}) ≠ form value (${scenePipSize})` : undefined
        );

        console.groupEnd();
      }
    }
  }, 250);

  useEffect(() => {
    if (scene.pip?.delay === undefined) {
      return;
    }

    if (scene.pip?.media?.mediaType === MediaType.IMAGE) {
      form.setFieldsValue({
        scenePipDelay: scene.pip?.delay ?? 0,
        scenePipDuration: scene.pip?.duration ?? PROJECTS.PIP.PIP_ONLY_DEFAULT_DURATION,
      });
    } else {
      form.setFieldsValue({
        scenePipDelay: scene.pip?.delay ?? 0,
        scenePipDuration: scene.pip?.duration ?? PROJECTS.PIP.PIP_ONLY_DEFAULT_DURATION,
        scenePipTrimStart: scene.pip?.trimStartAt ?? 0,
        scenePipTrimEnd: scene.pip?.trimEndAt ?? PROJECTS.PIP.PIP_ONLY_DEFAULT_DURATION,
      });
    }

    checkIfScenePipEdited();
  }, [scene.pip?.delay, scene.pip?.duration, scene.pip?.trimStartAt, scene.pip?.trimEndAt]);

  // If there is no PIP media and the media selector is canceled, we select the RECORDABLE_VIDEO element in the timeline
  const callbackOnCancelPipMediaModal = () => {
    const scenePipMedia = form.getFieldValue('scenePipMedia');
    if (!scenePipMedia && hasSceneBackgroundVideo) {
      onSelectElement(ProjectSceneElements.RECORDABLE_VIDEO, true);
    }
  };

  const buildSceneFromForm = (isReset = false) => {
    const fields = form.getFieldsValue();

    const newPipDelayValue = () => {
      const delayFromForm = form.getFieldValue('scenePipDelay');

      if (isReset) {
        return initialScene.pip?.delay;
      }

      // No delay on PIP-only scenes with no background video
      if (!hasSceneBackgroundVideo) {
        return 0;
      }

      if (delayFromForm && backgroundVideoDuration && delayFromForm > backgroundVideoDuration) {
        return backgroundVideoDuration;
      }

      return delayFromForm ?? 0;
    };

    const fieldScenePipMedia: MediaFile = fields.scenePipMedia;
    const [pipEffectName, pipEffectValue] =
      fieldScenePipMedia?.mediaType === MediaType.VIDEO ? [] : fields.scenePipEffect ?? [];

    let delay = newPipDelayValue();
    let duration =
      fieldScenePipMedia?.mediaType === MediaType.VIDEO
        ? fields.scenePipTrimEnd - fields.scenePipTrimStart
        : fields.scenePipDuration;
    let trimStartAt = fieldScenePipMedia?.mediaType === MediaType.VIDEO ? fields.scenePipTrimStart : undefined;
    let trimEndAt = fieldScenePipMedia?.mediaType === MediaType.VIDEO ? fields.scenePipTrimEnd : undefined;
    let audioVolume = fieldScenePipMedia?.mediaType === MediaType.VIDEO && fields.scenePipVideoAudioVolume ? 1 : 0;

    if (
      (!isMediaSet && fieldScenePipMedia?.mediaType === MediaType.VIDEO) ||
      (scene.pip &&
        scene.pip?.media?.mediaType &&
        scene.pip.media?.mediaType !== fieldScenePipMedia?.mediaType &&
        fieldScenePipMedia?.mediaType === MediaType.VIDEO) ||
      (isMediaSet &&
        fieldScenePipMedia?.mediaType === MediaType.VIDEO &&
        fieldScenePipMedia?.id !== scene.pip?.media.id)
    ) {
      if (isReset) {
        duration = initialScene.pip?.duration;
        trimStartAt = initialScene.pip?.trimStartAt;
        trimEndAt = initialScene.pip?.trimEndAt;
        audioVolume = initialScene.pip?.audioVolume || 0;
        delay = initialScene.pip?.delay;
      } else {
        duration = fieldScenePipMedia?.duration;
        trimStartAt = 0;
        trimEndAt = fieldScenePipMedia?.duration;
        audioVolume = 0;
        delay = 0;
      }

      setIsMediaSet(true);
    } else if (
      (!isMediaSet && fieldScenePipMedia?.mediaType === MediaType.IMAGE) ||
      (scene.pip &&
        scene.pip?.media?.mediaType &&
        scene.pip.media?.mediaType !== fieldScenePipMedia?.mediaType &&
        fieldScenePipMedia?.mediaType === MediaType.IMAGE)
    ) {
      duration = ProjectUtils.computeInitialPipDurationOverVideo(backgroundVideoDuration);
      delay = ProjectUtils.computeInitialPipDelayOverVideo(backgroundVideoDuration);
      setIsMediaSet(true);
    }

    // If the position is `FULL_SCREEN` or `CENTER`, or in case of a PIP-only, the position value is not editable
    const computeNewPipPositionValue = () => {
      const pipPositionCode = fields.scenePipPositionCode;

      if (!hasSceneBackgroundVideo || [PipPositionCode.FULL_SCREEN, PipPositionCode.CENTER].includes(pipPositionCode)) {
        return PROJECTS.PIP.PIP_DEFAULT_POSITION;
      }

      return fields.scenePipPositionValue;
    };

    // If the position is `FULL_SCREEN` or in case of a PIP-only, the size is not editable
    const computeNewPipSize = () => {
      const pipPositionCode = fields.scenePipPositionCode;

      if (!hasSceneBackgroundVideo || pipPositionCode === PipPositionCode.FULL_SCREEN) {
        return PROJECTS.PIP.PIP_DEFAULT_SIZE;
      }

      return fields.scenePipSize;
    };

    return {
      ...scene,
      pip: {
        ...scene.pip,
        media: fields.scenePipMedia,
        positionCode: hasSceneBackgroundVideo ? fields.scenePipPositionCode : PipPositionCode.FULL_SCREEN,
        positionValue: computeNewPipPositionValue(),
        size: computeNewPipSize(),
        effectName: pipEffectName,
        effectValue: pipEffectValue,
        duration,
        delay,
        trimStartAt,
        trimEndAt,
        audioVolume,
      },
    };
  };

  const buildAndEditScene = (isReset = false) => {
    const updatedScene = buildSceneFromForm(isReset);
    onEditScene(updatedScene);
  };

  const onReset = (): void => {
    form.resetFields();
    onIsSceneEditedChange(false);

    // Reset the changes to the project scene being edited
    buildAndEditScene(true);
    // Cancel the PIP if needed and select the video element in the timeline
    callbackOnCancelPipMediaModal();
  };

  const onSubmit = (): void => {
    form
      .validateFields()
      .then(() => {
        const updatedScene = buildSceneFromForm();

        if (!updatedScene) {
          return;
        }

        onSubmitScene(updatedScene);
        onIsSceneEditedChange(false);
      })
      .catch((e) => {
        log.error(e);
      });
  };

  const onDeletePip = (): void => {
    const updatedScene = {
      ...scene,
      pip: undefined,
    };
    onReset();
    onSubmitScene(updatedScene);
    onDeleteSceneElement();
    if (hasSceneBackgroundVideo) {
      onSelectElement(ProjectSceneElements.RECORDABLE_VIDEO, true);
    }
  };

  // If the PIP duration has been erased, we force it to the minimal acceptable duration (0.5 sec)
  const handlePipDurationChange = (value: number) => {
    if (value <= 0 || value === null) {
      form.setFieldsValue({
        scenePipDuration: PROJECTS.PIP.PIP_MIN_DURATION,
      });
    }
  };

  // If the PIP delay has been erased, we force it to the minimal acceptable delay (0 sec)
  const handlePipDelayChange = (value: number) => {
    if (value < 0 || value === null) {
      form.setFieldsValue({
        scenePipDelay: 0,
      });
    }
  };

  // If the PIP position was changed, ensure to correctly update the other PIP parameters (positionValue and size)
  const handlePipPositionCodeChange = (value: PipPositionCode) => {
    // If the positionCode was not changed, do nothing
    if (!value) {
      return;
    }

    // If the positionCode was changed to `FULL_SCREEN`, ensure to reset the positionValue and size to the default values
    if (value === PipPositionCode.FULL_SCREEN) {
      form.setFieldsValue({
        scenePipSize: PROJECTS.PIP.PIP_DEFAULT_SIZE,
        scenePipPositionValue: PROJECTS.PIP.PIP_DEFAULT_POSITION,
      });

      // Update the state tracking the last position code
      setPositionCode(value);
      return;
    }

    // If the positionCode was changed to `CENTER`, ensure to reset the positionValue to the default value (the size is still editable)
    if (value === PipPositionCode.CENTER) {
      form.setFieldsValue({
        scenePipPositionValue: PROJECTS.PIP.PIP_DEFAULT_POSITION,
      });
    }

    // If the previous positionCode was `FULL_SCREEN` and the positionCode has been changed, the size is set to `0.5` as default value
    if (positionCode === PipPositionCode.FULL_SCREEN) {
      form.setFieldsValue({
        scenePipSize: 0.5,
      });
    }

    setPositionCode(value);
  };

  const onValuesChange = (changedValues: Store): void => {
    if (isSlideScene) {
      return;
    }

    // Update the PIP duration if needed
    handlePipDurationChange(changedValues.scenePipDuration);

    // Update the PIP delay if needed
    handlePipDelayChange(changedValues.scenePipDelay);

    // Update the PIP position and size if needed
    handlePipPositionCodeChange(changedValues.scenePipPositionCode);

    // Check if the values in the form differ from the initial scene ones
    checkIfScenePipEdited();

    // Delayed callback when one field gets updated
    buildAndEditScene();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      className={classes.form}
      size="small"
      initialValues={initialValues}
      onValuesChange={onValuesChange}
    >
      <Form.Item
        name="scenePipMedia"
        label={t('charters.projects.projectEditor.sceneEditor.fields.pip.mediaLabel')}
        rules={[{ required: true }]}
      >
        <MediaSelector
          fieldLabel={t('charters.projects.projectEditor.sceneEditor.fields.pip.mediaLabel')}
          mediaTypes={[MediaType.IMAGE, MediaType.VIDEO]}
          format={format}
          disableReset
          defaultVisible={!(scene.pip && scene.pip.media)}
          enableWebcamRecording
          webcamRecordingOptions={{
            recommendedTime: scene.videoRecommendedTime,
            overlayImage: scene.overlay?.phoneUrl,
          }}
          defaultMediaType={scene.pip?.media?.mediaType}
          callbackOnCancelModal={callbackOnCancelPipMediaModal}
          disabledTabMessages={
            scene.backgroundVideo === undefined
              ? [
                  {
                    mediaType: MediaType.VIDEO,
                    message: t('charters.projects.projectEditor.sceneEditor.fields.pip.backgroundVideoDisabled'),
                  },
                ]
              : []
          }
        />
      </Form.Item>

      <Form.Item
        name="scenePipEffect"
        label={t('charters.projects.projectEditor.sceneEditor.fields.pip.effectLabel')}
        rules={[{ required: true }]}
        hidden={scene.pip?.media?.mediaType !== MediaType.IMAGE}
      >
        <Cascader options={pipEffectOptions} />
      </Form.Item>

      {scene.pip?.media?.mediaType === MediaType.VIDEO && (
        <Form.Item
          name="scenePipVideoAudioVolume"
          label={t('charters.projects.projectEditor.sceneEditor.fields.pip.audioVolumeLabel')}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      )}
      <Form.Item
        name="scenePipPositionCode"
        label={t('charters.projects.projectEditor.sceneEditor.fields.pip.positionCodeLabel')}
        rules={[{ required: true }]}
        // Hidden for PIP-only
        hidden={!hasSceneBackgroundVideo}
      >
        <Select getPopupContainer={(trigger): HTMLElement => trigger.parentNode}>
          {map(PipPositions, (pipPositionCode) => (
            <Select.Option key={pipPositionCode.code} value={pipPositionCode.code}>
              {t(pipPositionCode.key)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="scenePipPositionValue"
        rules={[{ required: true }]}
        // The position is not editable for FULL_SCREEN or CENTER pip, and is hidden in case of PIP-only
        hidden={
          !hasSceneBackgroundVideo ||
          [PipPositionCode.FULL_SCREEN, PipPositionCode.CENTER].includes(form.getFieldValue('scenePipPositionCode'))
        }
      >
        <SliderWithInput min={0} max={0.25} step={0.05} />
      </Form.Item>

      <Form.Item
        name="scenePipSize"
        label={t('charters.projects.projectEditor.sceneEditor.fields.pip.sizeLabel')}
        rules={[{ required: true }]}
        // The size is not editable for FULL_SCREEN pip, and is hidden in case of PIP-only
        hidden={!hasSceneBackgroundVideo || form.getFieldValue('scenePipPositionCode') === PipPositionCode.FULL_SCREEN}
      >
        <SliderWithInput min={0} max={1} step={0.01} />
      </Form.Item>

      <Divider>{t('charters.projects.projectEditor.sceneEditor.fields.pip.timeFieldsDivider')}</Divider>

      {hasSceneBackgroundVideo && (
        // Cannot set a delay on a PIP-only scene (no background video)
        <Form.Item name="scenePipDelay" label={t('charters.projects.projectEditor.sceneEditor.fields.pip.delayLabel')}>
          <InputNumber
            min={0}
            max={backgroundVideoDuration}
            step={1}
            formatter={(value: number | undefined) =>
              value ? MathUtils.formatInputValueWithXDecimals(Number(value), 2)?.toString() : ''
            }
          />
        </Form.Item>
      )}
      <Form.Item
        label={t('charters.projects.projectEditor.sceneEditor.fields.pip.durationLabel')}
        name="scenePipDuration"
        rules={[{ required: true }]}
        hidden={scene.pip?.media?.mediaType !== MediaType.IMAGE}
      >
        <InputNumber
          min={PROJECTS.PIP.PIP_MIN_DURATION}
          max={backgroundVideoDuration ?? PROJECTS.PIP.PIP_ONLY_MAX_DURATION}
          step={1}
          formatter={(value: number | undefined) =>
            value ? MathUtils.formatInputValueWithXDecimals(Number(value), 2)?.toString() : ''
          }
        />
      </Form.Item>

      {scene.pip?.media?.mediaType === MediaType.VIDEO && [
        <Form.Item
          label={t('charters.projects.projectEditor.sceneEditor.fields.pip.trimStartAtLabel')}
          key="scenePipTrimStart"
          name="scenePipTrimStart"
          rules={[{ required: true }]}
          hidden={scene.pip?.media?.mediaType !== MediaType.VIDEO}
        >
          <InputNumber
            min={0}
            max={MathUtils.formatInputValueWithXDecimals(
              ((scene?.pip?.trimEndAt || scene.pip?.media?.duration) ?? PROJECTS.PIP.PIP_ONLY_MAX_DURATION) -
                PROJECTS.PIP.PIP_MIN_DURATION,
              3
            )}
            disabled={scene?.backgroundVideo === undefined || scene.backgroundVideo.media === undefined}
            step={0.01}
            formatter={(value: number | undefined) =>
              value ? MathUtils.formatInputValueWithXDecimals(Number(value), 2)?.toString() : ''
            }
          />
        </Form.Item>,
        <Form.Item
          label={t('charters.projects.projectEditor.sceneEditor.fields.pip.trimEndAtLabel')}
          key="scenePipTrimEnd"
          name="scenePipTrimEnd"
          rules={[{ required: true }]}
          hidden={scene.pip?.media?.mediaType !== MediaType.VIDEO}
        >
          <InputNumber
            min={MathUtils.formatInputValueWithXDecimals(
              (scene?.pip?.trimStartAt || 0) + PROJECTS.PIP.PIP_MIN_DURATION,
              3
            )}
            max={MathUtils.formatInputValueWithXDecimals(
              scene.pip?.media?.duration ?? PROJECTS.PIP.PIP_ONLY_MAX_DURATION,
              3
            )}
            step={0.01}
            formatter={(value: number | undefined) =>
              value ? MathUtils.formatInputValueWithXDecimals(Number(value), 2)?.toString() : ''
            }
          />
        </Form.Item>,
      ]}

      {/* We can delete the PIP only if the scene has a background video (one of the background video or the PIP is mandatory) */}
      {hasSceneBackgroundVideo && (
        <div className={classes.deletePipButton}>
          <Popconfirm
            title={t('charters.projects.projectEditor.sceneEditor.fields.pip.deletePipPopConfirm')}
            onConfirm={onDeletePip}
            okText={t('global.yes')}
            cancelText={t('global.no')}
          >
            <Button type="primary" danger shape="circle" icon={<FaTrashAlt />} />
          </Popconfirm>
        </div>
      )}
      <div className={classes.actionsContainer}>
        <Button disabled={!isSceneEdited} onClick={onReset}>
          {t('global.reset')}
        </Button>
        <Button disabled={!isSceneEdited || isSceneInError} type="primary" onClick={onSubmit}>
          {t('global.save')}
        </Button>
      </div>
    </Form>
  );
};

export default ProjectScenePipOptions;
