import { Tooltip } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import { createUseStyles } from 'react-jss';
import RoundTag from '../../../components/tag/round-tag/RoundTag';
import { APIModelCharter } from '../../../services/api/types/ChartersServiceTypes';
import { APICompanyUsers } from '../../../services/api/types/CompaniesServiceTypes';
import { APIModelUserEnriched } from '../../../services/api/types/UsersServiceTypes';
import LinkUserToCharterTag from './LinkUserToCharterTag';
import TagCharter from './TagCharter';

type Props = {
  row: APICompanyUsers;
  callbackOpenRow: (row: any) => void;
  callbackLinkUserToCharter: (isLoading: boolean, data?: APIModelUserEnriched, charter?: APIModelCharter) => void;
  callbackRemoveUserFromCharter: (loading: boolean, charter?: APIModelCharter, user?: APICompanyUsers) => void;
};

const useStyles = createUseStyles({
  container: {
    padding: '16px 8px',
  },
});

const CharterCell: FunctionComponent<Props> = ({
  row,
  callbackOpenRow,
  callbackLinkUserToCharter,
  callbackRemoveUserFromCharter,
}: Props) => {
  const classes = useStyles();
  const [addVisible, setAddVisible] = useState(false);
  const handleMouseEnter = (): void => {
    setAddVisible(true);
  };
  const handleMouseLeave = (): void => {
    setAddVisible(false);
  };

  const tagNumber = 5;
  const charters = [...row.charters];
  const chartersInTags = charters.splice(0, tagNumber);
  const chartersInTooltip = charters.splice(-tagNumber);

  return (
    <div
      key={`row_user_charter_${row.id}`}
      className={classes.container}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {chartersInTags.map((charter) => {
        return (
          <TagCharter
            key={`tag_charter_${charter.id}`}
            user={row}
            charter={charter}
            callbackRemoveUserFromCharter={callbackRemoveUserFromCharter}
          />
        );
      })}
      {row.charters.length > tagNumber && (
        <Tooltip title={chartersInTooltip.map((charter) => charter.name).join(', ')}>
          <RoundTag color="#333" onClick={(): void => callbackOpenRow(row)}>
            {row.charters.length - tagNumber}+
          </RoundTag>
        </Tooltip>
      )}
      <LinkUserToCharterTag visible={addVisible} user={row} callbackLinkUserToCharter={callbackLinkUserToCharter} />
    </div>
  );
};

export default CharterCell;
