import { Alert } from 'antd';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LINK } from '../../Constants';
import useCanAccess from '../../hooks/useCanAccess';
import { RootState } from '../../redux/RootState';
import { APIManager } from '../../services/api/APIManager';
import { getCompanyAnalyticsStartingDate } from '../../services/api/CompaniesService';
import { APICompanyAnalyticsStartingDateResponse } from '../../services/api/types/CompaniesServiceTypes';
import TimeUtils from '../../utils/TimeUtils';
import { PermissionList } from '../../utils/types/CharterPermissionTypes';

type Props = {
  excludeKnlTeam: boolean;
};

const useStyles = createUseStyles({
  warningMessage: {
    '& .ant-alert-message': {
      fontWeight: 'bold',
    },
    marginBottom: 16,
  },
});

const CompanyAnalyticsDataCollectionDateDisclaimer: FunctionComponent<Props> = ({ excludeKnlTeam }: Props) => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  const company = useSelector((state: RootState) => state.companies.current);

  const { hasUserAccessToCompany } = useCanAccess(PermissionList.WEB_DASHBOARD);

  const [firstCompanyEventTimestamp, setFirstCompanyEventTimestamp] = useState<number>();
  const [analyticsStartDateLoading, setAnalyticsStartDateLoading] = useState(true);

  useEffect(() => {
    if (!company) {
      return undefined;
    }

    const cancelTokenSource = APIManager.getCancelToken();

    setAnalyticsStartDateLoading(true);
    getCompanyAnalyticsStartingDate(company.id, excludeKnlTeam)
      .then((companyAnalyticsStartingDate: APICompanyAnalyticsStartingDateResponse) => {
        const { firstEventTimestamp } = companyAnalyticsStartingDate.data;
        setFirstCompanyEventTimestamp(firstEventTimestamp);
      })
      .catch((e: any) => {
        log.error(e.message);
      })
      .finally(() => {
        setAnalyticsStartDateLoading(false);
      });

    return (): void => {
      cancelTokenSource.cancel('Cancelled fetching company analytics starting date due to component unmount.');
    };
  }, [company, excludeKnlTeam]);

  useEffect(() => {
    if (!analyticsStartDateLoading && !firstCompanyEventTimestamp) {
      setFirstCompanyEventTimestamp(new Date().getTime());
    }
  }, [analyticsStartDateLoading, firstCompanyEventTimestamp]);

  if (!firstCompanyEventTimestamp) {
    return null;
  }
  if (!(company && hasUserAccessToCompany(company.id))) {
    history.push(LINK.UNAUTHORIZED.path);
  }

  return (
    <Alert
      message={
        <>
          {t('analytics.startingDateDisclaimerTitle')}{' '}
          {TimeUtils.fromTimestampToHumanReadableDate(t, firstCompanyEventTimestamp)}.
        </>
      }
      description={
        <>
          {t('analytics.startingDateDisclaimerText')}{' '}
          <a href={`mailto:${t('support.global.supportEmail')}`}>{t('support.global.supportEmail')}</a>.
        </>
      }
      type="warning"
      showIcon
      className={classes.warningMessage}
    />
  );
};

export default CompanyAnalyticsDataCollectionDateDisclaimer;
