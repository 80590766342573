import React, { FunctionComponent, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import AccessChecker from '../access-checker/AccessChecker';

type Props = {
  renderUnauthorizedMessage: () => void;
  hasAccess: boolean;
  linkTo: string;
  children: JSX.Element[] | JSX.Element | ReactNode | string;
};

const useStyles = createUseStyles({
  unauthorized: {
    cursor: 'not-allowed !important',
    color: '#7E8AAA !important',
  },
});

const SecuredLink: FunctionComponent<Props> = ({ renderUnauthorizedMessage, hasAccess, linkTo, children }: Props) => {
  const classes = useStyles();

  return (
    <Link to={hasAccess ? linkTo : '#'} className={!hasAccess ? classes.unauthorized : undefined}>
      <AccessChecker renderUnauthorizedMessage={renderUnauthorizedMessage} hasAccess={hasAccess}>
        {children}
      </AccessChecker>
    </Link>
  );
};

export default SecuredLink;
