/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-use-before-define */

import { CloudServerOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { Alert, Button, Modal, Popconfirm, Progress } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCamera, FaRedoAlt } from 'react-icons/fa';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import Webcam from 'react-webcam';
import { useTimer } from 'use-timer';
import { DEVICE_SIZES_QUERIES, LOGGING_EVENT, PROJECTS, THEME, WEB_SOCKET_ACTIONS } from '../../Constants';
import useSocket from '../../hooks/useSocket';
import { RootState } from '../../redux/RootState';
import { getCharterMediaSignedUrlToUpload } from '../../services/api/ChartersService';
import { uploadFileToSignedUrl } from '../../services/api/GlobalService';
import { SocketManager } from '../../services/api/SocketManager';
import { MediaFile } from '../../services/api/types/ChartersServiceTypes';
import { AssetOrMediaProcessingResponse } from '../../services/api/types/WebSocketTypes';
import FileUtils from '../../utils/FileUtils';
import MathUtils from '../../utils/MathUtils';
import MediaUtils from '../../utils/MediaUtils';
import TimeUtils from '../../utils/TimeUtils';
import { MediaType } from '../../utils/types/MediaTypes';
import PageContentLoader from '../loader/PageContentLoader';
import VideoWithCustomControls from '../video/VideoWithCustomControls';
import { WebcamRecorderOptions } from './WebcamRecorder';
import WebcamRecorderModalSettings from './WebcamRecorderModalSettings';

type Props = {
  isVisible: boolean;
  format?: string;
  mediaType?: MediaType;
  options?: WebcamRecorderOptions;
  onRecordingProcessed?: (media: MediaFile) => void;
  onCancel: () => void;
};

type StyleProps = {
  aspectRatio: number;
};

const useStyles = createUseStyles({
  webcamRecorderModal: {
    width: '75% !important',
    '& .ant-modal-footer': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      width: '95% !important',
    },
  },
  webcamAndVideoContainer: {
    height: '60vh',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    overflow: 'hidden',
  },
  webcamLoader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  webcamContainer: ({ aspectRatio }: StyleProps) => ({
    position: 'relative',
    height: aspectRatio <= 1 ? '100%' : undefined,
    width: aspectRatio > 1 ? '100%' : undefined,
  }),
  webcam: {
    height: 'inherit',
    width: 'inherit',
    maxHeight: '60vh',
    borderRadius: 4,
  },
  overlayImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  countdownTimer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontFamily: 'Menlo, monospace',
    color: 'white',
    fontSize: 100,
    textShadow: '1px 2px rgba(0, 0, 0, 0.5)',
    zIndex: 1,
  },
  recordingVideo: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    borderRadius: 4,
    overflow: 'hidden',
    '& video': {
      borderRadius: 4,
    },
  },
  previewMedia: {
    maxHeight: '100%',
    maxWidth: '100%',
    borderRadius: 4,
    '& video': {
      maxHeight: '100%',
      maxWidth: '100%',
      objectFit: 'cover',
    },
  },
  mirroredPreview: {
    transform: 'rotateY(180deg)',
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 30,
    position: 'relative',
  },
  recordOrTakePictureBaseButton: {
    height: 60,
    width: 60,
    borderRadius: '50%',
    border: '4px solid #C3C3C3',
    position: 'relative',
    boxShadow: '0 8px 8px 0 hsla(0, 0%, 0%, 0.15) !important',
    cursor: 'pointer',
    '&:hover': {
      border: '4px solid #A3A3A3',
    },
    '&:active': {
      '& > div': {
        filter: 'brightness(0.9)',
      },
      boxShadow: '0 10px 10px 0 hsla(0, 0%, 0%, 0.15) !important',
    },
    '& > div': {
      height: 30,
      width: 30,
      position: 'absolute',
      top: '50%',
      left: '50%',
      margin: '-15px 0px 0px -15px',
      transition: 'border-radius 0.2s, background 0.2s',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
    },
  },
  startRecordingButton: {
    '& > div': {
      borderRadius: '50%',
      background: 'red',
    },
  },
  stopRecordingButton: {
    '& > div': {
      borderRadius: 3,
      background: 'red',
    },
  },
  retakeRecordingButton: {
    '& > div': {
      borderRadius: '50%',
      background: THEME.DEFAULT.MAIN_COLOR_GREEN,
    },
  },
  takePictureButton: {
    '& > div': {
      background: '#C3C3C3',
      borderRadius: '50%',
      height: 48,
      width: 48,
      margin: '-24px 0px 0px -24px',
      fontSize: 22,
    },
  },
  disabledRecordButton: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  uploadProgressContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      fontSize: 10,
    },
  },
  uploadIcon: {
    marginRight: 4,
  },
  processingIcon: {
    marginRight: 4,
  },
  uploadProgressBar: {
    marginLeft: 8,
    marginRight: 20,
    width: 200,
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      width: 100,
    },
  },
  recordingDataContainer: {
    position: 'absolute',
    top: 0,
    left: '70%',
    fontFamily: 'Menlo, monospace',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      right: 10,
      left: 'unset',
    },
  },
  invisibleDot: {
    visibility: 'hidden',
  },
  recordingDot: {
    animation: '1s $blink ease infinite',
  },
  '@keyframes blink': {
    'from, to': {
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
  },
  orangeTimer: {
    color: THEME.DEFAULT.WARNING_COLOR,
  },
  redTimer: {
    color: THEME.DEFAULT.DANGER_COLOR,
  },
  recordingLimitStopWarning: {
    position: 'absolute',
    top: 72,
    '& .ant-alert-warning': {
      padding: '2px 8px',
    },
  },
  cameraSettingsContainer: {
    position: 'absolute',
    height: 30,
    top: 15,
    width: '40%',
    left: '5%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > div': {
      padding: '0 8px',
    },
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      flexDirection: 'column',
      '& div': {
        padding: '4px 0',
      },
    },
  },
});

const WebcamRecorderModal: FunctionComponent<Props> = ({
  isVisible,
  format,
  mediaType: mediaTypeFromProp,
  options,
  onRecordingProcessed,
  onCancel,
}: Props) => {
  const { t } = useTranslation();
  const webcamContainerRef = useRef<HTMLDivElement>(null);
  const webcamRef = useRef<Webcam>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);

  const [mediaType, setMediaType] = useState(mediaTypeFromProp ?? MediaType.VIDEO);
  const [isCapturing, setIsCapturing] = useState(false);
  const [isWebcamLoading, setIsWebcamLoading] = useState(true);
  const [isWebcamError, setIsWebcamError] = useState(false);
  const [isCountdownActive, setIsCountdownActive] = useState(false);
  const [isMirroringActive, setIsMirroringActive] = useState(true);
  const [showWebcam, setShowWebcam] = useState(true);
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);
  const [previewImageOrVideoSource, setPreviewImageOrVideoSource] = useState<string>();
  const [uploadProgress, setUploadProgress] = useState<number>();
  const [isUploading, setIsUploading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [fileName, setFileName] = useState<string>();
  const [socketRoom, setSocketRoom] = useState<string>();
  const isSocketConnected = useSocket(socketRoom ?? undefined);

  const charter = useSelector((state: RootState) => state.charters.current);
  const company = useSelector((state: RootState) => state.companies.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);

  // Timer to compute the recording time (for videos)
  const { time: timer, start: startTimer, pause: pauseTimer, reset: resetTimer } = useTimer({
    interval: 500,
    step: 0.5,
  });

  // Countdown timer to optionally give the user 3000ms before the recording/screenshot to start
  const {
    time: countdownTimer,
    start: startCountdownTimer,
    reset: resetCountdownTimer,
    status: countdownTimerStatus,
  } = useTimer({ initialTime: PROJECTS.WEBCAM_RECORDER.COUNTDOWN_TIME, timerType: 'DECREMENTAL', endTime: 0 });
  const isCountdownTimerRunning = countdownTimerStatus === 'RUNNING';

  // Build the webcam constraint using the optional input 'format' passed as prop (defaults to 16/9)
  const constraints = useMemo(() => {
    const aspectRatio = format ? MediaUtils.transformFormatToAspectRatio(format) : 16 / 9;

    return {
      aspectRatio,
    };
  }, [format]);

  const classes = useStyles({ aspectRatio: constraints.aspectRatio });

  // Join the right websockets room
  useEffect(() => {
    if (!charter) {
      return;
    }

    setSocketRoom(`charters/${charter.id}/media`);
  }, [charter]);

  // Callback executed when receiving a websocket message in the joined room, to listen for the processing state of the
  // recorded or captured media
  const onMediaProcessed = (payload: AssetOrMediaProcessingResponse) => {
    const isMediaMessage = WEB_SOCKET_ACTIONS.CHARTER_GLOBAL_MEDIA.includes(payload.action);

    if (!isMediaMessage) {
      return;
    }

    const processedMedia = payload.data as MediaFile;
    const { status, publicName } = processedMedia;

    // If the received message corresponds to the uploaded file
    if (fileName && publicName === fileName) {
      // We know that the uploading is achieved and that the processing started
      setIsUploading(false);
      setIsProcessing(true);

      // If the processing is achieved, we reset the processing states, execute the 'onRecordingProcessed' callback if defined
      // and close the modal
      if (status === 'PROCESSED') {
        setUploadProgress(undefined);
        setIsProcessing(false);

        if (onRecordingProcessed) {
          onRecordingProcessed(processedMedia);
        }
        onCancel();
      }
    }
  };

  // Handle the websocket messages when the recorded or captured media is being processed
  useEffect(() => {
    if (!isSocketConnected) {
      return undefined;
    }

    SocketManager.onMessage(onMediaProcessed);

    return (): void => SocketManager.offMessage(onMediaProcessed);
  }, [isSocketConnected, fileName]);

  // Automatically stop the recording if the recording time limit is reached
  useEffect(() => {
    if (isCapturing && timer >= PROJECTS.WEBCAM_RECORDER.RECORD_LIMIT) {
      onStopCaptureClick();
    }
  }, [timer, isCapturing]);

  // Callback to reset all states to an initial state, as if the modal had never been opened before
  const onResetAll = () => {
    setIsCapturing(false);
    setShowWebcam(true);
    setRecordedChunks([]);
    setPreviewImageOrVideoSource(undefined);
    setFileName(undefined);
    setIsUploading(false);
    setUploadProgress(undefined);
    setIsProcessing(false);
    setIsWebcamError(false);
    resetTimer();
    resetCountdownTimer();
  };

  // Whenever the modal is shown or hidden, we ensure to reset all states to the initial values
  useEffect(() => {
    setIsWebcamLoading(true);
    onResetAll();
  }, [isVisible]);

  // Whenever the mediaType is a 'VIDEO' and the recordedChunks evolve, we make sure to update the previewImageOrVideoSource,
  // to make it correctly point to a Blob for the latest data
  useEffect(() => {
    if (recordedChunks && recordedChunks.length > 0) {
      const blob = new Blob(recordedChunks, {
        type: 'video/webm',
      });
      const url = URL.createObjectURL(blob);
      setPreviewImageOrVideoSource(url);
    }
  }, [recordedChunks]);

  // Callback executed when the button to retake a picture or a recording is clicked
  const onRetakePictureOrVideo = () => {
    onResetAll();
  };

  // Callback executed on response to the 'dataavailable' event on the video MediaRecorder, that is to say when the video
  // Blob data is made available for use
  const onDataAvailable = useCallback(
    ({ data }: BlobEvent) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  // Callback executed when the recording is started, when the mediaType is 'IMAGE'
  const onStartCaptureClick = useCallback(() => {
    if (!(webcamRef.current && webcamRef.current.stream)) {
      return undefined;
    }

    // If there is a countdown timer (3000ms), we start it
    if (isCountdownActive) {
      startCountdownTimer();
    }

    // In a timeout, we start the recording. The callback is either executed instantly if no countdown timer is set,
    // else it is executed 3000ms in the future
    const timeout = setTimeout(
      () => {
        if (!(webcamRef.current && webcamRef.current.stream)) {
          return;
        }

        startTimer();
        setIsCapturing(true);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
          mimeType: 'video/webm',
        });
        mediaRecorderRef.current.addEventListener('dataavailable', onDataAvailable);
        mediaRecorderRef.current.start();
      },
      isCountdownActive ? PROJECTS.WEBCAM_RECORDER.COUNTDOWN_TIME * 1000 : 0
    );

    return () => clearTimeout(timeout);
  }, [webcamRef, setIsCapturing, mediaRecorderRef, onDataAvailable, isCountdownActive]);

  // Callback executed when the recording is stopped, when the mediaType is 'VIDEO'
  const onStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current?.stop();
    pauseTimer();
    setIsCapturing(false);
    setShowWebcam(false);
  }, [mediaRecorderRef, webcamRef, setIsCapturing]);

  // Callback executed when capturing a picture when the mediaType is 'IMAGE'
  const onCapturePictureClick = useCallback(() => {
    if (!webcamRef.current) {
      return undefined;
    }

    // If there is a countdown timer (3000ms), we start it
    if (isCountdownActive) {
      startCountdownTimer();
    }

    // In a timeout, we capture the picture. The callback is either executed instantly if no countdown timer is set,
    // else it is executed 3000ms in the future
    const timeout = setTimeout(
      () => {
        if (!webcamRef.current) {
          return;
        }

        const imageSource = webcamRef.current.getScreenshot();
        if (imageSource) {
          setPreviewImageOrVideoSource(imageSource);
        }

        setShowWebcam(false);
      },
      isCountdownActive ? PROJECTS.WEBCAM_RECORDER.COUNTDOWN_TIME * 1000 : 0
    );

    return () => clearTimeout(timeout);
  }, [webcamRef, isCountdownActive]);

  // Callback executed once the webcam is ready to be used and receives a stream
  const onUserMedia = () => {
    setIsWebcamLoading(false);
  };

  // Callback executed when there is an error while loading the camera (either no camera or access denied)
  const onUserMediaError = () => {
    setIsWebcamLoading(false);
    setIsWebcamError(true);
  };

  // Callback executed when the mediaType is changed using the switch
  const onMediaTypeChange = () => {
    onResetAll();
    if (mediaType === MediaType.VIDEO) {
      setMediaType(MediaType.IMAGE);
    } else {
      setMediaType(MediaType.VIDEO);
    }
  };

  // Callback executed when the usage of the countdown changes with the switch
  const onCountdownActiveChange = (checked: boolean) => {
    setIsCountdownActive(checked);
  };

  // Callback executed when the usage of the mirroring changes with the switch
  const onIsMirroringActiveChange = (checked: boolean) => {
    setIsMirroringActive(checked);
  };

  // Callback executed to upload a file onto S3
  const onUploadFile = async () => {
    if (
      !(
        charter &&
        ((mediaType === MediaType.VIDEO && recordedChunks && recordedChunks.length > 0) ||
          (mediaType === MediaType.IMAGE && previewImageOrVideoSource))
      )
    ) {
      return;
    }

    // Build the Blob object depending on the mediaType
    let blob: Blob = new Blob();

    if (mediaType === MediaType.VIDEO && recordedChunks && recordedChunks.length > 0) {
      blob = new Blob(recordedChunks, { type: 'video/webm' });
    } else if (mediaType === MediaType.IMAGE && previewImageOrVideoSource) {
      blob = await FileUtils.base64toBlob(previewImageOrVideoSource);
    }

    // If the blob is empty, abort
    if (!blob.size) {
      return;
    }

    // Build the file name
    const recordingFileName = MediaUtils.buildRecordingFileName(mediaType);
    setFileName(recordingFileName);

    // Upload the file onto S3
    const params = {
      charterId: charter.id,
      filename: recordingFileName,
      mediaType,
      // For videos only, we have to explicitly set a metadata on the uploaded file to require the back-end to mirror it.
      // This is not useful for images as the front-end can flip it directly
      ...(isMirroringActive && mediaType === MediaType.VIDEO && { isMirrored: true }),
    };

    setUploadProgress(0);
    setIsUploading(true);

    loggingManager.logEvent(LOGGING_EVENT.UPLOAD_MEDIA, {
      name: params.filename,
      size: `${Math.floor(MathUtils.convertBytesToKilobytes(blob.size))} ${t('charters.mediaLibrary.KB')}`,
      charterId: charter.id,
      companyId: company?.id,
      mimeType: blob.type,
    });

    getCharterMediaSignedUrlToUpload(params)
      .then((signedUrl) => {
        return uploadFileToSignedUrl(signedUrl.fileUploadURL, blob, (event) => {
          setUploadProgress(Math.floor((event.loaded / event.total) * 100));
        }).catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  };

  // Display an error message if there is no camera, the permission is denied, or for any other error
  const renderWebcamError = () => {
    return (
      <Alert
        message={t('charters.mediaLibrary.webcamRecorder.webcamErrorTitle')}
        description={
          <>
            <div>{t('charters.mediaLibrary.webcamRecorder.webcamErrorDescriptionPart1')}</div>
            <div>{t('charters.mediaLibrary.webcamRecorder.webcamErrorDescriptionPart2')}</div>
            <div>
              {t('charters.mediaLibrary.webcamRecorder.webcamErrorDescriptionPart3')}
              <a href={`mailto:${t('support.global.supportEmail')}`}>{t('support.global.supportEmail')}</a>.
            </div>
          </>
        }
        type="error"
        showIcon
      />
    );
  };

  // Render the recording time, and if there is a recommended time, compare the recorded time with the recommended time
  const renderRecordingTime = () => {
    if (mediaType === MediaType.IMAGE) {
      return null;
    }

    const recommendedTime = options?.recommendedTime;

    let timerClassName = '';
    // If there is a recommended time
    if (recommendedTime !== undefined && timer !== undefined) {
      const redTimerLimit = 2 * recommendedTime;
      // If the timer is greater than the recommended time but lower than its double, color it in orange
      if (timer >= recommendedTime && timer < redTimerLimit) {
        timerClassName = classes.orangeTimer;
      } else if (timer >= redTimerLimit) {
        // Else if the timer is greater than twice the recommended time, color it in red
        timerClassName = classes.redTimer;
      }
    }

    return (
      <div className={classes.recordingDataContainer}>
        {/* Blinking red dot to show the recording status */}
        <svg height="60" width="40" className={classNames(classes.recordingDot, !isCapturing && classes.invisibleDot)}>
          <circle cx="20" cy="30" r="5" fill="red" />
        </svg>

        {/* Timer and the optional recommended time */}
        {timer !== undefined && (
          <div>
            <span className={timerClassName}>{TimeUtils.formatSecondsIntoDuration(Math.trunc(timer))}</span>
            {recommendedTime !== undefined && <span> / {TimeUtils.formatSecondsIntoDuration(recommendedTime)}</span>}
          </div>
        )}
      </div>
    );
  };

  // Render a warning message to explain the recording time limit is about to be reached and the recording will stop
  const renderRecordingLimitWarning = () => {
    const remainingSecondsBeforeStoppingCapture = Math.ceil(PROJECTS.WEBCAM_RECORDER.RECORD_LIMIT - timer);

    if (
      !(isCapturing && remainingSecondsBeforeStoppingCapture <= PROJECTS.WEBCAM_RECORDER.RECORD_LIMIT_WARNING_LIMIT)
    ) {
      return null;
    }

    return (
      <div className={classes.recordingLimitStopWarning}>
        <Alert
          showIcon
          message={t('charters.mediaLibrary.webcamRecorder.recordingWillStopWarning', {
            limit: Math.round(PROJECTS.WEBCAM_RECORDER.RECORD_LIMIT / 60),
            remaining: remainingSecondsBeforeStoppingCapture,
          })}
          type="warning"
        />
      </div>
    );
  };

  const renderCameraSettings = () => {
    return (
      <div className={classes.cameraSettingsContainer}>
        <WebcamRecorderModalSettings
          isProcessing={isProcessing}
          isUploading={isUploading}
          isCapturing={isCapturing}
          isCountdownTimerRunning={isCountdownTimerRunning}
          isCountdownActive={isCountdownActive}
          isMirroringActive={isMirroringActive}
          previewImageOrVideoSource={previewImageOrVideoSource}
          mediaType={mediaType}
          isMediaTypeForced={mediaTypeFromProp !== undefined}
          onMediaTypeChange={onMediaTypeChange}
          onCountdownActiveChange={onCountdownActiveChange}
          onIsMirroringActiveChange={onIsMirroringActiveChange}
        />
      </div>
    );
  };

  // Render the button to take a capture (image), start or stop a recording (video)
  const renderRecordOrCaptureButton = () => {
    const retakeVideoOrPicture = previewImageOrVideoSource !== undefined;
    const isVideoTooShort =
      mediaType === MediaType.VIDEO && isCapturing && timer < PROJECTS.WEBCAM_RECORDER.MIN_RECORD_TIME;
    const isPictureCapture = mediaType === MediaType.IMAGE;

    // In case there is already a capture or recording displayed as preview, add a Popconfirm to retake a new capture or video
    if (retakeVideoOrPicture) {
      return (
        <Popconfirm
          title={
            isPictureCapture
              ? t('charters.mediaLibrary.webcamRecorder.retakePictureConfirm')
              : t('charters.mediaLibrary.webcamRecorder.retakeVideoConfirm')
          }
          onConfirm={onRetakePictureOrVideo}
          okText={t('global.yes')}
          cancelText={t('global.no')}
          disabled={isProcessing || isUploading}
        >
          <div
            className={classNames(
              classes.recordOrTakePictureBaseButton,
              classes.retakeRecordingButton,
              (isProcessing || isUploading) && classes.disabledRecordButton
            )}
          >
            <div>{retakeVideoOrPicture && <FaRedoAlt />}</div>
          </div>
        </Popconfirm>
      );
    }

    let onClick;
    if (isPictureCapture) {
      onClick = onCapturePictureClick;
    } else if (isCapturing) {
      onClick = !isVideoTooShort ? onStopCaptureClick : undefined;
    } else {
      onClick = onStartCaptureClick;
    }

    return (
      <div
        className={classNames(
          classes.recordOrTakePictureBaseButton,
          (isProcessing || isUploading || isCountdownTimerRunning) && classes.disabledRecordButton,
          isPictureCapture
            ? classes.takePictureButton
            : isCapturing
            ? classes.stopRecordingButton
            : classes.startRecordingButton
        )}
        onClick={onClick}
      >
        <div>{isPictureCapture && <FaCamera />}</div>
      </div>
    );
  };

  const renderPreview = () => {
    if (!previewImageOrVideoSource) {
      return null;
    }

    // If the media to preview is an image, we can display it directly (no need to mirror it even if taken in mirroring mode)
    if (mediaType === MediaType.IMAGE) {
      return <img src={previewImageOrVideoSource} className={classes.previewMedia} alt="Captured screenshot" />;
    }

    // Else, if the media to preview is a video and if it's taken in mirroring mode, we have to mirror the preview.
    // Therefore, we have to implement our own controls for the video player
    return (
      <VideoWithCustomControls
        url={previewImageOrVideoSource}
        className={classNames(classes.previewMedia, isMirroringActive && classes.mirroredPreview)}
        config={{ file: { attributes: { disablePictureInPicture: true } } }}
      />
    );
  };

  // Render the modal footer, composed of the cancel/submit buttons, as well as a progress bar for the upload/processing progress
  const renderModalFooter = () => {
    return [
      // Message and progress bar to explain that the media is either being uploaded or processed
      ...(isUploading || isProcessing
        ? [
            <div key="uploadOrProgress" className={classes.uploadProgressContainer}>
              {isUploading ? (
                // If the media is being uploaded
                <>
                  <CloudUploadOutlined className={classes.uploadIcon} />
                  {t('charters.mediaLibrary.webcamRecorder.uploadInProgress')}
                </>
              ) : (
                // Else if the media is being processed on the servers
                <>
                  <CloudServerOutlined className={classes.processingIcon} />
                  {t('charters.mediaLibrary.webcamRecorder.processingInProgress')}
                </>
              )}
              <Progress percent={uploadProgress} status="active" size="small" className={classes.uploadProgressBar} />
            </div>,
          ]
        : []),
      // Cancel button
      <Button key="cancelModal" onClick={onCancel}>
        {t('global.cancel')}
      </Button>,
      // Submit button
      <Button
        key="submit"
        type="primary"
        onClick={onUploadFile}
        loading={isUploading || isProcessing}
        disabled={isUploading || isProcessing || !previewImageOrVideoSource}
      >
        {t('global.submit')}
      </Button>,
    ];
  };

  return (
    <Modal
      visible={isVisible}
      onCancel={onCancel}
      className={classes.webcamRecorderModal}
      title={
        mediaType === MediaType.VIDEO
          ? t('charters.mediaLibrary.webcamRecorder.recordingTitle')
          : t('charters.mediaLibrary.webcamRecorder.pictureTitle')
      }
      destroyOnClose
      footer={renderModalFooter()}
    >
      {isWebcamError ? (
        renderWebcamError()
      ) : (
        <>
          <div className={classes.webcamAndVideoContainer} ref={webcamContainerRef}>
            {/* Display a loader if the webcam stream is loading */}
            {showWebcam && isWebcamLoading && (
              <div className={classes.webcamLoader}>
                <PageContentLoader message={t('charters.mediaLibrary.webcamRecorder.loading')} />
              </div>
            )}

            {/* Display either the webcam or an image/video preview of the captured image/video */}
            {showWebcam ? (
              // If the webcam must be displayed
              <div className={classes.webcamContainer}>
                {options?.overlayImage && !isWebcamLoading && !isWebcamError && (
                  <img src={options?.overlayImage} alt="Overlay" className={classes.overlayImage} />
                )}
                {isCountdownActive && isCountdownTimerRunning && countdownTimer > 0 && (
                  <div className={classes.countdownTimer}>{countdownTimer}</div>
                )}
                <Webcam
                  className={classes.webcam}
                  videoConstraints={constraints}
                  audio
                  muted
                  mirrored={isMirroringActive}
                  screenshotQuality={1}
                  onUserMedia={onUserMedia}
                  onUserMediaError={onUserMediaError}
                  ref={webcamRef}
                />
              </div>
            ) : (
              // Else, if a preview must be displayed
              <div className={classes.recordingVideo}>{renderPreview()}</div>
            )}
          </div>

          {/* Render the actions: the camera settings, the button to record, capture a picture or retake it, and the
          recordingTime if relevant  */}
          <div className={classes.actionsContainer}>
            {mediaType === MediaType.VIDEO && renderRecordingLimitWarning()}
            {renderCameraSettings()}
            {renderRecordOrCaptureButton()}
            {mediaType === MediaType.VIDEO && renderRecordingTime()}
          </div>
        </>
      )}
    </Modal>
  );
};

export default WebcamRecorderModal;
