import { CancelTokenSource } from 'axios';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import KannelleSwitch from '../../../components/switch/KannelleSwitch';
import { LOGGING_EVENT } from '../../../Constants';
import { RootState } from '../../../redux/RootState';
import { APIManager } from '../../../services/api/APIManager';
import { updateCompanyNotificationConfigs } from '../../../services/api/CompaniesService';
import { APICompanyNotificationConfig } from '../../../services/api/types/CompaniesServiceTypes';

type Props = {
  fieldToSwitch: 'isEmailActive' | 'isWebActive';
  callback: (notificationConfigId: number, updatedField: 'isEmailActive' | 'isWebActive', newValue: boolean) => void;
  notificationConfig: APICompanyNotificationConfig;
  isConfigBeingUpdated: boolean;
  setIsConfigBeingUpdated: React.Dispatch<React.SetStateAction<boolean>>;
};

const NotificationConfigSwitch: FunctionComponent<Props> = ({
  notificationConfig,
  callback,
  fieldToSwitch,
  isConfigBeingUpdated,
  setIsConfigBeingUpdated,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const company = useSelector((state: RootState) => state.companies.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);
  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());

  useEffect(() => {
    const cancelTokenSource = cancelTokenSourceRef.current;

    return (): void => {
      cancelTokenSource.cancel(
        `Cancelled patching company notification config (id: ${notificationConfig.id}) due to component unmount.`
      );
    };
    // eslint-disable-next-line
  }, []);

  const onToggle = (newValue: boolean): void => {
    if (!company) {
      return;
    }

    const params = {
      [fieldToSwitch]: newValue,
    };

    setIsConfigBeingUpdated(true);
    setIsError(false);
    setIsLoading(true);
    const cancelTokenSource = cancelTokenSourceRef.current;
    updateCompanyNotificationConfigs(company.id, notificationConfig.id, params, cancelTokenSource)
      .then(() => {
        loggingManager.logEvent(LOGGING_EVENT.UPDATE_NOTIFICATION_CONFIG, {
          notificationConfigId: notificationConfig.id,
          notificationConfigName: notificationConfig.name,
          [fieldToSwitch]: newValue,
          companyId: company.id,
        });
        callback(notificationConfig.id, fieldToSwitch, newValue);
      })
      .catch((e) => {
        log.debug('Error while fetching company notification configs', e);
        setIsConfigBeingUpdated(false);
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <KannelleSwitch
      isChecked={notificationConfig[fieldToSwitch]}
      size="small"
      labelActivated="form.activated"
      labelDeactivated="form.deactivated"
      callbackChange={onToggle}
      loading={isLoading}
      error={isError}
      disabled={isConfigBeingUpdated}
    />
  );
};

export default NotificationConfigSwitch;
