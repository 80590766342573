import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { Button, Slider } from 'antd';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { BsFillLayersFill, BsPauseFill, BsPlayFill } from 'react-icons/bs';
import { FaFilm } from 'react-icons/fa';
import { IoText } from 'react-icons/io5';
import { MdSubtitles } from 'react-icons/md';
import { createUseStyles } from 'react-jss';
import { useDebouncedCallback } from 'use-debounce';
import { PROJECTS, THEME } from '../../../../../../Constants';
import useWindowSize from '../../../../../../hooks/useWindowSize';
import AnimationsUtils from '../../../../../../utils/AnimationsUtils';
import ProjectUtils from '../../../../../../utils/ProjectUtils';
import TimeUtils from '../../../../../../utils/TimeUtils';
import { AnimationFormats, Animations } from '../../../../../../utils/types/AnimationTypes';
import {
  Project,
  ProjectScene,
  ProjectSceneElement,
  ProjectSceneElements,
  ProjectSceneElementsCode,
  SubtitleBlock,
} from '../../../../../../utils/types/ProjectTypes';
import { AnimationTheme } from '../../../../../../utils/types/ThemeTypes';
import WindowUtils from '../../../../../../utils/WindowUtils';
import AddAnimationToSceneButton from './components/scene-animation-row/AddAnimationToSceneButton';
import SceneAnimationBar from './components/scene-animation-row/SceneAnimationBar';
import AddPipToSceneButton from './components/scene-pip-row/AddPipToSceneButton';
import ScenePipBar from './components/scene-pip-row/ScenePipBar';
import SceneSubtitles from './components/scene-subtitle-row/SceneSubtitles';
import AddBackgroundVideoToSceneButton from './components/scene-video-row/AddBackgroundVideoToSceneButton';
import SceneVideoTrimBar from './components/scene-video-row/SceneVideoTrimBar';
import SceneTimelineSkeleton from './components/SceneTimelineSkeleton';
import SeekBar from './components/SeekBar';
import SeekBarHover from './components/SeekBarHover';
import VideoGraduatedTimeRange from './components/VideoGraduatedTimeRange';
import { VideoTrim, VideoTrimUtils } from './utils/VideoTrimUtils';

type Props = {
  project: Project;
  scene: ProjectScene;
  selectedElement?: ProjectSceneElement;
  selectedElementId?: number;
  duration: number;
  isLoading: boolean;
  isPlaying: boolean;
  currentTime: number;
  format?: AnimationFormats;
  theme: AnimationTheme;
  customColors: boolean;
  onPlay: () => void;
  onPause: () => void;
  onSelectElement: (e: ProjectSceneElement, skipConfirm?: boolean, elementId?: number) => void;
  onCurrentTimeChange: (e: number) => void;
  onSubmitScene: (updatedScene: ProjectScene) => void;
  onEditScene: (edited: ProjectScene, updateInitialScene?: boolean) => void;
  videoTrimValue: VideoTrim;
  onTrimChange: (minmaxAnchors: VideoTrim, type?: ProjectSceneElementsCode) => void;
  callbackIfUnsavedChangesConfirmed: (callback: () => void, confirmTitle?: string, confirmDescription?: string) => void;
  resetTrimAndDelayValues: () => void;
  callbackReset: () => void;
  submitSubtitleBlock: () => void; // save the currentlySelected subtitle block
};

type StyleProps = {
  graduatedTimeRangeWidth: number;
  scene: ProjectScene;
  intervalTickWidth: number;
  startTrimOverflow: number;
  mediaDurationWithTrim: number;
  hasSceneBackgroundVideo: number;
};

const useStyles = createUseStyles({
  sceneTimelineContainer: {
    height: '100%',
    position: 'relative',
  },
  videoControlsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
  },
  timelineZoomSliderContainer: {
    position: 'absolute',
    top: 0,
    right: 15,
    height: 50,
    width: 130,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  timelineZoomSlider: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  zoomSlider: {
    flexGrow: 1,
    margin: '0 12px',
  },
  zoomSliderIcon: {
    fontSize: 12,
  },
  controlButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  timeValuesContainer: {
    marginLeft: 10,
    borderRadius: 32,
    background: '#EDEFF3',
    padding: '5px 12px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sceneElementRow: {
    display: 'flex',
    marginBottom: 20,
  },
  selectedSceneElementIcon: {
    color: 'white',
    background: THEME.PROJECTS.EDITOR.TIMELINE.SELECTED_ELEMENT_BORDER,
  },
  sceneElementIcon: {
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 24,
    maxWidth: 24,
    width: 24,
    height: 24,
    fontSize: 12,
    lineHeight: '24px',
    textAlign: 'center',
    borderRadius: 24,
    marginRight: '12px !important',
  },
  seekbarContainer: {
    marginLeft: 20 + PROJECTS.TRIM.ANCHOR_WIDTH,
  },
  videoTimelineContainer: {
    overflowX: 'auto',
    overflowY: 'hidden',
    position: 'relative',
    padding: '20px 0 0 35px',

    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      height: '9px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '6px',
      backgroundColor: 'rgba(0, 0, 0, .5)',
      boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
    },
  },
  seekBarHoverContainer: ({ graduatedTimeRangeWidth }: StyleProps) => ({
    height: '100%',
    width: graduatedTimeRangeWidth,
    position: 'relative',
    // 20 - 1 because of the + 1 in videoGraduatedTimeRangeContainer
    marginLeft: 20 - 1,
  }),
  videoGraduatedTimeRangeContainer: ({ graduatedTimeRangeWidth, startTrimOverflow }: StyleProps) => ({
    height: 45,
    marginLeft: 20 + PROJECTS.TRIM.ANCHOR_WIDTH,
    transform: `translateX(${PROJECTS.TRIM.ANCHOR_WIDTH - 20 + 1 - startTrimOverflow}px)`,
    overflow: 'hidden',
    width: graduatedTimeRangeWidth,
  }),
  videoTrimBarContainer: ({ hasSceneBackgroundVideo }: StyleProps) => ({
    marginLeft: !hasSceneBackgroundVideo ? PROJECTS.TRIM.ANCHOR_WIDTH : undefined,
    height: 40,
    position: 'relative',
  }),
  sceneAnimationContainer: {
    marginLeft: PROJECTS.TRIM.ANCHOR_WIDTH,
    height: 40,
    position: 'relative',
  },
  scenePipContainer: {
    height: 40,
    position: 'relative',
  },
  addSceneButtonPipContainer: {
    marginLeft: PROJECTS.TRIM.ANCHOR_WIDTH,
  },
});

const minZoomLevel = PROJECTS.MIN_INTERVAL_TICK_WIDTH / PROJECTS.DEFAULT_INTERVAL_TICK_WIDTH;
const maxZoomLevel = PROJECTS.MAX_INTERVAL_TICK_WIDTH / PROJECTS.DEFAULT_INTERVAL_TICK_WIDTH;
const zoomLevelStep = 0.05;

const SceneTimeline: FunctionComponent<Props> = ({
  project,
  scene,
  selectedElement,
  selectedElementId,
  duration,
  isLoading,
  isPlaying,
  currentTime,
  format,
  theme,
  customColors,
  onPause,
  onPlay,
  onSelectElement,
  onCurrentTimeChange,
  onSubmitScene,
  onEditScene,
  videoTrimValue,
  onTrimChange,
  callbackIfUnsavedChangesConfirmed,
  resetTrimAndDelayValues,
  callbackReset,
  submitSubtitleBlock,
}: Props) => {
  const [intervalTickWidth, setIntervalTickWidth] = useState<number>(PROJECTS.MIN_INTERVAL_TICK_WIDTH);
  const [timelineContainerWidth, setTimelineContainerWidth] = useState<number>(0);
  const [graduatedTimeRangeWidth, setGraduatedTimeRangeWidth] = useState<number>(0);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [scrollValueOnX, setScrollValueOnX] = useState<number>(0);
  const [mousePositionOnXWithScroll, setMousePositionOnXWithScroll] = useState<number>(0);
  const [zoomLevel, setZoomLevel] = useState(0.5);
  const [startTrimOverflow, setStartTrimOverflow] = useState(0);

  const ref = useRef<any>();

  useEffect(() => {
    const { trimStart } = videoTrimValue;
    const pipTrimStart = scene?.pip?.trimStartAt || 0;
    const pipDelay = scene?.pip?.delay || 0;

    const realTrimStart = trimStart > pipTrimStart - pipDelay ? trimStart : pipTrimStart;
    const ceil = Math.ceil(realTrimStart);
    const diff = ceil - realTrimStart;

    setStartTrimOverflow(diff * intervalTickWidth);
  }, [videoTrimValue]);

  useEffect(() => {
    if (!ref.current || !videoTrimValue) {
      return;
    }

    const calculatedScrollToDo = videoTrimValue.trimStart * intervalTickWidth;

    if (calculatedScrollToDo > ref.current.scrollLeft) {
      ref.current.scrollTo({
        top: 0,
        left: videoTrimValue.trimStart * intervalTickWidth,
        behavior: 'smooth',
      });
    }
  }, [ref, videoTrimValue]);

  useEffect(() => {
    if (currentTime < videoTrimValue.trimStart) {
      onCurrentTimeChange(videoTrimValue.trimStart);
    }
    if (currentTime > videoTrimValue.trimEnd) {
      onCurrentTimeChange(videoTrimValue.trimEnd);
    }
  }, [videoTrimValue]);

  const windowSize = useWindowSize();

  const timelineRef = useCallback(
    (node: HTMLDivElement) => {
      if (node) {
        setTimelineContainerWidth(node.offsetWidth);
      }
    },
    [windowSize]
  );

  const onMouseOverHandle = () => {
    setIsHovering(true);
  };

  const onMouseOutHandle = () => {
    setIsHovering(false);
  };

  // The graduatedTimeRange's width is the max between the timeline container width (for short scenes) and the available scrollWidth
  // (based on the scaled scene elements width in the timeline)
  const sceneElementsRef = useCallback(
    (node: HTMLDivElement) => {
      if (node) {
        setGraduatedTimeRangeWidth(Math.max(timelineContainerWidth * 0.8, node.scrollWidth + intervalTickWidth));
      }
    },
    [windowSize, intervalTickWidth]
  );

  let sceneTotalDuration = 0;
  if (ProjectUtils.isSlideScene(scene)) {
    sceneTotalDuration = duration;
  } else {
    sceneTotalDuration = scene.backgroundVideo?.media?.duration ?? scene.pip?.duration ?? 0;
  }
  const mediaDurationWithTrim =
    scene && scene.backgroundVideo && scene.backgroundVideo.trimStartAt && scene.backgroundVideo.trimEndAt
      ? scene.backgroundVideo.trimEndAt - scene.backgroundVideo.trimStartAt
      : 0;
  const isSlideScene = ProjectUtils.isSlideScene(scene);
  const hasSceneBackgroundVideo = scene.backgroundVideo !== undefined;

  const pipTrimValue = {
    trimStart: scene.pip?.trimStartAt || 0,
    trimEnd: scene.pip?.trimEndAt || 0,
    delay: scene.pip?.delay || 0,
  };

  const classes = useStyles({
    graduatedTimeRangeWidth,
    scene,
    intervalTickWidth,
    startTrimOverflow,
    mediaDurationWithTrim,
    hasSceneBackgroundVideo,
  });

  const computeIntervalTickWidth = (inputValue: number) => {
    return Math.min(Math.max(inputValue, PROJECTS.MIN_INTERVAL_TICK_WIDTH), PROJECTS.MAX_INTERVAL_TICK_WIDTH);
  };

  useEffect(() => {
    let tick;
    if (!duration || duration * PROJECTS.DEFAULT_INTERVAL_TICK_WIDTH > timelineContainerWidth) {
      tick = PROJECTS.DEFAULT_INTERVAL_TICK_WIDTH;
    } else {
      tick = (timelineContainerWidth * 0.8) / (Math.ceil(duration / 5) * 5);
    }

    setIntervalTickWidth(computeIntervalTickWidth(tick));
  }, [duration, timelineContainerWidth]);

  useEffect(() => {
    setZoomLevel(intervalTickWidth / PROJECTS.DEFAULT_INTERVAL_TICK_WIDTH);
  }, [intervalTickWidth]);

  const onZoomLevelChange = (newValue: number) => {
    setZoomLevel(newValue);
    setIntervalTickWidth(computeIntervalTickWidth(newValue * PROJECTS.DEFAULT_INTERVAL_TICK_WIDTH));
  };

  const onZoomIn = () => {
    const newZoomLevel = zoomLevel + zoomLevelStep;
    if (newZoomLevel <= maxZoomLevel) {
      onZoomLevelChange(newZoomLevel);
    }
  };

  const onZoomOut = () => {
    const newZoomLevel = zoomLevel - zoomLevelStep;
    if (newZoomLevel >= minZoomLevel) {
      onZoomLevelChange(newZoomLevel);
    }
  };

  const onAnimationDelayChange = (newDelay: number) => {
    if (!scene.animation) {
      return;
    }

    onEditScene({
      ...scene,
      animation: { ...scene.animation, delay: newDelay },
    });
  };

  const onPipDelayChange = (newDelay: number) => {
    if (!scene.pip) {
      return;
    }

    onEditScene({
      ...scene,
      pip: { ...scene.pip, delay: newDelay },
    });
  };

  const onAddSubtitleBlock = (newSubtitleBlock: SubtitleBlock) => {
    if (!project.subtitlesLanguage) {
      return;
    }

    if (!scene.subtitles) {
      onEditScene(
        {
          ...scene,
          subtitles: { language: project.subtitlesLanguage, blocks: [newSubtitleBlock] },
        },
        true
      );
      return;
    }

    const newBlocks = [...scene.subtitles.blocks, newSubtitleBlock];
    const newBlocksSorted = newBlocks.sort((a, b) => {
      return a.endAt > b.endAt ? 1 : -1;
    });

    onEditScene({
      ...scene,
      subtitles: { ...scene.subtitles, blocks: [...newBlocksSorted] },
    });
  };

  const onAddSubtitleBlocks = (newSubtitleBlock: SubtitleBlock[]) => {
    if (!project.subtitlesLanguage) {
      return;
    }

    const newBlocks = [...newSubtitleBlock];
    const newBlocksSorted = newBlocks.sort((a, b) => {
      return a.endAt > b.endAt ? 1 : -1;
    });

    onEditScene({
      ...scene,
      subtitles: { language: project.subtitlesLanguage, blocks: [...newBlocksSorted] },
    });
  };

  const onAddElementToScene = (sceneElement: ProjectSceneElement) => {
    onPause();
    onCurrentTimeChange(0);
    onSelectElement(sceneElement);
  };

  const [calculateMousePositionOnX] = useDebouncedCallback((event: React.MouseEvent<HTMLDivElement>) => {
    if (ref.current) {
      const positionValueOnX = ref.current.scrollLeft ?? 0;
      setScrollValueOnX(positionValueOnX);
      setMousePositionOnXWithScroll(WindowUtils.getRelativeCoordinates(event, ref.current).x + positionValueOnX);
    }
  }, 1);

  const [checkScrollChange] = useDebouncedCallback(() => {
    if (ref.current && scrollValueOnX !== ref.current.scrollLeft) {
      setScrollValueOnX(ref.current.scrollLeft ?? 0);
    }
  }, 10);

  const renderSeekBarHover = () => {
    return (
      <SeekBarHover
        scrollValueOnX={scrollValueOnX}
        mousePositionOnXWithScroll={mousePositionOnXWithScroll}
        intervalTickWidth={intervalTickWidth}
        onCurrentTimeChange={onCurrentTimeChange}
        videoTrimValue={videoTrimValue}
        pipTrimValue={pipTrimValue}
      />
    );
  };

  const renderVideoControls = () => {
    const playPauseButton = isPlaying ? (
      <Button
        type="primary"
        shape="circle"
        icon={<BsPauseFill />}
        onClick={onPause}
        className={classes.controlButton}
      />
    ) : (
      <Button type="primary" shape="circle" icon={<BsPlayFill />} onClick={onPlay} className={classes.controlButton} />
    );

    const trimStartOverflow = VideoTrimUtils.getMinTrimStartForPosition(videoTrimValue, pipTrimValue);
    const newCurrentTime = Math.max(currentTime - trimStartOverflow, 0);
    const currentTimeString = TimeUtils.formatSecondsIntoDuration(newCurrentTime, { withMillis: true });
    const durationString = TimeUtils.formatSecondsIntoDuration(videoTrimValue.trimEnd - videoTrimValue.trimStart, {
      withMillis: true,
    });

    return (
      <>
        {playPauseButton}
        <span className={classes.timeValuesContainer}>
          {currentTimeString}&nbsp;/&nbsp;{durationString}
        </span>
      </>
    );
  };

  const renderZoomSlider = () => {
    return (
      <div className={classes.timelineZoomSlider}>
        <ZoomOutOutlined className={classes.zoomSliderIcon} onClick={onZoomOut} />
        <Slider
          tipFormatter={null}
          className={classes.zoomSlider}
          onChange={onZoomLevelChange}
          value={zoomLevel}
          min={minZoomLevel}
          max={maxZoomLevel}
          step={zoomLevelStep}
        />
        <ZoomInOutlined className={classes.zoomSliderIcon} onClick={onZoomIn} />
      </div>
    );
  };

  const computeSceneAnimationDuration = () => {
    if (isSlideScene || !scene || !scene.animation || !scene.animation.duration) {
      return duration;
    }

    const videoDuration = videoTrimValue.trimEnd - videoTrimValue.trimStart;

    if (
      AnimationsUtils.isMotionDesignAnimation(scene.animation.code) ||
      (!AnimationsUtils.isLowerThirdAnimation(scene.animation.code) && scene.animation.duration > videoDuration) ||
      scene.animation.code === Animations.PLANE.code
    ) {
      return videoDuration;
    }

    return scene.animation.duration;
  };

  const renderSceneAnimationRow = () => {
    // If there is no animation on the scene, we display a button to add one
    if (!scene.animation) {
      return (
        <div className={classNames(classes.sceneElementRow, 'sceneElementAnimationRow')}>
          <div className={classNames(classes.sceneElementIcon)}>
            <IoText />
          </div>
          <div className={classes.sceneAnimationContainer}>
            <AddAnimationToSceneButton
              isSlide={isSlideScene}
              format={format}
              theme={theme}
              customColors={customColors}
              scene={scene}
              onSelectElement={onSelectElement}
              onSubmitScene={onSubmitScene}
              intervalTickWidth={intervalTickWidth}
              callbackIfUnsavedChangesConfirmed={callbackIfUnsavedChangesConfirmed}
              videoTrimValue={videoTrimValue}
              pipTrimValue={pipTrimValue}
              resetTrimAndDelayValues={resetTrimAndDelayValues}
            />
          </div>
        </div>
      );
    }

    const animationWidth = computeSceneAnimationDuration() * intervalTickWidth;
    const isSelected = ProjectUtils.isAnimationSelected(selectedElement);

    return (
      <div className={classNames(classes.sceneElementRow, 'sceneElementAnimationRow')}>
        <div className={classNames(classes.sceneElementIcon, isSelected && classes.selectedSceneElementIcon)}>
          <IoText />
        </div>

        <div className={classes.sceneAnimationContainer}>
          <SceneAnimationBar
            animation={scene.animation}
            backgroundVideo={scene?.backgroundVideo}
            pip={scene?.pip}
            isSelected={isSelected}
            width={animationWidth}
            intervalTickWidth={intervalTickWidth}
            videoTrimValue={videoTrimValue}
            pipTrimValue={pipTrimValue}
            onAnimationDelayChange={onAnimationDelayChange}
            onClick={() => onSelectElement(ProjectSceneElements.ANIMATION)}
          />
        </div>
      </div>
    );
  };

  const renderSceneRecordableVideoRow = () => {
    // No video row if the scene is a slide (or uses the VideoEmpty)
    if (
      isSlideScene ||
      (scene.backgroundVideo &&
        scene.backgroundVideo.media &&
        AnimationsUtils.isVideoEmpty(scene.backgroundVideo.media.url))
    ) {
      return null;
    }

    const isSelected = ProjectUtils.isVideoSelected(selectedElement);
    const videoWidth = sceneTotalDuration ? sceneTotalDuration * intervalTickWidth : 0;

    if (!scene.backgroundVideo) {
      return (
        <div className={classes.sceneElementRow}>
          <div className={classNames(classes.sceneElementIcon, isSelected && classes.selectedSceneElementIcon)}>
            <FaFilm />
          </div>

          <div className={classes.videoTrimBarContainer}>
            <AddBackgroundVideoToSceneButton
              backgroundVideo={scene?.backgroundVideo}
              animation={scene?.animation}
              pip={scene?.pip}
              intervalTickWidth={intervalTickWidth}
              videoTrimValue={videoTrimValue}
              onClick={() => onAddElementToScene(ProjectSceneElements.RECORDABLE_VIDEO)}
            />
          </div>
        </div>
      );
    }

    return (
      <div className={classNames(classes.sceneElementRow, 'sceneElementVideoRow')}>
        <div className={classNames(classes.sceneElementIcon, isSelected && classes.selectedSceneElementIcon)}>
          <FaFilm />
        </div>

        <div className={classes.videoTrimBarContainer}>
          <SceneVideoTrimBar
            backgroundVideo={scene?.backgroundVideo}
            pip={scene?.pip}
            animation={scene?.animation}
            isSelected={isSelected}
            onTrimChange={(trim: VideoTrim) => {
              onTrimChange(trim, ProjectSceneElementsCode.RECORDABLE_VIDEO);
            }}
            videoName={scene.backgroundVideo.media.publicName}
            videoTrimValue={videoTrimValue}
            width={videoWidth}
            onClick={() => onSelectElement(ProjectSceneElements.RECORDABLE_VIDEO)}
            intervalTickWidth={intervalTickWidth}
            pipTrimValue={pipTrimValue}
          />
        </div>
      </div>
    );
  };

  const handleOnSubtitleBlockChange = (index: number, newBlockTrimValues: VideoTrim) => {
    if (!scene.subtitles?.blocks) {
      return;
    }

    const subtitlesChanged = cloneDeep(scene.subtitles?.blocks);
    subtitlesChanged[index].startAt = newBlockTrimValues.trimStart;
    subtitlesChanged[index].endAt = newBlockTrimValues.trimEnd;

    onEditScene({
      ...scene,
      subtitles: scene.subtitles ? { ...scene.subtitles, blocks: [...subtitlesChanged] } : undefined,
    });
  };

  const renderSceneSubtitlesRow = () => {
    // No subtitles row if the scene is a slide (or uses the VideoEmpty), or if the project has no subtitlesLanguage set
    if (
      isSlideScene ||
      (scene.backgroundVideo &&
        scene.backgroundVideo.media &&
        AnimationsUtils.isVideoEmpty(scene.backgroundVideo.media.url)) ||
      !project.subtitlesLanguage
    ) {
      return null;
    }

    const isSelected = ProjectUtils.areSubtitlesSelected(selectedElement);

    return (
      <div className={classNames(classes.sceneElementRow)}>
        <div className={classNames(classes.sceneElementIcon, isSelected && classes.selectedSceneElementIcon)}>
          <MdSubtitles />
        </div>

        <div className={classes.sceneAnimationContainer}>
          <SceneSubtitles
            projectId={project.id}
            scene={scene}
            subtitlesLanguage={project.subtitlesLanguage}
            subtitleBlocks={scene.subtitles?.blocks || []}
            intervalTickWidth={intervalTickWidth}
            selectedElementId={selectedElementId}
            videoTrimValue={videoTrimValue}
            pipTrimValue={pipTrimValue}
            onClick={(elementId: number) => {
              onSelectElement(ProjectSceneElements.SUBTITLES, false, elementId);
            }}
            callbackReset={callbackReset}
            onAddSubtitleBlock={onAddSubtitleBlock}
            onAddSubtitleBlocks={onAddSubtitleBlocks}
            onSubtitleBlockChange={handleOnSubtitleBlockChange}
            callbackIfUnsavedChangesConfirmed={callbackIfUnsavedChangesConfirmed}
            submitSubtitleBlock={submitSubtitleBlock}
            scrollLeft={ref?.current?.scrollLeft}
          />
        </div>
      </div>
    );
  };

  const renderScenePipRow = () => {
    if (isSlideScene) {
      return null;
    }

    const isSelected = ProjectUtils.isPipSelected(selectedElement);

    if (!(scene.pip && scene.pip.media)) {
      return (
        <div className={classes.sceneElementRow}>
          <div className={classNames(classes.sceneElementIcon, isSelected && classes.selectedSceneElementIcon)}>
            <BsFillLayersFill />
          </div>

          <div className={classNames(classes.addSceneButtonPipContainer, classes.scenePipContainer)}>
            <AddPipToSceneButton
              backgroundVideo={scene?.backgroundVideo}
              animation={scene?.animation}
              pip={scene?.pip}
              intervalTickWidth={intervalTickWidth}
              videoTrimValue={videoTrimValue}
              onClick={() => onAddElementToScene(ProjectSceneElements.PIP)}
            />
          </div>
        </div>
      );
    }

    return (
      <div className={classNames(classes.sceneElementRow, 'sceneElementPipRow')}>
        <div className={classNames(classes.sceneElementIcon, isSelected && classes.selectedSceneElementIcon)}>
          <BsFillLayersFill />
        </div>

        <div className={classes.scenePipContainer}>
          <ScenePipBar
            backgroundVideo={scene?.backgroundVideo}
            animation={scene?.animation}
            pip={scene.pip}
            videoTrimValue={videoTrimValue}
            isSelected={isSelected}
            intervalTickWidth={intervalTickWidth}
            onClick={() => onSelectElement(ProjectSceneElements.PIP)}
            onPipDelayChange={onPipDelayChange}
            onPipTrimChange={(trim: VideoTrim) => {
              onTrimChange(trim, ProjectSceneElementsCode.PIP);
            }}
          />
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <SceneTimelineSkeleton />;
  }

  return (
    <div className={classes.sceneTimelineContainer} ref={timelineRef} onMouseLeave={onMouseOutHandle}>
      {isHovering && renderSeekBarHover()}
      <div className={classes.videoControlsContainer}>{renderVideoControls()}</div>
      <div className={classes.timelineZoomSliderContainer}>{renderZoomSlider()}</div>
      <div
        ref={ref}
        className={classNames(classes.videoTimelineContainer, 'joyRideVideoTimeLineContainer')}
        onMouseMove={() => checkScrollChange()}
      >
        <div className={classes.seekbarContainer}>
          <SeekBar
            duration={sceneTotalDuration}
            videoTrimValue={videoTrimValue}
            pipTrimValue={pipTrimValue}
            currentTime={currentTime}
            onCurrentTimeChange={onCurrentTimeChange}
            intervalTickWidth={intervalTickWidth}
          />
        </div>
        <div className={classes.videoGraduatedTimeRangeContainer}>
          <VideoGraduatedTimeRange
            maxSeconds={graduatedTimeRangeWidth / intervalTickWidth}
            intervalTickWidth={intervalTickWidth}
            videoTrimValue={videoTrimValue}
            pipTrimValue={pipTrimValue}
          />
          <div
            className={classes.seekBarHoverContainer}
            onMouseOver={onMouseOverHandle}
            onMouseMove={(event) => calculateMousePositionOnX(event)}
          />
        </div>
        <div ref={sceneElementsRef} onMouseOver={onMouseOutHandle}>
          {renderSceneSubtitlesRow()}
          {renderSceneAnimationRow()}
          {renderScenePipRow()}
          {renderSceneRecordableVideoRow()}
        </div>
      </div>
    </div>
  );
};

export default SceneTimeline;
