import { Spin } from 'antd';
import { SpinIndicator } from 'antd/es/spin';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { createUseStyles } from 'react-jss';
import { THEME } from '../../Constants';

type Props = {
  size?: 'small' | 'large';
  indicator?: SpinIndicator;
  message?: string;
  className?: string;
};

const useStyles = createUseStyles({
  loaderContainer: {
    width: '100%',
    height: '100%',
    zIndex: 2,
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spin: {
    '& .ant-spin-dot-item': {
      backgroundColor: THEME.DEFAULT.MAIN_COLOR,
    },
    '& .ant-spin-text': {
      color: THEME.DEFAULT.MAIN_COLOR,
    },
  },
});

const PageContentLoader: FunctionComponent<Props> = ({ size = 'large', indicator, message, className }: Props) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.loaderContainer, className)}>
      <Spin className={classes.spin} size={size} indicator={indicator} tip={message} />
    </div>
  );
};

export default PageContentLoader;
