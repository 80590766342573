import { ColumnProps } from 'antd/es/table';
import Fuse from 'fuse.js';
import { TFunction } from 'i18next';
import { findIndex } from 'lodash';
import React, { ReactElement, ReactNode } from 'react';
import Highlighter from 'react-highlight-words';
import Preformatted from '../../../../components/preformatted/Preformatted';
import FilterIcon from '../../../../components/table/search-in-column-filter/FilterIcon';
import SearchInColumnFilter from '../../../../components/table/search-in-column-filter/SearchInColumnFilter';
import { Role } from '../../../../core/rule/Roles';
import { DashboardSubtitles, UsersModelCharter } from '../../../../services/api/types/ChartersServiceTypes';
import { APICompanyUsers } from '../../../../services/api/types/CompaniesServiceTypes';
import { APIModelUser } from '../../../../services/api/types/UsersServiceTypes';
import StringUtils from '../../../../utils/StringUtils';
import TimeUtils from '../../../../utils/TimeUtils';
import UsersUtils from '../../../../utils/UsersUtils';
import EditableUsername from './editable-username/EditableUsername';
import KannelleRoleTag from './role-tag/KannelleRoleTag';
import KannelleRoleTagDropdown from './role-tag/KannelleRoleTagDropdown';
import SliderColumnFilter from './slider-column-filter/SliderColumnFilter';
import KannelleStatusSwitch from './status-switch/KannelleStatusSwitch';

/**
 **
 ** BUILD COLUMNS
 **
 */

type BuildColumnsArgs = {
  users: UsersModelCharter[];
  currentUser: APIModelUser;
  currentUserRole: Role;
  filter: Record<string, string> | undefined;
  setFilter: React.Dispatch<React.SetStateAction<Record<string, string> | undefined>>;
  isMobileOrTablet: boolean;
  canReadUserIds: boolean;
  classes: Record<string, string>;
  t: TFunction;
  filteredInfo: Record<string, string[] | null>;
  disabledUserIds: Array<string> | undefined;
  isCurrentUserPoc: boolean;
  handleSearch?: (confirm: () => void) => void;
  activeCompanyUsers: APICompanyUsers[];
  allowedToUpdateUser?: boolean;
};

export const buildColumns = ({
  users,
  currentUser,
  currentUserRole,
  filter,
  setFilter,
  isMobileOrTablet,
  canReadUserIds,
  classes,
  t,
  filteredInfo,
  disabledUserIds,
  isCurrentUserPoc,
  handleSearch,
  activeCompanyUsers,
  allowedToUpdateUser = true,
}: BuildColumnsArgs): ColumnProps<UsersModelCharter>[] => {
  const fuseName = new Fuse(users, {
    keys: ['name'],
    threshold: 0.35,
  });
  const fuseEmail = new Fuse(users, {
    keys: ['email'],
    threshold: 0.3,
  });

  return [
    {
      title: t('users.name'),
      dataIndex: 'name',
      key: 'user_name',
      fixed: !isMobileOrTablet ? 'left' : undefined,
      sorter: (userA, userB, sortOrder): number => UsersUtils.sortUsersByName(userA, userB, currentUser, sortOrder),
      render: (name: string, row: UsersModelCharter): ReactElement => {
        const isCurrentUser = row.id === currentUser?.id;
        return (
          <EditableUsername
            user={row}
            filter={filter}
            isCurrentUser={isCurrentUser}
            isEditable={!isCurrentUser && disabledUserIds && !disabledUserIds.includes(row.id) && allowedToUpdateUser}
          />
        );
      },
      filteredValue: filteredInfo.user_name || null,
      filterDropdown: (filterProps): ReactNode => (
        <SearchInColumnFilter
          {...filterProps}
          dataIndex="name"
          fuse={fuseName}
          filter={filter}
          placeholder={t('users.searchOnColumnPlaceholder', { columnName: t('users.name').toLowerCase() })}
          setFilter={setFilter}
          possibleValues={users?.map((u) => u.name)}
          handleSearch={handleSearch}
        />
      ),
      filterIcon: FilterIcon,
      onFilter: (value, record: UsersModelCharter): boolean => {
        const result = fuseName.search(value as any);
        const index = findIndex(result, ({ item }) => {
          return item.id === record.id;
        });

        return index >= 0;
      },
    },
    {
      title: t('users.email'),
      dataIndex: 'email',
      key: 'user_email',
      filteredValue: filteredInfo.user_email || null,
      sorter: (userA, userB, sortOrder): number => UsersUtils.sortUsersByEmail(userA, userB, currentUser, sortOrder),
      render: (email: string, row: UsersModelCharter): ReactElement => {
        return (
          <div>
            {filter && filter.email ? (
              <>
                <Highlighter
                  className={classes.highlightedText}
                  searchWords={[filter.email]}
                  autoEscape
                  textToHighlight={email.toString()}
                />
                {canReadUserIds && (
                  <Preformatted className={classes.userId}>
                    <Highlighter
                      className={classes.highlightedText}
                      searchWords={[filter.email]}
                      autoEscape
                      textToHighlight={row.id}
                    />
                  </Preformatted>
                )}
              </>
            ) : (
              <>
                <span>{email}</span>
                {canReadUserIds && <Preformatted className={classes.userId}>{row.id}</Preformatted>}
              </>
            )}
          </div>
        );
      },
      filterDropdown: (filterProps): ReactNode => {
        let possibleValues = users?.map((u) => u.email);
        if (canReadUserIds && users && possibleValues) {
          const userIds = users?.map((u) => u.id);
          possibleValues = possibleValues.concat(userIds);
        }
        return (
          <SearchInColumnFilter
            {...filterProps}
            dataIndex="email"
            fuse={fuseEmail}
            filter={filter}
            setFilter={setFilter}
            placeholder={t('users.searchOnColumnPlaceholder', { columnName: t('users.email').toLowerCase() })}
            possibleValues={possibleValues}
            handleSearch={handleSearch}
          />
        );
      },
      filterIcon: FilterIcon,
      onFilter: (value, record: UsersModelCharter): boolean => {
        if (canReadUserIds) {
          // If canReadUserIds, we return records matching either on the email or the ID
          return [record.email, record.id]
            .map((userEmailOrId) => StringUtils.normalize(userEmailOrId.toString()))
            .some((userEmailOrIdNormalized) =>
              userEmailOrIdNormalized.includes(StringUtils.normalize(value.toString()))
            );
        }
        // Else we only return records matching on the email
        return StringUtils.normalize(record.email.toString()).includes(StringUtils.normalize(value.toString()));
      },
    },
    {
      title: t('users.role'),
      dataIndex: 'role',
      key: 'user_role',
      filteredValue: filteredInfo.user_role || null,
      render: (role: string, row: UsersModelCharter): ReactElement => (
        <KannelleRoleTagDropdown
          concernedUser={row}
          disabled={
            (!isCurrentUserPoc && row.id === currentUser?.id) ||
            (disabledUserIds && disabledUserIds.includes(row.id)) ||
            !allowedToUpdateUser
          }
        />
      ),
      sorter: (userA, userB, sortOrder): number =>
        UsersUtils.sortUsersByRoleAndPlaceCurrentUserFirst(userA, userB, currentUser, sortOrder),
      filters: currentUserRole
        ?.getAccessibleRolesCodes()
        .map((role) => ({ value: role, text: <KannelleRoleTag roleName={role} /> })),
      onFilter: (value, record: UsersModelCharter): boolean => record.acl.role === value,
    },
    {
      title: t('users.status'),
      dataIndex: 'isActive',
      key: 'user_activated',
      filteredValue: filteredInfo.user_activated || null,
      sorter: (userA, userB, sortOrder): number => UsersUtils.sortUsersByStatus(userA, userB, currentUser, sortOrder),
      render: (isActive: boolean, row: UsersModelCharter): ReactElement => {
        return (
          <KannelleStatusSwitch
            concernedUser={row}
            activeCompanyUsers={activeCompanyUsers}
            disabled={
              (!isCurrentUserPoc && row.id === currentUser?.id) ||
              (disabledUserIds && disabledUserIds.includes(row.id)) ||
              !allowedToUpdateUser
            }
          />
        );
      },
      filters: [
        {
          value: true,
          text: t('users.statusActive'),
        },
        {
          value: false,
          text: t('users.statusInactive'),
        },
      ],
      onFilter: (value, record: UsersModelCharter): boolean => record.acl.isActive === value,
    },
    {
      title: t('users.subtitleConsumption'),
      dataIndex: 'dashboard',
      key: 'user_subtitle',
      filteredValue: filteredInfo.user_subtitle || null,
      sorter: (userA, userB, sortOrder): number =>
        UsersUtils.sortUsersBySubtitleConsumption(userA, userB, currentUser, sortOrder),
      render: (dashboard: DashboardSubtitles): string => {
        const { currentUsage } = dashboard.subtitles;
        return TimeUtils.formatSecondsIntoHumanTimeString(currentUsage, t);
      },
      filterDropdown: (filterProps): ReactNode => (
        <SliderColumnFilter {...filterProps} dataIndex="dashboard" setFilter={setFilter} />
      ),
      onFilter: (value, record: UsersModelCharter): boolean => {
        const { currentUsage } = record.dashboard.subtitles;
        // Value is formatted like 'min_max'.
        // If max is the maximum possible value, the 'value' input is like 'min_+' saying there is no maximum bound
        const [min, max] = value
          .toString()
          .split('_')
          .map((bound) => (bound === '+' ? undefined : Number(bound)));

        if (currentUsage !== undefined) {
          // If no max bound
          if (min !== undefined && max === undefined) {
            return currentUsage >= min;
          }
          // If min and max bounds
          if (min !== undefined && max !== undefined) {
            return currentUsage >= min && currentUsage <= max;
          }
        }
        return false;
      },
    },
  ];
};
