import { FontSizeOutlined } from '@ant-design/icons/lib';
import { BackTop, Col, message, Row, Tabs } from 'antd';
import { BreadcrumbProps } from 'antd/es/breadcrumb';
import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import { UploadChangeParam } from 'antd/lib/upload';
import { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { Link, Prompt, useHistory, useLocation, useParams } from 'react-router-dom';
import ErrorAlert from '../../../../components/alert/ErrorAlert';
import PageContentLoader from '../../../../components/loader/PageContentLoader';
import FileUploader, { DraggerCustomRequestParams } from '../../../../components/modal/FileUploader';
import KannelleHelpButton from '../../../../components/page-header/KannelleHelpButton';
import KannellePageHeader from '../../../../components/page-header/KannellePageHeader';
import StylesheetLink from '../../../../components/stylesheet-link/StylesheetLink';
import { DEVICE_SIZES_QUERIES, LINK, LOGGING_EVENT, THEME } from '../../../../Constants';
import useCanAccess from '../../../../hooks/useCanAccess';
import useFetchCharterById from '../../../../hooks/useFetchCharterById';
import useSocket from '../../../../hooks/useSocket';
import useUnsavedChangesNotification from '../../../../hooks/useUnsavedChangesNotification';
import JoyRideHelpFonts from '../../../../onboarding/JoyRideHelpFonts';
import { RootState } from '../../../../redux/RootState';
import { APIManager } from '../../../../services/api/APIManager';
import {
  getCharterFontFamilies,
  getCharterFontSignedUrlToUpload,
  patchCharterThemeFonts,
  resetCharterThemeFonts,
} from '../../../../services/api/ChartersService';
import { getPublicFontFamilies } from '../../../../services/api/FontFamiliesService';
import { uploadFileToSignedUrl } from '../../../../services/api/GlobalService';
import { SocketManager } from '../../../../services/api/SocketManager';
import { APIFullModelCharter } from '../../../../services/api/types/ChartersServiceTypes';
import { APIGetFontFamiliesResponse, Font, FontFamily } from '../../../../services/api/types/FontFamiliesServiceTypes';
import { AssetOrMediaProcessingResponse } from '../../../../services/api/types/WebSocketTypes';
import { CharterIdPathParam } from '../../../../services/navigation/NavigationConfigTypes';
import FontUtils from '../../../../utils/FontUtils';
import { PermissionList } from '../../../../utils/types/CharterPermissionTypes';
import FontFamilyCard from './font-families/FontFamilyCard';
import FontSettingsToolbar from './FontSettingsToolbar';
import SelectedFontsRow from './SelectedFontsRow';

const { TabPane } = Tabs;

const useStyles = createUseStyles({
  pageContent: {
    backgroundColor: '#FFFFFF',
    padding: 24,
    margin: 24,
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      margin: 0,
      padding: '5px 10px',
    },
  },
  backTop: {
    right: '32px',
    bottom: '100px',
  },
  avatar: {
    backgroundColor: THEME.DEFAULT.MAIN_COLOR,
  },
  pageDescription: {
    marginBottom: 16,
    textAlign: 'justify',
  },
  fontTabs: {
    marginTop: '10px !important',
  },
  fontCardColumn: {
    display: 'inline-flex',
    alignItems: 'stretch',
  },
  errorAlert: {
    marginTop: '30px',
  },
  helpIcon: {
    marginLeft: 8,
    fontSize: '14px !important',
    '& *': {
      fontSize: '14px !important',
    },
  },
  titleWithIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

const CharterFonts: FunctionComponent = () => {
  const [publicFontFamilies, setPublicFontFamilies] = useState<FontFamily[]>();
  const [charterFontFamilies, setCharterFontFamilies] = useState<FontFamily[]>();
  const [fontTextExample, setFontTextExample] = useState<string>();
  const [fontSize, setFontSize] = useState<number>(18);
  const [selectedTitleFont, setSelectedTitleFont] = useState<Font>();
  const [selectedTextFont, setSelectedTextFont] = useState<Font>();
  const [initialTitleFont, setInitialTitleFont] = useState<Font>();
  const [initialTextFont, setInitialTextFont] = useState<Font>();
  const [isPublicFontFamiliesLoading, setIsPublicFontFamiliesLoading] = useState(true);
  const [isCharterFontFamiliesLoading, setIsCharterFontFamiliesLoading] = useState(true);
  const [isApplyLoading, setIsApplyLoading] = useState(false);
  const [runHelp, setRunHelp] = useState(false);
  const [room, setRoom] = useState<string>();
  const [activeTab, setActiveTab] = useState('kannelle_fonts');
  const isSocketConnected = useSocket(room ?? undefined);

  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { charterId } = useParams<CharterIdPathParam>();
  const currentCharter = useSelector((state: RootState) => state.charters.current);
  const currentCompany = useSelector((state: RootState) => state.companies.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);

  const { hasUserAccessToCharter } = useCanAccess(PermissionList.WEB_DASHBOARD);

  const { loading: isCharterLoading, isError } = useFetchCharterById(parseInt(charterId, 10));
  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());
  const fontSizeLimit = 10; // MB
  const query = new URLSearchParams(location.search);
  const activeTabParam = query.get('activeTab') ?? 'kannelle_fonts';

  useEffect(() => {
    const cancelTokenSource = cancelTokenSourceRef.current;

    return (): void => {
      cancelTokenSource.cancel('Cancelled patching charter fonts or fetching font families due to component unmount.');
    };
  }, []);

  useEffect(() => {
    if (activeTabParam) {
      setActiveTab(activeTabParam);
    }
  }, [activeTabParam]);

  // Fetch the Kannelle public fonts
  useEffect(() => {
    const cancelTokenSource = cancelTokenSourceRef.current;
    getPublicFontFamilies(cancelTokenSource)
      .then((response: APIGetFontFamiliesResponse) => {
        const { data } = response;
        setPublicFontFamilies(data.items);
        setIsPublicFontFamiliesLoading(false);
      })
      .catch((e) => {
        log.debug('Error during public fonts fetch', e);
        setIsPublicFontFamiliesLoading(false);
      });
  }, []);

  const fetchCharterFontFamilies = (charter: APIFullModelCharter): void => {
    const cancelTokenSource = cancelTokenSourceRef.current;
    getCharterFontFamilies(charter.id, cancelTokenSource)
      .then((response: APIGetFontFamiliesResponse) => {
        const { data } = response;
        setCharterFontFamilies(data.items);
        setIsCharterFontFamiliesLoading(false);
      })
      .catch((e) => {
        log.debug('Error during charter fonts fetch', e);
        setIsCharterFontFamiliesLoading(false);
      });
  };

  // Fetch the charter private fonts
  useEffect(() => {
    if (!currentCharter) {
      return;
    }

    fetchCharterFontFamilies(currentCharter);
    setRoom(`charters/${currentCharter.id}/font`);
  }, [currentCharter]);

  // Refresh the charter fonts when a new font has been processed in the charter
  useEffect(() => {
    if (!currentCharter) {
      return undefined;
    }

    const refreshCharterFontsIfNeeded = (payload: AssetOrMediaProcessingResponse): void => {
      if (payload.data.status !== 'PROCESSED') {
        return;
      }
      setActiveTab('charter_fonts');
      fetchCharterFontFamilies(currentCharter);
    };

    if (isSocketConnected) {
      SocketManager.onMessage(refreshCharterFontsIfNeeded);

      return (): void => SocketManager.offMessage(refreshCharterFontsIfNeeded);
    }

    return undefined;
  }, [isSocketConnected, currentCharter]);

  // Initialize selected fonts if the charter had specific selected fonts, and also set the
  // initial fonts (for the reset action).
  useEffect(() => {
    if (!currentCharter) {
      return;
    }

    if (currentCharter.themeFonts && !isEmpty(currentCharter.themeFonts)) {
      const { titleFont, textFont } = currentCharter.themeFonts;
      if (publicFontFamilies && charterFontFamilies) {
        if (titleFont) {
          const titleFontFamily = FontUtils.findFontFamilyByFontId(
            [...publicFontFamilies, ...charterFontFamilies],
            titleFont.id
          );
          const newTitleFont = {
            ...titleFont,
            fontFamilyName: titleFontFamily?.name,
          };
          setInitialTitleFont(newTitleFont);
          setSelectedTitleFont(newTitleFont);
        }

        if (textFont) {
          const textFontFamily = FontUtils.findFontFamilyByFontId(
            [...publicFontFamilies, ...charterFontFamilies],
            textFont.id
          );
          const newTextFont = {
            ...textFont,
            fontFamilyName: textFontFamily?.name,
          };
          setInitialTextFont(newTextFont);
          setSelectedTextFont(newTextFont);
        }
      }
    } else {
      setInitialTitleFont(undefined);
      setInitialTextFont(undefined);
      setSelectedTitleFont(undefined);
      setSelectedTextFont(undefined);
    }
  }, [currentCharter, charterFontFamilies, publicFontFamilies]);

  // Check if the selected fonts have been updated without being saved
  const areFontsUnsaved = (): boolean => {
    return (
      (selectedTextFont && initialTextFont?.id !== selectedTextFont?.id) || // If textFont selected, check if different from initial one
      (selectedTitleFont && initialTitleFont?.id !== selectedTitleFont?.id) || // Or if titleFont selected, check if different from initial one
      (!selectedTitleFont && !!initialTitleFont) || // Or check if selectedTitle font is undefined but the initial one was not undefined
      (!selectedTextFont && !!initialTextFont) || // Or check if selectedText font is undefined but the initial one was not undefined
      false
    );
  };

  const areFontsChangesUnsaved = areFontsUnsaved();
  useUnsavedChangesNotification(areFontsChangesUnsaved, t('charters.fonts.unsavedFontsNotification'), [
    selectedTextFont,
    selectedTitleFont,
    initialTextFont,
    initialTitleFont,
    currentCharter,
  ]);

  if (!currentCharter) {
    return null;
  }

  const onFontTextExampleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newText = event.target.value;

    if (!newText || newText === '') {
      setFontTextExample(undefined);
    }

    setFontTextExample(newText);
  };

  const onFontSizeChange = (value: number | undefined): void => {
    if (value) {
      setFontSize(value);
    }
  };

  const onSelectTitleFont = (font: Font, fontFamily: FontFamily): void => {
    setSelectedTitleFont({ ...font, fontFamilyName: fontFamily.name });
  };

  const onSelectTextFont = (font: Font, fontFamily: FontFamily): void => {
    setSelectedTextFont({ ...font, fontFamilyName: fontFamily.name });
  };

  const onResetToDefault = (): void => {
    setSelectedTitleFont(undefined);
    setSelectedTextFont(undefined);
  };

  const onCancelFonts = (): void => {
    setSelectedTitleFont(initialTitleFont);
    setSelectedTextFont(initialTextFont);
  };

  // Patch the charter fonts by applying the selected fonts
  const onApplyFonts = (): void => {
    if (!currentCharter) {
      return;
    }

    setIsApplyLoading(true);
    const data = {
      titleFontId: selectedTitleFont?.id,
      textFontId: selectedTextFont?.id,
    };

    if (!selectedTextFont && !selectedTitleFont) {
      resetCharterThemeFonts(currentCharter.id, cancelTokenSourceRef.current)
        .then(() => {
          loggingManager.logEvent(LOGGING_EVENT.REINITIALIZE_CHARTER_FONTS, {
            companyId: currentCompany?.id,
            charterId: currentCharter.id,
          });

          message.success(t('charters.fonts.themeFontsPatchSuccess'));
          setInitialTitleFont(undefined);
          setInitialTextFont(undefined);
          setIsApplyLoading(false);
        })
        .catch((e) => {
          log.debug(e.message);
          message.error(t('charters.fonts.themeFontsPatchError'));
          setIsApplyLoading(false);
        });
    } else {
      patchCharterThemeFonts(currentCharter.id, data, cancelTokenSourceRef.current)
        .then(() => {
          loggingManager.logEvent(LOGGING_EVENT.UPDATE_CHARTER_FONTS, {
            companyId: currentCompany?.id,
            charterId: currentCharter.id,
            titleFont: selectedTitleFont?.fullName,
            titleFontId: selectedTitleFont?.id,
            textFont: selectedTextFont?.fullName,
            textFontId: selectedTextFont?.id,
          });

          message.success(t('charters.fonts.themeFontsPatchSuccess'));
          setInitialTitleFont(selectedTitleFont);
          setInitialTextFont(selectedTextFont);
          setIsApplyLoading(false);
        })
        .catch((e) => {
          log.debug(e.message);
          message.error(t('charters.fonts.themeFontsPatchError'));
          setIsApplyLoading(false);
        });
    }
  };

  // Called when a font file is being uploaded
  const onUploadFontFile = (requestParam: DraggerCustomRequestParams): void => {
    if (!currentCharter) {
      return;
    }

    loggingManager.logEvent(LOGGING_EVENT.UPLOAD_CHARTER_FONT, {
      companyId: currentCompany?.id,
      charterId: currentCharter.id,
      fontFileName: requestParam.file.name,
    });

    getCharterFontSignedUrlToUpload(currentCharter.id, requestParam.file.name)
      .then((signedUrl) => {
        return uploadFileToSignedUrl(signedUrl.fileUploadURL, requestParam.file, (event) => {
          requestParam.onProgress({ percent: (event.loaded / event.total) * 100 }, requestParam.file);
        })
          .then(() => {
            requestParam.onSuccess({ ...requestParam, data: signedUrl.metadata });
          })
          .catch((err) => requestParam.onError(err));
      })
      .catch((err) => requestParam.onError(err));
  };

  const onUploadStatusChangeToError = (info: UploadChangeParam): void => {
    message.error(t('charters.fonts.uploadError', { fileName: info.file.name }));
  };

  const onSizeLimitExceeded = (): void => {
    message.error(t(`charters.fonts.uploadSizeLimit`, { limit: fontSizeLimit }));
  };

  if (!hasUserAccessToCharter(parseInt(charterId, 10))) {
    history.push(LINK.UNAUTHORIZED.path);
    return null;
  }

  const routes = [
    {
      path: '/charters',
      breadcrumbName: t('menu.charters'),
    },
    {
      path: `/charters/${currentCharter.id}`,
      breadcrumbName: currentCharter.name,
    },
    {
      path: location.pathname,
      breadcrumbName: t('charters.fonts.title'),
    },
  ];

  const breadcrumbProps: BreadcrumbProps = {
    itemRender: (route: Route) => {
      if (route.path === location.pathname) {
        return route.breadcrumbName;
      }
      return <Link to={route.path}>{route.breadcrumbName}</Link>;
    },
    routes,
  };

  const pageDescription = (
    <>
      {t('charters.fonts.pageDescription')}
      <br />
      {t('charters.fonts.pageDescriptionHint')}
    </>
  );

  const defaultSampleText =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.';

  const renderFontFamiliesTab = (familyType: 'public' | 'charter', fontFamilies?: FontFamily[]): JSX.Element => {
    if (
      (familyType === 'public' && isPublicFontFamiliesLoading) ||
      (familyType === 'charter' && isCharterFontFamiliesLoading)
    ) {
      return <PageContentLoader />;
    }

    return fontFamilies && fontFamilies.length > 0 ? (
      <Row gutter={[16, 16]}>
        {fontFamilies.map((fontFamily) => (
          <React.Fragment key={`${charterId}_font_${fontFamily.name}`}>
            <Col xs={24} sm={24} md={12} lg={8} xl={6} className={classes.fontCardColumn}>
              <FontFamilyCard
                fontFamily={fontFamily}
                fontSize={fontSize}
                selectedTitleFont={selectedTitleFont}
                selectedTextFont={selectedTextFont}
                onSelectTitleFont={onSelectTitleFont}
                onSelectTextFont={onSelectTextFont}
                text={fontTextExample || defaultSampleText}
              />
            </Col>
          </React.Fragment>
        ))}
      </Row>
    ) : (
      <div>{familyType === 'public' ? t('charters.fonts.noPublicFont') : t('charters.fonts.noCharterFont')}</div>
    );
  };

  const startHelp = (): void => {
    setRunHelp(true);
  };

  const endHelp = (): void => {
    setRunHelp(false);
  };

  if (isError) {
    return <ErrorAlert className={classes.errorAlert} message={t('ajaxError.charterFetch')} />;
  }

  if (isCharterLoading) {
    return <PageContentLoader />;
  }

  return (
    <KannellePageHeader
      title={
        <div className={classes.titleWithIcon}>
          {t('charters.fonts.title')}
          <KannelleHelpButton startHelp={startHelp} />
        </div>
      }
      breadcrumb={breadcrumbProps}
      className={classes.pageContent}
      avatar={{ className: classes.avatar, icon: <FontSizeOutlined /> }}
      extra={
        <FileUploader
          triggerText={t('charters.fonts.uploadFont')}
          modalTitle={t('charters.fonts.uploadFont')}
          uploadInstructionText={t('charters.fonts.uploadTitle')}
          uploadInstructionHint={t('charters.fonts.uploadHint')}
          uploadInstructionSizeLimit={t(`charters.fonts.uploadSizeLimit`, { limit: fontSizeLimit })}
          acceptFileType="font/ttf,font/otf,.ttf,.otf"
          sizeLimit={fontSizeLimit}
          onSizeLimitExceeded={onSizeLimitExceeded}
          onUploadStatusChangeToError={onUploadStatusChangeToError}
          onUploadFile={onUploadFontFile}
          listType="text"
        />
      }
    >
      <JoyRideHelpFonts runHelp={runHelp} callbackRunDone={endHelp} />

      {/* Load the stylesheets for every font family */}
      {publicFontFamilies &&
        charterFontFamilies &&
        [...publicFontFamilies, ...charterFontFamilies].map((fontFamily) => (
          <StylesheetLink key={fontFamily.id} url={fontFamily.stylesheetUrl} />
        ))}

      <BackTop visibilityHeight={150} className={classes.backTop} />
      <Prompt when={areFontsChangesUnsaved} message={t('charters.fonts.unsavedChangeWarning')} />

      <Row className={classes.pageDescription}>{pageDescription}</Row>

      <SelectedFontsRow
        selectedTitleFont={selectedTitleFont}
        selectedTextFont={selectedTextFont}
        onResetToDefault={onResetToDefault}
        areFontsUnsaved={areFontsChangesUnsaved}
        onApply={onApplyFonts}
        onCancel={onCancelFonts}
        isApplyLoading={isApplyLoading}
      />

      <FontSettingsToolbar
        fontSize={fontSize}
        fontTextExample={fontTextExample}
        onFontSizeChange={onFontSizeChange}
        onFontTextExampleChange={onFontTextExampleChange}
      />

      <Tabs
        defaultActiveKey="kannelle_fonts"
        className={classNames(classes.fontTabs, 'fontTabs')}
        activeKey={activeTab}
        onChange={setActiveTab}
      >
        <TabPane className="kannelleFonts" tab={t('charters.fonts.kannelleFonts')} key="kannelle_fonts">
          {renderFontFamiliesTab('public', publicFontFamilies)}
        </TabPane>
        <TabPane className="charterFonts" tab={t('charters.fonts.charterFonts')} key="charter_fonts">
          {renderFontFamiliesTab('charter', charterFontFamilies)}
        </TabPane>
      </Tabs>
    </KannellePageHeader>
  );
};

export default CharterFonts;
