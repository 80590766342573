import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'typeface-lato';
import AppInitializer from './AppInitializer';
import './choreSetup';
import { LOGGING_EVENT } from './Constants';
import Auth0Provider from './core/auth/Auth0Config';
import history from './core/router/History';
import './i18n';
import './index.css';
import { LoggingManager } from './manager/logging/LoggingManager';
import './momentjs';
import { storeLoggingManager } from './redux/action/AppAction';
import StoreCreator from './redux/StoreCreator';
import * as serviceWorker from './serviceWorker';
import AuthUtils from './utils/AuthUtils';

const storeCreator = new StoreCreator();
const { store } = storeCreator;
const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
const auth0RedirectUri = AuthUtils.getAuthEnvironmentVariable(process.env.REACT_APP_AUTH0_REDIRECT_URI) || '';
const APIBaseURL = process.env.REACT_APP_API_BASE_URL || '';

const onRedirectCallback = (appState: any): void => {
  // this function is called only on Login
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

const callbackInitLoggingTools = (user: any, newAuthentication: boolean): void => {
  const loggingManager = new LoggingManager(user);
  store.dispatch(storeLoggingManager(loggingManager));

  if (newAuthentication) {
    loggingManager.logEvent(LOGGING_EVENT.LOGIN, {
      email: user.email,
      method: 'Email',
      name: user.name,
      companyId: store ? store.getState()?.companies?.current?.id : undefined,
    });
  }
};

ReactDOM.render(
  // TODO: Reactivate StrictMode when AntD issue is solved (see https://github.com/ant-design/ant-design/issues/22493)
  // <React.StrictMode>
  <Provider store={store}>
    <Auth0Provider
      domain={auth0Domain}
      client_id={auth0ClientId}
      redirect_uri={auth0RedirectUri}
      onRedirectCallback={onRedirectCallback}
      callbackInitLoggingTools={callbackInitLoggingTools}
      audience={APIBaseURL}
    >
      <Sentry.ErrorBoundary showDialog>
        <AppInitializer />
      </Sentry.ErrorBoundary>
    </Auth0Provider>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
