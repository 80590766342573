import { MailOutlined } from '@ant-design/icons/lib';
import { Form, Input } from 'antd';
import { ValidateStatus } from 'antd/es/form/FormItem';
import { CancelTokenSource } from 'axios';
import { find } from 'lodash';
import log from 'loglevel';
import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { APIManager } from '../../services/api/APIManager';
import { APIModelUser } from '../../services/api/types/UsersServiceTypes';
import { getUserByEmail } from '../../services/api/UsersService';
import StringUtils from '../../utils/StringUtils';

type Props = {
  callback: (isValidEmail: boolean, responseIsNewUser?: boolean, user?: APIModelUser) => void;
  callbackIsValidating: (isValidating: boolean) => void;
  visible: boolean;
  errorInfo?: { errorFields: [{ name: string[] }] };
  label?: string;
  extra?: string;
  placeholder?: string;
};

const Email: FunctionComponent<Props> = ({
  callback,
  callbackIsValidating,
  visible,
  errorInfo,
  label,
  extra,
  placeholder,
}: Props) => {
  const { t } = useTranslation();
  const [validateStatus, setValidateStatus] = useState<ValidateStatus>('');
  const [help, setHelp] = useState(null);
  const [cancelSource, setCancelSource] = useState<CancelTokenSource>();
  const [value, setValue] = useState<string>();

  useEffect(() => {
    if (!visible) {
      setValidateStatus('');
      setHelp(null);
    }
  }, [visible]);

  useEffect(() => {
    if (errorInfo) {
      const emailFieldsError = find(errorInfo.errorFields, (fields) => {
        return fields.name.includes('email');
      });

      if (emailFieldsError) {
        setValidateStatus('error');
      }
    }
  }, [errorInfo]);

  const validateEmail = (val: string): boolean => {
    const isEmail = StringUtils.checkIsEmail(val);
    if (val === '') {
      setValidateStatus('error');
      setHelp(t('form.emailRuleRequiredError'));
      callback(false);
      return false;
    }
    if (!isEmail) {
      setValidateStatus('error');
      setHelp(t('form.emailRuleTypeError'));
      callback(false);
      return false;
    }

    return true;
  };

  const [delayedValidator] = useDebouncedCallback((val: string): void => {
    const cancelTokenSource = APIManager.getCancelToken();
    setCancelSource(cancelTokenSource);
    getUserByEmail(encodeURIComponent(escape(val)), cancelTokenSource)
      .then((response) => {
        // If the email changed between the time the promise was sent and the moment we get the answer, we invalidate
        if (!value || !StringUtils.checkIsEmail(value) || (response.data && response.data.email !== value)) {
          setValidateStatus('error');
          setHelp(t('form.emailRuleTypeError'));
          callback(false);
        } else {
          callback(true, response.status !== 200, response.data);
          setValidateStatus('success');
          setHelp(null);
        }
      })
      .catch((error) => {
        callback(false);
        log.error(error);
      });
  }, 500);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const inputEmail = event?.target?.value;
    setValue(inputEmail);
    setValidateStatus('validating');
    callbackIsValidating(true);

    if (cancelSource) {
      cancelSource.cancel('Email fetching cancelled due to email changing');
    }

    const isValidEmail = validateEmail(inputEmail);
    if (isValidEmail) {
      delayedValidator(inputEmail);
    } else {
      callback(false);
    }
  };

  return (
    <Form.Item
      label={label}
      name="email"
      validateStatus={validateStatus}
      help={help}
      extra={extra}
      rules={[
        { required: true, message: t('form.emailRuleRequiredError') },
        { whitespace: true, message: t('form.emailRuleEmptyRequiredError') },
      ]}
      hasFeedback
      required
      normalize={(v): string => v?.toLowerCase()}
    >
      <Input
        prefix={<MailOutlined className="site-form-item-icon" />}
        placeholder={placeholder}
        onChange={handleChange}
      />
    </Form.Item>
  );
};

export default Email;
