import { Col, Empty, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import { UploadFile } from 'antd/lib/upload/interface';
import { CancelTokenSource } from 'axios';
import { remove } from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import ImageFileCard from '../../../../../components/card/ImageFileCard';
import ProcessingFileCard from '../../../../../components/card/ProcessingFileCard';
import VideoOrMusicFileCardPlayer from '../../../../../components/card/VideoOrMusicFileCardPlayer';
import EmptyPageContent from '../../../../../components/empty/EmptyPageContent';
import { patchAssets } from '../../../../../services/api/ChartersService';
import { APIFullModelCharter, Asset, AssetType } from '../../../../../services/api/types/ChartersServiceTypes';

type Props = {
  charter: APIFullModelCharter;
  assets?: Array<Asset>;
  processingAssets: UploadFile[];
  type: AssetType;
  onChangeAssetIsActive: (updatedAsset: Asset) => void;
  onChangeAssetIsArchived: (updatedAsset: Asset) => void;
  onRetryUploadFile: (uploadFile: UploadFile) => void;
};

const useStyles = createUseStyles({
  title: {
    '&.ant-typography': {
      color: '#3B3B3B',
      fontWeight: 400,
      marginTop: 32,
      borderBottom: 'solid 1px #dddddd',
    },
  },
  empty: {
    width: '100%',
  },
});

const AssetsManager: FunctionComponent<Props> = ({
  charter,
  assets = [],
  processingAssets = [],
  type,
  onChangeAssetIsActive,
  onRetryUploadFile,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activatedAssets, setActivatedAssets] = useState(assets.filter((asset: Asset) => asset.isActive));
  const [deactivatedAssets, setDeactivatedAssets] = useState(assets.filter((asset: Asset) => !asset.isActive));
  const [assetPlaying, setAssetPlaying] = useState<Asset>();

  useEffect(() => {
    if (assets.length) {
      setActivatedAssets([...assets.filter((asset: Asset) => asset.isActive)]);
      setDeactivatedAssets([...assets.filter((asset: Asset) => !asset.isActive)]);
    }
  }, [assets]);

  if (assets.length === 0 && processingAssets.length === 0) {
    return <EmptyPageContent />;
  }

  const handleAssetsIsActiveChange = (isActive: boolean, asset: Asset): void => {
    const updatedAsset = { ...asset };
    updatedAsset.isActive = isActive;
    onChangeAssetIsActive(updatedAsset);

    if (isActive) {
      remove(deactivatedAssets, { id: asset.id });
      activatedAssets.unshift(updatedAsset);

      setDeactivatedAssets([...deactivatedAssets]);
      setActivatedAssets([...activatedAssets]);
    } else {
      remove(activatedAssets, { id: asset.id });
      deactivatedAssets.unshift(updatedAsset);

      setDeactivatedAssets([...deactivatedAssets]);
      setActivatedAssets([...activatedAssets]);
    }
  };

  const handleAssetsIsPlayingChange = (isPlaying: boolean, asset: Asset): void => {
    if (isPlaying) {
      setAssetPlaying(asset);
    } else {
      setAssetPlaying(undefined);
    }
  };

  const handleAssetIsActiveChange = (
    isActive: boolean,
    asset: Asset,
    cancelTokenSource?: CancelTokenSource
  ): Promise<Asset> => {
    return patchAssets(charter.id, asset.id, { isActive }, cancelTokenSource);
  };

  const handleArchiveAsset = (asset: Asset, cancelTokenSource?: CancelTokenSource): Promise<Asset> => {
    return patchAssets(charter.id, asset.id, { isArchived: true }, cancelTokenSource);
  };

  const renderCardComponent = (asset: Asset): JSX.Element => {
    return type === AssetType.LOGO ? (
      <ImageFileCard
        imageFile={asset}
        assetType={type}
        handleIsActiveChange={handleAssetIsActiveChange}
        handleArchive={handleArchiveAsset}
        callbackOnIsActiveChange={(isActive: boolean): void => handleAssetsIsActiveChange(isActive, asset)}
        trigger="switch"
      />
    ) : (
      <VideoOrMusicFileCardPlayer
        videoOrMusicFile={asset}
        assetType={type}
        isMusic={type === AssetType.MUSIC}
        videoOrMusicPlaying={assetPlaying}
        handleIsActiveChange={handleAssetIsActiveChange}
        handleArchive={handleArchiveAsset}
        callbackOnIsActiveChange={(isActive: boolean): void => handleAssetsIsActiveChange(isActive, asset)}
        callbackPlaying={(isActive: boolean): void => handleAssetsIsPlayingChange(isActive, asset)}
        trigger="switch"
      />
    );
  };

  const layoutColProps = { xs: 24, sm: 24, md: 12, lg: 8, xl: 6, xxl: 6 };

  return (
    <>
      {processingAssets && processingAssets.length > 0 && (
        <>
          <Title className={classes.title} level={4}>
            {t(`charters.${type}.processing`)}
          </Title>

          <Row gutter={[16, 16]} className="processing-assets">
            {processingAssets.map((uploadFile: UploadFile) => {
              return (
                <Col key={uploadFile.uid} {...layoutColProps}>
                  <ProcessingFileCard
                    uploadFile={uploadFile}
                    onRetryUploadFile={(): void => onRetryUploadFile(uploadFile)}
                    processingMessage={t('charters.assets.processing')}
                    processingErrorMessage={t('charters.assets.processingError')}
                  />
                </Col>
              );
            })}
          </Row>
        </>
      )}

      <Title className={classes.title} level={4}>
        {t(`charters.${type}.activated`)}
      </Title>

      <Row gutter={[16, 16]} className="activated-assets">
        {activatedAssets.length === 0 && (
          <Empty className={classes.empty} image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('global.nodata')} />
        )}
        {activatedAssets.map((asset: Asset) => {
          return (
            <Col key={asset.id} {...layoutColProps}>
              {renderCardComponent(asset)}
            </Col>
          );
        })}
      </Row>

      <Title className={classes.title} level={4}>
        {t(`charters.${type}.deactivated`)}
      </Title>

      <Row gutter={[16, 16]} className="deactivated-assets">
        {deactivatedAssets.length === 0 && (
          <Empty className={classes.empty} image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('global.nodata')} />
        )}
        {deactivatedAssets.map((asset: Asset) => {
          return (
            <Col key={asset.id} {...layoutColProps}>
              {renderCardComponent(asset)}
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default AssetsManager;
