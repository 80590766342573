import { Alert, Modal } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  visible: boolean;
};

const MaintenanceModal: FunctionComponent<Props> = ({ visible }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal visible={visible} footer={null} closable={false}>
      <Alert
        message={t('maintenanceModal.title')}
        description={
          <>
            <p>{t('maintenanceModal.text')}</p>
            <p>
              {t('maintenanceModal.text2')}
              <a href={`mailto:${t('support.global.supportEmail')}`}>{t('support.global.supportEmail')}</a>.
            </p>
          </>
        }
        type="warning"
        showIcon
        closable={false}
      />
    </Modal>
  );
};

export default MaintenanceModal;
