import { CloseCircleOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { THEME } from '../../Constants';

const useStyles = createUseStyles({
  error: {
    backgroundColor: THEME.ALERT.ERROR_BACKGROUND,
    '& .anticon, & .ant-alert-message': {
      color: THEME.ALERT.ERROR_MAIN,
    },
  },
});

type Props = {
  message: string;
  className?: string;
};

const ErrorAlert: FunctionComponent<Props> = ({ message, className }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Alert
      className={classNames(classes.error, className)}
      icon={<CloseCircleOutlined />}
      message={t(message)}
      type="error"
      showIcon
    />
  );
};

export default ErrorAlert;
