import { InboxOutlined } from '@ant-design/icons';
import { UploadOutlined } from '@ant-design/icons/lib';
import { Button, Modal, Tooltip, Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { RcFile, UploadFile, UploadListType } from 'antd/lib/upload/interface';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import MathUtils from '../../utils/MathUtils';

const { Dragger } = Upload;

const useStyles = createUseStyles({
  hidden: {
    display: 'none',
  },
  draggerContainer: {
    '& .ant-upload-list-item-name': {
      padding: '0 8px 2px !important',
    },
    '& .ant-upload-list-picture-container .ant-upload-list-item-progress': {
      bottom: 10,
    },
  },
  dragger: {
    padding: 8,
  },
});

export type DraggerCustomRequestParams = {
  action: string;
  data: any;
  file: File;
  filename: string;
  headers: any;
  method: string;
  onError: (err: Error) => void;
  onProgress: (e: { percent: number }, file: File) => void;
  onSuccess: (ret: any) => void;
  withCredentials: boolean;
};

export type FileUploaderProps = {
  triggerText: string;
  modalTitle: string;
  acceptFileType: string;
  sizeLimit: number; // X MB
  onSizeLimitExceeded: () => void;
  onUploadStatusChangeToDone?: (info: UploadChangeParam) => void;
  onUploadStatusChangeToError?: (info: UploadChangeParam) => void;
  onUploadFile: (requestParam: DraggerCustomRequestParams) => void;
  uploadInstructionText?: string;
  uploadInstructionHint?: string;
  uploadInstructionSizeLimit?: string;
  listType?: UploadListType;
  assetType?: string;
  bundleLimitExceeded?: boolean;
  onBeforeUpload?: (file: UploadFile, FileList: RcFile[]) => boolean;
};

const FileUploader: FunctionComponent<FileUploaderProps> = ({
  triggerText,
  modalTitle,
  acceptFileType,
  sizeLimit,
  onSizeLimitExceeded,
  onUploadStatusChangeToDone,
  onUploadStatusChangeToError,
  onUploadFile,
  uploadInstructionText,
  uploadInstructionHint,
  uploadInstructionSizeLimit,
  listType,
  assetType,
  bundleLimitExceeded = false,
  onBeforeUpload,
}: FileUploaderProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [visible, setVisible] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isModalClosable, setIsModalClosable] = useState(true);

  const showModal = (): void => {
    setVisible(true);
  };

  const hideModal = (): void => {
    setFileList([]);
    setVisible(false);
  };

  const beforeUpload = (file: UploadFile): boolean => {
    const fileSizeInKB = MathUtils.convertBytesToKilobytes(file.size!);
    const isFileSmallEnough = fileSizeInKB / 1024 < sizeLimit;
    if (!isFileSmallEnough) {
      onSizeLimitExceeded();
    }

    return isFileSmallEnough;
  };

  const onUploadStatusChange = (info: UploadChangeParam): void => {
    const filteredFileList = info.fileList.filter((f) => f.status && f.status !== 'error');
    setFileList(filteredFileList);

    const areAllUploadFilesDone = info.fileList.every((file) => file.status === 'done');
    const areAllUploadFilesInError = info.fileList.every((file) => file.status === 'error');
    const areAllFilesOnSizeLimitExceeded = info.fileList.every((file) => !file.status);
    setIsModalClosable(areAllUploadFilesDone || areAllUploadFilesInError || areAllFilesOnSizeLimitExceeded);

    const currentFile = info.file;
    switch (currentFile.status) {
      case 'error':
        if (onUploadStatusChangeToError) {
          onUploadStatusChangeToError(info);
        }
        break;
      case 'done':
        if (onUploadStatusChangeToDone) {
          onUploadStatusChangeToDone(info);
        }
        if (areAllUploadFilesDone) {
          hideModal();
        }
        break;
      case 'success':
      case 'removed':
      default:
        break;
    }
  };

  const props = {
    className: classes.dragger,
    accept: acceptFileType,
    multiple: true,
    onChange: onUploadStatusChange,
    listType: listType || ('text' as UploadListType),
    customRequest: (file: DraggerCustomRequestParams) => onUploadFile(file),
    showUploadList: {
      showPreviewIcon: true,
      showDownloadIcon: false,
      showRemoveIcon: false,
    },
    beforeUpload: onBeforeUpload || beforeUpload,
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      format: (percent: number): string => {
        return `${parseFloat(percent.toFixed(0))}%`;
      },
    },
  } as any;

  return (
    <>
      <Tooltip
        title={bundleLimitExceeded ? t(`charters.${assetType}.bundleLimitExceededAssetUpload`) : undefined}
        placement="bottom"
      >
        <Button type="primary" onClick={showModal} disabled={bundleLimitExceeded}>
          <UploadOutlined /> {triggerText}
        </Button>
      </Tooltip>

      <Modal
        title={modalTitle}
        centered
        visible={visible}
        onOk={hideModal}
        onCancel={hideModal}
        okButtonProps={{ className: classes.hidden }}
        cancelButtonProps={{ disabled: !isModalClosable }}
        cancelText={t('global.close')}
        closable={isModalClosable}
        keyboard={isModalClosable}
        maskClosable={isModalClosable}
      >
        <div className={classes.draggerContainer}>
          <Dragger {...props} fileList={fileList}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            {uploadInstructionText && <p className="ant-upload-text">{uploadInstructionText}</p>}
            {uploadInstructionHint && (
              <p className="ant-upload-hint">
                {uploadInstructionHint}
                {uploadInstructionSizeLimit && <> {uploadInstructionSizeLimit}</>}
              </p>
            )}
          </Dragger>
        </div>
      </Modal>
    </>
  );
};
export default FileUploader;
