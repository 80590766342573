import React, { FunctionComponent, useState } from 'react';
import Cropper, { CropperProps } from 'react-easy-crop';
import { createUseStyles } from 'react-jss';
import PageContentLoader from '../loader/PageContentLoader';

type Props = Omit<CropperProps, 'video'>;

const useStyles = createUseStyles({
  imageLoaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  cropArea: {
    '&, &::before, &::after': {
      borderColor: 'rgba(190, 190, 190, 0.8) !important',
    },
  },
});

const ImageMediaResizer: FunctionComponent<Props> = ({ image, ...cropperProps }: Props) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const classes = useStyles();

  const onImageLoaded = (): void => {
    // Timeout needed to ensure the media is loaded and the crop size is calculated
    setTimeout(() => {
      setIsImageLoaded(true);
    }, 500);
  };

  return (
    <>
      {/* First, we render invisibly the image to await for the onLoadedData to complete.
            When the image was fully loaded, we display the actual cropper
        */}
      <img onLoad={onImageLoaded} src={image} style={{ visibility: 'hidden' }} alt="Hidden to load the media" />

      {/* If the image is loaded, we can render the cropper since it can fully calculate the cropper grid.
            Else we display a loader
        */}
      {isImageLoaded ? (
        <Cropper image={image} {...cropperProps} classes={{ cropAreaClassName: classes.cropArea }} />
      ) : (
        <div className={classes.imageLoaderContainer}>
          <PageContentLoader />
        </div>
      )}
    </>
  );
};

export default ImageMediaResizer;
