import { Layout } from 'antd';
import React, { FunctionComponent } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { THEME } from '../../Constants';
import { RootState } from '../../redux/RootState';
import KannelleMenu from '../menu/KannelleMenu';
import KannelleMobileOrTabletSider from './KannelleMobileOrTabletSider';

const { Sider } = Layout;

const useStyles = createUseStyles({
  sider: {
    overflow: 'auto',
    background: THEME.MENU.BACKGROUND,
    boxShadow: '1px 10px 5px 1px #ecebeb',
    zIndex: 5,
    position: 'fixed',
    top: 64,
    height: 'calc(100vh - 64px)',
  },
});

const KannelleSider: FunctionComponent = () => {
  const menuCollapsed = useSelector((state: RootState) => state.app.menuCollapsed);
  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);

  const classes = useStyles();

  const renderMenu = (): JSX.Element => {
    return <KannelleMenu isMobileOrTablet={isMobileOrTablet} />;
  };

  const isDrawerVisible = !menuCollapsed;

  return isMobileOrTablet ? (
    <KannelleMobileOrTabletSider isVisible={isDrawerVisible}>{renderMenu()}</KannelleMobileOrTabletSider>
  ) : (
    <Sider
      className={classes.sider}
      trigger={null}
      collapsible
      collapsed={menuCollapsed}
      collapsedWidth={0}
      width={210}
    >
      {renderMenu()}
    </Sider>
  );
};

export default KannelleSider;
