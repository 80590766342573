import { SearchOutlined } from '@ant-design/icons/lib';
import React, { FunctionComponent } from 'react';
import { THEME } from '../../../Constants';

type Props = {
  filtered: Props;
};

const FilterIcon: FunctionComponent<Props> = ({ filtered }: Props) => (
  <SearchOutlined style={{ color: filtered ? THEME.DEFAULT.MAIN_TEXT_COLOR : undefined }} />
);

export default FilterIcon;
