import { Button, Modal } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { LINK } from '../../Constants';

type Props = {
  visible: boolean;
};

const FreeTrialEndModal: FunctionComponent<Props> = (props: Props) => {
  const { visible } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const goToBundles = () => {
    history.push(LINK.COMPANY_BUNDLES.path);
  };

  return (
    <Modal
      title={t('freeTrialEndModal.title')}
      visible={visible}
      footer={[
        <Button key="goToBundles" onClick={goToBundles} type="primary">
          {t('freeTrialEndModal.goToBundles')}
        </Button>,
      ]}
      closable={false}
    >
      <p>{t('freeTrialEndModal.content1')}</p>
      <p>{t('freeTrialEndModal.content2')}</p>
    </Modal>
  );
};

export default FreeTrialEndModal;
