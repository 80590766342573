import { CopyOutlined, DeleteOutlined, LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { Card, message, Modal, Popconfirm, Tag, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { FaPlayCircle, FaRegCalendarCheck, FaRegCalendarPlus } from 'react-icons/all';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AccessChecker from '../../../../../components/access-checker/AccessChecker';
import FormatTag from '../../../../../components/tag/format-tag/FormatTag';
import { LINK } from '../../../../../Constants';
import useCanAccess from '../../../../../hooks/useCanAccess';
import { RootState } from '../../../../../redux/RootState';
import VideoMontage from '../../../../../resources/img/video-montage.png';
import { cloneCharterProject, deleteCharterProject } from '../../../../../services/api/ChartersService';
import ProjectUtils from '../../../../../utils/ProjectUtils';
import TimeUtils from '../../../../../utils/TimeUtils';
import { PermissionList } from '../../../../../utils/types/CharterPermissionTypes';
import { Project, ProjectStatus } from '../../../../../utils/types/ProjectTypes';
import ProjectDetailsModal from './project-details/ProjectDetailsModal';
import WebEditingDesktopOnlyModal from './WebEditingDesktopOnlyModal';

const { Meta } = Card;

type Props = {
  project: Project;
};

type StyleProps = {
  hasPermissionToEdit: boolean;
  hasPermissionToDelete: boolean;
};

const useStyles = createUseStyles({
  projectCoverImage: {
    maxHeight: 120,
    objectFit: 'contain',
    width: '100%',
    padding: 10,
    borderBottom: '1px solid #f0f0f0',
    background: '#f0f0f0',
  },
  projectCoverThumbnail: {
    height: 120,
    width: '100%',
    borderBottom: '1px solid #f0f0f0',
    background: '#f0f0f0',
    objectFit: 'cover',
  },
  projectCard: ({ hasPermissionToEdit }: StyleProps) => ({
    cursor: hasPermissionToEdit ? 'pointer' : 'not-allowed',

    '& .ant-card-body': {
      padding: 18,
    },
    '& .ant-card-meta-title': {
      fontSize: 14,
    },
    '& .ant-card-meta-description': {
      fontSize: 12,
    },
  }),
  userEmailTooltip: {
    '& .ant-tooltip-inner': {
      width: 'fit-content',
      maxWidth: '350px',
      fontSize: '12px',
    },
  },
  projectFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 4,
  },
  fieldLabelTag: {
    fontSize: 11,
    padding: '0 4px',
    '& .anticon + span': {
      marginLeft: 0,
    },
    display: 'flex',
    alignItems: 'center',
  },
  fieldLabelTagIcon: {
    marginRight: 4,
    fontSize: 10,
  },
  ellipsisDiv: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    flex: 1,
    minWidth: 0,
  },
  divNoWrap: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  projectStatusTag: {
    position: 'absolute',
    top: 5,
    left: 5,
  },
  projectFormatTag: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
  projectCardAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      marginRight: 4,
    },
  },
  projectCardDeleteAction: ({ hasPermissionToDelete }: StyleProps) => ({
    cursor: hasPermissionToDelete ? 'pointer' : 'not-allowed',
  }),
  neverFinalizedLabel: {
    fontStyle: 'italic',
  },
  backupedMessage: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    marginBottom: 8,
  },
  cloneLoading: {
    animation: 'none',
    paddingRight: 8,
    '& svg': {
      marginRight: 0,
    },
  },
});

const ProjectCard: FunctionComponent<Props> = ({ project }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isMounted = useRef<boolean>();

  const [isProjectTitleEllipsisActive, setIsProjectTitleEllipsisActive] = useState(false);
  const [isUserEmailEllipsisActive, setIsUserEmailEllipsisActive] = useState(false);
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isCloneLoading, setIsCloneLoading] = useState(false);
  const [isDesktopOnlyModalVisible, setIsDesktopOnlyModalVisible] = useState(false);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState<boolean>(false);

  const projectCoverUrl = project.finalVideo?.thumbnailImage?.url;
  const hasFinalVideo = project.finalVideo !== undefined;
  const hasScenes = project.sceneIndexesOrder && project.sceneIndexesOrder.length > 0;
  const isProjectInError = ProjectUtils.getProjectStatusByCode(project.status) === ProjectStatus.ERROR;
  const isProjectInBackuped = ProjectUtils.getProjectStatusByCode(project.status) === ProjectStatus.BACKUPED;
  const hasDismissedFetaures = project.dismissedFeatures && project.dismissedFeatures.length > 0;

  const permissionToEditProject = useCanAccess(PermissionList.PROJECT_EDIT);
  const permissionToDeleteProject = useCanAccess(PermissionList.PROJECT_DELETE);
  const permissionToCloneProject = useCanAccess(PermissionList.PROJECT_DUPLICATE);

  const charter = useSelector((state: RootState) => state.charters.current);

  const classes = useStyles({
    hasPermissionToEdit: permissionToEditProject.hasUserAccess(),
    hasPermissionToDelete: permissionToDeleteProject.hasUserAccess(),
  });

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const projectTitleRef = useCallback(
    (node: HTMLDivElement) => {
      if (node && isMounted.current) {
        setIsProjectTitleEllipsisActive(node.offsetWidth < node.scrollWidth);
      }
    },
    [isMounted.current]
  );

  const userEmailRef = useCallback(
    (node: HTMLDivElement) => {
      if (node && isMounted.current) {
        setIsUserEmailEllipsisActive(node.offsetWidth < node.scrollWidth);
      }
    },
    [isMounted.current]
  );

  const renderProjectTitle = () => {
    const { title } = project;

    return isProjectTitleEllipsisActive ? (
      <Tooltip title={title}>
        <div ref={projectTitleRef} className={classes.ellipsisDiv}>
          {title}
        </div>
      </Tooltip>
    ) : (
      <div className={classes.divNoWrap} ref={projectTitleRef}>
        {title}
      </div>
    );
  };

  const renderProjectDescription = () => {
    const userEmail = project.author?.email;
    const creationDate = TimeUtils.formatDateAsYearMonthDayHourMinute(project.createdAt);
    const lastFinalizedDate =
      project.lastFinalizedAt && TimeUtils.formatDateAsYearMonthDayHourMinute(project.lastFinalizedAt);

    return (
      <div>
        {/* Author */}
        {userEmail && (
          <div className={classes.projectFieldContainer}>
            <Tag icon={<UserOutlined className={classes.fieldLabelTagIcon} />} className={classes.fieldLabelTag}>
              {t('charters.projects.projectCard.createdBy')}
            </Tag>
            {isUserEmailEllipsisActive ? (
              <Tooltip title={userEmail} overlayClassName={classes.userEmailTooltip}>
                <div ref={userEmailRef} className={classes.ellipsisDiv}>
                  {userEmail}
                </div>
              </Tooltip>
            ) : (
              <div ref={userEmailRef} className={classes.divNoWrap}>
                {userEmail}
              </div>
            )}
          </div>
        )}

        {/* Creation date */}
        {creationDate && (
          <div className={classes.projectFieldContainer}>
            <Tag icon={<FaRegCalendarPlus className={classes.fieldLabelTagIcon} />} className={classes.fieldLabelTag}>
              {t('charters.projects.projectCard.createdAt')}
            </Tag>
            {creationDate}
          </div>
        )}

        {/* Last finalized date */}
        <div className={classes.projectFieldContainer}>
          <Tag icon={<FaRegCalendarCheck className={classes.fieldLabelTagIcon} />} className={classes.fieldLabelTag}>
            {t('charters.projects.projectCard.lastFinalizedAt')}
          </Tag>
          {lastFinalizedDate ?? (
            <span className={classes.neverFinalizedLabel}>{t('charters.projects.projectCard.neverFinalized')}</span>
          )}
        </div>
      </div>
    );
  };

  const renderProjectStatusTag = () => {
    const projectStatus = ProjectUtils.getProjectStatusByCode(project.status);
    if (!projectStatus) {
      return null;
    }

    const Icon = <projectStatus.icon />;
    return (
      <Tag className={classes.projectStatusTag} icon={Icon} color={projectStatus.color}>
        {t(projectStatus.key)}
      </Tag>
    );
  };

  const renderProjectFormatTag = () => {
    const format = ProjectUtils.getProjectFormatByCode(project.videoFormat);

    if (!format) {
      return null;
    }

    return (
      <div className={classes.projectFormatTag}>
        <FormatTag format={t(format.key)} image={format.image} />
      </div>
    );
  };

  const onShowDesktopOnlyModal = () => {
    setIsDesktopOnlyModalVisible(true);
  };

  const onHideDesktopOnlyModal = () => {
    setIsDesktopOnlyModalVisible(false);
  };

  const onShowDetailsModal = (): void => {
    setIsDetailsModalVisible(true);
  };

  const onHideDetailsModal = (): void => {
    setIsDetailsModalVisible(false);
  };

  const renderBackupedProjectWarningModal = () => {
    Modal.info({
      title: t('charters.projects.projectCard.information'),
      width: 600,
      content: (
        <div className={classes.backupedMessage}>
          <span>{t('charters.projects.projectCard.backupedMessage1')}</span>
          <span>{t('charters.projects.projectCard.backupedMessage2')}</span>
          <span>{t('charters.projects.projectCard.backupedMessage3', { authorName: project.author?.name })}</span>
        </div>
      ),
    });
  };

  const renderCopytoDashboardWarningModal = () => {
    return new Promise((resolve, reject) => {
      Modal.confirm({
        title: t('charters.projects.projectCard.information'),
        cancelText: t('charters.projects.projectCard.dismissedFeature.button.cancelText'),
        okText: t('charters.projects.projectCard.dismissedFeature.button.okText'),
        width: 600,
        content: (
          <div className={classes.backupedMessage}>
            <span>{t('charters.projects.projectCard.dismissedFeature.message1')}</span>
            <ul>
              <br />
              {project.dismissedFeatures
                ? project.dismissedFeatures.map((feature) => {
                    return <li>{t(`charters.projects.projectCard.dismissedFeature.features.${feature}`)}</li>;
                  })
                : null}
            </ul>
            <span>{t('charters.projects.projectCard.dismissedFeature.message2')}</span>
          </div>
        ),
        onOk: () => {
          resolve(true);
        },
        onCancel: () => {
          resolve(false);
        },
      });
    });
  };

  const onClick = async () => {
    if (isMobile) {
      onShowDesktopOnlyModal();
      return;
    }

    if (!permissionToEditProject.hasUserAccess()) {
      return;
    }

    // We cannot open projects having the 'Backuped' status, but we display an information message instead
    if (isProjectInBackuped) {
      renderBackupedProjectWarningModal();
      return;
    }

    // Alert before opening project, on possible dismissed feature if user modify project (as subtitle and multi pips)
    console.log('hasDismissedFetaures:', hasDismissedFetaures);
    if (hasDismissedFetaures) {
      const isConfirmed = await renderCopytoDashboardWarningModal();
      if (!isConfirmed) {
        return;
      }
    }

    // We cannot open projects having no scene
    if (!hasScenes) {
      return;
    }

    const projectEditorPath =
      charter && LINK.CHARTER_PROJECT_EDITOR.path.replace(':charterId', charter.id.toString()).replace('(\\d+)', '');

    if (!projectEditorPath) {
      return;
    }

    history.push({
      pathname: projectEditorPath,
      state: {
        projectId: project.id,
      },
    });
  };

  const onDeleteProjectClick = (e?: MouseEvent) => {
    if (permissionToDeleteProject.hasUserAccess()) {
      e?.stopPropagation();
      setIsDeleteConfirmVisible(true);
    }
  };

  const onCloneProject = (): void => {
    if (!charter) {
      return;
    }

    setIsCloneLoading(true);

    cloneCharterProject(charter.id, project.id)
      .then(() => {
        message.success(t('charters.projects.projectCard.cloneProjectSuccess'));
      })
      .catch(() => {
        message.error(t('charters.projects.projectCard.cloneProjectError'));
      })
      .finally(() => {
        setIsCloneLoading(false);
      });
  };

  const onDeleteProjectConfirmed = (): void => {
    if (!charter) {
      return;
    }

    setIsDeleteLoading(true);

    deleteCharterProject(charter.id, project.id)
      .then(() => {
        message.success(t('charters.projects.projectCard.deleteProjectSuccess'));
      })
      .catch(() => {
        message.error(t('charters.projects.projectCard.deleteProjectError'));
      })
      .finally(() => {
        setIsDeleteLoading(false);
        setIsDeleteConfirmVisible(false);
      });
  };

  const onDeleteProjectCancelled = (): void => {
    setIsDeleteConfirmVisible(false);
  };

  const renderCardActions = () => {
    const cardActions = [
      <Popconfirm
        title={t('charters.projects.projectCard.deleteProjectConfirm')}
        visible={isDeleteConfirmVisible}
        key="deleteProject"
        onConfirm={(e) => {
          e?.stopPropagation();
          onDeleteProjectConfirmed();
        }}
        onCancel={(e) => {
          e?.stopPropagation();
          onDeleteProjectCancelled();
        }}
        okText={t('global.yes')}
        cancelText={t('global.no')}
        okButtonProps={{ loading: isDeleteLoading, disabled: isDeleteLoading }}
        cancelButtonProps={{ disabled: isDeleteLoading }}
      >
        <AccessChecker
          hasAccess={permissionToDeleteProject.hasUserAccess()}
          renderUnauthorizedMessage={permissionToDeleteProject.renderUnauthorizedMessage}
        >
          <div
            onClick={(e) => {
              e?.stopPropagation();
              onDeleteProjectClick();
            }}
            className={classNames(classes.projectCardDeleteAction, classes.projectCardAction)}
          >
            <DeleteOutlined />
            {t('charters.projects.projectCard.deleteProject')}
          </div>
        </AccessChecker>
      </Popconfirm>,
      ...(hasFinalVideo && !isProjectInError
        ? [
            <div
              onClick={(e) => {
                e?.stopPropagation();
                onShowDetailsModal();
              }}
              key="projectInfo"
              className={classes.projectCardAction}
            >
              <FaPlayCircle /> {t('charters.projects.projectCard.watchVideo')}
            </div>,
          ]
        : []),
    ];

    if (project.status !== ProjectStatus.BACKUPED.code && project.status !== ProjectStatus.PROCESSING.code) {
      cardActions.push(
        <AccessChecker
          hasAccess={permissionToCloneProject.hasUserAccess()}
          renderUnauthorizedMessage={permissionToCloneProject.renderUnauthorizedMessage}
        >
          <div
            onClick={(e) => {
              e?.stopPropagation();
              if (!isCloneLoading) {
                onCloneProject();
              }
            }}
            className={classNames(classes.projectCardDeleteAction, classes.projectCardAction)}
          >
            {isCloneLoading ? (
              <span className={classNames(classes.cloneLoading)}>
                <LoadingOutlined />
              </span>
            ) : (
              <CopyOutlined />
            )}
            {t('charters.projects.projectCard.cloneProject')}
          </div>
        </AccessChecker>
      );
    }

    return cardActions;
  };

  return (
    <>
      <Card
        cover={
          <AccessChecker
            hasAccess={permissionToEditProject.hasUserAccess()}
            renderUnauthorizedMessage={permissionToEditProject.renderUnauthorizedMessage}
          >
            <img
              alt="Video montage"
              className={projectCoverUrl ? classes.projectCoverThumbnail : classes.projectCoverImage}
              src={projectCoverUrl ?? VideoMontage}
              loading="lazy"
            />
          </AccessChecker>
        }
        className={classNames(classes.projectCard)}
        onClick={onClick}
        actions={renderCardActions()}
      >
        <AccessChecker
          hasAccess={permissionToEditProject.hasUserAccess()}
          renderUnauthorizedMessage={permissionToEditProject.renderUnauthorizedMessage}
        >
          {renderProjectStatusTag()}
          {renderProjectFormatTag()}

          <Meta title={renderProjectTitle()} description={renderProjectDescription()} />
        </AccessChecker>
      </Card>

      <WebEditingDesktopOnlyModal isVisible={isDesktopOnlyModalVisible} onHide={onHideDesktopOnlyModal} />

      {hasFinalVideo && (
        <ProjectDetailsModal isVisible={isDetailsModalVisible} onHide={onHideDetailsModal} project={project} />
      )}
    </>
  );
};

export default ProjectCard;
