import { PageHeader, Row, Skeleton } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import ErrorAlert from '../../components/alert/ErrorAlert';
import EmptyPageContent from '../../components/empty/EmptyPageContent';
import useFetchCompanyStats from '../../hooks/useFetchCompanyStats';
import { RootState } from '../../redux/RootState';
import CompaniesUtils from '../../utils/CompaniesUtils';
import ChartersStatistics from './statistics/ChartersStatistics';
import SubtitlesStatistics from './statistics/SubtitlesStatistics';
import UsersStatistics from './statistics/UsersStatistics';

const useStyles = createUseStyles({
  title: {
    padding: 0,
  },
  fullHeight: {
    height: '100%',
  },

  '@keyframes fadeout': {
    '0:': { opacity: 1 },
    '100.0%': { opacity: 0 },
  },
});

const Statistics: FunctionComponent = () => {
  const classes = useStyles();
  const currentCompany = useSelector((state: RootState) => {
    return state.companies.current;
  });
  const { loading, isError, fetchedCompanyStats } = useFetchCompanyStats();
  const highestRoleInCurrentCompany = CompaniesUtils.getHighestRoleInCompany(currentCompany);

  const { t } = useTranslation();

  if (isError) {
    return <ErrorAlert message={t('ajaxError.statisticsFetch')} />;
  }

  if (!fetchedCompanyStats && !loading) {
    return <EmptyPageContent />;
  }

  // Creators can't see statistics
  if (highestRoleInCurrentCompany?.isCreator()) {
    return null;
  }

  return (
    <PageHeader className={classes.title} title={t('home.statistics.informations')}>
      {fetchedCompanyStats && !loading ? (
        <Row
          gutter={[
            { xs: 8, sm: 8, md: 8, lg: 8 },
            { xs: 8, sm: 8, md: 8, lg: 8 },
          ]}
        >
          <UsersStatistics statistics={fetchedCompanyStats.users} maxNbLicenses={currentCompany?.maxNbLicenses} />
          <ChartersStatistics statistics={fetchedCompanyStats.charters} maxNbCharters={currentCompany?.maxNbCharters} />
          <SubtitlesStatistics statistics={fetchedCompanyStats.subtitles} />
        </Row>
      ) : (
        <Skeleton active />
      )}
    </PageHeader>
  );
};

export default Statistics;
