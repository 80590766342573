import { Form, InputNumber } from 'antd';
import classNames from 'classnames';
import sample from 'lodash/sample';
import React, { FunctionComponent, Ref, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { DEVICE_SIZES_QUERIES, THEME } from '../../Constants';
import { MediaFile } from '../../services/api/types/ChartersServiceTypes';
import ScenarioUtils from '../../utils/ScenarioUtils';
import { MediaType } from '../../utils/types/MediaTypes';
import { TemplateVideoPip } from '../../utils/types/ScenarioTypes';
import MediaSelector from '../media-selector/MediaSelector';

type Props = {
  value?: TemplateVideoPip;
  onChange?: (newValue: TemplateVideoPip | undefined) => void;
  format?: string;
  callbackOnDemoSourceChange?: () => void;
  onMediaSelectorClick?: () => void;
};

const useStyles = createUseStyles({
  subFormItem: {
    marginBottom: '5px',

    '& .ant-col.ant-form-item-label': {
      width: '30%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',

      [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
        justifyContent: 'flex-start',
      },
    },

    '& .ant-col.ant-form-item-control': {
      width: '60%',
    },

    '& label': {
      fontSize: '11px',
    },

    '& input, .ant-select': {
      fontSize: '11px',
    },

    '& .ant-select-arrow': {
      right: '11px !important',
    },

    '& .ant-select-selector,  .ant-input-number': {
      height: '20px!important',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  requiredField: {
    '& label::before': {
      color: '#FF4D4F',
      lineHeight: '1',
      content: "'*'",
    },
  },
  optionalField: {
    '& input': {
      borderColor: `${THEME.DEFAULT.BORDER_COLOR} !important`,
    },
  },
  userPipContainer: {
    backgroundColor: '#F3F3F3',
    borderRadius: '3px',
    padding: '5px 10px 2px 5px',
    marginRight: '19px',
  },
  subSubForm: {
    '& .ant-col.ant-form-item-label': {
      paddingTop: '3px',
      alignSelf: 'flex-start',
    },
    '& > .ant-col.ant-form-item-control': {
      [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
        paddingLeft: '30px',
      },
    },
  },
  effectItem: {
    extend: 'subFormItem',
    '& .ant-col.ant-form-item-label': {
      width: '100%',
      justifyContent: 'flex-start',
    },

    '& .ant-col.ant-form-item-control': {
      width: '100%',
    },
  },
});

const ScenarioSceneUserPip: FunctionComponent<Props> = React.forwardRef(
  ({ value, onChange, format, callbackOnDemoSourceChange, onMediaSelectorClick }: Props, ref: Ref<any>) => {
    const classes = useStyles();
    const { t } = useTranslation();

    // Called to update the upper `form` object
    const triggerChange = (changedValue: TemplateVideoPip | undefined): void => {
      if (onChange) {
        onChange(changedValue);
      }
    };

    useEffect(() => {
      if (!value) {
        const randomEffect = ScenarioUtils.getRandomUserPipEffect();
        const defaultValues = {
          duration: 3,
          effect: {
            name: randomEffect.code,
            value: sample(randomEffect.allowedValues)?.value ?? randomEffect.allowedValues?.soft.value,
          },
        };
        triggerChange(defaultValues);
      }
      // eslint-disable-next-line
    }, [value]);

    const onDemoSourceValueChange = (media: MediaFile | undefined): void => {
      const newUserPip = {
        ...value,
        demoSource: media,
      };
      triggerChange(newUserPip as TemplateVideoPip);

      if (callbackOnDemoSourceChange) {
        callbackOnDemoSourceChange();
      }
    };

    const onInputNumberValueChange = (newValue: number | string | undefined | null, changedField: string): void => {
      const newAnimationText = {
        ...value,
        [changedField]: newValue ?? 1,
      };
      triggerChange(newAnimationText as TemplateVideoPip);
    };

    return (
      <div ref={ref}>
        <div className={classes.userPipContainer}>
          {/* userPip.demoSource */}
          <Form.Item
            label={t('charters.scenarios.variantEditor.sceneForm.fields.userPipSubfields.demoSourceLabel')}
            className={classNames(classes.subFormItem, classes.requiredField)}
          >
            <MediaSelector
              fieldLabel={t('charters.scenarios.variantEditor.sceneForm.fields.userPipSubfields.demoSourceLabel')}
              value={value?.demoSource}
              onChange={onDemoSourceValueChange}
              size="small"
              format={format}
              mediaTypes={[MediaType.IMAGE]}
              onClick={onMediaSelectorClick}
            />
          </Form.Item>

          {/* userPip.duration */}
          <Form.Item
            label={t('charters.scenarios.variantEditor.sceneForm.fields.userPipSubfields.durationLabel')}
            className={classNames(classes.subFormItem, classes.requiredField)}
          >
            <InputNumber
              min={1}
              max={10}
              step={1}
              placeholder={t('charters.scenarios.variantEditor.sceneForm.fields.userPipSubfields.durationPlaceholder')}
              value={value?.duration ?? undefined}
              onChange={(e): void => onInputNumberValueChange(e, 'duration')}
            />
          </Form.Item>
        </div>
      </div>
    );
  }
);
export default ScenarioSceneUserPip;
