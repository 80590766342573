import { Button, Col, Empty, Modal, Row } from 'antd';
import { capitalize } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import ImageFileCard from '../../../../components/card/ImageFileCard';
import VideoOrMusicFileCardPlayer from '../../../../components/card/VideoOrMusicFileCardPlayer';
import { RootState } from '../../../../redux/RootState';
import { APICharterPermission, Asset, AssetType } from '../../../../services/api/types/ChartersServiceTypes';
import { AssetPermissions, PermissionRoles } from '../../../../utils/types/CharterPermissionTypes';

type Props = {
  isVisible: boolean;
  permission: APICharterPermission;
  role: PermissionRoles;
  onCancel: () => void;
  assetType: AssetPermissions;
  allowedAssets: Asset[];
  selectedAsset?: Asset;
  onSelectAsset: (role: PermissionRoles, asset: Asset) => void;
};

const useStyles = createUseStyles({
  assetChoiceModal: {
    width: '75% !important',
  },
  empty: {
    width: '100%',
  },
});

const PermissionExpandedRowAssetChoiceModal: FunctionComponent<Props> = ({
  isVisible,
  permission,
  role,
  onCancel,
  assetType,
  allowedAssets,
  selectedAsset,
  onSelectAsset,
}: Props) => {
  const [assetPlaying, setAssetPlaying] = useState<Asset>();

  const classes = useStyles();
  const { t } = useTranslation();
  const currentCharter = useSelector((state: RootState) => {
    return state.charters.current;
  });

  if (!currentCharter) {
    return null;
  }

  const handleAssetsIsPlayingChange = (isPlaying: boolean, asset: Asset): void => {
    if (isPlaying) {
      setAssetPlaying(asset);
    } else {
      setAssetPlaying(undefined);
    }
  };

  const layoutColProps = { xs: 24, sm: 24, md: 12, lg: 8, xl: 6, xxl: 6 };

  const renderCardComponent = (asset: Asset): JSX.Element => {
    let apiModelAssetType: string;
    if (assetType === AssetPermissions.LOGO) {
      apiModelAssetType = AssetType.LOGO;
    } else {
      apiModelAssetType = assetType === AssetPermissions.INTRO ? AssetType.INTRO : AssetType.OUTRO;
    }

    return assetType === AssetPermissions.LOGO ? (
      <ImageFileCard
        imageFile={asset}
        selected={selectedAsset && asset.id === selectedAsset.id}
        trigger="button"
        callbackOnSelect={(): void => onSelectAsset(role, asset)}
      />
    ) : (
      <VideoOrMusicFileCardPlayer
        videoOrMusicFile={asset}
        isMusic={apiModelAssetType === AssetType.MUSIC}
        videoOrMusicPlaying={assetPlaying}
        selected={selectedAsset && asset.id === selectedAsset.id}
        trigger="button"
        callbackOnSelect={(): void => onSelectAsset(role, asset)}
        callbackPlaying={(isPlaying: boolean): void => handleAssetsIsPlayingChange(isPlaying, asset)}
      />
    );
  };

  return (
    <Modal
      title={`${t(`roles.names.${capitalize(role)}`)} - ${permission.name}`}
      visible={isVisible}
      onCancel={onCancel}
      className={classes.assetChoiceModal}
      footer={[
        <Button key="cancelModal" onClick={onCancel}>
          {t('global.cancel')}
        </Button>,
      ]}
    >
      <Row gutter={[16, 16]}>
        {allowedAssets.length === 0 && (
          <Empty className={classes.empty} image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('global.nodata')} />
        )}
        {allowedAssets.map((asset: Asset) => {
          return (
            <Col key={asset.id} {...layoutColProps}>
              {renderCardComponent(asset)}
            </Col>
          );
        })}
      </Row>
    </Modal>
  );
};

export default PermissionExpandedRowAssetChoiceModal;
