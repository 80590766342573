import { CheckCircleOutlined, CopyrightOutlined } from '@ant-design/icons';
import { Button, List, Modal, Popover, Skeleton } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import FontObserver from '../../../../../components/font-observer/FontObserver';
import { DEVICE_SIZES_QUERIES, THEME } from '../../../../../Constants';
import { RootState } from '../../../../../redux/RootState';
import { Font, FontFamily } from '../../../../../services/api/types/FontFamiliesServiceTypes';
import FontSettingsToolbar from '../FontSettingsToolbar';

type Props = {
  fontFamily: FontFamily;
  selectedTitleFont?: Font;
  selectedTextFont?: Font;
  onSelectTextFont: (font: Font, fontFamily: FontFamily) => void;
  onSelectTitleFont: (font: Font, fontFamily: FontFamily) => void;
};

const useStyles = createUseStyles({
  fontFamilyModal: {
    width: '80% !important',
  },
  fontVariantsList: {
    marginTop: '15px',
    '& li:not(:last-child)': {
      borderBottom: '1px solid #f0f0f0',
    },
  },
  fontVariantContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 0',
    color: 'rgba(0,0,0,.65)',
    position: 'relative',
  },
  fontVariant: {
    width: '100%',
  },
  fontVariantNameContainer: {
    marginBottom: '4px',
    color: 'rgba(0,0,0,.65)',
    lineHeight: '1.5715',
    fontSize: '14px',
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  fontVariantCopyrightIcon: {
    '& .anticon': {
      marginLeft: '5px',
      display: 'inline-flex',
      fontSize: '10px',
    },
  },
  fontVariantCopyright: {
    fontSize: '12px',
  },
  fontVariantRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      alignItems: 'inherit',
      flexDirection: 'column',
    },
  },
  fontVariantTextSample: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    position: 'relative',
  },
  fontVariantActionsContainer: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    background: 'linear-gradient(271.24deg,#fff 56.18%,rgba(255,255,255,0) 97.24%)',
    width: '300px',
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      background: 'none',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    },
  },
  mobileOverflowGradient: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    background: 'linear-gradient(271.24deg,#fff 56.18%,rgba(255,255,255,0) 97.24%)',
    width: '20px',
  },
  selectFontButton: {
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '20px',
  },
  selectedFontButton: {
    borderColor: THEME.DEFAULT.MAIN_TEXT_COLOR,
    color: THEME.DEFAULT.MAIN_TEXT_COLOR,
    backgroundColor: THEME.GLOBAL.MAIN_HIGHLIGHT_BACKGROUND_COLOR,
    '&:hover, &:active, &:focus': {
      backgroundColor: THEME.GLOBAL.MAIN_HIGHLIGHT_BACKGROUND_COLOR,
    },
    '& .anticon + span': {
      marginLeft: '0',
    },
    '& .anticon': {
      lineHeight: 0,
    },
  },
  selectedFontIcon: {
    marginRight: '4px',
  },
  emptyCircle: {
    height: 14,
    width: 14,
    border: 'solid 1px #CECECE',
    borderRadius: '50%',
    marginRight: 4,
  },
});

const FontFamilyModal: FunctionComponent<Props> = ({
  fontFamily,
  selectedTitleFont,
  selectedTextFont,
  onSelectTitleFont,
  onSelectTextFont,
}: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [fontTextExample, setFontTextExample] = useState<string>();
  const [fontSize, setFontSize] = useState<number>(30);

  const { t } = useTranslation();
  const classes = useStyles();
  const isMobileOrTablet = useSelector((state: RootState) => {
    return state.app.isMobileOrTablet;
  });

  const defaultSampleText = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.';

  const showModal = (): void => {
    setIsVisible(true);
  };

  const closeModal = (): void => {
    setIsVisible(false);
  };

  const onFontTextExampleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newText = event.target.value;

    if (!newText || newText === '') {
      setFontTextExample(undefined);
    }

    setFontTextExample(newText);
  };

  const onFontSizeChange = (value: number | undefined): void => {
    if (value) {
      setFontSize(value);
    }
  };

  const renderFontVariant = (variant: Font): JSX.Element => {
    const isSelectedTitleFont = variant.id === selectedTitleFont?.id;
    const isSelectedTextFont = variant.id === selectedTextFont?.id;
    return (
      <li className={classes.fontVariantContainer}>
        <div className={classes.fontVariant}>
          <h4 className={classes.fontVariantNameContainer}>
            <span>
              {/* Font variant name */}
              {variant.fullName}

              {/* Font variant copyright */}
              <Popover
                content={variant.copyright}
                title={t('charters.fonts.copyright')}
                trigger={['click', 'hover']}
                overlayClassName={classes.fontVariantCopyright}
                placement={isMobileOrTablet ? 'bottom' : 'right'}
              >
                <span className={classes.fontVariantCopyrightIcon}>
                  <CopyrightOutlined />
                </span>
              </Popover>
            </span>
          </h4>
          <div className={classes.fontVariantRow}>
            <FontObserver
              fontFamilyName={fontFamily.name}
              fontWeight={variant.weight}
              fontStyle={variant.style}
              loader={<Skeleton active paragraph={{ rows: 0 }} title={{ width: '100%' }} />}
            >
              <div
                className={classes.fontVariantTextSample}
                style={{
                  fontFamily: fontFamily.name,
                  fontSize,
                  fontWeight: variant.weight || 'normal',
                  fontStyle: variant.style || 'normal',
                }}
              >
                {fontTextExample || defaultSampleText}

                {isMobileOrTablet && <div className={classes.mobileOverflowGradient} />}
              </div>
            </FontObserver>

            <div className={classes.fontVariantActionsContainer}>
              <div>
                <Button
                  onClick={(): void => onSelectTitleFont(variant, fontFamily)}
                  className={classNames(classes.selectFontButton, isSelectedTitleFont && classes.selectedFontButton)}
                  size="small"
                >
                  {isSelectedTitleFont ? (
                    <CheckCircleOutlined className={classes.selectedFontIcon} />
                  ) : (
                    <span className={classes.emptyCircle} />
                  )}
                  {t('charters.fonts.titleFont')}
                </Button>
                <Button
                  onClick={(): void => onSelectTextFont(variant, fontFamily)}
                  className={classNames(classes.selectFontButton, isSelectedTextFont && classes.selectedFontButton)}
                  size="small"
                >
                  {isSelectedTextFont ? (
                    <CheckCircleOutlined className={classes.selectedFontIcon} />
                  ) : (
                    <span className={classes.emptyCircle} />
                  )}
                  {t('charters.fonts.textFont')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </li>
    );
  };

  return (
    <>
      <Button shape="round" onClick={showModal} className="openFontModalButton">
        {t('global.select')}
      </Button>
      <Modal
        title={fontFamily.name}
        visible={isVisible}
        className={classes.fontFamilyModal}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            {t('global.close')}
          </Button>,
        ]}
      >
        <div>
          <FontSettingsToolbar
            fontSize={fontSize}
            fontTextExample={fontTextExample}
            onFontSizeChange={onFontSizeChange}
            onFontTextExampleChange={onFontTextExampleChange}
          />
          <List
            className={classes.fontVariantsList}
            itemLayout="horizontal"
            dataSource={fontFamily.fonts}
            renderItem={renderFontVariant}
          />
        </div>
      </Modal>
    </>
  );
};
export default FontFamilyModal;
