import React, { FunctionComponent, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { MediaType } from '../../../../../../../utils/types/MediaTypes';
import {
  ProjectSceneAnimation,
  ProjectSceneElementsCode,
  ProjectSceneMedia,
} from '../../../../../../../utils/types/ProjectTypes';
import { VideoTrim, VideoTrimUtils } from '../utils/VideoTrimUtils';

type Props = {
  children: JSX.Element[] | JSX.Element | ReactNode | string;
  element: ProjectSceneElementsCode;
  backgroundVideo?: ProjectSceneMedia;
  pip?: ProjectSceneMedia;
  animation?: ProjectSceneAnimation;
  intervalTickWidth: number;
  videoTrimValue: VideoTrim;
};

type StyleProps = {
  intervalTickWidth: number;
  videoTrimValue: VideoTrim;
  element: ProjectSceneElementsCode;
  backgroundVideo?: ProjectSceneMedia;
  pipVideoTrimValue: VideoTrim;
  pip?: ProjectSceneMedia;
  latestElementEndsAt: number;
};

const getTopPosition = (element: ProjectSceneElementsCode): number => {
  switch (element) {
    case ProjectSceneElementsCode.ANIMATION:
      return 0;
    case ProjectSceneElementsCode.SUBTITLES:
      return 10;
    default:
      return -20;
  }
};

const useStyles = createUseStyles({
  sceneRowsBefore: ({ videoTrimValue, pipVideoTrimValue, intervalTickWidth, element }: StyleProps) => {
    const trimStart = VideoTrimUtils.getMinTrimStartForPosition(videoTrimValue, pipVideoTrimValue);

    return {
      zIndex: 1,
      background: '#F0F0F0',
      opacity: 0.7,
      position: 'relative',
      height: element === ProjectSceneElementsCode.ANIMATION ? '100%' : '60px',
      top: getTopPosition(element),
      width: trimStart * intervalTickWidth,
    };
  },
  sceneRowsAfter: ({ backgroundVideo, pip, intervalTickWidth, latestElementEndsAt, element }: StyleProps) => {
    const isPipWithoutBackgroundVideo = !backgroundVideo && pip;
    let trimStartAt;
    let trimEndAt;
    let left;

    // If there is just a PIP (no background video)
    if (isPipWithoutBackgroundVideo) {
      trimStartAt = 0;
      trimEndAt = pip!.duration;
      left = trimEndAt ? trimEndAt * intervalTickWidth : undefined;
    } else {
      // Else, generic case if there is a video
      trimStartAt = backgroundVideo?.trimStartAt ?? 0;
      trimEndAt = backgroundVideo?.trimEndAt ?? backgroundVideo?.media.duration;
      left = backgroundVideo && trimEndAt !== undefined ? (trimEndAt - trimStartAt) * intervalTickWidth : undefined;
    }

    let width = latestElementEndsAt && left ? latestElementEndsAt * intervalTickWidth - left : undefined;

    if (trimEndAt !== undefined && trimEndAt - trimStartAt >= latestElementEndsAt) {
      left = undefined;
      width = undefined;
    }

    return {
      zIndex: 1,
      background: '#F0F0F0',
      opacity: 0.7,
      position: 'relative',
      left,
      width,
      height: element === ProjectSceneElementsCode.ANIMATION ? '100%' : '60px',
      top: getTopPosition(element),
    };
  },
});

const SceneTimelineGrayOverlay: FunctionComponent<Props> = ({
  children,
  element,
  backgroundVideo,
  pip,
  animation,
  intervalTickWidth,
  videoTrimValue,
}: Props) => {
  const pipVideoTrimValue: VideoTrim = {
    trimStart: pip?.trimStartAt || 0,
    trimEnd: pip?.trimEndAt || 0,
    delay: pip?.delay || 0,
  };
  const totalBackgroundVideoEndsAt = backgroundVideo?.media.duration
    ? backgroundVideo.media.duration - videoTrimValue.trimStart
    : 0;
  const videoBackgroundTrimDiff = (backgroundVideo?.trimEndAt || 0) - (backgroundVideo?.trimStartAt || 0);
  const animationDelay = animation?.delay || 0;
  const isPipVideo = pip?.media?.mediaType === MediaType.VIDEO;
  const pipEndsAt = isPipVideo
    ? (pip?.media.duration || 0) + (pip?.delay || 0) - (pip?.trimStartAt || 0)
    : (pip?.duration || 0) + (pip?.delay || 0);
  let animationEndsAt = 0;
  if (animation?.duration) {
    if (videoBackgroundTrimDiff > 0 && animation?.duration) {
      animationEndsAt =
        animation.duration + (videoBackgroundTrimDiff > animationDelay ? animationDelay : videoBackgroundTrimDiff);
    } else {
      animationEndsAt = animation.duration + animationDelay;
    }
  }

  const latestElementEndsAt = Math.max(totalBackgroundVideoEndsAt, pipEndsAt, animationEndsAt);

  const classes = useStyles({
    videoTrimValue,
    intervalTickWidth,
    latestElementEndsAt,
    backgroundVideo,
    pipVideoTrimValue,
    element,
  });

  return (
    <>
      <div className={classes.sceneRowsBefore} />
      {children}
      <div className={classes.sceneRowsAfter} />
    </>
  );
};

export default SceneTimelineGrayOverlay;
