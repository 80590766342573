import { Col, Row } from 'antd';
import { cloneDeep, findIndex } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { DEVICE_SIZES_QUERIES } from '../../../../../Constants';
import MathUtils from '../../../../../utils/MathUtils';
import ProjectUtils from '../../../../../utils/ProjectUtils';
import { AnimationFormats } from '../../../../../utils/types/AnimationTypes';
import { MediaType } from '../../../../../utils/types/MediaTypes';
import {
  Project,
  ProjectScene,
  ProjectSceneElement,
  ProjectSceneElements,
  ProjectSceneElementsCode,
} from '../../../../../utils/types/ProjectTypes';
import { AnimationTheme } from '../../../../../utils/types/ThemeTypes';
import ProjectSceneOptions from './ProjectSceneOptions';
import ProjectScenePreview from './ProjectScenePreview';
import SceneTimeline from './scene-timeline/SceneTimeline';
import { VideoTrim } from './scene-timeline/utils/VideoTrimUtils';

type Props = {
  project: Project;
  initialScene: ProjectScene;
  editedScene: ProjectScene;
  isSceneEdited: boolean;
  isSceneInError: boolean;
  format?: AnimationFormats;
  theme: AnimationTheme;
  customColors: boolean;
  audioVolume: number;
  selectedSceneTitle: string;
  onEditScene: (updatedScene: ProjectScene, updateInitialScene?: boolean) => void;
  onSubmitScene: (updatedScene: ProjectScene) => void;
  onIsSceneEditedChange: (isEdited: boolean) => void;
  onIsSceneInErrorChange: (isInError: boolean) => void;
  callbackIfUnsavedChangesConfirmed: (
    callback: (saveConfirmed?: boolean) => void,
    confirmTitle?: string,
    confirmDescription?: string,
    skipConfirm?: boolean,
    selectedElement?: ProjectSceneElementsCode
  ) => void;
  onSubmitSubtitleBlock: (selectedElementId: number) => void;
};

type StyleProps = {
  isSlideScene: boolean;
};

const useStyles = createUseStyles({
  editorContainer: {
    boxShadow: '1px 2px 8px 0 hsla(0, 0%, 0%, 0.15) !important',
    borderRadius: 5,
    overflow: 'hidden',
  },
  editorVideoRow: {
    minHeight: 400,
    borderBottom: '1px solid #F0F0F0',
  },
  projectScenePreviewColumn: {
    background: '#F7F7F7',
  },
  projectScenePreviewContainer: {
    background: '#F7F7F7',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '60%',
    [`@media screen and ${DEVICE_SIZES_QUERIES.LAPTOP}`]: {
      maxWidth: '75%',
    },
    [`@media screen and ${DEVICE_SIZES_QUERIES.SMALL_LAPTOP}`]: {
      maxWidth: '90%',
    },
    margin: 'auto',
  },
  scenePlayerWrapper: {
    boxShadow: '0 8px 8px 0 hsla(0, 0%, 0%, 0.15) !important',
    borderRadius: 4,
  },
  timelineContainer: ({ isSlideScene }: StyleProps) => ({
    height: isSlideScene ? 280 : 400,
  }),
  selectedItemOptionsContainer: {
    height: '100%',
    borderLeft: '1px solid #F0F0F0',
  },
});

const ProjectSceneEditor: FunctionComponent<Props> = ({
  project,
  initialScene,
  editedScene,
  isSceneEdited,
  isSceneInError,
  format,
  theme,
  customColors,
  audioVolume,
  selectedSceneTitle,
  onEditScene,
  onSubmitScene,
  onIsSceneEditedChange,
  onIsSceneInErrorChange,
  callbackIfUnsavedChangesConfirmed,
  onSubmitSubtitleBlock,
}: Props) => {
  const [selectedSceneElement, setSelectedSceneElement] = useState<ProjectSceneElement>();
  const [selectedElementId, setSelectedElementId] = useState<number>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [seekTo, setSeekTo] = useState<number>();
  const [sceneDuration, setSceneDuration] = useState<number>(0);
  const [videoTrimValue, setVideoTrimValue] = useState<VideoTrim>({
    trimStart: editedScene?.backgroundVideo?.trimStartAt === undefined ? 0 : editedScene.backgroundVideo.trimStartAt,
    trimEnd:
      editedScene?.backgroundVideo?.trimEndAt === undefined ? sceneDuration : editedScene.backgroundVideo.trimEndAt,
  });

  const [isTimelineLoading, setIsTimelineLoading] = useState(true);

  const isActiveScene = editedScene.isActive;

  const leftColumnLayoutProps = { sm: 12, md: 12, lg: 14, xl: 16 };
  const rightColumnLayoutProps = {
    sm: 24 - leftColumnLayoutProps.sm,
    md: 24 - leftColumnLayoutProps.md,
    lg: 24 - leftColumnLayoutProps.lg,
    xl: 24 - leftColumnLayoutProps.xl,
  };

  const classes = useStyles({ isSlideScene: ProjectUtils.isSlideScene(initialScene) });
  const { t } = useTranslation();

  useEffect(() => {
    if (ProjectUtils.isSlideScene(editedScene)) {
      return;
    }

    const duration = editedScene.backgroundVideo?.media.duration ?? editedScene.pip?.duration ?? 0;
    setSceneDuration(duration);

    setVideoTrimValue({
      trimStart: editedScene.backgroundVideo?.trimStartAt !== undefined ? editedScene.backgroundVideo.trimStartAt : 0,
      trimEnd:
        editedScene.backgroundVideo?.trimEndAt !== undefined ? editedScene.backgroundVideo.trimEndAt : sceneDuration,
    });
  }, [editedScene, sceneDuration]);

  useEffect(() => {
    if (!editedScene || !selectedElementId) {
      return;
    }

    const subtitlesBlocks = editedScene?.subtitles?.blocks;
    const index = findIndex(subtitlesBlocks, { id: selectedElementId });

    if (index < 0 && subtitlesBlocks && subtitlesBlocks.length > 0) {
      setSelectedElementId(subtitlesBlocks[0].id);
    }
  }, [editedScene, selectedElementId]);

  useEffect(() => {
    // If the selected scene has changed, we set the  timeline loading to true, and reset the currentTime to 0
    setIsTimelineLoading(true);
    setCurrentTime(0);
  }, [initialScene.id]);

  useEffect(() => {
    // If the animation is selected, and it gets deleted for the current scene, we unselect it
    if (ProjectUtils.isAnimationSelected(selectedSceneElement) && !initialScene.animation) {
      setSelectedSceneElement(undefined);
    }
  }, [initialScene, selectedSceneElement]);

  // When the selected scene changes, if it is a 'Slide', we directly select
  // the animation element, else we try selecting the background video, or the animation
  // or the PIP for 'Recordable' scenes
  useEffect(() => {
    if (!isActiveScene) {
      setSelectedSceneElement(undefined);
    } else if (ProjectUtils.isSlideScene(initialScene)) {
      setSelectedSceneElement(ProjectSceneElements.ANIMATION);
    } else if (initialScene.backgroundVideo) {
      setSelectedSceneElement(ProjectSceneElements.RECORDABLE_VIDEO);
    } else if (initialScene.pip) {
      setSelectedSceneElement(ProjectSceneElements.PIP);
    } else if (initialScene.animation) {
      setSelectedSceneElement(ProjectSceneElements.ANIMATION);
    } else {
      setSelectedSceneElement(undefined);
    }

    setSelectedElementId(undefined);
  }, [initialScene.id, isActiveScene]);

  const onPlay = useCallback(() => {
    setIsPlaying(true);
  }, []);

  const onPause = useCallback(() => {
    setIsPlaying(false);
  }, []);

  const onIsPlayingChange = useCallback(
    (newValue: boolean): void => {
      return newValue ? onPlay() : onPause();
    },
    [onPlay, onPause]
  );

  const onCurrentTimeChange = useCallback((seekedTime: number, fromSeekbar = false) => {
    setCurrentTime(seekedTime);
    // If the time was updated from the seekbar, we pause the video and seek the player at the right time
    if (fromSeekbar) {
      onPause();
      setSeekTo(seekedTime);
    }
  }, []);

  const resetVideoTrimAndAnimationDelayValues = () => {
    if (!editedScene || !editedScene.backgroundVideo) {
      return;
    }

    const startTrimReset =
      initialScene.backgroundVideo?.trimStartAt === undefined ? 0 : initialScene.backgroundVideo.trimStartAt;
    const endTrimReset =
      initialScene.backgroundVideo?.trimEndAt === undefined ? sceneDuration : initialScene.backgroundVideo.trimEndAt;
    const delayReset = initialScene?.animation?.delay === undefined ? 0 : initialScene.animation.delay;

    onEditScene({
      ...editedScene,
      backgroundVideo: editedScene.backgroundVideo
        ? { ...editedScene.backgroundVideo, trimStartAt: startTrimReset, trimEndAt: endTrimReset }
        : undefined,
      animation: editedScene.animation ? { ...editedScene.animation, delay: delayReset } : undefined,
      pip: initialScene.pip ? { ...initialScene.pip } : undefined,
    });

    setVideoTrimValue({
      trimStart: startTrimReset,
      trimEnd: endTrimReset,
    });
  };

  const resetAnimationsValues = () => {
    if (!editedScene || !editedScene.animation || !initialScene.animation) {
      return;
    }

    onEditScene({
      ...editedScene,
      animation: {
        ...initialScene?.animation,
      },
    });
  };

  const resetSubtitlesValues = () => {
    if (!editedScene || !editedScene.subtitles || !initialScene.subtitles?.language) {
      return;
    }

    onEditScene({
      ...editedScene,
      subtitles: {
        ...initialScene?.subtitles,
      },
    });
  };

  const resetPipValues = () => {
    if (!editedScene || !editedScene.pip) {
      return;
    }

    onEditScene({
      ...editedScene,
      pip: initialScene.pip
        ? {
            ...initialScene?.pip,
          }
        : undefined,
    });
  };

  const submitSubtitleBlock = (): void => {
    if (!selectedElementId) {
      return;
    }

    onSubmitSubtitleBlock(selectedElementId);
  };

  const onSelectSceneElement = useCallback(
    (element: ProjectSceneElement, skipConfirm = false, elementId?: number) => {
      if (element === selectedSceneElement && elementId === selectedElementId) {
        return;
      }

      const callbackIfSelectSceneElementConfirmed = (saveConfirmed?: boolean) => {
        if (saveConfirmed === false) {
          const previouslySelectedElementCode = selectedSceneElement?.code;

          // Depending on the previously selected element, if the changes aren't saved in the modal, then we reset the appropriate scene properties
          if (previouslySelectedElementCode === ProjectSceneElementsCode.ANIMATION) {
            resetAnimationsValues();
          } else if (previouslySelectedElementCode === ProjectSceneElementsCode.PIP) {
            resetPipValues();
          } else if (previouslySelectedElementCode === ProjectSceneElementsCode.RECORDABLE_VIDEO) {
            resetVideoTrimAndAnimationDelayValues();
          } else if (previouslySelectedElementCode === ProjectSceneElementsCode.SUBTITLES) {
            resetSubtitlesValues();
          }
        } else if (saveConfirmed && selectedSceneElement?.code === ProjectSceneElementsCode.SUBTITLES) {
          submitSubtitleBlock();
        }

        setSelectedSceneElement(element);
        setSelectedElementId(elementId);
        onIsSceneEditedChange(false);
      };

      callbackIfUnsavedChangesConfirmed(
        callbackIfSelectSceneElementConfirmed,
        undefined,
        t('charters.projects.projectEditor.unsavedChangesSelectSceneElementConfirmDescription', {
          sceneName: selectedSceneTitle,
        }),
        skipConfirm,
        selectedSceneElement?.code as ProjectSceneElementsCode
      );
    },
    [initialScene, callbackIfUnsavedChangesConfirmed, selectedSceneTitle, selectedSceneElement]
  );

  const onSceneDurationChange = useCallback(
    (duration: number) => {
      if (duration <= 0) {
        return;
      }

      if (ProjectUtils.isSlideScene(initialScene)) {
        setSceneDuration(duration);
        setVideoTrimValue({
          trimStart: 0,
          trimEnd: duration,
        });
      }
      setIsTimelineLoading(false);
    },
    [initialScene.id]
  );

  // Once the ProjectScenePreview player has loaded the animation and computed its duration,
  // we ensure to update the editedScene.animation with that new duration
  const onAnimationDurationChange = useCallback(
    (duration: number) => {
      if (duration <= 0 || (editedScene && editedScene.animation && editedScene.animation?.duration === duration)) {
        return;
      }

      const newScene = {
        ...editedScene,
        animation: editedScene.animation ? { ...editedScene.animation, duration } : undefined,
      };

      onEditScene(newScene);

      // If the animation has no duration, we know that is has not been initialized yet.
      // Indeed, the animation has just been added to the scene, so we might compute its duration and persist it through the API.
      // We only do that when an animation is added to a scene which did not have an animation previously (not when changing the animation).
      if (!editedScene.animation?.duration) {
        onSubmitScene(newScene);
      }
    },
    [editedScene]
  );

  const onTrimChange = useCallback(
    (minMaxAnchors: VideoTrim, type: ProjectSceneElementsCode = ProjectSceneElementsCode.RECORDABLE_VIDEO) => {
      if (type === ProjectSceneElementsCode.RECORDABLE_VIDEO) {
        setVideoTrimValue(minMaxAnchors);

        const pip = editedScene.pip ? { ...editedScene.pip } : undefined;
        if (pip && pip.media.mediaType === MediaType.IMAGE) {
          const videoDuration = minMaxAnchors.trimEnd - minMaxAnchors.trimStart;
          const pipDuration = pip.duration || 0;
          const pipDelay = pip.delay || 0;

          if (videoDuration < pipDuration) {
            pip.duration = videoDuration;
          }

          if (minMaxAnchors.trimEnd < pipDelay) {
            pip.delay = minMaxAnchors.trimEnd;
          }
        }

        onEditScene({
          ...editedScene,
          backgroundVideo: editedScene.backgroundVideo
            ? { ...editedScene.backgroundVideo, trimStartAt: minMaxAnchors.trimStart, trimEndAt: minMaxAnchors.trimEnd }
            : undefined,
          pip: pip ? { ...pip } : undefined,
        });
      } else if (type === ProjectSceneElementsCode.PIP) {
        const pipType = editedScene.pip?.media.mediaType;
        if (pipType === MediaType.IMAGE) {
          const maxVideoDuration = editedScene.backgroundVideo?.media.duration || 0;
          const delay = minMaxAnchors.delay || 0;

          onEditScene({
            ...editedScene,
            pip: editedScene.pip
              ? {
                  ...editedScene.pip,
                  delay:
                    minMaxAnchors.trimStart + delay > maxVideoDuration
                      ? minMaxAnchors.delay
                      : minMaxAnchors.trimStart + delay,
                  duration: minMaxAnchors.trimEnd - minMaxAnchors.trimStart,
                }
              : undefined,
          });
        } else {
          onEditScene({
            ...editedScene,
            pip: editedScene.pip
              ? {
                  ...editedScene.pip,
                  delay: minMaxAnchors.delay,
                  duration: minMaxAnchors.trimEnd - minMaxAnchors.trimStart,
                  trimStartAt: MathUtils.formatInputValueWithXDecimals(minMaxAnchors.trimStart, 3),
                  trimEndAt: MathUtils.formatInputValueWithXDecimals(minMaxAnchors.trimEnd, 3),
                }
              : undefined,
          });
        }
      }
    },
    [editedScene]
  );

  const onDeleteSceneElement = () => {
    onPause();
    onCurrentTimeChange(0, true);
  };

  const handleResetSubtitles = () => {
    const sceneToReset = { ...editedScene };
    sceneToReset.subtitles = cloneDeep(initialScene.subtitles);

    onEditScene(sceneToReset);
  };

  return (
    <div className={classes.editorContainer}>
      <Row className={classes.editorVideoRow}>
        <Col {...leftColumnLayoutProps} className={classes.projectScenePreviewColumn}>
          <div className={classes.projectScenePreviewContainer}>
            <ProjectScenePreview
              scene={editedScene}
              format={format}
              isPlaying={isPlaying}
              seekTo={seekTo}
              theme={theme}
              onIsPlayingChange={onIsPlayingChange}
              customColors={customColors}
              projectAudioVolume={audioVolume}
              wrapperClassName={classes.scenePlayerWrapper}
              onCurrentTimeChange={onCurrentTimeChange}
              onDurationChange={onSceneDurationChange}
              onAnimationDurationChange={onAnimationDurationChange}
              videoTrimValue={videoTrimValue}
            />
          </div>
        </Col>
        <Col {...rightColumnLayoutProps}>
          <div className={classes.selectedItemOptionsContainer}>
            <ProjectSceneOptions
              project={project}
              initialScene={initialScene}
              scene={editedScene}
              selectedElement={selectedSceneElement}
              selectedElementId={selectedElementId}
              onSelectElement={onSelectSceneElement}
              isSceneEdited={isSceneEdited}
              isSceneInError={isSceneInError}
              sceneDuration={sceneDuration}
              isLoading={isTimelineLoading}
              format={format}
              theme={theme}
              customColors={customColors}
              videoTrimValue={videoTrimValue}
              onEditScene={onEditScene}
              onSubmitScene={onSubmitScene}
              onIsSceneEditedChange={onIsSceneEditedChange}
              onIsSceneInErrorChange={onIsSceneInErrorChange}
              onTrimChange={onTrimChange}
              onDeleteSceneElement={onDeleteSceneElement}
            />
          </div>
        </Col>
      </Row>

      {isActiveScene && (
        <div className={classes.timelineContainer}>
          <SceneTimeline
            format={format}
            theme={theme}
            customColors={customColors}
            scene={editedScene}
            project={project}
            selectedElement={selectedSceneElement}
            selectedElementId={selectedElementId}
            onSelectElement={onSelectSceneElement}
            duration={sceneDuration}
            onPlay={onPlay}
            onPause={onPause}
            isLoading={isTimelineLoading}
            isPlaying={isPlaying}
            currentTime={currentTime}
            onEditScene={onEditScene}
            onSubmitScene={onSubmitScene}
            onCurrentTimeChange={(e) => onCurrentTimeChange(e, true)}
            videoTrimValue={videoTrimValue}
            onTrimChange={onTrimChange}
            callbackIfUnsavedChangesConfirmed={callbackIfUnsavedChangesConfirmed}
            resetTrimAndDelayValues={resetVideoTrimAndAnimationDelayValues}
            callbackReset={handleResetSubtitles}
            submitSubtitleBlock={submitSubtitleBlock}
          />
        </div>
      )}
    </div>
  );
};

export default ProjectSceneEditor;
