import { FolderOpenOutlined } from '@ant-design/icons';
import { message, Row } from 'antd';
import { BreadcrumbProps } from 'antd/es/breadcrumb';
import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import { UploadChangeParam } from 'antd/lib/upload';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { filter, findIndex } from 'lodash';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import ErrorAlert from '../../../../../components/alert/ErrorAlert';
import EmptyFilesContent from '../../../../../components/empty/EmptyFilesContent';
import EmptyPageContent from '../../../../../components/empty/EmptyPageContent';
import PageContentLoader from '../../../../../components/loader/PageContentLoader';
import FileUploader, { DraggerCustomRequestParams } from '../../../../../components/modal/FileUploader';
import KannelleHelpButton from '../../../../../components/page-header/KannelleHelpButton';
import KannellePageHeader from '../../../../../components/page-header/KannellePageHeader';
import { CompanyBundleFeatureList, DEVICE_SIZES_QUERIES, LINK, LOGGING_EVENT, THEME } from '../../../../../Constants';
import useCanAccess from '../../../../../hooks/useCanAccess';
import useFetchCharterById from '../../../../../hooks/useFetchCharterById';
import useSocket from '../../../../../hooks/useSocket';
import JoyRideHelpAssets from '../../../../../onboarding/JoyRideHelpAssets';
import {
  fileUploadedAndProcessing,
  refreshProcessingAssetsByType,
  updateAssetProcessingProgression,
  updateProcessingFileOperationId,
} from '../../../../../redux/action/ChartersAction';
import { RootState } from '../../../../../redux/RootState';
import smallLogo from '../../../../../resources/img/K-rectangle-small.png';
import { getCharterAssetSignedUrlToUpload } from '../../../../../services/api/ChartersService';
import { uploadFileToSignedUrl } from '../../../../../services/api/GlobalService';
import { SocketManager } from '../../../../../services/api/SocketManager';
import { Asset, AssetType } from '../../../../../services/api/types/ChartersServiceTypes';
import { AssetOrMediaProcessingResponse } from '../../../../../services/api/types/WebSocketTypes';
import { CharterIdPathParam } from '../../../../../services/navigation/NavigationConfigTypes';
import CharterUtils from '../../../../../utils/CharterUtils';
import JoyRideUtils from '../../../../../utils/JoyRideUtils';
import MathUtils from '../../../../../utils/MathUtils';
import { PermissionList } from '../../../../../utils/types/CharterPermissionTypes';
import AssetsManager from './AssetsManager';

const useStyles = createUseStyles({
  pageContent: {
    backgroundColor: '#FFFFFF',
    margin: 24,
    '& .ant-page-header-heading-title': {
      lineHeight: '48px !important',
    },
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      margin: 0,
    },
  },
  titleWithIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    backgroundColor: THEME.DEFAULT.MAIN_COLOR,
    minWidth: 32,
  },
  subtitleRow: {
    marginBottom: 16,
  },
  errorAlert: {
    marginTop: '30px',
  },
  helpIcon: {
    marginLeft: 8,
    fontSize: '14px !important',
    '& *': {
      fontSize: '14px !important',
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

type Props = {
  assetType: AssetType;
  acceptFileType: string;
  sizeLimit: number;
  bundleFeatureLimit?: CompanyBundleFeatureList;
};

const GenericCharterAssets: FunctionComponent<Props> = ({
  assetType,
  acceptFileType,
  sizeLimit,
  bundleFeatureLimit,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { charterId } = useParams<CharterIdPathParam>();
  const { loading, isError } = useFetchCharterById(parseInt(charterId, 10), true, false);
  const [assets, setAssets] = useState<Asset[]>();
  const [room, setRoom] = useState<string>();
  const isSocketConnected = useSocket(room ?? undefined);
  const [runHelp, setRunHelp] = useState(false);
  const [bundleLimitExceeded, setBundleLimitExceeded] = useState(false);
  const [addedRunExample, setAddedRunExample] = useState(false);

  const { hasUserAccessToCharter } = useCanAccess(PermissionList.WEB_DASHBOARD);

  const processingAssets = useSelector((state: RootState) => state.charters.processingAssets);
  const charter = useSelector((state: RootState) => state.charters.current);
  const company = useSelector((state: RootState) => state.companies.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);
  const bundle = useSelector((state: RootState) => state.companies.bundle);

  useEffect(() => {
    const updateProgress = (payload: AssetOrMediaProcessingResponse): void => {
      const { operationId, progress } = payload.data;
      if (progress) {
        dispatch(updateAssetProcessingProgression(operationId, progress, assetType));
      }
    };

    if (isSocketConnected) {
      SocketManager.onMessage(updateProgress);

      return (): void => SocketManager.offMessage(updateProgress);
    }

    return undefined;
  }, [isSocketConnected, assetType, dispatch]);

  useEffect(() => {
    if (!bundle || !bundleFeatureLimit) {
      return;
    }

    const bundleLimit = bundle.featureValues[bundleFeatureLimit];
    const activeAssets = filter(assets, (asset) => asset.isActive);

    setBundleLimitExceeded(bundleLimit !== undefined && activeAssets?.length >= bundleLimit);
  }, [assets]);

  useEffect(() => {
    if (!charter) {
      return;
    }

    setAssets(CharterUtils.getCharterAssetsByType(assetType, charter));
    setRoom(`charters/${charter.id}/${assetType}`);
  }, [charter, assetType]);

  // Remove processed assets of assetType from the processingAssets in store
  useEffect(() => {
    if (!assets) {
      return;
    }

    dispatch(refreshProcessingAssetsByType(assets, assetType));
  }, [assets, assetType, dispatch]);

  const onChangeAssetIsActive = (updatedAsset: Asset): void => {
    if (!assets) {
      return;
    }

    loggingManager.logEvent(LOGGING_EVENT.UPDATE_ASSET, {
      type: assetType,
      isActive: updatedAsset.isActive,
      companyId: company?.id,
      charterId: charter?.id,
      assetId: updatedAsset?.id,
    });

    const newAssets = assets.map((asset: Asset) => (asset.id === updatedAsset.id ? updatedAsset : asset));
    setAssets(newAssets);
  };

  const onChangeAssetIsArchived = (updatedAsset: Asset): void => {
    if (!assets) {
      return;
    }

    loggingManager.logEvent(LOGGING_EVENT.UPDATE_ASSET, {
      type: assetType,
      isArchived: updatedAsset.isArchived,
      companyId: company?.id,
      charterId: charter?.id,
      assetId: updatedAsset?.id,
    });
  };

  const onUploadStatusChangeToError = (info: UploadChangeParam): void => {
    message.error(t('charters.assets.uploadError', { fileName: info.file.name }));
  };

  const onUploadStatusChangeToDone = (info: UploadChangeParam): void => {
    dispatch(fileUploadedAndProcessing(info.file, assetType));
  };

  const onSizeLimitExceeded = (): void => {
    message.error(t(`charters.${assetType}.uploadSizeLimit`, { limit: sizeLimit }));
  };

  const onBundleLimitExceeded = (numberOfAddedAssets: number): void => {
    message.error(t(`charters.${assetType}.bundleLimitExceedUpload`, { fileUploadNumber: numberOfAddedAssets }), 3);
  };

  const onBeforeUpload = (file: UploadFile, fileList: RcFile[]): boolean => {
    const fileSizeInKB = MathUtils.convertBytesToKilobytes(file.size!);
    const isFileSmallEnough = fileSizeInKB / 1024 < sizeLimit;
    if (!isFileSmallEnough) {
      onSizeLimitExceeded();
      return false;
    }

    if (bundle && bundleFeatureLimit && assets) {
      const bundleLimit = bundle.featureValues[bundleFeatureLimit];
      if (bundleLimit) {
        const activeAssets = filter(assets, (asset) => asset.isActive);
        const isSuccess = activeAssets.length + fileList.length <= bundleLimit;
        const fileIndex = findIndex(fileList, { uid: file.uid });

        // fileIndex === fileList.length - 1 => This condition is here so that we display the message only once
        if (!isSuccess && fileIndex === fileList.length - 1) {
          onBundleLimitExceeded(fileList.length);
        }

        return isSuccess;
      }
    }

    return true;
  };

  const onUploadFile = (requestParam: DraggerCustomRequestParams): void => {
    if (!charter) {
      return;
    }
    const params = {
      charterId: charter.id,
      filename: requestParam.file.name,
      assetType,
    };

    loggingManager.logEvent(LOGGING_EVENT.UPLOAD_ASSET, {
      type: assetType,
      name: requestParam.file.name,
      size: `${Math.floor(MathUtils.convertBytesToKilobytes(requestParam.file.size))} ${t('charters.assets.KB')}`,
      companyId: company?.id,
      charterId: charter.id,
    });

    getCharterAssetSignedUrlToUpload(params)
      .then((signedUrl) => {
        return uploadFileToSignedUrl(signedUrl.fileUploadURL, requestParam.file, (event) => {
          requestParam.onProgress({ percent: (event.loaded / event.total) * 100 }, requestParam.file);
        })
          .then(() => {
            requestParam.onSuccess({ ...requestParam, data: signedUrl.metadata });
          })
          .catch((err) => requestParam.onError(err));
      })
      .catch((err) => requestParam.onError(err));
  };

  const onRetryUploadFile = (uploadFile: UploadFile): void => {
    if (!charter) {
      return;
    }

    const fileName = uploadFile.fileName || uploadFile.name;

    const params = {
      charterId: charter.id,
      filename: fileName,
      assetType,
    };

    dispatch(fileUploadedAndProcessing(uploadFile, assetType));

    getCharterAssetSignedUrlToUpload(params)
      .then((signedUrl) => {
        dispatch(
          updateProcessingFileOperationId(
            uploadFile.response.data.operationId,
            signedUrl.metadata.operationId,
            assetType
          )
        );
        return uploadFileToSignedUrl(signedUrl.fileUploadURL, uploadFile.originFileObj).catch((err) =>
          log.error(`An error occurred while retrying to upload an asset: ${err}`)
        );
      })
      .catch((err) => log.error(`An error occurred while retrying to upload an asset: ${err}`));
  };

  const startHelp = (): void => {
    if ((assets && assets.length === 0) || !assets) {
      const assetUrl = assetType === AssetType.LOGO ? smallLogo : smallLogo.replace('.png', '.mp4');
      const exampleAssets = JoyRideUtils.generateSampleAsset(assetUrl);
      setAssets([exampleAssets]);
      setAddedRunExample(true);
    }

    setRunHelp(true);
  };

  const endHelp = (): void => {
    setRunHelp(false);
    if (addedRunExample) {
      setAddedRunExample(false);
      setAssets(undefined);
    }
  };

  if (isError) {
    return <ErrorAlert className={classes.errorAlert} message={t('ajaxError.charterFetch')} />;
  }

  if (loading) {
    return <PageContentLoader />;
  }

  if (!charter) {
    return <EmptyPageContent />;
  }

  if (!hasUserAccessToCharter(parseInt(charterId, 10))) {
    history.push(LINK.UNAUTHORIZED.path);
    return null;
  }

  const routes = [
    {
      path: '/charters',
      breadcrumbName: t('menu.charters'),
    },
    {
      path: `/charters/${charter.id}`,
      breadcrumbName: charter.name,
    },
    {
      path: `/charters/${charter.id}/elements`,
      breadcrumbName: t('charters.graphicalElements.title'),
    },
    {
      path: location.pathname,
      breadcrumbName: t(`charters.${assetType}.title`),
    },
  ];

  const breadcrumbProps: BreadcrumbProps = {
    itemRender: (route: Route) => {
      if (route.path === location.pathname) {
        return route.breadcrumbName;
      }
      return <Link to={route.path}>{route.breadcrumbName}</Link>;
    },
    routes,
  };

  const processingAssetsList = CharterUtils.getUploadFilesByType(assetType, processingAssets);
  const unarchivedAssets = assets && assets.filter((asset) => !asset.isArchived);

  return (
    <KannellePageHeader
      title={
        <div className={classes.titleWithIcon}>
          {t(`charters.${assetType}.title`)}
          <KannelleHelpButton startHelp={startHelp} />
        </div>
      }
      breadcrumb={breadcrumbProps}
      className={classes.pageContent}
      avatar={{ className: classes.avatar, icon: <FolderOpenOutlined /> }}
      extra={
        <>
          <FileUploader
            triggerText={t('charters.assets.upload')}
            modalTitle={t(`charters.${assetType}.uploadModalTitle`)}
            uploadInstructionText={t(`charters.${assetType}.uploadTitle`)}
            uploadInstructionHint={t(`charters.${assetType}.uploadHint`)}
            uploadInstructionSizeLimit={t(`charters.${assetType}.uploadSizeLimit`, { limit: sizeLimit })}
            acceptFileType={acceptFileType}
            sizeLimit={sizeLimit}
            onSizeLimitExceeded={onSizeLimitExceeded}
            onUploadStatusChangeToDone={onUploadStatusChangeToDone}
            onUploadStatusChangeToError={onUploadStatusChangeToError}
            onUploadFile={onUploadFile}
            onBeforeUpload={onBeforeUpload}
            assetType={assetType}
            bundleLimitExceeded={bundleLimitExceeded}
          />
        </>
      }
    >
      <JoyRideHelpAssets assetType={assetType} runHelp={runHelp} callbackRunDone={endHelp} />
      <Row className={classes.subtitleRow}>
        <p>{t(`charters.${assetType}.pageDesc`)}</p>
      </Row>

      {!(unarchivedAssets && unarchivedAssets.length) && !(processingAssets && processingAssetsList.length) ? (
        <EmptyFilesContent
          emptyMessage={t('charters.assets.noAssets')}
          triggerText={t('charters.assets.upload')}
          modalTitle={t(`charters.${assetType}.uploadModalTitle`)}
          uploadInstructionText={t(`charters.${assetType}.uploadTitle`)}
          uploadInstructionHint={t(`charters.${assetType}.uploadHint`)}
          uploadInstructionSizeLimit={t(`charters.${assetType}.uploadSizeLimit`, { limit: sizeLimit })}
          acceptFileType={acceptFileType}
          sizeLimit={sizeLimit}
          onSizeLimitExceeded={onSizeLimitExceeded}
          onUploadStatusChangeToDone={onUploadStatusChangeToDone}
          onUploadStatusChangeToError={onUploadStatusChangeToError}
          onUploadFile={onUploadFile}
        />
      ) : (
        <AssetsManager
          charter={charter}
          assets={unarchivedAssets}
          processingAssets={processingAssetsList}
          onChangeAssetIsActive={onChangeAssetIsActive}
          onChangeAssetIsArchived={onChangeAssetIsArchived}
          type={assetType}
          onRetryUploadFile={onRetryUploadFile}
        />
      )}
    </KannellePageHeader>
  );
};

export default GenericCharterAssets;
