import { BellOutlined } from '@ant-design/icons/lib';
import { Badge, Dropdown, Menu } from 'antd';
import { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import log from 'loglevel';
import React, { FunctionComponent, ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { THEME } from '../../Constants';
import { clearNotifications, editNotificationById, pushNotification } from '../../redux/action/AppAction';
import { RootState } from '../../redux/RootState';
import { APIManager } from '../../services/api/APIManager';
import { getNotificationsForUserById } from '../../services/api/UsersService';
import LocalUtils from '../../utils/LocalUtils';
import LocationUtils from '../../utils/LocationUtils';
import NotificationUtils from '../../utils/NotificationUtils';
import { Notification } from '../../utils/types/NotificationTypes';
import NotificationsList from '../notifications/NotificationsList';

const useStyles = createUseStyles({
  dropdownMenu: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  menuContainer: {
    position: 'fixed',
  },
  menu: {
    position: 'relative',
    paddingTop: 0,
    paddingBottom: 0,
    overflowY: 'auto',
  },
  notificationsBadge: {
    lineHeight: '100%',
    marginRight: '4px',

    '& sup.ant-badge-count': {
      background: `${THEME.DEFAULT.MAIN_TEXT_COLOR} !important`,
      height: '14px',
      maxWidth: '12px',
      fontSize: '12px',
      lineHeight: '14px',
      padding: '0 4px',
    },
  },
  unreadNotificationsAnimation: {
    '&::after': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      border: `1px solid ${THEME.DEFAULT.MAIN_TEXT_COLOR}`,
      borderRadius: '50%',
      animation: '$badgeAnimation 1.2s infinite ease-in-out',
      content: "''",
    },
  },
  notificationBellIcon: {
    marginRight: '0 !important',
    fontSize: 'unset !important',
    '&:hover': {
      color: THEME.DEFAULT.HOVERED_LINK,
    },
  },
  '@keyframes badgeAnimation': {
    '0%': {
      transform: 'scale(0.8)',
      opacity: '0.5',
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: '0',
    },
  },
});

const KannelleNotificationsMenu: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [isNotificationsListVisible, setIsNotificationsListVisible] = useState(false);
  const [currentNotificationsPage, setCurrentNotificationsPage] = useState<string>('0');
  const [nextNotificationPage, setNextNotificationPage] = useState<string>();
  const [areAllNotificationsFetched, setAreAllNotificationsFetched] = useState(false);
  const [isError, setIsError] = useState(false);

  const notifications = useSelector((state: RootState) => state.app.notifications);
  const currentUser = useSelector((state: RootState) => state.user.user);

  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());
  const history = useHistory();

  const unreadNotificationsCount: number =
    notifications && notifications.filter((notification) => !notification.isRead).length;

  useEffect(() => {
    const cancelTokenSource = cancelTokenSourceRef.current;

    return (): void => {
      cancelTokenSource.cancel('Cancelled fetching user notifications due to component unmount.');
    };
  }, []);

  useEffect(() => {
    dispatch(clearNotifications());
    setCurrentNotificationsPage('0');
  }, [i18n.language, dispatch]);

  useEffect(() => {
    setAreAllNotificationsFetched(false);
  }, [i18n.language]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    const lang = LocalUtils.getLang();
    const cancelTokenSource = cancelTokenSourceRef.current;
    getNotificationsForUserById(currentUser.id, lang, currentNotificationsPage, cancelTokenSource)
      .then((res) => {
        const { items: fetchedNotifications, pagination } = res.data;
        if (!(fetchedNotifications && fetchedNotifications.length > 0)) {
          setAreAllNotificationsFetched(true);
        } else {
          if (pagination && pagination.next) {
            setNextNotificationPage(LocationUtils.getQueryParamByNameFromPathName('page', pagination.next));
          }
          NotificationUtils.APINotificationsToNotifications(fetchedNotifications, (notif: Notification): void => {
            dispatch(pushNotification(notif));
          });
        }
      })
      .catch((e) => {
        log.debug('Error while fetching user notifications', e);
        setIsError(true);
      });
    // eslint-disable-next-line
  }, [currentUser, currentNotificationsPage, dispatch]);

  const markAllNotificationsAsRead = (): void => {
    if (!(notifications && notifications.length > 0)) {
      return;
    }

    setTimeout(() => {
      notifications.forEach((notification) => {
        if (notification.id) {
          dispatch(editNotificationById(notification.id, { isRead: true }));
        }
      });
    }, 1500);
  };

  const onNotificationsListVisibilityChange = (): void => {
    setIsNotificationsListVisible((isVisible) => !isVisible);
    markAllNotificationsAsRead();
  };

  const fetchNextNotificationsPage = (): void => {
    if (nextNotificationPage) {
      setCurrentNotificationsPage(nextNotificationPage);
    }
  };

  const reloadThePage = (): void => {
    history.go(0);
  };

  const renderNotificationsList = (): ReactElement => {
    return notifications && notifications.length > 0 ? (
      <Menu className={classes.menu}>
        <NotificationsList
          notifications={notifications}
          nextData={fetchNextNotificationsPage}
          hasMore={!areAllNotificationsFetched}
          isError={isError}
          onReload={reloadThePage}
        />
      </Menu>
    ) : (
      <Menu>
        <Menu.Item>
          {isError ? t('ajaxError.notificationListFetch') : t('header.notifications.noNotification')}
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Dropdown
      overlay={renderNotificationsList()}
      overlayClassName={classes.menuContainer}
      placement="bottomRight"
      trigger={['click']}
      className={classNames(classes.dropdownMenu, 'notification-container')}
      onVisibleChange={onNotificationsListVisibilityChange}
      visible={isNotificationsListVisible}
    >
      <Badge
        dot={false}
        count={unreadNotificationsCount ?? 0}
        className={classNames(
          classes.notificationsBadge,
          unreadNotificationsCount > 0 && classes.unreadNotificationsAnimation
        )}
      >
        <BellOutlined className={classes.notificationBellIcon} />
      </Badge>
    </Dropdown>
  );
};

export default KannelleNotificationsMenu;
