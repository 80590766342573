import { Form, Select } from 'antd';
import map from 'lodash/map';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  name?: string;
  placeholder?: string;
  size?: 'small' | 'middle' | 'large';
  options: { code: string | number; key: string | JSX.Element }[];
  isReadOnly?: boolean;
  allowClear?: boolean;
  label?: string;
  errorText?: string;
  extra?: string;
  isRequired?: boolean;
  onChangeCallback?: (value: string) => void;
};

const InputSelect: FunctionComponent<Props> = ({
  name,
  label,
  extra,
  errorText,
  options,
  placeholder,
  size,
  allowClear = false,
  isReadOnly = false,
  isRequired = true,
  onChangeCallback,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={label}
      name={name}
      rules={[{ required: isRequired, message: errorText }]}
      extra={extra}
      hasFeedback
    >
      <Select
        disabled={isReadOnly}
        allowClear={allowClear}
        size={size}
        placeholder={placeholder}
        onChange={(value) => {
          if (onChangeCallback) {
            onChangeCallback(value as string);
          }
        }}
      >
        {map(options, (item: { code: string; key: string }) => {
          const isJSXElement = React.isValidElement(item.key);

          return (
            <Select.Option key={item.code} value={item.code}>
              {isJSXElement ? item.key : t(item.key)}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default InputSelect;
