import { Alert, Typography } from 'antd';
import { BreadcrumbProps } from 'antd/es/breadcrumb';
import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import filter from 'lodash/filter';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import EmptyPageContent from '../../../components/empty/EmptyPageContent';
import KannelleHelpButton from '../../../components/page-header/KannelleHelpButton';
import KannellePageHeader from '../../../components/page-header/KannellePageHeader';
import { LINK, THEME } from '../../../Constants';
import useCanAccess from '../../../hooks/useCanAccess';
import JoyRideHelpUsers from '../../../onboarding/JoyRideHelpUsers';
import { selectCharterById } from '../../../redux/action/ChartersAction';
import { RootState } from '../../../redux/RootState';
import { APIManager } from '../../../services/api/APIManager';
import { getCharterPermissions } from '../../../services/api/ChartersService';
import { CharterIdPathParam } from '../../../services/navigation/NavigationConfigTypes';
import LocalUtils from '../../../utils/LocalUtils';
import { PermissionList } from '../../../utils/types/CharterPermissionTypes';
import CharterUsersTable from './charter-users-table/CharterUsersTable';

const { Title } = Typography;

const useStyles = createUseStyles({
  pageDescription: {
    backgroundColor: THEME.DEFAULT.INFO_ALERT.BACKGROUND_COLOR,
    border: `1px solid ${THEME.DEFAULT.INFO_ALERT.BORDER_COLOR}`,
    marginBottom: '20px',
    textAlign: 'justify',
  },
  helpIcon: {
    marginLeft: 16,
    fontSize: '14px !important',
    '& *': {
      fontSize: '14px !important',
    },
  },
  titleWithIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

const CharterUsers: FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const charters = useSelector((state: RootState) => {
    return state.charters.current;
  });
  const classes = useStyles();
  const [runHelp, setRunHelp] = useState(false);

  const { hasUserAccessToCharter } = useCanAccess(PermissionList.WEB_DASHBOARD);

  const { charterId: charterIdString } = useParams<CharterIdPathParam>();
  const charterId = parseInt(charterIdString, 10);

  const query = new URLSearchParams(location.search);

  const from = query.get('from') || LINK.CHARTERS.key;

  log.info(`Render Charter users `);
  const fromLink = filter(LINK, (link) => link.key === from)[0];

  useEffect(() => {
    if (!charters || charters.id !== charterId) {
      const cancelTokenSource = APIManager.getCancelToken();
      const lang = LocalUtils.getLang();
      getCharterPermissions(charterId, lang, cancelTokenSource)
        .then((response) => {
          const { items } = response.data;
          dispatch(selectCharterById(charterId, items));
        })
        .catch((e) => {
          log.debug('Error during charter permissions fetch', e);
        });
    }
  }, [charterId, dispatch, charters]);

  if (!charters) {
    return <EmptyPageContent />;
  }

  if (!hasUserAccessToCharter(charterId)) {
    history.push(LINK.UNAUTHORIZED.path);
    return null;
  }

  const routes = [
    {
      path: fromLink.path,
      breadcrumbName: t(fromLink.title),
    },
    {
      path: `/charters/${charters.id}`,
      breadcrumbName: charters.name,
    },
    {
      path: location.pathname,
      breadcrumbName: t('users.title'),
    },
  ];

  const breadcrumbProps: BreadcrumbProps = {
    itemRender: (route: Route) => {
      if (route.path === location.pathname) {
        return route.breadcrumbName;
      }
      return <Link to={route.path}>{route.breadcrumbName}</Link>;
    },
    routes,
  };

  const startHelp = (): void => {
    setRunHelp(true);
  };

  const endHelp = (): void => {
    setRunHelp(false);
  };

  return (
    <>
      <KannellePageHeader
        className="usersPageContainer"
        title={
          <Title id="users" level={3} className={classes.titleWithIcon}>
            {t('users.title')}
            <KannelleHelpButton startHelp={startHelp} />
          </Title>
        }
        breadcrumb={breadcrumbProps}
      >
        <JoyRideHelpUsers runHelp={runHelp} callbackRunDone={endHelp} />

        <Alert
          className={classes.pageDescription}
          message={t('users.pageDescription', { charterName: charters.name })}
        />
        <CharterUsersTable showAction runHelp={runHelp} />
      </KannellePageHeader>
    </>
  );
};

export default CharterUsers;
