import { CheckCircleFilled, EditFilled } from '@ant-design/icons';
import { Button, Form, Input, Tooltip } from 'antd';
import { Store } from 'antd/lib/form/interface';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import OutsideClickHandler from 'react-outside-click-handler';

type Props = {
  name: string;
  value?: string;
  handleSubmitCallback: (newValue: string) => void;
  handleIsEditingCallback?: (isEditing: boolean) => void;
  isDisabled?: boolean;
  requiredMessage?: string;
};

const useStyles = createUseStyles({
  inputContainer: {
    display: 'flex',
    width: 300,
    '& .ant-input, & .ant-form': {
      flexGrow: 1,
    },
    '& .ant-form-item': {
      marginBottom: 0,
    },
    '& .ant-input': {
      borderRadius: '2px 0 0 2px',
    },
    '& .ant-btn': {
      borderRadius: '0 2px 2px 0',
    },
    '& .ant-input-group-addon': {
      border: 'none',
      padding: 0,
      left: -1,
    },
  },
  editableCell: {
    padding: 0,
  },
  editIcon: {
    marginLeft: 8,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
});

const EditableText: FunctionComponent<Props> = ({
  name,
  value = '',
  handleSubmitCallback,
  handleIsEditingCallback,
  isDisabled = false,
  requiredMessage = '',
}: Props) => {
  const [initialValues, setInitialValues] = useState<Store>();
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  const [form] = Form.useForm();

  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    const formInitialValues = {
      [name]: value,
    };

    setInitialValues(formInitialValues);
    form.setFieldsValue(formInitialValues);
  }, [value, form]);

  const onEditChange = (newValue: boolean) => {
    if (isDisabled) {
      return;
    }

    setIsEditing(newValue);

    handleIsEditingCallback?.(newValue);
  };

  const resetDefaultValue = (): void => {
    onEditChange(false);
    setIsSaveButtonDisabled(true);
  };

  const onSubmit = (): void => {
    form.validateFields().then((): void => {
      const newValue = form.getFieldValue(name)?.trim();

      handleSubmitCallback(newValue);
      form.setFieldsValue({
        [name]: newValue,
      });
      resetDefaultValue();
    });
  };

  const onCancel = (): void => {
    form.resetFields();
    resetDefaultValue();
  };

  const onValueChange = () => {
    const newValue = form.getFieldValue(name);
    const isEdited = initialValues && initialValues[name] !== String(newValue);
    const trimmedNewValue = newValue?.trim();

    if (!(isEdited && trimmedNewValue?.length)) {
      setIsSaveButtonDisabled(true);
      return;
    }

    setIsSaveButtonDisabled(false);
  };

  return isEditing ? (
    <div className={classes.inputContainer}>
      <OutsideClickHandler onOutsideClick={onCancel} display="contents">
        <Form form={form} size="small" layout="vertical" initialValues={initialValues} onValuesChange={onValueChange}>
          <Form.Item name={name} required rules={[{ required: true, message: t(requiredMessage) }]}>
            <Input
              onPressEnter={!isSaveButtonDisabled ? onSubmit : undefined}
              addonAfter={
                <Tooltip title={t('global.save')}>
                  <Button onClick={onSubmit} disabled={isSaveButtonDisabled} type="primary" size="small">
                    <CheckCircleFilled />
                  </Button>
                </Tooltip>
              }
            />
          </Form.Item>
        </Form>
      </OutsideClickHandler>
    </div>
  ) : (
    <div className={classes.editableCell}>
      {value}
      <Tooltip title={t('global.edit')}>
        <EditFilled
          className={classes.editIcon}
          hidden={isEditing}
          onClick={() => onEditChange(true)}
          disabled={isDisabled}
        />
      </Tooltip>
    </div>
  );
};

export default EditableText;
