import { ColumnProps } from 'antd/lib/table';
import Fuse from 'fuse.js';
import { TFunction } from 'i18next';
import { findIndex, uniq } from 'lodash';
import React, { ReactNode } from 'react';
import FilterIcon from '../../components/table/search-in-column-filter/FilterIcon';
import SearchInColumnFilter from '../../components/table/search-in-column-filter/SearchInColumnFilter';
import {
  APICompanyAnalyticsEventForPeriod,
  APICompanyAnalyticsUserEventsForPeriod,
} from '../../services/api/types/CompaniesServiceTypes';
import AnalyticsUtils from '../../utils/AnalyticsUtils';
import StringUtils from '../../utils/StringUtils';

type BuildColumnsArgs = {
  data: APICompanyAnalyticsUserEventsForPeriod[];
  t: TFunction;
  classes: Record<string, string>;
  filter: Record<string, string> | undefined;
  setFilter: React.Dispatch<React.SetStateAction<Record<string, string> | undefined>>;
  isMobileOrTablet: boolean;
};

export const buildColumns = ({
  data,
  t,
  classes,
  filter,
  setFilter,
  isMobileOrTablet,
}: BuildColumnsArgs): ColumnProps<APICompanyAnalyticsUserEventsForPeriod>[] => {
  const months = data && data[0] && AnalyticsUtils.getMonthsAsStringsFromUserEvents(t, data[0]);
  const fuseUserName = new Fuse(data, {
    keys: ['username'],
    threshold: 0.35,
  });

  return [
    {
      title: t('analytics.user'),
      key: 'user',
      fixed: !isMobileOrTablet ? 'left' : undefined,
      width: 250,
      render: (user: any) => {
        const { username: name, userEmail: email } = user;
        if (!(name || email)) {
          return <div>{user.userId}</div>;
        }

        return (
          <div>
            {name && <div>{name}</div>}
            {email && <div className={classes.email}>{email}</div>}
          </div>
        );
      },
      filterDropdown: (filterProps): ReactNode => {
        const possibleEmails = data?.map((u) => u.userEmail);
        const possibleUserNames = data?.map((u) => u.username);
        const possibleValues = uniq([...possibleEmails, ...possibleUserNames]);

        return (
          <SearchInColumnFilter
            {...filterProps}
            dataIndex="username"
            fuse={fuseUserName}
            filter={filter}
            setFilter={setFilter}
            placeholder={t('analytics.searchOnColumnPlaceholder')}
            possibleValues={possibleValues}
          />
        );
      },
      filterIcon: FilterIcon,
      onFilter: (value, record: APICompanyAnalyticsUserEventsForPeriod): boolean => {
        const resultFuseName = fuseUserName.search(value as any);
        const indexId = findIndex(resultFuseName, ({ item }) => {
          return item.userId === record.userId;
        });

        // Exact match on the email, or fuzzy search on the user name
        return (
          StringUtils.normalize(record.userEmail.toString()).includes(StringUtils.normalize(value.toString())) ||
          indexId >= 0
        );
      },
    },
    ...(months && months.length > 0
      ? [
          ...months.map((month) => ({
            title: month.title,
            dataIndex: 'eventsForPeriod',
            key: `month_${month.title}`,
            render: (userEventsForPeriods: APICompanyAnalyticsEventForPeriod[]) => {
              const eventForPeriod = AnalyticsUtils.findUserEventForPeriod(
                userEventsForPeriods,
                month.month,
                month.year
              );
              return eventForPeriod?.count ?? 0;
            },
            sorter: (userA: APICompanyAnalyticsUserEventsForPeriod, userB: APICompanyAnalyticsUserEventsForPeriod) => {
              const userAEventCount =
                AnalyticsUtils.findUserEventForPeriod(userA.eventsForPeriod, month.month, month.year)?.count ?? 0;

              const userBEventCount =
                AnalyticsUtils.findUserEventForPeriod(userB.eventsForPeriod, month.month, month.year)?.count ?? 0;

              return userBEventCount - userAEventCount;
            },
            width: 100,
          })),
        ]
      : []),
    {
      title: t('analytics.total'),
      dataIndex: 'eventsForPeriod',
      key: 'user_total',
      fixed: !isMobileOrTablet ? 'right' : undefined,
      render: (userEventsForPeriods: APICompanyAnalyticsEventForPeriod[]) => {
        return AnalyticsUtils.calculateTotalEventsForPeriod(userEventsForPeriods);
      },
      sorter: (userA: APICompanyAnalyticsUserEventsForPeriod, userB: APICompanyAnalyticsUserEventsForPeriod) => {
        const userATotal = AnalyticsUtils.calculateTotalEventsForPeriod(userA.eventsForPeriod);
        const userBTotal = AnalyticsUtils.calculateTotalEventsForPeriod(userB.eventsForPeriod);

        return userBTotal - userATotal;
      },
      width: 100,
      className: classes.totalColumn,
    },
  ];
};
