import { UploadFile } from 'antd/lib/upload/interface';
import { cloneDeep, findIndex } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LINK, WEB_SOCKET_ACTIONS } from '../Constants';
import { pushNotification } from '../redux/action/AppAction';
import {
  fileUploadedProcessed,
  mediaFileUploadedProcessed,
  setChartersList,
  setFileProcessingInError,
  setMediaFileProcessingInError,
  updateAsset,
  updateCharterPermissions,
  updateMedia,
} from '../redux/action/ChartersAction';
import { RootState } from '../redux/RootState';
import { SocketManager } from '../services/api/SocketManager';
import { Asset, AssetType, MediaFile } from '../services/api/types/ChartersServiceTypes';
import { AssetOrMediaProcessingResponse, PermissionUpdateResponse } from '../services/api/types/WebSocketTypes';
import CharterUtils from '../utils/CharterUtils';
import MediaUtils from '../utils/MediaUtils';
import NotificationUtils from '../utils/NotificationUtils';
import { MediaType } from '../utils/types/MediaTypes';
import { Notification } from '../utils/types/NotificationTypes';
import WebSocketService from '../utils/WebSocketService';
import useSocket from './useSocket';

const useCharterGlobalSocket = (): SocketManager | undefined => {
  const [rooms, setRooms] = useState<string[]>();
  const isSocketConnected = useSocket(rooms);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const processingAssets = useSelector((state: RootState) => state.charters.processingAssets);
  const chartersList = useSelector((state: RootState) => state.charters.list);
  const currentCharterPermissions = useSelector((state: RootState) => state.charters.currentCharterPermissions);
  const processingMedia = useSelector((state: RootState) => state.charters.processingMedia);

  useEffect(() => {
    if (chartersList && chartersList.length > 0) {
      const roomsToJoin: string[] = [];
      chartersList.forEach((charter) => {
        roomsToJoin.push(`charters/${charter.id}/global`);
        roomsToJoin.push(`charters/${charter.id}/permissions`);
      });
      setRooms(roomsToJoin);
    }
  }, [chartersList]);

  useEffect(() => {
    if (!chartersList) {
      return undefined;
    }

    // Asset updated message (archived/unarchived, etc.)
    const handleAssetUpdatedMessage = (payload: AssetOrMediaProcessingResponse): void => {
      const assetPayload = payload.data as Asset;
      const decodedAssetType = payload.action.split('_')[0];
      const processedAssetType = decodedAssetType as AssetType;
      const updatedCharterId = WebSocketService.getCharterIdFromRoomName(payload.roomName);
      const globalRoomName = `charters/${updatedCharterId}/global`;

      // If the message was not received on the global room, ignore it
      if (payload.roomName !== globalRoomName) {
        return;
      }

      dispatch(updateAsset(assetPayload, processedAssetType));
    };

    // Asset processed message (success or error)
    const handleAssetProcessedMessage = (payload: AssetOrMediaProcessingResponse, isError: boolean): void => {
      const assetPayload = payload.data as Asset;
      const decodedAssetType = payload.action.split('_')[0];
      const processedAssetType = decodedAssetType as AssetType;
      const updatedCharterId = WebSocketService.getCharterIdFromRoomName(payload.roomName);
      const globalRoomName = `charters/${updatedCharterId}/global`;

      // If the message was not received on the global room, ignore it
      if (payload.roomName !== globalRoomName) {
        return;
      }

      if (isError) {
        const uploadFiles = CharterUtils.getUploadFilesByType(processedAssetType, processingAssets);
        const processingAssetIndex = findIndex(uploadFiles, (uploadFile: UploadFile) => {
          return uploadFile.response.data.operationId === assetPayload.operationId;
        });
        // If the asset for which the WS was received does not appear in the processing assets of the client, we do nothing
        // Indeed, the error might concern another client in the room
        if (processingAssetIndex >= 0) {
          dispatch(setFileProcessingInError(assetPayload, processedAssetType, payload.error!.code));
        } else {
          return;
        }
      } else {
        dispatch(fileUploadedProcessed(assetPayload, processedAssetType));
      }

      // Push the notification
      const assetPageUrl = `/charters/${updatedCharterId}/elements/${CharterUtils.getCharterAssetsNameByType(
        processedAssetType
      )}`;
      const currentLocation = history.location.pathname;
      // If the user is already on the page where the asset is processed or in error, we do not send a notification
      // We use `startsWith` here to ignore optional query params in the url
      if (!updatedCharterId || currentLocation.startsWith(assetPageUrl)) {
        return;
      }

      const charterName = CharterUtils.getCharterByIdFromList(chartersList, parseInt(updatedCharterId, 10))?.name;
      const message = (
        <Trans
          i18nKey={`charters.${processedAssetType}.${
            isError ? 'processErrorNotification' : 'processSuccessNotification'
          }`}
          values={{ charterName }}
          components={{ bold: <strong /> }}
        />
      );
      const notification: Notification = {
        id: NotificationUtils.generateNotificationId(),
        type: isError ? 'error' : 'success',
        creationDate: moment(),
        message,
        onClick: () => history.push(assetPageUrl),
      };
      dispatch(pushNotification(notification));
    };

    // Media updated message (archived/unarchived, etc.)
    const handleMediaUpdatedMessage = (payload: AssetOrMediaProcessingResponse): void => {
      const mediaPayload = payload.data as MediaFile;
      const updatedCharterId = WebSocketService.getCharterIdFromRoomName(payload.roomName);
      const globalRoomName = `charters/${updatedCharterId}/global`;

      // If the message was not received on the global room, ignore it
      if (payload.roomName !== globalRoomName) {
        return;
      }

      dispatch(updateMedia(mediaPayload));
    };

    const handleMediaProcessedMessage = (payload: AssetOrMediaProcessingResponse, isError: boolean): void => {
      const mediaPayload = payload.data as MediaFile;
      const processedMediaType = mediaPayload.mediaType;
      const updatedCharterId = WebSocketService.getCharterIdFromRoomName(payload.roomName);
      const globalRoomName = `charters/${updatedCharterId}/global`;

      // If the message was not received on the global room, ignore it
      if (payload.roomName !== globalRoomName) {
        return;
      }

      if (isError) {
        const uploadFiles = processingMedia[processedMediaType];
        const processingMediaIndex = MediaUtils.findProcessingMediaIndexByOperationId(
          uploadFiles,
          mediaPayload.operationId
        );
        // If the media for which the WS was received does not appear in the processing media of the client, we do nothing
        // Indeed, the error might concern another client in the room
        if (processingMediaIndex >= 0) {
          dispatch(setMediaFileProcessingInError(mediaPayload, processedMediaType, payload.error!.code));
        } else {
          return;
        }
      } else {
        dispatch(mediaFileUploadedProcessed(mediaPayload, processedMediaType));
      }

      // Push the notification
      const mediaPageUrl = `/charters/${updatedCharterId}/elements/media-library`;
      const scenarioPageUrl = `/charters/${updatedCharterId}/scenarios`;
      const projectEditorPageUrl = `/charters/${updatedCharterId}/projects/editor`;
      const currentLocation = history.location.pathname;
      // If the user is already on the media library page (or one scenario page or subpage, or the project editor) where the media is processed or in error, we do not send a notification
      // We use `startsWith` here to ignore optional query params in the url
      if (
        !updatedCharterId ||
        currentLocation.startsWith(mediaPageUrl) ||
        currentLocation.startsWith(scenarioPageUrl) ||
        currentLocation.startsWith(projectEditorPageUrl)
      ) {
        return;
      }

      const charterName = CharterUtils.getCharterByIdFromList(chartersList, parseInt(updatedCharterId, 10))?.name;
      const message = (
        <Trans
          i18nKey={`charters.mediaLibrary.${processedMediaType === MediaType.IMAGE ? 'images' : 'videos'}.${
            isError ? 'processErrorNotification' : 'processSuccessNotification'
          }`}
          values={{ charterName }}
          components={{ bold: <strong /> }}
        />
      );
      const notification: Notification = {
        id: NotificationUtils.generateNotificationId(),
        type: isError ? 'error' : 'success',
        creationDate: moment(),
        message,
        onClick: () => history.push(`${mediaPageUrl}?activeTab=${processedMediaType}`),
      };
      dispatch(pushNotification(notification));
    };

    // Font processed message
    const handleFontProcessedMessage = (payload: AssetOrMediaProcessingResponse): void => {
      const updatedCharterId = WebSocketService.getCharterIdFromRoomName(payload.roomName);
      const fontPageUrl = `/charters/${updatedCharterId}/elements/fonts`;
      const currentLocation = history.location.pathname;
      const globalRoomName = `charters/${updatedCharterId}/global`;

      // If the message was not received on the global room, ignore it
      if (payload.roomName !== globalRoomName) {
        return;
      }

      // If the user is already on the page where the font is processed, we do not send a notification.
      // We use `startsWith` here to ignore optional query params in the url
      if (!updatedCharterId || currentLocation.startsWith(fontPageUrl)) {
        return;
      }
      const notification: Notification = {
        id: NotificationUtils.generateNotificationId(),
        type: 'success',
        creationDate: moment(),
        message: t(`charters.fonts.processSuccessNotification`, {
          charterName: CharterUtils.getCharterByIdFromList(chartersList, parseInt(updatedCharterId, 10))?.name,
        }),
        onClick: () => history.push(`${fontPageUrl}?activeTab=charter_fonts`),
      };
      dispatch(pushNotification(notification));
    };

    // Update permissions at company level ('WEB' section permissions)
    const handleUpdatedWebPermission = (payload: PermissionUpdateResponse) => {
      const updatedPermission = payload.data;
      const updatedCharterId = WebSocketService.getCharterIdFromRoomName(payload.roomName);

      if (!(updatedCharterId && chartersList)) {
        return;
      }

      const updatedChartersList = cloneDeep(chartersList);
      const updatedCharterIndex = updatedChartersList.findIndex(
        (charter) => charter.id === parseInt(updatedCharterId, 10)
      );
      const companyPermissionIndex = updatedChartersList[updatedCharterIndex].currentUser?.permissions.findIndex(
        (permission) => {
          return permission.code === updatedPermission.code;
        }
      );
      const isRolePresent =
        updatedChartersList[updatedCharterIndex].currentUser?.role.toUpperCase() === updatedPermission.role;

      if (isRolePresent && companyPermissionIndex >= 0) {
        updatedChartersList[updatedCharterIndex].currentUser.permissions[companyPermissionIndex] = updatedPermission;
        dispatch(setChartersList(updatedChartersList));

        if (!CharterUtils.hasAccessibleCharter(updatedChartersList)) {
          history.push(LINK.UNAUTHORIZED.path);
        }
      }
    };

    // Update permissions at charter level (any permissions)
    const handleAllUpdatedPermissions = (payload: PermissionUpdateResponse) => {
      const updatedPermission = payload.data;

      if (!currentCharterPermissions) {
        return;
      }

      const updatedCharterPermissions = cloneDeep(currentCharterPermissions);
      const charterPermissionIndex = updatedCharterPermissions.findIndex(
        (permission) => permission.code === updatedPermission.code
      );
      const charterPermissionRoleIndex = updatedCharterPermissions[charterPermissionIndex]?.roles.findIndex(
        (role) => role.code === updatedPermission.role
      );

      if (charterPermissionIndex >= 0 && charterPermissionRoleIndex >= 0) {
        updatedCharterPermissions[charterPermissionIndex].roles[charterPermissionRoleIndex].hasAccess.access =
          updatedPermission.access;
        updatedCharterPermissions[charterPermissionIndex].roles[charterPermissionRoleIndex].hasAccess.value =
          updatedPermission.value;
        dispatch(updateCharterPermissions(updatedCharterPermissions));
      }
    };

    const handleMessage = (payload: AssetOrMediaProcessingResponse | PermissionUpdateResponse): void => {
      if (payload.action === 'permission_patch') {
        const permissionPayload = payload as PermissionUpdateResponse;
        const permissionPayloadSection = permissionPayload.data.section;

        if (permissionPayloadSection === 'WEB') {
          handleUpdatedWebPermission(permissionPayload);
          return;
        }

        handleAllUpdatedPermissions(permissionPayload);
        return;
      }

      const isAssetMessage = WEB_SOCKET_ACTIONS.CHARTER_GLOBAL_ASSETS.includes(payload.action);
      const isFontMessage = WEB_SOCKET_ACTIONS.CHARTER_GLOBAL_FONT.includes(payload.action);
      const isMediaMessage = WEB_SOCKET_ACTIONS.CHARTER_GLOBAL_MEDIA.includes(payload.action);
      const assetOrMediaPayload = payload as AssetOrMediaProcessingResponse;

      if (isAssetMessage) {
        if (payload.action.includes('patch')) {
          handleAssetUpdatedMessage(assetOrMediaPayload);
        } else {
          const isError = !!assetOrMediaPayload.error && assetOrMediaPayload.data.status === 'ERROR';
          handleAssetProcessedMessage(assetOrMediaPayload, isError);
        }
      } else if (isMediaMessage) {
        if (payload.action.includes('patch')) {
          handleMediaUpdatedMessage(assetOrMediaPayload);
        } else {
          const isError = !!assetOrMediaPayload.error && assetOrMediaPayload.data.status === 'ERROR';
          handleMediaProcessedMessage(assetOrMediaPayload, isError);
        }
      } else if (isFontMessage) {
        handleFontProcessedMessage(assetOrMediaPayload);
      }
    };

    if (isSocketConnected) {
      SocketManager.onMessage(handleMessage);

      return (): void => SocketManager.offMessage(handleMessage);
    }

    return undefined;
  }, [
    isSocketConnected,
    chartersList,
    processingAssets,
    dispatch,
    t,
    history,
    processingMedia,
    currentCharterPermissions,
  ]);

  return isSocketConnected;
};

export default useCharterGlobalSocket;
