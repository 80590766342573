import { CancelTokenSource } from 'axios';
import { APIManager } from './APIManager';
import { APIGetCompaniesResult } from './types/CompaniesServiceTypes';
import {
  APICreateUserParams,
  APICreateUserResult,
  APIGetNotificationsForUserByIdResponse,
  APIGetUserResult,
  APIModelUser,
} from './types/UsersServiceTypes';

const getUserById = (userId: string): Promise<APIGetUserResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(`/users/${userId}`);
};

const getUserMe = (): Promise<APIGetUserResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(`/users/me`);
};

const getUserCompanies = (
  userId: string,
  isContact = false,
  cancelSource?: CancelTokenSource
): Promise<APIGetCompaniesResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(`/users/${userId}/companies${isContact && '?isContact=true'}`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const getUserByEmail = (email: string, cancelSource?: CancelTokenSource): Promise<APIGetUserResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(`/users?email=${email}`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const patchUser = (
  userId: string,
  userData: Partial<APIModelUser>,
  cancelSource?: CancelTokenSource
): Promise<APIGetUserResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.patch(`/users/${userId}`, userData, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const createUser = (data: APICreateUserParams, cancelSource?: CancelTokenSource): Promise<APICreateUserResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.post(`/users`, data, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const getNotificationsForUserById = (
  id: string,
  lang = 'en',
  page = '0',
  cancelSource?: CancelTokenSource
): Promise<APIGetNotificationsForUserByIdResponse> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(`/users/${id}/notifications?lang=${lang}&page=${page}`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const postVerifyUserEmailById = (
  id: string,
  cancelSource?: CancelTokenSource
): Promise<APIGetNotificationsForUserByIdResponse> => {
  const apiManager = APIManager.getInstance();

  return apiManager.post(`/users/${id}/email-verification`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

export {
  getUserById,
  getUserMe,
  getUserByEmail,
  createUser,
  getNotificationsForUserById,
  patchUser,
  getUserCompanies,
  postVerifyUserEmailById,
};
