import { Button, Menu, Slider } from 'antd';
import { SliderMarks } from 'antd/lib/slider';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import mapValues from 'lodash/mapValues';
import max from 'lodash/max';
import min from 'lodash/min';
import omit from 'lodash/omit';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { CHARTER_USER } from '../../../../Constants';
import TimeUtils from '../../../../utils/TimeUtils';

type Props = {
  dataIndex: string;
  setFilter: React.Dispatch<React.SetStateAction<Record<string, string> | undefined>>;
} & FilterDropdownProps;

const useStyles = createUseStyles({
  mainContainer: {
    minWidth: '20rem',
    padding: 0,
    '& .ant-menu-item:active': {
      background: 'unset !important',
    },
  },
  slider: {
    marginLeft: '20px',
    marginRight: '20px',
  },
  input: {
    marginBottom: 8,
    display: 'block',
  },
  actionsContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    lineHeight: 'unset !important',
    height: 'unset !important',
    justifyContent: 'space-between',
    padding: '7px 8px 7px 3px !important',
    marginTop: '0px !important',
    marginBottom: '0px !important',
  },
});

const SliderColumnFilter: FunctionComponent<Props> = ({
  confirm,
  clearFilters,
  dataIndex,
  selectedKeys,
  setSelectedKeys,
  setFilter,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [minValue, setMinValue] = useState<number>();
  const [maxValue, setMaxValue] = useState<number>();
  const [marks, setMarks] = useState<SliderMarks>();
  const [rangeLeft, setRangeLeft] = useState<number>();
  const [rangeRight, setRangeRight] = useState<number>();

  useEffect(() => {
    // Initialize the slider marks with a translated step text
    const { MARKS } = CHARTER_USER.SUBTITLES_CONSUMPTION_FILTER;
    setMarks(mapValues(MARKS, (markValue) => t(`users.subtitlesConsumptionFilter.${markValue.key}`)));
    // Initiate the min/max values using the min/max key from MARKS
    const mininumMark = min(Object.keys(MARKS).map((markValue) => Number(markValue)));
    const maximumMark = max(Object.keys(MARKS).map((markValue) => Number(markValue)));
    setMinValue(mininumMark);
    setMaxValue(maximumMark);
    // Initiate the left/right range using the min/max values if the selectedKeys is undefined
    // or else use the selectedKeys (parse it with '_' since it's a string formatted like `min_max`)
    const [left, right] =
      selectedKeys && selectedKeys[0]
        ? selectedKeys[0]
            .toString()
            .split('_')
            .map((bound) => (bound === '+' ? undefined : Number(bound)))
        : [undefined, undefined];
    setRangeLeft(left ?? mininumMark);
    setRangeRight(right ?? maximumMark);
  }, [t, selectedKeys]);

  const handleReset = (): void => {
    if (clearFilters) {
      clearFilters();
    }
    setFilter((prev) => omit(prev, dataIndex));
  };

  const handleConfirm = (): void => {
    if (rangeLeft !== undefined && rangeRight !== undefined) {
      if (rangeLeft === minValue && rangeRight === maxValue) {
        handleReset();
      } else {
        setFilter((prev) => ({ ...prev, [dataIndex]: selectedKeys.toString() }));
      }
    }
    confirm();
  };

  const formatTooltip = (seconds?: number): string => {
    if (!seconds) {
      return '';
    }

    const tooltip = TimeUtils.formatSecondsIntoHumanTimeString(seconds, t);
    if (seconds === maxValue) {
      return `${tooltip} ${t('users.subtitlesConsumptionFilter.andMore')}`;
    }

    return tooltip;
  };

  const onSliderChange = (e: number[]): void => {
    if (!Array.isArray(e)) {
      return;
    }
    setRangeLeft(e[0]);
    setRangeRight(e[1]);
    if (e[1] === maxValue) {
      setSelectedKeys([`${e[0]}_+`]);
    } else {
      setSelectedKeys([`${e[0]}_${e[1]}`]);
    }
  };

  if ([rangeLeft, rangeRight, minValue, maxValue].some((v) => v === undefined) || !marks || !selectedKeys) {
    return null;
  }

  return (
    <Menu className={classes.mainContainer} selectable={false}>
      <Menu.Item>
        <Slider
          range
          value={[rangeLeft!, rangeRight!]}
          onChange={onSliderChange}
          marks={marks}
          min={minValue}
          max={maxValue}
          step={300}
          tipFormatter={formatTooltip}
          className={classes.slider}
        />
      </Menu.Item>

      <Menu.Divider />

      <Menu.Item className={classes.actionsContainer}>
        <Button
          onClick={handleReset}
          size="small"
          type="link"
          disabled={rangeLeft === minValue && rangeRight === maxValue}
        >
          {t('global.reset')}
        </Button>
        <Button type="primary" onClick={handleConfirm} size="small">
          {t('global.apply')}
        </Button>
      </Menu.Item>
    </Menu>
  );
};

export default SliderColumnFilter;
