import { CancelTokenSource } from 'axios';
import { CHARTER_ACTIVE } from '../../Constants';
import { APIManager } from './APIManager';
import { APIGetCompanyBundleResult } from './types/BundlesServiceTypes';
import {
  APICloneCompanyResult,
  APICompanyAnalyticsResponse,
  APICompanyAnalyticsStartingDateResponse,
  APICompanyBundleParams,
  APICompanyUsersResult,
  APICreateCompanyCharterParams,
  APICreateCompanyCharterResponse,
  APICreateCompanyParams,
  APICreateCompanyResult,
  APIGetCompaniesResult,
  APIGetCompanyByIdResult,
  APIGetCompanyContactsResult,
  APIGetCompanyNotificationConfigsResult,
  APIGetCompanyNotificationParametersResult,
  APIGetCompanyStatsResult,
  APIPatchCompanyNotificationConfigsData,
  APIPatchCompanyNotificationParametersData,
  APIPatchCompanyNotificationParametersResult,
  APIPatchCompanyParams,
} from './types/CompaniesServiceTypes';

const getCompaniesByUser = (
  userId: string,
  isActiveCharters = CHARTER_ACTIVE.ACTIVE,
  cancelSource?: CancelTokenSource,
  options?: { lang: string }
): Promise<APIGetCompaniesResult> => {
  const apiManager = APIManager.getInstance();
  const lang = options?.lang ?? 'en';

  return apiManager.get(
    `/users/${userId}/charters?type=enterprise&isActive=${isActiveCharters}&permissionSections=WEB&lang=${lang}`,
    {
      cancelToken: cancelSource?.token || undefined,
    }
  );
};

const getCompanyStatsById = (
  companyId: number,
  cancelSource?: CancelTokenSource
): Promise<APIGetCompanyStatsResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(`/companies/${companyId}/stats`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const createCompany = (
  data: APICreateCompanyParams,
  cancelSource?: CancelTokenSource
): Promise<APICreateCompanyResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.post(`/companies`, data, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const patchCompany = (
  companyId: number,
  data: Partial<APIPatchCompanyParams>,
  cancelSource?: CancelTokenSource
): Promise<APICreateCompanyResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.patch(`/companies/${companyId}`, data, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const cloneCompany = (companyId: number, cancelSource?: CancelTokenSource): Promise<APICloneCompanyResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.post(`/companies/${companyId}/clone`, null, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const createCharterInCompany = (
  companyId: number,
  charterData: APICreateCompanyCharterParams,
  cancelSource?: CancelTokenSource
): Promise<APICreateCompanyCharterResponse> => {
  const apiManager = APIManager.getInstance();

  return apiManager.post(`/companies/${companyId}/charters/`, charterData, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const getCompanyNotificationConfigs = (
  companyId: number,
  lang = 'en',
  cancelSource?: CancelTokenSource
): Promise<APIGetCompanyNotificationConfigsResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(`/companies/${companyId}/notificationconfigs?lang=${lang}`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const updateCompanyNotificationConfigs = (
  companyId: number,
  notificationConfigId: number,
  data: APIPatchCompanyNotificationConfigsData,
  cancelSource?: CancelTokenSource
): Promise<any> => {
  const apiManager = APIManager.getInstance();

  return apiManager.patch(`/companies/${companyId}/notificationconfigs/${notificationConfigId}`, data, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const getCompanyNotificationParameters = (
  companyId: number,
  cancelSource?: CancelTokenSource
): Promise<APIGetCompanyNotificationParametersResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(`/companies/${companyId}/notificationsettings`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const getCompanyContacts = (
  companyId: number,
  cancelSource?: CancelTokenSource
): Promise<APIGetCompanyContactsResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(`/companies/${companyId}/contacts`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const getCompanyById = (companyId: number, cancelSource?: CancelTokenSource): Promise<APIGetCompanyByIdResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(`/companies/${companyId}`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const updateCompanyNotificationParameters = (
  companyId: number,
  data: APIPatchCompanyNotificationParametersData,
  cancelSource?: CancelTokenSource
): Promise<APIPatchCompanyNotificationParametersResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.patch(`/companies/${companyId}/notificationsettings`, data, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const getCompanyUsers = (companyId: number, cancelSource?: CancelTokenSource): Promise<APICompanyUsersResult> => {
  const apiManager = APIManager.getInstance();
  return apiManager.get(`/companies/${companyId}/users`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const getCompanyAnalytics = (
  companyId: number,
  startDate: string,
  endDate: string,
  excludeKnlTeam: boolean,
  cancelSource?: CancelTokenSource
): Promise<APICompanyAnalyticsResponse> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(
    `/companies/${companyId}/analytics?startDate=${startDate}&endDate=${endDate}&excludeKnlTeam=${excludeKnlTeam}`,
    {
      cancelToken: cancelSource?.token || undefined,
    }
  );
};

const getCompanyAnalyticsStartingDate = (
  companyId: number,
  excludeKnlTeam: boolean,
  cancelSource?: CancelTokenSource
): Promise<APICompanyAnalyticsStartingDateResponse> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(`/companies/${companyId}/analytics/starting-date?excludeKnlTeam=${excludeKnlTeam}`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const getCompanyBundleById = (
  companyId: number,
  cancelSource?: CancelTokenSource
): Promise<APIGetCompanyBundleResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(`/companies/${companyId}/bundles`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const postCompanyBundleById = (
  companyId: number,
  data: APICompanyBundleParams,
  cancelSource?: CancelTokenSource
): Promise<APIGetCompanyBundleResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.post(`/companies/${companyId}/bundles`, data, {
    cancelToken: cancelSource?.token || undefined,
  });
};

export {
  getCompaniesByUser,
  getCompanyStatsById,
  createCompany,
  patchCompany,
  cloneCompany,
  createCharterInCompany,
  getCompanyNotificationConfigs,
  updateCompanyNotificationConfigs,
  getCompanyNotificationParameters,
  updateCompanyNotificationParameters,
  getCompanyUsers,
  getCompanyAnalytics,
  getCompanyContacts,
  getCompanyAnalyticsStartingDate,
  getCompanyById,
  getCompanyBundleById,
  postCompanyBundleById,
};
