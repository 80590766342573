import { UploadFile } from 'antd/lib/upload/interface';
import { findIndex } from 'lodash';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { MediaSize, Point } from 'react-easy-crop/types';
import i18n from '../i18n';
import { MediaCroppedArea, MediaFile } from '../services/api/types/ChartersServiceTypes';
import { ExtendedVideoMediaType, MediaType } from './types/MediaTypes';

export default class MediaUtils {
  static getImageMimeTypeList(): string[] {
    return ['image/jpg', 'image/jpeg', 'image/x-png', 'image/png'];
  }

  static getVideoMimeTypeList(): string[] {
    return ['video/mpeg', 'video/3gpp', 'video/mp4', 'video/quicktime'];
  }

  static getMediaMimeTypeList(): string[] {
    return MediaUtils.getImageMimeTypeList().concat(MediaUtils.getVideoMimeTypeList());
  }

  static getMediaMimeTypeListByMediaTypeList(mediaTypes: MediaType[]): string[] {
    let mimeTypes: string[] = [];
    if (mediaTypes.includes(MediaType.IMAGE)) {
      mimeTypes = [...mimeTypes, ...MediaUtils.getImageMimeTypeList()];
    }

    if (mediaTypes.includes(MediaType.VIDEO)) {
      mimeTypes = [...mimeTypes, ...MediaUtils.getVideoMimeTypeList()];
    }

    return mimeTypes;
  }

  static determineMediaTypeFromMimeType(mimeType: string): MediaType {
    if (MediaUtils.getVideoMimeTypeList().includes(mimeType)) {
      return MediaType.VIDEO;
    }
    return MediaType.IMAGE;
  }

  static findProcessingMediaIndexByOperationId = (processingFiles: UploadFile[], operationId: string): number => {
    return findIndex(processingFiles, (uploadFile: UploadFile) => {
      return uploadFile.response.data.operationId === operationId;
    });
  };

  static getProcessingMediaOfTypeAfterMediaUploadedProcessing = (
    baseProcessingMedia: {
      [key: string]: UploadFile[];
    },
    processingMediaType: MediaType,
    uploadedFile: UploadFile
  ): UploadFile[] => {
    const processingMediaOfType = baseProcessingMedia[processingMediaType];
    const processingMediaIndex = MediaUtils.findProcessingMediaIndexByOperationId(
      processingMediaOfType,
      uploadedFile.response.data.operationId
    );
    // If the media was already in processing, it was in error, we reset it
    if (processingMediaIndex >= 0) {
      processingMediaOfType[processingMediaIndex].response.data.error = undefined;
    } else {
      // Else a new file has been uploaded, we add it to the processing media
      processingMediaOfType.push(uploadedFile);
    }

    return processingMediaOfType;
  };

  static removeProcessedMediaFromProcessingList = (
    baseProcessingMediaOfType: UploadFile[],
    mediaList: MediaFile[]
  ): UploadFile[] => {
    const processingMediaOfType = baseProcessingMediaOfType;
    mediaList.forEach((media: MediaFile) => {
      const processingMediaIndex = MediaUtils.findProcessingMediaIndexByOperationId(
        processingMediaOfType,
        media.operationId
      );
      // If the media is found in the processing list but was also present in the media list (processed media), we remove it from the processing list
      if (processingMediaIndex >= 0) {
        processingMediaOfType.splice(processingMediaIndex, 1);
      }
    });
    return processingMediaOfType;
  };

  static getFormatOperands = (format: string): { numerator: number; denominator: number } => {
    const [numerator, denominator] = format.split(':').map((i) => parseInt(i, 10));
    return { numerator, denominator };
  };

  static transformFormatToAspectRatio = (format: string): number => {
    const { numerator, denominator } = MediaUtils.getFormatOperands(format);
    return numerator / denominator;
  };

  static validateCroppedAreaForFormat = (
    inputCroppedArea: MediaCroppedArea,
    format: string,
    mediaToResize: MediaFile
  ): MediaCroppedArea => {
    const { numerator, denominator } = MediaUtils.getFormatOperands(format);
    const ratio = MediaUtils.transformFormatToAspectRatio(format);

    const { x, y, height, width } = inputCroppedArea;

    const validatedCroppedArea = { ...inputCroppedArea };

    const isCroppedAreaToLargeOrHighForMedia =
      mediaToResize.height &&
      mediaToResize.width &&
      (y + height > mediaToResize.height || x + width > mediaToResize.width);

    if (
      validatedCroppedArea.width / validatedCroppedArea.height === ratio &&
      Number.isInteger(validatedCroppedArea.width) &&
      Number.isInteger(validatedCroppedArea.height) &&
      !isCroppedAreaToLargeOrHighForMedia
    ) {
      return validatedCroppedArea;
    }

    let closestDivisibleWidthRatio = 0;
    if (validatedCroppedArea.width >= validatedCroppedArea.height) {
      const referenceWidth = mediaToResize.width
        ? Math.min(validatedCroppedArea.width, mediaToResize.width)
        : validatedCroppedArea.width;
      closestDivisibleWidthRatio = Math.floor(referenceWidth / numerator);
    } else {
      const referenceHeight = mediaToResize.height
        ? Math.min(validatedCroppedArea.height, mediaToResize.height)
        : validatedCroppedArea.height;
      closestDivisibleWidthRatio = Math.floor(referenceHeight / denominator);
    }

    const newWidth = closestDivisibleWidthRatio * numerator;

    return {
      ...validatedCroppedArea,
      width: newWidth,
      height: newWidth / ratio,
    };
  };

  static getMediaUrl = (mediaFile: MediaFile): string => {
    if (mediaFile.mediaType === MediaType.IMAGE) {
      return mediaFile.croppedFileUrl ?? mediaFile.url;
    }
    return mediaFile.croppedHlsFileUrl ?? mediaFile.croppedFileUrl ?? mediaFile.hlsUrl ?? mediaFile.url;
  };

  static checkIfMediaHasCroppedArea = (mediaFile: MediaFile, croppedArea: MediaCroppedArea): boolean => {
    return isEqual(mediaFile.croppedArea, croppedArea);
  };

  // Generates a blob object corresponding to the cropped `imageSource` based on the `croppedArea`
  static async getCroppedImg(imageSrc: string, croppedArea: MediaCroppedArea): Promise<string | undefined> {
    const createImage = (url: string): Promise<HTMLImageElement> =>
      new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
        image.src = `${url}#${Date.now()}`; // To prevent cache from being used (which causes CORS errors)
      });

    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return undefined;
    }

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));
    canvas.width = safeArea;
    canvas.height = safeArea;

    // Draw image in canvas context and store data
    ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // Set canvas width to final desired crop size - this will clear existing context
    canvas.width = croppedArea.width;
    canvas.height = croppedArea.height;

    // Paste generated image with correct offsets for x,y crop values
    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - croppedArea.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - croppedArea.y)
    );

    // As a blob
    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve(URL.createObjectURL(file));
      }, 'image/jpeg');
    });
  }

  // Compute the zoom from the croppedArea
  static getZoomFromCroppedArea = (croppedArea: MediaCroppedArea, mediaSize: MediaSize): number => {
    const aspect = croppedArea.width / croppedArea.height;
    const isHeightMaxSize = mediaSize.naturalWidth >= mediaSize.naturalHeight * aspect;
    return isHeightMaxSize ? mediaSize.naturalHeight / croppedArea.height : mediaSize.naturalWidth / croppedArea.width;
  };

  // Compute the crop and zoom from the croppedArea
  static getInitialCropFromCroppedArea = (
    croppedArea: MediaCroppedArea,
    mediaSize: MediaSize
  ): { crop: Point; zoom: number } => {
    const mediaZoom = mediaSize.width / mediaSize.naturalWidth;

    const zoom = MediaUtils.getZoomFromCroppedArea(croppedArea, mediaSize);

    const cropZoom = mediaZoom * zoom;

    const crop = {
      x: ((mediaSize.naturalWidth - croppedArea.width) / 2 - croppedArea.x) * cropZoom,
      y: ((mediaSize.naturalHeight - croppedArea.height) / 2 - croppedArea.y) * cropZoom,
    };
    return { crop, zoom };
  };

  // Calculate an automatic croppedArea for a media in the given format (string formatted like '16:9')
  static calculateAutoCroppedAreaForRatio = (media: MediaFile, format: string): MediaCroppedArea | undefined => {
    const [numerator, denominator] = format.split(':');
    const aspectRatio = parseInt(numerator, 10) / parseInt(denominator, 10);

    // If there is no media height/width, we don't know how to crop
    if (!(media.height && media.width)) {
      return undefined;
    }

    let x = 0;
    let y = 0;
    let height;
    let width;
    // The media is landscaped
    if (media.width >= media.height) {
      height = media.height;
      width = Math.round(media.height * aspectRatio);
      x = Math.round((media.width - width) / 2);
    } else {
      // The media is portrait
      width = media.width;
      height = Math.round(media.width / aspectRatio);
      y = Math.round((media.height - height) / 2);
    }

    return {
      x,
      y,
      height,
      width,
    };
  };

  static buildRecordingFileName = (mediaType: MediaType | ExtendedVideoMediaType): string => {
    const baseName =
      // eslint-disable-next-line no-nested-ternary
      mediaType === MediaType.IMAGE
        ? i18n.t('charters.mediaLibrary.webcamRecorder.screenshot')
        : mediaType === ExtendedVideoMediaType.SCREEN_RECORDING
        ? i18n.t('charters.mediaLibrary.webcamRecorder.screenrecording')
        : i18n.t('charters.mediaLibrary.webcamRecorder.recording');
    const extension = mediaType === MediaType.IMAGE ? 'png' : 'mp4';
    const date = moment().format('YYYY-MM-DD_HH-mm-ss');
    return `${baseName}-${date}.${extension}`;
  };

  static getCroppedAreaFromUrl = (url: string): MediaCroppedArea => {
    const splitUrl = url.split('_');

    return {
      x: parseInt(splitUrl[1]?.replace('x', ''), 10),
      y: parseInt(splitUrl[2]?.replace('y', ''), 10),
      height: parseInt(splitUrl[3]?.replace('height', ''), 10),
      width: parseInt(splitUrl[4]?.replace('width', ''), 10),
    };
  };

  static areCroppedAreaEqual = (croppedAreaA?: MediaCroppedArea, croppedAreaB?: MediaCroppedArea): boolean => {
    return (
      croppedAreaA?.x === croppedAreaB?.x &&
      croppedAreaA?.y === croppedAreaB?.y &&
      croppedAreaA?.width === croppedAreaB?.width &&
      croppedAreaA?.height === croppedAreaB?.height
    );
  };
}
