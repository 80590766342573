import { CancelTokenSource } from 'axios';
import { APIManager } from './APIManager';
import { APIGetBundlesResult, APIGetCompanyBundleResult } from './types/BundlesServiceTypes';

const getBundles = (language: string, cancelSource?: CancelTokenSource): Promise<APIGetBundlesResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(`/bundles?lang=${language}`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const getBundle = (
  chargebeeBundleId: string,
  language: string,
  cancelSource?: CancelTokenSource
): Promise<APIGetCompanyBundleResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(`/bundles/${chargebeeBundleId}?language=${language}`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

export { getBundles, getBundle };
