import { SortOrder } from 'antd/lib/table/interface';
import find from 'lodash/find';
import moment from 'moment';
import { TFunction } from 'react-i18next';
import {
  APICompanyAnalyticsEventAggregation,
  APICompanyAnalyticsEventForPeriod,
  APICompanyAnalyticsEvents,
  APICompanyAnalyticsEventsOfType,
  APICompanyAnalyticsEventType,
  APICompanyAnalyticsTimeRange,
  APICompanyAnalyticsUserEventsForPeriod,
} from '../services/api/types/CompaniesServiceTypes';
import StringUtils from './StringUtils';
import TimeUtils from './TimeUtils';

export default class AnalyticsUtils {
  static getEventsDataByEventAndAggregation = (
    analyticsEvents: APICompanyAnalyticsEvents[],
    event: APICompanyAnalyticsEventType,
    aggregation: APICompanyAnalyticsEventAggregation
  ): APICompanyAnalyticsEventsOfType | undefined => {
    const data = find(
      analyticsEvents,
      (eventType) => eventType.event === event && eventType.aggregation === aggregation
    )?.data;

    if (!data) {
      return undefined;
    }
    const sortedItems = AnalyticsUtils.sortUsersByTotalEventsForPeriod(data.items, 'descend');

    return { ...data, items: sortedItems } as APICompanyAnalyticsEventsOfType;
  };

  static getMonthAsString = (
    t: TFunction,
    analyticsUserEventForPeriod: APICompanyAnalyticsEventForPeriod
  ): { month: number; year: number; title: string } => {
    return {
      title: t('analytics.monthColumn', {
        year: analyticsUserEventForPeriod.year,
        month: TimeUtils.padWithZerosIfNeeded(analyticsUserEventForPeriod.month),
      }),
      month: analyticsUserEventForPeriod.month,
      year: analyticsUserEventForPeriod.year,
    };
  };

  static getMonthsAsStringsFromUserEvents = (
    t: TFunction,
    analyticsUserEventForPeriod: APICompanyAnalyticsUserEventsForPeriod
  ): { month: number; year: number; title: string }[] => {
    return analyticsUserEventForPeriod.eventsForPeriod.map((period) => AnalyticsUtils.getMonthAsString(t, period));
  };

  static getMonthsAsStringsFromPeriodEvents = (
    t: TFunction,
    periodEvents: APICompanyAnalyticsEventForPeriod[]
  ): { month: number; year: number; title: string }[] => {
    return periodEvents.map((periodEvent) => AnalyticsUtils.getMonthAsString(t, periodEvent));
  };

  static getStartDateAsISOFromTimeRange = (timeRange: APICompanyAnalyticsTimeRange): string => {
    let startDate;
    switch (timeRange) {
      case 'TWELVE_LAST_MONTHS':
        startDate = moment().subtract(11, 'month');
        break;
      case 'SIX_LAST_MONTHS':
        startDate = moment().subtract(5, 'month');
        break;
      case 'THREE_LAST_MONTHS':
      default:
        startDate = moment().subtract(2, 'month');
        break;
    }

    return startDate.startOf('month').toISOString();
  };

  static getEndDateAsISO = (): string => {
    return moment().endOf('month').toISOString();
  };

  static findUserEventForPeriod = (
    userEventsForPeriods: APICompanyAnalyticsEventForPeriod[],
    month: number,
    year: number
  ): APICompanyAnalyticsEventForPeriod | undefined => {
    return find(userEventsForPeriods, (e) => e.year === year && e.month === month);
  };

  static calculateTotalEventsForPeriod = (userEventsForPeriods: APICompanyAnalyticsEventForPeriod[]): number => {
    return userEventsForPeriods.reduce((acc, curr) => acc + curr.count, 0) ?? 0;
  };

  static sortUsersByTotalEventsForPeriod = (
    eventsForPeriods: APICompanyAnalyticsUserEventsForPeriod[],
    sortOrder: SortOrder = 'descend'
  ): APICompanyAnalyticsUserEventsForPeriod[] => {
    return eventsForPeriods.sort((userA, userB) => {
      const userATotal = AnalyticsUtils.calculateTotalEventsForPeriod(userA.eventsForPeriod);
      const userBTotal = AnalyticsUtils.calculateTotalEventsForPeriod(userB.eventsForPeriod);

      return sortOrder === 'descend' ? userBTotal - userATotal : userATotal - userBTotal;
    });
  };

  static buildCSVUserEventsForPeriodData = (
    t: TFunction,
    eventsForPeriods: APICompanyAnalyticsUserEventsForPeriod[]
  ): Array<Array<string>> => {
    const result: Array<Array<string>> = [];
    const months =
      eventsForPeriods &&
      eventsForPeriods[0] &&
      AnalyticsUtils.getMonthsAsStringsFromUserEvents(t, eventsForPeriods[0]);
    const monthsTitles = months.map((m) => m.title);

    const headerRow = [t('analytics.user'), ...monthsTitles, t('analytics.total')];
    // Headers
    result.push(headerRow);

    // For each userEvent, we build rows
    eventsForPeriods.forEach((userEventsForPeriod) => {
      // Add the user email
      const userRow = [userEventsForPeriod.userEmail];
      // For each month, add the count
      months.forEach((month) => {
        const eventForPeriodCount =
          AnalyticsUtils.findUserEventForPeriod(userEventsForPeriod.eventsForPeriod, month.month, month.year)?.count ??
          0;
        userRow.push(eventForPeriodCount.toString());
      });
      // Add the total for user
      const totalForUser = AnalyticsUtils.calculateTotalEventsForPeriod(userEventsForPeriod.eventsForPeriod);
      userRow.push(totalForUser.toString());
      // Add the user row to the final result
      result.push(userRow);
    });

    return result;
  };

  static generateCSVFilename = (
    companyName: string,
    eventTypeCode: APICompanyAnalyticsEventType,
    timeRange: APICompanyAnalyticsTimeRange
  ): string => {
    const startDate = AnalyticsUtils.getStartDateAsISOFromTimeRange(timeRange).split('T')[0];
    const endDate = AnalyticsUtils.getEndDateAsISO().split('T')[0];
    return `analytics_${StringUtils.transformToSnakeCase(
      companyName
    )}_${eventTypeCode.toLowerCase()}_${startDate}_${endDate}.csv`;
  };
}
