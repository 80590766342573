import i18n from 'i18next';
import { uniqBy } from 'lodash';
import log from 'loglevel';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCharterPermissions } from '../redux/action/ChartersAction';
import { RootState } from '../redux/RootState';
import { getCharterPermissions } from '../services/api/ChartersService';

const useCharterPermissions = (): void => {
  const dispatch = useDispatch();
  const charterId = useSelector((state: RootState) => state.charters.current?.id);

  useEffect(() => {
    if (!charterId) {
      return;
    }

    const lang = i18n.language.split('-')[0] || 'en';
    getCharterPermissions(charterId, lang)
      .then((response) => {
        const { data } = response;
        const permissions = uniqBy(data.items, (permission) => permission.code); // Ensure we do not have duplicates
        dispatch(updateCharterPermissions(permissions));
      })
      .catch((e) => {
        log.debug('Error during charter permissions fetch', e);
      });
  }, [charterId, i18n.language]);
};

export default useCharterPermissions;
