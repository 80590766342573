import { Empty, Table } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { ANALYTICS } from '../../Constants';
import { RootState } from '../../redux/RootState';
import { APICompanyAnalyticsUserEventsForPeriod } from '../../services/api/types/CompaniesServiceTypes';
import { buildColumns } from './CompanyAnalyticsPerUserTableColumns';

type Props = {
  tableData?: APICompanyAnalyticsUserEventsForPeriod[];
  loading?: boolean;
};

const useStyles = createUseStyles({
  totalColumn: {
    background: '#FAFAFA',
    borderLeft: '1px solid #f0f0f0',
    fontWeight: '700 !important',
  },
  email: {
    color: '#7E8AAA',
    fontSize: 13,
  },
});

const CompanyAnalyticsPerUser: FunctionComponent<Props> = ({ tableData, loading }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);
  const [filter, setFilter] = useState<Record<string, string>>();

  if (!tableData && !loading) {
    return <Empty description={t('global.nodata')} />;
  }

  const columns = tableData ? buildColumns({ data: tableData, t, classes, filter, setFilter, isMobileOrTablet }) : [];

  return (
    <Table
      tableLayout="fixed"
      columns={columns}
      dataSource={tableData}
      showSorterTooltip={false}
      loading={loading}
      scroll={{ x: 'max-content' }}
      rowKey={(record): string => {
        return `users_row_${record.userId}`;
      }}
      pagination={{
        defaultPageSize: ANALYTICS.PAGINATION.PAGE_SIZE,
        showSizeChanger: tableData ? tableData.length > ANALYTICS.PAGINATION.PAGE_SIZE : false,
      }}
      locale={{ emptyText: t('global.nodata') }}
    />
  );
};

export default CompanyAnalyticsPerUser;
