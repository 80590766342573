import { CodeOutlined, CopyOutlined, DeleteOutlined, DragOutlined, InfoCircleOutlined } from '@ant-design/icons/lib';
import { Badge, Card, Empty, Popconfirm, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { DEVICE_SIZES_QUERIES, THEME } from '../../../../Constants';
import useCroppedMedia from '../../../../hooks/useCroppedMedia';
import { RootState } from '../../../../redux/RootState';
import CompaniesUtils from '../../../../utils/CompaniesUtils';
import ScenarioUtils from '../../../../utils/ScenarioUtils';
import { ScenarioSceneTemplate } from '../../../../utils/types/ScenarioTypes';
import ScenarioScenePreview from './ScenarioScenePreview';

type Props = {
  scene: ScenarioSceneTemplate;
  index: number;
  format: string;
  isDeletable?: boolean;
  onDeleteScene: () => void;
  onDuplicateScene: () => void;
  onSelectScene: () => void;
  isSelected?: boolean;
  isUnsaved?: boolean;
};

const useStyles = createUseStyles({
  cardContainer: {
    height: '100%',
    width: '220px',
    margin: '0 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  sceneCard: {
    width: '100%',
    minHeight: 200,
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',

    '& .ant-card-head': {
      padding: '0 4px',
    },
    '& .ant-card-head-title': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '8px 0',
    },
    '& .ant-card-body': {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
    },
    '& .ant-card-actions > li': {
      margin: '4px 0',
    },
  },
  sceneTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 12,
    width: '100%',
  },
  sceneTitle: {
    width: '60%',
  },
  ellipsisDiv: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    flex: 1,
    minWidth: 0,
  },
  divNoWrap: {
    whiteSpace: 'nowrap',
  },
  sceneCardTooltipsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 4,
    '& :not(:first-child)': {
      marginLeft: '6px',
    },
  },
  tooltip: {
    '& .ant-tooltip-inner': {
      width: 'fit-content',
      maxWidth: '350px',
      [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
        width: 'auto',
      },
    },
  },
  sceneJSON: {
    fontSize: '10px',
  },
  selectedScene: {
    borderRight: `5px solid ${THEME.MENU.MAIN_COLOR}`,
    '& .ant-card-head': {
      backgroundColor: `${THEME.MENU.MAIN_BACKGROUND_COLOR}`,
      color: THEME.MENU.MAIN_TEXT_COLOR,
    },
  },
  coverImageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  coverImage: {
    maxWidth: '100%',
    maxHeight: '120px',
    filter: 'blur(10px)',
    width: '100%',
    objectFit: 'contain',
  },
  coverImageCropped: {
    animation: '$removeBlur 1s ease-in-out forwards',
  },
  '@keyframes removeBlur': {
    '0%': {
      filter: 'blur(10px)',
    },
    '100%': {
      filter: 'none',
    },
  },
  popconfirm: {
    width: '100%',
  },
  sceneDragIcon: {
    cursor: 'grab !important',
    marginRight: '6px',
    height: '32px',
    width: '32px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  unsavedSceneBadge: {
    paddingLeft: '10px',
    '& .ant-badge-status-dot': {
      background: THEME.DEFAULT.TEXT_COLOR_CONTRASTING_BLUE,
      width: '8px',
      height: '8px',
    },
    '& .ant-badge-status-dot::after': {
      border: `1px solid ${THEME.DEFAULT.TEXT_COLOR_CONTRASTING_BLUE}`,
    },
  },
  emptySceneCover: {
    margin: '24px 0',
    justifySelf: 'center',
    alignSelf: 'center',
  },
  sceneIndexBadge: {
    marginTop: 5,
    '& .ant-badge-count': {
      backgroundColor: THEME.DEFAULT.MAIN_TEXT_COLOR,
      boxShadow: 'none',
    },
  },
  selectedSceneIndexBadge: {
    '& .ant-badge-count': {
      backgroundColor: THEME.DEFAULT.MAIN_COLOR,
    },
  },
});

const ScenarioSceneCard: FunctionComponent<Props> = React.memo(
  ({
    scene,
    index,
    format,
    isDeletable = true,
    onDeleteScene,
    onDuplicateScene,
    onSelectScene,
    isSelected = false,
    isUnsaved = false,
  }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [croppedDemoCoverImage, isDemoCoverImageCropLoading] = useCroppedMedia(scene.demoCoverImageSource);
    const [isSceneTitleEllipsisActive, setIsSceneTitleEllipsisActive] = useState(false);

    const companies = useSelector((state: RootState) => state.companies.list);
    const isKnlTeam = companies ? CompaniesUtils.checkIsKnlProfile(companies) : false;
    const isSlideScene = ScenarioUtils.isSlideKind(scene.kind);

    const sceneTitleRef = useCallback(
      (node: HTMLDivElement) => {
        if (node) {
          setIsSceneTitleEllipsisActive(node.offsetWidth < node.scrollWidth);
        }
      },
      [scene]
    );

    const renderSceneTooltip = (): JSX.Element => {
      const sceneJSON = ScenarioUtils.transformScenesOrSceneIntoJSON(scene);
      return (
        <div onClick={(e): void => e.stopPropagation()}>
          <Tooltip title={<pre className={classes.sceneJSON}>{sceneJSON}</pre>} overlayClassName={classes.tooltip}>
            <span onClick={(e): void => e.stopPropagation()}>
              <CodeOutlined />
            </span>
          </Tooltip>
        </div>
      );
    };

    const renderSceneDescription = (): JSX.Element => {
      return (
        <div onClick={(e): void => e.stopPropagation()}>
          <Tooltip title={scene.description}>
            <span onClick={(e): void => e.stopPropagation()}>
              <InfoCircleOutlined />
            </span>
          </Tooltip>
        </div>
      );
    };

    const renderSceneCover = (): JSX.Element => {
      if (isSlideScene && scene.animationText) {
        return (
          <ScenarioScenePreview
            scene={scene}
            format={format}
            isPlaying={false}
            controls={false}
            initialTime={1.3}
            hideBorders
          />
        );
      }

      return scene.demoCoverImageSource ? (
        <img
          alt="Demo cover source"
          className={classNames(classes.coverImage, !isDemoCoverImageCropLoading && classes.coverImageCropped)}
          src={croppedDemoCoverImage?.croppedFileUrl}
        />
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t('charters.scenarios.variantEditor.sceneForm.fields.noDemoCoverImageSource')}
          className={classes.emptySceneCover}
        />
      );
    };

    const renderSceneTitle = () => {
      const { title } = scene;

      return (
        <div className={classes.sceneTitle}>
          {isSceneTitleEllipsisActive ? (
            <Tooltip title={title} className={classes.ellipsisDiv}>
              <div ref={sceneTitleRef}>{title}</div>
            </Tooltip>
          ) : (
            <div ref={sceneTitleRef} className={classes.divNoWrap}>
              {title}
            </div>
          )}
        </div>
      );
    };

    return (
      // Created scenes do not yet have an id so we use the index
      <Draggable index={index} draggableId={scene.id ? scene.id.toString() : scene.index.toString()}>
        {(draggableProvided): JSX.Element => (
          <div
            {...draggableProvided.draggableProps}
            ref={draggableProvided.innerRef}
            key={scene.id}
            className={classes.cardContainer}
          >
            <Card
              title={
                <>
                  <div className={classes.sceneTitleContainer}>
                    <DragOutlined {...draggableProvided.dragHandleProps} className={classes.sceneDragIcon} />

                    {renderSceneTitle()}
                    {isUnsaved && (
                      <Tooltip title={t('charters.scenarios.variantEditor.unsavedChangesTooltip')}>
                        <Badge
                          className={classNames(classes.unsavedSceneBadge, 'unsavedVariantSceneChangesBadge')}
                          status="processing"
                        />
                      </Tooltip>
                    )}
                    <div className={classes.sceneCardTooltipsContainer}>
                      {renderSceneDescription()}
                      {isKnlTeam && renderSceneTooltip()}
                    </div>
                  </div>
                </>
              }
              className={classNames(classes.sceneCard, isSelected && classes.selectedScene)}
              onClick={onSelectScene}
              actions={[
                <div onClick={(e): void => e.stopPropagation()}>
                  <Popconfirm
                    title={t('charters.scenarios.variantEditor.sceneDuplicationConfirm')}
                    onConfirm={onDuplicateScene}
                    okText={t('global.yes')}
                    cancelText={t('global.no')}
                    className={classes.popconfirm}
                  >
                    <CopyOutlined className="duplicateScenarioVariantScene" />
                  </Popconfirm>
                </div>,
                ...(isDeletable
                  ? [
                      <div onClick={(e): void => e.stopPropagation()}>
                        <Popconfirm
                          title={t('charters.scenarios.variantEditor.sceneDeletionConfirm')}
                          onConfirm={onDeleteScene}
                          okText={t('global.yes')}
                          cancelText={t('global.no')}
                          className={classes.popconfirm}
                        >
                          <DeleteOutlined className="deleteScenarioVariantScene" />
                        </Popconfirm>
                      </div>,
                    ]
                  : []),
              ]}
            >
              <div className={classes.coverImageContainer}>{renderSceneCover()}</div>
            </Card>

            <Badge
              count={index + 1}
              size="small"
              className={classNames(classes.sceneIndexBadge, isSelected && classes.selectedSceneIndexBadge)}
            />
          </div>
        )}
      </Draggable>
    );
  }
);

export default ScenarioSceneCard;
