import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { createUseStyles } from 'react-jss';
import AccessChecker from '../../access-checker/AccessChecker';

type Props = {
  format: string;
  image: string;
  hasAccess?: boolean;
  renderUnauthorizedMessage?: () => void;
};

type StyleProps = {
  format: string;
};

const useStyles = createUseStyles({
  formatTagContainer: {
    lineHeight: '0.7rem',
    height: 25,
    width: 25,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  formatImage: ({ format }: StyleProps) => ({
    maxWidth: '80%',
    maxHeight: '60%',
    transform: format === '1:1' ? 'scale(0.75)' : undefined,
  }),
  formatCode: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  unauthorized: {
    cursor: 'not-allowed',
  },
});

const FormatTag: FunctionComponent<Props> = ({
  format,
  image,
  hasAccess = true,
  renderUnauthorizedMessage = () => undefined,
}: Props) => {
  const classes = useStyles({ format });

  return (
    <AccessChecker renderUnauthorizedMessage={renderUnauthorizedMessage} hasAccess={hasAccess}>
      <div className={classNames(classes.formatTagContainer, !hasAccess && classes.unauthorized)}>
        <img src={image} alt="Format img" className={classes.formatImage} />
        <div className={classes.formatCode}>{format}</div>
      </div>
    </AccessChecker>
  );
};

export default FormatTag;
