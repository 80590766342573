import { VideoCameraAddOutlined } from '@ant-design/icons';
import { Button, Col, Form, message, Modal, Row, Select, Tabs } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Store } from 'antd/lib/form/interface';
import { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import { map } from 'lodash';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AccessChecker from '../../../../components/access-checker/AccessChecker';
import ScenarioCard from '../../../../components/card/ScenarioCard';
import TextInput from '../../../../components/form/TextInput';
import MultipleMediaSelector from '../../../../components/media-selector/MultipleMediaSelector';
import FormatTag from '../../../../components/tag/format-tag/FormatTag';
import { LINK, LOGGING_EVENT } from '../../../../Constants';
import useCanAccess from '../../../../hooks/useCanAccess';
import i18n from '../../../../i18n';
import { RootState } from '../../../../redux/RootState';
import { APIManager } from '../../../../services/api/APIManager';
import { createCharterProject, getCharterScenariosForMe } from '../../../../services/api/ChartersService';
import {
  APICreateCharterProjectParams,
  MediaFile,
  Scenario,
  ScenarioVariant,
} from '../../../../services/api/types/ChartersServiceTypes';
import { AnimationFormats } from '../../../../utils/types/AnimationTypes';
import { PermissionList } from '../../../../utils/types/CharterPermissionTypes';
import { MediaType } from '../../../../utils/types/MediaTypes';
import { ProjectCreationType, ProjectFormats } from '../../../../utils/types/ProjectTypes';

const { Option } = Select;
const { TabPane } = Tabs;

type Props = {
  triggerClassName?: string;
};

const useStyles = createUseStyles({
  modal: {
    width: '80% !important',
  },
  form: {
    height: '100%',
  },
  tabContainer: {
    '& .ant-tabs-nav': {
      marginLeft: '25%',
      marginBottom: 20,
    },
    '& .ant-tabs-nav-list': {
      width: '100%',
    },
    '& .ant-tabs-tab': {
      flex: 1,
      justifyContent: 'center',
    },
  },
  disabledTabContainer: {
    '& .ant-tabs-ink-bar': {
      backgroundColor: 'rgba(0, 0, 0, 0.25) !important',
    },
    '& .ant-tabs-tab-disabled > div': {
      color: 'rgba(0, 0, 0, 0.25) !important',
    },
  },
});

const CreateProjectModal: FunctionComponent<Props> = ({ triggerClassName }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [form] = Form.useForm();
  const formRef = useRef<FormInstance>(null);

  const [isVisible, setIsVisible] = useState(false);
  const [isAsyncLoading, setIsAsyncLoading] = useState(false);
  const [projectTitle, setProjectTitle] = useState<string>();
  const [selectedFormat, setSelectedFormat] = useState<AnimationFormats>();
  const [selectedScenarioVariant, setSelectedScenarioVariant] = useState<ScenarioVariant>();
  const [selectedCreationType, setSelectedCreationType] = useState<ProjectCreationType>(ProjectCreationType.MEDIA);
  const [fetchedScenarios, setFetchedScenarios] = useState<Scenario[]>();

  const company = useSelector((state: RootState) => state.companies.current);
  const charter = useSelector((state: RootState) => state.charters.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);
  const currentUser = useSelector((state: RootState) => state.user);
  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());

  const isSubmitDisabled =
    isAsyncLoading ||
    !(
      selectedFormat &&
      projectTitle &&
      (selectedCreationType === ProjectCreationType.MEDIA ||
        (selectedCreationType === ProjectCreationType.SCENARIO && selectedScenarioVariant !== undefined))
    );

  const areTabsDisabled = !selectedFormat;

  const permissionToCreateProject = useCanAccess(PermissionList.PROJECT_CREATE);
  const permissionToSelectLandscapeFormat = useCanAccess(PermissionList.VIDEO_FORMAT_16_9);
  const permissionToSelectPortraitFormat = useCanAccess(PermissionList.VIDEO_FORMAT_9_16);
  const permissionToSelectSquareFormat = useCanAccess(PermissionList.VIDEO_FORMAT_1_1);

  useEffect(() => {
    const cancelTokenSource = cancelTokenSourceRef.current;

    return (): void => {
      cancelTokenSource.cancel('Cancelled the project creation due to component unmount.');
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isVisible) {
      setIsAsyncLoading(false);

      if (formRef.current && form) {
        form.resetFields();
        setSelectedFormat(undefined);
        setSelectedCreationType(ProjectCreationType.MEDIA);
      }
    }
  }, [isVisible, form]);

  useEffect(() => {
    if (!(charter && isVisible)) {
      return;
    }

    const lang = i18n.language.split('-')[0] || 'en';

    getCharterScenariosForMe(charter.id, lang).then((response) => {
      const scenarios = response.items;
      setFetchedScenarios(scenarios);
    });
  }, [charter, i18n, isVisible]);

  useEffect(() => {
    setSelectedScenarioVariant(undefined);
  }, [selectedFormat, form]);

  const getScenariosByFormat = (scenarios: Scenario[], format: AnimationFormats): Scenario[] => {
    return scenarios.filter((scenario) => scenario.variants.some((variant) => variant.format === format));
  };

  useEffect(() => {
    // If the SCENARIO tab is selected and the format changed, we select one scenarioVariant by default
    if (
      !(
        fetchedScenarios &&
        fetchedScenarios.length > 0 &&
        selectedCreationType === ProjectCreationType.SCENARIO &&
        selectedFormat
      )
    ) {
      return;
    }

    const allowedScenariosByFormat = getScenariosByFormat(fetchedScenarios, selectedFormat).filter(
      (scenario) => scenario.permission?.isAllowed ?? true
    ); // Exclude the disallowed scenarios;

    const defaultVariantOfFormat =
      allowedScenariosByFormat.length > 0 &&
      allowedScenariosByFormat[0].variants.find((variant) => variant.format === selectedFormat);

    if (defaultVariantOfFormat) {
      setSelectedScenarioVariant(defaultVariantOfFormat);
    }
  }, [selectedCreationType, fetchedScenarios, selectedFormat]);

  const formItemLayout = {
    labelCol: {
      sm: { span: 6 },
    },
    wrapperCol: {
      sm: { span: 18 },
    },
  };

  const onShowModal = (): void => {
    setIsVisible(true);
  };

  const onHideModal = (): void => {
    setIsVisible(false);
  };

  const onTabChange = (newTabKey: string) => {
    setSelectedCreationType(newTabKey as ProjectCreationType);
  };

  const handleSubmit = (): void => {
    if ((!formRef.current && form) || !charter) {
      return;
    }

    const cancelTokenSource = cancelTokenSourceRef.current;

    form
      .validateFields()
      .then((values) => {
        setIsAsyncLoading(true);

        const newProjectValues: APICreateCharterProjectParams = {
          title: values.title,
          videoFormat: values.format,
          ...(selectedCreationType === ProjectCreationType.MEDIA &&
            values.rushes &&
            values.rushes.length > 0 && { sceneMediaIds: values.rushes.map((rush: MediaFile) => rush.id) }),
          ...(selectedCreationType === ProjectCreationType.SCENARIO &&
            selectedScenarioVariant && { scenarioVariantId: selectedScenarioVariant.id }),
        };

        createCharterProject(charter.id, newProjectValues, cancelTokenSource)
          .then((createdProject) => {
            message.success(t('charters.projects.projectCreationForm.projectCreationSuccess'));
            setIsAsyncLoading(false);
            onHideModal();

            const creationType =
              // eslint-disable-next-line no-nested-ternary
              selectedCreationType === ProjectCreationType.SCENARIO
                ? ProjectCreationType.SCENARIO
                : values.rushes
                ? ProjectCreationType.MEDIA
                : ProjectCreationType.EMPTY;

            // Analytics
            loggingManager.logEvent(LOGGING_EVENT.NEW_PROJECT, {
              charterId: charter.id,
              companyId: company?.id,
              companyCheckoutChannel: company?.checkoutChannel,
              companyName: company?.name,
              charterName: charter.name,
              companySubscriptionLastStatus: company?.chargebeeSubscriptionLastStatus,
              userActiveInCharter: true,
              userRoleInCharter: currentUser.role?.currentRole,
              videoFormat: values.format,
              projectCreationType: creationType,
              projectId: createdProject.projectUuid,
              theme: createdProject.theme,
              ...(selectedCreationType === ProjectCreationType.SCENARIO && {
                scenarioVariantId: selectedScenarioVariant?.id,
              }),
            });

            const projectEditorPath =
              charter &&
              LINK.CHARTER_PROJECT_EDITOR.path.replace(':charterId', charter.id.toString()).replace('(\\d+)', '');

            history.push({
              pathname: projectEditorPath,
              state: {
                projectId: createdProject.id,
              },
            });
          })
          .catch((error) => {
            setIsAsyncLoading(false);
            message.error(t('charters.projects.projectCreationForm.projectCreationError'));
            log.error(error);
          });
      })
      .catch((e) => {
        log.error(e);
        message.error(t('charters.projects.projectCreationForm.validationError'));
      });
  };

  const hasAccessToFormatOption = (projectFormat: string) => {
    if (projectFormat === ProjectFormats.LANDSCAPE_16_9.code) {
      return permissionToSelectLandscapeFormat.hasUserAccess();
    }
    if (projectFormat === ProjectFormats.PORTRAIT_9_16.code) {
      return permissionToSelectPortraitFormat.hasUserAccess();
    }
    if (projectFormat === ProjectFormats.SQUARE_1_1.code) {
      return permissionToSelectSquareFormat.hasUserAccess();
    }
    return true;
  };

  const hasAccessToCreateProject = () => {
    return (
      permissionToSelectLandscapeFormat.hasUserAccess() ||
      permissionToSelectPortraitFormat.hasUserAccess() ||
      permissionToSelectSquareFormat.hasUserAccess()
    );
  };

  const renderUnauthorizedMessageForFormat = (projectFormat: string) => {
    if (projectFormat === ProjectFormats.LANDSCAPE_16_9.code) {
      return permissionToSelectLandscapeFormat.renderUnauthorizedMessage();
    }
    if (projectFormat === ProjectFormats.PORTRAIT_9_16.code) {
      return permissionToSelectPortraitFormat.renderUnauthorizedMessage();
    }
    if (projectFormat === ProjectFormats.SQUARE_1_1.code) {
      return permissionToSelectSquareFormat.renderUnauthorizedMessage();
    }
    return undefined;
  };

  const onValuesChange = (changedValues: Store, allValues: Store): void => {
    setSelectedFormat(allValues.format);
    setProjectTitle(allValues.title);
  };

  const renderFromScratchChoicePane = () => {
    return (
      <Form.Item
        name="rushes"
        label={t('charters.projects.projectCreationForm.projectRushesLabel')}
        extra={t('charters.projects.projectCreationForm.projectRushesExtra')}
      >
        <MultipleMediaSelector
          fieldLabel={t('charters.projects.projectCreationForm.projectRushesLabel')}
          disabled={!selectedFormat}
          defaultMediaType={MediaType.VIDEO}
          enableWebcamRecording
        />
      </Form.Item>
    );
  };

  const onSelectScenarioVariant = (clickedScenario: Scenario) => {
    const variantOfFormat = clickedScenario.variants.find((variant) => variant.format === selectedFormat);
    if (variantOfFormat) {
      setSelectedScenarioVariant(variantOfFormat);
    }
  };

  const renderScenarioVariantChoicePane = () => {
    if (!(fetchedScenarios && selectedFormat)) {
      return null;
    }

    const scenarioHavingVariantInFormat = getScenariosByFormat(fetchedScenarios, selectedFormat);

    const lang = i18n.language.split('-')[0] || 'en';

    return (
      <Row gutter={[16, 16]}>
        {scenarioHavingVariantInFormat.map((scenario) => {
          const isSelected =
            selectedScenarioVariant && scenario.variants.some((variant) => variant.id === selectedScenarioVariant.id);

          return (
            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={6} key={scenario.id}>
              <ScenarioCard
                scenario={scenario}
                language={lang}
                format={selectedFormat}
                isSelected={isSelected}
                onClick={() => onSelectScenarioVariant(scenario)}
              />
            </Col>
          );
        })}
      </Row>
    );
  };

  return (
    <>
      <AccessChecker
        hasAccess={permissionToCreateProject.hasUserAccess() && hasAccessToCreateProject()}
        renderUnauthorizedMessage={permissionToCreateProject.renderUnauthorizedMessage}
      >
        <Button
          type="primary"
          onClick={onShowModal}
          className={triggerClassName}
          disabled={!permissionToCreateProject.hasUserAccess() || !hasAccessToCreateProject()}
        >
          <VideoCameraAddOutlined /> {t('charters.projects.createProjectTitle')}
        </Button>
      </AccessChecker>

      <Modal
        visible={isVisible}
        onCancel={onHideModal}
        title={t('charters.projects.createProjectTitle')}
        cancelText={t('global.cancel')}
        okText={t('charters.projects.projectCreationForm.createProject')}
        className={classes.modal}
        onOk={handleSubmit}
        cancelButtonProps={{ disabled: isAsyncLoading }}
        okButtonProps={{
          loading: isAsyncLoading,
          disabled: isSubmitDisabled,
        }}
      >
        <Form
          form={form}
          ref={formRef}
          {...formItemLayout}
          validateMessages={{
            required: t('global.requiredField'),
          }}
          className={classes.form}
          onValuesChange={onValuesChange}
        >
          {/* projectTitle */}
          <TextInput
            fieldName="title"
            label={t('charters.projects.projectCreationForm.titleLabel')}
            placeholder={t('charters.projects.projectCreationForm.titlePlaceholder')}
            requiredMessage={t('global.requiredField')}
            emptyMessage={t('global.notEmptyField')}
            hasFeedback
          />

          {/* format */}
          <Form.Item
            name="format"
            label={t('charters.projects.projectCreationForm.formatLabel')}
            rules={[{ required: true }]}
            hasFeedback
          >
            <Select
              getPopupContainer={(trigger): HTMLElement => trigger.parentNode}
              placeholder={t('charters.projects.projectCreationForm.formatPlaceholder')}
            >
              {map(ProjectFormats, (format) => (
                <Option key={format.code} value={format.code} disabled={!hasAccessToFormatOption(format.code)}>
                  <FormatTag
                    format={t(format.key)}
                    image={format.image}
                    hasAccess={hasAccessToFormatOption(format.code)}
                    renderUnauthorizedMessage={() => renderUnauthorizedMessageForFormat(format.code)}
                  />
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Tabs
            activeKey={selectedCreationType}
            className={classNames(classes.tabContainer, areTabsDisabled && classes.disabledTabContainer)}
            onChange={onTabChange}
          >
            <TabPane
              tab={t('charters.projects.projectCreationForm.createFromRushes')}
              key={ProjectCreationType.MEDIA}
              disabled={areTabsDisabled}
            >
              {renderFromScratchChoicePane()}
            </TabPane>

            <TabPane
              tab={t('charters.projects.projectCreationForm.createFromScenario')}
              key={ProjectCreationType.SCENARIO}
              disabled={areTabsDisabled}
            >
              {renderScenarioVariantChoicePane()}
            </TabPane>
          </Tabs>
        </Form>
      </Modal>
    </>
  );
};

export default CreateProjectModal;
