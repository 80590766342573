import { Button, message, Select, Table } from 'antd';
import { CancelTokenSource } from 'axios';
import { find } from 'lodash';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { LOGGING_EVENT } from '../../../../Constants';
import { RootState } from '../../../../redux/RootState';
import { APIManager } from '../../../../services/api/APIManager';
import { updateCharterPermissions } from '../../../../services/api/ChartersService';
import {
  APICharterPermission,
  APIUpdateCharterPermissionResponse,
} from '../../../../services/api/types/ChartersServiceTypes';
import {
  ChangedFieldPermissionByRole,
  PermissionRoles,
  TranslatedOptionsPermissions,
} from '../../../../utils/types/CharterPermissionTypes';

const { Option } = Select;

type Props = {
  permission: APICharterPermission;
  roleColumnWidth: number;
  callback: (permissionCode: string, changedFieldsByRole: ChangedFieldPermissionByRole[]) => void;
  isPermissionBeingUpdated: boolean;
  setIsPermissionBeingUpdated: React.Dispatch<React.SetStateAction<boolean>>;
};

type StyleProps = {
  roleColumnWidth: number;
};

const useStyles = createUseStyles({
  expandedRowTable: {
    '& .ant-table table': {
      width: 'unset',
      float: 'right',
    },
    '& .ant-table-cell': {
      borderBottom: 'none',
    },
    '& .ant-table-content': {
      background: '#fafafa',
    },
  },
  rowFormButtonContainer: {
    width: (props: StyleProps): string => `calc(2*${props.roleColumnWidth}px)`,
    float: 'right',
    padding: '0 16px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  permissionValueLabel: {
    textAlign: 'justify',
    fontSize: '12px',
  },
});

const PermissionExpandedRow: FunctionComponent<Props> = ({
  permission,
  roleColumnWidth,
  callback,
  isPermissionBeingUpdated,
  setIsPermissionBeingUpdated,
}: Props) => {
  const [ownerValue, setOwnerValue] = useState<string>();
  const [adminValue, setAdminValue] = useState<string>();
  const [creatorValue, setCreatorValue] = useState<string>();
  const [isAdminDisabled, setIsAdminDisabled] = useState(true);
  const [isCreatorDisabled, setIsCreatorDisabled] = useState(true);
  const [isOwnerDisabled, setIsOwnerDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const company = useSelector((state: RootState) => state.companies.current);
  const currentCharter = useSelector((state: RootState) => state.charters.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);

  const classes = useStyles({ roleColumnWidth });
  const { t } = useTranslation();
  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());

  useEffect(() => {
    const cancelTokenSource = cancelTokenSourceRef.current;

    return (): void => {
      cancelTokenSource.cancel('Cancelled patching charter permissions values due to component unmount.');
    };
  }, []);

  useEffect(() => {
    if (!permission) {
      return;
    }

    const { roles } = permission;
    const ownerRole = find(roles, (role) => role.code === PermissionRoles.OWNER);
    const adminRole = find(roles, (role) => role.code === PermissionRoles.ADMIN);
    const creatorRole = find(roles, (role) => role.code === PermissionRoles.CREATOR);
    setOwnerValue(ownerRole?.hasAccess.value ?? '');
    setAdminValue(adminRole?.hasAccess.value ?? '');
    setCreatorValue(creatorRole?.hasAccess.value ?? '');
    if (ownerRole && adminRole && creatorRole) {
      setIsOwnerDisabled(ownerRole.hasAccess ? ownerRole.hasAccess.locked || ownerRole.hasAccess.access : false);
      setIsAdminDisabled(adminRole.hasAccess ? adminRole.hasAccess.locked || adminRole.hasAccess.access : false);
      setIsCreatorDisabled(
        creatorRole.hasAccess ? creatorRole.hasAccess.locked || creatorRole.hasAccess.access : false
      );
    }
  }, [permission]);

  const handleOwnerValueChange = (newValue: string): void => {
    setOwnerValue(newValue);
  };

  const handleAdminValueChange = (newValue: string): void => {
    setAdminValue(newValue);
  };

  const handleCreatorValueChange = (newValue: string): void => {
    setCreatorValue(newValue);
  };

  const handleUpdatePermissionValues = (): void => {
    if (!currentCharter || !permission) {
      return;
    }

    setIsPermissionBeingUpdated(true);
    setIsLoading(true);

    const promises: Promise<APIUpdateCharterPermissionResponse>[] = [];
    Object.keys(PermissionRoles).forEach((roleCode) => {
      const role = find(permission.roles, (r) => r.code === roleCode);
      let value: string | undefined;
      if (roleCode === PermissionRoles.ADMIN) {
        value = adminValue;
      } else {
        value = roleCode === PermissionRoles.OWNER ? ownerValue : creatorValue;
      }

      // If the permission is disabled for this role, we do not update its value
      if (
        (roleCode === PermissionRoles.OWNER && isOwnerDisabled) ||
        (roleCode === PermissionRoles.ADMIN && isAdminDisabled) ||
        (roleCode === PermissionRoles.CREATOR && isCreatorDisabled)
      ) {
        return;
      }

      const data = {
        roleCode,
        permissionCode: permission.code,
        access: role ? role.hasAccess.access : true,
        value: value ?? null,
      };

      promises.push(updateCharterPermissions(currentCharter.id, data, cancelTokenSourceRef.current));
    });

    Promise.all(promises)
      .then(() => {
        message.success(t('charters.permissions.permissionValuesUpdateSuccess'));

        loggingManager.logEvent(LOGGING_EVENT.UPDATE_CHARTER_PERMISSION_VALUES, {
          permission: permission.code,
          ownerValue: ownerValue ?? 'null',
          adminValue: adminValue ?? 'null',
          creatorValue: creatorValue ?? 'null',
          companyId: company?.id,
          charterId: currentCharter.id,
        });

        callback(permission.code, [
          { roleCode: PermissionRoles.OWNER, changedField: ownerValue },
          { roleCode: PermissionRoles.ADMIN, changedField: adminValue },
          { roleCode: PermissionRoles.CREATOR, changedField: creatorValue },
        ]);
      })
      .catch((e) => {
        message.error(t('charters.permissions.permissionValuesUpdateError'));
        log.error(`Error while updating charter permission values`, e);
        setIsPermissionBeingUpdated(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderValueSelect = (role: PermissionRoles): JSX.Element => {
    let value;
    let onChangeCallback;
    let isDisabled;
    if (role === PermissionRoles.OWNER) {
      value = ownerValue;
      onChangeCallback = handleOwnerValueChange;
      isDisabled = isOwnerDisabled;
    } else if (role === PermissionRoles.ADMIN) {
      value = adminValue;
      onChangeCallback = handleAdminValueChange;
      isDisabled = isAdminDisabled;
    } else if (role === PermissionRoles.CREATOR) {
      value = creatorValue;
      onChangeCallback = handleCreatorValueChange;
      isDisabled = isCreatorDisabled;
    }
    const { code: permissionCode, allowedValues } = permission;
    const isTranslatedOptionsPermission = Object.keys(TranslatedOptionsPermissions).includes(permissionCode);

    return (
      <Select
        value={value}
        style={{ width: '100%' }}
        onChange={onChangeCallback}
        disabled={isDisabled || isPermissionBeingUpdated}
        allowClear
        size="small"
      >
        {allowedValues &&
          allowedValues.length > 0 &&
          allowedValues.map((allowedValue) => (
            <Option key={`permission_${permissionCode}_${allowedValue}`} value={allowedValue}>
              {isTranslatedOptionsPermission // If the allowed value contains a dot, we replace it with a comma since a i18next key can't contain dots
                ? t(`charters.permissions.permissionValuesOptions.${permissionCode}.${allowedValue.replace('.', ',')}`)
                : allowedValue}
            </Option>
          ))}
      </Select>
    );
  };

  const expandedRowColumns = [
    {
      key: 'permission_value_label',
      render: (): JSX.Element => (
        <div className={classes.permissionValueLabel}>{t('charters.permissions.permissionValuesLabel')}</div>
      ),
      width: 'auto',
    },
    {
      key: 'permission_role_owner',
      render: (): JSX.Element | undefined => renderValueSelect(PermissionRoles.OWNER),
      width: roleColumnWidth,
    },
    {
      key: 'permission_role_admin',
      render: (): JSX.Element | undefined => renderValueSelect(PermissionRoles.ADMIN),
      width: roleColumnWidth,
    },
    {
      key: 'permission_role_creator',
      render: (): JSX.Element | undefined => renderValueSelect(PermissionRoles.CREATOR),
      width: roleColumnWidth,
    },
  ];

  return (
    <>
      <Table
        tableLayout="fixed"
        className={classes.expandedRowTable}
        columns={expandedRowColumns}
        dataSource={[permission]}
        pagination={false}
        showHeader={false}
        rowKey={(record): string => {
          return `charters_permission_row_expanded_${record.code}`;
        }}
      />
      <div className={classes.rowFormButtonContainer}>
        <Button
          type="primary"
          disabled={isCreatorDisabled && isAdminDisabled && isOwnerDisabled}
          onClick={handleUpdatePermissionValues}
          loading={isLoading}
        >
          {t('global.apply')}
        </Button>
      </div>
    </>
  );
};

export default PermissionExpandedRow;
