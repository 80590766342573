import { BgColorsOutlined } from '@ant-design/icons/lib';
import { Button, Form } from 'antd';
import { FormInstance } from 'antd/es/form';
import classNames from 'classnames';
import React, { FunctionComponent, RefObject, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import Colors from '../../../components/form/Colors';
import Name from '../../../components/form/Name';
import { RootState } from '../../../redux/RootState';
import { APIFullModelCharter } from '../../../services/api/types/ChartersServiceTypes';

type Props = {
  form: FormInstance;
  formRef: RefObject<FormInstance>;
  colorsList: string[];
  onColorsListChange: (newColorsList: string[]) => void;
  isLoading?: boolean;
  onSubmit: () => void;
  onReset: () => void;
  charterToEdit?: APIFullModelCharter;
};

const useStyles = createUseStyles({
  formContainer: {
    marginTop: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    width: '85%',
    '& .ant-form-item-label': {
      whiteSpace: 'normal',
    },
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: 8,
    },
  },
  mobileActionsContainer: {
    flexDirection: 'column',
    '& button': {
      margin: '10px',
    },
  },
});

const CharterCreationOrUpdateForm: FunctionComponent<Props> = ({
  form,
  formRef,
  colorsList,
  onColorsListChange,
  isLoading = false,
  onSubmit,
  onReset,
  charterToEdit = undefined,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isMobileOrTablet = useSelector((state: RootState) => {
    return state.app.isMobileOrTablet;
  });

  useEffect(() => {
    if (charterToEdit) {
      onReset();
    }
    // eslint-disable-next-line
  }, [charterToEdit]);

  const isCreationForm = !charterToEdit;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 9 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 13 },
    },
  };

  const actionButtonsLayout = {
    wrapperCol: { xs: { span: 24 }, sm: { offset: 9, span: 13 } },
  };

  const formInitialValues = {
    colors: colorsList,
    ...(!isCreationForm && charterToEdit && { name: charterToEdit.name }),
  };

  return (
    <div className={classes.formContainer}>
      <Form
        form={form}
        {...formItemLayout}
        ref={formRef}
        className={classNames(!isMobileOrTablet && classes.form)}
        initialValues={formInitialValues}
      >
        <Name
          key="newCharterName"
          label={t('charters.creationOrUpdate.name')}
          extra={t('charters.creationOrUpdate.nameDescription')}
          placeholder={t('charters.creationOrUpdate.namePlaceholder')}
          icon={<BgColorsOutlined />}
        />

        <Colors
          key="newCharterColors"
          label={t('charters.creationOrUpdate.colors')}
          extra={t('charters.creationOrUpdate.colorsDescription')}
          colorsList={colorsList}
          onColorsListChange={onColorsListChange}
          preventWhite
          preventBlack
        />

        <Form.Item {...actionButtonsLayout}>
          <span className={classNames(classes.actionsContainer, isMobileOrTablet && classes.mobileActionsContainer)}>
            <Button onClick={onReset}>{t('global.reset')}</Button>
            <Button type="primary" onClick={onSubmit} loading={isLoading} disabled={isLoading}>
              {t(`charters.${isCreationForm ? 'creation' : 'update'}.validationButton`)}
            </Button>
          </span>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CharterCreationOrUpdateForm;
