import React, { FunctionComponent, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { PROJECTS, THEME } from '../../../../../../../../Constants';
import {
  AnchorType,
  ProjectSceneAnimation,
  ProjectSceneElementsCode,
  ProjectSceneMedia,
} from '../../../../../../../../utils/types/ProjectTypes';
import { VideoTrim, VideoTrimUtils } from '../../utils/VideoTrimUtils';
import SceneTimelineGrayOverlay from '../SceneTimelineGrayOverlay';
import SceneTrimAnchor from './SceneTrimAnchor';

type Props = {
  backgroundVideo?: ProjectSceneMedia;
  pip?: ProjectSceneMedia;
  animation?: ProjectSceneAnimation;
  isSelected?: boolean;
  videoName?: string;
  videoTrimValue: VideoTrim;
  width: number;
  intervalTickWidth: number;
  onTrimChange: (e: VideoTrim) => void;
  onClick: () => void;
  pipTrimValue: VideoTrim;
};

type StyleProps = {
  isSelected: boolean;
  videoTrimValue: VideoTrim;
  backgroundVideo: ProjectSceneMedia;
  pipTrimValue: VideoTrim;
  width: number;
  duration: number;
  intervalTickWidth: number;
};

const useStyles = createUseStyles({
  frameContainer: () => {
    return {
      cursor: 'pointer',
      height: '100%',
      display: 'flex',
      position: 'absolute',
      borderRadius: 8,
      left: PROJECTS.TRIM.ANCHOR_WIDTH,
    };
  },
  backgroundVideoFrame: ({ width, pipTrimValue, intervalTickWidth, backgroundVideo }: StyleProps) => {
    const videoTrimStart = backgroundVideo.trimStartAt || 0;
    const trimStart = VideoTrimUtils.getVideoOverflowWithPipTrimValue(videoTrimStart, pipTrimValue);

    return {
      cursor: 'pointer',
      height: '100%',
      display: 'flex',
      position: 'absolute',
      background: THEME.PROJECTS.EDITOR.TIMELINE.VIDEO_EXCLUDED_BACKGROUND,
      borderRadius: 8,
      left: trimStart * intervalTickWidth,
      width,
    };
  },
  colorFrameCentralElement: ({
    isSelected,
    videoTrimValue,
    intervalTickWidth,
    pipTrimValue,
    backgroundVideo,
  }: StyleProps) => {
    const videoTrimStart = backgroundVideo.trimStartAt || 0;
    const trimStart = VideoTrimUtils.getVideoOverflowWithPipTrimValue(videoTrimStart, pipTrimValue);
    const unshadedAreaBetweenAnchorsWidth = VideoTrimUtils.calculateUnshadedAreaWidthBetweenStartAndEndAnchor(
      videoTrimValue,
      intervalTickWidth
    );
    const unshadedAreaLeftBoundValue = VideoTrimUtils.calculateUnshadedAreaLeftBoundValue(
      videoTrimValue.trimStart + trimStart,
      intervalTickWidth
    );

    const common = {
      position: 'absolute',
      pointerEvents: 'none',
      height: '100%',
      opacity: '100%',
      display: 'flex',
      border: isSelected ? `6px solid ${THEME.PROJECTS.EDITOR.TIMELINE.SELECTED_ELEMENT_BORDER}` : undefined,
      borderLeft: isSelected ? 'none' : undefined,
      borderRight: isSelected ? 'none' : undefined,
      // Explanation for the `+ 2` and `- 1` below.
      // Sometimes, the CSS cannot fill an entire pixel with the color.
      // In such cases, it might fill with the color and sometimes it might be left empty so in order not to
      // have this behavior, we add some width (`2px` here) and remove `1px` to ensure that the anchor are above these gaps
      width: isSelected ? unshadedAreaBetweenAnchorsWidth + 2 : unshadedAreaBetweenAnchorsWidth,
      background: THEME.PROJECTS.EDITOR.TIMELINE.ELEMENT_BACKGROUND,
      left: isSelected ? unshadedAreaLeftBoundValue - 1 : unshadedAreaLeftBoundValue,
      alignItems: 'center',
      borderRadius: !isSelected ? 8 : undefined,
      zIndex: 3,
    };

    return { ...common };
  },
  videoName: ({ isSelected }: StyleProps) => ({
    color: 'white',
    marginLeft: 10,
    fontFamily: 'SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace',
    fontSize: 12,
    margin: isSelected ? '0 4px' : '0 10px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),
  trimAnchorOverflow: ({ pipTrimValue, intervalTickWidth, backgroundVideo }: StyleProps) => {
    const videoTrimStart = backgroundVideo.trimStartAt || 0;
    const trimStart = VideoTrimUtils.getVideoOverflowWithPipTrimValue(videoTrimStart, pipTrimValue);

    return {
      left: trimStart * intervalTickWidth,
    };
  },
});

const SceneVideoTrimBar: FunctionComponent<Props> = ({
  backgroundVideo,
  pip,
  animation,
  isSelected = false,
  videoName,
  videoTrimValue,
  width,
  intervalTickWidth,
  onTrimChange,
  onClick,
  pipTrimValue,
}: Props) => {
  const [onGoingTrim, setOnGoingTrim] = useState<VideoTrim>(videoTrimValue);

  const classes = useStyles({
    isSelected,
    videoTrimValue: onGoingTrim,
    width,
    intervalTickWidth,
    backgroundVideo,
    pipTrimValue,
  });

  useEffect(() => {
    setOnGoingTrim(videoTrimValue);
  }, [videoTrimValue]);

  const handleAnchorChange = (localTrim: VideoTrim) => {
    setOnGoingTrim(localTrim);
  };

  return (
    <div>
      {isSelected && (
        <SceneTrimAnchor
          callbackAnchorChange={handleAnchorChange}
          type={AnchorType.START_ANCHOR}
          trimValue={videoTrimValue}
          width={width}
          onTrimChange={onTrimChange}
          intervalTickWidth={intervalTickWidth}
          className={classes.trimAnchorOverflow}
        />
      )}

      <div className={classes.frameContainer} onClick={onClick}>
        <div className={classes.backgroundVideoFrame} />
        <SceneTimelineGrayOverlay
          element={ProjectSceneElementsCode.RECORDABLE_VIDEO}
          backgroundVideo={backgroundVideo}
          pip={pip}
          animation={animation}
          videoTrimValue={videoTrimValue}
          intervalTickWidth={intervalTickWidth}
        >
          <div className={classes.colorFrameCentralElement} id="colorFrameCentralElement">
            {videoName && <span className={classes.videoName}>{videoName}</span>}
          </div>
        </SceneTimelineGrayOverlay>
      </div>

      {isSelected && (
        <SceneTrimAnchor
          callbackAnchorChange={handleAnchorChange}
          type={AnchorType.END_ANCHOR}
          trimValue={videoTrimValue}
          width={width}
          onTrimChange={onTrimChange}
          intervalTickWidth={intervalTickWidth}
          className={classes.trimAnchorOverflow}
        />
      )}
    </div>
  );
};

export default SceneVideoTrimBar;
