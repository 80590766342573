import { PlusOutlined } from '@ant-design/icons/lib';
import { Tag } from 'antd';
import { find } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import LinkCharterModal from '../../../components/modal/LinkCharterModal';
import { RootState } from '../../../redux/RootState';
import { APIModelCharter } from '../../../services/api/types/ChartersServiceTypes';
import { APICompanyUsers } from '../../../services/api/types/CompaniesServiceTypes';
import { APIModelUserEnriched } from '../../../services/api/types/UsersServiceTypes';

const useStyles = createUseStyles({
  tag: {
    borderRadius: '25px',
    borderStyle: 'dashed',
    cursor: 'pointer',
    '&:hover': {
      borderStyle: 'solid',
    },
  },
});

type Props = {
  visible: boolean;
  user: APICompanyUsers;
  callbackLinkUserToCharter: (isLoading: boolean, data?: APIModelUserEnriched, charter?: APIModelCharter) => void;
};

const LinkUserToCharterTag: FunctionComponent<Props> = ({ visible, user, callbackLinkUserToCharter }: Props) => {
  const classes = useStyles();
  const [modalVisible, setModalVisible] = useState(false);
  const companyStats = useSelector((state: RootState) => {
    return state.companies.stats;
  });
  const handleClick = (e: any): void => {
    e.preventDefault();

    setModalVisible(true);
  };

  const handleHideModal = (): void => {
    setModalVisible(false);
  };

  if (!companyStats) {
    return null;
  }

  const isUserPresentAndActive =
    find(user.charters, (charter) => {
      return charter.currentUser.isActive;
    }) !== undefined;

  const canLinkUserToCharters = isUserPresentAndActive || companyStats.canLinkUserToCharters();
  const isVisible = visible && canLinkUserToCharters ? 'visible' : 'hidden';
  return (
    <>
      <Tag className={classes.tag} onClick={handleClick} style={{ visibility: isVisible }}>
        <PlusOutlined />
      </Tag>
      <LinkCharterModal
        visible={modalVisible}
        callBackHideModal={handleHideModal}
        user={user}
        callbackLinkUserToCharter={callbackLinkUserToCharter}
      />
    </>
  );
};

export default LinkUserToCharterTag;
