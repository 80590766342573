import { Form, InputNumber as AntInputNumber } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { createUseStyles } from 'react-jss';

type Props = {
  name: string;
  label: string;
  step?: number;
  min?: number;
  max?: number;
  onChange?: (newValue?: number) => void;
  className?: string;
  errorText?: string;
  required?: boolean;
};

const useStyles = createUseStyles({
  inputNumber: {
    width: '100%',
  },
  tooltip: {
    marginLeft: 8,
  },
});

const InputNumber: FunctionComponent<Props> = ({
  name,
  label,
  min = 0,
  max = undefined,
  step = 1,
  onChange,
  className,
  errorText = '',
  required = false,
}: Props) => {
  const classes = useStyles();

  const onInputChange = (newValue?: string | number | null): void => {
    if (typeof newValue === 'number') {
      if (onChange) {
        onChange(newValue);
      }
    }
  };

  return (
    <Form.Item name={name} label={label} rules={[{ required, message: errorText }]}>
      <AntInputNumber
        step={step}
        min={min}
        max={max}
        className={classNames(classes.inputNumber, className)}
        onChange={onInputChange}
      />
    </Form.Item>
  );
};

export default InputNumber;
