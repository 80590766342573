import { DeleteOutlined, MoreOutlined, StarFilled } from '@ant-design/icons';
import { FormOutlined, VideoCameraAddOutlined } from '@ant-design/icons/lib';
import { Button, Form, message, Modal, Popconfirm, Popover, Select, Tooltip } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Store } from 'antd/lib/form/interface';
import { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import { find, map } from 'lodash';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoDuplicateOutline } from 'react-icons/io5';
import { createUseStyles } from 'react-jss';
import OutsideClickHandler from 'react-outside-click-handler';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AccessChecker from '../../../../components/access-checker/AccessChecker';
import TextInput from '../../../../components/form/TextInput';
import MediaSelector from '../../../../components/media-selector/MediaSelector';
import FormatTag from '../../../../components/tag/format-tag/FormatTag';
import {
  AXIOS_PARAMS,
  DEVICE_SIZES_QUERIES,
  LINK,
  LOGGING_EVENT,
  SCENARIO_COVER_FORMAT,
  THEME_KEY,
} from '../../../../Constants';
import useCanAccess from '../../../../hooks/useCanAccess';
import { RootState } from '../../../../redux/RootState';
import { APIManager } from '../../../../services/api/APIManager';
import {
  cloneCharterScenario,
  createCharterScenario,
  deleteCharterScenario,
  updateCharterScenarioMetadata,
} from '../../../../services/api/ChartersService';
import {
  APICreateCharterScenarioParams,
  APIUpdateCharterScenarioMetadataParams,
  Scenario,
} from '../../../../services/api/types/ChartersServiceTypes';
import CompaniesUtils from '../../../../utils/CompaniesUtils';
import ScenarioUtils from '../../../../utils/ScenarioUtils';
import { MediaType } from '../../../../utils/types/MediaTypes';
import { ScenarioFormats, ScenarioLanguages, ScenarioScopes } from '../../../../utils/types/ScenarioTypes';
import { AnimationTheme } from '../../../../utils/types/ThemeTypes';

const { Option } = Select;

type Props = {
  scenarioToEdit?: Scenario;
  mode: 'creation' | 'edition';
  triggerClassName?: string;
};

const useStyles = createUseStyles({
  modal: {
    width: '60% !important',
  },
  form: {
    height: '100%',
    '& label': {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      height: 'unset !important',
      whiteSpace: 'normal',
      '&::before': {
        marginRight: '0 !important',
      },

      [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
        justifyContent: 'flex-start',
      },
    },
    '& .ant-select-selection-item, & .ant-select-item-option-content': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  variantDetail: {
    marginLeft: '5px',
    display: 'flex',
  },
  deleteScenarioConfirm: {
    maxWidth: 350,
  },
  isMainIcon: {
    color: '#FADB14',
    fontSize: '14px',
    marginRight: 4,
  },
  scenarioActionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  duplicateScenarioButton: {
    marginLeft: 8,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      marginRight: 8,
    },
  },
  deleteScenarioButton: {
    marginLeft: 8,
  },
});

const CreateOrEditScenarioModal: FunctionComponent<Props> = ({ scenarioToEdit, mode, triggerClassName }: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const history = useHistory();
  const formRef = useRef<FormInstance>(null);
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);
  const [initialValues, setInitialValues] = useState<Store>();
  const [isAsyncLoading, setIsAsyncLoading] = useState(false);
  const [isScenarioDeleteLoading, setIsScenarioDeleteLoading] = useState(false);
  const [isScenarioActionPopoverVisible, setIsScenarioActionPopoverVisible] = useState(false);
  const [isCloneLoading, setIsCloneLoading] = useState(false);

  const companies = useSelector((state: RootState) => state.companies.list);
  const company = useSelector((state: RootState) => state.companies.current);
  const charter = useSelector((state: RootState) => state.charters.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);
  const isKnlTeam = companies ? CompaniesUtils.checkIsKnlProfile(companies) : false;

  const permissionToUseScenario = useCanAccess(scenarioToEdit ? `SCENARIO_${scenarioToEdit.code}` : undefined);
  const hasUserAccessToScenario = permissionToUseScenario.hasUserAccess();

  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());

  useEffect(() => {
    const cancelTokenSource = cancelTokenSourceRef.current;

    return (): void => {
      cancelTokenSource.cancel(
        mode === 'edition'
          ? 'Cancelled updating the scenario metadata due to component unmount.'
          : 'Cancelled creation the scenario due to component unmount.'
      );
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isVisible) {
      setIsAsyncLoading(false);

      if (formRef.current && form) {
        form.resetFields();
      }
    }
  }, [isVisible, form]);

  useEffect(() => {
    let formInitialValues;

    if (mode === 'creation') {
      formInitialValues = {
        scope: !isKnlTeam ? ScenarioScopes.PRIVATE.code : undefined, // Non-KnlTeam users can only create 'PRIVATE' scenarios
      };
    } else if (mode === 'edition' && scenarioToEdit) {
      const mainVariant = find(scenarioToEdit.variants, (variant) => variant.isMain);
      formInitialValues = {
        scope: scenarioToEdit.scope,
        coverImageSource: scenarioToEdit.coverImageSource,
        mainVariant: mainVariant?.id,
      };
    }

    setInitialValues(formInitialValues);
  }, [scenarioToEdit, mode, isKnlTeam]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const onShowPopoverActions = (): void => {
    setIsScenarioActionPopoverVisible(true);
  };

  const onHideActionsPopover = (): void => {
    setIsScenarioActionPopoverVisible(false);
  };

  const onShowModal = (): void => {
    onHideActionsPopover();
    setIsVisible(true);
  };

  const onHideModal = (): void => {
    setIsVisible(false);
  };

  const onDuplicateScenario = (): void => {
    if (!(charter && scenarioToEdit)) {
      return;
    }

    onShowPopoverActions();
    setIsCloneLoading(true);
    cloneCharterScenario(charter.id, scenarioToEdit.id, cancelTokenSourceRef.current)
      .then(() => {
        message.success(t('charters.scenarios.duplicateScenarioSuccess'));
      })
      .catch((e) => {
        log.debug(e.message);
        message.error(t('charters.scenarios.duplicateScenarioError'));
      })
      .finally(() => {
        setIsCloneLoading(false);
        onHideActionsPopover();
      });
  };

  const handleSubmit = (): void => {
    if ((!formRef.current && form) || !charter) {
      return;
    }

    const cancelTokenSource = cancelTokenSourceRef.current;

    form.validateFields().then((values) => {
      setIsAsyncLoading(true);

      if (mode === 'edition' && scenarioToEdit) {
        const updatedScenarioValues: APIUpdateCharterScenarioMetadataParams = {
          scope: values.scope ?? ScenarioScopes.PRIVATE.code,
          coverImageSourceMedia: {
            id: values.coverImageSource.id,
            croppedArea: values.coverImageSource.croppedArea,
          },
          // BALI is the imposed defaultTheme
          defaultTheme: AnimationTheme.BALI,
          isMain: values.mainVariant,
        };

        loggingManager.logEvent(LOGGING_EVENT.UPDATE_SCENARIO_METADATA, {
          charterId: charter.id,
          companyId: company?.id,
          scenarioId: scenarioToEdit.id,
          ...updatedScenarioValues,
        });

        updateCharterScenarioMetadata(
          charter.id,
          scenarioToEdit.id,
          updatedScenarioValues,
          isKnlTeam ? { timeout: AXIOS_PARAMS.TIMEOUT_IN_MS_LARGE_FOR_HEAVY_OPERATIONS } : undefined,
          cancelTokenSource
        )
          .then(() => {
            message.success(t('charters.scenarios.scenarioMetadataForm.metadataUpdateSuccess'));

            onHideModal();
          })
          .catch((error) => {
            message.error(t('charters.scenarios.scenarioMetadataForm.metadataUpdateError'));
            log.error(error);
          })
          .finally(() => {
            setIsAsyncLoading(false);
          });
      } else {
        const newScenarioValues: APICreateCharterScenarioParams = {
          scope: values.scope ?? ScenarioScopes.PRIVATE.code,
          mainVariantTitle: values.mainVariantTitle,
          mainVariantDescription: values.mainVariantDescription,
          mainVariantLanguage: values.mainVariantLanguage,
          mainVariantFormat: values.mainVariantFormat,
          coverImageSourceMedia: {
            id: values.coverImageSource.id,
            croppedArea: values.coverImageSource.croppedArea,
          },
          // BALI is the imposed defaultTheme
          defaultTheme: THEME_KEY.BALI.code,
        };

        loggingManager.logEvent(LOGGING_EVENT.CREATE_SCENARIO, {
          charterId: charter.id,
          companyId: company?.id,
          ...newScenarioValues,
        });

        let createdScenario: Scenario;

        createCharterScenario(charter.id, newScenarioValues, cancelTokenSource)
          .then((response) => {
            createdScenario = response;
            message.success(t('charters.scenarios.scenarioMetadataForm.scenarioCreationSuccess'));
            setIsAsyncLoading(false);
            onHideModal();
            // Redirect to the editor on the main variant of the created scenario
            if (createdScenario) {
              const scenarioVariantEditorPath =
                charter &&
                LINK.CHARTER_SCENARIO_VARIANT_EDITOR.path
                  .replace(':charterId', charter.id.toString())
                  .replace('(\\d+)', '');

              if (!scenarioVariantEditorPath) {
                return;
              }
              const mainVariant = createdScenario.variants.filter((variants) => variants.isMain)[0];

              history.push({
                pathname: scenarioVariantEditorPath,
                state: {
                  scenarioId: createdScenario.id,
                  scenarioVariantId: mainVariant.id,
                },
              });
            }
          })
          .catch((error) => {
            setIsAsyncLoading(false);
            message.error(t('charters.scenarios.scenarioMetadataForm.scenarioCreationError'));
            log.error(error);
          });
      }
    });
  };

  const onDeleteScenarioConfirmed = (): void => {
    if (!(mode === 'edition' && charter && scenarioToEdit)) {
      return;
    }

    onShowPopoverActions();
    setIsScenarioDeleteLoading(true);
    deleteCharterScenario(charter.id, scenarioToEdit.id)
      .then(() => {
        message.success(t('charters.scenarios.deleteScenarioSuccess'));
        setIsVisible(false);
      })
      .catch((e) => {
        log.error(e);
        message.error(t('charters.scenarios.deleteScenarioError'));
      })
      .finally(() => {
        setIsScenarioDeleteLoading(false);
        onHideActionsPopover();
      });
  };

  return (
    <>
      {mode === 'edition' ? (
        <AccessChecker
          renderUnauthorizedMessage={permissionToUseScenario?.renderUnauthorizedMessage}
          hasAccess={hasUserAccessToScenario}
        >
          <Popover
            visible={isScenarioActionPopoverVisible}
            autoAdjustOverflow
            placement="topRight"
            content={
              <OutsideClickHandler onOutsideClick={onHideActionsPopover} display="contents">
                <div
                  className={classes.scenarioActionsContainer}
                  onMouseEnter={hasUserAccessToScenario ? onShowPopoverActions : undefined}
                >
                  {/* Edit the scenario metadata */}
                  <Tooltip title={t('charters.scenarios.editMetadataTooltip')} placement="bottom">
                    <Button
                      type="primary"
                      ghost
                      icon={<FormOutlined />}
                      disabled={isAsyncLoading || isScenarioDeleteLoading || isCloneLoading}
                      size="small"
                      onClick={onShowModal}
                    >
                      {t('global.edit')}
                    </Button>
                  </Tooltip>

                  {/* Duplicate the scenario */}
                  <Tooltip title={t('charters.scenarios.duplicateScenarioTooltip')} placement="bottom">
                    <Button
                      type="default"
                      icon={<IoDuplicateOutline />}
                      size="small"
                      onClick={onDuplicateScenario}
                      disabled={isAsyncLoading || isScenarioDeleteLoading || isCloneLoading}
                      loading={isCloneLoading}
                      className={classes.duplicateScenarioButton}
                    >
                      {t('charters.scenarios.duplicateScenario')}
                    </Button>
                  </Tooltip>

                  {/* Delete the scenario */}
                  <Tooltip title={t('charters.scenarios.deleteScenarioTooltip')} placement="bottom">
                    <Popconfirm
                      title={t('charters.scenarios.deleteScenarioConfirm')}
                      onConfirm={onDeleteScenarioConfirmed}
                      okText={t('global.yesDelete')}
                      cancelText={t('global.no')}
                      okButtonProps={{ danger: true }}
                      overlayClassName={classes.deleteScenarioConfirm}
                    >
                      <Button
                        key="delete"
                        danger
                        size="small"
                        disabled={isAsyncLoading || isScenarioDeleteLoading || isCloneLoading}
                        loading={isScenarioDeleteLoading}
                        className={classes.deleteScenarioButton}
                      >
                        <DeleteOutlined /> {t('global.delete')}
                      </Button>
                    </Popconfirm>
                  </Tooltip>
                </div>
              </OutsideClickHandler>
            }
          >
            <span className={classNames(triggerClassName, 'scenarioActionsButton')}>
              <Button
                shape="circle"
                icon={<MoreOutlined />}
                size="small"
                disabled={!hasUserAccessToScenario}
                onClick={hasUserAccessToScenario ? onShowPopoverActions : undefined}
              />
            </span>
          </Popover>
        </AccessChecker>
      ) : (
        <Button type="primary" onClick={onShowModal} className={triggerClassName}>
          <VideoCameraAddOutlined /> {t('charters.scenarios.createScenario')}
        </Button>
      )}

      <Modal
        visible={isVisible}
        title={t(
          mode === 'edition' ? 'charters.scenarios.editScenarioTitle' : 'charters.scenarios.createScenarioTitle'
        )}
        className={classes.modal}
        onCancel={onHideModal}
        footer={[
          <Button key="cancel" onClick={onHideModal} disabled={isAsyncLoading}>
            {t('global.cancel')}
          </Button>,
          <Button key="submit" type="primary" loading={isAsyncLoading} disabled={isAsyncLoading} onClick={handleSubmit}>
            {t('global.submit')}
          </Button>,
        ]}
      >
        <Form
          form={form}
          ref={formRef}
          {...formItemLayout}
          validateMessages={{
            required: t('global.requiredField'),
          }}
          initialValues={initialValues}
          className={classes.form}
          name={`${mode}-${scenarioToEdit?.id}`}
        >
          {mode === 'creation' && (
            <>
              {/* mainVariantTitle */}
              <TextInput
                fieldName="mainVariantTitle"
                label={t('charters.scenarios.scenarioMetadataForm.mainVariantTitleLabel')}
                extra={t('charters.scenarios.scenarioMetadataForm.mainVariantTitleExtra')}
                placeholder={t('charters.scenarios.scenarioMetadataForm.mainVariantTitlePlaceholder')}
                requiredMessage={t('global.requiredField')}
                emptyMessage={t('global.notEmptyField')}
                hasFeedback
              />

              {/* mainVariantDescription */}
              <TextInput
                fieldName="mainVariantDescription"
                label={t('charters.scenarios.scenarioMetadataForm.mainVariantDescriptionLabel')}
                extra={t('charters.scenarios.scenarioMetadataForm.mainVariantDescriptionExtra')}
                placeholder={t('charters.scenarios.scenarioMetadataForm.mainVariantDescriptionPlaceholder')}
                hasFeedback
              />

              {/* mainVariantLanguage */}
              <Form.Item
                name="mainVariantLanguage"
                label={t('charters.scenarios.scenarioMetadataForm.mainVariantLanguageLabel')}
                rules={[{ required: true }]}
                extra={t('charters.scenarios.scenarioMetadataForm.mainVariantLanguageExtra')}
                hasFeedback
              >
                <Select
                  getPopupContainer={(trigger): HTMLElement => trigger.parentNode}
                  placeholder={t('charters.scenarios.scenarioMetadataForm.mainVariantLanguagePlaceholder')}
                >
                  {map(ScenarioLanguages, (language) => (
                    <Option key={language.code} value={language.code}>
                      {t(language.key)} {language.flag}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              {/* mainVariantFormat */}
              <Form.Item
                name="mainVariantFormat"
                label={t('charters.scenarios.scenarioMetadataForm.mainVariantFormatLabel')}
                rules={[{ required: true }]}
                extra={t('charters.scenarios.scenarioMetadataForm.mainVariantFormatExtra')}
                hasFeedback
              >
                <Select
                  getPopupContainer={(trigger): HTMLElement => trigger.parentNode}
                  placeholder={t('charters.scenarios.scenarioMetadataForm.mainVariantFormatPlaceholder')}
                >
                  {map(ScenarioFormats, (format) => (
                    <Option key={format.code} value={format.code}>
                      <FormatTag format={t(format.key)} image={format.image} />
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}

          {/* Scope */}
          <Form.Item
            name="scope"
            label={t('charters.scenarios.scenarioMetadataForm.scopeLabel')}
            rules={[{ required: true }]}
            extra={t('charters.scenarios.scenarioMetadataForm.scopeExtra')}
            hasFeedback
          >
            <Select
              getPopupContainer={(trigger): HTMLElement => trigger.parentNode}
              placeholder={t('charters.scenarios.scenarioMetadataForm.scopePlaceholder')}
              disabled={!isKnlTeam}
            >
              {map(ScenarioScopes, (scope) => {
                return (
                  <Option key={scope.code} value={scope.code}>
                    {t(scope.key)}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          {/* coverImageSource */}
          <Form.Item
            name="coverImageSource"
            label={t('charters.scenarios.scenarioMetadataForm.coverImageSourceLabel')}
            extra={t('charters.scenarios.scenarioMetadataForm.coverImageSourceExtra')}
            rules={[{ required: true }]}
            hasFeedback
          >
            <MediaSelector
              fieldLabel={t('charters.scenarios.scenarioMetadataForm.coverImageSourceLabel')}
              mediaTypes={[MediaType.IMAGE]}
              format={SCENARIO_COVER_FORMAT}
            />
          </Form.Item>

          {/* mainVariant */}
          {mode === 'edition' && scenarioToEdit && (
            <Form.Item
              name="mainVariant"
              label={
                <span>
                  <StarFilled className={classes.isMainIcon} />
                  {t('charters.scenarios.scenarioMetadataForm.mainVariantLabel')}
                </span>
              }
              rules={[{ required: true }]}
              extra={t('charters.scenarios.scenarioMetadataForm.mainVariantExtra')}
              hasFeedback
            >
              <Select
                getPopupContainer={(trigger): HTMLElement => trigger.parentNode}
                placeholder={t('charters.scenarios.scenarioMetadataForm.mainVariantPlaceholder')}
              >
                {map(scenarioToEdit.variants, (variant) => {
                  const format = ScenarioUtils.getVariantFormatByCode(variant.format);
                  return (
                    <Option key={variant.id} value={variant.id}>
                      {variant.title}
                      <span className={classes.variantDetail}>
                        {ScenarioUtils.getVariantLanguage(variant.language)?.flag}
                      </span>
                      {format && (
                        <span className={classes.variantDetail}>
                          <FormatTag format={t(format.key)} image={format.image} />
                        </span>
                      )}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default CreateOrEditScenarioModal;
