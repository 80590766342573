import { CloseOutlined } from '@ant-design/icons/lib';
import { Button } from 'antd';
import React, { FunctionComponent } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  tooltip: {
    width: 320,
    display: 'flex',
    padding: '24px 16px 16px 16px',
    borderRadius: 5,
    flexDirection: 'column',
    boxShadow: '0 8px 8px 0 hsla(0, 0%, 0%, 0.15) !important',
    backgroundColor: '#fff',
    zIndex: 10000,
  },
  closeButton: {
    backgroundColor: 'transparent',
    border: 0,
    borderRadius: 0,
    cursor: 'pointer',
    fontSize: 16,
    appearance: 'none',
    position: 'absolute',
    boxShadow: 'unset',
    right: 0,
    top: 16,
    '&:hover': {
      background: 'none',
    },
  },
  textContainer: {
    padding: '0px 8px 16px 8px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  skipButton: {
    padding: '4px 8px',
  },
});

type TooltipProps = {
  continuous: any;
  index: any;
  step: any;
  size: number;
  backProps: any;
  closeProps: any;
  skipProps: any;
  primaryProps: any;
  tooltipProps: any;
};

const KannelleJoyRideTooltip: FunctionComponent<TooltipProps> = ({
  continuous,
  index,
  step,
  size,
  backProps,
  skipProps,
  primaryProps,
  tooltipProps,
}: TooltipProps) => {
  const classes = useStyles();
  return (
    <div {...tooltipProps} className={classes.tooltip}>
      <div className={classes.textContainer}>
        {step.title && <h3>{step.title}</h3>}
        <div>{step.content}</div>
      </div>
      <div className={classes.buttonContainer}>
        <div>
          {skipProps && (
            <Button className={classes.skipButton} type="link" danger {...skipProps}>
              {skipProps.title}
            </Button>
          )}
        </div>
        <div>
          {!step.hideBackButton && index > 0 && (
            <Button type="link" {...backProps}>
              {backProps.title}
            </Button>
          )}
          {continuous && (
            <Button type="primary" {...primaryProps}>
              {primaryProps.title} {index + 1 !== size ? `(${index + 1}/${size})` : null}
            </Button>
          )}
        </div>
        {skipProps && (
          <Button {...skipProps} className={classes.closeButton}>
            <CloseOutlined />
          </Button>
        )}
      </div>
    </div>
  );
};

export default KannelleJoyRideTooltip;
