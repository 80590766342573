import i18n from 'i18next';
import log from 'loglevel';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectCharter } from '../redux/action/ChartersAction';
import { APIManager } from '../services/api/APIManager';
import { getCharterById, getCharterPermissions } from '../services/api/ChartersService';
import { APIFullModelCharter } from '../services/api/types/ChartersServiceTypes';
import LocalUtils from '../utils/LocalUtils';

type FetchResult = {
  loading: boolean;
  isError: boolean;
  fetchedCharter?: APIFullModelCharter;
};

const useFetchCharterById = (charterId: number, filterAll = false, statusAll = false): FetchResult => {
  const [fetchedCharter, setFetchedCharter] = useState<APIFullModelCharter | undefined>();
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!charterId) {
      return undefined;
    }

    const cancelTokenSource = APIManager.getCancelToken();
    const lang = LocalUtils.getLang();

    setLoading(true);

    Promise.all([
      getCharterById(charterId, cancelTokenSource, filterAll, statusAll),
      getCharterPermissions(charterId, lang, cancelTokenSource),
    ])
      .then(([charterResponse, permissionsResponse]) => {
        setLoading(false);
        setIsError(false);
        const { data } = charterResponse;
        const permissions = permissionsResponse.data.items;
        setFetchedCharter(data);
        dispatch(selectCharter(data, permissions));
      })
      .catch((e) => {
        log.debug('Error during charter fetch', e);
        setLoading(false);
        setIsError(true);
      });

    return (): void => {
      cancelTokenSource.cancel('Fetch charter by id canceled due to component unmounting');
    };
    // eslint-disable-next-line
  }, [charterId, i18n.language]);

  return {
    loading,
    fetchedCharter,
    isError,
  };
};

export default useFetchCharterById;
