import { EditFilled, LoadingOutlined } from '@ant-design/icons';
import { message, Switch, Tag } from 'antd';
import classNames from 'classnames';
import { find } from 'lodash';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import KannelleRetryButton from '../../../../../components/retry-button/KannelleRetryButton';
import { LOGGING_EVENT, THEME } from '../../../../../Constants';
import { Roles } from '../../../../../core/rule/RolesTypes';
import { updateCurrentUserInCharterList, updateUserInCurrentCharter } from '../../../../../redux/action/ChartersAction';
import { setCompanyStats } from '../../../../../redux/action/CompaniesAction';
import { RootState } from '../../../../../redux/RootState';
import { updateCharterUserStatusByCharterId } from '../../../../../services/api/ChartersService';
import { UsersModelCharter } from '../../../../../services/api/types/ChartersServiceTypes';
import { APICompanyUsers } from '../../../../../services/api/types/CompaniesServiceTypes';
import LogUtils from '../../../../../utils/LogUtils';

type Props = {
  concernedUser: UsersModelCharter;
  activeCompanyUsers: APICompanyUsers[];
  disabled?: boolean;
};

const useStyles = createUseStyles({
  statusTagContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  statusTag: {
    borderRadius: 25,
    border: 'unset',
    marginRight: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  editableStatusTag: {
    cursor: 'pointer',
  },
  nonEditableStatusTag: {
    cursor: 'default',
    pointerEvents: 'none',
  },
  deactivatedUserStatusTag: {
    backgroundColor: THEME.CHARTERS.USERS.INACTIVE.BACKGROUND_COLOR,
    color: THEME.CHARTERS.USERS.INACTIVE.TEXT_COLOR,
  },
  isActiveText: {
    marginLeft: '5px',
  },
  actionsContainer: {
    '& :not(:last-child)': {
      marginRight: '5px',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  retryButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  editStatusIcon: {
    fontSize: '10px',
    marginLeft: '5px',
    cursor: 'pointer',
  },
  loadingIcon: {
    fontSize: '10px',
    marginLeft: '5px',
  },
});

const KannelleStatusSwitch: FunctionComponent<Props> = ({
  concernedUser,
  activeCompanyUsers,
  disabled = false,
}: Props) => {
  const classes = useStyles({ disabled });
  const [isLoading, setIsLoading] = useState(concernedUser.updating?.isActive || false);
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation();
  const currentCharter = useSelector((state: RootState) => state.charters.current);
  const companyStats = useSelector((state: RootState) => state.companies.stats);
  const currentCompany = useSelector((state: RootState) => state.companies.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);
  const currentUser = useSelector((state: RootState) => state.user.user);
  const dispatch = useDispatch();

  const { isActive, role } = concernedUser?.acl;
  const tagText = isActive ? t('users.statusActive') : t('users.statusInactive');

  const updateStats = (newStatus: boolean, userRole: Roles): void => {
    if (!companyStats || userRole === Roles.KnlTeam) {
      return;
    }

    if (newStatus) {
      companyStats.userActivated();
    } else {
      companyStats.userDeactivated();
    }

    dispatch(setCompanyStats(companyStats));
  };

  const toggleUserStatus = (): void => {
    const newStatus = !isActive;

    const isUserPresentAndActive =
      find(activeCompanyUsers, (user) => {
        return user.email === concernedUser.email;
      }) !== undefined;

    if (
      newStatus &&
      role !== Roles.KnlTeam &&
      !companyStats?.canCreateNewUser() &&
      !companyStats?.canLinkUserToCharters(isUserPresentAndActive)
    ) {
      message.error(t('global.maxNbLicensesReachedError', { count: companyStats?.users.max }));
      return;
    }

    setIsLoading(true);
    setIsError(false);

    const event = LogUtils.getUsersUpdateEvent(
      LOGGING_EVENT.UPDATE_USER,
      concernedUser.id,
      { isActive: newStatus },
      currentCharter!.id,
      currentCompany?.id
    );
    loggingManager.logEventObject(event);

    updateCharterUserStatusByCharterId(currentCharter!.id, concernedUser.id, newStatus)
      .then((response) => {
        if (response.data) {
          updateStats(newStatus, role);
          dispatch(updateUserInCurrentCharter(response.data));

          if (currentUser?.id === concernedUser.id) {
            dispatch(updateCurrentUserInCharterList(response.data, currentCharter!.id));
          }
          setIsLoading(false);
        }
      })
      .catch((error) => {
        const errorResponse = error.response;
        const errorResponseData = error.response?.data;

        log.error(error);
        if (errorResponseData && errorResponseData.data && errorResponse.status === 403) {
          message.error(<>{t('users.bundleChangeIsActiveError', { role: t(`roles.names.${role}`) })}</>, 3);
        } else {
          message.error(
            <>
              {t('users.updateIsActive.error')}
              <br />
              {t('support.global.emojiFingerRight')}
              <a href={`mailto:${t('support.global.supportEmail')}`}>{t('support.global.supportEmail')}</a>
              {t('support.global.emojiFingerLeft')}
            </>
          );

          setIsError(true);
        }

        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(concernedUser.updating?.isActive || false);
  }, [concernedUser.updating]);

  return (
    <div className={classes.statusTagContainer}>
      <Tag
        className={classNames(
          classes.statusTag,
          !isActive && classes.deactivatedUserStatusTag,
          !disabled && !isLoading ? classes.editableStatusTag : classes.nonEditableStatusTag,
          'statusTag'
        )}
        onClick={!disabled && !isLoading ? toggleUserStatus : undefined}
      >
        <Switch size="small" checked={isActive} disabled={disabled || isLoading} />
        <span className={classes.isActiveText}>{tagText.toUpperCase()}</span>
      </Tag>

      <span className={classes.actionsContainer}>
        {!disabled && !isLoading && isError && (
          <KannelleRetryButton
            onClick={(e): void => {
              e.stopPropagation();
              toggleUserStatus();
            }}
          />
        )}
        {!disabled && !isLoading && <EditFilled className={classes.editStatusIcon} onClick={toggleUserStatus} />}
        {!disabled && isLoading && <LoadingOutlined className={classes.loadingIcon} />}
      </span>
    </div>
  );
};

export default KannelleStatusSwitch;
