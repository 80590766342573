import { Form, message } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { DEVICE_SIZES_QUERIES } from '../../Constants';
import ColorsUtils from '../../utils/ColorsUtils';
import AddColorCard from '../card/AddColorCard';
import ColorCard from '../card/ColorCard';

type Props = {
  label?: string;
  extra?: string;
  className?: string;
  limit?: number;
  colorsList: string[];
  onColorsListChange: (newColors: string[]) => void;
  preventWhite: boolean;
  preventBlack: boolean;
};

const useStyles = createUseStyles({
  colorsContainer: {
    marginBottom: '20px',
    display: 'grid',
    gridColumnGap: '5px',
    gridRowGap: '20px',

    [`@media screen and ${DEVICE_SIZES_QUERIES.LARGE}`]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))',
    },
    [`@media screen and ${DEVICE_SIZES_QUERIES.MEDIUM}`]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(125px, 1fr))',
    },
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
    },
  },
});

const Colors: FunctionComponent<Props> = ({
  label,
  extra,
  colorsList,
  onColorsListChange,
  className,
  limit,
  preventWhite,
  preventBlack,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const isColorsListReducible = colorsList.length > 1;

  const onAddColor = (): void => {
    const newColor = ColorsUtils.generateRandomColor();
    onColorsListChange([...colorsList, newColor]);
  };

  const onEditColor = (indexOfColorToReplace: number, newColor: string): void => {
    if (preventWhite && newColor === '#FFFFFF') {
      message.error(t('form.colorsNotWhite'), 5);
      return;
    }
    if (preventBlack && newColor === '#000000') {
      message.error(t('form.colorsNotBlack'), 5);
      return;
    }

    const newColorsList = [...colorsList];
    newColorsList[indexOfColorToReplace] = newColor;
    onColorsListChange(newColorsList);
  };

  const onDeleteColor = (indexOfColorToDelete: number): void => {
    const newColorsList = [...colorsList];
    newColorsList.splice(indexOfColorToDelete, 1);
    onColorsListChange(newColorsList);
  };

  const validateColor = (): Promise<void> => {
    if (colorsList.length > 0) {
      return Promise.resolve();
    }

    return Promise.reject(t('form.colorsRuleRequiredError'));
  };

  return (
    <Form.Item
      label={label}
      name="colors"
      className={className || ''}
      rules={[
        { required: true, message: t('form.colorsRuleRequiredError') },
        { validator: validateColor, message: t('form.colorsRuleRequiredError') },
      ]}
      extra={extra}
    >
      <div className={classes.colorsContainer}>
        {colorsList
          .map((color, index) => ({
            color,
            id: `${color}-${index}`,
          }))
          .map(({ color, id }, index) => (
            <ColorCard
              key={id}
              color={color}
              size="small"
              onEditColor={(newColor: string): void => onEditColor(index, newColor)}
              onDeleteColor={isColorsListReducible ? (): void => onDeleteColor(index) : undefined}
            />
          ))}

        {(!limit || (limit && limit > colorsList.length)) && <AddColorCard onClick={onAddColor} />}
      </div>
    </Form.Item>
  );
};

export default Colors;
