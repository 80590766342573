import { LoginOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { LINK, THEME } from '../../Constants';
import { RootState } from '../../redux/RootState';
import CompaniesUtils from '../../utils/CompaniesUtils';
import AdminBundlesCard from './bundles/AdminBundlesCard';
import AdminChartersCard from './charters/AdminChartersCard';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
  },
  card: {
    width: 300,
    marginLeft: 8,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    backgroundColor: THEME.DEFAULT.MAIN_COLOR,
    color: '#ffffff',
    borderRadius: 25,
    marginRight: 8,
    fontSize: 18,
    width: 24,
    height: 24,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px',
  },
});

const Admin: FunctionComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const companies = useSelector((state: RootState) => {
    return state.companies.list;
  });
  const isUserKnlTeamInCharter = CompaniesUtils.checkIsKnlProfile(companies || []);

  if (!isUserKnlTeamInCharter) {
    history.push(LINK.UNAUTHORIZED.path);
  }

  return (
    <div className={classes.container}>
      <Card
        className={classes.card}
        title={
          <div className={classes.titleContainer}>
            <UnorderedListOutlined className={classes.icon} /> {t(LINK.ADMIN_TRANSLATION.title)}
          </div>
        }
        extra={
          <Link to={LINK.ADMIN_TRANSLATION.path}>
            <Button type="primary" icon={<LoginOutlined />}>
              {t('global.go')}
            </Button>
          </Link>
        }
      >
        {t('admin.translation.desc')}
      </Card>
      <AdminBundlesCard classes={classes} />

      <AdminChartersCard classes={classes} />
    </div>
  );
};

export default Admin;
