import { Form, Input } from 'antd';
import React, { FunctionComponent } from 'react';

type Props = {
  label?: string;
  extra?: string;
  placeholder?: string;
  fieldName: string;
  icon?: JSX.Element;
  disabled?: boolean;
  requiredMessage?: string;
  emptyMessage?: string;
  hasFeedback?: boolean;
};

const TextInput: FunctionComponent<Props> = ({
  label,
  extra,
  placeholder,
  fieldName,
  icon,
  disabled = false,
  requiredMessage,
  emptyMessage,
  hasFeedback = true,
}: Props) => {
  return (
    <Form.Item
      label={label}
      name={fieldName}
      rules={[
        { type: 'string' },
        ...(requiredMessage ? [{ required: true, message: requiredMessage }] : []),
        ...(emptyMessage ? [{ whitespace: true, message: emptyMessage }] : []),
      ]}
      extra={extra}
      hasFeedback={hasFeedback}
    >
      <Input prefix={icon} disabled={disabled} placeholder={placeholder} />
    </Form.Item>
  );
};

export default TextInput;
