import { ToolOutlined } from '@ant-design/icons/lib';
import { Row } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import KannellePageHeader from '../../components/page-header/KannellePageHeader';
import { DEVICE_SIZES_QUERIES, LINK, THEME } from '../../Constants';
import { RootState } from '../../redux/RootState';
import CompaniesUtils from '../../utils/CompaniesUtils';
import NotificationConfigs from './components/NotificationConfigs';
import NotificationParameters from './components/NotificationParameters';

const useStyles = createUseStyles({
  pageContent: {
    backgroundColor: '#FFFFFF',
    margin: 24,
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      margin: 0,
    },
  },
  avatar: {
    backgroundColor: THEME.DEFAULT.MAIN_COLOR,
  },
  subtitleRow: {
    marginBottom: 16,
  },
});

const NotificationSettings: FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const companies = useSelector((state: RootState) => {
    return state.companies.list;
  });

  const isUserKnlTeamInCharter = CompaniesUtils.checkIsKnlProfile(companies || []);

  // TODO: Remove this temporary fix
  if (!isUserKnlTeamInCharter) {
    history.push(LINK.UNAUTHORIZED.path);
  }

  return (
    <KannellePageHeader
      title={t('notificationConfigs.parameters.title')}
      className={classes.pageContent}
      avatar={{ className: classes.avatar, icon: <ToolOutlined /> }}
    >
      <Row className={classes.subtitleRow}>
        <p>{t('notificationConfigs.parameters.pageDescription')}</p>
      </Row>

      <NotificationParameters isKnlTeam={isUserKnlTeamInCharter} />
      <NotificationConfigs isKnlTeam={isUserKnlTeamInCharter} />
    </KannellePageHeader>
  );
};

export default NotificationSettings;
