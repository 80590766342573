import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Col, Modal, Row } from 'antd';
import classNames from 'classnames';
import map from 'lodash/map';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdColorPalette } from 'react-icons/io';
import { IoColorWand } from 'react-icons/io5';
import { MdBorderStyle } from 'react-icons/md';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import AccessChecker from '../../../../../../components/access-checker/AccessChecker';
import { THEME } from '../../../../../../Constants';
import useCanAccess from '../../../../../../hooks/useCanAccess';
import { RootState } from '../../../../../../redux/RootState';
import StandardColors from '../../../../../../resources/img/theme-default-colors.png';
import ImagePlaceholder from '../../../../../../resources/img/womanBehindDesk.jpg';
import ProjectUtils from '../../../../../../utils/ProjectUtils';
import { MaskCodes } from '../../../../../../utils/types/AnimationTypes';
import { CustomColors, PermissionList } from '../../../../../../utils/types/CharterPermissionTypes';
import { ProjectMask, ProjectTheme, ProjectThemeParameters } from '../../../../../../utils/types/ProjectTypes';
import { AnimationTheme } from '../../../../../../utils/types/ThemeTypes';

type Props = {
  value?: ProjectThemeParameters;
  isVisible: boolean;
  fieldLabel: string;
  onCancel: () => void;
  onSubmit: (values: ProjectThemeParameters) => void;
};

type StyleProps = {
  hasMaskAccess: boolean;
};

const useStyles = createUseStyles({
  modal: {
    width: '75% !important',
  },
  fieldLabel: {
    display: 'flex',
    height: 32,
    justifyContent: 'flex-end',
    alignItems: 'center',
    '&::after': {
      content: "':'",
      position: 'relative',
      top: '-0.5px',
      margin: '0 8px 0 2px',
    },
  },
  fieldLabelIcon: {
    marginRight: 6,
  },
  formFieldContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 40,
  },
  fieldSelectorColumn: {
    display: 'flex',
    alignItems: 'center',
  },
  colorsSelectorRow: {
    width: '100%',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
  },
  disabledElement: {
    opacity: '0.4 !important',
    cursor: 'not-allowed',
  },
  selectedDisabledCard: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    outline: `6px solid ${THEME.MENU.MAIN_COLOR}`,
    outlineOffset: '-6px',
    cursor: 'not-allowed',
  },
  cardWrapper: {
    display: 'flex',
    borderRadius: 5,
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    boxShadow: `2px 2px 7px 0px ${THEME.DEFAULT.BORDER_COLOR}`,
  },
  cardCoverImage: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    opacity: 0.7,
  },
  selectedThemeCoverImage: {
    outline: `6px solid ${THEME.MENU.MAIN_COLOR}`,
    outlineOffset: '-6px',
    opacity: 1,
  },
  cardTitle: {
    color: '#FFFFFF',
    textShadow: '1px 1px 10px #000000',
    padding: 10,
    width: '80%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  selectedMaskCoverImage: {
    opacity: 1,
  },
  selectedMask: ({ hasMaskAccess }: StyleProps) => ({
    position: 'absolute',
    top: '8px',
    right: '12px',
    color: THEME.MENU.MAIN_COLOR,
    fontSize: 18,
    cursor: !hasMaskAccess ? 'not-allowed' : 'pointer',
  }),
  miniMask: {
    outline: `10px solid ${THEME.DEFAULT.MAIN_COLOR_GREEN}`,
    outlineOffset: '-10px',
  },
  themeColorsCard: {
    height: 80,
  },
  charterThemeColorsContainer: {
    width: '100%',
    display: 'flex',
    opacity: 0.7,
  },
  selectedCharterThemeColorsContainer: {
    opacity: 1,
    outline: `6px solid ${THEME.MENU.MAIN_COLOR}`,
    outlineOffset: '-6px',
  },
  charterThemeColorSection: {
    height: 80,
  },
  selectedThemeColorsCoverImage: {
    outline: `6px solid ${THEME.MENU.MAIN_COLOR}`,
    outlineOffset: '-6px',
    opacity: 1,
  },
});

const ProjectThemeSettingsModal: FunctionComponent<Props> = ({
  value,
  isVisible,
  fieldLabel,
  onCancel,
  onSubmit,
}: Props) => {
  const permissionToAccessCustomColors = useCanAccess(PermissionList.CUSTOM_COLORS);
  const permissionToSelectBaliTheme = useCanAccess(PermissionList.THEME_BALI);
  const permissionToSelectBerlinTheme = useCanAccess(PermissionList.THEME_BERLIN);
  const permissionToSelectGenevaTheme = useCanAccess(PermissionList.THEME_GENEVA);
  const permissionToSelectLosAngelesTheme = useCanAccess(PermissionList.THEME_LOS_ANGELES);
  const permissionToSelectSeattleTheme = useCanAccess(PermissionList.THEME_SEATTLE);
  const permissionToSelectAlgiersTheme = useCanAccess(PermissionList.THEME_ALGIERS);
  const permissionToEditFrame = useCanAccess(PermissionList.FRAME);
  const permissionToSelectMiniFrame = useCanAccess(PermissionList.FRAME_MINI);

  const [selectedTheme, setSelectedTheme] = useState(value?.theme ?? AnimationTheme.BALI);
  const [selectedMask, setSelectedMask] = useState(value?.mask ?? MaskCodes.NONE);
  const [isCustomColorsSelected, setIsCustomColorsSelected] = useState(value?.customColors ?? true);

  const charter = useSelector((state: RootState) => state.charters.current);

  const { t } = useTranslation();

  const hasFrameAccess = (projectMask: string) => {
    if (!permissionToEditFrame.hasUserAccess()) {
      return false;
    }
    if (projectMask === ProjectMask.MINI.code) {
      return permissionToSelectMiniFrame.hasUserAccess();
    }
    return true;
  };

  const classes = useStyles({
    hasMaskAccess: hasFrameAccess(selectedMask),
  });

  useEffect(() => {
    setSelectedTheme(value?.theme ?? AnimationTheme.BALI);
    setSelectedMask(value?.mask ?? MaskCodes.NONE);
    setIsCustomColorsSelected(
      ProjectUtils.getPermissionForcedCustomColors(value?.customColors, permissionToAccessCustomColors)
    );
  }, [value, isVisible]);

  useEffect(() => {
    if (!permissionToAccessCustomColors.forcedValue && permissionToAccessCustomColors.hasUserAccess()) {
      return;
    }
    // This effect is here in case the manager decided to change the permissions.
    setIsCustomColorsSelected(
      ProjectUtils.getPermissionForcedCustomColors(value?.customColors, permissionToAccessCustomColors)
    );
  }, [permissionToAccessCustomColors]);

  const leftColumnLayoutProps = { md: 6, lg: 6 };
  const rightColumnLayoutProps = { md: 24 - leftColumnLayoutProps.md, lg: 24 - leftColumnLayoutProps.lg };

  const isSubmitButtonDisabled = () => {
    const maskValue = value?.mask ?? ProjectMask.NONE.code;
    return (
      value &&
      value.theme === selectedTheme &&
      value.customColors === isCustomColorsSelected &&
      maskValue === selectedMask
    );
  };

  const submitForm = () => {
    onSubmit({
      theme: selectedTheme,
      mask: selectedMask,
      customColors: isCustomColorsSelected,
    });
  };

  const getThemePermission = (projectTheme: AnimationTheme) => {
    switch (projectTheme) {
      case AnimationTheme.BALI:
        return permissionToSelectBaliTheme;
      case AnimationTheme.BERLIN:
        return permissionToSelectBerlinTheme;
      case AnimationTheme.GENEVA:
        return permissionToSelectGenevaTheme;
      case AnimationTheme.LOS_ANGELES:
        return permissionToSelectLosAngelesTheme;
      case AnimationTheme.SEATTLE:
        return permissionToSelectSeattleTheme;
      case AnimationTheme.ALGIERS:
        return permissionToSelectAlgiersTheme;
      default:
        return undefined;
    }
  };

  const hasThemeAccess = (projectTheme: AnimationTheme) => {
    const theme = getThemePermission(projectTheme);
    if (!theme) {
      return true;
    }

    return theme.hasUserAccess();
  };

  const renderThemeUnauthorizedMessage = (projectTheme: AnimationTheme) => {
    const theme = getThemePermission(projectTheme);
    if (!theme) {
      return undefined;
    }

    return theme.renderUnauthorizedMessage();
  };

  const renderFrameUnauthorizedMessage = (projectMask: string) => {
    if (!permissionToEditFrame.hasUserAccess()) {
      return permissionToEditFrame.renderUnauthorizedMessage();
    }
    if (projectMask === ProjectMask.MINI.code) {
      return permissionToSelectMiniFrame.renderUnauthorizedMessage();
    }
    return undefined;
  };

  const onSelectTheme = (selectedThemeCode: AnimationTheme) => {
    if (hasThemeAccess(selectedThemeCode)) {
      setSelectedTheme(selectedThemeCode);
    }
  };

  const onSelectMask = (selectedMaskCode: MaskCodes) => {
    if (permissionToEditFrame.hasUserAccess()) {
      if (selectedMaskCode === MaskCodes.MINI && !permissionToSelectMiniFrame.hasUserAccess()) {
        return;
      }
      setSelectedMask(selectedMaskCode);
    }
  };

  const onSelectThemeColors = (themeColors: 'DEFAULT' | 'CHARTER') => {
    if (!permissionToAccessCustomColors.hasUserAccess()) {
      return;
    }
    setIsCustomColorsSelected(themeColors !== 'DEFAULT');
  };

  const renderAnimationThemeSelector = () => {
    return (
      <Row className={classes.formFieldContainer}>
        <Col {...leftColumnLayoutProps} className={classes.fieldLabel}>
          <IoColorWand className={classes.fieldLabelIcon} />
          {t('charters.projects.projectEditor.projectFinalization.graphicalTheme.themeLabel')}
        </Col>
        <Col {...rightColumnLayoutProps} className={classes.fieldSelectorColumn}>
          <Row gutter={[16, 16]}>
            {map(ProjectTheme, (projectTheme) => {
              const isSelected = selectedTheme === projectTheme.code;

              // TODO remove this condition when the back-end is fixed about Bogota animation
              if (projectTheme.code === 'BOGOTA') {
                return undefined;
              }

              return (
                <Col
                  span={6}
                  key={projectTheme.code}
                  className={classNames(classes.cardContainer)}
                  onClick={() => onSelectTheme(projectTheme.code)}
                >
                  <AccessChecker
                    renderUnauthorizedMessage={() => renderThemeUnauthorizedMessage(projectTheme.code)}
                    hasAccess={hasThemeAccess(projectTheme.code)}
                  >
                    <div className={classes.cardWrapper}>
                      <img
                        src={projectTheme.cover}
                        alt={`${projectTheme.code} cover`}
                        className={classNames(
                          classes.cardCoverImage,
                          isSelected && classes.selectedThemeCoverImage,
                          !hasThemeAccess(projectTheme.code) && classes.disabledElement
                        )}
                      />
                      <div
                        className={classNames(
                          classes.cardTitle,
                          !hasThemeAccess(projectTheme.code) && classes.disabledElement
                        )}
                      >
                        {t(projectTheme.key)}
                      </div>
                      {isSelected && !hasThemeAccess(projectTheme.code) && (
                        <div className={classes.selectedDisabledCard} />
                      )}
                    </div>
                  </AccessChecker>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    );
  };

  const renderAnimationMaskSelector = () => {
    return (
      <Row className={classes.formFieldContainer}>
        <Col {...leftColumnLayoutProps} className={classes.fieldLabel}>
          <MdBorderStyle className={classes.fieldLabelIcon} />
          {t('charters.projects.projectEditor.projectFinalization.graphicalTheme.maskLabel')}
        </Col>
        <Col {...rightColumnLayoutProps} className={classes.fieldSelectorColumn}>
          <Row gutter={[16, 16]}>
            {map(ProjectMask, (projectMask) => {
              const isSelected = selectedMask === projectMask.code;
              const isMini = projectMask.code === MaskCodes.MINI;

              return (
                <Col
                  span={6}
                  key={projectMask.code}
                  className={classNames(classes.cardContainer)}
                  onClick={() => onSelectMask(projectMask.code as MaskCodes)}
                >
                  <AccessChecker
                    renderUnauthorizedMessage={() => renderFrameUnauthorizedMessage(projectMask.code)}
                    hasAccess={hasFrameAccess(projectMask.code)}
                  >
                    <div className={classes.cardWrapper}>
                      <img
                        src={ImagePlaceholder}
                        alt={`${projectMask.code} frame`}
                        className={classNames(
                          classes.cardCoverImage,
                          isSelected && classes.selectedMaskCoverImage,
                          isMini && classes.miniMask,
                          !hasFrameAccess(projectMask.code) && classes.disabledElement
                        )}
                      />
                      <div
                        className={classNames(
                          classes.cardTitle,
                          !hasFrameAccess(projectMask.code) && classes.disabledElement
                        )}
                      >
                        {t(projectMask.key)}
                      </div>
                      {isSelected && (
                        <div className={classes.selectedMask}>
                          <CheckCircleFilled />
                        </div>
                      )}
                    </div>
                  </AccessChecker>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    );
  };

  const renderAnimationColorsSelector = () => {
    if (!charter) {
      return null;
    }

    const charterColors = charter.firmColors;
    const charterName = charter.name;

    return (
      <Row>
        <Col {...leftColumnLayoutProps} className={classes.fieldLabel}>
          <IoMdColorPalette className={classes.fieldLabelIcon} />
          {t('charters.projects.projectEditor.projectFinalization.graphicalTheme.animationColorsLabel')}
        </Col>
        <Col {...rightColumnLayoutProps} className={classes.fieldSelectorColumn}>
          <Row gutter={[16, 16]} className={classes.colorsSelectorRow}>
            {/* Charter colors */}
            <AccessChecker
              hasAccess={
                permissionToAccessCustomColors.hasUserAccess() ||
                permissionToAccessCustomColors.forcedValue === CustomColors.CUSTOM.code
              }
              renderUnauthorizedMessage={permissionToAccessCustomColors.renderUnauthorizedMessage}
            >
              <Col
                span={6}
                className={
                  permissionToAccessCustomColors.hasUserAccess() ||
                  permissionToAccessCustomColors.forcedValue === CustomColors.CUSTOM.code
                    ? classes.cardContainer
                    : classes.disabledElement
                }
                onClick={() => onSelectThemeColors('CHARTER')}
              >
                <div className={classes.cardWrapper}>
                  <div
                    className={classNames(
                      classes.charterThemeColorsContainer,
                      isCustomColorsSelected && classes.selectedCharterThemeColorsContainer
                    )}
                  >
                    {charterColors.map((color) => (
                      <div
                        style={{ backgroundColor: color, width: `calc(100% / ${charterColors.length})` }}
                        className={classes.charterThemeColorSection}
                        key={color}
                      />
                    ))}
                  </div>
                  <div className={classes.cardTitle}>{charterName}</div>
                </div>
              </Col>
            </AccessChecker>

            {/* Default theme colors */}
            <AccessChecker
              hasAccess={
                permissionToAccessCustomColors.hasUserAccess() ||
                permissionToAccessCustomColors.forcedValue === CustomColors.ORIGINAL.code
              }
              renderUnauthorizedMessage={permissionToAccessCustomColors.renderUnauthorizedMessage}
            >
              <Col
                span={6}
                className={
                  permissionToAccessCustomColors.hasUserAccess() ||
                  permissionToAccessCustomColors.forcedValue !== CustomColors.CUSTOM.code
                    ? classes.cardContainer
                    : classes.disabledElement
                }
                onClick={() => onSelectThemeColors('DEFAULT')}
              >
                <div className={classes.cardWrapper}>
                  <img
                    src={StandardColors}
                    alt="Standard theme colors"
                    className={classNames(
                      classes.cardCoverImage,
                      classes.themeColorsCard,
                      !isCustomColorsSelected && classes.selectedThemeColorsCoverImage
                    )}
                  />
                  <div className={classes.cardTitle}>
                    {t('charters.projects.projectEditor.projectFinalization.graphicalTheme.standardAnimationColors')}
                  </div>
                </div>
              </Col>
            </AccessChecker>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <Modal
      title={fieldLabel}
      visible={isVisible}
      onCancel={onCancel}
      footer={[
        <Button key="cancelModal" onClick={onCancel}>
          {t('global.cancel')}
        </Button>,
        <Button key="submit" onClick={submitForm} type="primary" disabled={isSubmitButtonDisabled()}>
          {t('global.submit')}
        </Button>,
      ]}
      className={classes.modal}
    >
      {renderAnimationThemeSelector()}

      {renderAnimationMaskSelector()}

      {renderAnimationColorsSelector()}
    </Modal>
  );
};

export default ProjectThemeSettingsModal;
