import { FolderOpenOutlined } from '@ant-design/icons';
import { Col, Empty, Row } from 'antd';
import { BreadcrumbProps } from 'antd/es/breadcrumb';
import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import { CancelTokenSource } from 'axios';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import ErrorAlert from '../../../components/alert/ErrorAlert';
import KannelleLoader from '../../../components/loader/KannelleLoader';
import KannelleHelpButton from '../../../components/page-header/KannelleHelpButton';
import KannellePageHeader from '../../../components/page-header/KannellePageHeader';
import { DEVICE_SIZES_QUERIES, LINK, THEME, WEB_SOCKET_ACTIONS } from '../../../Constants';
import useCanAccess from '../../../hooks/useCanAccess';
import useFetchCharterById from '../../../hooks/useFetchCharterById';
import useSocket from '../../../hooks/useSocket';
import JoyRideHelpCharterProjects from '../../../onboarding/JoyRideHelpCharterProjects';
import {
  addCharterProject,
  deleteCharterProject,
  saveCharterProjects,
  updateCharterProject,
} from '../../../redux/action/ChartersAction';
import { RootState } from '../../../redux/RootState';
import { APIManager } from '../../../services/api/APIManager';
import { getCharterProjects } from '../../../services/api/ChartersService';
import { SocketManager } from '../../../services/api/SocketManager';
import { ProjectDeleteResponse, ProjectUpdateResponse } from '../../../services/api/types/WebSocketTypes';
import { CharterIdPathParam } from '../../../services/navigation/NavigationConfigTypes';
import JoyRideUtils from '../../../utils/JoyRideUtils';
import { PermissionList } from '../../../utils/types/CharterPermissionTypes';
import { Project } from '../../../utils/types/ProjectTypes';
import CreateProjectModal from './components/CreateProjectModal';
import ProjectCard from './components/project-card/ProjectCard';
import ProjectsFilterButton from './components/ProjectsFilterButton';

const useStyles = createUseStyles({
  pageContent: {
    backgroundColor: '#FFFFFF',
    padding: 24,
    margin: 24,
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      margin: 0,
      padding: '5px 10px',
    },
  },
  titleWithIcon: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: 'normal',
  },
  avatar: {
    backgroundColor: THEME.DEFAULT.MAIN_COLOR,
  },
  pageDescription: {
    marginBottom: 16,
    textAlign: 'justify',
  },
  row: {
    margin: '10px !important',
  },
  errorAlert: {
    marginTop: '30px',
  },
  empty: {
    width: '100%',
  },
});

const CharterProjects: FunctionComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { charterId } = useParams<CharterIdPathParam>();
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentCharter = useSelector((state: RootState) => state.charters.current);
  const projects = useSelector((state: RootState) => state.charters.currentCharterProjects);

  const currentCharterId = parseInt(charterId, 10);

  const [runHelp, setRunHelp] = useState(false);

  const { loading: isCharterLoading, isError } = useFetchCharterById(currentCharterId);
  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());

  const { hasUserAccessToCharter } = useCanAccess(PermissionList.WEB_DASHBOARD);

  const [room, setRoom] = useState<string>();
  const [projectsList, setProjectsList] = useState<Project[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const isSocketConnected = useSocket(room ?? undefined);

  useEffect(() => {
    if (!currentCharterId) {
      return;
    }

    setIsLoading(true);
    const cancelTokenSource = cancelTokenSourceRef.current;
    getCharterProjects(currentCharterId, cancelTokenSource)
      .then((response) => {
        const projectListResponse = response.items;
        dispatch(saveCharterProjects(projectListResponse));
        setProjectsList(projectListResponse);
      })
      .catch((e) => {
        log.error('Error during charter projects fetch', e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentCharterId, dispatch]);

  useEffect(() => {
    if (!currentCharter) {
      return;
    }

    setRoom(`charters/${currentCharter.id}/projects`);
  }, [currentCharter]);

  useEffect(() => {
    const handleUpdatedProject = (payload: ProjectUpdateResponse | ProjectDeleteResponse): void => {
      // Check if the action corresponds to one we can handle here
      if (!WEB_SOCKET_ACTIONS.CHARTER_LOCAL_PROJECTS.includes(payload.action)) {
        return;
      }

      if (payload.action === 'project_delete') {
        const deletedProjectId = (payload as ProjectDeleteResponse).data.projectId;
        dispatch(deleteCharterProject(deletedProjectId));
      } else if (payload.action === 'project_put') {
        const createdProject = (payload as ProjectUpdateResponse).data;
        dispatch(addCharterProject(createdProject));
      } else if (payload.action === 'project_patch') {
        const updatedProject = (payload as ProjectUpdateResponse).data;
        dispatch(updateCharterProject(updatedProject));
      } else if (payload.action === 'finalvideo_put') {
        const finalizedOrBackupedProject = (payload as ProjectUpdateResponse).data;
        // If the project already exists in the list, update it
        if (projects && projects.some((p: Project) => p.id === finalizedOrBackupedProject.id)) {
          dispatch(updateCharterProject(finalizedOrBackupedProject));
        } else {
          // Else, add it to the list
          dispatch(addCharterProject(finalizedOrBackupedProject));
        }
      }
    };

    if (isSocketConnected) {
      SocketManager.onMessage(handleUpdatedProject);

      return (): void => SocketManager.offMessage(handleUpdatedProject);
    }

    return undefined;
  }, [isSocketConnected, dispatch, projects]);

  useEffect(() => {
    if ((!projects || projects.length === 0) && projectsList.length !== 0) {
      setProjectsList([]);
    }
  }, [projects]);

  const projectCardsLayout = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 8,
    xxl: 6,
  };

  // Sort projects by createdAt
  const onSortCreatedAtChange = (project: Project[], sortOrder: string) => {
    return project.slice().sort((prev, next) => {
      if (prev.createdAt === next.createdAt) {
        return 0;
      }
      if (sortOrder === 'asc') {
        return prev.createdAt < next.createdAt ? -1 : 1;
      }
      return prev.createdAt > next.createdAt ? -1 : 1;
    });
  };

  // Sort projects by lastFinalizedAt
  const onSortLastFinalizedAtChange = (project: Project[], sortOrder: string) => {
    return project.slice().sort((prev, next) => {
      if (prev.lastFinalizedAt === next.lastFinalizedAt) {
        return 0;
      }

      if (!prev.lastFinalizedAt) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      if (!next.lastFinalizedAt) {
        return sortOrder === 'asc' ? -1 : 1;
      }

      if (sortOrder === 'asc') {
        return prev.lastFinalizedAt < next.lastFinalizedAt ? -1 : 1;
      }
      return prev.lastFinalizedAt > next.lastFinalizedAt ? -1 : 1;
    });
  };

  // Sort projects by author email
  const onSortAuthorEmailChange = (project: Project[], sortOrder: string) => {
    return project.slice().sort((prev: Project, next: Project) => {
      if (prev.author && next.author) {
        if (prev.author.email === next.author.email) {
          return 0;
        }
        if (sortOrder === 'asc') {
          return prev.author.email < next.author.email ? -1 : 1;
        }
        return prev.author.email > next.author.email ? -1 : 1;
      }
      return 0;
    });
  };

  // When the user changes the input value of one of the filters
  const onFilterChange = (
    titleFilter: string[],
    authorFilter: string[],
    statusFilter: string[],
    metadataFilter: string,
    sortOrder: string
  ) => {
    if (!projects) {
      return;
    }
    // New filtered projects
    let filteredProjects: Project[] = projects;

    // Filter by title
    if (titleFilter && titleFilter.length > 0) {
      filteredProjects = projects.filter((project: Project) => titleFilter.includes(project.title));
    }
    // Filter by author name or email
    if (authorFilter && authorFilter.length > 0) {
      filteredProjects = projects.filter((project: Project) => {
        if (project.author) {
          return authorFilter.includes(project.author.name || project.author.email);
        }
        return false;
      });
    }
    // Filter by status
    if (statusFilter && statusFilter.length > 0) {
      filteredProjects = projects.filter((project: Project) => statusFilter.includes(project.status));
    }
    // Sort by metadata
    switch (metadataFilter) {
      case 'createdAt':
        filteredProjects = onSortCreatedAtChange(filteredProjects, sortOrder);
        break;
      case 'lastFinalizedAt':
        filteredProjects = onSortLastFinalizedAtChange(filteredProjects, sortOrder);
        break;
      case 'email':
        filteredProjects = onSortAuthorEmailChange(filteredProjects, sortOrder);
        break;
      default:
        return;
    }
    // Save the filtered projects
    setProjectsList(filteredProjects);
  };

  if (!currentCharter) {
    return null;
  }

  if (!hasUserAccessToCharter(parseInt(charterId, 10))) {
    history.push(LINK.UNAUTHORIZED.path);
    return null;
  }

  const startHelp = (): void => {
    const joyrideProjectExample = JoyRideUtils.generateSampleProject();
    dispatch(addCharterProject(joyrideProjectExample));
    setRunHelp(true);
  };

  const endHelp = (): void => {
    setRunHelp(false);
    const joyrideProjectExample = JoyRideUtils.generateSampleProject();
    dispatch(deleteCharterProject(joyrideProjectExample.id));
    setProjectsList(projectsList.filter((pr) => pr.id !== joyrideProjectExample.id));
  };

  const routes = [
    {
      path: '/charters',
      breadcrumbName: t('menu.charters'),
    },
    {
      path: `/charters/${currentCharter.id}`,
      breadcrumbName: currentCharter.name,
    },
    {
      path: location.pathname,
      breadcrumbName: t('charters.projects.title'),
    },
  ];

  const breadcrumbProps: BreadcrumbProps = {
    itemRender: (route: Route) => {
      if (route.path === location.pathname) {
        return route.breadcrumbName;
      }
      return <Link to={route.path}>{route.breadcrumbName}</Link>;
    },
    routes,
  };

  if (isError) {
    return <ErrorAlert className={classes.errorAlert} message={t('ajaxError.charterFetch')} />;
  }

  if (isCharterLoading || isLoading) {
    return <KannelleLoader />;
  }

  return (
    <KannellePageHeader
      title={
        <div className={classes.titleWithIcon}>
          {t('charters.projects.title')}
          <KannelleHelpButton startHelp={startHelp} />
        </div>
      }
      breadcrumb={breadcrumbProps}
      className={classes.pageContent}
      avatar={{ className: classes.avatar, icon: <FolderOpenOutlined /> }}
      extra={!isMobile ? <CreateProjectModal /> : undefined}
    >
      <JoyRideHelpCharterProjects runHelp={runHelp} callbackRunDone={endHelp} />

      <Row className={classes.pageDescription}>{t('charters.projects.pageDescription')}</Row>

      {projects && projects.length > 0 && (
        <ProjectsFilterButton projects={projects} onFilterChange={onFilterChange} runJoyrideHelp={runHelp} />
      )}

      <Row gutter={[16, 16]} className={classes.row}>
        {!(projectsList && projectsList.length > 0) ? (
          <Empty
            className={classes.empty}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t('charters.projects.noData')}
          />
        ) : (
          projectsList.map((project) => (
            <Col {...projectCardsLayout} key={project.id}>
              <ProjectCard project={project} />
            </Col>
          ))
        )}
      </Row>
    </KannellePageHeader>
  );
};

export default CharterProjects;
