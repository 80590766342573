import { CloseCircleFilled, EditFilled } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React, { FunctionComponent, Ref, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import useCanAccess from '../../hooks/useCanAccess';
import AnimationsUtils from '../../utils/AnimationsUtils';
import {
  AnimationConfig,
  AnimationOption,
  RecordableAnimations,
  SlideAnimations,
} from '../../utils/types/AnimationTypes';
import { PermissionList } from '../../utils/types/CharterPermissionTypes';
import { AnimationTheme } from '../../utils/types/ThemeTypes';
import AccessChecker from '../access-checker/AccessChecker';
import Preformatted from '../preformatted/Preformatted';
import AnimationSelectorModal from './AnimationSelectorModal';

type Props = {
  value?: AnimationConfig;
  onChange?: (animationConfig?: AnimationConfig) => void;
  fieldLabel: string;
  format?: string;
  theme: AnimationTheme;
  customColors?: boolean;
  isSlide?: boolean;
  noReset?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  hasSlide?: boolean;
};

type StyleProps = {
  hasPermissionToEdit: boolean;
};

const useStyles = createUseStyles({
  selectAnimationButton: {
    padding: 0,
  },
  selectedAnimationContainer: ({ hasPermissionToEdit }: StyleProps) => ({
    cursor: hasPermissionToEdit ? 'pointer' : 'not-allowed',
    '& .anticon': {
      marginRight: 4,
    },
  }),
  selectedAnimation: {
    fontSize: '11px',
    marginRight: '5px',
  },
});

const AnimationSelector: FunctionComponent<Props> = React.forwardRef(
  (
    {
      value,
      onChange,
      fieldLabel,
      format,
      theme,
      customColors = true,
      isSlide = false,
      noReset = false,
      onClick,
      disabled = false,
      hasSlide = false,
    }: Props,
    ref: Ref<any>
  ) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [animations, setAnimations] = useState<AnimationOption[]>();

    const permissionToEditSceneAnimation = useCanAccess(PermissionList.SCENE_ANIMATION_TYPE_EDIT);

    const { t } = useTranslation();
    const classes = useStyles({
      hasPermissionToEdit: !disabled && permissionToEditSceneAnimation.hasUserAccess(),
    });

    // Called to update the upper `form` object
    const triggerChange = (changedValue?: AnimationConfig): void => {
      if (onChange) {
        onChange(changedValue);
      }
    };

    useEffect(() => {
      const animationsForKind = isSlide ? SlideAnimations : RecordableAnimations;
      setAnimations(animationsForKind);
    }, [isSlide]);

    // Whenever the isSlide param changes, we ensure to recompute the value so that the animationText duration is well calculated
    useEffect(() => {
      triggerChange(value);
      // eslint-disable-next-line
    }, [isSlide]);

    const onOpenModal = (): void => {
      if (!hasSlide && (disabled || !permissionToEditSceneAnimation.hasUserAccess())) {
        return;
      }

      setIsModalVisible(true);

      // If the onClick callback is defined, execute it
      if (onClick) {
        onClick();
      }
    };

    const onCloseModal = (): void => {
      setIsModalVisible(false);
    };

    const onSelectAnimation = (animationConfig: AnimationConfig): void => {
      triggerChange(animationConfig);
      onCloseModal();
    };

    const onResetAnimation = (): void => {
      triggerChange(undefined);
    };

    return (
      <div ref={ref}>
        <div onClick={onOpenModal}>
          {value ? (
            <span className={classes.selectedAnimationContainer}>
              <AccessChecker
                renderUnauthorizedMessage={permissionToEditSceneAnimation.renderUnauthorizedMessage}
                hasAccess={disabled || permissionToEditSceneAnimation.hasUserAccess()}
              >
                <Preformatted className={classes.selectedAnimation}>
                  {AnimationsUtils.getPublicAnimationNameByCode(value.name)}
                </Preformatted>
                <Tooltip
                  title={permissionToEditSceneAnimation.hasUserAccess() ? t('animationSelector.edit') : undefined}
                >
                  <EditFilled />
                </Tooltip>
                {!noReset && (
                  <Tooltip
                    title={
                      disabled || !permissionToEditSceneAnimation.hasUserAccess()
                        ? undefined
                        : t('animationSelector.reset')
                    }
                  >
                    <CloseCircleFilled
                      onClick={(e): void => {
                        e.stopPropagation();
                        onResetAnimation();
                      }}
                    />
                  </Tooltip>
                )}
              </AccessChecker>
            </span>
          ) : (
            <Button type="link" className={classes.selectAnimationButton}>
              {t('animationSelector.label')}
            </Button>
          )}
        </div>

        <AnimationSelectorModal
          selectedAnimation={value}
          onSelectAnimation={onSelectAnimation}
          isVisible={isModalVisible}
          fieldLabel={fieldLabel}
          format={format}
          theme={theme}
          customColors={customColors}
          onCancel={onCloseModal}
          animations={animations}
        />
      </div>
    );
  }
);

export default AnimationSelector;
