import { ExportOutlined, QuestionCircleOutlined } from '@ant-design/icons/lib';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { LOGGING_EVENT, THEME } from '../../Constants';
import { RootState } from '../../redux/RootState';

type Props = {
  iconClassName?: string;
};

const useStyles = createUseStyles({
  helpLink: {
    color: THEME.DEFAULT.DEFAULT_GREY_TEXT_COLOR,
  },
  mobileHelpLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  externalLinkIcon: {
    marginLeft: '5px',
    fontSize: '10px !important',
  },
  faqIcon: {
    marginLeft: '3px',
  },
});

const KannelleFaqButton: FunctionComponent<Props> = ({ iconClassName }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const faqUrl = t('help.faq.link');

  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);
  const company = useSelector((state: RootState) => state.companies.current);

  if (!faqUrl) {
    return null;
  }

  const onClickCallback = (): void => {
    loggingManager.logEvent(LOGGING_EVENT.OPEN_EXTERNAL_HELP, {
      companyId: company?.id,
    });
  };

  if (isMobileOrTablet) {
    return (
      <a
        href={faqUrl}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClickCallback}
        className={classNames(classes.mobileHelpLink, 'helps-link')}
      >
        <QuestionCircleOutlined className={classNames(iconClassName)} /> {t('menu.help')}{' '}
        <ExportOutlined className={classNames(iconClassName, classes.externalLinkIcon)} />
      </a>
    );
  }

  return (
    <Tooltip
      title={
        <>
          {t('help.faq.tooltip')}
          <ExportOutlined className={classes.externalLinkIcon} />
        </>
      }
    >
      <a
        href={faqUrl}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClickCallback}
        className={classNames(classes.helpLink, 'helps-link')}
      >
        {t('help.faq.title')}
        <QuestionCircleOutlined className={classNames(iconClassName, classes.faqIcon)} />
      </a>
    </Tooltip>
  );
};

export default KannelleFaqButton;
