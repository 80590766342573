import { VideoCameraOutlined } from '@ant-design/icons/lib';
import { Col, Empty, Row, Typography } from 'antd';
import { BreadcrumbProps } from 'antd/es/breadcrumb';
import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import { CancelTokenSource } from 'axios';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import ErrorAlert from '../../../components/alert/ErrorAlert';
import KannelleLoader from '../../../components/loader/KannelleLoader';
import KannelleHelpButton from '../../../components/page-header/KannelleHelpButton';
import KannellePageHeader from '../../../components/page-header/KannellePageHeader';
import { DEVICE_SIZES_QUERIES, LINK, THEME, WEB_SOCKET_ACTIONS } from '../../../Constants';
import useCanAccess from '../../../hooks/useCanAccess';
import useFetchCharterById from '../../../hooks/useFetchCharterById';
import useSocket from '../../../hooks/useSocket';
import JoyRideHelpCharterScenarios from '../../../onboarding/JoyRideHelpCharterScenarios';
import {
  removeCharterScenario,
  removeCharterScenarioVariant,
  saveCharterScenarios,
  updateCharterScenario,
  updateCharterScenarioVariant,
  updateCharterScenarioVariantMetadata,
} from '../../../redux/action/ChartersAction';
import { RootState } from '../../../redux/RootState';
import smallLogo from '../../../resources/img/K-rectangle-small.png';
import { APIManager } from '../../../services/api/APIManager';
import { getCharterScenarios, getPublicScenarios } from '../../../services/api/ChartersService';
import { SocketManager } from '../../../services/api/SocketManager';
import { ExtendedScenarioVariant, Scenario } from '../../../services/api/types/ChartersServiceTypes';
import {
  DeletedScenarioPayload,
  DeletedScenarioVariantPayload,
  ScenarioUpdateResponse,
} from '../../../services/api/types/WebSocketTypes';
import { CharterIdPathParam } from '../../../services/navigation/NavigationConfigTypes';
import JoyRideUtils from '../../../utils/JoyRideUtils';
import { PermissionList } from '../../../utils/types/CharterPermissionTypes';
import CreateOrEditScenarioModal from './components/CreateOrEditScenarioModal';
import ScenarioDetailedCard from './components/ScenarioDetailedCard';

const { Title } = Typography;

const useStyles = createUseStyles({
  pageContent: {
    backgroundColor: '#FFFFFF',
    padding: 24,
    margin: 24,
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      margin: 0,
      padding: '5px 10px',
    },
  },
  titleWithIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    backgroundColor: THEME.DEFAULT.MAIN_COLOR,
  },
  pageDescription: {
    marginBottom: 16,
    textAlign: 'justify',
  },
  row: {
    margin: '10px !important',
  },
  errorAlert: {
    marginTop: '30px',
  },
  empty: {
    width: '100%',
  },
  title: {
    '&.ant-typography': {
      color: '#3B3B3B',
      fontWeight: 400,
      marginTop: 38,
      borderBottom: 'solid 1px #dddddd',
      marginBottom: 20,
    },
  },
});

const CharterScenarios: FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const { charterId } = useParams<CharterIdPathParam>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading: isCharterLoading, isError } = useFetchCharterById(parseInt(charterId, 10));
  const currentCharter = useSelector((state: RootState) => state.charters.current);
  const scenarios = useSelector((state: RootState) => state.charters.currentCharterScenarios);

  const [publicScenarios, setPublicScenarios] = useState<Scenario[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [room, setRoom] = useState<string>();
  const [runHelp, setRunHelp] = useState(false);
  const [addedHelpExample, setAddedHelpExample] = useState(false);

  const { hasUserAccessToCharter } = useCanAccess(PermissionList.WEB_DASHBOARD);

  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());
  const isSocketConnected = useSocket(room ?? undefined);

  const currentCharterId = currentCharter?.id;

  useEffect(() => {
    // Do not fetch the scenarios while the user has no permission to access this feature
    if (!currentCharterId) {
      return;
    }

    setIsLoading(true);
    const cancelTokenSource = cancelTokenSourceRef.current;
    Promise.all([getCharterScenarios(currentCharterId, cancelTokenSource), getPublicScenarios(cancelTokenSource)])
      .then(([charterScenarioResponse, publicScenariosResponse]) => {
        dispatch(saveCharterScenarios(charterScenarioResponse.items));
        const publicScenariosFromOtherCharters = publicScenariosResponse.items
          .filter((publicScenarioItem) => publicScenarioItem.charterId !== currentCharterId)
          .map((publicScenarioItem) => publicScenarioItem.scenario);
        setPublicScenarios(publicScenariosFromOtherCharters);
      })
      .catch((e) => {
        log.debug('Error during charter scenarios fetch', e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentCharterId, dispatch]);

  useEffect(() => {
    if (!currentCharter) {
      return;
    }

    setRoom(`charters/${currentCharter.id}/scenarios`);
  }, [currentCharter]);

  useEffect(() => {
    const handleUpdatedScenario = (payload: ScenarioUpdateResponse): void => {
      if (WEB_SOCKET_ACTIONS.CHARTER_LOCAL_SCENARIOS.includes(payload.action)) {
        switch (payload.action) {
          // If one scenario has been deleted
          case 'scenario_delete': {
            const deletedScenarioPayload = payload.data as DeletedScenarioPayload;
            const { id: deletedScenarioId } = deletedScenarioPayload;
            dispatch(removeCharterScenario(deletedScenarioId));
            break;
          }
          // If a Scenario has been created or updated
          case 'scenario_patch':
          case 'scenario_put':
          default: {
            const updatedScenario = payload.data as Scenario;
            dispatch(updateCharterScenario(updatedScenario));
          }
        }
      } else if (WEB_SOCKET_ACTIONS.CHARTER_LOCAL_SCENARIO_VARIANTS.includes(payload.action)) {
        switch (payload.action) {
          // In case just the title was updated, we do not update the variant scenes
          case 'scenario_variant_metadata_patch': {
            const extendedScenarioVariant = payload.data as ExtendedScenarioVariant;
            const { scenarioId, ...updatedVariant } = extendedScenarioVariant;
            dispatch(
              updateCharterScenarioVariantMetadata(
                scenarioId,
                updatedVariant.id,
                updatedVariant.title,
                updatedVariant.description
              )
            );
            break;
          }
          // If one variant has been deleted
          case 'scenario_variant_delete': {
            const deletedVariantPayload = payload.data as DeletedScenarioVariantPayload;
            const { id: deletedVariantId, scenarioId } = deletedVariantPayload;
            dispatch(removeCharterScenarioVariant(scenarioId, deletedVariantId));
            break;
          }
          // Else, the whole variant is updated
          case 'scenario_variant_put':
          case 'scenario_variant_patch':
          default: {
            const extendedScenarioVariant = payload.data as ExtendedScenarioVariant;
            const { scenarioId, ...updatedVariant } = extendedScenarioVariant;
            dispatch(updateCharterScenarioVariant(scenarioId, updatedVariant));
            break;
          }
        }
      }
    };

    if (isSocketConnected) {
      SocketManager.onMessage(handleUpdatedScenario);

      return (): void => SocketManager.offMessage(handleUpdatedScenario);
    }

    return undefined;
  }, [isSocketConnected, dispatch]);

  const startHelp = (): void => {
    if (!(scenarios && scenarios.length > 0)) {
      const demoScenario = JoyRideUtils.generateSampleScenario(smallLogo);
      dispatch(saveCharterScenarios([demoScenario]));
      setAddedHelpExample(true);
    }

    setRunHelp(true);
  };

  const endHelp = (): void => {
    setRunHelp(false);

    if (addedHelpExample) {
      setAddedHelpExample(false);
      dispatch(saveCharterScenarios([]));
    }
  };

  const scenarioCardsLayout = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 8,
    xxl: 6,
  };

  if (!currentCharter) {
    return null;
  }

  if (!hasUserAccessToCharter(parseInt(charterId, 10))) {
    history.push(LINK.UNAUTHORIZED.path);
    return null;
  }

  const routes = [
    {
      path: '/charters',
      breadcrumbName: t('menu.charters'),
    },
    {
      path: `/charters/${currentCharter.id}`,
      breadcrumbName: currentCharter.name,
    },
    {
      path: location.pathname,
      breadcrumbName: t('charters.scenarios.title'),
    },
  ];

  const breadcrumbProps: BreadcrumbProps = {
    itemRender: (route: Route) => {
      if (route.path === location.pathname) {
        return route.breadcrumbName;
      }
      return <Link to={route.path}>{route.breadcrumbName}</Link>;
    },
    routes,
  };

  if (isError) {
    return <ErrorAlert className={classes.errorAlert} message={t('ajaxError.charterFetch')} />;
  }

  if (isCharterLoading || isLoading) {
    return <KannelleLoader />;
  }

  return (
    <KannellePageHeader
      title={
        <div className={classes.titleWithIcon}>
          {t('charters.scenarios.title')}
          <KannelleHelpButton startHelp={startHelp} />
        </div>
      }
      breadcrumb={breadcrumbProps}
      className={classes.pageContent}
      avatar={{ className: classes.avatar, icon: <VideoCameraOutlined /> }}
      extra={<CreateOrEditScenarioModal mode="creation" />}
    >
      <JoyRideHelpCharterScenarios runHelp={runHelp} callbackRunDone={endHelp} />

      <Row className={classes.pageDescription}>{t('charters.scenarios.pageDescription')}</Row>

      <div>
        <Title className={classes.title} level={4}>
          {t(`charters.scenarios.charterScenarios`)}
        </Title>
        <Row gutter={[16, 16]} className={classes.row}>
          {!(scenarios && scenarios.length > 0) ? (
            <Empty
              className={classes.empty}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('charters.scenarios.noData')}
            />
          ) : (
            scenarios.map((scenario) => (
              <Col {...scenarioCardsLayout} key={scenario.id}>
                <ScenarioDetailedCard scenario={scenario} />
              </Col>
            ))
          )}
        </Row>
      </div>

      <div>
        <Title className={classes.title} level={4}>
          {t(`charters.scenarios.otherPublicScenarios`)}
        </Title>
        <Row gutter={[16, 16]} className={classes.row}>
          {!(publicScenarios && publicScenarios.length > 0) ? (
            <Empty
              className={classes.empty}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('charters.scenarios.noData')}
            />
          ) : (
            publicScenarios.map((publicScenario) => (
              <Col {...scenarioCardsLayout} key={publicScenario.id}>
                <ScenarioDetailedCard scenario={publicScenario} isEditable={false} />
              </Col>
            ))
          )}
        </Row>
      </div>
    </KannellePageHeader>
  );
};

export default CharterScenarios;
