declare global {
  interface Window {
    Appcues: any;
    AppcuesReady: any;
  }
}
declare global {
  interface Window {
    AppcuesSettings: any;
  }
}

export function useAppcues(user: any) {
  const { email, name, nickname, sub } = user;
  const auth0UserIdPrefix = process.env.REACT_APP_AUTH0_USER_ID_PREFIX ?? '';
  const userId = sub?.replace(auth0UserIdPrefix, '');
  window.AppcuesReady(function () {
    window.Appcues.identify(userId, {
      email,
      name,
      nickname,
    });
  });
}
