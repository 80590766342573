import { useMemo } from 'react';
import ScenarioUtils from '../../../../../utils/ScenarioUtils';
import { ScenarioSceneTemplate } from '../../../../../utils/types/ScenarioTypes';

export type ExtendedScenarioSceneTemplate = ScenarioSceneTemplate & {
  absoluteStartAt: number;
  absoluteEndAt: number;
};

type ScenarioFinalRenderData = {
  scenarioDuration: number;
  scenarioElements: ExtendedScenarioSceneTemplate[];
};

const useScenarioFinalRenderData = (scenesTemplate: ScenarioSceneTemplate[]): ScenarioFinalRenderData => {
  const scenarioDuration = ScenarioUtils.computeScenesDuration(scenesTemplate);

  const scenarioElements: ExtendedScenarioSceneTemplate[] = useMemo(() => {
    const sceneElements: ExtendedScenarioSceneTemplate[] =
      scenesTemplate
        .filter((scene) => {
          return scene.animationText !== undefined || scene.demoVideoUri !== undefined;
        })
        .map((scene) => ({
          ...scene,
          absoluteStartAt: ScenarioUtils.computeSceneStartTimeInScenario(scenesTemplate, scene),
          absoluteEndAt: ScenarioUtils.computeSceneEndTimeInScenario(scenesTemplate, scene),
        })) ?? [];

    return [...(sceneElements ? [...sceneElements] : [])];
  }, [scenesTemplate]);

  return {
    scenarioDuration,
    scenarioElements,
  };
};

export default useScenarioFinalRenderData;
