import { Button, message, Modal } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { LOGGING_EVENT, MEDIA_SIZE_LIMIT } from '../../Constants';
import useSocket from '../../hooks/useSocket';
import {
  mediaFileUploadedAndProcessing,
  refreshProcessingMediaByMediaType,
  updateMediaProcessingProgression,
} from '../../redux/action/ChartersAction';
import { RootState } from '../../redux/RootState';
import { getCharterMediaSignedUrlToUpload } from '../../services/api/ChartersService';
import { uploadFileToSignedUrl } from '../../services/api/GlobalService';
import { SocketManager } from '../../services/api/SocketManager';
import { MediaFile } from '../../services/api/types/ChartersServiceTypes';
import { AssetOrMediaProcessingResponse } from '../../services/api/types/WebSocketTypes';
import MathUtils from '../../utils/MathUtils';
import MediaUtils from '../../utils/MediaUtils';
import { MediaType } from '../../utils/types/MediaTypes';
import MediaLibraryTabs from '../media-library/components/MediaLibraryTabs';
import FileUploader, { DraggerCustomRequestParams, FileUploaderProps } from '../modal/FileUploader';
import { WebcamRecorderOptions } from '../webcam-recorder/WebcamRecorder';

type CommonProps = {
  isVisible: boolean;
  isLoading: boolean;
  mediaList?: MediaFile[];
  fieldLabel: string;
  onCancel: () => void;
  mediaTypes?: MediaType[];
  defaultMediaType?: MediaType;
  enableWebcamRecording?: boolean;
  webcamRecordingOptions?: WebcamRecorderOptions;
  format?: string;
  onSelectMedia: (media: MediaFile) => void;
  disabledMedia?: MediaFile | MediaFile[];
  disabledTabMessages?: { mediaType: MediaType; message: string }[];
};

type SingleMediaSelectorModalProps = CommonProps & {
  selectedMedia?: MediaFile;
  multiple: false;
  onSubmitMediaSelection?: undefined;
};

type MultipleMediaSelectorModalProps = CommonProps & {
  selectedMedia?: MediaFile[];
  multiple?: true;
  onSubmitMediaSelection?: () => void;
};

type Props = SingleMediaSelectorModalProps | MultipleMediaSelectorModalProps;

type StyleProps = {
  isMobileOrTablet: boolean;
};

const useStyles = createUseStyles({
  mediaChoiceModal: {
    width: '75% !important',
    '& .ant-modal-body': {
      paddingTop: '10px',
    },
  },
  empty: {
    width: '100%',
  },
  mediaLibraryTabs: {
    '& .ant-tabs-content-holder': {
      maxHeight: '50vh',
      overflow: 'auto',
    },
  },
  selectedMediaCount: ({ isMobileOrTablet }: StyleProps) => ({
    color: 'gray',
    marginRight: isMobileOrTablet ? 0 : 24,
    marginBottom: isMobileOrTablet ? 16 : 0,
    display: isMobileOrTablet ? 'block' : 'inline',
  }),
  archivedMediaMessage: {
    float: 'left',
    fontSize: 12,
    lineHeight: '24px',
  },
});

const MediaSelectorModal: FunctionComponent<Props> = ({
  isVisible,
  isLoading,
  mediaList,
  fieldLabel,
  mediaTypes,
  selectedMedia,
  multiple = false,
  defaultMediaType,
  enableWebcamRecording = false,
  webcamRecordingOptions,
  format,
  onCancel,
  onSelectMedia,
  onSubmitMediaSelection = undefined,
  disabledMedia,
  disabledTabMessages,
}: Props) => {
  const [room, setRoom] = useState<string>();
  const [activeTab, setActiveTab] = useState<MediaType>(
    defaultMediaType || (mediaTypes && mediaTypes[0]) || MediaType.IMAGE
  );
  const charter = useSelector((state: RootState) => state.charters.current);
  const company = useSelector((state: RootState) => state.companies.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);
  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);

  const isSocketConnected = useSocket(room ?? undefined);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles({ isMobileOrTablet });
  const sizeLimit = MEDIA_SIZE_LIMIT;

  const mediaFileTypes = useMemo(() => {
    if (!(mediaTypes && mediaTypes.length > 0)) {
      return MediaUtils.getMediaMimeTypeList().join(',');
    }

    return MediaUtils.getMediaMimeTypeListByMediaTypeList(mediaTypes).join(',');
  }, [mediaTypes]);

  // Determine if the select media is archived or not in the charter
  const isSelectedMediaArchived = useMemo(() => {
    if (!(selectedMedia && mediaList && mediaList.length > 0)) {
      return false;
    }

    // In case of a "multiple" selector, check if one of the selected media is archived
    if (Array.isArray(selectedMedia)) {
      return selectedMedia.some((media) => {
        const selectedMediaInList = mediaList?.find((m) => m.id === media.id);
        return selectedMediaInList?.isArchived ?? false;
      });
    }

    // Else, check if the single selected media is archived
    const selectedMediaInList = mediaList?.find((media) => media.id === selectedMedia.id);
    return selectedMediaInList?.isArchived ?? false;
  }, [mediaList, selectedMedia]);

  // Remove processed media of mediaType from the processingMedia in store
  useEffect(() => {
    if (!mediaList) {
      return;
    }

    // The active tab (key) corresponds to the current mediaType
    dispatch(refreshProcessingMediaByMediaType(mediaList, activeTab));
  }, [mediaList, dispatch, activeTab]);

  useEffect(() => {
    if (!charter) {
      return;
    }
    setRoom(`charters/${charter.id}/media`);
  }, [charter]);

  useEffect(() => {
    const updateProgress = (payload: AssetOrMediaProcessingResponse): void => {
      const { operationId, progress } = payload.data;
      if (progress) {
        dispatch(updateMediaProcessingProgression(operationId, progress, activeTab));
      }
    };

    if (isSocketConnected) {
      SocketManager.onMessage(updateProgress);

      return (): void => SocketManager.offMessage(updateProgress);
    }

    return undefined;
  }, [isSocketConnected, activeTab, dispatch]);

  const onSizeLimitExceeded = (): void => {
    message.error(t(`charters.mediaLibrary.uploadSizeLimit`, { limit: sizeLimit }));
  };

  const onUploadStatusChangeToError = (info: UploadChangeParam): void => {
    message.error(t('charters.mediaLibrary.uploadError', { fileName: info.file.name }));
  };

  const onUploadStatusChangeToDone = (info: UploadChangeParam): void => {
    if (info.file.type) {
      dispatch(mediaFileUploadedAndProcessing(info.file, MediaUtils.determineMediaTypeFromMimeType(info.file.type)));
    }
  };

  const onUploadFile = (requestParam: DraggerCustomRequestParams): void => {
    if (!charter) {
      return;
    }
    const uploadMediaType = MediaUtils.determineMediaTypeFromMimeType(requestParam.file.type);

    if (uploadMediaType !== activeTab) {
      setActiveTab(uploadMediaType);
    }

    const params = {
      charterId: charter.id,
      filename: requestParam.file.name,
      mediaType: uploadMediaType,
    };

    loggingManager.logEvent(LOGGING_EVENT.UPLOAD_MEDIA, {
      name: requestParam.file.name,
      size: `${Math.floor(MathUtils.convertBytesToKilobytes(requestParam.file.size))} ${t('charters.mediaLibrary.KB')}`,
      charterId: charter.id,
      companyId: company?.id,
      mimeType: requestParam.file.type,
    });

    getCharterMediaSignedUrlToUpload(params)
      .then((signedUrl) => {
        return uploadFileToSignedUrl(signedUrl.fileUploadURL, requestParam.file, (event) => {
          requestParam.onProgress({ percent: (event.loaded / event.total) * 100 }, requestParam.file);
        })
          .then(() => {
            requestParam.onSuccess({ ...requestParam, data: signedUrl.metadata });
          })
          .catch((err) => requestParam.onError(err));
      })
      .catch((err) => requestParam.onError(err));
  };

  const onRetryUploadMediaFile = (uploadFile: UploadFile, mediaFileType: MediaType): void => {
    if (!charter) {
      return;
    }

    const params = {
      charterId: charter.id,
      filename: uploadFile.name,
      mediaType: mediaFileType,
    };

    getCharterMediaSignedUrlToUpload(params)
      .then((signedUrl) => {
        return uploadFileToSignedUrl(signedUrl.fileUploadURL, uploadFile.originFileObj).catch((err) =>
          log.error(`An error occurred while retrying to upload a media: ${err}`)
        );
      })
      .catch((err) => log.error(`An error occurred while retrying to upload a media: ${err}`));
  };

  const uploaderProps: FileUploaderProps = {
    triggerText: t('charters.mediaLibrary.upload'),
    modalTitle: t('charters.mediaLibrary.uploadModalTitle'),
    uploadInstructionText:
      mediaTypes && mediaTypes.length === 1
        ? t(`charters.mediaLibrary.${activeTab}s.uploadTitle`)
        : t(`charters.mediaLibrary.uploadTitle`),
    uploadInstructionHint:
      mediaTypes && mediaTypes.length === 1
        ? t(`charters.mediaLibrary.${activeTab}s.uploadHint`)
        : t(`charters.mediaLibrary.uploadHint`),
    uploadInstructionSizeLimit: t(`charters.mediaLibrary.uploadSizeLimit`, { limit: sizeLimit }),
    acceptFileType: mediaFileTypes,
    sizeLimit,
    onSizeLimitExceeded,
    onUploadStatusChangeToDone,
    onUploadStatusChangeToError,
    onUploadFile,
  };

  const handleMediaTabChange = (newTab: MediaType): void => {
    setActiveTab(newTab);
  };

  const renderMessageAboutArchivedMedia = () => {
    return (
      <div key="archivedMediaMessage" className={classes.archivedMediaMessage}>
        {t('charters.mediaLibrary.mediaSelector.selectedMediaIsArchived')}
      </div>
    );
  };

  return (
    <Modal
      title={fieldLabel}
      visible={isVisible}
      onCancel={onCancel}
      footer={[
        ...(isSelectedMediaArchived ? [renderMessageAboutArchivedMedia()] : []),
        ...(multiple && selectedMedia && Array.isArray(selectedMedia) && selectedMedia.length > 0
          ? [
              <span key="selectedCount" className={classes.selectedMediaCount}>
                {t('charters.mediaLibrary.numberOfSelectedMedia', { count: selectedMedia.length })}
              </span>,
            ]
          : []),
        <Button key="cancelModal" onClick={onCancel}>
          {t('global.cancel')}
        </Button>,
        ...(multiple
          ? [
              <Button
                key="submit"
                onClick={onSubmitMediaSelection}
                type="primary"
                disabled={!(selectedMedia && Array.isArray(selectedMedia) && selectedMedia.length > 0)}
              >
                {t('charters.mediaLibrary.mediaSelector.selectMedia')}
              </Button>,
            ]
          : []),
      ]}
      className={classes.mediaChoiceModal}
    >
      <MediaLibraryTabs
        mediaList={mediaList?.filter((media) => !media.isArchived)}
        isLoading={isLoading}
        activeTab={activeTab}
        uniqueActiveTab={mediaTypes?.length === 1 ? mediaTypes[0] : undefined}
        handleActiveTabChange={handleMediaTabChange}
        onRetryUploadMediaFile={onRetryUploadMediaFile}
        selectedMedia={selectedMedia}
        disabledMedia={disabledMedia}
        disabledTabMessages={disabledTabMessages}
        onSelectMedia={onSelectMedia}
        {...uploaderProps}
        enableWebcamRecording={enableWebcamRecording}
        webcamRecordingOptions={webcamRecordingOptions}
        format={format}
        multiple={multiple}
        extra={<FileUploader {...uploaderProps} />}
        className={classes.mediaLibraryTabs}
      />
    </Modal>
  );
};

export default MediaSelectorModal;
