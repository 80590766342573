import { BgColorsOutlined } from '@ant-design/icons/lib';
import { Form, message, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { CancelTokenSource } from 'axios';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ErrorAlert from '../../../components/alert/ErrorAlert';
import KannelleLoader from '../../../components/loader/KannelleLoader';
import KannellePageHeader from '../../../components/page-header/KannellePageHeader';
import { CHARTER, DEVICE_SIZES_QUERIES, LINK, LOGGING_EVENT, THEME } from '../../../Constants';
import useFetchCompanyStats from '../../../hooks/useFetchCompanyStats';
import { addCreatedCharter } from '../../../redux/action/ChartersAction';
import { addOrUpdateCharterInCompany } from '../../../redux/action/CompaniesAction';
import { RootState } from '../../../redux/RootState';
import { APIManager } from '../../../services/api/APIManager';
import { updateCharterColors } from '../../../services/api/ChartersService';
import { createCharterInCompany } from '../../../services/api/CompaniesService';
import { APICreateCompanyCharterParams } from '../../../services/api/types/CompaniesServiceTypes';
import LogUtils from '../../../utils/LogUtils';
import ThemeUtils from '../../../utils/ThemeUtils';
import CharterCreationOrUpdateForm from './CharterCreationOrUpdateForm';

const useStyles = createUseStyles({
  pageContent: {
    backgroundColor: '#FFFFFF',
    padding: 24,
    margin: 24,
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      margin: 0,
      padding: '5px 10px',
    },
  },
  avatar: {
    backgroundColor: THEME.DEFAULT.MAIN_COLOR,
    minWidth: 32,
  },
  pageDescription: {
    marginBottom: 16,
    textAlign: 'justify',
  },
  pageDescriptionHint: {
    marginTop: '20px',
    textAlign: 'justify',
  },
  errorAlert: {
    marginTop: '30px',
  },
});

const CharterCreation: FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const formRef = useRef<FormInstance>(null);
  const dispatch = useDispatch();
  const [colorsList, setColorsList] = useState<string[]>(CHARTER.CORPORATE_COLORS);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const history = useHistory();
  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());
  const { loading, isError, fetchedCompanyStats } = useFetchCompanyStats();
  const [isStatsValidated, setIsStatsValidated] = useState(false);

  const charters = useSelector((state: RootState) => state.charters.list);
  const currentCompany = useSelector((state: RootState) => state.companies.current);
  const bundle = useSelector((state: RootState) => state.companies.bundle);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);

  useEffect(() => {
    const cancelTokenSource = cancelTokenSourceRef.current;
    return (): void => {
      cancelTokenSource.cancel(
        'Cancelled fetching company stats, creating charter or updating its colors due to component unmount.'
      );
    };
  }, []);

  useEffect(() => {
    if (!bundle) {
      return;
    }

    if (
      bundle.featureValues.MAX_CHARTERS !== undefined &&
      bundle.featureValues.MAX_CHARTERS <= (charters?.length || 0)
    ) {
      history.push(LINK.UNAUTHORIZED.path);
    }
  }, [bundle, charters]);

  useEffect(() => {
    if (!currentCompany || !fetchedCompanyStats) {
      return;
    }

    if (fetchedCompanyStats.charters.active >= currentCompany.maxNbCharters && currentCompany.maxNbCharters !== 0) {
      message.error(t('global.maxNbChartersReachedError', { count: currentCompany.maxNbCharters }));
      log.error(`Max number of charters (${currentCompany.maxNbCharters}) reached for company ${currentCompany.name}.`);
      history.goBack();
    }

    setIsStatsValidated(true);
  }, [currentCompany, history, t, fetchedCompanyStats]);

  const onColorsListChange = (newColorsList: string[]): void => {
    setColorsList(newColorsList);
    if (formRef.current && form) {
      form.setFieldsValue({
        colors: newColorsList,
      });
    }
  };

  const onResetClick = (): void => {
    if (formRef.current && form) {
      form.resetFields();
      form.setFieldsValue({ colors: CHARTER.DEFAULT_COLORS, name: undefined });
      setColorsList(CHARTER.DEFAULT_COLORS);
    }
  };

  const onCreateCharterValidation = (): void => {
    if ((!formRef.current && form) || !currentCompany) {
      return;
    }

    form.validateFields().then((values) => {
      setIsSubmitLoading(true);
      const data: APICreateCompanyCharterParams = {
        colors: values.colors,
        name: values.name,
      };

      createCharterInCompany(currentCompany.id, data, cancelTokenSourceRef.current)
        .then((response) => {
          const createdCharter = response.data;
          const themeColors = ThemeUtils.buildThemeColorsByBaseColors(colorsList);
          return updateCharterColors(createdCharter.id, { themeColors }, cancelTokenSourceRef.current).then(() => {
            const event = LogUtils.getChartersCreateOrUpdateEvent(
              LOGGING_EVENT.CREATE_CHARTER,
              createdCharter.name,
              currentCompany.id,
              createdCharter.id
            );
            loggingManager.logEventObject(event);

            dispatch(addCreatedCharter(createdCharter));
            dispatch(addOrUpdateCharterInCompany(currentCompany, createdCharter));
            message.success(t('charters.creation.success'));
            setIsSubmitLoading(false);
            history.push(`/charters/${createdCharter.id}`);
          });
        })
        .catch((e) => {
          log.debug(e.message);
          message.error(t('charters.creation.error'));
          setIsSubmitLoading(false);
        });
    });
  };

  if (isError) {
    return <ErrorAlert className={classes.errorAlert} message={t('ajaxError.statisticsFetch')} />;
  }

  if (loading || !isStatsValidated) {
    return <KannelleLoader />;
  }

  const pageDescription = (
    <>
      {t('charters.creation.pageDescription')}
      <div className={classes.pageDescriptionHint}>{t('charters.creation.pageDescriptionHint')}</div>
    </>
  );

  return (
    <KannellePageHeader
      title={t('charters.creation.title')}
      className={classes.pageContent}
      avatar={{ className: classes.avatar, icon: <BgColorsOutlined /> }}
    >
      <Row className={classes.pageDescription}>{pageDescription}</Row>

      <CharterCreationOrUpdateForm
        form={form}
        formRef={formRef}
        colorsList={colorsList}
        onColorsListChange={onColorsListChange}
        isLoading={isSubmitLoading}
        onSubmit={onCreateCharterValidation}
        onReset={onResetClick}
      />
    </KannellePageHeader>
  );
};

export default CharterCreation;
