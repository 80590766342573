import { DatabaseOutlined } from '@ant-design/icons/lib';
import { message, Row } from 'antd';
import { BreadcrumbProps } from 'antd/lib/breadcrumb';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Action } from 'redux';
import { DEVICE_SIZES_QUERIES, MEDIA_SIZE_LIMIT, THEME } from '../../Constants';
import useSocket from '../../hooks/useSocket';
import { updateCharterMedia } from '../../services/api/ChartersService';
import { SocketManager } from '../../services/api/SocketManager';
import { MediaFile } from '../../services/api/types/ChartersServiceTypes';
import { AssetOrMediaProcessingResponse } from '../../services/api/types/WebSocketTypes';
import { CharterIdPathParam } from '../../services/navigation/NavigationConfigTypes';
import MediaUtils from '../../utils/MediaUtils';
import { MediaType } from '../../utils/types/MediaTypes';
import FileUploader, { DraggerCustomRequestParams, FileUploaderProps } from '../modal/FileUploader';
import KannelleHelpButton from '../page-header/KannelleHelpButton';
import KannellePageHeader from '../page-header/KannellePageHeader';
import MediaLibraryTabs from './components/MediaLibraryTabs';

type Props = {
  pageTitle: string;
  pageDescription: string;
  breadcrumb?: BreadcrumbProps;
  socketRoom?: string;
  activeMediaTab: MediaType;
  handleMediaTabChange: (newMediaType: MediaType) => void;
  onUploadFile: (requestParam: DraggerCustomRequestParams) => void;
  onRetryUploadMediaFile: (uploadFile: UploadFile, mediaType: MediaType) => void;
  isLoading: boolean;
  mediaList?: MediaFile[];
  handleMediaProcessingProgressionMessageAction: (
    operationId: string,
    progress: number,
    mediaType: MediaType
  ) => Action;
  handleMediaFileUploadedAndProcessingMessageAction: (file: UploadFile, mediaType: MediaType) => Action;
  onStartHelp: () => void;
  joyrideHelperComponent: JSX.Element;
};

const useStyles = createUseStyles({
  pageContent: {
    backgroundColor: '#FFFFFF',
    margin: 24,
    '& .ant-page-header-heading-title': {
      lineHeight: '48px !important',
    },
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      margin: 0,
    },
  },
  titleWithIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    backgroundColor: THEME.DEFAULT.MAIN_COLOR,
    minWidth: 32,
  },
  subtitleRow: {
    marginBottom: 16,
  },
});

const MediaLibrary: FunctionComponent<Props> = ({
  pageTitle,
  pageDescription,
  breadcrumb,
  socketRoom,
  mediaList,
  activeMediaTab,
  handleMediaTabChange,
  onUploadFile,
  onRetryUploadMediaFile,
  isLoading,
  handleMediaProcessingProgressionMessageAction,
  handleMediaFileUploadedAndProcessingMessageAction,
  onStartHelp,
  joyrideHelperComponent,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const mediaFileTypes = useMemo(() => {
    return MediaUtils.getMediaMimeTypeList().join(',');
  }, []);
  const sizeLimit = MEDIA_SIZE_LIMIT;
  const isSocketConnected = useSocket(socketRoom ?? undefined);
  const { charterId } = useParams<CharterIdPathParam>();

  useEffect(() => {
    const updateProgress = (payload: AssetOrMediaProcessingResponse): void => {
      const { operationId, progress } = payload.data;
      if (progress) {
        dispatch(handleMediaProcessingProgressionMessageAction(operationId, progress, activeMediaTab));
      }
    };

    if (isSocketConnected) {
      SocketManager.onMessage(updateProgress);

      return (): void => SocketManager.offMessage(updateProgress);
    }

    return undefined;
  }, [isSocketConnected, activeMediaTab, dispatch, handleMediaProcessingProgressionMessageAction]);

  const onSizeLimitExceeded = (): void => {
    message.error(t(`charters.mediaLibrary.uploadSizeLimit`, { limit: sizeLimit }));
  };

  const onUploadStatusChangeToError = (info: UploadChangeParam): void => {
    message.error(t('charters.mediaLibrary.uploadError', { fileName: info.file.name }));
  };

  const onUploadStatusChangeToDone = (info: UploadChangeParam): void => {
    if (info.file.type) {
      dispatch(
        handleMediaFileUploadedAndProcessingMessageAction(
          info.file,
          MediaUtils.determineMediaTypeFromMimeType(info.file.type)
        )
      );
    }
  };

  const onArchiveMedia = (media: MediaFile): Promise<MediaFile> => {
    return updateCharterMedia(parseInt(charterId, 10), media.id, { isArchived: true });
  };

  const uploaderProps: FileUploaderProps = {
    triggerText: t('charters.mediaLibrary.upload'),
    modalTitle: t('charters.mediaLibrary.uploadModalTitle'),
    uploadInstructionText: t(`charters.mediaLibrary.uploadTitle`),
    uploadInstructionHint: t(`charters.mediaLibrary.uploadHint`),
    uploadInstructionSizeLimit: t(`charters.mediaLibrary.uploadSizeLimit`, { limit: sizeLimit }),
    acceptFileType: mediaFileTypes,
    sizeLimit,
    onSizeLimitExceeded,
    onUploadStatusChangeToDone,
    onUploadStatusChangeToError,
    onUploadFile,
  };

  return (
    <KannellePageHeader
      title={
        <div className={classes.titleWithIcon}>
          {pageTitle}
          <KannelleHelpButton startHelp={onStartHelp} />
        </div>
      }
      breadcrumb={breadcrumb ?? undefined}
      className={classes.pageContent}
      avatar={{ className: classes.avatar, icon: <DatabaseOutlined /> }}
      extra={
        <>
          <FileUploader {...uploaderProps} />
        </>
      }
    >
      {/* Render the JoyRide helper */}
      {joyrideHelperComponent}

      <Row className={classes.subtitleRow}>
        <p>{pageDescription}</p>
      </Row>

      <MediaLibraryTabs
        isLoading={isLoading}
        mediaList={mediaList}
        {...uploaderProps}
        activeTab={activeMediaTab}
        handleActiveTabChange={handleMediaTabChange}
        onRetryUploadMediaFile={onRetryUploadMediaFile}
        onArchiveMedia={onArchiveMedia}
      />
    </KannellePageHeader>
  );
};

export default MediaLibrary;
