import { Select } from 'antd';
import Fuse from 'fuse.js';
import map from 'lodash/map';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { APIModelCharter } from '../../services/api/types/ChartersServiceTypes';

type Props = {
  handleChange: (value: number[]) => void;
  selectedCharters: number[];
  charterList?: APIModelCharter[];
};

const useStyles = createUseStyles({
  select: {
    width: '100%',
    marginBottom: 8,
  },
});

const CharterMultiSelect: FunctionComponent<Props> = ({ selectedCharters, charterList, handleChange }: Props) => {
  const classes = useStyles();
  const [charterSorted, setCharterSorted] = useState(charterList || []);
  const [query, setQuery] = useState<string>();

  const fuseCharter = new Fuse(charterList || [], {
    keys: ['name'],
    threshold: 0.5,
  });

  useEffect(() => {
    setCharterSorted(charterList || []);
  }, [charterList]);

  useEffect(() => {
    if (!query || query === '') {
      setCharterSorted(charterList || []);
      return;
    }

    const result = fuseCharter.search(query);

    const charterSortedFuse = result.map((res) => res.item);
    setCharterSorted(charterSortedFuse);
    // eslint-disable-next-line
  }, [query]);

  const onSearch = (value: string): void => {
    setQuery(value);
  };

  if (!charterList) {
    return null;
  }

  return (
    <Select
      mode="multiple"
      className={classes.select}
      onChange={handleChange}
      value={selectedCharters}
      filterOption={false}
      onSearch={onSearch}
    >
      {map(charterSorted, (charter) => {
        return (
          <Select.Option key={`charter_option_${charter.id}`} value={charter.id}>
            {charter.name}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default CharterMultiSelect;
