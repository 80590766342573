import { ReloadOutlined } from '@ant-design/icons/lib';
import { Button, Menu } from 'antd';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import React, { FunctionComponent, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { THEME } from '../../Constants';
import useWindowSize from '../../hooks/useWindowSize';
import { RootState } from '../../redux/RootState';
import NotificationUtils from '../../utils/NotificationUtils';
import { Notification } from '../../utils/types/NotificationTypes';
import PageContentLoader from '../loader/PageContentLoader';
import NotificationItem from './NotificationItem';

type Props = MenuItemProps & {
  notifications: Notification[];
  nextData: () => void;
  hasMore: boolean;
  isError?: boolean;
  onReload?: () => void;
};

const useStyles = createUseStyles({
  itemGroup: {
    '& .ant-dropdown-menu-item-group-title': {
      padding: '0 !important',
    },
    '& .ant-dropdown-menu-item-group-list': {
      margin: '0 !important',
    },
  },
  divider: {
    backgroundColor: '#d2d2d2',
    margin: 0,
  },
  loader: {
    marginTop: '10px',
    maxHeight: '100px',
  },
  allFetched: {
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  errorMessage: {
    backgroundColor: THEME.ALERT.ERROR_BACKGROUND,
    color: THEME.ALERT.ERROR_MAIN,
    '&:hover': {
      backgroundColor: THEME.ALERT.ERROR_BACKGROUND,
    },
  },
  reloadButton: {
    marginLeft: '20px',
    height: '20px !important',
    fontSize: '12px !important',
  },
});

const NotificationsList: FunctionComponent<Props> = ({
  notifications,
  nextData,
  hasMore,
  isError = false,
  onReload,
  ...props
}: Props) => {
  const isMobileOrTablet = useSelector((state: RootState) => {
    return state.app.isMobileOrTablet;
  });
  const classes = useStyles();
  const maxHeight = isMobileOrTablet ? 400 : 700;
  const windowSize = useWindowSize();

  const [height, setHeight] = useState<number>(maxHeight);
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!(windowSize && windowSize.height)) {
      return;
    }
    const windowHeight = windowSize.height * 0.7;
    setHeight(windowHeight < maxHeight ? windowHeight : maxHeight);
  }, [windowSize]);

  useLayoutEffect(() => {
    if (!(ref.current && notifications.length && windowSize.height)) {
      return;
    }

    const listHeight = ref.current.clientHeight;
    const windowHeight = windowSize.height * 0.7;
    if (listHeight) {
      setHeight(listHeight <= maxHeight && listHeight > windowHeight ? listHeight : windowHeight);
    }
  }, [maxHeight, notifications]);

  return (
    <Menu.ItemGroup {...props} className={classes.itemGroup}>
      <InfiniteScroll
        dataLength={notifications.length}
        hasMore={hasMore}
        loader={
          !isError && (
            <div className={classes.loader}>
              <PageContentLoader />
            </div>
          )
        }
        height={height}
        next={nextData}
        endMessage={<p className={classes.allFetched}>{t('header.notifications.allNotificationsFetched')}</p>}
      >
        {notifications && notifications.length > 0 && (
          <div ref={ref}>
            {notifications
              .slice()
              .sort(NotificationUtils.sortNotificationsByAntiChronologicalCreationDate)
              .map((notification, index) => {
                return (
                  <React.Fragment key={notification.id}>
                    <NotificationItem notification={notification} />
                    {index !== notifications.length - 1 && <Menu.Divider className={classes.divider} {...props} />}
                  </React.Fragment>
                ); // No divider on the last notification
              })}
            {isError && (
              <Menu.Item className={classes.errorMessage} {...props}>
                {t('ajaxError.notificationListFetch')}
                {onReload && (
                  <Button
                    type="primary"
                    shape="round"
                    danger
                    icon={<ReloadOutlined />}
                    size="small"
                    onClick={onReload}
                    className={classes.reloadButton}
                  >
                    {t('global.reload')}
                  </Button>
                )}
              </Menu.Item>
            )}
          </div>
        )}
      </InfiniteScroll>
    </Menu.ItemGroup>
  );
};

export default NotificationsList;
