import { Button, Col, Descriptions, Divider, Form, Typography } from 'antd';
import { join, map } from 'lodash';
import moment from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import InputCheckBox from '../../components/form/InputCheckBox';
import Company from '../../model/Company';
import { RootState } from '../../redux/RootState';
import { ChargebeeSubscriptionObject } from '../../services/api/types/ChargebeeServiceTypes';
import { APICompanyUsers, APIPatchCompanyParams } from '../../services/api/types/CompaniesServiceTypes';
import CompaniesUtils from '../../utils/CompaniesUtils';

const { Title } = Typography;

type Props = {
  currentCompany?: Company;
  subscriptions?: ChargebeeSubscriptionObject[];
  contacts?: APICompanyUsers[];
  handleSubmit: (values: APIPatchCompanyParams, callback: () => void) => void;
  fileStorageAgreed?: boolean;
};

const useStyles = createUseStyles({
  titleSubscription: {
    marginBottom: 0,
    fontWeight: 'bold',
  },
  buttonContainer: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: 8,
    },
  },
});

const CompanyDescription: FunctionComponent<Props> = ({
  currentCompany,
  subscriptions,
  contacts = [],
  handleSubmit,
  fileStorageAgreed = false,
}: Props) => {
  const bundle = useSelector((state: RootState) => state.companies.bundle);
  const [asyncLoading, setAsyncLoading] = useState(false);
  const [acceptedFileStorage, setAcceptedFileStorage] = useState(fileStorageAgreed);

  const { t } = useTranslation();
  const classes = useStyles();
  const [form] = Form.useForm();

  const isUserPoc = currentCompany?.isUserPoc || false;
  const isFormEditable = isUserPoc;

  const initialValues = {
    acceptFileStorage: fileStorageAgreed,
  };

  if (!currentCompany) {
    return null;
  }

  const contactsEmail = contacts.map((contact) => contact.email);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 10 },
      xl: { span: 8 },
      xxl: { span: 6, offset: 2 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 12 },
      xl: { span: 12 },
      xxl: { span: 10 },
    },
  };

  const onReset = (): void => {
    form.resetFields();
  };

  const handleCallbackOnFileStorageChange = (fileStorageAccepted: boolean): void => {
    setAcceptedFileStorage(fileStorageAccepted);
  };

  const handleFormSubmit = (): void => {
    form.validateFields().then(() => {
      const formattedValues = {
        name: currentCompany.name,
        email: currentCompany.email,
        billingEmail: currentCompany.billingEmail,
        maxNbLicenses: currentCompany.maxNbLicenses,
        maxNbCharters: currentCompany.maxNbCharters,
        autoCollection: 'off',
        dunsSiret: currentCompany.dunsSiret,
        isActive: currentCompany.isActive,
        acceptedFileStorage,
      };

      setAsyncLoading(true);
      handleSubmit(formattedValues, () => setAsyncLoading(false));
    });
  };

  return (
    <>
      <Descriptions title={<Title level={3}>{currentCompany.name}</Title>} column={{ xs: 1, sm: 1, md: 1, lg: 2 }}>
        <Descriptions.Item label={t('company.form.fields.email')}>{currentCompany.email}</Descriptions.Item>
        <Descriptions.Item label={t('company.form.fields.billingEmail')}>
          {currentCompany.billingEmail}
        </Descriptions.Item>

        {map(subscriptions, ({ subscription }) => {
          return (
            <React.Fragment key={subscription.id}>
              <Col span={2}>
                <h3 className={classes.titleSubscription}>{t('company.form.subscriptionSection')}</h3>
              </Col>

              <Descriptions.Item key="startedAt" label={t('company.form.fields.startDate')} span={2}>
                {moment(subscription.started_at * 1000).format(t('timeFormats.yearMonthDayHourMinute'))}
              </Descriptions.Item>
              <Descriptions.Item key="nextBilling" label={t('company.form.fields.nextBilling')} span={2}>
                {moment(subscription.next_billing_at * 1000).format(t('timeFormats.yearMonthDayHourMinute'))}
              </Descriptions.Item>
              <Descriptions.Item key="billingPeriod" label={t('company.form.fields.billingPeriod')} span={2}>
                {`${subscription.billing_period} / ${t(`timeLabel.${subscription.billing_period_unit}`)}`}
              </Descriptions.Item>
            </React.Fragment>
          );
        })}

        <Descriptions.Item label={t('company.form.fields.contacts', { count: contactsEmail.length })}>
          {contacts.length > 0 ? join(contactsEmail, ', ') : t('company.form.fields.noContacts')}
        </Descriptions.Item>
      </Descriptions>

      <Divider>{t('company.form.settingsSection')}</Divider>

      <Form form={form} {...formItemLayout} initialValues={initialValues}>
        <InputCheckBox
          name="acceptFileStorage"
          label={t('company.form.fields.fileStorage')}
          checkboxLabel={t('company.form.fields.acceptFileStorage')}
          defaultChecked={initialValues.acceptFileStorage}
          disabled={!isFormEditable}
          extra={t('company.form.fields.acceptFileStorageExtra')}
          helps={CompaniesUtils.buildFileStorageAgreementHelp(
            !isFormEditable && contactsEmail.length > 0,
            contactsEmail
          )}
          callback={handleCallbackOnFileStorageChange}
        />

        {isFormEditable && (
          <Col sm={24} md={22} xl={20} xxl={18}>
            <span className={classes.buttonContainer}>
              <Button onClick={onReset}>{t('global.cancel')}</Button>
              <Button type="primary" onClick={handleFormSubmit} loading={asyncLoading}>
                {t('company.form.update')}
              </Button>
            </span>
          </Col>
        )}
      </Form>

      {bundle && <Divider>{t('company.form.bundleSection')}</Divider>}
      {bundle && (
        <Descriptions title={<Title level={3}>{bundle.bundleName}</Title>} column={{ xs: 1, sm: 1, md: 1, lg: 2 }}>
          <Descriptions.Item label={t('company.form.fields.maxNbAdminsAndOwners')}>
            {bundle.featureValues.MAX_ADMINS_AND_OWNERS}
          </Descriptions.Item>
          <Descriptions.Item label={t('company.form.fields.maxNbCreators')}>
            {bundle.featureValues.MAX_CREATORS}
          </Descriptions.Item>
          <Descriptions.Item label={t('company.form.fields.maxNbGroups')}>
            {bundle.featureValues.MAX_CHARTERS}
          </Descriptions.Item>
          <Descriptions.Item label={t('company.form.fields.maxNbLogos')}>
            {bundle.featureValues.MAX_LOGO_PER_CHARTER}
          </Descriptions.Item>
          <Descriptions.Item label={t('company.form.fields.maxNbIntros')}>
            {bundle.featureValues.MAX_INTRO_PER_CHARTER}
          </Descriptions.Item>
          <Descriptions.Item label={t('company.form.fields.maxNbOutros')}>
            {bundle.featureValues.MAX_OUTRO_PER_CHARTER}
          </Descriptions.Item>
          <Descriptions.Item label={t('company.form.fields.maxNbPrivateScenarios')}>
            {bundle.featureValues.MAX_CUSTOM_SCENARIOS}
          </Descriptions.Item>
          <Descriptions.Item label={t('company.form.fields.subtitlesAccess')}>
            {bundle.featureValues.ACCESS_TO_SUBTITLES ? t('global.yes').toLowerCase() : t('global.no').toLowerCase()}
          </Descriptions.Item>
          <Descriptions.Item label={t('company.form.fields.analyticsAccess')}>
            {bundle.featureValues.ACCESS_TO_ANALYTICS ? t('global.yes').toLowerCase() : t('global.no').toLowerCase()}
          </Descriptions.Item>
        </Descriptions>
      )}
    </>
  );
};

export default CompanyDescription;
