import { Roles } from '../../core/rule/RolesTypes';
import { APIModelCharter } from '../../services/api/types/ChartersServiceTypes';
import { RootState } from '../RootState';

export class ChartersMiddlewareService {
  static getCharterFromStateById = (state: RootState, charterId: number): APIModelCharter | undefined => {
    return state.charters.list?.filter((charter) => charter.id === charterId)[0];
  };

  static getCurrentUserRoleByCharter = (state: RootState, charter?: APIModelCharter): string => {
    if (!charter?.currentUser && state.companies.current?.isUserPoc) {
      return Roles.Owner;
    }

    return charter?.currentUser?.role || Roles.Creator;
  };
}
