import { BreadcrumbProps } from 'antd/es/breadcrumb';
import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import { UploadFile } from 'antd/lib/upload/interface';
import { CancelTokenSource } from 'axios';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import EmptyPageContent from '../../../../components/empty/EmptyPageContent';
import MediaLibrary from '../../../../components/media-library/MediaLibrary';
import { DraggerCustomRequestParams } from '../../../../components/modal/FileUploader';
import { LINK, LOGGING_EVENT, MEDIA_STATUS } from '../../../../Constants';
import useCanAccess from '../../../../hooks/useCanAccess';
import useFetchCharterById from '../../../../hooks/useFetchCharterById';
import JoyRideHelpCharterMediaLibrary from '../../../../onboarding/JoyRideHelpCharterMediaLibrary';
import {
  mediaFileUploadedAndProcessing,
  refreshProcessingMediaByMediaType,
  saveCharterMedia,
  updateMediaProcessingProgression,
  updateProcessingMediaOperationId,
} from '../../../../redux/action/ChartersAction';
import { RootState } from '../../../../redux/RootState';
import smallLogo from '../../../../resources/img/K-rectangle-small.png';
import { APIManager } from '../../../../services/api/APIManager';
import { getCharterMedia, getCharterMediaSignedUrlToUpload } from '../../../../services/api/ChartersService';
import { uploadFileToSignedUrl } from '../../../../services/api/GlobalService';
import { APIGetCharterMediaResponse } from '../../../../services/api/types/ChartersServiceTypes';
import { CharterIdPathParam } from '../../../../services/navigation/NavigationConfigTypes';
import JoyRideUtils from '../../../../utils/JoyRideUtils';
import MathUtils from '../../../../utils/MathUtils';
import MediaUtils from '../../../../utils/MediaUtils';
import { PermissionList } from '../../../../utils/types/CharterPermissionTypes';
import { MediaType } from '../../../../utils/types/MediaTypes';

const CharterMediaLibrary: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { charterId: charterIdString } = useParams<CharterIdPathParam>();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const activeTabParam = (query.get('activeTab') as MediaType) ?? MediaType.IMAGE;
  const [activeTab, setActiveTab] = useState<MediaType>(MediaType.IMAGE);
  const [isMediaLoading, setIsMediaLoading] = useState(false);
  const [room, setRoom] = useState<string>();
  const [runHelp, setRunHelp] = useState(false);
  const [addedHelpExample, setAddedHelpExample] = useState(false);
  const { loading: isCharterLoading } = useFetchCharterById(parseInt(charterIdString, 10));

  const { hasUserAccessToCharter } = useCanAccess(PermissionList.WEB_DASHBOARD);

  const charter = useSelector((state: RootState) => state.charters.current);
  const company = useSelector((state: RootState) => state.companies.current);
  const charterMedia = useSelector((state: RootState) => state.charters.currentCharterMedia);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);
  const charterId = charter?.id;

  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());

  useEffect(() => {
    const cancelTokenSource = cancelTokenSourceRef.current;

    return (): void => {
      cancelTokenSource.cancel('Cancelled fetching charter media due to component unmount.');
    };
  }, []);

  useEffect(() => {
    if (activeTabParam) {
      setActiveTab(activeTabParam);
    }
  }, [activeTabParam]);

  // Remove processed media of mediaType from the processingMedia in store
  useEffect(() => {
    if (!charterMedia) {
      return;
    }

    // The active tab (key) corresponds to the current mediaType
    dispatch(refreshProcessingMediaByMediaType(charterMedia, activeTab));
  }, [charterMedia, dispatch, activeTab]);

  useEffect(() => {
    if (!charterId) {
      return;
    }
    setRoom(`charters/${charterId}/media`);
  }, [charterId]);

  // Fetch the charter media
  useEffect(() => {
    // Do not fetch the medias while the user has no permission to access this feature
    if (!charterId) {
      return;
    }

    setIsMediaLoading(true);

    const cancelTokenSource = cancelTokenSourceRef.current;
    getCharterMedia(charterId, false, cancelTokenSource)
      .then((response: APIGetCharterMediaResponse) => {
        const { data } = response;
        const processedMedia = data.items.filter((mediaItem) => mediaItem.status === MEDIA_STATUS.PROCESSED);
        dispatch(saveCharterMedia(processedMedia));
      })
      .catch((e) => {
        log.debug('Error during charter media fetch', e);
      })
      .finally(() => {
        setIsMediaLoading(false);
      });
  }, [charterId, dispatch]);

  const onUploadFile = (requestParam: DraggerCustomRequestParams): void => {
    if (!charterId) {
      return;
    }
    const mediaType = MediaUtils.determineMediaTypeFromMimeType(requestParam.file.type);

    const params = {
      charterId,
      filename: requestParam.file.name,
      mediaType,
    };

    loggingManager.logEvent(LOGGING_EVENT.UPLOAD_MEDIA, {
      name: requestParam.file.name,
      size: `${Math.floor(MathUtils.convertBytesToKilobytes(requestParam.file.size))} ${t('charters.mediaLibrary.KB')}`,
      charterId,
      companyId: company?.id,
      mimeType: requestParam.file.type,
    });

    getCharterMediaSignedUrlToUpload(params)
      .then((signedUrl) => {
        return uploadFileToSignedUrl(signedUrl.fileUploadURL, requestParam.file, (event) => {
          requestParam.onProgress({ percent: (event.loaded / event.total) * 100 }, requestParam.file);
        })
          .then(() => {
            requestParam.onSuccess({ ...requestParam, data: signedUrl.metadata });
            setActiveTab(mediaType);
          })
          .catch((err) => requestParam.onError(err));
      })
      .catch((err) => requestParam.onError(err));
  };

  const onRetryUploadMediaFile = (uploadFile: UploadFile, mediaType: MediaType): void => {
    if (!charterId) {
      return;
    }

    const params = {
      charterId,
      filename: uploadFile.name,
      mediaType,
    };

    dispatch(mediaFileUploadedAndProcessing(uploadFile, activeTab));

    getCharterMediaSignedUrlToUpload(params)
      .then((signedUrl) => {
        dispatch(
          updateProcessingMediaOperationId(
            uploadFile.response.data.operationId,
            signedUrl.metadata.operationId,
            activeTab
          )
        );

        return uploadFileToSignedUrl(signedUrl.fileUploadURL, uploadFile.originFileObj).catch((err) =>
          log.error(`An error occurred while retrying to upload a media: ${err}`)
        );
      })
      .catch((err) => log.error(`An error occurred while retrying to upload a media: ${err}`));
  };

  const handleMediaTabChange = (newTab: MediaType): void => {
    setActiveTab(newTab);
  };

  const startHelp = (): void => {
    // If there is no media for the selected tab (mediaType)
    if (!(charterMedia && charterMedia.filter((media) => media.mediaType === activeTab).length > 0)) {
      // We add a sample media of the right mediaType to the charter media
      const mediaUrl = activeTab === MediaType.IMAGE ? smallLogo : smallLogo.replace('.png', '.mp4');
      const sampleMedia = JoyRideUtils.generateSampleMediaFile(mediaUrl, activeTab);
      dispatch(
        saveCharterMedia([...(charterMedia?.filter((media) => media.mediaType !== activeTab) || []), sampleMedia])
      );
      setAddedHelpExample(true);
    }

    setRunHelp(true);
  };

  const endHelp = (): void => {
    setRunHelp(false);
    // If one sample media had been added for the selected tab (mediaType), we remove it
    if (addedHelpExample) {
      setAddedHelpExample(false);
      dispatch(saveCharterMedia(charterMedia?.filter((media) => media.mediaType !== activeTab) || []));
    }
  };

  if (!charter) {
    return <EmptyPageContent />;
  }

  if (!(charterId && hasUserAccessToCharter(charterId))) {
    history.push(LINK.UNAUTHORIZED.path);
    return null;
  }

  const routes = [
    {
      path: '/charters',
      breadcrumbName: t('menu.charters'),
    },
    {
      path: `/charters/${charter.id}`,
      breadcrumbName: charter.name,
    },
    {
      path: `/charters/${charter.id}/elements`,
      breadcrumbName: t('charters.graphicalElements.title'),
    },
    {
      path: location.pathname,
      breadcrumbName: t('charters.mediaLibrary.title'),
    },
  ];

  const breadcrumbProps: BreadcrumbProps = {
    itemRender: (route: Route) => {
      if (route.path === location.pathname) {
        return route.breadcrumbName;
      }
      return <Link to={route.path}>{route.breadcrumbName}</Link>;
    },
    routes,
  };

  return (
    <>
      <MediaLibrary
        pageTitle={t(`charters.mediaLibrary.title`)}
        pageDescription={t(`charters.mediaLibrary.pageDesc`)}
        breadcrumb={breadcrumbProps}
        socketRoom={room}
        mediaList={charterMedia?.filter((m) => !m.isArchived)}
        activeMediaTab={activeTab}
        handleMediaTabChange={handleMediaTabChange}
        onUploadFile={onUploadFile}
        onRetryUploadMediaFile={onRetryUploadMediaFile}
        isLoading={isMediaLoading || isCharterLoading}
        handleMediaProcessingProgressionMessageAction={updateMediaProcessingProgression}
        handleMediaFileUploadedAndProcessingMessageAction={mediaFileUploadedAndProcessing}
        onStartHelp={startHelp}
        joyrideHelperComponent={<JoyRideHelpCharterMediaLibrary runHelp={runHelp} callbackRunDone={endHelp} />}
      />
    </>
  );
};

export default CharterMediaLibrary;
