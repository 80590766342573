import { PageHeader } from 'antd';
import { PageHeaderProps } from 'antd/lib/page-header';
import classNames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { THEME } from '../../Constants';
import { RootState } from '../../redux/RootState';

type Props = {
  children: JSX.Element[] | JSX.Element | ReactNode | string;
  title?: ReactNode;
  subTitle?: ReactNode;
} & PageHeaderProps;

type StyleProps = { isMobileOrTablet: boolean };

const useStyles = createUseStyles({
  pageHeader: {
    '& .ant-page-header-heading': {
      marginBottom: '5px',
    },

    '& .ant-page-header-heading-extra': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  mobilePageHeader: {
    padding: '16px 8px',
    '& .ant-page-header-heading-left': {
      width: '100%',
      display: 'flex',

      '& .ant-page-header-heading-title': {
        marginRight: 0,
        maxWidth: '75%',
        '& *': {
          overflow: 'hidden',
          whiteSpace: 'normal',
        },
      },

      '& .ant-page-header-heading-sub-title': {
        maxWidth: '25%',
        marginRight: '0px !important',
      },
    },

    '& .ant-page-header-heading-extra': {
      flexDirection: 'column',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginTop: '15px',

      '& > *:not(button):not(:last-child)': {
        marginBottom: '10px',
      },

      '& > *': {
        marginLeft: '0',
        width: 'auto',
        maxWidth: '100%',
        minWidth: '70%',
        marginBottom: '5px',

        '& > button': {
          width: '100%',
        },
      },
    },
  },
  mobilePageHeaderWithoutSubtitle: {
    '& .ant-page-header-heading-left': {
      '& .ant-page-header-heading-title': {
        maxWidth: '100%',
        '& *': {
          overflow: 'unset',
          textOverflow: 'unset',
          whiteSpace: 'normal',
        },
      },

      '& .ant-page-header-heading-sub-title': {
        maxWidth: '0%',
      },
    },
  },
  title: {
    color: THEME.GLOBAL.MAIN_TITLE_COLOR,

    '& > *': {
      fontSize: ({ isMobileOrTablet }: StyleProps): string =>
        isMobileOrTablet ? '26px !important' : '38px !important',
    },

    '& h1': {
      color: THEME.GLOBAL.MAIN_TITLE_COLOR,
      marginBottom: '0 !important',
    },
  },
  subTitle: {
    '& *': {
      marginLeft: ({ isMobileOrTablet }: StyleProps): string =>
        isMobileOrTablet ? '0px !important' : '25px!important',
      fontSize: ({ isMobileOrTablet }: StyleProps): string => (isMobileOrTablet ? '11px!important' : '18px!important'),
      marginBottom: '0 !important',
      display: 'block',
    },
  },
});

const KannellePageHeader: FunctionComponent<Props> = (props: Props) => {
  const { children, title, subTitle, className, ...rest } = props;
  const isMobileOrTablet = useSelector((state: RootState) => {
    return state.app.isMobileOrTablet;
  });

  const classes = useStyles({ isMobileOrTablet });

  const Title = <span className={classes.title}>{title}</span>;
  const Subtitle = subTitle && <span className={classes.subTitle}>{subTitle}</span>;

  return (
    <PageHeader
      title={Title}
      subTitle={Subtitle}
      {...rest}
      className={classNames(
        classes.pageHeader,
        isMobileOrTablet && classes.mobilePageHeader,
        isMobileOrTablet && !subTitle && classes.mobilePageHeaderWithoutSubtitle,
        className
      )}
    >
      {children}
    </PageHeader>
  );
};

export default KannellePageHeader;
