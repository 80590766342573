import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons/lib';
import { Card, message, Tooltip } from 'antd';
import Meta from 'antd/lib/card/Meta';
import classNames from 'classnames';
import Color from 'color';
import React, { FunctionComponent, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { DEVICE_SIZES_QUERIES } from '../../Constants';
import ColorPicker from '../color-picker/ColorPicker';

type Props = {
  color: string;
  size?: 'small' | 'large';
  layout?: 'vertical' | 'horizontal';
  onEditColor?: (newColor: string) => void;
  onDeleteColor?: () => void;
};

const useStyles = createUseStyles({
  cardContainer: {
    position: 'relative',
  },
  colorCard: {
    maxWidth: 150,
    minWidth: 100,
    boxShadow: '0 8px 8px 0 hsla(0, 0%, 0%, 0.15) !important',
    borderRadius: 8,
    overflow: 'hidden',

    [`@media screen and ${DEVICE_SIZES_QUERIES.LARGE}`]: {
      width: 140,
    },
    [`@media screen and ${DEVICE_SIZES_QUERIES.MEDIUM}`]: {
      width: 125,
    },
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      width: 100,
    },

    '& .ant-card-body': {
      padding: '8px 16px',
    },

    '& .ant-card-actions': {
      backgroundColor: 'inherit',
      border: 'unset',
      '& li': {
        margin: '0 0 8px 0',
        '& *': {
          height: '100%',
        },
      },
    },
  },

  colorCardHorizontal: {
    height: 'auto !important',
    borderColor: '#dddddd',
    maxWidth: 150,
    minWidth: 100,
    boxShadow: '0 8px 8px 0 hsla(0, 0%, 0%, 0.15) !important',
    borderRadius: 8,
    overflow: 'hidden',

    display: 'flex',
    width: 110,

    '& .ant-card-body': {
      padding: '8px 16px',
      display: 'none',
    },
    '& .ant-card-cover': {
      flexGrow: 1,
      transform: 'translateY(0px)',
    },

    '& .ant-card-actions': {
      display: 'flex',
      flexDirection: 'column',

      backgroundColor: 'inherit',
      border: 'unset',
      '& li': {
        width: 'auto',
        border: 'unset',
        margin: '0 0 0 0',

        ' & .anticon': {
          fontSize: 14,
        },

        '& *': {
          height: '100%',
        },
      },
    },
  },

  smallCard: {
    height: '100px',
  },

  colorCover: ({ color, size }: Props) => ({
    height: size === 'large' ? '100px' : '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: color,
    borderRadius: '8px 8px 0 0',
    borderColor: color,
    '&:hover': {
      backgroundColor: color,
    },
    '&:active, &:focus': {
      backgroundColor: color,
    },
    borderBottom: '1px solid #f0f0f0',
  }),

  colorCoverHorizontal: ({ color }: Props) => ({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: color,
    '&:hover': {
      backgroundColor: color,
    },
    '&:active, &:focus': {
      backgroundColor: color,
    },
    borderRight: '2px solid #f0f0f0',
  }),

  lightTextColor: {
    color: '#FFFFFF',
  },

  darkTextColor: {
    color: '#000000',
  },
});

const ColorCard: FunctionComponent<Props> = ({
  color,
  size = 'large',
  layout = 'vertical',
  onEditColor = undefined,
  onDeleteColor = undefined,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles({ color, size });
  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);

  const isEditable = onEditColor !== undefined;
  const isDeletable = onDeleteColor !== undefined;

  const onEditActionClick = (): void => {
    setIsColorPickerVisible(true);
  };

  const onClosePicker = (): void => {
    setIsColorPickerVisible(false);
  };

  const cardClasses = layout === 'horizontal' ? classes.colorCardHorizontal : classes.colorCard;
  const coverClasses = layout === 'horizontal' ? classes.colorCoverHorizontal : classes.colorCover;
  const cover =
    size === 'large' ? (
      <div className={classes.colorCover} />
    ) : (
      <div
        className={classNames(coverClasses, Color(color).isLight() ? classes.darkTextColor : classes.lightTextColor)}
      >
        {color}
      </div>
    );
  return (
    <div className={classes.cardContainer}>
      <Card
        className={classNames(cardClasses, size === 'small' && classes.smallCard)}
        cover={cover}
        actions={[
          <Tooltip key={`copyToClipboard_${color}`} title={t('global.clickCopyToClipboard')} placement="top">
            <CopyToClipboard
              text={color}
              onCopy={(): void => {
                message.info(t('global.copiedToClipboard'));
              }}
            >
              <CopyOutlined />
            </CopyToClipboard>
          </Tooltip>,
          ...(isEditable
            ? [
                <Tooltip key={`editColor_${color}`} title={t('global.edit')} placement="top">
                  <div onClick={onEditActionClick}>
                    <EditOutlined />
                  </div>
                </Tooltip>,
              ]
            : []),
          ...(isDeletable
            ? [
                <Tooltip key={`deleteColor_${color}`} title={t('global.delete')} placement="top">
                  <div onClick={onDeleteColor}>
                    <DeleteOutlined />
                  </div>
                </Tooltip>,
              ]
            : []),
        ]}
      >
        {size === 'large' && <Meta title={color} />}
      </Card>

      {isEditable && isColorPickerVisible && (
        <ColorPicker initialColor={color} onValidate={onEditColor!} onClosePicker={onClosePicker} />
      )}
    </div>
  );
};

export default ColorCard;
