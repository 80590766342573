import { SwapOutlined } from '@ant-design/icons/lib';
import { Button, Form, message, Modal } from 'antd';
import Tooltip from 'antd/es/tooltip';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import RoleRadioGroup from '../../../../components/form/RoleRadioGroup';
import { LOGGING_EVENT } from '../../../../Constants';
import { Role } from '../../../../core/rule/Roles';
import { Roles } from '../../../../core/rule/RolesTypes';
import { RootState } from '../../../../redux/RootState';
import { patchUserByCharterIdAndUserId } from '../../../../services/api/ChartersService';
import { APIModelCharter, UsersModelCharter } from '../../../../services/api/types/ChartersServiceTypes';
import LogUtils from '../../../../utils/LogUtils';
import { SubRowCharterSelect } from '../../../../utils/types/UsersTableTypes';

const useStyles = createUseStyles({
  buttonPosition: {
    float: 'right',
  },
});

type Props = {
  selectedRows: SubRowCharterSelect[];
  callbackHandleUpdate: (
    updating: boolean,
    charterId: number,
    updatedDataArray?: UsersModelCharter[],
    error?: boolean
  ) => void;
};

const UpdateUserRoleModal: FunctionComponent<Props> = ({ selectedRows, callbackHandleUpdate }: Props) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const [form] = Form.useForm();
  const [role, setRole] = useState(Roles.Creator);
  const companyStats = useSelector((state: RootState) => state.companies.stats);
  const currentCompany = useSelector((state: RootState) => state.companies.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);

  // Todo to remove when open to other users
  const currentUserRole = new Role(Roles.KnlTeam);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      // we reset to default value if the user doesn't exist
      setRole(Roles.Creator);
      form.setFieldsValue({ role: Roles.Creator });
    }
  }, [visible, form]);

  const showModal = (): void => {
    setVisible(true);
  };

  const handleSubmit = (): void => {
    form.validateFields().then((values) => {
      if (!companyStats) {
        return;
      }

      if (!companyStats.canUpdateCompanyUsersFromRoleToRole(selectedRows, values.role)) {
        message.error(t('global.maxNbLicensesReachedError', { count: companyStats?.users.max }));
        return;
      }

      setVisible(false);
      selectedRows.forEach((subRow: SubRowCharterSelect) => {
        const event = LogUtils.getCompanyUsersBatchUpdateEvent(
          LOGGING_EVENT.BATCH_UPDATE_USERS_ROLE,
          subRow,
          { role: values.role },
          subRow.charters.map((charter) => charter.id),
          currentCompany?.id
        );
        loggingManager.logEventObject(event);
        const updateData = {
          role: values.role,
        };

        subRow.charters.forEach((charter: APIModelCharter) => {
          if (charter.currentUser.role === values.role) {
            return;
          }

          callbackHandleUpdate(true, charter.id);

          // one request per user to avoid bulk fail
          patchUserByCharterIdAndUserId(charter.id, subRow.userId, updateData)
            .then((response) => {
              if (response.data) {
                companyStats.userUpdatedFromRole(
                  charter.currentUser.role as Roles,
                  values.role,
                  charter.currentUser.isActive
                );
                callbackHandleUpdate(false, charter.id, [response.data]);
              }
            })
            .catch((error) => {
              log.error(error);
              callbackHandleUpdate(false, charter.id, undefined, true);
              message.error(
                <>
                  {t('users.updateRole.error', { count: selectedRows.length })}
                  <br />
                  {t('support.global.emojiFingerRight')}
                  <a href={`mailto:${t('support.global.supportEmail')}`}>{t('support.global.supportEmail')}</a>
                  {t('support.global.emojiFingerLeft')}
                </>
              );
            });
        });
      });
    });
  };

  const handleCancel = (): void => {
    setVisible(false);
  };

  const handleRoleChange = (value: Roles): void => {
    setRole(value);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  const button =
    selectedRows.length === 0 ? (
      <Tooltip title={t('users.selectOnSubTable')}>
        <Button className={classes.buttonPosition} disabled onClick={showModal}>
          <SwapOutlined /> {t('users.updateRoleButton')}
        </Button>
      </Tooltip>
    ) : (
      <Button className={classes.buttonPosition} onClick={showModal}>
        <SwapOutlined /> {t('users.updateRoleButton')}
      </Button>
    );

  return (
    <>
      {button}
      <Modal
        centered
        title={t('users.updateRole.title', { count: selectedRows.length })}
        visible={visible}
        onOk={handleSubmit}
        onCancel={handleCancel}
        cancelText={t('global.cancel')}
        okText={t('global.confirm')}
        forceRender
      >
        <Form
          form={form}
          {...formItemLayout}
          initialValues={{
            role: Roles.Creator,
          }}
        >
          <RoleRadioGroup
            label={t('users.fields.role')}
            visibleRoles={currentUserRole.getAccessibleRoles()}
            role={role}
            callbackHandleChange={handleRoleChange}
          />
        </Form>
      </Modal>
    </>
  );
};

export default UpdateUserRoleModal;
