import { EditFilled, LoadingOutlined } from '@ant-design/icons';
import { Form, message, Modal } from 'antd';
import { FormInstance } from 'antd/lib/form';
import classNames from 'classnames';
import log from 'loglevel';
import React, { FunctionComponent, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import Name from '../../../../components/form/Name';
import KannelleRetryButton from '../../../../components/retry-button/KannelleRetryButton';
import { LOGGING_EVENT, THEME } from '../../../../Constants';
import { RootState } from '../../../../redux/RootState';
import { APICompanyUsers } from '../../../../services/api/types/CompaniesServiceTypes';
import { APIModelUser } from '../../../../services/api/types/UsersServiceTypes';
import { patchUser } from '../../../../services/api/UsersService';
import LogUtils from '../../../../utils/LogUtils';
import StringUtils from '../../../../utils/StringUtils';

type Props = {
  user: APICompanyUsers;
  filter: Record<string, string> | undefined;
  handleUserNameUpdated: (user: APIModelUser) => void;
  isCurrentUser?: boolean;
  isEditable?: boolean;
};

const useStyles = createUseStyles({
  usernameContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  editableUsername: {
    cursor: 'pointer',
  },
  nonEditableUsername: {
    cursor: 'default',
  },
  you: {
    marginLeft: '5px',
    color: THEME.DEFAULT.MAIN_TEXT_COLOR,
    '& span': {
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
  },
  highlightedText: {
    '& mark': {
      backgroundColor: '#ffc069',
      padding: 0,
    },
  },
  editUsernameIcon: {
    fontSize: '10px',
  },
  loadingIcon: {
    extend: 'editUsernameIcon',
  },
  actionsContainer: {
    '& :not(:last-child)': {
      marginRight: '5px',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const EditableUsername: FunctionComponent<Props> = ({
  user,
  filter,
  handleUserNameUpdated,
  isCurrentUser = false,
  isEditable = true,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [form] = Form.useForm();
  const formRef = useRef<FormInstance>(null);

  const currentCompany = useSelector((state: RootState) => state.companies.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);

  const username = user.name;

  const onEditClick = (): void => {
    if (isEditable) {
      setIsModalOpened(true);
    }
  };

  const onCloseModal = (): void => {
    setIsModalOpened(false);
  };

  const onCancel = (): void => {
    if (formRef.current && form) {
      form.resetFields();
    }
    onCloseModal();
  };

  const onSubmit = (): void => {
    if (!formRef.current) {
      return;
    }

    form.validateFields().then((values) => {
      setIsLoading(true);
      setIsError(false);

      if (user.id) {
        const event = LogUtils.getUsersUpdateEvent(
          LOGGING_EVENT.UPDATE_USER,
          user.id,
          { name: values.name },
          undefined,
          currentCompany?.id
        );
        loggingManager.logEventObject(event);

        patchUser(user.id, { name: values.name })
          .then((response) => {
            handleUserNameUpdated(response.data);
            message.success(t('users.editUsername.success'));
            setIsLoading(false);
          })
          .catch((error) => {
            log.error(error);
            message.error(t('users.editUsername.error'));
            setIsLoading(false);
            setIsError(true);
          });

        onCloseModal();
      }
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  return (
    <>
      <div
        onClick={isEditable && !isLoading ? onEditClick : undefined}
        className={classNames(
          classes.usernameContainer,
          isEditable && !isLoading ? classes.editableUsername : classes.nonEditableUsername
        )}
      >
        {filter && filter.name ? (
          <Highlighter
            className={classes.highlightedText}
            searchWords={[filter.name]}
            autoEscape
            textToHighlight={username}
            sanitize={(text): string => StringUtils.normalize(text)}
          />
        ) : (
          <span>{username}</span>
        )}
        {isCurrentUser && (
          <span className={classes.you}>
            (<span>{t('users.you')}</span>)
          </span>
        )}

        <span className={classes.actionsContainer}>
          {isEditable && !isLoading && isError && (
            <KannelleRetryButton
              onClick={(e): void => {
                e.stopPropagation();
                onSubmit();
              }}
            />
          )}
          {isEditable && !isLoading && <EditFilled className={classes.editUsernameIcon} />}
          {isEditable && isLoading && <LoadingOutlined className={classes.loadingIcon} />}
        </span>
      </div>

      <Modal
        centered
        title={t('users.editUsername.title')}
        visible={isModalOpened}
        onOk={onSubmit}
        onCancel={onCancel}
        cancelText={t('global.cancel')}
        okText={t('global.submit')}
      >
        <Form
          form={form}
          {...formItemLayout}
          initialValues={{
            name: username,
          }}
          ref={formRef}
        >
          <Name
            key={`editUserName_${user.id}`}
            label={t('users.fields.name')}
            extra={t('users.fields.nameDesc')}
            placeholder={t('users.fields.namePlaceholder')}
          />
        </Form>
      </Modal>
    </>
  );
};

export default EditableUsername;
