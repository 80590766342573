import { Form, Input } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { Rule } from 'antd/lib/form';
import React, { FunctionComponent } from 'react';

type Props = {
  name: string;
  isReadOnly?: boolean;
  required?: boolean;
  errorMessage?: string;
  label?: string;
  placeholder?: string;
  extra?: string;
  size?: SizeType;
  icon?: JSX.Element;
  callbackOnChange?: any;
  min?: number;
  minErrorMessage?: string;
  extraRule?: Rule;
  hasFeedback?: boolean;
};

const InputText: FunctionComponent<Props> = ({
  name,
  label,
  placeholder,
  extra,
  icon,
  isReadOnly = false,
  errorMessage = '',
  required = false,
  size = 'middle',
  callbackOnChange,
  min,
  minErrorMessage,
  extraRule,
  hasFeedback = true,
}: Props) => {
  const rules: Rule[] = [
    { type: 'string' },
    { required, message: errorMessage },
    { whitespace: true, message: errorMessage },
  ];

  if (extraRule) {
    rules.push(extraRule);
  }

  if (min && minErrorMessage) {
    rules.push({ min, message: minErrorMessage });
  }

  return (
    <Form.Item label={label} name={name} extra={extra} hasFeedback={hasFeedback} rules={rules}>
      <Input prefix={icon} disabled={isReadOnly} placeholder={placeholder} size={size} onChange={callbackOnChange} />
    </Form.Item>
  );
};

export default InputText;
