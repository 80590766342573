import { CloseOutlined, DragOutlined } from '@ant-design/icons';
import { Button, Row } from 'antd';
import { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import { find } from 'lodash';
import findIndex from 'lodash/findIndex';
import log from 'loglevel';
import React, { FunctionComponent, Ref, useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { MEDIA_STATUS, THEME } from '../../Constants';
import { saveCharterMedia } from '../../redux/action/ChartersAction';
import { RootState } from '../../redux/RootState';
import { APIManager } from '../../services/api/APIManager';
import { getCharterMedia } from '../../services/api/ChartersService';
import { APIGetCharterMediaResponse, MediaFile } from '../../services/api/types/ChartersServiceTypes';
import { MediaType } from '../../utils/types/MediaTypes';
import ImageFileCard from '../card/ImageFileCard';
import VideoOrMusicFileCardPlayer from '../card/VideoOrMusicFileCardPlayer';
import MediaSelectorModal from './MediaSelectorModal';

type Props = {
  value?: MediaFile[];
  onChange?: (newValue: MediaFile[] | undefined) => void;
  fieldLabel: string;
  mediaType?: MediaType;
  size?: 'normal' | 'small';
  disabled?: boolean;
  defaultMediaType?: MediaType;
  enableWebcamRecording?: boolean;
};

type StyleProps = {
  isMobileOrTablet: boolean;
};

const useStyles = createUseStyles({
  selectMediaButton: {
    padding: 0,
  },
  smallMediaSelectorButton: {
    fontSize: '11px',
  },
  selectedMediaMainContainer: {
    overflowX: 'auto !important',
    width: '100%',
  },
  selectedMediaWrapperContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto!important',
    paddingBottom: '10px !important',
    marginBottom: '10px !important',

    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      height: '9px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '6px',
      backgroundColor: 'rgba(0, 0, 0, .5)',
      boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
    },
  },
  selectedMediaWrapper: ({ isMobileOrTablet }: StyleProps) => ({
    display: isMobileOrTablet ? 'unset' : 'inline-flex',
    flexGrow: isMobileOrTablet ? 'unset' : 1,
  }),
  selectedMediaContainer: ({ isMobileOrTablet }: StyleProps) => ({
    display: 'flex',
    flexDirection: isMobileOrTablet ? 'column' : 'row',
  }),
  selectedMedia: ({ isMobileOrTablet }: StyleProps) => ({
    flex: 1,
    padding: 15,
    position: 'relative',
    width: isMobileOrTablet ? '100%' : 200,
  }),
  selectedMediaIndexContainer: {
    position: 'absolute',
    top: 20,
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1,
  },
  selectedMediaIndex: {
    color: 'white',
    width: 26,
    height: 26,
    display: 'flex',
    minWidth: 26,
    background: THEME.DEFAULT.MAIN_TEXT_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    lineHeight: '26px',
    padding: 8,
    fontSize: 11,
  },
  mediaDragButton: {
    position: 'absolute',
    top: 20,
    left: 20,
    zIndex: 1,
  },
  mediaRemoveButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    zIndex: 1,
  },
});

const MultipleMediaSelector: FunctionComponent<Props> = React.forwardRef(
  (
    {
      value,
      onChange,
      fieldLabel,
      mediaType,
      size = 'normal',
      disabled = false,
      defaultMediaType,
      enableWebcamRecording = false,
    }: Props,
    ref: Ref<any>
  ) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isMediaLoading, setIsMediaLoading] = useState(false);
    const [mediaPlaying, setMediaPlaying] = useState<MediaFile>();
    const [selectedMedia, setSelectedMedia] = useState<MediaFile[]>(value ?? []);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());

    const charter = useSelector((state: RootState) => state.charters.current);
    const mediaList = useSelector((state: RootState) => state.charters.currentCharterMedia);
    const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);
    const charterId = charter?.id;

    const classes = useStyles({ isMobileOrTablet });

    useEffect(() => {
      const cancelTokenSource = cancelTokenSourceRef.current;

      return (): void => {
        cancelTokenSource.cancel('Cancelled fetching charter media due to component unmount.');
      };
    }, []);

    // Initialize the selected media for the MediaSelectorModal with the field value
    useEffect(() => {
      if (value) {
        setSelectedMedia(value);
      }
    }, [isModalVisible]);

    // Fetch the media list
    useEffect(() => {
      if (!(charterId && isModalVisible)) {
        return;
      }
      setIsMediaLoading(true);

      const cancelTokenSource = cancelTokenSourceRef.current;
      getCharterMedia(charterId, true, cancelTokenSource)
        .then((response: APIGetCharterMediaResponse) => {
          const { data } = response;
          const processedMedia = mediaType
            ? data.items.filter(
                (mediaItem) => mediaItem.status === MEDIA_STATUS.PROCESSED && mediaItem.mediaType === mediaType
              )
            : data.items.filter((mediaItem) => mediaItem.status === MEDIA_STATUS.PROCESSED);
          dispatch(saveCharterMedia(processedMedia));
        })
        .catch((e) => {
          log.debug('Error during charter media fetch', e);
        })
        .finally(() => {
          setIsMediaLoading(false);
        });
    }, [charterId, mediaType, isModalVisible, dispatch]);

    // Called to update the upper `form` object
    const triggerChange = (changedValue: MediaFile[] | undefined): void => {
      if (onChange) {
        onChange(changedValue);
      }
    };

    const onOpenModal = (): void => {
      setIsModalVisible(true);
    };

    const onCloseModal = (): void => {
      setIsModalVisible(false);
    };

    // Once a media is selected/unselected (toggled) from the MediaSelectorModal
    const onSelectMedia = (mediaFile: MediaFile): void => {
      const selectedMediaFiles = selectedMedia ? [...selectedMedia] : [];
      const mediaIndex = findIndex(selectedMediaFiles, (m) => m.id === mediaFile.id);
      if (mediaIndex >= 0) {
        selectedMediaFiles.splice(mediaIndex, 1);
      } else {
        selectedMediaFiles.push(mediaFile);
      }
      setSelectedMedia(selectedMediaFiles);
    };

    // Only one video can be played at once
    const handleMediaPlayingChange = (isPlaying: boolean, media: MediaFile): void => {
      if (isPlaying) {
        setMediaPlaying(media);
      } else {
        setMediaPlaying(undefined);
      }
    };

    // Called when media are being reordered
    const onDragEnd = (result: DropResult): void => {
      const { source, destination, draggableId } = result;

      if (destination?.index === source.index || !selectedMedia) {
        return;
      }

      const reorderedMedia = [...selectedMedia];
      const draggedMedia = find(reorderedMedia, (media) => media.id.toString() === draggableId);

      if (draggedMedia && destination) {
        reorderedMedia.splice(source.index, 1);
        reorderedMedia.splice(destination.index, 0, draggedMedia);
        setSelectedMedia(reorderedMedia);
        triggerChange(reorderedMedia);
      }
    };

    // Called once a selected media is unselected directly with the trash icon
    const onUnselectMedia = (media: MediaFile) => {
      if (!(value && selectedMedia)) {
        return;
      }
      const unselectedMediaIndex = findIndex(selectedMedia, (m) => m.id === media.id);
      if (unselectedMediaIndex < 0) {
        return;
      }

      const newSelectedMedia = [...selectedMedia];
      newSelectedMedia.splice(unselectedMediaIndex, 1);

      setSelectedMedia(newSelectedMedia);
      triggerChange(newSelectedMedia);
    };

    // Once the selection is being validated from the MediaSelectorModal
    const onSubmitMediaSelection = () => {
      triggerChange(selectedMedia);
      onCloseModal();
    };

    return (
      <div ref={ref}>
        <div>
          {/* If there are selected media, we display a button to edit the selection as well as the media themselves */}
          {value && value.length > 0 ? (
            <>
              <Button
                type="link"
                className={classNames(size === 'small' && classes.smallMediaSelectorButton, classes.selectMediaButton)}
                onClick={onOpenModal}
                disabled={disabled}
              >
                {t('charters.mediaLibrary.editSelectedMedia')}
              </Button>
              <Row gutter={16}>
                <div className={classes.selectedMediaMainContainer}>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="selected-media" direction={isMobileOrTablet ? 'vertical' : 'horizontal'}>
                      {(provided): JSX.Element => (
                        <div
                          className={classes.selectedMediaWrapperContainer}
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          <div className={classes.selectedMediaWrapper}>
                            <div className={classes.selectedMediaContainer}>
                              {value.map((media, index) => (
                                <Draggable key={media.id} index={index} draggableId={media.id.toString()}>
                                  {(draggableProvided): JSX.Element => (
                                    <span
                                      className={classes.selectedMedia}
                                      {...draggableProvided.draggableProps}
                                      ref={draggableProvided.innerRef}
                                    >
                                      {/* Draggable icon to reorder the selected media (only if more than 1 media) */}
                                      {value.length > 1 && (
                                        <Button
                                          {...draggableProvided.dragHandleProps}
                                          className={classes.mediaDragButton}
                                          shape="circle"
                                          size="small"
                                        >
                                          <DragOutlined />
                                        </Button>
                                      )}

                                      {/* Media index */}
                                      <div className={classes.selectedMediaIndexContainer}>
                                        <div className={classes.selectedMediaIndex}>{index + 1}</div>
                                      </div>

                                      {/* Media index */}
                                      <Button
                                        danger
                                        className={classes.mediaRemoveButton}
                                        onClick={() => onUnselectMedia(media)}
                                        shape="circle"
                                        size="small"
                                      >
                                        <CloseOutlined />
                                      </Button>

                                      {/* Media card */}
                                      {media.mediaType === MediaType.IMAGE ? (
                                        <ImageFileCard imageFile={media} />
                                      ) : (
                                        <VideoOrMusicFileCardPlayer
                                          videoOrMusicFile={media}
                                          videoOrMusicPlaying={mediaPlaying}
                                          callbackPlaying={(isPlaying: boolean): void =>
                                            handleMediaPlayingChange(isPlaying, media)
                                          }
                                        />
                                      )}
                                    </span>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          </div>
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </Row>
            </>
          ) : (
            <Button
              type="link"
              className={classNames(size === 'small' && classes.smallMediaSelectorButton, classes.selectMediaButton)}
              onClick={onOpenModal}
              disabled={disabled}
            >
              {t('charters.mediaLibrary.selectMultiple')}
            </Button>
          )}
        </div>

        <MediaSelectorModal
          onCancel={onCloseModal}
          isVisible={isModalVisible}
          isLoading={isMediaLoading}
          fieldLabel={fieldLabel}
          mediaList={mediaList}
          selectedMedia={selectedMedia}
          onSelectMedia={onSelectMedia}
          mediaTypes={mediaType && [mediaType]}
          defaultMediaType={defaultMediaType}
          multiple
          enableWebcamRecording={enableWebcamRecording}
          onSubmitMediaSelection={onSubmitMediaSelection}
        />
      </div>
    );
  }
);

export default MultipleMediaSelector;
