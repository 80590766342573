import React, { FunctionComponent } from 'react';
import ScenePlayer from '../../../../components/scene-player/ScenePlayer';
import { THEME_KEY } from '../../../../Constants';
import ScenarioUtils from '../../../../utils/ScenarioUtils';
import { AnimationFormats, PIP } from '../../../../utils/types/AnimationTypes';
import { ScenarioSceneTemplate } from '../../../../utils/types/ScenarioTypes';
import { AnimationTheme } from '../../../../utils/types/ThemeTypes';

type Props = {
  scene?: ScenarioSceneTemplate;
  format?: string;
  isPlaying?: boolean;
  controls?: boolean;
  initialTime?: number;
  seekTo?: number;
  volume?: number;
  hideBorders?: boolean;
  onIsPlayingChange?: (newIsPlaying: boolean) => void;
  onCurrentTimeChange?: (currentTime: number) => void;
  onEndReached?: () => void;
  onIsLoadingChange?: (isLoading: boolean) => void;
};

const ScenarioScenePreview: FunctionComponent<Props> = React.memo(
  ({
    scene,
    format,
    isPlaying = true,
    controls = true,
    initialTime = 0,
    seekTo = 0,
    volume = 0,
    hideBorders,
    onIsPlayingChange,
    onCurrentTimeChange,
    onEndReached,
    onIsLoadingChange,
  }: Props) => {
    const sceneAnimation = scene?.animationText;
    const animationTexts = ScenarioUtils.getAnimationTextsFromScene(scene);
    const demoVideo = scene?.demoVideoUri;
    const playerPip: PIP | undefined =
      scene && scene.userPip && scene.userPip.demoSource
        ? {
            source: scene.userPip.demoSource,
            duration: scene.userPip.duration,
            trimStartAt: 0,
            trimEndAt: 0,
            delay: 0,
            effect: scene.userPip.effect,
            audioVolume: 0,
          }
        : undefined;

    if (!(scene && (sceneAnimation || demoVideo))) {
      return null;
    }

    return (
      <ScenePlayer
        isPlaying={isPlaying}
        animationName={sceneAnimation?.name}
        animationTheme={THEME_KEY.BALI.code as AnimationTheme}
        animationTexts={animationTexts}
        format={format ?? AnimationFormats.FORMAT_16_9}
        videoMedia={demoVideo}
        animationPosition={sceneAnimation?.position}
        pip={playerPip}
        audioVolume={volume}
        controls={controls}
        hideBorders={hideBorders}
        onIsPlayingChange={onIsPlayingChange}
        seekTo={seekTo || initialTime}
        onCurrentTimeChange={onCurrentTimeChange}
        onEndReached={onEndReached}
        onIsLoadingChange={onIsLoadingChange}
      />
    );
  }
);

export default ScenarioScenePreview;
