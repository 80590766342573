import { EditFilled, InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Dropdown, Menu, message, Tooltip } from 'antd';
import classNames from 'classnames';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import KannelleRetryButton from '../../../../components/retry-button/KannelleRetryButton';
import { LOGGING_EVENT, THEME } from '../../../../Constants';
import { Role } from '../../../../core/rule/Roles';
import { Roles } from '../../../../core/rule/RolesTypes';
import { setCompanyStats } from '../../../../redux/action/CompaniesAction';
import { RootState } from '../../../../redux/RootState';
import { updateCharterUserRoleByCharterId } from '../../../../services/api/ChartersService';
import { APIModelCharter } from '../../../../services/api/types/ChartersServiceTypes';
import { APICompanyUsers } from '../../../../services/api/types/CompaniesServiceTypes';
import LogUtils from '../../../../utils/LogUtils';
import KannelleRoleTag from './KannelleRoleTag';

type Props = {
  concernedUser: APICompanyUsers;
  role: Role;
  charter: APIModelCharter;
  callbackUpdateRole: any;
  updating?: boolean;
  disabled?: boolean;
};

const useStyles = createUseStyles({
  roleTagContainer: {
    width: '100%',
    maxWidth: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  editableRoleTag: {
    cursor: 'pointer',
  },
  nonEditableRoleTag: {
    cursor: 'default',
  },
  editRoleIcon: {
    fontSize: '10px',
    marginLeft: '5px',
    cursor: 'pointer',
  },
  loadingIcon: {
    fontSize: '10px',
    marginLeft: '5px',
  },
  rolesChoiceMenuHeader: {
    '&:hover': {
      cursor: 'default',
    },
  },
  dropdownMenuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .ant-dropdown-menu-title-content': {
      display: 'flex',
    },

    '& .ant-tag': {
      width: '100%',
    },
  },
  roleTooltipIcon: {
    color: THEME.DEFAULT.MAIN_TEXT_COLOR,
    float: 'right',
    marginLeft: '10px',
  },
  actionsContainer: {
    '& :not(:last-child)': {
      marginRight: '5px',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const KannelleRoleTagDropdown: FunctionComponent<Props> = ({
  concernedUser,
  role,
  charter,
  callbackUpdateRole,
  updating = false,
  disabled = false,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const companyStats = useSelector((state: RootState) => state.companies.stats);
  const currentCompany = useSelector((state: RootState) => state.companies.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);
  const [isLoading, setIsLoading] = useState(updating);
  const [currentRole, setCurrentRole] = useState<Roles>(role.currentRole);
  const [selectedNewRole, setSelectedNewRole] = useState<Roles>();
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const charterId = charter.id;

  // todo when we open this view to user we need to change below
  // only for knl team for now
  const canUpdateToKnlTeam = true; // role.isAllowedTo(CHARTERS_USERS_RULE.UPDATE_USERS_ROLES_TO_KNL_TEAM);
  const canUpdateToOwner = true; // role.isAllowedTo(CHARTERS_USERS_RULE.UPDATE_USERS_ROLES_TO_OWNER);

  const updateUserRole = (newRole?: Roles): void => {
    const roleToSet = newRole || selectedNewRole;
    if (!roleToSet || currentRole === roleToSet || !companyStats) {
      return;
    }

    if (!companyStats.canChangeRole(charter.currentUser.role as Roles, charter.currentUser.isActive)) {
      message.error(t('global.maxNbLicensesReachedError', { count: companyStats?.users.max }));
      return;
    }

    const event = LogUtils.getUsersUpdateEvent(
      LOGGING_EVENT.UPDATE_USER,
      concernedUser.id,
      { role: roleToSet },
      charterId,
      currentCompany?.id
    );
    loggingManager.logEventObject(event);

    setIsLoading(true);
    setIsError(false);
    updateCharterUserRoleByCharterId(charterId, concernedUser.id, roleToSet)
      .then((response) => {
        if (response.data) {
          companyStats.userUpdatedFromRole(charter.currentUser.role as Roles, roleToSet, charter.currentUser.isActive);
          dispatch(setCompanyStats(companyStats));
          callbackUpdateRole(concernedUser, charterId, roleToSet);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        log.error(error);
        message.error(
          <>
            {t('users.updateRole.error', { count: 1 })}
            <br />
            {t('support.global.emojiFingerRight')}
            <a href={`mailto:${t('support.global.supportEmail')}`}>{t('support.global.supportEmail')}</a>
            {t('support.global.emojiFingerLeft')}
          </>
        );
        setIsLoading(false);
        setIsError(true);
      });
  };

  useEffect(() => {
    setIsLoading(concernedUser.updating?.role || false);
  }, [concernedUser.updating]);

  useEffect(() => {
    setCurrentRole(role.currentRole);
  }, [role]);

  useEffect(() => {
    setIsLoading(updating);
  }, [updating]);

  const renderTooltipByRole = (roleItem: Roles): JSX.Element => {
    const tooltipText = t(`roles.tooltip.${roleItem}`);
    return (
      <Tooltip placement="right" trigger={['click', 'hover']} title={tooltipText}>
        <InfoCircleOutlined className={classes.roleTooltipIcon} onClick={(e): void => e.stopPropagation()} />
      </Tooltip>
    );
  };

  const renderRolesChoiceMenu = (): JSX.Element => {
    const renderRoleChoiceItemByRole = (roleItem: Roles): JSX.Element => (
      <Menu.Item
        className={classes.dropdownMenuItem}
        onClick={(): void => {
          setSelectedNewRole(roleItem);
          updateUserRole(roleItem);
        }}
      >
        <KannelleRoleTag roleName={roleItem} />
        {renderTooltipByRole(roleItem)}
      </Menu.Item>
    );

    return (
      <Menu>
        <Menu.Item disabled className={classes.rolesChoiceMenuHeader}>
          {t('users.changeRole')}
        </Menu.Item>
        <Menu.Divider />
        {canUpdateToKnlTeam && renderRoleChoiceItemByRole(Roles.KnlTeam)}
        {canUpdateToOwner && renderRoleChoiceItemByRole(Roles.Owner)}
        {renderRoleChoiceItemByRole(Roles.Admin)}
        {renderRoleChoiceItemByRole(Roles.Creator)}
      </Menu>
    );
  };

  if (!concernedUser) {
    return null;
  }

  return (
    <Dropdown overlay={renderRolesChoiceMenu} disabled={disabled || isLoading} trigger={['click']}>
      <span
        className={classNames(
          classes.roleTagContainer,
          !disabled && !isLoading ? classes.editableRoleTag : classes.nonEditableRoleTag
        )}
      >
        <KannelleRoleTag roleName={currentRole} />

        <span className={classes.actionsContainer}>
          {!disabled && !isLoading && isError && (
            <KannelleRetryButton
              onClick={(e): void => {
                e.stopPropagation();
                updateUserRole();
              }}
            />
          )}
          {!disabled && !isLoading && <EditFilled className={classes.editRoleIcon} />}
          {!disabled && isLoading && <LoadingOutlined className={classes.loadingIcon} />}
        </span>
      </span>
    </Dropdown>
  );
};

export default KannelleRoleTagDropdown;
