import { Checkbox, Radio, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RadioChangeEvent } from 'antd/lib/radio';
import map from 'lodash/map';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { ANALYTICS, DEVICE_SIZES_QUERIES } from '../../Constants';
import { RootState } from '../../redux/RootState';
import {
  APICompanyAnalyticsEventType,
  APICompanyAnalyticsTimeRange,
} from '../../services/api/types/CompaniesServiceTypes';
import CompaniesUtils from '../../utils/CompaniesUtils';

const { Option } = Select;

type Props = {
  onEventTypeChange: (selectedType: APICompanyAnalyticsEventType) => void;
  eventType: APICompanyAnalyticsEventType;
  onTimeRangeChange: (e: RadioChangeEvent) => void;
  timeRange: APICompanyAnalyticsTimeRange;
  onExcludeKnlTeamChange: (e: CheckboxChangeEvent) => void;
  excludeKnlTeam: boolean;
  loading?: boolean;
};

const useStyles = createUseStyles({
  mainContainer: {
    display: 'flex',
    marginBottom: 20,
    width: '100%',
    background: '#FFFFFF',
    borderRadius: 10,
    padding: 20,
    [`@media screen and ${DEVICE_SIZES_QUERIES.INTERMEDIATE}`]: {
      display: 'block',
    },
  },
  label: {
    [`@media screen and ${DEVICE_SIZES_QUERIES.INTERMEDIATE}`]: {
      display: 'block',
    },
    '&::after': {
      content: '":"',
      position: 'relative',
      top: '-.5px',
      margin: '0 8px 0 2px',
    },
  },
  eventSelect: {
    width: 250,
  },
  timeRange: {
    marginLeft: 40,
    [`@media screen and ${DEVICE_SIZES_QUERIES.INTERMEDIATE}`]: {
      marginLeft: 0,
      marginTop: 20,
    },
  },
  timeRangeRadioGroup: {
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      display: 'flex',
      flexDirection: 'column',

      '& .ant-radio-button-wrapper': {
        width: 250,
        height: 'initial',
        minHeight: '32px',
        marginBottom: 8,
        borderLeftWidth: 'thin',
      },
    },
  },
  excludeKnlTeamCheckbox: {
    marginLeft: 40,
    display: 'flex',
    alignItems: 'center',
    [`@media screen and ${DEVICE_SIZES_QUERIES.INTERMEDIATE}`]: {
      justifyContent: 'flex-start',
      marginLeft: 0,
      marginTop: 20,
    },
  },
});

const CompanyAnalyticsSettings: FunctionComponent<Props> = ({
  onEventTypeChange,
  onTimeRangeChange,
  onExcludeKnlTeamChange,
  eventType,
  timeRange,
  excludeKnlTeam,
  loading = false,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const companies = useSelector((state: RootState) => state.companies.list);
  const isKnlTeam = companies ? CompaniesUtils.checkIsKnlProfile(companies) : false;

  return (
    <div className={classes.mainContainer}>
      <div>
        <span className={classes.label}>{t('analytics.statisticName')}</span>
        <Select value={eventType} className={classes.eventSelect} onChange={onEventTypeChange} disabled={loading}>
          {map(ANALYTICS.EVENT_TYPES, ({ key, code }) => (
            <Option value={code} key={code}>
              {t(key)}
            </Option>
          ))}
        </Select>
      </div>

      <div className={classes.timeRange}>
        <span className={classes.label}>{t('analytics.statisticTimeRange')}</span>
        <Radio.Group
          buttonStyle="solid"
          className={classes.timeRangeRadioGroup}
          value={timeRange}
          onChange={onTimeRangeChange}
          disabled={loading}
        >
          {map(ANALYTICS.TIME_RANGES, ({ key, code }) => (
            <Radio.Button value={code} key={code}>
              {t(key)}
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>

      {isKnlTeam && (
        <div className={classes.excludeKnlTeamCheckbox}>
          <Checkbox onChange={onExcludeKnlTeamChange} checked={excludeKnlTeam} disabled={loading}>
            {t('analytics.excludeKnlTeam')}
          </Checkbox>
        </div>
      )}
    </div>
  );
};

export default CompanyAnalyticsSettings;
