import { CloseCircleFilled, EditFilled } from '@ant-design/icons/lib';
import { Button, message, Table } from 'antd';
import { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import { find } from 'lodash';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import Preformatted from '../../../../components/preformatted/Preformatted';
import { LOGGING_EVENT } from '../../../../Constants';
import { RootState } from '../../../../redux/RootState';
import { APIManager } from '../../../../services/api/APIManager';
import { updateCharterPermissions } from '../../../../services/api/ChartersService';
import {
  APICharterPermission,
  APIUpdateCharterPermissionResponse,
  Asset,
} from '../../../../services/api/types/ChartersServiceTypes';
import StringUtils from '../../../../utils/StringUtils';
import {
  AssetPermissions,
  ChangedFieldPermissionByRole,
  PermissionRoles,
} from '../../../../utils/types/CharterPermissionTypes';
import PermissionExpandedRowAssetChoiceModal from './PermissionExpandedRowAssetChoiceModal';

type Props = {
  permission: APICharterPermission;
  roleColumnWidth: number;
  isPermissionBeingUpdated: boolean;
  setIsPermissionBeingUpdated: React.Dispatch<React.SetStateAction<boolean>>;
  callback: (permissionCode: string, changedFieldsByRole: ChangedFieldPermissionByRole[]) => void;
};

type StyleProps = {
  roleColumnWidth: number;
};

const useStyles = createUseStyles({
  expandedRowTable: {
    '& .ant-table table': {
      width: 'unset',
      float: 'right',
    },
    '& .ant-table-cell': {
      borderBottom: 'none',
    },
    '& .ant-table-content': {
      background: '#fafafa',
    },
    '& .ant-table': {
      margin: '0 !important',
    },
  },
  selectedFileNameContainer: {
    cursor: 'pointer',
  },
  selectedFileName: {
    fontSize: '10px',
    marginRight: '5px',
  },
  disabledSelectedFilename: {
    cursor: 'not-allowed',
    opacity: '0.4',
  },
  rowFormButtonContainer: {
    width: (props: StyleProps): string => `calc(2*${props.roleColumnWidth}px)`,
    float: 'right',
    padding: '0 16px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  resetAssetIcon: {
    marginLeft: '5px',
  },
  permissionValueLabel: {
    textAlign: 'justify',
    fontSize: '12px',
  },
});

const PermissionExpandedRowAssetChoice: FunctionComponent<Props> = ({
  permission,
  roleColumnWidth,
  isPermissionBeingUpdated,
  setIsPermissionBeingUpdated,
  callback,
}: Props) => {
  const classes = useStyles({ roleColumnWidth });
  const [ownerAsset, setOwnerAsset] = useState<Asset>();
  const [adminAsset, setAdminAsset] = useState<Asset>();
  const [creatorAsset, setCreatorAsset] = useState<Asset>();
  const [assetType, setAssetType] = useState<AssetPermissions>();
  const [allowedAssets, setAllowedAssets] = useState<Asset[]>();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [modalOpenedOnRole, setModalOpenedOnRole] = useState<PermissionRoles>();
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const company = useSelector((state: RootState) => state.companies.current);
  const currentCharter = useSelector((state: RootState) => state.charters.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);
  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());

  useEffect(() => {
    const cancelTokenSource = cancelTokenSourceRef.current;

    return (): void => {
      cancelTokenSource.cancel('Cancelled patching charter permissions values due to component unmount.');
    };
  }, []);

  const initializeSelectedAssets = (assets: Asset[], perm: APICharterPermission): void => {
    perm.roles.forEach((role) => {
      const selectedAsset = find(assets, (asset) => asset.id.toString() === role.hasAccess.value);
      switch (role.code) {
        case PermissionRoles.OWNER:
          setOwnerAsset(selectedAsset);
          break;
        case PermissionRoles.ADMIN:
          setAdminAsset(selectedAsset);
          break;
        case PermissionRoles.CREATOR:
          setCreatorAsset(selectedAsset);
          break;
        default:
          break;
      }
    });
  };

  useEffect(() => {
    if (!currentCharter) {
      return;
    }

    let currentCharterAssets;

    switch (permission.code) {
      case AssetPermissions.LOGO:
        currentCharterAssets = currentCharter.logos;
        break;
      case AssetPermissions.INTRO:
        currentCharterAssets = currentCharter.intros;
        break;
      case AssetPermissions.OUTRO:
        currentCharterAssets = currentCharter.outros;
        break;
      default:
        break;
    }

    if (
      currentCharterAssets &&
      currentCharterAssets.length > 0 &&
      Object.keys(AssetPermissions).includes(permission.code)
    ) {
      initializeSelectedAssets(currentCharterAssets, permission);
      setAssetType(permission.code as AssetPermissions);
      setAllowedAssets(currentCharterAssets.filter((asset) => permission.allowedValues?.includes(asset.id.toString())));
    }
  }, [currentCharter, permission]);

  const onOpenModal = (role: PermissionRoles): void => {
    setIsModalOpened(true);
    setModalOpenedOnRole(role);
  };

  const onCloseModal = (): void => {
    setIsModalOpened(false);
    setModalOpenedOnRole(undefined);
  };

  const onSelectAsset = (role: PermissionRoles, asset?: Asset): void => {
    switch (role) {
      case PermissionRoles.OWNER: {
        setOwnerAsset(asset);
        break;
      }
      case PermissionRoles.ADMIN:
        setAdminAsset(asset);
        break;
      case PermissionRoles.CREATOR:
        setCreatorAsset(asset);
        break;
      default:
        break;
    }

    onCloseModal();
  };

  const onResetAsset = (role: PermissionRoles): void => {
    onSelectAsset(role, undefined);
  };

  const renderSelectedAsset = (role: PermissionRoles): JSX.Element => {
    let selectedAsset: Asset | undefined;
    switch (role) {
      case PermissionRoles.OWNER: {
        selectedAsset = ownerAsset;
        break;
      }
      case PermissionRoles.ADMIN:
        selectedAsset = adminAsset;
        break;
      case PermissionRoles.CREATOR:
        selectedAsset = creatorAsset;
        break;
      default:
        break;
    }

    const roleObject = find(permission.roles, (permissionRole) => permissionRole.code === role);
    const isDisabled =
      (roleObject && roleObject.hasAccess ? roleObject.hasAccess.locked || roleObject.hasAccess.access : false) ||
      isPermissionBeingUpdated;

    return (
      <div onClick={isDisabled ? undefined : (): void => onOpenModal(role)}>
        {selectedAsset ? (
          <span
            className={classNames(isDisabled ? classes.disabledSelectedFilename : classes.selectedFileNameContainer)}
          >
            <Preformatted className={classes.selectedFileName}>
              {selectedAsset?.publicName ?? StringUtils.getFileNameFromUrl(selectedAsset.url)}
            </Preformatted>
            {!isDisabled && <EditFilled />}
            {!isDisabled && (
              <CloseCircleFilled
                className={classes.resetAssetIcon}
                onClick={(e): void => {
                  e.stopPropagation();
                  onResetAsset(role);
                }}
              />
            )}
          </span>
        ) : (
          <Button type="link" disabled={isDisabled}>
            {t('global.select')}
          </Button>
        )}
      </div>
    );
  };

  const handleUpdatePermissionValues = (): void => {
    if (!currentCharter || !permission) {
      return;
    }

    setIsPermissionBeingUpdated(true);
    setIsLoading(true);

    const promises: Promise<APIUpdateCharterPermissionResponse>[] = [];
    Object.keys(PermissionRoles).forEach((roleCode) => {
      const role = find(permission.roles, (r) => r.code === roleCode);
      let assetId: number | undefined;
      if (roleCode === PermissionRoles.ADMIN) {
        assetId = adminAsset?.id;
      } else {
        assetId = roleCode === PermissionRoles.OWNER ? ownerAsset?.id : creatorAsset?.id;
      }

      // If the permission is disabled for this role, we do not update its value
      if (role && role.hasAccess && (role.hasAccess.locked || role.hasAccess.access)) {
        return;
      }

      const data = {
        roleCode,
        permissionCode: permission.code,
        access: role ? role.hasAccess.access : true,
        value: assetId ? assetId.toString() : null,
      };

      promises.push(updateCharterPermissions(currentCharter.id, data, cancelTokenSourceRef.current));
    });

    Promise.all(promises)
      .then(() => {
        message.success(t('charters.permissions.permissionValuesUpdateSuccess'));

        loggingManager.logEvent(LOGGING_EVENT.UPDATE_CHARTER_PERMISSION_VALUES, {
          companyId: company?.id,
          charterId: currentCharter.id,
          permission: permission.code,
          ownerAsset: ownerAsset ? ownerAsset.id : 'null',
          adminAsset: adminAsset ? adminAsset.id : 'null',
          creatorAsset: creatorAsset ? creatorAsset.id : 'null',
        });

        callback(permission.code, [
          { roleCode: PermissionRoles.OWNER, changedField: ownerAsset?.id.toString() },
          { roleCode: PermissionRoles.ADMIN, changedField: adminAsset?.id.toString() },
          { roleCode: PermissionRoles.CREATOR, changedField: creatorAsset?.id.toString() },
        ]);
      })
      .catch((e) => {
        message.error(t('charters.permissions.permissionValuesUpdateError'));
        log.error(`Error while updating charter permission values`, e);
        setIsPermissionBeingUpdated(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const expandedRowColumns = [
    {
      key: 'permission_value_label',
      render: (): JSX.Element => (
        <div className={classes.permissionValueLabel}>{t('charters.permissions.permissionValuesLabel')}</div>
      ),
      width: 'auto',
    },
    {
      key: 'permission_role_owner',
      render: (): JSX.Element => renderSelectedAsset(PermissionRoles.OWNER),
      width: roleColumnWidth,
    },
    {
      key: 'permission_role_admin',
      render: (): JSX.Element => renderSelectedAsset(PermissionRoles.ADMIN),
      width: roleColumnWidth,
    },
    {
      key: 'permission_role_creator',
      render: (): JSX.Element => renderSelectedAsset(PermissionRoles.CREATOR),
      width: roleColumnWidth,
    },
  ];

  let selectedAssetForSelectedRole: Asset | undefined;
  if (modalOpenedOnRole === PermissionRoles.OWNER) {
    selectedAssetForSelectedRole = ownerAsset;
  } else {
    selectedAssetForSelectedRole = modalOpenedOnRole === PermissionRoles.ADMIN ? adminAsset : creatorAsset;
  }

  // The apply button is disabled if the permission is disabled or locked for every role
  const disabledApplyButton = Object.keys(PermissionRoles).every((role) => {
    const roleObject = find(permission.roles, (permissionRole) => permissionRole.code === role);
    return roleObject && roleObject.hasAccess ? roleObject.hasAccess.locked || roleObject.hasAccess.access : false;
  });

  return (
    <>
      <Table
        tableLayout="fixed"
        className={classes.expandedRowTable}
        columns={expandedRowColumns}
        dataSource={[permission]}
        pagination={false}
        showHeader={false}
        rowKey={(record): string => {
          return `charters_permission_row_expanded_${record.code}`;
        }}
      />

      <div className={classes.rowFormButtonContainer}>
        <Button
          type="primary"
          disabled={disabledApplyButton}
          onClick={handleUpdatePermissionValues}
          loading={isLoading}
        >
          {t('global.apply')}
        </Button>
      </div>

      {modalOpenedOnRole && isModalOpened && assetType && allowedAssets && (
        <PermissionExpandedRowAssetChoiceModal
          isVisible={isModalOpened}
          permission={permission}
          role={modalOpenedOnRole}
          assetType={assetType}
          allowedAssets={allowedAssets}
          selectedAsset={selectedAssetForSelectedRole}
          onSelectAsset={onSelectAsset}
          onCancel={onCloseModal}
        />
      )}
    </>
  );
};

export default PermissionExpandedRowAssetChoice;
