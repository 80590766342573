import { Col, Row, Table } from 'antd';
import { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import { each, find, findIndex, parseInt } from 'lodash';
import log from 'loglevel';
import React, { FunctionComponent, Key, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ErrorAlert from '../../../../components/alert/ErrorAlert';
import PageContentLoader from '../../../../components/loader/PageContentLoader';
import { CHARTER_USER, DEVICE_SIZES_QUERIES, LOGGING_EVENT, THEME } from '../../../../Constants';
import { Roles } from '../../../../core/rule/RolesTypes';
import { CHARTERS_USERS_RULE } from '../../../../core/rule/Rules';
import useCanAccess from '../../../../hooks/useCanAccess';
import useCharterPermissions from '../../../../hooks/useCharterPermissions';
import useFetchCompanyStats from '../../../../hooks/useFetchCompanyStats';
import {
  addedUserToCharterDisplayed,
  addUserToCharter,
  removeUserFromCurrentCharter,
  setCharterUsers,
} from '../../../../redux/action/ChartersAction';
import { RootState } from '../../../../redux/RootState';
import { APIManager } from '../../../../services/api/APIManager';
import { getUsersByCharterId } from '../../../../services/api/ChartersService';
import { getCompanyUsers } from '../../../../services/api/CompaniesService';
import { APIModelCharter, UsersModelCharter } from '../../../../services/api/types/ChartersServiceTypes';
import { APICompanyUsers, APICompanyUsersResult } from '../../../../services/api/types/CompaniesServiceTypes';
import { CharterIdPathParam } from '../../../../services/navigation/NavigationConfigTypes';
import BundlesUtils from '../../../../utils/BundlesUtils';
import CompaniesUtils from '../../../../utils/CompaniesUtils';
import JoyRideUtils from '../../../../utils/JoyRideUtils';
import { PermissionList } from '../../../../utils/types/CharterPermissionTypes';
import UsersUtils from '../../../../utils/UsersUtils';
import { buildColumns } from './CharterUsersTableColumns';
import AddUserModal from './modals/AddUserModal';
import RemoveUserFromCharterModal from './modals/RemoveUserFromCharterModal';
import UpdateUserIsActiveModal from './modals/UpdateUserIsActiveModal';
import UpdateUserRoleModal from './modals/UpdateUserRoleModal';

type Props = {
  showAction?: boolean;
  runHelp: boolean;
};

const useStyles = createUseStyles({
  table: {
    '& .ant-table-content': {
      overflow: 'auto hidden !important',
    },
    '& .ant-pagination': {
      paddingBottom: '50px',
    },
    '& .ant-table-tbody > tr.ant-table-row-selected > td ': {
      backgroundColor: `${THEME.DEFAULT.FADE_COLOR} !important`,
    },
  },
  title: {
    marginTop: 16,
    padding: 0,
  },
  avatar: {
    marginRight: 4,
  },
  disabledUserRow: {
    opacity: '0.65',
    cursor: 'not-allowed',
    pointerEvents: 'none',
  },
  '@keyframes fadein': {
    '0:': { backgroundColor: '#E3F9E5' },
    '100.0%': { backgroundColor: 'inherit' },
  },
  rowAdded: {
    '& td, & .ant-table-cell-fix-left': {
      animationName: '$fadein',
      animationDuration: '4s',
      animationTimingFunction: 'ease-in-out',
      animationIterationCount: 1,
      animationFillMode: 'forwards',
      animationPlayState: 'running',
      backgroundColor: THEME.GLOBAL.MAIN_HIGHLIGHT_BACKGROUND_COLOR,
    },
  },
  userId: {
    display: 'block',
  },
  highlightedText: {
    '& mark': {
      backgroundColor: '#ffc069',
      padding: 0,
    },
  },
  addUserPosition: {
    [`@media screen and ${DEVICE_SIZES_QUERIES.LARGE}`]: {
      justifyContent: 'flex-end',
    },
  },
});

const CharterUsersTable: FunctionComponent<Props> = (props: Props) => {
  const classes = useStyles();
  const { charterId } = useParams<CharterIdPathParam>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showAction, runHelp } = props;
  const { loading: refreshHookLoading } = useFetchCompanyStats();
  const currentUser = useSelector((state: RootState) => state.user.user);
  const currentUserRole = useSelector((state: RootState) => state.user.role);
  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);
  const users = useSelector((state: RootState) => state.charters.currentCharterUsers);
  const companies = useSelector((state: RootState) => state.companies.list);
  const company = useSelector((state: RootState) => state.companies.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);
  const bundle = useSelector((state: RootState) => state.companies.bundle);

  const [loading, setLoading] = useState(true);
  const [companyUserLoading, setCompanyUserLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [filter, setFilter] = useState<Record<string, string>>();
  const [selectedRow, setSelectedRow] = useState([] as UsersModelCharter[]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [disabledUserIds, setDisabledUserIds] = useState<Array<string>>();
  const [activeCompanyUsers, setActiveCompanyUsers] = useState<APICompanyUsers[]>([]);

  const isCurrentUserPoc = company?.isUserPoc || false;
  const canReadUserIds = currentUserRole?.isAllowedTo(CHARTERS_USERS_RULE.READ_USERS_ID_KNL_TEAM) ?? false;
  const isCurrentUserKnlTeam = CompaniesUtils.checkIsKnlProfile(companies || []);

  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());

  useCharterPermissions();
  const permissionToAddUser = useCanAccess(PermissionList.ADD_USER);
  const permissionToEditUserInfo = useCanAccess(PermissionList.EDIT_USER_INFO);
  const permissionToDeleteUser = useCanAccess(PermissionList.DELETE_USER);

  useEffect(() => {
    const cancelTokenSource = cancelTokenSourceRef.current;

    return (): void => {
      cancelTokenSource.cancel('Cancelled fetching users by charter id due to component unmount.');
    };
  }, []);

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    setLoading(true);

    const cancelTokenSource = cancelTokenSourceRef.current;
    getUsersByCharterId(parseInt(charterId, 10), cancelTokenSource)
      .then((usersByCharterResponse) => {
        setIsError(false);
        const charterUsers = usersByCharterResponse.data.items;
        const visibleRoles = currentUserRole?.getVisibleRolesCodes();
        const visibleUsersForCurrentRole = charterUsers.filter((u) => visibleRoles?.includes(u.acl.role));
        const sortedVisibleUsers = UsersUtils.defaultSortUsersByActiveThenRoleAndPlaceCurrentUserFirst(
          visibleUsersForCurrentRole,
          currentUser
        );

        dispatch(setCharterUsers(sortedVisibleUsers));
      })
      .catch((e) => {
        log.debug(e.message);
        setIsError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [charterId, dispatch, currentUserRole, currentUser]);

  useEffect(() => {
    if (!company) {
      return;
    }
    setLoading(true);

    const cancelTokenSource = cancelTokenSourceRef.current;
    getCompanyUsers(company.id, cancelTokenSource)
      .then((usersByCharterResponse: APICompanyUsersResult) => {
        if (!usersByCharterResponse.data || usersByCharterResponse.data.items.length === 0) {
          setActiveCompanyUsers([]);
          return;
        }

        const filteredUsers = usersByCharterResponse.data.items.filter((user) => {
          const isOneCharterActive = find(user.charters, (charter: APIModelCharter) => {
            return charter.currentUser.isActive && charter.currentUser.role !== Roles.KnlTeam;
          });

          return user.charters.length > 0 && isOneCharterActive !== undefined;
        });

        setActiveCompanyUsers([...filteredUsers]);
      })
      .catch((e: any) => {
        log.error(e.message);
        setIsError(true);
      })
      .finally(() => {
        setCompanyUserLoading(false);
      });
  }, [charterId, dispatch, currentUserRole, currentUser]);

  useEffect(() => {
    if (users && users.length && currentUserRole) {
      const disabledUsersIdsForCurrentUserRole = UsersUtils.getDisabledUsersIdsForRole(users, currentUserRole);
      setDisabledUserIds(disabledUsersIdsForCurrentUserRole);
    }
  }, [currentUserRole, users]);

  useEffect(() => {
    const temporaryUser: UsersModelCharter = JoyRideUtils.generateSampleUser();
    if (runHelp && users && users.length === 1) {
      dispatch(addUserToCharter(temporaryUser));
    } else if (!runHelp && users) {
      const index = findIndex(users, { id: temporaryUser.id });
      if (index > -1) {
        dispatch(removeUserFromCurrentCharter(temporaryUser.id));
      }
    }
  }, [runHelp, users, dispatch]);

  useEffect(() => {
    if (!(selectedRow && selectedRow.length > 0)) {
      return;
    }

    const selectedUsers: UsersModelCharter[] = [];
    selectedRow.forEach((selectedUser) => {
      const user = find(users, (u) => u.id === selectedUser.id);
      if (user) {
        selectedUsers.push(user);
      }
    });
    setSelectedRow(selectedUsers);
  }, [users]);

  const handleSearch = (confirm: any): void => {
    setTableLoading(true);
    setTimeout(() => confirm(), 100);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: UsersModelCharter[]): void => {
      setSelectedRow(selectedRows);
    },
    getCheckboxProps: (record: UsersModelCharter): { disabled: boolean; name: string; key: string } => {
      const isDisabled =
        (record.id === currentUser?.id && !isCurrentUserPoc) ||
        (disabledUserIds && disabledUserIds.includes(record.id));
      return {
        disabled: isDisabled ?? false,
        name: record.name,
        key: record.id,
      };
    },
  };

  if (isError) {
    return <ErrorAlert message={t('ajaxError.usersFetch')} />;
  }

  if (loading || refreshHookLoading || companyUserLoading) {
    return <PageContentLoader />;
  }

  if (!users || !currentUser || !currentUserRole) {
    return null;
  }

  const columns = buildColumns({
    users: users || [],
    currentUser,
    currentUserRole,
    filter,
    setFilter,
    isMobileOrTablet,
    canReadUserIds,
    classes,
    t,
    filteredInfo,
    disabledUserIds,
    isCurrentUserPoc,
    handleSearch,
    activeCompanyUsers,
    allowedToUpdateUser:
      permissionToEditUserInfo.hasUserAccessToCharter(parseInt(charterId, 10)) &&
      !permissionToEditUserInfo.isHidden &&
      !permissionToEditUserInfo.isDisabled,
  });

  const handleResetFilter = (): void => {
    setFilteredInfo({});
  };

  return (
    <>
      {showAction === true ? (
        <Row style={{ marginBottom: 16 }} justify="space-between">
          <Col sm={24} md={24} lg={16}>
            <Row gutter={[8, 8]}>
              <Col>
                {!permissionToDeleteUser.isHidden && (
                  <RemoveUserFromCharterModal
                    selectedRows={selectedRow}
                    isDisabled={
                      permissionToDeleteUser.isDisabled ||
                      !permissionToDeleteUser.hasUserAccessToCharter(parseInt(charterId, 10))
                    }
                    renderUnauthorizedMessage={permissionToDeleteUser.renderUnauthorizedMessage}
                  />
                )}
              </Col>
              {!permissionToEditUserInfo.isHidden && (
                <>
                  <Col>
                    <UpdateUserRoleModal
                      selectedRows={selectedRow}
                      isDisabled={
                        permissionToEditUserInfo.isDisabled ||
                        !permissionToEditUserInfo.hasUserAccessToCharter(parseInt(charterId, 10))
                      }
                      renderUnauthorizedMessage={permissionToEditUserInfo.renderUnauthorizedMessage}
                    />
                  </Col>
                  <Col>
                    <UpdateUserIsActiveModal
                      selectedRows={selectedRow}
                      isDisabled={
                        permissionToEditUserInfo.isDisabled ||
                        !permissionToEditUserInfo.hasUserAccessToCharter(parseInt(charterId, 10))
                      }
                      renderUnauthorizedMessage={permissionToEditUserInfo.renderUnauthorizedMessage}
                    />
                  </Col>
                </>
              )}
            </Row>
          </Col>
          <Col sm={24} md={24} lg={8}>
            <Row className={classNames(classes.addUserPosition, 'addUserButton')}>
              <Col>
                {!permissionToAddUser.isHidden && (
                  <AddUserModal
                    callbackResetFilter={handleResetFilter}
                    companyUsers={activeCompanyUsers}
                    isDisabled={
                      permissionToAddUser.isDisabled ||
                      !permissionToAddUser.hasUserAccessToCharter(parseInt(charterId, 10))
                    }
                    isBundleDisabled={!BundlesUtils.canAddUser(users, bundle, isCurrentUserKnlTeam)}
                    renderUnauthorizedMessage={permissionToAddUser.renderUnauthorizedMessage}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      ) : null}

      <Table
        className={!runHelp || isMobileOrTablet ? classes.table : ''}
        tableLayout="auto"
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns}
        dataSource={users}
        loading={tableLoading}
        showSorterTooltip={false}
        rowKey={(record): string => {
          return `charters_users_row_${record.id}`;
        }}
        onChange={(pagination, filters, sorter: any, extra): void => {
          if (extra.action === 'sort') {
            if (sorter.field) {
              loggingManager.logEvent(LOGGING_EVENT.SORT_USERS, {
                columnName: sorter.field,
                direction: sorter.order,
                companyId: company?.id,
                charterId,
              });
            }
          } else if (extra.action === 'filter') {
            const filterSyntheses: { [key: string]: any } = {};
            each(filters, (dataSource: any, key: string) => {
              if (dataSource) {
                filterSyntheses[key.replace('user_', '')] = dataSource;
              }
            });

            loggingManager.logEvent(LOGGING_EVENT.FILTER_USERS, {
              filter: filterSyntheses,
              companyId: company?.id,
              charterId,
            });
            setFilteredInfo(filters);
            setTableLoading(false);
          }
          dispatch(addedUserToCharterDisplayed(currentUser));
        }}
        rowClassName={(record): string => {
          if (record.highlightRow) {
            return classes.rowAdded;
          }
          if (
            (!company?.isUserPoc && record.id === currentUser?.id) ||
            (disabledUserIds && disabledUserIds.includes(record.id))
          ) {
            return classes.disabledUserRow;
          }
          return '';
        }}
        scroll={{ x: 'max-content' }}
        pagination={{
          defaultPageSize: CHARTER_USER.PAGINATION.PAGE_SIZE,
          showSizeChanger: users.length > CHARTER_USER.PAGINATION.PAGE_SIZE,
        }}
        size="small"
        locale={{ filterConfirm: t('global.ok'), filterReset: t('global.reset'), emptyText: t('global.nodata') }}
      />
    </>
  );
};

export default CharterUsersTable;
