import { ANIMATION_CONSTANTS } from '../../Constants';
// eslint-disable-next-line import/no-cycle
import { Asset, MediaFile } from '../../services/api/types/ChartersServiceTypes';

export type LottieAnimation = any;

export enum AnimationFormats {
  FORMAT_16_9 = '16:9',
  FORMAT_1_1 = '1:1',
  FORMAT_9_16 = '9:16',
}

export type AnimationConfig = {
  name: string; // AnimationOption (code)
  position?: AnimationPosition;
  delay?: number;
  duration?: number;
};

export type AnimationPosition = { code: string; x?: number; y?: number; key?: string };
export type AnimationPositionStyle = {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
};

export type Size = {
  height: number;
  width: number;
};

export enum AnimationTextValidationRuleCode {
  NOT_EMPTY = 'NOT_EMPTY',
  MAX_LENGTH = 'MAX_LENGTH',
  DIGITS_OR_PERCENTAGE_ONLY = 'DIGITS_OR_PERCENTAGE_ONLY',
  DIGITS_ONLY = 'DIGITS_ONLY',
  CONTAINS_EMOJI = 'CONTAINS_EMOJI',
}

export enum AnimationTextValidationRuleType {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

const defaultNotEmptyAnimationRule = {
  ruleCode: AnimationTextValidationRuleCode.NOT_EMPTY,
  message: 'animations.validationRulesMessages.notEmpty',
  ruleType: AnimationTextValidationRuleType.ERROR,
};
const defaultMaxLengthAnimationRule = {
  ruleCode: AnimationTextValidationRuleCode.MAX_LENGTH,
  ruleValue: ANIMATION_CONSTANTS.MAX_ANIMATION_TEXT_LENGTH,
  ruleType: AnimationTextValidationRuleType.ERROR,
};
const defaultDigitsOrPercentageOnlyAnimationRule = {
  ruleCode: AnimationTextValidationRuleCode.DIGITS_OR_PERCENTAGE_ONLY,
  ruleType: AnimationTextValidationRuleType.ERROR,
  message: 'animations.validationRulesMessages.onlyDigitsOrPercentage',
};
const defaultContainEmojiRule = {
  ruleCode: AnimationTextValidationRuleCode.CONTAINS_EMOJI,
  message: 'animations.validationRulesMessages.containsEmoji',
  ruleType: AnimationTextValidationRuleType.WARNING,
};

const defaultAnimationRules = {
  validationRules: [defaultNotEmptyAnimationRule, defaultMaxLengthAnimationRule, defaultContainEmojiRule],
};

export const Animations: AnimationsOptions = {
  NISSAN: {
    code: 'NISSAN',
    key: 'animations.list.nissan.publicName',
    animationTexts: [
      {
        labelKey: 'animations.list.nissan.animationTexts.top',
        ...defaultAnimationRules,
      },
      {
        labelKey: 'animations.list.nissan.animationTexts.bottom',
        ...defaultAnimationRules,
      },
    ],
  },
  MUSTANG: {
    code: 'MUSTANG',
    key: 'animations.list.mustang.publicName',
    animationTexts: [{ labelKey: 'animations.list.mustang.animationTexts.text', ...defaultAnimationRules }],
  },
  MAZDA: {
    code: 'MAZDA',
    key: 'animations.list.mazda.publicName',
    animationTexts: [{ labelKey: 'animations.list.mazda.animationTexts.text', ...defaultAnimationRules }],
  },
  PLANE: {
    code: 'PLANE',
    key: 'animations.list.plane.publicName',
    animationTexts: [
      { labelKey: 'animations.list.plane.animationTexts.titleTop', ...defaultAnimationRules },
      {
        labelKey: 'animations.list.plane.animationTexts.titleBottom',
        ...defaultAnimationRules,
      },
      {
        labelKey: 'animations.list.plane.animationTexts.subtitleTop',
        ...defaultAnimationRules,
      },
      {
        labelKey: 'animations.list.plane.animationTexts.subtitleBottom',
        ...defaultAnimationRules,
      },
    ],
  },
  FORD: {
    code: 'FORD',
    key: 'animations.list.ford.publicName',
    animationTexts: [
      { labelKey: 'animations.list.ford.animationTexts.firstPart', ...defaultAnimationRules },
      { labelKey: 'animations.list.ford.animationTexts.secondPart', ...defaultAnimationRules },
    ],
  },
  PEUGEOT: {
    code: 'PEUGEOT',
    key: 'animations.list.peugeot.publicName',
    animationTexts: [
      { labelKey: 'animations.list.peugeot.animationTexts.optionA', ...defaultAnimationRules },
      { labelKey: 'animations.list.peugeot.animationTexts.optionB', ...defaultAnimationRules },
    ],
  },
  SIMCA: {
    code: 'SIMCA',
    key: 'animations.list.simca.publicName',
    animationTexts: [{ labelKey: 'animations.list.simca.animationTexts.text', ...defaultAnimationRules }],
  },
  RAM: {
    code: 'RAM',
    key: 'animations.list.ram.publicName',
    animationTexts: [
      { labelKey: 'animations.list.ram.animationTexts.titleTop', ...defaultAnimationRules },
      { labelKey: 'animations.list.ram.animationTexts.titleBottom', ...defaultAnimationRules },
    ],
  },
  LINCOLN: {
    code: 'LINCOLN',
    key: 'animations.list.lincoln.publicName',
    animationTexts: [
      {
        labelKey: 'animations.list.lincoln.animationTexts.percentage',
        placeholderKey: 'animations.list.lincoln.animationTexts.percentagePlaceholder',
        validationRules: [
          ...defaultAnimationRules.validationRules,
          defaultDigitsOrPercentageOnlyAnimationRule,
          {
            ruleCode: AnimationTextValidationRuleCode.MAX_LENGTH,
            ruleValue: ANIMATION_CONSTANTS.MAX_DIGITS_ONLY_ANIMATION_TEXT_LENGTH,
            ruleType: AnimationTextValidationRuleType.ERROR,
          },
        ],
      },
      {
        labelKey: 'animations.list.lincoln.animationTexts.description',
        ...defaultAnimationRules,
      },
      { labelKey: 'animations.list.lincoln.animationTexts.caption', ...defaultAnimationRules },
    ],
  },
  ROUSH: {
    code: 'ROUSH',
    key: 'animations.list.roush.publicName',
    animationTexts: [
      { labelKey: 'animations.list.roush.animationTexts.textTop', ...defaultAnimationRules },
      { labelKey: 'animations.list.roush.animationTexts.textBottom', ...defaultAnimationRules },
    ],
  },
  DELOREAN: {
    code: 'DELOREAN',
    key: 'animations.list.delorean.publicName',
    animationTexts: [
      { labelKey: 'animations.list.delorean.animationTexts.title', ...defaultAnimationRules },
      {
        labelKey: 'animations.list.delorean.animationTexts.descriptionTop',
        ...defaultAnimationRules,
      },
      {
        labelKey: 'animations.list.delorean.animationTexts.percentageTop',
        placeholderKey: 'animations.list.delorean.animationTexts.percentageTopPlaceholder',
        validationRules: [
          defaultNotEmptyAnimationRule,
          defaultDigitsOrPercentageOnlyAnimationRule,
          {
            ruleCode: AnimationTextValidationRuleCode.MAX_LENGTH,
            ruleValue: ANIMATION_CONSTANTS.MAX_DIGITS_ONLY_ANIMATION_TEXT_LENGTH,
            ruleType: AnimationTextValidationRuleType.ERROR,
          },
        ],
      },
      {
        labelKey: 'animations.list.delorean.animationTexts.descriptionBottom',
        ...defaultAnimationRules,
      },
      {
        labelKey: 'animations.list.delorean.animationTexts.percentageBottom',
        placeholderKey: 'animations.list.delorean.animationTexts.percentageBottomPlaceholder',
        validationRules: [
          defaultNotEmptyAnimationRule,
          defaultDigitsOrPercentageOnlyAnimationRule,
          {
            ruleCode: AnimationTextValidationRuleCode.MAX_LENGTH,
            ruleValue: ANIMATION_CONSTANTS.MAX_DIGITS_ONLY_ANIMATION_TEXT_LENGTH,
            ruleType: AnimationTextValidationRuleType.ERROR,
          },
        ],
      },
      { labelKey: 'animations.list.delorean.animationTexts.caption', ...defaultAnimationRules },
    ],
  },
  DODGE: {
    code: 'DODGE',
    key: 'animations.list.dodge.publicName',
    animationTexts: [
      {
        labelKey: 'animations.list.dodge.animationTexts.percentage',
        placeholderKey: 'animations.list.dodge.animationTexts.percentagePlaceholder',
        validationRules: [
          defaultNotEmptyAnimationRule,
          defaultDigitsOrPercentageOnlyAnimationRule,
          {
            ruleCode: AnimationTextValidationRuleCode.MAX_LENGTH,
            ruleValue: ANIMATION_CONSTANTS.MAX_DIGITS_ONLY_ANIMATION_TEXT_LENGTH,
            ruleType: AnimationTextValidationRuleType.ERROR,
          },
        ],
      },
      {
        labelKey: 'animations.list.dodge.animationTexts.description',
        ...defaultAnimationRules,
      },
      { labelKey: 'animations.list.dodge.animationTexts.caption', ...defaultAnimationRules },
    ],
  },
  AUSTIN: {
    code: 'AUSTIN',
    key: 'animations.list.austin.publicName',
    animationTexts: [
      { labelKey: 'animations.list.austin.animationTexts.title', ...defaultAnimationRules },
      {
        labelKey: 'animations.list.austin.animationTexts.descriptionTop',
        ...defaultAnimationRules,
      },
      {
        labelKey: 'animations.list.austin.animationTexts.percentageTop',
        placeholderKey: 'animations.list.austin.animationTexts.percentageTopPlaceholder',
        validationRules: [
          defaultNotEmptyAnimationRule,
          defaultDigitsOrPercentageOnlyAnimationRule,
          {
            ruleCode: AnimationTextValidationRuleCode.MAX_LENGTH,
            ruleValue: ANIMATION_CONSTANTS.MAX_DIGITS_ONLY_ANIMATION_TEXT_LENGTH,
            ruleType: AnimationTextValidationRuleType.ERROR,
          },
        ],
      },
      {
        labelKey: 'animations.list.austin.animationTexts.descriptionBottom',
        ...defaultAnimationRules,
      },
      {
        labelKey: 'animations.list.austin.animationTexts.percentageBottom',
        placeholderKey: 'animations.list.austin.animationTexts.percentageBottomPlaceholder',
        validationRules: [
          defaultNotEmptyAnimationRule,
          defaultDigitsOrPercentageOnlyAnimationRule,
          {
            ruleCode: AnimationTextValidationRuleCode.MAX_LENGTH,
            ruleValue: ANIMATION_CONSTANTS.MAX_DIGITS_ONLY_ANIMATION_TEXT_LENGTH,
            ruleType: AnimationTextValidationRuleType.ERROR,
          },
        ],
      },
      { labelKey: 'animations.list.austin.animationTexts.caption', ...defaultAnimationRules },
    ],
  },
  AVENTI: {
    code: 'AVENTI',
    key: 'animations.list.aventi.publicName',
    animationTexts: [
      { labelKey: 'animations.list.aventi.animationTexts.textTop', ...defaultAnimationRules },
      {
        labelKey: 'animations.list.aventi.animationTexts.textBottom',
        ...defaultAnimationRules,
      },
    ],
  },
  MCLAREN: {
    code: 'MCLAREN',
    key: 'animations.list.mclaren.publicName',
    animationTexts: [
      {
        labelKey: 'animations.list.dodge.animationTexts.percentage',
        placeholderKey: 'animations.list.dodge.animationTexts.percentagePlaceholder',
        validationRules: [
          defaultNotEmptyAnimationRule,
          defaultDigitsOrPercentageOnlyAnimationRule,
          {
            ruleCode: AnimationTextValidationRuleCode.MAX_LENGTH,
            ruleValue: ANIMATION_CONSTANTS.MAX_DIGITS_ONLY_ANIMATION_TEXT_LENGTH,
            ruleType: AnimationTextValidationRuleType.ERROR,
          },
        ],
      },
      {
        labelKey: 'animations.list.dodge.animationTexts.description',
        ...defaultAnimationRules,
      },
      { labelKey: 'animations.list.dodge.animationTexts.caption', ...defaultAnimationRules },
    ],
  },
  TOYOTA: {
    code: 'TOYOTA',
    key: 'animations.list.toyota.publicName',
    animationTexts: [
      {
        labelKey: 'animations.list.toyota.animationTexts.percentage',
        placeholderKey: 'animations.list.toyota.animationTexts.percentagePlaceholder',
        validationRules: [
          defaultNotEmptyAnimationRule,
          defaultDigitsOrPercentageOnlyAnimationRule,
          {
            ruleCode: AnimationTextValidationRuleCode.MAX_LENGTH,
            ruleValue: ANIMATION_CONSTANTS.MAX_DIGITS_ONLY_ANIMATION_TEXT_LENGTH,
            ruleType: AnimationTextValidationRuleType.ERROR,
          },
        ],
      },
      {
        labelKey: 'animations.list.toyota.animationTexts.description',
        ...defaultAnimationRules,
      },
      { labelKey: 'animations.list.toyota.animationTexts.caption', ...defaultAnimationRules },
    ],
  },
  VECTOR: {
    code: 'VECTOR',
    key: 'animations.list.vector.publicName',
    animationTexts: [
      { labelKey: 'animations.list.vector.animationTexts.titleTop', ...defaultAnimationRules },
      {
        labelKey: 'animations.list.vector.animationTexts.titleBottom',
        ...defaultAnimationRules,
      },
    ],
  },
  BUTTERFLY: {
    code: 'BUTTERFLY',
    key: 'animations.list.butterfly.publicName',
    animationTexts: [
      {
        labelKey: 'animations.list.butterfly.animationTexts.number',
        placeholderKey: 'animations.list.butterfly.animationTexts.numberPlaceholder',
        validationRules: [
          defaultNotEmptyAnimationRule,
          {
            ruleCode: AnimationTextValidationRuleCode.DIGITS_ONLY,
            message: 'animations.validationRulesMessages.onlyDigits',
            ruleType: AnimationTextValidationRuleType.ERROR,
          },
          {
            ruleCode: AnimationTextValidationRuleCode.MAX_LENGTH,
            ruleValue: ANIMATION_CONSTANTS.MAX_DIGITS_ONLY_SHORT_ANIMATION_TEXT_LENGTH,
            ruleType: AnimationTextValidationRuleType.ERROR,
          },
        ],
      },
      {
        labelKey: 'animations.list.butterfly.animationTexts.text',
        validationRules: [
          defaultNotEmptyAnimationRule,
          {
            ruleCode: AnimationTextValidationRuleCode.MAX_LENGTH,
            ruleValue: ANIMATION_CONSTANTS.MAX_ANIMATION_SHORT_TEXT_LENGTH,
            ruleType: AnimationTextValidationRuleType.ERROR,
          },
        ],
      },
    ],
  },
  FOX: {
    code: 'FOX',
    key: 'animations.list.fox.publicName',
    animationTexts: [
      {
        labelKey: 'animations.list.fox.animationTexts.textTop',
        validationRules: [
          defaultNotEmptyAnimationRule,
          {
            ruleCode: AnimationTextValidationRuleCode.MAX_LENGTH,
            ruleValue: 12,
            ruleType: AnimationTextValidationRuleType.ERROR,
          },
        ],
      },
      {
        labelKey: 'animations.list.fox.animationTexts.textBottom',
        validationRules: [
          defaultNotEmptyAnimationRule,
          {
            ruleCode: AnimationTextValidationRuleCode.MAX_LENGTH,
            ruleValue: 22,
            ruleType: AnimationTextValidationRuleType.ERROR,
          },
        ],
      },
    ],
  },
  OWL: {
    code: 'OWL',
    key: 'animations.list.owl.publicName',
    animationTexts: [
      {
        labelKey: 'animations.list.owl.animationTexts.textTop',
        validationRules: [
          defaultNotEmptyAnimationRule,
          {
            ruleCode: AnimationTextValidationRuleCode.MAX_LENGTH,
            ruleValue: 24,
            ruleType: AnimationTextValidationRuleType.ERROR,
          },
        ],
      },
      {
        labelKey: 'animations.list.owl.animationTexts.textBottom',
        ...defaultAnimationRules,
      },
    ],
  },
  MINI: {
    code: 'MINI',
    key: 'animations.list.mini.publicName',
    animationTexts: [],
  },
};

export const LowerThirdAnimations: AnimationOption[] = [Animations.NISSAN, Animations.MAZDA, Animations.MUSTANG];

export const BogotaSlideAnimations: AnimationOption[] = [
  Animations.RAM,
  Animations.LINCOLN,
  Animations.ROUSH,
  Animations.DODGE,
  Animations.DELOREAN,
];

export const RecordableAnimations: AnimationOption[] = [
  ...LowerThirdAnimations,
  Animations.PLANE,
  Animations.AUSTIN,
  Animations.AVENTI,
  Animations.MCLAREN,
  Animations.TOYOTA,
  Animations.VECTOR,
  Animations.BUTTERFLY,
  Animations.FOX,
];
// Slide animations are all animations except recordable ones and MINI
export const SlideAnimations: AnimationOption[] = Object.values(Animations).filter(
  (animationConfig) => !RecordableAnimations.includes(animationConfig) && animationConfig.code !== Animations.MINI.code
);

export const AnimationPositionConfig: AnimationPositionConfigOptions = {
  BOTTOM_LEFT: {
    code: 'BOTTOMLEFT',
    key: 'animations.positions.options.bottomLeft',
  },
  BOTTOM_RIGHT: {
    code: 'BOTTOMRIGHT',
    key: 'animations.positions.options.bottomRight',
  },
  FULL_SCREEN: {
    code: 'FULLSCREEN',
    key: 'animations.positions.options.fullScreen',
  },
};

export const AnimationPositionPresets: AnimationPositionPresetOptions = {
  SMALL_TRANSLATION: {
    x: 50,
    y: 50,
    code: 'SMALL_TRANSLATION',
    key: 'animations.positions.presets.smallTranslation',
  },
  MEDIUM_TRANSLATION: {
    x: 50,
    y: 100,
    code: 'MEDIUM_TRANSLATION',
    key: 'animations.positions.presets.mediumTranslation',
  },
  LARGE_TRANSLATION: {
    x: 50,
    y: 200,
    code: 'LARGE_TRANSLATION',
    key: 'animations.positions.presets.largeTranslation',
  },
  FULL_SCREEN: {
    x: 0,
    y: 0,
    code: 'FULL_SCREEN',
    key: 'animations.positions.presets.fullScreen',
  },
};

export type AnimationTextDescriptor = {
  labelKey: string;
  placeholderKey?: string;
  validationRules?: AnimationTextValidationRule[];
};
export type AnimationTextValidationRule = {
  ruleCode: AnimationTextValidationRuleCode;
  message?: string;
  ruleValue?: string | number;
  ruleType: AnimationTextValidationRuleType;
};
export type AnimationsOptions = Record<string, AnimationOption>;
export type AnimationOption = {
  code: string;
  key: string;
  animationTexts: Array<AnimationTextDescriptor>;
};

export type AnimationPositionConfigOptions = Record<string, AnimationPositionConfig>;
export type AnimationPositionConfig = {
  code: string;
  key: string;
};

export type AnimationPositionPresetOptions = Record<string, AnimationPositionPreset>;
export type AnimationPositionPreset = {
  code: string;
  key: string;
  x: number;
  y: number;
};

export type PIP = {
  source: MediaFile;
  duration: number;
  delay: number;
  trimStartAt: number;
  trimEndAt: number;
  audioVolume: number;
  effect?: {
    name?: string;
    value?: number;
  };
  positionCode?: PipPositionCode;
  positionValue?: number;
  size?: number;
};

export const PipEffects: PipEffectOptions = {
  ZOOMIN: {
    code: 'ZOOMIN',
    key: 'animations.pip.effectOptions.zoomIn',
    allowedValues: {
      soft: { value: 1.2, key: 'animations.pip.effectOptions.zoomInSoft' },
      strong: { value: 1.5, key: 'animations.pip.effectOptions.zoomInStrong' },
    },
  },
  ZOOMOUT: {
    code: 'ZOOMOUT',
    key: 'animations.pip.effectOptions.zoomOut',
    allowedValues: {
      soft: { value: 1.2, key: 'animations.pip.effectOptions.zoomOutSoft' },
      strong: { value: 1.5, key: 'animations.pip.effectOptions.zoomOutStrong' },
    },
  },
  TRANSLATION_LEFT: {
    code: 'TRANSLATION_LEFT',
    key: 'animations.pip.effectOptions.translationLeft',
    allowedValues: {
      soft: { value: 0.1, key: 'animations.pip.effectOptions.translationLeftSoft' },
      strong: { value: 0.3, key: 'animations.pip.effectOptions.translationLeftStrong' },
    },
  },
  TRANSLATION_RIGHT: {
    code: 'TRANSLATION_RIGHT',
    key: 'animations.pip.effectOptions.translationRight',
    allowedValues: {
      soft: { value: 0.1, key: 'animations.pip.effectOptions.translationRightSoft' },
      strong: { value: 0.3, key: 'animations.pip.effectOptions.translationRightStrong' },
    },
  },
  NONE: {
    code: 'NONE',
    key: 'animations.pip.effectOptions.none',
  },
};

export type PipEffectOptions = Record<string, PipEffect>;
export type PipEffectOptionAllowedValue = {
  value: number;
  key: string;
};
export type PipEffect = {
  code: string;
  key: string;
  allowedValues?: Record<string, PipEffectOptionAllowedValue>;
};

export enum PipPositionCode {
  FULL_SCREEN = 'FULLSCREEN',
  TOP_LEFT = 'TOPLEFT',
  TOP_RIGHT = 'TOPRIGHT',
  BOTTOM_LEFT = 'BOTTOMLEFT',
  BOTTOM_RIGHT = 'BOTTOMRIGHT',
  CENTER = 'CENTER',
}

export const PipPositions = {
  FULL_SCREEN: {
    code: PipPositionCode.FULL_SCREEN,
    key: 'animations.pip.positionCodeOptions.fullScreen',
  },
  TOP_LEFT: {
    code: PipPositionCode.TOP_LEFT,
    key: 'animations.pip.positionCodeOptions.topLeft',
  },
  TOP_RIGHT: {
    code: PipPositionCode.TOP_RIGHT,
    key: 'animations.pip.positionCodeOptions.topRight',
  },
  BOTTOM_LEFT: {
    code: PipPositionCode.BOTTOM_LEFT,
    key: 'animations.pip.positionCodeOptions.bottomLeft',
  },
  BOTTOM_RIGHT: {
    code: PipPositionCode.BOTTOM_RIGHT,
    key: 'animations.pip.positionCodeOptions.bottomRight',
  },
  CENTER: {
    code: PipPositionCode.CENTER,
    key: 'animations.pip.positionCodeOptions.center',
  },
};

export enum MaskCodes {
  NONE = 'NONE',
  MINI = 'MINI',
}

// Logo
export type Logo = {
  logoAsset: Asset;
  positionCode?: LogoPosition;
  size?: LogoSize;
  displayMode?: LogoDisplayMode;
};

export enum LogoPosition {
  BOTTOM_LEFT = 'BOTTOMLEFT',
  BOTTOM_RIGHT = 'BOTTOMRIGHT',
  TOP_LEFT = 'TOPLEFT',
  TOP_RIGHT = 'TOPRIGHT',
}

export enum LogoSize {
  XS = 0.05,
  S = 0.1,
  M = 0.15,
  L = 0.2,
  XL = 0.25,
}

export enum LogoDisplayMode {
  RECORDABLE_ONLY = 'RECORDABLE_ONLY',
  ALL_SCENES = 'ALL_SCENES',
}
