/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import ReactPlayer from 'react-player';
import { Asset } from '../../../../../../../services/api/types/ChartersServiceTypes';
import { Music } from '../../../../../../../services/api/types/MusicsServiceTypes';
import AssetsUtils from '../../../../../../../utils/AssetsUtils';
import ProjectUtils from '../../../../../../../utils/ProjectUtils';
import { Project, ProjectMusicDuckingVolume } from '../../../../../../../utils/types/ProjectTypes';
import {
  ExtendedAsset,
  ExtendedProjectElement,
  ExtendedProjectScene,
  ProjectElementType,
} from '../hooks/useProjectFinalRenderData';

type Props = {
  project: Project;
  isPlaying: boolean;
  currentTime: number;
  projectDuration: number;
  isSeeking: boolean;
  visibleProjectElement?: ExtendedProjectElement;
  intro?: ExtendedAsset;
  outro?: ExtendedAsset;
};

const useStyles = createUseStyles({
  backgroundMusicPlayer: {
    zIndex: 2,
    position: 'absolute',
    top: 50,
    left: 0,
  },
});

const ProjectFinalRenderBackgroundMusicPlayer: FunctionComponent<Props> = ({
  project,
  isPlaying,
  currentTime,
  projectDuration,
  isSeeking,
  visibleProjectElement,
  intro,
  outro,
}: Props) => {
  const classes = useStyles();
  const initialMusicVolume = project.music?.volume ?? 0.5;

  const [musicDuration, setMusicDuration] = useState<number>();
  const [audioVolume, setAudioVolume] = useState(initialMusicVolume);

  const audioPlayerRef = useRef<ReactPlayer>(null);

  const musicFile = project.music?.file;
  const musicDucking = project.music?.duckingEnabled ? project.music.duckingVolume : undefined;
  const musicFadeout = project.music?.fadeOutEnabled ? project.music.fadeOutDuration : undefined;

  const isSlideSceneVisible =
    visibleProjectElement &&
    visibleProjectElement.elementType === ProjectElementType.SCENE &&
    ProjectUtils.isSlideScene(visibleProjectElement as ExtendedProjectScene);

  const isIntroOrOutroVisible = visibleProjectElement && visibleProjectElement.elementType !== ProjectElementType.SCENE;
  const hasNoAudibleContent =
    visibleProjectElement?.elementType === ProjectElementType.SCENE &&
    !(
      (visibleProjectElement as ExtendedProjectScene).backgroundVideo?.audioVolume ||
      (visibleProjectElement as ExtendedProjectScene).pip?.audioVolume
    );

  // Seek the audio track if the project video was seeked or ended
  useEffect(() => {
    // If the user is seeking the project video
    if (audioPlayerRef.current && isSeeking && !isIntroOrOutroVisible) {
      audioPlayerRef.current?.seekTo(computeNewMusicTime(currentTime), 'seconds');
    } else if (isPlaying && (currentTime === 0 || isIntroOrOutroVisible)) {
      // Else if the project video ended, we also reset the currentTime of the audio track
      audioPlayerRef.current?.seekTo(0, 'seconds');
      setAudioVolume(initialMusicVolume);
    }
  }, [currentTime, isSeeking, isIntroOrOutroVisible, audioPlayerRef, musicDuration]);

  // Music ducking simulation: based on a benchmark, we apply a ducking of the volume in a percentage ratio similar
  // to the actual ducking in dB
  useEffect(() => {
    if (!musicDucking || isSlideSceneVisible || hasNoAudibleContent) {
      setAudioVolume(initialMusicVolume);
      return;
    }

    let musicDuckingFactor = 1;
    switch (musicDucking) {
      case ProjectMusicDuckingVolume.HIGH:
        musicDuckingFactor = 0.4;
        break;
      case ProjectMusicDuckingVolume.MEDIUM:
        musicDuckingFactor = 0.6;
        break;
      case ProjectMusicDuckingVolume.LOW:
        musicDuckingFactor = 0.8;
        break;
      default:
        break;
    }

    setAudioVolume(initialMusicVolume * musicDuckingFactor);
  }, [isSlideSceneVisible, hasNoAudibleContent, musicDucking]);

  // Music fadeout: progressively decrease the project background music volume on the last seconds
  useEffect(() => {
    if (!musicFadeout) {
      return;
    }

    // If the remaining time before the end of the project is lower than the fadeOutDuration, we start the fadeout
    const outroDuration = outro?.duration ?? 0;
    const remainingTime = (projectDuration - outroDuration - currentTime) * 1000;
    const shouldStartFadeout = remainingTime <= musicFadeout;

    if (shouldStartFadeout) {
      const newVolume = Math.max((initialMusicVolume * remainingTime) / musicFadeout, 0);
      setAudioVolume(newVolume);
    }
  }, [musicFadeout, currentTime, projectDuration, outro, isPlaying]);

  // The background music is repeated as much as necessary to cover the whole video
  // i.e. if a project lasts for 6 minutes and the background music lasts for 2 minutes,
  // it will be repeated three times, that's why we use the `%` modulo operator here
  const computeNewMusicTime = (projectTime: number) => {
    if (isIntroOrOutroVisible) {
      return 0;
    }

    const introDuration = intro?.duration ?? 0;
    const projectTimeWithoutIntro = Math.max(projectTime - introDuration, 0);
    return musicDuration ? projectTimeWithoutIntro % musicDuration : projectTimeWithoutIntro;
  };

  // If the project music was changed, ensure to resynchronize it with the video
  useEffect(() => {
    if (audioPlayerRef.current) {
      audioPlayerRef.current?.seekTo(computeNewMusicTime(currentTime), 'seconds');
    }
  }, [musicFile]);

  if (!musicFile) {
    return null;
  }

  const isPublicMusic = AssetsUtils.isPublicMusic(musicFile);
  const musicUrl = isPublicMusic ? (musicFile as Music).fileUri : (musicFile as Asset).url;

  // Callback called when the player loaded the music file and computed its total duration
  const onDurationChange = (duration: number) => {
    setMusicDuration(duration);
  };

  return (
    <div className={classes.backgroundMusicPlayer}>
      <ReactPlayer
        url={musicUrl}
        ref={audioPlayerRef}
        playing={!isIntroOrOutroVisible && isPlaying}
        volume={audioVolume}
        loop
        height="inherit"
        width="inherit"
        onDuration={onDurationChange}
      />
    </div>
  );
};

export default ProjectFinalRenderBackgroundMusicPlayer;
