import React, { FunctionComponent } from 'react';
import { createUseStyles } from 'react-jss';
import ProjectUtils from '../../../../../../../utils/ProjectUtils';
import { AnimationFormats, MaskCodes } from '../../../../../../../utils/types/AnimationTypes';
import { PlayerSize, ProjectLogo, ProjectLogoDisplayMode } from '../../../../../../../utils/types/ProjectTypes';
import { ExtendedProjectElement, ExtendedProjectScene, ProjectElementType } from '../hooks/useProjectFinalRenderData';

type Props = {
  logo?: ProjectLogo;
  format: AnimationFormats;
  playerSize?: PlayerSize;
  mask?: string;
  visibleProjectElement?: ExtendedProjectElement;
};

type StyleProps = {
  playerSize: PlayerSize;
};

const useStyles = createUseStyles({
  logoContainer: {
    zIndex: 2,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  logoContainerInFormat: ({ playerSize }: StyleProps) => ({
    height: playerSize?.height ?? '100%',
    width: playerSize?.width ?? '100%',
    position: 'relative',
    margin: 'auto',
  }),
  logo: {
    position: 'absolute',
  },
});

const ProjectFinalRenderLogo: FunctionComponent<Props> = ({
  logo,
  format,
  playerSize,
  mask,
  visibleProjectElement,
}: Props) => {
  const classes = useStyles({ playerSize });

  const isIntroOrOutro =
    visibleProjectElement &&
    [ProjectElementType.INTRO, ProjectElementType.OUTRO].includes(visibleProjectElement.elementType);

  const isSlide =
    visibleProjectElement &&
    visibleProjectElement.elementType === ProjectElementType.SCENE &&
    ProjectUtils.isSlideScene(visibleProjectElement as ExtendedProjectScene);

  const hasMiniMask = mask ? mask === MaskCodes.MINI : false;

  // If no logo nor playerSize is defined, or is the visibleElement is the intro/outro, or if it is a Slide,
  // and we want to show the logo only on Recordable scenes, we hide it
  if (
    !(logo && playerSize) ||
    isIntroOrOutro ||
    (isSlide && logo.displayMode === ProjectLogoDisplayMode.RECORDABLE_ONLY)
  ) {
    return null;
  }

  // Compute the logo style
  const logoStyle = ProjectUtils.computeLogoStyle(logo, format, hasMiniMask, playerSize);

  return (
    <div className={classes.logoContainer}>
      <div className={classes.logoContainerInFormat}>
        <img src={logo.logoAsset.url} alt="Logo" style={logoStyle} className={classes.logo} />
      </div>
    </div>
  );
};

export default ProjectFinalRenderLogo;
