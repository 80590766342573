import { FieldTimeOutlined, InfoCircleFilled } from '@ant-design/icons/lib';
import { Card, Col, Row, Statistic, Tooltip } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { KANNELLE_PRODUCT, THEME } from '../../../Constants';
import { RootState } from '../../../redux/RootState';
import TimeUtils from '../../../utils/TimeUtils';
import useStyles from './StatisticsStyle';

type Props = {
  statistics: {
    currentMonth: number;
    total: number;
  };
};

const SubtitlesStatistics: FunctionComponent<Props> = ({ statistics }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobileOrTablet = useSelector((state: RootState) => {
    return state.app.isMobileOrTablet;
  });

  const totalConsumption = TimeUtils.formatSecondsIntoHumanTimeString(statistics.total, t);
  const currentMonthConsumption = TimeUtils.formatSecondsIntoHumanTimeString(statistics.currentMonth, t);
  const currentMonthConsumptionColor =
    statistics.currentMonth >= KANNELLE_PRODUCT.MAX_SUBTITLES_SECONDS_PER_MONTH
      ? THEME.HOME.STATISTICS.INVALID_COLOR
      : THEME.HOME.STATISTICS.VALID_COLOR;

  const dateFormat = t('timeFormats.dateDayOfYear');
  const firstDayOfMonth = moment().startOf('month').format(dateFormat);

  if (!statistics) {
    return null;
  }

  return (
    <Col xs={12} sm={12} md={12} lg={7}>
      <Row gutter={0} className={classNames(classes.fullHeight, classes.row)}>
        <Col className={classes.column} span={24}>
          <Card className={classNames(classes.fullHeight, classes.card)}>
            <Statistic
              title={
                <span>
                  {t('home.statistics.subtitles.totalConsumption')}
                  <Tooltip title={t('home.statistics.subtitles.totalConsumptionToolTip')} arrowPointAtCenter>
                    <InfoCircleFilled className={classes.tooltipIcon} />
                  </Tooltip>
                </span>
              }
              value={totalConsumption}
              precision={0}
              valueStyle={{ color: THEME.HOME.STATISTICS.DEFAULT_COLOR }}
              prefix={<FieldTimeOutlined />}
              className={isMobileOrTablet ? classes.smallStatisticsValue : ''}
            />
          </Card>
        </Col>
        <Col className={classes.column} span={24}>
          <Card className={classNames(classes.fullHeight, classes.card)}>
            <Statistic
              title={
                <span>
                  {t('home.statistics.subtitles.currentMonthConsumption', { date: firstDayOfMonth })}
                  <Tooltip title={t('home.statistics.subtitles.currentMonthConsumptionToolTip')} arrowPointAtCenter>
                    <InfoCircleFilled className={classes.tooltipIcon} />
                  </Tooltip>
                </span>
              }
              value={currentMonthConsumption}
              precision={0}
              valueStyle={{ color: currentMonthConsumptionColor }}
              prefix={<FieldTimeOutlined />}
              className={isMobileOrTablet ? classes.smallStatisticsValue : ''}
            />
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

export default SubtitlesStatistics;
