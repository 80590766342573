import { Col, InputNumber, Row, Slider } from 'antd';
import React, { FunctionComponent, Ref } from 'react';
import { createUseStyles } from 'react-jss';

type Props = {
  value?: number;
  onChange?: (newValue: number) => void;
  min?: number;
  max?: number;
  step?: number;
};

const useStyles = createUseStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    margin: '0 16px',
  },
  slider: {
    marginLeft: 12,
  },
});

const SliderWithInput: FunctionComponent<Props> = React.forwardRef(
  ({ value, onChange, min, max, step }: Props, ref: Ref<any>) => {
    const classes = useStyles();

    const onFieldChange = (newValue: number) => {
      onChange?.(newValue);
    };

    const parseInputValue = (newValue?: string) => {
      const parsedValue = newValue && newValue.replace('%', '');
      if (!parsedValue) {
        return 0;
      }

      return parseFloat(parsedValue) / 100;
    };

    const formatPercentageValue = (valueToFormat?: number) => {
      if (valueToFormat === undefined) {
        return '';
      }

      return `${Math.trunc(valueToFormat * 100)}%`;
    };

    return (
      <div ref={ref}>
        <Row className={classes.row}>
          <Col span={12}>
            <Slider
              min={min}
              max={max}
              step={step}
              onChange={onFieldChange}
              value={value}
              tipFormatter={formatPercentageValue}
              className={classes.slider}
            />
          </Col>
          <Col span={4}>
            <InputNumber
              min={min}
              max={max}
              step={step}
              value={value}
              className={classes.input}
              formatter={formatPercentageValue}
              parser={parseInputValue}
              onChange={onFieldChange}
            />
          </Col>
        </Row>
      </div>
    );
  }
);

export default SliderWithInput;
