import { CheckCircleFilled } from '@ant-design/icons/lib';
import { Button } from 'antd';
import classNames from 'classnames';
import Color from 'color';
import React, { FunctionComponent, useState } from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import OutsideClickHandler from 'react-outside-click-handler';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/RootState';

type Props = {
  initialColor: string;
  onValidate: (color: string) => void;
  onClosePicker: () => void;
};

const useStyles = createUseStyles({
  colorPickerContainer: {
    position: 'absolute',
    zIndex: '3',
    left: '100px',
    top: '-50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '225px',
    background: '#FFFFFF',
    borderRadius: '2px',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 4px 8px',

    '& .chrome-picker': {
      boxShadow: 'unset !important',
    },
  },

  mobileColorPickerContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '135px',

    '& .chrome-picker': {
      width: '135px !important',
    },
  },

  validateButton: {
    width: '80%',
    marginBottom: '10px',
  },
});

const ColorPicker: FunctionComponent<Props> = ({ initialColor, onValidate, onClosePicker }: Props) => {
  const [color, setColor] = useState(initialColor);
  const classes = useStyles({ color });
  const { t } = useTranslation();
  const isMobileOrTablet = useSelector((state: RootState) => {
    return state.app.isMobileOrTablet;
  });

  const handleColorChange = (colorResult: ColorResult): void => {
    setColor(colorResult.hex);
  };

  const handleValidate = (): void => {
    onValidate(color.toUpperCase());
    onClosePicker();
  };

  return (
    <OutsideClickHandler onOutsideClick={onClosePicker} display="contents">
      <div className={classNames(classes.colorPickerContainer, isMobileOrTablet && classes.mobileColorPickerContainer)}>
        <ChromePicker color={color} onChange={handleColorChange} disableAlpha />

        <Button
          icon={<CheckCircleFilled />}
          className={classes.validateButton}
          onClick={handleValidate}
          style={{ backgroundColor: color, color: Color(color).isLight() ? '#000000' : '#FFFFFF' }}
        >
          {t('global.validate')}
        </Button>
      </div>
    </OutsideClickHandler>
  );
};

export default ColorPicker;
