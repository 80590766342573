import Title from 'antd/lib/typography/Title';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import KannellePageHeader from '../../../components/page-header/KannellePageHeader';
import { LINK } from '../../../Constants';
import { RootState } from '../../../redux/RootState';
import CompaniesUtils from '../../../utils/CompaniesUtils';
import TranslationTable from './TranslationTable';

const TranslationManagement: FunctionComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const companies = useSelector((state: RootState) => {
    return state.companies.list;
  });
  const isUserKnlTeamInCharter = CompaniesUtils.checkIsKnlProfile(companies || []);

  if (!isUserKnlTeamInCharter) {
    history.push(LINK.UNAUTHORIZED.path);
  }
  return (
    <KannellePageHeader title={<Title level={3}>{t('admin.translation.title')}</Title>}>
      <TranslationTable />
    </KannellePageHeader>
  );
};

export default TranslationManagement;
