import i18n from 'i18next';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactJoyride, { CallBackProps, STATUS } from 'react-joyride';
import JoyRideUtils from '../utils/JoyRideUtils';
import { Project, ProjectScene } from '../utils/types/ProjectTypes';
import KannelleJoyRideTooltip from './KannelleJoyRideTooltip';

type Props = {
  runHelp: boolean;
  callbackRunDone: () => void;
  project: Project | undefined;
  selectedScene: ProjectScene | undefined;
  currentStep: number;
  onStepClicked: (e: number) => void;
  onSelectScene: (p: ProjectScene) => void;
  onEditProject: (p: Project) => void;
};

const JoyRideHelpCharterProjectEditor: FunctionComponent<Props> = ({
  runHelp,
  callbackRunDone,
  onStepClicked,
  onSelectScene,
  project,
  selectedScene,
  currentStep,
  onEditProject,
}: Props) => {
  const { t } = useTranslation();

  const [run, setRun] = useState(false);
  const [initialProject, setInitialProject] = useState<Project | undefined>(project);
  const [initialStep, setInitialStep] = useState<number>(currentStep);
  const [initialSelectedScene, setInitialSelectedScene] = useState<ProjectScene | undefined>(selectedScene);

  const projectDemo = useMemo(() => {
    if (!project) {
      return undefined;
    }

    return JoyRideUtils.updateProjectForDemo(project);
  }, [project, i18n.language]);

  useEffect(() => {
    setRun(runHelp);
    if (project && selectedScene) {
      setInitialProject(project);
      setInitialStep(currentStep);
      setInitialSelectedScene(selectedScene);
    }
  }, [runHelp]);

  const disableOverflowX = () => {
    const videoTimeLineElement = document.querySelector('.joyRideVideoTimeLineContainer') as HTMLHtmlElement;
    if (videoTimeLineElement) {
      videoTimeLineElement.style.overflowX = 'hidden';
    }
    const scenesCarouselElement = document.querySelector('.scenesCarousel') as HTMLHtmlElement;
    if (scenesCarouselElement) {
      scenesCarouselElement.style.overflow = 'hidden';
    }
  };

  const enableOverflowX = () => {
    const videoTimeLineElement = document.querySelector('.joyRideVideoTimeLineContainer') as HTMLHtmlElement;
    if (videoTimeLineElement) {
      videoTimeLineElement.style.overflowX = '';
    }
    const scenesCarouselElement = document.querySelector('.scenesCarousel') as HTMLHtmlElement;
    if (scenesCarouselElement) {
      scenesCarouselElement.style.overflow = '';
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const scrollScenesCarouselToRight = () => {
    const addSceneButtonElement = document.querySelector('.addSceneButton') as HTMLHtmlElement;
    if (addSceneButtonElement) {
      addSceneButtonElement.scrollIntoView(false);
    }
  };

  useEffect(() => {
    if (!runHelp) {
      enableOverflowX();
    }
  }, [runHelp]);

  const handleJoyrideCallback = (data: CallBackProps): void => {
    if (!(projectDemo && projectDemo.scenes && selectedScene)) {
      return;
    }

    const { status, lifecycle, index } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    const projectScenesLength = projectDemo.scenes.length;
    const recordableScene = projectDemo.scenes[projectScenesLength - 3];
    const recordableSceneIndex = recordableScene?.index;
    const slideScene = projectDemo.scenes[projectScenesLength - 2];
    const slideSceneIndex = slideScene?.index;
    const inactiveSlideScene = projectDemo.scenes[projectScenesLength - 1];
    const inactiveSlideSceneIndex = inactiveSlideScene?.index;

    // Prevent bug on spotlight
    if (status === STATUS.RUNNING) {
      disableOverflowX();
      if (index === 0 || data.step.title === t(`userHelp.charterProjectEditor.stepEditor.nextStep.title`)) {
        scrollScenesCarouselToRight();
      }
    }

    // Specific actions according to the index
    if (status === STATUS.RUNNING && index === 0 && project === initialProject) {
      onEditProject(projectDemo);

      // Show editor step 1 and the recordable demo scene of the project
      if (currentStep !== 0) {
        onStepClicked(0);
      }
      if (recordableSceneIndex && selectedScene.index !== recordableSceneIndex) {
        onSelectScene(recordableScene);
      }
    }

    // Show the slide demo scene of the project (still in step 0)
    if (data.step.title === t(`userHelp.charterProjectEditor.stepEditor.slideScene.title`) && currentStep === 0) {
      onSelectScene(slideScene);
    }

    // Show the inactive slide demo scene of the project (still in step 0)
    if (data.step.title === t(`userHelp.charterProjectEditor.stepEditor.inactiveScene.title`) && currentStep === 0) {
      onSelectScene(inactiveSlideScene);
    }

    // Show the first scene of the project (still in step 0) if the user reverses
    if (
      data.step.title === t(`userHelp.charterProjectEditor.stepEditor.sceneRecordableOptions.title`) &&
      selectedScene.index !== recordableSceneIndex
    ) {
      onSelectScene(recordableScene);
    }

    // Show editor step 1 (finalization options)
    if (
      data.step.title === t(`userHelp.charterProjectEditor.stepFinalization.stepGeneral.title`) &&
      currentStep === 0
    ) {
      onStepClicked(1);
    }

    // Show the inactive slide demo scene of the project (still in step 0) if the user reverses
    if (data.step.content === t(`userHelp.charterProjectEditor.stepEditor.editAgain.text`) && currentStep === 1) {
      onStepClicked(0);
      if (selectedScene.index !== inactiveSlideSceneIndex) {
        onSelectScene(inactiveSlideScene);
      }
    }

    // Show the slide demo scene of the project (still in step 0) if the user reverses
    if (data.step.title === t(`userHelp.charterProjectEditor.stepEditor.sceneSlideOptions.title`)) {
      if (selectedScene.index !== slideSceneIndex) {
        onSelectScene(slideScene);
      }
    }

    // Scroll to top when talking about recordable scene and slide scene
    if (
      data.step.title === t(`userHelp.charterProjectEditor.stepEditor.recordableScene.title`) ||
      data.step.title === t(`userHelp.charterProjectEditor.stepEditor.slideScene.title`)
    ) {
      scrollToTop();
    }

    // What to do at the end
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      if (initialProject) {
        onEditProject(initialProject);
      }

      if (currentStep === 1 && initialStep === 0) {
        onStepClicked(0);
      }

      if (currentStep === 0 && initialStep === 1) {
        onStepClicked(1);
      }

      if (initialSelectedScene) {
        onSelectScene(initialSelectedScene);
      }
    }

    if (finishedStatuses.includes(status) && lifecycle === 'complete') {
      setRun(false);
      callbackRunDone();
    }
  };

  const steps = JoyRideUtils.getCharterProjectEditorSteps(t);

  return (
    <>
      <ReactJoyride
        continuous
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        scrollOffset={120}
        tooltipComponent={KannelleJoyRideTooltip}
        steps={steps}
        callback={handleJoyrideCallback}
        locale={{
          back: t('onBoarding.steps.back'),
          close: t('onBoarding.steps.close'),
          last: t('onBoarding.steps.last'),
          next: t('onBoarding.steps.next'),
          open: t('onBoarding.steps.open'),
          skip: t('onBoarding.steps.skip'),
        }}
      />
    </>
  );
};

export default JoyRideHelpCharterProjectEditor;
