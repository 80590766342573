import { FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import { Badge, Button, Divider, Empty, Popover, Select, Tag, Tooltip } from 'antd';
import CheckableTag from 'antd/es/tag/CheckableTag';
import classNames from 'classnames';
import Color from 'color';
import Fuse from 'fuse.js';
import { map } from 'lodash';
import uniqBy from 'lodash/uniqBy';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSortAlphaDownAlt, FaSortAlphaUpAlt } from 'react-icons/all';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/RootState';
import { APIModelUser } from '../../../../services/api/types/UsersServiceTypes';
import ProjectUtils from '../../../../utils/ProjectUtils';
import StringUtils from '../../../../utils/StringUtils';
import { Project, ProjectStatus } from '../../../../utils/types/ProjectTypes';

type Props = {
  projects: Project[];
  runJoyrideHelp: boolean;
  onFilterChange: (
    titleFilter: string[],
    authorFilter: string[],
    statusFilter: string[],
    metadataFilter: string,
    sortFilter: string
  ) => void;
};

const useStyles = createUseStyles({
  select: {
    width: '100%',
  },
  sortFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },
  sortSelect: {
    width: '80%',
  },
  sortOrderContainer: {
    display: 'flex',
  },
  sortOrderTag: {
    marginLeft: 8,
    marginRight: 0,
    borderRadius: 30,
    height: 24,
    width: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    padding: '0 6px',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 18,
  },
  popover: {
    '& .ant-popover-inner-content': {
      padding: 0,
    },
  },
  buttonReset: {
    width: '100%',
    background: '#CBD2D9',
    color: '#323F4B',
    borderWidth: 0,
    borderRadius: 0,
    '&:hover, &:focus, &:visited, &:active': {
      background: Color('#CBD2D9').lighten(0.05).hex(),
      color: '#323F4B',
      borderWidth: '0',
    },
  },
  badge: {
    '& .ant-badge-count': {
      boxShadow: 'unset',
    },
  },
  popoverContainer: {
    minWidth: 320,
    maxWidth: 320,
    padding: '12px 16px 0',
    overflow: 'hidden',
  },
  filterItem: {
    marginBottom: 8,
  },
  divider: {
    margin: '18px -16px 12px',
    width: 'calc(100% + 2 * 16px)',
  },
  emptyImage: {
    color: '#aeb8c2',
    '& .ant-empty-image': {
      height: 50,
    },
  },
});

const ProjectsFilterButton: FunctionComponent<Props> = ({ projects, onFilterChange, runJoyrideHelp }: Props) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);
  const placement = isMobileOrTablet ? 'bottomRight' : 'right';
  const maxTagCount = 4;
  const maxTagTextLength = 12;

  const distinctProjectAuthors = useMemo(() => {
    const projectsWithDistinctAuthors = uniqBy(projects, 'author.name');
    return projectsWithDistinctAuthors.filter((p) => p.author !== undefined).map((p) => p!.author) as APIModelUser[];
  }, [projects]);

  const distinctProjectTitles = useMemo(() => {
    const projectsWithDistinctTitles = uniqBy(projects, 'title');
    return projectsWithDistinctTitles.map((p) => p.title);
  }, [projects]);

  const [projectsTitleOptions, setProjectsTitleOptions] = useState<string[]>();
  const [projectAuthorsOptions, setProjectAuthorsOptions] = useState<APIModelUser[]>();

  const [queryTitle, setQueryTitle] = useState<string>();
  const [queryAuthor, setQueryAuthor] = useState<string>();

  const [projectsTitleFilterValue, setProjectsTitleFilterValue] = useState<string[]>([]);
  const [projectsStatusFilterValue, setProjectsStatusFilterValue] = useState<string[]>([]);
  const [projectsAuthorFilterValue, setProjectsAuthorFilterValue] = useState<string[]>([]);
  const [projectsMetadataFilterValue, setProjectsMetadataFilterValue] = useState<string>('createdAt');
  const [projectsOrderFilterValue, setProjectsOrderFilterValue] = useState<string>('dsc');

  // Fuzzy search on the project title
  const fuseTitle = new Fuse(distinctProjectTitles || [], {
    threshold: 0.5,
  });

  // Fuzzy search on the project author
  const fuseAuthor = new Fuse(distinctProjectAuthors || [], {
    keys: ['email', 'name'],
    threshold: 0.5,
  });

  useEffect(() => {
    onFilterChange(
      projectsTitleFilterValue,
      projectsAuthorFilterValue,
      projectsStatusFilterValue,
      projectsMetadataFilterValue,
      projectsOrderFilterValue
    );
  }, [projects]);

  useEffect(() => {
    setProjectsTitleOptions(distinctProjectTitles);
    setProjectAuthorsOptions(distinctProjectAuthors || []);
  }, [distinctProjectAuthors, distinctProjectTitles]);

  // Fuzzy search on the project title
  useEffect(() => {
    if (!queryTitle) {
      setProjectsTitleOptions(distinctProjectTitles || []);
      return;
    }
    const result = fuseTitle.search(queryTitle);
    const projectsFuseFiltered = result.map((res) => res.item);
    setProjectsTitleOptions(projectsFuseFiltered);
  }, [queryTitle, distinctProjectTitles]);

  // Fuzzy search on the project author
  useEffect(() => {
    if (!queryAuthor) {
      setProjectAuthorsOptions(distinctProjectAuthors || []);
      return;
    }
    const result = fuseAuthor.search(queryAuthor);
    const projectsFuseFiltered = result.map((res) => res.item);
    setProjectAuthorsOptions(projectsFuseFiltered);
  }, [queryAuthor, distinctProjectAuthors]);

  // Search on project title
  const onSearchTitle = (value: string): void => {
    setQueryTitle(value);
  };

  // Search on project author
  const onSearchAuthor = (value: string): void => {
    setQueryAuthor(value);
  };

  // Filter project by title
  const handleTitleFilterChange = (value: string[]): void => {
    setProjectsTitleFilterValue(value);
    onFilterChange(
      value,
      projectsAuthorFilterValue,
      projectsStatusFilterValue,
      projectsMetadataFilterValue,
      projectsOrderFilterValue
    );
  };

  // Filter project by author
  const handleAuthorFilterChange = (value: string[]): void => {
    setProjectsAuthorFilterValue(value);
    onFilterChange(
      projectsTitleFilterValue,
      value,
      projectsStatusFilterValue,
      projectsMetadataFilterValue,
      projectsOrderFilterValue
    );
  };

  // Filter project by status
  const handleStatusFilterChange = (value: string[]): void => {
    setProjectsStatusFilterValue(value);
    onFilterChange(
      projectsTitleFilterValue,
      projectsAuthorFilterValue,
      value,
      projectsMetadataFilterValue,
      projectsOrderFilterValue
    );
  };

  // Sort project by metadata
  const handleSortMetadataChange = (value: string): void => {
    setProjectsMetadataFilterValue(value);
    onFilterChange(
      projectsTitleFilterValue,
      projectsAuthorFilterValue,
      projectsStatusFilterValue,
      value,
      projectsOrderFilterValue
    );
  };

  // Sort project by order
  const handleSortOrderChange = (value: string): void => {
    setProjectsOrderFilterValue(value);
    onFilterChange(
      projectsTitleFilterValue,
      projectsAuthorFilterValue,
      projectsStatusFilterValue,
      projectsMetadataFilterValue,
      value
    );
  };

  // Reset filter options
  const handleReset = (): void => {
    setProjectsTitleFilterValue([]);
    setProjectsAuthorFilterValue([]);
    setProjectsStatusFilterValue([]);
    setProjectsMetadataFilterValue('createdAt');
    setProjectsOrderFilterValue('dsc');
    onFilterChange([], [], [], 'createdAt', 'dsc');
  };

  // Number of active filters
  const totalActiveFilters =
    projectsTitleFilterValue.length + projectsStatusFilterValue.length + projectsAuthorFilterValue.length;

  useEffect(() => {
    if (runJoyrideHelp) {
      handleReset();
    }
  }, [runJoyrideHelp]);

  // Filter options
  const content = (
    <>
      <div className={classes.popoverContainer}>
        {/* Filter by title */}
        <li className={classes.filterItem}>
          <h4>{t('charters.projects.projectCard.filter.title')}</h4>
          <Select
            onChange={handleTitleFilterChange}
            className={classes.select}
            mode="multiple"
            showSearch
            showArrow
            allowClear
            maxTagCount={maxTagCount}
            maxTagTextLength={maxTagTextLength}
            filterOption={false}
            notFoundContent={<Empty className={classes.emptyImage} description={t('global.nodata')} />}
            onSearch={onSearchTitle}
            value={projectsTitleFilterValue}
            placeholder={t('charters.projects.projectCard.filter.titlePlaceholder')}
          >
            {projectsTitleOptions &&
              projectsTitleOptions.map((title: string) => {
                return (
                  <Select.Option key={title} value={title}>
                    {title}
                  </Select.Option>
                );
              })}
          </Select>
        </li>

        {/* Filter by author */}
        <li className={classes.filterItem}>
          <h4>{t('charters.projects.projectCard.filter.author')}</h4>
          <Select
            onChange={handleAuthorFilterChange}
            className={classes.select}
            mode="multiple"
            showSearch
            showArrow
            allowClear
            maxTagCount={maxTagCount}
            maxTagTextLength={maxTagTextLength}
            filterOption={false}
            notFoundContent={<Empty className={classes.emptyImage} description={t('global.nodata')} />}
            onSearch={onSearchAuthor}
            value={projectsAuthorFilterValue}
            placeholder={t('charters.projects.projectCard.filter.authorPlaceholder')}
          >
            {projectAuthorsOptions &&
              projectAuthorsOptions.map((author: APIModelUser) => {
                const authorValue = author.name ?? author.email;
                return (
                  <Select.Option key={author.id} value={authorValue}>
                    {authorValue}
                  </Select.Option>
                );
              })}
          </Select>
        </li>

        {/* Filter by status */}
        <li className={classes.filterItem}>
          <h4>{t('charters.projects.projectCard.filter.status')}</h4>
          <Select
            onChange={handleStatusFilterChange}
            className={classes.select}
            mode="multiple"
            showArrow
            allowClear
            filterOption={(inputValue, option) => {
              const status = ProjectUtils.getProjectStatusByCode(option?.value);
              return status ? StringUtils.normalize(t(status.key)).includes(StringUtils.normalize(inputValue)) : false;
            }}
            value={projectsStatusFilterValue}
            tagRender={(p) => <>{p.label}</>}
            notFoundContent={<Empty className={classes.emptyImage} description={t('global.nodata')} />}
            placeholder={t('charters.projects.projectCard.filter.statusPlaceholder')}
          >
            {map(ProjectStatus, (status) => (
              <Select.Option key={status.code} value={status.code}>
                <Tag icon={<status.icon />} color={status.color}>
                  {t(status.key)}
                </Tag>
              </Select.Option>
            ))}
          </Select>
        </li>

        <Divider className={classes.divider} dashed />

        {/* Sort by fields */}
        <li className={classes.filterItem}>
          <h4>{t('charters.projects.projectCard.filter.sort')}</h4>
          <div className={classes.sortFieldContainer}>
            <Select
              className={classes.sortSelect}
              value={projectsMetadataFilterValue}
              onChange={handleSortMetadataChange}
            >
              <Select.Option value="createdAt">{t('charters.projects.projectCard.createdAt')}</Select.Option>
              <Select.Option value="lastFinalizedAt">
                {t('charters.projects.projectCard.lastFinalizedAt')}
              </Select.Option>
              <Select.Option value="email">{t('charters.projects.projectCard.author.email')}</Select.Option>
            </Select>

            <div className={classes.sortOrderContainer}>
              <Tooltip title={t('charters.projects.projectCard.filter.asc')}>
                <CheckableTag
                  checked={projectsOrderFilterValue === 'asc'}
                  onChange={() => handleSortOrderChange('asc')}
                  className={classes.sortOrderTag}
                >
                  <FaSortAlphaUpAlt />
                </CheckableTag>
              </Tooltip>
              <Tooltip title={t('charters.projects.projectCard.filter.dsc')}>
                <CheckableTag
                  checked={projectsOrderFilterValue === 'dsc'}
                  onChange={() => handleSortOrderChange('dsc')}
                  className={classes.sortOrderTag}
                >
                  <FaSortAlphaDownAlt />
                </CheckableTag>
              </Tooltip>
            </div>
          </div>
        </li>
      </div>
      <Button className={classes.buttonReset} onClick={handleReset}>
        {t('global.reset')} <ReloadOutlined />
      </Button>
    </>
  );

  return (
    <div>
      <Popover overlayClassName={classes.popover} content={content} trigger="click" placement={placement}>
        <Badge className={classNames(classes.badge)} count={totalActiveFilters}>
          <Button className={classes.button}>
            <span>{t('charters.projects.projectCard.filter.button')}</span>
            <FilterOutlined />
          </Button>
        </Badge>
      </Popover>
    </div>
  );
};

export default ProjectsFilterButton;
