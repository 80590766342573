import { FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import { Badge, Button, Popover, Select } from 'antd';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import classNames from 'classnames';
import Color from 'color';
import Fuse from 'fuse.js';
import { map } from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { RoleList, Roles } from '../../../../core/rule/RolesTypes';
import { RootState } from '../../../../redux/RootState';
import KannelleRoleTag from '../role-tag/KannelleRoleTag';

type Props = {
  callbackFilterCharterChange: (value: number[] | undefined) => void;
  callbackFilterRoleChange: (value: string[] | undefined) => void;
  callbackFilterIsActiveChange: (value: boolean | undefined) => void;
};

const useStyles = createUseStyles({
  select: {
    width: '100%',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
  },
  popover: {
    '& .ant-popover-inner-content': {
      padding: 0,
    },
  },
  buttonReset: {
    width: '100%',
    background: '#CBD2D9',
    color: '#323F4B',
    borderWidth: 0,
    borderRadius: 0,
    '&:hover, &:focus, &:visited, &:active': {
      background: Color('#CBD2D9').lighten(0.05).hex(),
      color: '#323F4B',
      borderWidth: '0',
    },
  },
  badge: {
    '& .ant-badge-count': {
      boxShadow: 'unset',
    },
  },
  popoverContainer: {
    minWidth: 250,
    maxWidth: 250,
    padding: '12px 16px 0',
  },
  filterItem: {
    marginBottom: 8,
  },
  tagSelected: {
    marginRight: '4px !important',
    pointerEvents: 'auto !important',
  },
});

const FilterUserCharterTable: FunctionComponent<Props> = ({
  callbackFilterCharterChange,
  callbackFilterRoleChange,
  callbackFilterIsActiveChange,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const charterList = useSelector((state: RootState) => state.charters.list);
  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);

  const placement = isMobileOrTablet ? 'bottomRight' : 'right';

  const [query, setQuery] = useState<string>();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [charterSorted, setCharterSorted] = useState(charterList || []);
  const [charterFilterValue, setCharterFilterValue] = useState<number[]>([]);
  const [roleFilterValue, setRoleFilterValue] = useState<string[]>([]);

  const fuseCharter = new Fuse(charterList || [], {
    keys: ['name'],
    threshold: 0.5,
  });

  useEffect(() => {
    if (!query || query === '') {
      setCharterSorted(charterList || []);
      return;
    }

    const result = fuseCharter.search(query);

    const charterSortedFuse = result.map((res) => res.item);
    setCharterSorted(charterSortedFuse);
    // eslint-disable-next-line
  }, [query]);

  const handleFilterCharterChange = (value: number[]): void => {
    setCharterFilterValue(value);
    callbackFilterCharterChange(value);
  };

  const onSearch = (value: string): void => {
    setQuery(value);
  };

  const handleFilterRoleChange = (value: string[]): void => {
    setRoleFilterValue(value);
    callbackFilterRoleChange(value);
  };

  const handleFilterIsActiveChange = (tag: string, checked: boolean): void => {
    const nextSelectedTags = checked ? [tag] : selectedTags.filter((selectedTag: string) => selectedTag !== tag);
    const nextSelectedTagValue = checked ? tag === 'activated' : undefined;

    callbackFilterIsActiveChange(nextSelectedTagValue);
    setSelectedTags(nextSelectedTags);
  };

  const handleReset = (): void => {
    setSelectedTags([]);
    setCharterFilterValue([]);
    setRoleFilterValue([]);
    callbackFilterCharterChange(undefined);
    callbackFilterRoleChange(undefined);
    callbackFilterIsActiveChange(undefined);
  };

  const tagRender = (properties: any): JSX.Element => {
    const { value, closable, onClose } = properties;
    const role: Roles = value as Roles;

    return <KannelleRoleTag roleName={role} className={classes.tagSelected} closable={closable} onClose={onClose} />;
  };

  const totalActiveFilter = selectedTags.length + charterFilterValue.length + roleFilterValue.length;

  const content = (
    <>
      <div className={classes.popoverContainer}>
        <li className={classes.filterItem}>
          <h4>{t('users.filters.charters')}</h4>
          <Select
            onChange={handleFilterCharterChange}
            className={classes.select}
            mode="multiple"
            showSearch
            showArrow
            allowClear
            filterOption={false}
            onSearch={onSearch}
            value={charterFilterValue}
          >
            {charterSorted &&
              charterSorted.map((charter) => {
                return (
                  <Select.Option key={charter.id} value={charter.id}>
                    {charter.name}
                  </Select.Option>
                );
              })}
          </Select>
        </li>
        <li className={classes.filterItem}>
          <h4>{t('users.filters.roles')}</h4>
          <Select
            onChange={handleFilterRoleChange}
            className={classes.select}
            mode="multiple"
            showSearch={false}
            showArrow
            allowClear
            tagRender={tagRender}
            value={roleFilterValue}
          >
            {map(RoleList, (role) => {
              return (
                <Select.Option key={role.code} value={role.code}>
                  <KannelleRoleTag roleName={role.code} closable={false} />
                </Select.Option>
              );
            })}
          </Select>
        </li>
        <li className={classes.filterItem}>
          <h4>{t('users.filters.isActive')}</h4>

          <CheckableTag
            key="activated"
            checked={selectedTags.indexOf('activated') > -1}
            onChange={(checked): void => handleFilterIsActiveChange('activated', checked)}
          >
            {t('form.activated')}
          </CheckableTag>
          <CheckableTag
            key="deactivated"
            checked={selectedTags.indexOf('deactivated') > -1}
            onChange={(checked): void => handleFilterIsActiveChange('deactivated', checked)}
          >
            {t('form.deactivated')}
          </CheckableTag>
        </li>
      </div>
      <Button className={classes.buttonReset} onClick={handleReset}>
        {t('global.reset')} <ReloadOutlined />
      </Button>
    </>
  );

  return (
    <div>
      <Popover overlayClassName={classes.popover} content={content} trigger="click" placement={placement}>
        <Badge className={classNames(classes.badge)} count={totalActiveFilter}>
          <Button className={classes.button}>
            <span>{t('global.filter')}</span>
            <FilterOutlined />
          </Button>
        </Badge>
      </Popover>
    </div>
  );
};

export default FilterUserCharterTable;
