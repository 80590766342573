/* eslint-disable import/no-cycle */
import { Button } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCamera } from 'react-icons/fa';
import { createUseStyles } from 'react-jss';
import { MediaFile } from '../../services/api/types/ChartersServiceTypes';
import { MediaType } from '../../utils/types/MediaTypes';
import WebcamRecorderModal from './WebcamRecorderModal';

type Props = {
  format?: string;
  mediaType?: MediaType;
  options?: WebcamRecorderOptions;
  onRecordingProcessed?: (media: MediaFile) => void;
};

export type WebcamRecorderOptions = {
  recommendedTime?: number;
  overlayImage?: string;
};

const useStyles = createUseStyles({
  recordButton: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 8,
    '& > svg': {
      marginRight: 8,
    },
  },
});

const WebcamRecorder: FunctionComponent<Props> = ({ format, mediaType, options, onRecordingProcessed }: Props) => {
  const [isRecordModalVisible, setIsRecordModalVisible] = useState(false);

  const classes = useStyles();
  const { t } = useTranslation();

  const onOpenModal = () => {
    setIsRecordModalVisible(true);
  };

  const onCloseModal = () => {
    setIsRecordModalVisible(false);
  };

  const buttonTitle =
    mediaType === MediaType.IMAGE
      ? t('charters.mediaLibrary.webcamRecorder.pictureButton')
      : t('charters.mediaLibrary.webcamRecorder.recordButton');

  return (
    <>
      <Button onClick={onOpenModal} icon={<FaCamera />} type="ghost" className={classes.recordButton}>
        {buttonTitle}
      </Button>

      <WebcamRecorderModal
        isVisible={isRecordModalVisible}
        format={format}
        mediaType={mediaType}
        options={options}
        onRecordingProcessed={onRecordingProcessed}
        onCancel={onCloseModal}
      />
    </>
  );
};

export default WebcamRecorder;
