import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMenu } from '../../redux/action/AppAction';
import { RootState } from '../../redux/RootState';
import smallLogo from '../../resources/img/K-rectangle-small.png';
import logoKnl from '../../resources/img/K-rectangle.png';
import { APISubscription } from '../../services/api/types/ChargebeeServiceTypes';
import ChargebeeUtils from '../../utils/ChargebeeUtils';
import KannelleFaqButton from './KannelleFaqButton';
import KannelleHeaderCompanyInfo from './KannelleHeaderCompanyInfo';
import KannelleHeaderCompanySelect from './KannelleHeaderCompanySelect';
import KannelleHeaderSubscriptionCancelledInfo from './KannelleHeaderSubscriptionCancelledInfo';
import KannelleHeaderUserInfo from './KannelleHeaderUserInfo';
import KannelleNotificationsMenu from './KannelleNotificationsMenu';

const { Header } = Layout;

type Props = {
  isMobileOrTablet: boolean;
};

const useStyles = createUseStyles({
  header: {
    backgroundColor: '#fff',
    padding: '0 8px 0 0',
    boxShadow: '-3px 3px 3px #ecebeb',
    position: 'fixed',
    top: 0,
    zIndex: 5,
    width: '100%',
  },

  logoContainer: {
    maxWidth: 200,
    textAlign: 'center',
    height: '64px',
    lineHeight: '64px',
    padding: '0 8px',
    marginRight: 8,
  },
  logo: (props: Props) => ({
    width: props.isMobileOrTablet ? '60%' : '90%',
    maxWidth: props.isMobileOrTablet ? 50 : 182,
    minWidth: props.isMobileOrTablet ? 40 : 170,
    maxHeight: 64,
  }),
  rightItems: {
    float: 'right',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  faqButton: {
    marginLeft: '10px',
  },
});

const KannelleHeader: FunctionComponent = () => {
  const menuCollapsed = useSelector((state: RootState) => state.app.menuCollapsed);
  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);
  const subscriptions = useSelector((state: RootState) => state.companies.subscriptions);
  const currentCompany = useSelector((state: RootState) => state.companies.current);
  const [subscriptionInfoToDisplay, setSubscriptionInfoToDisplay] = useState<APISubscription>();

  useEffect(() => {
    setSubscriptionInfoToDisplay(ChargebeeUtils.getMostRelevantSubscriptionForHeaderDisplay(subscriptions));
  }, [subscriptions]);

  const classes = useStyles({ isMobileOrTablet });

  const dispatch = useDispatch();
  const ToggleIcon = menuCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined;
  const toggle = (): void => {
    dispatch(toggleMenu());
  };
  const logo = isMobileOrTablet ? smallLogo : logoKnl;
  const logoClass = classes.logo;

  return (
    <Header className={classes.header}>
      <span className={classes.logoContainer}>
        <img className={logoClass} src={logo} alt="logo" />
      </span>

      <ToggleIcon className="menu-trigger" onClick={toggle} />

      <KannelleHeaderCompanySelect />

      <span className={classes.rightItems}>
        {subscriptionInfoToDisplay && (
          <KannelleHeaderSubscriptionCancelledInfo subscription={subscriptionInfoToDisplay} />
        )}

        {currentCompany?.isActive === false && <KannelleHeaderCompanyInfo />}

        <KannelleNotificationsMenu />

        {!isMobileOrTablet && (
          <span className={classes.faqButton}>
            <KannelleFaqButton />
          </span>
        )}
        <KannelleHeaderUserInfo />
      </span>
    </Header>
  );
};

export default KannelleHeader;
