import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row } from 'antd';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import AnimationsUtils from '../../utils/AnimationsUtils';
import { AnimationConfig, AnimationOption } from '../../utils/types/AnimationTypes';
import { AnimationTheme } from '../../utils/types/ThemeTypes';
import AnimationSelectorCard from './AnimationSelectorCard';

const { confirm } = Modal;

type Props = {
  selectedAnimation?: AnimationConfig;
  onSelectAnimation: (animationConfig: AnimationConfig) => void;
  isVisible: boolean;
  fieldLabel: string;
  format?: string;
  theme: AnimationTheme;
  customColors: boolean;
  onCancel: () => void;
  animations?: AnimationOption[];
};

const useStyles = createUseStyles({
  animationSelectorModal: {
    width: '75% !important',
    '& .ant-modal-body': {
      paddingTop: '10px',
    },
  },
});

const AnimationSelectorModal: FunctionComponent<Props> = ({
  selectedAnimation,
  onSelectAnimation,
  isVisible,
  fieldLabel,
  format,
  theme,
  customColors,
  onCancel,
  animations,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const layoutColProps = { xs: 24, sm: 24, md: 12, lg: 8, xl: 8, xxl: 8 };

  if (!(animations && format && isVisible && theme)) {
    return null;
  }

  const confirmBeforeSelectingAnimationIfNeeded = (
    selectedAnimationConfig: AnimationConfig,
    shouldAskConfirmation: boolean
  ) => {
    if (!shouldAskConfirmation) {
      onSelectAnimation(selectedAnimationConfig);
      return;
    }

    confirm({
      title: t('animationSelector.someTextsWillBeReplacedConfirmTitle'),
      icon: <ExclamationCircleOutlined />,
      content: t('animationSelector.someTextsWillBeReplacedConfirm'),
      onOk: () => {
        onSelectAnimation(selectedAnimationConfig);
      },
    });
  };

  return (
    <Modal
      title={fieldLabel}
      visible={isVisible}
      onCancel={onCancel}
      footer={[
        <Button key="cancelModal" onClick={onCancel}>
          {t('global.cancel')}
        </Button>,
      ]}
      className={classes.animationSelectorModal}
    >
      <Row gutter={[16, 16]}>
        {animations.map((animation) => {
          const availablePositions = AnimationsUtils.getPossiblePositionsByAnimationName(animation, theme);
          const animationDefaultTexts = AnimationsUtils.getDefaultAnimationTextsByAnimation(animation);

          return availablePositions.map((animationPosition) => {
            // Check if the current cards corresponds to the selected animation if there is one
            const isSelected =
              selectedAnimation &&
              selectedAnimation.name === animation.code &&
              isEqual(selectedAnimation.position, omit(animationPosition, 'key'));

            const shouldAskConfirmation = AnimationsUtils.isSwitchingToAnimationForcingNumbers(
              selectedAnimation?.name,
              animation.code
            );

            return (
              <Col key={`${animation.code}-${animationPosition.code}`} {...layoutColProps}>
                <AnimationSelectorCard
                  animationOption={animation}
                  animationPosition={animationPosition}
                  theme={theme}
                  format={format}
                  customColors={customColors}
                  animationDefaultTexts={animationDefaultTexts}
                  isSelected={isSelected}
                  onSelectAnimation={(animationConfig) =>
                    confirmBeforeSelectingAnimationIfNeeded(animationConfig, shouldAskConfirmation)
                  }
                />
              </Col>
            );
          });
        })}
      </Row>
    </Modal>
  );
};

export default AnimationSelectorModal;
