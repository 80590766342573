import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { createUseStyles } from 'react-jss';
import { ExtendedScenarioSceneTemplate } from './hooks/useScenarioFinalRenderData';
import ScenarioScenePreview from './ScenarioScenePreview';

type Props = {
  currentTime: number;
  seekTo: number;
  volume: number;
  isVisible: boolean;
  scene: ExtendedScenarioSceneTemplate;
  format: string;
  isPlaying: any;
  onIsPlayingChange: any;
  onEndReached: () => void;
  onUpdateCurrentTime: (newTimeInScenario: number) => void;
  onIsLoadingChange?: (isLoading: boolean) => void;
};

const useStyles = createUseStyles({
  sceneContainer: {
    zIndex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  invisibleSceneContainer: {
    zIndex: 0,
    visibility: 'hidden',
  },
});

const ScenarioFinalRenderScenePlayer: FunctionComponent<Props> = ({
  currentTime,
  isVisible,
  scene,
  format,
  isPlaying,
  seekTo,
  onIsPlayingChange,
  onEndReached,
  onUpdateCurrentTime,
  volume,
  onIsLoadingChange,
}: Props) => {
  const classes = useStyles();

  // Callback called when the time updates in the visible scene. Based on the new time in the scene, we compute
  // the new time in the scenario.
  const onCurrentTimeChangeInScene = (newTimeInScene: number) => {
    const newTime = scene.absoluteStartAt + newTimeInScene;

    if (currentTime < newTime) {
      onUpdateCurrentTime(newTime);
    }
  };

  return (
    <div className={classNames(classes.sceneContainer, !isVisible && classes.invisibleSceneContainer)}>
      <ScenarioScenePreview
        scene={scene}
        format={format}
        isPlaying={isPlaying && isVisible}
        seekTo={seekTo}
        onCurrentTimeChange={isVisible ? onCurrentTimeChangeInScene : undefined}
        onIsPlayingChange={onIsPlayingChange}
        controls={false}
        onEndReached={onEndReached}
        volume={volume}
        onIsLoadingChange={onIsLoadingChange}
      />
    </div>
  );
};

export default ScenarioFinalRenderScenePlayer;
