import { CopyOutlined, DeleteOutlined, EyeInvisibleFilled } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import AccessChecker from '../../../../../../components/access-checker/AccessChecker';
import { THEME } from '../../../../../../Constants';
import useCanAccess from '../../../../../../hooks/useCanAccess';
import { PermissionList } from '../../../../../../utils/types/CharterPermissionTypes';
import { ProjectScene } from '../../../../../../utils/types/ProjectTypes';
import { AnimationTheme } from '../../../../../../utils/types/ThemeTypes';
import ProjectScenePreview from '../ProjectScenePreview';

type Props = {
  scene: ProjectScene;
  canDeleteScene: boolean;
  isSelectedScene: boolean;
  isProjectLoading: boolean;
  format?: string;
  theme: AnimationTheme;
  customColors: boolean;
  onDeleteScene: (sceneToDelete: ProjectScene) => void;
  onDuplicateScene: (sceneToDuplicate: ProjectScene) => void;
};

const useStyles = createUseStyles({
  sceneCard: {
    height: 80,
    width: 120,
    borderRadius: 5,
    overflow: 'hidden',
    position: 'relative',
  },
  selectedScenePreviewContainer: {
    outline: `5px solid ${THEME.MENU.MAIN_COLOR}`,
    outlineOffset: '-5px',
    '& > div:first-child': {
      margin: '0 5px',
    },
  },
  inactiveSceneCard: ({ isSelectedScene }) => ({
    position: 'absolute',
    top: isSelectedScene ? 5 : 0,
    left: isSelectedScene ? 5 : 0,
    width: isSelectedScene ? 'calc(100% - 10px)' : '100%',
    height: isSelectedScene ? 'calc(100% - 10px)' : '100%',
    zIndex: 3,
    backgroundColor: 'rgba(128, 128, 128, 0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: 32,
  }),
  scenePreviewContainer: {
    height: '100%',
    width: '100%',
    background: '#000000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  duplicateSceneButton: {
    position: 'absolute',
    top: 5,
    left: 5,
    zIndex: 4,
    transition: 'none !important',
  },
  deleteSceneButton: {
    position: 'absolute',
    top: 5,
    right: 5,
    zIndex: 4,
    transition: 'none !important',
  },
});

const ProjectSceneCard: FunctionComponent<Props> = ({
  scene,
  canDeleteScene,
  format,
  theme,
  customColors,
  isSelectedScene,
  isProjectLoading,
  onDuplicateScene,
  onDeleteScene,
}: Props) => {
  const classes = useStyles({ isSelectedScene });
  const { t } = useTranslation();

  const permissionToDeleteScene = useCanAccess(PermissionList.SCENE_DELETE);
  const permissionToDuplicateScene = useCanAccess(PermissionList.SCENE_DUPLICATE);

  const renderScenePreview = () => {
    return (
      <div
        className={classNames(classes.scenePreviewContainer, isSelectedScene && classes.selectedScenePreviewContainer)}
      >
        <ProjectScenePreview
          scene={scene}
          format={format}
          theme={theme}
          customColors={customColors}
          isPlaying={false}
          seekTo={1} // Seek to a time for which every animation has a visible frame
          isCarousel
        />
        {!scene.isActive && (
          <div className={classes.inactiveSceneCard}>
            <Tooltip title={t('charters.projects.projectEditor.sceneActions.inactive')}>
              <EyeInvisibleFilled />
            </Tooltip>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={classes.sceneCard}>
      <AccessChecker
        renderUnauthorizedMessage={permissionToDuplicateScene.renderUnauthorizedMessage}
        hasAccess={permissionToDuplicateScene.hasUserAccess()}
      >
        <Tooltip
          title={
            permissionToDuplicateScene.hasUserAccess()
              ? t('charters.projects.projectEditor.sceneActions.duplicate')
              : undefined
          }
        >
          <Button
            disabled={isProjectLoading || !permissionToDuplicateScene.hasUserAccess()}
            icon={<CopyOutlined />}
            className={classes.duplicateSceneButton}
            shape="circle"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onDuplicateScene(scene);
            }}
          />
        </Tooltip>
      </AccessChecker>

      {/* Delete scene (in a popconfirm) if the project has more than 1 scene */}
      {canDeleteScene && (
        <div onClick={(e) => e.stopPropagation()}>
          <AccessChecker
            renderUnauthorizedMessage={permissionToDeleteScene.renderUnauthorizedMessage}
            hasAccess={permissionToDeleteScene.hasUserAccess()}
          >
            <Popconfirm
              title={t('charters.projects.projectEditor.sceneActions.deleteSceneConfirm')}
              onConfirm={() => onDeleteScene(scene)}
              okText={t('global.yes')}
              cancelText={t('global.no')}
              disabled={isProjectLoading || !permissionToDeleteScene.hasUserAccess()}
            >
              <Tooltip
                title={
                  permissionToDeleteScene.hasUserAccess()
                    ? t('charters.projects.projectEditor.sceneActions.delete')
                    : undefined
                }
              >
                <Button
                  disabled={isProjectLoading || !permissionToDeleteScene.hasUserAccess()}
                  danger
                  icon={<DeleteOutlined />}
                  className={classes.deleteSceneButton}
                  shape="circle"
                  size="small"
                />
              </Tooltip>
            </Popconfirm>
          </AccessChecker>
        </div>
      )}
      {renderScenePreview()}
    </div>
  );
};

export default ProjectSceneCard;
