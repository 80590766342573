/* eslint-disable no-shadow */
import { DeleteOutlined } from '@ant-design/icons';
import { CheckCircleFilled } from '@ant-design/icons/lib';
import { Button, Card, message, Popconfirm, Tooltip } from 'antd';
import { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import log from 'loglevel';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { isChrome, isFirefox, isSafari } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import ReactPlayer from 'react-player';
import { THEME } from '../../Constants';
import { APIManager } from '../../services/api/APIManager';
import { Asset, MediaFile } from '../../services/api/types/ChartersServiceTypes';
import { Music } from '../../services/api/types/MusicsServiceTypes';
import StringUtils from '../../utils/StringUtils';
import KannelleSwitch from '../switch/KannelleSwitch';

type MediaObject = Asset | MediaFile | Music;

type Props = {
  videoOrMusicFile: MediaObject;
  isMusic?: boolean;
  videoOrMusicPlaying?: MediaObject;
  callbackOnIsActiveChange?: (isActive: boolean) => void;
  callbackPlaying: (isPlaying: boolean) => void;
  selected?: boolean;
  assetType?: string;
  disabled?: boolean;
  trigger?: 'switch' | 'button';
  callbackOnSelect?: () => void;
  handleIsActiveChange?: (
    isActive: boolean,
    imageFile: Asset | MediaFile,
    cancelTokenSource?: CancelTokenSource
  ) => Promise<MediaObject>;
  handleArchive?: (imageFile: Asset | MediaFile, cancelTokenSource?: CancelTokenSource) => Promise<Asset | MediaFile>;
};

type StyleProps = {
  isMusic: boolean;
  isSafari: boolean;
  isChrome: boolean;
  isFirefox: boolean;
  hasArchiveButton: boolean;
};

const useStyles = createUseStyles({
  card: {
    boxShadow: '0 8px 8px 0 hsla(0, 0%, 0%, 0.15) !important',
    overflow: 'hidden',

    display: 'flex',
    flexDirection: 'column',

    height: '100%',
    wordBreak: 'break-word',
    width: '100%',

    '& .ant-card-body': {
      '& p': {
        flexGrow: 1,
      },
      padding: '8px 8px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
  },
  imageFileCoverContainer: ({ isMusic }: StyleProps) => {
    let browserBackground = '#f1f3f4';
    if (isChrome) {
      browserBackground = '#f1f3f4';
    } else if (isSafari) {
      browserBackground = '#4f4f4f';
    } else if (isFirefox) {
      browserBackground = '#434343';
    }

    const background = isMusic ? browserBackground : '#EDEDED';
    return {
      padding: 0,
      display: 'flex',
      height: isMusic ? 40 : 200,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: background,
    };
  },
  fileNameContainer: ({ hasArchiveButton }: StyleProps) => ({
    marginBottom: hasArchiveButton ? '16px' : 'unset',
  }),
  ellipsisP: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  pNoWrap: {
    whiteSpace: 'nowrap',
  },

  playerContainer: {
    '& audio': {
      outline: 'unset',
      borderRadius: 0,
    },
  },
  selectedVideoFile: {
    position: 'absolute',
    top: '2px',
    right: '4px',
    color: THEME.DEFAULT.MAIN_TEXT_COLOR,
  },
  selectButton: {
    margin: 'auto',
    width: 'fit-content',
  },
  selectedCardButton: {
    '&, &:active, &:focus': {
      color: THEME.DEFAULT.MAIN_TEXT_COLOR,
      background: THEME.DEFAULT.FADE_COLOR,
      borderColor: THEME.DEFAULT.MAIN_TEXT_COLOR,
    },
    '&:hover': {
      color: THEME.DEFAULT.HOVERED_LINK,
      borderColor: THEME.DEFAULT.HOVERED_LINK,
    },
  },
  archiveButtonContainer: {
    position: 'absolute',
    right: '8px',
    bottom: '8px',
  },
});

const VideoOrMusicFileCardPlayer: FunctionComponent<Props> = ({
  handleIsActiveChange,
  handleArchive,
  videoOrMusicFile,
  isMusic = false,
  videoOrMusicPlaying,
  callbackOnIsActiveChange,
  callbackPlaying,
  trigger,
  callbackOnSelect,
  assetType,
  selected = false,
  disabled = selected,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles({ isMusic, isSafari, isChrome, isFirefox, hasArchiveButton: handleArchive !== undefined });
  const [loading, setLoading] = useState(false);
  const [isArchivedLoading, setIsArchivedLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const myNodeRef = useCallback((node: any) => {
    if (node) {
      setIsEllipsisActive(node.offsetWidth < node.scrollWidth);
    }
  }, []);

  const isPublicMusic = (file: MediaObject): file is Music => {
    return (file as Music).fileUri !== undefined;
  };

  const isAssetOrMediaFile = (file: MediaObject): file is Asset | MediaFile => {
    return (file as Asset).url !== undefined || (file as MediaFile).url !== undefined;
  };

  const isMediaFile = (file: MediaObject): file is MediaFile => {
    return (file as MediaFile).mediaType !== undefined;
  };

  // Compute file name, file url and thumbnail if exists
  let fileName;
  let fileUrl;
  let fileThumbnailImage;

  // If the file is a PublicMusic
  if (isPublicMusic(videoOrMusicFile)) {
    fileName = videoOrMusicFile.name;
    fileUrl = videoOrMusicFile.previewFileUri;
  } else {
    // Else it is either an Asset or MediaFile
    fileName = videoOrMusicFile?.publicName ?? StringUtils.getFileNameFromUrl(videoOrMusicFile.url);
    fileUrl = videoOrMusicFile?.hlsUrl ?? videoOrMusicFile.url;
    if (isMediaFile(videoOrMusicFile)) {
      fileThumbnailImage = videoOrMusicFile?.thumbnailImage?.url;
    }
  }

  const handleChange = (isActive: boolean): void => {
    const cancelTokenSource = APIManager.getCancelToken();

    if (!handleIsActiveChange) {
      return;
    }

    setLoading(true);
    setError(false);

    handleIsActiveChange(isActive, videoOrMusicFile as MediaFile | Asset, cancelTokenSource)
      .then(() => {
        setLoading(false);
        if (callbackOnIsActiveChange) {
          callbackOnIsActiveChange(isActive);
        }
      })
      .catch((patchError) => {
        const errorResponse = patchError.response;
        if (errorResponse.status === 403) {
          message.error(t(`charters.${assetType}.bundleLimitExceedIsActive`), 3);
          setLoading(false);
          return;
        }

        log.error(patchError);
        setError(true);
        setLoading(false);
      });
  };

  const onArchive = (): void => {
    const cancelTokenSource = APIManager.getCancelToken();
    setIsArchivedLoading(true);

    if (!handleArchive) {
      return;
    }

    handleArchive(videoOrMusicFile as MediaFile | Asset, cancelTokenSource)
      .then(() => {
        message.success(t('charters.archive.successfullyArchived'));
      })
      .catch((patchError) => {
        message.error(t('charters.archive.archiveError'));
        log.error(patchError.response);
      })
      .finally(() => {
        setIsArchivedLoading(false);
      });
  };

  const handleOnStart = (): void => {
    callbackPlaying(true);
  };

  return (
    <Card
      className={classNames(classes.card)}
      cover={
        <div className={classes.imageFileCoverContainer}>
          <ReactPlayer
            className={classes.playerContainer}
            url={fileUrl}
            light={fileThumbnailImage}
            width="100%"
            height="100%"
            controls
            playing={videoOrMusicPlaying && videoOrMusicFile.id === videoOrMusicPlaying.id}
            onStart={handleOnStart}
            onPlay={handleOnStart}
            onClickPreview={handleOnStart}
          />
        </div>
      }
    >
      {trigger === 'switch' && selected && (
        <div className={classes.selectedVideoFile}>
          <CheckCircleFilled />
        </div>
      )}
      <div className={classes.fileNameContainer}>
        {isEllipsisActive ? (
          <Tooltip title={fileName}>
            <p ref={myNodeRef} className={classes.ellipsisP}>
              {fileName}
            </p>
          </Tooltip>
        ) : (
          <p className={classes.pNoWrap} ref={myNodeRef}>
            {fileName}
          </p>
        )}
      </div>

      {trigger === 'switch' && isAssetOrMediaFile(videoOrMusicFile) && videoOrMusicFile.isActive !== undefined && (
        <KannelleSwitch
          isChecked={videoOrMusicFile.isActive}
          size="small"
          callbackChange={handleChange}
          loading={loading}
          error={error}
          labelActivated="form.activated"
          labelDeactivated="form.deactivated"
        />
      )}

      {trigger === 'button' && callbackOnSelect && (
        <Button
          shape="round"
          className={classNames(classes.selectButton, selected && classes.selectedCardButton)}
          disabled={disabled}
          onClick={callbackOnSelect}
          icon={selected ? <CheckCircleFilled /> : undefined}
        >
          {selected ? t('global.unselect') : t('global.select')}
        </Button>
      )}

      {handleArchive !== undefined && (
        <div className={classes.archiveButtonContainer}>
          <Popconfirm
            title={t('charters.archive.archiveConfirm')}
            onConfirm={onArchive}
            okText={t('global.yes')}
            cancelText={t('global.no')}
            okButtonProps={{ loading: isArchivedLoading, disabled: isArchivedLoading, danger: true }}
            disabled={isArchivedLoading}
          >
            <Button
              type="primary"
              danger
              shape="circle"
              icon={<DeleteOutlined />}
              size="small"
              loading={isArchivedLoading}
            />
          </Popconfirm>
        </div>
      )}
    </Card>
  );
};

export default VideoOrMusicFileCardPlayer;
