import { Table } from 'antd';
import React, { FunctionComponent, Key, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { THEME } from '../../../Constants';
import { Role } from '../../../core/rule/Roles';
import { Roles } from '../../../core/rule/RolesTypes';
import { APIModelCharter } from '../../../services/api/types/ChartersServiceTypes';
import { APICompanyUsers } from '../../../services/api/types/CompaniesServiceTypes';
import KannelleRoleTagDropdown from './role-tag/KannelleRoleTagDropdown';
import KannelleStatusSwitch from './status-switch/KannelleStatusSwitch';

type Props = {
  user: APICompanyUsers;
  handleSubRowSelection: (user: APICompanyUsers, selectedRows: APIModelCharter[]) => void;
  callbackUpdateRole: (user: APICompanyUsers, charterId: number, role: Roles) => void;
  callbackUpdateIsActive: (user: APICompanyUsers, charterId: number, isActive: boolean) => void;
};

const useStyles = createUseStyles({
  table: {
    backgroundColor: '#fbfbfb',

    '& .ant-table': {
      margin: '0px !important',

      '& table': {
        border: 'solid 1px #f0f0f0',
        backgroundColor: '#ffffff',
        width: 'auto',
      },
    },

    '& .ant-table-tbody > tr.ant-table-row-selected > td ': {
      backgroundColor: `${THEME.DEFAULT.FADE_COLOR} !important`,
    },

    '& .ant-table-content': {
      background: '#fafafa',
    },
    '& .ant-table .ant-table-selection-col': {
      width: 30,
    },
    '& tbody tr:last-child td': {
      borderBottom: 0,
    },
    '& .ant-table-container::after': {
      boxShadow: 'unset !important',
    },
  },
  '@keyframes fadein': {
    '0:': { backgroundColor: '#E3F9E5' },
    '100.0%': { backgroundColor: 'inherit' },
  },
  rowAdded: {
    '& td, & .ant-table-cell-fix-left': {
      animationName: '$fadein',
      animationDuration: '4s',
      animationTimingFunction: 'ease-in-out',
      animationIterationCount: 1,
      animationFillMode: 'forwards',
      animationPlayState: 'running',
      backgroundColor: THEME.GLOBAL.MAIN_HIGHLIGHT_BACKGROUND_COLOR,
    },
  },
});

const UsersExpandedRow: FunctionComponent<Props> = ({
  user,
  handleSubRowSelection,
  callbackUpdateRole,
  callbackUpdateIsActive,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const expandedRowColumns = [
    {
      title: t('users.charterName'),
      key: 'charterName',
      dataIndex: 'name',
      render: (name: string): JSX.Element | undefined => <span>{name}</span>,
      width: 300,
    },
    {
      title: t('users.role'),
      dataIndex: 'role',
      key: 'user_role',
      render: (userRole: string, row: APIModelCharter): ReactElement => {
        const role = new Role(row.currentUser.role);
        return (
          <KannelleRoleTagDropdown
            concernedUser={user}
            charter={row}
            role={role}
            updating={row.updating?.role}
            callbackUpdateRole={callbackUpdateRole}
          />
        );
      },
      width: 300,
    },
    {
      title: t('users.status'),
      dataIndex: 'isActive',
      key: 'user_activated',
      render: (isActive: boolean, row: APIModelCharter): ReactElement => {
        return (
          <KannelleStatusSwitch
            concernedUser={user}
            charterId={row.id}
            isActive={row.currentUser.isActive}
            role={row.currentUser.role as Roles}
            updating={row.updating?.isActive}
            callbackUpdateIsActive={callbackUpdateIsActive}
          />
        );
      },
      width: 300,
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: APIModelCharter[]): void => {
      handleSubRowSelection(user, selectedRows);
    },
    getCheckboxProps: (record: APIModelCharter): { name: string; key: number } => {
      return {
        name: record.name,
        key: record.id,
      };
    },
  };

  return (
    <>
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        rowClassName={(record): string => {
          if (record.highlightRow) {
            return classes.rowAdded;
          }
          return '';
        }}
        className={classes.table}
        columns={expandedRowColumns}
        dataSource={[...user.charters] || []}
        pagination={false}
        rowKey={(record): string => {
          return `charters_row_expanded_${record.id}`;
        }}
      />
    </>
  );
};

export default UsersExpandedRow;
