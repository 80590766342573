import { Tooltip } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { createUseStyles } from 'react-jss';
import { PROJECTS, THEME } from '../../../../../../../../Constants';
import MathUtils from '../../../../../../../../utils/MathUtils';
import TimeUtils from '../../../../../../../../utils/TimeUtils';
import { AnchorType } from '../../../../../../../../utils/types/ProjectTypes';
import { VideoTrim, VideoTrimUtils } from '../../utils/VideoTrimUtils';

type Props = {
  type: AnchorType;
  trimValue: VideoTrim;
  width: number;
  onTrimChange: (e: VideoTrim) => void;
  callbackAnchorChange: (e: VideoTrim) => void;
  intervalTickWidth: number;
  className?: string;
  delay?: number; // if scene element has a delay (in seconds)
  maxDuration?: number; // if scene element has a limit (in seconds)
  isMediaImage?: boolean; // is media an image or a video
  anchorColor?: string; // if not provided default to THEME.PROJECTS.EDITOR.TIMELINE.SELECTED_ELEMENT_BORDER
};

type StylesProps = {
  type: AnchorType;
  trimValue: VideoTrim;
  intervalTickWidth: number;
  anchorColor: string;
};

const useStyles = createUseStyles({
  trimContainer: {
    width: PROJECTS.TRIM.ANCHOR_WIDTH,
    position: 'absolute',
    overflow: 'visible',
    display: 'flex',
    height: '100%',
  },
  startTrimContainer: {
    zIndex: 8,
  },
  endTrimContainer: {
    zIndex: 7,
  },
  trimElement: {
    position: 'relative',
    height: '100%',
    width: '100%',
    display: 'flex',
  },
  startTrimElement: {
    cursor: 'w-resize',
  },
  endTrimElement: {
    cursor: 'e-resize',
  },
  iconElement: ({ type, anchorColor }: StylesProps) => ({
    borderRadius: type === AnchorType.START_ANCHOR ? '8px 0 0 8px' : '0 8px 8px 0',
    backgroundColor: anchorColor,
    color: 'white',
    zIndex: 5,
    height: '100%',
  }),
});

const SceneTrimAnchor: FunctionComponent<Props> = ({
  trimValue,
  width,
  onTrimChange,
  type,
  intervalTickWidth,
  callbackAnchorChange,
  className,
  delay = undefined,
  maxDuration = 0,
  isMediaImage = false,
  anchorColor = THEME.PROJECTS.EDITOR.TIMELINE.SELECTED_ELEMENT_BORDER,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles({ type, trimValue, intervalTickWidth, anchorColor });

  const [internalTrimValues, setInternalTrimValues] = useState(trimValue);
  const internalTrimDelay = internalTrimValues.delay || 0;

  const getTrimWithDelay = (trim: VideoTrim) => {
    if (!trim.delay) {
      return trim;
    }

    return {
      delay: trim.delay || 0,
      trimStart: trim.trimStart,
      trimEnd: trim.trimEnd,
    };
  };

  useEffect(() => {
    if (trimValue.trimStart < 0) {
      const correctedTrim = { trimStart: 0, trimEnd: internalTrimValues.trimEnd };
      onTrimChange(correctedTrim);
      setInternalTrimValues(getTrimWithDelay(correctedTrim));
    } else {
      setInternalTrimValues(getTrimWithDelay(trimValue));
    }
  }, [trimValue]);

  const calculateAnchorPositionOnX = () => {
    if (type === AnchorType.START_ANCHOR) {
      return VideoTrimUtils.calculateStartAnchorPositionOnX(
        internalTrimValues.trimStart + internalTrimDelay,
        intervalTickWidth
      );
    }

    const value = delay !== undefined ? internalTrimValues.trimEnd + internalTrimDelay : internalTrimValues.trimEnd;

    return VideoTrimUtils.calculateEndAnchorPositionOnX(value, intervalTickWidth);
  };

  const calculateAnchorDefaultPositionOnX = () => {
    if (type === AnchorType.START_ANCHOR) {
      return 0;
    }
    return width;
  };

  const calculateLeftBoundPosition = () => {
    if (type === AnchorType.START_ANCHOR) {
      return VideoTrimUtils.calculateStartAnchorLeftBoundValue(
        internalTrimValues,
        intervalTickWidth,
        maxDuration,
        isMediaImage
      );
    }

    return VideoTrimUtils.calculateEndAnchorLeftBoundValue(
      internalTrimValues.trimStart + internalTrimDelay,
      intervalTickWidth
    );
  };

  const calculateRightBoundPosition = () => {
    if (type === AnchorType.START_ANCHOR) {
      return VideoTrimUtils.calculateStartAnchorRightBoundValue(
        internalTrimValues.trimEnd + internalTrimDelay,
        intervalTickWidth
      );
    }

    return VideoTrimUtils.calculateEndAnchorRightBoundValue(
      width,
      PROJECTS.TRIM.ANCHOR_WIDTH,
      (delay ?? 0) * intervalTickWidth
    );
  };

  const onDraggableChange = (event: DraggableEvent, data: DraggableData) => {
    if (type === AnchorType.START_ANCHOR) {
      const newTrimValue: VideoTrim = {
        delay: internalTrimValues.delay,
        trimStart:
          MathUtils.formatInputValueWithXDecimals(
            VideoTrimUtils.calculateStartAnchorValue(data.x, intervalTickWidth),
            3
          ) - internalTrimDelay,
        trimEnd: internalTrimValues.trimEnd,
      };

      setInternalTrimValues(newTrimValue);
      callbackAnchorChange(newTrimValue);
    } else {
      const newTrimValue: VideoTrim = {
        delay: internalTrimValues.delay,
        trimStart: internalTrimValues.trimStart,
        trimEnd: MathUtils.formatInputValueWithXDecimals(
          VideoTrimUtils.calculateEndAnchorValue(data.x, intervalTickWidth, internalTrimValues.delay),
          3
        ),
      };

      setInternalTrimValues(newTrimValue);
      callbackAnchorChange(newTrimValue);
    }
  };

  const onDraggableStopChange = (event: DraggableEvent, data: DraggableData) => {
    if (type === AnchorType.START_ANCHOR) {
      onTrimChange({
        delay: internalTrimValues.delay,
        trimStart:
          MathUtils.formatInputValueWithXDecimals(
            VideoTrimUtils.calculateStartAnchorValue(data.x, intervalTickWidth),
            3
          ) - internalTrimDelay,
        trimEnd: internalTrimValues.trimEnd,
      });
    } else {
      onTrimChange({
        delay: internalTrimValues.delay,
        trimStart: internalTrimValues.trimStart,
        trimEnd: MathUtils.formatInputValueWithXDecimals(
          VideoTrimUtils.calculateEndAnchorValue(data.x, intervalTickWidth, delay),
          3
        ),
      });
    }
  };

  return (
    <div
      className={classNames(
        classes.trimContainer,
        type === AnchorType.START_ANCHOR ? classes.startTrimContainer : classes.endTrimContainer,
        className
      )}
    >
      <div
        className={classNames(
          classes.trimElement,
          type === AnchorType.START_ANCHOR ? classes.startTrimElement : classes.endTrimElement
        )}
      >
        <Draggable
          position={{
            x: calculateAnchorPositionOnX(),
            y: 0,
          }}
          bounds={{
            left: calculateLeftBoundPosition(),
            top: 0,
            right: calculateRightBoundPosition(),
            bottom: 0,
          }}
          defaultPosition={{ x: calculateAnchorDefaultPositionOnX(), y: 0 }}
          axis="x"
          onDrag={onDraggableChange}
          onStop={onDraggableStopChange}
        >
          <div>
            {type === AnchorType.START_ANCHOR ? (
              <Tooltip
                autoAdjustOverflow={false}
                getPopupContainer={(trigger): HTMLElement => trigger.parentElement ?? document.body}
                title={
                  TimeUtils.formatSecondsIntoDuration(Math.trunc(internalTrimValues.trimStart * 100) / 100) +
                  t('charters.projects.projectEditor.timeline.trim.timeAbbreviation')
                }
                placement="leftTop"
                color={anchorColor}
              >
                <BsChevronCompactLeft className={classes.iconElement} />
              </Tooltip>
            ) : (
              <Tooltip
                autoAdjustOverflow={false}
                getPopupContainer={(trigger): HTMLElement => trigger.parentElement ?? document.body}
                title={
                  TimeUtils.formatSecondsIntoDuration(Math.trunc(internalTrimValues.trimEnd * 100) / 100) +
                  t('charters.projects.projectEditor.timeline.trim.timeAbbreviation')
                }
                placement="rightTop"
                color={anchorColor}
              >
                <BsChevronCompactRight className={classes.iconElement} />
              </Tooltip>
            )}
          </div>
        </Draggable>
      </div>
    </div>
  );
};

export default SceneTrimAnchor;
