import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactJoyride, { CallBackProps, STATUS } from 'react-joyride';
import JoyRideUtils from '../utils/JoyRideUtils';
import KannelleJoyRideTooltip from './KannelleJoyRideTooltip';

type Props = {
  runHelp: boolean;
  callbackRunDone: () => void;
};

const JoyRideHelpCharterScenarios: FunctionComponent<Props> = ({ runHelp, callbackRunDone }: Props) => {
  const { t } = useTranslation();

  const [run, setRun] = useState(false);

  useEffect(() => {
    setRun(runHelp);
  }, [runHelp]);

  const handleJoyrideCallback = (data: CallBackProps): void => {
    const { status, lifecycle } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status) && lifecycle === 'complete') {
      setRun(false);
      callbackRunDone();
    }
  };
  const steps = JoyRideUtils.getCharterScenariosSteps(t);

  return (
    <>
      <ReactJoyride
        continuous
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        scrollOffset={120}
        tooltipComponent={KannelleJoyRideTooltip}
        steps={steps}
        callback={handleJoyrideCallback}
        locale={{
          back: t('onBoarding.steps.back'),
          close: t('onBoarding.steps.close'),
          last: t('onBoarding.steps.last'),
          next: t('onBoarding.steps.next'),
          open: t('onBoarding.steps.open'),
          skip: t('onBoarding.steps.skip'),
        }}
      />
    </>
  );
};

export default JoyRideHelpCharterScenarios;
