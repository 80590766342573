import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { FunctionComponent } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { LINK } from '../../../Constants';
import { APIBundles } from '../../../services/api/types/BundlesServiceTypes';

type Props = {
  bundle: APIBundles;
};

const useStyles = createUseStyles({
  button: {
    marginRight: 4,
  },
});

const BundlesButtonActions: FunctionComponent<Props> = ({ bundle }: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const handleEditBundle = () => {
    const path = LINK.ADMIN_BUNDLES_EDIT.path.replace(':bundleId', bundle.id.toString()).replace('(\\d+)', '');
    history.push(path);
  };

  return (
    <>
      <Button className={classes.button} icon={<EditOutlined />} onClick={handleEditBundle} />
      {/* <Button className={classes.button} danger icon={<DeleteOutlined />} /> */}
    </>
  );
};

export default BundlesButtonActions;
