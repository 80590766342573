import { FilePdfOutlined } from '@ant-design/icons/lib';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Button } from 'antd';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LOGGING_EVENT } from '../../../../Constants';
import { RootState } from '../../../../redux/RootState';
import { ScenarioVariant } from '../../../../services/api/types/ChartersServiceTypes';
import MediaUtils from '../../../../utils/MediaUtils';
import StringUtils from '../../../../utils/StringUtils';
import ScenarioPDF from './ScenarioPDF';

type Props = {
  scenarioVariant: ScenarioVariant;
};

const ScenarioPDFDownloadButton: FunctionComponent<Props> = ({ scenarioVariant }: Props) => {
  const { t } = useTranslation();
  const [croppedDemoSourceImages, setCroppedDemoSourceImages] = useState<Array<string | undefined>>();
  const charter = useSelector((state: RootState) => state.charters.current);
  const company = useSelector((state: RootState) => state.companies.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);

  // Crop the demoCoverImageSource of the scenes before rendering the PDF
  useEffect(() => {
    if (!scenarioVariant.scenes) {
      return undefined;
    }

    let isMounted = true;
    const croppedImages: Array<string | undefined> = [];
    const promises = scenarioVariant.scenes.map((scene, index) => {
      if (!(scene.demoCoverImageSource && scene.demoCoverImageSource.croppedArea)) {
        croppedImages[index] = scene.demoCoverImageSource ? scene.demoCoverImageSource.url : undefined;
        return undefined;
      }

      return MediaUtils.getCroppedImg(scene.demoCoverImageSource.url, scene.demoCoverImageSource.croppedArea)
        .then((croppedImageBlob) => {
          croppedImages[index] = croppedImageBlob || scene.demoCoverImageSource!.url;
        })
        .catch((e) => {
          console.error('Error while cropping image', e);
          throw e;
        });
    });

    Promise.all(promises).then(() => {
      if (isMounted) {
        setCroppedDemoSourceImages(croppedImages);
      }
    });

    return (): void => {
      isMounted = false;
    };
  }, [scenarioVariant]);

  const logPDFDownload = useCallback(
    (fileTitle: string): void => {
      loggingManager.logEvent(LOGGING_EVENT.SCENARIO_VARIANT_PDF_DOWNLOAD, {
        charterId: charter?.id,
        companyId: company?.id,
        scenarioVariantId: scenarioVariant.id,
        format: scenarioVariant.format,
        language: scenarioVariant.language,
        fileTitle,
      });
    },
    [loggingManager, charter, company, scenarioVariant]
  );

  return useMemo(() => {
    const titleSnakeCase = StringUtils.transformToSnakeCase(scenarioVariant.title);
    const fileTitle = `scenario_${titleSnakeCase}_${new Date().toISOString()}.pdf`;
    return (
      <PDFDownloadLink
        document={<ScenarioPDF scenarioVariant={scenarioVariant} croppedDemoSourceImages={croppedDemoSourceImages} />}
        fileName={fileTitle}
        key={fileTitle}
      >
        <Button
          type="primary"
          onClick={(): void => logPDFDownload(fileTitle)}
          className="downloadScenarioVariantPDFButton"
          size="small"
        >
          <FilePdfOutlined /> {t('charters.scenarios.variantEditor.downloadPDF')}
        </Button>
      </PDFDownloadLink>
    );
  }, [scenarioVariant, logPDFDownload, croppedDemoSourceImages, t]);
};

export default ScenarioPDFDownloadButton;
