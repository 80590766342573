import { createReducer } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';
import log from 'loglevel';
import Company from '../../model/Company';
import CompanyStats from '../../model/CompanyStats';
import { ChargebeePaymentMethod, ChargebeeSubscriptionObject } from '../../services/api/types/ChargebeeServiceTypes';
import { APICompanyUsers, APIModelCompany } from '../../services/api/types/CompaniesServiceTypes';
import { CompanyBundle } from '../../utils/types/BundlesTypes';
import { APP_ACTION_TYPE } from '../action/AppAction';
import { COMPANIES_ACTION_TYPE } from '../action/CompaniesAction';

export type CompaniesState = {
  list?: Company[];
  current?: Company;
  subscriptions?: ChargebeeSubscriptionObject[];
  freeTrialSubscription?: ChargebeeSubscriptionObject;
  stats?: CompanyStats;
  users?: APICompanyUsers[];
  paymentMethod?: ChargebeePaymentMethod;
  bundle?: CompanyBundle;
};

export type CompanySerializedState = {
  list: APIModelCompany[];
  current: APIModelCompany;
  subscriptions?: ChargebeeSubscriptionObject[];
  bundle?: CompanyBundle;
};

const initialState: CompaniesState = {
  list: undefined,
  current: undefined,
  stats: undefined,
  subscriptions: undefined,
  freeTrialSubscription: undefined,
  users: [],
  paymentMethod: undefined,
  bundle: undefined,
};

export const CompaniesReducer = createReducer(initialState, {
  [APP_ACTION_TYPE.RESET_STORE_STATE]: () => {
    log.info('Store Hard reset Companies');
    return { ...initialState };
  },
  [COMPANIES_ACTION_TYPE.INITIALIZE_COMPANIES]: (draftState, action) => {
    log.info('We fetched the current companies successfully: ', action.payload);
    draftState.list = [...action.payload];
    const firstCompany = action.payload[0];
    if (!draftState.current) {
      draftState.current = firstCompany;
    }
  },
  [COMPANIES_ACTION_TYPE.SELECT_COMPANY]: (draftState, action) => {
    log.info('Selected company: ', action.payload);
    draftState.current = action.payload;
  },
  [COMPANIES_ACTION_TYPE.ADD_COMPANY]: (draftState, action) => {
    log.info('Add company: ', action.payload);
    const currentList = draftState.list || [];
    draftState.list = [...currentList, action.payload];
    draftState.current = action.payload;
  },
  [COMPANIES_ACTION_TYPE.UPDATE_COMPANY]: (draftState, action) => {
    log.info('Update company: ', action.payload);

    const updatedCompany = action.payload;
    const currentList = draftState.list || [];
    const updatedCompanyIndex = currentList?.findIndex((company) => company.id === updatedCompany.id);
    // The API (v3) do not yet return the charters after patching a company so we copy them manually
    updatedCompany.charters = currentList[updatedCompanyIndex].charters;

    currentList[updatedCompanyIndex] = updatedCompany;
    draftState.list = [...currentList];
    draftState.current = action.payload;
  },
  [COMPANIES_ACTION_TYPE.UPDATE_COMPANY_FROM_SOCKET]: (draftState, action) => {
    log.info('Update company from socket: ', action.payload);

    const socketCompany = action.payload as Company;
    const currentCompany = draftState?.current;

    if (currentCompany && socketCompany.updatedAt > currentCompany?.updatedAt) {
      const currentList = draftState.list || [];
      const updatedCompanyIndex = currentList?.findIndex((company) => company.id === socketCompany.id);
      currentList[updatedCompanyIndex] = socketCompany;

      draftState.list = [...currentList];
      draftState.current = socketCompany;
    }
  },
  [COMPANIES_ACTION_TYPE.SET_COMPANY_STATS]: (draftState, action) => {
    log.info('Set company stats: ', action.payload);
    draftState.stats = action.payload;
  },
  [COMPANIES_ACTION_TYPE.SET_SUBSCRIPTIONS]: (draftState, action) => {
    log.info('Set company subscription: ', action.payload);
    draftState.subscriptions = action.payload;
  },
  [COMPANIES_ACTION_TYPE.SET_FREE_TRIAL_SUBSCRIPTION]: (draftState, action) => {
    log.info('Set company free trial subscription: ', action.payload);
    draftState.freeTrialSubscription = action.payload;
  },
  [COMPANIES_ACTION_TYPE.SET_PAYMENT_METHOD]: (draftState, action) => {
    log.info('Set company payment method: ', action.payload);
    draftState.paymentMethod = action.payload;
  },
  [COMPANIES_ACTION_TYPE.ADD_OR_UPDATE_CHARTER_IN_COMPANY]: (draftState, action) => {
    log.info('Add or update charter in company: ', action.payload);

    const { company: companyToUpdate, charter: charterToInsert } = action.payload;
    const currentList = draftState.list || [];
    // Index of company to update in list
    const updatedCompanyIndex = currentList?.findIndex((company) => company.id === companyToUpdate.id);
    const companyCharters = currentList[updatedCompanyIndex].charters;
    // Index of the charter to insert in the company charters list
    const charterIndexInCompany = companyCharters.findIndex((charter) => charter.id === charterToInsert.id);
    // If the charter was present in company, we update it, else we add it
    if (charterIndexInCompany >= 0) {
      companyCharters[charterIndexInCompany] = charterToInsert;
    } else {
      companyCharters.unshift(charterToInsert);
    }

    companyToUpdate.created = false;
    companyToUpdate.charters = companyCharters;
    currentList[updatedCompanyIndex] = companyToUpdate;

    draftState.list = [...currentList];
  },
  [COMPANIES_ACTION_TYPE.DELETE_CHARTER]: (draftState, action) => {
    const { charterId } = action.payload;
    log.info('Remove charter from list: ', charterId);

    if (draftState.current) {
      const currentCompany: Company = new Company(draftState.current.toJsonModel());
      const charterIndexToDelete = findIndex(currentCompany.charters, { id: charterId });

      if (charterIndexToDelete >= 0) {
        currentCompany.charters.splice(charterIndexToDelete, 1);

        draftState.current = currentCompany;
      }
    }
  },
  [COMPANIES_ACTION_TYPE.SET_COMPANY_BUNDLE]: (draftState, action) => {
    log.info('Set company bundle: ', action.payload);
    draftState.bundle = action.payload;
  },
});
