import classNames from 'classnames';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import ReactPlayer from 'react-player';
import AssetsUtils from '../../../../../../../utils/AssetsUtils';
import { PlayerSize } from '../../../../../../../utils/types/ProjectTypes';
import { ExtendedAsset } from '../hooks/useProjectFinalRenderData';

type Props = {
  introOrOutro: ExtendedAsset;
  currentTime: number;
  audioVolume: number;
  seekTo?: number;
  isPlaying: boolean;
  isVisible: boolean;
  playerSize?: PlayerSize;
  onEndReached: () => void;
  onUpdateCurrentTime: (newTimeInProject: number) => void;
};

type StyleProps = {
  playerSize: PlayerSize;
};

const useStyles = createUseStyles({
  introOrOutroContainer: {
    zIndex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  invisibleIntroOrOutroContainer: {
    zIndex: 0,
    visibility: 'hidden',
  },
  introOrOutroPlayer: ({ playerSize }: StyleProps) => ({
    height: playerSize?.height ?? '100%',
    width: playerSize?.width ?? '100%',
    position: 'relative',
    margin: 'auto',
    background: 'black',
  }),
});

const ProjectFinalRenderIntroOutroPlayer: FunctionComponent<Props> = ({
  introOrOutro,
  currentTime,
  audioVolume,
  seekTo,
  isPlaying,
  isVisible,
  playerSize,
  onEndReached,
  onUpdateCurrentTime,
}: Props) => {
  const videoPlayerRef = useRef<ReactPlayer>(null);

  const classes = useStyles({ playerSize });

  // Whenever the seekTo value changes, we seek to the seekTo value in the video player
  useEffect(() => {
    if (isVisible && videoPlayerRef.current) {
      if (seekTo !== undefined) {
        videoPlayerRef.current?.seekTo(seekTo, 'seconds');
      } else {
        videoPlayerRef.current?.seekTo(0, 'seconds');
      }
    }
  }, [seekTo, isVisible, videoPlayerRef]);

  // Whenever the current time changes in the intro or outro which is visible, we compute the new time in project
  const onCurrentTimeChangeInIntroOrOutro = (newTimeInIntroOrOutro: number) => {
    const newTime = introOrOutro.absoluteStartAt + newTimeInIntroOrOutro;
    if (currentTime < newTime) {
      onUpdateCurrentTime(newTime);
    }
  };

  return (
    <div className={classNames(classes.introOrOutroContainer, !isVisible && classes.invisibleIntroOrOutroContainer)}>
      <div className={classes.introOrOutroPlayer}>
        <ReactPlayer
          url={AssetsUtils.getAssetUrl(introOrOutro)}
          ref={videoPlayerRef}
          playing={isPlaying && isVisible}
          progressInterval={25}
          volume={audioVolume}
          height="inherit"
          width="inherit"
          onProgress={
            isVisible && isPlaying ? ({ playedSeconds }) => onCurrentTimeChangeInIntroOrOutro(playedSeconds) : undefined
          }
          onEnded={isVisible && isPlaying ? onEndReached : undefined}
        />
      </div>
    </div>
  );
};

export default ProjectFinalRenderIntroOutroPlayer;
