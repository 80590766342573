import { WarningOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { THEME } from '../../Constants';

const useStyles = createUseStyles({
  warning: {
    margin: '8px 0',
    backgroundColor: THEME.ALERT.WARNING_BACKGROUND,
    '& .anticon, & .ant-alert-message': {
      color: THEME.ALERT.WARNING_MAIN,
    },
  },
});

type Props = {
  message: string;
};

const WarningAlert: FunctionComponent<Props> = ({ message }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return <Alert className={classes.warning} icon={<WarningOutlined />} message={t(message)} type="warning" showIcon />;
};

export default WarningAlert;
