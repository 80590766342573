import { CloseCircleFilled, EditFilled, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import classNames from 'classnames';
import { find } from 'lodash';
import React, { Ref, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegFileImage } from 'react-icons/fa';
import { MdFeaturedVideo, MdOutlineHideImage, MdPhotoSizeSelectLarge } from 'react-icons/md';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import AccessChecker from '../../../../../../components/access-checker/AccessChecker';
import Preformatted from '../../../../../../components/preformatted/Preformatted';
import useCanAccess from '../../../../../../hooks/useCanAccess';
import { RootState } from '../../../../../../redux/RootState';
import { Asset } from '../../../../../../services/api/types/ChartersServiceTypes';
import AssetsUtils from '../../../../../../utils/AssetsUtils';
import { PermissionList } from '../../../../../../utils/types/CharterPermissionTypes';
import {
  ProjectLogo,
  ProjectLogoDisplayMode,
  ProjectLogoPosition,
  ProjectLogoPositions,
  ProjectLogoSize,
  ProjectLogoSizes,
} from '../../../../../../utils/types/ProjectTypes';
import ProjectLogoSettingsModal from './ProjectLogoSettingsModal';

type Props = {
  value?: ProjectLogo;
  onChange?: (newValue: ProjectLogo | undefined) => void;
  fieldLabel: string;
};

const useStyles = createUseStyles({
  configureVolumeButton: {
    padding: 0,
  },
  fieldValueContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: 6,
  },
  selectedLogoContainer: {
    cursor: 'pointer',
    '& .anticon': {
      marginRight: 4,
    },
  },
  fieldLabelIcon: {
    marginRight: 2,
  },
  fieldValueIcon: {
    marginRight: '0px !important',
  },
  unauthorized: {
    cursor: 'not-allowed',
    '& button': {
      padding: 0,
    },
  },
});

const ProjectLogoSettings = React.forwardRef(({ value, onChange, fieldLabel }: Props, ref: Ref<any>) => {
  const currentCharter = useSelector((state: RootState) => state.charters.current);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const permissionToEditLogo = useCanAccess(PermissionList.LOGO);
  const permissionLogoSize = useCanAccess(PermissionList.LOGO_SIZE);
  const permissionLogoPosition = useCanAccess(PermissionList.LOGO_POSITION);

  const classes = useStyles();
  const { t } = useTranslation();

  // Called to update the upper `form` object
  const triggerChange = (changedValue: ProjectLogo | undefined): void => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  useEffect(() => {
    const { forcedValue } = permissionLogoSize;

    if (
      (!forcedValue && permissionLogoSize.hasUserAccess()) ||
      !currentCharter ||
      !value ||
      (forcedValue && value?.size?.toString() === forcedValue)
    ) {
      return;
    }

    // This effect is here in case the manager decided to change the permissions.
    if (value?.size !== forcedValue && forcedValue && value) {
      const size = find(ProjectLogoSize, (p) => p.toString() === forcedValue) as ProjectLogoSize;
      if (size) {
        const valueToUpdate: ProjectLogo = {
          ...value,
          size,
        };
        triggerChange(valueToUpdate);
      }
    }
  }, [permissionLogoSize]);

  useEffect(() => {
    const { forcedValue } = permissionLogoPosition;

    if (
      (!permissionLogoPosition.forcedValue && permissionLogoPosition.hasUserAccess()) ||
      !currentCharter ||
      !value ||
      (forcedValue && value?.positionCode === forcedValue)
    ) {
      return;
    }

    // This effect is here in case the manager decided to change the permissions.
    if (value?.positionCode !== forcedValue && forcedValue && value) {
      const positionCode = find(ProjectLogoPosition, (p) => p === forcedValue);
      if (positionCode) {
        const valueToUpdate: ProjectLogo = {
          ...value,
          positionCode,
        };
        triggerChange(valueToUpdate);
      }
    }
  }, [permissionLogoPosition]);

  useEffect(() => {
    const { forcedValue } = permissionToEditLogo;

    if (
      (!forcedValue && permissionToEditLogo.hasUserAccess()) ||
      !currentCharter ||
      !value ||
      (forcedValue && value.logoAsset.id === parseInt(forcedValue, 10))
    ) {
      return;
    }

    // This effect is here in case the manager decided to change the permissions.
    if (forcedValue && value && value.logoAsset.id !== parseInt(forcedValue, 10)) {
      const logoAsset = currentCharter.logos.find((logo) => logo.id === parseInt(forcedValue, 10));
      if (logoAsset) {
        const valueToUpdate: ProjectLogo = {
          ...value,
          logoAsset,
        };
        triggerChange(valueToUpdate);
      }
    }
  }, [permissionToEditLogo]);

  const onOpenModal = (): void => {
    if (!permissionToEditLogo.hasUserAccess()) {
      return;
    }
    setIsModalVisible(true);
  };

  const onCloseModal = (): void => {
    setIsModalVisible(false);
  };

  const onSubmit = (logoSettings?: ProjectLogo) => {
    triggerChange(logoSettings);
    onCloseModal();
  };

  const onReset = (): void => {
    if (!permissionToEditLogo.hasUserAccess()) {
      return;
    }

    triggerChange(undefined);
  };

  const renderLogoAsset = (logoAsset?: Asset) => {
    if (!(logoAsset && currentCharter && currentCharter.logos)) {
      return null;
    }

    return (
      <Tooltip title={t('charters.projects.projectEditor.projectFinalization.logo.logoFileLabel')}>
        <span className={classes.fieldValueContainer}>
          <FaRegFileImage className={classes.fieldLabelIcon} />
          <Preformatted>
            {AssetsUtils.computeAssetNameWithPermission(logoAsset, currentCharter.logos, permissionToEditLogo)}
          </Preformatted>
        </span>
      </Tooltip>
    );
  };

  const renderDisplayLogoOnSlide = (displayLogoOnSlide: boolean) => {
    if (displayLogoOnSlide === undefined) {
      return null;
    }

    return (
      <Tooltip title={t('charters.projects.projectEditor.projectFinalization.logo.displayOnSlides')}>
        <span className={classes.fieldValueContainer}>
          <MdOutlineHideImage className={classes.fieldLabelIcon} />
          <Preformatted>
            {displayLogoOnSlide ? (
              <EyeOutlined className={classes.fieldValueIcon} />
            ) : (
              <EyeInvisibleOutlined className={classes.fieldValueIcon} />
            )}
          </Preformatted>
        </span>
      </Tooltip>
    );
  };

  const renderLogoPosition = (logoPosition?: ProjectLogoPosition) => {
    const positionOption = find(ProjectLogoPositions, (p) => p.code === logoPosition);

    if (!positionOption) {
      return null;
    }

    return (
      <Tooltip title={t('charters.projects.projectEditor.projectFinalization.logo.logoPosition')}>
        <span className={classes.fieldValueContainer}>
          <MdFeaturedVideo className={classes.fieldLabelIcon} />
          <Preformatted>{t(positionOption.key)}</Preformatted>
        </span>
      </Tooltip>
    );
  };

  const renderLogoSize = (logoSize?: ProjectLogoSize) => {
    const sizeOption = find(ProjectLogoSizes, (p) => p.code === logoSize);

    if (!sizeOption) {
      return null;
    }

    return (
      <Tooltip title={t('charters.projects.projectEditor.projectFinalization.logo.logoSize')}>
        <span className={classes.fieldValueContainer}>
          <MdPhotoSizeSelectLarge className={classes.fieldLabelIcon} />
          <Preformatted>{t(sizeOption.key)}</Preformatted>
        </span>
      </Tooltip>
    );
  };

  return (
    <div ref={ref}>
      <div onClick={onOpenModal}>
        <AccessChecker
          renderUnauthorizedMessage={permissionToEditLogo.renderUnauthorizedMessage}
          hasAccess={permissionToEditLogo.hasUserAccess()}
        >
          {value ? (
            <span
              className={classNames(
                classes.selectedLogoContainer,
                !permissionToEditLogo.hasUserAccess() && classes.unauthorized
              )}
            >
              {renderLogoAsset(value?.logoAsset)}
              {renderDisplayLogoOnSlide(value?.displayMode === ProjectLogoDisplayMode.ALL_SCENES)}
              {renderLogoPosition(value?.positionCode)}
              {renderLogoSize(value?.size)}
              <Tooltip title={permissionToEditLogo.hasUserAccess() ? t('global.edit') : undefined}>
                <EditFilled hidden={!permissionToEditLogo.hasUserAccess()} />
              </Tooltip>
              <Tooltip title={permissionToEditLogo.hasUserAccess() ? t('global.reset') : undefined}>
                <CloseCircleFilled
                  hidden={!permissionToEditLogo.hasUserAccess()}
                  onClick={(e): void => {
                    e.stopPropagation();
                    onReset();
                  }}
                />
              </Tooltip>
            </span>
          ) : (
            <Button
              type="link"
              className={classNames(
                classes.configureVolumeButton,
                !permissionToEditLogo.hasUserAccess() && classes.unauthorized
              )}
              disabled={!permissionToEditLogo.hasUserAccess()}
            >
              {t('charters.projects.projectEditor.projectFinalization.logo.editButton')}
            </Button>
          )}
        </AccessChecker>
      </div>

      <ProjectLogoSettingsModal
        value={value}
        isVisible={isModalVisible}
        fieldLabel={fieldLabel}
        onCancel={onCloseModal}
        onSubmit={onSubmit}
      />
    </div>
  );
});

export default ProjectLogoSettings;
