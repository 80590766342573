import {
  BarChartOutlined,
  BgColorsOutlined,
  CrownOutlined,
  HomeOutlined,
  NotificationOutlined,
  PlusCircleOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons/lib';
import { CompanyBundleFeatureList, LINK } from '../../Constants';
import { Roles } from '../../core/rule/RolesTypes';
import Admin from '../../pages/admin/Admin';
import BundlesEdition from '../../pages/admin/bundles/BundlesEdition';
import BundlesManagement from '../../pages/admin/bundles/BundlesManagement';
import AdminChartersTable from '../../pages/admin/charters/AdminChartersTable';
import TranslationManagement from '../../pages/admin/translation/TranslationManagement';
import CompanyAnalytics from '../../pages/analytics/CompanyAnalytics';
import CharterCreation from '../../pages/charters/charter-creation-or-update/CharterCreation';
import CharterUpdate from '../../pages/charters/charter-creation-or-update/CharterUpdate';
import CharterIntros from '../../pages/charters/charter-graphical-elements/charter-assets/CharterIntros';
import CharterLogos from '../../pages/charters/charter-graphical-elements/charter-assets/CharterLogos';
import CharterMusics from '../../pages/charters/charter-graphical-elements/charter-assets/CharterMusics';
import CharterOutros from '../../pages/charters/charter-graphical-elements/charter-assets/CharterOutros';
import CharterFonts from '../../pages/charters/charter-graphical-elements/charter-fonts/CharterFonts';
import CharterMediaLibrary from '../../pages/charters/charter-graphical-elements/charter-media-library/CharterMediaLibrary';
import CharterGraphicalElements from '../../pages/charters/charter-graphical-elements/CharterGraphicalElements';
import CharterHome from '../../pages/charters/charter-home/CharterHome';
import CharterPermissions from '../../pages/charters/charter-permissions/CharterPermissions';
import CharterProjects from '../../pages/charters/charter-projects/CharterProjects';
import ProjectEditor from '../../pages/charters/charter-projects/components/project-editor/ProjectEditor';
import CharterScenarios from '../../pages/charters/charter-scenarios/CharterScenarios';
import ScenarioVariantEditor from '../../pages/charters/charter-scenarios/components/ScenarioVariantEditor';
import CharterUsers from '../../pages/charters/charter-users/CharterUsers';
import ChartersList from '../../pages/charters/ChartersList';
import CompanySettings from '../../pages/company/CompanySettings';
import CreateCompany from '../../pages/company/CreateCompany';
import Home from '../../pages/home/Home';
import NotificationSettings from '../../pages/notifications/NotificationSettings';
import Users from '../../pages/users/Users';
import { NavigationMenuItems } from './NavigationMenuItem';

export const NavigationConfig: NavigationMenuItems = [
  {
    key: LINK.HOME.key,
    icon: HomeOutlined,
    title: LINK.HOME.title,
    path: LINK.HOME.path,
    exact: true,
    component: Home,
    isInMenu: true,
    isDefaultRoute: true,
    isPoc: {
      accessible: true,
      inGroupNeeded: false,
    },
    roleRestriction: [Roles.Owner, Roles.Admin, Roles.Creator],
  },
  {
    key: LINK.USERS.key,
    icon: UserOutlined,
    title: LINK.USERS.title,
    path: LINK.USERS.path,
    exact: true,
    component: Users,
    isInMenu: true,
    knlTeamOnly: true,
  },
  {
    key: LINK.CHARTERS.key,
    path: LINK.CHARTERS.path,
    title: LINK.CHARTERS.title,
    className: 'charters-link',
    exact: true,
    icon: BgColorsOutlined,
    component: ChartersList,
    isInMenu: true,
    asyncChildren: true,
    chartersRoot: true,
    isPoc: {
      accessible: true,
      inGroupNeeded: false,
    },
    roleRestriction: [Roles.Owner, Roles.Admin, Roles.Creator],
    children: [
      {
        key: LINK.CHARTERS_CREATION.key,
        path: LINK.CHARTERS_CREATION.path,
        title: LINK.CHARTERS_CREATION.title,
        icon: PlusCircleOutlined,
        exact: true,
        component: CharterCreation,
        isInMenu: false,
        associatedMenuKey: 'charters',
        roleRestriction: [Roles.Owner, Roles.Admin],
      },
      {
        key: LINK.CHARTER.key,
        path: LINK.CHARTER.path,
        title: LINK.CHARTER.title,
        exact: true,
        component: CharterHome,
        isInMenu: false,
        associatedMenuKey: 'charters',
        isPoc: {
          accessible: true,
          inGroupNeeded: false,
        },
        roleRestriction: [Roles.Owner, Roles.Admin, Roles.Creator],
      },
      {
        key: LINK.CHARTER_USERS.key,
        path: LINK.CHARTER_USERS.path,
        title: LINK.CHARTER_USERS.title,
        exact: true,
        component: CharterUsers,
        isInMenu: true,
        associatedMenuKey: 'charters',
        isPoc: {
          accessible: true,
          inGroupNeeded: false,
        },
        roleRestriction: [Roles.Owner, Roles.Admin],
      },
      {
        key: LINK.CHARTER_UPDATE.key,
        path: LINK.CHARTER_UPDATE.path,
        title: `${LINK.CHARTER_UPDATE.title}`,
        exact: true,
        component: CharterUpdate,
        isInMenu: true,
        associatedMenuKey: 'charters',
        isPoc: {
          accessible: true,
          inGroupNeeded: true,
        },
        roleRestriction: [Roles.Owner, Roles.Admin],
      },
      {
        key: LINK.CHARTER_GRAPHICAL_ELEMENTS.key,
        path: LINK.CHARTER_GRAPHICAL_ELEMENTS.path,
        title: LINK.CHARTER_GRAPHICAL_ELEMENTS.title,
        exact: true,
        component: CharterGraphicalElements,
        isInMenu: true,
        associatedMenuKey: 'charters',
        isPoc: {
          accessible: true,
          inGroupNeeded: true,
        },
        roleRestriction: [Roles.Owner, Roles.Admin],
        children: [
          {
            key: LINK.ASSETS_LOGOS.key,
            path: LINK.ASSETS_LOGOS.path,
            title: LINK.ASSETS_LOGOS.title,
            exact: true,
            component: CharterLogos,
            isInMenu: true,
            isPoc: {
              accessible: true,
              inGroupNeeded: true,
            },
            associatedMenuKey: 'charters',
          },
          {
            key: LINK.ASSETS_INTROS.key,
            path: LINK.ASSETS_INTROS.path,
            title: LINK.ASSETS_INTROS.title,
            exact: true,
            component: CharterIntros,
            isInMenu: true,
            isPoc: {
              accessible: true,
              inGroupNeeded: true,
            },
            associatedMenuKey: 'charters',
          },
          {
            key: LINK.ASSETS_OUTROS.key,
            path: LINK.ASSETS_OUTROS.path,
            title: LINK.ASSETS_OUTROS.title,
            exact: true,
            component: CharterOutros,
            isInMenu: true,
            isPoc: {
              accessible: true,
              inGroupNeeded: true,
            },
            associatedMenuKey: 'charters',
          },
          {
            key: LINK.ASSETS_MUSICS.key,
            path: LINK.ASSETS_MUSICS.path,
            title: LINK.ASSETS_MUSICS.title,
            exact: true,
            component: CharterMusics,
            isInMenu: true,
            isPoc: {
              accessible: true,
              inGroupNeeded: true,
            },
            associatedMenuKey: 'charters',
          },
          {
            key: LINK.CHARTER_FONTS.key,
            path: LINK.CHARTER_FONTS.path,
            title: LINK.CHARTER_FONTS.title,
            exact: true,
            component: CharterFonts,
            isInMenu: true,
            isPoc: {
              accessible: true,
              inGroupNeeded: true,
            },
            associatedMenuKey: 'charters',
          },
          {
            key: LINK.CHARTER_MEDIA_LIBRARY.key,
            path: LINK.CHARTER_MEDIA_LIBRARY.path,
            title: LINK.CHARTER_MEDIA_LIBRARY.title,
            exact: true,
            component: CharterMediaLibrary,
            isInMenu: true,
            isPoc: {
              accessible: true,
              inGroupNeeded: true,
            },
            associatedMenuKey: 'charters',
          },
        ],
      },
      {
        key: LINK.CHARTER_PERMISSIONS.key,
        path: LINK.CHARTER_PERMISSIONS.path,
        title: LINK.CHARTER_PERMISSIONS.title,
        exact: true,
        component: CharterPermissions,
        isInMenu: true,
        associatedMenuKey: 'charters',
        isPoc: {
          accessible: true,
          inGroupNeeded: true,
        },
        roleRestriction: [Roles.Owner, Roles.Admin],
      },
      {
        key: LINK.CHARTER_SCENARIOS.key,
        path: LINK.CHARTER_SCENARIOS.path,
        title: LINK.CHARTER_SCENARIOS.title,
        exact: true,
        component: CharterScenarios,
        isInMenu: true,
        associatedMenuKey: 'charters',
        associatedPaths: LINK.CHARTER_SCENARIOS.associatedPaths,
        isPoc: {
          accessible: true,
          inGroupNeeded: true,
        },
        roleRestriction: [Roles.Owner, Roles.Admin],
      },
      {
        key: LINK.CHARTER_SCENARIO_VARIANT_EDITOR.key,
        path: LINK.CHARTER_SCENARIO_VARIANT_EDITOR.path,
        title: LINK.CHARTER_SCENARIO_VARIANT_EDITOR.title,
        exact: true,
        component: ScenarioVariantEditor,
        isInMenu: false,
        isPoc: {
          accessible: true,
          inGroupNeeded: true,
        },
        roleRestriction: [Roles.Owner, Roles.Admin],
      },
      {
        key: LINK.CHARTER_PROJECTS.key,
        path: LINK.CHARTER_PROJECTS.path,
        title: LINK.CHARTER_PROJECTS.title,
        exact: true,
        component: CharterProjects,
        isInMenu: true,
        isPoc: {
          accessible: true,
          inGroupNeeded: true,
        },
        associatedMenuKey: 'charters',
        associatedPaths: LINK.CHARTER_PROJECTS.associatedPaths,
        roleRestriction: [Roles.Creator, Roles.Owner, Roles.Admin],
      },
      {
        key: LINK.CHARTER_PROJECT_EDITOR.key,
        path: LINK.CHARTER_PROJECT_EDITOR.path,
        title: LINK.CHARTER_PROJECT_EDITOR.title,
        exact: true,
        component: ProjectEditor,
        knlTeamOnly: false,
        isInMenu: false,
        roleRestriction: [Roles.Creator, Roles.Owner, Roles.Admin],
      },
    ],
  },
  {
    key: LINK.COMPANY_ANALYTICS.key,
    path: LINK.COMPANY_ANALYTICS.path,
    title: LINK.COMPANY_ANALYTICS.title,
    icon: BarChartOutlined,
    exact: true,
    component: CompanyAnalytics,
    isInMenu: true,
    isPoc: {
      accessible: true,
      inGroupNeeded: false,
    },
    bundleRestrictions: [CompanyBundleFeatureList.ACCESS_TO_ANALYTICS],
    roleRestriction: [Roles.Owner, Roles.Admin],
  },
  {
    key: '/company/settings',
    path: '/company/settings',
    className: 'company-settings-link',
    title: 'menu.companySettings',
    icon: SettingOutlined,
    exact: true,
    component: CompanySettings,
    isInMenu: true,
    isPoc: {
      accessible: true,
      inGroupNeeded: false,
    },
    roleRestriction: [Roles.Owner, Roles.Admin],
  },
  // Disabled for now - SELFCHECKOUT-TAG
  // {
  //   key: '/company',
  //   path: '#nolink',
  //   title: 'menu.companySettings',
  //   className: 'company-settings-link',
  //   isNotLink: true,
  //   icon: SettingOutlined,
  //   exact: true,
  //   isInMenu: true,
  //   children: [
  //     {
  //       key: '/company/settings',
  //       path: '/company/settings',
  //       title: 'menu.companySettings',
  //       icon: SettingOutlined,
  //       exact: true,
  //       component: CompanySettings,
  //       isInMenu: true,
  //     },
  //     {
  //       key: LINK.COMPANY_CURRENT_PLAN.key,
  //       path: LINK.COMPANY_CURRENT_PLAN.path,
  //       title: LINK.COMPANY_CURRENT_PLAN.title,
  //       component: MyPlan,
  //       isInMenu: true,
  //       needsChargebeeSubscription: true,
  //       roleRestriction: [Roles.Owner],
  //       accessErrorMessage: 'menu.disabledPlanLink',
  //     },
  //     {
  //       key: LINK.COMPANY_BUNDLES.key,
  //       path: LINK.COMPANY_BUNDLES.path,
  //       title: LINK.COMPANY_BUNDLES.title,
  //       component: Bundles,
  //       isInMenu: true,
  //       needsChargebeeSubscription: false,
  //       accessErrorMessage: 'menu.disabledPlanLink',
  //       roleRestriction: [Roles.Owner],
  //     },
  //   ],
  // },
  {
    key: '/company/settings/notifications',
    path: '/company/settings/notifications',
    title: 'menu.notificationConfigs',
    icon: NotificationOutlined,
    exact: true,
    component: NotificationSettings,
    isInMenu: true,
    knlTeamOnly: true,
  },
  {
    key: '/create-company',
    path: '/create-company',
    exact: true,
    component: CreateCompany,
    isInMenu: false,
    knlTeamOnly: true,
  },
  {
    key: LINK.ADMIN.key,
    path: LINK.ADMIN.path,
    title: LINK.ADMIN.title,
    icon: CrownOutlined,
    exact: true,
    component: Admin,
    isInMenu: true,
    knlTeamOnly: true,
    children: [
      {
        key: LINK.ADMIN_TRANSLATION.key,
        path: LINK.ADMIN_TRANSLATION.path,
        title: LINK.ADMIN_TRANSLATION.title,
        component: TranslationManagement,
        isInMenu: true,
        knlTeamOnly: true,
      },
      {
        key: LINK.ADMIN_BUNDLES.key,
        path: LINK.ADMIN_BUNDLES.path,
        title: LINK.ADMIN_BUNDLES.title,
        component: BundlesManagement,
        isInMenu: true,
        knlTeamOnly: true,
      },
      {
        key: LINK.ADMIN_CHARTERS.key,
        path: LINK.ADMIN_CHARTERS.path,
        title: LINK.ADMIN_CHARTERS.title,
        component: AdminChartersTable,
        isInMenu: true,
        knlTeamOnly: true,
      },
      {
        key: LINK.ADMIN_BUNDLES_EDIT.key,
        path: LINK.ADMIN_BUNDLES_EDIT.path,
        component: BundlesEdition,
        knlTeamOnly: true,
        exact: true,
        isInMenu: false,
      },
    ],
  },
];
