import { UserOutlined } from '@ant-design/icons/lib';
import { Card, Col, Row, Statistic } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { THEME } from '../../../Constants';
import { RootState } from '../../../redux/RootState';
import useStyles from './StatisticsStyle';

type Props = {
  statistics: {
    total: number;
    active: number;
  };
  maxNbLicenses?: number;
};

const UsersStatistics: FunctionComponent<Props> = ({ statistics, maxNbLicenses }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobileOrTablet = useSelector((state: RootState) => {
    return state.app.isMobileOrTablet;
  });

  const unlimited = t('home.statistics.unlimited');

  const totalAllowed = maxNbLicenses || unlimited;
  const inactiveUsers = statistics.total - statistics.active;
  const activeUsersColor =
    statistics.active >= statistics.total && totalAllowed !== unlimited
      ? THEME.HOME.STATISTICS.INVALID_COLOR
      : THEME.HOME.STATISTICS.VALID_COLOR;

  if (!statistics) {
    return null;
  }

  return (
    <Col xs={24} sm={24} md={24} lg={10}>
      <Row gutter={0} className={classNames(classes.fullHeight, classes.row)}>
        <Col className={classes.column} span={24}>
          <Card className={classNames(classes.fullHeight, classes.card)}>
            <Statistic
              title={t('home.statistics.users.totalAllowed')}
              value={totalAllowed}
              precision={0}
              valueStyle={{
                color: THEME.HOME.STATISTICS.DEFAULT_COLOR,
              }}
              prefix={<UserOutlined />}
              className={isMobileOrTablet ? classes.smallStatisticsValue : ''}
            />
          </Card>
        </Col>
        <Col className={classNames(classes.column, classes.sideBySideColumns)} span={24}>
          <Col className={classes.column} span={12}>
            <Card className={classNames(classes.fullHeight, classes.card)}>
              <Statistic
                title={t('home.statistics.users.active')}
                value={statistics.active}
                precision={0}
                valueStyle={{ color: activeUsersColor }}
                prefix={<UserOutlined />}
                className={isMobileOrTablet ? classes.smallStatisticsValue : ''}
              />
            </Card>
          </Col>
          <Col className={classes.column} span={12}>
            <Card className={classNames(classes.fullHeight, classes.card)}>
              <Statistic
                title={t('home.statistics.users.inactive')}
                value={inactiveUsers}
                precision={0}
                valueStyle={{
                  color: THEME.HOME.STATISTICS.DEFAULT_COLOR,
                }}
                prefix={<UserOutlined />}
                className={isMobileOrTablet ? classes.smallStatisticsValue : ''}
              />
            </Card>
          </Col>
        </Col>
      </Row>
    </Col>
  );
};

export default UsersStatistics;
