import { Slider } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { BsPauseFill, BsPlayFill } from 'react-icons/bs';
import { HiVolumeOff, HiVolumeUp } from 'react-icons/hi';
import { createUseStyles } from 'react-jss';
import TimeUtils from '../../../../utils/TimeUtils';

type Props = {
  displayControls: boolean;
  isLoading?: boolean;
  isPlaying: boolean;
  currentTime: number;
  scenarioDuration: number;
  volume: number;
  onPlay: () => void;
  onPause: () => void;
  onSeekBarChange: () => void;
  onSeekBarReleased: () => void;
  handleVolumeChange: (volume: number) => void;
};

type StyleProps = {
  displayControls: boolean;
};

const useStyles = createUseStyles({
  controlsContainer: ({ displayControls }: StyleProps) => ({
    padding: 10,
    height: 30,
    zIndex: 3,
    background: '#00000070',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    opacity: displayControls ? 1 : 0,
    transition: 'opacity 0.4s',
    '& > *:not(:last-child)': {
      marginRight: 15,
    },
  }),
  controlButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: 20,
  },
  disabledPlayPauseButton: {
    opacity: 0.7,
    cursor: 'not-allowed',
  },
  seekBar: {
    width: '100%',
  },
  timeValue: {
    color: 'white',
  },
});

const ScenarioFinalRenderPlayerControls: FunctionComponent<Props> = ({
  displayControls,
  isLoading = false,
  isPlaying,
  currentTime,
  scenarioDuration,
  onPlay,
  onPause,
  onSeekBarChange,
  onSeekBarReleased,
  handleVolumeChange,
  volume,
}: Props) => {
  const classes = useStyles({ displayControls });

  const handleVolumeClick = () => {
    const newVolume = volume === 0 ? 1 : 0;
    handleVolumeChange(newVolume);
  };

  return (
    <div className={classes.controlsContainer}>
      {/* Play/pause button */}
      {isPlaying ? (
        <BsPauseFill
          className={classNames(classes.controlButton, isLoading && classes.disabledPlayPauseButton)}
          onClick={!isLoading ? onPause : undefined}
        />
      ) : (
        <BsPlayFill
          className={classNames(classes.controlButton, isLoading && classes.disabledPlayPauseButton)}
          onClick={!isLoading ? onPlay : undefined}
        />
      )}

      {volume === 0 ? (
        <HiVolumeOff
          className={classNames(classes.controlButton, isLoading && classes.disabledPlayPauseButton)}
          onClick={handleVolumeClick}
        />
      ) : (
        <HiVolumeUp
          className={classNames(classes.controlButton, isLoading && classes.disabledPlayPauseButton)}
          onClick={handleVolumeClick}
        />
      )}
      {/* Current time value */}
      <div className={classes.timeValue}>{TimeUtils.formatSecondsIntoDuration(currentTime)}</div>

      {/* Seek bar */}
      <Slider
        className={classes.seekBar}
        min={0}
        max={1}
        step={0.005}
        value={currentTime / scenarioDuration}
        tipFormatter={null}
        onChange={onSeekBarChange}
        onAfterChange={onSeekBarReleased}
      />

      {/* Total scenario duration */}
      <div className={classes.timeValue}>{TimeUtils.formatSecondsIntoDuration(scenarioDuration)}</div>
    </div>
  );
};

export default ScenarioFinalRenderPlayerControls;
