import { PlusSquareOutlined } from '@ant-design/icons';
import { Empty, Select } from 'antd';
import Title from 'antd/lib/typography/Title';
import classNames from 'classnames';
import Fuse from 'fuse.js';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { DEVICE_SIZES_QUERIES, LINK, LOGGING_EVENT } from '../../Constants';
import useCanAccess from '../../hooks/useCanAccess';
import { setChartersList } from '../../redux/action/ChartersAction';
import { selectCompany, setCompanyBundle } from '../../redux/action/CompaniesAction';
import { RootState } from '../../redux/RootState';
import { getCompanyBundleById } from '../../services/api/CompaniesService';
import BundlesUtils from '../../utils/BundlesUtils';
import CompaniesUtils from '../../utils/CompaniesUtils';
import { PermissionList } from '../../utils/types/CharterPermissionTypes';
import AccessChecker from '../access-checker/AccessChecker';

const { Option } = Select;

const useStyles = createUseStyles({
  container: {
    margin: '0 16px',
  },
  companySelector: {
    width: 200,
    maxWidth: '40%',
  },
  dropdown: {
    width: '230px !important',
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      width: '200px !important',
      '& .ant-select-item-option': {
        fontSize: 13,
      },
    },
    padding: 0,
    '& .ant-select-item': {
      padding: '5px 8px',
    },
    '& div[class]:not([role=listbox])': {
      '&::-webkit-scrollbar': {
        '-webkit-appearance': 'none',
        width: '9px',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '6px',
        backgroundColor: 'rgba(0, 0, 0, .5)',
        boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
      },
    },
  },
  title: {
    display: 'inherit',
  },
  newCompanyOption: {
    borderBottom: '1px solid #F0F0F0F0',
  },
  emptyImage: {
    color: '#aeb8c2',
    '& .ant-empty-image': {
      height: 50,
    },
  },
});

const KannelleHeaderCompanySelect: FunctionComponent = () => {
  const classes = useStyles();
  const companies = useSelector((state: RootState) => state.companies.list);
  const current = useSelector((state: RootState) => state.companies.current);
  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);

  const { renderUnauthorizedWebMessage, hasUserAccessToCompany } = useCanAccess(PermissionList.WEB_DASHBOARD);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [query, setQuery] = useState<string>();
  const initialSortedCompanies = useMemo(() => {
    return companies ? companies.slice().sort(CompaniesUtils.sortCompaniesByName) : [];
  }, [companies]);
  const [sortedCompanies, setSortedCompanies] = useState(initialSortedCompanies);

  const fuse = useMemo(() => {
    return new Fuse(companies || [], {
      keys: ['name'],
      includeScore: true,
      threshold: 0.4,
    });
  }, [companies]);

  useEffect(() => {
    if (!query || query === '') {
      setSortedCompanies(initialSortedCompanies);
      return;
    }

    const result = fuse.search(query);

    const companyFuse = result.map((res) => res.item);
    setSortedCompanies(companyFuse);
  }, [query, initialSortedCompanies, fuse]);

  if (!companies || !current) {
    return null;
  }

  const isUserKnlTeamInCharter = CompaniesUtils.checkIsKnlProfile(companies);

  const handleChange = (value: number | string, option: any): void => {
    if (value === 'new-company' || value === 'empty') {
      return;
    }

    loggingManager.logEvent(LOGGING_EVENT.CHANGE_CURRENT_COMPANY, {
      toCompanyId: option.value,
      companyId: current.id,
    });

    dispatch(selectCompany(option.param));
    dispatch(setChartersList(option.param.charters));

    history.push(LINK.HOME.path);

    getCompanyBundleById(option.value).then((bundleResponse) => {
      dispatch(
        setCompanyBundle(bundleResponse.data ? BundlesUtils.formatApiBundlesToStore(bundleResponse.data) : undefined)
      );
    });
  };

  const isSearchEmpty = sortedCompanies.length === 0 && query && query !== '';
  const onSearch = (value: string): void => {
    setQuery(value);
  };

  return (
    <span className={classes.container}>
      {initialSortedCompanies.length > 1 || isUserKnlTeamInCharter ? (
        <Select
          showSearch
          placeholder={t('header.company.placeholder')}
          optionFilterProp="children"
          size="middle"
          filterOption={false}
          onSearch={onSearch}
          className={classNames(classes.companySelector, 'companySelector')}
          value={current.id}
          onChange={handleChange}
          dropdownClassName={classes.dropdown}
        >
          {isUserKnlTeamInCharter ? (
            <Option value="new-company" className={classes.newCompanyOption}>
              <Link to="/create-company">
                {!isMobileOrTablet && <PlusSquareOutlined />} {t('header.company.create')}
              </Link>
            </Option>
          ) : null}
          {isSearchEmpty && (
            <Option value="empty" disabled>
              <Empty className={classes.emptyImage} description={t('global.nodata')} />
            </Option>
          )}
          {sortedCompanies.map((company) => {
            return (
              <Option
                key={`header_company_${company.id}`}
                value={company.id}
                param={company}
                disabled={!hasUserAccessToCompany(company.id)}
              >
                <AccessChecker
                  renderUnauthorizedMessage={renderUnauthorizedWebMessage}
                  hasAccess={hasUserAccessToCompany(company.id)}
                  placement="right"
                >
                  {company.name}
                </AccessChecker>
              </Option>
            );
          })}
        </Select>
      ) : (
        <Title level={4} className={classes.title}>
          {current.name}
        </Title>
      )}
    </span>
  );
};

export default KannelleHeaderCompanySelect;
