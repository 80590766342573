import { Menu } from 'antd';
import { find, map } from 'lodash';
import React, { FunctionComponent, ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LANGUAGE } from '../../Constants';
import { updateLanguage } from '../../redux/action/AppAction';
import { RootState } from '../../redux/RootState';
import logoKnl from '../../resources/img/K-rectangle.png';

const { SubMenu } = Menu;

type Props = {
  children: JSX.Element[] | JSX.Element | ReactNode | string;
};

const useStyles = createUseStyles({
  publicPageContent: ({ isMobileOrTablet }) => ({
    overflowX: isMobileOrTablet ? 'hidden' : 'inherit',
    paddingTop: 16,
  }),
  topContainer: ({ isMobileOrTablet }) => ({
    padding: isMobileOrTablet ? 16 : 32,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    zIndex: 3,
    position: 'relative',
    '& .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu': {
      margin: 0,
      width: 'auto',
    },
  }),
  logo: {
    maxWidth: 200,
  },
  wave: {
    position: 'fixed',
    width: '100%',
    padding: 0,
    margin: 0,
    bottom: 0,
    left: 0,
    lineHeight: 0,
    zIndex: 1,
  },

  leftRadius: {
    border: '0 !important',
    borderRadius: '25px !important',
  },
  rightRadius: {
    border: 0,
    borderRadius: '25px !important',
  },
  menu: {
    border: 'unset',
    background: 'inherit',
  },
  subMenu: {
    padding: '0 8px',
    width: 160,
  },
  icon: {
    marginRight: 8,
  },
  childrenContainer: {
    zIndex: 2,
    position: 'relative',
  },
});

const PublicPageShell: FunctionComponent<Props> = ({ children }: Props) => {
  const currentAppLanguage = useSelector((state: RootState) => state.app.language);
  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);

  const { t } = useTranslation();
  const classes = useStyles({ isMobileOrTablet });
  const dispatch = useDispatch();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const queryLang = query.get('language');

  const [language, setLanguage] = useState(queryLang || currentAppLanguage);

  const languageObj = useMemo(() => find(LANGUAGE, { code: language }), [language]);

  useEffect(() => {
    if ((!queryLang || queryLang === language) && currentAppLanguage === language) {
      return;
    }

    dispatch(updateLanguage(language));
  }, []);

  const updateLang = (lang: string): void => {
    setLanguage(lang);
    dispatch(updateLanguage(lang));
  };

  return (
    <>
      <div className={classes.publicPageContent}>
        <div className={classes.topContainer}>
          <div>
            <img className={classes.logo} src={logoKnl} alt="logo" />
          </div>
          <div>
            <Menu selectable={false} mode="horizontal" className={classes.menu}>
              <SubMenu
                key="subLanguage"
                className={classes.subMenu}
                icon={<span className={classes.icon}>{languageObj?.flag}</span>}
                title={t(languageObj?.key || '')}
              >
                {map(LANGUAGE, ({ key, code, flag }) => {
                  return (
                    <Menu.Item key={`language_${code}`} onClick={(): void => updateLang(code)}>
                      <span className={classes.icon}>{flag}</span>
                      {t(key)}
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            </Menu>
          </div>
        </div>

        <div className={classes.childrenContainer}>{children}</div>

        <div className={classes.wave}>
          <svg
            id="visual"
            viewBox="0 0 1920 1080"
            width="1920"
            height="1080"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
          >
            <rect x="0" y="0" width="1920" height="1080" fill="#FBFAFF" />
            <path
              d="M0 659L45.7 645.7C91.3 632.3 182.7 605.7 274.2 618.2C365.7 630.7 457.3 682.3 548.8 707.7C640.3 733 731.7 732 823 695.8C914.3 659.7 1005.7 588.3 1097 583.5C1188.3 578.7 1279.7 640.3 1371.2 667C1462.7 693.7 1554.3 685.3 1645.8 659.7C1737.3 634 1828.7 591 1874.3 569.5L1920 548L1920 1081L1874.3 1081C1828.7 1081 1737.3 1081 1645.8 1081C1554.3 1081 1462.7 1081 1371.2 1081C1279.7 1081 1188.3 1081 1097 1081C1005.7 1081 914.3 1081 823 1081C731.7 1081 640.3 1081 548.8 1081C457.3 1081 365.7 1081 274.2 1081C182.7 1081 91.3 1081 45.7 1081L0 1081Z"
              fill="#f2f6ff"
            />
            <path
              d="M0 692L45.7 687.2C91.3 682.3 182.7 672.7 274.2 663.3C365.7 654 457.3 645 548.8 646.5C640.3 648 731.7 660 823 689.3C914.3 718.7 1005.7 765.3 1097 764.7C1188.3 764 1279.7 716 1371.2 697.5C1462.7 679 1554.3 690 1645.8 694C1737.3 698 1828.7 695 1874.3 693.5L1920 692L1920 1081L1874.3 1081C1828.7 1081 1737.3 1081 1645.8 1081C1554.3 1081 1462.7 1081 1371.2 1081C1279.7 1081 1188.3 1081 1097 1081C1005.7 1081 914.3 1081 823 1081C731.7 1081 640.3 1081 548.8 1081C457.3 1081 365.7 1081 274.2 1081C182.7 1081 91.3 1081 45.7 1081L0 1081Z"
              fill="#e0e9ff"
            />
            <path
              d="M0 961L45.7 953.3C91.3 945.7 182.7 930.3 274.2 928C365.7 925.7 457.3 936.3 548.8 938.7C640.3 941 731.7 935 823 919C914.3 903 1005.7 877 1097 861.8C1188.3 846.7 1279.7 842.3 1371.2 847.5C1462.7 852.7 1554.3 867.3 1645.8 885.8C1737.3 904.3 1828.7 926.7 1874.3 937.8L1920 949L1920 1081L1874.3 1081C1828.7 1081 1737.3 1081 1645.8 1081C1554.3 1081 1462.7 1081 1371.2 1081C1279.7 1081 1188.3 1081 1097 1081C1005.7 1081 914.3 1081 823 1081C731.7 1081 640.3 1081 548.8 1081C457.3 1081 365.7 1081 274.2 1081C182.7 1081 91.3 1081 45.7 1081L0 1081Z"
              fill="#8fadff"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default PublicPageShell;
