import { InfoCircleOutlined, StopOutlined } from '@ant-design/icons';
import { Col, Row, Tag } from 'antd';
import Tooltip from 'antd/es/tooltip';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { THEME } from '../../../Constants';
import { Role } from '../../../core/rule/Roles';
import { Roles } from '../../../core/rule/RolesTypes';

const useStyles = createUseStyles({
  icon: {
    marginLeft: 8,
  },
  help: {
    color: '#333',
    padding: 4,
    marginTop: 4,
    marginBottom: 4,
  },
  roundTag: {
    borderRadius: 25,
  },
  inactiveTag: {
    color: THEME.CHARTERS.USERS.INACTIVE.TEXT_COLOR,
    margin: 4,
  },
  disabledIcon: {
    marginRight: 4,
  },
});

const CharterCellHelp: FunctionComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  // Todo to remove when open to other users
  const currentUserRole = new Role(Roles.KnlTeam);

  const help = (
    <>
      <p className={classes.help}>{t('users.helpChartersColor')}</p>
      <Row gutter={[4, 4]}>
        {currentUserRole.getAccessibleRoles().map((role) => {
          return (
            <Col key={`tag_role_help_${role.code}`} span={12}>
              <Tag
                className={classes.roundTag}
                color={THEME.CHARTERS.ROLES[role.code].BACKGROUND_COLOR}
                style={{ width: 100, margin: 4, color: THEME.CHARTERS.ROLES[role.code].TEXT_COLOR }}
              >
                {t(role.key)}
              </Tag>
            </Col>
          );
        })}
      </Row>

      <p className={classes.help}>{t('users.helpChartersColor2')}</p>
      <Tag
        className={classNames(classes.roundTag, classes.inactiveTag)}
        color={THEME.CHARTERS.USERS.INACTIVE.BACKGROUND_COLOR}
      >
        <StopOutlined className={classes.disabledIcon} /> {t('users.helpChartersName')}
      </Tag>
    </>
  );
  return (
    <Tooltip title={help} color="#ffffff" placement="right">
      <InfoCircleOutlined className={classes.icon} />
    </Tooltip>
  );
};

export default CharterCellHelp;
