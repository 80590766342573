import { find } from 'lodash';
import { CHARGEBEE_SUBSCRIPTION_STATUS } from '../Constants';
import { APISubscription, ChargebeeSubscriptionObject } from '../services/api/types/ChargebeeServiceTypes';

export default class ChargebeeUtils {
  static getMostRelevantSubscriptionForHeaderDisplay = (
    subscriptions?: ChargebeeSubscriptionObject[]
  ): APISubscription | undefined => {
    let subscriptionInfoToDisplay: APISubscription | undefined;
    subscriptions?.forEach(({ subscription }) => {
      if (!subscription) {
        return;
      }

      if (subscription.status === CHARGEBEE_SUBSCRIPTION_STATUS.IN_TRIAL) {
        if (!subscriptionInfoToDisplay) {
          subscriptionInfoToDisplay = subscription;
        } else if (
          subscriptionInfoToDisplay.status === CHARGEBEE_SUBSCRIPTION_STATUS.CANCELLED ||
          subscriptionInfoToDisplay.status === CHARGEBEE_SUBSCRIPTION_STATUS.PAUSED
        ) {
          subscriptionInfoToDisplay = subscription;
        } else if (
          subscriptionInfoToDisplay?.trial_end &&
          subscription?.trial_end &&
          subscriptionInfoToDisplay?.trial_end < subscription?.trial_end
        ) {
          subscriptionInfoToDisplay = subscription;
        }
      }

      if (subscription.status === CHARGEBEE_SUBSCRIPTION_STATUS.ACTIVE) {
        if (!subscriptionInfoToDisplay) {
          subscriptionInfoToDisplay = subscription;
        } else if (
          subscriptionInfoToDisplay &&
          subscriptionInfoToDisplay.status !== CHARGEBEE_SUBSCRIPTION_STATUS.IN_TRIAL
        ) {
          subscriptionInfoToDisplay = subscription;
        }
      }

      if (
        subscription.status === CHARGEBEE_SUBSCRIPTION_STATUS.CANCELLED ||
        subscription.status === CHARGEBEE_SUBSCRIPTION_STATUS.PAUSED
      ) {
        if (!subscriptionInfoToDisplay) {
          subscriptionInfoToDisplay = subscription;
        }
      }
    });

    return subscriptionInfoToDisplay;
  };

  /**
   * Check if at least one subscription is active | in_trial | non_renewing
   */
  static isOneSubscriptionValid = (subscriptions: ChargebeeSubscriptionObject[]): boolean => {
    let isOneSubscriptionValid = false;
    subscriptions.forEach(({ subscription }) => {
      if (!isOneSubscriptionValid) {
        isOneSubscriptionValid =
          subscription.status === CHARGEBEE_SUBSCRIPTION_STATUS.IN_TRIAL ||
          subscription.status === CHARGEBEE_SUBSCRIPTION_STATUS.NON_RENEWING ||
          subscription.status === CHARGEBEE_SUBSCRIPTION_STATUS.ACTIVE;
      }
    });

    return isOneSubscriptionValid;
  };

  /**
   * Check if the free trial subscription is over
   */
  static isFreeTrialSubscriptionOver = (subscriptions: ChargebeeSubscriptionObject[]): boolean => {
    let isFreeTrialSubscriptionOver = false;
    subscriptions.forEach(({ subscription }) => {
      const freeTrialPlan = process.env.REACT_APP_CHARGEBEE_FREE_TRIAL_PLAN || '';
      if (subscription.plan_id === freeTrialPlan && subscription.cancel_reason === 'no_card') {
        isFreeTrialSubscriptionOver = true;
      }
    });

    return isFreeTrialSubscriptionOver;
  };

  /**
   * get the free trial subscription from the list
   */
  static getFreeTrialSubscription = (subscriptions: ChargebeeSubscriptionObject[]): ChargebeeSubscriptionObject => {
    const freeTrialPlan = process.env.REACT_APP_CHARGEBEE_FREE_TRIAL_PLAN || '';
    return find(subscriptions, ({ subscription }) => {
      return subscription.plan_id === freeTrialPlan;
    }) as ChargebeeSubscriptionObject;
  };
}
