import { ColumnProps } from 'antd/es/table';
import Fuse from 'fuse.js';
import { TFunction } from 'i18next';
import { findIndex } from 'lodash';
import React, { ReactElement, ReactNode } from 'react';
import Highlighter from 'react-highlight-words';
import Preformatted from '../../../components/preformatted/Preformatted';
import FilterIcon from '../../../components/table/search-in-column-filter/FilterIcon';
import SearchInColumnFilter from '../../../components/table/search-in-column-filter/SearchInColumnFilter';
import { APIModelCharter, DashboardSubtitles } from '../../../services/api/types/ChartersServiceTypes';
import { APICompanyUsers } from '../../../services/api/types/CompaniesServiceTypes';
import { APIModelUser, APIModelUserEnriched } from '../../../services/api/types/UsersServiceTypes';
import StringUtils from '../../../utils/StringUtils';
import TimeUtils from '../../../utils/TimeUtils';
import UsersUtils from '../../../utils/UsersUtils';
import CharterCell from './CharterCell';
import CharterCellHelp from './CharterCellHelp';
import EditableUsername from './editable-username/EditableUsername';
import SliderColumnFilter from './slider-column-filter/SliderColumnFilter';

/**
 **
 ** BUILD COLUMNS
 **
 */

type BuildColumnsArgs = {
  users: APICompanyUsers[];
  currentUser: APIModelUser;
  filter: Record<string, string> | undefined;
  setFilter: React.Dispatch<React.SetStateAction<Record<string, string> | undefined>>;
  isMobileOrTablet: boolean;
  canReadUserIds: boolean;
  classes: Record<string, string>;
  t: TFunction;
  filteredInfo: Record<string, string[] | null>;
  callbackOpenRow: (row: APICompanyUsers) => void;
  callbackLinkUserToCharter: (isLoading: boolean, data?: APIModelUserEnriched, charter?: APIModelCharter) => void;
  callbackRemoveUserFromCharter: (isLoading: boolean, charter?: APIModelCharter, user?: APICompanyUsers) => void;
  callbackUserNameUpdated: (user: APIModelUser) => void;
  handleSearch?: (confirm: () => void) => void;
};

export const buildColumns = ({
  users,
  currentUser,
  filter,
  setFilter,
  isMobileOrTablet,
  canReadUserIds,
  classes,
  t,
  filteredInfo,
  callbackOpenRow,
  callbackLinkUserToCharter,
  callbackRemoveUserFromCharter,
  callbackUserNameUpdated,
  handleSearch,
}: BuildColumnsArgs): ColumnProps<APICompanyUsers>[] => {
  const fuseName = new Fuse(users, {
    keys: ['name'],
    threshold: 0.35,
  });
  const fuseEmail = new Fuse(users, {
    keys: ['email'],
    threshold: 0.3,
  });

  return [
    {
      title: t('users.name'),
      dataIndex: 'name',
      key: 'user_name',
      fixed: !isMobileOrTablet ? 'left' : undefined,
      sorter: (userA, userB, sortOrder): number => UsersUtils.sortUsersByName(userA, userB, currentUser, sortOrder),
      render: (name: string, row: APICompanyUsers): ReactElement => {
        const isCurrentUser = row.id === currentUser?.id;
        return (
          <EditableUsername
            user={row}
            filter={filter}
            handleUserNameUpdated={callbackUserNameUpdated}
            isCurrentUser={isCurrentUser}
            isEditable={!isCurrentUser}
          />
        );
      },
      filteredValue: filteredInfo.user_name || null,
      filterDropdown: (filterProps): ReactNode => (
        <SearchInColumnFilter
          {...filterProps}
          dataIndex="name"
          fuse={fuseName}
          placeholder={t('users.searchOnColumnPlaceholder', { columnName: t('users.name').toLowerCase() })}
          filter={filter}
          setFilter={setFilter}
          possibleValues={users?.map((u) => u.name)}
          handleSearch={handleSearch}
        />
      ),
      filterIcon: FilterIcon,
      onFilter: (value, record: APICompanyUsers): boolean => {
        const result = fuseName.search(value as any);
        const index = findIndex(result, ({ item }) => {
          return item.id === record.id;
        });

        return index >= 0;
      },
      width: 200,
    },
    {
      title: t('users.email'),
      dataIndex: 'email',
      key: 'user_email',
      filteredValue: filteredInfo.user_email || null,
      sorter: (userA, userB, sortOrder): number => UsersUtils.sortUsersByEmail(userA, userB, currentUser, sortOrder),
      render: (email: string, row: APICompanyUsers): ReactElement => {
        return (
          <div>
            {filter && filter.email ? (
              <>
                <Highlighter
                  className={classes.highlightedText}
                  searchWords={[filter.email]}
                  autoEscape
                  textToHighlight={email.toString()}
                />
                {canReadUserIds && (
                  <Preformatted className={classes.userId}>
                    <Highlighter
                      className={classes.highlightedText}
                      searchWords={[filter.email]}
                      autoEscape
                      textToHighlight={row.id}
                    />
                  </Preformatted>
                )}
              </>
            ) : (
              <>
                <span>{email}</span>
                {canReadUserIds && <Preformatted className={classes.userId}>{row.id}</Preformatted>}
              </>
            )}
          </div>
        );
      },
      filterDropdown: (filterProps): ReactNode => {
        let possibleValues = users?.map((u) => u.email);
        if (canReadUserIds && users && possibleValues) {
          const userIds = users?.map((u) => u.id);
          possibleValues = possibleValues.concat(userIds);
        }
        return (
          <SearchInColumnFilter
            {...filterProps}
            dataIndex="email"
            fuse={fuseEmail}
            filter={filter}
            setFilter={setFilter}
            placeholder={t('users.searchOnColumnPlaceholder', { columnName: t('users.email').toLowerCase() })}
            possibleValues={possibleValues}
            handleSearch={handleSearch}
          />
        );
      },
      filterIcon: FilterIcon,
      onFilter: (value, record: APICompanyUsers): boolean => {
        if (canReadUserIds) {
          // If canReadUserIds, we return records matching either on the email or the ID
          return [record.email, record.id]
            .map((userEmailOrId) => StringUtils.normalize(userEmailOrId.toString()))
            .some((userEmailOrIdNormalized) =>
              userEmailOrIdNormalized.includes(StringUtils.normalize(value.toString()))
            );
        }
        // Else we only return records matching on the email
        return StringUtils.normalize(record.email.toString()).includes(StringUtils.normalize(value.toString()));
      },
      width: 200,
    },
    {
      title: (
        <>
          {t('users.charters')}

          <CharterCellHelp />
        </>
      ),
      dataIndex: 'charter',
      key: 'user_charter',
      className: 'userCharter',
      filteredValue: filteredInfo.user_charter || null,
      render: (test: string, row: APICompanyUsers): ReactElement => {
        return (
          <CharterCell
            row={row}
            callbackOpenRow={callbackOpenRow}
            callbackLinkUserToCharter={callbackLinkUserToCharter}
            callbackRemoveUserFromCharter={callbackRemoveUserFromCharter}
          />
        );
      },
      width: 300,
    },
    {
      title: t('users.subtitleConsumption'),
      dataIndex: 'dashboard',
      key: 'user_subtitle',
      filteredValue: filteredInfo.user_subtitle || null,
      sorter: (userA, userB, sortOrder): number =>
        UsersUtils.sortUsersBySubtitleConsumption(userA, userB, currentUser, sortOrder),
      render: (dashboard: DashboardSubtitles): string => {
        const { currentUsage } = dashboard.subtitles;
        return TimeUtils.formatSecondsIntoHumanTimeString(currentUsage, t);
      },
      filterDropdown: (filterProps): ReactNode => (
        <SliderColumnFilter {...filterProps} dataIndex="dashboard" setFilter={setFilter} />
      ),
      onFilter: (value, record: APICompanyUsers): boolean => {
        const { currentUsage } = record.dashboard.subtitles;
        // Value is formatted like 'min_max'.
        // If max is the maximum possible value, the 'value' input is like 'min_+' saying there is no maximum bound
        const [min, max] = value
          .toString()
          .split('_')
          .map((bound) => (bound === '+' ? undefined : Number(bound)));

        if (currentUsage !== undefined) {
          // If no max bound
          if (min !== undefined && max === undefined) {
            return currentUsage >= min;
          }
          // If min and max bounds
          if (min !== undefined && max !== undefined) {
            return currentUsage >= min && currentUsage <= max;
          }
        }
        return false;
      },
      width: 200,
    },
  ];
};
