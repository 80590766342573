import { Descriptions } from 'antd';
import classNames from 'classnames';
import { find } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import Preformatted from '../../../../../components/preformatted/Preformatted';
import { DEVICE_SIZES_QUERIES } from '../../../../../Constants';
import AnimationsUtils from '../../../../../utils/AnimationsUtils';
import ScenarioUtils from '../../../../../utils/ScenarioUtils';
import TimeUtils from '../../../../../utils/TimeUtils';
import {
  DemoMediaType,
  ScenarioFormats,
  ScenarioSceneTemplate,
  TemplateSequenceKind,
} from '../../../../../utils/types/ScenarioTypes';
import ScenarioScenePreview from '../ScenarioScenePreview';

type Props = {
  scene: ScenarioSceneTemplate;
  format: string;
  isScenePreviewPlaying: boolean;
  onIsScenePreviewPlayingChange: (newIsPlaying: boolean) => void;
};

const useStyles = createUseStyles({
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      flexDirection: 'column',
    },
  },
  description: {
    flex: 1,
  },
  subFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  subFieldWithNoPaddingBottom: {
    paddingBottom: '0px !important',
  },
  overlayImage: {
    marginTop: 8,
    width: 60,
    border: '1px solid #afafaf',
    filter: 'brightness(0.2)',
    borderRadius: 3,
    marginRight: 10,
  },
  overlayImageSquare: {
    extend: 'overlayImage',
    height: 50,
    width: 50,
    objectFit: 'cover',
  },
  overlayImagePortrait: {
    extend: 'overlayImage',
    height: 50,
    width: 32,
    objectFit: 'cover',
  },
  scenePreviewContainer: {
    flex: 1,
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      width: '100%',
    },
  },
});

const ScenarioSceneDescription = ({ scene, format, isScenePreviewPlaying, onIsScenePreviewPlayingChange }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const isSlide = ScenarioUtils.isSlideKind(scene.kind);
  const formattedDescription = scene.description.replace(/\/n/g, '\n');

  const renderSceneDescription = () => {
    return (
      <Descriptions.Item label={t('charters.scenarios.variantEditor.sceneForm.fields.descriptionLabel')}>
        {formattedDescription.split('\n').map((line, index) => (
          <>
          {line}
          <br />
          </>
      ))}
      </Descriptions.Item>
    );
  };

  const renderSceneKind = () => {
    const sceneKind = find(TemplateSequenceKind, (kind) => kind.code === scene.kind);

    if (!sceneKind) {
      return null;
    }

    return (
      <Descriptions.Item label={t('charters.scenarios.variantEditor.sceneForm.fields.kindLabel')}>
        {t(sceneKind.key)}
      </Descriptions.Item>
    );
  };

  const renderSceneUserPip = () => {
    if (!scene.userPip) {
      return null;
    }

    return (
      <Descriptions.Item label={t('charters.scenarios.variantEditor.sceneForm.fields.userPipLabel')}>
        <div className={classes.subFieldContainer}>
          <Preformatted>{scene.userPip.demoSource?.publicName}</Preformatted>
          <Descriptions>
            <Descriptions.Item
              label={t('charters.scenarios.variantEditor.sceneForm.fields.userPipSubfields.durationLabel')}
            >
              {scene.userPip?.duration && TimeUtils.formatSecondsIntoDuration(scene.userPip.duration)}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </Descriptions.Item>
    );
  };

  const renderSceneDemoVideoUri = () => {
    if (!scene.demoVideoUri) {
      return null;
    }

    return (
      <Descriptions.Item label={t('charters.scenarios.variantEditor.sceneForm.fields.demoVideoUriLabel')}>
        <div className={classes.subFieldContainer}>
          <Preformatted>{scene.demoVideoUri.publicName}</Preformatted>
        </div>
      </Descriptions.Item>
    );
  };

  const renderSceneDemoMedia = () => {
    const sceneMediaType =
      // eslint-disable-next-line no-nested-ternary
      scene.demoVideoUri !== undefined
        ? DemoMediaType.VIDEO
        : scene.userPip !== undefined
        ? DemoMediaType.IMAGE
        : undefined;

    if (!sceneMediaType) {
      return null;
    }

    return (
      <>
        <Descriptions.Item label={t('charters.scenarios.variantEditor.sceneForm.fields.demoMediaTypeLabel')}>
          {t(sceneMediaType.key)}
        </Descriptions.Item>
        {sceneMediaType.code === DemoMediaType.IMAGE.code ? renderSceneUserPip() : renderSceneDemoVideoUri()}
      </>
    );
  };

  const renderSceneAnimationTexts = () => {
    if (!(scene.animationText && scene.overlayTexts && scene.overlayTexts.length > 0)) {
      return null;
    }

    const animation = AnimationsUtils.getAnimationOptionByCode(scene.animationText.name);

    if (!animation) {
      return null;
    }

    return (
      <Descriptions.Item label={t('charters.scenarios.variantEditor.sceneForm.fields.animationTextLabel')}>
        <div className={classes.subFieldContainer}>
          <Preformatted>{t(animation.key)}</Preformatted>
          <Descriptions column={1} labelStyle={{ fontStyle: 'italic' }}>
            {animation.animationTexts.map((animationText, index) => {
              const overlayText = scene.overlayTexts![index];
              if (!overlayText) {
                return null;
              }

              return (
                <Descriptions.Item
                  label={t(animationText.labelKey)}
                  key={`${animationText.labelKey}-${overlayText.text}`}
                  className={classes.subFieldWithNoPaddingBottom}
                >
                  {overlayText.text}
                </Descriptions.Item>
              );
            })}
          </Descriptions>
        </div>
      </Descriptions.Item>
    );
  };

  const renderSceneOverlayImage = () => {
    if (!scene.overlayImage) {
      return null;
    }

    const allowedOverlayImages = ScenarioUtils.getVariantFormatByCode(format)?.allowedOverlayImages;
    const overlayImageOption = find(allowedOverlayImages, (overlayImage) => overlayImage.code === scene.overlayImage);

    if (!overlayImageOption) {
      return null;
    }

    return (
      <Descriptions.Item label={t('charters.scenarios.variantEditor.sceneForm.fields.overlayImageLabel')}>
        <div className={classes.subFieldContainer}>
          {t(overlayImageOption.key)}
          <img
            alt="Overlay"
            src={overlayImageOption.image}
            className={classNames(
              classes.overlayImage, // Default style (landscape overlayImages)
              format === ScenarioFormats.SQUARE_1_1.code && classes.overlayImageSquare, // Style square overlayImages
              format === ScenarioFormats.PORTRAIT_9_16.code && classes.overlayImagePortrait // Style portrait overlayImages
            )}
          />
        </div>
      </Descriptions.Item>
    );
  };

  const renderSceneVideoRecommendedTime = () => {
    if (!scene.videoRecommendedTime) {
      return null;
    }

    return (
      <Descriptions.Item label={t('charters.scenarios.variantEditor.sceneForm.fields.videoRecommendedTimeWithoutUnit')}>
        {TimeUtils.formatSecondsIntoDuration(scene.videoRecommendedTime)}
      </Descriptions.Item>
    );
  };

  const renderSceneVideoMinRecordTime = () => {
    if (!scene.videoMinRecordTime) {
      return null;
    }

    return (
      <Descriptions.Item
        label={t('charters.scenarios.variantEditor.sceneForm.fields.videoMinRecordTimeWithoutUnitLabel')}
      >
        {TimeUtils.formatSecondsIntoDuration(scene.videoMinRecordTime)}
      </Descriptions.Item>
    );
  };

  const renderSceneVideoMaxRecordTime = () => {
    if (!scene.videoMaxRecordTime) {
      return null;
    }

    return (
      <Descriptions.Item
        label={t('charters.scenarios.variantEditor.sceneForm.fields.videoMaxRecordTimeWithoutUnitLabel')}
      >
        {TimeUtils.formatSecondsIntoDuration(scene.videoMaxRecordTime)}
      </Descriptions.Item>
    );
  };

  const renderScenePreview = () => {
    return (
      <div className={classes.scenePreviewContainer}>
        <ScenarioScenePreview
          scene={scene}
          format={format}
          isPlaying={isScenePreviewPlaying}
          onIsPlayingChange={onIsScenePreviewPlayingChange}
        />
      </div>
    );
  };

  return (
    <div className={classes.mainContainer}>
      <Descriptions title={scene.title} column={1} className={classes.description} labelStyle={{ fontWeight: 'bold' }}>
        {renderSceneDescription()}
        {renderSceneKind()}
        {renderSceneAnimationTexts()}
        {!isSlide && (
          <>
            {renderSceneDemoMedia()}
            {renderSceneOverlayImage()}
            {renderSceneVideoRecommendedTime()}
            {renderSceneVideoMinRecordTime()}
            {renderSceneVideoMaxRecordTime()}
          </>
        )}
      </Descriptions>

      {renderScenePreview()}
    </div>
  );
};

export default ScenarioSceneDescription;
