import { CloseOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import React, { FunctionComponent } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { THEME } from '../../Constants';
import { toggleMenu } from '../../redux/action/AppAction';

type Props = {
  isVisible: boolean;
  children: JSX.Element[] | JSX.Element;
};

const useStyles = createUseStyles({
  mobileDrawerFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeMobileMenuButton: {
    extend: 'mobileDrawerFooter',
    background: THEME.MENU.MAIN_COLOR,
    color: '#FFFFFF',
    border: 'none',
    marginBottom: '30px',
    height: '50px',
    width: '50px',
    '& .anticon': {
      lineHeight: '0',
    },
    '&:hover': {
      background: THEME.MENU.CLOSE_MOBILE_MENU_BUTTON_COLOR,
      color: '#FFFFFF',
    },
  },
});

const KannelleMobileOrTabletSider: FunctionComponent<Props> = ({ isVisible, children }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onDrawerMenuClose = (): void => {
    dispatch(toggleMenu());
  };

  const DrawerFooter = (
    <div className={classes.mobileDrawerFooter}>
      <Button
        className={classes.closeMobileMenuButton}
        shape="circle"
        icon={<CloseOutlined />}
        onClick={onDrawerMenuClose}
      />
    </div>
  );

  return (
    <Drawer
      visible={isVisible}
      placement="left"
      width="100%"
      footer={DrawerFooter}
      closable={false}
      onClose={onDrawerMenuClose}
      footerStyle={{ borderTop: 'none' }}
    >
      {children}
    </Drawer>
  );
};

export default KannelleMobileOrTabletSider;
