import { CheckOutlined, EditFilled } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { Ref, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiEqualizer } from 'react-icons/bi';
import { FaMicrophone, FaSignal, FaWaveSquare } from 'react-icons/fa';
import { IoMusicalNotes } from 'react-icons/io5';
import { createUseStyles } from 'react-jss';
import Preformatted from '../../../../../../components/preformatted/Preformatted';
import ProjectUtils from '../../../../../../utils/ProjectUtils';
import { ProjectVolumeParameters } from '../../../../../../utils/types/ProjectTypes';
import ProjectVolumeSettingsModal from './ProjectVolumeSettingsModal';

type Props = {
  value?: ProjectVolumeParameters;
  onChange?: (newValue: ProjectVolumeParameters | undefined) => void;
  fieldLabel: string;
};

const useStyles = createUseStyles({
  configureVolumeButton: {
    padding: 0,
  },
  fieldValueContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: 6,
  },
  selectedVolumesContainer: {
    cursor: 'pointer',
  },
  fieldLabelIcon: {
    marginRight: 2,
  },
  fieldValueIcon: {
    marginRight: '0px !important',
  },
  musicVolumeFadeOutDurationIcon: {
    transform: 'scale(-1, 1)',
  },
});

const ProjectVolumeSettings = React.forwardRef(({ value, onChange, fieldLabel }: Props, ref: Ref<any>) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const classes = useStyles();
  const { t } = useTranslation();

  // Called to update the upper `form` object
  const triggerChange = (changedValue: ProjectVolumeParameters | undefined): void => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  const onOpenModal = (): void => {
    setIsModalVisible(true);
  };

  const onCloseModal = (): void => {
    setIsModalVisible(false);
  };

  const onSubmit = (volumeParameters: ProjectVolumeParameters) => {
    triggerChange(volumeParameters);
    onCloseModal();
  };

  const renderAudioVolumeValue = (audioVolume: number) => {
    return (
      <Tooltip title={t('charters.projects.projectEditor.projectFinalization.volume.audioVolumeLabel')}>
        <span className={classes.fieldValueContainer}>
          <FaMicrophone className={classes.fieldLabelIcon} />
          <Preformatted>{ProjectUtils.formatVolumeToPercentage(audioVolume)}%</Preformatted>
        </span>
      </Tooltip>
    );
  };

  const renderBackgroundMusicVolumeValue = (backgroundMusicVolume?: number) => {
    if (backgroundMusicVolume === undefined) {
      return null;
    }
    return (
      <Tooltip title={t('charters.projects.projectEditor.projectFinalization.volume.backgroundMusicVolumeLabel')}>
        <span className={classes.fieldValueContainer}>
          <IoMusicalNotes className={classes.fieldLabelIcon} />
          <Preformatted>{ProjectUtils.formatVolumeToPercentage(backgroundMusicVolume)}%</Preformatted>
        </span>
      </Tooltip>
    );
  };

  const renderAudioProcessingEnabledValue = (audioProcessingEnabled?: boolean) => {
    if (!audioProcessingEnabled) {
      return null;
    }

    return (
      <Tooltip title={t('charters.projects.projectEditor.projectFinalization.volume.audioProcessingEnabledPopover')}>
        <span className={classes.fieldValueContainer}>
          <BiEqualizer className={classes.fieldLabelIcon} />
          <Preformatted>
            <CheckOutlined className={classes.fieldValueIcon} />
          </Preformatted>
        </span>
      </Tooltip>
    );
  };

  const renderMusicDuckingVolumeValue = (musicDuckingVolume?: number) => {
    const musicDuckingVolumeOption = musicDuckingVolume
      ? ProjectUtils.findProjectMusicDuckingVolumeByValue(musicDuckingVolume)
      : undefined;

    if (!(musicDuckingVolume !== undefined && musicDuckingVolumeOption)) {
      return null;
    }

    return (
      <Tooltip title={t('charters.projects.projectEditor.projectFinalization.volume.musicDuckingPopover')}>
        <span className={classes.fieldValueContainer}>
          <FaWaveSquare className={classes.fieldLabelIcon} />
          <Preformatted>{t(musicDuckingVolumeOption.key)}</Preformatted>
        </span>
      </Tooltip>
    );
  };

  const renderMusicFadeOutDurationValue = (musicFadeOutDuration?: number) => {
    const musicFadeOutDurationOption = musicFadeOutDuration
      ? ProjectUtils.findProjectMusicFadeOutDurationByValue(musicFadeOutDuration)
      : undefined;

    if (!(musicFadeOutDuration !== undefined && musicFadeOutDurationOption)) {
      return null;
    }

    return (
      <Tooltip title={t('charters.projects.projectEditor.projectFinalization.volume.musicFadeOutDurationPopover')}>
        <span className={classes.fieldValueContainer}>
          <FaSignal className={classNames(classes.fieldLabelIcon, classes.musicVolumeFadeOutDurationIcon)} />
          <Preformatted>{t(musicFadeOutDurationOption.key)}</Preformatted>
        </span>
      </Tooltip>
    );
  };

  return (
    <div ref={ref}>
      <div onClick={onOpenModal}>
        {value ? (
          <span className={classes.selectedVolumesContainer}>
            {renderAudioVolumeValue(value.audioVolume)}
            {renderBackgroundMusicVolumeValue(value?.backgroundMusicVolume)}
            {renderAudioProcessingEnabledValue(value?.audioProcessingEnabled)}
            {renderMusicDuckingVolumeValue(value?.musicDuckingVolume)}
            {renderMusicFadeOutDurationValue(value?.musicFadeOutDuration)}
            <Tooltip title={t('global.edit')}>
              <EditFilled />
            </Tooltip>
          </span>
        ) : (
          <Button type="link" className={classNames(classes.configureVolumeButton)}>
            {t('charters.projects.projectEditor.projectFinalization.volume.editButton')}
          </Button>
        )}
      </div>

      <ProjectVolumeSettingsModal
        value={value}
        isVisible={isModalVisible}
        fieldLabel={fieldLabel}
        onCancel={onCloseModal}
        onSubmit={onSubmit}
      />
    </div>
  );
});

export default ProjectVolumeSettings;
