import { Button, Modal } from 'antd';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { ANIMATION_CONSTANTS, DEVICE_SIZES_QUERIES } from '../../../../Constants';
import { ScenarioSceneTemplate } from '../../../../utils/types/ScenarioTypes';
import useScenarioFinalRenderData, { ExtendedScenarioSceneTemplate } from './hooks/useScenarioFinalRenderData';
import ScenarioFinalRenderPlayerControls from './ScenarioFinalRenderPlayerControls';
import ScenarionFinalRenderScenePlayer from './ScenarioFinalRenderScenePlayer';

type Props = {
  isVisible: boolean;
  scenes?: ScenarioSceneTemplate[];
  format: string;
  handleClose: () => void;
};

const useStyles = createUseStyles({
  mainContainer: {
    height: 0,
    paddingBottom: '56.25%', // (1 / ratio) * 100% where ratio = 16/9
    width: '100%',
    position: 'relative',
    boxShadow: '0 8px 8px 0 hsla(0, 0%, 0%, 0.15) !important',
    borderRadius: 4,
    overflow: 'hidden',
    background: '#F7F7F7',
  },
  fullscreenModal: {
    width: '85% !important',
    [`@media screen and ${DEVICE_SIZES_QUERIES.EXTRA_LARGER}`]: {
      width: '70% !important',
    },
    '& .ant-modal-body': {
      position: 'relative',
    },
  },
  playerInModalContainer: {
    marginTop: 30,
    position: 'relative',
  },
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: '#F7F7F7',
  },
});

const ScenarioFinalPlayerModal: FunctionComponent<Props> = ({ isVisible, scenes = [], format, handleClose }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const finalRenderPlayerRef = useRef<HTMLDivElement>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [visibleScenarioElement, setVisibleScenarioElement] = useState<ScenarioSceneTemplate>();
  const [visibleElementSeekTo, setVisibleElementSeekTo] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [displayControls, setDisplayControls] = useState(false);
  const [isSeeking, setIsSeeking] = useState(false);
  const [volume, setVolume] = useState(0);

  const { scenarioDuration, scenarioElements } = useScenarioFinalRenderData(scenes);

  // Callback to play the player
  const onPlay = useCallback(() => {
    setIsPlaying(true);
  }, []);

  // Callback to pause the player
  const onPause = useCallback(() => {
    setIsPlaying(false);
  }, []);

  // Callback called to either play or pause the player
  const onIsPlayingChange = useCallback(
    (newValue: boolean): void => {
      return newValue ? onPlay() : onPause();
    },
    [onPlay, onPause]
  );

  // Callback determining which scenario scene to play depending on the `newTime` input
  const updateVisibleElement = useCallback(
    (newTime: number) => {
      const currentlyVisibleScene = scenarioElements.find((scene: ExtendedScenarioSceneTemplate) => {
        return newTime >= scene.absoluteStartAt && newTime < scene.absoluteEndAt;
      });

      if (currentlyVisibleScene?.id !== visibleScenarioElement?.id) {
        setVisibleElementSeekTo(0);
        setVisibleScenarioElement(currentlyVisibleScene);
      }

      return currentlyVisibleScene;
    },
    [scenarioElements]
  );

  // Callback called when the user seeked in the video. We determine the newTime to reach depending on the seeked
  // value, and which scene must be visible at this target time. Then, we ensure to correctly seek in that new
  // visible scene
  const onSeekBarChange = (percentage?: number) => {
    if (percentage !== undefined) {
      if (!isSeeking) {
        setIsSeeking(true);
      }

      onPause();
      const newTime = percentage * scenarioDuration;
      const newVisibleElement = updateVisibleElement(newTime);

      // Set the visibleElementSeekTo state to correctly seek in the new visible element
      if (newVisibleElement) {
        const seekTo = newTime - newVisibleElement.absoluteStartAt;
        setVisibleElementSeekTo(seekTo);
      }

      setCurrentTime(newTime);
    }
  };

  // Callback called when the seekBar is released
  const onSeekBarReleased = useCallback(() => {
    setIsSeeking(false);
  }, []);

  // Callback to update the currentTime state
  const onUpdateCurrentTime = useCallback((newTime: number) => {
    setCurrentTime(newTime);
  }, []);

  // Callback called to reset the player states
  const resetPlayer = useCallback(() => {
    setCurrentTime(0);
    setIsPlaying(false);
    const newVisibleElement = updateVisibleElement(0);
    setVisibleElementSeekTo(newVisibleElement?.absoluteStartAt ?? 0);
  }, [updateVisibleElement]);

  // Callback called when the visibleElement reaches its end. If the finished element is the last one, then we reset
  // the currentTime to 0 and pause the player. Else, if the finished element has a following one, we ensure to update
  // the currentTime to the absoluteStartAt of this following element
  const onPlayNextElement = (endedElement: ExtendedScenarioSceneTemplate) => {
    if (endedElement.id !== visibleScenarioElement?.id) {
      return;
    }

    const endedElementIndex = scenarioElements.findIndex(
      (element: ExtendedScenarioSceneTemplate) => element.id === endedElement.id
    );

    // If element is not found in the scenarioElements
    if (endedElementIndex < 0) {
      return;
    }

    // If the endedElement is not the last one, we play the next element
    if (endedElementIndex < scenarioElements.length - 1) {
      const nextElement = scenarioElements[endedElementIndex + 1];
      const newTime = nextElement.absoluteStartAt;

      updateVisibleElement(newTime);
      setCurrentTime(newTime);
      return;
    }

    // Else we reset to zero
    if (endedElementIndex === scenarioElements.length - 1) {
      // Timeout to reset the player, so has to ensure not to receive any other `onProgress` callback coming from the players
      onPause();
      const progressInterval = ANIMATION_CONSTANTS.PLAYER_PROGRESS_INTERVAL;
      setTimeout(resetPlayer, progressInterval * 2);
    }
  };

  // Callback to get the new element to render in the scenario, depending on the visibleScenarioElement state
  const getNextElement = (): ScenarioSceneTemplate | undefined => {
    if (!visibleScenarioElement) {
      return undefined;
    }

    const visibleElementIndex = scenes.findIndex((element) => element.id === visibleScenarioElement.id);

    // If there is one following element, return it
    if (visibleElementIndex < scenes.length - 1) {
      return scenes[visibleElementIndex + 1];
    }

    // Else, return the first element of the scenario
    return scenes[0];
  };

  // Method to render one scenario scene
  const renderScene = (scene: ExtendedScenarioSceneTemplate) => {
    const isSceneVisible = scene.id === visibleScenarioElement?.id;
    const isNextScene = getNextElement()?.id === scene.id;

    // If the scene is not visible nor the next element, do not render it at all
    if (!(isSceneVisible || isNextScene)) {
      return null;
    }

    return (
      <ScenarionFinalRenderScenePlayer
        key={`final_scene_${scene.index}`}
        isVisible={isSceneVisible}
        scene={scene}
        format={format}
        isPlaying={isPlaying}
        onIsPlayingChange={onIsPlayingChange}
        currentTime={currentTime}
        seekTo={visibleElementSeekTo}
        onEndReached={() => onPlayNextElement(scene)}
        onUpdateCurrentTime={onUpdateCurrentTime}
        volume={volume}
      />
    );
  };

  const handleVolumeChange = (vol: number) => {
    setVolume(vol);
  };

  // Method to render the controls to play/pause the player, seek in the scenario and seek the current time and scenario duration
  const renderControls = () => {
    return (
      <ScenarioFinalRenderPlayerControls
        isPlaying={isPlaying}
        currentTime={currentTime}
        scenarioDuration={scenarioDuration}
        displayControls={displayControls}
        onPlay={onPlay}
        onPause={onPause}
        onSeekBarChange={onSeekBarChange}
        onSeekBarReleased={onSeekBarReleased}
        handleVolumeChange={handleVolumeChange}
        volume={volume}
      />
    );
  };

  // In this effect, we ensure to update the visible scene depending on the current time
  useEffect(() => {
    if (!visibleScenarioElement) {
      updateVisibleElement(currentTime);
    }
  }, [currentTime, updateVisibleElement]);

  // Callback to display the controls bar
  const onDisplayControls = () => {
    setDisplayControls(true);
  };

  // Callback to hide the controls bar
  const onHideControls = () => {
    setDisplayControls(false);
  };

  // Callback to hide the controls bar
  const handleCancel = () => {
    resetPlayer();
    handleClose();
  };

  return (
    <Modal
      centered
      title={t('charters.scenarios.variantEditor.finalPlayerTitle')}
      visible={isVisible}
      className={classes.fullscreenModal}
      cancelText={t('global.close')}
      onCancel={handleCancel}
      destroyOnClose
      footer={[
        <Button key="closeModal" onClick={handleCancel}>
          {t('global.close')}
        </Button>,
      ]}
    >
      <div className={classes.playerInModalContainer}>
        <div
          className={classes.mainContainer}
          ref={finalRenderPlayerRef}
          onMouseEnter={onDisplayControls}
          onMouseLeave={onHideControls}
        >
          {renderControls()}
          {scenarioElements?.map((scene: ExtendedScenarioSceneTemplate) => renderScene(scene))}
        </div>
      </div>
    </Modal>
  );
};

export default ScenarioFinalPlayerModal;
