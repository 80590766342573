import { WarningOutlined } from '@ant-design/icons/lib';
import { Button, Col, Descriptions, Row } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import FontObserver from '../../../../components/font-observer/FontObserver';
import PageContentLoader from '../../../../components/loader/PageContentLoader';
import { DEVICE_SIZES_QUERIES } from '../../../../Constants';
import { Font } from '../../../../services/api/types/FontFamiliesServiceTypes';

type Props = {
  selectedTitleFont?: Font;
  selectedTextFont?: Font;
  onResetToDefault: () => void;
  onApply: () => void;
  onCancel: () => void;
  areFontsUnsaved: boolean;
  isApplyLoading: boolean;
};

const useStyles = createUseStyles({
  mainContainer: {
    margin: '20px 0px',
    padding: '10px 8px',
    background: '#F5F5F5',
    border: '1px solid #E8E8E8',
    position: 'relative',
  },
  charterFontSettingsHeader: {
    position: 'absolute',
    top: '5px',
    left: '10px',
    fontSize: '12px',
  },
  selectedFontsContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '& .ant-descriptions-item': {
      paddingBottom: '0 !important',
    },
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      marginTop: '15px',
    },
  },
  actionsColumn: {
    width: '100%',
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%',
  },
  actionButtonColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mobileActionsContainer: {
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      flexDirection: 'column',
      '& button': {
        margin: '10px',
        width: '80%',
        whiteSpace: 'normal',
      },
    },
  },
  wideButton: {
    width: '90%',
  },
  notSelectedFont: {
    fontStyle: 'italic',
    '& .anticon': {
      marginRight: 2,
      color: '#faad14',
    },
  },
  resetToDefault: {
    [`@media screen and ${DEVICE_SIZES_QUERIES.MOBILE_OR_TABLET}`]: {
      margin: '10px 10px 0 !important',
    },
  },
});

const SelectedFontsRow: FunctionComponent<Props> = ({
  selectedTitleFont,
  selectedTextFont,
  onResetToDefault,
  onApply,
  onCancel,
  areFontsUnsaved,
  isApplyLoading,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const resetButtonDisabled = !(selectedTitleFont || selectedTextFont);
  const cancelButtonDisabled = !areFontsUnsaved;
  const applyButtonDisabled = !areFontsUnsaved;

  const renderNotSelectedFont = (): ReactElement => (
    <span className={classes.notSelectedFont}>
      <WarningOutlined /> {t('charters.fonts.notSelected')}
    </span>
  );

  const renderSelectedFont = (font: Font): ReactElement =>
    font.fontFamilyName ? (
      <FontObserver
        fontFamilyName={font.fontFamilyName}
        fontWeight={font.weight}
        fontStyle={font.style}
        loader={<PageContentLoader size="small" />}
      >
        <div
          style={{
            fontFamily: font.fontFamilyName || 'unset',
            fontWeight: font.weight || 'normal',
            fontStyle: font.style || 'normal',
          }}
        >
          {font.fullName}
        </div>
      </FontObserver>
    ) : (
      <div
        style={{
          fontWeight: font.weight || 'normal',
          fontStyle: font.style || 'normal',
        }}
      >
        {font.fullName}
      </div>
    );

  return (
    <div className={classes.mainContainer}>
      <div className={classes.charterFontSettingsHeader}>{t('charters.fonts.charterFontSettings')}</div>
      <Row>
        <Col xs={24} sm={14} md={15} xl={18} xxl={18}>
          <Descriptions className={classes.selectedFontsContainer} column={2}>
            <Descriptions.Item label={t('charters.fonts.titleFont')} className="titleFont">
              {selectedTitleFont ? renderSelectedFont(selectedTitleFont) : renderNotSelectedFont()}
            </Descriptions.Item>
            <Descriptions.Item label={t('charters.fonts.textFont')} className="textFont">
              {selectedTextFont ? renderSelectedFont(selectedTextFont) : renderNotSelectedFont()}
            </Descriptions.Item>
          </Descriptions>
        </Col>

        <Col xs={24} sm={10} md={9} xl={6} xxl={6} className={classes.actionsColumn}>
          <span className={classNames(classes.actionsContainer, classes.mobileActionsContainer)}>
            <Row>
              <Col span={24} className={classes.actionButtonColumn}>
                <Button
                  type="link"
                  disabled={resetButtonDisabled}
                  className={classNames(classes.resetToDefault, 'reset-font')}
                  onClick={onResetToDefault}
                >
                  {t('charters.fonts.resetToDefault')}
                </Button>
              </Col>
              <Col span={12} className={classes.actionButtonColumn}>
                <Button disabled={cancelButtonDisabled} className={classes.wideButton} onClick={onCancel}>
                  {t('global.cancel')}
                </Button>
              </Col>
              <Col span={12} className={classes.actionButtonColumn}>
                <Button
                  type="primary"
                  disabled={applyButtonDisabled}
                  className={classNames(classes.wideButton, 'apply-font')}
                  onClick={onApply}
                  loading={isApplyLoading}
                >
                  {t('global.apply')}
                </Button>
              </Col>
            </Row>
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default SelectedFontsRow;
