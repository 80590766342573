import { Avatar, Button, Card } from 'antd';
import Meta from 'antd/lib/card/Meta';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  card: {
    borderRadius: 8,
    boxShadow: '0 8px 8px 0 hsla(0, 0%, 0%, 0.15) !important',
  },
  metaContent: {
    color: '#555555',
  },
  emoji: {
    marginRight: 5,
  },
  contact: {
    fontSize: 16,
    padding: 0,
    border: 0,
  },
});

const CharterSupportCard: FunctionComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <Meta
        avatar={
          <Avatar
            src="https://ca.slack-edge.com/T1F88RSAW-UR5TV1CA3-7d192f70a565-512"
            alt={t('support.global.supportName')}
            size={64}
          />
        }
        title={
          <Button type="link" className={classes.contact}>
            {t('support.global.supportName')}
          </Button>
        }
        description={
          <div className={classes.metaContent}>
            <p>
              {t('support.global.needHelpPart1')}
              <span role="img" aria-label="smiley" className={classes.emoji}>
                {t('support.global.needHelpPart2')}
              </span>
              {t('support.global.needHelpPart3')}
            </p>
            <p>{t('support.charter.needHelp2')}</p>
            <p>{t('support.charter.needHelp3')}</p>
          </div>
        }
      />
    </Card>
  );
};

export default CharterSupportCard;
