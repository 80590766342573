import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  row: {
    flexDirection: 'column',
  },
  fullHeight: {
    height: '100%',
  },
  column: {
    flex: 1,
  },
  card: {
    '& .ant-card-body': {
      height: '100%',
      '& .ant-statistic': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
    },
  },
  sideBySideColumns: {
    display: 'flex',
    flexDirection: 'row',
  },
  tooltipIcon: {
    marginLeft: '5px',
  },
  smallStatisticsValue: {
    '& .ant-statistic-content': {
      fontSize: '18px',
    },
  },
});

export default useStyles;
