import { Alert, Checkbox, Form } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Rule, RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { createUseStyles } from 'react-jss';

export type InputCheckboxHelpType = 'success' | 'info' | 'warning' | 'error';

type Props = {
  name: string;
  label?: string;
  checkboxLabel?: string;
  extra?: string | JSX.Element;
  className?: string;
  required?: boolean;
  errorMessage?: string;
  mustBeChecked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  helps?: {
    helpType: InputCheckboxHelpType;
    content: string | JSX.Element;
  }[];
  callback?: (isChecked: boolean) => void;
};

const useStyles = createUseStyles({
  checkbox: {
    '& .ant-checkbox-wrapper': {
      marginTop: 6,
    },
  },
  helpAlert: {
    margin: '8px 0',
  },
});

const InputCheckBox: FunctionComponent<Props> = ({
  name,
  label,
  checkboxLabel,
  extra,
  className,
  errorMessage,
  required = false,
  mustBeChecked = false,
  defaultChecked = false,
  disabled = false,
  helps,
  callback,
}: Props) => {
  const classes = useStyles();

  const validateField = (rule: RuleObject, value: StoreValue): Promise<void> => {
    if (value === undefined && required) {
      return Promise.resolve();
    }

    if (value) {
      return Promise.resolve();
    }

    return Promise.reject(errorMessage);
  };

  const onChange = (e: CheckboxChangeEvent): void => {
    if (!callback) {
      return;
    }

    const isChecked = e.target.checked;
    callback(isChecked);
  };

  const rules: Rule[] = [{ required, message: errorMessage }];

  if (mustBeChecked) {
    rules.push({ validator: validateField });
  }

  return (
    <Form.Item
      name={name}
      label={label}
      valuePropName="checked"
      rules={rules}
      extra={extra}
      className={classNames(className, helps && classes.checkbox)}
    >
      <Checkbox defaultChecked={defaultChecked} disabled={disabled} onChange={onChange}>
        {checkboxLabel}
      </Checkbox>
      {helps &&
        helps.map((help, index) => (
          <Alert
            className={classes.helpAlert}
            message={help.content}
            type={help.helpType}
            showIcon
            key={`${index.toString()}-${help.helpType}`}
          />
        ))}
    </Form.Item>
  );
};

export default InputCheckBox;
