/* eslint-disable @typescript-eslint/no-explicit-any */
import { find } from 'lodash';
import { ThemeColorKey, ThemeColorKeyConfig } from './ThemeUtils';
import { LottieObject } from './types/ThemeTypes';

export default class LottieUtils {
  // Returns the ThemeColorKey corresponding to the `filter` animations (the keys of the animations we filter)
  // based on the `lottieArray` (description of the animations in the theme), and the charter theme colors, in the
  // `themeFields` object
  static filterThemeFieldByAnimations = (
    filter: string[],
    lottieArray: LottieObject[],
    themeFields: ThemeColorKey
  ): ThemeColorKey => {
    const keyArray: string[] = [];
    const filteredThemeFields: any = {
      code: themeFields.code,
      colorKeys: [],
    };

    lottieArray
      .filter((lottieObject: LottieObject) => {
        return filter.includes(lottieObject.key);
      })
      .forEach((lottieObject: LottieObject) => {
        const { fields } = lottieObject;
        fields.forEach((field: any) => {
          const themeField = find(themeFields.colorKeys, (colorKey: ThemeColorKeyConfig) => {
            return colorKey.key === field;
          });

          if (themeField && !keyArray.includes(themeField.key)) {
            filteredThemeFields.colorKeys.push(themeField);
            keyArray.push(themeField.key);
          }
        });
      });

    return filteredThemeFields;
  };

  // Computes the Lottie animation duration from some properties in the JSON
  static computeAnimationDuration = (op: number, fr: number): number => {
    return op / fr;
  };
}
