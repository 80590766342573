import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Form, Tag, Tooltip, Typography } from 'antd';
import { Store } from 'antd/lib/form/interface';
import classNames from 'classnames';
import i18n from 'i18next';
import { find } from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import EditableText from '../../../../components/form/EditableText';
import FormatTag from '../../../../components/tag/format-tag/FormatTag';
import { DEVICE_SIZES_QUERIES, THEME } from '../../../../Constants';
import { ScenarioVariant } from '../../../../services/api/types/ChartersServiceTypes';
import ScenarioUtils from '../../../../utils/ScenarioUtils';
import TimeUtils from '../../../../utils/TimeUtils';
import { ScenarioLanguages, ScenarioStatus } from '../../../../utils/types/ScenarioTypes';

const { Title } = Typography;

type Props = {
  scenarioVariant?: ScenarioVariant;
  onUpdate: (title: string, description: string, status: string) => void;
};

const useStyles = createUseStyles({
  mainContainer: {
    marginBottom: '30px',
  },
  form: {
    '& .ant-form-item': {
      flexGrow: 1,
    },
    '& .ant-select-item-option-selected': {
      fontWeight: 'unset',
    },
  },
  mobileForm: {
    flexDirection: 'column',
    '& .ant-form-item': {
      marginBottom: 6,
    },
    '& .ant-form-item-label': {
      paddingBottom: 0,
    },
  },
  variantInfoContainer: {
    display: 'flex',
  },
  formActionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 0,
    '& button:not(:last-child)': {
      marginRight: 8,
    },

    [`@media screen and ${DEVICE_SIZES_QUERIES.INTERMEDIATE}`]: {
      float: 'right',
    },
  },
  variantLanguageFlag: {
    marginLeft: '5px',
  },
  variantMetadataField: {
    '&:not(:last-child)': {
      marginRight: 20,
    },
    '& .ant-tag': {
      marginRight: 0,
    },
    marginBottom: 10,
  },
  fieldLabel: {
    '&::after': {
      content: "':'",
      position: 'relative',
      top: '-0.5px',
      margin: '0 8px 0 2px',
    },
  },
  metadataConfirmDescription: {
    marginBottom: 16,
  },
  scenarioVariantFieldsContainer: {
    display: 'flex',
    gap: 16,
    '& .ant-form-item': {
      marginBottom: 0,
    },
  },
  tag: {
    marginLeft: 8,
  },
  warningEditingMessage: {
    marginTop: 8,
    padding: '16px 8px',
    color: THEME.ALERT.WARNING_MAIN,
    background: THEME.ALERT.WARNING_BACKGROUND,
    '& .anticon': {
      marginRight: 8,
    },
  },
});

const ScenarioVariantMetadataForm: FunctionComponent<Props> = ({ scenarioVariant, onUpdate }: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const classes = useStyles();
  const [values, setValues] = useState<Store>({});
  const [isEditing, setIsEditing] = useState(false);
  const currentStatus = find(ScenarioStatus, { code: scenarioVariant?.status });

  const lang = i18n.language.split('-')[0] || 'en';

  const variantLanguage = ScenarioUtils.getVariantLanguage(scenarioVariant?.language || '') || ScenarioLanguages.FR;
  const variantLanguageText = variantLanguage && variantLanguage.key && t(variantLanguage.key);

  useEffect(() => {
    if (!scenarioVariant) {
      return;
    }

    const formInitialValues = {
      variantTitle: scenarioVariant.title,
      variantDescription: scenarioVariant.description,
      variantStatus: scenarioVariant.status,
    };

    setValues(formInitialValues);
    form.setFieldsValue(formInitialValues);
  }, [scenarioVariant, form]);

  const onSubmit = (newValues: Store): void => {
    const { variantTitle, variantDescription, variantStatus } = newValues;

    onUpdate(variantTitle, variantDescription, variantStatus);
  };

  const handleSetNewStatus = (newStatus: ScenarioStatus) => {
    onSubmit({
      ...values,
      variantStatus: newStatus.code,
    });
  };

  const renderScenarioVariantDuration = (duration: number): JSX.Element | null => {
    return (
      <div className={classes.variantMetadataField}>
        <span className={classes.fieldLabel}>
          {t('charters.scenarios.variantEditor.variantMetadataForm.fields.durationLabel')}
        </span>
        {TimeUtils.formatSecondsIntoHumanTimeString(duration, t)}
      </div>
    );
  };

  const renderScenarioVariantFormatTag = (formatCode: string): JSX.Element | null => {
    const format = ScenarioUtils.getVariantFormatByCode(formatCode);

    if (!format) {
      return null;
    }

    return (
      <div className={classes.variantMetadataField}>
        <FormatTag format={t(format.key)} image={format.image} />
      </div>
    );
  };

  const renderScenarioVariantLanguageTag = (languageCode: string): JSX.Element | null => {
    const language = ScenarioUtils.getVariantLanguage(languageCode);

    if (!language) {
      return null;
    }

    return (
      <div className={classes.variantMetadataField}>
        {t(language.key)}
        <span className={classes.variantLanguageFlag}>{language.flag}</span>
      </div>
    );
  };

  const handleIsEditingCallback = (isFieldEditing: boolean) => {
    setIsEditing(isFieldEditing);
  };

  return (
    <div className={classNames(classes.mainContainer, 'scenarioVariantMetadataForm')}>
      <Title level={4}>{t('charters.scenarios.variantEditor.metadataEditionTitle')}</Title>
      {scenarioVariant && (
        <span className={classes.variantInfoContainer}>
          {renderScenarioVariantDuration(scenarioVariant.duration)}
          {renderScenarioVariantFormatTag(scenarioVariant.format)}
          {renderScenarioVariantLanguageTag(scenarioVariant.language)}
        </span>
      )}

      <div className={classes.scenarioVariantFieldsContainer}>
        <span>
          <Form.Item label={t('charters.scenarios.variantEditor.variantMetadataForm.fields.titleLabel')}>
            <EditableText
              name="variantTitle"
              value={scenarioVariant?.title}
              handleSubmitCallback={(newValue) => {
                setValues({
                  ...values,
                  variantTitle: newValue,
                });

                onSubmit({
                  ...values,
                  variantTitle: newValue,
                });
              }}
              requiredMessage="charters.scenarios.variantEditor.variantMetadataForm.rules.titleRequired"
              handleIsEditingCallback={handleIsEditingCallback}
            />
          </Form.Item>
        </span>

        <span>
          <Form.Item label={t('charters.scenarios.variantEditor.variantMetadataForm.fields.descriptionLabel')}>
            <EditableText
              name="variantDescription"
              value={scenarioVariant?.description}
              handleSubmitCallback={(newValue) => {
                setValues({
                  ...values,
                  variantDescription: newValue,
                });

                onSubmit({
                  ...values,
                  variantDescription: newValue,
                });
              }}
              requiredMessage="charters.scenarios.variantEditor.variantMetadataForm.rules.descriptionRequired"
              handleIsEditingCallback={handleIsEditingCallback}
            />
          </Form.Item>
        </span>

        <span>
          <Form.Item label={t('charters.scenarios.variantEditor.variantMetadataForm.fields.statusLabel')}>
            <Tag className={classes.tag} color={currentStatus?.color}>
              {t(currentStatus?.key || '')}
            </Tag>
          </Form.Item>
        </span>

        <span className={classNames(classes.formActionsContainer)}>
          {currentStatus?.code !== ScenarioStatus.ARCHIVED.code && (
            <Tooltip title={t('charters.scenarios.variantEditor.variantMetadataForm.archiveTooltip')}>
              <Button type="default" onClick={() => handleSetNewStatus(ScenarioStatus.ARCHIVED)}>
                {t('charters.scenarios.variantEditor.variantMetadataForm.archiveTitle')}
              </Button>
            </Tooltip>
          )}
          {currentStatus?.code !== ScenarioStatus.DRAFT.code && (
            <Tooltip title={t('charters.scenarios.variantEditor.variantMetadataForm.draftTooltip')}>
              <Button type="default" onClick={() => handleSetNewStatus(ScenarioStatus.DRAFT)}>
                {t('charters.scenarios.variantEditor.variantMetadataForm.draftTitle')}
              </Button>
            </Tooltip>
          )}
          {currentStatus?.code !== ScenarioStatus.PUBLISHED.code && (
            <Tooltip title={t('charters.scenarios.variantEditor.variantMetadataForm.publishTooltip')}>
              <Button type="primary" onClick={() => handleSetNewStatus(ScenarioStatus.PUBLISHED)}>
                {t('charters.scenarios.variantEditor.variantMetadataForm.publishTitle')}
              </Button>
            </Tooltip>
          )}
        </span>
      </div>

      {isEditing && (
        <div className={classes.warningEditingMessage}>
          <ExclamationCircleOutlined size={32} />
          {t('charters.scenarios.variantEditor.variantMetadataForm.titleDescriptionWarning', {
            variantLanguage: lang === 'en' ? variantLanguageText : variantLanguageText?.toLowerCase(),
            variantLanguageFlag: variantLanguage?.flag,
          })}
        </div>
      )}
    </div>
  );
};

export default ScenarioVariantMetadataForm;
