import { UserOutlined } from '@ant-design/icons/lib';
import { Form, Input } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  label?: string;
  extra?: string;
  name?: string;
  placeholder?: string;
  icon?: JSX.Element;
  isReadOnly?: boolean;
  size?: SizeType;
};

const Name: FunctionComponent<Props> = ({
  label,
  extra,
  placeholder,
  icon,
  isReadOnly,
  size = 'middle',
  name = 'name',
}: Props) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        { type: 'string' },
        { required: true, message: t('form.nameRuleRequiredError') },
        { whitespace: true, message: t('form.nameRuleEmptyRequiredError') },
      ]}
      extra={extra}
      hasFeedback
    >
      <Input prefix={icon || <UserOutlined />} disabled={isReadOnly} placeholder={placeholder} size={size} />
    </Form.Item>
  );
};

export default Name;
