import { Switch, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import i18n from 'i18next';
import { findIndex } from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import PageContentLoader from '../../../components/loader/PageContentLoader';
import { CHARTER_ACTIVE, THEME } from '../../../Constants';
import { useAuth0 } from '../../../core/auth/Auth0Config';
import Company from '../../../model/Company';
import { setChartersList } from '../../../redux/action/ChartersAction';
import { RootState } from '../../../redux/RootState';
import { updateCharterById } from '../../../services/api/ChartersService';
import { getCompaniesByUser } from '../../../services/api/CompaniesService';
import { APIModelCharter } from '../../../services/api/types/ChartersServiceTypes';
import CharterUtils from '../../../utils/CharterUtils';
import CompaniesUtils from '../../../utils/CompaniesUtils';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'space-between',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  switchContainer: {
    minWidth: 110,
  },
  switch: {
    marginRight: '8px !important',
  },
  main: {
    color: THEME.DEFAULT.MAIN_TEXT_COLOR,
  },
});

const AdminChartersTable: FunctionComponent = () => {
  const { user } = useAuth0();
  const lang = i18n.language?.split('-')[0] || 'en';

  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [charters, setCharters] = useState<APIModelCharter[]>([]);

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [loadingRow, setLoadingRow] = useState(0);
  const [currentCompanyFromState] = useSelector((state: RootState) => {
    return [state.companies.current];
  });

  useEffect(() => {
    const auth0UserIdPrefix = process.env.REACT_APP_AUTH0_USER_ID_PREFIX ?? '';
    const userId = user?.sub.replace(auth0UserIdPrefix, '');
    setIsPageLoading(true);
    getCompaniesByUser(userId, CHARTER_ACTIVE.ALL, undefined, { lang })
      .then((response) => {
        const activeCompanies: Company[] = CompaniesUtils.getValidCompany(response.data.items, true);
        const currentCompany = CompaniesUtils.getCurrentCompany(activeCompanies, currentCompanyFromState);

        setCharters(currentCompany.charters);
      })
      .finally(() => {
        setIsPageLoading(false);
      });
  }, []);

  const handleChange = (checked: boolean, record: APIModelCharter) => {
    setLoadingRow(record.id);
    updateCharterById(record.id, { isActive: checked })
      .then(() => {
        const updatedCharters = [...charters];

        const charterIndexToUpdate = findIndex(updatedCharters, { id: record.id });
        if (charterIndexToUpdate > -1) {
          const updatedCharter = {
            ...updatedCharters[charterIndexToUpdate],
            isActive: checked,
          };

          updatedCharters.splice(charterIndexToUpdate, 1, updatedCharter);
        }

        setCharters([...updatedCharters]);
        dispatch(setChartersList([...updatedCharters]));
      })
      .finally(() => {
        setLoadingRow(0);
      });
  };

  const buildColumns = (): ColumnProps<APIModelCharter>[] => {
    return [
      {
        title: t('charters.name'),
        dataIndex: 'name',
        key: 'admin_charter_table_name',
        render: (text: string): JSX.Element => {
          return <span>{text}</span>;
        },
        sorter: CharterUtils.sortChartersByName,
        defaultSortOrder: 'ascend',
      },
      {
        title: t('charters.isActive'),
        dataIndex: 'isActive',
        key: 'admin_charter_table_isActive',
        render: (isActive: boolean, record: APIModelCharter): JSX.Element => {
          const info = isActive ? (
            <span className={classes.main}>{t('form.activated')}</span>
          ) : (
            <span>{t('form.deactivated')}</span>
          );

          return (
            <span className={classes.switchContainer}>
              <Switch
                checked={isActive}
                className={classes.switch}
                onChange={(isChecked) => handleChange(isChecked, record)}
                disabled={loadingRow !== 0 || (charters.filter((ch) => ch.isActive).length === 1 && isActive)}
                loading={loadingRow === record.id}
              />
              {info}
            </span>
          );
        },
        sorter: CharterUtils.sortChartersByName,
        defaultSortOrder: 'ascend',
      },
    ];
  };

  if (isPageLoading) {
    return <PageContentLoader />;
  }

  return (
    <>
      <Table
        rowKey={(record): string => {
          return `charters_row_${record.id}`;
        }}
        tableLayout="fixed"
        key="charters_table"
        columns={buildColumns()}
        dataSource={charters}
      />
    </>
  );
};

export default AdminChartersTable;
