import { Alert, Button, Form, InputNumber, Popconfirm, Switch } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { diff } from 'deep-object-diff';
import isEqual from 'lodash/isEqual';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTrashAlt } from 'react-icons/fa';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import MediaSelector from '../../../../../../components/media-selector/MediaSelector';
import { PROJECTS } from '../../../../../../Constants';
import { RootState } from '../../../../../../redux/RootState';
import { MediaFile } from '../../../../../../services/api/types/ChartersServiceTypes';
import CompaniesUtils from '../../../../../../utils/CompaniesUtils';
import MathUtils from '../../../../../../utils/MathUtils';
import ProjectUtils from '../../../../../../utils/ProjectUtils';
import { PipPositionCode } from '../../../../../../utils/types/AnimationTypes';
import { MediaType } from '../../../../../../utils/types/MediaTypes';
import {
  ProjectScene,
  ProjectSceneElement,
  ProjectSceneElements,
  ProjectSceneMedia,
} from '../../../../../../utils/types/ProjectTypes';
import { VideoTrim } from '../scene-timeline/utils/VideoTrimUtils';

type Props = {
  initialScene: ProjectScene;
  scene: ProjectScene;
  isSceneEdited: boolean;
  format?: string;
  sceneDuration: number;
  videoTrimValue: VideoTrim;
  onEditScene: (updatedScene: ProjectScene) => void;
  onSubmitScene: (updatedScene: ProjectScene) => void;
  onIsSceneEditedChange: (isEdited: boolean) => void;
  onTrimChange: (minmaxAnchors: VideoTrim) => void;
  onSelectElement: (e: ProjectSceneElement, skipConfirm?: boolean) => void;
  onDeleteSceneElement: () => void;
};

const useStyles = createUseStyles({
  form: {
    height: '90%',
    overflow: 'auto',
    paddingRight: 8,
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '6px',
      backgroundColor: 'rgb(123, 123, 123)',
      boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
    },
    '&::-webkit-scrollbar-track-piece': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      borderRadius: '6px',
    },

    '& .ant-form-item-label': {
      padding: 0,
    },
    '& .ant-form-item': {
      marginBottom: 10,
    },
  },
  actionsContainer: {
    position: 'absolute',
    bottom: 10,
    right: 0,
    '& > button:not(:last-child)': {
      marginRight: 10,
    },
  },
  deleteBackgroundVideoButton: {
    position: 'absolute',
    bottom: 10,
    '& .ant-btn': {
      lineHeight: 1,
    },
  },
  info: {
    padding: 4,
    '& .ant-alert-message': {
      color: '#094662 !important',
    },
  },
});

const SCENE_DEFAULT_DESCRIPTION = {
  en: 'You can give tips on the content of this scene.',
  fr: 'Vous pouvez donner des astuces sur cette scène.',
};

const ProjectSceneVideoOptions: FunctionComponent<Props> = ({
  initialScene,
  scene,
  isSceneEdited,
  format,
  videoTrimValue,
  onEditScene,
  onSubmitScene,
  onIsSceneEditedChange,
  sceneDuration,
  onTrimChange,
  onSelectElement,
  onDeleteSceneElement,
}: Props) => {
  const [initialValues, setInitialValues] = useState<Store>();
  const [trimEndIntern, setTrimEndIntern] = useState<number>();
  const [sceneBackgroundVideoIntern, setSceneBackgroundVideoIntern] = useState<MediaFile>();

  const { t } = useTranslation();
  const classes = useStyles();
  const [form] = Form.useForm();

  const companies = useSelector((state: RootState) => state.companies.list);
  const isKnlTeam = companies ? CompaniesUtils.checkIsKnlProfile(companies) : false;

  const hasScenePip = scene.pip !== undefined && scene.pip.media !== undefined;

  useEffect(() => {
    if (!initialScene.backgroundVideo) {
      return;
    }

    const formInitialValues = {
      sceneBackgroundVideo: initialScene.backgroundVideo.media,
      sceneBackgroundVideoAudioVolume: initialScene.backgroundVideo.audioVolume ?? 0,
      trimStartAt:
        initialScene.backgroundVideo.trimStartAt !== undefined ? initialScene.backgroundVideo.trimStartAt : 0,
      trimEndAt:
        initialScene.backgroundVideo.trimEndAt !== undefined ? initialScene.backgroundVideo.trimEndAt : sceneDuration,
    };

    setTrimEndIntern(
      initialScene.backgroundVideo.trimEndAt !== undefined ? initialScene.backgroundVideo.trimEndAt : sceneDuration
    );
    setSceneBackgroundVideoIntern(initialScene.backgroundVideo.media);
    setInitialValues(formInitialValues);
    form.setFieldsValue(formInitialValues);
  }, [initialScene, form]);

  useEffect(() => {
    if (!scene || !scene.backgroundVideo) {
      return;
    }

    form.setFieldsValue({
      trimStartAt: scene.backgroundVideo?.trimStartAt !== undefined ? scene.backgroundVideo?.trimStartAt : 0,
      trimEndAt: scene.backgroundVideo?.trimEndAt !== undefined ? scene.backgroundVideo?.trimEndAt : sceneDuration,
    });

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    checkIfSceneVideoEdited();
  }, [scene.backgroundVideo?.trimStartAt, scene.backgroundVideo?.trimEndAt, sceneDuration]);

  // If there is no backgroundVideo media and the media selector is canceled, we select the PIP element in the timeline
  const callbackOnCancelBackgroundVideoMediaModal = () => {
    const sceneBackgroundVideoMedia = form.getFieldValue('sceneBackgroundVideo');
    if (!sceneBackgroundVideoMedia && hasScenePip) {
      onSelectElement(ProjectSceneElements.PIP, true);
    }
  };

  const isBackgroundVideoEdited = () => {
    const initialVideo = initialScene.backgroundVideo?.media;
    const sceneBackgroundVideo = form.getFieldValue('sceneBackgroundVideo');

    return !isEqual(initialVideo, sceneBackgroundVideo);
  };

  const buildSceneFromForm = (isReset = false) => {
    const fields = form.getFieldsValue();
    const isThereATrim =
      (fields.trimStartAt !== undefined || fields.trimEndAt !== undefined) &&
      (fields.trimStartAt !== 0 || fields.trimEndAt !== sceneDuration);

    const newAnimationDelayValue = () => {
      if (!scene.animation) {
        return undefined;
      }

      if (isBackgroundVideoEdited()) {
        return 0;
      }

      if (isReset) {
        return initialScene.animation?.delay;
      }

      if (scene.animation?.delay && scene.animation?.delay > videoTrimValue.trimEnd - videoTrimValue.trimStart) {
        return videoTrimValue?.trimEnd - videoTrimValue?.trimStart;
      }

      return scene.animation.delay;
    };

    const newPipDelayValue = () => {
      if (!scene.pip) {
        return undefined;
      }

      if (isBackgroundVideoEdited()) {
        const newVideo = fields.sceneBackgroundVideo;
        return ProjectUtils.computeInitialPipDelayOverVideo(newVideo?.duration) || 0;
      }

      if (isReset) {
        return initialScene.pip?.delay;
      }

      if (scene.pip?.delay && scene.pip?.delay > videoTrimValue.trimEnd - videoTrimValue.trimStart) {
        return videoTrimValue?.trimEnd - videoTrimValue?.trimStart;
      }

      return scene.pip.delay;
    };

    const newPipDurationValue = () => {
      if (!scene.pip) {
        return undefined;
      }

      if (isBackgroundVideoEdited()) {
        const newVideo = fields.sceneBackgroundVideo;
        return (
          ProjectUtils.computeInitialPipDurationOverVideo(newVideo?.duration) || PROJECTS.PIP.PIP_ONLY_DEFAULT_DURATION
        );
      }

      if (isReset) {
        return initialScene.pip?.duration;
      }

      return scene.pip.duration;
    };

    const newSubtitlesValue = () => {
      if (!scene.subtitles) {
        return undefined;
      }

      if (isBackgroundVideoEdited()) {
        return { language: scene.subtitles.language, blocks: [] };
      }

      if (isReset) {
        return initialScene.subtitles;
      }

      return scene.subtitles;
    };

    let newPipDelay;
    let newPipDuration;
    const newSubtitles = newSubtitlesValue();
    if (isBackgroundVideoEdited()) {
      newPipDelay = scene.pip?.media?.mediaType === MediaType.VIDEO ? 0 : newPipDelayValue();
      newPipDuration = scene.pip?.media?.mediaType === MediaType.VIDEO ? scene.pip?.duration : newPipDurationValue();
    } else {
      newPipDelay = newPipDelayValue();
      newPipDuration = newPipDurationValue();
    }

    return {
      ...scene,
      ...(scene.animation ? { animation: { ...scene.animation, delay: newAnimationDelayValue() } } : undefined),
      ...(scene.pip ? { pip: { ...scene.pip, delay: newPipDelay, duration: newPipDuration } } : undefined),
      backgroundVideo: fields.sceneBackgroundVideo
        ? {
            ...scene.backgroundVideo,
            media: fields.sceneBackgroundVideo,
            audioVolume: fields.sceneBackgroundVideoAudioVolume ? 1 : 0,
            trimStartAt: isThereATrim ? fields.trimStartAt : undefined,
            trimEndAt: isThereATrim ? fields.trimEndAt : undefined,
          }
        : undefined,
      subtitles: newSubtitles,
    };
  };

  // Callback to see if the video options have been changed and differ from the ones of the
  // initialScene
  const [checkIfSceneVideoEdited] = useDebouncedCallback(() => {
    // Get the initial scene values
    const initialVideo = initialScene.backgroundVideo?.media;
    const initialAudioVolume = initialScene.backgroundVideo?.audioVolume;
    const initialTrimStartAt = initialScene.backgroundVideo?.trimStartAt;
    const initialTrimEndAt = initialScene.backgroundVideo?.trimEndAt;

    // Get the field values
    const sceneBackgroundVideo = form.getFieldValue('sceneBackgroundVideo');
    const sceneBackgroundVideoAudioVolume = form.getFieldValue('sceneBackgroundVideoAudioVolume') ? 1 : 0;
    const sceneBackgroundVideoTrimStartAt = form.getFieldValue('trimStartAt');
    const sceneBackgroundVideoTrimEndAt = form.getFieldValue('trimEndAt');

    // Compare the initial scene video params with the ones from the form
    const backgroundVideoIsEqual = isEqual(initialVideo, sceneBackgroundVideo);
    const audioVolumeIsEqual = initialAudioVolume === sceneBackgroundVideoAudioVolume;
    const trimStartValueIsEqual =
      initialTrimStartAt === sceneBackgroundVideoTrimStartAt ||
      (initialTrimStartAt === undefined && sceneBackgroundVideoTrimStartAt === 0);
    const trimEndValueIsEqual =
      initialTrimEndAt === sceneBackgroundVideoTrimEndAt ||
      (initialTrimEndAt === undefined && sceneBackgroundVideoTrimEndAt === sceneDuration);

    // Check if the video was edited
    const wasNotEdited = backgroundVideoIsEqual && audioVolumeIsEqual && trimStartValueIsEqual && trimEndValueIsEqual;

    if (wasNotEdited) {
      onIsSceneEditedChange(false);
    } else {
      onIsSceneEditedChange(true);
      if (!backgroundVideoIsEqual) {
        const wasBackgroundVideoMediaUpdated = !(
          sceneBackgroundVideo?.id === sceneBackgroundVideoIntern?.id &&
          isEqual(sceneBackgroundVideo?.croppedArea, sceneBackgroundVideoIntern?.croppedArea)
        );
        if (wasBackgroundVideoMediaUpdated) {
          setSceneBackgroundVideoIntern(scene.backgroundVideo?.media);
          form.setFieldsValue({
            trimStartAt: 0,
            trimEndAt: sceneBackgroundVideo.duration,
            sceneBackgroundVideoAudioVolume: initialAudioVolume,
          });
        }
      }
      // Log the difference for KnlTeam (ease of debug)
      if (isKnlTeam) {
        console.groupCollapsed('ℹ️ Video was edited');
        console.log(
          'Video media difference: ',
          !backgroundVideoIsEqual && initialVideo ? diff(initialVideo, sceneBackgroundVideo) : undefined
        );
        console.log(
          'Video audioVolume difference: ',
          !audioVolumeIsEqual
            ? `initial value (${initialAudioVolume}) ≠ form value (${sceneBackgroundVideoAudioVolume})`
            : undefined
        );
        console.log(
          'Video trim start value difference: ',
          !trimStartValueIsEqual
            ? `initial value (${initialTrimStartAt}) ≠ form value (${sceneBackgroundVideoTrimStartAt})`
            : undefined
        );
        console.log(
          'Video trim end value difference: ',
          !trimEndValueIsEqual
            ? `initial value (${initialTrimEndAt}) ≠ form value (${sceneBackgroundVideoTrimEndAt})`
            : undefined
        );
        console.groupEnd();
      }
    }
  }, 0);

  const buildAndEditScene = (isReset = false) => {
    const updatedScene = buildSceneFromForm(isReset);
    onEditScene(updatedScene);
  };

  // 500 ms after a form field was changed, we call the `buildAndEditScene` callback with the "temporary" scene corresponding
  // to the unsaved scene
  const [delayedBuildAndEditScene] = useDebouncedCallback(() => {
    buildAndEditScene();
  }, 500);

  const onValuesChange = (changedValues: Store): void => {
    // Check if the values in the form differ from the initial scene ones
    checkIfSceneVideoEdited();

    // Depending on the updated field, delayed the callback or not
    if (
      changedValues.sceneBackgroundVideoAudioVolume !== undefined ||
      changedValues.sceneBackgroundVideo !== undefined
    ) {
      buildAndEditScene();
    } else {
      delayedBuildAndEditScene();
    }
  };

  const onReset = (): void => {
    form.resetFields();
    onIsSceneEditedChange(false);
    // If after resetting the form, there is no sceneBackgroundVideo in the form, it means the initial scene
    // only contained a PIP (no backgroundVideo), so we select back the PIP in the timeline
    if (form.getFieldValue('sceneBackgroundVideo') === undefined) {
      onSelectElement(ProjectSceneElements.PIP, true);
    }

    // Reset the trim values
    if (initialScene) {
      if (
        initialScene.backgroundVideo?.trimStartAt !== undefined &&
        initialScene.backgroundVideo?.trimEndAt !== undefined
      ) {
        onTrimChange({
          trimStart: initialScene.backgroundVideo.trimStartAt,
          trimEnd: initialScene.backgroundVideo?.trimEndAt,
        });
      }
      if (
        initialScene.backgroundVideo?.trimStartAt === undefined &&
        initialScene.backgroundVideo?.trimEndAt === undefined
      ) {
        onTrimChange({
          trimStart: 0,
          trimEnd: sceneDuration,
        });
      }

      // Reset the changes to the project scene being edited
      buildAndEditScene(true);
    }
  };

  const onSubmit = (): void => {
    form
      .validateFields()
      .then(() => {
        const updatedScene = buildSceneFromForm();

        if (!updatedScene) {
          return;
        }

        onSubmitScene(updatedScene);
        onIsSceneEditedChange(false);
      })
      .catch((e) => {
        log.error(e);
      });
  };

  const [delayedValidatorAndFormatter] = useDebouncedCallback((val: number): void => {
    const formattedValue = MathUtils.formatInputValueWithXDecimals(val, 3);

    onTrimChange({
      trimStart: videoTrimValue.trimStart,
      trimEnd: formattedValue,
    });

    setTrimEndIntern(val);

    form.setFieldsValue({
      trimEndAt: formattedValue,
    });
  }, 500);

  const handleTrimEndChange = (value: number) => {
    if (trimEndIntern === value) {
      return;
    }

    if (!value) {
      const initialTrimEnd =
        initialScene?.backgroundVideo?.trimEndAt !== undefined
          ? initialScene?.backgroundVideo?.trimEndAt
          : sceneDuration;
      form.setFieldsValue({
        trimEndAt: MathUtils.formatInputValueWithXDecimals(initialTrimEnd, 3),
      });
      setTrimEndIntern(MathUtils.formatInputValueWithXDecimals(initialTrimEnd, 3));

      return;
    }

    delayedValidatorAndFormatter(value);
  };

  const handleTrimStartChange = (value: number) => {
    if (value === undefined || value === null) {
      const initialTrimStart =
        initialScene?.backgroundVideo?.trimStartAt !== undefined ? initialScene?.backgroundVideo?.trimStartAt : 0;

      form.setFieldsValue({
        trimStartAt: MathUtils.formatInputValueWithXDecimals(initialTrimStart, 3),
      });

      return;
    }

    onTrimChange({
      trimStart: value,
      trimEnd: videoTrimValue.trimEnd,
    });
  };

  const onDeleteBackgroundVideo = (): void => {
    let updatedScene = {
      ...scene,
      backgroundVideo: undefined,
    };
    onReset();
    onDeleteSceneElement();

    if (hasScenePip) {
      // If the scene has a PIP whose duration is greater than the maximal allowed PIP-only duration (10 sec), we force it
      // to 5 seconds. We also ensure its delay is reset to 0 sec
      const isPipVideo = updatedScene.pip?.media.mediaType === MediaType.VIDEO;
      let newPipDuration;

      if (isPipVideo) {
        newPipDuration = (updatedScene.pip?.trimEndAt || 0) - (updatedScene.pip?.trimStartAt || 0);
      } else {
        newPipDuration =
          updatedScene.pip!.duration! > PROJECTS.PIP.PIP_ONLY_MAX_DURATION
            ? PROJECTS.PIP.PIP_ONLY_DEFAULT_DURATION
            : updatedScene.pip!.duration;
      }

      updatedScene = {
        ...updatedScene,
        pip: {
          ...updatedScene.pip,
          duration: newPipDuration,
          delay: 0,
          trimStartAt: 0,
          positionCode: PipPositionCode.FULL_SCREEN,
          positionValue: PROJECTS.PIP.PIP_DEFAULT_POSITION,
          size: PROJECTS.PIP.PIP_DEFAULT_SIZE,
        } as ProjectSceneMedia,
      };

      // Then we select the PIP in the timeline
      onSelectElement(ProjectSceneElements.PIP, true);
    }

    onSubmitScene(updatedScene);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      className={classes.form}
      size="small"
      initialValues={initialValues}
      onValuesChange={onValuesChange}
    >
      <Form.Item
        name="sceneBackgroundVideo"
        label={t('charters.projects.projectEditor.sceneEditor.fields.video.videoLabel')}
        rules={[{ required: true }]}
      >
        <MediaSelector
          fieldLabel={t('charters.projects.projectEditor.sceneEditor.fields.video.videoLabel')}
          mediaTypes={[MediaType.VIDEO]}
          format={format}
          defaultVisible={!(scene.backgroundVideo && scene.backgroundVideo.media)}
          disableReset
          enableWebcamRecording
          webcamRecordingOptions={{
            recommendedTime: scene.videoRecommendedTime,
            overlayImage: scene.overlay?.phoneUrl,
          }}
          callbackOnCancelModal={callbackOnCancelBackgroundVideoMediaModal}
        />
      </Form.Item>
      <Form.Item
        name="sceneBackgroundVideoAudioVolume"
        label={t('charters.projects.projectEditor.sceneEditor.fields.video.audioVolumeLabel')}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      {scene.backgroundVideo?.media && (
        <>
          <Form.Item
            label={t('charters.projects.projectEditor.sceneEditor.fields.video.trimStartAtLabel')}
            name="trimStartAt"
            rules={[
              {
                required: true,
                min: 0,
                max: ProjectUtils.getMaximumValueOfTrimStartAt(scene, sceneDuration),
                validateTrigger: 'onChange',
                type: 'number',
              },
            ]}
          >
            <InputNumber
              min={0}
              max={ProjectUtils.getMaximumValueOfTrimStartAt(scene, sceneDuration)}
              step={0.01}
              formatter={(value: number | undefined) => MathUtils.formatInputValueWithTwoDecimals(value)}
              onChange={handleTrimStartChange}
            />
          </Form.Item>

          <Form.Item
            label={t('charters.projects.projectEditor.sceneEditor.fields.video.trimEndAtLabel')}
            name="trimEndAt"
            rules={[
              {
                required: true,
                min: ProjectUtils.getMinimumValueOfTrimEndAt(scene),
                max: sceneDuration,
                validateTrigger: 'onChange',
                type: 'number',
              },
            ]}
          >
            <InputNumber
              min={ProjectUtils.getMinimumValueOfTrimEndAt(scene)}
              max={sceneDuration}
              step={0.01}
              formatter={(value: number | undefined) => MathUtils.formatInputValueWithTwoDecimals(value)?.toString()}
              onChange={handleTrimEndChange}
            />
          </Form.Item>
        </>
      )}

      {scene.description !== SCENE_DEFAULT_DESCRIPTION.fr && scene.description !== SCENE_DEFAULT_DESCRIPTION.en && (
        <Alert
          className={classes.info}
          message={
            <>
              <p>{t('charters.projects.projectEditor.sceneEditor.fields.video.advice')}</p>
              <span>{scene.description}</span>
            </>
          }
          type="info"
        />
      )}

      {/* We can delete the backgroundVideo only if the scene has a PIP (one of the background video or the PIP is mandatory) */}
      {hasScenePip && scene.pip?.media?.mediaType !== MediaType.VIDEO && (
        <div className={classes.deleteBackgroundVideoButton}>
          <Popconfirm
            title={t('charters.projects.projectEditor.sceneEditor.fields.video.deleteVideoPopConfirm')}
            onConfirm={onDeleteBackgroundVideo}
            okText={t('global.yes')}
            cancelText={t('global.no')}
          >
            <Button type="primary" danger shape="circle" icon={<FaTrashAlt />} />
          </Popconfirm>
        </div>
      )}

      <div className={classes.actionsContainer}>
        <Button disabled={!isSceneEdited} onClick={onReset}>
          {t('global.reset')}
        </Button>
        <Button disabled={!isSceneEdited} type="primary" onClick={onSubmit}>
          {t('global.save')}
        </Button>
      </div>
    </Form>
  );
};

export default ProjectSceneVideoOptions;
