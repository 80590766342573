import React from 'react';

type Coordinate = {
  x: number;
  y: number;
};

export default class WindowUtils {
  static getRelativeCoordinates(event: React.MouseEvent<HTMLDivElement>, referenceElement: HTMLElement): Coordinate {
    const position = {
      x: event.pageX,
      y: event.pageY,
    };

    const offset = {
      left: referenceElement.offsetLeft,
      top: referenceElement.offsetTop,
    };

    let reference = referenceElement.offsetParent;

    while (reference) {
      if (reference instanceof HTMLElement) {
        offset.left += reference.offsetLeft;
        offset.top += reference.offsetTop;
        reference = reference.offsetParent;
      }
    }

    return {
      x: position.x - offset.left,
      y: position.y - offset.top,
    };
  }
}
