import { Button, Col, Form, message } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { CancelTokenSource } from 'axios';
import { isEqual } from 'lodash';
import log from 'loglevel';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import SelectAsRadio from '../../../components/form/SelectAsRadio';
import { EMAIL_LANGUAGE_OPTIONS, EMAIL_SENDER_OPTIONS, LOGGING_EVENT } from '../../../Constants';
import useUnsavedChangesNotification from '../../../hooks/useUnsavedChangesNotification';
import { RootState } from '../../../redux/RootState';
import { APIManager } from '../../../services/api/APIManager';
import {
  getCompanyNotificationParameters,
  updateCompanyNotificationParameters,
} from '../../../services/api/CompaniesService';
import { APICompanyNotificationParameters } from '../../../services/api/types/CompaniesServiceTypes';

type Props = {
  isKnlTeam: boolean;
};

const useStyles = createUseStyles({
  buttonContainer: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: 8,
    },
  },
});

const NotificationParameters: FunctionComponent<Props> = ({ isKnlTeam }: Props) => {
  const [initialParameters, setInitialParameters] = useState<APICompanyNotificationParameters>();
  const [isAsyncLoading, setIsAsyncLoading] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [form] = Form.useForm();

  const { t } = useTranslation();
  const classes = useStyles();
  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());
  useUnsavedChangesNotification(unsavedChanges, t('notificationConfigs.parameters.unsavedParameters'), [
    unsavedChanges,
  ]);

  const company = useSelector((state: RootState) => state.companies.current);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);

  useEffect(() => {
    const cancelTokenSource = cancelTokenSourceRef.current;

    return (): void => {
      cancelTokenSource.cancel('Cancelled fetching company notification parameters due to component unmount.');
    };
  }, []);

  useEffect(() => {
    if (!company) {
      return;
    }

    const cancelTokenSource = cancelTokenSourceRef.current;
    getCompanyNotificationParameters(company.id, cancelTokenSource)
      .then((res) => {
        setInitialParameters({
          language: res.data.language ?? '$BROWSER_LANGUAGE',
          sender: res.data.sender ?? undefined,
        });
      })
      .catch((e) => {
        log.debug('Error while fetching company notification parameters', e);
      });
  }, [company]);

  const initialValues = {
    emailLanguage: (initialParameters && initialParameters.language) || '$BROWSER_LANGUAGE',
    emailSender: (initialParameters && initialParameters.sender) || 'default',
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 10 },
      xl: { span: 8 },
      xxl: { span: 6, offset: 2 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 12 },
      xl: { span: 12 },
      xxl: { span: 10 },
    },
  };

  const handleFormSubmit = (): void => {
    if (!company) {
      return;
    }

    form.validateFields().then((values) => {
      const formattedValues = {
        language: values.emailLanguage,
        sender: values.emailSender === 'default' ? undefined : values.emailSender,
      };

      setIsAsyncLoading(true);
      updateCompanyNotificationParameters(company.id, formattedValues, cancelTokenSourceRef.current)
        .then(() => {
          loggingManager.logEvent(LOGGING_EVENT.UPDATE_NOTIFICATION_PARAMETERS, {
            language: formattedValues.language,
            sender: formattedValues.sender || 'undefined',
            companyId: company.id,
          });

          message.success(t('notificationConfigs.parameters.updateSuccess'));
          setUnsavedChanges(false);
          setInitialParameters(formattedValues);
        })
        .catch(() => {
          message.error(t('notificationConfigs.parameters.updateError'));
        })
        .finally(() => {
          setIsAsyncLoading(false);
        });
    });
  };

  const onReset = (): void => {
    form.resetFields();
    setUnsavedChanges(false);
  };

  const onFormValuesChange = (changedFields: Store, newValues: Store): void => {
    if (!isEqual(newValues, initialValues)) {
      setUnsavedChanges(true);
    } else {
      setUnsavedChanges(false);
      form.resetFields();
    }
  };

  if (!(initialParameters && initialValues)) {
    return null;
  }

  return (
    <Form form={form} {...formItemLayout} initialValues={initialValues} onValuesChange={onFormValuesChange}>
      <SelectAsRadio
        name="emailLanguage"
        options={EMAIL_LANGUAGE_OPTIONS}
        label={t('notificationConfigs.parameters.emailLanguages.field')}
        extra={t('notificationConfigs.parameters.emailLanguages.description')}
      />

      {isKnlTeam && (
        <SelectAsRadio
          name="emailSender"
          options={EMAIL_SENDER_OPTIONS}
          label={t('notificationConfigs.parameters.emailSender.field')}
          extra={t('notificationConfigs.parameters.emailSender.description')}
        />
      )}

      <Col sm={24} md={22} xl={20} xxl={18}>
        <span className={classes.buttonContainer}>
          <Button onClick={onReset} disabled={!unsavedChanges}>
            {t('global.reset')}
          </Button>
          <Button type="primary" onClick={handleFormSubmit} loading={isAsyncLoading} disabled={!unsavedChanges}>
            {t('global.submit')}
          </Button>
        </span>
      </Col>
    </Form>
  );
};

export default NotificationParameters;
