import { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { Roles } from '../../core/rule/RolesTypes';
import { SubtitleBlock } from '../../utils/types/ProjectTypes';
import { APIManager } from './APIManager';
import {
  APICharterResponse,
  APICloneCharterScenarioResult,
  APICreateCharterProjectParams,
  APICreateCharterProjectResult,
  APICreateCharterScenarioParams,
  APICreateCharterScenarioResult,
  APICreateCharterScenarioVariantParams,
  APICreateCharterScenarioVariantResult,
  APIFetchAnimationByCharterIdParams,
  APIGetCharterAssetSignedUrlToUploadParams,
  APIGetCharterAssetSignedUrlToUploadResult,
  APIGetCharterFontSignedUrlToUploadResult,
  APIGetCharterMediaResponse,
  APIGetCharterMediaSignedUrlToUploadParams,
  APIGetCharterMediaSignedUrlToUploadResult,
  APIGetCharterPermissionsResponse,
  APIGetCharterProjectDetailsByIdResult,
  APIGetCharterProjectsResult,
  APIGetCharterScenarioDetailsByIdResult,
  APIGetCharterScenariosResult,
  APIGetPublicScenariosResult,
  APIPatchCharterThemeFontsByCharterIdParams,
  APIPatchCharterThemeFontsByCharterIdResponse,
  APIPatchCharterUsersByCharterIdParams,
  APIPatchCharterUsersByCharterIdResult,
  APIProcessCharterMediaCroppedUrlByIdResult,
  APIShareCharterProjectPubliclyResult,
  APIUpdateCharterByIdParams,
  APIUpdateCharterByIdResponse,
  APIUpdateCharterColorsParams,
  APIUpdateCharterColorsResponse,
  APIUpdateCharterPermissionResponse,
  APIUpdateCharterPermissionsParams,
  APIUpdateCharterProjectParams,
  APIUpdateCharterProjectResult,
  APIUpdateCharterScenarioMetadataParams,
  APIUpdateCharterScenarioMetadataResult,
  APIUpdateCharterScenarioVariantParams,
  APIUpdateCharterScenarioVariantResult,
  APIUsersResponse,
  Asset,
  MediaCroppedArea,
  MediaFile,
} from './types/ChartersServiceTypes';
import { APICreateCompanyCharterResponse } from './types/CompaniesServiceTypes';
import { APIGetFontFamiliesResponse } from './types/FontFamiliesServiceTypes';
import {
  APIAddUserToCharterResult,
  APICreateUserParams,
  APIRemoveUserFromCharterByIdResult,
} from './types/UsersServiceTypes';

const getCharterById = (
  charterId: number,
  cancelSource?: CancelTokenSource,
  filterAll = false,
  statusAll = false
): Promise<APICharterResponse> => {
  const apiManager = APIManager.getInstance();

  const filter = filterAll ? 'filter=not-archived' : undefined;
  const status = statusAll ? 'status=all' : undefined;
  const query = [filter, status].filter((param) => !!param).join('&');
  const queryParams = query ? `?${query}` : '';

  return apiManager.get(`/charters/${charterId}${queryParams}`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const getUsersByCharterId = (charterId: number, cancelSource?: CancelTokenSource): Promise<APIUsersResponse> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(`/charters/${charterId}/users`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const postUserInCharter = (
  params: APICreateUserParams,
  cancelSource?: CancelTokenSource
): Promise<APIAddUserToCharterResult> => {
  const apiManager = APIManager.getInstance();

  const { charterId, id, ...data } = params;
  return apiManager.post(`/charters/${charterId}/users/${id}`, data, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const patchUserByCharterIdAndUserId = (
  charterId: number,
  userId: string,
  data: APIPatchCharterUsersByCharterIdParams,
  cancelSource?: CancelTokenSource
): Promise<APIPatchCharterUsersByCharterIdResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.patch(`/charters/${charterId}/users/${userId}`, data, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const updateCharterUserRoleByCharterId = (
  charterId: number,
  userId: string,
  newRole: Roles
): Promise<APIPatchCharterUsersByCharterIdResult> => {
  const data: APIPatchCharterUsersByCharterIdParams = {
    role: newRole as Roles,
  };

  return patchUserByCharterIdAndUserId(charterId, userId, data);
};

const updateCharterUserStatusByCharterId = (
  charterId: number,
  userId: string,
  newIsActive: boolean
): Promise<APIPatchCharterUsersByCharterIdResult> => {
  const data: APIPatchCharterUsersByCharterIdParams = {
    isActive: newIsActive,
  };

  return patchUserByCharterIdAndUserId(charterId, userId, data);
};

const deleteUserFromCharter = (charterId: number, userId: string): Promise<APIRemoveUserFromCharterByIdResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.delete(`/charters/${charterId}/users/${userId}`);
};

const updateCharterById = (
  charterId: number,
  charterData: APIUpdateCharterByIdParams,
  cancelSource?: CancelTokenSource
): Promise<APIUpdateCharterByIdResponse> => {
  const apiManager = APIManager.getInstance();

  return apiManager.patch(`/charters/${charterId}`, charterData, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const updateCharterColors = (
  charterId: number,
  data: APIUpdateCharterColorsParams,
  cancelSource?: CancelTokenSource
): Promise<APIUpdateCharterColorsResponse> => {
  const apiManager = APIManager.getInstance();

  return apiManager.patch(`/charters/${charterId}/colors`, data, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const patchAssets = (
  charterId: number,
  assetId: number,
  data: { isActive?: boolean; isArchived?: boolean },
  cancelSource?: CancelTokenSource
): Promise<Asset> => {
  const apiManager = APIManager.getInstance();

  return apiManager.patch(`/charters/${charterId}/assets/${assetId}`, data, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const getCharterAssetSignedUrlToUpload = (
  data: APIGetCharterAssetSignedUrlToUploadParams
): Promise<APIGetCharterAssetSignedUrlToUploadResult> => {
  return APIManager.getInstance()
    .get(
      `/charters/${data.charterId}/assets/initiate-upload?charterId=${data.charterId}&assetType=${
        data.assetType
      }&filename=${encodeURIComponent(data.filename)}`
    )
    .then((response) => {
      return response.data as APIGetCharterAssetSignedUrlToUploadResult;
    });
};

const getCharterFontFamilies = (
  charterId: number,
  cancelSource?: CancelTokenSource
): Promise<APIGetFontFamiliesResponse> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(`/charters/${charterId}/fontfamilies`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const patchCharterThemeFonts = (
  charterId: number,
  data: APIPatchCharterThemeFontsByCharterIdParams,
  cancelSource?: CancelTokenSource
): Promise<APIPatchCharterThemeFontsByCharterIdResponse> => {
  const apiManager = APIManager.getInstance();

  return apiManager.patch(`/charters/${charterId}/themefonts`, data, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const resetCharterThemeFonts = (charterId: number, cancelSource?: CancelTokenSource): Promise<void> => {
  const apiManager = APIManager.getInstance();

  return apiManager.delete(`/charters/${charterId}/themefonts`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const getCharterFontSignedUrlToUpload = (
  charterId: number,
  fileName: string
): Promise<APIGetCharterFontSignedUrlToUploadResult> => {
  return APIManager.getInstance()
    .get(`/charters/${charterId}/fontfamilies/initiate-upload?filename=${encodeURIComponent(fileName)}`)
    .then((response) => {
      return response.data as APIGetCharterFontSignedUrlToUploadResult;
    });
};

const getCharterPermissions = (
  charterId: number,
  lang = 'en',
  cancelSource?: CancelTokenSource
): Promise<APIGetCharterPermissionsResponse> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(`/charters/${charterId}/permissions?lang=${lang}`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const updateCharterPermissions = (
  charterId: number,
  data: APIUpdateCharterPermissionsParams,
  cancelSource?: CancelTokenSource
): Promise<APIUpdateCharterPermissionResponse> => {
  const apiManager = APIManager.getInstance();

  return apiManager.patch(`/charters/${charterId}/permissions`, data, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const getCharterMedia = (
  charterId: number,
  filterAll = false,
  cancelSource?: CancelTokenSource
): Promise<APIGetCharterMediaResponse> => {
  const apiManager = APIManager.getInstance();

  const filter = filterAll ? 'filter=not-archived' : undefined;
  const queryParams = filter ? `?${filter}` : '';

  return apiManager.get(`/charters/${charterId}/media${queryParams}`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const updateCharterMedia = (
  charterId: number,
  mediaId: number,
  data: { isArchived?: boolean },
  cancelSource?: CancelTokenSource
): Promise<MediaFile> => {
  const apiManager = APIManager.getInstance();

  return apiManager.patch(`/charters/${charterId}/media/${mediaId}`, data, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const getCharterMediaSignedUrlToUpload = (
  data: APIGetCharterMediaSignedUrlToUploadParams
): Promise<APIGetCharterMediaSignedUrlToUploadResult> => {
  const isMirroredOptionalParam = data.isMirrored ? `&isMirrored=${data.isMirrored}` : '';

  return APIManager.getInstance()
    .get(
      `/charters/${data.charterId}/media/initiate-upload?filename=${encodeURIComponent(data.filename)}&mediaType=${
        data.mediaType
      }${isMirroredOptionalParam}`
    )
    .then((response) => {
      return response.data as APIGetCharterMediaSignedUrlToUploadResult;
    });
};

const getPublicScenarios = (cancelSource?: CancelTokenSource): Promise<APIGetPublicScenariosResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(`/scenarios`, { cancelToken: cancelSource?.token || undefined }).then((response) => {
    return response.data as APIGetPublicScenariosResult;
  });
};

const getCharterScenarios = (
  charterId: number,
  cancelSource?: CancelTokenSource
): Promise<APIGetCharterScenariosResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager
    .get(`/charters/${charterId}/scenarios`, {
      cancelToken: cancelSource?.token || undefined,
    })
    .then((response) => {
      return response.data as APIGetCharterScenariosResult;
    });
};

const getCharterScenariosForMe = (
  charterId: number,
  lang: string,
  cancelSource?: CancelTokenSource
): Promise<APIGetCharterScenariosResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager
    .get(`/charters/${charterId}/scenarios/me?lang=${lang}&version=2`, {
      cancelToken: cancelSource?.token || undefined,
    })
    .then((response) => {
      return response.data as APIGetCharterScenariosResult;
    });
};

const getCharterScenarioDetailsById = (
  charterId: number,
  scenarioId: number,
  cancelSource?: CancelTokenSource
): Promise<APIGetCharterScenarioDetailsByIdResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager
    .get(`/charters/${charterId}/scenarios/${scenarioId}`, {
      cancelToken: cancelSource?.token || undefined,
    })
    .then((response) => {
      return response.data as APIGetCharterScenarioDetailsByIdResult;
    });
};

const updateCharterScenarioMetadata = (
  charterId: number,
  scenarioId: number,
  data: APIUpdateCharterScenarioMetadataParams,
  axiosOverride?: AxiosRequestConfig,
  cancelSource?: CancelTokenSource
): Promise<APIUpdateCharterScenarioMetadataResult> => {
  let apiManager = APIManager.getInstance();
  if (axiosOverride) {
    apiManager = APIManager.overrideAxiosInstance(apiManager, axiosOverride);
  }

  return apiManager
    .patch(`/charters/${charterId}/scenarios/${scenarioId}`, data, {
      cancelToken: cancelSource?.token || undefined,
    })
    .then((response) => {
      return response.data as APIUpdateCharterScenarioMetadataResult;
    });
};

const createCharterScenario = (
  charterId: number,
  data: APICreateCharterScenarioParams,
  cancelSource?: CancelTokenSource
): Promise<APICreateCharterScenarioResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager
    .post(`/charters/${charterId}/scenarios`, data, {
      cancelToken: cancelSource?.token || undefined,
    })
    .then((response) => {
      return response.data as APICreateCharterScenarioResult;
    });
};

const deleteCharterScenario = (
  charterId: number,
  scenarioId: number,
  cancelSource?: CancelTokenSource
): Promise<void> => {
  const apiManager = APIManager.getInstance();

  return apiManager.delete(`/charters/${charterId}/scenarios/${scenarioId}`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const cloneCharterScenario = (
  charterId: number,
  scenarioId: number,
  cancelSource?: CancelTokenSource
): Promise<APICloneCharterScenarioResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager.post(`/charters/${charterId}/scenarios/${scenarioId}/clone`, null, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const createCharterScenarioVariant = (
  charterId: number,
  scenarioId: number,
  data: APICreateCharterScenarioVariantParams,
  cancelSource?: CancelTokenSource
): Promise<APICreateCharterScenarioVariantResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager
    .post(`/charters/${charterId}/scenarios/${scenarioId}/variants`, data, {
      cancelToken: cancelSource?.token || undefined,
    })
    .then((response) => {
      return response.data as APICreateCharterScenarioVariantResult;
    });
};

const updateCharterScenarioVariant = (
  charterId: number,
  scenarioId: number,
  variantId: number,
  data: APIUpdateCharterScenarioVariantParams,
  cancelSource?: CancelTokenSource
): Promise<APIUpdateCharterScenarioVariantResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager
    .patch(`/charters/${charterId}/scenarios/${scenarioId}/variants/${variantId}`, data, {
      cancelToken: cancelSource?.token || undefined,
    })
    .then((response) => {
      return response.data as APIUpdateCharterScenarioVariantResult;
    });
};

const deleteCharterById = (charterId: number, cancelSource?: CancelTokenSource): Promise<void> => {
  const apiManager = APIManager.getInstance();

  return apiManager.delete(`/charters/${charterId}`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const deleteCharterScenarioVariant = (
  charterId: number,
  scenarioId: number,
  variantId: number,
  cancelSource?: CancelTokenSource
): Promise<void> => {
  const apiManager = APIManager.getInstance();

  return apiManager.delete(`/charters/${charterId}/scenarios/${scenarioId}/variants/${variantId}`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const processCharterMediaCroppedUrlById = (
  charterId: number,
  mediaId: number,
  croppedArea: MediaCroppedArea,
  messageId?: string,
  cancelSource?: CancelTokenSource
): Promise<APIProcessCharterMediaCroppedUrlByIdResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager
    .get(
      `/charters/${charterId}/media/${mediaId}/crop?x=${croppedArea.x}&y=${croppedArea.y}&height=${
        croppedArea.height
      }&width=${croppedArea.width}${messageId ? `&messageId=${messageId}` : ''}`,
      {
        cancelToken: cancelSource?.token || undefined,
      }
    )
    .then((response) => {
      return response.data as APIProcessCharterMediaCroppedUrlByIdResult;
    });
};

const fetchAnimationByCharterIdAndParams = (
  charterId: number,
  params: APIFetchAnimationByCharterIdParams,
  cancelSource?: CancelTokenSource
): Promise<any> => {
  const apiManager = APIManager.getInstance();

  return apiManager
    .post(`/charters/${charterId}/animations`, params, {
      cancelToken: cancelSource?.token || undefined,
    })
    .then((response) => {
      return response.data as any;
    });
};

const getCharterProjects = (
  charterId: number,
  cancelSource?: CancelTokenSource
): Promise<APIGetCharterProjectsResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager
    .get(`/charters/${charterId}/projects`, {
      cancelToken: cancelSource?.token || undefined,
    })
    .then((response) => {
      return response.data as APIGetCharterProjectsResult;
    });
};

const getCharterProjectDetailsById = (
  charterId: number,
  projectId: number,
  cancelSource?: CancelTokenSource
): Promise<APIGetCharterProjectDetailsByIdResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager
    .get(`/charters/${charterId}/projects/${projectId}`, {
      cancelToken: cancelSource?.token || undefined,
    })
    .then((response) => {
      return response.data as APIGetCharterProjectDetailsByIdResult;
    });
};

const postSubtitleBlockForProjectScene = (
  charterId: number,
  projectId: number,
  sceneId: number,
  data: any,
  cancelSource?: CancelTokenSource
): Promise<SubtitleBlock> => {
  const apiManager = APIManager.getInstance();

  return apiManager
    .post(`/charters/${charterId}/projects/${projectId}/scenes/${sceneId}/subtitles/blocks`, data, {
      cancelToken: cancelSource?.token || undefined,
    })
    .then((response) => {
      return response.data as SubtitleBlock;
    });
};

const createCharterProject = (
  charterId: number,
  data: APICreateCharterProjectParams,
  cancelSource?: CancelTokenSource
): Promise<APICreateCharterProjectResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager
    .post(`/charters/${charterId}/projects`, data, {
      cancelToken: cancelSource?.token || undefined,
    })
    .then((response) => {
      return response.data as APICreateCharterProjectResult;
    });
};

const updateCharterProject = (
  charterId: number,
  projectId: number,
  data: APIUpdateCharterProjectParams,
  cancelSource?: CancelTokenSource
): Promise<APIUpdateCharterProjectResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager
    .patch(`/charters/${charterId}/projects/${projectId}`, data, {
      cancelToken: cancelSource?.token || undefined,
    })
    .then((response) => {
      return response.data as APIUpdateCharterProjectResult;
    });
};

const deleteCharterProject = (
  charterId: number,
  projectId: number,
  cancelSource?: CancelTokenSource
): Promise<void> => {
  const apiManager = APIManager.getInstance();

  return apiManager.delete(`/charters/${charterId}/projects/${projectId}`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const cloneCharterProject = (charterId: number, projectId: number, cancelSource?: CancelTokenSource): Promise<void> => {
  const apiManager = APIManager.getInstance();

  return apiManager.post(
    `/charters/${charterId}/projects/${projectId}/clone`,
    {},
    {
      cancelToken: cancelSource?.token || undefined,
    }
  );
};

const generateCharterProjectFinalVideo = (
  charterId: number,
  projectId: number,
  cancelSource?: CancelTokenSource
): Promise<void> => {
  const apiManager = APIManager.getInstance();

  return apiManager.get(`/charters/${charterId}/projects/${projectId}/finalize`, {
    cancelToken: cancelSource?.token || undefined,
  });
};

const shareCharterProjectPublicly = (
  charterId: number,
  projectId: number,
  cancelSource?: CancelTokenSource
): Promise<APIShareCharterProjectPubliclyResult> => {
  const apiManager = APIManager.getInstance();

  return apiManager
    .get(`/charters/${charterId}/projects/${projectId}/share`, {
      cancelToken: cancelSource?.token || undefined,
    })
    .then((response) => {
      return response.data as APIShareCharterProjectPubliclyResult;
    });
};

const cloneCharter = (
  charterId: number,
  cancelSource?: CancelTokenSource
): Promise<APICreateCompanyCharterResponse> => {
  const apiManager = APIManager.getInstance();

  return apiManager.post(`/charters/${charterId}/clone`, null, {
    cancelToken: cancelSource?.token || undefined,
  });
};

export {
  getCharterById,
  getUsersByCharterId,
  patchUserByCharterIdAndUserId,
  updateCharterUserRoleByCharterId,
  updateCharterUserStatusByCharterId,
  postUserInCharter,
  deleteUserFromCharter,
  updateCharterById,
  updateCharterColors,
  patchAssets,
  getCharterAssetSignedUrlToUpload,
  getCharterFontFamilies,
  patchCharterThemeFonts,
  resetCharterThemeFonts,
  getCharterFontSignedUrlToUpload,
  getCharterPermissions,
  updateCharterPermissions,
  getCharterMedia,
  updateCharterMedia,
  getCharterMediaSignedUrlToUpload,
  getPublicScenarios,
  getCharterScenarios,
  getCharterScenariosForMe,
  getCharterScenarioDetailsById,
  updateCharterScenarioMetadata,
  createCharterScenario,
  deleteCharterScenario,
  cloneCharterScenario,
  createCharterScenarioVariant,
  updateCharterScenarioVariant,
  deleteCharterById,
  deleteCharterScenarioVariant,
  processCharterMediaCroppedUrlById,
  fetchAnimationByCharterIdAndParams,
  getCharterProjects,
  getCharterProjectDetailsById,
  createCharterProject,
  updateCharterProject,
  deleteCharterProject,
  generateCharterProjectFinalVideo,
  shareCharterProjectPublicly,
  cloneCharter,
  cloneCharterProject,
  postSubtitleBlockForProjectScene,
};
