import { find, findIndex } from 'lodash';
import { LINK } from '../Constants';
import { Role } from '../core/rule/Roles';
import { Roles } from '../core/rule/RolesTypes';
import Company from '../model/Company';
import { APIModelCharter } from '../services/api/types/ChartersServiceTypes';
import { APICompanyUsers } from '../services/api/types/CompaniesServiceTypes';
import { NavigationMenuItem } from '../services/navigation/NavigationMenuItem';
import CompaniesUtils from './CompaniesUtils';
import { CompanyBundle } from './types/BundlesTypes';

export default class SecurityUtils {
  static isPathAccessibleForPoc = (path: string, charters: APIModelCharter[], charterId?: string): boolean => {
    if (!charterId || !charters) {
      // if no charter => no inside restriction
      return true;
    }

    // if the user is in the charter then he can access all the inside url
    const currentCharter = find(charters, { id: parseInt(charterId, 10) });
    if (currentCharter && currentCharter.currentUser) {
      return true;
    }

    const currentPathAsKey = path.replace(charterId, ':charterId');
    const authorizedUrl = [LINK.CHARTER_USERS.key, LINK.CHARTER.key];
    const linkFromPath = find(LINK, { key: currentPathAsKey });

    if (linkFromPath && authorizedUrl.includes(linkFromPath.key)) {
      return true;
    }

    return false;
  };

  static isRouteAccessibleForUser = (
    route: NavigationMenuItem,
    isUserKannelleTeam: boolean,
    highestRoleInCurrentCompany?: Role,
    isUserPoc?: boolean,
    bundle?: CompanyBundle
  ): boolean => {
    if ((!route.roleRestriction && !route.knlTeamOnly) || isUserKannelleTeam) {
      return true;
    }

    if (route.knlTeamOnly && !isUserKannelleTeam) {
      return false;
    }

    if (isUserPoc && route.isPoc?.accessible) {
      return true;
    }

    if (bundle && route.bundleRestrictions) {
      let isRouteAccessible = true;
      route.bundleRestrictions.forEach((code) => {
        isRouteAccessible = bundle?.featureValues[code] === true ?? true;
      });

      if (!isRouteAccessible) {
        return isRouteAccessible;
      }
    }

    if (route.roleRestriction && route.roleRestriction.length > 0) {
      const index = findIndex(route.roleRestriction, (role) => {
        return role === highestRoleInCurrentCompany?.currentRole;
      });

      if (index < 0) {
        return false;
      }
    }

    return true;
  };

  static isUrlAccessible = (
    companies?: Company[],
    charters?: APIModelCharter[],
    params?: { [charterId: string]: string | undefined }
  ): boolean => {
    const charterId = params?.charterId;

    if (companies?.length === 0) {
      return false;
    }

    if (charterId) {
      const charter = find(charters, { id: parseInt(charterId, 10) });

      return charter !== undefined;
    }

    return true;
  };

  static isDowngradeNecessary = (
    currentCompany: Company,
    charters: APIModelCharter[],
    CompanyUsers: APICompanyUsers[]
  ): boolean => {
    const chartersLimit = currentCompany.maxNbCharters;
    const usersLimit = currentCompany.maxNbLicenses;

    const activeCharters = charters?.filter((charter) => charter.isActive);

    const activeUsers: string[] = CompaniesUtils.getActiveUserIdsInCompanies(CompanyUsers);
    if (activeUsers.length > usersLimit || (activeCharters?.length > chartersLimit && chartersLimit > 0)) {
      return true;
    }

    return false;
  };

  static isPlatformAccessible = (companies: Company[]): boolean => {
    return companies?.length > 0;
  };

  static isPlanUrlAccessible = (isUserKnl: boolean, isUserPoc: boolean, currentCompany?: Company): boolean => {
    if (!currentCompany) {
      return false;
    }

    if (currentCompany.subscriptionCreated) {
      return false;
    }

    if (isUserKnl || isUserPoc) {
      return true;
    }

    return CompaniesUtils.checkUserHasRole([currentCompany], Roles.Owner);
  };
}
