import { ReactText } from 'react';

export default class LocationUtils {
  static getTreeKeysForLocationPathName = (pathname: string): ReactText[] => {
    const pathKey = pathname.split('/');
    let i = 1;
    const keys = [];
    while (i < pathKey.length) {
      let key = pathKey[i];
      if (keys[keys.length - 1]) {
        const previousKey = keys[keys.length - 1];

        key = `${previousKey}/${key}`;
        if (key[0] !== '/') {
          key = `/${key}`;
        }
      } else if (key[0] !== '/') {
        key = `/${key}`;
      }

      keys.push(key);
      i++;
    }

    return keys;
  };

  static getQueryParamByNameFromPathName = (paramToRead: string, pathname: string): string | undefined => {
    const reg = new RegExp(`[?&]${paramToRead}=([^&#]*)`, 'i');
    const queryString = reg.exec(pathname);
    return queryString ? queryString[1] : undefined;
  };
}
