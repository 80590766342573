import { useCallback, useEffect, useRef } from 'react';
import hideSatismeter from './hideSatismeter';
import initializeSatismeter from './initialiseSatismeter';

type Result = {
  boot: (params: BootParams) => void;
  hide: () => void;
};

type BootParams = {
  userId: string;
  traits?: {
    name?: string;
    email?: string;
  };
};

const useSatismeter = (): Result => {
  const isBooted = useRef(false);

  useEffect(() => {
    initializeSatismeter();
    isBooted.current = true;
  }, []);

  const boot = useCallback((params: BootParams): void => {
    if (!(window as any).satismeter) {
      return;
    }

    const satismeterWriteKey = process.env.REACT_APP_SATISMETER_APP_ID ?? '';

    (window as any).satismeter({
      writeKey: satismeterWriteKey,
      ...params,
    });
  }, []);

  const hide = hideSatismeter;

  return { boot, hide };
};

export default useSatismeter;
