import { useEffect, useState } from 'react';

const useCountdown = (countdownDuration?: number) => {
  const [counter, setCounter] = useState<number | undefined>(countdownDuration);

  useEffect(() => {
    setCounter(countdownDuration);
  }, [countdownDuration]);

  useEffect(() => {
    if (!counter || counter <= 0) {
      return undefined;
    }

    const timer = setInterval(() => setCounter(counter - 1), 1000);

    return () => clearInterval(timer);
  }, [counter]);

  return counter ?? 0;
};

export default useCountdown;
