import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactJoyride, { CallBackProps, STATUS } from 'react-joyride';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/RootState';
import JoyRideUtils from '../utils/JoyRideUtils';
import KannelleJoyRideTooltip from './KannelleJoyRideTooltip';

type Props = {
  runHelp: boolean;
  callbackRunDone: () => void;
};

const JoyRideHelpUsers: FunctionComponent<Props> = ({ runHelp, callbackRunDone }: Props) => {
  const { t } = useTranslation();
  const isMobileOrTablet = useSelector((state: RootState) => {
    return state.app.isMobileOrTablet;
  });
  const [run, setRun] = useState(false);

  useEffect(() => {
    if (!run && runHelp) {
      const component = document.querySelector('html');
      if (component) {
        component.style.overflowX = 'hidden';
      }
    }
    setRun(runHelp);
  }, [run, runHelp]);

  const handleJoyrideCallback = (data: CallBackProps): void => {
    const { status, lifecycle } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status) && lifecycle === 'complete') {
      setRun(false);
      const component = document.querySelector('html');
      if (component) {
        component.style.overflowX = '';
      }
      callbackRunDone();
    }
  };
  const steps = !isMobileOrTablet ? JoyRideUtils.getUsersSteps(t) : JoyRideUtils.getUsersStepsMobile(t);

  return (
    <>
      <ReactJoyride
        continuous
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        tooltipComponent={KannelleJoyRideTooltip}
        steps={steps}
        callback={handleJoyrideCallback}
        locale={{
          back: t('onBoarding.steps.back'),
          close: t('onBoarding.steps.close'),
          last: t('onBoarding.steps.last'),
          next: t('onBoarding.steps.next'),
          open: t('onBoarding.steps.open'),
          skip: t('onBoarding.steps.skip'),
        }}
      />
    </>
  );
};

export default JoyRideHelpUsers;
