import { RightOutlined } from '@ant-design/icons/lib';
import { Table } from 'antd';
import { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import { each, findIndex } from 'lodash';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { APIManager } from '../../../services/api/APIManager';
import { getTranslations, getTranslationsByCode } from '../../../services/api/TranslationService';
import { APIGetTranslationsResult, APITranslation } from '../../../services/api/types/TranslationServiceTypes';
import StringUtils from '../../../utils/StringUtils';
import { buildTranslationTableColumns } from './TranslationTableColumns';
import TranslationTableSubRow from './TranslationTableSubRow';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'space-between',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tag: {
    color: 'white',
    borderRadius: 25,
    border: 'unset',
    marginRight: '0',
    textAlign: 'center',
    pointerEvents: 'none',
  },
  expandableIcon: {
    border: '1px solid #F0F0F0',
    borderRadius: '2px',
    height: '18px',
    width: '18px',
    float: 'left',
    position: 'relative',
    display: 'inline-flex',
    textDecoration: 'none',
    padding: '0',
    lineHeight: '18px',
    outline: 'none',
    background: '#FFFFFF',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  expandedIcon: {
    transform: 'rotate(90deg)',
    transition: 'transform 0.3s ease-out',
  },
  collapsedIcon: {
    transform: 'rotate(0deg)',
    transition: 'transform 0.3s ease-out',
  },
});

type Props = {
  translationFilter?: string;
};

const TranslationTable: FunctionComponent<Props> = ({ translationFilter }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [data, setData] = useState<APITranslation[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());

  const [filter, setFilter] = useState<Record<string, string>>();
  const [filteredInfo, setFilteredInfo] = useState({});

  useEffect(() => {
    const cancelToken = cancelTokenSourceRef.current;

    if (translationFilter) {
      getTranslationsByCode(translationFilter, cancelToken).then((result: APIGetTranslationsResult) => {
        setIsLoading(false);
        // in case there is no translation
        if (!result.data || !result.data.items) {
          return;
        }

        const { items } = result.data;
        const sortedData = items.sort((dataA, dataB) => {
          return (
            StringUtils.compareNaturalNormalized(dataA.category, dataB.category) ||
            StringUtils.compareNaturalNormalized(dataA.code, dataB.code)
          );
        });

        setData(sortedData);
      });
    } else {
      getTranslations(cancelToken).then((result: APIGetTranslationsResult) => {
        setIsLoading(false);
        // in case there is no translation
        if (!result.data || !result.data.items) {
          return;
        }

        const { items } = result.data;
        const sortedData = items.sort((dataA, dataB) => {
          return (
            StringUtils.compareNaturalNormalized(dataA.category, dataB.category) ||
            StringUtils.compareNaturalNormalized(dataA.code, dataB.code)
          );
        });

        setData(sortedData);
      });
    }

    return (): void => {
      cancelToken.cancel('cancel get or patch on unmount');
    };
  }, []);

  const columns = buildTranslationTableColumns({
    classes,
    t,
    filter,
    setFilter,
    filteredInfo,
  });

  const handleUpdate = (updating: boolean, updatedPartialData?: any, lang?: string): void => {
    if (updating) {
      setIsLoading(true);
      return;
    }

    setIsLoading(false);
    if (updatedPartialData && lang) {
      const index = findIndex(data, { id: updatedPartialData.id });
      const dataToBeUpdated = data[index];
      if (updatedPartialData.translations && dataToBeUpdated.translations) {
        dataToBeUpdated.translations[lang] = updatedPartialData.translations[lang];
      }

      const allData = [...data];
      allData.splice(index, 1, dataToBeUpdated);
      setData(allData);
    }
  };

  return (
    <Table
      loading={isLoading}
      rowKey={(record): string => `translation_row_${record.id}`}
      columns={columns}
      dataSource={data}
      scroll={{ x: 'max-content' }}
      pagination={false}
      expandable={{
        expandedRowRender: (record: APITranslation): JSX.Element => {
          return <TranslationTableSubRow record={record} handleUpdate={handleUpdate} />;
        },
        expandRowByClick: false,
        expandIcon: (expandIconProps): JSX.Element | null => {
          if (!expandIconProps.expandable) {
            return null;
          }
          return (
            <span
              className={classNames(
                classes.expandableIcon,
                expandIconProps.expanded ? classes.expandedIcon : classes.collapsedIcon
              )}
              onClick={(e): void => expandIconProps.onExpand(expandIconProps.record, e)}
            >
              <RightOutlined />
            </span>
          );
        },
      }}
      onChange={(pagination, filters, sorter: any, extra): void => {
        if (extra.action === 'filter') {
          const filterSyntheses: { [key: string]: any } = {};
          each(filters, (dataSource: any, key: string) => {
            if (dataSource) {
              filterSyntheses[key.replace('translation_', '')] = dataSource;
            }
          });

          setFilteredInfo(filters);
        }
      }}
      locale={{ filterConfirm: t('global.ok'), filterReset: t('global.reset'), emptyText: t('global.nodata') }}
    />
  );
};

export default TranslationTable;
