import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, List, message, Modal, Table } from 'antd';
import { find, findIndex } from 'lodash';
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { THEME } from '../../Constants';
import { updateCharter } from '../../redux/action/ChartersAction';
import { RootState } from '../../redux/RootState';
import { updateCharterById, updateCharterUserStatusByCharterId } from '../../services/api/ChartersService';
import { getCompanyUsers } from '../../services/api/CompaniesService';
import { APIModelCharter, APIPatchCharterUsersByCharterIdResult } from '../../services/api/types/ChartersServiceTypes';
import { APICompanyUsers, APICompanyUsersResult } from '../../services/api/types/CompaniesServiceTypes';
import CompaniesUtils from '../../utils/CompaniesUtils';
import KannelleSwitch from '../switch/KannelleSwitch';
import ValidationModal from './ConfirmModal';

type Props = {
  visible: boolean;
  bundleName: string;
  companyUsers: APICompanyUsers[];
  companyCharters: APIModelCharter[];
  callbackHandleClose: (done: boolean) => void;
  numberOfUsers: number;
  chartersLimit: number;
  closable?: boolean;
};

type DowngradeUser = {
  id: string;
  email: string;
  isActive: boolean;
  charters: APIModelCharter[];
  acl: {
    isActive: boolean;
  };
};

enum DOWNGRADE_MODAL_VIEW {
  USER = 'USER',
  CHARTER = 'CHARTER',
}

const useStyles = createUseStyles({
  list: {
    '& .ant-list-item-meta': {
      alignItems: 'center',
      '& .ant-list-item-meta-avatar': {
        color: THEME.DEFAULT.ERROR,
      },
    },
  },
  error: {
    color: THEME.DEFAULT.ERROR,
  },
  success: {
    color: THEME.DEFAULT.VALID_COLOR,
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .ant-form-item': {
      margin: 0,
    },
  },
  loadingIcon: {
    fontSize: '10px',
    marginLeft: '5px',
  },
  contactUsContainer: {
    marginTop: 8,

    display: 'flex',
    justifyContent: 'space-between',
  },
});

const DowngradeModal: FunctionComponent<Props> = ({
  visible,
  bundleName,
  companyUsers,
  companyCharters,
  callbackHandleClose,
  numberOfUsers,
  chartersLimit,
  closable = true,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [view, setView] = useState(DOWNGRADE_MODAL_VIEW.CHARTER);

  // Users
  const [companyUserList, setCompanyUserList] = useState<APICompanyUsers[]>(companyUsers);
  const [formattedUsers, setFormattedUsers] = useState<DowngradeUser[]>([]);
  const [numberOfActiveUsers, setNumberOfActiveUsers] = useState(0);
  const [numberOfUsersToDeactivate, setNumberOfUsersToDeactivate] = useState(0);
  const [confirmationUserModalVisible, setConfirmationUserModalVisible] = useState(false);
  const [userAction, setUserAction] = useState<{
    isActive: boolean;
    row: DowngradeUser;
  }>();

  // Charters
  const [companyCharterList, setCompanyCharterList] = useState<APIModelCharter[]>(companyCharters);
  const [numberOfActiveCharters, setNumberOfActiveCharters] = useState(0);
  const [numberOfChartersToDeactivate, setNumberOfChartersToDeactivate] = useState(0);
  const [confirmationCharterModalVisible, setConfirmationCharterModalVisible] = useState(false);
  const [charterAction, setCharterAction] = useState<{
    isActive: boolean;
    row: any;
  }>();
  const [isCharterLoading, setIsCharterLoading] = useState<string | undefined>();

  const [isUserLoading, setIsUserLoading] = useState<string | undefined>();
  const currentCompany = useSelector((state: RootState) => state.companies.current);
  const currentUser = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    if (!visible || !companyCharters) {
      return;
    }

    let activeUsers = 0;
    const users = companyUserList.map((user) => {
      let isActive = false;

      user.charters.forEach((charter) => {
        if (charter.currentUser.isActive) {
          isActive = true;
        }
      });

      if (isActive) {
        activeUsers += 1;
      }

      return {
        id: user.id,
        email: user.email,
        charters: user.charters,
        acl: {
          isActive,
        },
        isActive,
      };
    });

    setNumberOfActiveUsers(activeUsers);
    setNumberOfUsersToDeactivate(activeUsers - numberOfUsers);
    setFormattedUsers(users);
  }, [visible, companyUserList]);

  useEffect(() => {
    if (!visible) {
      return;
    }

    const activeCharters = companyCharters.filter((charter) => charter.isActive).length;
    setNumberOfActiveCharters(activeCharters);
    const calculatedNumberOfChartersToDeactivate = activeCharters - chartersLimit;
    if (chartersLimit !== 0) {
      setNumberOfChartersToDeactivate(calculatedNumberOfChartersToDeactivate);
    } else {
      setNumberOfChartersToDeactivate(0);
    }

    if (chartersLimit === 0 || calculatedNumberOfChartersToDeactivate <= 0) {
      setView(DOWNGRADE_MODAL_VIEW.USER);
    }
  }, [visible]);

  useEffect(() => {
    if (numberOfChartersToDeactivate <= 0 && numberOfUsersToDeactivate > 0) {
      setView(DOWNGRADE_MODAL_VIEW.USER);
    }
  }, [numberOfChartersToDeactivate]);

  const handleSwitchUserAction = (value: boolean, row: DowngradeUser) => {
    // Set the switch in a loading state
    setIsUserLoading(row.id);
    // Show confirmation modal
    setConfirmationUserModalVisible(true);
    // Store action (row + isActive 'new' state)
    setUserAction({
      isActive: value,
      row,
    });
  };

  const handleSwitchUser = (validation: boolean) => {
    setConfirmationUserModalVisible(false);
    if (!userAction || !validation) {
      setIsUserLoading(undefined);
      return;
    }

    const { row, isActive } = userAction;
    const promises: Promise<APIPatchCharterUsersByCharterIdResult | void>[] = [];
    row.charters.forEach((charter) => {
      promises.push(updateCharterUserStatusByCharterId(charter.id, row.id, isActive));
    });

    Promise.all(promises)
      .then(() => {
        const users = [...formattedUsers];
        const userInList = find(users, { id: row.id });

        if (userInList) {
          userInList.isActive = isActive;
          setFormattedUsers([...users]);
        }

        if (!isActive) {
          setNumberOfActiveUsers(numberOfActiveUsers - 1);
        } else {
          setNumberOfActiveUsers(numberOfActiveUsers + 1);
        }

        if (numberOfUsersToDeactivate > 0 && !isActive) {
          setNumberOfUsersToDeactivate(numberOfUsersToDeactivate - 1);
        } else {
          setNumberOfUsersToDeactivate(numberOfUsersToDeactivate + 1);
        }
      })
      .catch(() => {
        message.error(
          <>
            {t('users.updateIsActive.error')}
            <br />
            {t('support.global.emojiFingerRight')}
            <a href={`mailto:${t('support.global.supportEmail')}`}>{t('support.global.supportEmail')}</a>
            {t('support.global.emojiFingerLeft')}
          </>
        );
      })
      .finally(() => {
        setUserAction(undefined);
        setIsUserLoading(undefined);
      });
  };

  const fetchCompanyUsers = (): Promise<void | APICompanyUsersResult> | null => {
    if (!currentCompany) {
      return null;
    }

    return getCompanyUsers(currentCompany.id).then((usersByCharterResponse: APICompanyUsersResult) => {
      const filteredCompanyUsers = CompaniesUtils.getNonKnlTeamUsersInCompanies(usersByCharterResponse.data.items);

      setCompanyUserList(filteredCompanyUsers);
    });
  };

  const handleSwitchCharterAction = (value: boolean, row: DowngradeUser) => {
    // Set the switch in a loading state
    setIsCharterLoading(row.id);

    // Show confirmation modal
    setConfirmationCharterModalVisible(true);

    // Store action (row + isActive 'new' state)
    setCharterAction({
      isActive: value,
      row,
    });
  };

  const handleSwitchCharter = (validation: boolean) => {
    setConfirmationCharterModalVisible(false);
    if (!charterAction || !validation) {
      setIsCharterLoading(undefined);
      return;
    }

    const { row, isActive } = charterAction;

    updateCharterById(row.id, { isActive } as any).then(() => {
      // we need to update our local list of charters
      const currentCharterList = [...companyCharterList];
      const charterIndexToUpdate = findIndex(currentCharterList, { id: row.id });

      if (charterIndexToUpdate >= 0) {
        const charterToUpdate = { ...currentCharterList[charterIndexToUpdate] };
        charterToUpdate.isActive = isActive;

        dispatch(updateCharter(charterToUpdate));
        currentCharterList.splice(charterIndexToUpdate, 1, charterToUpdate);

        setCompanyCharterList([...currentCharterList]);
      }

      if (!isActive) {
        setNumberOfActiveCharters(numberOfActiveCharters - 1);
        setNumberOfChartersToDeactivate(numberOfChartersToDeactivate - 1);
      } else {
        setNumberOfActiveCharters(numberOfActiveCharters + 1);
        setNumberOfChartersToDeactivate(numberOfChartersToDeactivate + 1);
      }

      const promise = fetchCompanyUsers();
      if (promise) {
        promise.finally(() => {
          setCharterAction(undefined);
          setIsCharterLoading(undefined);
        });
      } else {
        setCharterAction(undefined);
        setIsCharterLoading(undefined);
      }
    });
  };

  const columnsCharter = [
    {
      title: t('checkoutBundles.downgradeModal.charterName'),
      dataIndex: 'name',
      key: 'charter_name',
      render: (name: string): ReactElement => {
        return <div>{name}</div>;
      },
    },
    {
      title: '',
      dataIndex: 'isActive',
      key: 'user_activated',
      render: (isActive: boolean, row: any): ReactElement => {
        return (
          <span className={classes.switchContainer}>
            <KannelleSwitch
              isChecked={isActive}
              size="small"
              callbackChange={(value) => handleSwitchCharterAction(value, row)}
              loading={isCharterLoading === row.id}
              labelActivated="form.activated"
              labelDeactivated="form.deactivated"
              disabled={isCharterLoading === row.id || currentUser?.id === row.id}
              isFieldControlled
            />
          </span>
        );
      },
    },
  ];

  const columnsUser = [
    {
      title: t('users.email'),
      dataIndex: 'email',
      key: 'user_email',
      render: (email: string): ReactElement => {
        return <div>{email}</div>;
      },
    },
    {
      title: t('users.status'),
      dataIndex: 'isActive',
      key: 'user_activated',
      render: (isActive: boolean, row: any): ReactElement => {
        return (
          <span className={classes.switchContainer}>
            <KannelleSwitch
              isChecked={isActive}
              size="small"
              callbackChange={(value) => handleSwitchUserAction(value, row)}
              loading={isUserLoading === row.id}
              labelActivated="form.activated"
              labelDeactivated="form.deactivated"
              disabled={isUserLoading === row.id || currentUser?.id === row.id}
              isFieldControlled
            />
          </span>
        );
      },
    },
  ];

  const handleDowngradeFinished = () => {
    if (numberOfChartersToDeactivate <= 0 && numberOfUsersToDeactivate <= 0) {
      callbackHandleClose(true);
    }
  };

  const handleShowContactUs = () => {
    // Previously Intercom support
  };

  return (
    <>
      <Modal
        width={600}
        title={t('checkoutBundles.downgradeModal.title')}
        visible={visible}
        closable={closable}
        footer={[
          <Button key="contactUsDowngrade" onClick={handleShowContactUs}>
            {t('checkoutBundles.contactUs')}
          </Button>,
          <Button
            key="continueToCheckout"
            type="primary"
            onClick={handleDowngradeFinished}
            disabled={numberOfChartersToDeactivate > 0 || numberOfUsersToDeactivate > 0}
          >
            {t('global.continue')}
          </Button>,
        ]}
        onCancel={() => {
          if (closable) {
            callbackHandleClose(false);
          }
        }}
      >
        <p>{t('checkoutBundles.downgradeModal.intro')}</p>
        <List
          className={classes.list}
          itemLayout="horizontal"
          dataSource={[
            {
              title: (
                <>
                  {t('checkoutBundles.downgradeModal.charterLimitPart1', {
                    count: numberOfActiveCharters,
                  })}
                  <b>{bundleName}</b>
                  {t('checkoutBundles.downgradeModal.charterLimitPart2', { count: chartersLimit })}
                </>
              ),
              isSuccess: numberOfChartersToDeactivate <= 0,
            },
            {
              title: (
                <>
                  {t('checkoutBundles.downgradeModal.userLimitPart1', { count: numberOfActiveUsers })}
                  <b>{bundleName}</b>
                  {t('checkoutBundles.downgradeModal.userLimitPart2', { count: numberOfUsers })}
                </>
              ),
              isSuccess: numberOfUsersToDeactivate <= 0,
            },
          ]}
          renderItem={(item) => {
            if (item.isSuccess) {
              return null;
            }

            return (
              <List.Item>
                <List.Item.Meta avatar={<CloseCircleOutlined className={classes.error} />} title={item.title} />
              </List.Item>
            );
          }}
        />

        {numberOfChartersToDeactivate <= 0 && numberOfUsersToDeactivate <= 0 && (
          <List.Item>
            <List.Item.Meta
              avatar={<CheckCircleOutlined className={classes.success} />}
              title={t('checkoutBundles.downgradeModal.conflictResolved')}
            />
          </List.Item>
        )}

        {view === DOWNGRADE_MODAL_VIEW.CHARTER && (
          <Table
            tableLayout="fixed"
            columns={columnsCharter}
            dataSource={companyCharterList}
            showSorterTooltip={false}
            rowKey={(record): string => {
              return `user_row_${record.id}`;
            }}
            size="small"
          />
        )}

        {view === DOWNGRADE_MODAL_VIEW.USER && (
          <div>
            <Table
              tableLayout="fixed"
              columns={columnsUser}
              dataSource={formattedUsers}
              showSorterTooltip={false}
              rowKey={(record): string => {
                return `user_row_${record.id}`;
              }}
              size="small"
            />
          </div>
        )}

        <p className={classes.contactUsContainer}>
          {t('checkoutBundles.downgradeModal.contactUs')} <br />
        </p>
      </Modal>

      {confirmationCharterModalVisible && charterAction && (
        <ValidationModal
          callBackValidation={handleSwitchCharter}
          message={
            charterAction.isActive
              ? t('checkoutBundles.downgradeModal.validateCharterActivation')
              : t('checkoutBundles.downgradeModal.validateCharterDeactivation')
          }
          visible={confirmationCharterModalVisible}
        />
      )}

      {confirmationUserModalVisible && userAction && (
        <ValidationModal
          callBackValidation={handleSwitchUser}
          message={
            userAction.isActive
              ? t('checkoutBundles.downgradeModal.validateUserActivation')
              : t('checkoutBundles.downgradeModal.validateUserDeactivation')
          }
          visible={confirmationUserModalVisible}
        />
      )}
    </>
  );
};

export default DowngradeModal;
