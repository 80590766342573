import { Form, Input } from 'antd';
import classNames from 'classnames';
import { cloneDeep, find } from 'lodash';
import React, { ChangeEvent, FunctionComponent, Ref, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { THEME } from '../../Constants';
import AnimationsUtils from '../../utils/AnimationsUtils';
import ScenarioUtils from '../../utils/ScenarioUtils';
import { AnimationConfig, AnimationOption, AnimationTextDescriptor } from '../../utils/types/AnimationTypes';
import { TemplateCameraOverlayText } from '../../utils/types/ScenarioTypes';

const useStyles = createUseStyles({
  overlayTextFieldLabel: {
    fontSize: '12px',
    '&::after': {
      content: '":"',
      marginLeft: '2px',
    },
  },
  subFormItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',

    '& .ant-col.ant-form-item-label': {
      width: '30%',
    },

    '& label': {
      fontSize: '11px',
    },

    '& input': {
      fontSize: '11px',
    },
  },
  requiredField: {
    '& label::before': {
      color: '#FF4D4F',
      lineHeight: '1',
      content: "'*'",
    },
  },
  optionalField: {
    '& input': {
      borderColor: `${THEME.DEFAULT.BORDER_COLOR} !important`,
    },
  },
  overlayTextContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  overlayText: {
    backgroundColor: '#F3F3F3',
    borderRadius: '3px',
    padding: '5px 10px 2px 5px',
    marginBottom: '5px',
    flexGrow: '1',
  },
  addOverlayTextButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingBottom: '3px',
  },
});

type Props = {
  value?: TemplateCameraOverlayText[];
  onChange?: (newValue: TemplateCameraOverlayText[] | undefined) => void;
  selectedAnimationText?: AnimationConfig;
  initialOverlayTexts?: TemplateCameraOverlayText[];
};

const ScenarioSceneOverlayTexts: FunctionComponent<Props> = React.forwardRef(
  ({ value, onChange, selectedAnimationText, initialOverlayTexts }: Props, ref: Ref<any>) => {
    const classes = useStyles({});
    const { t } = useTranslation();
    const [selectedAnimationTextConfig, setSelectedAnimationTextConfig] = useState<AnimationOption>();

    // Called to update the upper `form` object
    const triggerChange = (changedValue: TemplateCameraOverlayText[] | undefined): void => {
      if (onChange) {
        onChange(changedValue);
      }
    };

    const generateDefaultOverlayText = (placeholder: string): TemplateCameraOverlayText => {
      return {
        id: ScenarioUtils.generateId(),
        placeholderText: placeholder,
        demoText: placeholder,
        text: placeholder,
      };
    };

    useEffect(() => {
      if (!selectedAnimationText) {
        return;
      }

      const config = AnimationsUtils.getAnimationOptionByCode(selectedAnimationText.name);
      if (config) {
        setSelectedAnimationTextConfig(config);
        const initialValue = config.animationTexts.map((descriptor, index) => {
          // We try to find it in the initial scene values for the `overlayText` field, else we generate a default one
          return initialOverlayTexts && initialOverlayTexts[index]
            ? initialOverlayTexts[index]
            : generateDefaultOverlayText(
                descriptor.placeholderKey ? t(descriptor.placeholderKey) : t(descriptor.labelKey)
              );
        });
        triggerChange(initialValue);
      }
      // eslint-disable-next-line
    }, [selectedAnimationText, initialOverlayTexts]);

    const onInputFieldChange = (e: ChangeEvent<HTMLInputElement>, updatedOverlayTextId: string): void => {
      const fieldValue = e.target.value;
      const newOverlays = cloneDeep(value);
      const updatedOverlay = find(newOverlays, (overlay) => overlay.id === updatedOverlayTextId);
      if (updatedOverlay) {
        updatedOverlay.text = fieldValue;
        updatedOverlay.demoText = fieldValue;
        updatedOverlay.placeholderText = fieldValue;
      }
      triggerChange(newOverlays);
    };

    if (
      !(
        selectedAnimationTextConfig &&
        selectedAnimationTextConfig.animationTexts &&
        selectedAnimationTextConfig.animationTexts.length > 0 &&
        value
      )
    ) {
      return null;
    }

    return (
      <div ref={ref}>
        <div className={classes.overlayTextFieldLabel}>
          {t('charters.scenarios.variantEditor.sceneForm.fields.overlayTextsLabel')}
        </div>
        {selectedAnimationTextConfig.animationTexts.map((descriptor: AnimationTextDescriptor, index: number) => {
          const overlayTextValue = value[index];
          if (!overlayTextValue) {
            return null;
          }

          return (
            <div key={overlayTextValue.id} className={classes.overlayTextContainer}>
              <div className={classes.overlayText}>
                {/* overlayTexts[index].demoText, overlayTexts[index].text and overlayTexts[index].placeholderText */}
                <Form.Item
                  label={t(descriptor.labelKey)}
                  className={classNames(classes.subFormItem, classes.requiredField)}
                >
                  <Input
                    placeholder={t(descriptor.labelKey)}
                    value={overlayTextValue.text}
                    onChange={(e): void => onInputFieldChange(e, overlayTextValue.id)}
                  />
                </Form.Item>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
);

export default ScenarioSceneOverlayTexts;
