import { Button, Modal, Progress } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSocket from '../../../../../../hooks/useSocket';
import { SocketManager } from '../../../../../../services/api/SocketManager';
import {
  ProjectSubtitleGeneratedResponse,
  ProjectSubtitleGenerationProgressResponse,
} from '../../../../../../services/api/types/WebSocketTypes';
import MathUtils from '../../../../../../utils/MathUtils';
import { Project } from '../../../../../../utils/types/ProjectTypes';

type Props = {
  charterId: number;
  projectId: number;
  isVisible: boolean;
  project: Project;
  callBackHideModal: () => void;
  onSubtitlesGenerated: (updatedProject: Project) => void;
};

const ProjectSubtitleGenerationModal: FunctionComponent<Props> = ({
  isVisible,
  project,
  charterId,
  projectId,
  callBackHideModal,
  onSubtitlesGenerated,
}: Props) => {
  const { t } = useTranslation();
  const isSocketConnected = useSocket(`charters/${charterId}/projects/${projectId}`);
  const [closable, setClosable] = useState(false);
  const [progress, setProgress] = useState(0);

  // Handle websocket messages
  useEffect(() => {
    const handleUpdatedProject = (
      payload: ProjectSubtitleGenerationProgressResponse | ProjectSubtitleGeneratedResponse
    ): void => {
      let messagePayload;
      // eslint-disable-next-line default-case
      switch (payload.action) {
        case 'project_subtitles_generation_progress': {
          messagePayload = payload as ProjectSubtitleGenerationProgressResponse;
          const newProgress = MathUtils.formatInputValueWithXDecimals(messagePayload.data.progress * 100, 0);
          if (newProgress > progress) {
            setProgress(newProgress);
          }
          break;
        }
        case 'project_subtitles_generated': {
          setClosable(true);
          messagePayload = payload as ProjectSubtitleGeneratedResponse;
          onSubtitlesGenerated(messagePayload.data);
          break;
        }
      }
    };

    if (isSocketConnected) {
      SocketManager.onMessage(handleUpdatedProject);

      return (): void => SocketManager.offMessage(handleUpdatedProject);
    }

    return undefined;
  }, [isSocketConnected]);

  const handleClose = () => {
    if (!closable) {
      return;
    }
    setProgress(0);
    setClosable(false);
    callBackHideModal();
  };

  return (
    <Modal
      title={t('charters.projects.projectEditor.subtitleGenerationModal.title', { title: project.title })}
      closable={closable}
      width={700}
      visible={isVisible}
      onCancel={handleClose}
      footer={[
        <Button key="closeButton" type="primary" onClick={handleClose} disabled={!closable}>
          {t('global.close')}
        </Button>,
      ]}
      destroyOnClose
    >
      <div>
        {!closable ? (
          <span>
            <p>{t('charters.projects.projectEditor.subtitleGenerationModal.warningPart1')}</p>
            <p>{t('charters.projects.projectEditor.subtitleGenerationModal.warningPart2')}</p>
          </span>
        ) : (
          t('charters.projects.projectEditor.subtitleGenerationModal.success')
        )}
      </div>
      <Progress percent={progress} status={progress === 100 ? 'success' : 'active'} />
    </Modal>
  );
};

export default ProjectSubtitleGenerationModal;
