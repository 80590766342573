import { FunctionComponent } from 'react';
import { THEME_KEY } from '../Constants';
import AlgiersTheme from '../pages/charters/charter-creation-or-update/theme-edition/theme/AlgiersTheme';
import BaliTheme from '../pages/charters/charter-creation-or-update/theme-edition/theme/BaliTheme';
import BerlinTheme from '../pages/charters/charter-creation-or-update/theme-edition/theme/BerlinTheme';
import BogotaTheme from '../pages/charters/charter-creation-or-update/theme-edition/theme/BogotaTheme';
import GenevaTheme from '../pages/charters/charter-creation-or-update/theme-edition/theme/GenevaTheme';
import LosAngelesTheme from '../pages/charters/charter-creation-or-update/theme-edition/theme/LosAngelesTheme';
import SeattleTheme from '../pages/charters/charter-creation-or-update/theme-edition/theme/SeattleTheme';
import { ThemeAnimationProps } from './types/ThemeTypes';

export default class ThemeAnimationUtils {
  static getAnimationsComponentByThemeCode = (code: string): FunctionComponent<ThemeAnimationProps> | undefined => {
    switch (code) {
      case THEME_KEY.ALGIERS.code:
        return AlgiersTheme;
      case THEME_KEY.BALI.code:
        return BaliTheme;
      case THEME_KEY.GENEVA.code:
        return GenevaTheme;
      case THEME_KEY.SEATTLE.code:
        return SeattleTheme;
      case THEME_KEY.LOS_ANGELES.code:
        return LosAngelesTheme;
      case THEME_KEY.BERLIN.code:
        return BerlinTheme;
      case THEME_KEY.BOGOTA.code:
        return BogotaTheme;
      default:
        return undefined;
    }
  };
}
