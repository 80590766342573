import { Col, Row } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import Color from '../../../../components/form/Color';
import InputFloat from '../../../../components/form/InputFloat';
import { DEVICE_SIZES_QUERIES } from '../../../../Constants';
import ThemeUtils, { Theme, ThemeColorKey, ThemeColorKeyType } from '../../../../utils/ThemeUtils';

type Props = {
  themeFields: ThemeColorKey;
  charterThemeColors: Theme[];
  onThemeColorKeyValueChange: (themeCode: string, keyCode: string, newValue: string) => void;
};

const useStyles = createUseStyles({
  row: {
    padding: 8,
    [`@media screen and ${DEVICE_SIZES_QUERIES.EXTRA_LARGER}`]: {
      '& :nth-child(even)': {
        '& > .ant-form-item': {
          paddingLeft: 24,
        },
      },
    },
  },
  formInputNumber: {
    width: '110px',
  },
  formItem: {
    display: 'flex',
    alignItems: 'center',
  },
});

const CharterThemeColorsEditionForm: FunctionComponent<Props> = ({
  themeFields,
  charterThemeColors,
  onThemeColorKeyValueChange,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Row className={classNames(classes.row, 'themeFieldsContainer')}>
      {themeFields.colorKeys.map((colorKey) => {
        let component;
        switch (colorKey.type) {
          case ThemeColorKeyType.NUMBER:
            {
              const inputValue = ThemeUtils.getNumberValueByThemeAndKey(
                charterThemeColors,
                themeFields.code,
                colorKey.key
              );
              component = (
                <InputFloat
                  label={t(`charters.themes.formFields.${colorKey.key}`)}
                  className={classes.formInputNumber}
                  value={inputValue ? parseFloat(inputValue) : 0.0}
                  onChange={(newValue): void =>
                    onThemeColorKeyValueChange(themeFields.code, colorKey.key, newValue ? newValue.toString() : '0')
                  }
                />
              );
            }
            break;
          case ThemeColorKeyType.COLOR:
          default:
            component = (
              <Color
                label={t(`charters.themes.formFields.${colorKey.key}`)}
                color={ThemeUtils.getColorValueByThemeAndKey(
                  charterThemeColors,
                  themeFields.code,
                  colorKey.key,
                  colorKey.defaultMapping
                )}
                onEditColor={(newColor): void => onThemeColorKeyValueChange(themeFields.code, colorKey.key, newColor)}
              />
            );
            break;
        }

        return (
          <Col sm={24} md={24} lg={24} xl={12} key={`${themeFields.code}-${colorKey.key}`} className={classes.formItem}>
            {component}
          </Col>
        );
      })}
    </Row>
  );
};

export default CharterThemeColorsEditionForm;
