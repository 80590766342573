import deburr from 'lodash/deburr';
import naturalCompare from 'natural-compare';
import { FREE_TRIAL_DENY_EMAIL_LIST } from '../Constants';

export default class StringUtils {
  static normalize(stringToNormalize: string): string {
    return deburr(stringToNormalize.toLowerCase());
  }

  static compareNaturalNormalized(stringA: string, stringB: string): number {
    return naturalCompare(StringUtils.normalize(stringA), StringUtils.normalize(stringB));
  }

  static checkIsEmail(stringToTest: string): boolean {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(stringToTest);
  }

  static containsOnlyNumber(stringToTest: string): boolean {
    return /^[0-9]+$/.test(stringToTest);
  }

  static checkIsEmailAuthorized(stringToTest: string): boolean {
    let isEmailValid = true;
    const normalizedStringToTest = StringUtils.normalize(stringToTest);
    FREE_TRIAL_DENY_EMAIL_LIST.forEach((emailExtension) => {
      if (isEmailValid) {
        isEmailValid = !normalizedStringToTest.includes(emailExtension);
      }
    });

    return isEmailValid;
  }

  static getFileNameFromUrl(url: string): string {
    const fileNameSplitted = url.split('/');
    return fileNameSplitted[fileNameSplitted.length - 1];
  }

  static transformToSnakeCase = (stringInput: string): string | undefined => {
    return stringInput
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      ?.map((x) => x.toLowerCase())
      .join('_');
  };

  static lowerCaseExceptFirstLetter = (stringInput: string): string => {
    const lowerCasedString = stringInput.toLowerCase();
    return lowerCasedString.charAt(0).toUpperCase() + lowerCasedString.slice(1);
  };

  static countWordsInString = (stringValue: string) => {
    return stringValue.trim().split(/\s+/).length;
  };

  static stringToBoolean = (value: string) => {
    switch (value.toLowerCase().trim()) {
      case 'true':
      case 'yes':
      case '1':
        return true;
      case 'false':
      case 'no':
      case '0':
      case null:
      default:
        return false;
    }
  };
}
