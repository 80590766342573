import { PlusOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { find, forEach } from 'lodash';
import React, { FunctionComponent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDebouncedCallback } from 'use-debounce';
import { PROJECTS, SUBTITLES_CONSTANTS, THEME } from '../../../../../../../../Constants';
import {
  ProjectScene,
  ProjectSceneElementsCode,
  SubtitleBlock,
  SubtitleBlockMode,
} from '../../../../../../../../utils/types/ProjectTypes';
import WindowUtils from '../../../../../../../../utils/WindowUtils';
import { VideoTrim, VideoTrimUtils } from '../../utils/VideoTrimUtils';
import SceneTimelineGrayOverlay from '../SceneTimelineGrayOverlay';
import AddSubtitleBlockModal from './AddSubtitleBlockModal';
import SceneSubtitleBlock from './SceneSubtitleBlock';

type Props = {
  projectId: number;
  scene: ProjectScene;
  subtitleBlocks: SubtitleBlock[];
  subtitlesLanguage: string;
  selectedElementId?: number;
  intervalTickWidth: number;
  videoTrimValue: VideoTrim;
  pipTrimValue: VideoTrim;
  onClick: (elementId: number) => void;
  onSubtitleBlockChange: (index: number, newVal: VideoTrim) => void;
  onAddSubtitleBlock: (subtitleBlock: SubtitleBlock) => void;
  onAddSubtitleBlocks: (subtitleBlock: SubtitleBlock[]) => void;
  callbackIfUnsavedChangesConfirmed: (
    callback: (saveConfirmed?: boolean) => void,
    confirmTitle?: string,
    confirmDescription?: string,
    skipConfirm?: boolean,
    selectedElement?: ProjectSceneElementsCode
  ) => void;
  callbackReset: () => void;
  submitSubtitleBlock: () => void; // save the currentlySelected subtitle block
  scrollLeft?: number;
};

type StyleProps = {
  videoTrimValue: VideoTrim;
  intervalTickWidth: number;
};

const useStyles = createUseStyles({
  container: ({ videoTrimValue, intervalTickWidth }: StyleProps) => {
    const minWidth = (videoTrimValue.trimEnd - videoTrimValue.trimStart) * intervalTickWidth;

    return {
      display: 'flex',
      height: '100%',
      width: '100%',
      minWidth,
      alignItems: 'center',
      '& .react-draggable-dragging': {
        cursor: 'move !important',
      },
    };
  },
  frameContainer: () => {
    return {
      left: -PROJECTS.TRIM.ANCHOR_WIDTH,
      position: 'absolute',
      height: '100%',
      width: '100%',
    };
  },
  addSubtitleBlockButton: ({ subtitleBlocks, intervalTickWidth, mousePositionOnXWithScroll }) => {
    const left =
      subtitleBlocks.length > 0
        ? (subtitleBlocks[subtitleBlocks.length - 1].endAt + SUBTITLES_CONSTANTS.MIN_DURATION) * intervalTickWidth
        : 0;
    // -26 below is to center the cursor and the button's icon
    const trueLeftFloatingPosition =
      mousePositionOnXWithScroll - 26 < 0 ? mousePositionOnXWithScroll : mousePositionOnXWithScroll - 26;

    return {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',

      left: mousePositionOnXWithScroll !== 0 ? trueLeftFloatingPosition : left,
      zIndex: 2,
      background: THEME.PROJECTS.EDITOR.TIMELINE.BUTTON_BACKGROUND,
      transition:
        'background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important',

      '&:hover, &:focus': {
        background: THEME.PROJECTS.EDITOR.TIMELINE.BUTTON_BACKGROUND_HOVER,
        color: 'black',
        borderColor: THEME.PROJECTS.EDITOR.TIMELINE.BUTTON_BACKGROUND_ACTIVE,
      },
      '&:active': {
        background: THEME.PROJECTS.EDITOR.TIMELINE.BUTTON_BACKGROUND_ACTIVE,
        color: 'black',
        borderColor: THEME.PROJECTS.EDITOR.TIMELINE.BUTTON_BORDER_ACTIVE,
      },
    };
  },
  warningTooltip: {
    borderRadius: 32,
  },
});

const SceneSubtitles: FunctionComponent<Props> = ({
  projectId,
  scene,
  subtitleBlocks,
  subtitlesLanguage,
  selectedElementId,
  intervalTickWidth,
  videoTrimValue,
  onClick,
  pipTrimValue,
  onSubtitleBlockChange,
  onAddSubtitleBlock,
  onAddSubtitleBlocks,
  callbackIfUnsavedChangesConfirmed,
  callbackReset,
  submitSubtitleBlock,
  scrollLeft = 0,
}: Props) => {
  const { t } = useTranslation();

  const [isMouseInbound, setIsMouseInbound] = useState(false);
  const [isAddSubtitleModalVisible, setIsAddSubtitleModalVisible] = useState(false);
  const [mousePositionOnXWithScroll, setMousePositionOnXWithScroll] = useState<number>(0);
  const [canAddSubtitleBlockAtLocation, setCanAddSubtitleBlockAtLocation] = useState(true);
  const trimStart = VideoTrimUtils.getMinTrimStartForPosition(videoTrimValue, pipTrimValue);
  const ref = useRef<any>();

  const canAddNewSubtitleBlockAtTheEnd = (position: number): boolean => {
    const { trimEnd } = videoTrimValue;
    const maxLeftPosition = trimEnd * intervalTickWidth;
    const maxNextSubtitleBlockPosition = position + SUBTITLES_CONSTANTS.MIN_DURATION * intervalTickWidth;

    return maxNextSubtitleBlockPosition < maxLeftPosition;
  };

  const [calculateMousePositionOnX] = useDebouncedCallback((event: React.MouseEvent<HTMLDivElement>) => {
    if (ref.current && !isAddSubtitleModalVisible && isMouseInbound) {
      const position = WindowUtils.getRelativeCoordinates(event, ref.current).x + scrollLeft;
      // position of the add button as time
      const positionAsTime = position / intervalTickWidth;

      const isInBlock = find(subtitleBlocks, (block) => {
        return block.startAt < positionAsTime && positionAsTime < block.endAt;
      });

      let canAddBetweenBlock = true;
      // if the cursor is not in a current subtitle block then we move the position for the add button
      if (!isInBlock) {
        // we will need to determine if we have a previous / next block depending on our add button position
        let previousBlock: SubtitleBlock | undefined;
        let nextBlock: SubtitleBlock | undefined;

        // we need to go through every subtitle blocks because they may not be sorted
        forEach(subtitleBlocks, (block: SubtitleBlock) => {
          // a) if we don't have a previous block and my position time > block.endAt => previousBlock = block
          // b) if we HAVE a previous block we check if there is a previous block CLOSER to the add button
          if (
            (!previousBlock && block.endAt < positionAsTime) ||
            (previousBlock && block.endAt > previousBlock.endAt && block.endAt < positionAsTime)
          ) {
            previousBlock = block;
          }

          // a) if we don't have a next block and my position time => nextBlock = block
          // b) if we HAVE a next block we check if there is a next block CLOSER to the add button
          if (
            (!nextBlock && block.startAt > positionAsTime) ||
            (nextBlock && block.startAt < nextBlock.startAt && block.startAt > positionAsTime)
          ) {
            nextBlock = block;
          }
        });

        if (previousBlock || nextBlock) {
          // !previousBlock && nextBlock => mean we are between 0 and the 1rst subtitle, then we check if we have space to add a subtitle
          if (!previousBlock && nextBlock && positionAsTime + SUBTITLES_CONSTANTS.MIN_DURATION < nextBlock.startAt) {
            canAddBetweenBlock = true;
            // !nextBlock => no subtitle to come = we have all the space we want
          } else if (!nextBlock) {
            canAddBetweenBlock = canAddNewSubtitleBlockAtTheEnd(position);
          } else if (previousBlock && nextBlock) {
            // here we check if we have the minimal duration (0.5s) to add a subtitle
            canAddBetweenBlock =
              nextBlock.startAt - previousBlock.endAt > SUBTITLES_CONSTANTS.MIN_DURATION &&
              nextBlock.startAt - positionAsTime > SUBTITLES_CONSTANTS.MIN_DURATION;
          } else {
            canAddBetweenBlock = false;
          }
        } else {
          // if we are here this mean we have NO existing subtitle
          canAddBetweenBlock = true;
        }

        setCanAddSubtitleBlockAtLocation(canAddBetweenBlock);
      }

      if (!isInBlock) {
        setMousePositionOnXWithScroll(position);
      }
    }
  }, 1);

  const classes = useStyles({
    subtitleBlocks,
    intervalTickWidth,
    videoTrimValue,
    mousePositionOnXWithScroll,
  });

  const calculateLeftBoundPosition = () => {
    return trimStart * intervalTickWidth;
  };

  const calculateRightBoundPosition = () => {
    return videoTrimValue.trimEnd * intervalTickWidth;
  };

  const calculateLeftBoundPositionForSubtitleBlock = (previousEndAt?: number) => {
    if (!previousEndAt) {
      return calculateLeftBoundPosition();
    }

    return previousEndAt * intervalTickWidth;
  };

  const calculateRightBoundPositionForSubtitleBlock = (subtitleDuration: number, nextStartAt?: number) => {
    if (!nextStartAt) {
      return calculateRightBoundPosition();
    }

    return (nextStartAt - subtitleDuration) * intervalTickWidth;
  };

  const calculatePositionOnXForSubtitleBlock = (startAt: number) => {
    return startAt * intervalTickWidth;
  };

  const showModal = (): void => {
    setIsAddSubtitleModalVisible(true);
  };

  const hideModal = (): void => {
    setIsAddSubtitleModalVisible(false);
    setMousePositionOnXWithScroll(0);
  };

  const canAddNewSubtitleBlock = (): boolean => {
    const { trimEnd } = videoTrimValue;
    const maxLeftPosition = trimEnd * intervalTickWidth;
    const nextBlockTime = subtitleBlocks[subtitleBlocks.length - 1]?.endAt || 0;
    const maxNextSubtitleBlockPosition = (nextBlockTime + SUBTITLES_CONSTANTS.MIN_DURATION) * intervalTickWidth;

    return maxNextSubtitleBlockPosition < maxLeftPosition;
  };

  if (!subtitleBlocks) {
    return <></>;
  }

  const handleUnsavedModification = (saveConfirmed?: boolean) => {
    if (saveConfirmed === false) {
      callbackReset();
    } else if (saveConfirmed === true) {
      submitSubtitleBlock();
    }

    showModal();
  };

  return (
    <div
      ref={ref}
      className={classes.container}
      onMouseEnter={() => setIsMouseInbound(true)}
      onMouseMove={(event) => calculateMousePositionOnX(event)}
      onMouseLeave={() => {
        if (!isAddSubtitleModalVisible) {
          setIsMouseInbound(false);
          setCanAddSubtitleBlockAtLocation(true);
        }
      }}
    >
      <SceneTimelineGrayOverlay
        element={ProjectSceneElementsCode.SUBTITLES}
        backgroundVideo={scene.backgroundVideo}
        pip={scene.pip}
        animation={scene.animation}
        videoTrimValue={videoTrimValue}
        intervalTickWidth={intervalTickWidth}
      >
        <div className={classes.frameContainer}>
          {subtitleBlocks.map((subtitle, index) => {
            const trueXPosition = calculatePositionOnXForSubtitleBlock(subtitle.startAt);
            const nextSubtitleBlock = subtitleBlocks[index + 1]
              ? subtitleBlocks[index + 1].startAt
              : videoTrimValue.trimEnd;
            const prevSubtitleBlock = subtitleBlocks[index - 1] ? subtitleBlocks[index - 1].endAt : 0;
            const leftBoundSubtitleBlock = calculateLeftBoundPositionForSubtitleBlock(prevSubtitleBlock);
            const rightBoundSubtitleBlock = calculateRightBoundPositionForSubtitleBlock(
              subtitle.endAt - subtitle.startAt,
              nextSubtitleBlock
            );

            const maxSize = (subtitle.startAt + SUBTITLES_CONSTANTS.MAX_DURATION) * intervalTickWidth;
            const maxRightBound = (subtitle.endAt - subtitle.startAt) * intervalTickWidth + rightBoundSubtitleBlock;
            const key = `subtitle_block_${index}`;

            return (
              <SceneSubtitleBlock
                subtitle={subtitle}
                key={key}
                onClick={onClick}
                trueXPosition={trueXPosition}
                leftBoundSubtitleBlock={leftBoundSubtitleBlock}
                rightBoundSubtitleBlock={rightBoundSubtitleBlock}
                intervalTickWidth={intervalTickWidth}
                isActive={selectedElementId === subtitle.id}
                width={Math.min(maxSize, maxRightBound)}
                onSubtitleBlockChange={(newVal) => onSubtitleBlockChange(index, newVal)}
                isSubtitleModeAuto={subtitle.mode === SubtitleBlockMode.AUTO}
              />
            );
          })}
        </div>
      </SceneTimelineGrayOverlay>
      <Tooltip
        className={classes.warningTooltip}
        title={
          (!canAddSubtitleBlockAtLocation &&
            t('charters.projects.projectEditor.sceneEditor.fields.subtitles.disabledWarning')) ||
          (canAddSubtitleBlockAtLocation &&
            t('charters.projects.projectEditor.sceneEditor.fields.subtitles.addSubtitle'))
        }
      >
        <Button
          shape="round"
          icon={<PlusOutlined />}
          className={classes.addSubtitleBlockButton}
          onClick={() =>
            callbackIfUnsavedChangesConfirmed(
              handleUnsavedModification,
              undefined,
              undefined,
              false,
              ProjectSceneElementsCode.SUBTITLES
            )
          }
          disabled={!canAddSubtitleBlockAtLocation}
        />
      </Tooltip>

      {canAddSubtitleBlockAtLocation && (
        <AddSubtitleBlockModal
          projectId={projectId}
          sceneId={scene.id}
          isVisible={isAddSubtitleModalVisible}
          subtitleBlocks={subtitleBlocks}
          callbackHideModal={hideModal}
          subtitlesLanguage={subtitlesLanguage}
          onAddSubtitleBlock={onAddSubtitleBlock}
          onAddSubtitleBlocks={(subtitles) => {
            onAddSubtitleBlocks(subtitles);
            setIsAddSubtitleModalVisible(false);
          }}
          positionTime={mousePositionOnXWithScroll > 0 ? mousePositionOnXWithScroll / intervalTickWidth : 0}
        />
      )}
    </div>
  );
};

export default SceneSubtitles;
