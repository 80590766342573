import { CloseCircleFilled, EditFilled, GatewayOutlined } from '@ant-design/icons/lib';
import { Button, Tooltip } from 'antd';
import { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import log from 'loglevel';
import React, { FunctionComponent, Ref, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { MEDIA_STATUS } from '../../Constants';
import { saveCharterMedia } from '../../redux/action/ChartersAction';
import { RootState } from '../../redux/RootState';
import { APIManager } from '../../services/api/APIManager';
import { getCharterMedia } from '../../services/api/ChartersService';
import { APIGetCharterMediaResponse, MediaFile } from '../../services/api/types/ChartersServiceTypes';
import StringUtils from '../../utils/StringUtils';
import { MediaType } from '../../utils/types/MediaTypes';
import MediaResizerModal from '../media-resizer/MediaResizerModal';
import Preformatted from '../preformatted/Preformatted';
import { WebcamRecorderOptions } from '../webcam-recorder/WebcamRecorder';
import MediaSelectorModal from './MediaSelectorModal';

type Props = {
  value?: MediaFile;
  onChange?: (newValue: MediaFile | undefined) => void;
  fieldLabel: string;
  format?: string;
  mediaTypes?: MediaType[];
  size?: 'normal' | 'small';
  trigger?: JSX.Element;
  disabled?: boolean;
  disableReset?: boolean;
  defaultVisible?: boolean;
  defaultMediaType?: MediaType;
  enableWebcamRecording?: boolean;
  webcamRecordingOptions?: WebcamRecorderOptions;
  onClick?: () => void;
  callbackOnCancelModal?: () => void;
  disabledMedia?: MediaFile | MediaFile[];
  disabledTabMessages?: { mediaType: MediaType; message: string }[];
};

const useStyles = createUseStyles({
  selectedFileNameContainer: {
    cursor: 'pointer',
    '& .anticon': {
      marginRight: 4,
    },
  },
  selectedFileName: {
    fontSize: '11px',
    marginRight: 4,
  },
  selectMediaButton: {
    padding: 0,
  },
  smallMediaSelectorButton: {
    fontSize: '11px',
  },
  disabledSelectedFilename: {
    cursor: 'not-allowed',
    opacity: '0.4',
  },
});

const MediaSelector: FunctionComponent<Props> = React.forwardRef(
  (
    {
      value,
      onChange,
      fieldLabel,
      mediaTypes,
      format,
      size = 'normal',
      trigger,
      disabled = false,
      disableReset = false,
      defaultVisible = false,
      defaultMediaType = undefined,
      enableWebcamRecording = false,
      webcamRecordingOptions,
      onClick,
      callbackOnCancelModal = undefined,
      disabledMedia,
      disabledTabMessages,
    }: Props,
    ref: Ref<any>
  ) => {
    const [mediaToResize, setMediaToResize] = useState<MediaFile>();
    const [isModalVisible, setIsModalVisible] = useState(defaultVisible);
    const [isResizerModalVisible, setIsResizerModalVisible] = useState(false);
    const [isMediaLoading, setIsMediaLoading] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());

    const charter = useSelector((state: RootState) => state.charters.current);
    const mediaList = useSelector((state: RootState) => state.charters.currentCharterMedia);
    const charterId = charter?.id;

    const shouldResizeMedia = useMemo(() => !!format, [format]);

    useEffect(() => {
      const cancelTokenSource = cancelTokenSourceRef.current;

      return (): void => {
        cancelTokenSource.cancel('Cancelled fetching charter media due to component unmount.');
      };
    }, []);

    // Fetch the media
    useEffect(() => {
      if (!(charterId && isModalVisible)) {
        return;
      }
      setIsMediaLoading(true);

      const cancelTokenSource = cancelTokenSourceRef.current;
      getCharterMedia(charterId, true, cancelTokenSource)
        .then((response: APIGetCharterMediaResponse) => {
          const { data } = response;
          const processedMedia = mediaTypes
            ? data.items.filter(
                (mediaItem) => mediaItem.status === MEDIA_STATUS.PROCESSED && mediaTypes.includes(mediaItem.mediaType)
              )
            : data.items.filter((mediaItem) => mediaItem.status === MEDIA_STATUS.PROCESSED);
          dispatch(saveCharterMedia(processedMedia));
        })
        .catch((e) => {
          log.debug('Error during charter media fetch', e);
        })
        .finally(() => {
          setIsMediaLoading(false);
        });
    }, [charterId, mediaTypes, isModalVisible, dispatch]);

    // Called to update the upper `form` object
    const triggerChange = (changedValue: MediaFile | undefined): void => {
      if (onChange) {
        onChange(changedValue);
      }
    };

    const onOpenModal = (): void => {
      setIsModalVisible(true);

      // If the onClick callback is defined, execute it
      if (onClick) {
        onClick();
      }
    };

    const onCloseModal = (): void => {
      setIsModalVisible(false);
    };

    const onCancelModal = (): void => {
      onCloseModal();
      if (callbackOnCancelModal) {
        callbackOnCancelModal();
      }
    };

    const onOpenResizerModal = (mediaToCrop: MediaFile): void => {
      // If the onClick callback is defined, execute it
      if (onClick) {
        onClick();
      }

      setIsResizerModalVisible(true);
      setMediaToResize(mediaToCrop);
    };

    const onCloseResizerModal = (): void => {
      setIsResizerModalVisible(false);
      if (callbackOnCancelModal) {
        callbackOnCancelModal();
      }
    };

    const onSelectMedia = (media: MediaFile): void => {
      if (!shouldResizeMedia) {
        triggerChange(media);
        onCloseModal();
        return;
      }
      onOpenResizerModal(media);
      onCloseModal();
    };

    const onResetMedia = (): void => {
      triggerChange(undefined);
    };

    const onMediaCropped = (croppedMedia: MediaFile): void => {
      triggerChange(croppedMedia);
      onCloseResizerModal();
    };

    return (
      <div ref={ref}>
        <div onClick={onOpenModal}>
          {trigger || (
            <>
              {value ? (
                <span
                  className={classNames(
                    classes.selectedFileNameContainer,
                    disabled && classes.disabledSelectedFilename
                  )}
                >
                  <Preformatted className={classes.selectedFileName}>
                    {value.publicName ?? StringUtils.getFileNameFromUrl(value.url)}
                  </Preformatted>
                  <Tooltip title={t('charters.mediaLibrary.mediaSelector.edit')}>
                    <EditFilled />
                  </Tooltip>
                  {shouldResizeMedia && (
                    <Tooltip title={t('charters.mediaLibrary.mediaSelector.crop')}>
                      <span
                        onClick={(e): void => {
                          e.stopPropagation();
                        }}
                      >
                        <span onClick={() => onOpenResizerModal(value)}>
                          <GatewayOutlined />
                        </span>
                      </span>
                    </Tooltip>
                  )}
                  {!disableReset && (
                    <Tooltip title={t('charters.mediaLibrary.mediaSelector.reset')}>
                      <CloseCircleFilled
                        onClick={(e): void => {
                          e.stopPropagation();
                          onResetMedia();
                        }}
                      />
                    </Tooltip>
                  )}
                </span>
              ) : (
                <Button
                  type="link"
                  className={classNames(
                    size === 'small' && classes.smallMediaSelectorButton,
                    classes.selectMediaButton
                  )}
                  disabled={disabled}
                >
                  {t('charters.mediaLibrary.select')}
                </Button>
              )}
            </>
          )}
        </div>

        <MediaSelectorModal
          isVisible={isModalVisible}
          isLoading={isMediaLoading}
          fieldLabel={fieldLabel}
          mediaList={mediaList}
          selectedMedia={value}
          mediaTypes={mediaTypes}
          multiple={false}
          defaultMediaType={defaultMediaType}
          enableWebcamRecording={enableWebcamRecording}
          webcamRecordingOptions={webcamRecordingOptions}
          format={format}
          onCancel={onCancelModal}
          onSelectMedia={onSelectMedia}
          disabledMedia={disabledMedia}
          disabledTabMessages={disabledTabMessages}
        />

        {mediaToResize && shouldResizeMedia && (
          <MediaResizerModal
            mediaToResize={mediaToResize}
            format={format!}
            onMediaCropped={onMediaCropped}
            isVisible={isResizerModalVisible}
            onCloseModal={onCloseResizerModal}
          />
        )}
      </div>
    );
  }
);

export default MediaSelector;
