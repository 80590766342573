import { Roles } from '../core/rule/RolesTypes';
import { UsersModelCharter } from '../services/api/types/ChartersServiceTypes';
import { APIModelCompanyStats } from '../services/api/types/CompaniesServiceTypes';
import { SubRowCharterSelect } from '../utils/types/UsersTableTypes';
import Company from './Company';

type Stats = {
  max: number;
  total: number;
  active: number;
};

type StatsUsers = Stats & {
  adminOwners: { total: number; active: number };
  creators: { total: number; active: number };
};

type SubtitleStats = {
  total: number;
  currentMonth: number;
};

export default class CompanyStats {
  private readonly _charters: Stats;
  private readonly _users: StatsUsers;
  private readonly _subtitles: SubtitleStats;

  constructor(apiCompany: APIModelCompanyStats, company: Company) {
    this._charters = {
      ...apiCompany.charters,
      max: company.maxNbCharters,
    };
    this._users = {
      ...apiCompany.users,
      max: company.maxNbLicenses,
    };
    this._subtitles = apiCompany.subtitles;
  }

  get charters(): Stats {
    return this._charters;
  }

  get users(): StatsUsers {
    return this._users;
  }

  get subtitles(): SubtitleStats {
    return this._subtitles;
  }

  // ----------- UPDATE --------------
  private incrementUserStats = (): void => {
    this._users.active += 1;
    this._users.total += 1;
  };

  private decrementUserStats = (): void => {
    this._users.active -= 1;
    this._users.total -= 1;
  };

  userIsActiveUpdated = (isActivated: boolean): void => {
    if (isActivated) {
      this.userActivated();
    } else {
      this.userDeactivated();
    }
  };

  userActivated = (): void => {
    this.incrementUserStats();
  };

  userDeactivated = (): void => {
    this.decrementUserStats();
  };

  userAddedWithRole = (role: Roles): void => {
    if (role !== Roles.KnlTeam) {
      this.incrementUserStats();
    }
  };

  userUpdatedFromRole = (fromRole: Roles, newRole: Roles, isActive: boolean): void => {
    if (fromRole === newRole || !isActive) {
      return;
    }

    if (fromRole === Roles.KnlTeam) {
      this.incrementUserStats();
    } else if (newRole === Roles.KnlTeam) {
      this.decrementUserStats();
    }
  };

  // ----------- CHECK ---------------
  isUsersLicensesInfinite = (): boolean => {
    return this.users.max === 0;
  };

  canCreateNewUser = (isUserPresentInACharter?: boolean): boolean => {
    if (this.isUsersLicensesInfinite() || isUserPresentInACharter) {
      return true;
    }
    return this.users.active + 1 <= this.users.max;
  };

  canChangeRole = (previousRole: Roles, isActive: boolean): boolean => {
    if (!isActive || this.isUsersLicensesInfinite()) {
      return true;
    }

    return (
      (previousRole === Roles.KnlTeam && this.users.active + 1 <= this.users.max) || previousRole !== Roles.KnlTeam
    );
  };

  canUpdateUsers = (updatedUsers: number): boolean => {
    if (this.isUsersLicensesInfinite()) {
      return true;
    }
    const total = updatedUsers + this.users.active;
    return total <= this.users.max;
  };

  canUpdateUsersIsActive = (usersToUpdate: UsersModelCharter[], isActive: boolean): boolean => {
    if (!isActive || this.isUsersLicensesInfinite()) {
      return true;
    }

    let totalUserModification = 0;
    usersToUpdate.forEach((user): void => {
      if (user.acl.isActive === isActive) {
        return;
      }

      // user inactive -> active
      totalUserModification += 1;
    });

    const total = totalUserModification + this.users.active;
    return total <= this.users.max;
  };

  canUpdateUsersFromRoleToRole = (usersToUpdate: UsersModelCharter[], toRole: Roles): boolean => {
    if (toRole === Roles.KnlTeam || this.isUsersLicensesInfinite()) {
      return true;
    }

    let totalUserModification = 0;
    usersToUpdate.forEach((user): void => {
      if (user.acl.role === toRole || !user.acl.isActive) {
        return;
      }

      // is from role knlTeam to [Creator, admin, owner]
      if (user.acl.role === Roles.KnlTeam) {
        totalUserModification += 1;
      }
    });

    const total = totalUserModification + this.users.active;
    return total <= this.users.max;
  };

  canUpdateCompanyUsersIsActive = (usersToUpdate: SubRowCharterSelect[], isActive: boolean): boolean => {
    if (!isActive || this.isUsersLicensesInfinite()) {
      return true;
    }

    let totalUserModification = 0;
    usersToUpdate.forEach((user): void => {
      const isActiveArrays = user.charters.filter((charter) => charter.currentUser.isActive);
      if (isActiveArrays.length === 0) {
        for (let i = 0; i < user.charters.length; i++) {
          const charter = user.charters[i];
          if (charter.currentUser.isActive !== isActive) {
            totalUserModification += 1;
            break;
          }
        }
      }
    });

    const total = totalUserModification + this.users.active;
    return total <= this.users.max;
  };

  canUpdateCompanyUsersFromRoleToRole = (usersToUpdate: SubRowCharterSelect[], toRole: Roles): boolean => {
    if (toRole === Roles.KnlTeam || this.isUsersLicensesInfinite()) {
      return true;
    }

    let totalUserModification = 0;
    usersToUpdate.forEach((user): void => {
      const notKnlRoleArrays = user.charters.filter((charter) => charter.currentUser.role !== Roles.KnlTeam);

      if (notKnlRoleArrays.length === 0) {
        for (let i = 0; i < user.charters.length; i++) {
          const charter = user.charters[i];
          if (charter.currentUser.role === toRole || !charter.currentUser.isActive) {
            return;
          }
          if (charter.currentUser.role === Roles.KnlTeam) {
            totalUserModification += 1;
            break;
          }
        }
      }
    });

    const total = totalUserModification + this.users.active;
    return total <= this.users.max;
  };

  canLinkUserToCharters = (isUserPresentAndActive?: boolean): boolean => {
    if (this.isUsersLicensesInfinite() || isUserPresentAndActive) {
      return true;
    }

    return this.users.active + 1 <= this.users.max;
  };
}
