import moment from 'moment';
import { DependencyList, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteNotificationById, editNotificationById, pushNotification } from '../redux/action/AppAction';
import NotificationUtils from '../utils/NotificationUtils';
import { Notification } from '../utils/types/NotificationTypes';

const useUnsavedChangesNotification = (
  unsavedChangesCondition: boolean,
  notificationMessage: string,
  dependencies: DependencyList
): Notification | undefined => {
  const [unsavedNotification, setUnsavedNotification] = useState<Notification>();
  const dispatch = useDispatch();

  useEffect(() => {
    const clearNotification = (): void => {
      if (unsavedNotification) {
        dispatch(deleteNotificationById(unsavedNotification.id));
        setUnsavedNotification(undefined);
      }
    };

    // If we have unsaved changes
    if (unsavedChangesCondition) {
      // If one notification already existed, we update it
      if (unsavedNotification) {
        dispatch(
          editNotificationById(unsavedNotification.id, {
            message: notificationMessage,
            isRead: false,
          })
        );
      } else {
        // Else we create a new notification
        const id = NotificationUtils.generateNotificationId();
        const notification: Notification = {
          id,
          creationDate: moment(),
          type: 'warning',
          message: notificationMessage,
        };
        setUnsavedNotification(notification);
        dispatch(pushNotification(notification));
      }
    } else {
      // If we no longer have unsaved changes, we clear the notification
      clearNotification();
    }

    return (): void => clearNotification();
    // eslint-disable-next-line
  }, [unsavedNotification, ...dependencies]);

  return unsavedNotification;
};

export default useUnsavedChangesNotification;
