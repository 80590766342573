import React, { FunctionComponent } from 'react';
import { AssetType } from '../../../../services/api/types/ChartersServiceTypes';
import GenericCharterAssets from './manager/GenericCharterAssets';

const CharterMusics: FunctionComponent = () => {
  return (
    <GenericCharterAssets
      acceptFileType="audio/wave,audio/wav,audio/x-wav,audio/x-pn-wav,audio/ogg,audio/mp3,audio/midi,audio/mpeg,audio/mp4,audio/m4a,audio/x-m4a"
      assetType={AssetType.MUSIC}
      sizeLimit={20}
    />
  );
};

export default CharterMusics;
