import { Slider, Tooltip } from 'antd';
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import { BsPauseFill, BsPlayFill } from 'react-icons/bs';
import { FaVolumeDown, FaVolumeMute, FaVolumeUp } from 'react-icons/fa';
import { createUseStyles } from 'react-jss';
import ReactPlayer, { Config } from 'react-player';
import TimeUtils from '../../utils/TimeUtils';

type Props = {
  url: string;
  className?: string;
  config?: Config;
};

const useStyles = createUseStyles({
  mainContainer: {
    height: '100%',
  },
  videoControlsContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    padding: 8,
    height: 40,
    zIndex: 3,
    background: '#00000070',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    opacity: 1,
    '& > *:not(:last-child)': {
      marginRight: 16,
    },
  },
  controlButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: 20,
  },
  videoSeekBar: {
    display: 'flex',
    flexGrow: 2,
  },
  videoTimeIndicator: {
    fontSize: '12px',
    color: 'white',
  },
  volumeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginRight: 4,
    fontSize: '14px !important',
  },
  volumeSliderTooltip: {
    '& .ant-tooltip-inner': {
      padding: '4px 0px 10px',
    },
  },
  volumeSlider: {
    height: 100,
  },
});

const VideoWithCustomControls: FunctionComponent<Props> = ({ url, className, config }: Props) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [videoPercentage, setVideoPercentage] = useState(0);
  const [videoNode, setVideoNode] = useState<HTMLVideoElement>();
  const [videoVolume, setVideoVolume] = useState(1);

  const classes = useStyles();

  useEffect(() => {
    if (!videoNode) {
      return undefined;
    }

    videoNode.muted = false;

    const onPlay = (): void => {
      setIsPlaying(true);
    };
    const onPause = (): void => {
      setIsPlaying(false);
    };
    const onTimeUpdate = (): void => {
      const percentage = videoNode.currentTime / videoNode.duration;
      setVideoPercentage(percentage);
    };

    videoNode.addEventListener('play', onPlay);
    videoNode.addEventListener('pause', onPause);
    videoNode.addEventListener('timeupdate', onTimeUpdate);

    return (): void => {
      videoNode.removeEventListener('play', onPlay);
      videoNode.removeEventListener('pause', onPause);
      videoNode.removeEventListener('timeupdate', onTimeUpdate);
    };
  }, [videoNode]);

  useEffect(() => {
    if (!videoNode) {
      return;
    }

    videoNode.volume = videoVolume;
  }, [videoNode, videoVolume]);

  const videoRef = useCallback((node) => {
    if (node !== null) {
      const videoElement = node.querySelector('video');
      setVideoNode(videoElement);
    }
  }, []);

  const onPlayClick = () => {
    setIsPlaying(true);
  };

  const onPauseClick = () => {
    setIsPlaying(false);
  };

  const onVolumeChange = (percentage: number | undefined): void => {
    if (percentage !== undefined) {
      setVideoVolume(percentage);
    }
  };

  const onMuteVolumeToggle = (): void => {
    // If the volume is already muted, unmute it, else mute it
    setVideoVolume((prev) => (prev > 0 ? 0 : 1));
  };

  const onSeekBarChange = (percentage: number | undefined): void => {
    if (!videoNode) {
      return;
    }

    if (percentage !== undefined) {
      videoNode.currentTime = percentage * videoNode.duration;
      setVideoPercentage(percentage);
    }
  };

  const formatSeekbarValue = (value?: number): ReactNode => {
    if (!videoNode || !value) {
      return '';
    }
    return TimeUtils.formatSecondsIntoDuration(videoNode.currentTime);
  };

  const renderVolumeSettings = (): ReactNode => {
    // Render the right volume icon depending on the volume value
    const renderVolumeIcon = () => {
      if (videoVolume === 0) {
        return <FaVolumeMute onClick={onMuteVolumeToggle} className={classes.controlButton} />;
      }
      if (videoVolume >= 0.5) {
        return <FaVolumeUp onClick={onMuteVolumeToggle} className={classes.controlButton} />;
      }

      return <FaVolumeDown onClick={onMuteVolumeToggle} className={classes.controlButton} />;
    };

    return (
      <Tooltip
        title={
          <div className={classes.volumeSlider}>
            <Slider
              vertical
              value={videoVolume}
              onChange={onVolumeChange}
              tipFormatter={null}
              min={0}
              max={1}
              step={0.01}
            />
          </div>
        }
        overlayClassName={classes.volumeSliderTooltip}
      >
        <div className={classes.volumeContainer}>
          {renderVolumeIcon()}
          <div />
        </div>
      </Tooltip>
    );
  };

  return (
    <div ref={videoRef} className={classes.mainContainer}>
      <ReactPlayer
        url={url}
        className={className}
        controls={false}
        playing={isPlaying}
        height="inherit"
        width="inherit"
        config={config}
      />

      <div className={classes.videoControlsContainer}>
        {isPlaying ? (
          <BsPauseFill className={classes.controlButton} onClick={onPauseClick} />
        ) : (
          <BsPlayFill className={classes.controlButton} onClick={onPlayClick} />
        )}

        {renderVolumeSettings()}
        <Slider
          className={classes.videoSeekBar}
          min={0}
          max={1}
          step={0.01}
          onChange={onSeekBarChange}
          value={videoPercentage}
          tipFormatter={formatSeekbarValue}
        />
        {videoNode && videoNode.currentTime !== undefined && videoNode.duration !== undefined && (
          <div className={classes.videoTimeIndicator}>
            {TimeUtils.formatSecondsIntoDuration(videoNode.currentTime)}
            {!Number.isNaN(videoNode.duration) && Number.isFinite(videoNode.duration)
              ? ` / ${TimeUtils.formatSecondsIntoDuration(videoNode.duration)}`
              : ''}
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoWithCustomControls;
