import { Tooltip } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { PROJECTS, THEME } from '../../../../../../../../Constants';
import MathUtils from '../../../../../../../../utils/MathUtils';
import { AnchorType, ProjectSceneMedia, SubtitleBlock } from '../../../../../../../../utils/types/ProjectTypes';
import { VideoTrim } from '../../utils/VideoTrimUtils';
import SceneTrimAnchor from '../scene-video-row/SceneTrimAnchor';

type Props = {
  subtitle: SubtitleBlock;
  intervalTickWidth: number;

  trueXPosition: number;
  leftBoundSubtitleBlock: number;
  rightBoundSubtitleBlock: number;

  onClick: (elementId: number) => void;
  isActive: boolean;
  width: number;
  onSubtitleBlockChange: (newVal: VideoTrim) => void;
  isSubtitleModeAuto: boolean;
};

type StyleProps = {
  isActive: boolean;
  videoTrimValue: VideoTrim;
  backgroundVideo: ProjectSceneMedia;
  pipTrimValue: VideoTrim;
  width: number;
  duration: number;
  intervalTickWidth: number;
  isSubtitleModeAuto: boolean;
};

const useStyles = createUseStyles({
  frameContainer: () => {
    return {
      left: -PROJECTS.TRIM.ANCHOR_WIDTH,
      position: 'absolute',
      height: '100%',
      width: '100%',
    };
  },
  subtitleBlockBar: ({ isActive = true, isSubtitleModeAuto = false }: StyleProps) => {
    const borderColor = isSubtitleModeAuto
      ? THEME.PROJECTS.EDITOR.TIMELINE.GENERATED_SELECTED_ELEMENT_BORDER
      : THEME.PROJECTS.EDITOR.TIMELINE.SELECTED_ELEMENT_BORDER;
    const backgroundColor = isSubtitleModeAuto
      ? THEME.PROJECTS.EDITOR.TIMELINE.GENERATED_ELEMENT_BACKGROUND
      : THEME.PROJECTS.EDITOR.TIMELINE.ELEMENT_BACKGROUND;

    return {
      position: 'absolute',
      zIndex: 5,
      backgroundColor,
      height: '100%',
      borderRadius: isActive ? '6px 0 0 6px' : 6,
      display: 'flex',
      alignItems: 'center',
      borderTop: isActive ? `6px solid ${borderColor}` : undefined,
      borderBottom: isActive ? `6px solid ${borderColor}` : undefined,
      borderLeft: isActive ? `6px solid ${borderColor}` : undefined,
      cursor: 'pointer',
      left: 14,
    };
  },
  subtitleBlockText: ({ isActive }) => ({
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    margin: isActive ? '0 4px' : '0 10px',
    color: 'white',
    fontFamily: 'SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace',
    fontSize: 12,
  }),
  tooltipText: {
    width: '100%',
    display: 'block',
  },
  tooltipAutoGeneratedBlock: {
    fontStyle: 'italic',
  },
});

const SceneSubtitleBlock: FunctionComponent<Props> = ({
  subtitle,
  intervalTickWidth,
  trueXPosition,
  leftBoundSubtitleBlock,
  rightBoundSubtitleBlock,
  onClick,
  isActive,
  width,
  onSubtitleBlockChange,
  isSubtitleModeAuto,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles({ isActive, width, isSubtitleModeAuto });

  const [subtitleTrimValue, setSubtitleTrimValue] = useState({
    trimStart: subtitle.startAt,
    trimEnd: subtitle.endAt,
  });

  useEffect(() => {
    setSubtitleTrimValue({
      trimStart: subtitle.startAt,
      trimEnd: subtitle.endAt,
    });
  }, [subtitle]);

  const handleSelection = () => {
    onClick(subtitle.id);
  };

  const handleAnchorChange = (localTrim: VideoTrim) => {
    setSubtitleTrimValue(localTrim);
  };

  const handleTrimChange = (localTrim: VideoTrim) => {
    setSubtitleTrimValue(localTrim);
    onSubtitleBlockChange(localTrim);
  };

  const getFormattedTrimValue = (newDelay: number, duration: number) => {
    return {
      trimStart: MathUtils.formatInputValueWithXDecimals(Number(newDelay), 2),
      trimEnd: MathUtils.formatInputValueWithXDecimals(Number(newDelay + duration), 2),
    };
  };

  const onDrag = (event: DraggableEvent, data: DraggableData) => {
    const newDelay = data.x / intervalTickWidth;
    const duration = subtitleTrimValue.trimEnd - subtitleTrimValue.trimStart;

    setSubtitleTrimValue(getFormattedTrimValue(newDelay, duration));
  };

  const onDragStop = (event: DraggableEvent, data: DraggableData) => {
    const newData = data.x / intervalTickWidth;
    const duration = subtitleTrimValue.trimEnd - subtitleTrimValue.trimStart;

    const formattedData = getFormattedTrimValue(newData, duration);

    setSubtitleTrimValue(formattedData);
    onSubtitleBlockChange(formattedData);
  };

  const getTooltipText = (): string | JSX.Element => {
    if (!isSubtitleModeAuto) {
      return subtitle.text;
    }

    return (
      <span>
        <span className={classes.tooltipText}>{subtitle.text}</span>
        <small className={classes.tooltipAutoGeneratedBlock}>
          {t('charters.projects.projectEditor.sceneEditor.fields.subtitles.autoGenerated')}
        </small>
      </span>
    );
  };

  return (
    <>
      <Draggable
        axis="x"
        position={{
          x: trueXPosition,
          y: 0,
        }}
        bounds={{
          left: leftBoundSubtitleBlock,
          top: 0,
          right: rightBoundSubtitleBlock,
          bottom: 0,
        }}
        onStart={() => {
          onClick(subtitle.id);
        }}
        onDrag={onDrag}
        onStop={onDragStop}
      >
        <div
          className={classNames(classes.subtitleBlockBar)}
          onClick={handleSelection}
          style={{
            width: (subtitleTrimValue.trimEnd - subtitleTrimValue.trimStart) * intervalTickWidth + 1, // +1 is to fill a gap that may happen in the css
          }}
        >
          <Tooltip title={getTooltipText()}>
            <span className={classes.subtitleBlockText}>{subtitle.text}</span>
          </Tooltip>
        </div>
      </Draggable>
      {isActive && (
        <SceneTrimAnchor
          type={AnchorType.END_ANCHOR}
          width={width}
          intervalTickWidth={intervalTickWidth}
          trimValue={subtitleTrimValue}
          callbackAnchorChange={handleAnchorChange}
          onTrimChange={handleTrimChange}
          delay={0}
          anchorColor={
            isSubtitleModeAuto
              ? THEME.PROJECTS.EDITOR.TIMELINE.GENERATED_SELECTED_ELEMENT_BORDER
              : THEME.PROJECTS.EDITOR.TIMELINE.SELECTED_ELEMENT_BORDER
          }
        />
      )}
    </>
  );
};

export default SceneSubtitleBlock;
