import { EyeFilled, LoadingOutlined } from '@ant-design/icons';
import { Button, Card, Skeleton, Tag, Typography } from 'antd';
import classNames from 'classnames';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { THEME } from '../../Constants';
import useCanAccess from '../../hooks/useCanAccess';
import ScenarioVariantDescription from '../../pages/charters/charter-scenarios/components/scenario-variant-description/ScenarioVariantDescription';
import { RootState } from '../../redux/RootState';
import { Scenario } from '../../services/api/types/ChartersServiceTypes';
import ScenarioUtils from '../../utils/ScenarioUtils';
import { AnimationFormats } from '../../utils/types/AnimationTypes';
import { ScenarioFormat, ScenarioScopes } from '../../utils/types/ScenarioTypes';
import AccessChecker from '../access-checker/AccessChecker';
import FormatTag from '../tag/format-tag/FormatTag';

const { Paragraph } = Typography;

type Props = {
  scenario: Scenario;
  language?: string;
  format?: AnimationFormats;
  isSelected?: boolean;
  onClick?: () => void;
};

const useStyles = createUseStyles({
  scenarioCard: {
    minHeight: 150,
    '& .ant-card-body': {
      padding: 9,
    },
    borderRadius: 10,
    backgroundColor: 'rgba(122, 158, 255, 0.15)',
    position: 'relative',
  },
  selectedCard: {
    outline: `6px solid ${THEME.MENU.MAIN_COLOR}`,
    outlineOffset: '-6px',
  },
  clickableCard: {
    cursor: 'pointer',
  },
  disabledCard: {
    cursor: 'not-allowed',
    opacity: 0.4,
  },
  scenarioMetadataContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  coverImageContainer: {
    height: 72,
    width: 72,
    minWidth: 72,
    minHeight: 72,
  },
  coverImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: 10,
  },
  coverImageSkeletonContainer: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  coverImageSkeleton: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: 10,
    '& .ant-skeleton-image': {
      height: '100%',
      width: '100%',
    },
  },
  coverImageLoadingSpinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  invisibleImageForLoad: {
    display: 'none',
  },
  scenarioTitleAndDescriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
    marginRight: 6,
  },
  scenarioTitle: {
    fontWeight: 'bold',
    marginBottom: '0px !important',
    lineHeight: '1.2em',
  },
  scenarioDescription: {
    marginBottom: '0px !important',
    lineHeight: '1.2em',
    marginTop: 4,
  },
  charterAndFormatsContainer: {
    paddingTop: 4,
  },
  charterTag: {
    position: 'absolute',
    bottom: 9,
    background: '#FFD01F',
    fontWeight: 'bold',
    border: 0,
    borderRadius: 5,
  },
  formatsContainer: {
    float: 'right',
  },
  scenarioDescriptionIcon: {
    position: 'absolute',
    bottom: 8,
    right: 8,
    '& button': {
      height: 22,
      borderRadius: 5,
      fontSize: 12,
    },
  },
});

const ScenarioCard: FunctionComponent<Props> = ({ scenario, language, format, isSelected = false, onClick }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isCoverImageLoading, setIsCoverImageLoading] = useState(true);
  const currentCharter = useSelector((state: RootState) => state.charters.current);
  const permissionToUseScenario = useCanAccess(`SCENARIO_${scenario.code}`);
  const hasAccessToScenario = permissionToUseScenario.hasUserAccess();

  const referenceVariant = useMemo(() => {
    const defaultVariant = scenario.variants[0];
    if (language) {
      if (format) {
        const variantOfLangAndFormat = scenario.variants.find(
          (variant) => variant.language === language && variant.format === format
        );
        return variantOfLangAndFormat ?? defaultVariant;
      }

      const variantOfLang = scenario.variants.find((variant) => variant.language === language);
      return variantOfLang ?? defaultVariant;
    }

    return defaultVariant;
  }, [scenario, language, format]);

  const title = useMemo(() => referenceVariant.title, [referenceVariant]);
  const description = useMemo(() => referenceVariant.description, [referenceVariant]);

  const renderCoverImage = () => {
    const coverUrl = scenario.coverImageSource.url;

    return (
      <div className={classes.coverImageContainer}>
        <img
          src={coverUrl}
          alt="Cover source"
          className={classes.invisibleImageForLoad}
          onLoad={() => setIsCoverImageLoading(false)}
          loading="lazy"
        />

        {!isCoverImageLoading && <img src={coverUrl} alt="Cover source" className={classes.coverImage} />}

        {isCoverImageLoading && (
          <div className={classes.coverImageSkeletonContainer}>
            <Skeleton.Image className={classes.coverImageSkeleton} />
            <div className={classes.coverImageLoadingSpinner}>
              <LoadingOutlined />
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderScenarioVariantInfo = () => {
    const variantId = referenceVariant.id;
    if (!variantId) {
      return null;
    }

    return (
      <ScenarioVariantDescription
        trigger={
          <Button type="default" icon={<EyeFilled />} size="small">
            {t('charters.scenarios.scenarioMetadataForm.seeScenarioDetails')}
          </Button>
        }
        scenarioId={scenario.id}
        scenarioCode={scenario.code}
        scenarioVariantId={variantId}
      />
    );
  };

  const renderCharterTagForPrivateScenario = () => {
    if (scenario.scope === ScenarioScopes.PUBLIC.code || !currentCharter) {
      return null;
    }

    return <Tag className={classes.charterTag}>{currentCharter.name}</Tag>;
  };

  const renderFormats = () => {
    const formats = scenario.variants
      .map((variant) => ScenarioUtils.getVariantFormatByCode(variant.format))
      .filter((scenarioFormat): scenarioFormat is ScenarioFormat => !!scenarioFormat);

    return (
      <div className={classes.formatsContainer}>
        {formats.map((scenarioFormat) => (
          <FormatTag format={t(scenarioFormat.key)} image={scenarioFormat.image} key={scenarioFormat.code} />
        ))}
      </div>
    );
  };

  return (
    <AccessChecker
      hasAccess={hasAccessToScenario}
      renderUnauthorizedMessage={permissionToUseScenario.renderUnauthorizedMessage}
    >
      <Card
        className={classNames(
          classes.scenarioCard,
          isSelected && classes.selectedCard,
          onClick && classes.clickableCard,
          !hasAccessToScenario && classes.disabledCard
        )}
        onClick={hasAccessToScenario ? onClick : undefined}
      >
        <div className={classes.scenarioMetadataContainer}>
          {renderCoverImage()}

          <div className={classes.scenarioTitleAndDescriptionContainer}>
            <Paragraph className={classes.scenarioTitle} ellipsis={{ tooltip: title, rows: 2 }}>
              {title}
            </Paragraph>

            <Paragraph className={classes.scenarioDescription} ellipsis={{ tooltip: description, rows: 2 }}>
              {description}
            </Paragraph>
          </div>
        </div>

        <div className={classes.charterAndFormatsContainer}>
          {renderCharterTagForPrivateScenario()}
          {renderFormats()}
        </div>

        <div className={classes.scenarioDescriptionIcon} onClick={(e) => e.stopPropagation()}>
          {renderScenarioVariantInfo()}
        </div>
      </Card>
    </AccessChecker>
  );
};

export default ScenarioCard;
