import { SearchOutlined } from '@ant-design/icons/lib';
import { AutoComplete, Button, Menu } from 'antd';
import { RefSelectProps } from 'antd/lib/select';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import classNames from 'classnames';
import Fuse from 'fuse.js';
import { compact, map, uniq } from 'lodash';
import omit from 'lodash/omit';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import StringUtils from '../../../utils/StringUtils';

type Props = {
  dataIndex: string;
  filter?: Record<string, string>;
  setFilter: React.Dispatch<React.SetStateAction<Record<string, string> | undefined>>;
  placeholder: string;
  fuse?: Fuse<any>;
  possibleValues?: string[];
  className?: string;
  handleSearch?: (confirm: () => void) => void;
  runHelp?: boolean;
} & FilterDropdownProps;

const useStyles = createUseStyles({
  mainContainer: {
    padding: 0,
    minWidth: '13rem',
    '& .ant-menu-item:active': {
      background: 'unset !important',
    },
  },
  autocompleteContainer: {
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .ant-menu-title-content': {
      width: '100%',
    },
  },
  input: {
    width: '100%',
    pointerEvents: 'auto',
  },
  actionsContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    lineHeight: 'unset !important',
    height: 'unset !important',
    justifyContent: 'space-between',
    padding: '7px 8px 7px 3px !important',
    marginTop: '0px !important',
    marginBottom: '0px !important',
  },
  noSpaceBetweenIconAndText: {
    '& .anticon': {
      marginRight: '0px !important',
    },
  },
});

const SearchInColumnFilter: FunctionComponent<Props> = ({
  dataIndex,
  setSelectedKeys,
  filter,
  setFilter,
  selectedKeys,
  confirm,
  clearFilters,
  visible,
  fuse,
  possibleValues,
  placeholder,
  className,
  handleSearch,
  runHelp,
}: Props) => {
  const input = useRef<RefSelectProps>(null);
  const { t } = useTranslation();
  const classes = useStyles();
  const [options, setOptions] = useState(possibleValues);

  useEffect(() => {
    if (input && input.current && visible) {
      setTimeout(() => {
        input.current!.focus();
      }, 200);
    }
  }, [visible]);

  const handleReset = (): void => {
    setSelectedKeys(['']);
    if (clearFilters) {
      clearFilters();
    }
    setFilter((prev) => omit(prev, dataIndex));
  };

  const handleConfirm = (): void => {
    const newValue = StringUtils.normalize(selectedKeys.toString()) || undefined;
    if (!newValue) {
      handleReset();
      return;
    }

    if (handleSearch && filter && filter[dataIndex] !== newValue) {
      handleSearch(confirm);
    } else {
      confirm();
    }
    setFilter((prev) => ({ ...prev, [dataIndex]: newValue }));
  };

  const onChange = (data: string): void => {
    const exactMatchingItems =
      possibleValues?.filter((v) => StringUtils.normalize(v).includes(StringUtils.normalize(data))) ?? [];
    if (fuse) {
      const result = fuse.search(data);
      const items = map(result, ({ item }: { item: any }) => {
        return item[dataIndex];
      });

      setOptions(compact(uniq([...items, ...exactMatchingItems])));
    } else {
      setOptions(exactMatchingItems);
    }
    setSelectedKeys([data]);
  };

  useEffect(() => {
    if (runHelp) {
      handleReset();
    }
  }, [runHelp]);

  return (
    <Menu className={classNames(classes.mainContainer, className)} selectable={false}>
      <Menu.Item className={classes.autocompleteContainer}>
        <AutoComplete
          options={options?.map((value) => ({ value, key: `autocomplete_${value}_${Math.random()}` }))}
          onChange={onChange}
          className={classes.input}
          value={selectedKeys[0]?.toString() || ''}
          ref={input}
          placeholder={placeholder}
          backfill
        />
      </Menu.Item>

      <Menu.Divider />

      <Menu.Item className={classes.actionsContainer}>
        <Button onClick={handleReset} size="small" type="link" disabled={!selectedKeys[0]}>
          {t('global.reset')}
        </Button>
        <Button
          type="primary"
          onClick={handleConfirm}
          icon={<SearchOutlined />}
          size="small"
          className={classes.noSpaceBetweenIconAndText}
        >
          {t('global.search')}
        </Button>
      </Menu.Item>
    </Menu>
  );
};

export default SearchInColumnFilter;
