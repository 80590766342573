/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */

import Format16_9 from '../../resources/img/formats/format_16_9.png';
import Format1_1 from '../../resources/img/formats/format_1_1.png';
import Format9_16 from '../../resources/img/formats/format_9_16.png';
import OverlayChestCenter from '../../resources/img/overlays/overlay-chest-center.png';
import OverlayChestLeft from '../../resources/img/overlays/overlay-chest-left.png';
import OverlayChestRight from '../../resources/img/overlays/overlay-chest-right.png';
import OverlayFaceCenter from '../../resources/img/overlays/overlay-face-center.png';
import OverlayFaceLeft from '../../resources/img/overlays/overlay-face-left.png';
import OverlayFaceRight from '../../resources/img/overlays/overlay-face-right.png';
import OverlayMidchestCenter from '../../resources/img/overlays/overlay-midchest-center.png';
import OverlayMidchestLeft from '../../resources/img/overlays/overlay-midchest-left.png';
import OverlayMidchestRight from '../../resources/img/overlays/overlay-midchest-right.png';
import { MediaFile } from '../../services/api/types/ChartersServiceTypes';
import { AnimationConfig } from './AnimationTypes';

export type ScenarioSceneTemplate = {
  index: number;
  kindIndex?: number;
  id?: string;
  title: string;
  description: string;
  kind: string; // TemplateSequenceKind (code)
  demoCoverImageSource?: MediaFile;
  demoVideoUri?: MediaFile;
  videoRecommendedTime: number;
  excludeWatermark?: boolean;
  overlayTexts?: TemplateCameraOverlayText[];
  animationText?: AnimationConfig;
  backgroundMusicVolume: number;
  videoMinRecordTime?: number;
  videoMaxRecordTime?: number;
  overlayImage?: string; // TemplateCameraOverlayImage
  userVideoAudioVolume: number;
  userPip?: TemplateVideoPip;
};

export const TemplateSequenceKind: SequenceKindOptions = {
  RECORDABLE: { code: 'Recordable', key: 'charters.scenarios.variantEditor.sceneForm.fields.kindOptions.recordable' },
  SLIDE: { code: 'Slide', key: 'charters.scenarios.variantEditor.sceneForm.fields.kindOptions.slide' },
};

export const DemoMediaType: DemoMediaTypeOptions = {
  VIDEO: { code: 'VIDEO', key: 'charters.scenarios.variantEditor.sceneForm.fields.demoMediaTypeOptions.video' },
  IMAGE: { code: 'IMAGE', key: 'charters.scenarios.variantEditor.sceneForm.fields.demoMediaTypeOptions.image' },
};

export type TemplateCameraOverlayText = {
  id: string;
  placeholderText?: string;
  demoText: string;
  text: string;
};

export type TemplateVideoPip = {
  demoSource?: MediaFile;
  duration: number;
  effect?: {
    // PipEffect
    name: string;
    value?: number;
  };
};

export const TemplateCameraOverlayImage: CameraOverlayImageOptions = {
  OVERLAY_CHEST_LEFT: {
    code: 'OverlayChestLeft',
    key: 'charters.scenarios.variantEditor.sceneForm.fields.overlayImageValues.overlayChestLeft',
    image: OverlayChestLeft,
  },
  OVERLAY_CHEST_CENTER: {
    code: 'OverlayChestCenter',
    key: 'charters.scenarios.variantEditor.sceneForm.fields.overlayImageValues.overlayChestCenter',
    image: OverlayChestCenter,
  },
  OVERLAY_CHEST_RIGHT: {
    code: 'OverlayChestRight',
    key: 'charters.scenarios.variantEditor.sceneForm.fields.overlayImageValues.overlayChestRight',
    image: OverlayChestRight,
  },
  OVERLAY_MIDCHEST_LEFT: {
    code: 'OverlayMidchestLeft',
    key: 'charters.scenarios.variantEditor.sceneForm.fields.overlayImageValues.overlayMidchestLeft',
    image: OverlayMidchestLeft,
  },
  OVERLAY_MIDCHEST_CENTER: {
    code: 'OverlayMidchestCenter',
    key: 'charters.scenarios.variantEditor.sceneForm.fields.overlayImageValues.overlayMidchestCenter',
    image: OverlayMidchestCenter,
  },
  OVERLAY_MIDCHEST_RIGHT: {
    code: 'OverlayMidchestRight',
    key: 'charters.scenarios.variantEditor.sceneForm.fields.overlayImageValues.overlayMidchestRight',
    image: OverlayMidchestRight,
  },
  OVERLAY_FACE_LEFT: {
    code: 'OverlayFaceLeft',
    key: 'charters.scenarios.variantEditor.sceneForm.fields.overlayImageValues.overlayFaceLeft',
    image: OverlayFaceLeft,
  },
  OVERLAY_FACE_CENTER: {
    code: 'OverlayFaceCenter',
    key: 'charters.scenarios.variantEditor.sceneForm.fields.overlayImageValues.overlayFaceCenter',
    image: OverlayFaceCenter,
  },
  OVERLAY_FACE_RIGHT: {
    code: 'OverlayFaceRight',
    key: 'charters.scenarios.variantEditor.sceneForm.fields.overlayImageValues.overlayFaceRight',
    image: OverlayFaceRight,
  },
  OVERLAY_CHEST_PORTRAIT: {
    code: 'OverlayChestPortrait',
    key: 'charters.scenarios.variantEditor.sceneForm.fields.overlayImageValues.overlayChestPortrait',
    image: OverlayChestCenter,
  },
  OVERLAY_MIDCHEST_PORTRAIT: {
    code: 'OverlayMidchestPortrait',
    key: 'charters.scenarios.variantEditor.sceneForm.fields.overlayImageValues.overlayMidchestPortrait',
    image: OverlayMidchestCenter,
  },
  OVERLAY_FACE_PORTRAIT: {
    code: 'OverlayFacePortrait',
    key: 'charters.scenarios.variantEditor.sceneForm.fields.overlayImageValues.overlayFacePortrait',
    image: OverlayFaceCenter,
  },
  OVERLAY_CHEST_SQUARE: {
    code: 'OverlayChestSquare',
    key: 'charters.scenarios.variantEditor.sceneForm.fields.overlayImageValues.overlayChestSquare',
    image: OverlayChestCenter,
  },
  OVERLAY_MIDCHEST_SQUARE: {
    code: 'OverlayMidchestSquare',
    key: 'charters.scenarios.variantEditor.sceneForm.fields.overlayImageValues.overlayMidchestSquare',
    image: OverlayMidchestCenter,
  },
  OVERLAY_FACE_SQUARE: {
    code: 'OverlayFaceSquare',
    key: 'charters.scenarios.variantEditor.sceneForm.fields.overlayImageValues.overlayFaceSquare',
    image: OverlayFaceCenter,
  },
};

export const UserVideoAudioVolume: UserVideoAudioVolumeOptions = {
  VOLUME_ON: {
    code: 'VOLUME_ON',
    key: 'charters.scenarios.variantEditor.sceneForm.fields.userVideoAudioVolumeValues.volumeOn',
    value: 1,
  },
  VOLUME_MUTED: {
    code: 'VOLUME_MUTED',
    key: 'charters.scenarios.variantEditor.sceneForm.fields.userVideoAudioVolumeValues.volumeMuted',
    value: 0,
  },
};

export const ScenarioLanguages: ScenarioLanguagesOptions = {
  FR: { code: 'fr', key: 'charters.scenarios.languageOptions.french', flag: '🇫🇷' },
  EN: { code: 'en', key: 'charters.scenarios.languageOptions.english', flag: '🇬🇧' },
};

export const ScenarioFormats: ScenarioFormatsOptions = {
  LANDSCAPE_16_9: {
    code: '16:9',
    key: 'charters.scenarios.formatOptions.16_9',
    image: Format16_9,
    allowedOverlayImages: [
      TemplateCameraOverlayImage.OVERLAY_CHEST_LEFT,
      TemplateCameraOverlayImage.OVERLAY_CHEST_CENTER,
      TemplateCameraOverlayImage.OVERLAY_CHEST_RIGHT,
      TemplateCameraOverlayImage.OVERLAY_MIDCHEST_LEFT,
      TemplateCameraOverlayImage.OVERLAY_MIDCHEST_CENTER,
      TemplateCameraOverlayImage.OVERLAY_MIDCHEST_RIGHT,
      TemplateCameraOverlayImage.OVERLAY_FACE_LEFT,
      TemplateCameraOverlayImage.OVERLAY_FACE_CENTER,
      TemplateCameraOverlayImage.OVERLAY_FACE_RIGHT,
    ],
  },
  PORTRAIT_9_16: {
    code: '9:16',
    key: 'charters.scenarios.formatOptions.9_16',
    image: Format9_16,
    allowedOverlayImages: [
      TemplateCameraOverlayImage.OVERLAY_CHEST_PORTRAIT,
      TemplateCameraOverlayImage.OVERLAY_MIDCHEST_PORTRAIT,
      TemplateCameraOverlayImage.OVERLAY_FACE_PORTRAIT,
    ],
  },
  SQUARE_1_1: {
    code: '1:1',
    key: 'charters.scenarios.formatOptions.1_1',
    image: Format1_1,
    allowedOverlayImages: [
      TemplateCameraOverlayImage.OVERLAY_CHEST_SQUARE,
      TemplateCameraOverlayImage.OVERLAY_MIDCHEST_SQUARE,
      TemplateCameraOverlayImage.OVERLAY_FACE_SQUARE,
    ],
  },
};

export const ScenarioScopes: ScenarioScopesOptions = {
  PUBLIC: { code: 'PUBLIC', key: 'charters.scenarios.scenarioMetadataForm.scopeOptions.public' },
  PRIVATE: { code: 'PRIVATE', key: 'charters.scenarios.scenarioMetadataForm.scopeOptions.private' },
};

export const ScenarioStatus: ScenarioStatusOptions = {
  PUBLISHED: {
    code: 'PUBLISHED',
    key: 'charters.scenarios.status.published.key',
    color: 'green',
  },
  DRAFT: {
    code: 'DRAFT',
    key: 'charters.scenarios.status.draft.key',
    color: 'gold',
  },
  ARCHIVED: {
    code: 'ARCHIVED',
    key: 'charters.scenarios.status.archived.key',
  },
};

export type ScenarioLanguagesOptions = Record<string, ScenarioLanguage>;
export type ScenarioLanguage = { code: string; key: string; flag: string };

export type ScenarioFormatsOptions = Record<string, ScenarioFormat>;
export type ScenarioFormat = {
  code: string;
  key: string;
  image: string;
  allowedOverlayImages: CameraOverlayImage[];
};

export type ScenarioScopesOptions = Record<string, ScenarioScope>;
export type ScenarioScope = { code: string; key: string };

export type ScenarioStatusOptions = Record<string, ScenarioStatus>;
export type ScenarioStatus = { code: string; key: string; color?: string };

export type SequenceKindOptions = Record<string, SequenceKind>;
export type SequenceKind = {
  code: string;
  key: string;
};

export type DemoMediaTypeOptions = Record<string, DemoMediaType>;
export type DemoMediaType = {
  code: string;
  key: string;
};

export type CameraOverlayImageOptions = Record<string, CameraOverlayImage>;
export type CameraOverlayImage = {
  code: string;
  key: string;
  image?: string;
};

export type UserVideoAudioVolumeOptions = Record<string, UserVideoAudioVolumeOption>;
export type UserVideoAudioVolumeOption = {
  code: string;
  key: string;
  value: number;
};
