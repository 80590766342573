import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactJoyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride';
import { THEME_KEY } from '../Constants';
import JoyRideUtils from '../utils/JoyRideUtils';
import KannelleJoyRideTooltip from './KannelleJoyRideTooltip';

type Props = {
  runHelp: boolean;
  callbackRunDone: () => void;
  callbackTogglePanel: (panelKey: string) => void;
};
const JoyRideHelpChartersTheme: FunctionComponent<Props> = ({
  runHelp,
  callbackRunDone,
  callbackTogglePanel,
}: Props) => {
  const [stepIndex, setStepIndex] = useState(0);

  const { t } = useTranslation();
  const [run, setRun] = useState(false);

  useEffect(() => {
    setRun(runHelp);
  }, [runHelp]);

  const handleStepIndex = (action: string): void => {
    setStepIndex(stepIndex + (action === ACTIONS.PREV ? -1 : 1));
  };

  const handleJoyrideCallback = (data: CallBackProps): void => {
    const { status, type, step, action, lifecycle } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if ((finishedStatuses.includes(status) && lifecycle === 'init') || status === STATUS.SKIPPED) {
      setStepIndex(0);
      callbackRunDone();
    }

    if (action === ACTIONS.CLOSE && (type === EVENTS.TOOLTIP_CLOSE || type === EVENTS.TOUR_END)) {
      setRun(false);
      setStepIndex(0);
      callbackRunDone();
    }

    if (type === EVENTS.STEP_AFTER) {
      if (stepIndex === 0) {
        callbackTogglePanel('init');
        // little hack to let the panel expand
        setTimeout(() => {
          handleStepIndex(action);
        }, 500);
      } else if (stepIndex === 2) {
        callbackTogglePanel('init');
        callbackTogglePanel(THEME_KEY.ALGIERS.code);
        // little hack to let the panel expand
        setTimeout(() => {
          handleStepIndex(action);
        }, 500);
      } else {
        handleStepIndex(action);
      }
    }

    if (type === EVENTS.TOOLTIP) {
      if (typeof step.target === 'string') {
        const element = document.getElementsByClassName(step.target)[0];
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        }
      } else {
        step.target.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }
    }
  };

  const steps = JoyRideUtils.getCharterThemeSteps(t);

  return (
    <ReactJoyride
      continuous
      hideBackButton
      run={run}
      stepIndex={stepIndex}
      disableScrolling
      showProgress
      showSkipButton
      tooltipComponent={KannelleJoyRideTooltip}
      steps={steps}
      callback={handleJoyrideCallback}
      locale={{
        back: t('onBoarding.steps.back'),
        close: t('onBoarding.steps.close'),
        last: t('onBoarding.steps.last'),
        next: t('onBoarding.steps.next'),
        open: t('onBoarding.steps.open'),
        skip: t('onBoarding.steps.skip'),
      }}
    />
  );
};

export default JoyRideHelpChartersTheme;
