import { filter } from 'lodash';
import { Roles } from '../core/rule/RolesTypes';
import { APIBundles } from '../services/api/types/BundlesServiceTypes';
import { UsersModelCharter } from '../services/api/types/ChartersServiceTypes';
import { APICompanyBundleParams } from '../services/api/types/CompaniesServiceTypes';
import { CompanyBundle } from './types/BundlesTypes';

export default class BundlesUtils {
  static formatApiBundlesToStore = (bundleFromApi: APIBundles): CompanyBundle => {
    const formattedBundle: CompanyBundle = {
      bundleId: bundleFromApi.id,
      bundleName: bundleFromApi.name,
      featureValues: {},
    };

    bundleFromApi.features.forEach((feature) => {
      formattedBundle.featureValues[feature.code] =
        feature.customValue !== undefined ? feature.customValue : feature.defaultValue;
    });

    return formattedBundle;
  };

  static formatApiCompanyBundleParamsToStore = (bundleFromApi: APICompanyBundleParams): CompanyBundle => {
    const formattedBundle: CompanyBundle = {
      bundleId: 0,
      bundleName: bundleFromApi.bundleName,
      featureValues: {},
    };

    bundleFromApi.featureValues?.forEach(({ featureCode, value }) => {
      formattedBundle.featureValues[featureCode] = value;
    });
    bundleFromApi.featureOverrides?.forEach(({ featureCode, customValue }) => {
      formattedBundle.featureValues[featureCode] = customValue;
    });

    return formattedBundle;
  };

  static canAddUser = (users: UsersModelCharter[], bundle?: CompanyBundle, isCurrentUserKnlTeam = false): boolean => {
    if (!bundle || isCurrentUserKnlTeam) {
      return true;
    }

    const filteredUsers = filter(users, (user) => user.acl.role !== Roles.KnlTeam && user.acl.isActive);
    const ownerAndAdminLimit = (bundle.featureValues.MAX_ADMINS_AND_OWNERS as number) || 0;
    const creatorLimit = (bundle.featureValues.MAX_CREATORS as number) || 0;

    return creatorLimit + ownerAndAdminLimit > filteredUsers.length;
  };

  /**
   * Only return the relevant props for the API
   * @param bundleFromApi
   */
  static formatBundleForApi = (bundleFromApi: APICompanyBundleParams): APICompanyBundleParams => {
    return {
      bundleId: bundleFromApi.bundleId,
      featureOverrides: bundleFromApi.featureOverrides,
    };
  };
}
