import { Modal } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  message: string;
  visible: boolean;
  callBackValidation: (validation: boolean) => void;
};

const ValidationModal: FunctionComponent<Props> = ({ message, visible, callBackValidation }: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!visible) {
      setIsLoading(false);
    }
  }, [visible]);

  const hideModal = (validation: boolean) => {
    setIsLoading(true);
    callBackValidation(validation);
  };

  return (
    <Modal
      centered
      visible={visible}
      onOk={(): void => hideModal(true)}
      okButtonProps={{ loading: isLoading }}
      onCancel={(): void => hideModal(false)}
      okText={t('global.ok')}
      cancelText={t('global.close')}
    >
      {message}
    </Modal>
  );
};

export default ValidationModal;
