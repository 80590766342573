import { Button, message } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { getChargebee } from '../../services/api/ChargebeeService';
import { APIPortalPostChargebeeResult } from '../../services/api/types/ChargebeeServiceTypes';

type Props = {
  chargebeeCustomer?: string;
};

const useStyles = createUseStyles({
  button: {
    marginLeft: 8,
    marginRight: 8,
  },
});

const ChargebeePortal: FunctionComponent<Props> = ({ chargebeeCustomer }: Props) => {
  const [portalSession, setPortalSession] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    if (!chargebeeCustomer || !portalSession) {
      return;
    }

    if (window && window.Chargebee) {
      const { Chargebee } = window;
      const chargebeeInstance = Chargebee.init({
        site: process.env.REACT_APP_CHARGEBEE_SITE,
      });
      chargebeeInstance.setPortalSession(() => {
        return getChargebee(chargebeeCustomer).then((result: APIPortalPostChargebeeResult) => {
          return result.data;
        });
      });
      const cbPortal = chargebeeInstance.createChargebeePortal();

      cbPortal.open({
        close() {
          setPortalSession(false);
        },
      });
    }
  }, [chargebeeCustomer, portalSession]);

  if (!chargebeeCustomer) {
    return null;
  }

  const handleClick = (): void => {
    if (!chargebeeCustomer) {
      message.error(t('company.form.warningChargebeePlanCheckout'));
      return;
    }

    setPortalSession(true);
  };

  return (
    <Button className={classes.button} onClick={handleClick}>
      {t('company.form.openPortal')}
    </Button>
  );
};

export default ChargebeePortal;
