import { Button, Modal } from 'antd';
import { CancelTokenSource } from 'axios';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactJoyride, { CallBackProps, EVENTS, STATUS } from 'react-joyride';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { LINK, LOGGING_EVENT, THEME } from '../Constants';
import { useAuth0 } from '../core/auth/Auth0Config';
import { openMenuItem, setOnBoarding, setOpenMenuItem, toggleMenu } from '../redux/action/AppAction';
import { RootState } from '../redux/RootState';
import smallLogo from '../resources/img/K-rectangle-small.png';
import { APIManager } from '../services/api/APIManager';
import { patchUser } from '../services/api/UsersService';
import JoyRideUtils from '../utils/JoyRideUtils';
import KannelleJoyRideTooltip from './KannelleJoyRideTooltip';

const useStyles = createUseStyles({
  modalMobile: {
    maxWidth: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    '& .ant-modal-content': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    '& .ant-modal-footer': {
      display: 'flex',
      justifyContent: 'center',
      borderTop: 'unset',
    },
    '& .ant-modal-body': {
      height: '100%',
    },
  },
  modalContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '24px 0 0 0',
  },
  modalLogo: {
    width: '20%',
  },
  title: {
    color: THEME.MENU.MAIN_COLOR,
    fontWeight: 'bold',
  },
  emoji: {
    margin: '0 8px',
  },
});

const JoyRideOnBoarding: FunctionComponent = () => {
  const isMobileOrTablet = useSelector((state: RootState) => state.app.isMobileOrTablet);
  const onboardingDone = useSelector((state: RootState) => state.app.onboardingDone);
  const companies = useSelector((state: RootState) => state.companies.list);
  const company = useSelector((state: RootState) => state.companies.current);
  const user = useSelector((state: RootState) => state.user.user);
  const menuCollapsed = useSelector((state: RootState) => state.app.menuCollapsed);
  const charters = useSelector((state: RootState) => state.charters.list);
  const loggingManager = useSelector((state: RootState) => state.app.loggingManager);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user: userAuth0 } = useAuth0();

  const classes = useStyles();
  const [runRide, setRunRide] = useState(false);
  const [modalOpen, setModalOpen] = useState(!user?.onboarded?.web || false);
  const [modalOpenBye, setModalOpenBye] = useState(false);
  const cancelTokenSourceRef = useRef<CancelTokenSource>(APIManager.getCancelToken());

  useEffect(() => {
    if (!onboardingDone && userAuth0.email_verified) {
      setModalOpen(true);
    }
  }, [onboardingDone, userAuth0]);

  useEffect(() => {
    if (runRide) {
      document.body.classList.add('overflowHidden');
    } else {
      document.body.classList.remove('overflowHidden');
    }
  }, [runRide]);

  useEffect(() => {
    const cancelToken = cancelTokenSourceRef.current;
    return (): void => {
      cancelToken.cancel('Cancelled patch on unmount');
    };
    // eslint-disable-next-line
  }, []);

  const handlePatchUser = (): void => {
    if (!user) {
      return;
    }

    if (!user.onboarded?.web) {
      patchUser(user.id, { onboarded: { web: true } }, cancelTokenSourceRef.current);
    }
  };

  const handleJoyrideCallback = (data: CallBackProps): void => {
    const { status, type, step, lifecycle } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status) && lifecycle === 'complete') {
      setRunRide(false);
      dispatch(setOnBoarding(true));
      dispatch(setOpenMenuItem([]));

      handlePatchUser();
      setModalOpenBye(true);

      loggingManager.logEvent(LOGGING_EVENT.ONBOARDING_FINISHED, {
        email: user?.email,
        name: user?.name,
        status,
        companyId: company?.id,
      });
    }

    if (type === EVENTS.TOOLTIP) {
      if (step.target === '.charters-link') {
        dispatch(openMenuItem(LINK.CHARTERS.key));
      } else if (step.target === '.first-charter-link') {
        if (charters && charters.length >= 0) {
          dispatch(openMenuItem(`/charters/${charters[0].id}`));
        }
      }
    }
  };

  const handleSubmit = (): void => {
    setModalOpen(false);
    if (menuCollapsed && !isMobileOrTablet) {
      dispatch(toggleMenu());
    }
    setRunRide(true);
  };

  const handleCancelOnboarding = (): void => {
    setModalOpen(false);
    dispatch(setOnBoarding(true));
    handlePatchUser();
  };

  const handleCloseByeModal = (): void => {
    setModalOpenBye(false);
  };

  const steps = isMobileOrTablet
    ? JoyRideUtils.getOnBoardingStepMobile(t, companies)
    : JoyRideUtils.getOnBoardingStepComputer(t, companies);

  return (
    <>
      <ReactJoyride
        continuous
        run={runRide}
        scrollToFirstStep
        showProgress
        showSkipButton
        tooltipComponent={KannelleJoyRideTooltip}
        steps={steps}
        callback={handleJoyrideCallback}
        disableScrollParentFix
        locale={{
          back: t('onBoarding.steps.back'),
          close: t('onBoarding.steps.close'),
          last: t('onBoarding.steps.last'),
          next: t('onBoarding.steps.next'),
          open: t('onBoarding.steps.open'),
          skip: t('onBoarding.steps.skip'),
        }}
      />

      <Modal
        className={isMobileOrTablet ? classes.modalMobile : ''}
        centered
        visible={modalOpen}
        footer={[
          <Button type="text" key="backJoyRide" onClick={handleCancelOnboarding}>
            {t('onBoarding.welcomeModal.leave')}
          </Button>,
          <Button onClick={handleSubmit} key="submitJoyRide" type="primary">
            {t('onBoarding.welcomeModal.start')}
          </Button>,
        ]}
        onCancel={handleCancelOnboarding}
      >
        <div className={classes.modalContainer}>
          <img className={classes.modalLogo} src={smallLogo} alt="logo" />
          <h1 className={classes.title}>{t('onBoarding.welcomeModal.title')}</h1>
          <div>
            <p>
              {t('onBoarding.welcomeModal.contentPart1')}
              <span role="img" aria-label="emoji" className={classes.emoji}>
                😃
              </span>
            </p>
            <p>{t('onBoarding.welcomeModal.contentPart2')}</p>
          </div>
        </div>
      </Modal>

      <Modal
        className={isMobileOrTablet ? classes.modalMobile : ''}
        centered
        visible={modalOpenBye}
        onCancel={handleCloseByeModal}
        footer={[
          <Button type="text" key="backBye" onClick={handleCloseByeModal}>
            {t('global.close')}
          </Button>,
        ]}
      >
        <div className={classes.modalContainer}>
          <h1 className={classes.title}>{t('onBoarding.closingOnBoardingModal.title')}</h1>

          <p>
            <span role="img" aria-label="emoji" className={classes.emoji}>
              👋
            </span>
            {t('onBoarding.closingOnBoardingModal.content')}

            <a href={`mailto:${t('onBoarding.closingOnBoardingModal.link')}`}>
              {t('onBoarding.closingOnBoardingModal.link')}
            </a>
            {t('onBoarding.closingOnBoardingModal.emojiEnd')}
          </p>
        </div>
      </Modal>
    </>
  );
};

export default JoyRideOnBoarding;
